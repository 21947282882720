import { FullProfileData } from '../../../api/api'
import {
    Education as EducationModel,
    EducationType as MySpecialistsEducationType,
    MySpecialistsAnonymousEducation,
} from '../../../contracts/profile/education'
import { Profile } from '../../../contracts/profile/profile'
import { Education, EducationType } from '../../../contracts/specialist/specialistEducations'
import { history } from '../../../history'
import { randomString } from '../../../utils/strings'

const transformEducationType = (type: MySpecialistsEducationType) => {
    switch (type) {
        case 'BACHELOR_STUDIES':
            return EducationType.MASTER_STUDIES
        case 'MASTER_STUDIES':
            return EducationType.MASTER_STUDIES
        case 'PHD_STUDIES':
            return EducationType.PHD_STUDIES
        case 'CERTIFICATE_COURSE':
            return EducationType.CERTIFICATE_COURSE
        case 'LANGUAGE_COURSE':
            return EducationType.LANGUAGE_COURSE
        case 'CERTIFICATE':
            return EducationType.CERTIFICATE_COURSE
        case 'COURSE':
            return EducationType.CERTIFICATE_COURSE
        case 'EDUCATION':
            return EducationType.OTHER_STUDIES
        case 'OTHER_STUDIES':
            return EducationType.OTHER_STUDIES
    }
}

const getLink = (
    matchTalentMarketplaceProfile: boolean,
    matchPublicProfile: boolean,
    matchAnonymousProfile: boolean,
    shareLinkId?: string,
): string => {
    if (matchTalentMarketplaceProfile) {
        return `/public/profile/${shareLinkId}`
    } else if (matchPublicProfile || matchAnonymousProfile) {
        return `${history.location.pathname}`
    } else {
        return `/anonymous/profile/${shareLinkId}`
    }
}

const unifiedProfileData = (anonymousProfile: Profile, link: string): FullProfileData => ({
    specialist: {
        city: null,
        country: null,
        email: '',
        employmentType: null,
        remote: '',
        role: anonymousProfile.role,
        seniority: anonymousProfile.seniority,
        firstName: anonymousProfile.firstName || '',
        lastName: anonymousProfile.lastName || '',
        id: anonymousProfile?.specialistId || '',
    },
    summary: {
        experience: anonymousProfile.experience || null,
        recentIndustry: anonymousProfile.recentIndustries[0] || null,
        recentProject: anonymousProfile.recentProjects[0] || null,
        recentRole: anonymousProfile?.recentRoles[0] || null,
    },
    projects: anonymousProfile.projects.map(project => ({
        id: randomString(),
        role: project.positions[0].roles[0],
        currentlyWorkingThere: false,
        from: project.positions[0].from || null,
        to: project.positions[0].to || null,
        project: '',
        industry: project.positions[0].industry,
        stack: project.positions[0].techStack,
        description: project.positions[0].description || null,
        company: project.companyName,
        country: '',
        city: '',
    })),
    education: (anonymousProfile.education as Array<MySpecialistsAnonymousEducation>).map(
        (education): Education => ({
            id: randomString(12),
            type: transformEducationType(education.type),
            from: education.from ? education.from : null,
            to: education.to ? education.to : null,
            inProgress: education.inProgress ? education.inProgress : !!education.to ? !!education.to : false,
            studiesSpecialization: education.studiesSpecialization || '',
            studiesDepartment: education.studiesDepartment || '',
            studiesUniversity: education.studiesUniversity || '',
            certificationAcquisitionDate: education.certificationAcquisitionDate || null,
            certificationHasExpiration: !!education.to,
            certificationExpirationDate: education.to || null,
            certificationTitle: education.certificationTitle || '',
            certificationOrganization: education.certificationOrganization || '',
            languageName: education.languageName || '',
            languageSchool: education.languageSchool || '',
        }),
    ),
    languages: anonymousProfile.languages,
    techSkills: anonymousProfile.skillMatrix,
    link,
    personalStrengths: anonymousProfile.personalStrengths || null,
    generalInformation: {
        email: '',
        role: anonymousProfile.role,
        seniority: anonymousProfile.seniority,
        industries: anonymousProfile.recentIndustries,
        responsibilities: anonymousProfile.recentRoles,
        topSkills: anonymousProfile.topSkills || [],
        verifiedTopSkills: anonymousProfile.verifiedTopSkills || [],
        potentialRoles: anonymousProfile.potentialRoles || [],
        experienceYears: anonymousProfile.experienceInYears,
        customFields: [],
        profileSummary: null,
    },
    verifiedTopSkills: anonymousProfile.verifiedTopSkills,
})

const unifiedTalentMarketplaceProfileData = (anonymousProfile: Profile, link: string): FullProfileData => {
    return {
        specialist: {
            city: null,
            country: null,
            email: '',
            employmentType: null,
            remote: '',
            role: anonymousProfile.role,
            seniority: anonymousProfile.seniority,
            firstName: anonymousProfile.firstName || '',
            lastName: anonymousProfile.lastName || '',
            id: anonymousProfile?.specialistId || '',
        },
        summary: {
            experience: anonymousProfile.experience || null,
            recentIndustry: anonymousProfile.recentIndustries[0] || null,
            recentProject: anonymousProfile.recentProjects[0] || null,
            recentRole: anonymousProfile?.recentRoles[0] || null,
        },
        projects: anonymousProfile.projects.map(project => ({
            id: randomString(),
            role: project.positions[0].roles[0],
            currentlyWorkingThere: false,
            from: project.positions[0].from || null,
            to: project.positions[0].to || null,
            project: '',
            industry: project.positions[0].industry,
            stack: project.positions[0].techStack,
            description: project.positions[0].description || null,
            company: project.companyName,
            country: '',
            city: '',
        })),
        education: (anonymousProfile.education as Array<EducationModel>).map(
            (education: EducationModel): Education => ({
                id: randomString(12),
                type: transformEducationType(education.type),
                from: education.from ? education.from : null,
                to: education.to ? education.to : null,
                inProgress: false,
                studiesSpecialization: education.specialization || '',
                studiesDepartment: education.faculty || '',
                studiesUniversity: education.school || '',
                certificationAcquisitionDate: null,
                certificationHasExpiration: false,
                certificationExpirationDate: null,
                certificationTitle: education.name || '',
                certificationOrganization: education.school || '',
                languageName: education.name || '',
                languageSchool: education.school || '',
            }),
        ),
        languages: anonymousProfile.languages,
        techSkills: anonymousProfile.skillMatrix,
        link,
        personalStrengths: anonymousProfile.personalStrengths || null,
        generalInformation: {
            email: '',
            role: anonymousProfile.role,
            seniority: anonymousProfile.seniority,
            industries: anonymousProfile.recentIndustries,
            responsibilities: anonymousProfile.recentRoles,
            topSkills: anonymousProfile.topSkills || [],
            verifiedTopSkills: anonymousProfile.verifiedTopSkills || [],
            potentialRoles: anonymousProfile.potentialRoles || [],
            experienceYears: anonymousProfile.experienceInYears,
            customFields: [],
            profileSummary: null,
        },
        verifiedTopSkills: anonymousProfile.verifiedTopSkills,
    }
}

export { unifiedProfileData, unifiedTalentMarketplaceProfileData, getLink }
