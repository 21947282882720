/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import { ColumnsType } from 'antd/lib/table'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { DeepMap, FieldError, FieldValues } from 'react-hook-form'
import { BlueActionBox } from '../../../components/BlueActionBox'
import { Button } from '../../../components/Button'
import { Checkbox } from '../../../components/inputs/Checkbox'
import { ParsedCv } from '../../../contracts/cvs'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { useIsMobile } from '../../../utils/hooks'

type ResponsiveGridType<RecordType extends object = any> = FunctionComponent<
    React.PropsWithChildren<TableProps<RecordType> & MobileGridProps & ParsedCvsResponsiveGridProps>
>

type MobileGridItemProps = FunctionComponent<
    React.PropsWithChildren<{
        record: ParsedCv
        isSelected: boolean
        handleSelectChange: (checked: boolean, id: string) => void
        columns?: ColumnsType<ParsedCv>
        index: number
        disabled: boolean
    }>
>

type MobileGridProps = {
    onSortingChanged?: (field: string) => void
}

type ParsedCvsResponsiveGridProps = {
    selectedRowKeys: Array<any>
    onRowSelectedChange: (ids: Array<string>) => void
    errors: DeepMap<FieldValues, FieldError>
}

const Label = styled.div`
    color: ${COLOR_PALETTE.gray_4};
    font-size: 12px;
    line-height: 16px;
    height: 16px;
`

const MobileRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

const MobileGridItem: MobileGridItemProps = ({ record, isSelected, handleSelectChange, columns, index, disabled }) => {
    const handleCheckboxClick = useCallback((checked: any) => handleSelectChange(checked, record.id), [handleSelectChange, record.id])
    const getBackgroundColor = useMemo(() => (isSelected ? COLOR_PALETTE.blue_2 : COLOR_PALETTE.white), [isSelected])

    return (
        <div
            css={css`
                box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                border: 1px solid ${COLOR_PALETTE.gray_2};
                background-color: ${getBackgroundColor};
                border-radius: 2px;
                margin: 16px;
                flex-direction: column;
                padding: 24px 20px;
            `}
        >
            <div
                css={css`
                    display: flex;
                    border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                `}
            >
                <div
                    css={css`
                        width: 50px;
                    `}
                >
                    <Checkbox
                        checkboxLabel=''
                        name={`${record.id}`}
                        checked={isSelected}
                        onChange={handleCheckboxClick}
                        disabled={disabled}
                    />
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-wrap: wrap;
                        width: calc(100% - 50px);
                        align-items: center;
                    `}
                >
                    {columns?.map(column => {
                        const renderer = column?.render || null

                        return (
                            <div
                                key={`k_${column.key}`}
                                css={css`
                                    margin: 0 8px;
                                    width: calc(${column.key === 'skills' ? '11' : column.key === 'actions' ? '22' : '33'}% - 16px);

                                    ${mqMax[1]} {
                                        width: ${column.key === 'skills'
                                            ? 'calc(33% - 16px)'
                                            : column.key === 'actions'
                                            ? 'calc(66% - 16px)'
                                            : '100%'};
                                    }
                                `}
                            >
                                <MobileRowWrapper
                                    css={css`
                                        ${column.key === 'actions' && 'align-items:flex-end'}
                                    `}
                                >
                                    <>
                                        <Label>
                                            <>{column.title}</>
                                        </Label>
                                        {renderer && renderer(undefined, record, index)}
                                    </>
                                </MobileRowWrapper>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

const MobileGrid: ResponsiveGridType = ({ dataSource, columns, selectedRowKeys, onRowSelectedChange, errors }) => {
    const onCheckboxClicked = useCallback(
        (val: boolean, id: string): void =>
            val
                ? onRowSelectedChange([...selectedRowKeys.concat(id)])
                : onRowSelectedChange([...selectedRowKeys.filter(key => key !== id)]),
        [onRowSelectedChange, selectedRowKeys],
    )

    const isRowSelected = useCallback((id: string) => selectedRowKeys?.some(key => key === id), [selectedRowKeys])

    return (
        <div
            css={css`
                margin-bottom: 100px;
            `}
        >
            {dataSource?.map((record, index) => (
                <MobileGridItem
                    key={record.id}
                    record={record}
                    isSelected={isRowSelected(record.id)}
                    columns={columns}
                    handleSelectChange={onCheckboxClicked}
                    index={index}
                    disabled={!!errors?.parsedCvs?.[index]}
                />
            ))}
        </div>
    )
}

type ExtractedDataProps = {
    cvs: Array<ParsedCv>
    selectedParsedCvs: Array<string>
    columns: ColumnsType<ParsedCv>
    onSubmit: (e: any) => void
    validCvsLength: number
    errors: DeepMap<FieldValues, FieldError>
    handleSelectParsedCvs: (ids: Array<string>) => void
    handleDeselectAll: () => void
    handleSelectAll: () => void
    shouldBeVisible: boolean
    isSubmitting?: boolean
}

const ExtractedData: FunctionComponent<React.PropsWithChildren<ExtractedDataProps>> = ({
    cvs,
    selectedParsedCvs,
    columns,
    onSubmit,
    validCvsLength,
    errors,
    handleSelectParsedCvs,
    handleSelectAll,
    handleDeselectAll,
    shouldBeVisible,
    isSubmitting,
}) => {
    const rowKey = 'id'

    const isMobile = useIsMobile(2)

    return (
        <form onSubmit={onSubmit}>
            <div
                css={css`
                    padding: 0 25px;
                    display: ${shouldBeVisible ? 'block' : 'none'};
                `}
            >
                {selectedParsedCvs.length > 0 && (
                    <BlueActionBox
                        text={`${
                            selectedParsedCvs.length === validCvsLength
                                ? `You have selected ${selectedParsedCvs.length} specialists.`
                                : 'Do you want to select all of your Specialists? Only records with no validation errors will be selected.'
                        }`}
                        buttonText={selectedParsedCvs.length === validCvsLength ? 'Deselect All' : `Select All (${validCvsLength})`}
                        action={selectedParsedCvs.length === validCvsLength ? handleDeselectAll : handleSelectAll}
                        styles={css`
                            margin: 16px 0;
                            ${mqMax[2]} {
                                margin: 16px;
                            }
                        `}
                    />
                )}
                {isMobile ? (
                    <MobileGrid
                        rowKey={rowKey}
                        dataSource={cvs}
                        columns={columns}
                        selectedRowKeys={selectedParsedCvs}
                        onRowSelectedChange={handleSelectParsedCvs}
                        errors={errors}
                    />
                ) : (
                    <Table
                        css={css`
                            margin-bottom: 100px;
                            tbody tr:hover {
                                box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                            }
                            .ant-table-tbody > tr > td {
                                padding: 0 2px;

                                :first-of-type {
                                    padding: 0 16px;
                                }

                                :last-child {
                                    padding: 0 16px;
                                }
                            }
                            .ant-table-thead > tr > th {
                                vertical-align: top;
                            }

                            .ant-table-column-sorters {
                                padding: 0;
                            }
                            .ant-table-thead > tr > th {
                                padding: 16px 2px;
                            }
                        `}
                        pagination={false}
                        rowKey={rowKey}
                        dataSource={cvs}
                        columns={columns}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: handleSelectParsedCvs as any,
                            selectedRowKeys: selectedParsedCvs,
                            preserveSelectedRowKeys: false,
                            getCheckboxProps: record => ({
                                disabled: !!errors?.parsedCvs?.[cvs.findIndex(cv => cv.id === record.id)],
                                'data-testid': 'my-specialists-cv-parsing-checkbox',
                            }),
                        }}
                    />
                )}
            </div>
            <div
                css={css`
                    margin-top: 5px;
                    justify-content: flex-end;
                    padding: 17px 63px;
                    background-color: ${COLOR_PALETTE.blue_2};
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    display: ${shouldBeVisible ? 'flex' : 'none'};
                `}
            >
                <Button
                    disabled={selectedParsedCvs.length < 1 || isSubmitting}
                    type='submit'
                    dataTestId='my-specialists-cv-parsing-create-profiles'
                >
                    Create Profiles
                </Button>
            </div>
        </form>
    )
}

export { ExtractedData }
