export type Gender = '' | 'MALE' | 'FEMALE' | 'PREFER_NOT_TO_SAY' | 'OTHER'

const genderOptions = ['', 'Male', 'Female', 'Prefer not to say', 'Other']

type GenderMapping = {
    [k: string]: string
}

const genderMapping: GenderMapping = {
    MALE: 'Male',
    FEMALE: 'Female',
    PREFER_NOT_TO_SAY: 'Prefer not to say',
    OTHER: 'Other',
}

const mapGenderValue = (type: string) => {
    return genderMapping[type] || type
}

const mapGenderType = (value: string): Gender => {
    return (Object.keys(genderMapping).find(key => genderMapping[key] === value) || value) as Gender
}

export { genderOptions, mapGenderType, mapGenderValue }
