import { axios } from '../../api/axios'
import { Answer, FormDataModel } from './contracts'

const formsEndpoint = '/v1/forms'

const getForm = (formId: string, specialistId: string) => {
    return axios.get<FormDataModel>(`${formsEndpoint}/${formId}/specialist/${specialistId}`)
}

const saveFormAnswer = (formId: string, specialistId: string, answer: Answer) => {
    return axios.post<FormDataModel>(`${formsEndpoint}/${formId}/specialist/${specialistId}`, answer)
}

const submitForm = (formId: string, specialistId: string) => {
    return axios.post<FormDataModel>(`${formsEndpoint}/${formId}/specialist/${specialistId}:submit`)
}

const strengthFormsEndpoint = 'v1/strengths'

const getStrengthForm = (formId: string, specialistId: string) => {
    return axios.get<FormDataModel>(`${strengthFormsEndpoint}/${formId}/specialist/${specialistId}`)
}

const saveStrengthFormAnswer = (formId: string, specialistId: string, answer: Answer) => {
    return axios.post<FormDataModel>(`${strengthFormsEndpoint}/${formId}/specialist/${specialistId}`, answer)
}

const submitStrengthForm = (formId: string, specialistId: string) => {
    return axios.post<FormDataModel>(`${strengthFormsEndpoint}/${formId}/specialist/${specialistId}:submit`)
}

export { getForm, saveFormAnswer, submitForm, getStrengthForm, saveStrengthFormAnswer, submitStrengthForm }
