const distinctFilter = <T>(value: T, index: number, self: T[]): boolean => {
    return self.indexOf(value) === index
}

const countArrayFilters = (
    filters: {
        [key: string]: any
    },
    arrayFilterNames: Array<string>,
) => {
    let counter = 0

    arrayFilterNames.forEach(name => {
        if (typeof filters[name] === 'boolean' && filters[name]) {
            counter++
        }
        if (Array.isArray(filters[name]) && filters[name].length) {
            counter++
        }
    })
    return counter
}

export { distinctFilter, countArrayFilters }
