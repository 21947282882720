/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Icon } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Modal } from '../../../components/Modal'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { ImportErrors } from '../contracts'
import { mapErrorType } from './SpecialistImportUtils'

type ErrorModalProps = {
    handleCloseModal: () => void
    opened: boolean
    errors?: Array<ImportErrors>
}

const ErrorModal: FunctionComponent<React.PropsWithChildren<ErrorModalProps>> = ({ opened, handleCloseModal, errors }) => {
    return (
        <div>
            <Modal opened={opened} onClose={handleCloseModal} dataTestId='import-specialists-error-modal'>
                <h5>Error report</h5>

                <div
                    css={css`
                        background-color: ${COLOR_PALETTE.red_2};
                        color: ${COLOR_PALETTE.red_4};
                        padding: 8px;
                        margin-bottom: 24px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        border-radius: 4px;
                    `}
                >
                    <div
                        css={css`
                            margin: 0 12px;
                        `}
                    >
                        <Icon name='error' size={20} />
                    </div>
                    <div
                        css={css`
                            font-size: 12px;
                        `}
                    >
                        Your upload was unsuccessful. These are all the errors that were encountered:
                    </div>
                </div>
                <ul
                    css={css`
                        color: ${COLOR_PALETTE.red_4};
                        height: 350px;
                        overflow-y: scroll;
                    `}
                    data-testid='import-specialists-errors-list'
                >
                    {errors?.map((error, index) => {
                        return (
                            <li key={index}>
                                Row: {error.row} {mapErrorType(error.errorType)} {error.fieldName}
                            </li>
                        )
                    })}
                </ul>
                <div
                    css={css`
                        color: ${COLOR_PALETTE.red_4};
                    `}
                >
                    Please correct these errors and upload your spreadsheet again.
                </div>
                <Divider />
                <FlexBox justifyContent='flex-end'>
                    <Button onClick={handleCloseModal} variant='text'>
                        Close
                    </Button>
                </FlexBox>
            </Modal>
        </div>
    )
}

export { ErrorModal }
