/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AccessLevelContext } from 'src/AccessLevelContext'
import { getSpecialist, getSpecialistGeneralInformation, getSpecialistProfileLinkId } from 'src/api/api'
import { BackLink } from 'src/components/BackLink'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { LayoutWithStickyColumn } from 'src/components/layout/LayoutWithStickyColumn'
import { ContentLoader } from 'src/components/layout/Loader'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { CustomExportModal } from 'src/components/profile-export/CustomExportModal'
import { ExportType } from 'src/components/profile-export/types'
import { ShareProfileModal } from 'src/components/profile/ShareProfileModal'
import { SideBox } from 'src/components/SideBox'
import { getAppPath } from 'src/contracts/applications'
import { Specialist } from 'src/contracts/specialist/specialist'
import { CvData } from 'src/contracts/specialist/specialistCv'
import { mqMax } from 'src/GlobalStyle'
import { useMySpecialistsMenuItems } from 'src/hooks/useMySpecialistsMenuItems'
import { useUserAuthentication } from 'src/hooks/useUserAuthentication'
import { ReduxContext } from 'src/redux/Store'
import { Nullable } from 'src/types'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'
import { Full } from '../pdf/Full'
import { getCv, getKeyInfo } from './api'
import { KeyInfoResponse } from './contracts'
import { PersonalInformationSection } from './specialist-profile/PersonalInformationSection'
import { MySpecialistsSpecialistSideMenu } from './specialist-profile/side-menu/MySpecialistsSpecialistSideMenu'
import { SpecialistFilesForm } from './specialist-profile/SpecialistFilesForm'
import { SpecialistDetails } from './SpecialistDetails'
import { SpecialistTabs } from './SpecialistTabs'
import { SpecialistGeneralInformation } from 'src/contracts/specialist/specialistGeneralInformation'

export enum SpecialistTabType {
    GENERAL,
    PERSONAL,
    PROJECTS,
    EDUCATION,
    TECHNICAL,
    STRENGTH,
}

const SpecialistProfilePage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { isSpecialistAccount } = useUserAuthentication()
    const { specialistId } = useParams<{ specialistId: string }>()

    const query = useQuery()

    const [specialist, setSpecialist] = useState<Nullable<Specialist>>(null)
    const [opened, setOpened] = useState(false)
    const [shareLinkId, setShareLinkId] = useState<string>('')
    const [cvFile, setCvFile] = useState<Nullable<CvData>>(null)
    const { mySpecialists: mySpecialistsAccess } = useContext(AccessLevelContext)
    const navigate = useNavigate()
    const [exportProfileModalOpen, setExportProfileModalOpen] = useState(false)
    const [exportProfileSpecialistId, setExportProfileSpecialistId] = useState('')
    const [exportType, setExportType] = useState<Nullable<ExportType>>(null)
    const [keyInfo, setKeyInfo] = useState<Nullable<KeyInfoResponse>>(null)
    const [shouldDisplayKeyInfo, setShouldDisplayKeyInfo] = useState(false)
    const [forceFocus, setForceFocus] = useState('')

    const { addError } = useNotifications()

    const log = useLogger('error')
    const [isAnonymized, setIsAnonymized] = useState<Nullable<boolean>>(null)
    const [generalInformation, setGeneralInformation] = useState<Nullable<SpecialistGeneralInformation>>(null)

    const {
        selectors: {
            featureFlags: { specialistProfileRework },
        },
    } = useContext(ReduxContext)

    const {
        header: { menuItems: showMenuItems },
    } = useContext(AccessLevelContext)

    const { menuItems, applicationName } = useMySpecialistsMenuItems()

    const fetchGeneralInformation = useCallback(() => {
        if (specialistId) {
            getSpecialistGeneralInformation(specialistId)
                .then(data => {
                    setGeneralInformation(data)
                })
                .catch(log)
        }
    }, [log, specialistId])

    useEffect(() => {
        fetchGeneralInformation()
    }, [fetchGeneralInformation])

    const handleModalClose = () => setOpened(false)

    const handleModalOpen = () => {
        if (specialistId) {
            setOpened(true)

            getSpecialistProfileLinkId(specialistId).then((data: string) => {
                setShareLinkId(data)
            })
        }
    }

    const fetchSpecialist = useCallback(() => {
        if (specialistId) {
            getSpecialist(specialistId)
                .then(data => {
                    setSpecialist(data)
                })
                .catch(log)
        }
    }, [log, specialistId])

    const fetchKeyInfo = useCallback(() => {
        if (specialistId) {
            getKeyInfo(specialistId)
                .then(info => {
                    setKeyInfo(prevKeyInfo => {
                        if (prevKeyInfo === null && info.sections.filter(section => !section.completed)?.length > 0) {
                            setShouldDisplayKeyInfo(true)
                        }
                        return info
                    })
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, specialistId])

    useEffect(() => {
        if (specialistId) {
            getCv(specialistId)
                .then(data => setCvFile(data))
                .catch(log)
        }
    }, [specialistId, log])

    const handleSave = useCallback(() => {
        fetchSpecialist()
        fetchKeyInfo()
    }, [fetchKeyInfo, fetchSpecialist])

    useEffect(() => {
        if (specialistId) {
            getSpecialist(specialistId)
                .then(data => setSpecialist(data))
                .catch((error: any) => {
                    if (error?.status === 404) {
                        navigate('/profile-not-found')
                    }
                })
                .finally(() => window.scroll(0, 0))
        }
    }, [specialistId, navigate])

    const onClickExportProfile = useCallback((key: ExportType) => setExportType(key), [])

    const cleanExportProfileState = useCallback(() => {
        setExportProfileSpecialistId('')
        setExportType(null)
    }, [])

    const exportedDoc = useMemo(() => {
        let output = null
        if (specialistId) {
            switch (exportType) {
                case ExportType.FullExportNotAnonymized:
                    output = (
                        <Full
                            specialistId={specialistId}
                            forceDownload={true}
                            cleanExportProfileState={cleanExportProfileState}
                            isAnonymized={false}
                        />
                    )
                    break

                case ExportType.FullExportAnonymized:
                    output = (
                        <Full
                            specialistId={specialistId}
                            forceDownload={true}
                            isAnonymized={true}
                            cleanExportProfileState={cleanExportProfileState}
                        />
                    )
                    break

                case ExportType.QuickSummaryNotAnonymized:
                case ExportType.CustomExportNotAnonymized:
                    setIsAnonymized(false)
                    setExportProfileModalOpen(true)
                    setExportProfileSpecialistId(specialistId)
                    break

                case ExportType.QuickSummaryAnonymized:
                case ExportType.CustomExportAnonymized:
                    setIsAnonymized(true)
                    setExportProfileModalOpen(true)
                    setExportProfileSpecialistId(specialistId)
                    break
            }

            return output
        }
    }, [cleanExportProfileState, exportType, specialistId])

    const handleExportProfileCloseModal = useCallback(() => {
        setExportProfileModalOpen(false)
    }, [])

    const handleProfileExport = useCallback(
        (key: ExportType) => {
            if (specialistId && onClickExportProfile) {
                onClickExportProfile(key)
            }
        },
        [onClickExportProfile, specialistId],
    )

    const handleDismiss = useCallback(() => {
        setShouldDisplayKeyInfo(false)
    }, [])

    useEffect(() => {
        if (cvFile) {
            fetchKeyInfo()
        }
    }, [fetchKeyInfo, cvFile])

    const [sideBoxContent, setSideBoxContent] = useState<Nullable<'files' | 'personalInformation'>>(null)

    const handleFilesClick = useCallback(() => {
        setSideBoxContent('files')
    }, [])

    const handlePersonalInformationClick = useCallback(() => {
        setSideBoxContent('personalInformation')
    }, [])

    const handleCloseSideBox = useCallback(() => {
        setSideBoxContent(null)
    }, [])

    return (
        <Fragment>
            <SideBox isOpen={!!sideBoxContent} onClose={handleCloseSideBox}>
                {sideBoxContent === 'personalInformation' && specialistId && (
                    <PersonalInformationSection specialistId={specialistId} closeSideBox={handleCloseSideBox} />
                )}
                {sideBoxContent === 'files' && specialistId && <SpecialistFilesForm setCvFile={setCvFile} specialistId={specialistId} />}
            </SideBox>
            <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems} showMenuItems={showMenuItems}>
                <div
                    css={css`
                        padding: 24px;
                        ${mqMax[1]} {
                            padding: 24px 16px;
                        }
                    `}
                >
                    <LayoutWithStickyColumn
                        firstCol={
                            <Fragment>
                                {exportedDoc}
                                {mySpecialistsAccess.backToList && (
                                    <BackLink
                                        path={decodeURIComponent(query.get('prevPath') || getAppPath('MY_SPECIALISTS'))}
                                        text='Back to List'
                                        style={css`
                                            margin-bottom: 16px;
                                        `}
                                    />
                                )}

                                {isSpecialistAccount && (
                                    <BackLink
                                        path={'/specialist-dashboard'}
                                        text='Back to dashboard'
                                        style={css`
                                            margin-bottom: 16px;
                                        `}
                                    />
                                )}

                                {specialist !== null ? (
                                    specialistId !== undefined &&
                                    (specialistProfileRework ? (
                                        <SpecialistDetails
                                            specialist={specialist}
                                            specialistId={specialistId}
                                            handleFilesClick={handleFilesClick}
                                            handlePersonalInformationClick={handlePersonalInformationClick}
                                            forceFocus={forceFocus}
                                            setForceFocus={setForceFocus}
                                            generalInformation={generalInformation}
                                            fetchGeneralInformation={fetchGeneralInformation}
                                        />
                                    ) : (
                                        <SpecialistTabs
                                            specialist={specialist}
                                            specialistId={specialistId}
                                            handleSave={handleSave}
                                            forceFocus={forceFocus}
                                            setForceFocus={setForceFocus}
                                            generalInformation={generalInformation}
                                            fetchGeneralInformation={fetchGeneralInformation}
                                        />
                                    ))
                                ) : (
                                    <ContentLoader />
                                )}
                            </Fragment>
                        }
                        secondCol={
                            specialistId !== undefined && (
                                <MySpecialistsSpecialistSideMenu
                                    setForceFocus={setForceFocus}
                                    keyInfo={keyInfo}
                                    shouldDisplayKeyInfo={shouldDisplayKeyInfo}
                                    specialistId={specialistId}
                                    handleGetLinkToProfileClick={handleModalOpen}
                                    handleExportProfileClick={handleProfileExport}
                                    specialist={specialist}
                                    cvFile={cvFile}
                                    setCvFile={setCvFile}
                                    handleDismiss={handleDismiss}
                                    anonymousNickname={generalInformation?.anonymousNickname}
                                />
                            )
                        }
                    />
                </div>

                <ShareProfileModal onClose={handleModalClose} opened={opened} shareLinkId={shareLinkId} anonymous={true} />

                <CustomExportModal
                    specialistId={exportProfileSpecialistId}
                    dataTestId='quick-summary-modal'
                    opened={exportProfileModalOpen}
                    handleCloseModal={handleExportProfileCloseModal}
                    exportType={exportType}
                    setExportType={setExportType}
                    setSpecialistId={setExportProfileSpecialistId}
                    isAnonymized={isAnonymized}
                />
            </DashboardLayout>
        </Fragment>
    )
}

export { SpecialistProfilePage }
