/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { TwoCols } from '../layout/TwoCols'
import { Logo } from '../Logo'
import { ManagerLoginBanner } from '../ManagerLoginBanner'

const AuthPageLayout: FunctionComponent<
    React.PropsWithChildren<{
        children: ReactNode
        secondCol?: ReactNode
    }>
> = ({ children, secondCol }) => (
    <TwoCols
        firstCol={
            <section
                css={css`
                    display: flex;
                    justify-content: center;
                    padding: 16px;
                `}
            >
                <div
                    css={css`
                        width: 408px;
                        padding-top: 28px;
                    `}
                >
                    <Link to='/'>
                        <Logo
                            type='logoFull'
                            style={css`
                                margin-left: 0;
                                margin-bottom: 36px;
                            `}
                        />
                    </Link>
                    {children}
                </div>
            </section>
        }
        secondCol={secondCol || <ManagerLoginBanner />}
    />
)

export { AuthPageLayout }
