import { Nullable } from '../../../types'
import { Option } from './Autocomplete'

const industriesMock: Array<string> = [
    'Ad Tech',
    'Artificial Intelligence',
    'Audio Genre Recognition System',
    'Audio driver',
    'Automotive',
    'Aviation',
    'Banking and Finance',
    'Biotechnology',
    'Branding',
    'Cloud data storage',
    'Company asset administration',
    'Company framework',
    'Construction assets management',
    'Consumer electronics',
    'Customer Support',
    'Cybersecurity system',
    'E-commerce',
    'E-sport & Gaming',
    'Education',
    'Entertainment',
    'Face Recognition System',
    'Food & Farming',
    'Gambling',
    'Geographic Information System',
    'Geolocation',
    'Government',
    'HR',
    'Healthcare',
    'IT',
    'Industrial and Manufacturing',
    'Insurance',
    'Intellectual Property',
    'Internal support',
    'Laboratory/ Biotechnology',
    'Legal',
    'Location analysis',
    'Logistics',
    'Marketing',
    'Media & Publishing',
    'Natural Language Processing Competition',
    'Neuroscience',
    'Non-profit / NGOs',
    'Not industry specific',
    'Online dating',
    'Operations management',
    'Public administration',
    'Real Estate',
    'Retail',
    'Services',
    'Smart Cities',
    'Sport',
    'Telecommunication',
    'Tourism',
    'Various small projects',
    'graphic driver',
    'hospitality',
    'mass storage',
    'reasearch deep learning',
    'research',
]

const transformOptions = (optionsToTransform: Nullable<Array<Option | string>>, dataTestId = ''): Array<Option> => {
    const correctOptions: Array<Option> = []

    if (optionsToTransform && optionsToTransform?.length > 0) {
        optionsToTransform.forEach(option => {
            if (typeof option === 'string') {
                correctOptions.push({ label: option, value: option, 'data-testid': `${dataTestId}-item`, helperLabel: '' })
            } else {
                if (optionsToTransform !== undefined) {
                    correctOptions.push({
                        label: option.label,
                        value: option.value,
                        helperLabel: option.helperLabel,
                        'data-testid': `${dataTestId}-item`,
                    })
                }
            }
        })
    }
    return correctOptions
}

const transformValuesToOptions = (values: Array<string>, selectOptions: Array<Option>): Array<Option> => {
    const correctOptions: Array<Option> = []

    if (values && values?.length > 0) {
        values.forEach(option => {
            if (typeof option === 'string') {
                const newOption = selectOptions?.find(opt => opt.value === option)
                if (newOption) {
                    correctOptions.push(newOption)
                }
            }
        })
    }
    return correctOptions
}

export { industriesMock, transformOptions, transformValuesToOptions }
