/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useMemo, useState } from 'react'
import { Button } from '../../components/Button'
import { ExportType } from '../../components/profile-export/types'
import { Nullable } from '../../types'
import { useQuery } from '../../utils/useQuery'
import { Full } from './Full'
import { Quick } from './Quick'

const Pdf = () => {
    const [exportType, setExportType] = useState<Nullable<ExportType>>(null)
    const query = useQuery()

    const exportedDoc = useMemo(() => {
        let output = null

        switch (exportType) {
            case ExportType.FullExportNotAnonymized:
                output = <Full specialistId={query.get('specialistId') || undefined} forceDownload={true} />
                break

            case ExportType.QuickSummaryNotAnonymized:
                output = <Quick specialistId={query.get('specialistId') || undefined} forceDownload={true} />
                break
        }

        return output
    }, [exportType, query])

    return (
        <section
            css={css`
                position: absolute;
                height: 200px;
                width: 300px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
            `}
        >
            <Button onClick={() => setExportType(ExportType.QuickSummaryNotAnonymized)}>Quick Summary</Button>
            <Button onClick={() => setExportType(ExportType.FullExportNotAnonymized)}>Full Profile</Button>

            {exportedDoc}
        </section>
    )
}

export { Pdf }
