import { useCallback, useContext } from 'react'
import { UserDataSSO, UserType } from '../contracts/userDataSSO'
import { ReduxContext } from '../redux/Store'
import { decodeAccessToken, getUserRole } from '../utils/currentUser'
import { useUserContext } from './useUserContext'

const useUserAuthentication = () => {
    const {
        actions: {
            userLogout,
            whiteLabelSet,
            setShouldWaitForWhiteLabel,
            dictionaryDataSet,
            userLogin,
            authUnsubscribe,
            authSubscribe,
            setVMCstatus,
        },
        selectors: { user },
    } = useContext(ReduxContext)

    const { removeContextsData } = useUserContext()

    const isWaitingForStatus = user === null
    const isUnauthorized = !isWaitingForStatus && Object.keys(user).length === 0
    const isSpecialistAccount = getUserRole() === 'specialist' && user && !!user.specialistId
    const isEmailConfirmationNecessary = user && user.providerId === 'password'
    const isPasswordAccountActivated = isEmailConfirmationNecessary && user.emailVerified
    const isProviderAccountActivated = !isEmailConfirmationNecessary
    const isUserAuthorized =
        !isUnauthorized && !isWaitingForStatus && (isPasswordAccountActivated || isProviderAccountActivated || isSpecialistAccount)

    const logout = useCallback(() => {
        userLogout()
        localStorage.removeItem('accessToken')
        localStorage.removeItem('role')
        localStorage.removeItem('whiteLabel')
        localStorage.removeItem('refreshToken')
        sessionStorage.removeItem('currentUser')
        whiteLabelSet(null)
        setShouldWaitForWhiteLabel(false)
        dictionaryDataSet({ name: 'applications', data: null })
        authSubscribe()
        removeContextsData()
        setVMCstatus(null)
    }, [authSubscribe, dictionaryDataSet, setShouldWaitForWhiteLabel, userLogout, whiteLabelSet, removeContextsData, setVMCstatus])

    const login = useCallback(
        (userData: UserDataSSO, role: UserType) => {
            // for magic link flow we are using a different authentication method
            // based on proxy API handled and maintained by our team
            // unfortunately, this flow does not handle auth changes from the firebase
            // so we have to disable listeners for specialist
            if (role === 'specialist') {
                const decodedAccessToken = decodeAccessToken(userData.accessToken)
                userData.specialistId = decodedAccessToken.specialistId
                authUnsubscribe()
            } else {
                authSubscribe()
            }

            localStorage.setItem('accessToken', userData.accessToken)
            localStorage.setItem('refreshToken', userData.refreshToken)
            localStorage.setItem('role', role)
            userLogin(userData)
            sessionStorage.setItem('currentUser', userData.email)
        },
        [userLogin, authUnsubscribe, authSubscribe],
    )

    return {
        isWaitingForStatus,
        isUserAuthorized,
        isEmailConfirmationNecessary,
        isPasswordAccountActivated,
        isProviderAccountActivated,
        isUnauthorized,
        isSpecialistAccount,
        logout,
        login,
    }
}

export { useUserAuthentication }
