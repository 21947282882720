/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import queryString from 'query-string'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Theme } from '../../../theme/theme'
import { convertTimestampToDateString } from '../../../utils/dates'
import { encodeField, parseToArray, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { Booking, BookingsFilters, BookingsSearchRequest } from '../contracts'
import { BookingRequestModal } from './BookingRequestModal'
import { SpecialistNumberCell } from './SpecialistNumberCell'

const defaultPageSize = 10

const initialFilters: BookingsFilters = {
    seniorities: [],
    statuses: [],
    roles: [],
}

const rowClassName = (record: Booking) => {
    switch (record.bookingStatus) {
        case 'Accepted': {
            return 'ant-table-row-blue'
        }
        case 'Rejected': {
            return 'ant-table-row-red'
        }
        case 'Closed': {
            return 'ant-table-row-disabled'
        }
        case 'Completed': {
            return 'ant-table-row-green'
        }
        default: {
            return ''
        }
    }
}

const mobileItemStyles = (record: Booking, theme: Theme) => {
    switch (record.bookingStatus) {
        case 'Accepted': {
            return css`
                background-color: ${theme.colors.blue_2};
            `
        }
        case 'Rejected': {
            return css`
                background-color: ${theme.colors.red_2};
            `
        }
        case 'Closed': {
            return css`
                color: ${theme.colors.gray_3};
            `
        }
        case 'Completed': {
            return css`
                background-color: ${theme.colors.green_1};
            `
        }
        default: {
            return css``
        }
    }
}

const RenderStatus: FunctionComponent<{ record: string }> = ({ record }) => {
    const theme = useTheme()

    const getColor = () => {
        let color
        switch (record) {
            case 'Sent':
                color = theme.colors.violet_5
                break

            case 'Accepted':
                color = theme.colors.blue_5
                break

            case 'Rejected':
                color = theme.colors.red_5
                break

            case 'Completed':
                color = theme.colors.green_4
                break

            default:
                color = 'inherit'
        }
        return color
    }

    return (
        <span
            css={css`
                color: ${getColor()};
            `}
        >
            {record}
        </span>
    )
}

type ProfileActionsRendererProps = {
    bookingId: string
    specialistId: string
    bookingStatus: string
}

const ProfileActionsRenderer: FunctionComponent<React.PropsWithChildren<ProfileActionsRendererProps>> = ({
    bookingId,
    specialistId,
    bookingStatus,
}) => {
    const [isModalOpened, setIsModalOpened] = useState<boolean>(false)
    const handleModalClose = useCallback(() => {
        setIsModalOpened(false)
    }, [])
    const navigate = useNavigate()

    const handleModalOpen = useCallback((e: any) => {
        e.stopPropagation()
        e.preventDefault()
        setIsModalOpened(true)
    }, [])

    const handleFeedbackRedirect = useCallback(
        (e: any) => {
            e.stopPropagation()
            e.preventDefault()
            navigate(`/talent-marketplace/feedback?bookingId=${bookingId}&specialistId=${specialistId}`)
        },
        [bookingId, navigate, specialistId],
    )

    const stopPropagation = useCallback((e: any) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    return (
        <Fragment>
            <FlexBox
                css={css`
                    justify-content: center;
                `}
            >
                <Tooltip placement='topRight' title='View booking request'>
                    <div onClick={handleModalOpen} data-testid='view-booking'>
                        <IconButton name='eye' variant='standard' />
                    </div>
                </Tooltip>
                {bookingStatus === 'Accepted' && (
                    <Tooltip placement='topRight' title='Update booking status'>
                        <div
                            onClick={handleFeedbackRedirect}
                            css={css`
                                margin-left: 8px;
                            `}
                        >
                            <IconButton name='feedback' variant='standard' />
                        </div>
                    </Tooltip>
                )}
            </FlexBox>
            {isModalOpened && (
                <div onClick={stopPropagation}>
                    <BookingRequestModal onClose={handleModalClose} bookingId={bookingId} specialistId={specialistId} />
                </div>
            )}
        </Fragment>
    )
}

type ComparerCreator<RecordType = any> = (fieldName: keyof RecordType) => (a: RecordType, b: RecordType) => number
const createComparer: ComparerCreator = fieldName => (a, b) => a[fieldName] - b[fieldName]

const columns: ColumnsType<Booking> = [
    {
        title: 'Specialist ID',
        dataIndex: 'anonymousNumber',
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('anonymousNumber'),
            multiple: 1,
        },
        render: record => <SpecialistNumberCell anonymousNumber={record} />,
    },
    {
        title: 'Booked By',
        dataIndex: 'bookedBy',
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('bookedBy'),
            multiple: 1,
        },
    },
    {
        title: 'Project Name',
        dataIndex: 'projectName',
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('projectName'),
            multiple: 1,
        },
    },
    {
        title: 'Role',
        dataIndex: 'role',
        render: record => <span data-testid='booking-role'>{record}</span>,
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('role'),
            multiple: 2,
        },
    },
    {
        title: 'Last updated on',
        dataIndex: 'lastUpdatedAt',
        render: record => record && convertTimestampToDateString(record),
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('lastUpdatedAt'),
            multiple: 5,
        },
    },
    {
        title: 'Booking Request Sent On',
        dataIndex: 'bookingRequestSentOn',
        render: record => record && convertTimestampToDateString(record),
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('bookingRequestSentOn'),
            multiple: 7,
        },
    },

    {
        title: 'Booking Status',
        dataIndex: 'bookingStatus',
        render: record => <RenderStatus record={record} />,
        showSorterTooltip: false,
        sorter: {
            compare: createComparer('bookingStatus'),
            multiple: 6,
        },
    },
    {
        title: '',
        dataIndex: 'id',
        render: (_, record) => (
            <ProfileActionsRenderer bookingId={record.id} specialistId={record.specialistId} bookingStatus={record.bookingStatus} />
        ),
    },
]

const parseQueryToSearchRequest = (searchQuery: string): BookingsSearchRequest => {
    const params = queryString.parse(searchQuery, { decode: false })
    const { searchText, page, seniorities, statuses, roles, size } = params

    const result: BookingsSearchRequest = {
        searchText: parseToString(searchText),
        seniorities: parseToArray(seniorities),
        statuses: parseToArray(statuses),
        roles: parseToArray(roles),
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, defaultPageSize),
        },
    }

    return result
}

const stringifySearchRequestToQuery = (searchRequest: BookingsSearchRequest): string => {
    const params = []
    const { searchText, paging, seniorities, statuses, roles } = searchRequest

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (seniorities && seniorities.length > 0) {
        params.push(`seniorities=${encodeField(seniorities)}`)
    }

    if (statuses && statuses.length > 0) {
        params.push(`statuses=${encodeField(statuses)}`)
    }

    if (roles && roles.length > 0) {
        params.push(`roles=${encodeField(roles)}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    return params.join('&')
}

export {
    rowClassName,
    columns,
    defaultPageSize,
    initialFilters,
    mobileItemStyles,
    parseQueryToSearchRequest,
    stringifySearchRequestToQuery,
}
