import { axios } from '../../../api/axios'
import { FeedbackTeam, Specialist } from './contracts'

const feedbackTeamsEndpoint = `/v1/feedbackTeams`

const getFeedbackTeams = (): Promise<Array<FeedbackTeam>> => {
    return axios.get(feedbackTeamsEndpoint)
}

const saveFeedbackTeams = (data: { name: string; specialistIds: Array<string> }): Promise<void> => {
    return axios.post(feedbackTeamsEndpoint, data)
}

const deleteFeedbackTeams = (teamId: string): Promise<void> => {
    return axios.delete(`${feedbackTeamsEndpoint}/${teamId}`)
}

const getFeedbackTeamsProposalMembers = (): Promise<Array<Specialist>> => {
    return axios.get(`${feedbackTeamsEndpoint}:proposedMembers`)
}

export { getFeedbackTeams, saveFeedbackTeams, deleteFeedbackTeams, getFeedbackTeamsProposalMembers }
