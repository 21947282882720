/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { LayoutWithStickyColumn } from '../../components/layout/LayoutWithStickyColumn'
import { PageWidthWrapper } from '../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../components/layout/SpecialistPageWrapper'
import { LeavePageConfirmationModal } from '../../components/LeavePageConfirmationModal'
import { AddSkillsBox } from '../../components/skills-questionnaire/AddSkillsBox'
import { SkillsQuestionnaire } from '../../components/skills-questionnaire/SkillsQuestionnaire'
import { CvSourceType } from '../../contracts/cvs'
import { mqMax } from '../../GlobalStyle'
import { useSpecialistCvParsing } from '../../hooks/useSpecialistCvParsing'
import { BottomBar } from 'src/components/BottomBar'

type SpecialistFinishCvParsingPageProps = {}

const SpecialistFinishCvParsingPage: FunctionComponent<React.PropsWithChildren<SpecialistFinishCvParsingPageProps>> = () => {
    const { specialistId, cvParsingId } = useParams<{ specialistId: string; cvParsingId: string }>()

    const {
        cvParsingStatus,
        skills,
        handleSkillChange,
        handleSkillAdd,
        handleParsingConfirmation,
        areSomeSkillsNotRated,
        handleCancelProcess,
    } = useSpecialistCvParsing({
        specialistId,
        cvParsingId,
        redirectUrl: `/my-specialists/specialist/${specialistId}`,
        successNotification: 'Data added successfully.',
    })

    return (
        <Fragment>
            <SpecialistPageWrapper
                style={css`
                    margin-bottom: 75px;
                    ${mqMax[2]} {
                        margin-bottom: 150px;
                    }
                `}
            >
                <PageWidthWrapper>
                    <BackLink
                        text='Back to profile'
                        path={`/my-specialists/specialist/${specialistId}`}
                        style={css`
                            margin-bottom: 24px;
                        `}
                    />
                    <h3>Confirm Extracted Skills</h3>
                </PageWidthWrapper>
                <LayoutWithStickyColumn
                    dataTestId='skills'
                    firstCol={
                        <Fragment>
                            <SkillsQuestionnaire
                                specialistSkills={skills}
                                handleSkillChange={handleSkillChange}
                                removable
                                unvalidated
                                showExtractedSkillsInfo
                            />
                        </Fragment>
                    }
                    secondCol={<AddSkillsBox handleSkillSelect={handleSkillAdd} skills={skills} unvalidated specialistId={specialistId} />}
                />
                <BottomBar
                    containerStyles={css`
                        z-index: 1;
                        height: 60px;
                    `}
                >
                    <LayoutWithStickyColumn
                        dataTestId='skills-actions'
                        firstCol={
                            <FlexBox
                                css={css`
                                    align-items: center;
                                    justify-content: space-between;
                                `}
                            >
                                <Button variant='link' onClick={handleCancelProcess}>
                                    Cancel
                                </Button>
                                <FlexBox>
                                    {cvParsingStatus?.cvSource === CvSourceType.LINKEDIN && (
                                        <Link to={`/specialist-cv-parsing/${cvParsingId}/education`}>
                                            <Button variant='tertiary'>Previous</Button>
                                        </Link>
                                    )}
                                    {areSomeSkillsNotRated ? (
                                        <Tooltip title='Rate or Remove all the skills first'>
                                            <div>
                                                <Button
                                                    variant='primary'
                                                    style={css`
                                                        margin-left: 24px;
                                                    `}
                                                    disabled
                                                >
                                                    Add to Profile
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            variant='primary'
                                            style={css`
                                                margin-left: 24px;
                                            `}
                                            onClick={handleParsingConfirmation}
                                        >
                                            Add to Profile
                                        </Button>
                                    )}
                                </FlexBox>
                            </FlexBox>
                        }
                    />
                </BottomBar>
            </SpecialistPageWrapper>
            {areSomeSkillsNotRated && (
                <LeavePageConfirmationModal
                    title='Please rate all your skills before leaving'
                    description={`Your profile looks better if you have all of added skills rated. Please rate all your skills or simply remove the ones you don’t want to rate.`}
                    leaveButtonContent='Leave'
                    backButtonContent='Cancel'
                />
            )}
        </Fragment>
    )
}

export { SpecialistFinishCvParsingPage }
