import queryString from 'query-string'
import { Nullable } from '../../../types'
import { encodeField, parseToArray, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { ApplicationStatusEnum, SearchAllApplicationsRequestParams } from '../../../contracts/opportunities-manager/contracts'

const stringifyOpportunitiesManagerApplicationsSearchAllRequestToQuery = (
    searchRequest: SearchAllApplicationsRequestParams,
    prevPath: Nullable<string>,
): string => {
    const params = []
    const { paging, searchText, partnerNames, opportunityOwners, statusValues, clientNames, opportunityInternalNames } = searchRequest

    if (statusValues && statusValues.length > 0) {
        params.push(`statusValues=${statusValues.join(',')}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (opportunityOwners && opportunityOwners.length > 0) {
        params.push(`opportunityOwners=${encodeField(opportunityOwners)}`)
    }

    if (clientNames && clientNames.length > 0) {
        params.push(`clientNames=${encodeField(clientNames)}`)
    }

    if (partnerNames && partnerNames.length > 0) {
        params.push(`partnerNames=${encodeField(partnerNames)}`)
    }

    if (opportunityInternalNames && opportunityInternalNames.length > 0) {
        params.push(`opportunityInternalNames=${encodeField(opportunityInternalNames)}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (prevPath) {
        params.push(`prevPath=${encodeURIComponent(prevPath)}`)
    }

    return params.join('&')
}

const parseQueryToOpportunitiesManagerApplicationsSearchAllRequest = (
    searchQuery: string,
): Omit<SearchAllApplicationsRequestParams, 'sorting'> => {
    const params = queryString.parse(searchQuery, { decode: false })
    const { page, searchText, size, partnerNames, opportunityOwners, statusValues, clientNames, opportunityInternalNames } = params

    const result: Omit<SearchAllApplicationsRequestParams, 'sorting'> = {
        opportunityOwners: parseToArray(opportunityOwners),
        partnerNames: parseToArray(partnerNames),
        clientNames: parseToArray(clientNames),
        opportunityInternalNames: parseToArray(opportunityInternalNames),
        statusValues: statusValues
            ? ((typeof statusValues === 'string' ? statusValues : '').split(',') as Array<ApplicationStatusEnum>)
            : [],
        searchText: parseToString(searchText),
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, 10),
        },
    }

    return result
}

export { stringifyOpportunitiesManagerApplicationsSearchAllRequestToQuery, parseQueryToOpportunitiesManagerApplicationsSearchAllRequest }
