/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import backIcon from '../../assets/icons/back.svg'
import { DictionaryEntry } from '../../contracts/dictionaryEntry'
import { mqMax } from '../../GlobalStyle'
import { Button } from '../Button'
import { ResponsiveWrapper } from '../layout/ResponsiveWrapper'
import { SecondStepNegativeFeedback } from './second-step/SecondStepNegativeFeedback'
import { SecondStepPositiveFeedback } from './second-step/SecondStepPositiveFeedback'

export enum SecondStepType {
    'YES',
    'IN_PROGRESS',
    'NO',
}

type FeedbackSecondStepProps = {
    type: SecondStepType
    onBack: () => void
    pleaseRateMessage: string
    onPositiveSubmit: (formData: any) => void
    inProgressResponseButtonText: string
    inProgressResponseButtonPath: string
    inProgressSubmit: () => void
    negativeResponseReasonOptions: Array<DictionaryEntry>
    onNegativeSubmit: (formData: any) => void
}

const FeedbackSecondStep: FunctionComponent<React.PropsWithChildren<FeedbackSecondStepProps>> = ({
    type,
    onBack,
    pleaseRateMessage,
    onPositiveSubmit,
    inProgressResponseButtonText,
    inProgressResponseButtonPath,
    inProgressSubmit,
    negativeResponseReasonOptions,
    onNegativeSubmit,
}) => {
    const navigate = useNavigate()

    const onInProgressButtonClick = useCallback(() => {
        navigate(inProgressResponseButtonPath)
    }, [navigate, inProgressResponseButtonPath])

    useEffect(() => {
        if (type === SecondStepType.IN_PROGRESS) {
            inProgressSubmit()
        }
    }, [type, inProgressSubmit])

    return (
        <ResponsiveWrapper
            styles={css`
                max-width: 484px;
            `}
        >
            {type !== SecondStepType.IN_PROGRESS && (
                <Button
                    variant='link'
                    icon={backIcon}
                    onClick={onBack}
                    style={css`
                        margin: 16px 0 0 5px;
                    `}
                >
                    Back
                </Button>
            )}

            {type === SecondStepType.YES && (
                <SecondStepPositiveFeedback pleaseRateMessage={pleaseRateMessage} onPositiveSubmit={onPositiveSubmit} />
            )}

            {type === SecondStepType.IN_PROGRESS && (
                <Fragment>
                    <h3
                        css={css`
                            margin: 24px 0;
                        `}
                    >
                        Best of Luck in Your Negotiations
                    </h3>

                    <p
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        We’ll circle back and ask you again to give us feedback about your booking in seven business days.
                    </p>

                    <Button
                        variant='primary'
                        onClick={onInProgressButtonClick}
                        style={css`
                            width: 187px;

                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                    >
                        {inProgressResponseButtonText}
                    </Button>
                </Fragment>
            )}

            {type === SecondStepType.NO && (
                <SecondStepNegativeFeedback
                    pleaseRateMessage={pleaseRateMessage}
                    onNegativeSubmit={onNegativeSubmit}
                    negativeResponseReasonOptions={negativeResponseReasonOptions}
                />
            )}
        </ResponsiveWrapper>
    )
}

export { FeedbackSecondStep }
