/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useEffectOnce } from 'react-use'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { FieldLabel } from '../../../components/inputs/input-field/FieldLabel'
import { CustomField } from '../../../contracts/customFields'
import { mqMax } from '../../../GlobalStyle'
import { hasErrors } from '../../../utils/errors'
import { yup } from '../../../validation/yup'

export type FormValues = {
    fieldName: string
}

type CustomFieldFormProps = FunctionComponent<
    React.PropsWithChildren<{
        setEditMode: Dispatch<SetStateAction<boolean>>
        customField: CustomField
        handleCustomFieldSubmit: (formValues: FormValues, id: string, setFieldNameError: () => void) => void
    }>
>

const schema = yup.object().shape({
    fieldName: yup.string().required().max(100, 'Field name must be at most 100 characters'),
})

const CustomFieldForm: CustomFieldFormProps = ({ setEditMode, customField, handleCustomFieldSubmit }) => {
    const handleCancelClick = useCallback(() => {
        setEditMode(false)
    }, [setEditMode])

    const {
        control,
        handleSubmit,
        watch,
        setError,
        setValue,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            fieldName: customField.fieldName,
        },
    })

    const setFieldNameError = useCallback(() => {
        setError('fieldName', { type: 'manual', message: 'This field name is already in use' })
    }, [setError])

    const fieldName = watch('fieldName') as string | undefined

    // Due to react 18 `StrictMode`, conditionally rendered fields can have its values set to `undefined`, so use `setValue`
    useEffectOnce(() => {
        setValue('fieldName', customField.fieldName)
    })

    const onSubmit = useCallback(
        (data: FormValues) => {
            handleCustomFieldSubmit(data, customField.id, setFieldNameError)
        },
        [handleCustomFieldSubmit, customField.id, setFieldNameError],
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 8px;
                    ${mqMax[0]} {
                        flex-wrap: wrap;
                    }
                `}
            >
                <FieldLabel
                    label='Field Name'
                    styles={css`
                        margin-right: 30px;
                        margin-bottom: 20px;
                        ${mqMax[0]} {
                            width: 100%;
                            margin-right: 0;
                            margin-bottom: 4px;
                        }
                    `}
                />
                <ControlledInput
                    name='fieldName'
                    style={css`
                        flex: 1;
                    `}
                    infoMessage={`${fieldName?.length || 0}/100`}
                    dataTestId='custom-field-input'
                    control={control}
                />
            </div>
            <Divider
                style={css`
                    margin-bottom: 8px;
                `}
            />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button variant='link' onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting || !isDirty || hasErrors(errors)}
                    css={css`
                        margin-left: 30px;
                    `}
                    dataTestId='custom-field-submit'
                >
                    Save
                </Button>
            </div>
        </form>
    )
}

export { CustomFieldForm }
