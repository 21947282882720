/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useContext } from 'react'
import { Button } from '../../components/Button'
import { DefaultLayout } from '../../components/DefaultLayout'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { useQuery } from '../../utils/useQuery'

const ForbiddenForCurrentUser = () => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const query = useQuery()

    const handleGoToDashboard = useCallback(() => {
        history.push(decodedAccessToken?.specialistId ? '/specialist-dashboard' : '/dashboard')
    }, [decodedAccessToken])

    const handleLoginAsDifferentUser = useCallback(() => {
        const afterRelogin = query.get('afterRelogin')
        history.push(`/logout/user${afterRelogin ? `?afterLogin=${encodeURIComponent(afterRelogin)}` : ''}`)
    }, [query])

    return (
        <DefaultLayout
            icon='broken-link-public'
            title='You are logged in with another account'
            description='This page cannot be accessed with your current account. Please make sure to use email address that you received the invite to.'
            buttonText='Log In as another user'
            onButtonClick={handleLoginAsDifferentUser}
        >
            <div
                css={css`
                    margin-top: 8px;
                `}
            >
                <Button variant='link' onClick={handleGoToDashboard} dataTestId='go-to-dashboard'>
                    Go Back to Dashboard
                </Button>
            </div>
        </DefaultLayout>
    )
}

export { ForbiddenForCurrentUser }
