/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { DefaultLayout } from '../../components/DefaultLayout'
import { useQuery } from '../../utils/useQuery'

const MagicLinkSent: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const query = useQuery()

    return (
        <DefaultLayout
            icon='plane'
            title='E-mail Sent'
            description={`We sent your link to: ${query.get('email')}. Please click the link to continue.`}
        />
    )
}

export { MagicLinkSent }
