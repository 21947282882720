/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Box } from '../../Box'
import { FC } from 'react'

type InstructionsBoxProps = {
    isSmeCloud?: boolean
    isVMCCloudApp?: boolean
}

const InstructionsBox: FC<InstructionsBoxProps> = ({ isSmeCloud = false, isVMCCloudApp = false }) => {
    const theme = useTheme()
    return (
        <Box
            css={css`
                background-color: ${theme.colors.violet_2};
            `}
        >
            <>
                {isSmeCloud && (
                    <>
                        <h6>About an SME Cloud for SHs Manager:</h6>
                        <ol>
                            <li>
                                SME Cloud for SHs was designed for Software Houses and other small and medium IT service providers to enable
                                the sharing of their bench.
                            </li>
                            <li>
                                Users can post their Opportunities for free and apply with a Premium Plan (currently available for free),
                                without any Talent Alpha margin.
                            </li>
                            <li>
                                The Opportunity Manager enables you to publish an Opportunity and then view who applied. You can review CVs
                                and update candidate statuses based on the stage of the process.
                            </li>
                            <li>You have everything in one place and easy to manage </li>
                        </ol>
                    </>
                )}
                <>
                    <h6>Please follow the instructions below:</h6>
                    <ol>
                        <li>
                            If you want to add an {isVMCCloudApp ? 'vacancy' : 'opportunity'} draft, please add ad least{' '}
                            {isVMCCloudApp ? 'Vacancy' : 'Opportunity'} title.
                        </li>
                        <li>Fill in all required fields in order to be able to publish the {isVMCCloudApp ? 'vacancy' : 'opportunity'}.</li>
                    </ol>
                </>
            </>
        </Box>
    )
}

export { InstructionsBox }
