/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { NoData } from '../../../components/NoData'
import { NoDataContainer } from '../../../components/shared/shared-styles'
import { TabProps, Tabs, TabsColor } from '../../../components/Tabs'
import { TimelineGroup } from '../../../components/timeline/TimelineGroup'
import { MySpecialistsAnonymousEducation } from '../../../contracts/profile/education'
import { Profile } from '../../../contracts/profile/profile'
import { ReduxContext } from '../../../redux/Store'
import { MySpecialistsAnonymousEducationItem } from './MySpecialistsAnonymousEducationItem'

const TitleH6 = styled.h6`
    margin: 0 0 26px;
`

type AnonymousExperienceProps = { profile: Profile }

const AnonymousExperience: FunctionComponent<React.PropsWithChildren<AnonymousExperienceProps>> = ({ profile }) => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const [selectedExperienceTab, setSelectedExperienceTab] = useState<TabProps>({ title: 'Projects' })
    const [availableExperienceTabs] = useState<TabProps[]>([{ title: 'Projects' }, { title: 'Education' }])

    return (
        <Fragment>
            <TitleH6>Experience</TitleH6>
            <Tabs
                tabs={availableExperienceTabs}
                showOnMobile={true}
                onTabChange={newTab => {
                    setSelectedExperienceTab(availableExperienceTabs[newTab])
                }}
                mode={TabsColor.BLACK}
            />
            {selectedExperienceTab.title === 'Projects' && (
                <Fragment>
                    {profile.projects.length > 0 && (
                        <Fragment>
                            {profile.projects.map((project, index) => (
                                <TimelineGroup
                                    key={`project-${index}`}
                                    companyName={project.companyName}
                                    positions={project.positions}
                                    isUnix={true}
                                />
                            ))}
                        </Fragment>
                    )}
                    {profile.projects.length === 0 && (
                        <NoDataContainer
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            `}
                        >
                            <NoData />
                            {decodedAccessToken?.specialistId && (
                                <Link
                                    to='/my-specialists/basic-info'
                                    css={css`
                                        margin-top: 10px;
                                        text-decoration: none;
                                    `}
                                >
                                    <Button>Add Projects</Button>
                                </Link>
                            )}
                        </NoDataContainer>
                    )}
                </Fragment>
            )}
            {selectedExperienceTab.title === 'Education' && (
                <Fragment>
                    {profile.education.length > 0 && (
                        <Fragment>
                            {(profile.education as Array<MySpecialistsAnonymousEducation>).map((education, index) => (
                                <MySpecialistsAnonymousEducationItem key={`education-${index}`} education={education} />
                            ))}
                        </Fragment>
                    )}
                    {profile.education.length === 0 && (
                        <NoDataContainer
                            css={css`
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                            `}
                        >
                            <NoData />
                            {decodedAccessToken?.specialistId && (
                                <Link
                                    to='/my-specialists/basic-info?skipExperience'
                                    css={css`
                                        text-decoration: none;
                                        margin-top: 10px;
                                    `}
                                >
                                    <Button>Add Education</Button>
                                </Link>
                            )}
                        </NoDataContainer>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export { AnonymousExperience }
