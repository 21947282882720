/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { Dot } from 'src/components/Dot'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { Box } from 'src/components/opportunities-manager/Box'

import { skillProficiencyMapping } from './SkillRateItem'
import { getRate } from 'src/utils/application'
import { MsaInfo } from 'src/components/opportunities/MsaInfo'
import { useCompany } from 'src/hooks/useCompany'
import { ApplicationData } from './ApplicationWizard/redux/types'

type ApplicationSummaryProps = {
    application: Nullable<ApplicationData>
    showCvPreview: () => void
}

type CompleteInformationItemProps = {
    label: string
    value: string
    style?: SerializedStyles
}

const CompleteInformationItem: FunctionComponent<React.PropsWithChildren<CompleteInformationItemProps>> = ({ label, value, style }) => (
    <div
        css={css`
            width: 50%;
            margin-bottom: 16px;
            ${style}
        `}
    >
        <div>{label}</div>
        <strong>{value}</strong>
    </div>
)

const ApplicationSummary: FunctionComponent<React.PropsWithChildren<ApplicationSummaryProps>> = ({ application, showCvPreview }) => {
    const theme = useTheme()
    const { company } = useCompany()

    return application ? (
        <Fragment>
            <Box>
                <h6>{`${application?.firstName} ${application.lastName}`}</h6>
                {!application.smeCloud && !company?.msaSigned && <MsaInfo />}
            </Box>
            <Box>
                <div
                    css={css`
                        color: ${theme.colors.violet_5};
                        font-weight: 500;
                        cursor: pointer;
                    `}
                    onClick={showCvPreview}
                >
                    {application.cvFileName}
                </div>
            </Box>
            <Box>
                <h6>Complete Information</h6>
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    <CompleteInformationItem label='Role' value={application.role} />
                    <CompleteInformationItem label='Rate' value={getRate(application)} />
                    <CompleteInformationItem label='Available from' value={convertTimestampToDateString(application.availableFrom)} />
                    <CompleteInformationItem label='Available to' value={convertTimestampToDateString(application.availableTo)} />
                    <CompleteInformationItem
                        label='Country'
                        value={application.country}
                        style={css`
                            margin: 0;
                        `}
                    />
                    {application.city && (
                        <CompleteInformationItem
                            label='City'
                            value={application.city}
                            style={css`
                                margin: 0;
                            `}
                        />
                    )}
                </FlexBox>
            </Box>
            <Box>
                <h6>Rate Required Skills (optional step)</h6>
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    {application.skills.map((skill, index) => (
                        <div
                            key={skill.name}
                            css={css`
                                align-items: center;
                                flex-wrap: wrap;
                            `}
                        >
                            <strong>{skill.name}:</strong>{' '}
                            <span
                                css={css`
                                    color: ${theme.colors.gray_4};
                                `}
                            >
                                {skillProficiencyMapping[skill.rate]}
                            </span>{' '}
                            {index < application.skills.length - 1 && (
                                <Dot
                                    style={css`
                                        position: relative;
                                        top: -2px;
                                        margin-right: 8px;
                                        color: ${theme.colors.gray_2};
                                    `}
                                />
                            )}
                        </div>
                    ))}
                </FlexBox>
            </Box>
        </Fragment>
    ) : null
}

export { ApplicationSummary }
