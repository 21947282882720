/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AccessLevelContext } from '../../../AccessLevelContext'
import { BackLink } from '../../../components/BackLink'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { blackToBlueFilter, blackToVioletDarkFilter, Icon } from '../../../components/Icon'
import { DropParserProps, Dropzone } from '../../../components/inputs/Dropzone'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { ContentLoader, Spinner } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { getAppPath } from '../../../contracts/applications'
import { AxiosApiError } from '../../../contracts/common/apiError'
import { config } from '../../../environment/config'
import { useMySpecialistsMenuItems } from '../../../hooks/useMySpecialistsMenuItems'
import { COLOR_PALETTE } from '../../../theme/colors'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { checkImportInProgress, uploadSpecialists } from '../api'
import { ImportErrorResponse, ImportErrors } from '../contracts'
import { ErrorModal } from './ErrorModal'

const ERROR_MESSAGE_DISPLAY_DURATION = 5000
const MAX_FILE_SIZE = 10485760

enum ImportStates {
    CheckingPendingImport,
    AnotherImportInProgress,
    WaitingForFile,
    IsUploading,
    IsImporting,
}

export type ImportError = {
    errorType: 'VALIDATION_FAILED' | 'INVALID_FILE'
    errors?: Array<ImportErrors>
}

const SpecialistsImportPage = () => {
    const [importState, setImportState] = useState<ImportStates>(ImportStates.CheckingPendingImport)
    const [error, setError] = useState<string>('')
    const [errorTimeoutId, setTimeoutErrorId] = useState<Nullable<number>>(null)
    const [opened, setOpened] = useState(false)

    const [importError, setImportError] = useState<Nullable<ImportError>>(null)

    const log = useLogger()
    const { addSuccess, addError } = useNotifications()

    const { menuItems, applicationName } = useMySpecialistsMenuItems()

    const displayError = useCallback(
        (message: string) => {
            if (errorTimeoutId) {
                clearTimeout(errorTimeoutId)
            }

            setError(message)
            setTimeoutErrorId(
                window.setTimeout(() => {
                    setError('')
                }, ERROR_MESSAGE_DISPLAY_DURATION),
            )
        },
        [setError, errorTimeoutId, setTimeoutErrorId],
    )
    const parser = useCallback(
        ({ acceptedFiles, fileRejections, onChange }: DropParserProps) => {
            if (fileRejections.length) {
                let errorMessage
                const firstErrorCode = fileRejections[0].errors[0].code

                switch (firstErrorCode) {
                    case 'too-many-files': {
                        errorMessage = 'Multiple files dropped. Please drop single spreadsheet.'
                        break
                    }
                    case 'file-invalid-type': {
                        errorMessage = 'This file type cannot be uploaded. Accepted file types are: .xlsx, .xls'
                        break
                    }
                    case 'file-too-large': {
                        errorMessage = 'This spreadsheet file is too large. The maximum file size is 10 MB.'
                    }
                }

                if (errorMessage) {
                    displayError(errorMessage)
                }
            } else {
                if (error) {
                    setError('')
                }

                onChange(acceptedFiles[0])
            }
        },
        [displayError, error],
    )
    const handleDrop = useCallback(
        (fileOrFiles: File | Array<File>) => {
            setImportError(null)
            const fileToUpload = Array.isArray(fileOrFiles) ? fileOrFiles[0] : fileOrFiles
            setImportState(ImportStates.IsUploading)
            uploadSpecialists(fileToUpload).then(
                () => {
                    setImportState(ImportStates.IsImporting)
                    addSuccess('Your spreadsheet was uploaded succesfully! Your data Import has been initiated.')
                    setImportError(null)
                },
                (error: AxiosApiError<ImportErrorResponse>) => {
                    if (error.status === 422) {
                        setImportError({ errorType: 'VALIDATION_FAILED', errors: error.data.errors })
                    } else if (error.status === 400) {
                        setImportError({ errorType: 'INVALID_FILE' })
                    } else {
                        log(error)
                        addError()
                    }

                    setImportState(ImportStates.WaitingForFile)
                },
            )
        },
        [addError, addSuccess, log],
    )

    useEffect(() => {
        checkImportInProgress().then(
            ({ inProgress }) => {
                if (inProgress) {
                    setImportState(ImportStates.AnotherImportInProgress)
                } else {
                    setImportState(ImportStates.WaitingForFile)
                }
            },
            e => {
                log(e)
                setImportState(ImportStates.WaitingForFile)
            },
        )
    }, [log])

    const { mySpecialists: mySpecialistsAccess } = useContext(AccessLevelContext)

    const handleCloseModal = useCallback(() => {
        setOpened(false)
    }, [])

    const handleOpenModal = useCallback(() => {
        setOpened(true)
    }, [])
    const statusInfo = useMemo(() => {
        const status =
            importState === ImportStates.IsUploading
                ? 'Uploading file...'
                : importState === ImportStates.IsImporting
                ? 'Importing Data...'
                : importState === ImportStates.AnotherImportInProgress
                ? 'Another Spreadsheet is Currently Importing...'
                : null
        const message =
            importState === ImportStates.IsUploading
                ? 'Please keep this page open until the file has been uploaded successfully.'
                : importState === ImportStates.IsImporting
                ? 'We will email you once your data Import has been completed.'
                : importState === ImportStates.AnotherImportInProgress
                ? 'Try refreshing this page in a few minutes to begin your Import.'
                : null

        const inProgressIcon = <Icon name='time-clock-file-refresh' size={30} />
        const icon =
            importState === ImportStates.IsUploading ? (
                <Spinner circleWidth={4.5} sizeInner={28} sizeOuter={36} />
            ) : importState === ImportStates.IsImporting ? (
                inProgressIcon
            ) : importState === ImportStates.AnotherImportInProgress ? (
                inProgressIcon
            ) : null
        return {
            icon,
            status,
            message,
        }
    }, [importState])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            {importState === ImportStates.CheckingPendingImport ? (
                <ContentLoader />
            ) : (
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        padding: 15px 15px 40px;
                    `}
                >
                    <div
                        css={css`
                            max-width: 555px;
                            width: 100%;
                            margin: 0 auto;
                        `}
                    >
                        {mySpecialistsAccess.backToList && (
                            <BackLink
                                path={getAppPath('MY_SPECIALISTS')}
                                text='Back to List'
                                style={css`
                                    margin-bottom: 16px;
                                `}
                            />
                        )}
                        <div
                            css={css`
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            `}
                        >
                            <h3>Import from Spreadsheet</h3>
                            <Divider />

                            {importError && (
                                <div
                                    css={css`
                                        background-color: ${COLOR_PALETTE.red_2};
                                        color: ${COLOR_PALETTE.red_4};
                                        padding: 13px 0;
                                        margin-bottom: 24px;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        border-radius: 4px;
                                    `}
                                    data-testid='import-specialists-error-alert'
                                >
                                    <div
                                        css={css`
                                            margin: 0 16px; ;
                                        `}
                                    >
                                        <Icon name='error' size={20} />
                                    </div>

                                    <div>
                                        {importError?.errorType === 'INVALID_FILE'
                                            ? 'Provided file is invalid'
                                            : 'We encountered errors while uploading your sample.xls file. Please correct these errors and upload your spreadsheet again.'}
                                    </div>
                                    {importError?.errorType === 'VALIDATION_FAILED' && (
                                        <Button
                                            variant='secondary'
                                            size='small'
                                            dataTestId='import-specialists-error-modal-button'
                                            css={css`
                                                margin: 0 30px;
                                                height: 24px;
                                                font-size: 12px;
                                            `}
                                            onClick={handleOpenModal}
                                        >
                                            Details
                                        </Button>
                                    )}
                                </div>
                            )}
                            <h5>Before You Start Your Import</h5>

                            <p>
                                If you have a large number of Specialists you would like to import, you can upload them using a spreadsheet.
                                First download our spreadsheet template, read the instructions carefully and then fill in each field for
                                each specialist. Please double check your spreadsheet file before saving and uploading it below. Once your
                                file has successfully been uploaded the data will automatically start importing and be populated to your
                                Specialists’ Profiles.
                            </p>
                            <div
                                css={css`
                                    border: 1px solid ${COLOR_PALETTE.blue_3};
                                    border-radius: 4px;
                                    padding: 6px 12px;
                                    display: flex;
                                    align-items: center;
                                    margin-top: 20px;
                                `}
                            >
                                <span>
                                    Use our <strong>Spreadsheet Template</strong> to Import Specialist Data
                                </span>
                                <Button
                                    variant='text'
                                    style={css`
                                        margin-left: auto;
                                    `}
                                >
                                    <a
                                        css={css`
                                            display: flex;
                                            align-items: center;
                                            color: ${COLOR_PALETTE.violet_5};
                                        `}
                                        href={config.specialistImportTemplateUrl}
                                        data-testid='template-download-link'
                                    >
                                        <Icon
                                            name='download'
                                            size={24}
                                            style={css`
                                                margin-right: 8px;
                                                filter: ${blackToVioletDarkFilter};
                                            `}
                                        />
                                        Download Template
                                    </a>
                                </Button>
                            </div>
                            <Divider
                                style={css`
                                    margin: 11px 0;
                                `}
                            />
                            <div
                                css={css`
                                    position: relative;
                                `}
                            >
                                <Dropzone
                                    onChange={handleDrop}
                                    maxSize={MAX_FILE_SIZE}
                                    parser={parser}
                                    accept={{
                                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                        'application/vnd.ms-excel': ['.xls'],
                                    }}
                                    dropErrorMessages={{
                                        invalidType: 'This file type cannot be uploaded. Accepted file types are: .xlsx, .xls .',
                                        tooManyFiles: 'Too many files. Please drop single spreadsheet.',
                                    }}
                                    dataTestId='import-specialists-input'
                                    dropzoneContent={
                                        <div
                                            data-testid='import-specialists-dropzone'
                                            css={css`
                                                position: relative;
                                                overflow: hidden;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    padding: 64px 24px 24px;
                                                    background: ${COLOR_PALETTE.blue_2};
                                                    display: flex;
                                                    flex-direction: column;
                                                    align-items: center;
                                                `}
                                            >
                                                <Icon
                                                    name='upload-desktop'
                                                    size={39}
                                                    style={css`
                                                        filter: ${blackToBlueFilter};
                                                    `}
                                                />
                                                <span
                                                    css={css`
                                                        margin-top: 24px;
                                                        text-align: center;
                                                    `}
                                                >
                                                    <strong
                                                        css={css`
                                                            display: block;
                                                        `}
                                                    >
                                                        Drag and Drop your saved spreadsheet file here
                                                    </strong>
                                                </span>
                                                <Button
                                                    css={css`
                                                        margin-top: 24px;
                                                    `}
                                                    variant='secondary'
                                                >
                                                    Browse Files
                                                </Button>
                                            </div>
                                            <div
                                                css={css`
                                                    border-radius: 4px 4px 0 0;
                                                    width: 555px;
                                                    background-color: ${COLOR_PALETTE.red_2};
                                                    padding: 5px 0;
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    position: absolute;
                                                    top: 0;
                                                    left: 0;
                                                    color: ${COLOR_PALETTE.red_4};
                                                    font-size: 12px;
                                                    transform: translateY(${error ? '0' : '-100%'});
                                                    transition: transform 300ms ease;
                                                `}
                                            >
                                                {error}
                                            </div>
                                        </div>
                                    }
                                />
                                {(importState === ImportStates.IsUploading ||
                                    importState === ImportStates.IsImporting ||
                                    importState === ImportStates.AnotherImportInProgress) && (
                                    <span
                                        data-testid='import-specialists-info'
                                        css={css`
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 4px 4px 0 0;
                                            color: ${COLOR_PALETTE.gray_3};
                                            background-color: ${COLOR_PALETTE.gray_1};
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            flex-direction: column;
                                        `}
                                    >
                                        <span
                                            css={css`
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 60px;
                                                height: 60px;
                                            `}
                                        >
                                            {statusInfo.icon}
                                        </span>
                                        <strong
                                            css={css`
                                                display: block;
                                            `}
                                        >
                                            {statusInfo.status}
                                        </strong>
                                        {statusInfo.message}
                                    </span>
                                )}
                            </div>
                            <span
                                css={css`
                                    display: block;
                                    margin-top: 24px;
                                    color: ${COLOR_PALETTE.gray_3};
                                    font-size: 12px;
                                `}
                            >
                                Your spreadsheet file cannot be larger than 10 MB.
                            </span>
                        </div>
                    </div>
                </div>
            )}
            {importError?.errorType === 'VALIDATION_FAILED' && (
                <ErrorModal handleCloseModal={handleCloseModal} opened={opened} errors={importError?.errors} />
            )}
        </DashboardLayout>
    )
}

export { SpecialistsImportPage }
