/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { NewSpecialistSkillsRatingPage } from './skills/NewSpecialistSkillsRatingPage'

const SpecialistSkillsRatingPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    return <NewSpecialistSkillsRatingPage />
}

export { SpecialistSkillsRatingPage }
