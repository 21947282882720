import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { getAppPath } from '../../../contracts/applications'
import { CompanyMembersResponse } from '../../../contracts/companyMembers'
import { history } from '../../../history'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { useQuery } from '../../../utils/useQuery'
import { OpportunityFormPage } from '../../../components/opportunities-manager/OpportunityForm/OpportunityFormPage'
import { parseFormData } from '../../../components/opportunities-manager/OpportunityForm/utils/parseFormData'
import { editOpportunity, getOpportunity } from '../../../api/opportunities-manager/api'
import { OpportunityResponseBody, UpdateOpportunityFormData } from '../../../contracts/opportunities-manager/contracts'

const EditOpportunityPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { opportunityId } = useParams<{ opportunityId: string }>()
    const [opportunity, setOpportunity] = useState<Nullable<OpportunityResponseBody>>(null)
    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const query = useQuery()

    const isSmeCloudApp = false

    const appPath = getAppPath('OPPORTUNITIES_MANAGER')

    useEffect(() => {
        if (opportunityId) {
            getOpportunity(opportunityId)
                .then(setOpportunity)
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, opportunityId])

    const onSubmit = useCallback(
        (formData: UpdateOpportunityFormData, companyMembers: Nullable<CompanyMembersResponse>, hideLoaderOnSuccess = true) => {
            if (opportunityId) {
                layoutToggleLoader(true)
                return editOpportunity(parseFormData(formData, companyMembers, isSmeCloudApp), opportunityId)
                    .then(updatedOpportunity => {
                        if (hideLoaderOnSuccess) {
                            history.push(decodeURIComponent(query.get('prevPath') || appPath))
                            addSuccess('You successfully saved this Opportunity')
                            layoutToggleLoader(false)
                        }
                        return updatedOpportunity
                    })
                    .catch(err => {
                        log(err)
                        addError()
                        layoutToggleLoader(false)
                    })
            } else {
                return Promise.resolve()
            }
        },
        [addError, addSuccess, appPath, isSmeCloudApp, layoutToggleLoader, log, opportunityId, query],
    )

    return <OpportunityFormPage opportunity={opportunity} onSubmit={onSubmit} isSmeCloudApp={isSmeCloudApp} isVMCCloudApp={false} />
}

export { EditOpportunityPage }
