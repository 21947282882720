/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useContext, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { sendResetPasswordEmail } from '../../api/api'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Info } from '../../components/Info'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'

const AccountLoginSettings = () => {
    const { removeAllToasts } = useToasts()
    const {
        selectors: { user },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { addError } = useNotifications()
    const log = useLogger('error')
    const { addSuccess } = useNotifications()

    const [emailSent, setEmailSent] = useState(false)

    const handleChangePassword = useCallback(() => {
        removeAllToasts()
        layoutToggleLoader(true)
        sendResetPasswordEmail(user.email)
            .then(() => {
                addSuccess('An email has been sent successfully.')
                setEmailSent(true)
            })
            .catch(err => {
                log(err)
                addError()
            })
            .finally(() => layoutToggleLoader(false))
    }, [removeAllToasts, layoutToggleLoader, user, addSuccess, log, addError])

    return (
        <SettingsWrapper>
            <h4 data-testid='login-settings-check'>Login Settings</h4>
            <Divider />
            <h6>Email Address</h6>
            <p>
                Your current Email Address is <strong>{user && user.email}</strong>
            </p>
            <Divider />
            <h6>Change Password</h6>
            {emailSent ? (
                <Info>
                    <h6 data-testid='change-password-confirmation'>Check your Inbox</h6>
                    Please check your email account to proceed with changing your password.
                    <Divider />
                    <Button onClick={handleChangePassword} variant='linkForm' dataTestId='resend-email'>
                        Resend Email
                    </Button>
                </Info>
            ) : (
                <Button
                    variant='primary'
                    onClick={handleChangePassword}
                    disabled={!user}
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                    dataTestId='change-password-submit'
                >
                    Change Password
                </Button>
            )}
        </SettingsWrapper>
    )
}

export { AccountLoginSettings }
