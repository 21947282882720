/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../../components/forms/ControlledAutocompleteSelect'
import { IconButton } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { LanguageRequest, LanguageResponse, LevelType, levelTypes, mapLevelType } from '../../../../contracts/profile/language'
import { mqMax } from '../../../../GlobalStyle'
import { NullableArray } from '../../../../types'
import { yup } from '../../../../validation/yup'
import { updateSpecialistLanguage } from '../../api'
import { InlineFieldWrapper, ItemActionsPanel, PropertyLabel } from '../../shared/ProfileTabsHelpers'

type LanguageItemFormProps = {
    language: LanguageResponse
    languageDictionary?: NullableArray<string>
    specialistId: string
    languages?: Array<LanguageResponse>
    fetchLanguages: () => void
    onSave?: () => void
    toggleEditMode: () => void
    handleDelete: () => void
}

const LanguageItemForm: FunctionComponent<React.PropsWithChildren<LanguageItemFormProps>> = ({
    language: { name, level, id },
    languageDictionary = null,
    specialistId,
    languages,
    fetchLanguages,
    onSave,
    toggleEditMode,
    handleDelete,
}) => {
    const { addSuccess, addError } = useNotifications()

    const schema = useMemo(
        () =>
            yup.object().shape({
                name: yup
                    .string()
                    .required()
                    .test('validateUniqueLanguage', 'You already selected this language', (pickedLanguage: any) => {
                        for (const lang of languages || []) {
                            if (lang.name === pickedLanguage && pickedLanguage !== name) {
                                return false
                            }
                        }
                        return true
                    }),
                level: yup.string().required(),
            }),
        [languages, name],
    )

    const {
        handleSubmit,
        control,
        formState: { isDirty, isSubmitting },
    } = useForm<LanguageRequest>({
        resolver: yupResolver(schema),
        defaultValues: {
            name,
            level,
        },
    })

    const handleLanguageUpdate = useCallback(
        (formData: LanguageRequest): void => {
            if (id) {
                updateSpecialistLanguage(specialistId, id, formData)
                    .then(() => {
                        toggleEditMode()
                        addSuccess('Language updated!')
                        fetchLanguages()

                        if (onSave) {
                            onSave()
                        }
                    })
                    .catch(() => {
                        addError()
                    })
            }
        },
        [addError, addSuccess, fetchLanguages, id, onSave, specialistId, toggleEditMode],
    )

    return (
        <form onSubmit={handleSubmit(handleLanguageUpdate)}>
            <FlexBox
                css={css`
                    flex-wrap: wrap;
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <InlineFieldWrapper
                    css={css`
                        align-items: flex-start;
                    `}
                >
                    <PropertyLabel
                        css={css`
                            line-height: 40px;
                        `}
                    >
                        Language
                    </PropertyLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Language'
                        options={languageDictionary}
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        control={control}
                        name='name'
                        styles={css`
                            width: 150px;
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                    />
                </InlineFieldWrapper>

                <InlineFieldWrapper
                    css={css`
                        margin-left: 24px;
                        ${mqMax[1]} {
                            margin: 0;
                        }
                    `}
                >
                    <PropertyLabel
                        css={css`
                            line-height: 40px;
                        `}
                    >
                        Level
                    </PropertyLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Level'
                        options={levelTypes}
                        control={control}
                        selectedLabelTransformer={(val: LevelType) => mapLevelType(val)}
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        name='level'
                        styles={css`
                            width: 150px;
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                    />
                </InlineFieldWrapper>

                <ItemActionsPanel>
                    <IconButton name='edit' size={32} onClick={toggleEditMode} />
                    <IconButton name='trash' size={32} onClick={handleDelete} />
                </ItemActionsPanel>
            </FlexBox>
            <Divider
                style={css`
                    margin-bottom: 0;
                `}
            />
            <section
                css={css`
                    display: flex;
                    justify-content: flex-end;

                    ${mqMax[1]} {
                        justify-content: center;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            >
                <Button
                    css={css`
                        margin-right: 12px;
                        ${mqMax[1]} {
                            width: 100%;
                            margin: 12px 0 0;
                        }
                    `}
                    variant='text'
                    onClick={toggleEditMode}
                >
                    Cancel
                </Button>
                <Button
                    style={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                    variant='primary'
                    type='submit'
                    disabled={isSubmitting || !isDirty}
                    dataTestId='save-language'
                >
                    Save
                </Button>
            </section>
        </form>
    )
}

export { LanguageItemForm }
