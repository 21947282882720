/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Button } from './Button'
import { Divider } from './Divider'
import { Modal } from './Modal'

type UnsavedChangesModalProps = {
    opened: boolean
    onStayClicked: () => void
    onLeaveClicked: () => void
}

const UnsavedChangesModal: FunctionComponent<React.PropsWithChildren<UnsavedChangesModalProps>> = ({
    opened,
    onStayClicked,
    onLeaveClicked,
}) => {
    return (
        <Modal onClose={onStayClicked} opened={opened}>
            <h5
                css={css`
                    color: ${COLOR_PALETTE.gray_6};
                `}
            >
                Are you sure you want to leave?
            </h5>
            <p
                css={css`
                    color: ${COLOR_PALETTE.gray_4};
                `}
            >
                The changes you made will not be saved.
            </p>
            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button
                    css={css`
                        margin-right: 29px;
                    `}
                    variant='link'
                    onClick={onStayClicked}
                >
                    Go Back
                </Button>
                <Button variant='primary' onClick={onLeaveClicked}>
                    Leave
                </Button>
            </div>
        </Modal>
    )
}

export { UnsavedChangesModal }
