import { deepClone } from './deepClone'

const arrayShuffle = <T>(array: Array<T>): Array<T> => {
    const clonedArray = deepClone(array)

    let currentIndex = clonedArray.length,
        temporaryValue,
        randomIndex

    while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex)
        currentIndex -= 1
        temporaryValue = clonedArray[currentIndex]
        clonedArray[currentIndex] = clonedArray[randomIndex]
        clonedArray[randomIndex] = temporaryValue
    }

    return clonedArray
}

export { arrayShuffle }
