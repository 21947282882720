/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect } from 'react'
import { Control } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { mqMax } from 'src/GlobalStyle'
import { Nullable } from 'src/types'
import { mapSharedType, ShareMySpecialistsFormData, sharedTypes } from '../../contracts'
import { CellWrapper, getSharedStatusStyles, InputWrapper } from '../../utils'
import { SharedType } from 'src/contracts/specialist/specialist'

interface StatusProps {
    sharedStatus: Nullable<SharedType>
    currentBookingRequestId?: string
    id?: string
    setBookNowResponseModalOpen?: Dispatch<SetStateAction<boolean>>
    setBookNowResponseModalData?: Dispatch<
        SetStateAction<{
            specialistId: string
            bookingId: string
        }>
    >
}

interface StatusEditProps extends StatusProps {
    control: Control<ShareMySpecialistsFormData>
    editing: boolean
    index: number
    setValue: UseFormSetValue<ShareMySpecialistsFormData>
}

const Status: FunctionComponent<React.PropsWithChildren<StatusProps>> = ({
    sharedStatus,
    currentBookingRequestId,
    setBookNowResponseModalOpen,
    setBookNowResponseModalData,
    id,
}) => {
    const isNotShared = sharedStatus === 'NOT_SHARED'
    const status = mapSharedType(sharedStatus)

    const handleProjectModalOpen = useCallback(() => {
        if (
            currentBookingRequestId &&
            setBookNowResponseModalData &&
            setBookNowResponseModalOpen &&
            id &&
            sharedStatus === 'BOOKING_REQUEST_RECEIVED'
        ) {
            setBookNowResponseModalData({ specialistId: id, bookingId: currentBookingRequestId })
            setBookNowResponseModalOpen(true)
        }
    }, [currentBookingRequestId, setBookNowResponseModalData, setBookNowResponseModalOpen, id, sharedStatus])

    return (
        <CellWrapper css={getSharedStatusStyles(sharedStatus)}>
            {isNotShared ? (
                <Tooltip title='This Specialist is not currently shared on the Talent Marketplace.'>
                    <div>{status}</div>
                </Tooltip>
            ) : (
                <div onClick={handleProjectModalOpen}>{status}</div>
            )}
        </CellWrapper>
    )
}

const StatusEdit: FunctionComponent<React.PropsWithChildren<StatusEditProps>> = ({ sharedStatus, index, control, setValue }) => {
    useEffect(() => {
        setValue(`specialists.${index}.shared`, sharedStatus)
    }, [index, sharedStatus, setValue])

    return (
        <InputWrapper
            css={css`
                max-width: 130px;
                ${mqMax[2]} {
                    max-width: none;
                }
            `}
        >
            <ControlledAutocompleteSelect
                canFilter={false}
                control={control}
                name={`specialists.${index}.shared`}
                options={sharedTypes}
                selectedLabelTransformer={(val: SharedType) => mapSharedType(val)}
                valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
            />
        </InputWrapper>
    )
}

export { Status, StatusEdit }
