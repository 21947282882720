import queryString from 'query-string'
import { Paging } from '../../contracts/common/pagingProps'
import { countArrayFilters } from '../../utils/filters'
import { encodeField, parseToArray, parseToBoolean, parseToNumber, parseToString } from '../../utils/searchFilters'
import { SpecialistSearchRequest, SpecialistsFilters, SpecialistsFiltersRequest } from './contracts'

const countSelectedFilters = (filters: SpecialistsFilters): number => {
    let counter = 0
    const { remote } = filters

    counter += countArrayFilters(filters, ['employmentTypes', 'roles', 'seniorities', 'skills', 'locations'])

    if (remote) {
        counter++
    }

    return counter
}

const normalizeUrl = (url: string) => {
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
        return `https://${url}`
    } else {
        return url
    }
}

const stringifyMySpecialistsSearchRequestToQuery = (searchRequest: SpecialistSearchRequest): string => {
    const params = []
    const { searchText, seniorities, skills, locations, remote, paging, employmentTypes, roles } = searchRequest

    if (employmentTypes && employmentTypes.length > 0) {
        params.push(`employmentTypes=${encodeField(employmentTypes)}`)
    }

    if (roles && roles.length > 0) {
        params.push(`roles=${encodeField(roles)}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (seniorities && seniorities.length > 0) {
        params.push(`seniorities=${encodeField(seniorities)}`)
    }

    if (skills && skills.length > 0) {
        params.push(`skills=${encodeField(skills)}`)
    }

    if (locations && locations.length > 0) {
        if (locations[0].country) {
            params.push(`locationsCountry=${encodeField(locations[0].country)}`)
            if (locations[0].cities.length > 0) {
                params.push(`locationsCities=${encodeField(locations[0].cities)}`)
            }
        }
    }

    if (remote) {
        params.push(`remote=1`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    return params.join('&')
}

const parseQueryToMySpecialistsSearchRequest = (searchQuery: string): SpecialistsFiltersRequest & SpecialistsFilters & Paging => {
    const params = queryString.parse(searchQuery, { decode: false })
    const { searchText, seniorities, skills, locationsCountry, locationsCities, roles, remote, page, employmentTypes, size } = params

    const result: SpecialistsFiltersRequest & SpecialistsFilters & Paging = {
        searchText: parseToString(searchText),
        seniorities: parseToArray(seniorities),
        skills: parseToArray(skills),
        roles: parseToArray(roles),
        remote: parseToBoolean(remote),
        employmentTypes: parseToArray(employmentTypes),
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, 10),
        },
        locations: locationsCountry
            ? [
                  {
                      country: parseToString(locationsCountry),
                      cities: parseToArray(locationsCities),
                  },
              ]
            : locationsCities
            ? [
                  {
                      country: parseToString(locationsCountry),
                      cities: parseToArray(locationsCities),
                  },
              ]
            : [],
    }

    return result
}

export { countSelectedFilters, normalizeUrl, parseQueryToMySpecialistsSearchRequest, stringifyMySpecialistsSearchRequestToQuery }
