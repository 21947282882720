/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Icon, IconName } from './Icon'
import { Button } from './Button'
import { Link } from 'react-router-dom'
import { FunctionComponent } from 'react'
import { mqMax } from 'src/GlobalStyle'

const InfoLinkWrapper = styled.div`
    background-color: ${props => props.theme.colors.violet_1};
    display: flex;
    padding: 2rem;
    justify-content: space-between;
    border-radius: 4px;
    box-shadow: ${props => props.theme.shadow.small};

    ${mqMax[1]} {
        flex-direction: column;
        align-items: center;
    }
`

const IconWrapper = styled.div`
    background-color: ${props => props.theme.colors.violet_2};
    border-radius: 50%;
    width: 56px;
    padding: 12px;
    margin-right: 24px;
    ${mqMax[1]} {
        margin-right: 0px;
        margin-bottom: 24px;
    }
`

const LinkWrapper = styled.div`
    color: ${props => props.theme.colors.violet_5};
`
const Subtitile = styled.div`
    color: ${props => props.theme.colors.gray_4};
`

type InfoLinkProps = {
    title: string
    subtitle: string
    linkTitle: string
    linkUrl: string
    icon: IconName
}

const InfoLink: FunctionComponent<InfoLinkProps> = ({ title, subtitle, linkTitle, linkUrl, icon }) => {
    return (
        <div>
            <InfoLinkWrapper>
                <div
                    css={css`
                        display: flex;
                        ${mqMax[1]} {
                            flex-direction: column;
                            align-items: center;
                        }
                    `}
                >
                    <IconWrapper>
                        <Icon name={icon} size={32} />
                    </IconWrapper>
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            ${mqMax[1]} {
                                flex-direction: column;
                                align-items: center;
                            }
                        `}
                    >
                        <div
                            css={css`
                                font-weight: 500;
                                font-size: 16px;
                            `}
                        >
                            {title}
                        </div>
                        <Subtitile>{subtitle}</Subtitile>
                    </div>
                </div>
                <Button variant='link' color='violet'>
                    <Link to={linkUrl} target='_blank' rel='noopener noreferrer'>
                        <LinkWrapper>{linkTitle} &gt;</LinkWrapper>
                    </Link>
                </Button>
            </InfoLinkWrapper>
        </div>
    )
}

export { InfoLink }
