import App from './App'
import { fetchFeatures } from './redux/featureFlags'
import { initialState, ReduxProvider } from './redux/Store'
import { getDecodedAccessToken, getUserRole } from './utils/currentUser'
import { log } from './utils/useLogger'

const createRoot = async () => {
    let features = null

    try {
        features = await fetchFeatures()
    } catch (e) {
        log(e, 'error')
    }

    const user = getDecodedAccessToken()
    const authSubscribed = !['specialist'].includes(getUserRole() || '')

    return (
        <ReduxProvider defaultState={{ ...initialState, features: features?.features || [], user, authSubscribed }}>
            <App />
        </ReduxProvider>
    )
}

export { createRoot }
