import { KeyInfo } from '../../pages/my-specialists/contracts'
import { Nullable } from '../../types'
import { formatDate } from '../../utils/dates'
import { AvailableOn } from '../search/availableOn'

interface Specialist {
    city: Nullable<string>
    country: Nullable<string>
    email?: string
    employmentType: Nullable<string>
    remote: boolean | string
    role: string
    seniority?: Nullable<string>
    firstName: string
    lastName: string
    topSkills?: Array<string>
    profileStrength?: number
    id: string
    availableFrom?: number
    availableTo?: number
    availability?: string
    rate?: number
    availableOnSyndicates?: Array<AvailableOn>
    availableInPublicCloud?: boolean
    publicCloud?: AvailableOn
    companyName?: string
    missingKeyInfo?: Array<KeyInfo>
}

type SpecialistSummary = {
    experience: Nullable<string>
    recentIndustry: Nullable<string>
    recentProject: Nullable<string>
    recentRole: Nullable<string>
}

type EmploymentTypeMapping = {
    [k: string]: string
}

const employmentTypesMapping: EmploymentTypeMapping = {
    B2B: 'B2B',
    CANDIDATE: 'Candidate',
    CONTRACTOR: 'Contractor',
    FULL_TIME: 'Full Time Employee',
}

const mapEmploymentType = (type: string) => {
    return employmentTypesMapping[type] || type
}

const mapRemoteType = (remote: boolean) => {
    return remote ? 'Yes' : 'No'
}

const mapEmploymentToType = (value: string): string => {
    return Object.keys(employmentTypesMapping).find(key => employmentTypesMapping[key] === value) || value
}

const mapAvailability = (availabilityFrom: number, availabilityTo?: number): string => {
    return `${formatDate(availabilityFrom)} - ${availabilityTo ? formatDate(availabilityTo) : 'Not Specified'}`
}

enum SharedType {
    SHARED = 'SHARED',
    NOT_SHARED = 'NOT_SHARED',
    BOOKING_REQUEST_ACCEPTED = 'BOOKING_REQUEST_ACCEPTED',
    BOOKING_REQUEST_RECEIVED = 'BOOKING_REQUEST_RECEIVED',
}

interface ShareMySpecialistsEditData {
    rate?: Nullable<number>
    availableFrom?: Nullable<number>
    availableTo?: Nullable<number>
    availableOn?: Array<string>
    shared?: Nullable<SharedType>
    country?: string
    currency?: string
}

interface ShareMySpecialistsSpecialist {
    id: string
    firstName: string
    lastName: string
    role: string
    email: string
    seniority: string
    rate: number
    availableFrom: number
    availableTo: number
    availableOn: Array<string>
    sharedStatus: SharedType
    availability?: string
    currentBookingRequestId?: string
    country?: Nullable<string>
    missingKeyInfo?: Array<KeyInfo>
    currency?: string
}

export { mapEmploymentType, mapEmploymentToType, mapRemoteType, mapAvailability, SharedType }
export type { Specialist, SpecialistSummary, ShareMySpecialistsEditData, ShareMySpecialistsSpecialist }
