/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { useDebounce, useUnmount } from 'react-use'
import { Button } from '../../../../components/Button'
import { Nullable } from '../../../../types'
import { GameMatrix } from '../../contracts'
import { GameBoard } from '../GameBoard'
import { StepWrapper } from '../StepWrapper'
import game from './../../../../assets/icons/game.svg'
import refresh from './../../../../assets/icons/refresh.svg'

type PlayProps = {
    matrix?: Nullable<GameMatrix>
    isFinished?: boolean
    isTraining?: boolean
    isCorrect?: boolean
    onClick?: (rowIndex: number, colIndex: number) => void
    time?: number
    round: number
    rounds: number
    startAgain?: (isTraining: boolean) => void
}

const Play: FunctionComponent<React.PropsWithChildren<PlayProps>> = ({
    matrix,
    isFinished = false,
    onClick = () => null,
    time = null,
    isTraining = false,
    round,
    rounds,
    isCorrect = false,
    startAgain,
}) => {
    const [isDisabled, setIsDisabled] = useState(true)
    const [, cancel] = useDebounce(() => setIsDisabled(false), 300, [])
    useUnmount(cancel)

    const startGame = useCallback(() => {
        if (startAgain) {
            startAgain(false)
        }
    }, [startAgain])

    const restartTraining = useCallback(() => {
        if (startAgain) {
            startAgain(true)
        }
    }, [startAgain])

    return (
        <StepWrapper step={3} round={round} rounds={rounds} time={time} description='Tap the dots that were black before.'>
            <div
                css={css`
                    width: 324px;
                    height: 300px;
                    margin: 0 auto;
                    position: relative;
                `}
            >
                {isDisabled && (
                    <div
                        css={css`
                            width: 100%;
                            height: 100%;
                            background-color: red;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 999999;
                            opacity: 0;
                        `}
                    />
                )}
                <GameBoard
                    matrix={matrix}
                    isVisible={false}
                    isFinished={isFinished}
                    padding='14px'
                    boxSize='62px'
                    handleClick={onClick}
                    isCorrect={isCorrect}
                />
            </div>

            <section
                css={css`
                    margin-top: 45px;
                    display: flex;
                    justify-content: space-between;
                    height: 37px;
                `}
            >
                {isTraining && isFinished && (
                    <Fragment>
                        <Button icon={refresh} variant='tertiary' onClick={restartTraining}>
                            Practice Again
                        </Button>
                        <div
                            css={css`
                                width: 10px;
                            `}
                        ></div>
                        <Button icon={game} onClick={startGame}>
                            I’m Ready to Play!
                        </Button>
                    </Fragment>
                )}
            </section>
        </StepWrapper>
    )
}
export { Play }
