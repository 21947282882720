/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Modal } from '../../../../components/Modal'
import { COLOR_PALETTE } from '../../../../GlobalStyle'

type LeavingConfirmationModalProps = {
    isVisible: boolean
    handleRejection: () => void
    handleConfirmation: () => void
}

const LeavingConfirmationModal: FunctionComponent<React.PropsWithChildren<LeavingConfirmationModalProps>> = ({
    isVisible,
    handleRejection,
    handleConfirmation,
}) => (
    <Modal onClose={handleRejection} opened={isVisible}>
        <h5
            css={css`
                color: ${COLOR_PALETTE.gray_6};
            `}
            data-testid='cancel-confirmation-modal-check'
        >
            Are you sure you want to leave?
        </h5>
        <p
            css={css`
                color: ${COLOR_PALETTE.gray_4};
            `}
        >
            The changes you made will not be saved.
        </p>
        <Divider />
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button
                css={css`
                    margin-right: 29px;
                `}
                variant='link'
                onClick={handleRejection}
            >
                Go Back
            </Button>
            <Button variant='primary' onClick={handleConfirmation}>
                Leave
            </Button>
        </div>
    </Modal>
)

export { LeavingConfirmationModal }
