/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import ITTalent from '../assets/images/IT_talent.svg'
import pwcRegister from '../assets/images/pwc_register.svg'
import { useSyndicate } from '../hooks/useSyndicate'

const ManagerLoginBanner: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { isPwcSyndicate } = useSyndicate()
    const theme = useTheme()

    return isPwcSyndicate ? (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                height: 100%;
                background-color: ${theme.colors.orange_1};
            `}
        >
            <img
                src={pwcRegister}
                alt='Welcome to the PwC Human Cloud'
                css={css`
                    width: 100%;
                `}
            />
            <h4
                css={css`
                    margin-top: 42px;
                    color: ${theme.colors.white};
                `}
            >
                Welcome to the PwC Human Cloud
            </h4>
            <p
                css={css`
                    max-width: 350px;
                    font-size: 16px;
                    color: ${theme.colors.white};
                `}
            >
                Discover engaging projects and unlock new opportunities for your organization.
            </p>
        </div>
    ) : (
        <div
            css={css`
                height: 100%;
                background-color: ${theme.colors.blue_2};
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            `}
        >
            <div
                css={css`
                    margin: 0 auto;
                    max-width: 450px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                `}
            >
                <img
                    src={ITTalent}
                    alt='Open IT Talent'
                    css={css`
                        width: 100%;
                    `}
                />
                <h4
                    css={css`
                        margin-top: 42px;
                    `}
                >
                    Open IT Talent
                </h4>
                <p
                    css={css`
                        font-size: 16px;
                    `}
                >
                    Your all-in-one platform for finding IT talent <br />
                    and discovering new technology projects
                </p>
            </div>
        </div>
    )
}

export { ManagerLoginBanner }
