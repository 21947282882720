/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mqMax } from '../../GlobalStyle'
import { BackLink } from '../BackLink'
import { Button } from '../Button'
import { ControlledRatingStars } from '../forms/ControlledRatingStars'
import { ControlledTextarea } from '../forms/ControlledTextarea'
import { FieldWrapper } from '../layout/FormHelpers'
import { ResponsiveWrapper } from '../layout/ResponsiveWrapper'

type RateYourExperienceProps = {
    message: string
    onSubmit: (formData: any) => void
    backPath: string
}

const RateYourExperience: FunctionComponent<React.PropsWithChildren<RateYourExperienceProps>> = ({ message, onSubmit, backPath }) => {
    const { reset, handleSubmit, formState, control } = useForm({
        mode: 'onChange',
    })

    useEffect(() => {
        reset({ rating: 0 })
    }, [reset])

    return (
        <ResponsiveWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BackLink
                    path={backPath}
                    text='Back'
                    style={css`
                        margin-top: 24px;
                    `}
                />
                <h3
                    css={css`
                        margin-top: 24px;
                        margin-bottom: 16px;
                    `}
                >
                    Rate Your Experience
                </h3>
                <p
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    {message}
                </p>

                <ControlledRatingStars control={control} name='rating' />
                <FieldWrapper
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    <ControlledTextarea label='Add a Comment (Optional)' name='comments' placeholder='Type something' control={control} />
                </FieldWrapper>

                <Button
                    variant='primary'
                    type='submit'
                    disabled={formState.isSubmitting}
                    style={css`
                        width: 145px;

                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Submit
                </Button>
            </form>
        </ResponsiveWrapper>
    )
}

export { RateYourExperience }
