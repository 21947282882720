import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mqMax } from 'src/GlobalStyle'
import { Button } from 'src/components/Button'
import { FieldInfo } from 'src/components/inputs/input-field/FieldInfo'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { SuggestedSkillsBox } from 'src/components/skills-questionnaire/SuggestedSkillsBox'

const H5 = styled.h5`
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray_6};
    margin-bottom: 16px;
`

const StyledLoader = styled(OverlayContentLoader)`
    z-index: 10;
`

const FieldsWrapper = styled(FlexBox)`
    margin-bottom: 16px;
`

const FieldWrapper = styled.div<{ padding?: string; width?: string }>`
    padding: ${({ padding }) => padding || 0};
    width: ${({ width }) => width || '100%'};
`

const Divider = styled.div`
    margin: 24px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray_2};
`

const StyledSuggestedSkills = styled(SuggestedSkillsBox)`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 0;
    margin-bottom: 16px;
`
const LanguageWrapper = styled.div<{ noError?: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.gray_2};
    padding: 16px 24px;
    position: relative;

    ${({ noError = true }) =>
        noError &&
        css`
            margin-bottom: 16px;
        `}
`

const AddLanguageButton = styled(Button)`
    ${mqMax[1]} {
        width: 100%;
    }
`

const StyledFieldInfo = styled(FieldInfo)`
    margin-bottom: 16px;
`

const CVWrapper = styled.div`
    width: 100%;
    padding: 10px 16px;
    border: 1px solid ${({ theme }) => theme.colors.gray_2};
    border-radius: 2px;
    margin-bottom: 14px;
`

const FileName = styled.div`
    color: ${({ theme }) => theme.colors.gray_6};
    cursor: pointer;
`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

const CancelButton = styled(Button)`
    margin-right: 29px;
`

const ApplicantName = styled.h6<{ hasMargin: boolean }>`
    ${({ hasMargin }) =>
        hasMargin &&
        css`
            margin: 0.5em 0;
        `};
`

const CustomCvTemplateBanner = styled.p`
    background: ${({ theme }) => theme.colors.red_2};
    padding: 8px;
`

const DownloadCvTemplate = styled(Button)`
    padding: 0;
    text-decoration: underline;
`

const CustomCvTemplateCheckbox = styled.div`
    margin-bottom: 1em;
`

export {
    H5,
    StyledLoader,
    FieldsWrapper,
    FieldWrapper,
    Divider,
    StyledSuggestedSkills,
    LanguageWrapper,
    AddLanguageButton,
    StyledFieldInfo,
    CVWrapper,
    FileName,
    ButtonsWrapper,
    CancelButton,
    ApplicantName,
    CustomCvTemplateBanner,
    DownloadCvTemplate,
    CustomCvTemplateCheckbox,
}
