/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment from 'moment'
import { FunctionComponent, useCallback } from 'react'
import { SpecialistProject } from '../../contracts/specialist/specialistProject'
import { mqMax } from '../../GlobalStyle'
import { COLOR_PALETTE } from '../../theme/colors'
import { monthFormat } from '../../utils/dates'
import { Checkbox } from '../inputs/Checkbox'
import { cleanStringFromHTMLTags } from 'src/utils/strings'

type ProjectItemProps = {
    project: SpecialistProject
    onChange: (id: string) => void
    isChecked: boolean
}

const ProjectItem: FunctionComponent<React.PropsWithChildren<ProjectItemProps>> = ({ project, onChange, isChecked }) => {
    const handleChange = useCallback(() => {
        onChange(project.id)
    }, [onChange, project.id])

    return (
        <div
            css={css`
                margin-top: 24px;
            `}
        >
            <Checkbox
                checkboxLabel={project.role}
                onChange={handleChange}
                name={project.id}
                checked={isChecked}
                style={css`
                    font-weight: 500;
                `}
            />
            <div
                css={css`
                    height: 22px;
                    margin: 8px 0 0 24px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    ${mqMax[1]} {
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        ${mqMax[1]} {
                            flex-direction: column;
                        }
                    `}
                >
                    <div
                        css={css`
                            font-size: 12px;
                            font-weight: 500;
                        `}
                    >
                        {project.company}
                    </div>
                    <div
                        css={css`
                            margin: 0 14px;
                            padding-top: 4px;
                            font-size: 16px;
                            color: ${COLOR_PALETTE.gray_4};
                            ${mqMax[1]} {
                                display: none;
                            }
                        `}
                    >
                        &#8226;
                    </div>
                    <div
                        css={css`
                            ${mqMax[1]} {
                                margin: 4px 0;
                            }
                        `}
                    >
                        <span
                            css={css`
                                color: ${COLOR_PALETTE.gray_4};
                                font-size: 12px;
                                margin-right: 4px;
                            `}
                        >
                            Industry
                        </span>
                        <span
                            css={css`
                                font-size: 12px;
                            `}
                        >
                            {project.industry ? project.industry : '-'}
                        </span>
                    </div>
                </div>
                <div
                    css={css`
                        font-size: 12px;
                    `}
                >
                    <span>
                        {typeof project.from === 'string'
                            ? moment(project.from).format(monthFormat)
                            : moment.unix(project.from || 0).format(monthFormat)}
                        -{' '}
                    </span>
                    <span>
                        {typeof project.to === 'string'
                            ? moment(project.from).format(monthFormat)
                            : project.to
                            ? moment.unix(project.to).format(monthFormat)
                            : 'Now'}
                    </span>
                </div>
            </div>
            <p
                css={css`
                    color: ${COLOR_PALETTE.gray_4};
                    font-size: 12px;
                    margin: 4px 0 0 24px;
                `}
            >
                Project description:{' '}
                {project.description
                    ? cleanStringFromHTMLTags(project.description).length > 150
                        ? `${cleanStringFromHTMLTags(project.description).substring(0, 150)} (...)`
                        : cleanStringFromHTMLTags(project.description)
                    : '-'}
            </p>
        </div>
    )
}

export default ProjectItem
