/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FormEvent, Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { acceptUserTerms, getUserTerms } from '../api/api'
import { Button } from '../components/Button'
import { Divider } from '../components/Divider'
import { Checkbox } from '../components/inputs/Checkbox'
import { Modal } from '../components/Modal'
import { useNotifications } from '../components/notification/NotificationProvider'
import { TermsNames, TermsToReCertify } from '../contracts/terms'
import { useUserAuthentication } from '../hooks/useUserAuthentication'
import { ReduxContext, TermsAcceptStatus } from '../redux/Store'
import { Nullable } from '../types'
import { getUserRole } from '../utils/currentUser'
import { useLogger } from '../utils/useLogger'
import { VMCstatusEnum } from 'src/contracts/vmc'

const AcceptTerms: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [newsletterAccepted, setNewsletterAccepted] = useState(false)
    const [isTermsUpdate, setIsTermsUpdate] = useState(false)
    const { addError } = useNotifications()
    const navigate = useNavigate()
    const [terms, setTerms] = useState<Nullable<Array<TermsToReCertify>>>(null)
    const {
        selectors: { user, termsStatus, VMCstatus },
        actions: { setTermsStatus },
    } = useContext(ReduxContext)

    const { isSpecialistAccount, isPasswordAccountActivated, isUserAuthorized, isWaitingForStatus } = useUserAuthentication()

    const log = useLogger('error')
    const role = getUserRole()

    const isVMCUser = useMemo(() => VMCstatus !== VMCstatusEnum.NONE, [VMCstatus])

    useEffect(() => {
        setTermsStatus(null)
    }, [role, setTermsStatus])

    useEffect(() => {
        if (termsStatus === TermsAcceptStatus.Declined) {
            navigate(isSpecialistAccount ? '/specialist-terms-decline' : '/logout/user')
        }
    }, [navigate, isSpecialistAccount, termsStatus])

    useEffect(() => {
        if (isUserAuthorized && !termsStatus && !isWaitingForStatus) {
            getUserTerms()
                .then(responseBody => {
                    if (responseBody) {
                        const termsOfUseRequired = responseBody.termsToReCertify.find(
                            term => term.name === TermsNames.TERMS_OF_USE && term.required,
                        )
                        if (termsOfUseRequired) {
                            setIsTermsUpdate(responseBody.hasNoCertifiedTerms)
                            setTerms(responseBody.termsToReCertify)
                            setTermsStatus(TermsAcceptStatus.RequiredAction)
                        } else {
                            setTermsStatus(TermsAcceptStatus.Accepted)
                        }
                    }
                })
                .catch(log)
        }
    }, [isUserAuthorized, termsStatus, addError, setTermsStatus, log, isWaitingForStatus])

    const handleDecline = useCallback(() => {
        setTermsStatus(TermsAcceptStatus.Declined)
        navigate(isSpecialistAccount ? '/specialist-terms-decline' : '/logout/user')
    }, [navigate, isSpecialistAccount, setTermsStatus])

    const handleAccept = useCallback<(event: FormEvent<HTMLFormElement>) => void>(
        event => {
            event.preventDefault()
            const termsOfUse = terms?.find(termsItem => termsItem.name === TermsNames.TERMS_OF_USE)
            const marketingTerms = terms?.find(termsItem => termsItem.name === TermsNames.MARKETING_CONSENTS)
            const termsToSave = []

            if (termsOfUse) {
                termsToSave.push({
                    isChecked: true,
                    id: termsOfUse.id,
                })
            }

            if (marketingTerms) {
                termsToSave.push({
                    isChecked: newsletterAccepted,
                    id: marketingTerms.id,
                })
            }

            return acceptUserTerms(termsToSave, isPasswordAccountActivated ? user.email : null)
                .then(() => {
                    setTermsStatus(TermsAcceptStatus.Accepted)
                })
                .catch((e: any) => {
                    log(e)
                    addError()
                })
        },
        [newsletterAccepted, terms, user, setTermsStatus, addError, isPasswordAccountActivated, log],
    )

    if (isUserAuthorized && termsStatus === TermsAcceptStatus.RequiredAction) {
        return (
            <Modal
                onClose={() => undefined}
                opened
                closeButton={false}
                dataTestId='accept-terms-modal'
                style={css`
                    a {
                        &:focus {
                            text-decoration: underline;
                        }
                    }
                `}
            >
                {isTermsUpdate ? (
                    <h5>We’ve Updated Our Terms</h5>
                ) : isVMCUser ? (
                    <h5>Welcome to the Vendor Management Cloud (VMC)</h5>
                ) : (
                    <h5>Welcome to Talent Alpha Human Cloud</h5>
                )}
                <p>
                    Before using the Platform, you need to agree to Talent Alpha's Terms. We ask that you also familiarize yourself with our{' '}
                    <Link
                        to='/documents/privacy-policy'
                        css={css`
                            text-decoration: underline;

                            &:hover {
                                text-decoration: underline;
                            }
                        `}
                        target='_blank'
                    >
                        Privacy Policy
                    </Link>
                    . If you choose to decline, you will be logged out of the Platform.
                </p>
                <form
                    css={css`
                        display: flex;
                        flex-direction: column;
                    `}
                    onSubmit={handleAccept}
                >
                    <Checkbox
                        name='terms'
                        checkboxLabel={
                            <Fragment>
                                I agree with{' '}
                                <Link
                                    css={css`
                                        text-decoration: underline;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    `}
                                    to='/documents/terms-of-use'
                                    target='_blank'
                                >
                                    Terms of Use
                                </Link>
                            </Fragment>
                        }
                        checked={termsAccepted}
                        onChange={setTermsAccepted}
                    />
                    {!isSpecialistAccount && (
                        <Checkbox
                            name='newsletter'
                            style={css`
                                margin-top: 6px;
                                margin-bottom: 6px;
                            `}
                            checkboxLabel='I want to sign up for the newsletter (optional)'
                            checked={newsletterAccepted}
                            onChange={setNewsletterAccepted}
                        />
                    )}
                    <Divider />
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <Button variant='text' type='button' onClick={handleDecline} dataTestId='terms-decline'>
                            Decline
                        </Button>
                        <Button disabled={!termsAccepted} type='submit' dataTestId='enter-application'>
                            Enter Application
                        </Button>
                    </div>
                </form>
            </Modal>
        )
    }

    return null
}

export { AcceptTerms }
