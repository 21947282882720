import { css, Global, SerializedStyles, useTheme } from '@emotion/react'
import 'antd/es/auto-complete/style/index.css'
import 'antd/es/checkbox/style/index.css'
import 'antd/es/date-picker/style/index.css'
import 'antd/es/dropdown/style/index.css'
import 'antd/es/form/style/index.css'
import 'antd/es/grid/style/index.css'
import 'antd/es/input/style/index.css'
import 'antd/es/menu/style/index.css'
import 'antd/es/popover/style/index.css'
import 'antd/es/progress/style/index.css'
import 'antd/es/radio/style/index.css'
import 'antd/es/rate/style/index.css'
import 'antd/es/select/style/index.css'
import 'antd/es/style/index.css'
import 'antd/es/table/style/index.css'
import 'antd/es/tag/style/index.css'
import 'antd/es/tooltip/style/index.css'
import 'antd/es/collapse/style/index.css'
import { Fragment } from 'react'
import { Theme } from '../../theme/theme'

const globalAntdStyles = (theme: Theme): SerializedStyles => css`
    // Datepicker Input

    .ant-picker {
        padding: 8px 11px;
        border-color: ${theme.colors.gray_2};
        transition-duration: 0ms;
        &:hover {
            border-color: ${theme.colors.gray_2};
        }
    }

    .ant-picker-cell .ant-picker-cell-inner,
    .ant-picker-cell .ant-picker-cell-disabled {
        min-width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 50%;
    }

    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: ${theme.colors.text};
        background: ${theme.colors.main};
    }

    .ant-picker-focused {
        color: ${theme.colors.gray_3};
        box-shadow: none;
        border-color: ${theme.colors.gray_3};
        &:hover {
            color: ${theme.colors.gray_3};
            border-color: ${theme.colors.gray_3};
        }
    }

    .ant-picker-today-btn {
        color: ${theme.colors.gray_5};
        cursor: pointer;
        &:hover {
            color: ${theme.colors.gray_5};
        }
    }

    .ant-picker-cell::before {
        height: 32px;
    }
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-radius: 50%50%;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-color: ${theme.colors.main};
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
        background-color: ${theme.colors.main};
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
        color: ${theme.colors.text};
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
    .ant-picker-panel
        > :not(.ant-picker-date-panel)
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
    .ant-picker-panel
        > :not(.ant-picker-date-panel)
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
    .ant-picker-date-panel
        .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
        .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
        background: ${theme.colors.main_lighter};
    }
    .ant-picker-dropdown {
        z-index: 1111;
    }

    // Range Picker

    .ant-picker-range .ant-picker-active-bar {
        background-color: ${theme.colors.gray_3};
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
        color: ${theme.colors.white};
        background: ${theme.colors.gray_5};
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
        .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
        .ant-picker-cell-inner {
        border-radius: 50%;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end),
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end),
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range) {
        &::after {
            position: absolute;
            top: 50%;
            z-index: 0;
            height: calc(100% - 6px);
            border-top: none;
            border-bottom: none;
            border: none;
            transform: translateY(-50%);
            content: '';
        }
    }

    .ant-picker-cell-range-hover-start::after,
    .ant-picker-cell-range-hover-end::after,
    .ant-picker-cell-range-hover::after {
        right: 0;
        left: 0;
    }

    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-of-type::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-of-type::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
        left: 0; //6px;
        border-left: none;
    }

    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
    tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
        right: 0;
        border-right: none;
    }

    .ant-picker-header-view button:hover {
        color: ${theme.colors.gray_5};
    }

    .ant-picker-month-panel .ant-picker-cell-inner,
    .ant-picker-quarter-panel .ant-picker-cell-inner,
    .ant-picker-year-panel .ant-picker-cell-inner {
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-transform: none;
    }

    .ant-picker-decade-panel .ant-picker-cell-inner {
        padding: 0 4px;
        height: 78px;
        line-height: 78px;
    }

    // Tooltip

    .ant-tooltip-arrow-content {
        background-color: ${theme.colors.gray_6};
    }

    .ant-tooltip-inner {
        border-radius: 0;
        background-color: ${theme.colors.gray_6};
    }
    .ant-tooltip-image {
        .ant-tooltip-inner {
            padding: 1px;
            background-color: ${theme.colors.white};
            border-radius: 2px;
        }
        .ant-tooltip-arrow-content {
            background-color: ${theme.colors.white};
        }
    }

    // Dropdown

    .ant-dropdown-menu-item-group-list,
    .ant-dropdown-menu {
        margin: 0;
        padding: 0;
    }

    .ant-dropdown-menu-submenu-title,
    .ant-dropdown-menu-item {
        line-height: 28px;
        font-size: 14px;
        border-left: 3px solid transparent;
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background-color: transparent;
        border-left: 3px solid ${theme.colors.main};
    }
    .ant-dropdown-menu-submenu-expand-icon {
        display: none;
    }

    // Radio

    .ant-radio {
        top: 0;
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: ${theme.colors.gray_5};
        &:after {
            background-color: ${theme.colors.gray_5};
        }
    }
    .ant-radio-checked::after {
        border: 1px solid ${theme.colors.gray_5};
    }

    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${theme.colors.gray_5};
    }
    .ant-radio-input:focus + .ant-radio-inner {
        box-shadow: 0 0 0 3px rgb(0 0 0 / 8%);
    }
    .ant-radio-group label {
        display: inline-flex;
        align-items: center;
    }

    .ant-radio-button-wrapper {
        border: 1px solid ${theme.colors.violet_2};
        border-radius: 2px;
        box-shadow: ${theme.shadow.small};
        color: ${theme.colors.gray_6};
        margin-right: 8px;
        height: 40px;
        &::before {
            display: none;
        }
        &:first-of-type {
            border-left: 1px solid ${theme.colors.violet_2};
            border-radius: 2px;
        }
        &:last-child {
            border-radius: 2px;
        }
        &:not(:first-of-type)::before {
            display: none;
        }
        &:hover {
            color: ${theme.colors.gray_6};
            border: 1px solid ${theme.colors.violet_3};
        }
        &:focus-within {
            box-shadow: ${theme.shadow.small};
        }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background-color: ${theme.colors.violet_3};
        border: 1px solid ${theme.colors.violet_3};
        box-shadow: ${theme.shadow.small};
        color: ${theme.colors.gray_6};
        z-index: 0;

        &:first-of-type {
            border-color: ${theme.colors.violet_3};
        }

        &::before {
            display: none;
        }

        &:hover {
            color: ${theme.colors.gray_6};
            border-color: ${theme.colors.violet_3};
        }

        &:focus-within {
            box-shadow: ${theme.shadow.small};
        }
    }

    .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-of-type {
        border-right-color: ${theme.colors.violet_3};
    }

    // Table

    .ant-table-thead > tr > th {
        font-weight: 400;
        color: ${theme.colors.gray_4};
    }

    .ant-table table {
        border-top: 1px solid ${theme.colors.gray_2};
        height: 1px;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: transparent;
    }

    .ant-table-tbody > tr.ant-table-row.ant-table-row-blue {
        > td {
            background: rgba(0, 133, 255, 0.07);
            border-color: rgba(0, 0, 0, 0.03);
        }

        &:hover {
            > td {
                background: rgba(0, 133, 255, 0.07);
            }
        }
    }
    .ant-table-tbody > tr.ant-table-row.ant-table-row.ant-table-row-red {
        > td {
            background: rgba(255, 92, 92, 0.07);
            border-color: rgba(0, 0, 0, 0.03);
        }

        &:hover {
            > td {
                background: rgba(255, 92, 92, 0.07);
            }
        }
    }
    .ant-table-row-disabled {
        > td {
            color: ${theme.colors.gray_3};
        }
    }
    .ant-table-tbody > tr.ant-table-row.ant-table-row-green {
        > td {
            background: rgba(23, 92, 92, 0.07);
            border-color: rgba(0, 0, 0, 0.03);
        }

        &:hover {
            > td {
                background: rgba(23, 92, 92, 0.07);
            }
        }
    }
    .ant-table-tbody > tr.ant-table-row.ant-table-row-yellow {
        > td {
            background-color: rgba(231, 231, 57, 0.07);
            border-color: rgba(0, 0, 0, 0.03);
        }
        &:hover {
            > td {
                background-color: rgba(231, 231, 57, 0.07);
            }
        }
    }

    .ant-table table > tbody tr:hover {
        cursor: pointer;
        box-shadow: none;
        background-color: ${theme.colors.violet_1};
    }

    .anticon.anticon-caret-up.ant-table-column-sorter-up.active {
        color: ${theme.colors.gray_5};
    }

    .anticon.anticon-caret-down.ant-table-column-sorter-down.active {
        color: ${theme.colors.gray_5};
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background: ${theme.colors.gray_1};
    }

    .ant-table-thead > tr > th {
        background: ${theme.colors.gray_1};
    }

    td.ant-table-column-sort {
        background: initial;
    }
    .ant-table-thead th.ant-table-column-sort {
        background: ${theme.colors.gray_1};
    }

    table {
        .ant-checkbox-inner {
            border-radius: 0;
        }
        .ant-checkbox:hover .ant-checkbox-inner {
            border-color: ${theme.colors.gray_6};
        }
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: ${theme.colors.gray_6};
        }
        .ant-checkbox-checked {
            .ant-checkbox-indeterminate {
                background-color: ${theme.colors.gray_6};
                border-color: ${theme.colors.gray_6};
            }
            .ant-checkbox-inner {
                background-color: ${theme.colors.gray_6};
                border-color: ${theme.colors.gray_6};
            }
            &::after {
                border-color: ${theme.colors.gray_6};
            }
        }
        .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: ${theme.colors.gray_6};
        }
    }

    @keyframes animate-created-row {
        0% {
            background: ${theme.colors.blue_2};
        }
        100% {
            background: transparent;
        }
    }

    .ant-table-row-created {
        > td {
            animation-name: animate-created-row;
            animation-duration: 1s;
        }
        > td:first-of-type {
            border-left: 5px solid ${theme.colors.blue_4};
        }
    }

    // Popover

    .ant-popover,
    .ant-popover-inner-content {
        margin: 0;
        padding: 0;
    }

    // Autocomplete

    .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
        height: 38px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 40px;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border-color: ${theme.colors.gray_3};
        box-shadow: none;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border-color: ${theme.colors.gray_3};
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 40px;
        padding: 0 16px;
        color: ${theme.colors.gray_5};
    }

    .ant-select-selection-search-input > .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${theme.colors.gray_1};
    }

    // SELECT

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        font-weight: 400;
    }

    .autocomplete > .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${theme.colors.violet_1};
    }

    .ant-select-selection-item-remove {
        padding-left: 4px;
    }

    .ant-select-multiple .ant-select-selection-item {
        background-color: ${theme.colors.violet_2};
        border-color: ${theme.colors.violet_2};
        line-height: 32px;
        height: 32px;
        padding: 0 8px;
    }

    //   SELECT DROPDOWN

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${theme.colors.violet_1};
    }

    .ant-select-item .ant-select-item-option .ant-select-item-option-content {
        height: 42px;
    }
    .ant-select-item-option {
        margin-top: 1px;
        line-height: 38px;
    }
    .ant-select-dropdown {
        z-index: 1111;
    }

    // TAG

    .ant-tag {
        background-color: ${theme.colors.violet_2};
        border-color: ${theme.colors.violet_2};
        line-height: 32px;
        height: 32px;
        padding: 0 8px;
        margin: 2px;
        font-size: 14px;
    }

    // Form

    .ant-form-item {
        padding: 0;
        color: rgba(19, 16, 13, 0.65);
        font-size: 14px;
        line-height: 1.5715;
        margin: 0 0 16px;
        vertical-align: top;
    }

    .ant-col-24.ant-form-item-label > label,
    .ant-col-xl-24.ant-form-item-label > label,
    .ant-form-vertical .ant-form-item-label > label {
        margin: 0 0 4px;
        line-height: 1.5715;
    }

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding: 0;
    }

    .ant-form-item-label > label {
        position: relative;
        display: inline-flex;
        align-items: center;
        height: 20px;
        color: ${theme.colors.gray_4};
        font-size: 14px;
    }

    // Input

    .ant-input {
        box-sizing: border-box;
        margin: 0;
        width: 100%;
        min-width: 0;
        color: ${theme.colors.gray_5};
        font-size: 14px;
        line-height: 1.5715;
        border: 1px solid ${theme.colors.gray_2};
        border-radius: 2px;
        height: 40px;
        padding: 0 16px;
    }

    .ant-input-focused,
    .ant-input:focus {
        border-color: ${theme.colors.gray_3};
        outline: 0;
        box-shadow: none;
    }

    .ant-input:hover {
        border-color: ${theme.colors.gray_3};
    }

    .ant-table-thead > tr > th {
        background-color: transparent;
    }

    .ant-table-thead th.ant-table-column-has-sorters:hover {
        background-color: transparent;
    }

    .ant-table table {
        border-top: 0;
    }
`

const GlobalAntdStylesProvider = ({ children }: { children: any }): any => {
    const theme = useTheme()

    return (
        <Fragment>
            <Global styles={globalAntdStyles(theme)} />
            {children}
        </Fragment>
    )
}

export { GlobalAntdStylesProvider, globalAntdStyles }
