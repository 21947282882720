/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useInterval } from 'react-use'
import { getSpecialistCvParsingStatus, getSpecialistParsedCvs, removeSpecialistCv, uploadSpecialistCv } from '../../../../api/api'
import { CvDropzone } from '../../../../components/inputs/CvDropzone'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { SpecialistCv, SpecialistCvStatus, SpecialistParsedCv } from '../../../../contracts/cvs'
import { CvData } from '../../../../contracts/specialist/specialistCv'
import { ReduxContext } from '../../../../redux/Store'
import { Nullable, NullableArray } from '../../../../types'
import { useLogger } from '../../../../utils/useLogger'
import { deleteCv, uploadCv } from '../../api'

const useCvUpload = (specialistId: string, setCvFile: Dispatch<SetStateAction<Nullable<CvData>>>) => {
    const {
        selectors: {
            featureFlags: { managerAiCvParsing },
        },
    } = useContext(ReduxContext)
    const [value, setValue] = useState('')
    const [isCvLoading, setIsCvLoading] = useState(false)
    const [cvParsingStatus, setCvParsingStatus] = useState<NullableArray<SpecialistCv>>(null)
    const fileInput = useRef<HTMLInputElement>(null)
    const { addSuccess, addError } = useNotifications()
    const log = useLogger('error')

    const handleCvUpload = useCallback(() => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }, [])

    const fetchSpecialistCvParsingStatus = useCallback(() => {
        getSpecialistCvParsingStatus(specialistId)
            .then(setCvParsingStatus)
            .catch(err => {
                log(err)
                addError()
            })
    }, [specialistId, log, addError])

    const handleDeleteCv = useCallback(() => {
        setIsCvLoading(true)
        const request: any = managerAiCvParsing ? removeSpecialistCv : deleteCv
        const args = [specialistId]
        if (managerAiCvParsing && cvParsingStatus?.[0]) {
            args.push(cvParsingStatus[0].cvParsingId)
        }
        request(...args)
            .then(() => {
                addSuccess('CV removed successfully.')
                setCvFile({ downloadUrl: null, fileName: null, id: null, specialistId: null, uploadedAt: null })
                fetchSpecialistCvParsingStatus()
            })
            .catch(() => addError())
            .finally(() => {
                setIsCvLoading(false)
            })
    }, [addError, addSuccess, cvParsingStatus, fetchSpecialistCvParsingStatus, managerAiCvParsing, setCvFile, specialistId])

    const handleDrop = useCallback(
        (files: File | Array<File> | null) => {
            let uploadedFile: File
            const cvData = new FormData()

            if (files) {
                uploadedFile = Array.isArray(files) ? files[0] : files
                cvData.append('file', uploadedFile)
            }

            setIsCvLoading(true)
            const request = managerAiCvParsing ? uploadSpecialistCv : uploadCv
            request(cvData, specialistId)
                .then(response => {
                    if (managerAiCvParsing) {
                        fetchSpecialistCvParsingStatus()
                    } else {
                        addSuccess('CV uploaded successfully.')
                        setCvFile(response as CvData)
                    }
                })
                .catch(err => {
                    if (err.status === 400) {
                        addError('Invalid CV format.')
                    } else {
                        log(err)
                        addError()
                    }
                })
                .finally(() => {
                    setIsCvLoading(false)
                    setValue('')
                })
        },
        [managerAiCvParsing, specialistId, fetchSpecialistCvParsingStatus, addSuccess, setCvFile, addError, log],
    )

    const onChangeFile = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation()
            event.preventDefault()

            if (event.target.files) {
                setValue(event.target.value)
            }

            handleDrop(event.target.files ? event.target.files[0] : null)
        },
        [handleDrop],
    )

    const CvUploadInput = useMemo(
        () => (
            <input
                type='file'
                id='file'
                accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ref={fileInput}
                onChange={onChangeFile}
                value={value}
                data-testid='upload-cv-input'
                css={css`
                    display: none;
                `}
            />
        ),
        [onChangeFile, value],
    )

    const CvUploadDropZone = useMemo(() => <CvDropzone handleDrop={handleDrop} isUploading={isCvLoading} />, [handleDrop, isCvLoading])

    useEffect(() => {
        fetchSpecialistCvParsingStatus()
    }, [fetchSpecialistCvParsingStatus])

    const [specialistParsedCvs, setSpecialistParsedCvs] = useState<Array<SpecialistParsedCv>>([])

    useEffect(() => {
        getSpecialistParsedCvs(specialistId).then(setSpecialistParsedCvs).catch(log)
    }, [log, specialistId])

    useInterval(
        () => {
            fetchSpecialistCvParsingStatus()
        },
        cvParsingStatus?.some(status => status.status === SpecialistCvStatus.IN_PROGRESS) ? 5000 : null,
    )

    return { handleCvUpload, handleDeleteCv, CvUploadInput, CvUploadDropZone, cvParsingStatus, specialistParsedCvs, isCvLoading }
}

export { useCvUpload }
