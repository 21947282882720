import { Collapse } from 'antd'

import { StyledSectionHeader, StyledCollapse } from './styles'
import { useHandleQuestionClick } from './hooks'
import { SUBSCRIPTION } from './consts'

const SubscriptionFAQ = ({ setQuestion, question }: { setQuestion: (q: string) => void; question: string | undefined }) => {
    const onQuestionClick = useHandleQuestionClick(setQuestion)

    return (
        <StyledCollapse defaultActiveKey={question} onChange={onQuestionClick}>
            <Collapse.Panel
                key={SUBSCRIPTION.BENEFITS}
                header={<StyledSectionHeader>What are the benefits of subscribing to the Premium service? </StyledSectionHeader>}
            >
                <p>When you become a Premium user, you get the following benefits:</p>
                <ul>
                    <li>
                        Early access to opportunities. New opportunities are only open to Premium users for the first 48 hours. Hot
                        opportunities are usually filled within the first couple days, so early access can boost your chance of winning a
                        project.
                    </li>
                    <li>
                        Automatic email alerts about new matching opportunities. If a new opportunity matches the Core Skills and Specialist
                        Locations of your company’s profile, we let you know as soon as it’s posted.
                    </li>
                    <li>
                        CV management (free anonymization of specialist CVs). Anonymizing the CVs on Talent Marketplace helps reduce human
                        bias. It also speeds up the process at our end, which means quicker candidate matching and sharing.
                    </li>
                </ul>
            </Collapse.Panel>
            <Collapse.Panel
                key={SUBSCRIPTION.MANAGER_SUBSCRIPTION}
                header={<StyledSectionHeader>Does every user at my company need to subscribe to get the benefits?</StyledSectionHeader>}
            >
                <p>
                    No, the subscription is purchased for the company account. This means you can invite as many Functional Managers as you
                    need within a single subscription.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={SUBSCRIPTION.CANCEL_SUBSCRIPTION}
                header={<StyledSectionHeader>Can I cancel my subscription?</StyledSectionHeader>}
            >
                <p>
                    You can cancel your subscription at any time and you won’t be charged at the start of the next billing cycle. Your
                    subscription will stay active until the end of your current billing cycle. You will not be refunded for the remaining
                    time of your subscription usage.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={SUBSCRIPTION.PAYMENT_INFO_PROCESSING}
                header={<StyledSectionHeader>How does Talent Alpha store and process my payment info?</StyledSectionHeader>}
            >
                <p>
                    Talent Alpha partners with Stripe for payment processing. We have no access to your payment information, card data or
                    any other information you provide to Stripe when making a payment.
                </p>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export { SubscriptionFAQ }
