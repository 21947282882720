/** @jsxImportSource @emotion/react */

import { COLOR_PALETTE } from '../theme/colors'
import { Awards } from './Awards'
import { Clouds } from './Clouds'

const AwardsBox = () => {
    return (
        <Clouds color={COLOR_PALETTE.violet_1}>
            <Awards />
        </Clouds>
    )
}

export { AwardsBox }
