import styled from '@emotion/styled'
import { Button } from '../Button'
import { css } from '@emotion/react'
import { ApplicationStatusColors, ApplicationStatusEnum } from 'src/contracts/opportunities-manager/contracts'

const StyledButton = styled(Button)`
    padding: 0;
`

const StyledStatus = styled.div<{ colors: ApplicationStatusColors; count: number; index: number; status: ApplicationStatusEnum }>`
    padding: 5px 6px;
    margin-top: 16px;
    margin-left: ${({ count, index }) => count > 1 && index === count - 1 && '4px'};
    margin-right: ${({ count, index }) => (index < count - 1 ? '16px' : '0')};
    color: ${({ colors, status }) => colors[status].color};
    background-color: ${({ colors, status }) => colors[status].backgroundColor};
    position: relative;

    ${({ colors, count, index, status }) =>
        index < count - 1
            ? css`
                  &:after {
                      content: '';
                      top: 0;
                      right: -8px;
                      bottom: 0;
                      width: 8px;
                      border-left: 8px solid ${colors[status].backgroundColor};
                      border-right: 0;
                      border-bottom: 15px solid transparent;
                      border-top: 15px solid transparent;
                      display: inline-block;
                      position: absolute;
                  }
              `
            : count !== 1 &&
              css`
                  &:before {
                      content: '';
                      top: 0;
                      left: -8px;
                      bottom: 0;
                      width: 8px;
                      border-left: 8px solid transparent;
                      border-bottom: 15px solid ${colors[status].backgroundColor};
                      border-top: 15px solid ${colors[status].backgroundColor};
                      display: inline-block;
                      position: absolute;
                  }
              `}
`

const RejectionReason = styled.div<{ colors: ApplicationStatusColors; backgroundColor: string }>`
    padding: 8px;
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.gray_6};
    background-color: ${({ backgroundColor }) => backgroundColor};
    white-space: pre-wrap;
`

export { StyledButton, StyledStatus, RejectionReason }
