import { SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'

const Container = styled.div<{ containerStyles?: SerializedStyles }>`
    height: 75px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    box-shadow: ${({ theme }) => theme.shadow.bottomBar};
    border-top: 1px solid ${({ theme }) => theme.colors.gray_2};
    ${({ containerStyles }) => containerStyles};
`

const Wrapper = styled.div<{ styles?: SerializedStyles }>`
    max-width: 1440px;
    width: 100%;
    ${({ styles }) => styles}
`

export { Container, Wrapper }
