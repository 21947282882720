/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../components/Button'
import { Dropzone } from '../../components/inputs/Dropzone'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { OverlayContentLoader } from '../../components/layout/Loader'

type ApplicationCvDropzoneProps = {
    handleApplicationCvChange: (files: Array<File> | File) => void
    isSendingCv: boolean
    error: boolean
}

const ApplicationCvDropzone: FunctionComponent<React.PropsWithChildren<ApplicationCvDropzoneProps>> = ({
    handleApplicationCvChange,
    isSendingCv,
    error,
}) => {
    const theme = useTheme()

    return (
        <FieldWrapper>
            <Dropzone
                accept={{ 'application/pdf': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] }}
                dataTestId='application-cv-dropzone'
                onChange={handleApplicationCvChange}
                styles={css`
                    background-color: ${error ? theme.colors.red_1 : theme.colors.violet_1};
                    border-color: ${error ? theme.colors.red_4 : theme.colors.violet_3};
                    :hover {
                        border-color: ${error ? theme.colors.red_4 : theme.colors.violet_3};
                    }
                `}
                dragActiveStyles={css`
                    background-color: ${theme.colors.violet_2};
                `}
                dropzoneContent={
                    <FlexBox
                        css={css`
                            padding: 16px;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                        `}
                    >
                        {isSendingCv && <OverlayContentLoader />}
                        <h6
                            css={
                                error
                                    ? css`
                                          color: ${theme.colors.red_4};
                                      `
                                    : undefined
                            }
                        >
                            {error ? 'CV is required' : 'Upload CV'}
                        </h6>
                        <p
                            css={css`
                                color: ${theme.colors.gray_4};
                                max-width: 315px;
                            `}
                        >
                            Please remove any personal information, such as candidate’s name, from within the CV. Our team will anonymize
                            the file name for you.
                        </p>
                        <Button size='small' variant='secondary'>
                            Browse Files
                        </Button>
                    </FlexBox>
                }
            />
        </FieldWrapper>
    )
}
export { ApplicationCvDropzone }
