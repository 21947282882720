/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Icon, IconButton } from '../../../components/Icon'
import { InfoAlert } from '../../../components/InfoAlert'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Spinner } from '../../../components/layout/Loader'
import { SpecialistCvStatus } from '../../../contracts/cvs'
import { CvData } from '../../../contracts/specialist/specialistCv'
import { Nullable } from '../../../types'
import { convertTimestampToDateString } from '../../../utils/dates'
import { DeleteConfirmationModal } from './DeleteConfirmationModal'
import { useCvUpload } from './side-menu/useCvUpload'

type SpecialistFilesFormProps = {
    setCvFile: Dispatch<SetStateAction<Nullable<CvData>>>
    specialistId: string
}

const Box = styled.div`
    border: 1px solid ${p => p.theme.colors.gray_2};
    padding: 24px;
`

const SpecialistFilesForm: FunctionComponent<React.PropsWithChildren<SpecialistFilesFormProps>> = ({ setCvFile, specialistId }) => {
    const theme = useTheme()

    const { CvUploadDropZone, CvUploadInput, specialistParsedCvs, cvParsingStatus, handleCvUpload, handleDeleteCv } = useCvUpload(
        specialistId,
        setCvFile,
    )

    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    const showModal = useCallback(() => setModalVisible(true), [])

    const handleRejection = useCallback(() => setModalVisible(false), [])

    const handleConfirmation = useCallback(() => {
        handleDeleteCv()
        setModalVisible(false)
    }, [handleDeleteCv])

    return (
        <Fragment>
            <h5>Files</h5>
            <InfoAlert
                description='You can upload CV to the profile. It can be used to apply to opportuntes, extract data'
                styles={css`
                    background-color: ${theme.colors.beige_2};
                    margin-bottom: 32px;
                `}
            />
            <Box
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <h6>Upload CV</h6>
                {cvParsingStatus && cvParsingStatus[0].cvDownloadUrl ? (
                    <Fragment>
                        {CvUploadInput}
                        <FlexBox
                            justifyContent='space-between'
                            alignItems='center'
                            css={css`
                                width: 100%;
                            `}
                        >
                            <a href={cvParsingStatus[0].cvDownloadUrl || ''} download target='_blank' rel='noopener noreferrer'>
                                <div
                                    css={css`
                                        font-weight: 500;
                                        color: ${theme.colors.violet_5};
                                    `}
                                >
                                    Preview file
                                </div>
                            </a>
                            {cvParsingStatus && cvParsingStatus[0].status !== SpecialistCvStatus.IN_PROGRESS && (
                                <FlexBox alignItems='center'>
                                    <Button
                                        variant='text'
                                        css={css`
                                            padding: 0;
                                            margin-right: 8px;
                                            color: ${theme.colors.violet_5};
                                        `}
                                        onClick={handleCvUpload}
                                    >
                                        Replace
                                    </Button>
                                    <IconButton name='trash' innerSize={20} size={30} onClick={showModal} dataTestId='remove-cv-icon' />
                                </FlexBox>
                            )}
                        </FlexBox>

                        {cvParsingStatus && cvParsingStatus[0].status === SpecialistCvStatus.IN_PROGRESS ? (
                            <FlexBox
                                css={css`
                                    color: ${theme.colors.blue_5};
                                    width: 100%;
                                `}
                                alignItems='center'
                                justifyContent='space-between'
                            >
                                parsing in progress...
                                <div
                                    css={css`
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 20px;
                                        height: 20px;
                                    `}
                                >
                                    <Spinner circleWidth={2} sizeInner={12} sizeOuter={16} dataTestId='processing-cv-spinner' />
                                </div>
                            </FlexBox>
                        ) : (
                            <Fragment>
                                <div
                                    css={css`
                                        font-size: 12px;
                                        color: ${theme.colors.gray_3};
                                    `}
                                >
                                    Uploaded: {convertTimestampToDateString(cvParsingStatus[0].uploadedOn || 0)}
                                </div>
                                {cvParsingStatus[0].status === SpecialistCvStatus.READY_FOR_VERIFICATION && (
                                    <Link
                                        css={css`
                                            width: 100%;
                                        `}
                                        to={`/my-specialists/specialist/${specialistId}/finish-extracting/${cvParsingStatus[0].cvParsingId}`}
                                    >
                                        <FlexBox
                                            alignItems='center'
                                            justifyContent='space-between'
                                            css={css`
                                                position: relative;
                                                padding: 10px;
                                                width: 100%;
                                                margin-top: 16px;
                                                background-color: ${theme.colors.white};
                                                border: 1px solid ${theme.colors.gray_2};
                                                border-radius: 4px;
                                                box-shadow: ${theme.shadow.small};
                                                cursor: pointer;
                                            `}
                                            data-testid='finish-cv-parsing-link'
                                        >
                                            <FlexBox
                                                alignItems='center'
                                                css={css`
                                                    color: ${theme.colors.gray_3};
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        width: 26px;
                                                        height: 26px;
                                                        background-color: ${theme.colors.violet_2};
                                                        border-radius: 13px;
                                                        margin-right: 12px;
                                                    `}
                                                />
                                                Extract data from CV
                                            </FlexBox>
                                            <span
                                                css={css`
                                                    color: ${theme.colors.violet_5};
                                                `}
                                            >
                                                Finish extracting
                                                <Icon name='arrow-right-single' size={20} />
                                            </span>
                                        </FlexBox>
                                    </Link>
                                )}
                                {cvParsingStatus[0].status === SpecialistCvStatus.ERROR && (
                                    <FlexBox
                                        alignItems='center'
                                        justifyContent='space-between'
                                        css={css`
                                            position: relative;
                                            padding: 10px;
                                            width: 100%;
                                            margin-top: 4px;
                                            background-color: ${theme.colors.white};
                                            border-radius: 4px;
                                            box-shadow: ${theme.shadow.small};
                                            cursor: pointer;
                                        `}
                                    >
                                        <span
                                            css={css`
                                                color: ${theme.colors.red_5};
                                            `}
                                        >
                                            Retry
                                        </span>
                                    </FlexBox>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <Fragment>{CvUploadDropZone}</Fragment>
                )}
            </Box>

            {specialistParsedCvs.length > 0 && (
                <Box>
                    <h6>Files added by Specialist</h6>
                    <InfoAlert
                        description='Specialist can upload their own files from dashboard'
                        styles={css`
                            background-color: ${theme.colors.beige_2};
                            margin-bottom: 32px;
                        `}
                    />
                    {specialistParsedCvs.map((parsedCv, index) => (
                        <FlexBox
                            key={parsedCv.cvDownloadUrl}
                            justifyContent='space-between'
                            css={css`
                                width: 100%;
                                flex-direction: column;
                            `}
                        >
                            {index !== 0 && (
                                <Divider
                                    style={css`
                                        margin: 0;
                                    `}
                                />
                            )}
                            <a href={parsedCv.cvDownloadUrl} download target='_blank' rel='noopener noreferrer'>
                                <Tooltip title={parsedCv.fileName}>
                                    <div
                                        css={css`
                                            font-weight: 500;
                                            color: ${theme.colors.violet_5};
                                        `}
                                    >
                                        Preview file
                                    </div>
                                </Tooltip>
                            </a>
                            <div
                                css={css`
                                    font-size: 12px;
                                    color: ${theme.colors.gray_3};
                                `}
                            >
                                Uploaded: {convertTimestampToDateString(parsedCv.uploadedOn)}
                            </div>
                        </FlexBox>
                    ))}
                </Box>
            )}
            <DeleteConfirmationModal isVisible={isModalVisible} handleConfirmation={handleConfirmation} handleRejection={handleRejection} />
        </Fragment>
    )
}

export { SpecialistFilesForm }
