/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { SearchLocation } from '../../contracts/common/searchLocation'
import { OpportunityLocationTypeV2Enum } from '../../contracts/opportunities-manager/contracts'
import { Nullable } from '../../types'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { CountryCitiesList } from './CountryCitiesList'
import { OnsiteRemoteTag } from './OnsiteRemoteTag'
import { generateVisibleOnsiteValues, generateVisibleValues } from './utils'

type OpportunityLocationProps = {
    type: Nullable<OpportunityLocationTypeV2Enum>
    countriesCitiesList: Array<SearchLocation>
    regions: Array<string>
    countries: Array<string>
    styles?: SerializedStyles
}

const OpportunityLocation: FunctionComponent<React.PropsWithChildren<OpportunityLocationProps>> = ({
    type,
    countries,
    countriesCitiesList,
    regions,
    styles,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const theme = useTheme()
    const [visibleValues, setVisibleValues] = useState<{ content: ReactNode; numberOfNotVisibleValues: number }>({
        content: '',
        numberOfNotVisibleValues: 0,
    })

    const { width } = useWindowSize()

    const onsite = type && type !== OpportunityLocationTypeV2Enum.REMOTE

    const regionsCountriesList = useMemo(() => regions.concat(countries), [countries, regions])

    useEffect(() => {
        setVisibleValues(
            regionsCountriesList.length === 0
                ? { content: 'Anywhere', numberOfNotVisibleValues: 0 }
                : onsite
                ? generateVisibleOnsiteValues({
                      values: countriesCitiesList,
                      width: ref.current?.clientWidth || 0,
                  })
                : generateVisibleValues({
                      values: regionsCountriesList,
                      width: ref.current?.clientWidth || 0,
                  }),
        )
    }, [countriesCitiesList, onsite, regionsCountriesList, type, width])

    return (
        <FlexBox alignItems='center'>
            <OnsiteRemoteTag type={type} />
            <div
                css={css`
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;

                    ${styles}
                `}
                ref={ref}
            >
                {visibleValues.content}
                {visibleValues.numberOfNotVisibleValues > 0 && (
                    <Tooltip
                        title={
                            onsite ? (
                                <CountryCitiesList
                                    countryCitiesList={countriesCitiesList.slice(
                                        countriesCitiesList.length - visibleValues.numberOfNotVisibleValues,
                                    )}
                                />
                            ) : (
                                regionsCountriesList.slice(regionsCountriesList.length - visibleValues.numberOfNotVisibleValues).join(', ')
                            )
                        }
                    >
                        <span
                            css={css`
                                background-color: ${theme.colors.violet_3};
                                width: 25px;
                                height: 18px;
                                line-height: 18px;
                                text-align: center;
                                border-radius: 2px;
                                margin-left: 8px;
                                display: inline-block;
                                font-size: 12px;
                                cursor: pointer;
                            `}
                            data-testid='more-locations'
                        >
                            +{visibleValues.numberOfNotVisibleValues}
                        </span>
                    </Tooltip>
                )}
            </div>
        </FlexBox>
    )
}

export { OpportunityLocation }
