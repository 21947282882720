/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { DataNotFound } from 'src/components/DataNotFound'

const NoTMSpecialists = () => (
    <DataNotFound
        styles={css`
            margin-top: 10vh;

            h5 {
                max-width: 480px;
            }
        `}
        iconName='add'
        title={'Please add Specialists to My Specialists App before adding them to the Talent Marketplace App '}
        description={
            <span
                css={css`
                    display: block;
                    max-width: 440px;
                `}
            >
                After adding your Specialists to My Specialists App you will be able to make them visible for other Companies on the Talent
                Marketplace App.
            </span>
        }
    >
        <Link
            to='/my-specialists'
            css={css`
                &:hover {
                    text-decoration: none;
                }
            `}
        >
            <Button variant='primary'>Go to My Specialists</Button>
        </Link>
    </DataNotFound>
)

type SpecialistsResultsWrapperProps = {
    pending?: boolean
    total: number
    filtered: boolean
}

const SpecialistsResultsWrapper: FunctionComponent<React.PropsWithChildren<SpecialistsResultsWrapperProps>> = ({
    pending,
    total,
    filtered,
    children,
}) => (
    <div
        css={css`
            position: relative;
        `}
    >
        {!pending && !total && filtered && (
            <div
                css={css`
                    margin-top: 10vh;
                `}
            >
                <DataNotFound iconName='no-data' title='No Specialists Found' description='Try changing your filters or query' />
            </div>
        )}
        {!pending && !total && !filtered && <NoTMSpecialists />}
        {total > 0 && <div>{children}</div>}
        {pending && <OverlayContentLoader />}
    </div>
)

export { SpecialistsResultsWrapper }
