/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { DataNotFound } from '../../../components/DataNotFound'

type BookingsResultsWrapperProps = {
    pending?: boolean
    total: number
}

const BookingsResultsWrapper: FunctionComponent<React.PropsWithChildren<BookingsResultsWrapperProps>> = ({ pending, total, children }) => (
    <div
        css={css`
            position: relative;
            z-index: 2;
        `}
    >
        {!pending && !total && (
            <DataNotFound
                styles={css`
                    margin-top: 10vh;

                    h5 {
                        max-width: 450px;
                    }
                `}
                iconName='no-data'
                title={'No Booking Requests Sent Yet'}
                description={
                    <span
                        css={css`
                            display: block;
                            max-width: 450px;
                        `}
                    >
                        When you send a Booking Request for a Specialist, the request will appear in this Bookings section. Here you’ll be
                        able to track the status of your Booking Requests through to the start of your project.
                    </span>
                }
            />
        )}
        {total > 0 && <div>{children}</div>}
    </div>
)

export { BookingsResultsWrapper }
