/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, MouseEvent, useCallback, useContext, useState } from 'react'
import { AccessLevelContext } from '../../AccessLevelContext'
import { RoleType, SearchSpecialist, SearchSpecialistRole } from '../../contracts/search/searchSpecialist'
import { mqMax } from '../../GlobalStyle'
import { useIsMobile } from '../../utils/hooks'
import { TalentBadge } from '../Badge'
import { IconButton } from '../Icon'
import { TextHighlight } from '../TextHighlight'
import { TalentBoxLabelBlack, TalentBoxLabelGrey } from './TalentResultBox'

const TalentBoxRoleInfoRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;

    .talent-box-label {
        width: auto;
        padding-bottom: 0;
    }
`

export interface TalentBoxRolesInfoProps {
    talent: SearchSpecialist
    searchWords: Array<string>
    onToggleSavedProfile: (savedProfile: boolean, specialistId: string) => Promise<any>
    onlySavedProfiles: boolean
}

const TalentBoxRolesInfo: FunctionComponent<React.PropsWithChildren<TalentBoxRolesInfoProps>> = ({
    talent,
    searchWords,
    onToggleSavedProfile,
    onlySavedProfiles,
}) => {
    const [isSavedProfile, setIsSavedProfile] = useState(talent.savedProfile)

    const isMobile = useIsMobile()
    const theme = useTheme()

    const {
        talentMarketplace: { savedProfiles },
    } = useContext(AccessLevelContext)

    const handleToggleSavedProfile = useCallback(
        (e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            onToggleSavedProfile(!isSavedProfile, talent.specialistId).then(() => !onlySavedProfiles && setIsSavedProfile(!isSavedProfile))
        },
        [isSavedProfile, talent.specialistId, onToggleSavedProfile, onlySavedProfiles],
    )

    const renderRoleByType = (rolesInfo: Array<SearchSpecialistRole>, roleType: RoleType) => {
        const foundRole = rolesInfo.find(role => role.roleType === roleType)

        if (foundRole) {
            return (
                <Fragment>
                    {foundRole.roleType === RoleType.PRIMARY ? (
                        <TalentBoxLabelBlack className='talent-box-label'>
                            <TextHighlight searchWords={searchWords} text={foundRole.role} />
                        </TalentBoxLabelBlack>
                    ) : (
                        <TalentBoxLabelGrey className='talent-box-label'>
                            <TextHighlight searchWords={searchWords} text={foundRole.role} />
                        </TalentBoxLabelGrey>
                    )}
                    {foundRole.roleSeniority && (
                        <TalentBadge variant={foundRole.roleType === RoleType.PRIMARY ? 'primary' : 'tertiary'}>
                            {foundRole.roleSeniority}
                        </TalentBadge>
                    )}
                </Fragment>
            )
        }

        return roleType === RoleType.PRIMARY ? 'Primary role has not been set' : ''
    }

    if (talent) {
        return (
            <div
                css={css`
                    padding: 19px 16px 12px;
                    border-bottom: 1px solid ${theme.colors.gray_2};
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .talent-box-label {
                        margin-right: 10px;
                    }
                `}
            >
                <div>
                    <TalentBoxRoleInfoRow>{renderRoleByType(talent.rolesInfo, RoleType.PRIMARY)}</TalentBoxRoleInfoRow>
                    <span
                        css={css`
                            display: block;
                            font-size: 12px;
                            color: ${theme.colors.gray_4};
                        `}
                    >
                        {talent.nickname}
                    </span>
                </div>
                {savedProfiles && (
                    <div
                        className='contact-us-buttons'
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            position: absolute;
                            top: 0;
                            right: 0;
                            opacity: 1;
                            height: 100%;
                            padding: 0 16px;

                            ${mqMax[1]} {
                                opacity: 1;
                                justify-content: space-between;
                                width: 100%;
                                padding: 16px 0 4px;
                                position: relative;
                            }
                        `}
                    >
                        <Tooltip
                            trigger={isMobile ? 'focus' : 'hover'}
                            placement='topRight'
                            title={`${isSavedProfile ? 'Remove from Saved Profiles' : 'Add to Saved Profiles'}`}
                        >
                            <div>
                                <IconButton
                                    name='fav'
                                    variant='outlined'
                                    size={40}
                                    pressed={isSavedProfile}
                                    onClick={handleToggleSavedProfile}
                                    dataTestId={`saved-profile-icon${isSavedProfile ? '-saved' : ''}`}
                                />
                            </div>
                        </Tooltip>
                    </div>
                )}
            </div>
        )
    }

    return null
}

export { TalentBoxRolesInfo }
