/** @jsxImportSource @emotion/react */
import { css, ThemeProvider } from '@emotion/react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getUser } from '../../../../api/api'
import Hello from '../../../../assets/images/hello.svg'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { DashboardLayout } from '../../../../components/layout/dashboard/DashboardLayout'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { WhiteLabel } from '../../../../contracts/whiteLabel'
import { mqMax } from '../../../../GlobalStyle'
import { history } from '../../../../history'
import { ReduxContext } from '../../../../redux/Store'
import { generateTheme, Theme } from '../../../../theme/theme'
import { Nullable } from '../../../../types'
import { useLogger } from '../../../../utils/useLogger'
import { checkInvitationStatus, confirmInvitation } from '../../api'

const JoinCompanyPage = () => {
    const {
        selectors: { user },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { prospectId } = useParams<{ prospectId: string }>()
    const { addSuccess, addError } = useNotifications()
    const log = useLogger()
    const [canShowContent, setCanShowContent] = useState(false)
    const [localWhiteLabel, setLocalWhiteLabel] = useState<Nullable<WhiteLabel>>(null)

    useEffect(() => {
        if (prospectId) {
            checkInvitationStatus(prospectId)
                .then(({ whiteLabel }) => {
                    setLocalWhiteLabel(whiteLabel)
                    setCanShowContent(true)
                })
                .catch(error => {
                    log(error)
                    history.push('/invitation-expired')
                })
        }
    }, [log, prospectId])

    const handleConfirmation = useCallback(() => {
        if (prospectId) {
            layoutToggleLoader(true)

            confirmInvitation(prospectId)
                .then(data => {
                    if (data) {
                        getUser(user.firebaseId)
                            .then(userData => {
                                history.push(
                                    `/refresh-user-context?wantedContextId=${data.contextId}${
                                        userData.firstName
                                            ? ''
                                            : '&afterSuccess=/dashboard/my-account/personal-details?afterSuccess=/dashboard'
                                    }`,
                                )
                            })
                            .catch(error => {
                                log(error)
                                addError()
                            })
                            .finally(() => {
                                layoutToggleLoader(false)
                            })
                        addSuccess('Invitation confirmed successfully!')
                    } else {
                        layoutToggleLoader(false)
                    }
                })
                .catch(error => {
                    log(error)
                    addError()
                    layoutToggleLoader(false)
                })
        }
    }, [addError, addSuccess, layoutToggleLoader, log, prospectId, user.firebaseId])

    const theme = useMemo<Theme>(() => generateTheme(localWhiteLabel), [localWhiteLabel])

    return canShowContent ? (
        <ThemeProvider theme={theme}>
            <DashboardLayout showMenuItems={false} forcedLogoUrl={localWhiteLabel?.logoUrl || undefined}>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                        flex-direction: column;
                        margin: 0 auto 50px;
                        padding: 24px;
                        align-items: center;
                        text-align: center;
                        ${mqMax[1]} {
                            margin-top: 57px;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                            max-width: 430px;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                            color: ${theme.colors.gray_6};
                        `}
                    >
                        <img
                            src={Hello}
                            alt='Welcome drawing'
                            css={css`
                                margin-bottom: 48px;
                                padding-top: 25px;
                            `}
                        />
                        <h2
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            Welcome !
                        </h2>
                        <div
                            css={css`
                                font-size: 18px;
                                margin-bottom: 40px;
                            `}
                        >
                            It’s great to see you on Talent Alpha’s Platform
                        </div>
                        <div
                            css={css`
                                font-size: 14px;
                                line-height: 22px;
                            `}
                        >
                            By clicking “Join Company” you will join your Company’s Talent Alpha workspace and get access to the Platform as
                            a Functional Manager. Please make sure to the same email address that you received invite on.
                        </div>
                        <Divider
                            style={css`
                                width: 50px;
                                margin: 40px 0;
                            `}
                        />

                        <Button
                            variant='primary'
                            onClick={handleConfirmation}
                            style={css`
                                font-size: 18px;
                                padding: 16px 57px;
                            `}
                        >
                            Join Company
                        </Button>
                    </div>
                </div>
            </DashboardLayout>
        </ThemeProvider>
    ) : null
}

export { JoinCompanyPage }
