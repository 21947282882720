/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import * as d3 from 'd3'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { ReduxContext } from '../../redux/Store'
import { StringMapping } from '../../types'
import { Button } from '../Button'
import { NoData } from '../NoData'
import { BreadCrumbs } from './BreadCrumbs'
import { Chart } from './Chart'
import { BreadCrumbsDataShape, Node, SkillGraphProps } from './types'
import { parseSkillMatrix, taPallette } from './utils'
import { useNotifications } from '../notification/NotificationProvider'
import { useLogger } from 'src/utils/useLogger'
import { downloadAnonymizedCV } from 'src/api/api'
import { CVButton, CVContainer, StyledLink } from '../shared/shared-styles'
import { Icon } from '../Icon'
import { saveAs } from 'file-saver'
import { fileTypeToExtension } from 'src/utils/values'

const SkillGraph: FunctionComponent<React.PropsWithChildren<SkillGraphProps>> = ({
    skillMatrix,
    rootName,
    id,
    hasAnonymizedCv,
    filename,
}) => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const [breadCrumbsData, setBreadcrumbsData] = useState<BreadCrumbsDataShape>({ nodeArray: [], percentageString: '' })
    const [baseColors, setBaseColors] = useState<StringMapping>({})
    const data = useMemo(() => parseSkillMatrix(skillMatrix, rootName), [skillMatrix, rootName])

    const color = d3
        .scaleOrdinal<string, string>()
        .range(d3.quantize(d3.interpolateRgbBasis(taPallette), (data?.children?.length || 1) + 1))

    const handleMouseLeave = () => {
        setBreadcrumbsData({ nodeArray: [], percentageString: '' })
        d3.select(`#${id}-trail`).style('visibility', 'hidden')
    }

    const nodeColor = useCallback(
        (node: Node) => {
            const depth = node.depth
            while (node.depth > 1 && node.parent) {
                node = node.parent
            }

            let baseColor = baseColors[node.data.name]

            if (!baseColor) {
                baseColor = color(node.data.name)
                setBaseColors(prev => ({ ...prev, [node.data.name]: baseColor }))
            }

            const labColor = d3.lab(d3.color(baseColor) as d3.RGBColor)
            labColor.a += Math.min(depth * 15, 160)
            labColor.b += Math.min(depth * 15, 160)

            return labColor.rgb().toString()
        },
        [baseColors, color],
    )

    const { addError } = useNotifications()
    const log = useLogger()

    const handleCVDownload = useCallback(() => {
        downloadAnonymizedCV(id)
            .then(data => {
                saveAs(data, `${filename}${fileTypeToExtension(data.type)}`)
            })
            .catch(err => {
                addError()
                log(err)
            })
    }, [id, filename, addError, log])

    return data ? (
        <div
            onMouseLeave={handleMouseLeave}
            css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
            `}
        >
            <BreadCrumbs {...{ nodeColor, breadCrumbsData, id }} />
            <Chart {...{ data, setBreadcrumbsData, nodeColor, id }} />
        </div>
    ) : (
        <CVContainer alignItems='center'>
            {!decodedAccessToken?.specialistId && (
                <Fragment>
                    {hasAnonymizedCv ? (
                        <Fragment>
                            <Icon name='cv' size={24} />
                            <CVButton onClick={handleCVDownload}>Download CV</CVButton>
                        </Fragment>
                    ) : (
                        <NoData />
                    )}
                </Fragment>
            )}
            {decodedAccessToken?.specialistId && (
                <Fragment>
                    <NoData />
                    <StyledLink to={'/specialist-skills-edit'}>
                        <Button>Add Skills</Button>
                    </StyledLink>
                </Fragment>
            )}
        </CVContainer>
    )
}

export { SkillGraph }
