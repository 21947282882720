/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { mqMax } from '../../../GlobalStyle'
import { OverlayContentLoader } from '../../layout/Loader'
import { DashboardLayout } from '../../layout/dashboard/DashboardLayout'
import { CompanyMembersResponse } from '../../../contracts/companyMembers'
import { useCompanyMembers } from '../../../hooks/useCompanyMembers'
import { Nullable } from '../../../types'
import { OpportunityResponseBody, UpdateOpportunityFormData } from '../../../contracts/opportunities-manager/contracts'
import { SMEmenuItems, menuItems } from '../utils/menuItems'
import { OpportunityForm } from './OpportunityForm'
import { VMCmenuItems } from 'src/components/open-opportunities/utils/openOpportunitiesUtils'

type OpportunityFormPageProps = {
    opportunity?: Nullable<OpportunityResponseBody>
    isSmeCloudApp: boolean
    isVMCCloudApp: boolean
    onSubmit: (
        formData: UpdateOpportunityFormData,
        companyMembers: Nullable<CompanyMembersResponse>,
    ) => Promise<OpportunityResponseBody | void>
}

const OpportunityFormPage: FunctionComponent<React.PropsWithChildren<OpportunityFormPageProps>> = ({
    opportunity,
    onSubmit,
    isSmeCloudApp,
    isVMCCloudApp,
}) => {
    const companyMembers = useCompanyMembers()
    const theme = useTheme()

    return (
        <DashboardLayout
            applicationName={isSmeCloudApp ? 'Opportunities Manager SME' : isVMCCloudApp ? 'Vacancies Manager' : 'Opportunities Manager'}
            applicationMenuItems={isSmeCloudApp ? SMEmenuItems : isVMCCloudApp ? VMCmenuItems : menuItems}
        >
            <div
                css={css`
                    background-color: ${theme.colors.beige_1};
                    padding: 24px;
                    ${mqMax[1]} {
                        padding: 24px 16px;
                    }
                `}
            >
                {opportunity !== null ? (
                    <OpportunityForm
                        opportunity={opportunity}
                        onSubmit={onSubmit}
                        companyMembers={companyMembers}
                        isSmeCloudApp={isSmeCloudApp}
                        isVMCCloudApp={isVMCCloudApp}
                    />
                ) : (
                    <OverlayContentLoader />
                )}
            </div>
        </DashboardLayout>
    )
}

export { OpportunityFormPage }
