/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { SelectDivider } from './styled'

type GroupNameProps = {
    name: string
    index: number
}
const GroupName: FunctionComponent<React.PropsWithChildren<GroupNameProps>> = ({ name, index }) => (
    <Fragment>
        {index !== 0 && <SelectDivider />}
        <div
            css={css`
                padding: 12px 18px;
                font-size: 12px;
                line-height: 16px;
            `}
        >
            {name}
        </div>
    </Fragment>
)

export { GroupName }
