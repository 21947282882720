/** @jsxImportSource @emotion/react */
import { useCallback, Fragment } from 'react'
import { saveAs } from 'file-saver'
import { Control } from 'react-hook-form'

import { useNotifications } from 'src/components/notification/NotificationProvider'
import { useLogger } from 'src/utils/useLogger'
import { getCvTemplate } from 'src/api/open-opportunities/api'
import { ControlledCheckbox } from 'src/components/forms/ControlledCheckbox'
import { UpdateApplicationRequestBody } from 'src/contracts/open-opportunities/contracts'

import { CustomCvTemplateBanner, DownloadCvTemplate, CustomCvTemplateCheckbox } from './styles'

interface OwnProps {
    control: Control<UpdateApplicationRequestBody>
}

const CustomCvTemplate: React.FC<OwnProps> = ({ control }) => {
    const { addError } = useNotifications()
    const log = useLogger()

    const downloadCvTemplate = useCallback(() => {
        getCvTemplate()
            .then(res => {
                // FIXME
                // BE returns filename in `content-disposition` header
                // but due to axios interceptor for response we don't have access to it
                // so for now name is hardcoded.
                saveAs(res, 'cv_template')
            })
            .catch(err => {
                addError()
                log(err)
            })
    }, [addError, log])

    return (
        <Fragment>
            <CustomCvTemplateBanner>
                This opportunity requires a custom CV. Please{' '}
                <DownloadCvTemplate variant='text' onClick={downloadCvTemplate}>
                    download the CV template
                </DownloadCvTemplate>{' '}
                and fill it in before uploading.
            </CustomCvTemplateBanner>
            <CustomCvTemplateCheckbox>
                <ControlledCheckbox
                    control={control}
                    name='useCvTemplate'
                    checkboxLabel="I've attached a completed copy of the special custom CV."
                />
            </CustomCvTemplateCheckbox>
        </Fragment>
    )
}

export { CustomCvTemplate }
