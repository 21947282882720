import { LanguageRequest } from 'src/contracts/profile/language'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { axios } from 'src/api/axios'
import {
    Application,
    ApplicationsFiltersResponse,
    ApplicationsParams,
    ApplicationWithdrawalParams,
    OpenOpportunitiesFiltersResponse,
    OpenOpportunitiesParams,
    OpportunityApplicationsResponse,
    RejectionReasons,
    SearchApplicationsResponse,
    SearchOpenOpportunitiesResponse,
    Specialist,
    UpdateApplicationRequestBody,
    UpdateApplicationSkillsRequestBody,
    OpportunityShort,
    InvitedCandidate,
} from '../../contracts/open-opportunities/contracts'

const opportunitiesPath = '/v4/opportunities'

const applyWithSpecialist = (opportunityId: string, specialistId: string): Promise<Application> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}:applyWithSpecialist`, { specialistId })
}

const applyWithCv = (opportunityId: string, data: FormData): Promise<Application> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}:applyWithCv`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

const getAllSpecialists = (opportunityId: string): Promise<{ availableSpecialists: Array<Specialist> }> => {
    return axios.get(`${opportunitiesPath}/${opportunityId}/applications:availableSpecialists`)
}

const getApplication = (applicationId: string): Promise<Application> => {
    return axios.get(`${opportunitiesPath}/-/applications/${applicationId}`)
}

const getApplicationsFilters = (): Promise<ApplicationsFiltersResponse> => {
    return axios.post(`${opportunitiesPath}/-/applications:filters`)
}

const getOpenOpportunitiesApplications = (id: string): Promise<OpportunityApplicationsResponse> => {
    return axios.get(`${opportunitiesPath}/${id}/applications`)
}

const getOpenOpportunitiesFilters = (): Promise<OpenOpportunitiesFiltersResponse> => {
    return axios.post(`${opportunitiesPath}/-/open:filters`)
}

const getOpportunityApplications = (opportunityId: string): Promise<{ applications: Array<Application> }> => {
    return axios.get(`${opportunitiesPath}/${opportunityId}/applications`)
}

const getRejectionReasons = (): Promise<RejectionReasons> => {
    return axios.get(`${opportunitiesPath}/-/applications/-/rejectionReasons`)
}

const removeApplication = (applicationId: string): Promise<void> => {
    return axios.delete(`${opportunitiesPath}/-/applications/${applicationId}`)
}

const searchApplications = (body: ApplicationsParams): Promise<SearchApplicationsResponse> => {
    return axios.post(`${opportunitiesPath}/-/applications:search`, body)
}

const searchOpenOpportunities = (body: OpenOpportunitiesParams): Promise<SearchOpenOpportunitiesResponse> => {
    return axios.post(`${opportunitiesPath}/-/open:search`, body)
}

const updateApplication = (applicationId: string, data: UpdateApplicationRequestBody): Promise<Application> => {
    return axios.put(`${opportunitiesPath}/-/applications/${applicationId}`, data)
}

const updateApplicationSkills = (applicationId: string, data: UpdateApplicationSkillsRequestBody): Promise<Application> => {
    return axios.patch(`${opportunitiesPath}/-/applications/${applicationId}:editSkills`, data)
}

const getApplicationCv = (applicationId: string): Promise<File> => {
    return axios.get(`${opportunitiesPath}/-/applications/${applicationId}/cv`, {
        responseType: 'blob',
    })
}

const updateApplicationCv = (applicationId: string, data: FormData): Promise<Application> => {
    return axios.patch(`${opportunitiesPath}/-/applications/${applicationId}/cv`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}
const withdrawApplication = (applicationId: string, body: ApplicationWithdrawalParams): Promise<Application> => {
    return axios.patch(`${opportunitiesPath}/-/applications/${applicationId}:withdraw`, body)
}
const applyWithApplication = (opportunityId: string, applicationId: string): Promise<Application> => {
    return axios.patch(`${opportunitiesPath}/${opportunityId}/applications/${applicationId}:apply`)
}

const addCvToParsing = (applicationId: string): Promise<void> => {
    return axios.post(`${opportunitiesPath}/-/applications/${applicationId}/cv:parse`)
}

const specialistsPath = 'specialists'

const getShareMySpecialistsSpecialist = (specialistId: string): Promise<ShareMySpecialistsSpecialist> => {
    return axios.get(`/marketplaceManager/${specialistsPath}/${specialistId}`)
}

const saveSpecialistLanguages = (specialistId: string, languages: Array<LanguageRequest>): Promise<void> => {
    return axios.post(`${specialistsPath}/${specialistId}/languages:batch`, { languages })
}

const getPublishedOpportunities = (): Promise<{ opportunityShortResponses: OpportunityShort[] }> => {
    return axios.get(`${opportunitiesPath}/-/shortList`)
}

const inviteToOpportunity = (opportunityId: string, specialistId: string): Promise<InvitedCandidate> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}/invitations`, { specialistId })
}

const getCvTemplate = (): Promise<File> => {
    return axios.get(`${opportunitiesPath}/-/cvTemplate`, {
        responseType: 'blob',
    })
}

export {
    applyWithCv,
    applyWithSpecialist,
    getAllSpecialists,
    getApplication,
    getApplicationsFilters,
    getOpenOpportunitiesApplications,
    getOpenOpportunitiesFilters,
    getOpportunityApplications,
    getRejectionReasons,
    removeApplication,
    searchApplications,
    searchOpenOpportunities,
    updateApplication,
    updateApplicationSkills,
    getApplicationCv,
    updateApplicationCv,
    withdrawApplication,
    applyWithApplication,
    addCvToParsing,
    getShareMySpecialistsSpecialist,
    saveSpecialistLanguages,
    getPublishedOpportunities,
    inviteToOpportunity,
    getCvTemplate,
}
