/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { usePromise } from 'react-use'
import { getSpecialistTechSkills } from '../../../api/api'
import { Button } from '../../../components/Button'
import { Loader } from '../../../components/layout/Loader'
import { SkillGraph } from '../../../components/skill-graph/SkillGraph'
import { SkillMatrix as SkillMatrixComponent } from '../../../components/SkillMatrix'
import { Tabs, TabsColor } from '../../../components/Tabs'
import { SkillMatrix } from '../../../contracts/profile/skillMatrix'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { SectionWrapper } from '../components/SectionWrapper'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound } from '../specialist-profile/SectionHelpers'

type TechnicalSkillsProps = {
    specialistId: string
}

const TechnicalSkills: FunctionComponent<React.PropsWithChildren<TechnicalSkillsProps>> = ({ specialistId }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [skillMatrix, setSkillMatrix] = useState<Nullable<SkillMatrix>>(null)
    const [selectedTab, setSelectedTab] = useState(0)

    const isMounted = usePromise()
    const log = useLogger()

    const onTabChange = useCallback((index: any) => {
        setSelectedTab(index)
    }, [])

    const fetchSkills = useCallback((): void => {
        setLoading(true)

        isMounted(
            getSpecialistTechSkills(specialistId)
                .then(setSkillMatrix)
                .catch(log)
                .finally(() => {
                    setLoading(false)
                }),
        )
    }, [isMounted, specialistId, log])

    useEffect(() => {
        fetchSkills()
    }, [fetchSkills])

    return (
        <SectionWrapper showButton={false}>
            <div
                css={css`
                    margin-bottom: 16px;
                `}
            >
                <SectionHeaderWithActions header='Technical Skills'>
                    <Link
                        to={`/my-specialists/specialist/${specialistId}/skills-edit`}
                        css={css`
                            &:hover {
                                text-decoration: none;
                            }
                        `}
                    >
                        <Button variant='tertiary'>Edit</Button>
                    </Link>
                </SectionHeaderWithActions>
            </div>

            <Tabs
                tabs={[{ title: 'Skill Matrix' }, { title: 'Skill Graph' }]}
                mode={TabsColor.BLACK}
                onTabChange={onTabChange}
                currentTab={selectedTab}
            />
            {loading ? (
                <Loader />
            ) : selectedTab === 0 ? (
                skillMatrix && (
                    <Fragment>
                        {skillMatrix && skillMatrix.categories.length > 0 ? (
                            <SkillMatrixComponent skillMatrix={skillMatrix} />
                        ) : (
                            <SectionDataNotFound
                                title='Add first skill'
                                iconName='add-empty'
                                description='Currently there are no skills added. Please use "Edit" button in the top right corner to add and rate new skills. Or you can click Send Request/Send Reminder button on the right to email your specialist and they will be able to add and rate their skills.'
                                iconSize={85}
                            />
                        )}
                    </Fragment>
                )
            ) : (
                skillMatrix && (
                    <Fragment>
                        {skillMatrix && skillMatrix.categories.length > 0 ? (
                            <SkillGraph id={specialistId} skillMatrix={skillMatrix} />
                        ) : (
                            <SectionDataNotFound
                                title='Add first skill'
                                iconName='add-empty'
                                description='Currently there are no skills added. Please use "Edit" button in the top right corner to add and rate new skills. Or you can click Send Request/Send Reminder button on the right to email your specialist and they will be able to add and rate their skills.'
                                iconSize={85}
                            />
                        )}
                    </Fragment>
                )
            )}
        </SectionWrapper>
    )
}

export { TechnicalSkills }
