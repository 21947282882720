/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/Button'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { Specialist } from '../../../contracts/specialist/specialist'
import { mqMax } from '../../../GlobalStyle'
import { hasErrors } from '../../../utils/errors'
import { useLogger } from '../../../utils/useLogger'
import { yup } from '../../../validation/yup'
import { updateSpecialist } from '../api'

type SpecialistNameFormProps = {
    onCancel?: () => void
    onSubmit?: (specialist: Specialist) => void
    specialistData: Specialist
    specialistId: string
}

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
})

const SpecialistNameForm: FunctionComponent<React.PropsWithChildren<SpecialistNameFormProps>> = ({
    onCancel,
    onSubmit,
    specialistData,
    specialistId,
}) => {
    const {
        handleSubmit,
        formState: { errors, isDirty, isSubmitting, touchedFields },
        reset,
        control,
        getValues,
        setValue,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            firstName: '',
            lastName: '',
        },
    })

    const { addSuccess, addError } = useNotifications()
    const log = useLogger('error')

    useEffect(() => {
        if (specialistData.firstName) {
            setValue('firstName', specialistData.firstName)
        }
        if (specialistData.lastName) {
            setValue('lastName', specialistData.lastName)
        }
        reset(getValues())
    }, [setValue, specialistData, reset, getValues])

    const handleFormSubmit = (formData: any) => {
        const { firstName, lastName } = formData
        const postData = {
            city: specialistData.city,
            country: specialistData.country,
            email: specialistData.email,
            employmentType: specialistData.employmentType,
            remote: specialistData.remote,
            role: specialistData.role,
            topSkills: specialistData.topSkills,
            firstName,
            lastName,
        }

        updateSpecialist(specialistId, postData)
            .then(specialist => {
                addSuccess('User data updated!')
                if (onSubmit) {
                    onSubmit(specialist)
                }
            })
            .catch(err => {
                addError()
                log(err)
            })
    }

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        } else {
            reset()
        }
    }

    return (
        <form
            onSubmit={handleSubmit(handleFormSubmit)}
            css={css`
                width: 100%;
            `}
        >
            <section
                css={css`
                    display: flex;
                    position: relative;
                    justify-content: space-between;
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                    `}
                >
                    <FieldWrapper noLabel>
                        <ControlledInput
                            control={control}
                            name='firstName'
                            placeholder='First name'
                            style={css`
                                margin-right: 12px;
                            `}
                        />
                    </FieldWrapper>
                    <FieldWrapper noLabel>
                        <ControlledInput
                            control={control}
                            name='lastName'
                            placeholder='Last name'
                            style={css`
                                margin-right: 12px;
                                ${mqMax[1]} {
                                    margin-right: 0;
                                }
                            `}
                        />
                    </FieldWrapper>
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 24px;
                            margin-bottom: 20px;
                        `}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        css={css`
                            margin-bottom: 20px;
                        `}
                        variant='primary'
                        type='submit'
                        disabled={isSubmitting || !isDirty || !touchedFields || hasErrors(errors)}
                        dataTestId='confirm-basic-info'
                    >
                        Save
                    </Button>
                </div>
            </section>
        </form>
    )
}

export { SpecialistNameForm }
