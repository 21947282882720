/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { sendResetPasswordEmail } from '../../api/api'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { DefaultLayout } from '../../components/DefaultLayout'
import { ControlledInput } from '../../components/forms/ControlledInput'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { hasErrors } from '../../utils/errors'
import { yup } from '../../validation/yup'
import { RecoverPasswordEmailSent } from './RecoverPasswordEmailSent'

const schema = yup.object().shape({
    email: yup.string().email().required(),
})

const RecoverPassword = () => {
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })
    const { addError } = useNotifications()
    const [emailSent, setEmailSent] = useState(false)
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const onSubmit = useCallback(
        (data: any) => {
            const { email } = data
            layoutToggleLoader(true)
            sendResetPasswordEmail(email)
                .then(response => setEmailSent(true))
                .catch(() => addError('Invalid email'))
                .finally(() => layoutToggleLoader(false))
        },
        [addError, layoutToggleLoader],
    )

    return emailSent ? (
        <RecoverPasswordEmailSent />
    ) : (
        <DefaultLayout>
            <form onSubmit={handleSubmit(onSubmit)}>
                <BackLink
                    path={'/?role=manager'}
                    text='Back to Login'
                    style={css`
                        margin-top: 42px;
                    `}
                />
                <h3
                    css={css`
                        margin-top: 24px;
                        margin-bottom: 16px;

                        &:focus-visible {
                            outline: 5px auto -webkit-focus-ring-color;
                        }
                    `}
                >
                    Forgot Your Password?
                </h3>
                <p
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    Please submit your email below to reset your password. The generated link will only be active for 1 hour, so please make
                    sure you have easy access to your email. Otherwise, you will need to generate a new link.
                </p>
                <FieldWrapper>
                    <ControlledInput
                        name='email'
                        label='Email:'
                        placeholder='Email'
                        type='text'
                        dataTestId='email-input'
                        control={control}
                    />
                </FieldWrapper>
                <Button
                    variant='primary'
                    type='submit'
                    disabled={!isDirty || isSubmitting || hasErrors(errors)}
                    style={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                    dataTestId='reset-password-submit'
                >
                    Reset my Password
                </Button>
            </form>
        </DefaultLayout>
    )
}

export { RecoverPassword }
