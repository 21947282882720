/** @jsxImportSource @emotion/react */

import { DatePicker } from 'antd'
import moment, { Moment } from 'moment'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../theme/colors'
import { Nullable } from '../../types'
import { randomString } from '../../utils/strings'
import { InputField, InputFieldProps } from './input-field/InputField'

type formatFunctionType = (value: Moment) => string
export type DatePickerInputProps = InputFieldProps & {
    name?: string
    placeholder?: string
    style?: any
    disabled?: boolean
    format?: string | formatFunctionType
    onChange?: (value: any) => void
    value?: Nullable<number>
    defaultValue?: any
    disabledDate?: (currentDate: Moment) => boolean
    extraFooter?: any
    showToday?: boolean
    popupStyle?: any
    dropdownAlign?: any
    isActive?: boolean
    dataTestId?: string
    picker?: 'week' | 'month' | 'quarter' | 'year'
    canClear?: boolean
    showTime?: boolean
    minuteStep?: number
}

const DatePickerInput: FunctionComponent<React.PropsWithChildren<DatePickerInputProps>> = ({
    label,
    labelTooltip,
    name = randomString(),
    placeholder = 'DD.MM.YYYY',
    style,
    disabled = false,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    format = 'DD.MM.YYYY',
    onChange = () => null,
    value,
    defaultValue = '',
    extraFooter,
    showToday = true,
    popupStyle,
    dropdownAlign,
    isActive,
    dataTestId = '',
    picker,
    canClear,
    ...rest
}) => {
    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId || name}
        >
            <DatePicker
                name={name}
                placeholder={placeholder}
                format={format}
                style={{
                    width: '100%',
                    borderColor: errorMessage ? COLOR_PALETTE.red_4 : isActive ? COLOR_PALETTE.gray_3 : undefined,
                    ...style,
                }}
                disabled={disabled}
                onChange={date => onChange(date?.unix())}
                defaultValue={defaultValue ? moment(defaultValue * 1000) : undefined}
                value={value ? moment(value * 1000) : undefined}
                renderExtraFooter={() => extraFooter}
                showToday={showToday}
                popupStyle={popupStyle}
                dropdownAlign={dropdownAlign}
                {...rest}
                data-testid={dataTestId || name}
                picker={picker}
                allowClear={canClear}
            />
        </InputField>
    )
}

export { DatePickerInput }
