/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { InputField, InputFieldProps } from './input-field/InputField'

export type TextAreaProps = InputFieldProps & {
    name: string
    onChange?: (value: string) => void
    value?: string
    placeholder?: string
    disabled?: boolean
    style?: SerializedStyles
    innerRef?: any
    dataTestId?: string
}

const Textarea: FunctionComponent<React.PropsWithChildren<TextAreaProps>> = ({
    name,
    onChange,
    value = '',
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    label,
    placeholder,
    disabled = false,
    style,
    innerRef,
    labelTooltip,
    dataTestId = '',
}) => {
    const [valueState, setValueState] = useState(value)

    useEffect(() => {
        setValueState(value)
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setValueState(event.target.value)

        if (onChange) {
            onChange(event.target.value)
        }
    }

    const controlProps = !innerRef
        ? {
              value: valueState,
              onChange: handleChange,
          }
        : {}

    const addErrorStyles = () => {
        return errorMessage
            ? css`
                  textarea {
                      border: 1px solid ${COLOR_PALETTE.red_4};
                  }
              `
            : null
    }

    return (
        <div
            css={[
                css`
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    margin-bottom: 16px;

                    ${style}
                `,
                addErrorStyles(),
            ]}
        >
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                dataTestId={dataTestId || name}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
            >
                <textarea
                    css={css`
                        width: 100%;
                        height: 144px;
                        border: 1px solid ${COLOR_PALETTE.gray_2};
                        border-radius: 2px;
                        padding: 9px 16px;
                        outline: none;
                        color: ${COLOR_PALETTE.gray_6};
                        resize: none;

                        &::placeholder {
                            color: ${COLOR_PALETTE.gray_3};
                        }

                        &[disabled] {
                            background-color: ${COLOR_PALETTE.gray_1};
                            color: ${COLOR_PALETTE.gray_3};
                        }

                        &:focus {
                            border-color: ${COLOR_PALETTE.gray_3};
                        }
                    `}
                    name={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    ref={innerRef}
                    {...controlProps}
                    data-testid={dataTestId || name}
                />
            </InputField>
        </div>
    )
}

export { Textarea }
