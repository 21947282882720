/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAppPath } from 'src/contracts/applications'
import { useUserAuthentication } from 'src/hooks/useUserAuthentication'
import { ReduxContext } from 'src/redux/Store'
import { AccessLevelContext } from '../../../../AccessLevelContext'
import dotsIcon from '../../../../assets/icons/dots.svg'
import downloadIcon from '../../../../assets/icons/download.svg'
import plane from '../../../../assets/icons/plane.svg'
import shareIcon from '../../../../assets/icons/share.svg'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { AntDropdown } from '../../../../components/dropdown/AntDropdown'
import { blackToRed4Filter, Icon } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { ContentLoader } from '../../../../components/layout/Loader'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { ProfileExportButton } from '../../../../components/profile-export/ProfileExportButton'
import { ExportType } from '../../../../components/profile-export/types'
import { SideMenuBox } from '../../../../components/SideMenuBox'
import { SpecialistCvStatus } from '../../../../contracts/cvs'
import { Specialist } from '../../../../contracts/specialist/specialist'
import { CvData } from '../../../../contracts/specialist/specialistCv'
import { Nullable } from '../../../../types'
import { useLogger } from '../../../../utils/useLogger'
import { cancelInvitation, deleteSpecialist, getSpecialistDashboardAccess, sendInvitation } from '../../api'
import { KeyInfoResponse } from '../../contracts'
import { DeleteProfileModal } from '../DeleteProfileModal'
import { UploadCv } from '../UploadCv/UploadCv'
import { CancelInvitationModal } from './CancelInvitationModal'
import { ToDoList } from './ToDoList'
import { useCvUpload } from './useCvUpload'

enum OtherType {
    Send = 'send',
    Cancel = 'cancel',
    Delete = 'delete',
}

type MySpecialistsSpecialistSideMenuProps = {
    handleGetLinkToProfileClick: () => void
    handleExportProfileClick: (key: ExportType) => void
    specialist: Nullable<Specialist>
    cvFile: Nullable<CvData>
    setCvFile: Dispatch<SetStateAction<Nullable<CvData>>>
    specialistId: string
    keyInfo: Nullable<KeyInfoResponse>
    setForceFocus: Dispatch<SetStateAction<string>>
    shouldDisplayKeyInfo: Nullable<boolean>
    handleDismiss: () => void
    anonymousNickname?: string
}

const MySpecialistsSpecialistSideMenu: FunctionComponent<React.PropsWithChildren<MySpecialistsSpecialistSideMenuProps>> = ({
    handleGetLinkToProfileClick,
    handleExportProfileClick,
    specialist,
    cvFile,
    setCvFile,
    specialistId,
    keyInfo,
    setForceFocus,
    shouldDisplayKeyInfo,
    handleDismiss,
    anonymousNickname,
}) => {
    const [specialistDashboardAccess, setSpecialistDashboardAccess] = useState(false)
    const [isInviteLoading, setIsInviteLoading] = useState(true)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const theme = useTheme()
    const { mySpecialists: mySpecialistsAccess } = useContext(AccessLevelContext)
    const { isSpecialistAccount } = useUserAuthentication()
    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()
    const navigate = useNavigate()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const fetchSpecialistDashboardAccess = useCallback(() => {
        setIsInviteLoading(true)
        getSpecialistDashboardAccess(specialistId)
            .then(data => setSpecialistDashboardAccess(data.accessGranted))
            .catch(err => {
                log(err)
                addError()
            })
            .finally(() => setIsInviteLoading(false))
    }, [addError, log, specialistId])

    useEffect(() => {
        fetchSpecialistDashboardAccess()
    }, [fetchSpecialistDashboardAccess])

    const handleApiCall = useCallback(() => {
        setIsInviteLoading(true)

        sendInvitation(specialistId)
            .then(() => {
                fetchSpecialistDashboardAccess()
                addSuccess('Request sent successfully.')
            })
            .catch(err => {
                log(err)
                addError()
                setIsInviteLoading(false)
            })
    }, [specialistId, fetchSpecialistDashboardAccess, addSuccess, log, addError])

    const [isCancelInvitationModalVisible, setIsCancelInvitationModalVisible] = useState(false)

    const toggleCancelInvitationModal = useCallback(() => {
        setIsCancelInvitationModalVisible(isVisible => !isVisible)
    }, [])

    const handleInvitationCancellation = useCallback(() => {
        setIsCancelInvitationModalVisible(false)
        if (specialistDashboardAccess) {
            setIsInviteLoading(true)
            cancelInvitation(specialistId)
                .then(() => {
                    fetchSpecialistDashboardAccess()
                })
                .catch(err => {
                    log(err)
                    addError()
                    setIsInviteLoading(false)
                })
        }
    }, [specialistDashboardAccess, specialistId, fetchSpecialistDashboardAccess, log, addError])

    const { handleCvUpload, handleDeleteCv, CvUploadInput, cvParsingStatus, specialistParsedCvs, isCvLoading } = useCvUpload(
        specialistId,
        setCvFile,
    )

    const handleOpportunitiesSearch = useCallback(() => {
        if (specialist?.role) {
            navigate(`${getAppPath('OPEN_OPPORTUNITIES')}?searchText=${specialist?.role}`)
        }
    }, [navigate, specialist?.role])

    const handleCloseConfirmation = useCallback(() => {
        setShowDeleteConfirmation(false)
    }, [])

    const showDeleteConfirmationModal = useCallback(() => {
        setShowDeleteConfirmation(true)
    }, [])

    const handleOther = useCallback(
        (key: OtherType) => {
            switch (key) {
                case OtherType.Send:
                    handleApiCall()
                    break
                case OtherType.Cancel:
                    toggleCancelInvitationModal()
                    break
                case OtherType.Delete:
                    showDeleteConfirmationModal()
                    break
            }
        },
        [handleApiCall, showDeleteConfirmationModal, toggleCancelInvitationModal],
    )

    const deleteProfileHandler = useCallback(() => {
        if (specialistId) {
            layoutToggleLoader(true)
            deleteSpecialist(specialistId)
                .then(() => {
                    setShowDeleteConfirmation(false)
                    navigate('/my-specialists')
                })
                .catch(() => addError())
                .finally(() => layoutToggleLoader(false))
        }
    }, [addError, specialistId, navigate, layoutToggleLoader])

    return (
        <Fragment>
            {CvUploadInput}
            {shouldDisplayKeyInfo && (
                <ToDoList
                    isCvLoading={isCvLoading || cvParsingStatus?.[0].status === SpecialistCvStatus.IN_PROGRESS}
                    handleCvUpload={handleCvUpload}
                    keyInfo={keyInfo}
                    setForceFocus={setForceFocus}
                    specialistId={specialistId}
                    handleDismiss={handleDismiss}
                />
            )}
            <SideMenuBox>
                {specialist?.role && !isSpecialistAccount && (
                    <Fragment>
                        <Button
                            size='big'
                            style={css`
                                width: 100%;
                            `}
                            onClick={handleOpportunitiesSearch}
                        >
                            Find Opportunities
                        </Button>
                        <p
                            css={css`
                                margin-top: 20px;
                                color: ${theme.colors.gray_4};
                            `}
                        >
                            See if you can find a job opportunity for this specialist on our platform right now!
                        </p>
                        <Divider
                            style={css`
                                margin-bottom: 0;
                            `}
                        />
                    </Fragment>
                )}
                <FlexBox
                    css={css`
                        flex-direction: column;
                        width: 100%;
                    `}
                >
                    {mySpecialistsAccess.sharingProfile && (
                        <Button variant='link' icon={shareIcon} onClick={handleGetLinkToProfileClick} dataTestId='share-profile-button'>
                            Get link to profile
                        </Button>
                    )}
                    <Divider
                        style={css`
                            margin-bottom: 0;
                        `}
                    />
                    <ProfileExportButton handleClick={handleExportProfileClick}>
                        <div>
                            <Button icon={downloadIcon} variant='link' dataTestId='export-profile-button'>
                                Export profile <Icon name='arrow-down' />
                            </Button>
                        </div>
                    </ProfileExportButton>
                    <Divider
                        style={css`
                            margin-bottom: 0;
                        `}
                    />
                    {isInviteLoading ? (
                        <ContentLoader height='102px' />
                    ) : (
                        (mySpecialistsAccess.invitations.send ||
                            mySpecialistsAccess.invitations.resend ||
                            (mySpecialistsAccess.invitations.cancel && specialistDashboardAccess) ||
                            mySpecialistsAccess.deleteProfile) && (
                            <AntDropdown
                                options={[
                                    ...(mySpecialistsAccess.invitations.send || mySpecialistsAccess.invitations.resend
                                        ? [
                                              {
                                                  title: (
                                                      <Tooltip
                                                          title={
                                                              !specialist?.email &&
                                                              'You need to provide email before you can send a request to this specialist.'
                                                          }
                                                      >
                                                          <FlexBox
                                                              alignItems='center'
                                                              css={css`
                                                                  width: 100%;
                                                                  ${!specialist?.email &&
                                                                  css`
                                                                      cursor: not-allowed;
                                                                  `}
                                                              `}
                                                          >
                                                              <Button
                                                                  icon={plane}
                                                                  variant='link'
                                                                  style={css`
                                                                      padding: 6px 0;
                                                                      ${!specialist?.email &&
                                                                      css`
                                                                          pointer-events: none;
                                                                      `}
                                                                  `}
                                                                  iconStyle={css`
                                                                      margin-right: 18px;
                                                                  `}
                                                                  disabled={!specialist?.email}
                                                              >
                                                                  {specialistDashboardAccess
                                                                      ? 'Send Reminder'
                                                                      : 'Email specialist (to fill in their profile)'}
                                                              </Button>
                                                          </FlexBox>
                                                      </Tooltip>
                                                  ),
                                                  key: OtherType.Send,
                                                  dataTestId: OtherType.Send,
                                                  disabled: !specialist?.email,
                                              },
                                          ]
                                        : []),
                                    ...(mySpecialistsAccess.invitations.cancel && specialistDashboardAccess
                                        ? [
                                              {
                                                  title: (
                                                      <FlexBox alignItems='center'>
                                                          <Icon
                                                              name='withdraw'
                                                              size={18}
                                                              style={css`
                                                                  margin-right: 16px;
                                                              `}
                                                          />
                                                          Cancel Request
                                                      </FlexBox>
                                                  ),
                                                  key: OtherType.Cancel,
                                                  dataTestId: OtherType.Cancel,
                                              },
                                          ]
                                        : []),
                                    ...(mySpecialistsAccess.deleteProfile
                                        ? [
                                              {
                                                  title: (
                                                      <FlexBox
                                                          alignItems='center'
                                                          css={css`
                                                              color: ${theme.colors.red_4};
                                                          `}
                                                      >
                                                          <Icon
                                                              name='trash'
                                                              size={18}
                                                              style={css`
                                                                  filter: ${blackToRed4Filter};
                                                                  margin-right: 16px;
                                                              `}
                                                          />
                                                          Delete profile
                                                      </FlexBox>
                                                  ),
                                                  key: OtherType.Delete,
                                                  dataTestId: OtherType.Delete,
                                              },
                                          ]
                                        : []),
                                ]}
                                handleClick={handleOther}
                            >
                                <div>
                                    <Button
                                        icon={dotsIcon}
                                        variant='link'
                                        dataTestId='other-button'
                                        style={css`
                                            width: 100%;
                                        `}
                                    >
                                        Other <Icon name='arrow-down' />
                                    </Button>
                                </div>
                            </AntDropdown>
                        )
                    )}
                </FlexBox>
                {mySpecialistsAccess.uploadCV && specialist && (
                    <UploadCv
                        handleCvUpload={handleCvUpload}
                        handleDeleteCv={handleDeleteCv}
                        cvFile={cvFile}
                        isLoading={isCvLoading}
                        cvParsingStatus={cvParsingStatus}
                        specialistParsedCvs={specialistParsedCvs}
                        styles={css`
                            margin-bottom: 0;
                            margin-top: 16px;
                        `}
                        specialist={specialist}
                        anonymousNickname={anonymousNickname}
                    />
                )}
            </SideMenuBox>
            <CancelInvitationModal
                handleClose={toggleCancelInvitationModal}
                handleCancellation={handleInvitationCancellation}
                isVisible={isCancelInvitationModalVisible}
            />
            {mySpecialistsAccess.deleteProfile && (
                <DeleteProfileModal {...{ deleteProfileHandler, handleCloseConfirmation, showDeleteConfirmation }} />
            )}
        </Fragment>
    )
}

export { MySpecialistsSpecialistSideMenu }
