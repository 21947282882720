/** @jsxImportSource @emotion/react */
import { css, Global, SerializedStyles } from '@emotion/react'
import { FunctionComponent, MouseEvent, useCallback } from 'react'
import { createPortal } from 'react-dom'
import useResizeObserver from 'use-resize-observer'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

const CONTENT_MARGIN = 60
const MAX_WIDTH_NORMAL = 545
const MAX_WIDTH_LARGE = 875

type Sizes = 'normal' | 'large' | 'content'

export type ModalProps = {
    opened?: boolean
    onClose?: () => void
    maskClosable?: boolean
    style?: SerializedStyles
    closeButton?: boolean
    dataTestId?: string
    size?: Sizes
    containerStyles?: SerializedStyles
    closeButtonStyles?: SerializedStyles
}

const Modal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({
    opened = false,
    onClose,
    maskClosable = true,
    style,
    children,
    closeButton = true,
    dataTestId = '',
    size = 'normal',
    containerStyles,
    closeButtonStyles,
}) => {
    const onOutsideClick = useCallback(
        (event: MouseEvent<HTMLDivElement>): void => {
            if (maskClosable && onClose) {
                event.stopPropagation()
                event.preventDefault()
                onClose()
            }
        },
        [maskClosable, onClose],
    )

    const handleClose = useCallback(
        (e: MouseEvent) => {
            if (onClose) {
                e.stopPropagation()
                e.preventDefault()

                onClose()
            }
        },
        [onClose],
    )

    const onInsideClick = useCallback((event: MouseEvent<HTMLDivElement>): void => {
        event.stopPropagation()
    }, [])

    const { ref, height = 1 } = useResizeObserver<HTMLDivElement>()
    const isContentHigherThanWindow = height > document.body.clientHeight - 2 * CONTENT_MARGIN

    let maxWidth
    let width

    switch (size) {
        case 'normal': {
            maxWidth = `${MAX_WIDTH_NORMAL}px`
            width = '100%'
            break
        }
        case 'large': {
            maxWidth = `${MAX_WIDTH_LARGE}px`
            width = '100%'
            break
        }
        case 'content': {
            maxWidth = 'none'
            width = 'auto'
            break
        }
        default: {
            maxWidth = `${MAX_WIDTH_NORMAL}px`
            width = '100%'
        }
    }

    return createPortal(
        <div
            onMouseDown={onOutsideClick}
            css={css`
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10;
                width: 100%;
                height: 100%;
                overflow: auto;
                background-color: rgba(0, 0, 0, 0.5);
                display: ${opened ? 'flex' : 'none'};
                align-items: ${isContentHigherThanWindow ? 'flex-start' : 'center'};
                justify-content: center;
                padding: ${CONTENT_MARGIN}px 17px;

                ${containerStyles}
            `}
        >
            {opened && (
                <Global
                    styles={css`
                        body {
                            overflow: hidden;
                        }
                    `}
                />
            )}
            <div
                ref={ref}
                data-testid={dataTestId}
                onMouseDown={onInsideClick}
                onClick={onInsideClick}
                css={css`
                    position: relative;
                    width: ${width};
                    background-color: ${COLOR_PALETTE.white};
                    max-width: ${maxWidth};
                    padding: 32px 40px;
                    ${style}
                `}
            >
                {closeButton ? (
                    <Icon
                        name='close-grey'
                        onClick={handleClose}
                        css={css`
                            color: ${COLOR_PALETTE.gray_3};
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            height: 30px;
                            width: 30px;
                            cursor: pointer;
                            ${closeButtonStyles}
                        `}
                        dataTestId='modal-close'
                    />
                ) : null}
                {children}
            </div>
        </div>,
        document.body,
    )
}

export { Modal }
