/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { getSpecialistCompanyId, resendMagicLink } from '../../api/api'
import { Button } from '../../components/Button'
import { ControlledInput } from '../../components/forms/ControlledInput'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { config } from '../../environment/config'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { COLOR_PALETTE } from '../../theme/colors'
import { hasErrors } from '../../utils/errors'
import { useLogger } from '../../utils/useLogger'
import { yup } from '../../validation/yup'

const schema = yup.object().shape({
    email: yup.string().email().required(),
})

const SpecialistLoginForm: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const log = useLogger('error')
    const navigate = useNavigate()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const { addError } = useNotifications()

    const {
        control,
        formState: { errors, isSubmitting },
        handleSubmit,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })

    const onSubmit = useCallback(
        async (formData: any) => {
            layoutToggleLoader(true)
            localStorage.setItem('userEmail', formData.email)
            let companyId

            try {
                const { companyId: specialistCompanyId } = await getSpecialistCompanyId(formData.email)
                companyId = specialistCompanyId
            } catch (err) {
                log(err)
            }

            resendMagicLink(formData.email, decodeURIComponent(`${config.appUrl}/specialist-dashboard`), companyId)
                .then(() => navigate(`/magic-link/confirmation?email=${encodeURIComponent(formData.email)}`))
                .catch(err => {
                    if (err.status === 400) {
                        navigate('/magic-link-access-denied')
                        return
                    }

                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        },
        [addError, navigate, layoutToggleLoader, log],
    )

    return (
        <div
            css={css`
                padding-bottom: 100px;
            `}
        >
            <h3
                css={css`
                    margin-top: 40px;
                `}
                data-testid='login-check'
            >
                Log in as Specialist
            </h3>
            <span
                css={css`
                    color: ${COLOR_PALETTE.gray_4};
                `}
            >
                Please make sure you submit the same email address that you received the initial request to start editing your Talent Alpha
                Profile. Thanks!
            </span>
            <form
                css={css`
                    margin-top: 32px;
                `}
                onSubmit={handleSubmit(onSubmit)}
            >
                <FieldWrapper>
                    <ControlledInput label='E-mail' placeholder='E-mail' name='email' control={control} />
                </FieldWrapper>
                <Button
                    variant='primary'
                    type='submit'
                    disabled={isSubmitting || hasErrors(errors)}
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Continue as Specialist
                </Button>
            </form>
        </div>
    )
}

export { SpecialistLoginForm }
