/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { FunctionComponent } from 'react'
import { ResponsiveGrid } from '../../../../../components/data-grid/ResponsiveGrid'
import { NoData } from '../../../../../components/NoData'

import { createComparer } from 'src/utils/sorting'

import { VMCUser } from 'src/contracts/vmc'

interface RolesGridProps {
    users: Array<VMCUser>
    fetchData: () => void
}

const RolesGrid: FunctionComponent<React.PropsWithChildren<RolesGridProps>> = ({ users }) => {
    const columns: ColumnsType<VMCUser> = [
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: {
                compare: createComparer('firstName'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: {
                compare: createComparer('lastName'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'Company name',
            dataIndex: 'companyName',
            key: 'companyName',
            sorter: {
                compare: createComparer('companyName'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: createComparer('email'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
    ]

    return (
        <div>
            {users?.length === 0 ? (
                <div
                    css={css`
                        margin: 40px auto;
                    `}
                >
                    <NoData />
                </div>
            ) : (
                <ResponsiveGrid dataTestId='user-roles-grid' rowKey='userId' dataSource={users} columns={columns} pagination={false} />
            )}
        </div>
    )
}

export { RolesGrid }
