/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment from 'moment'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { FilterColumn, FilterDoubleColumn, FiltersBarWrapper, FiltersClearTrigger } from 'src/components/filters/FiltersBarHelpers'
import { AutocompleteSelect } from 'src/components/inputs/autocomplete-select/AutocompleteSelect'
import { DatePickerInput } from 'src/components/inputs/DatePickerInput'
import { Input } from 'src/components/inputs/Input'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { SearchLocation } from 'src/contracts/common/searchLocation'
import { AvailableOn } from 'src/contracts/search/availableOn'
import { naturalNumbersRegex } from 'src/utils/regexes'
import { resolveOptionsChange } from 'src/utils/searchFilters'
import { mapSharedType, ShareMySpecialistsFilters } from '../contracts'
import { SharedType } from 'src/contracts/specialist/specialist'
import { findSyndicateNameById } from 'src/utils/values'
import { countSelectedFilters } from '../utils'

type ShareMySpecialistsFiltersProps = {
    possibleFilters: ShareMySpecialistsFilters
    selectedFilters: ShareMySpecialistsFilters
    opened: boolean
    onChangeFilters: (filters: ShareMySpecialistsFilters) => void
    onClearAll: () => void
    syndicates: Array<AvailableOn>
}

const ShareMySpecialistsFiltersBar: FunctionComponent<React.PropsWithChildren<ShareMySpecialistsFiltersProps>> = ({
    possibleFilters,
    selectedFilters,
    opened,
    onChangeFilters,
    onClearAll,
    syndicates,
}) => {
    const isInSyndicate = useMemo(() => syndicates.length > 1, [syndicates.length])

    const onChangeFilter = useCallback(
        (values: Array<string | SearchLocation> | boolean | number, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: values,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    const onChangeRateFrom = useCallback((rateFrom: any) => onChangeFilter(rateFrom, 'rateFrom'), [onChangeFilter])
    const onChangeRateTo = useCallback((rateTo: any) => onChangeFilter(rateTo, 'rateTo'), [onChangeFilter])
    const onChangeAvailableFrom = useCallback(
        (availableFrom: any) =>
            onChangeFilter(
                Number(
                    moment
                        .utc(new Date(availableFrom * 1000))
                        .startOf('day')
                        .format('X'),
                ),
                'availableFrom',
            ),
        [onChangeFilter],
    )
    const onChangeAvailableOn = useCallback((availableOn: any) => onChangeFilter(availableOn, 'availableOn'), [onChangeFilter])

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Role'
                            options={possibleFilters.roles}
                            filterName='roles'
                            currentValues={selectedFilters.roles}
                            onSelectedValuesChange={onChangeFilter}
                            isActive={selectedFilters.roles.length > 0}
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Seniority'
                            options={possibleFilters.seniorities}
                            filterName='seniorities'
                            currentValues={selectedFilters.seniorities}
                            onSelectedValuesChange={onChangeFilter}
                            isActive={selectedFilters.seniorities.length > 0}
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Currency'
                            options={possibleFilters.currencies}
                            filterName='currencies'
                            currentValues={selectedFilters.currencies}
                            onSelectedValuesChange={onChangeFilter}
                            canClear
                        />
                    </FilterColumn>

                    <FilterDoubleColumn
                        css={css`
                            display: flex;
                        `}
                    >
                        <Input
                            style={css`
                                min-width: 110px;
                            `}
                            name='rateFrom'
                            placeholder='Rate From (hourly)'
                            onBlur={onChangeRateFrom}
                            value={selectedFilters.rateFrom ? String(selectedFilters.rateFrom) : ''}
                            isActive={Boolean(selectedFilters.rateFrom)}
                            regex={naturalNumbersRegex}
                        />
                        <Input
                            style={css`
                                min-width: 110px;
                            `}
                            name='rateTo'
                            placeholder='Rate To (hourly)'
                            onBlur={onChangeRateTo}
                            value={selectedFilters.rateTo ? String(selectedFilters.rateTo) : ''}
                            isActive={Boolean(selectedFilters.rateTo)}
                            regex={naturalNumbersRegex}
                        />
                    </FilterDoubleColumn>

                    <FilterColumn>
                        <DatePickerInput
                            name='availableFrom'
                            placeholder='Available From'
                            onChange={onChangeAvailableFrom}
                            value={selectedFilters.availableFrom}
                            isActive={Boolean(selectedFilters.availableFrom)}
                        />
                    </FilterColumn>
                    {isInSyndicate && (
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Available On'
                                options={possibleFilters.availableOn}
                                filterName='availableOn'
                                onChange={onChangeAvailableOn}
                                currentValues={resolveOptionsChange(selectedFilters.availableOn, possibleFilters.availableOn)}
                                isActive={selectedFilters.availableOn.length > 0}
                                onSelectedValuesChange={onChangeFilter}
                                selectedLabelTransformer={(id: string) => findSyndicateNameById(id, syndicates)}
                            />
                        </FilterColumn>
                    )}

                    <FilterColumn
                        css={css`
                            max-width: 200px !important;
                        `}
                    >
                        <AutocompleteSelect
                            multiple
                            canFilter={false}
                            placeholder='Status'
                            options={possibleFilters.shared}
                            selectedLabelTransformer={(val: SharedType) => mapSharedType(val)}
                            filterName='shared'
                            currentValues={resolveOptionsChange(selectedFilters.shared, possibleFilters.shared)}
                            isActive={selectedFilters.shared.length > 0}
                            onSelectedValuesChange={onChangeFilter}
                            dataTestId='share-my-specialists-statuses'
                        />
                    </FilterColumn>

                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(selectedFilters)} />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { ShareMySpecialistsFiltersBar }
