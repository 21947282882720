import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { ResponsiveWrapper } from '../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { ThankYouView } from '../../../components/ThankYouView'
import { history } from '../../../history'
import { useUserAuthentication } from '../../../hooks/useUserAuthentication'
import { useLogger } from '../../../utils/useLogger'
import { downloadErrorReport } from '../api'

const CorrectionSpreadsheetDownload = () => {
    const { importId } = useParams<{ importId: string }>()
    const log = useLogger()
    const { addError } = useNotifications()
    const { isUserAuthorized, isWaitingForStatus } = useUserAuthentication()

    const handleRedirection = useCallback(() => {
        history.push('/dashboard')
    }, [])

    useEffect(() => {
        if (isUserAuthorized && !isWaitingForStatus && importId) {
            downloadErrorReport(importId)
                .then((response: any) => {
                    const url = window.URL.createObjectURL(new Blob([response]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `correction-spreadsheet.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(url)
                })
                .catch((error: any) => {
                    addError()
                    log(error)
                })
        }
    }, [addError, importId, isUserAuthorized, isWaitingForStatus, log])

    return (
        <ResponsiveWrapper withLogo>
            <ThankYouView
                title='Your Correction Spreadsheet was downloaded successfully!'
                iconName='check'
                description={<Button onClick={handleRedirection}>Go To Dashboard</Button>}
            />
        </ResponsiveWrapper>
    )
}

export default CorrectionSpreadsheetDownload
