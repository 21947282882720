/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccessLevelContext } from '../../../../AccessLevelContext'
import { useUserContext } from '../../../../hooks/useUserContext'

const MyAccountAccessLevelGuard: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const {
        dashboard: { myAccount: myAccountAccess },
    } = useContext(AccessLevelContext)

    const navigate = useNavigate()

    const { userActiveContextGroups } = useUserContext()

    useEffect(() => {
        if (!myAccountAccess && userActiveContextGroups) {
            navigate('/access-denied')
        }
    }, [navigate, myAccountAccess, userActiveContextGroups])

    return myAccountAccess ? <Fragment>{children}</Fragment> : null
}

export { MyAccountAccessLevelGuard }
