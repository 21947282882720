/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent } from 'react'
import { DefaultLayout } from '../../components/DefaultLayout'

const MaintenancePage: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <DefaultLayout
        icon='maintenance'
        title='We’re down for maintenance'
        description={
            <Fragment>
                <p>Sorry for the inconvenience, but we are performing some maintenance at the moment. We expect to be back soon.</p>{' '}
                <p>Thank you for your patience.</p>
            </Fragment>
        }
    />
)

export { MaintenancePage }
