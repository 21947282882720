/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, KeyboardEvent, RefObject, useCallback, useState } from 'react'
import { blackToWhiteFilter, Icon } from '../../Icon'

type FilterBoxProps = {
    placeholder: string
    onClick: () => void
    displayedValue?: string
    styles?: SerializedStyles
    dataTestId?: string
    onClear?: () => void
    forwardedRef?: RefObject<HTMLButtonElement>
}

const FilterBox: FunctionComponent<React.PropsWithChildren<FilterBoxProps>> = ({
    placeholder,
    displayedValue,
    onClick,
    styles,
    dataTestId = '',
    onClear,
    forwardedRef,
}) => {
    const [shouldCloseIconDisplay, setShouldCloseIconDisplay] = useState(false)

    const handleClear = useCallback(
        (event: any) => {
            event.stopPropagation()
            if (onClear) {
                onClear()
            }
        },
        [onClear],
    )

    const handleMouseEnter = useCallback(() => {
        setShouldCloseIconDisplay(true)
    }, [])

    const handleMouseLeave = useCallback(() => {
        setShouldCloseIconDisplay(false)
    }, [])

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            switch (e.key) {
                case ' ':
                case 'SpaceBar':
                case 'Enter':
                    e.preventDefault()
                    onClick()
            }
        },
        [onClick],
    )

    const theme = useTheme()

    return (
        <button
            ref={forwardedRef}
            type='button'
            onKeyDown={handleKeyDown}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            data-testid={dataTestId}
            css={css`
                height: 40px;
                border: 1px solid ${theme.colors.gray_2};
                border-radius: 2px;
                padding: 0 8px 0 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                position: relative;
                background-color: ${theme.colors.white};
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                color: ${displayedValue ? theme.colors.gray_6 : theme.colors.gray_3};
                ${styles}
                width: 100%;

                &:focus {
                    border: 1px solid ${theme.colors.gray_3};
                }
            `}
        >
            <span
                css={css`
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                `}
            >
                {displayedValue || placeholder}
            </span>
            <div
                css={css`
                    margin-left: auto;
                    display: flex;
                    align-items: center;
                `}
            >
                {onClear && displayedValue ? (
                    <span
                        css={css`
                            height: 14px;
                            width: 14px;
                            border-radius: 7px;
                            background-color: ${theme.colors.gray_4};
                            opacity: 0.4;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: opacity 0.3s ease;
                            opacity: ${shouldCloseIconDisplay ? 1 : 0};
                        `}
                    >
                        <Icon
                            name='close'
                            css={css`
                                filter: ${blackToWhiteFilter};
                            `}
                            onClick={handleClear}
                            size={14}
                            dataTestId={`${dataTestId}-clear`}
                        />
                    </span>
                ) : (
                    <span
                        css={css`
                            height: 14px;
                            width: 14px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            display: flex;
                        `}
                    >
                        <Icon
                            name='arrow-down'
                            style={css`
                                display: block;
                                font-size: 24px;
                                color: ${theme.colors.gray_6};

                                &.opened {
                                    transform: rotate(180deg);
                                }
                            `}
                        />
                    </span>
                )}
            </div>
        </button>
    )
}

export { FilterBox }
