/** @jsxImportSource @emotion/react */

import { FunctionComponent, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { Checkbox, CheckboxProps } from '../inputs/Checkbox'
import { ControlledFieldProps } from './types'

type ControlledCheckboxProps = Omit<CheckboxProps, 'checked' | 'onChange' | 'errorMessage'> &
    ControlledFieldProps<boolean> & {
        beforeChange?: (values: Array<boolean>) => any
    }

const ControlledCheckbox: FunctionComponent<React.PropsWithChildren<ControlledCheckboxProps>> = ({
    defaultValue,
    checkboxLabel,
    checkboxTooltip,
    name,
    style,
    disabled,
    dataTestId,
    control,
    beforeChange,
    infoMessage,
    label,
    labelTooltip,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    const handleChange = useCallback(
        (val: any) => {
            let newValue = val
            if (beforeChange) {
                newValue = beforeChange(val)
            }
            onChange(newValue)
        },
        [beforeChange, onChange],
    )

    return (
        <Checkbox
            infoMessage={infoMessage}
            label={label}
            labelTooltip={labelTooltip}
            checkboxLabel={checkboxLabel}
            checkboxTooltip={checkboxTooltip}
            checked={value}
            onChange={handleChange}
            name={name}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            style={style}
            disabled={disabled}
            dataTestId={dataTestId}
        />
    )
}
export { ControlledCheckbox }
