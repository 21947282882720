/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { Control, UseFieldArrayRemove, UseFieldArrayUpdate, UseFormGetValues, UseFormTrigger } from 'react-hook-form'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { IconButton } from 'src/components/Icon'
import { LanguageItemDetails } from 'src/components/LanguageItemDetails'
import { LanguageRequest, LevelType, levelTypes, mapLevelType } from 'src/contracts/profile/language'
import { mqMax } from 'src/GlobalStyle'
import { InlineFieldWrapper, ItemActionsPanel, PropertyLabel } from 'src/pages/my-specialists/shared/ProfileTabsHelpers'
import { useLanguages } from 'src/redux/dictionaryDataHooks'
import { ShareCandidateModalFormData } from './ShareCandidateModal'
import { LanguageWrapper } from './styles'

type LanguageItemProps = {
    language: LanguageRequest
    index: number
    remove: UseFieldArrayRemove
    control: Control<ShareCandidateModalFormData>
    update: UseFieldArrayUpdate<ShareCandidateModalFormData>
    trigger: UseFormTrigger<ShareCandidateModalFormData>
    getValues: UseFormGetValues<ShareCandidateModalFormData>
}

const LanguageItem: FunctionComponent<React.PropsWithChildren<LanguageItemProps>> = ({
    language,
    index,
    remove,
    control,
    update,
    trigger,
    getValues,
}) => {
    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const { name, level } = language
    const languages = useLanguages()

    const toggleEditMode = useCallback(() => {
        setEditModeEnabled(currentEditModeEnabled => !currentEditModeEnabled)
    }, [])

    const handleDelete = useCallback(() => {
        remove(index)
    }, [index, remove])

    const handleCancel = useCallback(() => {
        update(index, { name, level })
        toggleEditMode()
    }, [index, level, name, toggleEditMode, update])

    const handleSave = useCallback(() => {
        trigger([`languages.${index}.name`, `languages.${index}.level`]).then(isValid => {
            if (isValid) {
                const [currentName, currentLevel] = getValues([`languages.${index}.name`, `languages.${index}.level`])
                update(index, { name: currentName, level: currentLevel })
                toggleEditMode()
            }
        })
    }, [getValues, index, toggleEditMode, trigger, update])

    return (
        <LanguageWrapper>
            {editModeEnabled ? (
                <Fragment>
                    <InlineFieldWrapper
                        css={css`
                            margin-bottom: 10px;
                        `}
                    >
                        <PropertyLabel
                            css={css`
                                line-height: 40px;
                            `}
                        >
                            Language
                        </PropertyLabel>
                        <ControlledAutocompleteSelect
                            placeholder='Language'
                            options={languages}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            control={control}
                            name={`languages.${index}.name`}
                            styles={css`
                                width: 150px;
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            shouldUnregister={false}
                        />
                    </InlineFieldWrapper>

                    <InlineFieldWrapper>
                        <PropertyLabel
                            css={css`
                                line-height: 40px;
                            `}
                        >
                            Level
                        </PropertyLabel>
                        <ControlledAutocompleteSelect
                            placeholder='Level'
                            options={levelTypes}
                            control={control}
                            selectedLabelTransformer={(val: LevelType) => mapLevelType(val)}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            name={`languages.${index}.level`}
                            styles={css`
                                width: 150px;
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            shouldUnregister={false}
                        />
                    </InlineFieldWrapper>

                    <ItemActionsPanel>
                        <IconButton name='edit' size={36} onClick={handleCancel} />
                        <IconButton name='trash' size={36} onClick={handleDelete} />
                    </ItemActionsPanel>
                    <Divider
                        style={css`
                            margin-bottom: 0;
                        `}
                    />
                    <section
                        css={css`
                            display: flex;
                            justify-content: flex-end;

                            ${mqMax[1]} {
                                justify-content: center;
                                flex-direction: column-reverse;
                                justify-content: center;
                                align-items: center;
                            }
                        `}
                    >
                        <Button
                            css={css`
                                margin-right: 12px;
                                ${mqMax[1]} {
                                    width: 100%;
                                    margin: 12px 0 0;
                                }
                            `}
                            variant='text'
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={css`
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            variant='primary'
                            onClick={handleSave}
                        >
                            Save
                        </Button>
                    </section>
                </Fragment>
            ) : (
                <LanguageItemDetails language={language} toggleEditMode={toggleEditMode} handleDelete={handleDelete} />
            )}
        </LanguageWrapper>
    )
}

export { LanguageItem }
