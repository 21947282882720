import styled from '@emotion/styled'

const SkillsBoxWrapper = styled.div`
    padding: 24px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.beige_1};
    margin-bottom: 24px;
`

const H6 = styled.h6`
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray_6};
    margin-bottom: 4px;
`

export { SkillsBoxWrapper, H6 }
