/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../../../components/filters/FiltersBarHelpers'
import { AutocompleteSelect } from '../../../components/inputs/autocomplete-select/AutocompleteSelect'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { BookingsFilters, BookingsFiltersRequest } from '../contracts'
import { countSelectedFilters } from '../talentMarketplaceUtils'

const initialFilters: BookingsFiltersRequest = {
    seniorities: [],
    statuses: [],
    roles: [],
    searchText: '',
}

type FiltersBarProps = {
    onChangeFilters: (filters: BookingsFilters) => void
    onClearFilters: () => void
    selectedFilters: BookingsFilters
    possibleFiltersValues: BookingsFilters
    filtersOpened: boolean
}

const FiltersBar: FunctionComponent<React.PropsWithChildren<FiltersBarProps>> = ({
    onChangeFilters,
    selectedFilters,
    onClearFilters,
    possibleFiltersValues,
    filtersOpened,
}) => {
    const [filters, setFilters] = useState<BookingsFilters>(selectedFilters)

    const [seniorities, setSeniorities] = useState<Array<string> | undefined>([])
    const [roles, setRoles] = useState<Array<string> | undefined>([])
    const [statuses, setStatuses] = useState<Array<string> | undefined>([])

    useEffect(() => {
        setSeniorities(possibleFiltersValues?.seniorities)
        setRoles(possibleFiltersValues?.roles)
        setStatuses(possibleFiltersValues?.statuses)
    }, [filters, possibleFiltersValues])

    const onSelectFilterChange = useCallback(
        (values: Array<string>, filterName: any) => {
            const currentFilters: any = { ...filters }
            if (currentFilters[filterName]) {
                currentFilters[filterName] = values
            }

            setFilters(currentFilters)
            onChangeFilters(currentFilters)
        },
        [onChangeFilters, filters],
    )

    const onClearAll = () => {
        setFilters(initialFilters)
        onClearFilters()
    }

    return (
        <FiltersBarWrapper filtersOpened={filtersOpened}>
            <FlexBox
                css={css`
                    width: 100%;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Roles'
                            options={roles}
                            filterName='roles'
                            currentValues={filters.roles}
                            isActive={(filters?.roles?.length || 0) > 0}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='roles'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Seniority'
                            options={seniorities}
                            filterName='seniorities'
                            isActive={(filters?.seniorities?.length || 0) > 0}
                            currentValues={filters?.seniorities}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='seniority'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Status'
                            options={statuses}
                            filterName='statuses'
                            currentValues={filters.statuses}
                            isActive={(filters?.statuses?.length || 0) > 0}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='status'
                        />
                    </FilterColumn>
                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(filters)} />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { FiltersBar, initialFilters }
