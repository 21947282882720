/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Icon } from '../../components/Icon'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { OpportunityDetails } from '../../components/opportunities/OpportunityDetails'
import { SideMenuBox } from '../../components/SideMenuBox'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getOpportunityAuthorised } from '../../api/opportunities-manager/api'
import { ShareOpportunityModal } from '../../components/opportunities/ShareOpportunityModal'
import { OpportunityOfferTypeEnum, OpportunityResponseBody } from '../../contracts/opportunities-manager/contracts'

import { ApplyLink } from '../../components/opportunities/ApplyLink'
import { OpportunityError } from '../../contracts/open-opportunities/contracts'
import { VMCmenuItems } from '../../components/open-opportunities/utils/openOpportunitiesUtils'
import { rowButtonStyles } from '../../components/open-opportunities/utils/style'
import { applicationType } from '../../components/open-opportunities/utils/const'
import { history } from 'src/history'
import { ContactUsModal } from 'src/components/open-opportunities/ContactUsModal'
import { SubscriptionRequiredModal } from 'src/components/open-opportunities/SubscriptionRequiredModal'

const OpenVacanciesDetails: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { addError } = useNotifications()
    const log = useLogger('error')
    const [opportunity, setOpportunity] = useState<Nullable<Omit<OpportunityResponseBody, 'internalDetails'>>>(null)
    const [error, setError] = useState<OpportunityError>('')
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
    const [isContactUsModalVisible, setIsContactUsModalVisible] = useState<boolean>(false)
    const { opportunityId } = useParams<{ opportunityId: string }>()
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)

    const isPermittedToOpportunitiesEarlyAccess = opportunity !== null && opportunity?.availableForCurrentCompany

    const {
        selectors: {
            featureFlags: { payments },
        },
    } = useContext(ReduxContext)

    const theme = useTheme()

    const {
        selectors: {
            featureFlags: { opportunityContact },
        },
    } = useContext(ReduxContext)

    const loadOpportunity = useCallback(() => {
        if (opportunityId) {
            getOpportunityAuthorised(opportunityId)
                .then(setOpportunity)
                .catch(err => {
                    if (err.data.code === 'OPPORTUNITY_NOT_EXIST') {
                        setError('closed')
                    } else if (err.status === 404 || err.status === 400) {
                        setError('not found')
                    } else {
                        log(err)
                        addError()
                    }
                })
        }
    }, [addError, log, opportunityId])

    useEffect(() => {
        loadOpportunity()
    }, [loadOpportunity])

    const onShare = useCallback(() => {
        setShareModalOpen(true)
    }, [])

    const onShareModalClose = useCallback(() => {
        setShareModalOpen(false)
    }, [])

    const toggleContactUsModal = useCallback(() => {
        setIsContactUsModalVisible(currentIsContactUsModalVisible => !currentIsContactUsModalVisible)
    }, [])

    const toggleSubscriptionModal = useCallback(() => {
        setIsSubscriptionModalOpen(prevState => !prevState)
    }, [])

    const handleSubscribe = useCallback(() => {
        history.push('/dashboard/company-account/subscription/details')
    }, [])

    return (
        <>
            <OpportunityDetails
                applicationMenuItems={VMCmenuItems}
                applicationName='Open Vacancies'
                applicationType={applicationType}
                dataTestId='open-opportunity-header'
                error={error}
                opportunity={opportunity as Nullable<OpportunityResponseBody>}
                shareModal={
                    shareModalOpen &&
                    opportunity && (
                        <ShareOpportunityModal
                            opened={shareModalOpen}
                            onClose={onShareModalClose}
                            opportunityId={opportunity.opportunityId}
                            isVMCCloudApp={true}
                        />
                    )
                }
                secondCol={
                    opportunity && (
                        <SideMenuBox>
                            <ApplyLink
                                opportunity={opportunity}
                                openSubscriptionModal={toggleSubscriptionModal}
                                isPermittedToOpportunitiesEarlyAccess={Boolean(isPermittedToOpportunitiesEarlyAccess)}
                                applicationType='OPEN_VACANCIES'
                            />
                            <div
                                css={css`
                                    color: ${theme.colors.gray_6};
                                    margin: 5px 0;
                                    text-align: center;
                                `}
                            >
                                {payments && !isPermittedToOpportunitiesEarlyAccess
                                    ? 'Applications are currently open to Premium users only. Subscribe for early access to this Vacancy.'
                                    : opportunity.opportunityDetails.offerType === OpportunityOfferTypeEnum.TEAM
                                    ? 'All candidates you submit for this Vacancy will be treated as a team offer.'
                                    : 'Each candidate you submit for this Vacancy will be treated as a separate offer.'}
                            </div>

                            <Divider
                                style={css`
                                    margin: 0;
                                `}
                            />
                            <Button
                                onClick={onShare}
                                variant='link'
                                style={css`
                                    ${rowButtonStyles}
                                    display: flex;
                                    align-items: center;
                                `}
                                dataTestId='open-opportunity-share'
                            >
                                <span
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <Icon
                                        style={css`
                                            margin-right: 8px;
                                        `}
                                        name='share'
                                    />
                                    Share Vacancy
                                </span>
                            </Button>
                            {opportunityContact && (
                                <Fragment>
                                    <Button
                                        onClick={toggleContactUsModal}
                                        variant='link'
                                        style={css`
                                            ${rowButtonStyles}
                                            display: flex;
                                            align-items: center;
                                        `}
                                        dataTestId='open-opportunity-contact-us'
                                    >
                                        <span
                                            css={css`
                                                display: flex;
                                                align-items: center;
                                            `}
                                        >
                                            <Icon
                                                style={css`
                                                    margin-right: 8px;
                                                `}
                                                name='user'
                                            />
                                            Contact Us
                                        </span>
                                    </Button>
                                    {isContactUsModalVisible && (
                                        <ContactUsModal
                                            onClose={toggleContactUsModal}
                                            opportunityId={opportunity.readableId}
                                            title='Ask us about this vacancy'
                                            description='Please message us in case you have a question about this vacancy. Our team usually tries to respond within 1 business
                                            day.'
                                            messagePlaceholder='Ask about vacancy'
                                        />
                                    )}
                                </Fragment>
                            )}
                        </SideMenuBox>
                    )
                }
            />
            <SubscriptionRequiredModal opened={isSubscriptionModalOpen} onClose={toggleSubscriptionModal} onSubscribe={handleSubscribe} />
        </>
    )
}

export { OpenVacanciesDetails }
