/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { mqMax } from 'src/GlobalStyle'
import { convertTimestampToDateString } from 'src/utils/dates'
import { ShareMySpecialistsFormData } from '../../contracts'
import { CellWrapper, InputWrapper } from '../../utils'

interface AvailabilityProps {
    availableDate: number
}

interface AvailabilityEditProps extends AvailabilityProps {
    index: number
    control: Control<ShareMySpecialistsFormData>
    availableName: string
}

const Availability: FunctionComponent<React.PropsWithChildren<AvailabilityProps>> = ({ availableDate }) => (
    <CellWrapper
        css={css`
            min-width: 100px;
            ${mqMax[2]} {
                min-width: none;
            }
        `}
    >
        {availableDate ? convertTimestampToDateString(availableDate) : '-'}
    </CellWrapper>
)

const AvailabilityEdit: FunctionComponent<React.PropsWithChildren<AvailabilityEditProps>> = ({
    availableDate,
    index,
    control,
    availableName,
}) => (
    <InputWrapper
        css={css`
            max-width: 120px;
            ${mqMax[2]} {
                max-width: none;
            }
        `}
    >
        <ControlledDatePickerInput
            name={`specialists.${index}.${availableName}`}
            defaultValue={availableDate}
            placeholder={availableName === 'availableFrom' ? 'From' : 'To (Optional)'}
            control={control}
        />
    </InputWrapper>
)

export { Availability, AvailabilityEdit }
