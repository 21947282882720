/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Icon } from '../../components/Icon'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { OpportunityDetails } from '../../components/opportunities/OpportunityDetails'
import { SideMenuBox } from '../../components/SideMenuBox'
import { history } from '../../history'
import { useUserAuthentication } from '../../hooks/useUserAuthentication'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { ApplyLink } from '../../components/opportunities/ApplyLink'
import { OpportunityError } from '../../contracts/open-opportunities/contracts'
import { VMCmenuItems } from '../../components/open-opportunities/utils/openOpportunitiesUtils'
import { getOpportunityAuthorised, getSharedOpportunity } from '../../api/opportunities-manager/api'
import { ShareOpportunityModal } from '../../components/opportunities/ShareOpportunityModal'
import { OpportunityOfferTypeEnum, OpportunityResponseBody } from '../../contracts/opportunities-manager/contracts'

import { ReduxContext } from 'src/redux/Store'
import { SubscriptionRequiredModal } from 'src/components/open-opportunities/SubscriptionRequiredModal'

const rowButtonStyles = css`
    width: 100%;
`

const SharedVacancyPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { addError } = useNotifications()
    const log = useLogger('error')
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
    const [opportunity, setOpportunity] = useState<Nullable<Omit<OpportunityResponseBody, 'internalDetails'>>>(null)
    const [error, setError] = useState<OpportunityError>('')
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false)
    const { sharedLinkId: opportunityId } = useParams<{ sharedLinkId: string }>()

    const { isUserAuthorized, isSpecialistAccount } = useUserAuthentication()

    const {
        selectors: {
            featureFlags: { payments },
        },
    } = useContext(ReduxContext)

    const isPermittedToOpportunitiesEarlyAccess = (opportunity !== null && opportunity?.availableForCurrentCompany) || false

    const loadOpportunity = useCallback(
        (id: any) => {
            const request = isUserAuthorized ? getOpportunityAuthorised : getSharedOpportunity
            request(id)
                .then(setOpportunity)
                .catch(err => {
                    if (err.data.code === 'OPPORTUNITY_NOT_EXIST') {
                        setError('closed')
                    } else if (err.status === 404 || err.status === 400) {
                        setError('not found')
                    } else {
                        log(err)
                        addError()
                    }
                })
        },
        [addError, isUserAuthorized, log],
    )

    useEffect(() => {
        if (opportunityId) {
            loadOpportunity(opportunityId)
        }
    }, [loadOpportunity, opportunityId])

    const onShare = useCallback(() => {
        setShareModalOpen(true)
    }, [])

    const onShareModalClose = useCallback(() => {
        setShareModalOpen(false)
    }, [])

    const goToLoginPage = useCallback(() => {
        history.push(`/logout?afterLogin=${encodeURIComponent(`/open-opportunities/${opportunityId}`)}`)
    }, [opportunityId])

    const toggleSubscriptionModal = useCallback(() => {
        setIsSubscriptionModalOpen(prevState => !prevState)
    }, [])

    const handleSubscribe = useCallback(() => {
        history.push('/dashboard/company-account/subscription/details')
    }, [])

    const theme = useTheme()

    return (
        <>
            <OpportunityDetails
                applicationMenuItems={isUserAuthorized && !isSpecialistAccount && VMCmenuItems}
                applicationName={isUserAuthorized && !isSpecialistAccount && 'Open Vacancies'}
                applicationType={isUserAuthorized && !isSpecialistAccount && 'OPEN_VACANCIES'}
                dataTestId='opportunity-details-header'
                error={error}
                notFoundBacklinkText={isUserAuthorized && !isSpecialistAccount ? 'Go to list of vacancies' : 'Go to the main page'}
                opportunity={opportunity as Nullable<OpportunityResponseBody>}
                shareModal={
                    shareModalOpen &&
                    opportunity && (
                        <ShareOpportunityModal
                            opened={shareModalOpen}
                            onClose={onShareModalClose}
                            opportunityId={opportunity.opportunityId}
                            isVMCCloudApp={true}
                        />
                    )
                }
                secondCol={
                    opportunity && (
                        <SideMenuBox>
                            {isUserAuthorized ? (
                                <Fragment>
                                    <ApplyLink
                                        opportunity={opportunity}
                                        openSubscriptionModal={toggleSubscriptionModal}
                                        isPermittedToOpportunitiesEarlyAccess={isPermittedToOpportunitiesEarlyAccess}
                                        applicationType='OPEN_VACANCIES'
                                    />
                                    <div
                                        css={css`
                                            color: ${theme.colors.gray_6};
                                            margin: 5px 0;
                                            text-align: center;
                                        `}
                                    >
                                        {payments && !isPermittedToOpportunitiesEarlyAccess
                                            ? 'Applications are currently open to Premium users only. Subscribe for early access to this Vacancy.'
                                            : opportunity.opportunityDetails.offerType === OpportunityOfferTypeEnum.TEAM
                                            ? 'All candidates you submit for this Opportunity will be treated as a team offer.'
                                            : 'Each candidate you submit for this Opportunity will be treated as a separate offer.'}
                                    </div>
                                </Fragment>
                            ) : (
                                <Button
                                    style={css`
                                        ${rowButtonStyles};
                                        margin-bottom: 10px;
                                    `}
                                    onClick={goToLoginPage}
                                    size='big'
                                >
                                    Sign in to apply
                                </Button>
                            )}
                            <Divider
                                style={css`
                                    margin: 0;
                                `}
                            />
                            <Button
                                onClick={onShare}
                                variant='link'
                                style={css`
                                    ${rowButtonStyles}
                                    display: flex;
                                    align-items: center;
                                `}
                                dataTestId='opportunity-share'
                            >
                                <span
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <Icon
                                        style={css`
                                            margin-right: 8px;
                                        `}
                                        name='share'
                                    />
                                    Share Vacancy
                                </span>
                            </Button>
                        </SideMenuBox>
                    )
                }
            />
            <SubscriptionRequiredModal opened={isSubscriptionModalOpen} onClose={toggleSubscriptionModal} onSubscribe={handleSubscribe} />
        </>
    )
}

export { SharedVacancyPage }
