/** @jsxImportSource @emotion/react */

import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { FullProfileData, getFullProfileDataToExport } from '../../api/api'
import { useRoutesMatching } from '../../hooks/useRoutesMatching'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getLink } from '../profile/shared/helpers'
import { PdfDoc } from './components/PdfDoc/PdfDoc'
import { QuickSummary } from './components/QuickSummary/QuickSummary'

const defaultSpecialistId = 'b5ababd0-e496-481c-9c54-cd6206563855'

const customGlobalStyles = `
    *[data-avoid-break]{
        page-break-inside: avoid !important;
    }

  
    
    @page {
        margin-top: 35px;
        min-height: 100%;
    }

    
    @page :first {
        margin-top: 0px;
    }

    .quick-summary *{
        opacity:1 !important;
        z-index: 1 !important;
    }


`

type QuickProps = {
    specialistId?: string
    forceDownload?: boolean
    isAnonymized?: boolean
    showBigFive?: boolean
    chosenProjects?: Nullable<Array<string>>
    onCloseModal?: () => void
    anonymousProfile?: FullProfileData
}

const Quick: FunctionComponent<React.PropsWithChildren<QuickProps>> = ({
    specialistId = defaultSpecialistId,
    forceDownload = false,
    isAnonymized = false,
    showBigFive = true,
    chosenProjects,
    onCloseModal,
    anonymousProfile,
}) => {
    const {
        actions: { layoutToggleLoader },
        selectors: { whiteLabel },
    } = useContext(ReduxContext)
    const log = useLogger('error')

    const [profileData, setProfileData] = useState<Nullable<FullProfileData>>(null)

    const anonymized = useMemo(() => isAnonymized, [isAnonymized])

    const { matchTalentMarketplaceProfile, matchPublicProfile, matchAnonymousProfile } = useRoutesMatching()

    useEffect(() => {
        layoutToggleLoader(true)
        if (anonymousProfile) {
            setProfileData({
                ...anonymousProfile,
                link: getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, anonymousProfile.link),
            })
            layoutToggleLoader(false)
        } else {
            getFullProfileDataToExport(specialistId, true, true)
                .then(data => {
                    setProfileData({
                        ...data,
                        link: getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, data.link),
                    })
                })
                .catch(log)
                .finally(() => layoutToggleLoader(false))
        }
    }, [anonymousProfile, layoutToggleLoader, log, matchAnonymousProfile, matchPublicProfile, matchTalentMarketplaceProfile, specialistId])

    return profileData ? (
        <PdfDoc
            customGlobalStyles={customGlobalStyles}
            fileName={`profile-quick-${specialistId}`}
            preview={!forceDownload}
            onCloseModal={onCloseModal}
        >
            <QuickSummary
                profileData={profileData}
                isAnonymized={anonymized}
                showBigFive={showBigFive}
                chosenProjects={chosenProjects}
                whiteLabel={whiteLabel || undefined}
            />
        </PdfDoc>
    ) : null
}

export { Quick }
