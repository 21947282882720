/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useMemo } from 'react'
import { Skill, SkillProficiency, SkillStatus } from '../../contracts/skills'
import { mqMax } from '../../GlobalStyle'
import { StringMapping } from '../../types'
import { Button } from '../Button'
import { RadioButton, RadioGroup } from '../inputs/Radio'
import { FlexBox } from '../layout/FlexBoxHelpers'

type SkillsQuestionnaireItemProps = {
    highlighted?: boolean
    unvalidated?: boolean
    removable?: boolean
    value?: Skill
    onChange?: (skill: Skill, category?: string) => void
    category?: string
    labels?: StringMapping
    expandedScale?: boolean
}

const defaultLabels: StringMapping = {
    [SkillProficiency.BASIC]: 'Basic',
    [SkillProficiency.GOOD]: 'Good',
    [SkillProficiency.EXPERT]: 'Expert',
    [SkillProficiency.NOT_RATED]: 'Not rated',
}

const SkillsQuestionnaireItem: FunctionComponent<React.PropsWithChildren<SkillsQuestionnaireItemProps>> = ({
    highlighted,
    unvalidated = false,
    removable = false,
    value,
    category,
    onChange,
    labels = defaultLabels,
    expandedScale = false,
}) => {
    const theme = useTheme()

    const handleChange = useCallback(
        (e: any) => {
            if (onChange && value) {
                onChange({ ...value, proficiency: e.target.value }, category)
            }
        },
        [value, onChange, category],
    )

    const handleNotSkillClick = useCallback(
        (e: any) => {
            if (onChange && value) {
                onChange(
                    {
                        ...value,
                        proficiency: value?.proficiency || SkillProficiency.NOT_RATED,
                        status: SkillStatus.NOT_VALID,
                    },
                    category,
                )
            }
        },
        [value, onChange, category],
    )

    const handleRemoveClick = useCallback(
        (e: any) => {
            if (onChange && value) {
                onChange(
                    {
                        ...value,
                        proficiency: value?.proficiency || SkillProficiency.NOT_RATED,
                        status: SkillStatus.DELETED,
                    },
                    category,
                )
            }
        },
        [value, onChange, category],
    )
    const handleUndoClick = useCallback(
        (e: any) => {
            if (onChange && value) {
                onChange(
                    {
                        ...value,
                        proficiency: value?.proficiency || SkillProficiency.NOT_RATED,
                        status: SkillStatus.IN_PROGRESS,
                    },
                    category,
                )
            }
        },
        [value, onChange, category],
    )

    const isNotEditable = useMemo(() => value?.status && value.status !== SkillStatus.IN_PROGRESS, [value])

    return (
        <FlexBox
            alignItems='center'
            justifyContent='space-between'
            data-testid='skills-questionnaire-item'
            css={css`
                padding: 16px;
                border-radius: 2px;
                width: 100%;
                border-bottom: 1px solid ${theme.colors.gray_2};

                ${isNotEditable ? `background-color:${theme.colors.beige_1};` : `background-color:${theme.colors.white};`}

                ${mqMax[1]} {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 16px 8px;
                }
            `}
        >
            <div
                css={css`
                    color: ${isNotEditable ? theme.colors.gray_3 : theme.colors.gray_6};
                `}
            >
                {value?.name} {value?.status === SkillStatus.NOT_VALID && '- Not a skill'}
                {value?.status === SkillStatus.DELETED && '- Removed'}
            </div>
            {isNotEditable ? (
                <Button
                    variant='linkForm'
                    onClick={handleUndoClick}
                    style={css`
                        padding: 12px 0;
                        ${mqMax[1]} {
                            margin-top: 16px;
                        }
                    `}
                >
                    Undo
                </Button>
            ) : (
                <div>
                    <RadioGroup
                        onChange={handleChange}
                        value={value?.proficiency}
                        styles={css`
                            ${mqMax[1]} {
                                margin-top: 16px;
                            }
                        `}
                    >
                        <RadioButton
                            style={css`
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `}
                            value={SkillProficiency.BASIC}
                            label={labels[SkillProficiency.BASIC]}
                            checked={value?.proficiency === SkillProficiency.BASIC}
                        />
                        <RadioButton
                            style={css`
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `}
                            value={SkillProficiency.GOOD}
                            label={labels[SkillProficiency.GOOD]}
                            checked={value?.proficiency === SkillProficiency.GOOD}
                        />
                        <RadioButton
                            style={css`
                                margin-right: ${expandedScale ? '8px' : 0};
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `}
                            value={SkillProficiency.EXPERT}
                            label={labels[SkillProficiency.EXPERT]}
                            checked={value?.proficiency === SkillProficiency.EXPERT}
                        />

                        {expandedScale && (
                            <RadioButton
                                style={css`
                                    margin: 0;
                                    border-color: transparent;
                                    box-shadow: none;

                                    &:not(.ant-radio-button-wrapper-checked) {
                                        background-color: transparent;

                                        &:hover {
                                            border-color: transparent;
                                            text-decoration: underline;
                                            text-decoration-color: ${theme.colors.main};
                                        }
                                    }

                                    ${mqMax[1]} {
                                        padding: 12px;
                                    }
                                `}
                                value={SkillProficiency.NOT_RATED}
                                label={labels[SkillProficiency.NOT_RATED]}
                                checked={value?.proficiency === SkillProficiency.NOT_RATED}
                            />
                        )}
                    </RadioGroup>

                    {!expandedScale && (
                        <Fragment>
                            {unvalidated && (
                                <Button
                                    variant='link'
                                    style={css`
                                        margin-left: 16px;
                                        padding: 12px 0;
                                        ${mqMax[1]} {
                                            margin-top: 16px;
                                            margin-left: 8px;
                                        }
                                    `}
                                    onClick={handleNotSkillClick}
                                >
                                    Not a skill
                                </Button>
                            )}

                            {removable && (
                                <Button
                                    variant='link'
                                    onClick={handleRemoveClick}
                                    style={css`
                                        margin-left: 16px;
                                        padding: 12px 0;
                                        ${mqMax[1]} {
                                            margin-top: 16px;
                                            margin-left: 8px;
                                        }
                                    `}
                                >
                                    Remove
                                </Button>
                            )}
                        </Fragment>
                    )}
                </div>
            )}
        </FlexBox>
    )
}

export { SkillsQuestionnaireItem }
