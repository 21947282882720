import { Collapse } from 'antd'

import { StyledSectionHeader, StyledCollapse } from './styles'
import { MY_SPECIALISTS } from './consts'
import { useHandleQuestionClick } from './hooks'

const MySpecialists = ({ setQuestion, question }: { setQuestion: (q: string) => void; question: string | undefined }) => {
    const onQuestionClick = useHandleQuestionClick(setQuestion)

    return (
        <StyledCollapse defaultActiveKey={question} onChange={onQuestionClick}>
            <Collapse.Panel
                key={MY_SPECIALISTS.MY_SPECIALISTS_APP_OVERVIEW}
                header={<StyledSectionHeader>App overview</StyledSectionHeader>}
            >
                <p>My Specialists app allows you to add and manage your specialists. There are 3 ways new candidates can be created:</p>
                <ul>
                    <li>Simply adding a new empty profile.</li>
                    <li>Using CV parsing to create a profile with some skills added.</li>
                    <li>Using the Bulk Upload feature to quickly set up a large number of profiles.</li>
                </ul>
            </Collapse.Panel>
            <Collapse.Panel
                key={MY_SPECIALISTS.MY_SPECIALISTS_ADDED_SPECIALISTS}
                header={<StyledSectionHeader>Will other users see my added specialists?</StyledSectionHeader>}
            >
                <p>
                    Not unless you explicitly share any of them. Any specialists added to your company profile will be visible only to
                    members of your company by default. There is an option for you to share your specialist profiles located on the Share My
                    Specialists tab where you can select which specialists you would like to share.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={MY_SPECIALISTS.MY_SPECIALISTS_MANAGE_SPECIALISTS}
                header={
                    <StyledSectionHeader>How do I invite more people to my company account to manage my specialists?</StyledSectionHeader>
                }
            >
                <p>
                    You can invite more users to manage the data in your company. You can use the Role Management feature that is located in
                    the Company Account section of the app (visible if you click the person icon in the top right-hand corner of the
                    screen). You need to provide the user’s email address and they will need to register on our platform using the invite
                    link. The user will then receive an invite email. Once the user has registered, they will be able to manage your company
                    data, including specialists, applying for opportunities, etc.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={MY_SPECIALISTS.MY_SPECIALISTS_SHARE_SPECIALIST}
                header={<StyledSectionHeader>How do I share a specialist?</StyledSectionHeader>}
            >
                <p>
                    You can share your specialists in the Share My Specialists tab in the My Specialists app. You will need to set some
                    fields, such as hourly rate, availability, and specialist location before you can share a specialist.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={MY_SPECIALISTS.MY_SPECIALISTS_TECHNICAL_SKILLS}
                header={<StyledSectionHeader>How do I add technical skills for a specialist?</StyledSectionHeader>}
            >
                <p>
                    On the list of specialists click on any row. Then on the detailed specialist view, go to the Technical Skills tab and
                    click the Edit button there. You will be able to add, remove and rate skills in this view.
                </p>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export { MySpecialists }
