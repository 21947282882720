/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useCallback } from 'react'
import { Button } from '../../../../components/Button'
import { COLOR_PALETTE, mqMax } from '../../../../GlobalStyle'
import { FlowStep } from '../../enums'
import { GamePlay } from '../GamePlay'
import disruption from './../../assets/disruption.png'
import tap from './../../assets/tap.png'

type TapIconProps = {
    bottom: number
    left: number
}

const TapIcon: FunctionComponent<React.PropsWithChildren<TapIconProps>> = ({ bottom, left }) => (
    <img
        src={tap}
        alt=''
        css={css`
            max-height: 54px;
            position: absolute;
            bottom: ${bottom + 'px'};
            left: ${left + 'px'};
            z-index: 99999;
        `}
    />
)

const ListItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
`

const Title = styled.div`
    background-color: ${COLOR_PALETTE.green_2};
    width: 60px;
    text-align: center;
    font-weight: bold;
    padding: 3px 0;
    margin-bottom: 10px;
`

const Description = styled.div`
    width: 266px;
    text-align: center;
    font-size: 16px;
`

const CenteredBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StepPreview = styled.div`
    width: 90%;
    height: 270px;
    margin-bottom: 55px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
`

type InstructionProps = {
    changeStep: (step: FlowStep) => void
}

const Instruction: FunctionComponent<React.PropsWithChildren<InstructionProps>> = ({ changeStep }) => {
    const handleStartGame = useCallback(() => {
        changeStep(FlowStep.Memorize)
    }, [changeStep])

    return (
        <section
            css={css`
                width: 997px;
                height: 100%;
                display: flex;
                justify-content: space-between;
                flex-direction: column;

                ${mqMax[1]} {
                    width: 100%;
                }
            `}
        >
            <CenteredBox>
                <h1
                    css={css`
                        font-size: 32px;
                        line-height: 32px;
                        margin-bottom: 50px;
                    `}
                >
                    How to play
                </h1>
            </CenteredBox>

            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;

                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <ListItem>
                    <StepPreview>
                        <GamePlay animation={false} />
                    </StepPreview>
                    <Title>Step 1</Title>
                    <Description>
                        <strong>Memorize</strong> the positions of the black dots.
                    </Description>
                </ListItem>

                <ListItem>
                    <StepPreview>
                        <TapIcon bottom={-10} left={65} />
                        <img
                            src={disruption}
                            alt=''
                            css={css`
                                max-height: 260px;
                            `}
                        />
                    </StepPreview>
                    <Title>Step 2</Title>
                    <Description>
                        <strong>Click the shape in the bottom row</strong> that matches one of the shapes in the top row.
                    </Description>
                </ListItem>

                <ListItem>
                    <StepPreview>
                        <TapIcon bottom={58} left={47} />
                        <GamePlay />
                    </StepPreview>
                    <Title>Step 3</Title>
                    <Description>
                        <strong>Then click on the dots</strong> that were black on the first screen.
                    </Description>
                </ListItem>
            </div>

            <CenteredBox>
                <Button
                    css={css`
                        width: 350px;
                        text-align: center;

                        ${mqMax[1]} {
                            width: 100%;
                            margin-bottom: 24px;
                        }
                    `}
                    onClick={handleStartGame}
                >
                    Start practice game
                </Button>
            </CenteredBox>
        </section>
    )
}

export { Instruction }
