/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { signOut } from '../../api/firebase'
import { ReduxContext } from '../../redux/Store'
import { useQuery } from '../../utils/useQuery'
import { RecoverPasswordNewPassword } from '../recover-password/RecoverPasswordNewPassword'
import { VerifyEmail } from '../register/VerifyEmail'
import { ConfirmEmail } from '../my-specialists/ConfirmEmail'

type Modes = 'resetPassword' | 'verifyEmail' | 'signIn'

type QueryParams = {
    mode: Modes
    oobCode: string
    apiKey?: string
    continueUrl?: string
}

const FirebaseActionHandler = () => {
    const query = useQuery()
    const navigate = useNavigate()

    const {
        actions: { userLogout },
    } = useContext(ReduxContext)

    const params: QueryParams = useMemo(() => {
        const queryParams: QueryParams = {
            mode: query.get('mode') as Modes,
            oobCode: query.get('oobCode') as string,
        }

        if (queryParams.mode === 'signIn') {
            queryParams.apiKey = query.get('apiKey') as string
            queryParams.continueUrl = query.get('continueUrl') as string
        }

        return queryParams
    }, [query])

    const actionHandler = useMemo(() => {
        if (params.oobCode) {
            switch (params.mode) {
                case 'resetPassword':
                    return <RecoverPasswordNewPassword actionCode={params.oobCode} />

                case 'verifyEmail':
                    return <VerifyEmail actionCode={params.oobCode} />

                case 'signIn':
                    return <ConfirmEmail oobCode={params.oobCode} continueUrl={params.continueUrl || null} />
            }
        } else {
            navigate('/access-denied')
        }
    }, [params.mode, params.oobCode, navigate, params.continueUrl])

    useEffect(() => {
        signOut().then(userLogout)
    }, [userLogout])

    return <Fragment>{actionHandler}</Fragment>
}

export { FirebaseActionHandler }
