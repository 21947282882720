/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import normalizeUrl from 'normalize-url'
import { Fragment, FunctionComponent } from 'react'
import { mapGenderValue } from '../../../contracts/gender'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { convertTimestampToDateString } from '../../../utils/dates'
import { SpecialistProfileInformationResponse } from '../contracts'

const GreyLabel = styled.label`
    font-size: 12px;
    color: ${COLOR_PALETTE.gray_4};
    line-height: 22px;
`
type ContentTextProps = {
    color?: string
}

const ContentText = styled.div<ContentTextProps>`
    font-size: 14px;
    color: ${(props: ContentTextProps) => (props.color ? props.color : COLOR_PALETTE.gray_6)};
    margin-bottom: 24px;
`

type PersonalDataProps = {
    personalData: SpecialistProfileInformationResponse
}

const PersonalTabReadOnly: FunctionComponent<React.PropsWithChildren<PersonalDataProps>> = ({ personalData }) => {
    const { birthDate, gender, city, country, street, phone, postalCode, socialLinks = [] } = personalData
    const birthDateString = birthDate ? convertTimestampToDateString(birthDate) : '-'
    const genderString = gender ? mapGenderValue(gender) : '-'

    return (
        <div>
            <GreyLabel>Phone</GreyLabel>
            <ContentText data-testid='personal-tab-ro-phone'>{phone || '-'}</ContentText>
            <GreyLabel>Birth Date</GreyLabel>
            <ContentText data-testid='personal-tab-ro-birthday'>{birthDateString || '-'}</ContentText>
            <GreyLabel>Gender</GreyLabel>
            <ContentText data-testid='personal-tab-ro-gender'>{genderString}</ContentText>
            <h6>Home Address</h6>
            <GreyLabel>Country</GreyLabel>
            <ContentText data-testid='personal-tab-ro-country'>{country || '-'}</ContentText>
            <GreyLabel>City</GreyLabel>
            <ContentText data-testid='personal-tab-ro-city'>{city || '-'}</ContentText>
            <GreyLabel>Street Address</GreyLabel>
            <ContentText data-testid='personal-tab-ro-street'>{street || '-'}</ContentText>
            <GreyLabel>Postal</GreyLabel>
            <ContentText data-testid='personal-tab-ro-postal-code'>{postalCode || '-'}</ContentText>
            <h6>Social Links</h6>
            {socialLinks.length === 0 ? (
                <div data-testid='personal-tab-ro-no-social'>-</div>
            ) : (
                socialLinks.map(({ label, url }) => {
                    return (
                        <Fragment key={url}>
                            <GreyLabel>{label}</GreyLabel>
                            <ContentText>
                                <a data-testid='personal-tab-ro-social' href={normalizeUrl(url, { defaultProtocol: 'https:' })}>
                                    {url}
                                </a>
                            </ContentText>
                        </Fragment>
                    )
                })
            )}
        </div>
    )
}

export { PersonalTabReadOnly }
