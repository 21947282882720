/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { SkillMatrix as SkillMatrixModel } from '../contracts/profile/skillMatrix'
import { COLOR_PALETTE, mqMax, mqMin } from '../GlobalStyle'
import { ReduxContext } from '../redux/Store'
import { Button } from './Button'
import { AutocompleteSelect } from './inputs/autocomplete-select/AutocompleteSelect'
import { SkillsList } from './talent/SkillsList'
import { NavBar } from './NavBar'
import { Icon } from './Icon'
import { CVButton, CVContainer, StyledLink } from './shared/shared-styles'
import { useNotifications } from './notification/NotificationProvider'
import { downloadAnonymizedCV } from 'src/api/api'
import { useLogger } from 'src/utils/useLogger'
import { NoData } from './NoData'
import { saveAs } from 'file-saver'
import { Nullable } from 'src/types'
import { fileTypeToExtension } from 'src/utils/values'

const SkillMatrixContainer = styled.div`
    margin-top: 24px;
    display: flex;

    ${mqMax[1]} {
        flex-direction: column;
        margin-top: 0;
    }
`

const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    height: 104px;
    background-color: ${COLOR_PALETTE.gray_1};
    margin: 0 -16px 28px;
    padding: 0 16px;

    ${mqMin[1]} {
        display: none;
    }
`

const ContentContainer = styled.div`
    flex: 5;
    margin-left: 44px;

    ${mqMax[1]} {
        margin-left: 0;
    }
`

const ZeroMarginH6 = styled.h6`
    margin: 0;
`

const SubcategoryContainer = styled.div`
    margin-top: 22px;
`

const SubcategoryHeader = styled.div`
    padding: 7px 15px;
    background-color: ${COLOR_PALETTE.gray_1};
`

const SubcategoryContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 15px;
`

const SubcategoryLevelContainer = styled.div`
    display: flex;
    padding: 26px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.gray_1};

    &:last-child {
        border-bottom: none;
    }
`

const SubcategoryLevelTitle = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    color: ${COLOR_PALETTE.gray_4};
    font-size: 12px;
`

const SubcategoryLevelItems = styled.div`
    flex: 4;
    display: flex;
    flex-wrap: wrap;
`

type SkillMatrixProps = {
    skillMatrix: SkillMatrixModel
    id?: Nullable<string>
    hasAnonymizedCv?: boolean
    filename?: string
}

const SkillMatrix: FunctionComponent<React.PropsWithChildren<SkillMatrixProps>> = ({ skillMatrix, id, hasAnonymizedCv, filename }) => {
    const [selectedCategory, setSelectedCategory] = useState<string>(skillMatrix?.categories[0]?.name)
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const navbarCategories = useMemo(() => skillMatrix.categories.map(category => category.name), [skillMatrix])
    const { addError } = useNotifications()
    const log = useLogger()

    const handleCVDownload = useCallback(() => {
        if (id) {
            downloadAnonymizedCV(id)
                .then(data => {
                    saveAs(data, `${filename}${fileTypeToExtension(data.type)}`)
                })
                .catch(err => {
                    addError()
                    log(err)
                })
        }
    }, [id, filename, addError, log])

    return (
        <SkillMatrixContainer>
            {!!skillMatrix?.categories?.length && (
                <Fragment>
                    <NavBar items={navbarCategories} handleClick={setSelectedCategory} selectedItem={selectedCategory} onlyDesktop />

                    <SelectContainer>
                        <AutocompleteSelect
                            canFilter={false}
                            options={skillMatrix.categories.map(category => category.name)}
                            onSelectedValuesChange={(values: Array<string>) => setSelectedCategory(values[0])}
                            currentValues={[selectedCategory]}
                        ></AutocompleteSelect>
                    </SelectContainer>
                    <ContentContainer>
                        <ZeroMarginH6>{selectedCategory}</ZeroMarginH6>
                        {skillMatrix.categories
                            .find(category => category.name === selectedCategory)
                            ?.subcategories.map(subscategory => {
                                return (
                                    <SubcategoryContainer key={subscategory.name}>
                                        <SubcategoryHeader>{subscategory.name}</SubcategoryHeader>
                                        <SubcategoryContentContainer>
                                            {subscategory.expert.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Expert</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems data-testid='skill-list-expert'>
                                                        <SkillsList skills={subscategory.expert} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}

                                            {subscategory.good.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Good</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems data-testid='skill-list-good'>
                                                        <SkillsList skills={subscategory.good} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}

                                            {subscategory.basic.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Basic</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems data-testid='skill-list-basic'>
                                                        <SkillsList skills={subscategory.basic} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}

                                            {subscategory.notRated.length > 0 && (
                                                <SubcategoryLevelContainer>
                                                    <SubcategoryLevelTitle>Declared Skills</SubcategoryLevelTitle>
                                                    <SubcategoryLevelItems data-testid='skill-list-not-rated'>
                                                        <SkillsList skills={subscategory.notRated} />
                                                    </SubcategoryLevelItems>
                                                </SubcategoryLevelContainer>
                                            )}
                                        </SubcategoryContentContainer>
                                    </SubcategoryContainer>
                                )
                            })}
                    </ContentContainer>
                </Fragment>
            )}

            {!skillMatrix?.categories?.length && (
                <CVContainer alignItems='center'>
                    {!decodedAccessToken?.specialistId && (
                        <Fragment>
                            {hasAnonymizedCv ? (
                                <Fragment>
                                    <Icon name='cv' size={24} />
                                    <CVButton onClick={handleCVDownload} dataTestId='skills-view-cv-button'>
                                        Download CV
                                    </CVButton>
                                </Fragment>
                            ) : (
                                <NoData />
                            )}
                        </Fragment>
                    )}
                    {decodedAccessToken?.specialistId && (
                        <Fragment>
                            <NoData />
                            <StyledLink to={'/specialist-skills-edit'}>
                                <Button>Add Skills</Button>
                            </StyledLink>
                        </Fragment>
                    )}
                </CVContainer>
            )}
        </SkillMatrixContainer>
    )
}

export { SkillMatrix }
