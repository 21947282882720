/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '../../../components/Icon'
import { Logo } from '../../../components/Logo'
import { COLOR_PALETTE } from '../../../theme/colors'
import { SendMagicLinkForm } from './SendMagicLinkForm'

type SendMagicLinkProps = {
    header: string
    description: string
}

const SendMagicLink: FunctionComponent<React.PropsWithChildren<SendMagicLinkProps>> = ({ header, description }) => (
    <div
        css={css`
            display: flex;
            box-sizing: border-box;
            flex-direction: column;
            height: 100vh;
            padding: 48px 1rem;
        `}
    >
        <div
            css={css`
                max-width: 610px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                flex: 1;
            `}
        >
            <Link to='/'>
                <Logo
                    type='logoFull'
                    css={css`
                        align-self: self-start;
                        margin-bottom: 42px;
                    `}
                />
            </Link>
            <div
                css={css`
                    width: 80px;
                    height: 80px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 3px solid ${COLOR_PALETTE.yellow_4};
                    border-radius: 40px;
                    margin-bottom: 30px;
                `}
            >
                <Icon name='id' size={50} />
            </div>
            <h3
                css={css`
                    margin-bottom: 32px;
                `}
            >
                {header}
            </h3>

            <p
                css={css`
                    color: ${COLOR_PALETTE.gray_6};
                `}
            >
                {description}
            </p>

            <SendMagicLinkForm />
        </div>
    </div>
)

export { SendMagicLink }
