import { CardsType, KanbanCard, KanbanColumnType } from './contract'

const generateState = <T>(columns: Array<KanbanColumnType>, data: Array<KanbanCard<T>>) => {
    const cards: CardsType<T> = {}
    columns.forEach(column => {
        cards[column.id] = []
    })
    data.forEach(dataItem => {
        cards[dataItem.columnId].push(dataItem)
    })
    return cards
}

const getCardsCount = <T>(cards: CardsType<T>): number => {
    let count = 0
    Object.values(cards).forEach(kanbanCards => {
        count += kanbanCards.length
    })
    return count
}

export { generateState, getCardsCount }
