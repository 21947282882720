/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { AccessLevelContext } from '../../../../AccessLevelContext'
import { useUserContext } from '../../../../hooks/useUserContext'

const CompanyAccessLevelGuard: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const {
        dashboard: { myAccount: companyAccountAccess },
    } = useContext(AccessLevelContext)

    const navigate = useNavigate()

    const { userActiveContextGroups } = useUserContext()

    useEffect(() => {
        if (!companyAccountAccess && userActiveContextGroups) {
            navigate('/access-denied')
        }
    }, [navigate, companyAccountAccess, userActiveContextGroups])

    return companyAccountAccess ? <Fragment>{children}</Fragment> : null
}

export { CompanyAccessLevelGuard }
