/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { usePromise } from 'react-use'
import { getSpecialistTechSkills } from '../../../api/api'
import { Button } from '../../../components/Button'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { SkillMatrix as SkillMatrixComponent } from '../../../components/SkillMatrix'
import { SkillMatrix } from '../../../contracts/profile/skillMatrix'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

type TechnicalSkillsTabProps = {
    specialistId: string
}

const TechnicalSkillsTab: FunctionComponent<React.PropsWithChildren<TechnicalSkillsTabProps>> = ({ specialistId }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [skillMatrix, setSkillMatrix] = useState<Nullable<SkillMatrix>>(null)
    const [temporarySkillMatrix, setTemporarySkillMatrix] = useState<Nullable<SkillMatrix>>(null)

    const isMounted = usePromise()
    const log = useLogger()

    useEffect(() => {
        setTemporarySkillMatrix(skillMatrix)
    }, [skillMatrix])

    const fetchSkills = useCallback((): void => {
        setLoading(true)

        isMounted(
            getSpecialistTechSkills(specialistId)
                .then(setSkillMatrix)
                .catch(log)
                .finally(() => {
                    setLoading(false)
                }),
        )
    }, [isMounted, specialistId, log])

    useEffect(() => {
        fetchSkills()
    }, [fetchSkills])

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Technical Skills' divider>
                <Link
                    to={`/my-specialists/specialist/${specialistId}/skills-edit`}
                    css={css`
                        &:hover {
                            text-decoration: none;
                        }
                    `}
                >
                    <Button variant='tertiary' dataTestId='edit-skills'>
                        Edit
                    </Button>
                </Link>
            </SectionHeaderWithActions>

            <section
                css={css`
                    position: relative;
                `}
            >
                {skillMatrix && temporarySkillMatrix && (
                    <Fragment>
                        {skillMatrix && skillMatrix.categories.length > 0 ? (
                            <SkillMatrixComponent skillMatrix={skillMatrix} />
                        ) : (
                            <SectionDataNotFound
                                title='Add first skill'
                                iconName='add-empty'
                                description='Currently there are no skills added. Please use "Edit" button in the top right corner to add and rate new skills. Or you can click Send Request/Send Reminder button on the right to email your specialist and they will be able to add and rate their skills.'
                                iconSize={85}
                            />
                        )}
                    </Fragment>
                )}

                {loading && <OverlayContentLoader />}
            </section>
        </SectionWrapper>
    )
}

export { TechnicalSkillsTab }
