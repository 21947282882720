import { Nullable, StringMapping } from '../../types'

export enum EducationType {
    CERTIFICATE_COURSE = 'CERTIFICATE_COURSE',
    LANGUAGE_COURSE = 'LANGUAGE_COURSE',
    BACHELOR_STUDIES = 'BACHELOR_STUDIES',
    MASTER_STUDIES = 'MASTER_STUDIES',
    PHD_STUDIES = 'PHD_STUDIES',
    OTHER_STUDIES = 'OTHER_STUDIES',
}

export enum EducationStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    DELETED = 'DELETED',
    NOT_VALID = 'NOT_VALID',
}

export type Education = {
    id?: string
    type: Nullable<EducationType>
    from: Nullable<number>
    to: Nullable<number>
    inProgress: boolean
    studiesSpecialization: string
    studiesDepartment: string
    studiesUniversity: string
    certificationAcquisitionDate: Nullable<number>
    certificationHasExpiration: boolean
    certificationExpirationDate: Nullable<number>
    certificationTitle: string
    certificationOrganization: string
    languageName: string
    languageSchool: string
    status?: EducationStatus
}

const educationTypeMapping: StringMapping = {
    [EducationType.CERTIFICATE_COURSE]: 'Certificate Course',
    [EducationType.LANGUAGE_COURSE]: 'Language Course',
    [EducationType.BACHELOR_STUDIES]: 'Bachelor Studies',
    [EducationType.MASTER_STUDIES]: 'Master Studies',
    [EducationType.PHD_STUDIES]: 'PhD Studies',
    [EducationType.OTHER_STUDIES]: 'Other Education Type',
}

const mapEducationType = (type: Nullable<EducationType>) => {
    return type !== null ? educationTypeMapping[type] : ''
}

export { mapEducationType }
