/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledTextarea } from '../../../components/forms/ControlledTextarea'
import { Modal } from '../../../components/Modal'
import { yup } from '../../../validation/yup'
import { FeedbackRequestBody } from '../tests'

type FeedbackModalProps = {
    opened: boolean
    handleClose: () => void
    handleSendFeedback: (data: FeedbackRequestBody) => void
}

const schema = yup.object().shape({
    feedback: yup.string().required(),
})

const FeedbackModal: FunctionComponent<React.PropsWithChildren<FeedbackModalProps>> = ({ opened, handleClose, handleSendFeedback }) => {
    const {
        handleSubmit,
        control,
        formState: { isDirty, isValid },
    } = useForm<FeedbackRequestBody>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })
    return (
        <Modal opened={opened} onClose={handleClose}>
            <h5>Give Feedback</h5>
            <form onSubmit={handleSubmit(handleSendFeedback)}>
                <ControlledTextarea
                    control={control}
                    name='feedback'
                    label='Please describe your issue below'
                    placeholder='Type something'
                    dataTestId='feedback-textarea'
                />
                <Divider />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button variant='text' onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type='submit' disabled={!isDirty || !isValid} dataTestId='send-feedback-button'>
                        Send
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export { FeedbackModal }
