/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment, { Moment } from 'moment'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, UseFormSetError, UseFormTrigger } from 'react-hook-form'
import { getCities } from 'src/api/api'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ControlledSuggestionInput } from 'src/components/forms/ControlledSuggestionInput'
import { ControlledTextarea } from 'src/components/forms/ControlledTextarea'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { useCountries, useSpecialistRoles } from 'src/redux/dictionaryDataHooks'
import { Nullable } from 'src/types'
import { naturalNumbersRegex, naturalNumbersWithTwoDecimalsRegex } from 'src/utils/regexes'
import { APPLICATION_COMMENT_CHAR_LIMIT, MAX_INTEGER } from 'src/validation/consts'
import { MAX_INTEGER_MESSAGE } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'
import { Box } from 'src/components/opportunities-manager/Box'
import { OpportunityLocationTypeV2Enum, OpportunitySummary } from 'src/contracts/opportunities-manager/contracts'
import { UpdateApplicationRequestBody } from 'src/contracts/open-opportunities/contracts'

import { ApplicationCvDropdown } from './ApplicationCvDropdown'
import { ApplicationCvDropzone } from './ApplicationCvDropzone'
import { ApplicantName } from './styles'
import { CustomCvTemplate } from './CustomCvTemplate'
import { MsaInfo } from 'src/components/opportunities/MsaInfo'

import { useCompany } from 'src/hooks/useCompany'
import { ApplicationData } from './ApplicationWizard/redux/types'

const getSchema = (opportunity: Nullable<OpportunitySummary>) =>
    yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        role: yup.string().required(),
        preferredRate: yup.number().positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
        negotiableRate: yup
            .number()
            .positive()
            .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
            .transform((val, a) => (isNaN(val) ? undefined : val))
            .optional(),
        availableFrom: yup.number().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
        availableTo: yup.number().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
        country: yup.string().required(),
        city: opportunity?.location.locationTypeV2 !== OpportunityLocationTypeV2Enum.REMOTE ? yup.string().required() : yup.string(),
        comment: yup.string().max(APPLICATION_COMMENT_CHAR_LIMIT),
        // FIXME
        // One whitespace for error is intentional, so that visually checkbox label is red,
        // but there's no error message below it (which now is not well positioned).
        // Possible solution for it would be to not make checkbox label red,
        // and position error message to the left (or have it customizable as a prop).
        useCvTemplate: opportunity?.customCvTemplate ? yup.boolean().oneOf([true], ' ') : yup.boolean(),
    })

export type LocationField = Nullable<'country' | 'city'>

const useCompleteInformationForm = (
    application: Nullable<ApplicationData>,
    handleApplicationCvChange: (
        files: Array<File> | File,
        values: UpdateApplicationRequestBody,
        trigger: UseFormTrigger<UpdateApplicationRequestBody>,
        setError: UseFormSetError<UpdateApplicationRequestBody>,
        changeFocus: (name: LocationField) => void,
    ) => void,
    showApplicationCvDropzone: (application: UpdateApplicationRequestBody) => void,
    showCvPreview: () => void,
    opportunity: Nullable<OpportunitySummary>,
) => {
    const theme = useTheme()
    const roles = useSpecialistRoles()
    const countriesDictionary = useCountries()
    const { company } = useCompany()

    const shouldShowMSAWarning = useMemo(() => !!company && !company.msaSigned && !application?.smeCloud, [application?.smeCloud, company])

    const {
        control,
        watch,
        reset,
        getValues,
        trigger,
        formState: { dirtyFields, errors },
        setError,
    } = useForm<UpdateApplicationRequestBody>({
        resolver: yupResolver(getSchema(opportunity)),
        defaultValues: {
            firstName: application?.firstName || '',
            lastName: application?.lastName || '',
            role: application?.role || '',
            preferredRate: application?.preferredRate || '',
            negotiableRate: application?.negotiableRate || '',
            availableFrom: application?.availableFrom || '',
            availableTo: application?.availableTo || '',
            country: application?.country || '',
            city: application?.city || '',
            comment: application?.comment || '',
            useCvTemplate: false,
        },
        mode: 'onChange',
    })
    const watchCountry = watch('country')
    const watchRate = watch('preferredRate')
    const watchComment = watch('comment')

    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const getCitiesForCountry = useCallback((filter: string) => getCities(watchCountry, filter), [watchCountry])
    const [cvError, setCvError] = useState(false)

    const [focus, setFocus] = useState<Nullable<LocationField>>(null)
    const clearFocus = useCallback(() => setFocus(null), [])
    const changeFocus = useCallback((name: LocationField) => setFocus(name), [])

    const triggerAll = useCallback(
        () =>
            new Promise<boolean>((resolve, reject) => {
                const isCvValid = Boolean(application?.cvFileName && !application?.isUpdatingCv)
                setCvError(!isCvValid)
                trigger()
                    .then(isFormValid => {
                        resolve(isFormValid && isCvValid)
                    })
                    .catch(reject)
            }),
        [application?.cvFileName, application?.isUpdatingCv, trigger],
    )

    useEffect(() => {
        reset(
            {
                firstName: application?.firstName || '',
                lastName: application?.lastName || '',
                role: application?.role || '',
                preferredRate: application?.preferredRate || '',
                negotiableRate: application?.negotiableRate || '',
                availableFrom: application?.availableFrom || '',
                availableTo: application?.availableTo || '',
                country: application?.country || '',
                city: application?.city || '',
                comment: application?.comment || '',
                useCvTemplate: false,
            },
            { keepDirty: application?.currentStep === 'COMPLETE_INFORMATION' },
        )
    }, [application, reset])

    const isNameKnown = !!(application?.firstName && application.lastName)

    const handleCvChange = useCallback(
        (files: Array<File> | File) => {
            setCvError(false)
            handleApplicationCvChange(files, getValues(), trigger, setError, changeFocus)
        },
        [changeFocus, getValues, handleApplicationCvChange, setError, trigger],
    )

    const handleReplaceCvFile = useCallback(() => {
        showApplicationCvDropzone(getValues())
    }, [getValues, showApplicationCvDropzone])

    const disabledAvailableToDate = useCallback(
        (date: Moment): boolean => {
            const startDate = getValues('availableFrom')
            return startDate !== ''
                ? moment(date).isBefore(moment().startOf('day')) || moment(date).isSameOrBefore(moment(startDate * 1000))
                : moment(date).isBefore(moment().startOf('day'))
        },
        [getValues],
    )
    const disabledAvailableFromDate = useCallback(
        (date: Moment): boolean => {
            const endDate = getValues('availableTo')
            return endDate !== ''
                ? moment(date).isBefore(moment().startOf('day')) || moment(date).isSameOrAfter(moment(endDate * 1000))
                : moment(date).isBefore(moment().startOf('day'))
        },
        [getValues],
    )

    const cvWrapperRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (cvError) {
            cvWrapperRef.current?.scrollIntoView()
        }
    }, [cvError])

    const hasCustomTemplate = Boolean(opportunity?.customCvTemplate)

    const CompleteInformationForm = useMemo(
        () => (
            <Fragment>
                <Box>
                    <ApplicantName data-testid='applicant-name' hasMargin={isNameKnown}>
                        {isNameKnown ? `${application?.firstName} ${application.lastName}` : 'Talent Alpha Profile'}
                    </ApplicantName>
                    {shouldShowMSAWarning && <MsaInfo marginBottom={isNameKnown ? '8px' : '16px'} />}
                    {!isNameKnown && (
                        <Fragment>
                            <FieldWrapper>
                                <ControlledInput control={control} name='firstName' label='First name (visible internally)' />
                            </FieldWrapper>
                            <FieldWrapper>
                                <ControlledInput control={control} name='lastName' label='Last name (visible internally)' />
                            </FieldWrapper>
                        </Fragment>
                    )}
                </Box>
                <Box ref={cvWrapperRef}>
                    <h6>CV</h6>
                    {hasCustomTemplate && <CustomCvTemplate control={control} />}
                    {application?.cvFileName && !application?.isUpdatingCv ? (
                        <FlexBox
                            css={css`
                                width: 100%;
                                justify-content: space-between;
                                align-items: center;
                                padding: 8px 16px;
                                box-shadow: ${theme.shadow.small};
                                border: 1px solid ${theme.colors.gray_2};
                                border-radius: 4px;
                            `}
                        >
                            <div
                                css={css`
                                    color: ${theme.colors.violet_5};
                                    font-weight: 500;
                                    cursor: pointer;
                                `}
                                onClick={showCvPreview}
                            >
                                {application?.cvFileName}
                            </div>
                            <ApplicationCvDropdown handleReplaceCvFile={handleReplaceCvFile} />
                        </FlexBox>
                    ) : (
                        <ApplicationCvDropzone
                            error={cvError}
                            handleApplicationCvChange={handleCvChange}
                            isSendingCv={!!application?.isSendingCv}
                        />
                    )}
                </Box>
                <Box>
                    <h6>Complete Information</h6>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            control={control}
                            name='role'
                            label='Role'
                            options={roles}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledInput
                            control={control}
                            name='preferredRate'
                            label='Preferred Rate'
                            labelTooltip='What is your ideal rate for this talent? We’ll try to negotiate this rate or higher if possible'
                            prefix={application?.currency && `${application.currency}/h`}
                            regex={naturalNumbersWithTwoDecimalsRegex}
                            infoMessage={
                                (watchRate &&
                                    opportunity?.maxRate &&
                                    opportunity?.minRate &&
                                    (watchRate > opportunity.maxRate || watchRate < opportunity.minRate) &&
                                    'Your rate is out of range set by opportunity owner.') ||
                                undefined
                            }
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledInput
                            control={control}
                            name='negotiableRate'
                            label='Negotiable Rate'
                            labelTooltip='What is the lowest rate you would accept for this expert if we can’t negotiate your ideal rate?'
                            prefix={application?.currency && `${application.currency}/h`}
                            regex={naturalNumbersRegex}
                        />
                    </FieldWrapper>
                    <FlexBox>
                        <FieldWrapper
                            css={css`
                                padding-right: 8px;
                            `}
                        >
                            <ControlledDatePickerInput
                                control={control}
                                name='availableFrom'
                                label='Available from'
                                canClear={false}
                                disabledDate={disabledAvailableFromDate}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            css={css`
                                padding-left: 8px;
                            `}
                        >
                            <ControlledDatePickerInput
                                control={control}
                                name='availableTo'
                                label='Available to'
                                canClear={false}
                                disabledDate={disabledAvailableToDate}
                            />
                        </FieldWrapper>
                    </FlexBox>
                    <Divider />
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Country'
                            placeholder='Choose country'
                            options={memorizedCountries}
                            control={control}
                            name='country'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            focus={focus === 'country'}
                            clearFocus={clearFocus}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledSuggestionInput
                            label={`City${
                                opportunity?.location.locationTypeV2 === OpportunityLocationTypeV2Enum.REMOTE ? ' (optional)' : ''
                            }`}
                            placeholder='Choose city'
                            fetchOptions={getCitiesForCountry}
                            disabled={!watchCountry}
                            control={control}
                            name='city'
                            allowOnlyValuesFromDictionary
                            fetchFromLetterNumber={1}
                            focus={focus === 'city'}
                            clearFocus={clearFocus}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledTextarea
                            control={control}
                            name='comment'
                            label='Comments (optional)'
                            infoMessage={`${watchComment?.length || 0}/${APPLICATION_COMMENT_CHAR_LIMIT}`}
                        />
                    </FieldWrapper>
                </Box>
            </Fragment>
        ),
        [
            application?.currency,
            application?.cvFileName,
            application?.firstName,
            application?.isSendingCv,
            application?.isUpdatingCv,
            application?.lastName,
            clearFocus,
            control,
            cvError,
            disabledAvailableFromDate,
            disabledAvailableToDate,
            focus,
            getCitiesForCountry,
            handleCvChange,
            handleReplaceCvFile,
            isNameKnown,
            memorizedCountries,
            opportunity?.maxRate,
            opportunity?.minRate,
            opportunity?.location.locationTypeV2,
            roles,
            showCvPreview,
            theme.colors.gray_2,
            theme.colors.violet_5,
            theme.shadow.small,
            watchCountry,
            watchRate,
            watchComment,
            hasCustomTemplate,
            shouldShowMSAWarning,
        ],
    )

    return {
        CompleteInformationForm,
        getValues,
        trigger: triggerAll,
        isDirty: !!Object.keys(dirtyFields).length,
        hasErrors: !!Object.keys(errors).length,
        setError,
        changeFocus,
    }
}

export { useCompleteInformationForm }
