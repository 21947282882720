/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useCopyToClipboard } from 'react-use'
import { BlueActionBox } from '../../../components/BlueActionBox'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Input } from '../../../components/inputs/Input'
import { Modal, ModalProps } from '../../../components/Modal'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { GreyParagraph } from '../../../components/shared/shared-styles'

type TalentMarketplaceShareModalProps = ModalProps & {
    onClose: () => void
    linkIdWithoutRate: string
    linkIdWithRate: string
}

const TalentMarketplaceShareModal: FunctionComponent<React.PropsWithChildren<TalentMarketplaceShareModalProps>> = ({
    opened,
    onClose,
    linkIdWithoutRate,
    linkIdWithRate,
}) => {
    const [linkWithoutRate, setLinkIdWithoutRate] = useState('')
    const [linkWithRate, setLinkIdWithRate] = useState('')

    const { addSuccess, addError } = useNotifications()

    const [state, copyToClipboard] = useCopyToClipboard()

    useEffect(() => {
        if (linkIdWithoutRate) {
            setLinkIdWithoutRate(`${window.location.protocol}//${window.location.host}/public/profile/${linkIdWithoutRate}`)
        }
        if (linkIdWithRate) {
            setLinkIdWithRate(`${window.location.protocol}//${window.location.host}/public/profile/${linkIdWithRate}`)
        }
    }, [linkIdWithRate, linkIdWithoutRate])
    const handleClickWithRate = useCallback(() => {
        copyToClipboard(linkWithRate)
        onClose()
        addSuccess('Copied!')
        if (state.error) {
            addError()
        }
    }, [addError, addSuccess, copyToClipboard, linkWithRate, onClose, state.error])

    const handleClickWithoutRate = useCallback(() => {
        copyToClipboard(linkWithoutRate)
        onClose()
        addSuccess('Copied!')
        if (state.error) {
            addError()
        }
    }, [addError, addSuccess, copyToClipboard, linkWithoutRate, onClose, state.error])

    return (
        <Modal onClose={onClose} opened={opened}>
            <h5>Share the link to the anonymized specialist profile</h5>
            <GreyParagraph>Let others take a look at this specialist profile.</GreyParagraph>
            <GreyParagraph>Shareable Link:</GreyParagraph>
            <BlueActionBox
                withInfoIcon
                text='You can copy link with or without the rate'
                styles={css`
                    font-size: 12px;
                `}
            />
            <strong
                css={css`
                    margin: 8px 0;
                    display: inline-block;
                `}
            >
                Rate visible
            </strong>
            <div
                css={css`
                    display: flex;
                    margin-bottom: 8px;
                `}
            >
                <Input
                    name='linkWithRate'
                    dataTestId='linkWithRate'
                    value={linkWithRate ? linkWithRate : 'Loading...'}
                    disabled={true}
                    style={css`
                        width: 100%;
                    `}
                ></Input>
                <div
                    css={css`
                        margin-left: 16px;
                    `}
                >
                    <Button
                        onClick={handleClickWithRate}
                        variant='tertiary'
                        dataTestId='copy-and-close-with-rate'
                        style={css`
                            white-space: nowrap;
                            line-height: inherit;
                        `}
                    >
                        Copy & Close
                    </Button>
                </div>
            </div>
            <Divider
                style={css`
                    margin: 0;
                `}
            />
            <strong
                css={css`
                    margin: 8px 0;
                    display: inline-block;
                `}
            >
                Rate hidden
            </strong>
            <div
                css={css`
                    display: flex;
                `}
            >
                <Input
                    name='linkWithoutRate'
                    dataTestId='linkWithoutRate'
                    value={linkWithoutRate ? linkWithoutRate : 'Loading...'}
                    disabled={true}
                    style={css`
                        width: 100%;
                    `}
                ></Input>
                <div
                    css={css`
                        margin-left: 16px;
                    `}
                >
                    <Button
                        onClick={handleClickWithoutRate}
                        variant='tertiary'
                        dataTestId='copy-and-close-without-rate'
                        style={css`
                            white-space: nowrap;
                            line-height: inherit;
                        `}
                    >
                        Copy & Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export { TalentMarketplaceShareModal }
