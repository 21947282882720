/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { levelsMapping, levelTypes } from 'src/contracts/profile/language'
import { mqMax } from 'src/GlobalStyle'
import { InlineFieldWrapper, PropertyLabel } from 'src/pages/my-specialists/shared/ProfileTabsHelpers'
import { useLanguages } from 'src/redux/dictionaryDataHooks'
import { Divider } from './Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from './forms/ControlledAutocompleteSelect'
import { FlexBox } from './layout/FlexBoxHelpers'

type AddLanguageProps = {
    control: any
}

const AddLanguage: FunctionComponent<AddLanguageProps> = ({ control }) => {
    const languages = useLanguages()
    return (
        <Fragment>
            <FlexBox
                css={css`
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <InlineFieldWrapper
                    css={css`
                        align-items: flex-start;
                    `}
                >
                    <PropertyLabel
                        css={css`
                            line-height: 40px;
                        `}
                    >
                        Language
                    </PropertyLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Language'
                        options={languages}
                        control={control}
                        name='name'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        dataTestId='language'
                    />
                </InlineFieldWrapper>
                <InlineFieldWrapper
                    css={css`
                        align-items: flex-start;
                        margin-left: 24px;
                        min-width: 175px;
                        ${mqMax[1]} {
                            margin: 0;
                        }
                    `}
                >
                    <PropertyLabel
                        css={css`
                            line-height: 40px;
                        `}
                    >
                        Level
                    </PropertyLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Level'
                        options={levelTypes}
                        control={control}
                        name='level'
                        selectedLabelTransformer={(val: string) => levelsMapping[val]}
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        dropdownWidth='100%'
                        dataTestId='level'
                    />
                </InlineFieldWrapper>
            </FlexBox>
            <Divider
                style={css`
                    margin-top: 4px;
                    margin-bottom: 0;
                `}
            />
        </Fragment>
    )
}
export { AddLanguage }
