import calculateSize from 'calculate-size'

const industriesMock: Array<string> = [
    'Ad Tech',
    'Artificial Intelligence',
    'Audio Genre Recognition System',
    'Audio driver',
    'Automotive',
    'Aviation',
    'Banking and Finance',
    'Biotechnology',
    'Branding',
    'Cloud data storage',
    'Company asset administration',
    'Company framework',
    'Construction assets management',
    'Consumer electronics',
    'Customer Support',
    'Cybersecurity system',
    'E-commerce',
    'E-sport & Gaming',
    'Education',
    'Entertainment',
    'Face Recognition System',
    'Food & Farming',
    'Gambling',
    'Geographic Information System',
    'Geolocation',
    'Government',
    'HR',
    'Healthcare',
    'IT',
    'Industrial and Manufacturing',
    'Insurance',
    'Intellectual Property',
    'Internal support',
    'Laboratory/ Biotechnology',
    'Legal',
    'Location analysis',
    'Logistics',
    'Marketing',
    'Media & Publishing',
    'Natural Language Processing Competition',
    'Neuroscience',
    'Non-profit / NGOs',
    'Not industry specific',
    'Online dating',
    'Operations management',
    'Public administration',
    'Real Estate',
    'Retail',
    'Services',
    'Smart Cities',
    'Sport',
    'Telecommunication',
    'Tourism',
    'Various small projects',
    'graphic driver',
    'hospitality',
    'mass storage',
    'reasearch deep learning',
    'research',
]

type CalculateDisplayedValuesProps = {
    values: Array<string>
    width: number
    transformer: (opt: any) => string
}

const calculateDisplayedValues = ({ values, width, transformer }: CalculateDisplayedValuesProps): string => {
    let calculatedValue = ''
    values?.some((value, index) => {
        const newValue = `${calculatedValue.replace(` (+${values.length - index})`, '')}${index !== 0 ? ', ' : ''}${transformer(value)}${
            index !== values.length - 1 ? ` (+${values.length - index - 1})` : ''
        }`
        const calculatedWidth = calculateSize(newValue, { fontWeight: '400', fontSize: '14px', font: 'Jost, sans-serif' }).width
        if (calculatedWidth > width) {
            return true
        } else {
            calculatedValue = newValue
            if (index === values.length - 1) {
                return true
            }
            return false
        }
    })

    return calculatedValue || `${transformer(values[0])} (+${values.length - 1})`
}

export { calculateDisplayedValues, industriesMock }
