import { WhiteLabel } from './whiteLabel'

export enum VMCstatusEnum {
    VENDOR = 'VENDOR',
    OWNER = 'OWNER',
    NONE = 'NONE',
    INVITED = 'INVITED',
}

export interface VMCUser {
    id: string
    firstName: string
    lastName: string
    email: string
    companyName: string
}

export interface VMCInvitation {
    id: string
    email: string
    status: string
    updatedAt: number
}

export interface VMCUsersResponse {
    vmcs: VMCUser[]
}

export interface VMCInvitationsResponse {
    invitations: VMCInvitation[]
}

export interface VMCInvitationAdditionalDataResponse {
    id: string
    email: string
    companyId: string
    whitelabel: WhiteLabel
    companyName: string
}
