/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormEvent, Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DeepMap, FieldError, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { FlexButtons } from '../../../components/layout/FlexBoxHelpers'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { SettingsWrapper } from '../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { JoinSyndicateRequest, Syndicate } from '../../../contracts/syndicates'
import { mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { hasErrors } from '../../../utils/errors'
import { emailRegex, uuidRegex } from '../../../utils/regexes'
import { useLogger } from '../../../utils/useLogger'
import { yup } from '../../../validation/yup'
import { getIsSyndicateMember, getSyndicate, getSyndicateName, joinSyndicate, updateSyndicateContactPersonData } from '../api'
import { LeavingConfirmationModal } from './components/LeavingConfirmationModal'

const updateSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().matches(emailRegex).required(),
})

const joinSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().matches(emailRegex).required(),
    syndicateActivationCode: yup.string().matches(uuidRegex).required(),
})

type FormProps = {
    syndicate?: Syndicate
    handleSubmit: (event: FormEvent<HTMLFormElement>) => void
    syndicateName?: string
    codeError?: string
    isDirty: boolean
    isSubmitting: boolean
    errors: Partial<DeepMap<JoinSyndicateRequest, FieldError | undefined>>
    control: any
}

const Form: FunctionComponent<React.PropsWithChildren<FormProps>> = ({
    syndicate,
    isDirty,
    isSubmitting,
    handleSubmit,
    control,
    errors,
    syndicateName,
    codeError,
}) => {
    const navigate = useNavigate()
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false)

    const cancel = useCallback((): void => {
        navigate('/dashboard')
    }, [navigate])

    const handleCancel = useCallback((): void => {
        if (isDirty) {
            setSaveChangesModalVisible(true)
        } else {
            cancel()
        }
    }, [cancel, isDirty])

    const hideLeavingConfirmationModal = useCallback((): void => {
        setSaveChangesModalVisible(false)
    }, [])

    return (
        <Fragment>
            <DashboardLayout menuType={MenuType.companyAccount}>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                    `}
                >
                    <SettingsWrapper>
                        <h4>Private Human Cloud</h4>
                        <Divider />

                        <section>
                            <p>
                                {!syndicate && 'You are currently not a member of any Private Human Cloud.'} Please enter the name and email
                                address of your Private Human Cloud contact Person as well as the code you received to join. If you haven’t
                                yet received your code, please contact your Talent Alpha Account Representative.
                            </p>
                            <form
                                onSubmit={handleSubmit}
                                css={css`
                                    margin-top: 32px;
                                `}
                            >
                                <h6>Private Human Cloud Contact Person</h6>
                                <section>
                                    <ResponsiveColumns>
                                        <FirstColumn>
                                            <FieldWrapper>
                                                <ControlledInput
                                                    control={control}
                                                    name='firstName'
                                                    label='First Name'
                                                    placeholder='First Name'
                                                />
                                            </FieldWrapper>
                                        </FirstColumn>
                                        <SecondColumn>
                                            <FieldWrapper>
                                                <ControlledInput
                                                    name='lastName'
                                                    label='Last Name'
                                                    placeholder='Last Name'
                                                    control={control}
                                                />
                                            </FieldWrapper>
                                        </SecondColumn>
                                    </ResponsiveColumns>

                                    <FieldWrapper>
                                        <ControlledInput
                                            control={control}
                                            name='email'
                                            label='PHC Contact Email Address'
                                            placeholder='PHC Contact Email Address'
                                        />
                                    </FieldWrapper>
                                </section>

                                <section>
                                    <h6>Private Human Cloud Information</h6>
                                    {!syndicate && (
                                        <FieldWrapper>
                                            <ControlledInput
                                                name='syndicateActivationCode'
                                                label='Private Human Cloud Code'
                                                placeholder='Private Human Cloud Code'
                                                control={control}
                                                externalError={codeError}
                                            />
                                        </FieldWrapper>
                                    )}
                                    {syndicateName && <p>Your Private Human Cloud Name: {syndicateName}</p>}
                                    <Divider />
                                </section>

                                <FlexButtons
                                    css={css`
                                        ${mqMax[1]} {
                                            flex-direction: column-reverse;
                                        }
                                    `}
                                >
                                    <Button
                                        variant='link'
                                        style={css`
                                            margin-right: 24px;

                                            ${mqMax[1]} {
                                                margin: 12px 0 0;
                                                justify-content: center;
                                                width: 100%;
                                            }
                                        `}
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        css={css`
                                            ${mqMax[1]} {
                                                width: 100%;
                                            }
                                        `}
                                        variant='primary'
                                        type='submit'
                                        disabled={isSubmitting || !isDirty || !!codeError || hasErrors(errors)}
                                        dataTestId='private-human-cloud-member-check'
                                    >
                                        {syndicate ? 'Update Contact Details' : 'Join Now'}
                                    </Button>
                                </FlexButtons>
                            </form>
                        </section>
                    </SettingsWrapper>
                </div>
            </DashboardLayout>
            <LeavingConfirmationModal
                isVisible={saveChangesModalVisible}
                handleConfirmation={cancel}
                handleRejection={hideLeavingConfirmationModal}
            />
        </Fragment>
    )
}

type PrivateHumanCloudUpdateFormProps = {
    syndicate?: Syndicate
    onSubmit: (data: JoinSyndicateRequest) => void
}

const PrivateHumanCloudUpdateForm: FunctionComponent<React.PropsWithChildren<PrivateHumanCloudUpdateFormProps>> = ({
    syndicate,
    onSubmit,
}) => {
    const syndicateContactPersonData = useMemo(() => {
        const { syndicateName, ...rest } = syndicate || {}
        return rest
    }, [syndicate])

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<JoinSyndicateRequest>({
        mode: 'onChange',
        resolver: yupResolver(updateSchema),
        defaultValues: syndicateContactPersonData,
    })

    return (
        <Form
            syndicate={syndicate}
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            syndicateName={syndicate?.syndicateName}
        />
    )
}

type PrivateHumanCloudJoinFormProps = {
    onSubmit: (data: JoinSyndicateRequest) => void
}

const PrivateHumanCloudJoinForm: FunctionComponent<React.PropsWithChildren<PrivateHumanCloudJoinFormProps>> = ({ onSubmit }) => {
    const [codeError, setCodeError] = useState<string>('')
    const [syndicateName, setSyndicateName] = useState<string>('')
    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
        watch,
    } = useForm<JoinSyndicateRequest>({
        resolver: yupResolver(joinSchema),
        mode: 'onChange',
    })
    const syndicateActivationCode = watch('syndicateActivationCode')
    const log = useLogger('error')

    useEffect(() => {
        setCodeError('')
        if (uuidRegex.test(syndicateActivationCode)) {
            getSyndicateName(syndicateActivationCode)
                .then(name => {
                    setSyndicateName(name || '')
                })
                .catch(e => {
                    if (e.status === 400) {
                        setCodeError('Code expired')
                    } else if (e.status === 404) {
                        setCodeError('Invalid code')
                    }
                    log(e)
                })
        }
    }, [syndicateActivationCode, log])

    return (
        <Form
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            codeError={codeError}
            syndicateName={syndicateName}
        />
    )
}

const PrivateHumanCloudForm = () => {
    const [syndicate, setSyndicate] = useState<Nullable<Syndicate>>(null)
    const { addError, addSuccess } = useNotifications()
    const log = useLogger('error')
    const navigate = useNavigate()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    useEffect(() => {
        layoutToggleLoader(true)
        getIsSyndicateMember()
            .then(isMember => {
                if (isMember) {
                    getSyndicate()
                        .then(data => {
                            setSyndicate(data)
                        })
                        .catch(e => {
                            log(e)
                            addError()
                        })
                        .finally(() => layoutToggleLoader(false))
                } else {
                    layoutToggleLoader(false)
                }
            })
            .catch(e => {
                log(e)
                layoutToggleLoader(false)
                addError()
            })
    }, [log, layoutToggleLoader, addError])

    const onSubmit = useCallback(
        (data: JoinSyndicateRequest) => {
            const request = syndicate ? updateSyndicateContactPersonData : joinSyndicate
            layoutToggleLoader(true)
            request(data)
                .then(() => {
                    navigate('/dashboard/company-account/private-human-cloud')
                    addSuccess('Your Private Human Cloud form submitted successfully.')
                })
                .catch(e => {
                    log(e)
                    addError('There was a problem submitting your form. Please try again.')
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        },
        [addSuccess, addError, navigate, layoutToggleLoader, syndicate, log],
    )

    return syndicate ? (
        <PrivateHumanCloudUpdateForm syndicate={syndicate} onSubmit={onSubmit} />
    ) : (
        <PrivateHumanCloudJoinForm onSubmit={onSubmit} />
    )
}

export { PrivateHumanCloudForm }
