/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { FunctionComponent, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Theme } from '../theme/theme'
import { Icon } from './Icon'
import { AutocompleteSelect } from './inputs/autocomplete-select/AutocompleteSelect'
import { TooltipIcon } from './TooltipIcon'

export enum TabsColor {
    YELLOW,
    BLACK,
}

export type TabProps = { title: string; disabled?: boolean; tooltip?: string; dataTestId?: string }

type TabsProps = {
    tabs: Array<TabProps>
    currentTab?: number
    mode?: TabsColor
    onTabChange?: (tabIndex: number) => void
    withArrows?: boolean
    showOnMobile?: boolean
    containerRef?: RefObject<HTMLDivElement>
}

const YellowTabsStyles = (selectedTab: number, theme: Theme) => {
    return css`
        ul > li {
            background-color: ${COLOR_PALETTE.gray_1};
            color: ${COLOR_PALETTE.gray_6};
            padding: 16px 21px 8px;
            border-top: 2px solid ${COLOR_PALETTE.gray_1};
            border-left: 1px solid ${COLOR_PALETTE.gray_1};
            border-right: 1px solid ${COLOR_PALETTE.gray_1};
            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};

            &.active {
                background-color: ${COLOR_PALETTE.white};
                border-top: 2px solid ${theme.colors.main};
                border-left: 1px solid ${COLOR_PALETTE.gray_2};
                border-right: 1px solid ${COLOR_PALETTE.gray_2};
                border-bottom: 0;
            }
        }
    `
}

const BlackTabsStyles = (selectedTab: number) => {
    return css`
        ul {
            height: 34px;
            & > li {
                display: flex;
                align-items: center;
                padding: 0 12px;

                &.active {
                    background-color: ${COLOR_PALETTE.gray_6};
                    color: ${COLOR_PALETTE.white};
                }
            }
        }
    `
}

const ArrowIcon = styled.button`
    width: 22px;
    height: 22px;
    background-color: ${COLOR_PALETTE.gray_2};
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all linear 0.3s;
    border: 0;
    box-shadow: none;

    svg {
        width: 10px;
    }
    &:hover {
        background-color: ${COLOR_PALETTE.gray_6};

        path {
            fill: ${COLOR_PALETTE.white};
        }
    }
`

const ArrowIconContainer = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Tabs: FunctionComponent<React.PropsWithChildren<TabsProps>> = ({
    tabs,
    currentTab = 0,
    mode = TabsColor.YELLOW,
    onTabChange,
    withArrows,
    showOnMobile,
    containerRef,
    ...other
}) => {
    const wrapper = useRef<HTMLDivElement>(null)
    const child = useRef<HTMLDivElement>(null)
    const ul = useRef<HTMLUListElement>(null)

    const [selectedTab, setSelectedTab] = useState(currentTab)
    const [shift, setShift] = useState(0)
    const [currentPosition, setCurrentPosition] = useState(0)
    const [wrapperWidth, setWrapperWidth] = useState(0)

    const [ulWidth, setUlWidth] = useState(0)
    const [totalChildrenWidth, setTotalChildrenWidth] = useState(0)
    const [deepChildren, setDeepChildren] = useState<Array<ChildNode>>([])
    const [chevronsEnabled, setChevronsEnabled] = useState(false)
    const [isLeftArrowVisible, setIsLeftArrowVisible] = useState(false)
    const [isRightArrowVisible, setIsRightArrowVisible] = useState(true)
    const [showSelect, setShowSelect] = useState(false)
    const theme = useTheme()

    useEffect(() => {
        setSelectedTab(currentTab)
    }, [currentTab])

    const { width } = useWindowSize()

    useEffect(() => {
        if (ul.current?.clientWidth) {
            setUlWidth(ul.current?.clientWidth)
        }

        if (wrapper.current?.clientWidth) {
            setWrapperWidth(wrapper.current?.clientWidth)
        }
    }, [showSelect, width])

    const setChildren = useCallback(() => {
        if (wrapper.current) {
            setWrapperWidth(wrapper.current.clientWidth)

            if (child.current?.hasChildNodes()) {
                setDeepChildren(filterChildNodes(child.current.childNodes[0].childNodes))
                setTotalChildrenWidth(child.current.getBoundingClientRect().width)
            } else {
                setDeepChildren([])
                setTotalChildrenWidth(0)
            }
        }
    }, [])

    useEffect(() => {
        if (withArrows) {
            setChildren()

            if (child.current && wrapper.current) {
                setChevronsEnabled(child.current.clientWidth > wrapper.current.clientWidth)
            }
        }
    }, [setChildren, withArrows])

    const tabSelectionChange = useCallback(
        (index: number) => {
            setSelectedTab(index)
            if (onTabChange) {
                onTabChange(index)
            }
        },
        [onTabChange],
    )

    const setStyles = (tabsColor: TabsColor, selected: number, theme: Theme): SerializedStyles => {
        switch (tabsColor) {
            case TabsColor.YELLOW:
                return YellowTabsStyles(selected, theme)
            case TabsColor.BLACK:
                return BlackTabsStyles(selected)
            default:
                return YellowTabsStyles(selected, theme)
        }
    }

    const switchLeft = () => {
        doSwitch('left')
    }

    const switchRight = () => {
        doSwitch('right')
    }

    const doSwitch = (value: string) => {
        setChildren()

        if (deepChildren.length === 0) {
            return
        }

        const positive = value === 'left'
        const currentElement = deepChildren[currentPosition] as HTMLElement

        if (currentElement) {
            if (positive) {
                const newShift = shift + currentElement.clientWidth
                const isSmallerThanLeftBound = newShift > 0

                if (isSmallerThanLeftBound) {
                    setIsLeftArrowVisible(false)
                }

                setShift(isSmallerThanLeftBound ? 0 : newShift)
                setCurrentPosition(currentPosition - 1)
                setIsRightArrowVisible(true)
            } else {
                const newShift = shift - currentElement.clientWidth
                let minShift
                let isGreaterThanRightBound

                if (wrapperWidth && child.current) {
                    minShift = wrapperWidth - child.current.clientWidth
                    isGreaterThanRightBound = newShift < minShift
                }

                if (isGreaterThanRightBound) {
                    setIsRightArrowVisible(false)

                    if (minShift) {
                        setShift(minShift)
                    }
                } else {
                    setShift(newShift)
                }

                setCurrentPosition(currentPosition + 1)
                setIsLeftArrowVisible(true)
            }
        }

        correctEdgeValues(deepChildren)
    }

    const filterChildNodes = (nodes: NodeListOf<ChildNode>): Array<ChildNode> => {
        const nodesArray = Array.from(nodes)

        return nodesArray.filter(value => (value as HTMLElement).clientWidth !== undefined)
    }

    const correctEdgeValues = (deepChild: Array<any>) => {
        if (currentPosition < 0) {
            setCurrentPosition(0)
        }

        if (currentPosition >= deepChild.length) {
            setCurrentPosition(deepChild.length - 1)
        }

        if (totalChildrenWidth < wrapperWidth + Math.abs(shift)) {
            const newShift = wrapperWidth - totalChildrenWidth
            setShift(newShift > 0 ? 0 : newShift)
        }

        if (shift > 0) {
            setShift(0)
        }
    }
    const onMobileTabChange = useCallback(
        ([tabName]: [any]) => {
            const tabIndex = tabs.map(t => t.title).indexOf(tabName)
            tabSelectionChange(tabIndex)
        },
        [tabs, tabSelectionChange],
    )

    useEffect(() => {
        if (wrapperWidth < ulWidth) {
            setShowSelect(true)
        }

        if (wrapperWidth >= ulWidth) {
            setShowSelect(false)
        }
    }, [ulWidth, wrapperWidth])

    return (
        <div ref={containerRef}>
            <AutocompleteSelect
                placeholder='Choose Tab'
                options={tabs.filter(t => t.disabled !== true).map(t => t.title)}
                dataTestId='specialist-tab'
                currentValues={[tabs[selectedTab].title]}
                onSelectedValuesChange={onMobileTabChange}
                canFilter={false}
                dropdownWidth='100%'
                css={css`
                    visibility: ${showOnMobile ? 'hidden' : showSelect ? 'visible' : 'hidden'};
                    height: ${showOnMobile ? '0' : showSelect ? 'auto' : '0'};
                `}
            />

            <div
                css={[
                    css`
                        overflow-y: visible;
                        overflow-x: auto;
                        display: flex;
                        visibility: ${showOnMobile ? 'visible' : showSelect ? 'hidden' : 'visible'};
                        height: ${showOnMobile ? 'auto' : showSelect ? '0' : 'auto'};
                    `,

                    setStyles(mode, selectedTab, theme),
                ]}
                {...other}
            >
                {chevronsEnabled && withArrows && (
                    <ArrowIconContainer>
                        {isLeftArrowVisible && (
                            <ArrowIcon onClick={switchLeft}>
                                <Icon
                                    name='arrow-down'
                                    style={css`
                                        transform: rotate(90deg);
                                    `}
                                />
                            </ArrowIcon>
                        )}
                    </ArrowIconContainer>
                )}
                <div
                    css={css`
                        overflow-x: hidden;
                        width: 100%;

                        ${chevronsEnabled &&
                        withArrows &&
                        css`
                            width: calc(100% - 80px);
                        `}
                    `}
                >
                    <div
                        ref={wrapper}
                        css={css`
                            display: flex;
                            flex-wrap: nowrap;
                            flex-direction: row;
                            align-items: stretch;
                            font-size: 14px;
                            transform: translateX(${shift}px);
                        `}
                    >
                        <div
                            ref={child}
                            css={css`
                                ${!withArrows &&
                                css`
                                    width: 100%;
                                    border-bottom: 1px solid ${COLOR_PALETTE.gray_6};
                                    height: 34px;
                                `}
                            `}
                        >
                            <ul
                                ref={ul}
                                css={css`
                                    list-style: none;
                                    padding: 0;
                                    margin: 0;
                                    display: flex;
                                    overflow: visible;
                                    width: min-content;
                                `}
                            >
                                {tabs.map((tab, index) => (
                                    <li
                                        onClick={() => tab.disabled !== true && tabSelectionChange(index)}
                                        key={`tab-${tab.title}-${index}`}
                                        data-testid={`${tab.dataTestId}`}
                                        css={css`
                                            text-align: center;
                                            cursor: pointer;
                                            white-space: nowrap;
                                            box-sizing: border-box;
                                            position: relative;

                                            cursor: ${tab.disabled ? 'not-allowed' : 'pointer'};
                                        `}
                                        className={selectedTab === index ? 'active' : undefined}
                                    >
                                        <span
                                            css={css`
                                                opacity: ${tab.disabled ? 0.5 : 1};
                                                position: relative;
                                                right: ${tab.tooltip ? '10px' : 0};
                                            `}
                                        >
                                            {tab.title}
                                        </span>
                                        {tab.tooltip && (
                                            <Tooltip placement='top' title={tab.tooltip}>
                                                <span>
                                                    <TooltipIcon />
                                                </span>
                                            </Tooltip>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                {chevronsEnabled && withArrows && (
                    <ArrowIconContainer>
                        {isRightArrowVisible && (
                            <ArrowIcon onClick={switchRight}>
                                <Icon
                                    name='arrow-down'
                                    style={css`
                                        transform: rotate(-90deg);
                                    `}
                                />
                            </ArrowIcon>
                        )}
                    </ArrowIconContainer>
                )}
            </div>
        </div>
    )
}

export { Tabs }
