/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment, { Moment } from 'moment'
import { Fragment, FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import {
    getShareMySpecialistsSyndicates,
    getSpecialistCvParsingStatus,
    getSpecialistGeneralInformation,
    getSpecialistLanguages,
} from 'src/api/api'
import { AddLanguage } from 'src/components/AddLanguage'
import { Button } from 'src/components/Button'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { InputField } from 'src/components/inputs/input-field/InputField'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { Modal } from 'src/components/Modal'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { CheckableSkill } from 'src/components/skills-questionnaire/SuggestedSkillsBox'
import { SpecialistCv } from 'src/contracts/cvs'
import { LanguageRequest, LevelType } from 'src/contracts/profile/language'
import { AvailableOn } from 'src/contracts/search/availableOn'
import { SpecialistGeneralInformation } from 'src/contracts/specialist/specialistGeneralInformation'
import { editShareMySpecialistsSpecialistProfile } from 'src/api/api'
import { SharedType } from 'src/contracts/specialist/specialist'
import { updateSpecialistGeneralInformation } from 'src/pages/my-specialists/api'
import { getSpecialistSuggestedSkills } from 'src/pages/specialist-skills-edit/api'
import { useCountries, useCurrencies, useSeniorities, useSkills, useSpecialistRoles } from 'src/redux/dictionaryDataHooks'
import { naturalNumbersRegex } from 'src/utils/regexes'
import { useLogger } from 'src/utils/useLogger'
import { MAX_INTEGER } from 'src/validation/consts'
import { FIELD_REQUIRED, MAX_INTEGER_MESSAGE } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'

import { LanguageItem } from './LanguageItem'
import {
    AddLanguageButton,
    ButtonsWrapper,
    CVWrapper,
    CancelButton,
    Divider,
    FieldWrapper,
    FieldsWrapper,
    FileName,
    H5,
    LanguageWrapper,
    StyledFieldInfo,
    StyledLoader,
    StyledSuggestedSkills,
} from './styles'
import { saveSpecialistLanguages } from 'src/api/open-opportunities/api'
import { ApplicationData } from './ApplicationWizard/redux/types'

const checkLocalUniq = (name = '', context: any) => {
    const fields = (context.from[1] || context.from[0]).value.languages as Array<LanguageRequest>
    const occurrences = fields.filter(field => field.name === name).length
    return context.from[1] ? occurrences === 1 : !occurrences
}

const checkLanguagesCount = (languages: Array<LanguageRequest>, yupSchema: yup.StringSchema) =>
    !languages.length ? yupSchema.required() : yupSchema

const schema = yup.object().shape({
    seniority: yup.string().required(),
    role: yup.string().required(),
    preferredRate: yup.number().positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
    availableFrom: yup.number().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
    availableTo: yup.number().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).required(),
    country: yup.string().required(),
    topSkills: yup.array(yup.string()).min(1, FIELD_REQUIRED),
    name: yup
        .string()
        .when('$isAdding', (isAdding: boolean, yupSchema: yup.StringSchema) =>
            isAdding
                ? yupSchema.required().test('validateUniqueLanguage', 'You already selected this language', checkLocalUniq)
                : yupSchema,
        )
        .when('languages', checkLanguagesCount),
    level: yup
        .string()
        .when('$isAdding', (isAdding: boolean, yupSchema: yup.StringSchema) => (isAdding ? yupSchema.required() : yupSchema))
        .when('languages', checkLanguagesCount),
    languages: yup
        .array(
            yup.object().shape({
                name: yup.string().required().test('validateUniqueLanguage', 'You already selected this language', checkLocalUniq),
                level: yup.string().required(),
            }),
        )
        .min(1, 'Please select at least one language'),
})

type ShareCandidateModalProps = {
    onClose: () => void
    application: ApplicationData
    handleSharedStatus: (application: ApplicationData) => void
}

export type ShareCandidateModalFormData = {
    seniority: string
    role: string
    currency: string
    preferredRate: number
    availableFrom: number
    availableTo: number
    country: string
    topSkills: Array<string>
    name: string
    level?: LevelType
    languages: Array<LanguageRequest>
}

const ShareCandidateModal: FunctionComponent<PropsWithChildren<ShareCandidateModalProps>> = ({
    onClose,
    application,
    handleSharedStatus,
}) => {
    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()

    const context = useRef({ isAdding: false })
    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        resetField,
        setValue,
        trigger,
        watch,
    } = useForm<ShareCandidateModalFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            seniority: '',
            role: application.role,
            currency: application.currency,
            preferredRate: application.preferredRate,
            availableFrom: application.availableFrom,
            availableTo: application.availableTo,
            country: application.country,
            topSkills: [],
            languages: [],
        },
        mode: 'onChange',
        context: context.current,
    })
    const { fields, append, remove, update } = useFieldArray({
        control,
        name: 'languages',
    })
    const topSkills = watch('topSkills')

    const [isLoading, setLoading] = useState(false)
    const [suggestedSkills, setSuggestedSkills] = useState<Array<CheckableSkill>>([])
    const [cv, setCv] = useState<SpecialistCv>()
    const [specialistInfo, setSpecialistInfo] = useState<SpecialistGeneralInformation>()
    const [syndicate, setSyndicate] = useState<AvailableOn>()

    const roles = useSpecialistRoles()
    const seniorities = useSeniorities()
    const countries = useCountries()
    const memorizedCountries = useMemo(() => countries?.map(c => c.name) || [], [countries])
    const skills = useSkills()
    const currencies = useCurrencies()

    useEffect(() => {
        setLoading(true)
        if (application.specialistId) {
            Promise.all([
                getSpecialistGeneralInformation(application.specialistId),
                getSpecialistSuggestedSkills(application.specialistId),
                getSpecialistLanguages(application.specialistId),
                getSpecialistCvParsingStatus(application.specialistId),
                getShareMySpecialistsSyndicates(),
            ])
                .then(([specialist, { suggestedSkills: specialistSuggestedSkills }, specialistLanguages, cvs, syndicates]) => {
                    setValue('seniority', specialist.seniority || '')
                    setValue('topSkills', specialist.topSkills)
                    setSuggestedSkills(specialistSuggestedSkills.map(skill => ({ name: skill })))
                    setValue(
                        'languages',
                        specialistLanguages.map(({ name, level }) => ({ name, level })),
                    )
                    setCv(cvs[0])
                    setSpecialistInfo(specialist)
                    setSyndicate(syndicates[0])
                })
                .catch(error => {
                    log(error)
                    addError()
                })
                .finally(() => setLoading(false))
        }
    }, [addError, application.specialistId, log, setValue])

    const onSubmit = useCallback(
        (data: ShareCandidateModalFormData) => {
            if (application.specialistId && specialistInfo && syndicate?.id) {
                setLoading(true)
                Promise.all([
                    updateSpecialistGeneralInformation(application.specialistId, {
                        ...specialistInfo,
                        country: data.country,
                        role: data.role,
                        seniority: data.seniority,
                        topSkills: data.topSkills,
                    }),
                    saveSpecialistLanguages(application.specialistId, data.languages),
                ])
                    .then(() => {
                        if (application.specialistId) {
                            editShareMySpecialistsSpecialistProfile(application.specialistId, {
                                availableFrom: data.availableFrom,
                                availableOn: [syndicate.id],
                                availableTo: data.availableTo,
                                country: data.country,
                                currency: data.currency,
                                rate: data.preferredRate,
                                shared: SharedType.SHARED,
                            })
                        }
                    })
                    .then(() => {
                        addSuccess('Specialist shared successfully!')
                        handleSharedStatus({ ...application, isNotShared: false })
                        onClose()
                    })
                    .catch(error => {
                        log(error)
                        addError()
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        },
        [addError, addSuccess, application, handleSharedStatus, log, onClose, specialistInfo, syndicate?.id],
    )

    const disabledAvailableFromDate = useCallback(
        (date: Moment): boolean => {
            const endDate = getValues('availableTo')
            return moment(date).isSameOrAfter(moment(endDate * 1000))
        },
        [getValues],
    )
    const disabledAvailableToDate = useCallback(
        (date: Moment): boolean => {
            const startDate = getValues('availableFrom')
            return moment(date).isSameOrBefore(moment(startDate * 1000))
        },
        [getValues],
    )

    const handleSkillAdd = useCallback(
        (skillsToAdd: Array<string>) => {
            setValue(
                'topSkills',
                topSkills.includes(skillsToAdd[0]) ? topSkills.filter(skill => skill !== skillsToAdd[0]) : [...topSkills, skillsToAdd[0]],
            )
            setSuggestedSkills(previousSkills =>
                previousSkills.map(skill => (skill.name === skillsToAdd[0] ? { ...skill, checked: !skill.checked } : skill)),
            )
            trigger('topSkills')
        },
        [setValue, topSkills, trigger],
    )

    useEffect(() => {
        setSuggestedSkills(previousSkills => previousSkills.map(skill => ({ ...skill, checked: topSkills?.includes(skill.name) })))
    }, [topSkills])

    const addLanguage = useCallback(() => {
        context.current.isAdding = true
        trigger(['name', 'level']).then(isValid => {
            if (isValid) {
                append({
                    name: getValues('name'),
                    level: getValues('level') || undefined,
                })
                resetField('name')
                resetField('level')
            }
            context.current.isAdding = false
        })
    }, [append, getValues, resetField, trigger])

    return (
        <Modal
            opened
            onClose={onClose}
            maskClosable={false}
            style={css`
                max-height: 100%;
                overflow-y: scroll;
                position: relative;
            `}
            containerStyles={css`
                padding: 30px 17px;
            `}
            closeButtonStyles={css`
                top: 17px;
                right: 17px;
            `}
        >
            <H5>Please provide additional information</H5>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading && <StyledLoader />}
                <FieldsWrapper>
                    <FieldWrapper padding='0 8px 0 0'>
                        <ControlledAutocompleteSelect
                            label='Seniority'
                            placeholder='Choose seniority'
                            options={seniorities}
                            canFilter={false}
                            control={control}
                            name='seniority'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            dataTestId='seniority'
                        />
                    </FieldWrapper>
                    <FieldWrapper padding='0 0 0 8px'>
                        <ControlledAutocompleteSelect
                            control={control}
                            name='role'
                            label='Role'
                            options={roles}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            dropdownWidth='100%'
                            forceDownOpen
                        />
                    </FieldWrapper>
                </FieldsWrapper>
                <InputField label='Preferred Rate' />
                <FieldsWrapper>
                    <FieldWrapper padding='0 11px 0 0' width='130px'>
                        <ControlledAutocompleteSelect
                            control={control}
                            name='currency'
                            options={currencies}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            labelTransformer={opt => `${opt}/h`}
                            selectedLabelTransformer={opt => `${opt}/h`}
                            canFilter={false}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledInput control={control} name='preferredRate' regex={naturalNumbersRegex} />
                    </FieldWrapper>
                </FieldsWrapper>
                <FieldsWrapper>
                    <FieldWrapper padding='0 16px 0 0'>
                        <ControlledDatePickerInput
                            control={control}
                            name='availableFrom'
                            label='Available from'
                            canClear={false}
                            disabledDate={disabledAvailableFromDate}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledDatePickerInput
                            control={control}
                            name='availableTo'
                            label='Available to'
                            canClear={false}
                            disabledDate={disabledAvailableToDate}
                        />
                    </FieldWrapper>
                </FieldsWrapper>
                <ControlledAutocompleteSelect
                    label='Country'
                    placeholder='Choose country'
                    options={memorizedCountries}
                    control={control}
                    name='country'
                    dropdownWidth='100%'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
                <Divider />
                {!!suggestedSkills.length && (
                    <StyledSuggestedSkills
                        handleSkillAdd={handleSkillAdd}
                        suggestedSkills={suggestedSkills}
                        title='Suggested top skills'
                        showToggle={false}
                    />
                )}
                <ControlledAutocompleteSelect
                    multiple
                    label='Top skills'
                    placeholder='Top skills'
                    options={skills}
                    control={control}
                    name='topSkills'
                    dataTestId='top-skills'
                />
                <Divider />
                <LanguageWrapper noError={!errors.languages}>
                    <AddLanguage control={control} />
                    <FlexBox justifyContent='end'>
                        <AddLanguageButton onClick={addLanguage}>Add</AddLanguageButton>
                    </FlexBox>
                </LanguageWrapper>
                {errors.languages && <StyledFieldInfo errorMessage={errors.languages.message} />}
                {fields.map((item, index) => (
                    <LanguageItem
                        key={item.id}
                        language={item}
                        index={index}
                        remove={remove}
                        control={control}
                        update={update}
                        trigger={trigger}
                        getValues={getValues}
                    />
                ))}
                <Divider />
                {cv?.cvDownloadUrl && cv?.fileName && (
                    <Fragment>
                        <H5>CV</H5>
                        <CVWrapper>
                            <a href={cv.cvDownloadUrl} target='_blank' rel='noopener noreferrer'>
                                <FileName>{cv.fileName}</FileName>
                            </a>
                        </CVWrapper>
                        <Divider />
                    </Fragment>
                )}
                <ButtonsWrapper>
                    <CancelButton variant='link' onClick={onClose}>
                        Cancel
                    </CancelButton>
                    <Button variant='primary' type='submit'>
                        Share this Specialist
                    </Button>
                </ButtonsWrapper>
            </form>
        </Modal>
    )
}

export { ShareCandidateModal }
