/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../../../components/filters/FiltersBarHelpers'
import { AutocompleteSelect } from '../../../components/inputs/autocomplete-select/AutocompleteSelect'
import { LocationsPicker } from '../../../components/inputs/LocationsPicker'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { SearchLocation } from '../../../contracts/common/searchLocation'
import { mapEmploymentType } from '../../../contracts/specialist/specialist'
import { SpecialistsFilters, SpecialistsFiltersOptions } from '../contracts'
import { countSelectedFilters } from '../mySpecialistsUtils'

type SpecialistsFiltersProps = {
    possibleFilters: SpecialistsFiltersOptions
    selectedFilters: SpecialistsFilters
    opened: boolean
    onChangeFilters: (filters: SpecialistsFilters) => void
    onClearAll: () => void
}

const SpecialistsFiltersBar: FunctionComponent<React.PropsWithChildren<SpecialistsFiltersProps>> = ({
    possibleFilters,
    selectedFilters,
    opened,
    onChangeFilters,
    onClearAll,
}) => {
    const onChangeFilter = useCallback(
        (values: Array<string | SearchLocation> | boolean, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: values,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    const onChangeLocations = useCallback((locations: any) => onChangeFilter(locations, 'locations'), [onChangeFilter])
    const onChangeRemote = useCallback(
        (remote: any) => {
            onChangeFilter(remote, 'remote')
        },
        [onChangeFilter],
    )

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Type'
                            options={possibleFilters.employmentTypes}
                            filterName='employmentTypes'
                            isActive={(selectedFilters?.employmentTypes || []).length > 0}
                            currentValues={selectedFilters?.employmentTypes}
                            selectedLabelTransformer={mapEmploymentType}
                            onSelectedValuesChange={onChangeFilter}
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Role'
                            options={possibleFilters.roles}
                            filterName='roles'
                            isActive={(selectedFilters?.roles || []).length > 0}
                            currentValues={selectedFilters.roles}
                            onSelectedValuesChange={onChangeFilter}
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Seniority'
                            options={possibleFilters.seniorities}
                            filterName='seniorities'
                            isActive={(selectedFilters?.seniorities || []).length > 0}
                            currentValues={selectedFilters.seniorities}
                            onSelectedValuesChange={onChangeFilter}
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Top Skills'
                            options={possibleFilters.skills}
                            filterName='skills'
                            currentValues={selectedFilters.skills}
                            onSelectedValuesChange={onChangeFilter}
                        />
                    </FilterColumn>

                    <LocationsPicker
                        locations={possibleFilters.locations}
                        highlightActive={true}
                        selectedLocations={selectedFilters?.locations || []}
                        selectedRemote={selectedFilters.remote}
                        onChangeLocations={onChangeLocations}
                        onChangeRemote={onChangeRemote}
                    />

                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(selectedFilters)} />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { SpecialistsFiltersBar }
