/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useState } from 'react'
import { Button } from '../../../components/Button'
import { Icon } from '../../../components/Icon'

type SectionWrapperProps = {
    onEditClick?: (e: MouseEvent) => void
    showButton?: boolean
    children: any
}

const SectionWrapper: FunctionComponent<React.PropsWithChildren<SectionWrapperProps>> = ({ children, onEditClick, showButton = true }) => {
    const theme = useTheme()

    const [showEditButton, setShowEditButton] = useState(false)

    const handleHover = useCallback((show: boolean) => {
        setShowEditButton(show)
    }, [])

    const handleClick = useCallback(
        (e: any) => {
            if (onEditClick) {
                onEditClick(e)
            }
        },
        [onEditClick],
    )

    return (
        <div
            css={css`
                padding: 24px;
                margin: 24px 0;
                min-height: 90px;
                border: 1px solid ${theme.colors.gray_2};
                display: flex;
                justify-content: space-between;
                position: relative;
            `}
            onMouseEnter={() => handleHover(true)}
            onMouseLeave={() => handleHover(false)}
        >
            <div
                css={css`
                    padding-right: 24px;
                    width: 100%;
                `}
            >
                {children}
            </div>
            {showButton && (
                <div
                    css={css`
                        opacity: ${showEditButton ? 1 : 0};
                        position: absolute;
                        top: 24px;
                        right: 24px;
                    `}
                >
                    <Button variant='tertiary' onClick={handleClick}>
                        <Icon
                            name='edit'
                            size={16}
                            css={css`
                                margin-right: 8px;
                            `}
                        />
                        Edit
                    </Button>
                </div>
            )}
        </div>
    )
}

export { SectionWrapper }
