/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { CVEditFormData, CVStatusEnum, CVStatusMapping } from '../contracts'

interface StatusEditProps {
    status: CVStatusEnum
    control: Control<CVEditFormData>
    index: number
}

const StatusEdit: FunctionComponent<StatusEditProps> = ({ status, index, control }) => (
    <ControlledAutocompleteSelect
        canFilter={false}
        options={Object.keys(CVStatusEnum)}
        defaultValue={status ? [status] : []}
        control={control}
        name={`cvs.${index}.status`}
        dropdownWidth='100%'
        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
        selectedLabelTransformer={val => CVStatusMapping[val as CVStatusEnum]}
        styles={css`
            min-width: 123px;
        `}
        shouldUnregister={false}
    />
)

export { StatusEdit }
