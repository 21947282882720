/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { BottomBar } from 'src/components/BottomBar'
import { BackLink } from '../../BackLink'
import { BlueActionBox } from '../../BlueActionBox'
import { Button } from '../../Button'
import { ControlledInput } from '../../forms/ControlledInput'
import { FlexBox } from '../../layout/FlexBoxHelpers'
import { FieldWrapper } from '../../layout/FormHelpers'
import { LayoutWithStickyColumn } from '../../layout/LayoutWithStickyColumn'
import { useNotifications } from '../../notification/NotificationProvider'
import { getAppPath } from '../../../contracts/applications'
import { CompanyMembersResponse } from '../../../contracts/companyMembers'
import { OpportunityStatus } from '../../../contracts/opportunities'
import { history } from '../../../history'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { hasErrors } from '../../../utils/errors'
import { useLogger } from '../../../utils/useLogger'
import { useQuery } from '../../../utils/useQuery'
import { updateOpportunityStatus } from '../../../api/opportunities-manager/api'
import { Box } from '../Box'
import { OpportunityResponseBody, UpdateOpportunityFormData } from '../../../contracts/opportunities-manager/contracts'
import { CustomCVTemplate } from './components/CustomCVTemplate'
import { InstructionsBox } from './components/InstructionsBox'
import { InternalDetails } from './components/InternalDetails'
import { JobDetails } from './components/JobDetails'
import { LocationDetails } from './components/LocationDetails'
import { OpportunityDetails } from './components/OpportunityDetails'
import { ProjectDetails } from './components/ProjectDetails'
import { getDefaultEmptyValues, getDefaultValues } from './utils/getDefaultValues'
import { opportunityFormValidationSchema, smeOpportunityFormValidationSchema } from './utils/validation'
import { ContactDetails } from './components/ContactDetails'

type OpportunityFormProps = {
    opportunity?: OpportunityResponseBody
    onSubmit: (
        formData: UpdateOpportunityFormData,
        companyMembers: Nullable<CompanyMembersResponse>,
        hideLoaderOnSuccess?: boolean,
    ) => Promise<OpportunityResponseBody | void>
    isLoading?: boolean
    companyMembers: Nullable<CompanyMembersResponse>
    isSmeCloudApp: boolean
    isVMCCloudApp: boolean
}

const OpportunityForm: FunctionComponent<React.PropsWithChildren<OpportunityFormProps>> = ({
    opportunity,
    onSubmit,
    companyMembers,
    isSmeCloudApp,
    isVMCCloudApp,
}) => {
    const context = useRef({
        isDraft: true,
    })
    const {
        control,
        watch,
        handleSubmit,
        setValue,
        clearErrors,
        getValues,
        reset,
        formState: { isDirty, isSubmitting, errors, isValid },
    } = useForm<UpdateOpportunityFormData>({
        context: context.current,
        resolver: yupResolver(isSmeCloudApp ? smeOpportunityFormValidationSchema : opportunityFormValidationSchema),
        defaultValues: getDefaultEmptyValues(),
        mode: 'onChange',
    })
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const appPath = useMemo(
        () =>
            isSmeCloudApp
                ? getAppPath('OPPORTUNITIES_MANAGER_SME')
                : isVMCCloudApp
                ? getAppPath('VACANCIES_MANAGER')
                : getAppPath('OPPORTUNITIES_MANAGER'),
        [isSmeCloudApp, isVMCCloudApp],
    )

    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()

    const requiredSkills = watch('jobDetails.requiredTechnicalSkills')
    const keySkills = watch('jobDetails.keyTechnicalSkills')

    useEffect(() => {
        if (opportunity) {
            reset(getDefaultValues(opportunity))
        }
    }, [opportunity, reset])

    useEffect(() => {
        if (requiredSkills) {
            const filteredKeyTechnicalSkills = keySkills.filter(skill => requiredSkills?.includes(skill))
            setValue('jobDetails.keyTechnicalSkills', filteredKeyTechnicalSkills)
        }
        // Needed to do it due to value setting using react hook form
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requiredSkills, setValue])

    const query = useQuery()

    const handleSaveAsDraft = useCallback(() => {
        context.current.isDraft = true
        handleSubmit((formData: UpdateOpportunityFormData) => {
            onSubmit(formData, companyMembers)
        })()
    }, [companyMembers, handleSubmit, onSubmit])

    const handlePublish = useCallback(() => {
        context.current.isDraft = false
        handleSubmit((formData: UpdateOpportunityFormData) => {
            onSubmit(formData, companyMembers, false)
                .then(data => {
                    if (data && opportunity?.opportunityStatus !== OpportunityStatus.PUBLISHED) {
                        updateOpportunityStatus(data.opportunityId, { status: OpportunityStatus.PUBLISHED })
                            .then(() => {
                                addSuccess(
                                    isVMCCloudApp
                                        ? 'You successfully published this Vacancy'
                                        : 'You successfully published this Opportunity',
                                )
                                history.push(decodeURIComponent(query.get('prevPath') || appPath))
                            })
                            .catch(err => {
                                log(err)
                                addError()
                            })
                    } else {
                        addSuccess(isVMCCloudApp ? 'You successfully saved this Vacancy' : 'You successfully saved this Opportunity')
                        history.push(decodeURIComponent(query.get('prevPath') || appPath))
                    }
                    layoutToggleLoader(false)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        })()
    }, [
        addError,
        addSuccess,
        appPath,
        companyMembers,
        isVMCCloudApp,
        handleSubmit,
        layoutToggleLoader,
        log,
        onSubmit,
        opportunity?.opportunityStatus,
        query,
    ])

    return (
        <Fragment>
            <LayoutWithStickyColumn
                style={css`
                    padding-bottom: 75px;
                `}
                firstCol={
                    <form>
                        <BackLink
                            style={css`
                                margin-bottom: 20px;
                            `}
                            path={decodeURIComponent(query.get('prevPath') || appPath)}
                            text='Back to list'
                            dataTestId='back-to-list'
                        />
                        <BlueActionBox
                            text={
                                isVMCCloudApp
                                    ? 'Complete all required fields to publish your Vacancy'
                                    : 'Complete all required fields to publish your Opportunity'
                            }
                            styles={css`
                                margin-bottom: 32px;
                                border-radius: 4px;
                                padding: 8px;
                            `}
                        />
                        <Box>
                            <h6>{isVMCCloudApp ? 'Add New Vacancy' : 'Add New Opportunity'}</h6>
                            <FieldWrapper>
                                <ControlledInput
                                    control={control}
                                    name='opportunityTitle'
                                    label={isVMCCloudApp ? 'Vacancy Title' : 'Opportunity Title'}
                                    placeholder={isVMCCloudApp ? 'Vacancy Title' : 'Opportunity Title'}
                                />
                            </FieldWrapper>
                        </Box>
                        <InternalDetails
                            control={control}
                            companyMembers={companyMembers}
                            isSmeCloudApp={isSmeCloudApp}
                            isVMCCloudApp={isVMCCloudApp}
                        />
                        {isSmeCloudApp || isVMCCloudApp ? <ContactDetails control={control} /> : null}
                        <ProjectDetails control={control} />
                        <OpportunityDetails
                            control={control}
                            getValues={getValues}
                            watch={watch}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            isVMCCloudApp={isVMCCloudApp}
                        />
                        {!isSmeCloudApp && !isVMCCloudApp && <CustomCVTemplate control={control} />}
                        <LocationDetails
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            clearErrors={clearErrors}
                            getValues={getValues}
                        />
                        <JobDetails control={control} requiredSkills={requiredSkills} />
                    </form>
                }
                secondCol={<InstructionsBox isSmeCloud={isSmeCloudApp} isVMCCloudApp={isVMCCloudApp} />}
            />
            <BottomBar>
                <LayoutWithStickyColumn
                    firstCol={
                        <FlexBox
                            justifyContent={opportunity?.opportunityStatus === OpportunityStatus.PUBLISHED ? 'space-between' : 'flex-end'}
                        >
                            {opportunity?.opportunityStatus !== OpportunityStatus.PUBLISHED && (
                                <Button variant='tertiary' onClick={handleSaveAsDraft} dataTestId='save-opportunity-as-draft'>
                                    Save as Draft
                                </Button>
                            )}

                            {opportunity?.opportunityStatus === OpportunityStatus.PUBLISHED && (
                                <Link to={decodeURIComponent(query.get('prevPath') || appPath)}>
                                    <Button variant='link'>Cancel</Button>
                                </Link>
                            )}

                            <Button
                                onClick={handlePublish}
                                style={css`
                                    margin-left: 16px;
                                `}
                                disabled={
                                    opportunity?.opportunityStatus === OpportunityStatus.PUBLISHED
                                        ? !isDirty || isSubmitting || hasErrors(errors)
                                        : !isValid || isSubmitting || hasErrors(errors)
                                }
                                dataTestId='publish-opportunity'
                            >
                                {opportunity?.opportunityStatus === OpportunityStatus.PUBLISHED ? 'Save' : 'Publish'}
                            </Button>
                        </FlexBox>
                    }
                />
            </BottomBar>
        </Fragment>
    )
}

export { OpportunityForm }
