/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ClipboardEvent, FocusEvent, FunctionComponent, KeyboardEvent, useCallback, useMemo, useRef, useState } from 'react'
import { COLOR_PALETTE } from '../../../theme/colors'
import { InputField, InputFieldProps } from '../input-field/InputField'
import { Tag } from './components/Tag'

export type TagInputProps = InputFieldProps & {
    tags: Array<string>
    onChange: (tags: Array<string>) => void
    placeholder?: string
    maxTags?: number
    readOnly?: boolean
    removeOnBackspace?: boolean
    addKeys?: Array<string>
    pasteSplitRegex?: RegExp
    validationRegex: RegExp
    dataTestId?: string
}

const TagInput: FunctionComponent<React.PropsWithChildren<TagInputProps>> = ({
    tags,
    onChange,
    placeholder = '',
    maxTags,
    readOnly = false,
    removeOnBackspace,
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    addKeys = ['Enter'],
    pasteSplitRegex = /\s|,|;|>|</gm,
    validationRegex,
    dataTestId,
}) => {
    const [input, setInput] = useState('')

    const inputRef = useRef<HTMLInputElement>(null)

    const onInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value)
    }, [])

    const addTag = useCallback(
        (value: string) => {
            const innerTags = [...tags]
            onChange(innerTags.concat(value))
            setInput('')
        },
        [onChange, tags],
    )

    const removeTag = useCallback(
        (index: number) => {
            const innerTags = [...tags]
            innerTags.splice(index, 1)
            onChange(innerTags)
        },
        [onChange, tags],
    )

    const onPaste = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            event.preventDefault()
            const pastedData = event.clipboardData?.getData('Text')
            if (pastedData) {
                const splitted: Array<string> = pastedData
                    .trim()
                    .split(pasteSplitRegex)
                    .filter(i => i)

                onChange([...tags, ...splitted])
                setInput('')
            }
        },
        [onChange, pasteSplitRegex, tags],
    )

    const onInputKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
            if (addKeys.includes(event.key)) {
                event.preventDefault()
                if (input === '') {
                    return
                }

                addTag(input)
            } else if (removeOnBackspace && (event.key === 'Backspace' || event.key === 'Delete')) {
                if (input !== '') {
                    return
                }

                removeTag(tags.length - 1)
            }
        },
        [addKeys, addTag, input, removeOnBackspace, removeTag, tags.length],
    )

    const onBlur = useCallback(
        (event: FocusEvent<HTMLInputElement>) => {
            const innerTags = [...tags]
            if (event.target.value) {
                onChange(innerTags.concat(event.target.value))
            }

            setInput('')
        },
        [onChange, tags],
    )

    const maxTagsReached = useMemo(() => (maxTags !== undefined ? tags.length >= maxTags : false), [maxTags, tags.length])

    const showInput = useMemo(() => !readOnly && !maxTagsReached, [maxTagsReached, readOnly])

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
            `}
        >
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
                dataTestId={dataTestId}
            >
                <label
                    css={css`
                        position: relative;
                        width: 100%;
                        height: auto;
                        min-height: 100px;
                        padding: 6px 4px;
                        overflow-y: auto;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        background: white;
                        color: ${COLOR_PALETTE.gray_5};
                        border: 1px solid ${errorMessage ? COLOR_PALETTE.red_4 : COLOR_PALETTE.gray_3};
                        border-radius: 2px;
                        margin-bottom: 6px;
                    `}
                >
                    {tags.map((tag, i) => (
                        <Tag
                            key={i}
                            value={tag}
                            index={i}
                            readOnly={readOnly}
                            remove={removeTag}
                            removeOnBackspace={removeOnBackspace}
                            addKeys={addKeys}
                            validationRegex={validationRegex}
                        />
                    ))}
                    {showInput && (
                        <input
                            css={css`
                                width: auto;
                                flex-grow: 1;
                                padding: 4px 0;
                                margin: 4px;
                                font-size: 14px;
                                line-height: 22px;
                                background: transparent;
                                color: ${COLOR_PALETTE.gray_5};
                                border: none;
                                border-radius: 5px;
                                outline: 0;
                                box-shadow: none;
                                -webkit-appearance: none;
                            `}
                            ref={inputRef}
                            value={input}
                            placeholder={placeholder}
                            onChange={onInputChange}
                            onBlur={onBlur}
                            onKeyDown={onInputKeyDown}
                            onPaste={onPaste}
                        />
                    )}
                </label>
            </InputField>
        </div>
    )
}

export { TagInput }
