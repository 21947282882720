import { darken, lighten } from 'polished'
import { WhiteLabel } from '../contracts/whiteLabel'
import { Nullable } from '../types'
import { COLOR_PALETTE } from './colors'

export type Theme = {
    colors: typeof COLOR_PALETTE & {
        main_dark: string
        main: string
        main_light: string
        main_lighter: string
        text: string
    }
    space: Array<number>
    shadow: {
        small: string
        bottomBar: string
    }
}

const generateTheme = (whiteLabel: Nullable<WhiteLabel>) => {
    const mainColor = (whiteLabel?.active && whiteLabel?.color) || COLOR_PALETTE.yellow_4
    const textColor = whiteLabel?.active
        ? whiteLabel?.darkText !== false
            ? COLOR_PALETTE.gray_6
            : COLOR_PALETTE.white
        : COLOR_PALETTE.gray_6
    return {
        colors: {
            ...COLOR_PALETTE,
            main_dark: whiteLabel?.active ? darken(0.2, mainColor) : COLOR_PALETTE.yellow_5,
            main: mainColor,
            main_light: whiteLabel?.active ? lighten(0.1, mainColor) : COLOR_PALETTE.yellow_3,
            main_lighter: whiteLabel?.active ? lighten(0.3, mainColor) : COLOR_PALETTE.yellow_2,
            text: textColor,
        },
        space: [0, 2, 4, 6, 8, 12, 16, 24, 32, 64],
        shadow: {
            small: '0px 2px 5px rgba(0, 0, 0, 0.04)',
            bottomBar: '0px -2px 8px rgba(0, 0, 0, 0.08)',
        },
    }
}

export { generateTheme }
