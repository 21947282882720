import { axios } from '../axios'
import { OpportunityStatus as OpportunityStatusV2 } from '../../contracts/opportunities'
import {
    AllApplicationsItem,
    Application,
    OpportunityResponseBody,
    OpportunitySummary,
    SearchAllApplicationsFiltersOptions,
    SearchAllApplicationsRequestParams,
    SearchAllApplicationsResponseBody,
    SearchApplicationsFiltersOptions,
    SearchApplicationsRequestParams,
    SearchApplicationsResponseBody,
    SearchOpportunitiesFiltersOptions,
    SearchOpportunitiesRequestParams,
    SearchOpportunityResponseBody,
    UpdateApplicationBody,
    UpdateOpportunityRequestBody,
} from '../../contracts/opportunities-manager/contracts'

// OPPORTUNITIES
const opportunitiesPath = '/v4/opportunities'

const addOpportunity = (data: UpdateOpportunityRequestBody): Promise<OpportunityResponseBody> => {
    return axios.post(`${opportunitiesPath}`, data)
}

const editOpportunity = (data: UpdateOpportunityRequestBody, id: string): Promise<OpportunityResponseBody> => {
    return axios.put(`${opportunitiesPath}/${id}`, data)
}

const getOpportunity = (id: string): Promise<OpportunityResponseBody> => {
    return axios.get(`${opportunitiesPath}/${id}`)
}

const getOpportunitySummary = (id: string): Promise<OpportunitySummary> => {
    return axios.get(`${opportunitiesPath}/${id}/summary`)
}

const updateOpportunityStatus = (id: string, data: { status: OpportunityStatusV2 }): Promise<OpportunityResponseBody> => {
    return axios.patch(`${opportunitiesPath}/${id}/status`, data)
}

const searchOpportunities = (params: SearchOpportunitiesRequestParams): Promise<SearchOpportunityResponseBody> => {
    return axios.post(`${opportunitiesPath}:search`, params)
}

const getOpportunitiesFilters = (): Promise<SearchOpportunitiesFiltersOptions> => {
    return axios.post(`${opportunitiesPath}:filters`)
}

const duplicateOpportunity = (opportunityId: string): Promise<OpportunityResponseBody> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}:duplicate`)
}

const getSharedOpportunity = (id: string): Promise<Omit<OpportunityResponseBody, 'internalDetails'>> => {
    return axios.get(`/v4/public/opportunities/${id}`)
}

const getOpportunityAuthorised = (id: string): Promise<Omit<OpportunityResponseBody, 'internalDetails'>> => {
    return axios.get(`/v4/opportunities/${id}/open`)
}

const searchOpportunityApplications = (
    opportunityId: string,
    params: SearchApplicationsRequestParams,
): Promise<SearchApplicationsResponseBody> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}/applications:search`, params)
}

const getOpportunityApplicationsFilters = (opportunityId: string): Promise<SearchApplicationsFiltersOptions> => {
    return axios.post(`${opportunitiesPath}/${opportunityId}/applications:filters`)
}

const updateOpportunityApplication = (applicationId: string, data: UpdateApplicationBody): Promise<Application> => {
    return axios.patch(`${opportunitiesPath}/-/applications/${applicationId}`, data)
}

const getApplicationManagerView = (applicationId: string): Promise<Application> => {
    return axios.get(`${opportunitiesPath}/-/applications/${applicationId}:managerView`)
}

const getApplicationCv = (applicationId: string): Promise<File> => {
    return axios.get(`${opportunitiesPath}/-/applications/${applicationId}/cv`, {
        responseType: 'blob',
    })
}

const searchAllApplications = (params: SearchAllApplicationsRequestParams): Promise<SearchAllApplicationsResponseBody> => {
    return axios.post(`${opportunitiesPath}/-/applications:searchAll`, params)
}

const getAllApplicationsFilters = (): Promise<SearchAllApplicationsFiltersOptions> => {
    return axios.post(`${opportunitiesPath}/-/applications:searchAllFilters`)
}

const updateAllOpportunityApplication = (applicationId: string, data: UpdateApplicationBody): Promise<AllApplicationsItem> => {
    return axios.patch(`${opportunitiesPath}/-/applications/${applicationId}:allView`, data)
}

const inviteCandidate = (applicationId: string, opportunityId: string) => {
    return axios.post(`${opportunitiesPath}/-/applications/${applicationId}:invite`, { opportunityId })
}

export {
    addOpportunity,
    editOpportunity,
    getOpportunity,
    updateOpportunityStatus,
    getOpportunitiesFilters,
    duplicateOpportunity,
    searchOpportunities,
    getSharedOpportunity,
    searchOpportunityApplications,
    getOpportunityApplicationsFilters,
    updateOpportunityApplication,
    getApplicationManagerView,
    getOpportunitySummary,
    getApplicationCv,
    getOpportunityAuthorised,
    searchAllApplications,
    getAllApplicationsFilters,
    updateAllOpportunityApplication,
    inviteCandidate,
}
