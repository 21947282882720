import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { getSubscriptionData } from 'src/api/api'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { Subscription } from 'src/contracts/subscription'
import { useLogger } from 'src/utils/useLogger'

const useSubscription = () => {
    const [subscriptionData, setSubscriptionData] = useState<Subscription | null>(null)

    const { addError } = useNotifications()
    const log = useLogger('error')

    useEffectOnce(() => {
        getSubscriptionData()
            .then(response => {
                setSubscriptionData(response)
            })
            .catch(error => {
                addError('Error while trying to get subscription data...')
                log(error)
            })
    })

    return { subscriptionData }
}

export { useSubscription }
