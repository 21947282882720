import {
    SECTIONS,
    MY_SPECIALISTS,
    TALENT_MARKETPLACE,
    OPPORTUNITITES,
    MANAGE_MY_COMPANY,
    QUESTIONS_ENUM_TO_PARAM,
    SUBSCRIPTION,
} from './consts'

const questionToSection = (q: string | null) => {
    if (q === null) {
        return SECTIONS[0]
    } else if ((Object.values(MY_SPECIALISTS) as string[]).includes(q)) {
        return SECTIONS[0]
    } else if ((Object.values(TALENT_MARKETPLACE) as string[]).includes(q)) {
        return SECTIONS[1]
    } else if ((Object.values(OPPORTUNITITES) as string[]).includes(q)) {
        return SECTIONS[2]
    } else if ((Object.values(MANAGE_MY_COMPANY) as string[]).includes(q)) {
        return SECTIONS[3]
    } else if ((Object.values(SUBSCRIPTION) as string[]).includes(q)) {
        return SECTIONS[4]
    }

    return SECTIONS[0]
}

const getParam = (q: string) => (q ? QUESTIONS_ENUM_TO_PARAM[q] : q)

export { questionToSection, getParam }
