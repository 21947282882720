/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

type TooltipIconProps = {
    styles?: SerializedStyles
}

const TooltipIcon: FunctionComponent<React.PropsWithChildren<TooltipIconProps>> = ({ styles, children }) => {
    const theme = useTheme()
    return (
        <span
            css={css`
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 2px;
                background-color: ${theme.colors.gray_2};
                font-size: 12px;
                color: ${theme.colors.gray_4};
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 4px;
                cursor: default;

                ::before {
                    content: '?';
                }

                ${styles}
            `}
        >
            {children}
        </span>
    )
}

export { TooltipIcon }
