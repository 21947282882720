/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { UseFormSetValue } from 'react-hook-form/dist/types'
import { ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { TooltipIcon } from 'src/components/TooltipIcon'
import { AvailableOn as AvailableOnType } from 'src/contracts/search/availableOn'
import { ShareMySpecialistsFormData } from '../../contracts'
import { CellWrapper, InputWrapper } from '../../utils'
import { findSyndicateNameById } from 'src/utils/values'

interface AvailableOnProps {
    availableOn: Array<string>
    index: number
    syndicates: Array<AvailableOnType>
}

interface AvailableOnEditProps extends AvailableOnProps {
    control: Control<ShareMySpecialistsFormData>
    setValue: UseFormSetValue<ShareMySpecialistsFormData>
    watch?: (name: string) => any
    options: Array<string>
}

const AvailableOn: FunctionComponent<React.PropsWithChildren<AvailableOnProps>> = ({ index, availableOn, syndicates }) => (
    <CellWrapper
        css={css`
            white-space: nowrap;
            min-width: 100px;
        `}
    >
        {availableOn.length > 0 ? (
            availableOn.length === 1 ? (
                findSyndicateNameById(availableOn[0], syndicates)
            ) : (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {findSyndicateNameById(availableOn[0], syndicates)}

                    <Tooltip
                        placement='topRight'
                        key={`tooltip.specialists.${index}`}
                        overlay={availableOn.slice(1).map((id, key) => (
                            <div key={key}>{findSyndicateNameById(id, syndicates)} </div>
                        ))}
                    >
                        <span>
                            <TooltipIcon
                                styles={css`
                                    ::before {
                                        content: '${availableOn.length - 1}';
                                    }
                                `}
                            />
                        </span>
                    </Tooltip>
                </div>
            )
        ) : (
            '-'
        )}
    </CellWrapper>
)

const AvailableOnEdit: FunctionComponent<React.PropsWithChildren<AvailableOnEditProps>> = ({
    index,
    control,
    setValue,
    availableOn,
    syndicates,
    options,
}) => {
    const isInSyndicate = useMemo(() => syndicates.length > 1, [syndicates.length])

    useEffect(() => {
        if (!isInSyndicate) {
            setValue(`specialists.${index}.availableOn`, [syndicates[0].id])
        } else {
            setValue(`specialists.${index}.availableOn`, availableOn)
        }
    }, [index, availableOn, setValue, isInSyndicate, syndicates])

    const availableOnValues = useMemo(() => (isInSyndicate ? availableOn : [syndicates[0].id]), [availableOn, isInSyndicate, syndicates])

    return (
        <InputWrapper>
            <ControlledAutocompleteSelect
                canFilter={false}
                multiple
                placeholder='Available On'
                control={control}
                name={`specialists.${index}.availableOn`}
                defaultValues={availableOnValues}
                disabled={!isInSyndicate}
                selectedLabelTransformer={(id: string) => findSyndicateNameById(id, syndicates)}
                options={options}
            />
        </InputWrapper>
    )
}

export { AvailableOn, AvailableOnEdit }
