/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { mqMax } from 'src/GlobalStyle'
import { getLocale } from 'src/utils/locale'
import { naturalNumbersWithTwoDecimalsRegex } from 'src/utils/regexes'
import { ShareMySpecialistsFormData } from '../../contracts'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { CellWrapper, InputWrapper } from '../../utils'
interface RateProps {
    record: ShareMySpecialistsSpecialist
    roundToInteger?: boolean
}

interface RateEditProps extends RateProps {
    index: number
    control: Control<ShareMySpecialistsFormData>
}

// Current typescript doesn't have `roundingMode` type specified,
// even though javascript reference docs have it mentioned (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat).
// This property is annotaed as 'experimental' but testing it on Chrome, Safari (Mac) and Chrome (Android), it works as intended.
// @ts-ignore
const rateFormatter = new Intl.NumberFormat(getLocale(), { maximumFractionDigits: 2, roundingMode: 'ceil' })
// @ts-ignore
const integerFormatter = new Intl.NumberFormat(getLocale(), { maximumFractionDigits: 0, roundingMode: 'ceil' })

const Rate: FunctionComponent<React.PropsWithChildren<RateProps>> = ({ record, roundToInteger = false }) => {
    const formatter = useMemo(() => (roundToInteger ? integerFormatter : rateFormatter), [roundToInteger])

    return (
        <CellWrapper
            css={css`
                width: 60px;
                ${mqMax[2]} {
                    width: 100%;
                }
            `}
        >
            {record.rate ? formatter.format(record.rate) : '-'}
        </CellWrapper>
    )
}

const RateEdit: FunctionComponent<React.PropsWithChildren<RateEditProps>> = ({ record, index, control }) => (
    <InputWrapper
        css={css`
            width: 60px;
            ${mqMax[2]} {
                width: 100%;
            }
        `}
    >
        <ControlledInput
            control={control}
            type='number'
            name={`specialists.${index}.rate`}
            defaultValue={String(record.rate || '')}
            placeholder='Rate'
            inputStyle={css`
                padding: 0 11px;
            `}
            regex={naturalNumbersWithTwoDecimalsRegex}
        />
    </InputWrapper>
)

export { Rate, RateEdit }
