import { useEffect, useMemo, useState } from 'react'
import { getUserSegmentationCompanies } from '../api/api'
import { UserSegmentationCompany } from '../contracts/company'
import { Nullable } from '../types'
import { useLogger } from '../utils/useLogger'

const useUserCompanies = () => {
    const [userSegmentationCompanies, setUserSegmentationCompanies] = useState<Nullable<Array<UserSegmentationCompany>>>(null)
    const log = useLogger()

    useEffect(() => {
        getUserSegmentationCompanies()
            .then(data => {
                setUserSegmentationCompanies(data)
            })
            .catch(log)
    }, [log])

    const hasInactiveCompany: Nullable<boolean> = useMemo(
        () => (userSegmentationCompanies ? userSegmentationCompanies?.some(company => company.status === 'INACTIVE') : null),
        [userSegmentationCompanies],
    )

    return { hasInactiveCompany }
}

export { useUserCompanies }
