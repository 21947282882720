import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import { getCompanyMembers } from '../api/api'
import { CompanyMembersResponse } from '../contracts/companyMembers'
import { Nullable } from '../types'
import { useLogger } from '../utils/useLogger'

const useCompanyMembers = () => {
    const [companyMembers, setCompanyMembers] = useState<Nullable<CompanyMembersResponse>>(null)
    const log = useLogger()

    useEffectOnce(() => {
        getCompanyMembers()
            .then(data => {
                setCompanyMembers(data)
            })
            .catch(log)
    })

    return companyMembers
}

export { useCompanyMembers }
