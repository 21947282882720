/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AccessLevelContext } from 'src/AccessLevelContext'
import { Tabs, TabsColor } from 'src/components/Tabs'
import { Specialist } from 'src/contracts/specialist/specialist'
import { mqMax } from 'src/GlobalStyle'
import { useQuery } from 'src/utils/useQuery'
import { KeyInfo } from './contracts'
import { HeaderWithActions } from './shared/HeaderWithActions'
import { EducationTab } from './specialist-profile/EducationTab'
import { GeneralInformationTab } from './specialist-profile/general-information/GeneralInformationTab'
import { PersonalTab } from './specialist-profile/PersonalTab'
import { Projects } from './specialist-profile/Projects'
import { StrengthTab } from './specialist-profile/StrengthTab'
import { TechnicalSkillsTab } from './specialist-profile/TechnicalSkillsTab'
import { SpecialistTabType } from './SpecialistProfilePage'
import { SpecialistGeneralInformation } from 'src/contracts/specialist/specialistGeneralInformation'
import { Nullable } from 'src/types'

type SpecialistTabsProps = {
    specialist: Specialist
    specialistId: string
    handleSave: () => void
    forceFocus: string
    setForceFocus: Dispatch<SetStateAction<string>>
    generalInformation: Nullable<SpecialistGeneralInformation>
    fetchGeneralInformation: () => void
}

const SpecialistTabs: FunctionComponent<React.PropsWithChildren<SpecialistTabsProps>> = ({
    specialist,
    specialistId,
    handleSave,
    forceFocus,
    setForceFocus,
    generalInformation,
    fetchGeneralInformation,
}) => {
    const query = useQuery()
    const [tab, setTab] = useState(query.get('tab') ? Number(query.get('tab')) : SpecialistTabType.GENERAL)

    const {
        mySpecialists: mySpecialistsAccess,
        header: { menuItems: showMenuItems },
    } = useContext(AccessLevelContext)

    const containerRef = useRef<HTMLDivElement>(null)

    const onTabChange = useCallback((tabIndex: any) => {
        setTab(tabIndex)
    }, [])

    useEffect(() => {
        switch (forceFocus) {
            case KeyInfo.LOCATION: {
                setTab(SpecialistTabType.GENERAL)
                break
            }
            case KeyInfo.EXPERIENCE: {
                setTab(SpecialistTabType.PROJECTS)
                break
            }
            case KeyInfo.EDUCATION: {
                setTab(SpecialistTabType.EDUCATION)
                break
            }
            case KeyInfo.SKILLS: {
                setTab(SpecialistTabType.TECHNICAL)
                break
            }
            default: {
                break
            }
        }
    }, [forceFocus])

    const specialistTabsUtils = [
        { title: 'General Information' },
        { title: 'Personal Details', dataTestId: 'specialist-tab-personal-details' },
        { title: 'Experience', dataTestId: 'specialist-tab-experience' },
        { title: 'Education', dataTestId: 'specialist-tab-education' },
        {
            title: 'Technical Skills',
            dataTestId: 'specialist-tab-technical-skills',
            disabled: !mySpecialistsAccess.technicalSkills.read,
            tooltip: mySpecialistsAccess.technicalSkills.tabTooltip
                ? 'Once you finish adding Personal Details, Projects and Education you will receive Skill Discovery forms which will help filling this tab in.'
                : undefined,
        },
        {
            title: 'Personal Strengths',
            dataTestId: 'specialist-tab-personal-strengths',
        },
    ]

    return (
        <div
            css={css`
                overflow: visible;

                ${mqMax[1]} {
                    margin-top: ${showMenuItems ? 0 : 57}px;
                }
            `}
        >
            <HeaderWithActions
                header={`${specialist.firstName} ${specialist.lastName}`}
                specialistId={specialistId}
                onSave={handleSave}
                specialistData={specialist}
            />

            <Tabs
                tabs={specialistTabsUtils}
                mode={TabsColor.BLACK}
                onTabChange={onTabChange}
                currentTab={tab}
                containerRef={containerRef}
            />

            {tab === SpecialistTabType.GENERAL && (
                <GeneralInformationTab
                    key={generalInformation?.specialistId}
                    specialistId={specialistId}
                    onSave={handleSave}
                    companyName={specialist.companyName || ''}
                    focusLocation={forceFocus === KeyInfo.LOCATION}
                    setForceFocus={setForceFocus}
                    generalInformation={generalInformation}
                    fetchGeneralInformation={fetchGeneralInformation}
                />
            )}
            {tab === SpecialistTabType.PERSONAL && <PersonalTab specialistId={specialistId} onSave={handleSave} />}
            {tab === SpecialistTabType.PROJECTS && (
                <Projects
                    specialistId={specialistId}
                    onSave={handleSave}
                    focus={forceFocus === KeyInfo.EXPERIENCE}
                    setForceFocus={setForceFocus}
                    containerRef={containerRef}
                />
            )}
            {tab === SpecialistTabType.EDUCATION && (
                <EducationTab
                    specialistId={specialistId}
                    onSave={handleSave}
                    focus={forceFocus === KeyInfo.EDUCATION}
                    setForceFocus={setForceFocus}
                    containerRef={containerRef}
                />
            )}
            {tab === SpecialistTabType.TECHNICAL && <TechnicalSkillsTab specialistId={specialistId} />}
            {tab === SpecialistTabType.STRENGTH && <StrengthTab specialistId={specialistId} />}
        </div>
    )
}

export { SpecialistTabs }
