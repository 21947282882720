/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { FormView } from './components/FormView'

const ContinuousFeedbackSpecialist: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    return <FormView />
}

export { ContinuousFeedbackSpecialist }
