const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const phoneRegex = /^\+\d{0,4}\s[\d()\-\s]+$/
const websiteRegexWithHttps =
    /^(https?:\/\/)(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i
const dateRegex = /^(0[1-9]|1[1-9]|2[1-9]|3[01])\.(0[1-9]|1[012])\.(20)\d\d$/
const rationalNumbersRegex = /^\$?\d*(,\d{3})*\.?[0-9]?[0-9]?$/
const naturalNumbersRegex = /^\d*$/
const uuidRegex = /^[0-9a-fA-F]{8}-([a-fA-F0-9]{4}-){3}[a-fA-F0-9]{12}$/
const websiteRegex = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
const htmlCharactersRegex = /(<([^>]+)>)/gi
const naturalNumbersWithTwoDecimalsRegex = /^(?:\d+|\d+\.\d{0,2}|)$/
export {
    emailRegex,
    phoneRegex,
    websiteRegex,
    dateRegex,
    rationalNumbersRegex,
    naturalNumbersRegex,
    uuidRegex,
    websiteRegexWithHttps,
    htmlCharactersRegex,
    naturalNumbersWithTwoDecimalsRegex,
}
