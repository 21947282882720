/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment, { Moment } from 'moment'
import { FunctionComponent, useCallback } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../filters/FiltersBarHelpers'
import { RangeSelection } from '../filters/RangeSelection'
import { AutocompleteSelect } from '../inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../inputs/autocomplete-select/Option'
import { DatePickerInput } from '../inputs/DatePickerInput'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { RangeFilter } from '../opportunities/FiltersBar'
import { countArrayFilters } from '../../utils/filters'
import {
    ApplicationStatusLabel,
    SearchApplicationsFilters,
    SearchApplicationsFiltersOptions,
} from '../../contracts/opportunities-manager/contracts'

type ApplicationsFiltersBarProps = {
    filtersOptions: SearchApplicationsFiltersOptions
    selectedFilters: SearchApplicationsFilters
    opened: boolean
    onClearAll: () => void
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
    onValueChange: (value: any, filterName: string) => void
    onDatePickerChange: (timestamp: any, filterName: string) => void
    onRangeFilterChange: (values: Array<RangeFilter>) => void
    currency: string
}

const ApplicationsFiltersBar: FunctionComponent<React.PropsWithChildren<ApplicationsFiltersBarProps>> = ({
    filtersOptions,
    selectedFilters,
    opened,
    onClearAll,
    onSelectFilterChange,
    onValueChange,
    onDatePickerChange,
    onRangeFilterChange,
    currency,
}) => {
    const onAvailableFromChange = useCallback(
        (availableFrom: any) => onDatePickerChange(availableFrom, 'availableFrom'),
        [onDatePickerChange],
    )

    const onAvailableToChange = useCallback((availableTo: any) => onDatePickerChange(availableTo, 'availableTo'), [onDatePickerChange])

    const disableAvailableTo = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc().startOf('day')) ||
            (selectedFilters.availableFrom !== undefined && selectedFilters.availableFrom !== null
                ? date.isSameOrBefore(moment.utc(selectedFilters.availableFrom * 1000).startOf('day'))
                : false),

        [selectedFilters],
    )
    const disableAvailableFrom = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc().startOf('day')) ||
            (selectedFilters.availableFrom !== undefined && selectedFilters.availableTo !== null
                ? date.isSameOrAfter(moment.utc(selectedFilters.availableTo * 1000).startOf('day'))
                : false),
        [selectedFilters],
    )

    const onRateRangeFilterChange = useCallback(
        (minRate: number, maxRate: number) => {
            onRangeFilterChange([
                { value: minRate, filterName: 'minRate' },
                { value: maxRate, filterName: 'maxRate' },
            ])
        },
        [onRangeFilterChange],
    )

    const onMsaChange = useCallback(
        (values: any) => {
            const msa: { [k: string]: boolean } = { Yes: true, No: false }
            onValueChange(msa[values[0]], 'msa')
        },
        [onValueChange],
    )

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                    align-items: center;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                        align-items: center;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Role'
                            filterName='roles'
                            options={filtersOptions.roles}
                            currentValues={selectedFilters.roles}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Partner name'
                            filterName='partnerNames'
                            options={filtersOptions.partnerNames}
                            currentValues={selectedFilters.partnerNames}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='MSA'
                            filterName='msa'
                            options={['Yes', 'No']}
                            currentValues={selectedFilters.msa === true ? ['Yes'] : selectedFilters.msa === false ? ['No'] : []}
                            onSelectedValuesChange={onMsaChange}
                            canClear
                            canFilter={false}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Country'
                            filterName='countries'
                            options={filtersOptions.countries}
                            currentValues={selectedFilters.countries}
                            onSelectedValuesChange={onSelectFilterChange}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <RangeSelection
                            placeholder='Rate'
                            label={`Rate (${currency}/h)`}
                            isActive={
                                (selectedFilters.minRate || filtersOptions.minRate || 0) > (filtersOptions.minRate || 0) ||
                                (selectedFilters.maxRate || filtersOptions.maxRate || 0) < (filtersOptions.maxRate || 0)
                            }
                            min={filtersOptions.minRate}
                            max={filtersOptions.maxRate}
                            onRangeChange={onRateRangeFilterChange}
                            selectedMin={selectedFilters.minRate || null}
                            selectedMax={selectedFilters.maxRate || null}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Available From'
                            onChange={onAvailableFromChange}
                            value={selectedFilters.availableFrom}
                            isActive={Boolean(selectedFilters.availableFrom)}
                            disabledDate={disableAvailableFrom}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Available To'
                            onChange={onAvailableToChange}
                            value={selectedFilters.availableTo}
                            isActive={Boolean(selectedFilters.availableTo)}
                            disabledDate={disableAvailableTo}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Status'
                            filterName='statuses'
                            options={filtersOptions.statuses}
                            currentValues={selectedFilters.statuses}
                            labelTransformer={(opt: string) => ApplicationStatusLabel[opt] || opt}
                            selectedLabelTransformer={(opt: string) => ApplicationStatusLabel[opt] || opt}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                        />
                    </FilterColumn>

                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                            margin-bottom: 16px;
                        `}
                    >
                        <FiltersClearTrigger
                            onClear={onClearAll}
                            quantity={countArrayFilters(selectedFilters, Object.keys(selectedFilters))}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { ApplicationsFiltersBar }
