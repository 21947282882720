import { axios } from 'src/api/axios'
import {
    ShareMySpecialistsSearchRequest,
    ShareMySpecialistsSearchResponse,
    ShareMySpecialistsSelectAllRequest,
    ShareMySpecialistsSelectAllResponse,
} from './contracts'

const marketplaceManagerEndpoint = '/marketplaceManager'

const getShareMySpecialistsStatistics = (): Promise<any> => {
    return axios.get(`${marketplaceManagerEndpoint}/statistic`)
}

const marketplaceManagerSpecialistsEndpoint = `/marketplaceManager/specialists`

const getShareMySpecialistsSpecialistsProfiles = (
    searchRequest: ShareMySpecialistsSearchRequest,
): Promise<ShareMySpecialistsSearchResponse> => {
    return axios.post(`${marketplaceManagerSpecialistsEndpoint}:search`, searchRequest)
}

const bulkEditShareMySpecialistsSpecialists = (formData: any): Promise<any> => {
    return axios.put(`/v2${marketplaceManagerSpecialistsEndpoint}:bulk`, formData)
}

const getShareMySpecialistsSpecialistsSelectAll = (
    searchRequest: ShareMySpecialistsSelectAllRequest,
): Promise<ShareMySpecialistsSelectAllResponse> => {
    return axios.post(`${marketplaceManagerSpecialistsEndpoint}:selectAll`, searchRequest)
}

const getShareMySpecialistsFilters = (filters: any): Promise<any> => {
    return axios.post(`${marketplaceManagerSpecialistsEndpoint}:filters`, filters)
}

export {
    getShareMySpecialistsStatistics,
    bulkEditShareMySpecialistsSpecialists,
    getShareMySpecialistsSpecialistsProfiles,
    getShareMySpecialistsSpecialistsSelectAll,
    getShareMySpecialistsFilters,
}
