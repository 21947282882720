import { axios } from '../../api/axios'
import { GameMatrix } from './contracts'

const specialistsEndpoint = `/specialists`

const processDotMemory = (dotmemoryData: Array<GameMatrix>) => {
    return axios.post(`/v1${specialistsEndpoint}:processDotMemory`, { dotmemoryData })
}

export { processDotMemory }
