/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, ReactNode, useCallback, useMemo } from 'react'
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { InputField, InputFieldProps } from './input-field/InputField'

export type HandleSuccessType = (args: Array<File> | File) => void

export type DropParserProps = {
    acceptedFiles: Array<File>
    fileRejections: Array<FileRejection>
    onChange: HandleSuccessType
    onError?: (err: Error) => void
    event?: DropEvent
}

export type DropzoneProps = DropzoneOptions &
    InputFieldProps & {
        isDarkMode?: boolean
        onChange: HandleSuccessType
        parser?: (props: DropParserProps) => void
        dropErrorMessages?: {
            invalidType?: string
            tooManyFiles?: string
            tooLargeFile?: string
        }
        dropzoneContent: ReactNode
        dataTestId?: string
        styles?: SerializedStyles
        dragActiveStyles?: SerializedStyles
    }

const defaultParser = ({ acceptedFiles, onChange }: DropParserProps) => {
    onChange(acceptedFiles)
}

const Dropzone: FunctionComponent<React.PropsWithChildren<DropzoneProps>> = ({
    onChange,
    onError,
    isDarkMode = false,
    accept,
    multiple = false,
    parser = defaultParser,
    dropErrorMessages,
    dropzoneContent,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    label,
    labelTooltip,
    dataTestId,
    maxSize,
    styles,
    dragActiveStyles,
    ...rest
}) => {
    const theme = useTheme()

    const onDrop = useCallback(
        (acceptedFiles: Array<File>, fileRejections: Array<FileRejection>, event: DropEvent) => {
            parser({ acceptedFiles, fileRejections, event, onChange, onError })
        },
        [parser, onChange, onError],
    )

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        fileRejections: rejections,
        acceptedFiles: accepted,
    } = useDropzone({
        onDrop,
        accept,
        multiple,
        maxSize,
        ...rest,
    })

    const dragRejectMessage = useMemo(() => {
        let message = ''
        const filesLength = rejections.length + accepted.length
        if (isDragReject) {
            if (filesLength > 1 && !multiple) {
                message = dropErrorMessages?.tooManyFiles || 'Too many files. Please drop single file.'
            } else {
                message =
                    dropErrorMessages?.invalidType ||
                    `${
                        filesLength > 1 ? 'At least one of provided files' : 'Provided file '
                    } is not valid. Accepted file types: ${Object.keys(accept || {}).join(',')}.`
            }
        }
        return message
    }, [
        rejections.length,
        accepted.length,
        isDragReject,
        multiple,
        dropErrorMessages?.tooManyFiles,
        dropErrorMessages?.invalidType,
        accept,
    ])

    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId}
        >
            <div
                {...getRootProps()}
                css={css`
                    position: relative;
                    border: 1px dashed ${COLOR_PALETTE.gray_2};
                    border-radius: 2px;

                    ${isDarkMode && `background-color: ${COLOR_PALETTE.gray_6}; color:${COLOR_PALETTE.white};`}

                    &:hover {
                        border: 1px dashed ${theme.colors.blue_3};
                    }

                    ${styles}
                `}
            >
                <input {...getInputProps()} data-testid={dataTestId} />
                {dropzoneContent}
                {isDragActive ? (
                    isDragReject ? (
                        <div
                            css={css`
                                position: absolute;
                                top: 0;
                                left: 0;
                                border-radius: 2px;
                                width: 100%;
                                height: 100%;
                                background-color: ${COLOR_PALETTE.red_2};
                                border: 1px dashed ${COLOR_PALETTE.red_4};
                                padding: 15px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                text-align: center;
                                color: ${COLOR_PALETTE.red_4};
                                font-size: 12px;
                            `}
                        >
                            {dragRejectMessage}
                        </div>
                    ) : (
                        <div
                            css={css`
                                position: absolute;
                                top: 0;
                                left: 0;
                                border-radius: 2px;
                                width: 100%;
                                height: 100%;
                                background-color: ${theme.colors.main};
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                ${dragActiveStyles}
                            `}
                        >
                            Drop the files here
                        </div>
                    )
                ) : null}
            </div>
        </InputField>
    )
}

export { Dropzone }
