/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { SpecialistProject } from '../../contracts/specialist/specialistProject'
import { mqMax } from '../../GlobalStyle'
import { ProjectBoxEdit } from './ProjectBoxEdit'
import { ProjectBoxRead } from './ProjectBoxRead'

export type ProjectBoxProps = {
    project: SpecialistProject
    index: number
    onSaveClicked: (index: number, formData: any) => void
    onEditClicked: (index: number) => void
    onDeleteClicked: (index: number) => void
    onCancelClicked: (index: number) => void
    onUndoClicked?: (index: number) => void
    editMode?: boolean
    disabled?: boolean
    forceValidation?: boolean
    canCancel?: boolean
    removable?: boolean
    saving?: boolean
    setIsDirty?: (isDirty: boolean, index: number) => void
}

const ProjectBox: FunctionComponent<React.PropsWithChildren<ProjectBoxProps>> = ({
    project,
    index,
    onSaveClicked,
    onEditClicked,
    onDeleteClicked,
    onCancelClicked,
    onUndoClicked,
    editMode,
    disabled = false,
    forceValidation,
    canCancel = true,
    removable = false,
    saving = false,
    setIsDirty,
}) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                padding: 24px;
                border: 1px solid ${disabled ? theme.colors.violet_1 : theme.colors.gray_2};
                margin-bottom: 16px;
                border-radius: 4px;
                box-shadow: ${theme.shadow.small};
                ${disabled ? `background-color: ${theme.colors.violet_1};` : ''}

                ${mqMax[2]} {
                    max-width: unset;
                }
            `}
        >
            {!disabled && editMode ? (
                <ProjectBoxEdit
                    project={project}
                    index={index}
                    onSaveClicked={onSaveClicked}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                    onCancelClicked={onCancelClicked}
                    forceValidation={forceValidation}
                    canCancel={canCancel}
                    removable={removable}
                    saving={saving}
                    setIsDirty={setIsDirty}
                />
            ) : (
                <ProjectBoxRead
                    project={project}
                    index={index}
                    onEditClicked={onEditClicked}
                    onDeleteClicked={onDeleteClicked}
                    onUndoClicked={onUndoClicked}
                    disabled={disabled}
                />
            )}
        </div>
    )
}

export { ProjectBox }
