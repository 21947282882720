/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useCallback, useState } from 'react'
import { useEffectOnce, useMountedState } from 'react-use'
import { COLOR_PALETTE } from '../../../../theme/colors'
import { Nullable } from '../../../../types'
import { arrayShuffle } from '../../../../utils/arrayShuffle'
import { randomString } from '../../../../utils/strings'
import { GameSign, SIGNS } from '../GameSign'
import { StepWrapper } from '../StepWrapper'

const SignWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
`

type DisruptionProps = {
    time: number
    round: number
    rounds: number
}

const Disruption: FunctionComponent<React.PropsWithChildren<DisruptionProps>> = ({ time, round, rounds }) => {
    const [randomSigns, setRandomSigns] = useState<Array<number>>([])
    const [randomMatches, setRandomMatches] = useState<Array<number>>([])
    const [selectedSign, setSelectedSign] = useState<Nullable<number>>(null)
    const isMounted = useMountedState()

    const setGame = useCallback(() => {
        const randomMatrix = arrayShuffle(SIGNS)
        const randomSignsValue = [...randomMatrix].splice(0, 4)
        const randomMatch = arrayShuffle(randomSignsValue)
        const randomMatchesValue = arrayShuffle([randomMatrix[5], randomMatch[0]])
        setRandomSigns(randomSignsValue)
        setRandomMatches(randomMatchesValue)
    }, [])

    useEffectOnce(() => {
        setGame()
    })

    const selectSign = useCallback(
        (id: number) => {
            setSelectedSign(id)
            setTimeout(() => {
                if (isMounted()) {
                    setSelectedSign(null)
                    setGame()
                }
            }, 500)
        },
        [setGame, isMounted],
    )

    return (
        <StepWrapper
            step={2}
            round={round}
            rounds={rounds}
            time={time}
            description='Tap the card on the bottom that matches one of the cards above.'
        >
            <SignWrapper>
                {randomSigns.map(id => (
                    <GameSign id={id} key={randomString()} />
                ))}
            </SignWrapper>
            <div
                css={css`
                    height: 1px;
                    background-color: ${COLOR_PALETTE.green_2};
                    width: 100%;
                    margin-top: 55px;
                    margin-bottom: 40px;
                `}
            ></div>
            <div
                css={css`
                    width: 100%;
                `}
            >
                <div
                    css={css`
                        text-align: center;
                        margin-bottom: 65px;
                    `}
                >
                    <strong>Click the Match:</strong>
                </div>
                <SignWrapper>
                    {randomMatches.map(id => (
                        <GameSign
                            id={id}
                            key={randomString()}
                            size={70}
                            bordered={true}
                            onClick={selectSign}
                            isSelected={selectedSign === id}
                            isWin={Boolean(selectedSign && randomSigns.includes(id))}
                        />
                    ))}
                </SignWrapper>
            </div>
        </StepWrapper>
    )
}
export { Disruption }
