import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { ReduxContext } from '../redux/Store'
import { auth } from './../api/firebase'
import { useUserAuthentication } from './useUserAuthentication'

const useUserAuthListeners = () => {
    const { logout, login } = useUserAuthentication()
    const {
        actions: { layoutToggleLoader },
        state,
    } = useContext(ReduxContext)

    const isSubscribed = useRef<boolean>(state.authSubscribed)
    const [areListenersInitialized, setAreListenersInitialized] = useState<boolean>(false)

    useEffect(() => {
        isSubscribed.current = state.authSubscribed
    }, [state])

    const setupAuthListeners = useCallback(() => {
        if (!areListenersInitialized) {
            onAuthStateChanged(auth, (user: any) => {
                if (isSubscribed.current) {
                    const userData = user?.providerData[0] // @todo do something if there is more than a single provider
                    if (userData) {
                        if (userData.firebaseId === undefined) {
                            userData.firebaseId = user.uid
                        }
                        if (userData.emailVerified === undefined) {
                            userData.emailVerified = user.emailVerified
                        }
                        auth.currentUser?.getIdToken(true).then(accessToken => {
                            userData.accessToken = accessToken
                            userData.refreshToken = user.refreshToken
                            login(userData, 'manager')
                        })
                    } else {
                        logout()
                    }
                }
            })
            // time just to reduce UI glitches on page load
            setTimeout(() => layoutToggleLoader(false), 500)
            setAreListenersInitialized(true)
        }
    }, [areListenersInitialized, layoutToggleLoader, login, logout])

    useEffect(() => {
        setupAuthListeners()
    }, [setupAuthListeners])
}

export { useUserAuthListeners }
