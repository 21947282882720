/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, MutableRefObject, useCallback, useEffect, useState } from 'react'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { rationalNumbersRegex } from '../../utils/regexes'
import { DropdownComponent, DropdownContainer, DropdownDivider } from '../inputs/autocomplete-select/DropdownComponent'
import { DropdownFooter } from '../inputs/autocomplete-select/DropdownFooter'
import { FilterBox } from '../inputs/autocomplete-select/FilterBox'
import { Slider, sliderStyling } from '../inputs/Slider'

const parseInputValue = (value: string): number | undefined | null =>
    value === '' ? undefined : rationalNumbersRegex.test(value) ? Number(value) : null

const RangeSelectionInput = styled.input`
    border: 1px solid ${COLOR_PALETTE.gray_2};
    box-sizing: border-box;
    border-radius: 2px;
    color: ${COLOR_PALETTE.gray_6};
    font-size: 14px;
    line-height: 130%;
    padding: 13px 13px 12px;
    max-width: 54px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:first-of-type {
        margin-right: 10px;
    }

    &:last-of-type {
        margin-left: 10px;
    }
`

interface RangeSelectionProps {
    min?: number
    max?: number
    step?: number
    placeholder?: string
    label?: string
    onRangeChange: (min: number, max: number) => void
    selectedMin?: number
    selectedMax?: number
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    rangeWidth?: string
    dropdownWidth?: string
    valueTransformer?: (value: number) => number
    isActive?: boolean
    dataTestId?: string
}

const RangeSelectionBase: FunctionComponent<React.PropsWithChildren<RangeSelectionProps>> = ({
    min = 0,
    max = 40,
    step = 1,
    placeholder = 'Select range',
    label = 'Select range',
    onRangeChange,
    selectedMin = 0,
    selectedMax = 40,
    rangeRef,
    isOpen,
    setIsOpen,
    rangeWidth = '100%',
    dropdownWidth = '280px',
    valueTransformer = v => v,
    isActive,
    dataTestId,
}) => {
    const [selectedValues, setSelectedValues] = useState({ selectedMin: selectedMin || min, selectedMax: selectedMax || max })
    const [minValue, setMinValue] = useState(selectedMin || min)
    const [maxValue, setMaxValue] = useState(selectedMax || max)

    useEffect(() => {
        setSelectedValues({ selectedMin: selectedMin || min, selectedMax: selectedMax || max })
    }, [selectedMin, selectedMax, min, max])

    const onFilterBoxClick = () => {
        setIsOpen(!isOpen)

        if (!isOpen) {
            setMinValue(selectedValues.selectedMin)
            setMaxValue(selectedValues.selectedMax)
        }
    }

    const onApply = () => {
        setIsOpen(false)
        setSelectedValues({ selectedMin: minValue || min, selectedMax: maxValue || max })
        onRangeChange(valueTransformer(minValue || min), valueTransformer(maxValue || max))
    }

    const onClearAll = () => {
        setMinValue(min)
        setMaxValue(max)
        setSelectedValues({ selectedMin: min, selectedMax: max })
        onRangeChange(valueTransformer(min), valueTransformer(max))
    }

    const onSliderRangeChange = (value: Array<number> | number) => {
        if (Array.isArray(value)) {
            const [newMin, newMax] = value
            setMinValue(newMin)
            setMaxValue(newMax)
        }
    }

    const onMinInputChange = useCallback((event: any) => {
        const value = parseInputValue(event.target.value)
        if (value !== null) {
            setMinValue(value as number)
        }
    }, [])

    const onMaxInputChange = useCallback((event: any) => {
        const value = parseInputValue(event.target.value)
        if (value !== null) {
            setMaxValue(value as number)
        }
    }, [])

    const onMinInputBlur = useCallback(
        (event: any) => {
            const realMin = minValue < min ? min : minValue
            const realMax = maxValue > max ? max : maxValue
            if (!event.target.value) {
                setMinValue(min)
            } else if (realMin > realMax) {
                setMinValue(realMax < min ? min : realMax)
                setMaxValue(realMin > max ? max : realMin)
            } else if (realMin !== minValue) {
                setMinValue(realMin)
            }
        },
        [minValue, maxValue, min, max],
    )

    const onMaxInputBlur = useCallback(
        (event: any) => {
            const realMin = minValue < min ? min : minValue
            const realMax = maxValue > max ? max : maxValue
            if (!event.target.value) {
                setMaxValue(max)
            } else if (realMin > realMax) {
                setMinValue(realMax < min ? min : realMax)
                setMaxValue(realMin > max ? max : realMin)
            } else if (realMax !== maxValue) {
                setMaxValue(realMax)
            }
        },
        [minValue, maxValue, max, min],
    )

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                display: block;
                flex: 1;
            `}
        >
            <FilterBox
                onClick={onFilterBoxClick}
                placeholder={placeholder}
                displayedValue={selectedMin || selectedMax ? `${selectedMin} - ${selectedMax}` : ''}
                styles={css`
                    width: ${rangeWidth};
                    ${isActive || isOpen ? `border: 1px solid ${COLOR_PALETTE.gray_3}` : ''}
                `}
                dataTestId={`filter-${dataTestId}`}
            />
            {isOpen && (
                <DropdownContainer
                    noLabel
                    styles={css`
                        background-color: ${COLOR_PALETTE.white};
                        width: 100%;
                        ${mqMin[1]} {
                            width: ${dropdownWidth};
                        }
                    `}
                >
                    <div
                        css={css`
                            padding: 17px 18px 11px;
                        `}
                    >
                        <label
                            css={css`
                                width: 100%;
                                display: block;
                                color: ${COLOR_PALETTE.gray_4};
                                font-size: 12px;
                                line-height: 120%;
                                margin: 0 0 6px;
                            `}
                        >
                            {label}
                        </label>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <RangeSelectionInput
                                placeholder={'' + min}
                                value={minValue ? minValue : ''}
                                min={min}
                                max={max}
                                onChange={onMinInputChange}
                                onBlur={onMinInputBlur}
                                data-testid={`min-${dataTestId}`}
                            />
                            <Slider
                                range
                                min={min}
                                max={max}
                                value={[minValue, maxValue ? maxValue : max]}
                                onChange={onSliderRangeChange}
                                {...sliderStyling}
                            />
                            <RangeSelectionInput
                                placeholder={'' + max}
                                value={maxValue ? maxValue : ''}
                                min={min}
                                max={max}
                                onChange={onMaxInputChange}
                                onBlur={onMaxInputBlur}
                                data-testid={`max-${dataTestId}`}
                            />
                        </div>
                    </div>
                    <DropdownDivider />
                    <DropdownFooter onApply={onApply} onClearAll={onClearAll} dataTestId={dataTestId} />
                </DropdownContainer>
            )}
        </div>
    )
}

const RangeSelection = DropdownComponent(RangeSelectionBase)

export { RangeSelection }
