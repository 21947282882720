/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useMemo } from 'react'
import { Alert } from '../../../../components/Alert'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Icon } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { ContentLoader } from '../../../../components/layout/Loader'
import { mapEmploymentType } from '../../../../contracts/specialist/specialist'
import { SpecialistGeneralInformation } from '../../../../contracts/specialist/specialistGeneralInformation'
import { history } from '../../../../history'
import { Nullable } from '../../../../types'

const GreyLabel = styled.label`
    font-size: 12px;
    color: ${props => props.theme.colors.gray_4};
    line-height: 22px;
`

type ContentTextProps = {
    color?: string
}
const ContentText = styled.div<ContentTextProps>`
    font-size: 14px;
    color: ${props => (props.color ? props.color : props.theme.colors.gray_6)};
    margin-bottom: 24px;
`

type GeneralInformationReadOnlyProps = {
    specialistData: SpecialistGeneralInformation
    canUpdateGeneralInformation: boolean
    companyName: string
    specialistId: string
    setSpecialistData: Dispatch<SetStateAction<Nullable<SpecialistGeneralInformation>>>
    ioDataSyncFinished: boolean
    afterInstantOnboarding: boolean
}

const GeneralInformationReadOnly: FunctionComponent<React.PropsWithChildren<GeneralInformationReadOnlyProps>> = ({
    specialistData,
    canUpdateGeneralInformation,
    companyName,
    ioDataSyncFinished,
    afterInstantOnboarding,
}) => {
    const handleRedirection = () => {
        history.push('/instant-onboarding/step/expertise')
    }

    const ioSectionVisible = useMemo(() => !canUpdateGeneralInformation, [canUpdateGeneralInformation])

    const theme = useTheme()

    return (
        <div>
            {!canUpdateGeneralInformation && (
                <section
                    css={css`
                        margin-bottom: 24px;
                    `}
                >
                    <Alert
                        content={`This section is only editable by ${companyName} admin. Please contact them to edit or change any of this info.`}
                        type='info'
                    />
                </section>
            )}

            <GreyLabel>Summary</GreyLabel>
            <ContentText
                css={css`
                    white-space: pre-wrap;
                `}
            >
                {specialistData.profileSummary || '-'}
            </ContentText>
            <GreyLabel>Role</GreyLabel>
            <ContentText>{specialistData.role || '-'}</ContentText>

            <GreyLabel>Seniority</GreyLabel>
            <ContentText>{specialistData.seniority || '-'}</ContentText>

            <GreyLabel>E-mail (visible internally)</GreyLabel>
            <ContentText>{specialistData.email || '-'}</ContentText>

            <GreyLabel>Employment Status (visible internally)</GreyLabel>
            <ContentText>{specialistData.employmentType ? mapEmploymentType(specialistData.employmentType) : '-'}</ContentText>

            <GreyLabel>Top Skills</GreyLabel>
            <ContentText>
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    {specialistData.verifiedTopSkills.length
                        ? specialistData.verifiedTopSkills.map((verifiedTopSkill, index) => (
                              <FlexBox
                                  key={verifiedTopSkill.name}
                                  css={css`
                                      ${verifiedTopSkill.verified ? 'font-weight:500;' : ''}
                                      ${index < specialistData.verifiedTopSkills.length - 1 ? 'margin-right:5px;' : ''}
                                  `}
                                  alignItems='center'
                              >
                                  {verifiedTopSkill.name}
                                  {verifiedTopSkill.verified && (
                                      <Tooltip title='This skill is verified by Talent Alpha knowledge test'>
                                          <FlexBox
                                              alignItems='center'
                                              css={css`
                                                  background-color: ${theme.colors.blue_3};
                                                  border-radius: 50%;
                                                  padding: 3px;
                                                  margin-left: 4px;
                                              `}
                                          >
                                              <Icon name='check' size={8} />
                                          </FlexBox>
                                      </Tooltip>
                                  )}
                                  {index < specialistData.verifiedTopSkills.length - 1 && ', '}
                              </FlexBox>
                          ))
                        : '-'}
                </FlexBox>
            </ContentText>

            <h6>Work Location</h6>

            <GreyLabel>Country</GreyLabel>
            <ContentText>{specialistData.country || '-'}</ContentText>

            <GreyLabel>City</GreyLabel>
            <ContentText>{specialistData.city || '-'}</ContentText>

            <GreyLabel>Remote</GreyLabel>
            <ContentText>{specialistData.remote === null ? '-' : specialistData.remote ? 'Yes' : 'No'}</ContentText>

            <div
                css={css`
                    background-color: ${ioSectionVisible ? theme.colors.green_1 : 'transparent'};
                    padding: ${ioSectionVisible ? '16px' : '0px'};
                `}
            >
                <h6>Professional Development</h6>
                {afterInstantOnboarding && !ioDataSyncFinished ? (
                    <div
                        css={css`
                            height: 190px;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        `}
                    >
                        <div
                            css={css`
                                position: relative;
                            `}
                        >
                            <ContentLoader />
                        </div>

                        <p
                            css={css`
                                color: ${theme.colors.gray_5};
                                margin-bottom: 0;
                                line-height: 22px;
                            `}
                        >
                            Loading your results...
                        </p>
                        <p
                            css={css`
                                color: ${theme.colors.gray_3};
                                line-height: 22px;
                                text-align: center;
                            `}
                        >
                            We are analysing your skills and preparing results of Instant Onboarding. You will see them here very soon.
                        </p>
                    </div>
                ) : (
                    <Fragment>
                        {ioSectionVisible && (
                            <div>
                                <div>
                                    You can change the information visible in this section by going through our Instant Onboarding process.
                                </div>
                                <Divider
                                    style={css`
                                        margin: 6px 0;
                                    `}
                                />
                            </div>
                        )}

                        <GreyLabel>Potential Roles</GreyLabel>
                        <ContentText>{specialistData.potentialRoles?.join(', ') || '-'}</ContentText>

                        <GreyLabel>Industry Experience</GreyLabel>
                        <ContentText>{specialistData.industries?.join(', ') || '-'}</ContentText>

                        <GreyLabel>Professional Responsibilities</GreyLabel>
                        <ContentText>{specialistData.responsibilities?.join(', ') || '-'}</ContentText>
                        {ioSectionVisible && (
                            <Button variant='secondary' onClick={handleRedirection}>
                                Start Instant onboarding
                            </Button>
                        )}
                    </Fragment>
                )}
            </div>
            {!!specialistData.customFields.length && (
                <Fragment>
                    <h6>Additional Information</h6>
                    {specialistData.customFields.map(customField => (
                        <Fragment key={customField.fieldName}>
                            <GreyLabel data-testid='specialist-profile-custom-field-name'>{customField.fieldName}</GreyLabel>
                            <ContentText data-testid='specialist-profile-custom-field-value'>{customField.value || '-'}</ContentText>
                        </Fragment>
                    ))}
                </Fragment>
            )}
        </div>
    )
}

export { GeneralInformationReadOnly }
