/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from 'antd'
import moment, { Moment } from 'moment'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { getCities } from '../../../api/api'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from '../../../components/forms/ControlledDatePickerInput'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { ControlledPhoneInput } from '../../../components/forms/ControlledPhoneInput'
import { ControlledSuggestionInput } from '../../../components/forms/ControlledSuggestionInput'
import { Icon } from '../../../components/Icon'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { TooltipIcon } from '../../../components/TooltipIcon'
import { Gender, genderOptions, mapGenderType, mapGenderValue } from '../../../contracts/gender'
import { mqMax } from '../../../GlobalStyle'
import { useCountries } from '../../../redux/dictionaryDataHooks'
import { hasErrors } from '../../../utils/errors'
import { phoneRegex, websiteRegex } from '../../../utils/regexes'
import { useLogger } from '../../../utils/useLogger'
import { INVALID_DATE_FORMAT, INVALID_URL, PHONE_NOT_VALID } from '../../../validation/validation-messages'
import { yup } from '../../../validation/yup'
import { saveSpecialistProfileInformation, updateSpecialistProfileInformation } from '../api'
import { SpecialistProfileInformationResponse } from '../contracts'
import { TabFormWrapper } from '../shared/TabFormWrapper'

type TalentPersonalDetailsProps = {
    onCancel?: () => void
    onSubmit?: () => void
    specialistData: SpecialistProfileInformationResponse
    specialistId: string
}

const schema = yup.object().shape({
    country: yup.string(),
    city: yup.string(),
    gender: yup.string(),
    birthDate: yup.mixed().test({ message: INVALID_DATE_FORMAT, test: (value: any) => value === '' || yup.number().isValidSync(value) }),
    phone: yup.lazy(value => (value === '' ? yup.string() : yup.string().matches(phoneRegex, PHONE_NOT_VALID))),
    postalCode: yup.string(),
    street: yup.string(),
    socialLinks: yup.array(
        yup.object().shape({
            url: yup.lazy(value =>
                value === '' ? yup.string() : yup.string().matches(websiteRegex, { message: INVALID_URL, excludeEmptyString: true }),
            ),
            label: yup.string(),
        }),
    ),
})

type PersonalDetails = {
    country: string
    city: string
    gender: string
    birthDate: string
    phone: string
    postalCode: string
    street: string
    socialLinks: Array<{ label: string; url: string }>
}

const TalentPersonalDetails: FunctionComponent<React.PropsWithChildren<TalentPersonalDetailsProps>> = ({
    onCancel,
    onSubmit,
    specialistData,
    specialistId,
}) => {
    const log = useLogger('error')
    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])

    const {
        handleSubmit,
        formState: { errors, touchedFields, isDirty },
        reset,
        control,
        getValues,
        watch,
        setValue,
    } = useForm<PersonalDetails>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            country: '',
            city: '',
            gender: '',
            birthDate: '',
            phone: '',
            postalCode: '',
            street: '',
            socialLinks: [],
        },
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socialLinks',
    })

    const { addSuccess, addError } = useNotifications()

    const handleError = useCallback(
        (err: any) => {
            log(err)
            addError()
        },
        [addError, log],
    )

    useEffect(() => {
        setImmediate(() => {
            if (specialistData.birthDate) {
                setValue('birthDate', (specialistData.birthDate as any) || '')
            }

            if (specialistData.postalCode) {
                setValue('postalCode', specialistData.postalCode || '')
            }

            if (specialistData.country) {
                setValue('country', specialistData.country || '')
            }

            if (specialistData.city) {
                setValue('city', specialistData.city || '')
            }

            if (specialistData.gender) {
                setValue('gender', mapGenderValue(specialistData.gender || ''))
            }

            if (specialistData.phone) {
                setValue('phone', specialistData.phone || '')
            }

            if (specialistData.street) {
                setValue('street', specialistData.street || '')
            }
            reset(getValues())

            if (specialistData.socialLinks) {
                const specialKeys = ['Linkedin', 'Github']
                specialKeys.forEach(label => {
                    const linkValue = specialistData.socialLinks.find(link => link.label === label)
                    append({ label, url: linkValue?.url || '' })
                })
                if (Array.isArray(specialistData.socialLinks)) {
                    specialistData.socialLinks
                        .filter(link => !specialKeys.includes((link as any).label))
                        .forEach(link => append({ label: link.label || '', url: link.url || '' }))
                }
            }
        })
    }, [append, setValue, specialistData, getValues, reset])

    const handleFormSubmit = (formData: any) => {
        const gender: Gender = mapGenderType(formData.gender)
        const { city, country, phone, postalCode, street, birthDate } = formData
        const postData = {
            birthDate: parseInt(birthDate, 10),
            city,
            country,
            gender,
            phone,
            postalCode,
            socialLinks: formData.socialLinks
                ? formData.socialLinks.filter((link: { url: string; label: string }) => link.url && link.url.trim() !== '')
                : [],
            street,
        }
        if (specialistData.id) {
            updateSpecialistProfileInformation(specialistId, specialistData.id, postData)
                .then(() => {
                    addSuccess('Details Saved Successfully.')

                    if (onSubmit) {
                        onSubmit()
                    }
                })
                .catch(handleError)
        } else {
            saveSpecialistProfileInformation(specialistId, postData)
                .then(() => {
                    addSuccess('Details Saved Successfully.')

                    if (onSubmit) {
                        onSubmit()
                    }
                })
                .catch(handleError)
        }
    }

    const watchCountry = watch('country')

    const getCitiesForCountry = useCallback((fiter: string) => getCities(watchCountry, fiter), [watchCountry])

    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        } else {
            reset()
        }
    }

    return (
        <TabFormWrapper>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <section>
                    <FieldWrapper>
                        <ControlledPhoneInput
                            control={control}
                            firstOptionEmpty
                            name='phone'
                            label='Phone (optional)'
                            placeholder='Phone number (optional)'
                            countryAreaCodes={countriesDictionary || []}
                            dataTestId='talent-personal-details-phone'
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledDatePickerInput
                            control={control}
                            name='birthDate'
                            label='Birth Date (optional)'
                            placeholder='DD.MM.YYYY (optional)'
                            disabledDate={(date: Moment) => (moment.utc(date).isValid() ? date.isAfter(moment.utc()) : false)}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Gender (optional)'
                            placeholder='Choose gender'
                            options={genderOptions}
                            canFilter={false}
                            control={control}
                            name='gender'
                            dataTestId='talent-personal-details-gender'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>
                    <h6>
                        Home Address (optional)
                        <span
                            css={css`
                                display: inline-block;
                                margin-left: 10px;
                                top: -1px;
                                position: relative;
                            `}
                        >
                            <Tooltip title='Optional fields' placement='top'>
                                <span>
                                    <TooltipIcon />
                                </span>
                            </Tooltip>
                        </span>
                    </h6>
                    <ResponsiveColumns>
                        <FirstColumn>
                            <FieldWrapper>
                                <ControlledAutocompleteSelect
                                    label='Country'
                                    placeholder='Choose country'
                                    options={memorizedCountries}
                                    control={control}
                                    name='country'
                                    canClear
                                    dataTestId='talent-personal-details-country'
                                    dropdownWidth='100%'
                                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                />
                            </FieldWrapper>
                        </FirstColumn>

                        <SecondColumn>
                            <FieldWrapper>
                                <ControlledSuggestionInput
                                    label='City:'
                                    placeholder='Choose city'
                                    fetchOptions={getCitiesForCountry}
                                    disabled={!watchCountry}
                                    control={control}
                                    name='city'
                                    allowOnlyValuesFromDictionary
                                    fetchFromLetterNumber={1}
                                    dataTestId='talent-personal-details-city'
                                />
                            </FieldWrapper>
                        </SecondColumn>
                    </ResponsiveColumns>
                    <ResponsiveColumns>
                        <FirstColumn>
                            <FieldWrapper>
                                <ControlledInput control={control} name='street' placeholder='10 Downing Street' label='Street Address' />
                            </FieldWrapper>
                        </FirstColumn>

                        <SecondColumn>
                            <FieldWrapper>
                                <ControlledInput control={control} name='postalCode' placeholder='Postal' label='Postal' />
                            </FieldWrapper>
                        </SecondColumn>
                    </ResponsiveColumns>
                    <h6>
                        Social Links (optional)
                        <span
                            css={css`
                                display: inline-block;
                                margin-left: 10px;
                                top: -1px;
                                position: relative;
                            `}
                        >
                            <Tooltip title='Optional fields' placement='top'>
                                <span>
                                    <TooltipIcon />
                                </span>
                            </Tooltip>
                        </span>
                    </h6>
                    {fields.map((field, index) => (
                        <FieldWrapper
                            key={field.id}
                            css={css`
                                position: relative;
                            `}
                        >
                            <ControlledInput
                                control={control}
                                name={`socialLinks.${index}.url`}
                                placeholder=''
                                label={field.label}
                                defaultValue={field.url}
                                shouldUnregister={false}
                            />
                            {index > 1 && (
                                <Icon
                                    style={css`
                                        position: absolute;
                                        top: 9px;
                                        right: -30px;

                                        &:hover {
                                            opacity: 0.5;
                                            cursor: pointer;
                                        }
                                    `}
                                    name='small-close'
                                    size={24}
                                    onClick={() => remove(index)}
                                />
                            )}
                        </FieldWrapper>
                    ))}

                    <Button variant='linkForm' onClick={() => append({ label: '', url: '' })} dataTestId='another-link'>
                        + Add One More
                    </Button>
                </section>

                <Divider />

                <section
                    css={css`
                        display: flex;
                        justify-content: flex-start;

                        ${mqMax[1]} {
                            justify-content: center;
                            flex-direction: column-reverse;
                            justify-content: center;
                            align-items: center;
                        }
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 24px;

                            ${mqMax[1]} {
                                margin: 12px 0 0;
                            }
                        `}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>

                    <Button
                        variant='primary'
                        type='submit'
                        disabled={!isDirty || hasErrors(errors)}
                        css={css`
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                        dataTestId='save-personal-details'
                    >
                        Save Details
                    </Button>
                </section>
            </form>
        </TabFormWrapper>
    )
}

export { TalentPersonalDetails }
