/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { Textarea, TextAreaProps } from '../inputs/Textarea'
import { ControlledFieldProps } from './types'

type ControlledTextAreaProps = Omit<TextAreaProps, 'onChange' | 'value' | 'errorMessage'> & ControlledFieldProps<string> & {}

const ControlledTextarea: FunctionComponent<React.PropsWithChildren<ControlledTextAreaProps>> = ({
    name,
    infoMessage,
    label,
    placeholder,
    disabled,
    style,
    defaultValue,
    labelTooltip,
    dataTestId,
    control,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    return (
        <Textarea
            name={name}
            onChange={onChange}
            value={value}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            infoMessage={infoMessage}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            style={style}
            labelTooltip={labelTooltip}
            dataTestId={dataTestId}
        />
    )
}

export { ControlledTextarea }
