import faq from '../../assets/icons/faq.svg'
import { Application } from '../../contracts/applications'

const FAQ_APP: Application = {
    id: 'faq-temporary-id',
    name: 'FAQ',
    order: 9999,
    tooltip: 'FAQ',
    applicationType: 'FAQ',
    availabilityType: 'AVAILABLE',
    applicationUrlResponse: {
        path: '/faq',
        type: 'INTERNAL',
    },
    iconUrl: faq,
    total: 'total',
}

const SECTIONS = ['My Specialists', 'Talent Marketplace', 'Opportunities', 'Manage my company', 'Subscription']

const QUESTION_PARAM_KEY = 'q'

enum MY_SPECIALISTS {
    MY_SPECIALISTS_APP_OVERVIEW = 'MY_SPECIALISTS_APP_OVERVIEW',
    MY_SPECIALISTS_ADDED_SPECIALISTS = 'MY_SPECIALISTS_ADDED_SPECIALISTS',
    MY_SPECIALISTS_MANAGE_SPECIALISTS = 'MY_SPECIALISTS_MANAGE_SPECIALISTS',
    MY_SPECIALISTS_SHARE_SPECIALIST = 'MY_SPECIALISTS_SHARE_SPECIALIST',
    MY_SPECIALISTS_TECHNICAL_SKILLS = 'MY_SPECIALISTS_TECHNICAL_SKILLS',
}
enum TALENT_MARKETPLACE {
    TALENT_MARKETPLACE_APP_OVERVIEW = 'TALENT_MARKETPLACE_APP_OVERVIEW',
    TALENT_MARKETPLACE_SHARE_MY_SPECIALIST = 'TALENT_MARKETPLACE_SHARE_MY_SPECIALIST',
}
enum OPPORTUNITITES {
    OPPORTUNITITES_APP_OVERVIEW = 'OPPORTUNITITES_APP_OVERVIEW',
    OPPORTUNITITES_INFORMATION_TO_APPLY = 'OPPORTUNITITES_INFORMATION_TO_APPLY',
    OPPORTUNITITES_APPLICATION = 'OPPORTUNITITES_APPLICATION',
    OPPORTUNITITES_APPLICATION_FLOW = 'OPPORTUNITITES_APPLICATION_FLOW',
    OPPORTUNITITES_MULTIPLE_OPPORTUNITIES = 'OPPORTUNITITES_MULTIPLE_OPPORTUNITIES',
    OPPORTUNITITES_UPDATES = 'OPPORTUNITITES_UPDATES',
    OPPORTUNITITES_WITHDRAW = 'OPPORTUNITITES_WITHDRAW',
    OPPORTUNITITES_APPLYING = 'OPPORTUNITITES_APPLYING',
    OPPORTUNITITES_DOCUMENTS = 'OPPORTUNITITES_DOCUMENTS',
}
enum MANAGE_MY_COMPANY {
    MANAGE_MY_COMPANY_INVITE = 'MANAGE_MY_COMPANY_INVITE',
}
enum SUBSCRIPTION {
    BENEFITS = 'BENEFITS',
    MANAGER_SUBSCRIPTION = 'MANAGER_SUBSCRIPTION',
    CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
    PAYMENT_INFO_PROCESSING = 'PAYMENT_INFO_PROCESSING',
}
const QUESTIONS = {
    ...MY_SPECIALISTS,
    ...TALENT_MARKETPLACE,
    ...OPPORTUNITITES,
    ...MANAGE_MY_COMPANY,
    ...SUBSCRIPTION,
}

const QUESTIONS_ENUM_TO_PARAM = Object.values(QUESTIONS).reduce<{ [key: string]: string }>((accum, val) => {
    Object.assign(accum, {
        [val]: val
            .split('_')
            .map(word => word.toLocaleLowerCase())
            .join(' '),
    })
    return accum
}, {})

const QUESTIONS_PARAM_TO_ENUM = Object.entries(QUESTIONS_ENUM_TO_PARAM).reduce<{ [key: string]: string }>((accum, [e, p]) => {
    Object.assign(accum, { [p]: e })
    return accum
}, {})

export {
    FAQ_APP,
    SECTIONS,
    QUESTION_PARAM_KEY,
    MY_SPECIALISTS,
    TALENT_MARKETPLACE,
    OPPORTUNITITES,
    MANAGE_MY_COMPANY,
    QUESTIONS,
    QUESTIONS_ENUM_TO_PARAM,
    QUESTIONS_PARAM_TO_ENUM,
    SUBSCRIPTION,
}
