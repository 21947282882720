import { PagingProps } from 'src/contracts/common/pagingProps'
import { Sorting } from 'src/contracts/common/sorting'
import { Nullable } from 'src/types'

export type CV = {
    cvUploadedOn: number
    firstName: string
    hasAnonymizedCv: boolean
    lastName: string
    msaSigned: boolean
    role: string
    shName: string
    specialistId: string
    status: CVStatusEnum
    taOwner: string
    verified: boolean
    premium: boolean
}

export type CvsRequest = {
    paging: PagingProps
    searchText: string
    shNames: Array<string>
    sorting: Nullable<Sorting>
    taOwners: Array<string>
    msaSigned: boolean
    premium: boolean
}

export type CvsFiltersResponse = {
    shNames: Array<string>
    taOwners: Array<string>
    msaSigned: boolean
    premium: boolean
}

export type CvsSearchResponse = {
    cvs: Array<CV>
    total: number
}

export type CVEditRequest = {
    status: CVStatusEnum
    taOwner: string
}

export enum CVStatusEnum {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    AWAITING_INFO = 'AWAITING_INFO',
}

export const CVStatusMapping: Record<CVStatusEnum, string> = {
    [CVStatusEnum.NEW]: 'New',
    [CVStatusEnum.IN_PROGRESS]: 'In Progress',
    [CVStatusEnum.AWAITING_INFO]: 'Awaiting Info',
}

export type CVEditFormData = {
    cvs: Array<CVEditRequest>
}

export enum CVType {
    Anonymized = 'anonymized',
    Original = 'original',
}
