/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import clouds from './../assets/images/clouds.png'

type CloudsProps = {
    color?: string
}

const Clouds: FunctionComponent<React.PropsWithChildren<CloudsProps>> = ({ children, color }) => {
    const theme = useTheme()
    color = color || theme.colors.main

    return (
        <div
            css={css`
                display: flex;
                background-color: ${color};
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;

                height: 100%;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    background: url(${clouds});
                    background-size: cover;
                    background-position: bottom;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    height: 100%;
                `}
            ></div>

            <div
                css={css`
                    width: 100%;
                    background-color: ${color};
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    opacity: 0.1;
                    height: 100%;
                `}
            ></div>

            <div
                css={css`
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    z-index: 3;
                `}
            >
                {children}
            </div>
        </div>
    )
}

export { Clouds }
