/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { mqMax, mqMin } from '../../GlobalStyle'
import { Button } from '../../components/Button'
import { DataNotFound } from '../../components/DataNotFound'
import { Divider } from '../../components/Divider'
import { Icon } from '../../components/Icon'
import { Pagination } from '../../components/Pagination'
import { SearchInput } from '../../components/SearchInput'
import { ResponsiveGrid } from '../../components/data-grid/ResponsiveGrid'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { OverlayContentLoader } from '../../components/layout/Loader'
import { ColorBackgroundWrapper, FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { FiltersBar, FiltersFunctions } from '../../components/opportunities/FiltersBar'
import { OpportunityLocation } from '../../components/opportunities/OpportunityLocation'
import { getAppPath } from '../../contracts/applications'
import { SortEntry, SortEntryWithField } from '../../contracts/common/sorting'
import { OpportunityStatus } from '../../contracts/opportunities'

import { COLOR_PALETTE } from '../../theme/colors'
import { Nullable } from '../../types'
import { convertTimestampToDateString } from '../../utils/dates'
import { countArrayFilters } from '../../utils/filters'
import { alphabeticallyAsc, numberAsc } from '../../utils/sorting'
import { getOpportunitiesFilters, searchOpportunities } from '../../api/opportunities-manager/api'
import { OpportunitiesFiltersBar } from '../../components/opportunities-manager/OpportunitiesFiltersBar'
import { OpportunityActions } from '../../components/opportunities-manager/OpportunityActions'
import { OpportunityStatusCell } from '../../components/opportunities-manager/OpportunityStatusCell'
import {
    OpportunityLocationResponse,
    SearchOpportunitiesFilters,
    SearchOpportunitiesFiltersOptions,
    SearchOpportunitiesRequestParams,
    SearchOpportunityItem,
} from '../../contracts/opportunities-manager/contracts'
import { SMEmenuItems } from '../../components/opportunities-manager/utils/menuItems'
import {
    parseQueryToOpportunitiesManagerSearchRequest,
    stringifyOpportunitiesManagerSearchRequestToQuery,
} from '../../components/opportunities-manager/utils/opportunitiesSearchUtils'

const DEFAULT_ITEMS_PER_PAGE = 10

const defaultFilterOptions: SearchOpportunitiesFiltersOptions = {
    countries: [],
    locationTypes: [],
    regions: [],
    statuses: [],
    ownersNames: [],
    industries: [],
}

const OpportunitiesListSme: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { addError } = useNotifications()

    const [opportunities, setOpportunities] = useState<Nullable<Array<SearchOpportunityItem>>>(null)
    const [isFetchingOpportunities, setIsFetchingOpportunities] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(false)

    const navigate = useNavigate()
    const { search, pathname, hash } = useLocation()

    const isSmeCloudApp = true

    const appPath = getAppPath('OPPORTUNITIES_MANAGER_SME')

    useEffect(() => {
        if (search) {
            setShowFilters(true)
        }
    }, [search])

    const searchRequest = useMemo<SearchOpportunitiesRequestParams>(() => parseQueryToOpportunitiesManagerSearchRequest(search), [search])

    const [sorting, setSorting] = useState<Array<SortEntry>>(searchRequest.sorting || [])
    const [currentPage, setCurrentPage] = useState<number>(searchRequest.paging?.page || 1)
    const [pageSize, setPageSize] = useState<number>(searchRequest.paging?.size || DEFAULT_ITEMS_PER_PAGE)

    const defaultSearchFilters = useMemo((): SearchOpportunitiesFilters => {
        const { searchText: s, paging: p, sorting: sort, statuses, ...onlyFilters } = searchRequest
        const defaultStatuses = statuses.length > 0 ? statuses : []

        return {
            ...onlyFilters,
            statuses: defaultStatuses,
        }
    }, [searchRequest])

    const [selectedFilters, setSelectedFilters] = useState<SearchOpportunitiesFilters>(defaultSearchFilters)
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText || '')

    useEffect(() => {
        const completeRequest = { searchText, paging: { page: currentPage, size: pageSize }, sorting, ...selectedFilters }
        const newSearchQuery = stringifyOpportunitiesManagerSearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [currentPage, navigate, searchText, selectedFilters, pageSize, sorting])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const [filtersOptions, setFiltersOptions] = useState<SearchOpportunitiesFiltersOptions>(defaultFilterOptions)

    const loadFilters = useCallback(() => {
        getOpportunitiesFilters().then(setFiltersOptions)
    }, [])

    useEffect(() => {
        loadFilters()
    }, [loadFilters])

    const onChangeFilters = useCallback((filters: any) => {
        setSelectedFilters(filters)
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        setPageSize(Number(size.toString()))
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters({
            projectStartDate: null,
            projectEndDate: null,
            lastUpdate: null,
            addedOn: null,
            ownerName: null,
            countries: [],
            regions: [],
            statuses: [],
            locationTypes: [],
            industries: [],
        })
    }, [])
    const filtersApplied = useMemo(() => {
        let counter = 0

        counter += countArrayFilters(selectedFilters, ['countries', 'regions', 'statuses', 'locationTypes'])
        ;['projectStartDate', 'projectEndDate', 'lastUpdate', 'addedOn'].forEach(filterName => {
            if (selectedFilters[filterName as keyof SearchOpportunitiesFilters]) {
                counter++
            }
        })

        return counter > 0
    }, [selectedFilters])

    const [totalOpportunities, setTotalOpportunities] = useState<number>(0)
    const onChangePage = useCallback(setCurrentPage, [setCurrentPage])

    useEffect(() => {
        if (totalOpportunities > 0 && opportunities?.length === 0 && currentPage > 1) {
            setCurrentPage(1)
        }
    }, [currentPage, opportunities?.length, totalOpportunities])

    const loadOpportunities = useCallback(() => {
        setIsFetchingOpportunities(true)

        searchOpportunities({
            ...selectedFilters,
            sorting,
            searchText,
            internalManager: !isSmeCloudApp,
            paging: {
                page: currentPage,
                size: pageSize,
            },
        }).then(
            data => {
                setIsFetchingOpportunities(false)
                setOpportunities(data.opportunities)
                setTotalOpportunities(data.total)
            },
            () => {
                setIsFetchingOpportunities(false)
                addError()
            },
        )
    }, [addError, currentPage, isSmeCloudApp, pageSize, searchText, selectedFilters, sorting])

    const onStatusChange = useCallback(
        (opportunityId: any, status: any) => {
            setOpportunities(prev => prev?.map(item => (item.opportunityId === opportunityId ? { ...item, status } : item)) || null)
            loadFilters()
        },
        [loadFilters],
    )

    const theme = useTheme()

    const columns: ColumnsType<SearchOpportunityItem> = useMemo(
        () => [
            {
                title: 'Opportunity title',
                dataIndex: 'opportunityTitle',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.opportunityTitle, b.opportunityTitle),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'opportunityTitle' ? sorting[0].order : undefined,
                showSorterTooltip: false,
                render: (opportunityTitle: string, data) => {
                    const TitleBadge = () => (
                        <FlexBox alignItems='center'>
                            {opportunityTitle}
                            {data.hasNewApplications && (
                                <Tooltip placement='right' title='This opportunity has new applicants'>
                                    <Icon
                                        name='exclamation-mark'
                                        size={16}
                                        style={css`
                                            margin-left: 8px;
                                        `}
                                    />
                                </Tooltip>
                            )}
                        </FlexBox>
                    )
                    const IdBadge = () => (
                        <div
                            css={css`
                                ${mqMin[1]} {
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: none;
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                    margin-top: 10px;
                                    margin-bottom: -2px;

                                    ${mqMax[1]} {
                                        display: block;
                                    }
                                `}
                            >
                                Opportunity ID
                            </div>
                            <span
                                css={css`
                                    ${mqMax[1]} {
                                        display: none;
                                    }
                                `}
                            >
                                ID:
                            </span>{' '}
                            {data.generatedId || '-'}
                        </div>
                    )

                    return (
                        <Fragment>
                            <TitleBadge />
                            <IdBadge />
                        </Fragment>
                    )
                },
            },
            {
                title: 'Internal name',
                dataIndex: 'internalName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.internalName || '', b.internalName || ''),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'internalName' ? sorting[0].order : undefined,
                showSorterTooltip: false,
                render: (internalName: string, data) => {
                    const NameBadge = () => <div data-testid='internal-name'>{internalName}</div>
                    const CompanyBadge = () => (
                        <div
                            css={css`
                                ${mqMin[1]} {
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: none;
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                    margin-top: 10px;
                                    margin-bottom: -2px;

                                    ${mqMax[1]} {
                                        display: block;
                                    }
                                `}
                            >
                                Client name
                            </div>
                            {data.clientName || '-'}
                        </div>
                    )

                    return (
                        <Fragment>
                            <NameBadge />
                            <CompanyBadge />
                        </Fragment>
                    )
                },
            },

            {
                title: 'Opportunity owner',
                dataIndex: 'opportunityOwnerName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.opportunityOwnerName || '', b.opportunityOwnerName || ''),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'opportunityOwnerName' ? sorting[0].order : undefined,
                showSorterTooltip: false,
                render: (opportunityOwnerName: string) => {
                    return <Fragment>{opportunityOwnerName}</Fragment>
                },
            },

            {
                title: 'Vacancies',
                dataIndex: 'numberOfVacancies',
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'numberOfVacancies' ? sorting[0].order : undefined,
                sorter: true,
                showSorterTooltip: false,
                render: (numberOfVacancies: number) => {
                    return <Fragment>{numberOfVacancies || '-'}</Fragment>
                },
            },

            {
                title: <span data-testid='opportunity-candidates'>Candidates</span>,
                dataIndex: 'numberOfCandidates',
                sorter: {
                    compare: (a, b) => numberAsc(a.numberOfCandidates || 0, b.numberOfCandidates || 0),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'numberOfCandidates' ? sorting[0].order : undefined,
                showSorterTooltip: false,
                render: (numberOfCandidates: number) => {
                    return <Fragment>{numberOfCandidates || '-'}</Fragment>
                },
            },

            {
                title: 'Start Date',
                dataIndex: 'projectStartDate',
                sorter: {
                    compare: (a, b) => numberAsc(a.projectStartDate || 0, b.projectStartDate || 0),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'projectStartDate' ? sorting[0].order : undefined,
                render: (projectStartDate: number) => (projectStartDate ? convertTimestampToDateString(projectStartDate) : '-'),
                showSorterTooltip: false,
            },
            {
                title: 'End Date',
                dataIndex: 'projectEndDate',
                sorter: {
                    compare: (a, b) => numberAsc(a.projectEndDate || 0, b.projectEndDate || 0),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'projectEndDate' ? sorting[0].order : undefined,
                render: (projectEndDate: number) => (projectEndDate ? convertTimestampToDateString(projectEndDate) : '-'),
                showSorterTooltip: false,
            },
            {
                title: 'Location',
                dataIndex: 'location',
                showSorterTooltip: false,
                render: (location: OpportunityLocationResponse) => {
                    return (
                        <OpportunityLocation
                            countries={location.countriesWithCities.map(country => country.country)}
                            type={location.locationTypeV2}
                            countriesCitiesList={location.countriesWithCities}
                            regions={location.regions}
                        />
                    )
                },
            },
            {
                title: <span data-testid='added-on'>Added On</span>,
                dataIndex: 'addedOn',
                sorter: {
                    compare: (a, b) => numberAsc(a.addedOn || 0, b.addedOn || 0),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'addedOn' ? sorting[0].order : undefined,
                render: (addedOn: number) => convertTimestampToDateString(addedOn),
                showSorterTooltip: false,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.status || '', b.status || ''),
                },
                defaultSortOrder: (sorting[0] as SortEntryWithField)?.field === 'status' ? sorting[0].order : undefined,
                showSorterTooltip: false,
                render: (status: OpportunityStatus) => <OpportunityStatusCell status={status} />,
            },
            {
                title: '',
                dataIndex: 'actions',
                render: (_: any, opportunity: SearchOpportunityItem) => {
                    return <OpportunityActions opportunity={opportunity} onStatusChange={onStatusChange} isSmeCloud={isSmeCloudApp} />
                },
                width: 80,
            },
        ],
        [onStatusChange, sorting, theme.colors.gray_4, isSmeCloudApp],
    )

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            setCurrentPage(1)
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            setCurrentPage(1)
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    useEffect(() => {
        loadOpportunities()
    }, [loadOpportunities])

    const rowProps = useCallback(
        (record: any) => ({
            'data-testid': 'opportunity-table-row',
            onClick: () =>
                navigate(
                    `${appPath}/opportunities/${record.opportunityId}/applications?prevPath=${encodeURIComponent(
                        `${pathname}${encodeURIComponent(search)}${hash}`,
                    )}`,
                ),
        }),
        [navigate, appPath, pathname, search, hash],
    )

    return (
        <DashboardLayout applicationName='Opportunities Manager SME' applicationMenuItems={SMEmenuItems}>
            {!isFetchingOpportunities && !filtersApplied && !searchText && opportunities?.length === 0 ? null : (
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${COLOR_PALETTE.gray_1};
                        padding: 18px 16px 0;
                        ${mqMin[1]} {
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        }
                    `}
                >
                    <div
                        css={css`
                            max-width: 1440px;
                            margin: 0 auto;
                            ${mqMax[1]} {
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                            }
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                ${mqMax[1]} {
                                    flex: none;
                                }
                                margin-bottom: 16px;
                            `}
                        >
                            <h4
                                css={css`
                                    margin: 0;
                                `}
                            >
                                My Opportunities
                            </h4>
                            <Link
                                to={`${appPath}/add-opportunity?prevPath=${encodeURIComponent(
                                    window.location.pathname + window.location.search + window.location.hash,
                                )}`}
                                css={css`
                                    &:hover {
                                        text-decoration: none;
                                    }
                                `}
                            >
                                <Button variant='primary' dataTestId='opportunity-manager-add'>
                                    Add New Opportunity
                                </Button>
                            </Link>
                        </div>
                        <SearchInput
                            initialText={searchText}
                            onSubmit={setSearchText}
                            filtersOpened={showFilters}
                            onToggleFilters={onToggleFilters}
                            placeholder='Opportunity Title, Internal Name, Client Name, etc.'
                            styles={css`
                                margin-bottom: 16px;
                            `}
                            dataTestId='opportunities-manager-search'
                        />
                        {showFilters && filtersOptions && (
                            <FiltersBar<SearchOpportunitiesFilters> selectedFilters={selectedFilters} onChangeFilters={onChangeFilters}>
                                {({ onSelectFilterChange, onLocationChange, onValueChange, onDatePickerChange }: FiltersFunctions) => (
                                    <OpportunitiesFiltersBar
                                        opened={showFilters}
                                        filtersOptions={filtersOptions}
                                        selectedFilters={selectedFilters}
                                        onClearAll={onClearFilters}
                                        onSelectFilterChange={onSelectFilterChange}
                                        onLocationChange={onLocationChange}
                                        onValueChange={onValueChange}
                                        onDatePickerChange={onDatePickerChange}
                                    />
                                )}
                            </FiltersBar>
                        )}
                        <Divider />
                        <span data-testid='opportunities-manager-total-found'>
                            {totalOpportunities} Result{totalOpportunities !== 1 && 's'} Found
                        </span>
                    </div>
                </ColorBackgroundWrapper>
            )}
            {opportunities && totalOpportunities > 0 && (
                <Fragment>
                    <ResponsiveGrid
                        rowKey='opportunityId'
                        dataSource={opportunities}
                        columns={columns}
                        pagination={false}
                        dataTestId='opportunities-table'
                        onRow={rowProps}
                        onChange={onChangeTable}
                        mobileBreakpoint={2}
                        wrapperStyle={css`
                            .ant-table-row:hover {
                                img {
                                    display: inline;
                                }
                            }
                        `}
                    />
                    <FullPageWrapper>
                        <div
                            css={css`
                                margin: 40px 0 120px 0;
                            `}
                        >
                            <Pagination
                                pageSize={pageSize}
                                total={totalOpportunities}
                                defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                                onChangePageSize={onChangePageSize}
                                onChange={onChangePage}
                                current={currentPage}
                                css={css`
                                    ${mqMax[0]} {
                                        display: flex;
                                        justify-content: center;
                                    }
                                `}
                            />
                        </div>
                    </FullPageWrapper>
                </Fragment>
            )}
            {totalOpportunities === 0 && !isFetchingOpportunities && (
                <DataNotFound
                    styles={css`
                        margin-top: ${searchText || filtersApplied ? '50px' : '150px'};
                    `}
                    iconName='add-empty'
                    iconSize={85}
                    title={searchText || filtersApplied ? 'No Opportunities found' : 'No Opportunities Added'}
                    description={
                        searchText || filtersApplied ? (
                            'Try changing your filters or query'
                        ) : (
                            <span>
                                You haven’t added any Opportunities yet. <br />
                                Once added, your opportunities will appear under{' '}
                                <span
                                    css={css`
                                        color: ${COLOR_PALETTE.gray_6};
                                    `}
                                >
                                    Open Opportunities
                                </span>{' '}
                                in your dashboard.
                            </span>
                        )
                    }
                >
                    {!searchText && !filtersApplied && (
                        <Link
                            to={`${appPath}/add-opportunity`}
                            css={css`
                                &:hover {
                                    text-decoration: none;
                                }
                            `}
                        >
                            <Button dataTestId='opportunity-manager-add'>Add New Opportunity</Button>
                        </Link>
                    )}
                </DataNotFound>
            )}
            {isFetchingOpportunities && <OverlayContentLoader />}
        </DashboardLayout>
    )
}

export { OpportunitiesListSme }
