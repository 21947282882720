/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { Button } from '../../../components/Button'
import { ErrorAlert } from '../../../components/ErrorAlert'
import { CvDropzone } from '../../../components/inputs/CvDropzone'
import { DropParserProps } from '../../../components/inputs/Dropzone'
import { Modal } from '../../../components/Modal'
import { useLogger } from '../../../utils/useLogger'
import { uploadCvs } from '../api'

const MAX_FILE_SIZE = 20971520
const MAX_BULK_FILES_SIZE = 1.5 * MAX_FILE_SIZE
const MEGABYTE_IN_BYTES = 1048576

type UploadCvsProps = {
    loadProcessingCvs: () => void
}

const UploadCvs: FunctionComponent<React.PropsWithChildren<UploadCvsProps>> = ({ loadProcessingCvs }) => {
    const log = useLogger('error')
    const theme = useTheme()

    const [errorMessage, setErrorMessage] = useState('')
    const [rejectedFilesNames, setRejectedFilesNames] = useState<Array<string>>([])
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

    const parser = useCallback(({ acceptedFiles, fileRejections, onChange }: DropParserProps) => {
        let canUpload = true
        setErrorMessage('')
        if (fileRejections.length) {
            setErrorMessage(`We were unable to upload ${fileRejections.length} file${fileRejections.length > 1 ? 's' : ''}.`)
            setRejectedFilesNames(fileRejections.map(file => file.file.name))
        }
        if (acceptedFiles.reduce<number>((bulkFilesSize, file) => bulkFilesSize + file.size, 0) > MAX_BULK_FILES_SIZE) {
            setErrorMessage(`Bulk upload is too large. You can send max ${MAX_BULK_FILES_SIZE / MEGABYTE_IN_BYTES} MB at once`)
            canUpload = false
        }
        if (canUpload && acceptedFiles.length > 0) {
            onChange(acceptedFiles)
        }
    }, [])

    const handleDrop = useCallback(
        (files: File | Array<File>) => {
            setIsUploading(true)
            const cvsData = new FormData()
            if (Array.isArray(files)) {
                for (const file of files) {
                    cvsData.append('files', file)
                }
            } else {
                cvsData.append('files', files)
            }
            uploadCvs(cvsData)
                .catch(log)
                .finally(() => {
                    loadProcessingCvs()
                    setIsUploading(false)
                })
        },
        [loadProcessingCvs, log],
    )

    const cleanErrorMessage = useCallback(() => {
        setErrorMessage('')
    }, [])

    const toggleModal = useCallback(() => {
        setIsModalVisible(currentIsVisible => !currentIsVisible)
    }, [])

    return (
        <Fragment>
            <h4>1. Upload CVs to quickly create profiles</h4>
            {errorMessage && (
                <Fragment>
                    <div
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        <ErrorAlert onCloseClick={cleanErrorMessage}>
                            <span>{errorMessage}</span>
                            <Button
                                variant='secondary'
                                size='small'
                                style={css`
                                    margin-left: 16px;
                                `}
                                onClick={toggleModal}
                            >
                                Details
                            </Button>
                        </ErrorAlert>
                    </div>
                    <Modal onClose={toggleModal} opened={isModalVisible}>
                        <h5>File upload failed</h5>
                        <ErrorAlert>Upload of the following files was unsuccessful.</ErrorAlert>
                        <ul
                            css={css`
                                margin-top: 24px;
                                list-style-position: inside;
                                padding: 0;
                                color: ${theme.colors.red_4};
                            `}
                        >
                            {rejectedFilesNames.map(name => (
                                <li key={name}>{name}</li>
                            ))}
                        </ul>
                        <p
                            css={css`
                                color: ${theme.colors.red_4};
                            `}
                        >
                            Please check these files and upload again. Each file cannot be larger than 20 MB and it should be a .pdf or
                            .docx.
                        </p>
                    </Modal>
                </Fragment>
            )}
            <p>
                We will extract skills and add them to this profile. Projects and educational records will not be extracted yet. We are
                working on improving our parsing model to be able to do so.
            </p>
            <div
                css={css`
                    position: relative;
                `}
            >
                <CvDropzone handleDrop={handleDrop} parser={parser} isUploading={isUploading} multiple />
            </div>
            <span
                css={css`
                    display: block;
                    margin-top: 24px;
                    color: ${theme.colors.gray_3};
                    font-size: 12px;
                `}
            >
                Each file cannot be larger than 20 MB and you it should be a .pdf or .docx.
            </span>
        </Fragment>
    )
}

export { UploadCvs }
