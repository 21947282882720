/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'

type RoundIndicatorProps = {
    round: number
    rounds: number
}

const RoundIndicator: FunctionComponent<React.PropsWithChildren<RoundIndicatorProps>> = ({ rounds, round }) => (
    <div
        css={css`
            background-color: ${COLOR_PALETTE.gray_6};
            color: ${COLOR_PALETTE.white};
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 52px;
            height: 52px;
            font-size: 14px;
        `}
    >
        {round}/<span data-testid='dot-memory-rounds'>{rounds}</span>
    </div>
)

export { RoundIndicator }
