/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent, ImgHTMLAttributes, useContext } from 'react'
import logoFullInverted from '../assets/logos/logo-full-inverted.svg'
import logoFullMono from '../assets/logos/logo-full-mono.svg'
import logoFull from '../assets/logos/logo-full.svg'
import logoInverted from '../assets/logos/logo-inverted.svg'
import logoMono from '../assets/logos/logo-mono.svg'
import logo from '../assets/logos/logo.svg'
import { mqMax } from '../GlobalStyle'
import { ReduxContext } from '../redux/Store'

export type LogoType = 'logo' | 'logoMono' | 'logoInverted' | 'logoFull' | 'logoFullMono' | 'logoFullInverted'

const getSvg = (name: LogoType): string => {
    switch (name) {
        case 'logo':
            return logo
        case 'logoMono':
            return logoMono
        case 'logoInverted':
            return logoInverted
        case 'logoFull':
            return logoFull
        case 'logoFullMono':
            return logoFullMono
        case 'logoFullInverted':
            return logoFullInverted
    }
}
type LogoProps = ImgHTMLAttributes<HTMLImageElement> & {
    type?: LogoType
    forceTalentAlpha?: boolean
    style?: SerializedStyles
    forcedLogoUrl?: string
}
const Logo: FunctionComponent<React.PropsWithChildren<LogoProps>> = ({
    type = 'logo',
    forceTalentAlpha = false,
    style,
    forcedLogoUrl,
    ...rest
}) => {
    const {
        selectors: { whiteLabel },
    } = useContext(ReduxContext)
    const customLogo = forcedLogoUrl || (!forceTalentAlpha && whiteLabel?.active && whiteLabel.logoUrl)
    return (
        <img
            alt={customLogo ? 'Logo' : 'Talent Alpha Logo'}
            css={css`
                ${!customLogo && 'width: 112px;'}
                height: 40px;

                ${mqMax[0]} {
                    ${!customLogo && 'width: 106px;'}
                    height: 38px;
                }

                ${style}
            `}
            src={customLogo || getSvg(type)}
            {...rest}
        />
    )
}

export { Logo }
