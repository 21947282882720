/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'

const FieldValue = styled.span`
    margin-bottom: 24px;
    display: block;
    font-size: 14px;
    line-height: 26px;
    white-space: pre-wrap;

    p {
        margin-bottom: 0;
    }
`

export { FieldValue }
