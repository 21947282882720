/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import Hello from '../../assets/images/hello.svg'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { mqMax } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'
import { getIsOnboardingStarted } from './api'
import { useInstantOnboardingGuard } from './useInstantOnboardingGuard'

const InstantOnboardingWelcomeScreen = () => {
    const { specialistName } = useInstantOnboardingGuard()
    const theme = useTheme()

    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const log = useLogger('error')

    useEffectOnce(() => {
        getIsOnboardingStarted(decodedAccessToken.specialistId)
            .then(({ started }) => {
                if (started) {
                    history.push('/specialist-dashboard')
                }
            })
            .catch(log)
    })

    return (
        <DashboardLayout showMenuItems={false} applicationName='Instant Onboarding'>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    margin: 0 auto 50px;
                    padding: 24px;
                    align-items: center;
                    text-align: center;
                    ${mqMax[1]} {
                        margin-top: 57px;
                    }
                `}
            >
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                        max-width: 430px;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        color: ${theme.colors.gray_6};
                    `}
                >
                    <img
                        src={Hello}
                        alt='Instant Onboarding - Welcome to Talent Alpha Human Cloud!'
                        css={css`
                            margin-bottom: 48px;
                            padding-top: 25px;
                        `}
                    />
                    <h2
                        css={css`
                            margin-bottom: 8px;
                        `}
                    >
                        Welcome {specialistName}!
                    </h2>
                    <div
                        css={css`
                            font-size: 18px;
                            margin-bottom: 40px;
                        `}
                    >
                        It’s great to see you on our Human Cloud!
                    </div>
                    <div
                        css={css`
                            font-size: 14px;
                            line-height: 22px;
                        `}
                    >
                        We are excited for you to begin your journey with Talent Alpha by going through our Instant Onboarding, a Machine
                        Learning-enhanced skill mapping tool that instantly enriches your profile with up to date info about your technical
                        skills and experience.
                    </div>
                    <Divider
                        style={css`
                            width: 50px;
                            margin: 40px 0;
                        `}
                    />
                    <Link
                        to={`/instant-onboarding/step/expertise`}
                        css={css`
                            text-decoration: none;
                            width: 100%;
                        `}
                    >
                        <Button
                            variant='primary'
                            style={css`
                                width: 100%;
                                font-size: 18px;
                                padding-top: 16px;
                                padding-bottom: 16px;
                            `}
                        >
                            Start Onboarding Now
                        </Button>
                    </Link>
                    <div
                        css={css`
                            max-width: 272px;
                        `}
                    >
                        <p
                            css={css`
                                margin-bottom: 0;
                                margin-top: 40px;
                                color: ${theme.colors.gray_4};
                            `}
                        >
                            You can skip Instant Onboarding for now and go straight to adding info to your Profile:
                        </p>
                        <Link
                            to={'/specialist-dashboard'}
                            css={css`
                                text-decoration: none;
                            `}
                        >
                            <Button
                                style={css`
                                    margin: 0;
                                    padding: 0;
                                `}
                                variant='linkForm'
                            >
                                Take Me to My Profile
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export { InstantOnboardingWelcomeScreen }
