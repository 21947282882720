import merge from 'lodash.merge'
import { createContext, FunctionComponent, useMemo } from 'react'
import { ContextGroupName } from './contracts/contexts'
import { useUserAuthentication } from './hooks/useUserAuthentication'
import { useUserContext } from './hooks/useUserContext'
import { getUserRole } from './utils/currentUser'
import { deepClone } from './utils/deepClone'

type AccessLevel = {
    dashboard: { myAccount: boolean; companyAccount: boolean }
    talentMarketplace: {
        findTalent: boolean
        savedProfiles: boolean
        bookings: boolean
    }
    mySpecialists: {
        generalInformation: {
            read: boolean
            update: boolean
        }
        profileInformation: {
            read: boolean
            update: boolean
        }
        projects: {
            read: boolean
            update: boolean
        }
        education: {
            read: boolean
            update: boolean
        }
        technicalSkills: {
            read: boolean
            update: boolean
            tabTooltip: boolean
        }
        personalStrengths: {
            read: boolean
        }
        invitations: {
            send: boolean
            resend: boolean
            cancel: boolean
        }
        submittingProfile: boolean
        sharingProfile: boolean
        backToList: boolean
        uploadCV: boolean
        deleteProfile: boolean
    }
    skillsDiscovery: boolean
    strengthDiscovery: boolean
    header: {
        appsMenu: boolean
        menuItems: boolean
        appLabel: boolean
    }
}

const noAccess = {
    dashboard: { myAccount: false, companyAccount: false },
    talentMarketplace: {
        findTalent: false,
        savedProfiles: false,
        bookings: false,
    },
    mySpecialists: {
        generalInformation: {
            read: false,
            update: false,
        },
        profileInformation: {
            read: false,
            update: false,
        },
        projects: {
            read: false,
            update: false,
        },
        education: {
            read: false,
            update: false,
        },
        technicalSkills: {
            read: false,
            update: false,
            tabTooltip: false,
        },
        personalStrengths: {
            read: false,
        },
        invitations: {
            send: false,
            resend: false,
            cancel: false,
        },
        submittingProfile: false,
        sharingProfile: false,
        backToList: false,
        uploadCV: false,
        deleteProfile: false,
    },
    skillsDiscovery: false,
    strengthDiscovery: false,
    header: {
        appsMenu: false,
        menuItems: false,
        appLabel: false,
    },
}

const accessRoles = {
    specialist: {
        mySpecialists: {
            generalInformation: {
                read: true,
            },
            profileInformation: {
                read: true,
            },
            projects: {
                read: true,
                update: true,
            },
            education: {
                read: true,
                update: true,
            },
            technicalSkills: {
                tabTooltip: true,
            },
            personalStrengths: {
                read: true,
            },
            submittingProfile: true,
        },
        skillsDiscovery: true,
        strengthDiscovery: true,
        header: {
            appLabel: true,
        },
    },
    companySuperAdmin: {
        dashboard: { myAccount: true, companyAccount: true },
        header: {
            appsMenu: true,
            menuItems: true,
            appLabel: true,
        },
    },
    functionalManager: {
        dashboard: { myAccount: true, companyAccount: true },
        talentMarketplace: {
            findTalent: true,
            savedProfiles: true,
            bookings: true,
        },
        mySpecialists: {
            generalInformation: {
                read: true,
                update: true,
            },
            profileInformation: {
                read: true,
                update: true,
            },
            projects: {
                read: true,
                update: true,
            },
            education: {
                read: true,
                update: true,
            },
            technicalSkills: {
                read: true,
                update: true,
            },
            personalStrengths: {
                read: true,
            },
            invitations: {
                send: true,
                resend: true,
                cancel: true,
            },
            sharingProfile: true,
            backToList: true,
            uploadCV: true,
            deleteProfile: true,
        },
        header: {
            appsMenu: true,
            menuItems: true,
            appLabel: true,
        },
    },
    basicUser: {
        dashboard: { myAccount: true, companyAccount: true },
        talentMarketplace: {
            findTalent: true,
        },
        header: {
            appsMenu: true,
            menuItems: true,
            appLabel: true,
        },
    },
}

const AccessLevelContext = createContext<AccessLevel>(noAccess)

const AccessLevelContextProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const { userActiveContextGroups } = useUserContext()
    const { isUserAuthorized } = useUserAuthentication()
    const access = useMemo<AccessLevel>(() => {
        let grantedAccess = deepClone(noAccess)
        if (isUserAuthorized) {
            userActiveContextGroups?.forEach(group => {
                if (group.name === ContextGroupName.COMPANY_SUPER_ADMIN) {
                    grantedAccess = merge(grantedAccess, accessRoles.companySuperAdmin)
                } else if (group.name === ContextGroupName.FUNCTIONAL_MANAGER) {
                    grantedAccess = merge(grantedAccess, accessRoles.functionalManager)
                } else if (group.name === ContextGroupName.SPECIALIST) {
                    grantedAccess = merge(grantedAccess, accessRoles.specialist)
                } else if (group.name === ContextGroupName.BASIC_USER) {
                    grantedAccess = merge(grantedAccess, accessRoles.basicUser)
                }
            })
            // TODO: remove when BE will handle contexts for specialists
            if (getUserRole() === 'specialist') {
                grantedAccess = merge(grantedAccess, accessRoles.specialist)
            }
        }
        return grantedAccess
    }, [userActiveContextGroups, isUserAuthorized])

    return <AccessLevelContext.Provider value={access}>{children}</AccessLevelContext.Provider>
}

export { AccessLevelContext, AccessLevelContextProvider }
