import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

const useRoutesMatching = () => {
    const location = useLocation()

    const matchPublicProfile = useMemo(
        () => matchPath<'linkId', '/public/profile/:linkId'>('/public/profile/:linkId', location.pathname),
        [location.pathname],
    )

    const matchAnonymousProfile = useMemo(
        () => matchPath<'linkId', '/anonymous/profile/:linkId'>('/anonymous/profile/:linkId', location.pathname),
        [location.pathname],
    )

    const matchProfileSummary = useMemo(
        () => matchPath<'specialistId', '/profile-summary/:specialistId'>('/profile-summary/:specialistId', location.pathname),
        [location.pathname],
    )

    const matchSkillsDiscovery = useMemo(
        () => matchPath<'specialistId', '/skills-discovery/:specialistId'>('/skills-discovery/:specialistId', location.pathname),
        [location.pathname],
    )

    const matchTalentMarketplaceProfile = useMemo(
        () => matchPath<'linkId', '/profile/:linkId'>('/profile/:linkId', location.pathname),
        [location.pathname],
    )

    const matchMySpecialistsSpecialist = useMemo(
        () =>
            matchPath<'specialistId', '/my-specialists/specialist/:specialistId'>(
                '/my-specialists/specialist/:specialistId',
                location.pathname,
            ),

        [location.pathname],
    )

    const matchCompanyInvitation = useMemo(
        () => matchPath<'prospectId', '/company-invitation/:prospectId'>('/company-invitation/:prospectId', location.pathname),

        [location.pathname],
    )

    const matchOpportunitiesManagerApplicationDetails = useMemo(
        () =>
            matchPath<'opportunityId' | 'applicationId', '/opportunities-manager/opportunities/:opportunityId/applications/:applicationId'>(
                '/opportunities-manager/opportunities/:opportunityId/applications/:applicationId',
                location.pathname,
            ),

        [location.pathname],
    )

    const matchOpportunitiesManagerSMEApplicationDetails = useMemo(
        () =>
            matchPath<
                'opportunityId' | 'applicationId',
                '/opportunities-manager-sme/opportunities/:opportunityId/applications/:applicationId'
            >('/opportunities-manager-sme/opportunities/:opportunityId/applications/:applicationId', location.pathname),

        [location.pathname],
    )

    const matchOpportunitiesManagerVMCApplicationDetails = useMemo(
        () =>
            matchPath<'opportunityId' | 'applicationId', '/vacancies-manager/opportunities/:opportunityId/applications/:applicationId'>(
                '/vacancies-manager/opportunities/:opportunityId/applications/:applicationId',
                location.pathname,
            ),

        [location.pathname],
    )


    return {
        matchPublicProfile,
        matchAnonymousProfile,
        matchProfileSummary,
        matchSkillsDiscovery,
        matchTalentMarketplaceProfile,
        matchMySpecialistsSpecialist,
        matchCompanyInvitation,
        matchOpportunitiesManagerApplicationDetails,
        matchOpportunitiesManagerSMEApplicationDetails,
        matchOpportunitiesManagerVMCApplicationDetails,

    }
}

export { useRoutesMatching }
