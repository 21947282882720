/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { blackToRedFilter, Icon } from './Icon'

type ErrorAlertProps = {
    onCloseClick?: () => void
    fontWeight?: number
    style?: SerializedStyles
}

const ErrorAlert: FunctionComponent<React.PropsWithChildren<ErrorAlertProps>> = ({ children, onCloseClick, fontWeight = 500, style }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                text-align: center;
                background-color: ${theme.colors.red_2};
                padding: 6px 16px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: relative;
                border-radius: 4px;
                font-size: 12px;
                ${style}
            `}
        >
            <Icon name='error' size={20} />
            <div
                css={css`
                    color: ${theme.colors.red_4};
                    font-weight: ${fontWeight};
                `}
            >
                {children}
            </div>
            <div>
                {onCloseClick && (
                    <Icon
                        size={20}
                        name='close'
                        style={css`
                            cursor: pointer;
                            filter: ${blackToRedFilter};
                        `}
                        onClick={onCloseClick}
                    />
                )}
            </div>
        </div>
    )
}

export { ErrorAlert }
