/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { Nullable } from '../../../types'
import { GameWrapper } from './GameWrapper'
import { RoundIndicator } from './RoundIndicator'
import { StepDescription } from './StepDescription'
import { Timer } from './Timer'

type StepWrapperProps = {
    step: number
    round: number
    rounds: number
    time: Nullable<number>
    description: string
}

const StepWrapper: FunctionComponent<React.PropsWithChildren<StepWrapperProps>> = ({
    step,
    round,
    rounds,
    time,
    description,
    children,
}) => (
    <GameWrapper>
        <RoundIndicator round={round} rounds={rounds} />
        <Timer time={time} />
        <StepDescription step={step} description={description} />
        {children}
    </GameWrapper>
)

export { StepWrapper }
