/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, PropsWithChildren, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { ControlledInput } from '../../components/forms/ControlledInput'
import { ControlledTextarea } from '../../components/forms/ControlledTextarea'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { Modal } from '../../components/Modal'
import { yup } from '../../validation/yup'

type ContactUsModalProps = {
    onClose: () => void
    opportunityId: number
    title: string
    description: string
    messagePlaceholder: string
}

const schema = yup.object().shape({
    topic: yup.string().required(),
    message: yup.string().trim().required().max(2000),
})

type FormValuesType = {
    topic: string
    message: string
}

const ContactUsModal: FunctionComponent<PropsWithChildren<ContactUsModalProps>> = ({
    onClose,
    opportunityId,
    title,
    description,
    messagePlaceholder,
}) => {
    const { control, handleSubmit, watch } = useForm<FormValuesType>({ resolver: yupResolver(schema), defaultValues: { message: '' } })

    const onSubmit = useCallback(() => {
        handleSubmit((formData: FormValuesType) => {
            if (window.Intercom) {
                window.Intercom(
                    'showNewMessage',
                    `Topic: ${formData.topic}\nMessage: ${formData.message}\nOpportunity ID: ${opportunityId}`,
                )
            }
            onClose()
        })()
    }, [handleSubmit, onClose, opportunityId])

    const message = watch('message')

    return (
        <Modal opened onClose={onClose}>
            <h5>{title}</h5>
            <p
                css={css`
                    margin-bottom: 25px;
                `}
            >
                {description}
            </p>
            <form>
                <FieldWrapper>
                    <ControlledInput name='topic' control={control} label='Topic' />
                </FieldWrapper>
                <FieldWrapper>
                    <ControlledTextarea
                        name='message'
                        control={control}
                        label='Your message'
                        placeholder={messagePlaceholder}
                        infoMessage={`${message.length}/2000`}
                    />
                </FieldWrapper>
            </form>
            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button
                    css={css`
                        margin-right: 29px;
                    `}
                    variant='link'
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button variant='primary' onClick={onSubmit}>
                    Start chat
                </Button>
            </div>
        </Modal>
    )
}

export { ContactUsModal }
