/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { Icon } from '../../components/Icon'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { OpportunityDetails as OpportunityDetailsComponent } from '../../components/opportunities/OpportunityDetails'
import { SideMenuBox } from '../../components/SideMenuBox'
import { OpportunityStatus } from '../../contracts/opportunities'

import { Nullable } from '../../types'
import { getOpportunity } from '../../api/opportunities-manager/api'
import { OpportunityResponseBody } from '../../contracts/opportunities-manager/contracts'
import { VacancyManagerMenuItems } from '../../components/opportunities-manager/utils/menuItems'
import { useOpportunitiesActions } from '../../components/opportunities-manager/utils/useOpportunityActions'

const rowButtonStyles = css`
    width: 100%;
`

const OpportunityDetailsVMC: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { addError } = useNotifications()
    const [opportunity, setOpportunity] = useState<Nullable<OpportunityResponseBody>>(null)
    const [error, setError] = useState<string>('')
    const { opportunityId } = useParams<{ opportunityId: string }>()

    const loadOpportunity = useCallback(() => {
        if (opportunityId) {
            getOpportunity(opportunityId)
                .then(setOpportunity)
                .catch(err => {
                    if (err.status === 400) {
                        setError('not found')
                    } else {
                        addError()
                    }
                })
        }
    }, [addError, opportunityId])

    useEffect(() => {
        loadOpportunity()
    }, [loadOpportunity])

    const { onToggleStatus, onShare, onEdit, onDuplicate, ShareModal } = useOpportunitiesActions({
        opportunityId: opportunityId || '',
        status: opportunity?.opportunityStatus,
        onStatusChangeSuccessCallback: loadOpportunity,
        isSmeCloudApp: true,
        isVMCCloudApp: true,
    })

    return (
        <OpportunityDetailsComponent
            applicationName={'Vacancies Manager'}
            applicationMenuItems={VacancyManagerMenuItems}
            applicationType='VACANCIES_MANAGER'
            dataTestId='opportunity-details-header'
            error={error}
            opportunity={opportunity}
            shareModal={ShareModal}
            secondCol={
                opportunity && (
                    <SideMenuBox>
                        {opportunity.opportunityStatus === OpportunityStatus.DRAFT ? (
                            <Button
                                style={css`
                                    ${rowButtonStyles};
                                    margin-bottom: 10px;
                                `}
                                onClick={onEdit}
                                size='big'
                                dataTestId='opportunity-edit'
                            >
                                Finish editing
                            </Button>
                        ) : (
                            <Button
                                style={css`
                                    ${rowButtonStyles};
                                    margin-bottom: 10px;
                                `}
                                onClick={opportunity.opportunityStatus === OpportunityStatus.CLOSED ? onDuplicate : onToggleStatus}
                                dataTestId='opportunity-change-status'
                                size='big'
                                disabled={opportunity.opportunityStatus === OpportunityStatus.ON_HOLD}
                            >
                                {opportunity.opportunityStatus === OpportunityStatus.PUBLISHED && 'Close Vacancy'}
                                {opportunity.opportunityStatus === OpportunityStatus.CLOSED && 'Duplicate Vacancy'}
                                {opportunity.opportunityStatus === OpportunityStatus.ON_HOLD && 'On Hold'}
                            </Button>
                        )}
                        {opportunity?.opportunityStatus !== OpportunityStatus.CLOSED && (
                            <Button onClick={onDuplicate} variant='link' style={rowButtonStyles} dataTestId='opportunity-details-duplicate'>
                                <span
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                    `}
                                >
                                    <Icon
                                        style={css`
                                            margin-right: 8px;
                                        `}
                                        name='duplicate'
                                    />
                                    Duplicate
                                </span>
                            </Button>
                        )}
                        {opportunity?.opportunityStatus !== OpportunityStatus.DRAFT && (
                            <Tooltip
                                title={
                                    opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                    `This Opportunity is closed and can't be edited or shared.`
                                }
                            >
                                <div
                                    css={css`
                                        width: 100%;
                                        ${opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                        css`
                                            cursor: not-allowed;
                                        `}
                                    `}
                                >
                                    <Fragment>
                                        {opportunity?.opportunityStatus !== OpportunityStatus.CLOSED && (
                                            <Divider
                                                style={css`
                                                    margin: 0;
                                                `}
                                            />
                                        )}
                                        <Button
                                            onClick={onEdit}
                                            variant='link'
                                            style={css`
                                                ${rowButtonStyles}
                                                ${opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                                css`
                                                    pointer-events: none;
                                                `}
                                            `}
                                            dataTestId='opportunity-details-edit'
                                            disabled={opportunity?.opportunityStatus !== OpportunityStatus.PUBLISHED}
                                        >
                                            <span
                                                css={css`
                                                    display: flex;
                                                    align-items: center;
                                                `}
                                            >
                                                <Icon
                                                    style={css`
                                                        margin-right: 8px;
                                                    `}
                                                    name='edit'
                                                />
                                                Edit
                                            </span>
                                        </Button>
                                    </Fragment>
                                </div>
                            </Tooltip>
                        )}
                        {opportunity?.opportunityStatus !== OpportunityStatus.DRAFT && (
                            <Tooltip
                                title={
                                    opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                    `This Opportunity is closed and can't be edited or shared.`
                                }
                            >
                                <div
                                    css={css`
                                        width: 100%;
                                        ${opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                        css`
                                            cursor: not-allowed;
                                        `}
                                    `}
                                >
                                    <Fragment>
                                        <Divider
                                            style={css`
                                                margin: 0;
                                            `}
                                        />
                                        <Button
                                            onClick={onShare}
                                            variant='link'
                                            style={css`
                                                ${rowButtonStyles}
                                                display: flex;
                                                align-items: center;
                                                ${opportunity?.opportunityStatus === OpportunityStatus.CLOSED &&
                                                css`
                                                    pointer-events: none;
                                                `}
                                            `}
                                            dataTestId='opportunity-share'
                                            disabled={opportunity?.opportunityStatus !== OpportunityStatus.PUBLISHED}
                                        >
                                            <span
                                                css={css`
                                                    display: flex;
                                                    align-items: center;
                                                `}
                                            >
                                                <Icon
                                                    style={css`
                                                        margin-right: 8px;
                                                    `}
                                                    name='share'
                                                />
                                                Share Vacancy
                                            </span>
                                        </Button>
                                    </Fragment>
                                </div>
                            </Tooltip>
                        )}
                    </SideMenuBox>
                )
            }
        />
    )
}

export { OpportunityDetailsVMC }
