import { Fragment, ReactNode, useCallback } from 'react'
import { normalizeTimeStamp } from '../../utils/dates'
import { OptionObject, OptionType } from '../inputs/autocomplete-select/Option'

export type RangeFilter = { value: any; filterName: string }

export type FiltersFunctions = {
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
    onLocationChange: (values: Array<OptionType>, filterName: string) => void
    onValueChange: (value: any, filterName: string) => void
    onDatePickerChange: (timestamp: any, filterName: string) => void
    onRangeFilterChange: (values: Array<RangeFilter>) => void
}

type FiltersBarProps<T> = {
    selectedFilters: T
    onChangeFilters: (filters: T) => void
    children: (functions: FiltersFunctions) => ReactNode
}

const FiltersBar = <T,>({ selectedFilters, onChangeFilters, children }: FiltersBarProps<T>) => {
    const onSelectFilterChange = useCallback(
        (values: Array<OptionType>, filterName: string) => {
            const currentFilters: any = { ...selectedFilters }

            if (currentFilters[filterName]) {
                currentFilters[filterName] = values
            }

            onChangeFilters(currentFilters)
        },
        [onChangeFilters, selectedFilters],
    )

    const onLocationChange = useCallback(
        (values: Array<OptionType>, filterName: string) => {
            const currentFilters: any = { ...selectedFilters }
            const filterNames = filterName
                .replace(/([A-Z])/g, match => ` ${match}`)
                .replace(/^./, match => match.toUpperCase())
                .split(' ')

            currentFilters[filterNames[0].toLowerCase()] = values
                .filter(value => (value as OptionObject).groupName === filterNames[0])
                .map(country => (country as OptionObject).value)
            currentFilters[filterNames[1].toLowerCase()] = values
                .filter(value => (value as OptionObject).groupName === filterNames[1])
                .map(city => (city as OptionObject).value)

            onChangeFilters(currentFilters)
        },
        [onChangeFilters, selectedFilters],
    )

    const onValueChange = useCallback(
        (value: any, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: Array.isArray(value) ? value[0] : value,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    const onDatePickerChange = useCallback(
        (timestamp: any, filterName: string) => {
            onChangeFilters({ ...selectedFilters, [filterName]: Number(normalizeTimeStamp(timestamp)) })
        },
        [selectedFilters, onChangeFilters],
    )

    const onRangeFilterChange = useCallback(
        (values: Array<RangeFilter>) => {
            onChangeFilters({ ...selectedFilters, [values[0].filterName]: values[0].value, [values[1].filterName]: values[1].value })
        },
        [selectedFilters, onChangeFilters],
    )

    return (
        children && (
            <Fragment>
                {children({ onSelectFilterChange, onLocationChange, onValueChange, onDatePickerChange, onRangeFilterChange })}
            </Fragment>
        )
    )
}

export { FiltersBar }
