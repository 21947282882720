/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Dispatch, FunctionComponent, SetStateAction, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../Button'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../forms/ControlledAutocompleteSelect'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { Nullable } from '../../types'
import {
    Application,
    ApplicationStatus,
    ApplicationStatusEnum,
    applicationStatusesList,
    ApplicationStatusMapping,
} from '../../contracts/opportunities-manager/contracts'
import { useApplicationUpdate } from './utils/useApplicationUpdate'

type UpdateApplicationStatusFormProps = {
    application: Application
    applicationId: string
    currency: string
    recentStatus: ApplicationStatus
    toggleEditMode: () => void
    setApplication: Dispatch<SetStateAction<Nullable<Application>>>
}

type ApplicationStatusFormData = { status: ApplicationStatusEnum }

const UpdateApplicationStatusForm: FunctionComponent<React.PropsWithChildren<UpdateApplicationStatusFormProps>> = ({
    application,
    applicationId,
    currency,
    recentStatus,
    toggleEditMode,
    setApplication,
}) => {
    const afterRequestCallback = useCallback(
        (_applicationId: string, application?: Application) => {
            toggleEditMode()
            if (application) {
                setApplication(application)
            }
        },
        [setApplication, toggleEditMode],
    )

    const { handleApplicationChange, ApplicationStatusUpdateRejectionReasonModal } = useApplicationUpdate({
        afterRequestCallback,
        applicationData: {
            // `useApplicationUpdate` expects list of applications, from which it finds corresponding one (by searching via id),
            // but since this component 'operates' only on one application, we need to pass it as a list of (only one) application(s)
            applications: [application],
            currency,
        },
    })
    const { control, handleSubmit } = useForm<ApplicationStatusFormData>({
        defaultValues: { status: recentStatus.status },
    })

    const onSubmit = useCallback(
        (data: ApplicationStatusFormData) => {
            handleApplicationChange(applicationId, data.status)
        },
        [applicationId, handleApplicationChange],
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FlexBox alignItems='center' justifyContent='space-between'>
                <ControlledAutocompleteSelect
                    styles={css`
                        max-width: 240px;
                    `}
                    options={applicationStatusesList}
                    control={control}
                    name='status'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    selectedLabelTransformer={val => ApplicationStatusMapping[val as string]}
                    canFilter={false}
                    dropdownHeight='330px'
                />
                <div>
                    <Button
                        variant='link'
                        css={css`
                            margin-right: 16px;
                        `}
                        onClick={toggleEditMode}
                    >
                        Cancel
                    </Button>
                    <Button variant='primary' type='submit'>
                        Save
                    </Button>
                </div>
            </FlexBox>
            {ApplicationStatusUpdateRejectionReasonModal}
        </form>
    )
}

export { UpdateApplicationStatusForm }
