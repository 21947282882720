import { axios } from '../../api/axios'
import { FeedbackRequestBody, NextQuestionRequestBody, Test, TestState } from './tests'

const specialistsEndpoint = '/v1/specialists'

const getSpecialistTests = (specialistId: string): Promise<Array<Test>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/tests`)
}

const startSpecialistTests = (specialistId: string, testId: string): Promise<TestState> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/tests/${testId}:start`, {})
}

const resumeSpecialistTests = (specialistId: string, testId: string): Promise<TestState> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/tests/${testId}:resume`, {})
}

const getNextQuestion = (specialistId: string, testId: string, questionId: string, data: NextQuestionRequestBody): Promise<TestState> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/tests/${testId}/questions/${questionId}:next`, data)
}

const sendQuestionFeedback = (specialistId: string, testId: string, questionId: string, data: FeedbackRequestBody): Promise<undefined> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/tests/${testId}/questions/${questionId}:submitFeedback`, data)
}

export { getSpecialistTests, startSpecialistTests, resumeSpecialistTests, getNextQuestion, sendQuestionFeedback }
