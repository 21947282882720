/** @jsxImportSource @emotion/react */

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
    getShareMySpecialistsBookingFeedbackState,
    getShareMySpecialistsFeedbackNegativeResponseReasonOptions,
    sendInProgressShareMySpecialistsFeedback,
    sendShareMySpecialistsRateExperience,
    sendNegativeShareMySpecialistsFeedback,
    sendPositiveShareMySpecialistsFeedback,
} from 'src/api/api'
import { FeedbackStep, FeedbackWizard } from 'src/components/feedback/FeedbackWizard'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { getAppPath } from 'src/contracts/applications'
import { DictionaryEntry } from 'src/contracts/dictionaryEntry'
import { useMySpecialistsMenuItems } from 'src/hooks/useMySpecialistsMenuItems'
import { ReduxContext } from 'src/redux/Store'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'

const ShareMySpecialistsFeedbackPage = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const log = useLogger('error')
    const query = useQuery()

    const bookingId = useMemo(() => query.get('bookingId') || '', [query])
    const specialistId = useMemo(() => query.get('specialistId') || '', [query])
    const [step, setStep] = useState<FeedbackStep>(FeedbackStep.PENDING_RESULT)
    const [negativeResponseOptions, setNegativeResponseOptions] = useState<Array<DictionaryEntry>>([])

    const { menuItems, applicationName } = useMySpecialistsMenuItems()

    const handleError = useCallback((error: any) => {
        if (error.data.code === 'ADDING_BOOKING_FEEDBACK_NOT_ALLOWED') {
            setStep(FeedbackStep.NOT_ALLOWED)
        }
    }, [])

    const onRateYourExperienceSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendShareMySpecialistsRateExperience({ ...formData, bookingId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, handleError, log],
    )

    const onPositiveFeedbackSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendPositiveShareMySpecialistsFeedback({ ...formData, bookingId, specialistId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, specialistId, handleError, log],
    )

    const onNegativeFeedbackSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendNegativeShareMySpecialistsFeedback({ ...formData, bookingId, specialistId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, specialistId, handleError, log],
    )

    const onInProgressSelected = useCallback(() => {
        layoutToggleLoader(true)

        sendInProgressShareMySpecialistsFeedback({ bookingId })
            .then()
            .catch(error => {
                handleError(error)
                log(error)
            })
            .finally(() => layoutToggleLoader(false))
    }, [layoutToggleLoader, bookingId, handleError, log])

    const matchStepResponse = useCallback(
        (response: string) => {
            switch (response) {
                case 'PENDING_RESULT':
                    setStep(FeedbackStep.PENDING_RESULT)
                    break
                case 'PENDING_RATE_EXPERIENCE':
                    setStep(FeedbackStep.PENDING_RATE_EXPERIENCE)
                    break
                case 'DONE':
                    setStep(FeedbackStep.DONE)
                    break
                case 'EXPIRED':
                    setStep(FeedbackStep.EXPIRED)
                    break
            }
        },
        [setStep],
    )

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)

        Promise.all([getShareMySpecialistsFeedbackNegativeResponseReasonOptions(), getShareMySpecialistsBookingFeedbackState(bookingId)])
            .then(responses => {
                setNegativeResponseOptions(responses[0])
                matchStepResponse(responses[1])
            })
            .catch(log)
            .finally(() => {
                layoutToggleLoader(false)
            })
    }, [layoutToggleLoader, matchStepResponse, log, bookingId])

    useEffect(() => fetchData(), [fetchData])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <FeedbackWizard
                step={step}
                backButtonText='Back to Added Specialists'
                backButtonPath={`${getAppPath('MY_SPECIALISTS')}/share`}
                newFeedbackFirstStepBackText='Back to list'
                pleaseRateMessage='Please rate your experience managing the Booking Request process with our Share My Specialists tab in the My Specialists App (optional).'
                newFeedbackSecondStepPositiveResponseSubmit={onPositiveFeedbackSubmit}
                newFeedbackSecondStepInProgressResponseSubmit={onInProgressSelected}
                newFeedbackSecondStepNegativeResponseSubmit={onNegativeFeedbackSubmit}
                newFeedbackSecondStepNegativeReasonOptions={negativeResponseOptions}
                rateExperienceOnSubmit={onRateYourExperienceSubmit}
            />
        </DashboardLayout>
    )
}

export { ShareMySpecialistsFeedbackPage }
