/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { RatingStars, RatingStarsProps } from '../inputs/RatingStars'
import { ControlledFieldProps } from './types'

type ControlledRatingStarsProps = Omit<RatingStarsProps, 'onChange' | 'value' | 'errorMessage'> & ControlledFieldProps<number> & {}

const ControlledRatingStars: FunctionComponent<React.PropsWithChildren<ControlledRatingStarsProps>> = ({
    name,
    control,
    defaultValue = '',
    label,
    labelTooltip,
    infoMessage,
    dataTestId,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })
    return (
        <RatingStars
            onChange={onChange}
            value={value}
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            infoMessage={infoMessage}
            dataTestId={dataTestId}
        />
    )
}

export { ControlledRatingStars }
