import { FunctionComponent, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRoutesMatching } from '../hooks/useRoutesMatching'
import { useUserAuthentication } from '../hooks/useUserAuthentication'
import { ReduxContext } from '../redux/Store'
import { useQuery } from '../utils/useQuery'

const PublicRoute: FunctionComponent<React.PropsWithChildren<any>> = ({ component: Component, ...rest }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const query = useQuery()

    const { matchAnonymousProfile } = useRoutesMatching()

    const {
        actions: { whiteLabelSet, setShouldWaitForWhiteLabel },
        selectors: { whiteLabel, decodedAccessToken },
    } = useContext(ReduxContext)

    const { isUserAuthorized, isUnauthorized, isWaitingForStatus } = useUserAuthentication()

    useEffect(() => {
        if (!isWaitingForStatus) {
            const pathsDisabledForAuthorizedUsers = [
                '/login',
                '/register',
                '/register/confirmation',
                '/recover-password',
                '/',
                '/recover-password',
                '/vmc-invitation',
            ]
            if (isUserAuthorized && (pathsDisabledForAuthorizedUsers.includes(location.pathname) || location.pathname === '/logout')) {
                const afterLogin = query.get('afterLogin')
                const defaultRedirect = decodedAccessToken?.specialistId ? '/specialist-dashboard' : '/dashboard'
                navigate(afterLogin || defaultRedirect)
            } else if (isUnauthorized && whiteLabel && !matchAnonymousProfile) {
                whiteLabelSet(null)
                localStorage.removeItem('whiteLabel')
                setShouldWaitForWhiteLabel(false)
            }
        }
    }, [
        isUserAuthorized,
        isWaitingForStatus,
        navigate,
        location.pathname,
        query,
        isUnauthorized,
        whiteLabel,
        decodedAccessToken,
        whiteLabelSet,
        setShouldWaitForWhiteLabel,
        matchAnonymousProfile,
    ])

    return <Component {...rest} />
}

export { PublicRoute }
