import { StringMapping } from '../../types'

export interface Booking {
    id: string
    projectName: string
    role: string
    lastUpdatedAt: number
    bookingStatus: string
    bookingRequestSentOn: number
    specialistId: string
    bookedBy: string
    anonymousNumber: number
}

export interface BookingDetails {
    bookingStatus: string
    idealProjectStartDate: number
    lastUpdatedAt: number
    latestProjectStartDate: number
    projectDuration: string
    projectName: string
    techStack: Array<string>
}

export interface BookingDetailsForManager {
    bookingRequestId: string
    specialistId: string
    specialistName: string
    projectDuration: string
    idealProjectStartDate: number
    latestProjectStartDate: number
    techStack: Array<string>
}

export interface ResponseData {
    comment?: string
    refuseReason?: ResponseType
    response: 'ACCEPTED' | 'REJECTED'
}

export enum ResponseType {
    SPECIALIST_DOESNT_WORK = 'SPECIALIST_DOESNT_WORK',
    SPECIALIST_IN_ANOTHER_PROJECT = 'SPECIALIST_IN_ANOTHER_PROJECT',
    PROJECT_DURATION_NOT_FIT = 'PROJECT_DURATION_NOT_FIT',
    PROJECT_TECHSTACK_NOT_FIT = 'PROJECT_TECHSTACK_NOT_FIT',
    OTHER = 'OTHER',
}

const responseTypeMapping: StringMapping = {
    [ResponseType.SPECIALIST_DOESNT_WORK]: 'This Specialist is no longer employed by our Company',
    [ResponseType.SPECIALIST_IN_ANOTHER_PROJECT]: 'This Specialist is engaged during the proposed Project Duration timeline ',
    [ResponseType.PROJECT_DURATION_NOT_FIT]: 'The Project Duration is not a good fit for our Company',
    [ResponseType.PROJECT_TECHSTACK_NOT_FIT]: 'The Project Tech Stack is not a good fit for our Company',
    [ResponseType.OTHER]: 'Other Clarification (Please write in the text box provided)',
}

const mapResponseType = (type: ResponseType) => {
    return type !== null ? responseTypeMapping[type] : ''
}

export { mapResponseType }
