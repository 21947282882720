export type SortOrder = 'ascend' | 'descend'

export interface SortEntryWithField {
    field: string
    order: SortOrder
}

export type SortEntry = SortEntryWithField

export interface Sorting {
    sorting?: Array<SortEntry>
}

const EMPTY_SORTING = 'EMPTY'

export { EMPTY_SORTING }
