/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { BackLink } from '../../../../components/BackLink'
import { Divider } from '../../../../components/Divider'
import { Icon } from '../../../../components/Icon'
import { DashboardLayout } from '../../../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../../../components/layout/Loader'
import { ApplicationsWrapper } from '../../../../components/layout/ResponsiveWrapper'
import { mqMax } from '../../../../GlobalStyle'
import { useUserPendingInvitations } from '../../../../hooks/useUserPendingInvitations'
import { PendingInvitation } from './components/PendingInvitation'

const PendingInvitationsPage = () => {
    const pendingInvitations = useUserPendingInvitations()
    const theme = useTheme()
    return (
        <DashboardLayout>
            <ApplicationsWrapper>
                <BackLink
                    style={css`
                        margin: 36px 0;
                        ${mqMax[1]} {
                            margin: 93px 0 36px;
                        }
                    `}
                    text='Back'
                    path='/dashboard'
                />
                <h3>{`Pending Invitations ${pendingInvitations ? `(${pendingInvitations.length})` : ''}`}</h3>
                <Divider />
                <div
                    css={css`
                        line-height: 30px;
                        margin-bottom: 20px;
                    `}
                >
                    Accepting an invite will allow you to join that company's context and will grant you access to this company's data. You
                    can always switch between all contexts available to you by clicking the{' '}
                    <div
                        css={css`
                            background-color: ${theme.colors.gray_1};
                            display: inline-block;
                            width: 30px;
                            height: 30px;
                            border-radius: 15px;
                            padding: 5px;
                            position: relative;
                            top: 10px;
                            margin-top: -10px;
                        `}
                    >
                        <Icon
                            name='user'
                            size={20}
                            style={css`
                                display: block;
                            `}
                        />
                    </div>{' '}
                    icon in the top right corner and selecting new context.
                </div>
                {pendingInvitations &&
                    pendingInvitations.map(pendingInvitation => (
                        <PendingInvitation key={pendingInvitation.prospectId} pendingInvitation={pendingInvitation} />
                    ))}
                {pendingInvitations && pendingInvitations.length === 0 && (
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 70px;
                        `}
                    >
                        <Icon name='no-data' size={30} />
                        <h5
                            css={css`
                                margin-top: 30px;
                            `}
                        >
                            No pending invitations
                        </h5>
                    </div>
                )}
                {!pendingInvitations && <ContentLoader />}
            </ApplicationsWrapper>
        </DashboardLayout>
    )
}

export { PendingInvitationsPage }
