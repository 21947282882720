type Config = {
    environment: 'dev' | 'stage' | 'production'
    firebaseConfig: {
        apiKey: string
        authDomain: string
        databaseURL: string
        projectId: string
        storageBucket: string
        messagingSenderId: string
        appId: string
        measurementId: string
    }
    gcpTenantId: string
    gcpSpecialistTenantId: string
    appUrl: string
    apiUrl: string
    captchaSitekey: string
    intercomAppId: string
    specialistImportTemplateUrl: string
    dotMemoryRoundsLimit: number
    dotMemoryDisruptionTime: number
}

let config: Config

switch (process.env.REACT_APP_ENV) {
    case 'production':
        config = require('./prod.config.json')
        break

    case 'stage':
        config = require('./stage.config.json')
        break

    default:
        config = require('./dev.config.json')
        break
}

export { config }
