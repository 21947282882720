/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mqMax } from '../../GlobalStyle'
import { BackLink } from '../BackLink'
import { Button } from '../Button'
import { ControlledRadioGroup, ControlledRadioGroupOptionType } from '../forms/ControlledRadioGroup'
import { ResponsiveWrapper } from '../layout/ResponsiveWrapper'

type FeedbackFirstStepProps = {
    backPath: string
    backButtonText: string
    onNext: (formData: any) => void
}

const FeedbackFirstStep: FunctionComponent<React.PropsWithChildren<FeedbackFirstStepProps>> = ({ backPath, backButtonText, onNext }) => {
    const { handleSubmit, reset, control } = useForm()

    useEffect(() => {
        reset({ response: 'YES' })
    }, [reset])

    return (
        <ResponsiveWrapper
            styles={css`
                max-width: 474px;
            `}
        >
            <BackLink
                path={backPath}
                text={backButtonText}
                style={css`
                    margin: 24px 0;
                `}
            />

            <p
                css={css`
                    margin-bottom: 24px;
                `}
            >
                In the interest of helping you close this booking as smoothly and as quickly as possible, please answer the following
                question:
            </p>

            <h4
                css={css`
                    max-width: 433px;
                    margin-top: 24px;
                    margin-bottom: 16px;
                `}
            >
                Has the booking process been completed for this Project/Specialist?
            </h4>

            <form onSubmit={handleSubmit(onNext)}>
                <ControlledRadioGroup
                    name='response'
                    control={control}
                    options={[
                        {
                            value: 'YES',
                            label: 'Yes',
                            type: ControlledRadioGroupOptionType.OPTION,
                        },
                        {
                            value: 'IN_PROGRESS',
                            label: 'No, we are still negotiating the terms of our agreement',
                            type: ControlledRadioGroupOptionType.OPTION,
                        },
                        {
                            value: 'NO',
                            label: 'No, we could not come to terms and mutually agreed to halt negotiations and cancel the booking ',
                            type: ControlledRadioGroupOptionType.OPTION,
                            wrapperStyle: css`
                                margin-bottom: 24px;
                            `,
                        },
                    ]}
                />

                <Button
                    variant='primary'
                    type='submit'
                    style={css`
                        width: 145px;

                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Next
                </Button>
            </form>
        </ResponsiveWrapper>
    )
}

export { FeedbackFirstStep }
