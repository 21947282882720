/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { BlueActionBox } from 'src/components/BlueActionBox'
import { mqMax } from 'src/GlobalStyle'

type SelectedSpecialistsProps = {
    numberOfSelectedSpecialists: number
    total: number
    allEditableSpecialists?: number
    handleSelectAll: () => void
    handleDeselectAll: () => void
}

const SelectedSpecialists: FunctionComponent<React.PropsWithChildren<SelectedSpecialistsProps>> = ({
    numberOfSelectedSpecialists,
    total,
    allEditableSpecialists,
    handleSelectAll,
    handleDeselectAll,
}) => {
    const areAllSpecialistsSelected = allEditableSpecialists
        ? numberOfSelectedSpecialists === allEditableSpecialists
        : numberOfSelectedSpecialists === total

    return (
        <div
            css={css`
                display: ${numberOfSelectedSpecialists === 0 ? 'none' : 'block'};
                padding: 16px 0;
            `}
        >
            <div>
                <BlueActionBox
                    text={`You have selected ${numberOfSelectedSpecialists} specialists. ${
                        areAllSpecialistsSelected ? '' : 'Do you want to select all of your Specialists?'
                    }`}
                    buttonText={areAllSpecialistsSelected ? 'Deselect All' : `Select All (${total})`}
                    action={areAllSpecialistsSelected ? handleDeselectAll : handleSelectAll}
                    styles={css`
                        ${mqMax[2]} {
                            margin: 16px;
                        }
                    `}
                />
            </div>
        </div>
    )
}

export { SelectedSpecialists }
