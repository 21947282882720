/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { blackToVioletDarkFilter, Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { OpportunityStatus } from '../../contracts/opportunities'
import { mqMax } from '../../GlobalStyle'
import { SearchOpportunityItem } from '../../contracts/opportunities-manager/contracts'
import { OpportunityMoreActions } from './OpportunityMoreActions'

interface OpportunityActionsProps {
    opportunity: SearchOpportunityItem
    onStatusChange: (opportunityId: string, status: OpportunityStatus) => void
    isSmeCloud: boolean
}

const OpportunityActions: FunctionComponent<React.PropsWithChildren<OpportunityActionsProps>> = ({
    opportunity,
    onStatusChange,
    isSmeCloud,
}) => {
    return (
        <Fragment>
            <div
                css={css`
                    justify-content: center;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    ${mqMax[1]} {
                        margin-top: 15px;
                        justify-content: space-between;
                    }
                `}
            >
                <FlexBox
                    css={css`
                        margin: 0 20px 0 -10px;
                    `}
                >
                    <OpportunityMoreActions opportunity={opportunity} onStatusChange={onStatusChange} isSmeCloud={isSmeCloud} />
                </FlexBox>
                <Icon
                    name='arrow-right-single'
                    size={20}
                    style={css`
                        display: none;
                        margin-left: -20px;
                        filter: ${blackToVioletDarkFilter};
                    `}
                />
            </div>
        </Fragment>
    )
}

export { OpportunityActions }
