/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import saveAs from 'file-saver'
import { Dispatch, Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { usePromise } from 'react-use'
import { FullProfileData, getSpecialistData } from '../../api/api'
import Download from '../../assets/icons/download.svg'
import { VerifiedSkill } from '../../contracts/profile/skill'
import { SkillSubcategory } from '../../contracts/profile/skillSubcategory'
import { EducationType } from '../../contracts/specialist/specialistEducations'
import { mqMax } from '../../GlobalStyle'
import { useRoutesMatching } from '../../hooks/useRoutesMatching'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { deepClone } from '../../utils/deepClone'
import { useLogger } from '../../utils/useLogger'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Checkbox } from '../inputs/Checkbox'
import { ContentLoader } from '../layout/Loader'
import { Modal } from '../Modal'
import { NoData } from '../NoData'
import { useNotifications } from '../notification/NotificationProvider'
import { generatePdf } from './api'
import ProjectItem from './ProjectItem'
import { ExportType } from './types'

import { cleanStringFromHTMLTags } from 'src/utils/strings'

const SingleRow = styled.div`
    padding: 10px 0px;
`

type CustomExportModalProps = {
    opened: boolean
    handleCloseModal: () => void
    specialistId: string
    exportType: Nullable<ExportType>
    setExportType: Dispatch<Nullable<ExportType>>
    setSpecialistId?: Dispatch<string>
    dataTestId?: string
    anonymousProfile?: FullProfileData
    isAnonymized?: Nullable<boolean>
}

const CustomExportModal: FunctionComponent<React.PropsWithChildren<CustomExportModalProps>> = ({
    opened,
    handleCloseModal,
    specialistId = '',
    setExportType,
    setSpecialistId,
    dataTestId = '',
    anonymousProfile,
    isAnonymized = false,
}) => {
    const [educationChecked, setEducationChecked] = useState(true)
    const [certificatesChecked, setCertificatesChecked] = useState(true)
    const [languagesChecked, setLanguagesChecked] = useState(true)
    const [technicalSkillsChecked, setTechnicalSkillsChecked] = useState(true)
    const [personalStrengthsChecked, setPersonalStrengthsChecked] = useState(true)
    const [isDownloading, setIsDownloading] = useState(false)

    const [chosenProjects, setChosenProjects] = useState<Array<string>>([])
    const [loading, setLoading] = useState(false)
    const [dataToExport, setDataToExport] = useState<Nullable<FullProfileData>>(null)
    const theme = useTheme()
    const { addError } = useNotifications()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const mounted = usePromise()
    const { matchProfileSummary, matchTalentMarketplaceProfile, matchPublicProfile, matchAnonymousProfile } = useRoutesMatching()
    const log = useLogger()

    const fetchProjects = useCallback(() => {
        if (anonymousProfile && !matchProfileSummary) {
            setLoading(true)
            setDataToExport(anonymousProfile)
            setLoading(false)
        } else {
            if (specialistId) {
                setLoading(true)

                mounted(
                    getSpecialistData(specialistId, Boolean(isAnonymized))
                        .then(data => {
                            setDataToExport(data)
                        })
                        .catch(err => {
                            log(err)
                            addError()
                        })
                        .finally(() => {
                            setLoading(false)
                        }),
                )
            }
        }
    }, [addError, anonymousProfile, log, matchProfileSummary, mounted, specialistId, isAnonymized])

    useEffect(() => {
        setChosenProjects(dataToExport?.projects.map(project => project.id) || [])
    }, [dataToExport])

    const onChange = useCallback((id: string) => {
        setChosenProjects(currentChosenProjects =>
            currentChosenProjects.includes(id)
                ? [...currentChosenProjects.filter(element => element !== id)]
                : [...currentChosenProjects, id],
        )
    }, [])

    useEffect(() => {
        fetchProjects()
    }, [fetchProjects])

    const onCloseModal = useCallback(() => {
        handleCloseModal()
        setExportType(null)
        setChosenProjects([])

        setEducationChecked(true)
        setCertificatesChecked(true)
        setLanguagesChecked(true)
        setTechnicalSkillsChecked(true)
        setPersonalStrengthsChecked(true)
        setIsDownloading(false)

        if (setSpecialistId) {
            setSpecialistId('')
        }
    }, [handleCloseModal, setExportType, setSpecialistId])

    const cleanExportProfileState = useCallback(() => setExportType(null), [setExportType])

    const handleDownload = useCallback(() => {
        if (dataToExport) {
            const data = deepClone(dataToExport)

            if (!educationChecked) {
                data.education = []
            }

            if (educationChecked && !certificatesChecked) {
                data.education = data.education.filter(e => e.type !== EducationType.CERTIFICATE_COURSE)
            }

            if (!languagesChecked) {
                data.languages = []
            }

            if (!technicalSkillsChecked) {
                data.techSkills.categories = []
            } else {
                data.techSkills.categories = data.techSkills.categories.map(category => {
                    let temp: SkillSubcategory & {
                        name: string
                        basic: Array<string | VerifiedSkill>
                        good: Array<string | VerifiedSkill>
                        expert: Array<string | VerifiedSkill>
                        notRated: Array<string | VerifiedSkill>
                    } = {
                        name: category.name,
                        basic: [],
                        good: [],
                        expert: [],
                        notRated: [],
                    }

                    category.subcategories.forEach(subcategory => {
                        temp.basic.push(...subcategory.basic)
                        temp.good.push(...subcategory.good)
                        temp.expert.push(...subcategory.expert)
                    })

                    category.subcategories = [temp]

                    return category
                })
            }

            if (!personalStrengthsChecked) {
                data.personalStrengths = null
            }

            data.projects = data.projects
                .filter(p => chosenProjects.includes(p.id))
                .map(project => ({ ...project, description: cleanStringFromHTMLTags(project.description || '') }))

            layoutToggleLoader(true)
            setIsDownloading(true)

            generatePdf(data)
                .then((res: any) => {
                    saveAs(res, `${`profile-full-${specialistId}`}.pdf`)
                    layoutToggleLoader(false)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    layoutToggleLoader(false)
                    setIsDownloading(false)

                    if (onCloseModal) {
                        onCloseModal()
                    }
                    if (cleanExportProfileState) {
                        cleanExportProfileState()
                    }
                })
        }
    }, [
        addError,
        cleanExportProfileState,
        dataToExport,
        layoutToggleLoader,
        log,
        onCloseModal,
        specialistId,
        educationChecked,
        certificatesChecked,
        languagesChecked,
        personalStrengthsChecked,
        technicalSkillsChecked,
        chosenProjects,
    ])

    return (
        <Modal
            opened={opened}
            onClose={onCloseModal}
            dataTestId={dataTestId}
            style={css`
                ${mqMax[1]} {
                    padding: 24px;
                    z-index: 20;
                }
            `}
        >
            <h4>Export Profile</h4>
            {dataToExport && !matchTalentMarketplaceProfile && !matchPublicProfile && !matchAnonymousProfile && (
                <Fragment>
                    <p
                        css={css`
                            color: ${theme.colors.gray_4};
                        `}
                    >
                        You can choose which information you would like to display on your exported profile.
                    </p>
                    {dataToExport?.education.length !== 0 && (
                        <SingleRow>
                            <Checkbox
                                checkboxLabel='Education'
                                name='education'
                                style={css`
                                    font-weight: 500;
                                `}
                                checked={educationChecked}
                                onChange={setEducationChecked}
                            />
                        </SingleRow>
                    )}

                    {dataToExport?.education.filter(e => e.type === EducationType.CERTIFICATE_COURSE).length !== 0 && (
                        <SingleRow>
                            <Checkbox
                                checkboxLabel='Certificates'
                                name='certificates'
                                style={css`
                                    font-weight: 500;
                                `}
                                checked={certificatesChecked}
                                onChange={setCertificatesChecked}
                            />
                        </SingleRow>
                    )}

                    {dataToExport?.languages.length !== 0 && (
                        <SingleRow>
                            <Checkbox
                                checkboxLabel='Languages'
                                name='languages'
                                style={css`
                                    font-weight: 500;
                                `}
                                checked={languagesChecked}
                                onChange={setLanguagesChecked}
                            />
                        </SingleRow>
                    )}

                    {dataToExport?.techSkills.categories.length !== 0 && (
                        <SingleRow>
                            <Checkbox
                                checkboxLabel='Technical Skills'
                                name='technical_skills'
                                style={css`
                                    font-weight: 500;
                                `}
                                checked={technicalSkillsChecked}
                                onChange={setTechnicalSkillsChecked}
                            />
                        </SingleRow>
                    )}

                    {dataToExport?.personalStrengths && (
                        <SingleRow>
                            <Checkbox
                                checkboxLabel='Personal Strengths'
                                name='personal_strengths'
                                style={css`
                                    font-weight: 500;
                                `}
                                checked={personalStrengthsChecked}
                                onChange={setPersonalStrengthsChecked}
                            />
                        </SingleRow>
                    )}
                </Fragment>
            )}

            <Divider />
            <p
                css={css`
                    color: ${theme.colors.gray_4};
                `}
            >
                Please select the projects you want to display on your exported profile.
            </p>

            {loading ? (
                <ContentLoader />
            ) : dataToExport && dataToExport.projects.length ? (
                dataToExport.projects.map(project => (
                    <ProjectItem project={project} key={project.id} isChecked={chosenProjects.includes(project.id)} onChange={onChange} />
                ))
            ) : (
                <NoData
                    message='There are no projects added to the profile'
                    style={css`
                        width: 165px;
                        padding: 20px 0;
                        margin: 0 auto;
                        text-align: center;
                    `}
                />
            )}

            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    ${mqMax[1]} {
                        flex-direction: column-reverse;
                        justify-content: center;
                    }
                `}
            >
                <Button
                    variant='link'
                    css={css`
                        margin-right: 24px;
                        ${mqMax[1]} {
                            margin: 0 auto;
                        }
                    `}
                    onClick={onCloseModal}
                >
                    Cancel
                </Button>
                <Button
                    icon={Download}
                    onClick={handleDownload}
                    dataTestId='quick-summary-download-button'
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                    disabled={isDownloading}
                >
                    Download File
                </Button>
            </div>
        </Modal>
    )
}

export { CustomExportModal }
