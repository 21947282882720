/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { ReactElement, useCallback } from 'react'
import { Droppable, DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd'
import { Icon } from '../../components/Icon'
import { KanbanColumnProps } from './contract'
import { MemoizedInnerList } from './InnerList'

const KanbanColumn = <T,>({ column, columnIndex, cards, cardRenderer, handleColumnClick }: KanbanColumnProps<T>): ReactElement => {
    const theme = useTheme()

    const onColumnClick = useCallback(() => {
        handleColumnClick(columnIndex)
    }, [handleColumnClick, columnIndex])

    return (
        <div
            css={css`
                width: ${column.isCollapsed ? '80' : '260'}px;
                padding: 0 10px;
                ${columnIndex !== 0 ? `border-left:1px solid ${theme.colors.violet_2};` : ''}
                display:flex;
                flex-direction: column;
            `}
        >
            <div
                css={css`
                    font-weight: 500;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                `}
                onClick={onColumnClick}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        max-width: 100%;
                    `}
                >
                    <Icon
                        name='arrow-right-single'
                        size={14}
                        css={css`
                            width: 14px;
                            ${column.isCollapsed ? '' : 'transform:rotate(180deg)'}
                        `}
                    />
                    <div
                        css={css`
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        `}
                    >
                        {column.title}
                    </div>
                </div>
                {!column.isCollapsed && (
                    <div
                        css={css`
                            color: ${theme.colors.gray_4};
                            font-size: 12px;
                            padding: 4px;
                            background-color: ${theme.colors.white};
                            border-radius: 2px;
                        `}
                    >
                        {cards.length}
                    </div>
                )}
            </div>
            <Droppable droppableId={column.id}>
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        css={css`
                            flex-grow: 99999;
                            background-color: ${snapshot.isDraggingOver ? theme.colors.gray_1 : 'transparent'};
                        `}
                        data-testid={`kanban-column-${column.title}`}
                    >
                        <MemoizedInnerList<T> isCollapsed={column.isCollapsed} cards={cards} cardRenderer={cardRenderer} />
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    )
}

export { KanbanColumn }
