/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import nl2br from 'nl2br'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { usePromise } from 'react-use'
import { Note } from '../../contracts/notes/Notes'
import { mqMax } from '../../GlobalStyle'
import { addNote, deleteMyNote, editMyNote } from '../../pages/my-specialists/api'
import { COLOR_PALETTE } from '../../theme/colors'
import { useLogger } from '../../utils/useLogger'
import { FIELD_REQUIRED, MAX_LENGTH_1000 } from '../../validation/validation-messages'
import { yup } from '../../validation/yup'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { ControlledTextarea } from '../forms/ControlledTextarea'
import { IconButton } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { ContentLoader } from '../layout/Loader'
import { useNotifications } from '../notification/NotificationProvider'
import { Truncate } from '../Truncate'
import { escapeHtml, stringToLinks } from './noteHelpers'

const schema = yup.object().shape({
    content: yup.string().trim().max(1000, MAX_LENGTH_1000).required(FIELD_REQUIRED),
})

type NoteItemProps = {
    note?: Note
    adding?: boolean
    toggleOpen?: () => void
    specialistId: string
    fetchNotes?: () => void
    toggleAddNote?: () => void
}

const NoteItem: FunctionComponent<React.PropsWithChildren<NoteItemProps>> = ({
    note,
    adding,
    toggleOpen,
    specialistId,
    fetchNotes,
    toggleAddNote,
}) => {
    const [editMode, setEditMode] = useState(false)
    const [loading, setLoading] = useState(false)

    const isMounted = usePromise()

    const { addSuccess } = useNotifications()

    const log = useLogger()

    const theme = useTheme()

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: { content: note?.content },
    })

    useEffect(() => {
        if (note?.content) {
            reset({ content: note.content })
        }
    }, [note, reset])

    const content = watch('content')

    const toggleEdit = useCallback(() => {
        setEditMode(!editMode)
        if (toggleOpen) {
            toggleOpen()
        }
    }, [editMode, toggleOpen])

    const onSubmit = useCallback(
        (values: any) => {
            setLoading(true)
            if (adding) {
                isMounted(
                    addNote(specialistId, escapeHtml(values.content))
                        .then(() => addSuccess('Note added!'))
                        .catch(log)
                        .finally(() => {
                            setLoading(false)
                            setValue('content', '')
                            if (toggleAddNote) {
                                toggleAddNote()
                            }

                            if (fetchNotes) {
                                fetchNotes()
                            }
                        }),
                )
            } else {
                if (note?.id) {
                    isMounted(
                        editMyNote(specialistId, note.id, escapeHtml(values.content))
                            .then(() => addSuccess('Note edited!'))
                            .catch(log)
                            .finally(() => {
                                setLoading(false)
                                setEditMode(false)

                                if (fetchNotes) {
                                    fetchNotes()
                                }
                            }),
                    )
                }
            }
        },
        [addSuccess, adding, fetchNotes, isMounted, log, note, setValue, specialistId, toggleAddNote],
    )

    const handleDelete = useCallback(() => {
        if (note) {
            setLoading(true)
            isMounted(
                deleteMyNote(specialistId, note?.id)
                    .then(() => {
                        addSuccess('Note deleted!')
                    })
                    .catch(log)
                    .finally(() => {
                        setLoading(false)

                        if (fetchNotes) {
                            fetchNotes()
                        }
                    }),
            )
        }
    }, [addSuccess, fetchNotes, isMounted, log, note, specialistId])

    const showActionButtons = useMemo(() => !editMode && note?.editable && !adding, [adding, editMode, note])

    const htmlContent = useMemo(() => nl2br(stringToLinks(note ? note?.content : '')), [note])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div
                css={css`
                    border: 1px solid ${theme.colors.gray_2};
                    padding: 34px;
                    margin-bottom: 16px;
                    border-radius: 4px;
                    box-shadow: ${theme.shadow.small};
                `}
            >
                {loading ? (
                    <ContentLoader />
                ) : (
                    <Fragment>
                        <FlexBox
                            justifyContent='space-between'
                            css={css`
                                ${mqMax[0]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <FlexBox>
                                <strong>{adding ? 'New note' : note?.user}</strong>{' '}
                                {note?.updatedAt && (
                                    <div
                                        css={css`
                                            display: inline-block;
                                            background-color: ${COLOR_PALETTE.gray_1};
                                            font-size: 14px;
                                            line-height: 22px;
                                            border-radius: 11px;
                                            padding: 1px 8px;
                                            margin-left: 8px;
                                        `}
                                    >
                                        {moment.unix(note.updatedAt).format('DD.MM.YYYY')}
                                    </div>
                                )}
                            </FlexBox>
                            {showActionButtons && (
                                <div>
                                    <IconButton name='edit' size={32} onClick={toggleEdit} />
                                    <IconButton name='trash' size={32} onClick={handleDelete} />
                                </div>
                            )}
                        </FlexBox>
                        <Divider />
                        {editMode || adding ? (
                            <Fragment>
                                <ControlledTextarea
                                    control={control}
                                    name='content'
                                    defaultValue={note?.content}
                                    infoMessage={`${content?.length || 0}/1000`}
                                    placeholder='Type in here...'
                                    style={css`
                                        margin-bottom: 4px;
                                    `}
                                />

                                <Divider />
                                <FlexBox justifyContent='flex-end'>
                                    <Button
                                        variant='tertiary'
                                        onClick={toggleEdit}
                                        css={css`
                                            margin-right: 16px;
                                        `}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type='submit' disabled={!!errors.content || content?.trim().length === 0}>
                                        Save
                                    </Button>
                                </FlexBox>
                            </Fragment>
                        ) : (
                            <Truncate truncateHeight={64}>
                                <div dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
                            </Truncate>
                        )}
                    </Fragment>
                )}
            </div>
        </form>
    )
}

export { NoteItem }
