/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { COLOR_PALETTE } from '../../../theme/colors'
import { FormType } from '../contracts'
import { Paragraph } from './Paragraph'

type WelcomeScreenProps = {
    handleStart: () => void
    type?: FormType
}

const WelcomeScreen: FunctionComponent<React.PropsWithChildren<WelcomeScreenProps>> = ({ handleStart, type }) => (
    <section
        css={css`
            max-width: 556px;
        `}
    >
        {type === 'SKILL' && (
            <Fragment>
                <div
                    css={css`
                        color: ${COLOR_PALETTE.gray_4};
                        margin-top: -10px;
                        margin-bottom: 48px;
                        font-weight: 500;
                    `}
                >
                    Skill Form
                </div>

                <Paragraph>
                    This form will help us to learn about your skills and experience related to your role. It should take less than 15
                    minutes to complete.
                </Paragraph>
                <Paragraph>
                    After your finish this form, corresponding skills will be added to your profile. You will be able to edit your skills on
                    your profile later.
                </Paragraph>
            </Fragment>
        )}

        {type === 'STRENGTH' && (
            <Fragment>
                <div
                    css={css`
                        color: ${COLOR_PALETTE.gray_4};
                        margin-top: -10px;
                        margin-bottom: 48px;
                        font-weight: 500;
                    `}
                >
                    Strength Discovery Form
                </div>

                <Paragraph>
                    This questionnaire can identify strong sides of your thinking & behaviour style. Discover what can give you an advantage
                    in solving problems at work and how to nourish your Talent.
                </Paragraph>
                <Paragraph>
                    Consider the extent to which each statement is relevant to you and how it reflects your behaviour or preferences. Your
                    task is to state how much you agree or disagree with each of the statements.
                </Paragraph>
                <Paragraph>
                    Tips:
                    <br />
                    Think about how the statement relates to you in general and how you behave in general. There are no good or bad
                    responses to the statements - this questionnaire is not a test and you can't fail it.
                </Paragraph>

                <Paragraph>
                    Don't think too long about the response - it usually takes under 5 minutes to complete this form. Even if a question
                    does not seem entirely relevant, give the answer you consider would be most like you.
                </Paragraph>
            </Fragment>
        )}

        <Button
            css={css`
                margin-top: 20px;
                width: 146px;
                box-sizing: border-box;
                text-align: center;
            `}
            onClick={handleStart}
            dataTestId='enter-form'
        >
            Start
        </Button>
    </section>
)

export { WelcomeScreen }
