import { axios } from '../../api/axios'
import { Document } from '../../contracts/documentsResponse'
import { DocumentsResponse } from './contracts'

// DOCUMENTS
const documentsEndpoint = `/documents`

const getDocuments = (): Promise<DocumentsResponse> => {
    return axios.get(documentsEndpoint)
}

const getUserDocuments = (userId: string): Promise<Array<Document>> => {
    return axios.get(`${documentsEndpoint}/-/users/${userId}`)
}

export { getDocuments, getUserDocuments }
