/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Nullable } from '../../types'
import { CheckItem, CheckItemColors } from './CheckItem'

export const TOO_SHORT = 'Password too short'
export const TOO_LONG = 'Password too long'
export const NO_DIGIT = 'No digit'
export const NO_CAPITAL = 'No capital letter'

const ind2Color = (indicator: boolean | null) => {
    if (indicator === null) {
        return CheckItemColors.GREY
    } else if (!indicator) {
        return CheckItemColors.RED
    } else if (indicator) {
        return CheckItemColors.BLACK
    }
}

const Item = styled.li`
    margin: 8px 0;
`

type PasswordStrengthIndicators = {
    minMax: Nullable<boolean>
    oneCapital: Nullable<boolean>
    oneDigit: Nullable<boolean>
}

export type PasswordStrengthProps = {
    indicators: PasswordStrengthIndicators
    style?: SerializedStyles
}

export const PasswordStrength: FunctionComponent<React.PropsWithChildren<PasswordStrengthProps>> = ({ indicators, style }) => {
    const { minMax, oneCapital, oneDigit } = indicators
    return (
        <div
            css={css`
                margin-bottom: 24px;

                ${style}
            `}
        >
            <small>
                <strong>Password should contain:</strong>
            </small>
            <ul
                css={css`
                    margin: 0;
                    padding: 0;
                    list-style-type: none;
                `}
            >
                <Item>
                    <CheckItem color={ind2Color(minMax)}>
                        <small>Between 8-24 characters</small>
                    </CheckItem>
                </Item>
                <Item>
                    <CheckItem color={ind2Color(oneDigit)}>
                        <small>At least 1 number</small>
                    </CheckItem>
                </Item>
                <Item>
                    <CheckItem color={ind2Color(oneCapital)}>
                        <small>At least 1 capital letter</small>
                    </CheckItem>
                </Item>
            </ul>
        </div>
    )
}

const prepareIndicators = (value: boolean | null): PasswordStrengthIndicators => {
    return {
        minMax: value,
        oneCapital: value,
        oneDigit: value,
    }
}

export const extractStrengthIndicators = (fieldErrors: any, startValidation: boolean) => {
    const indicators: PasswordStrengthIndicators = prepareIndicators(startValidation ? true : null)
    if (!fieldErrors || !fieldErrors.types || !startValidation) {
        return indicators
    }
    const { types } = fieldErrors

    Object.keys(types).forEach((key: string) => {
        const msg = types[key]
        switch (msg) {
            case TOO_SHORT:
                indicators.minMax = false
                break
            case TOO_LONG:
                indicators.minMax = false
                break
            case NO_CAPITAL:
                indicators.oneCapital = false
                break
            case NO_DIGIT:
                indicators.oneDigit = false
                break
            default:
                break
        }
    })

    return indicators
}
