/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getRecentBookNowFormData, sendBookNowForm } from '../../api/api'
import { ContactForm } from '../../contracts/profile/contactForm'
import { Profile } from '../../contracts/profile/profile'
import { mqMax } from '../../GlobalStyle'
import { useSkills } from '../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { hasErrors } from '../../utils/errors'
import { INVALID_DATE_LATEST, INVALID_NUMBER } from '../../validation/validation-messages'
import { yup } from '../../validation/yup'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { ControlledAutocompleteSelect } from '../forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from '../forms/ControlledDatePickerInput'
import { ControlledInput } from '../forms/ControlledInput'
import { ControlledRadioGroup } from '../forms/ControlledRadioGroup'
import { FieldLabel } from '../inputs/input-field/FieldLabel'
import { FieldWrapper } from '../layout/FormHelpers'
import { ContentLoader } from '../layout/Loader'
import { Modal, ModalProps } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'

const schema = yup.object().shape({
    projectName: yup.string().required(),
    projectDuration: yup.number().typeError(INVALID_NUMBER).positive().required(),
    idealProjectStartDate: yup.number().required(),
    latestProjectStartDate: yup
        .number()
        .when('idealProjectStartDate', (idealProjectStartDate: number, yupSchema: any) =>
            yupSchema.min(idealProjectStartDate, INVALID_DATE_LATEST),
        )
        .required(),
    techStack: yup.array().required(),
})

type BookSpecialistModalProps = ModalProps & {
    onClose: () => void
    profile: Nullable<Profile>
    setRefetchProfile?: (val: boolean) => void
    onSuccess?: () => void
}

const BookSpecialistModal: FunctionComponent<React.PropsWithChildren<BookSpecialistModalProps>> = ({
    onClose,
    opened,
    profile,
    setRefetchProfile,
    onSuccess,
}) => {
    const skills = useSkills()
    const [loading, setLoading] = useState(false)
    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        control,
        getValues,
        setValue,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })
    const { addSuccess, addError } = useNotifications()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const sendForm = (formData: any) => {
        layoutToggleLoader(true)
        const requestBody = mapFormDataToRequest(formData)

        sendBookNowForm(requestBody)
            .then(
                () => {
                    if (setRefetchProfile) {
                        setRefetchProfile(true)
                    }

                    addSuccess('Your booking request was sent successfully. You should expect a response from the Vendor soon.')
                },
                () => {
                    addError()
                },
            )
            .finally(() => {
                reset()
                if (onSuccess) {
                    onSuccess()
                }
                onClose()
                layoutToggleLoader(false)
            })
    }

    const mapFormDataToRequest = (contactForm: ContactForm): any => {
        const requestBody: { [key: string]: any } = {}

        Object.entries(contactForm).forEach(([key, value]) => {
            if (value !== null && value !== '') {
                requestBody[key] = value
            }
        })

        requestBody.idealProjectStartDate = Number.parseInt(requestBody.idealProjectStartDate, 10)
        requestBody.latestProjectStartDate = Number.parseInt(requestBody.latestProjectStartDate, 10)
        requestBody.specialistId = profile?.specialistId

        return requestBody
    }

    useEffect(() => {
        if (opened) {
            setLoading(true)
            getRecentBookNowFormData()
                .then((formData: ContactForm | null) => {
                    if (formData) {
                        Object.entries(formData).forEach(([key, value]) => {
                            if (value !== null && value !== '') {
                                setValue(key, value, { shouldValidate: false })
                            }
                        })
                        setLoading(false)
                    } else {
                        setLoading(false)
                        reset({ projectDurationUnit: 'MONTHS', idealProjectStartDate: '', latestProjectStartDate: '', techStack: [] })
                    }
                })
                .catch(() => addError())
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [opened, setValue, reset, addError])

    return (
        <Modal onClose={onClose} opened={opened}>
            {loading ? (
                <ContentLoader height='703px' />
            ) : (
                <Fragment>
                    <h5>Booking Request</h5>
                    <GreyParagraph>
                        Please fill in all of your project info so that you can send a booking request to the Vendor of the chosen
                        Specialist. The Vendor will confirm their Specialist’s availability within three business days.
                    </GreyParagraph>

                    <form
                        onSubmit={handleSubmit(sendForm)}
                        css={css`
                            padding-bottom: 24px;
                        `}
                    >
                        <FieldWrapper>
                            <ControlledInput name='projectName' placeholder='Project Name' label='Project Name' control={control} />
                        </FieldWrapper>
                        <FieldLabel label='What is the expected project duration?' />
                        <div
                            css={css`
                                display: flex;
                                align-items: center;

                                ${mqMax[1]} {
                                    flex-direction: column;
                                    align-items: flex-start;
                                }
                            `}
                        >
                            <div>
                                <FieldWrapper noLabel>
                                    <ControlledInput
                                        name='projectDuration'
                                        placeholder='Number'
                                        type='number'
                                        style={css`
                                            margin-right: 12px;
                                            max-width: 135px;

                                            ${mqMax[1]} {
                                                max-width: unset;
                                            }
                                        `}
                                        control={control}
                                    />
                                </FieldWrapper>
                            </div>
                            <div
                                css={css`
                                    flex: 3;
                                    margin-bottom: 16px;
                                `}
                            >
                                <ControlledRadioGroup
                                    control={control}
                                    name='projectDurationUnit'
                                    dataTestId='project-duration-unit'
                                    options={[
                                        { value: 'MONTHS', label: 'Months' },
                                        { value: 'WEEKS', label: 'Weeks' },
                                        { value: 'DAYS', label: 'Days' },
                                    ]}
                                />
                            </div>
                        </div>

                        <FieldWrapper
                            css={css`
                                width: 230px;
                            `}
                        >
                            <ControlledDatePickerInput
                                control={control}
                                name='idealProjectStartDate'
                                label='What is the ideal project start date?'
                                placeholder='DD.MM.YYYY'
                            />
                        </FieldWrapper>

                        <FieldWrapper
                            css={css`
                                width: 230px;
                            `}
                        >
                            <ControlledDatePickerInput
                                control={control}
                                name='latestProjectStartDate'
                                label='What is the latest project start date?'
                                placeholder='DD.MM.YYYY'
                            />
                        </FieldWrapper>

                        <FieldWrapper>
                            <ControlledAutocompleteSelect
                                multiple
                                control={control}
                                name='techStack'
                                virtualized
                                label='What stack do you use in project?'
                                placeholder='Choose skills'
                                options={skills}
                                defaultValues={getValues('techStack') ? getValues('techStack') : []}
                                dropdownWidth='100%'
                            />
                        </FieldWrapper>

                        <Divider />

                        <ButtonContainer>
                            <Button
                                css={css`
                                    margin-right: 28px;
                                `}
                                variant='link'
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                variant='primary'
                                dataTestId='book-specialist-modal-send'
                                disabled={isSubmitting || hasErrors(errors)}
                            >
                                Send
                            </Button>
                        </ButtonContainer>
                    </form>
                </Fragment>
            )}
        </Modal>
    )
}

export { BookSpecialistModal }
