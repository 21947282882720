import { Fragment, useState } from 'react'

import { InviteToOpportunityModal } from 'src/components/profile/InviteToOpportunityModal'
import planeIcon from 'src/assets/icons/plane.svg'

import { StyledButton, StyledDivider } from './styles'

interface OwnProps {
    specialistId: string
}

const InviteToOpportunity: React.FC<OwnProps> = ({ specialistId }) => {
    const [inviteToOpportunityModal, setInviteToOpportunityModal] = useState(false)

    return (
        <Fragment>
            <StyledDivider />
            <StyledButton variant='link' icon={planeIcon} onClick={() => setInviteToOpportunityModal(true)}>
                Invite to an opportunity
            </StyledButton>
            <InviteToOpportunityModal
                onClose={() => setInviteToOpportunityModal(false)}
                opened={inviteToOpportunityModal}
                specialistId={specialistId}
            />
        </Fragment>
    )
}

export { InviteToOpportunity }
