/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useMemo } from 'react'
import { ItemsCountProps } from './TruncateList'

const TurncateListHiddenItemsCount: FunctionComponent<React.PropsWithChildren<ItemsCountProps>> = ({
    hiddenItemsCount,
    list,
    showCommaSeparator,
}) => {
    const theme = useTheme()

    const styles = useMemo(() => {
        return showCommaSeparator
            ? css`
                  position: relative;

                  &:after {
                      content: '';
                      width: 6px;
                      height: 6px;
                      background-color: ${theme.colors.white};
                      position: absolute;
                      bottom: 2px;
                      left: -20px;
                  }
              `
            : css``
    }, [showCommaSeparator, theme.colors])

    return (
        <Tooltip placement='topRight' title={[...list].splice(list.length - hiddenItemsCount, hiddenItemsCount).join(', ')}>
            <span
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 25px;
                    height: 18px;
                    border-radius: 2px;
                    margin-left: 8px;
                    font-size: 12px;
                    cursor: pointer;
                    background-color: ${theme.colors.violet_3};
                    ${styles}
                `}
            >
                +{hiddenItemsCount}
            </span>
        </Tooltip>
    )
}

export { TurncateListHiddenItemsCount }
