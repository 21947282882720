/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent } from 'react'
import { TooltipIcon } from '../../TooltipIcon'

export type FieldLabelProps = {
    label?: string
    labelTooltip?: string
    styles?: SerializedStyles
}

const FieldLabel: FunctionComponent<React.PropsWithChildren<FieldLabelProps>> = ({ label, labelTooltip, styles }) => {
    const theme = useTheme()
    return label ? (
        <label
            css={css`
                display: block;
                color: ${theme.colors.gray_4};
                margin-bottom: 4px;
                display: flex;
                align-items: center;
                ${styles}
            `}
        >
            {label}{' '}
            {labelTooltip && (
                <Tooltip title={labelTooltip}>
                    <span>
                        <TooltipIcon />
                    </span>
                </Tooltip>
            )}
        </label>
    ) : null
}

export { FieldLabel }
