import { AxiosError } from 'axios'
import { Application } from '../contracts/applications'
import { BookingDetailsForManager, ResponseData } from '../contracts/bookings/booking'
import { Company, UserSegmentationCompany } from '../contracts/company'
import { CompanyMembersResponse } from '../contracts/companyMembers'
import { PendingInvitation, UserContext, UserContextGroup } from '../contracts/contexts'
import { Country } from '../contracts/country'
import { SpecialistCv, SpecialistParsedCv, AnonymizedCVInfo } from '../contracts/cvs'
import { DictionaryEntry } from '../contracts/dictionaryEntry'
import { DocumentType } from '../contracts/documentsResponse'
import { InstantOnboardingLoadingStatus } from '../contracts/instant-onboarding/InstantOnboardingLoadingStatus'
import { PersonalStrengths } from '../contracts/personalStrengths'
import { ContactForm } from '../contracts/profile/contactForm'
import { LanguageResponse } from '../contracts/profile/language'
import { Profile } from '../contracts/profile/profile'
import { VerifiedSkill } from '../contracts/profile/skill'
import { SkillMatrix } from '../contracts/profile/skillMatrix'
import { AvailableOn } from '../contracts/search/availableOn'
import { SignDocumentsRequest } from '../contracts/signDocumentsRequest'
import { SkillForm } from '../contracts/skillForm'
import { FullExportSkills } from '../contracts/skills'
import { GetCompanyIdResponse } from '../contracts/specialist/companyId'
import { ShareMySpecialistsEditData, Specialist, SpecialistSummary } from '../contracts/specialist/specialist'
import { Education } from '../contracts/specialist/specialistEducations'
import { SpecialistGeneralInformation } from '../contracts/specialist/specialistGeneralInformation'
import { SpecialistProject } from '../contracts/specialist/specialistProject'
import { RefreshTokenResponse } from '../contracts/tenants/refreshToken'
import { CheckedTerms, TermsResponse } from '../contracts/terms'
import { UserData } from '../contracts/userData'
import { UserDataSSO } from '../contracts/userDataSSO'
import { WhiteLabel } from '../contracts/whiteLabel'
import { config } from '../environment/config'
import { Nullable } from '../types'
import { axios } from './axios'
import { Subscription } from 'src/contracts/subscription'
import { Permissions } from 'src/contracts/permissions'

// DICTIONARIES
const getCountries = (): Promise<Array<Country>> => {
    return axios.get(`/countries`)
}

const getRegions = (): Promise<Array<string>> => {
    return axios.get(`/regions`)
}

const getCities = (country: string, filter?: string): Promise<Array<string>> => {
    return axios.get(`/countries/${country}/cities?filter=${filter || ''}`)
}

const getSpecialistRoles = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/specialist-roles?filter=${filter || ''}`)
}

const getCompanyRoles = async (): Promise<Array<string>> => {
    return axios.get(`/company-roles`)
}

const getLanguages = async (): Promise<Array<string>> => {
    return axios.get(`/languages`)
}
const getIndustries = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/industries?filter=${filter || ''}`)
}

const getSkills = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/v1/skills?filter=${filter || ''}`)
}

const getExpertises = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/expertises?filter=${filter || ''}`)
}

const getResponsibilities = (filter?: string): Promise<Array<string>> => {
    return axios.get(`/responsibilities?filter=${filter || ''}`)
}

const getTimezones = (): Promise<Array<string>> => {
    return axios.get('/timezones')
}

const getCurrencies = (): Promise<Array<string>> => {
    return axios.get('/currencies')
}

const getSeniorities = (): Promise<{ seniority: Array<string> }> => {
    return axios.get('/specialists/-/seniority')
}

// USERS
const usersEndpoint = `/users`

const getUser = async (userId: string): Promise<UserData> => {
    return axios.get(`${usersEndpoint}/${userId}`)
}

const updateUser = async (userId: string, data: UserData): Promise<any> => {
    return axios.put(`${usersEndpoint}/${userId}`, data)
}

const addUserToSyndicate = async ({ email, syndicate }: { email: string; syndicate: string }): Promise<void> => {
    return axios.post(`${usersEndpoint}:addToSyndicate`, {
        email,
        syndicate,
    })
}

// COMPANIES

const companiesEndpointV4 = 'v4/companies'

const getCompanyById = (companyId: string): Promise<Company> => {
    return axios.get(`${companiesEndpointV4}/${companyId}`)
}

const getUserSegmentationCompanies = (): Promise<Array<UserSegmentationCompany>> => {
    return axios.get('v4/companies')
}

const getUserSegmentationCompanyById = (companyId: string): Promise<UserSegmentationCompany> => {
    return axios.get(`${companiesEndpointV4}/${companyId}`)
}

// DOCUMENTS

const documentsEndpoint = `/documents`

const signDocuments = (types: Array<DocumentType>, email: string): Promise<any> => {
    const requestPayload: SignDocumentsRequest = {
        documentsTypes: types,
        userEmail: email,
    }

    return axios.post(`${documentsEndpoint}:sign`, requestPayload)
}

// TERMS

const getUserTerms = (): Promise<TermsResponse> => axios.get(`/terms/user/-/versions`)

const acceptUserTerms = (checkedTerms: Array<CheckedTerms>, userMail: Nullable<string>) =>
    axios.post(`/terms/-/user/${userMail !== null ? userMail : '-'}`, {
        checkedTerms,
    })

// APPLICATIONS
const getApplications = (): Promise<Array<Application>> => {
    return axios.get('/v2/applications')
}

// TALENT MARKETPLACE

const bookingRequests = '/booking-requests'

const getBookingDetailsForManager = (bookingRequestId: string, specialistId: string): Promise<BookingDetailsForManager> => {
    return axios.get(`${bookingRequests}/${bookingRequestId}/manager/specialist/${specialistId}`)
}

const sendBookingResponse = (
    responseData: ResponseData,
    bookingRequestId: string,
    specialistId: string,
): Promise<BookingDetailsForManager> => {
    return axios.put(`${bookingRequests}/${bookingRequestId}/manager/specialist/${specialistId}:respond`, responseData)
}

const talentMarketplaceSpecialistsEndpoint = `/talentMarketplace/specialists`

const getSpecialistProfile = (specialistId: string): Promise<Profile> => {
    return axios.get(`${talentMarketplaceSpecialistsEndpoint}/${specialistId}`)
}

const getShareProfileLinkId = (specialistId: string): Promise<{ linkId: string }> => {
    return axios.post(`${talentMarketplaceSpecialistsEndpoint}/${specialistId}/links`)
}

const getShareProfileLinkIds = (specialistId: string): Promise<{ rateHiddenLinkId: string; rateVisibleLinkId: string }> => {
    return axios.post(`/v2${talentMarketplaceSpecialistsEndpoint}/${specialistId}/links`)
}

// BOOKING REQUESTS
const bookingRequestsEndpoint = '/booking-requests'

const getRecentBookNowFormData = (): Promise<ContactForm | null> => {
    return axios.get(`${bookingRequestsEndpoint}:ownLatest`)
}

const sendBookNowForm = (contactForm: ContactForm): Promise<void> => {
    return axios.post(bookingRequestsEndpoint, contactForm)
}

const bookingFeedbackEndpoint = '/booking-feedback'

const getShareMySpecialistsFeedbackNegativeResponseReasonOptions = (): Promise<Array<DictionaryEntry>> => {
    return axios.get(`${bookingFeedbackEndpoint}/manager-negotiation-fail-reasons`)
}

const getShareMySpecialistsBookingFeedbackState = (bookingId: string): Promise<string> => {
    return axios.get(`${bookingFeedbackEndpoint}/${bookingId}/manager-status`)
}

const sendInProgressShareMySpecialistsFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:managerNegotiationInProgress`, data)
}

const sendShareMySpecialistsRateExperience = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:managerRateExperience`, data)
}
const sendNegativeShareMySpecialistsFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:managerNegotiationFailed`, data)
}

const sendPositiveShareMySpecialistsFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:managerNegotiationSuccess`, data)
}

// SAVED PROFILES
const savedProfilesEndpoint = `/savedProfiles`

const addSpecialistProfileToSavedProfiles = (specialistId: string): Promise<{ specialistId: string }> => {
    return axios.post(savedProfilesEndpoint, { specialistId })
}

const removeSpecialistProfileFromSavedProfiles = (specialistId: string): Promise<void> => {
    return axios.delete(`${savedProfilesEndpoint}/${specialistId}`)
}

// SPECIALIST

const specialistsEndpoint = '/specialists'
const specialistEndpoint = '/specialist'

const getSpecialistSummary = (specialistId: string): Promise<SpecialistSummary> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/projects:summary`)
}

const getSpecialistProjects = (specialistId: string): Promise<Array<SpecialistProject>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/projects`)
}

const getSpecialist = (specialistId: string): Promise<Specialist> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}`)
}

const getSpecialistEducation = (specialistId: string): Promise<Array<Education>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/education`)
}

const getSpecialistLanguages = (specialistId: string): Promise<Array<LanguageResponse>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/languages`)
}

const getSpecialistTechSkills = (specialistId: string): Promise<SkillMatrix> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/techskills`)
}

const getSpecialistProfileLinkId = (specialistId: string): Promise<string> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}:share`)
}

const getSpecialistPsychometricSkills = (specialistId: string): Promise<PersonalStrengths> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/psychometricskills`)
}

const getSpecialistGeneralInformation = (specialistId: string): Promise<SpecialistGeneralInformation> => {
    return axios.get(`/v2${specialistsEndpoint}/${specialistId}/generalInformation`)
}

const getSpecialistTechSkillsAlchemerRated = (specialistId: string): Promise<SkillMatrix> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/techskills:filterOutAlchemerNotRated`)
}

const getSpecialistTechSkillsToExport = (
    query: 'marketplaceLinkId' | 'sharedLinkId' | 'specialistId',
    id: string,
): Promise<FullExportSkills> => {
    return axios.get(`/v2${specialistsEndpoint}/-/techskills:pdfexport?${query}=${id}`)
}

const uploadSpecialistCv = (data: FormData, specialistId: string, isFromLinkedIn = false): Promise<SpecialistCv> => {
    return axios.post(`/v1${specialistsEndpoint}/${specialistId}/cvs?isFromLinkedIn=${isFromLinkedIn}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
}

export type FullProfileData = {
    specialist: Specialist
    summary: SpecialistSummary
    projects: Array<SpecialistProject>
    education: Array<Education>
    languages: Array<LanguageResponse>
    techSkills: SkillMatrix
    link: string
    personalStrengths: PersonalStrengths | null
    generalInformation: SpecialistGeneralInformation | null
    verifiedTopSkills?: Array<VerifiedSkill>
}

const getFullProfileData = (specialistId: string, fetchGeneralInformation = false): Promise<FullProfileData> => {
    return Promise.all([
        getSpecialist(specialistId),
        getSpecialistSummary(specialistId),
        getSpecialistProjects(specialistId),
        getSpecialistEducation(specialistId),
        getSpecialistLanguages(specialistId),
        getSpecialistTechSkills(specialistId),
        getSpecialistProfileLinkId(specialistId),
        getSpecialistTopSkills(specialistId),
        getSpecialistPsychometricSkills(specialistId),
        fetchGeneralInformation ? getSpecialistGeneralInformation(specialistId) : null,
    ]).then(data => {
        const [specialist, summary, projects, education, languages, techSkills, link, topSkills, personalStrengths, generalInformation] =
            data
        return {
            specialist,
            summary,
            projects,
            education,
            languages,
            techSkills,
            link,
            verifiedTopSkills: topSkills.topSkills,
            personalStrengths,
            generalInformation,
        }
    })
}

const getSpecialistTopSkills = (specialistId: string): Promise<{ topSkills: Array<VerifiedSkill> }> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/topSkills`)
}

const getFullProfileDataToExport = (
    specialistId: string,
    fetchStrengthDiscovery = false,
    fetchGeneralInformation = false,
): Promise<FullProfileData> => {
    return Promise.all([
        getSpecialist(specialistId),
        getSpecialistSummary(specialistId),
        getSpecialistProjects(specialistId),
        getSpecialistEducation(specialistId),
        getSpecialistLanguages(specialistId),
        getSpecialistTechSkillsAlchemerRated(specialistId),
        getSpecialistProfileLinkId(specialistId),
        getSpecialistTopSkills(specialistId),
        fetchStrengthDiscovery ? getSpecialistPsychometricSkills(specialistId) : null,
        fetchGeneralInformation ? getSpecialistGeneralInformation(specialistId) : null,
    ]).then(data => {
        const [specialist, summary, projects, education, languages, techSkills, link, topSkills, personalStrengths, generalInformation] =
            data
        return {
            specialist,
            summary,
            projects,
            education,
            languages,
            techSkills,
            link,
            verifiedTopSkills: topSkills.topSkills,
            personalStrengths,
            generalInformation,
        }
    })
}

const getSpecialistData = (specialistId: string, isAnonymized = false): Promise<FullProfileData> => {
    return axios.get(`/v1/specialists/${specialistId}/exportData${isAnonymized ? '?anonymized=true' : ''}`)
}

const getSpecialistCompanyId = (email: string): Promise<GetCompanyIdResponse> => {
    return axios.get(`${specialistEndpoint}/-/companyDetails:companyId?email=${encodeURIComponent(email)}`)
}

const getSpecialistCvParsingStatus = (specialistId: string): Promise<Array<SpecialistCv>> => {
    return axios.get(`/v1${specialistsEndpoint}/${specialistId}/cvs:parsingStatus`)
}

const getSpecialistStrengthForms = (specialistId: string): Promise<Array<SkillForm>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/psychometricForms`)
}
const removeSpecialistCv = (specialistId: string, cvParsingId: string): Promise<void> => {
    return axios.delete(`/v1/${specialistsEndpoint}/${specialistId}/cvs/${cvParsingId}`)
}

const getSpecialistParsedCvs = (specialistId: string): Promise<Array<SpecialistParsedCv>> => {
    return axios.get(`/v1/${specialistsEndpoint}/${specialistId}/cvs:parsed`)
}
// SHARE MY SPECIALISTS

const marketplaceManagerEndpoint = '/marketplaceManager'

const getShareMySpecialistsSyndicates = (): Promise<Array<AvailableOn>> => {
    return axios.get(`${marketplaceManagerEndpoint}/availableOn`)
}

const editShareMySpecialistsSpecialistProfile = (
    specialistId: string,
    profileData: ShareMySpecialistsEditData,
): Promise<ShareMySpecialistsEditData> => {
    return axios.put(`/v2${marketplaceManagerEndpoint}/specialists/${specialistId}`, profileData)
}

const getTARate = (rate: number): Promise<{ tarate: number }> => axios.post(`${marketplaceManagerEndpoint}:calculateTARate`, { rate: rate })

// LOGIN

const tenantsEndpoint = `/tenants`

const signUpWithoutEmailVerification = (email: string, password: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:signUpWithoutEmailVerification`, {
        email,
        password,
        url: `${config.appUrl}/action`,
    })
}

const signUp = (email: string, password: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:signUp`, {
        email,
        password,
        url: `${config.appUrl}/action`,
    })
}

const signIn = (email: string, password: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:signIn`, {
        email,
        password,
    })
}

const sendResetPasswordEmail = (email: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:sendResetPasswordEmail`, {
        email,
        url: `${config.appUrl}/action`,
    })
}

const resetPassword = (password: string, oobCode: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:resetPassword`, {
        oobCode,
        password,
    })
}

const signInWithLink = (email: string, oobCode: string, url: Nullable<string>): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpSpecialistTenantId}:signInWithLink`, {
        oobCode,
        email,
        url,
    })
}

const resendMagicLink = (email: string, url: string, companyId?: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpSpecialistTenantId}:sendSignInLink`, {
        email,
        url,
        companyId,
    })
}

const refreshToken = (refreshToken: string, tenantId: string): Promise<RefreshTokenResponse> => {
    return axios.post(`${tenantsEndpoint}/${tenantId}:refreshToken`, {
        refreshToken,
    })
}

// SETTINGS
const settingsEndpoint = `/settings`

const getWhiteLabel = (): Promise<WhiteLabel> => {
    return axios.get(`${settingsEndpoint}:whiteLabel`)
}

const getWhiteLabelAnonymous = (anonymousProfileLinkId = ''): Promise<WhiteLabel> => {
    return axios.get(`${settingsEndpoint}:whiteLabelAnonymous?anonymousProfileLinkId=${anonymousProfileLinkId}`)
}

const getWhiteLabelSpecialistSummary = (specialistId = ''): Promise<WhiteLabel> => {
    return axios.get(`${settingsEndpoint}:whiteLabelSpecialistSummary?specialistId=${specialistId}`)
}

// UI VIEW CHANGES

const uiViewChanges = ({ toRoute, contextId }: { toRoute: string; contextId: Nullable<string> }): Promise<any> => {
    return axios.post(`/ui-view-changes`, { toRoute, contextId })
}

const InstantOnboardingEvents = 'onboarding-events'

const instantOnboardingLoadingStatus = (specialistId: string): Promise<InstantOnboardingLoadingStatus> => {
    return axios.get(`${InstantOnboardingEvents}/-/specialist/${specialistId}:latest-type`)
}

// CONTEXTS
const contextsEndpoint = '/currentUser/contexts'

const getCurrentUserContexts = (): Promise<Array<UserContext>> => {
    return axios.get(`${contextsEndpoint}`, {
        'axios-retry': {
            retryCondition: (error: AxiosError) => {
                const codes = [400, 401, 503, 504]
                return !error.response || codes.includes(error.response.status)
            },
        },
    })
}

const getCurrentUserContextGroups = (id: string): Promise<Array<UserContextGroup>> => {
    return axios.get(`${contextsEndpoint}/${id}/groups`)
}

// COMPANY ROLES

const rolesEndpoint = '/currentUser/inCompanyRoles'

const getCurrentUserCompanyRoles = (): Promise<string> => {
    return axios.get(`${rolesEndpoint}`)
}

// Multi user

const getCurrentUserPendingInvitations = (): Promise<Array<PendingInvitation>> => {
    return axios.get('/currentUser/pendingInvitations')
}

const feedbackQuestionEndpoint = `/v1/feedback`

const checkAccess = (): Promise<{ hasAccess: boolean }> => {
    return axios.get(`${feedbackQuestionEndpoint}/evaluator/me:hasAccess`)
}

const getCompanyMembers = (): Promise<CompanyMembersResponse> => {
    return axios.get('/company/my/members')
}

// cv management

export const cvsPath = '/cvsManagement'

const downloadAnonymizedCV = (id: string): Promise<File> => {
    return axios.get(`${cvsPath}/${id}:downloadAnonymizedCv`, {
        responseType: 'blob',
    })
}

const getAnonymizedCVInfo = (id: string): Promise<AnonymizedCVInfo> => {
    return axios.get(`${cvsPath}/${id}:anonymizedCvInfo`)
}

// Subscription

const getSubscriptionData = (): Promise<Subscription> => {
    return axios.get('/payment/-/subscription')
}

// Permissions

const getCompaniesPermissions = (): Promise<Permissions> => {
    return axios.get('/v1/permissions/-/companies')
}

export {
    getCountries,
    getRegions,
    getCities,
    getSpecialistRoles,
    getCompanyRoles,
    getLanguages,
    getIndustries,
    getSkills,
    getExpertises,
    getResponsibilities,
    getTimezones,
    getCurrencies,
    getSeniorities,
    updateUser,
    addUserToSyndicate,
    getUser,
    getUserTerms,
    acceptUserTerms,
    getApplications,
    signInWithLink,
    getWhiteLabel,
    getWhiteLabelAnonymous,
    getWhiteLabelSpecialistSummary,
    resendMagicLink,
    refreshToken,
    sendResetPasswordEmail,
    resetPassword,
    signUp,
    signUpWithoutEmailVerification,
    signIn,
    uiViewChanges,
    addSpecialistProfileToSavedProfiles,
    getCurrentUserContexts,
    getCurrentUserContextGroups,
    instantOnboardingLoadingStatus,
    getCompanyById,
    getCurrentUserCompanyRoles,
    getUserSegmentationCompanies,
    getUserSegmentationCompanyById,
    getFullProfileData,
    getSpecialistSummary,
    getSpecialistProjects,
    getSpecialist,
    getSpecialistEducation,
    getSpecialistLanguages,
    getSpecialistTechSkills,
    getSpecialistProfileLinkId,
    getSpecialistPsychometricSkills,
    getSpecialistGeneralInformation,
    getSpecialistCompanyId,
    getSpecialistCvParsingStatus,
    getSpecialistStrengthForms,
    removeSpecialistCv,
    getSpecialistParsedCvs,
    getFullProfileDataToExport,
    uploadSpecialistCv,
    removeSpecialistProfileFromSavedProfiles,
    getBookingDetailsForManager,
    sendBookingResponse,
    sendPositiveShareMySpecialistsFeedback,
    sendNegativeShareMySpecialistsFeedback,
    sendShareMySpecialistsRateExperience,
    sendInProgressShareMySpecialistsFeedback,
    getShareMySpecialistsBookingFeedbackState,
    getShareMySpecialistsFeedbackNegativeResponseReasonOptions,
    getRecentBookNowFormData,
    sendBookNowForm,
    getSpecialistProfile,
    getShareProfileLinkId,
    getShareProfileLinkIds,
    getShareMySpecialistsSyndicates,
    editShareMySpecialistsSpecialistProfile,
    getCurrentUserPendingInvitations,
    signDocuments,
    checkAccess,
    getSpecialistTechSkillsToExport,
    getSpecialistData,
    getCompanyMembers,
    downloadAnonymizedCV,
    getAnonymizedCVInfo,
    getSubscriptionData,
    getCompaniesPermissions,
    getTARate,
}
