import styled from '@emotion/styled'

import { Button } from 'src/components/Button'
import { mqMax } from 'src/GlobalStyle'

const ProfileNameRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${mqMax[1]} {
        flex-direction: column;
        align-items: flex-start;
    }
`

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 4px 0;

    ${mqMax[1]} {
        margin: 12px 0;
    }
`

const TitleH3 = styled.h3`
    margin: 0 16px 0 0;
`

const SendInviteButton = styled(Button)`
    float: right;
`

export { ProfileNameRow, FlexContainer, TitleH3, SendInviteButton }
