/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { DictionaryEntry } from '../../../contracts/dictionaryEntry'
import { mqMax } from '../../../GlobalStyle'
import { yup } from '../../../validation/yup'
import { Button } from '../../Button'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../../forms/ControlledAutocompleteSelect'
import { ControlledRatingStars } from '../../forms/ControlledRatingStars'
import { ControlledTextarea } from '../../forms/ControlledTextarea'
import { FieldWrapper } from '../../layout/FormHelpers'

const schema = yup.object().shape({
    reason: yup.string().required(),
    comments: yup.string().when(['reason'], {
        is: (reason: string) => reason && reason.toLocaleLowerCase().indexOf('other') !== -1,
        then: yup.string().required(),
    }),
})

type SecondStepNegativeFeedbackProps = {
    pleaseRateMessage: string
    negativeResponseReasonOptions: Array<DictionaryEntry>
    onNegativeSubmit: (formData: any) => void
}

const SecondStepNegativeFeedback: FunctionComponent<React.PropsWithChildren<SecondStepNegativeFeedbackProps>> = ({
    pleaseRateMessage,
    onNegativeSubmit,
    negativeResponseReasonOptions,
}) => {
    const { handleSubmit, control, getValues, watch, clearErrors, reset } = useForm({ resolver: yupResolver(schema) })
    const watchReason = watch('reason')
    const [clarificationOptional, setClarificationOptional] = useState<boolean>(true)

    const getNegativeResponseReasonId = (text: string): any => {
        return negativeResponseReasonOptions.find(option => option.text === text)?.id
    }

    const onSubmit = (formData: any) => {
        formData.reason = getNegativeResponseReasonId(formData?.reason)
        onNegativeSubmit(formData)
    }

    useEffect(() => {
        if (watchReason) {
            setClarificationOptional(watchReason.toLocaleLowerCase().indexOf('other') === -1)
        }

        clearErrors('clarification')
    }, [watchReason, clearErrors])

    useEffect(() => {
        reset({ rating: 0 })
    }, [reset])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3
                css={css`
                    margin: 24px 0;
                `}
            >
                We're sorry to hear that!
            </h3>

            <p
                css={css`
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 24px;
                `}
            >
                Please choose a clarification to help us understand why your were unable to come to terms:
            </p>

            <FieldWrapper noLabel>
                <ControlledAutocompleteSelect
                    canFilter={false}
                    placeholder='Choose a reason...'
                    options={useMemo(() => negativeResponseReasonOptions.map(option => option.text), [negativeResponseReasonOptions])}
                    defaultValues={[getValues('reason')]}
                    control={control}
                    name='reason'
                    dropdownWidth='100%'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>

            <FieldWrapper>
                <ControlledTextarea
                    label={clarificationOptional ? 'Written Clarification (Optional)' : 'Written Clarification'}
                    name='comments'
                    control={control}
                    placeholder='Type something'
                />
            </FieldWrapper>

            <p
                css={css`
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 24px;
                `}
            >
                {pleaseRateMessage}
            </p>

            <ControlledRatingStars control={control} name='rating' />

            <FieldWrapper
                css={css`
                    margin-top: 24px;
                `}
            >
                <ControlledTextarea
                    label='Add a Comment (Optional)'
                    name='additionalComments'
                    control={control}
                    placeholder='Type something'
                />
            </FieldWrapper>

            <Button
                variant='primary'
                type='submit'
                style={css`
                    width: 145px;

                    ${mqMax[1]} {
                        width: 100%;
                    }
                `}
            >
                Submit
            </Button>
        </form>
    )
}

export { SecondStepNegativeFeedback }
