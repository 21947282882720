/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { saveAs } from 'file-saver'
import { Fragment, FunctionComponent, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { FullProfileData } from '../../../api/api'
import { BackLink } from '../../../components/BackLink'
import { BlueActionBox } from '../../../components/BlueActionBox'
import { PageWidthWrapper } from '../../../components/layout/PageWidthWrapper'
import { Notes } from '../../../components/notes/Notes'
import { ContentWrapper, WhiteContentContainer } from '../../../components/opportunities/opportunities'
import { PersonalStrengthsChart } from '../../../components/personal-strengths-chart/PersonalStrengthsChart'
import { ExportType } from '../../../components/profile-export/types'
import { Experience } from '../../../components/profile/Experience'
import { Languages } from '../../../components/profile/Languages'
import { MoreInfo } from '../../../components/profile/MoreInfo'
import { ProfileHeader } from '../../../components/profile/ProfileHeader'
import { TechnicalSkills } from '../../../components/profile/TechnicalSkills'
import { getAppPath } from '../../../contracts/applications'
import { Note } from '../../../contracts/notes/Notes'
import { Profile } from '../../../contracts/profile/profile'
import { mqMax } from '../../../GlobalStyle'
import { useRoutesMatching } from '../../../hooks/useRoutesMatching'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { useQuery } from '../../../utils/useQuery'
import { Full } from '../../pdf/Full'
import { AnonymousExperience } from '../../my-specialists/components/AnonymousExperience'
import { getDownloadableCv, getDownloadableCvStatus, getMyCompanyNotes } from '../api'
import { CvStatus } from '../contracts'
import { getLink, unifiedProfileData, unifiedTalentMarketplaceProfileData } from './helpers'

type SpecialistProfileMainContentProps = {
    isPublic?: boolean
    isSpecialistAccount: boolean
    hasCompanyAccount?: boolean
    specialistProfile: Nullable<Profile>
    exportType: Nullable<ExportType>
    setExportType: (type: Nullable<ExportType>) => void
    isMySpecialists?: boolean
    shouldSeeNotes: boolean
    companyNotes: Array<Note>
    setCompanyNotes: (notes: Array<Note>) => void
    shareProfileLinkId?: string
    setExportProfileModalOpen: (isOpen: boolean) => void
    setAnonymousProfile: (profile: FullProfileData) => void
    setExportProfileSpecialistId: (id: string) => void
    shareProfileLinkIds?: { rateHiddenLinkId: string; rateVisibleLinkId: string }
    notesRef: Ref<HTMLDivElement>
    setIsAnonymized: (state: boolean) => void
}

const SpecialistProfileMainContent: FunctionComponent<React.PropsWithChildren<SpecialistProfileMainContentProps>> = ({
    isPublic,
    isSpecialistAccount,
    hasCompanyAccount,
    specialistProfile,
    exportType,
    setExportType,
    isMySpecialists,
    shouldSeeNotes,
    companyNotes,
    setCompanyNotes,
    shareProfileLinkId,
    setExportProfileModalOpen,
    setAnonymousProfile,
    setExportProfileSpecialistId,
    shareProfileLinkIds,
    notesRef,
    setIsAnonymized,
}) => {
    const { matchTalentMarketplaceProfile, matchPublicProfile, matchAnonymousProfile, matchMySpecialistsSpecialist, matchProfileSummary } =
        useRoutesMatching()
    const navigate = useNavigate()
    const query = useQuery()
    const log = useLogger()
    const [cvStatus, setCvStatus] = useState<Nullable<CvStatus>>(null)

    const link = useMemo(() => {
        return getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, shareProfileLinkId)
    }, [matchAnonymousProfile, matchPublicProfile, matchTalentMarketplaceProfile, shareProfileLinkId])

    const getFullProfile = useMemo(() => {
        return matchPublicProfile || matchTalentMarketplaceProfile
            ? specialistProfile && unifiedTalentMarketplaceProfileData(specialistProfile, shareProfileLinkIds?.rateHiddenLinkId || '')
            : specialistProfile && unifiedProfileData(specialistProfile, link)
    }, [link, matchPublicProfile, matchTalentMarketplaceProfile, shareProfileLinkIds, specialistProfile])

    const cleanExportProfileState = useCallback(() => {
        setExportType(null)
    }, [setExportType])

    const exportedDoc = useMemo(() => {
        let output = null

        switch (exportType) {
            case ExportType.FullExportNotAnonymized:
                output = (
                    <Full
                        specialistId={specialistProfile?.specialistId || ''}
                        forceDownload={true}
                        cleanExportProfileState={cleanExportProfileState}
                    />
                )
                break

            case ExportType.FullExportAnonymized:
                output = (
                    <Full
                        specialistId={specialistProfile?.specialistId || ''}
                        forceDownload={true}
                        isAnonymized={true}
                        cleanExportProfileState={cleanExportProfileState}
                    />
                )
                break

            case ExportType.CustomExportNotAnonymized:
            case ExportType.QuickSummaryNotAnonymized:
                setIsAnonymized(false)
                setExportProfileModalOpen(true)
                setExportProfileSpecialistId(specialistProfile?.specialistId || '')
                break

            case ExportType.QuickSummaryAnonymized:
            case ExportType.CustomExportAnonymized:
                setIsAnonymized(true)
                setExportProfileModalOpen(true)
                setExportProfileSpecialistId(specialistProfile?.specialistId || '')
                break

            case ExportType.QuickSummary:
                setExportProfileModalOpen(true)
                setExportProfileSpecialistId('')
                break

            case ExportType.FullExport:
                output = (
                    <Full
                        specialistId={specialistProfile?.specialistId || ''}
                        forceDownload={true}
                        isAnonymized={true}
                        cleanExportProfileState={cleanExportProfileState}
                        anonymousProfile={getFullProfile}
                    />
                )
        }
        return output
    }, [
        cleanExportProfileState,
        exportType,
        getFullProfile,
        setExportProfileModalOpen,
        setExportProfileSpecialistId,
        specialistProfile,
        setIsAnonymized,
    ])

    const goToCreateCompany = useCallback(() => {
        navigate('/dashboard/company-account/company-details')
    }, [navigate])

    const fetchNotes = useCallback(() => {
        if (shouldSeeNotes && specialistProfile?.specialistId) {
            getMyCompanyNotes(specialistProfile?.specialistId)
                .then(data => {
                    setCompanyNotes(data)
                })
                .catch(log)
        }
    }, [shouldSeeNotes, specialistProfile, log, setCompanyNotes])

    const fetchDownloadableCvStatus = useCallback(() => {
        if (shouldSeeNotes && specialistProfile?.specialistId) {
            getDownloadableCvStatus(specialistProfile?.specialistId)
                .then(data => {
                    setCvStatus(data.status)
                })
                .catch(log)
        }
    }, [log, shouldSeeNotes, specialistProfile])

    const downloadCv = useCallback(() => {
        if (shouldSeeNotes && specialistProfile?.specialistId) {
            getDownloadableCv(specialistProfile?.specialistId)
                .then(response => {
                    saveAs(response, `cv-${specialistProfile.specialistId}`)
                })

                .catch(log)
        }
    }, [log, shouldSeeNotes, specialistProfile])

    useEffectOnce(() => {
        fetchNotes()
    })

    useEffectOnce(() => {
        fetchDownloadableCvStatus()
    })

    useEffect(() => {
        if (specialistProfile) {
            if (matchTalentMarketplaceProfile) {
                setAnonymousProfile(unifiedTalentMarketplaceProfileData(specialistProfile, shareProfileLinkIds?.rateHiddenLinkId || ''))
            } else if (matchPublicProfile || matchAnonymousProfile) {
                setAnonymousProfile(unifiedTalentMarketplaceProfileData(specialistProfile, link))
            } else if (matchMySpecialistsSpecialist) {
                setAnonymousProfile(unifiedProfileData(specialistProfile, link))
            }
        }
    }, [
        link,
        matchAnonymousProfile,
        matchPublicProfile,
        matchMySpecialistsSpecialist,
        matchTalentMarketplaceProfile,
        setAnonymousProfile,
        shareProfileLinkIds,
        specialistProfile,
    ])

    return (
        <Fragment>
            {isSpecialistAccount ? (
                <BackLink
                    path={'/specialist-dashboard'}
                    text='Back to dashboard'
                    style={css`
                        margin-bottom: 16px;

                        ${mqMax[2]} {
                            display: none;
                        }
                    `}
                />
            ) : (
                !isPublic && (
                    <PageWidthWrapper>
                        {!hasCompanyAccount && (
                            <div
                                css={css`
                                    margin: 24px 0;
                                `}
                            >
                                <BlueActionBox
                                    text='Create Company Account to Book specialists'
                                    buttonText='Create company account'
                                    action={goToCreateCompany}
                                    styles={css`
                                        ${mqMax[2]} {
                                            margin: 16px;
                                        }
                                    `}
                                />
                            </div>
                        )}
                        <BackLink
                            path={
                                decodeURIComponent(query.get('prevPath') || '').replaceAll('#', '%23') || getAppPath('TALENT_MARKETPLACE')
                            }
                            text='Back to List'
                            style={css`
                                display: flex;
                                width: 100px;

                                ${mqMax[2]} {
                                    display: none;
                                }
                            `}
                        />
                    </PageWidthWrapper>
                )
            )}
            <ContentWrapper>
                {exportedDoc && exportedDoc}

                {specialistProfile && <ProfileHeader profile={specialistProfile} />}
            </ContentWrapper>

            <WhiteContentContainer>
                <ContentWrapper>{specialistProfile && <MoreInfo profile={specialistProfile} />}</ContentWrapper>
            </WhiteContentContainer>

            <WhiteContentContainer>
                <ContentWrapper>{specialistProfile && <TechnicalSkills profile={specialistProfile} />}</ContentWrapper>
            </WhiteContentContainer>

            <ContentWrapper>
                {specialistProfile && isMySpecialists && <AnonymousExperience profile={specialistProfile} />}
                {specialistProfile && !isMySpecialists && <Experience profile={specialistProfile} />}
            </ContentWrapper>

            <WhiteContentContainer>
                <ContentWrapper>{specialistProfile && <Languages profile={specialistProfile} />}</ContentWrapper>
            </WhiteContentContainer>

            <WhiteContentContainer>
                <ContentWrapper>
                    {specialistProfile?.personalStrengths && (
                        <Fragment>
                            <h6>Personal Strengths</h6>
                            <PersonalStrengthsChart {...specialistProfile.personalStrengths} matchProfileSummary={!!matchProfileSummary} />
                        </Fragment>
                    )}
                </ContentWrapper>
            </WhiteContentContainer>
            <WhiteContentContainer>
                <ContentWrapper>
                    {specialistProfile?.specialistId && shouldSeeNotes && (
                        <Notes
                            notes={companyNotes}
                            specialistId={specialistProfile?.specialistId}
                            fetchNotes={fetchNotes}
                            cvStatus={cvStatus}
                            downloadCv={downloadCv}
                            forwardedRef={notesRef}
                        />
                    )}
                </ContentWrapper>
            </WhiteContentContainer>
        </Fragment>
    )
}

export { SpecialistProfileMainContent }
