/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useMemo } from 'react'
import { mqMax } from '../../../GlobalStyle'
import { COLOR_PALETTE } from '../../../theme/colors'

const PropertyLabel = styled.span`
    color: ${COLOR_PALETTE.gray_4};
    margin-right: 24px;
    font-size: 14px;
    line-height: 32px;
    ${mqMax[1]} {
        width: 100%;
    }
`

const TabActionButtonWrapper = styled.section`
    display: flex;
    justify-content: flex-start;
    ${mqMax[1]} {
        justify-content: center;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
`

const InlineFieldWrapper = styled.div`
    width: 30%;
    display: flex;
    min-width: 200px;
    align-items: center;
    ${mqMax[1]} {
        width: 100%;
        align-items: initial;
        flex-direction: column;
    }
`

const PropertyValue = styled.span`
    color: ${COLOR_PALETTE.gray_6};
    font-size: 14px;
    line-height: 32px;
`

type PropertyProps = {
    label: string
    value?: string
}

const Property: FunctionComponent<React.PropsWithChildren<PropertyProps>> = ({ label, value, children }) => {
    return (
        <div>
            <PropertyLabel>{label}</PropertyLabel>
            {!children && <PropertyValue>{value || ' - '}</PropertyValue>}
            {children && <PropertyValue>{children}</PropertyValue>}
        </div>
    )
}

const ItemActionsPanel = styled.div`
    position: absolute;
    top: 16px;
    right: 24px;
`

type ItemBoxWrapperProps = {
    isPrintable?: boolean
    dataTestId?: string
    disabled?: boolean
}

const ItemBoxWrapper: FunctionComponent<React.PropsWithChildren<ItemBoxWrapperProps>> = ({
    children,
    isPrintable,
    dataTestId,
    disabled = false,
}) => {
    const theme = useTheme()

    const styles = useMemo(() => {
        return isPrintable
            ? css`
                  margin-bottom: 10px;
              `
            : css`
                  border: 1px solid ${disabled ? COLOR_PALETTE.violet_1 : COLOR_PALETTE.gray_2};
                  margin-bottom: 16px;
                  padding: 16px 24px;
                  position: relative;
                  ${disabled && `background-color: ${COLOR_PALETTE.violet_1};`};
                  box-shadow: ${theme.shadow.small};
                  border-radius: 4px;
              `
    }, [isPrintable, disabled, theme.shadow.small])

    return (
        <div
            data-testid={dataTestId}
            css={css`
                ${styles}
            `}
        >
            {children}
        </div>
    )
}

export { Property, PropertyLabel, PropertyValue, ItemActionsPanel, ItemBoxWrapper, InlineFieldWrapper, TabActionButtonWrapper }
