/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Select, Tag } from 'antd'
import { FunctionComponent, ReactNode, ReactText, useEffect, useState } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { Nullable } from '../../../types'
import { Icon } from '../../Icon'
import { FlexBox } from '../../layout/FlexBoxHelpers'
import { NoData } from '../../NoData'
import { OptionType } from '../autocomplete-select/Option'
import { InputField, InputFieldProps } from '../input-field/InputField'
import { transformOptions, transformValuesToOptions } from './utils'

export type Option = { label?: string | ReactNode; value: ReactText | number | string; ['data-testid']?: string; helperLabel?: string }

const GreyLabel = styled.span`
    color: ${COLOR_PALETTE.gray_4};
    font-size: 12px;
    margin-right: 32px;
`

const Label = styled.span`
    margin-right: auto;
`

export type AutocompleteProps = InputFieldProps & {
    defaultValue?: Array<string>
    placeholder?: string
    mode?: 'multiple' | 'tags' | null
    onChange?: (value: string[], option: any) => void
    options: Nullable<Array<Option | string>>
    isOpen?: boolean
    dropdownWidth?: string
    currentValues?: Nullable<Array<OptionType>>
    disabled?: boolean
    dataTestId?: string
    canClear?: boolean
    styles?: SerializedStyles
    name?: string
    maxTagCount?: number | 'responsive'
    maxTagTextLength?: number
    showArrow?: boolean
    value: Array<string>
    virtualized?: boolean
    autoClearSearchValue?: boolean
    allowClear?: boolean
    autoFocus?: boolean
    filterOption?: boolean | ((input: string, option: any) => boolean)
}

const Autocomplete: FunctionComponent<React.PropsWithChildren<AutocompleteProps>> = ({
    mode = 'multiple',
    placeholder = 'Please select',
    onChange,
    canClear = false,
    options = [],
    dropdownWidth = '100%',
    isOpen,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    disabled = false,
    virtualized = false,
    label,
    labelTooltip,
    dataTestId,
    name,
    maxTagCount = 'responsive',
    maxTagTextLength,
    showArrow = true,
    defaultValue,
    value,
    styles,
    autoClearSearchValue,
    autoFocus = false,
    filterOption,
}) => {
    const theme = useTheme()

    const [transformedOptions, setTransformedOptions] = useState<Array<Option>>(transformOptions(options, dataTestId))
    const [checkedValues, setCheckedValues] = useState<Array<Option>>(transformValuesToOptions(value, transformedOptions))
    const [optionsToMap, setOptionsToMap] = useState<Array<Option>>([])

    useEffect(() => {
        setCheckedValues(transformValuesToOptions(value, transformedOptions))
    }, [transformedOptions, value])

    useEffect(() => {
        setTransformedOptions(transformOptions(options, dataTestId))
    }, [dataTestId, options, value])

    useEffect(() => {
        setOptionsToMap(checkedValues.concat(transformedOptions.filter(opt => !value.includes(opt.value as string))))
    }, [checkedValues, transformedOptions, value])

    return (
        <div
            css={css`
                .ant-select-selector {
                    border: ${errorMessage ? '1px solid ' + theme.colors.red_4 : 'auto'} !important;
                }

                ${styles}
            `}
        >
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                dataTestId={dataTestId || name}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
            >
                <Select
                    mode={mode ? mode : undefined}
                    allowClear={canClear}
                    size='middle'
                    placeholder={placeholder}
                    onChange={onChange}
                    style={{ width: dropdownWidth }}
                    open={isOpen}
                    disabled={disabled}
                    menuItemSelectedIcon={<Icon name='violet-check-selected' size={28} />}
                    maxTagCount={maxTagCount}
                    maxTagTextLength={maxTagTextLength}
                    showArrow={showArrow}
                    value={value}
                    suffixIcon={<Icon name='arrow-down' />}
                    notFoundContent={<NoData />}
                    data-testid={`filter-${dataTestId}`}
                    autoFocus={autoFocus}
                    virtual={virtualized}
                    showSearch={true}
                    filterOption={filterOption}
                    optionLabelProp='label'
                    dropdownClassName='autocomplete'
                    tagRender={(props: any) => (
                        <Tag data-testid={`tag-${dataTestId}`}>{typeof props === 'string' ? props : props.label}</Tag>
                    )}
                    autoClearSearchValue={autoClearSearchValue}
                >
                    {optionsToMap.map((opt, index) => (
                        <Select.Option key={index} value={opt.value} label={opt.label} style={{ position: 'relative' }}>
                            <FlexBox
                                data-testid={`${dataTestId}-item`}
                                key={opt.value}
                                css={css`
                                    flex-wrap: wrap;
                                    white-space: normal;
                                `}
                            >
                                <Label>{opt.label}</Label>
                                {opt.helperLabel && <GreyLabel>{opt.helperLabel}</GreyLabel>}
                            </FlexBox>
                        </Select.Option>
                    ))}
                </Select>
            </InputField>
        </div>
    )
}

export { Autocomplete }
