/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback } from 'react'
import { Card } from '../../../components/Card'
import { AppTileConfig, AppTileStatus } from '../../../contracts/applications'
import { history } from '../../../history'

type ApplicationItemProps = {
    app: AppTileConfig
}

const ApplicationItem: FunctionComponent<React.PropsWithChildren<ApplicationItemProps>> = ({ app }) => {
    const theme = useTheme()
    const getAppStyle = useCallback(
        (status: AppTileStatus) => {
            switch (status) {
                case 'available':
                    return css`
                        border: 1px solid ${theme.colors.gray_2};
                        &:hover {
                            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                        }
                    `

                case 'disabled':
                    return css`
                        border: 1px solid ${theme.colors.gray_1};
                        background-color: ${theme.colors.gray_1};
                        cursor: not-allowed;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
                        transition: 0.3s;
                    `

                case 'unavailable':
                    return css`
                        border: 1px solid ${theme.colors.gray_1};
                        background-color: ${theme.colors.gray_1};
                        cursor: not-allowed;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
                        transition: 0.3s;
                    `
                default:
                    break
            }
        },
        [theme.colors.gray_1, theme.colors.gray_2],
    )

    const handleCardClick = useCallback(() => {
        if (app.status === 'available') {
            history.push(app.to)
        }
    }, [app.status, app.to])

    return (
        <Card
            height='auto'
            desktopSpan={4}
            smallSpan={18}
            xsmallSpan={24}
            iconStyles={css`
                margin-top: 16px;
            `}
            iconSize={48}
            iconUrl={app.iconUrl}
            status={app.status}
            onCardClick={handleCardClick}
            styles={getAppStyle(app.status)}
            title={
                <div
                    css={css`
                        font-size: 16px;
                        margin-top: 8px;
                        font-weight: 500;
                        text-align: center;
                    `}
                >
                    {app.title}
                </div>
            }
            dataTestId={`app-item-${app.to}`}
            footer={
                <Fragment>
                    <div
                        css={css`
                            font-size: 14px;
                            color: ${theme.colors.gray_4};
                            margin: 16px;
                            text-align: center;
                        `}
                    >
                        {app.hint && <div>{app.hint}</div>}
                        <div
                            css={css`
                                margin-top: 16px;
                            `}
                        >
                            {app.total}
                        </div>
                    </div>
                </Fragment>
            }
        />
    )
}

export default ApplicationItem
