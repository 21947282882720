/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback } from 'react'
import checkWhite from '../assets/icons/check-white.svg'
import edit from '../assets/icons/edit.svg'
import { TestStatus } from '../pages/skills-test/tests'
import { Button } from './Button'
import { Card } from './Card'
import { Icon } from './Icon'
import { TilesViewFormType } from './TilesView'

type FormTileProps = {
    form: TilesViewFormType
    onFillOutClick: (link: string, openInIframe: boolean) => void
    tileStyles?: {
        notCompletedIconStyles?: SerializedStyles
        completedIconStyles?: SerializedStyles
    }
}

const FormTile: FunctionComponent<React.PropsWithChildren<FormTileProps>> = ({ form, onFillOutClick, tileStyles }) => {
    const theme = useTheme()

    const handleCardClick = useCallback(() => {
        if (!form.isCompleted) {
            onFillOutClick(form.link, form.openInIframe)
        }
    }, [form.isCompleted, form.link, onFillOutClick, form.openInIframe])

    return (
        <Card
            iconUrl={form.isCompleted ? checkWhite : edit}
            iconBackgroundColor={form.isCompleted ? theme.colors.green_3 : theme.colors.violet_2}
            link={form.link}
            iconHoverColor={form.isCompleted ? theme.colors.green_3 : theme.colors.violet_3}
            desktopSpan={6}
            xsmallSpan={24}
            smallSpan={12}
            iconSize={48}
            onCardClick={handleCardClick}
            title={
                <div
                    css={css`
                        margin: 16px auto;
                        font-size: 16px;
                        min-height: 40px;
                        font-weight: 500;
                        text-align: center;
                    `}
                >
                    {form.title}
                </div>
            }
            footer={
                form.isCompleted ? (
                    <Button disabled={true} variant='link'>
                        Completed
                    </Button>
                ) : (
                    <Button variant='linkForm' onClick={handleCardClick}>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                            `}
                        >
                            <Fragment>
                                <span
                                    css={css`
                                        margin-top: -2px;
                                    `}
                                >
                                    {form.status
                                        ? form.status === TestStatus.IN_PROGRESS
                                            ? 'Continue the test'
                                            : 'Take the test'
                                        : 'Fill Out'}{' '}
                                </span>
                                <Icon
                                    name='arrow-right-single'
                                    size={18}
                                    style={css`
                                        margin-right: -7px;
                                    `}
                                />
                            </Fragment>
                        </div>
                    </Button>
                )
            }
            styles={css`
                border: 1px solid ${form.isCompleted ? theme.colors.green_2 : theme.colors.gray_2};
                background-color: ${form.isCompleted ? theme.colors.green_2 : 'transparent'};
                cursor: ${form.isCompleted ? 'default' : 'pointer'};
            `}
        />
    )
}

export { FormTile }
