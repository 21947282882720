/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { ChangeEvent, FunctionComponent, ReactElement, useCallback } from 'react'
import { Tooltip } from 'antd'

import { COLOR_PALETTE } from 'src/GlobalStyle'
import { Icon } from 'src/components/Icon'
import { TooltipIcon } from 'src/components/TooltipIcon'

import { InputField, InputFieldProps } from './input-field/InputField'

export type CheckboxProps = InputFieldProps & {
    checkboxLabel: string | ReactElement
    checkboxTooltip?: string
    checked: boolean
    onChange?: (checked: boolean) => void
    name: string
    style?: SerializedStyles
    disabled?: boolean
    dataTestId?: string
    tabIndex?: number
}

const Checkbox: FunctionComponent<React.PropsWithChildren<CheckboxProps>> = ({
    checked,
    onChange,
    checkboxLabel,
    checkboxTooltip,
    name = 'checkbox',
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    label,
    labelTooltip,
    style,
    disabled = false,
    dataTestId = '',
    tabIndex,
}) => {
    const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event.target.checked)
            }
        },
        [onChange],
    )

    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId || name}
        >
            <input
                type='checkbox'
                disabled={disabled}
                id={`checkbox_${name}`}
                tabIndex={tabIndex}
                name={name}
                css={css`
                    width: 0;
                    height: 0;
                    display: block;
                    opacity: 0;
                    &:checked + label > div {
                        background-color: ${disabled ? COLOR_PALETTE.gray_3 : COLOR_PALETTE.gray_6};
                        border: 1px solid ${errorMessage ? COLOR_PALETTE.red_4 : disabled ? COLOR_PALETTE.gray_3 : COLOR_PALETTE.gray_6};
                    }
                `}
                checked={checked}
                onChange={handleChange}
                data-testid={`checkbox-value-${dataTestId ? dataTestId : name}`}
            />
            <label
                tabIndex={-1}
                css={css`
                    display: flex;
                    align-items: center;
                    cursor: ${disabled ? 'not-allowed' : 'pointer'};

                    ${style}
                `}
                htmlFor={`checkbox_${name}`}
            >
                <div
                    css={css`
                        border: 1px solid ${errorMessage ? COLOR_PALETTE.red_4 : COLOR_PALETTE.gray_3};
                        flex: 0 0 16px;
                        height: 16px;
                        position: relative;
                        background-color: ${disabled ? COLOR_PALETTE.gray_1 : COLOR_PALETTE.white};
                    `}
                    id={`checkbox-${name}`}
                    data-testid={dataTestId || name}
                >
                    <Icon
                        name='check-white'
                        style={css`
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 14px;
                            height: 14px;
                            opacity: ${checked ? 1 : 0};
                        `}
                    />
                </div>
                <span
                    css={css`
                        margin-left: 8px;
                        color: ${errorMessage ? COLOR_PALETTE.red_4 : disabled ? COLOR_PALETTE.gray_4 : COLOR_PALETTE.gray_6};
                    `}
                >
                    {checkboxLabel}
                </span>
                {checkboxTooltip && (
                    <Tooltip title={checkboxTooltip}>
                        <span>
                            <TooltipIcon />
                        </span>
                    </Tooltip>
                )}
            </label>
        </InputField>
    )
}

export { Checkbox }
