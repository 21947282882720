import queryString from 'query-string'
import { countArrayFilters } from '../../utils/filters'
import {
    defaultPageSize,
    encodeField,
    parseToArray,
    parseToBoolean,
    parseToNumber,
    parseToString,
    toMonths,
} from '../../utils/searchFilters'
import {
    NonSearchQueryableFields,
    SearchFiltersResponse,
    ShowForCompany,
    SpecialistsSearchFilters,
    SpecialistsSearchRequest,
} from './contracts'

const countSelectedFilters = (filters: SpecialistsSearchFilters): number => {
    let counter = 0
    const { experience, remote, rate, availableFrom } = filters

    counter += countArrayFilters(filters, ['seniorities', 'skills', 'industries', 'languages', 'projects', 'locations', 'currencies'])

    if (experience && (experience.gte || experience.lte)) {
        counter++
    }

    if (remote) {
        counter++
    }

    if (rate) {
        counter++
    }

    if (availableFrom) {
        counter++
    }

    return counter
}

const stringifySearchRequestToQuery = (searchRequest: SpecialistsSearchRequest): string => {
    const params = []
    const {
        searchText,
        seniorities,
        skills,
        industries,
        languages,
        experience,
        rate,
        locations,
        remote,
        paging,
        availableFrom,
        availableOn,
        showForCompany,
        currencies,
    } = searchRequest

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (seniorities && seniorities.length > 0) {
        params.push(`seniorities=${encodeField(seniorities)}`)
    }

    if (skills && skills.length > 0) {
        params.push(`skills=${encodeField(skills)}`)
    }

    if (industries && industries.length > 0) {
        params.push(`industries=${encodeField(industries)}`)
    }

    if (languages && languages.length > 0) {
        params.push(`languages=${encodeField(languages)}`)
    }

    if (experience && experience.gte) {
        params.push(`experienceMin=${experience.gte}`)
    }
    if (experience && experience.lte) {
        params.push(`experienceMax=${experience.lte}`)
    }

    if (rate && rate.gte) {
        params.push(`rateMin=${rate.gte}`)
    }
    if (rate && rate.lte) {
        params.push(`rateMax=${rate.lte}`)
    }

    if (availableFrom) {
        params.push(`availableFrom=${availableFrom}`)
    }

    if (locations && locations.length > 0) {
        if (locations[0].country) {
            params.push(`locationsCountry=${encodeField(locations[0].country)}`)
            if (locations[0].cities.length > 0) {
                params.push(`locationsCities=${encodeField(locations[0].cities.join(','))}`)
            }
        }
    }

    if (availableOn && availableOn.length > 0) {
        params.push(`availableOn=${encodeField(availableOn)}`)
    }

    if (showForCompany) {
        params.push(`showForCompany=${showForCompany}`)
    }

    if (remote) {
        params.push(`remote=1`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (currencies && currencies.length > 0) {
        params.push(`currency=${encodeField(currencies[0])}`)
    }

    return params.join('&')
}

const parseQueryToSearchRequest = (searchQuery: string, additional: NonSearchQueryableFields): SpecialistsSearchRequest => {
    const params = queryString.parse(searchQuery, { decode: false })
    const {
        searchText,
        seniorities,
        skills,
        industries,
        languages,
        experienceMin,
        experienceMax,
        rateMin,
        rateMax,
        availableFrom,
        locationsCountry,
        locationsCities,
        remote,
        page,
        availableOn,
        showForCompany,
        size,
        currency,
    } = params

    const result: SpecialistsSearchRequest = {
        searchText: parseToString(searchText),
        seniorities: parseToArray(seniorities),
        skills: parseToArray(skills),
        industries: parseToArray(industries),
        languages: parseToArray(languages),
        availableOn: parseToArray(availableOn),
        remote: parseToBoolean(remote),
        showForCompany: (showForCompany || ShowForCompany.ALL) as ShowForCompany,
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, defaultPageSize),
        },
        currencies: parseToArray(currency),
    }

    if (experienceMin || experienceMax) {
        result.experience = {
            gte: experienceMin ? parseToNumber(experienceMin) : 0,
            lte: experienceMax ? parseToNumber(experienceMax) : toMonths(40),
        }
    }

    if (rateMin || rateMax) {
        result.rate = {
            gte: rateMin ? parseToNumber(rateMin) : 0,
            lte: rateMax ? parseToNumber(rateMax) : 250,
        }
    }

    if (availableFrom) {
        result.availableFrom = parseToNumber(availableFrom)
    }

    if (locationsCountry) {
        result.locations = [
            {
                country: parseToString(locationsCountry),
                cities: [],
            },
        ]
        if (locationsCities) {
            result.locations[0].cities = parseToArray(locationsCities)
        }
    }

    if (additional && additional.savedProfile) {
        result.savedProfile = additional.savedProfile
    }

    return result
}

const sanitizePossibleFilters = (possibleFilters: SearchFiltersResponse) => possibleFilters

export { countSelectedFilters, stringifySearchRequestToQuery, sanitizePossibleFilters, parseQueryToSearchRequest }
