/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Modal } from '../../../../components/Modal'

type CancelInvitationModalProps = {
    handleClose: () => void
    handleCancellation: () => void
    isVisible: boolean
}

const CancelInvitationModal: FunctionComponent<React.PropsWithChildren<CancelInvitationModalProps>> = ({
    handleClose,
    handleCancellation,
    isVisible,
}) => (
    <Modal onClose={handleClose} opened={isVisible}>
        <h5>Cancel Request</h5>
        <p>
            By cancelling your request for a Specialist to input data into their profile, their e-mail address will be temporarily blocked
            in our system and they will be unable to input any further data.
        </p>
        <Divider />
        <div
            css={css`
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
            `}
        >
            <Button
                variant='link'
                onClick={handleClose}
                style={css`
                    margin-right: 20px;
                `}
            >
                Go back
            </Button>
            <Button
                css={css`
                    margin-right: 8px;
                `}
                onClick={handleCancellation}
            >
                Continue
            </Button>
        </div>
    </Modal>
)

export { CancelInvitationModal }
