import queryString from 'query-string'
import { Paging } from '../../../contracts/common/pagingProps'
import { Nullable } from '../../../types'
import { countArrayFilters } from '../../../utils/filters'
import { encodeField, parseToArray, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { ApplicationStatusEnum } from '../../../contracts/opportunities-manager/contracts'
import { ApplicationsParams } from '../../../contracts/open-opportunities/contracts'
import { DEFAULT_ITEMS_PER_PAGE } from './openOpportunitiesUtils'

type ApplicationsFilters = {
    appliedOn: Nullable<number>
    cities: Array<string>
    countries: Array<string>
    roles: Array<string>
    statuses: Array<ApplicationStatusEnum>
}

const countFilters = (selectedFilters: Omit<ApplicationsParams, 'paging' | 'searchText' | 'sorting'>): number => {
    let counter = 0

    counter += countArrayFilters(selectedFilters, ['cities', 'countries', 'roles', 'statuses'])
    ;['appliedOn'].forEach(filterName => {
        if (selectedFilters[filterName as keyof ApplicationsFilters]) {
            counter++
        }
    })
    return counter
}

const stringifyApplicationsSearchRequestToQuery = (searchRequest: ApplicationsParams): string => {
    const params = []
    const { appliedOn, cities, countries, paging, roles, searchText, statuses } = searchRequest

    if (appliedOn) {
        params.push(`appliedOn=${appliedOn}`)
    }

    if (cities && cities.length > 0) {
        params.push(`cities=${encodeField(cities)}`)
    }

    if (countries && countries.length > 0) {
        params.push(`countries=${encodeField(countries)}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (roles && roles.length > 0) {
        params.push(`roles=${encodeField(roles)}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (statuses && statuses.length > 0) {
        params.push(`statuses=${statuses.join(',')}`)
    }

    return params.join('&')
}

const parseQueryToApplicationsSearchRequest = (searchQuery: string): ApplicationsParams & ApplicationsFilters & Paging => {
    const params = queryString.parse(searchQuery, { decode: false })
    const { appliedOn, cities, countries, page, roles, searchText, size, statuses, submittedBy } = params

    const result = {
        appliedOn: appliedOn ? parseToNumber(appliedOn) : null,
        cities: parseToArray(cities),
        countries: parseToArray(countries),
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, DEFAULT_ITEMS_PER_PAGE),
        },
        roles: parseToArray(roles),
        searchText: parseToString(searchText),
        statuses: parseToArray(statuses) as Array<ApplicationStatusEnum>,
        sorting: [],
        submittedBy: parseToArray(submittedBy),
    }

    return result
}

export { countFilters, parseQueryToApplicationsSearchRequest, stringifyApplicationsSearchRequestToQuery }
