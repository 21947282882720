import { htmlCharactersRegex } from './regexes'

const randomString = (length = 5) => {
    return Math.random().toString(36).slice(-length)
}

const capitalizeFirstLetter = (string: string): string => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const capitalizeFirstLetters = (s: string): string => {
    return s
        .split(' ')
        .map(str => capitalizeFirstLetter(str))
        .join(' ')
}

const replaceUnderscoresWithSpaces = (s: string): string => {
    return s.replace(/_/g, ' ')
}

const cleanStringFromHTMLTags = (text: string): string => {
    return text.replace(htmlCharactersRegex, '')
}

export { randomString, capitalizeFirstLetter, capitalizeFirstLetters, replaceUnderscoresWithSpaces, cleanStringFromHTMLTags }
