/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../theme/colors'
import { Dot } from './Dot'

type TagBoxProps = {
    style?: SerializedStyles
    itemStyle?: SerializedStyles
    tags: Array<string>
}

const TagBox: FunctionComponent<React.PropsWithChildren<TagBoxProps>> = ({ tags, itemStyle, style }) => (
    <div
        css={css`
            border-radius: 2px;
            border: 1px solid ${COLOR_PALETTE.violet_1};
            background-color: ${COLOR_PALETTE.violet_1};
            padding: 16px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            line-height: 26px;
            ${style}
        `}
    >
        {tags.map((tag, index, array) => (
            <Fragment key={tag}>
                <div
                    css={css`
                        color: ${COLOR_PALETTE.violet_6};
                        padding: 4px 8px;
                        ${itemStyle}
                    `}
                >
                    {tag}
                </div>
                {index < array.length - 1 && (
                    <Dot
                        style={css`
                            color: ${COLOR_PALETTE.gray_3};
                        `}
                    />
                )}
            </Fragment>
        ))}
    </div>
)

export { TagBox }
