/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent } from 'react'
import { TooltipIcon } from '../../TooltipIcon'

const infoTextStyles = css`
    font-size: 12px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

export type FieldInfoProps = {
    errorMessage?: string
    errorTooltipMessage?: string
    infoMessage?: string
    dataTestId?: string
    className?: string
}

const FieldInfo: FunctionComponent<React.PropsWithChildren<FieldInfoProps>> = ({
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    dataTestId,
    className,
}) => {
    const theme = useTheme()
    return errorMessage ? (
        <p
            css={css`
                ${infoTextStyles}
                color: ${theme.colors.red_4};
            `}
            data-testid={`${dataTestId}-error`}
            className={className}
        >
            {errorMessage}
            {errorTooltipMessage && (
                <Tooltip title={errorTooltipMessage} color={theme.colors.red_4} placement='bottomLeft'>
                    <span>
                        <TooltipIcon
                            styles={css`
                                background-color: ${theme.colors.red_4};
                                color: ${theme.colors.white};
                            `}
                        />
                    </span>
                </Tooltip>
            )}
        </p>
    ) : infoMessage ? (
        <p
            css={css`
                ${infoTextStyles}
                color: ${theme.colors.gray_4};
            `}
            data-testid={`${dataTestId}-info`}
            className={className}
        >
            {infoMessage}
        </p>
    ) : null
}

export { FieldInfo }
