/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useMemo } from 'react'
import { SearchLocation } from '../../contracts/common/searchLocation'
import { generateSubTestId } from '../../utils/testid'
import { FilterColumn } from '../filters/FiltersBarHelpers'
import { AutocompleteSelect } from './autocomplete-select/AutocompleteSelect'
import { Checkbox } from './Checkbox'

export type LocationsPickerProps = {
    locations: Array<SearchLocation>
    selectedLocations: Array<SearchLocation>
    selectedRemote: boolean
    onChangeLocations: (locations: Array<SearchLocation>) => void
    onChangeRemote: (remote: boolean) => void
    highlightActive?: boolean
    dataTestId?: string
}

const LocationsPicker: FunctionComponent<React.PropsWithChildren<LocationsPickerProps>> = ({
    locations,
    selectedLocations,
    selectedRemote,
    onChangeLocations,
    onChangeRemote,
    highlightActive,
    dataTestId,
}) => {
    const possibleCountries = useMemo(() => {
        return locations ? locations.map(location => location.country) : []
    }, [locations])

    const selectedCountries = useMemo(() => {
        return selectedLocations ? selectedLocations.map(selected => selected.country) : []
    }, [selectedLocations])

    const possibleCities = useMemo(() => {
        const filteredLocations = locations.filter(location => selectedCountries.includes(location.country))
        return filteredLocations.reduce((acc: Array<string>, { cities }) => acc.concat(cities), [])
    }, [locations, selectedCountries])

    const selectedCities = useMemo(() => {
        return selectedLocations.reduce((acc: Array<string>, { cities }) => acc.concat(cities), [])
    }, [selectedLocations])

    const onCountriesChange = useCallback(
        (newCountries: any) => {
            const newSelectedLocations: Array<SearchLocation> = []
            newCountries.forEach((newCountry: string) => {
                const foundSelectedLocation = selectedLocations.find(l => l.country === newCountry)
                if (foundSelectedLocation) {
                    newSelectedLocations.push(foundSelectedLocation) // preserve selected cities
                } else {
                    newSelectedLocations.push({ country: newCountry, cities: [] }) // new cities are empty by default
                }
            })
            onChangeLocations(newSelectedLocations)
        },
        [onChangeLocations, selectedLocations],
    )

    const onCitiesChange = useCallback(
        (newCities: any) => {
            const newSelectedLocations: Array<SearchLocation> = []

            if (newCities.length > 0) {
                newCities.forEach((newCity: string) => {
                    // find location it belongs to
                    const foundLocation = locations.find(l => l.cities.includes(newCity))
                    if (foundLocation) {
                        const newLocationsIndex = newSelectedLocations.findIndex(l => l.country === foundLocation.country)
                        if (newLocationsIndex > -1) {
                            // already in new locations, add to cities array
                            newSelectedLocations[newLocationsIndex].cities.push(newCity)
                        } else {
                            // push new location object
                            newSelectedLocations.push({
                                country: foundLocation.country,
                                cities: [newCity],
                            })
                        }
                    }
                })
            } else {
                selectedLocations.forEach(location => {
                    newSelectedLocations.push({ country: location.country, cities: [] })
                })
            }

            onChangeLocations(newSelectedLocations)
        },
        [onChangeLocations, locations, selectedLocations],
    )

    return (
        <Fragment>
            <FilterColumn>
                <AutocompleteSelect
                    multiple
                    placeholder='Country'
                    options={possibleCountries}
                    currentValues={selectedCountries}
                    isActive={highlightActive && selectedCountries.length > 0}
                    onSelectedValuesChange={onCountriesChange}
                    dataTestId={generateSubTestId(dataTestId, 'country')}
                />
            </FilterColumn>
            <FilterColumn>
                <AutocompleteSelect
                    multiple
                    placeholder='City'
                    options={possibleCities}
                    currentValues={selectedCities}
                    isActive={highlightActive && selectedCities.length > 0}
                    onSelectedValuesChange={onCitiesChange}
                    dataTestId={generateSubTestId(dataTestId, 'city')}
                />
            </FilterColumn>
            <FilterColumn>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        height: 40px;
                    `}
                >
                    <Checkbox
                        onChange={onChangeRemote}
                        name='remote'
                        checked={selectedRemote}
                        checkboxLabel='Remote'
                        style={css`
                            min-width: 70px;
                        `}
                    />
                </div>
            </FilterColumn>
        </Fragment>
    )
}

export { LocationsPicker }
