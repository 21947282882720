/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent, useState } from 'react'
import { DictionaryEntry } from '../../contracts/dictionaryEntry'
import { FeedbackFirstStep } from './FeedbackFirstStep'
import { FeedbackSecondStep, SecondStepType } from './FeedbackSecondStep'

type FeedbackWizardProps = {
    backPath: string
    pleaseRateMessage: string
    firstStepBackText: string
    onSecondStepPositiveResponseSubmit: (formData: any) => void
    secondStepInProgressResponseButtonText: string
    secondStepInProgressSubmit: () => void
    onSecondStepNegativeResponseSubmit: (formData: any) => void
    secondStepNegativeReasonOptions: Array<DictionaryEntry>
}

const NewFeedbackForm: FunctionComponent<React.PropsWithChildren<FeedbackWizardProps>> = ({
    backPath,
    pleaseRateMessage,
    firstStepBackText,
    onSecondStepPositiveResponseSubmit,
    secondStepInProgressResponseButtonText,
    secondStepInProgressSubmit,
    onSecondStepNegativeResponseSubmit,
    secondStepNegativeReasonOptions,
}) => {
    const [step, setStep] = useState<number>(1)
    const [secondStepType, setSecondStepType] = useState<SecondStepType>(SecondStepType.YES)

    const onFirstStepNext = (formData: any) => {
        switch (formData.response) {
            case 'YES':
                setSecondStepType(SecondStepType.YES)
                break
            case 'IN_PROGRESS':
                setSecondStepType(SecondStepType.IN_PROGRESS)
                break
            case 'NO':
                setSecondStepType(SecondStepType.NO)
                break
        }

        setStep(2)
    }

    const onSecondStepBack = () => {
        setStep(1)
    }

    return (
        <Fragment>
            {step === 1 && <FeedbackFirstStep backPath={backPath} backButtonText={firstStepBackText} onNext={onFirstStepNext} />}
            {step === 2 && (
                <FeedbackSecondStep
                    type={secondStepType}
                    onBack={onSecondStepBack}
                    pleaseRateMessage={pleaseRateMessage}
                    onPositiveSubmit={onSecondStepPositiveResponseSubmit}
                    inProgressResponseButtonText={secondStepInProgressResponseButtonText}
                    inProgressResponseButtonPath={backPath}
                    inProgressSubmit={secondStepInProgressSubmit}
                    negativeResponseReasonOptions={secondStepNegativeReasonOptions}
                    onNegativeSubmit={onSecondStepNegativeResponseSubmit}
                />
            )}
        </Fragment>
    )
}

export { NewFeedbackForm }
