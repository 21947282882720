/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent, ReactElement } from 'react'
import { Icon, IconName } from './Icon'
import { COLOR_PALETTE } from '../theme/colors'

export type DataNotFoundProps = {
    title?: string
    description?: string | ReactElement
    iconName?: IconName
    styles?: SerializedStyles
    iconSize?: number
}

const DataNotFound: FunctionComponent<React.PropsWithChildren<DataNotFoundProps>> = ({
    title,
    description = '',
    iconName,
    children,
    iconSize = 34,
    styles = {},
    ...rest
}) => {
    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                align-items: center;
                ${styles}
            `}
            data-testid='no-data-view'
            {...rest}
        >
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                `}
            >
                {iconName && (
                    <Icon
                        name={iconName}
                        size={iconSize}
                        css={css`
                            margin-bottom: 24px;
                        `}
                    />
                )}
                {title && (
                    <h5
                        css={css`
                            max-width: 200px;
                            text-align: center;
                        `}
                    >
                        {title}
                    </h5>
                )}
                <p
                    css={css`
                        color: ${COLOR_PALETTE.gray_3};
                        text-align: center;
                        margin: 0 auto 22px;
                        max-width: 440px;
                    `}
                >
                    {description}
                </p>
                {children}
            </div>
        </div>
    )
}

export { DataNotFound }
