/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { mqMax } from 'src/GlobalStyle'
import { useCountries } from 'src/redux/dictionaryDataHooks'
import { ShareMySpecialistsFormData } from '../../contracts'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { CellWrapper, InputWrapper } from '../../utils'
interface RateProps {
    record: ShareMySpecialistsSpecialist
}

interface RateEditProps extends RateProps {
    index: number
    control: Control<ShareMySpecialistsFormData>
}

const Country: FunctionComponent<React.PropsWithChildren<RateProps>> = ({ record }) => (
    <CellWrapper
        css={css`
            width: 100%;
        `}
    >
        {record.country || '-'}
    </CellWrapper>
)

const CountryEdit: FunctionComponent<React.PropsWithChildren<RateEditProps>> = ({ record, index, control }) => {
    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])

    return (
        <InputWrapper
            css={css`
                width: 60px;
                ${mqMax[2]} {
                    width: 100%;
                }
            `}
        >
            <ControlledAutocompleteSelect
                placeholder='Country'
                options={memorizedCountries}
                defaultValue={record.country ? [record.country] : []}
                control={control}
                name={`specialists.${index}.country`}
                dataTestId='company-details-form-country'
                canClear
                dropdownWidth='100%'
                valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                styles={css`
                    min-width: 123px;
                `}
            />
        </InputWrapper>
    )
}

export { Country, CountryEdit }
