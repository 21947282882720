/** @jsxImportSource @emotion/react */

import { FunctionComponent, memo } from 'react'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { HideFrom, ShowFrom } from '../../../../components/layout/ResponsiveHelpers'
import { Detail, DetailRow, DurationDetails } from '../../../../components/specialist/DetailHelpers'
import { Education, EducationStatus, EducationType, mapEducationType } from '../../../../contracts/specialist/specialistEducations'
import { SectionHeaderWithActions } from '../../shared/HeaderWithActions'
import { ItemBoxWrapper } from '../../shared/ProfileTabsHelpers'

const studiesEducationTypes = [
    EducationType.BACHELOR_STUDIES,
    EducationType.MASTER_STUDIES,
    EducationType.PHD_STUDIES,
    EducationType.OTHER_STUDIES,
]
const educationTypes = [...studiesEducationTypes, EducationType.LANGUAGE_COURSE, EducationType.CERTIFICATE_COURSE]

export type EducationEntryProps = Education & {
    isPrintable?: boolean
    showEducationDuration?: boolean
}

const StudiesEntry: FunctionComponent<React.PropsWithChildren<EducationEntryProps>> = ({
    type,
    from,
    to,
    inProgress,
    studiesDepartment,
    studiesSpecialization,
    studiesUniversity,
    children,
    isPrintable = false,
    status,
    showEducationDuration,
}) => {
    const Duration = memo(() => (
        <DurationDetails
            wide
            from={from}
            to={to}
            isActual={inProgress}
            isPrintable={isPrintable}
            disabled={status === EducationStatus.DELETED}
        />
    ))
    return (
        <ItemBoxWrapper dataTestId='education-box' isPrintable={isPrintable} disabled={status === EducationStatus.DELETED}>
            <SectionHeaderWithActions
                header={mapEducationType(type)}
                subheader
                divider={!isPrintable}
                isPrintable={isPrintable}
                disabled={status === EducationStatus.DELETED}
            >
                {showEducationDuration && (
                    <FlexBox alignItems='center'>
                        <ShowFrom breakpoint={1}>
                            <FlexBox>
                                <Duration />
                            </FlexBox>
                        </ShowFrom>
                        {children}
                    </FlexBox>
                )}
            </SectionHeaderWithActions>
            {showEducationDuration && (
                <HideFrom breakpoint={1}>
                    <DetailRow>
                        <Duration />
                    </DetailRow>
                </HideFrom>
            )}

            <Detail
                isPrintable={isPrintable}
                wide
                label='Degree / Specialization:'
                value={studiesSpecialization}
                disabled={status === EducationStatus.DELETED}
            />
            <Detail
                isPrintable={isPrintable}
                wide
                label='Department:'
                value={studiesDepartment}
                disabled={status === EducationStatus.DELETED}
            />
            <Detail
                isPrintable={isPrintable}
                wide
                label='University:'
                value={studiesUniversity}
                disabled={status === EducationStatus.DELETED}
            />
        </ItemBoxWrapper>
    )
}

const LanguageEntry: FunctionComponent<React.PropsWithChildren<EducationEntryProps>> = ({
    from,
    to,
    inProgress,
    languageName,
    languageSchool,
    children,
    isPrintable = false,
    showEducationDuration,
}) => {
    const Duration = memo(() => <DurationDetails wide from={from} to={to} isActual={inProgress} isPrintable={isPrintable} />)

    return (
        <ItemBoxWrapper dataTestId='education-box' isPrintable={isPrintable}>
            <SectionHeaderWithActions header='Language Course' subheader divider={!isPrintable} isPrintable={isPrintable}>
                {showEducationDuration && (
                    <FlexBox alignItems='center'>
                        <ShowFrom breakpoint={1}>
                            <FlexBox>
                                <Duration />
                            </FlexBox>
                        </ShowFrom>
                        {children}
                    </FlexBox>
                )}
            </SectionHeaderWithActions>
            {showEducationDuration && (
                <HideFrom breakpoint={1}>
                    <DetailRow>
                        <Duration />
                    </DetailRow>
                </HideFrom>
            )}

            <Detail isPrintable={isPrintable} wide label='Language Name:' value={languageName} />
            <Detail isPrintable={isPrintable} wide label='Language School:' value={languageSchool} />
        </ItemBoxWrapper>
    )
}

const CertificateEntry: FunctionComponent<React.PropsWithChildren<EducationEntryProps>> = ({
    certificationAcquisitionDate,
    certificationExpirationDate,
    certificationHasExpiration,
    certificationTitle,
    certificationOrganization,
    children,
    isPrintable = false,
    showEducationDuration,
}) => {
    const Duration = memo(() => (
        <DurationDetails
            wide
            from={certificationAcquisitionDate}
            to={certificationExpirationDate}
            isActual={!certificationHasExpiration}
            label='Validity:'
            actualText='No expiration date'
            isPrintable={isPrintable}
        />
    ))

    return (
        <ItemBoxWrapper dataTestId='education-box' isPrintable={isPrintable}>
            <SectionHeaderWithActions header='Certificate Course' subheader divider={!isPrintable} isPrintable={isPrintable}>
                {showEducationDuration && (
                    <FlexBox alignItems='center'>
                        <ShowFrom breakpoint={1}>
                            <FlexBox>
                                <Duration />
                            </FlexBox>
                        </ShowFrom>
                        {children}
                    </FlexBox>
                )}
            </SectionHeaderWithActions>
            {showEducationDuration && (
                <HideFrom breakpoint={1}>
                    <DetailRow>
                        <Duration />
                    </DetailRow>
                </HideFrom>
            )}

            <Detail isPrintable={isPrintable} wide label='Certification / Title:' value={certificationTitle} />
            <Detail isPrintable={isPrintable} wide label='Certifying Organization:' value={certificationOrganization} />
        </ItemBoxWrapper>
    )
}

export { StudiesEntry, CertificateEntry, LanguageEntry, studiesEducationTypes, educationTypes }
