export enum ExportType {
    FullExportAnonymized = 'full-export-anonymized',
    FullExportNotAnonymized = 'full-export-not-anonymized',
    QuickSummaryAnonymized = 'quick-summary-anonymized',
    QuickSummaryNotAnonymized = 'quick-summary-not-anonymized',
    QuickSummary = 'quick-summary',
    FullExport = 'full-export',
    CustomExportAnonymized = 'custom-export-anonymized',
    CustomExportNotAnonymized = 'custom-export-not-anonymized',
}
