/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useMemo } from 'react'
import TruncatedList from 'react-truncate-list'

export type ItemsCountProps = {
    hiddenItemsCount: number
    list: Array<string>
    showCommaSeparator?: boolean
}

type TruncateListProps = {
    list: Array<string>
    ListItem?: FunctionComponent<React.PropsWithChildren<unknown>>
    ItemsCount?: FunctionComponent<React.PropsWithChildren<ItemsCountProps>>
    showCommaSeparator?: boolean
}

type ItemProps = {
    isLast?: boolean
}

const DefaultItem: FunctionComponent<React.PropsWithChildren<ItemProps>> = ({ children, isLast = false }) => {
    return <div className={`default-list-item ${isLast ? 'last-item' : ''}`}>{children}</div>
}

const DefaultItemsCount: FunctionComponent<React.PropsWithChildren<ItemsCountProps>> = ({ hiddenItemsCount, list, showCommaSeparator }) => {
    const theme = useTheme()
    const styles = useMemo(() => {
        return showCommaSeparator
            ? css`
                  position: relative;

                  &:after {
                      content: '';
                      width: 6px;
                      height: 6px;
                      background-color: ${theme.colors.white};
                      position: absolute;
                      bottom: 2px;
                      left: -12px;
                  }
              `
            : css``
    }, [showCommaSeparator, theme.colors])

    return (
        <Tooltip placement='topRight' title={[...list].splice(list.length - hiddenItemsCount, hiddenItemsCount).join(', ')}>
            <div css={styles}>+{hiddenItemsCount}</div>
        </Tooltip>
    )
}

const TruncateList: FunctionComponent<React.PropsWithChildren<TruncateListProps>> = ({
    list,
    ListItem = DefaultItem,
    ItemsCount = DefaultItemsCount,
    showCommaSeparator = false,
}) => {
    return (
        <section
            css={css`
                width: 100%;

                .react-truncate-list {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                }

                .list {
                    position: relative;
                    display: flex;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                    align-items: center;
                    width: 100%;

                    .default-list-item {
                        white-space: nowrap;

                        &:not(.last-item) {
                            &:after {
                                content: '${`${showCommaSeparator ? ', ' : ''}`}';
                            }
                        }
                    }

                    > * {
                        margin: 4px;
                    }
                }
            `}
        >
            <TruncatedList
                className='list resizable'
                renderTruncator={({ hiddenItemsCount }) => (
                    <ItemsCount hiddenItemsCount={hiddenItemsCount} list={list} showCommaSeparator={showCommaSeparator} />
                )}
            >
                {list.map((item, index) => (
                    <ListItem key={index} isLast={list.length - 1 === index}>
                        {item}
                    </ListItem>
                ))}
            </TruncatedList>
        </section>
    )
}

export { TruncateList }
