/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { getCities } from '../../../../api/api'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { ControlledAutocomplete } from '../../../../components/forms/ControlledAutocomplete'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../../components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from '../../../../components/forms/ControlledInput'
import { ControlledSuggestionInput } from '../../../../components/forms/ControlledSuggestionInput'
import { ControlledTextarea } from '../../../../components/forms/ControlledTextarea'
import { FieldWrapper } from '../../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../../components/layout/ResponsiveColumns'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { CustomFieldValue } from '../../../../contracts/customFields'
import { mapEmploymentToType, mapEmploymentType } from '../../../../contracts/specialist/specialist'
import { SpecialistGeneralInformation } from '../../../../contracts/specialist/specialistGeneralInformation'
import { mqMax } from '../../../../GlobalStyle'
import {
    useCountries,
    useIndustries,
    useResponsibilities,
    useSeniorities,
    useSkills,
    useSpecialistRoles,
} from '../../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../../redux/Store'
import { Nullable } from '../../../../types'
import { hasErrors } from '../../../../utils/errors'
import { FIELD_REQUIRED, INVALID_NUMBER } from '../../../../validation/validation-messages'
import { yup } from '../../../../validation/yup'
import { updateSpecialistGeneralInformation } from '../../api'
import { TabFormWrapper } from '../../shared/TabFormWrapper'

type GeneralInformationFormProps = {
    onCancel?: () => void
    onSubmit?: (specialistData: SpecialistGeneralInformation) => void
    specialistData: SpecialistGeneralInformation
    specialistId: string
    focusLocation: boolean
    setForceFocus: Dispatch<SetStateAction<string>>
}

const GeneralInformationForm: FunctionComponent<React.PropsWithChildren<GeneralInformationFormProps>> = ({
    onCancel,
    onSubmit,
    specialistData,
    specialistId,
    focusLocation,
    setForceFocus,
}) => {
    const countriesDictionary = useCountries()
    const industriesDictionary = useIndustries()
    const skillsDictionary = useSkills()
    const responsibilitiesDictionary = useResponsibilities()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const rolesDictionary = useSpecialistRoles()
    const senioritiesDictionary = useSeniorities()
    const [emailServerError, setEmailServerError] = useState('')
    const [employmentTypeServerError, setEmploymentTypeServerError] = useState<Nullable<{ message: string; tooltipMessage: string }>>(null)

    const locationRef = useRef<HTMLHeadingElement>(null)

    const {
        actions: { layoutToggleLoader },
        selectors: {
            featureFlags: { newDropdownPoc, optionalSpecialistEmail },
        },
    } = useContext(ReduxContext)

    const schema = useMemo(
        () =>
            yup.object().shape({
                city: yup.string(),
                country: yup.string().required(FIELD_REQUIRED),
                email: optionalSpecialistEmail ? yup.string().email() : yup.string().email().required(),
                employmentType: yup.string(),
                remote: yup.string(),
                role: yup.string(),
                seniority: yup.string(),
                yearsOfExperience: yup.lazy(value => (value === '' ? yup.string() : yup.number().positive().typeError(INVALID_NUMBER))),
                potentialRoles: yup.array().of(yup.string()),
                industries: yup.array().of(yup.string()),
                responsibilities: yup.array().of(yup.string()),
                customFields: yup.array().of(yup.object().shape({ fieldName: yup.string().required(), value: yup.string() })),
            }),
        [optionalSpecialistEmail],
    )

    const customFields = useMemo(
        () =>
            specialistData.customFields.map(customField => ({
                fieldName: customField.fieldName,
                value: customField.value || '',
            })),
        [specialistData],
    )

    const {
        handleSubmit,
        formState: { errors, touchedFields, isDirty, isSubmitting },
        reset,
        control,
        getValues,
        watch,
        setValue,
        trigger,
    } = useForm<{
        city: string
        country: string
        email: string
        employmentType: string
        remote: string
        role: string
        seniority: string
        topSkills: Array<string>
        industries: Array<string>
        responsibilities: Array<string>
        potentialRoles: Array<string>
        experienceYears: string | number
        customFields: Array<CustomFieldValue>
        profileSummary: string
    }>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            city: '',
            country: '',
            email: '',
            employmentType: '',
            remote: 'No',
            role: '',
            seniority: '',
            topSkills: [],
            industries: [],
            responsibilities: [],
            potentialRoles: [],
            experienceYears: '',
            customFields,
            profileSummary: '',
        },
    })

    const { fields, append } = useFieldArray({
        control,
        name: 'customFields',
    })

    const { addSuccess, addError } = useNotifications()

    const handleError = useCallback(
        (err: any) => {
            if (err.status === 409) {
                setEmailServerError('You have already added specialist with this email.')
            } else if (err.status === 400) {
                setEmploymentTypeServerError({
                    message: 'Can’t be set',
                    tooltipMessage: 'Can’t be set for specialist added to Share My Specialists tab',
                })
            } else {
                addError()
            }
        },
        [addError],
    )

    useEffect(() => {
        if (specialistData.seniority) {
            setValue('seniority', specialistData.seniority)
        }

        if (specialistData.remote) {
            const remote = specialistData.remote ? 'Yes' : 'No'
            setValue('remote', remote)
        }

        if (specialistData.role) {
            setValue('role', specialistData.role)
        }

        if (specialistData.employmentType) {
            const type = mapEmploymentType(specialistData.employmentType)
            setValue('employmentType', type)
        }

        if (specialistData.email) {
            setValue('email', specialistData.email)
        }

        if (specialistData.country) {
            setValue('country', specialistData.country)
        }

        if (specialistData.city) {
            setValue('city', specialistData.city)
        }

        if (specialistData.topSkills) {
            setValue('topSkills', specialistData.topSkills)
        }

        if (specialistData.industries) {
            setValue('industries', specialistData.industries)
        }

        if (specialistData.potentialRoles) {
            setValue('potentialRoles', specialistData.potentialRoles)
        }

        if (specialistData.responsibilities) {
            setValue('responsibilities', specialistData.responsibilities)
        }

        if (specialistData.experienceYears) {
            setValue('experienceYears', specialistData.experienceYears)
        }

        if (specialistData.profileSummary) {
            setValue('profileSummary', specialistData.profileSummary)
        }

        reset(getValues())
    }, [setValue, specialistData, reset, getValues, append])

    const handleFormSubmit = (formData: any) => {
        if (!emailServerError && !employmentTypeServerError) {
            layoutToggleLoader(true)
            const {
                city,
                country,
                email,
                employmentType,
                remote,
                role,
                seniority,
                industries,
                responsibilities,
                potentialRoles,
                experienceYears,
                topSkills,
                customFields,
                profileSummary,
            } = formData

            const postData = {
                city,
                country,
                email,
                employmentType: mapEmploymentToType(employmentType),
                remote: remote === 'Yes',
                role,
                seniority,
                industries,
                responsibilities,
                potentialRoles,
                experienceYears,
                topSkills,
                customFields: customFields || [],
                profileSummary,
            }

            updateSpecialistGeneralInformation(specialistId, postData)
                .then(specialistData => {
                    addSuccess('User data updated!')

                    if (onSubmit) {
                        onSubmit(specialistData)
                    }
                })
                .catch(handleError)
                .finally(() => layoutToggleLoader(false))
        }
    }

    const watchCountry = watch('country')
    const getCitiesForCountry = useCallback((filter: string) => getCities(watchCountry, filter), [watchCountry])

    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    const handleCancel = () => {
        if (onCancel) {
            onCancel()
        } else {
            reset()
        }
    }

    const handleEmailChange = useCallback(() => {
        if (emailServerError) {
            setEmailServerError('')
        }
    }, [emailServerError])

    const handleTypeChange = useCallback(() => {
        if (employmentTypeServerError) {
            setEmploymentTypeServerError(null)
        }
    }, [employmentTypeServerError])

    useEffect(() => {
        if (focusLocation && locationRef.current) {
            locationRef.current.scrollIntoView({ behavior: 'smooth' })
            setForceFocus('')
        }
    }, [focusLocation, setForceFocus])

    useEffect(() => {
        trigger('country')
    }, [trigger])

    return (
        <TabFormWrapper>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <section>
                    <FieldWrapper>
                        <ControlledTextarea control={control} name='profileSummary' placeholder='Summary' label='Summary (optional):' />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Role:'
                            placeholder='Choose role'
                            options={rolesDictionary}
                            canFilter={true}
                            control={control}
                            name='role'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Seniority (optional):'
                            placeholder='Choose seniority'
                            options={senioritiesDictionary}
                            canFilter={false}
                            control={control}
                            name='seniority'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledInput
                            control={control}
                            name='email'
                            placeholder='E-mail'
                            label={`E-mail (${optionalSpecialistEmail ? 'optional, ' : ''}visible internally):`}
                            externalError={emailServerError}
                            beforeChange={handleEmailChange}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Employment Status (optional, visible internally):'
                            placeholder='Choose type'
                            options={['B2B', 'Candidate', 'Contractor', 'Full Time Employee']}
                            canFilter={false}
                            control={control}
                            beforeChange={handleTypeChange}
                            externalError={employmentTypeServerError}
                            name='employmentType'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        {newDropdownPoc ? (
                            <ControlledAutocomplete
                                label='Top Skills (optional):'
                                placeholder='Top Skills'
                                options={skillsDictionary}
                                control={control}
                                name='topSkills'
                                virtualized
                                dropdownWidth='100%'
                                autoClearSearchValue={false}
                                mode='multiple'
                                canClear
                                dataTestId='topSkills'
                            />
                        ) : (
                            <ControlledAutocompleteSelect
                                multiple
                                label='Top Skills (optional):'
                                placeholder='Top Skills'
                                options={skillsDictionary}
                                canFilter
                                control={control}
                                name='topSkills'
                                virtualized
                                dropdownWidth='100%'
                            />
                        )}
                    </FieldWrapper>

                    <h6 ref={locationRef}>Work Location</h6>

                    <ResponsiveColumns>
                        <FirstColumn>
                            <FieldWrapper>
                                <ControlledAutocompleteSelect
                                    label='Country:'
                                    placeholder='Choose country'
                                    options={memorizedCountries}
                                    control={control}
                                    name='country'
                                    canClear
                                    dataTestId='general-info-form-country'
                                    dropdownWidth='100%'
                                    isOpen={focusLocation}
                                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                />
                            </FieldWrapper>
                        </FirstColumn>

                        <SecondColumn>
                            <FieldWrapper>
                                <ControlledSuggestionInput
                                    label='City (optional):'
                                    placeholder='Choose city'
                                    fetchOptions={getCitiesForCountry}
                                    disabled={!watchCountry}
                                    control={control}
                                    name='city'
                                    allowOnlyValuesFromDictionary
                                    fetchFromLetterNumber={1}
                                    dataTestId='general-info-form-city'
                                />
                            </FieldWrapper>
                        </SecondColumn>
                    </ResponsiveColumns>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Remote (optional):'
                            placeholder='Choose option'
                            options={['Yes', 'No']}
                            canFilter={false}
                            control={control}
                            name='remote'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>

                    <h6>Professional Development (optional)</h6>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            multiple
                            label='Potential Roles:'
                            placeholder='Potential Roles'
                            options={rolesDictionary}
                            canFilter
                            control={control}
                            name='potentialRoles'
                            dropdownWidth='100%'
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            multiple
                            label='Industry Experience:'
                            placeholder='Industry Experience'
                            options={industriesDictionary}
                            canFilter
                            control={control}
                            name='industries'
                            dropdownWidth='100%'
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            multiple
                            label='Professional Responsibilities:'
                            placeholder='Professional Responsibilities'
                            options={responsibilitiesDictionary}
                            canFilter={false}
                            control={control}
                            name='responsibilities'
                            dropdownWidth='100%'
                        />
                    </FieldWrapper>
                    {!!fields.length && (
                        <Fragment>
                            <h6>Additional Information (optional)</h6>
                            {fields.map((customField, index) => (
                                <FieldWrapper key={customField.id}>
                                    <ControlledInput
                                        control={control}
                                        name={`customFields.${index}.value`}
                                        placeholder={customField.fieldName}
                                        label={customField.fieldName}
                                        shouldUnregister={false}
                                    />
                                </FieldWrapper>
                            ))}
                        </Fragment>
                    )}
                </section>

                <Divider />
                <section
                    css={css`
                        display: flex;
                        justify-content: flex-end;

                        ${mqMax[1]} {
                            justify-content: center;
                            flex-direction: column-reverse;
                            justify-content: center;
                            align-items: center;
                        }
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 24px;

                            ${mqMax[1]} {
                                margin: 12px 0 0;
                            }
                        `}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        css={css`
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                        variant='primary'
                        type='submit'
                        disabled={isSubmitting || !isDirty || !touchedFields || hasErrors(errors)}
                        dataTestId='general-information-save'
                    >
                        Save Details
                    </Button>
                </section>
            </form>
        </TabFormWrapper>
    )
}

export { GeneralInformationForm }
