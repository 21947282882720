/** @jsxImportSource @emotion/react */

import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { CVButton, CVContainer, NoDataContainer } from '../../components/shared/shared-styles'
import { Education } from '../../contracts/profile/education'
import { Profile } from '../../contracts/profile/profile'
import { Education as EducationComponent } from '../Education'
import { TabProps, Tabs, TabsColor } from '../Tabs'
import { TimelineGroup } from '../timeline/TimelineGroup'
import { useLogger } from 'src/utils/useLogger'
import { useNotifications } from '../notification/NotificationProvider'
import { downloadAnonymizedCV } from 'src/api/api'
import { Icon } from '../Icon'
import { NoData } from '../NoData'
import { saveAs } from 'file-saver'
import { fileTypeToExtension } from 'src/utils/values'

const TitleH6 = styled.h6`
    margin: 0 0 26px;
`

type ExperienceProps = { profile: Profile }

const Experience: FunctionComponent<React.PropsWithChildren<ExperienceProps>> = ({ profile }) => {
    const [selectedExperienceTab, setSelectedExperienceTab] = useState<string>('Projects')
    const [availableExperienceTabs] = useState<Array<TabProps>>([{ title: 'Projects' }, { title: 'Education' }])

    const { addError } = useNotifications()
    const log = useLogger()

    const handleCVDownload = useCallback(() => {
        if (profile.specialistId) {
            downloadAnonymizedCV(profile.specialistId)
                .then(data => {
                    saveAs(
                        data,
                        `${profile.seniority ? `${profile.seniority}_` : ''}${profile.role}${
                            profile.nickname ? `_${profile.nickname}` : ''
                        }${fileTypeToExtension(data.type)}`,
                    )
                })
                .catch(err => {
                    addError()
                    log(err)
                })
        }
    }, [profile.specialistId, profile.seniority, profile.role, profile.nickname, addError, log])

    return (
        <Fragment>
            <TitleH6>Experience</TitleH6>
            <Tabs
                tabs={availableExperienceTabs}
                showOnMobile={true}
                onTabChange={newTab => {
                    setSelectedExperienceTab(availableExperienceTabs[newTab].title)
                }}
                mode={TabsColor.BLACK}
            />
            {selectedExperienceTab === 'Projects' && (
                <Fragment>
                    {profile.projects.length > 0 && (
                        <Fragment>
                            {profile.projects.map((project, index) => (
                                <TimelineGroup
                                    key={`project-${index}`}
                                    companyName={project.companyName}
                                    positions={project.positions}
                                    isUnix={true}
                                />
                            ))}
                        </Fragment>
                    )}
                    {profile.projects.length === 0 && (
                        <Fragment>
                            {profile.hasAnonymizedCv ? (
                                <CVContainer alignItems='center' margin='24px 0 0'>
                                    <Icon name='cv' size={24} />
                                    <CVButton onClick={handleCVDownload} dataTestId='experience-view-cv-button'>
                                        Download CV
                                    </CVButton>
                                </CVContainer>
                            ) : (
                                <NoDataContainer>
                                    <NoData />
                                </NoDataContainer>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
            {selectedExperienceTab === 'Education' && (
                <Fragment>
                    {profile.education.length > 0 && (
                        <Fragment>
                            {(profile.education as Array<Education>).map((education, index) => (
                                <EducationComponent key={`education-${index}`} education={education} />
                            ))}
                        </Fragment>
                    )}
                    {profile.education.length === 0 && (
                        <Fragment>
                            {profile.hasAnonymizedCv ? (
                                <CVContainer alignItems='center' margin='24px 0 0'>
                                    <Icon name='cv' size={24} />
                                    <CVButton onClick={handleCVDownload}>Download CV</CVButton>
                                </CVContainer>
                            ) : (
                                <NoDataContainer>
                                    <NoData />
                                </NoDataContainer>
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export { Experience }
