import { CompanyAccessLevelGuard } from '../components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from '../components/CompanyStatusGuard'

const RoleManagement = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/role-management/form' />
    </CompanyAccessLevelGuard>
)

export { RoleManagement }
