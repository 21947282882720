/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, HTMLAttributes, ImgHTMLAttributes } from 'react'
import accessDenied from '../assets/icons/access-denied.svg'
import add from '../assets/icons/add.svg'
import alert from '../assets/icons/alert.svg'
import anchor from '../assets/icons/anchor.svg'
import applicationTracking from '../assets/icons/application-tracking.svg'
import arrowDown from '../assets/icons/arrow-down.svg'
import arrowRightFilled from '../assets/icons/arrow-right-filled.svg'
import arrowRightSingle from '../assets/icons/arrow-right-single.svg'
import arrowRight from '../assets/icons/arrow-right.svg'
import arrowUp from '../assets/icons/arrow-up.svg'
import back from '../assets/icons/back.svg'
import bag from '../assets/icons/bag.svg'
import bench from '../assets/icons/bench.svg'
import blitz from '../assets/icons/blitz.svg'
import bookmarkStar from '../assets/icons/bookmark-star.svg'
import bookmark from '../assets/icons/bookmark.svg'
import brokenLink from '../assets/icons/broken-link.svg'
import brokenLinkPublic from '../assets/icons/broken_link_public.svg'
import calendarDays from '../assets/icons/calendar-days.svg'
import calendar from '../assets/icons/calendar.svg'
import checkGreen from '../assets/icons/check-green.svg'
import checkGrey from '../assets/icons/check-grey.svg'
import checkRed from '../assets/icons/check-red.svg'
import checkWhite from '../assets/icons/check-white.svg'
import check from '../assets/icons/check.svg'
import chip from '../assets/icons/chip.svg'
import clock from '../assets/icons/clock.svg'
import closeGrey from '../assets/icons/close-grey.svg'
import close from '../assets/icons/close.svg'
import closed from '../assets/icons/closed.svg'
import cloud from '../assets/icons/cloud.svg'
import cog from '../assets/icons/cog.svg'
import contractor from '../assets/icons/contractor.svg'
import cosmonaut from '../assets/icons/cosmonaut.svg'
import cv from '../assets/icons/cv.svg'
import doc from '../assets/icons/doc.svg'
import dollarCircle from '../assets/icons/dollar-circle.svg'
import dollar from '../assets/icons/dollar.svg'
import dots from '../assets/icons/dots.svg'
import doubleArrowDown from '../assets/icons/double-arrow-down.svg'
import doubleArrowUp from '../assets/icons/double-arrow-up.svg'
import download from '../assets/icons/download.svg'
import duplicate from '../assets/icons/duplicate.svg'
import edit from '../assets/icons/edit.svg'
import envelopeWarning from '../assets/icons/envelope-warning.svg'
import error from '../assets/icons/error.svg'
import external from '../assets/icons/external.svg'
import eyeHide from '../assets/icons/eye-hide.svg'
import eye from '../assets/icons/eye.svg'
import faq from '../assets/icons/faq.svg'
import fav from '../assets/icons/fav.svg'
import feedback from '../assets/icons/feedback.svg'
import filter from '../assets/icons/filter.svg'
import find from '../assets/icons/find.svg'
import flag from '../assets/icons/flag.svg'
import grid from '../assets/icons/grid.svg'
import hamburger from '../assets/icons/hamburger.svg'
import id from '../assets/icons/id.svg'
import infoRound from '../assets/icons/info-round.svg'
import infoSmall from '../assets/icons/info-small.svg'
import linkedin from '../assets/icons/linkedin.svg'
import list from '../assets/icons/list.svg'
import lock from '../assets/icons/lock.svg'
import magGlass from '../assets/icons/mag-glass.svg'
import mail from '../assets/icons/mail.svg'
import maintenance from '../assets/icons/maintenance.svg'
import sad from '../assets/icons/mood-sad.svg'
import more from '../assets/icons/more.svg'
import noData from '../assets/icons/no-data.svg'
import notification from '../assets/icons/notification.svg'
import pdfBlack from '../assets/icons/pdf-black.svg'
import pdf from '../assets/icons/pdf.svg'
import plane from '../assets/icons/plane.svg'
import planet from '../assets/icons/planet.svg'
import plus from '../assets/icons/plus.svg'
import profileDetails from '../assets/icons/profile-details.svg'
import psychometrics from '../assets/icons/psychometrics.svg'
import ratingStarRibbon from '../assets/icons/rating-star-ribbon.svg'
import refresh from '../assets/icons/refresh.svg'
import sales from '../assets/icons/sales.svg'
import save from '../assets/icons/save.svg'
import search from '../assets/icons/search.svg'
import shareGrey from '../assets/icons/share-grey.svg'
import share from '../assets/icons/share.svg'
import smallClose from '../assets/icons/small-close.svg'
import smileBlack from '../assets/icons/smile-black.svg'
import smileGreen from '../assets/icons/smile-green.svg'
import smileRed from '../assets/icons/smile-red.svg'
import smile from '../assets/icons/smile.svg'
import smileyDrop from '../assets/icons/smiley-drop.svg'
import smileyDropCircle from '../assets/icons/smiley-drop-circle.svg'
import syndicateCloud from '../assets/icons/syndicate-cloud.svg'
import syndicate from '../assets/icons/syndicate.svg'
import technicalSkillsBlack from '../assets/icons/technical-skills-black.svg'
import technicalSkillsGreen from '../assets/icons/technical-skills-green.svg'
import technicalSkillsRed from '../assets/icons/technical-skills-red.svg'
import technicalSkills from '../assets/icons/technical-skills.svg'
import timeClockFileRefresh from '../assets/icons/time-clock-file-refresh.svg'
import time from '../assets/icons/time.svg'
import tooltip from '../assets/icons/tooltip.svg'
import trash from '../assets/icons/trash.svg'
import trophy from '../assets/icons/trophy.svg'
import uploadDesktop from '../assets/icons/upload-desktop.svg'
import upload from '../assets/icons/upload.svg'
import userAdd from '../assets/icons/user-add.svg'
import userRemove from '../assets/icons/user-remove.svg'
import user from '../assets/icons/user.svg'
import users from '../assets/icons/users.svg'
import verify from '../assets/icons/verify.svg'
import wavingFlag from '../assets/icons/waving-flag.svg'
import withdrawSummary from '../assets/icons/withdraw-summary.svg'
import withdraw from '../assets/icons/withdraw.svg'
import zoom from '../assets/icons/zoom.svg'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Theme } from '../theme/theme'
import addEmpty from './../assets/icons/add-empty.svg'
import codeEditor from './../assets/icons/code-editor.svg'
import exclamationMark from './../assets/icons/exclamation-mark.svg'
import experience from './../assets/icons/experience.svg'
import price from './../assets/icons/price.svg'
import removeSummary from './../assets/icons/remove-summary.svg'
import serviceUnavailable from './../assets/icons/service-unavailable.svg'
import success from './../assets/icons/success.svg'
import team from './../assets/icons/team.svg'
import violetCalendar from './../assets/icons/violet-calendar.svg'
import violetCheckSelected from './../assets/icons/violet-check-selected.svg'
import binoculars from './../assets/icons/binoculars.svg'
import checkReward from './../assets/icons/check-reward.svg'
import starOutlined from './../assets/icons/star-outlined.svg'

export type IconName =
    | 'access-denied'
    | 'add'
    | 'alert'
    | 'anchor'
    | 'application-tracking'
    | 'arrow-down'
    | 'arrow-right'
    | 'arrow-right-filled'
    | 'arrow-right-single'
    | 'arrow-up'
    | 'back'
    | 'bench'
    | 'blitz'
    | 'bookmark'
    | 'bookmark-star'
    | 'broken-link'
    | 'broken-link-public'
    | 'calendar'
    | 'check-grey'
    | 'check-white'
    | 'check-red'
    | 'check-green'
    | 'check'
    | 'close-grey'
    | 'clock'
    | 'close'
    | 'closed'
    | 'cloud'
    | 'cog'
    | 'contractor'
    | 'cosmonaut'
    | 'cv'
    | 'doc'
    | 'dollar'
    | 'double-arrow-down'
    | 'double-arrow-up'
    | 'download'
    | 'edit'
    | 'external'
    | 'eye-hide'
    | 'eye'
    | 'faq'
    | 'filter'
    | 'find'
    | 'flag'
    | 'waving-flag'
    | 'grid'
    | 'id'
    | 'hamburger'
    | 'list'
    | 'lock'
    | 'mag-glass'
    | 'mail'
    | 'more'
    | 'no-data'
    | 'notification'
    | 'plane'
    | 'profile-details'
    | 'psychometrics'
    | 'sad'
    | 'sales'
    | 'save'
    | 'search'
    | 'service-unavailable'
    | 'share'
    | 'share-grey'
    | 'small-close'
    | 'syndicate'
    | 'syndicate-cloud'
    | 'team'
    | 'time'
    | 'time-clock-file-refresh'
    | 'tooltip'
    | 'trash'
    | 'trophy'
    | 'upload'
    | 'user-add'
    | 'user-remove'
    | 'user'
    | 'users'
    | 'verify'
    | 'withdraw'
    | 'withdraw-summary'
    | 'smile-black'
    | 'smile-green'
    | 'smile-red'
    | 'smile'
    | 'smiley-drop'
    | 'smiley-drop-circle'
    | 'technical-skills-black'
    | 'technical-skills-green'
    | 'technical-skills-red'
    | 'technical-skills'
    | 'upload-desktop'
    | 'plus'
    | 'planet'
    | 'feedback'
    | 'refresh'
    | 'rating-star-ribbon'
    | 'info-small'
    | 'info-round'
    | 'chip'
    | 'envelope-warning'
    | 'pdf'
    | 'pdf-black'
    | 'error'
    | 'linkedin'
    | 'calendar-days'
    | 'bag'
    | 'dollar-circle'
    | 'fav'
    | 'duplicate'
    | 'violet-check-selected'
    | 'zoom'
    | 'maintenance'
    | 'dots'
    | 'exclamation-mark'
    | 'add-empty'
    | 'success'
    | 'price'
    | 'violet-calendar'
    | 'experience'
    | 'code-editor'
    | 'remove-summary'
    | 'binoculars'
    | 'check-reward'
    | 'star-outlined'

const getSvg = (name: IconName): string => {
    switch (name) {
        case 'access-denied':
            return accessDenied
        case 'add':
            return add
        case 'anchor':
            return anchor
        case 'application-tracking':
            return applicationTracking
        case 'alert':
            return alert
        case 'arrow-down':
            return arrowDown
        case 'arrow-right':
            return arrowRight
        case 'arrow-right-filled':
            return arrowRightFilled
        case 'arrow-right-single':
            return arrowRightSingle
        case 'arrow-up':
            return arrowUp
        case 'back':
            return back
        case 'bench':
            return bench
        case 'blitz':
            return blitz
        case 'bookmark':
            return bookmark
        case 'bookmark-star':
            return bookmarkStar
        case 'broken-link':
            return brokenLink
        case 'broken-link-public':
            return brokenLinkPublic
        case 'calendar':
            return calendar
        case 'check':
            return check
        case 'check-grey':
            return checkGrey
        case 'check-white':
            return checkWhite
        case 'check-red':
            return checkRed
        case 'check-green':
            return checkGreen
        case 'clock':
            return clock
        case 'close-grey':
            return closeGrey
        case 'close':
            return close
        case 'closed':
            return closed
        case 'cloud':
            return cloud
        case 'cog':
            return cog
        case 'contractor':
            return contractor
        case 'cosmonaut':
            return cosmonaut
        case 'cv':
            return cv
        case 'doc':
            return doc
        case 'dollar':
            return dollar
        case 'double-arrow-down':
            return doubleArrowDown
        case 'double-arrow-up':
            return doubleArrowUp
        case 'download':
            return download
        case 'edit':
            return edit
        case 'external':
            return external
        case 'eye-hide':
            return eyeHide
        case 'eye':
            return eye
        case 'faq':
            return faq
        case 'filter':
            return filter
        case 'find':
            return find
        case 'flag':
            return flag
        case 'waving-flag':
            return wavingFlag
        case 'grid':
            return grid
        case 'id':
            return id
        case 'hamburger':
            return hamburger
        case 'list':
            return list
        case 'lock':
            return lock
        case 'mag-glass':
            return magGlass
        case 'mail':
            return mail
        case 'more':
            return more
        case 'no-data':
            return noData
        case 'notification':
            return notification
        case 'plane':
            return plane
        case 'profile-details':
            return profileDetails
        case 'psychometrics':
            return psychometrics
        case 'sad':
            return sad
        case 'sales':
            return sales
        case 'save':
            return save
        case 'search':
            return search
        case 'service-unavailable':
            return serviceUnavailable
        case 'share':
            return share
        case 'share-grey':
            return shareGrey
        case 'small-close':
            return smallClose
        case 'syndicate':
            return syndicate
        case 'syndicate-cloud':
            return syndicateCloud
        case 'team':
            return team
        case 'time':
            return time
        case 'time-clock-file-refresh':
            return timeClockFileRefresh
        case 'tooltip':
            return tooltip
        case 'trash':
            return trash
        case 'trophy':
            return trophy
        case 'upload':
            return upload
        case 'user-add':
            return userAdd
        case 'user-remove':
            return userRemove
        case 'user':
            return user
        case 'users':
            return users
        case 'verify':
            return verify
        case 'withdraw':
            return withdraw
        case 'withdraw-summary':
            return withdrawSummary
        case 'smile-black':
            return smileBlack
        case 'smile-green':
            return smileGreen
        case 'smile-red':
            return smileRed
        case 'smile':
            return smile
        case 'smiley-drop':
            return smileyDrop
        case 'smiley-drop-circle':
            return smileyDropCircle
        case 'technical-skills-black':
            return technicalSkillsBlack
        case 'technical-skills-green':
            return technicalSkillsGreen
        case 'technical-skills-red':
            return technicalSkillsRed
        case 'technical-skills':
            return technicalSkills
        case 'upload-desktop':
            return uploadDesktop
        case 'plus':
            return plus
        case 'planet':
            return planet
        case 'feedback':
            return feedback
        case 'refresh':
            return refresh
        case 'rating-star-ribbon':
            return ratingStarRibbon
        case 'info-small':
            return infoSmall
        case 'info-round':
            return infoRound
        case 'chip':
            return chip
        case 'envelope-warning':
            return envelopeWarning
        case 'pdf':
            return pdf
        case 'pdf-black':
            return pdfBlack
        case 'error':
            return error
        case 'linkedin':
            return linkedin
        case 'calendar-days':
            return calendarDays
        case 'bag':
            return bag
        case 'dollar-circle':
            return dollarCircle
        case 'fav':
            return fav
        case 'duplicate':
            return duplicate
        case 'violet-check-selected':
            return violetCheckSelected
        case 'maintenance':
            return maintenance
        case 'zoom':
            return zoom
        case 'dots':
            return dots
        case 'exclamation-mark':
            return exclamationMark
        case 'add-empty':
            return addEmpty
        case 'success':
            return success
        case 'experience':
            return experience
        case 'violet-calendar':
            return violetCalendar
        case 'price':
            return price
        case 'code-editor':
            return codeEditor
        case 'remove-summary':
            return removeSummary
        case 'binoculars':
            return binoculars
        case 'check-reward':
            return checkReward
        case 'star-outlined':
            return starOutlined

        default:
            return ''
    }
}

const blackToGrayFilter = 'invert(100%) sepia(0%) saturate(1272%) hue-rotate(179deg) brightness(72%) contrast(100%)'
const blackToRedFilter = 'invert(9%) sepia(96%) saturate(6006%) hue-rotate(359deg) brightness(80%) contrast(109%)'
const blackToRed4Filter =
    'brightness(0) saturate(100%) invert(9%) sepia(99%) saturate(4942%) hue-rotate(359deg) brightness(99%) contrast(114%)'
const blackToVioletDarkFilter = 'invert(18%) sepia(25%) saturate(3850%) hue-rotate(229deg) brightness(94%) contrast(92%)'
const blackToViolet3Filter =
    'brightness(0) saturate(100%) invert(84%) sepia(28%) saturate(369%) hue-rotate(209deg) brightness(89%) contrast(81%)'
const blackToWhiteFilter = 'invert()'
const blackToBlueFilter = 'invert(32%) sepia(91%) saturate(665%) hue-rotate(174deg) brightness(99%) contrast(86%)'
const blackToGreenLightFilter = 'invert(63%) sepia(92%) saturate(2438%) hue-rotate(135deg) brightness(95%) contrast(101%)'
const blackToBeige4Filter = 'invert(77%) sepia(14%) saturate(335%) hue-rotate(349deg) brightness(105%) contrast(93%)'

type IconProps = ImgHTMLAttributes<HTMLImageElement> & {
    name?: IconName
    style?: SerializedStyles
    size?: number
    dataTestId?: string
}

const Icon: FunctionComponent<React.PropsWithChildren<IconProps>> = ({ name, size = 14, style, src, dataTestId = '', ...rest }) => {
    return (
        <img
            alt={name}
            css={css`
                width: ${size}px;
                height: ${size}px;

                ${style}
            `}
            src={name ? getSvg(name) : src}
            {...rest}
            data-testid={dataTestId}
        />
    )
}

export type RoundIconWrapperProps = HTMLAttributes<HTMLSpanElement> & {
    size: number
    dataTestId?: string
}

const RoundIconWrapper: FunctionComponent<React.PropsWithChildren<RoundIconWrapperProps>> = ({
    children,
    size,
    dataTestId = '',
    ...rest
}) => {
    const theme = useTheme()

    return (
        <span
            data-testid={dataTestId}
            css={css`
                display: inline-flex;
                width: ${size}px;
                height: ${size}px;
                border-width: 1px;
                border-color: ${theme.colors.main};
                border-style: solid;
                border-radius: ${size / 2}px;
                justify-content: center;
                align-items: center;
            `}
            {...rest}
        >
            {children}
        </span>
    )
}

type AppIconStatus = 'available' | 'unavailable' | 'disabled' | 'static'
type AppIconVariant = 'tile' | 'menu' | 'status'

const getAppIconStyles = (status: AppIconStatus, variant: AppIconVariant, theme: Theme, color?: string) => {
    let styles
    switch (status) {
        case 'available':
            styles = css`
                border-color: ${color};
                background-color: ${color};
                &:hover {
                    background-color: ${theme.colors.main};
                    border-color: ${theme.colors.main};
                    ${theme.colors.text === theme.colors.white && 'img { filter:invert(); }'}
                }
            `

            break

        case 'static':
            styles = css`
                border-color: ${color};
                background-color: ${color};
            `

            break
        case 'unavailable':
            styles = css`
                border-color: ${COLOR_PALETTE.gray_2};
                background: transparent;

                &:hover {
                    border-color: ${theme.colors.gray_2};
                }
            `
            break

        default:
            styles = css`
                border-color: ${COLOR_PALETTE.gray_1};
                background: ${COLOR_PALETTE.gray_1};

                img {
                    filter: ${blackToGrayFilter};
                }
            `
            break
    }

    if (variant === 'status') {
        styles = css`
            border-color: ${COLOR_PALETTE.violet_5};
            background: ${COLOR_PALETTE.violet_5};
        `
    }
    return styles
}

type AppIconProps = IconProps & {
    innerSize?: number
    status?: AppIconStatus
    style?: SerializedStyles
    variant?: AppIconVariant
    hovered?: boolean
    hoverColor?: string
    color?: string
}

const AppIcon: FunctionComponent<React.PropsWithChildren<AppIconProps>> = ({
    name,
    status = 'available',
    size = 60,
    innerSize = size / 2,
    variant = 'tile',
    hoverColor = '',
    color = '',
    style = {},
    hovered = false,
    src,
    ...rest
}) => {
    const theme = useTheme()

    return (
        <RoundIconWrapper
            size={size}
            css={css`
                ${getAppIconStyles(status, variant, theme, color)}
                ${style};
                ${hovered &&
                status !== 'disabled' &&
                status !== 'unavailable' &&
                `background-color: ${hoverColor || theme.colors.main};
                 border-color: ${hoverColor || theme.colors.main};
                    ${theme.colors.text === theme.colors.white && 'img { filter:invert(); }'}`}
            `}
            {...rest}
        >
            <Icon size={innerSize} name={name} src={src} />
        </RoundIconWrapper>
    )
}

type IconButtonVariant = 'standard' | 'outlined' | 'primary'

type IconButtonProps = IconProps & {
    innerSize?: number
    pressed?: boolean
    variant?: IconButtonVariant
    disabled?: boolean
    dataTestId?: string
    styles?: SerializedStyles
}

const getIconButtonStyles = (variant: IconButtonVariant, pressed: boolean, theme: Theme, disabled: boolean) => {
    let styles
    if (variant === 'outlined') {
        styles = css`
            border-color: ${disabled ? COLOR_PALETTE.gray_2 : pressed ? COLOR_PALETTE.violet_6 : COLOR_PALETTE.violet_3};
            background: ${pressed ? COLOR_PALETTE.violet_6 : 'transparent'};
            ${!disabled
                ? `
                @media(hover: hover) and (pointer: fine){
                &:hover {
                    border-color: ${COLOR_PALETTE.violet_5};
                    background: ${COLOR_PALETTE.violet_5};
                    img {
                        filter: ${blackToWhiteFilter};
                    }
                }}`
                : ''}
            img {
                filter: ${disabled ? blackToGrayFilter : pressed ? blackToWhiteFilter : 'none'};
            }
        `
    } else if (variant === 'primary') {
        styles = css`
            border-width: 0;
            background: ${pressed ? theme.colors.main_dark : theme.colors.main};
            ${!disabled
                ? `
                @media(hover: hover) and (pointer: fine){
                &:hover {
                    background: ${theme.colors.main_dark};
                }}`
                : ''}
            img {
                filter: ${disabled ? blackToGrayFilter : pressed ? blackToWhiteFilter : 'none'};
            }
        `
    } else {
        styles = css`
            border-width: 0;
            background: ${pressed ? COLOR_PALETTE.gray_6 : 'transparent'};
            ${!disabled
                ? `
                @media(hover: hover) and (pointer: fine){
                &:hover {
                    background: ${pressed ? COLOR_PALETTE.gray_5 : COLOR_PALETTE.gray_2};
                }}`
                : ''}
            img {
                filter: ${disabled ? blackToGrayFilter : pressed ? blackToWhiteFilter : 'none'};
            }
        `
    }
    return styles
}

const IconButton: FunctionComponent<React.PropsWithChildren<IconButtonProps>> = ({
    name,
    onClick,
    variant = 'standard',
    pressed = false,
    size = 40,
    innerSize = size / 2,
    title = '',
    disabled = false,
    dataTestId = '',
    styles = {},
    className,
    ...rest
}) => {
    const theme = useTheme()

    return (
        <RoundIconWrapper
            size={size}
            onClick={disabled ? undefined : onClick}
            title={title}
            css={css`
                cursor: ${disabled ? 'not-allowed' : 'pointer'};
                ${getIconButtonStyles(variant, pressed, theme, disabled)}
                ${styles}
            `}
            className={className}
        >
            <Icon size={innerSize} name={name} {...rest} dataTestId={dataTestId} />
        </RoundIconWrapper>
    )
}

export {
    Icon,
    AppIcon,
    IconButton,
    RoundIconWrapper,
    getSvg,
    blackToGrayFilter,
    blackToRedFilter,
    blackToRed4Filter,
    blackToVioletDarkFilter,
    blackToViolet3Filter,
    blackToWhiteFilter,
    blackToBlueFilter,
    blackToGreenLightFilter,
    blackToBeige4Filter,
}
