/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'

import { ApplicationStatusEnum } from '../contracts/opportunities-manager/contracts'
import { useApplicationStatusCellColors } from './opportunities-manager/utils/useApplicationStatusCellColors'

interface OwnProps {
    contractRate: number
    currency: string
    status: ApplicationStatusEnum
}

const ContractRateDetails: React.FC<OwnProps> = ({ contractRate, currency, status }) => {
    const colors = useApplicationStatusCellColors()
    const details = `Final contract rate for this candidate is ${contractRate} ${currency}/h.`

    return (
        <span
            css={css`
                color: ${colors[status].color};
                padding: 8px;
                margin-top: 16px;
                margin-bottom: 0;
                display: inline-block;
            `}
        >
            {details}
        </span>
    )
}

export { ContractRateDetails }
