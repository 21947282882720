/** @jsxImportSource @emotion/react */
import { StrictMode, useContext } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { AccessLevelContextProvider } from './AccessLevelContext'
import { GlobalAntdStylesProvider } from './components/antd'
import { IntercomProvider } from './components/IntercomProvider'
import { NotificationProvider } from './components/notification/NotificationProvider'
import { CustomRouter } from './CustomRouter'
import { GlobalStylesProvider } from './GlobalStyle'
import { PrivateRoute } from './guards/PrivateRoute'
import { PublicRoute } from './guards/PublicRoute'
import { history } from './history'
import { useGoogleTagManager } from './hooks/useGoogleTagManager'
import { SentryProvider } from './SentryProvider'
import { useUiChanges } from './hooks/useUiChanges'
import { useUserAuthListeners } from './hooks/useUserAuthListeners'
import { useUserContextListeners } from './hooks/useUserContextListeners'
import { MaintenancePage } from './pages/error/MaintenancePage'
import { ReduxContext } from './redux/Store'
import { getRoutesDefinition } from './Routes'
import { ThemeProvider } from './ThemeProvider'
import en_GB from 'antd/lib/locale-provider/en_GB'
import moment from 'moment'
import 'moment/locale/en-gb'
import { ConfigProvider } from 'antd'

const App = () => {
    useUserAuthListeners()
    useGoogleTagManager()
    useUiChanges()
    useUserContextListeners()
    moment.locale('en-gb')
    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)

    // React StrictMode nested under NotificationProvider until
    // https://github.com/jossmac/react-toast-notifications/issues/82
    // resolved (should be couple of days)

    return (
        <IntercomProvider>
            <CustomRouter history={history}>
                <ConfigProvider locale={en_GB}>
                    <ThemeProvider>
                        <NotificationProvider>
                            <StrictMode>
                                <AccessLevelContextProvider>
                                    <GlobalStylesProvider>
                                        <GlobalAntdStylesProvider>
                                            {featureFlags.maintenance ? (
                                                <MaintenancePage />
                                            ) : (
                                                <SentryProvider>
                                                    {getRoutesDefinition(featureFlags).map(route =>
                                                        route.public ? (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                element={<PublicRoute component={route.component} />}
                                                            />
                                                        ) : route.component ? (
                                                            <Route
                                                                key={route.path}
                                                                path={route.path}
                                                                element={
                                                                    <PrivateRoute
                                                                        component={route.component}
                                                                        allowedGroups={route.allowedGroups || []}
                                                                    />
                                                                }
                                                            />
                                                        ) : null,
                                                    )}
                                                    <Route path='*' element={<Navigate to='/page-not-found' />} />
                                                </SentryProvider>
                                            )}
                                        </GlobalAntdStylesProvider>
                                    </GlobalStylesProvider>
                                </AccessLevelContextProvider>
                            </StrictMode>
                        </NotificationProvider>
                    </ThemeProvider>
                </ConfigProvider>
            </CustomRouter>
        </IntercomProvider>
    )
}

export default App
