/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useMemo } from 'react'

interface Props {
    value: string
    index: number
    readOnly: boolean
    remove: (i: number) => void
    removeOnBackspace?: boolean
    addKeys: Array<string>
    validationRegex: RegExp
}

const Tag: FunctionComponent<React.PropsWithChildren<Props>> = ({ value, index, remove, readOnly, validationRegex }) => {
    const theme = useTheme()

    const handleRemove = useCallback(() => {
        remove(index)
    }, [index, remove])

    const isValid = useMemo(() => value.match(validationRegex), [validationRegex, value])

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                align-items: center;
                font-size: 14px;
                line-height: 22px;
                background: ${isValid ? theme.colors.violet_2 : theme.colors.red_2};
                border-radius: 2px;
                padding: 4px 8px;
                margin: 4px;
                color: ${theme.colors.gray_6};
            `}
        >
            <div>{value}</div>

            <div
                css={
                    readOnly
                        ? css`
                              position: relative;
                              width: 0;
                              cursor: pointer;
                              &:before,
                              &:after {
                                  content: '';
                                  width: 0;
                              }
                          `
                        : css`
                              position: relative;
                              height: 9px;
                              width: 9px;
                              margin-left: 12px;
                              cursor: pointer;

                              &:before,
                              &:after {
                                  position: absolute;
                                  top: 50%;
                                  left: 50%;
                                  content: ' ';
                                  height: 9px;
                                  width: 0.75px;
                                  background-color: ${theme.colors.gray_4};
                              }
                              &:before {
                                  transform: translateX(-50%) translateY(-50%) rotate(45deg);
                              }
                              &:after {
                                  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
                              }
                          `
                }
                onClick={handleRemove}
            />
        </div>
    )
}

export { Tag }
