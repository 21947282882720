/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Col, Row } from 'antd'
import { Fragment, FunctionComponent } from 'react'
import CreateCompanyButton from '../../../components/layout/dashboard/CreateCompanyButton'
import { AppTileGroup } from '../../../contracts/applications'
import ApplicationItem from './ApplicationItem'

type ApplicationsProps = {
    groups: Array<AppTileGroup>
}

const Applications: FunctionComponent<React.PropsWithChildren<ApplicationsProps>> = ({ groups }) => {
    const freeAppsGroup = groups.find(group => group.name === 'Your Free Apps:')
    const companyAccountAppsGroup = groups.find(group => group.name === 'Apps available with a Company Account:')
    const theme = useTheme()

    return (
        <div
            css={css`
                width: 100%;
                @media (max-width: 768px) {
                    margin-bottom: 64px;
                }
            `}
        >
            <Row
                gutter={[16, 16]}
                css={css`
                    width: 100%;
                `}
            >
                {freeAppsGroup?.apps.map((app, index) => {
                    return <ApplicationItem app={app} key={index} />
                })}
                {companyAccountAppsGroup?.apps.map((app, index) => {
                    return <ApplicationItem app={app} key={index} />
                })}
            </Row>

            {companyAccountAppsGroup && companyAccountAppsGroup.apps.length > 0 ? (
                <Fragment>
                    <div
                        css={css`
                            width: 100vw;
                            height: 64px;
                            left: 0;
                            background-color: ${theme.colors.white};
                            position: fixed;
                            bottom: 0;
                            box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.04);
                            padding: 12px 8px;
                            text-align: center;
                            display: none;
                            @media (max-width: 768px) {
                                display: block;
                            }
                        `}
                    >
                        <CreateCompanyButton />
                    </div>

                    <Row
                        css={css`
                            width: 100%;
                            margin-top: 16px;
                            display: block;
                            @media (max-width: 768px) {
                                display: none;
                            }
                        `}
                    >
                        <Col span={16} offset={4}>
                            <CreateCompanyButton />
                        </Col>
                    </Row>
                </Fragment>
            ) : null}
        </div>
    )
}

export { Applications }
