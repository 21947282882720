import { VerifiedSkill } from '../profile/skill'
import { AvailableOn } from './availableOn'

export interface SearchSpecialist {
    availability: string
    experienceInMonths: number
    savedProfile: any
    industries: Array<string>
    languages: Array<string>
    location: string
    nickname: string
    projects: Array<string>
    recentRoles: Array<string>
    rolesInfo: Array<SearchSpecialistRole>
    specialistId: string
    tribe: string
    availableFrom: number
    availableTo: number
    rate: number
    availableOnSyndicates: Array<AvailableOn>
    availableInPublicCloud: boolean
    publicCloud: AvailableOn
    responsibilities?: Array<string>
    potentialRoles: Array<string>
    recentIndustries?: Array<string>
    skills?: Array<string>
    currency?: string
}

export enum RoleType {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
}

export enum RoleSeniority {
    JUNIOR = 'JUNIOR',
    REGULAR = 'REGULAR',
    SENIOR = 'SENIOR',
}

export interface SearchSpecialistRole {
    role: string
    roleSeniority: RoleSeniority
    roleType: RoleType
    topSkillsForRole: Array<string>
    verifiedTopSkills: Array<VerifiedSkill>
}
