import { useContext, useEffect } from 'react'
import { ReduxContext } from 'src/redux/Store'
import { useUserContext } from './useUserContext'
import { useLogger } from 'src/utils/useLogger'
import { getUserSegmentationCompanyById } from 'src/api/api'
import { UserSegmentationCompany } from 'src/contracts/company'

const useCompany = () => {
    const { activeContext } = useUserContext()
    const log = useLogger('error')
    const {
        selectors: { company },
        actions: { companyUpdate },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (activeContext?.companyId && (!company || company.id !== activeContext.companyId)) {
            getUserSegmentationCompanyById(activeContext.companyId)
                .then((company: UserSegmentationCompany) => {
                    companyUpdate(company)
                })
                .catch(log)
        }
    }, [activeContext, company, companyUpdate, log])

    return { company }
}

export { useCompany }
