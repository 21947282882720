/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Dispatch, Fragment, FunctionComponent, MouseEvent, MutableRefObject, SetStateAction, useCallback } from 'react'
import { Button } from '../Button'
import { IconButton } from '../Icon'
import { DropdownComponent } from '../inputs/autocomplete-select/DropdownComponent'
import { OpportunityStatus } from '../../contracts/opportunities'
import { mqMax } from '../../GlobalStyle'
import { SearchOpportunityItem } from '../../contracts/opportunities-manager/contracts'
import { useOpportunitiesActions } from './utils/useOpportunityActions'

interface OpportunityMoreActionsProps {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    opportunity: SearchOpportunityItem
    onStatusChange: (opportunityId: string, status: OpportunityStatus) => void
    isSmeCloud?: boolean
}

const OpportunityMoreActionsBase: FunctionComponent<React.PropsWithChildren<OpportunityMoreActionsProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    opportunity,
    onStatusChange,
    isSmeCloud = false,
}) => {
    const theme = useTheme()
    const toggleOpen = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            setIsOpen(wasOpen => !wasOpen)
        },
        [setIsOpen],
    )

    const { onToggleStatus, onShare, onEdit, onViewDetails, onDuplicate, onHold, onClose, ShareModal } = useOpportunitiesActions({
        opportunityId: opportunity.opportunityId,
        status: opportunity.status,
        onStatusChangeSuccessCallback: onStatusChange,
        toggle: toggleOpen,
        opportunityTitle: opportunity.opportunityTitle,
        isSmeCloudApp: isSmeCloud,
    })

    const stopPropagation = useCallback((e: any) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                margin-left: 7px;
                z-index: 1;
            `}
            data-testid='opportunity-more-actions'
        >
            <IconButton name='more' onClick={toggleOpen} />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        bottom: calc(100% + 10px);
                        right: 0;
                        background-color: ${theme.colors.white};
                        border: 1px solid ${theme.colors.gray_2};
                        border-radius: 2px;
                        display: flex;
                        flex-direction: column;
                        z-index: 99;

                        ${mqMax[1]} {
                            left: 0;
                            right: auto;
                        }
                    `}
                >
                    {opportunity.status !== OpportunityStatus.CLOSED && (
                        <Button
                            variant='link'
                            style={css`
                                padding: 0 15px;
                                white-space: nowrap;
                                height: 48px;
                            `}
                            onClick={onToggleStatus}
                            dataTestId={opportunity.status === OpportunityStatus.PUBLISHED ? 'opportunity-close' : 'opportunity-publish'}
                        >
                            {opportunity.status === OpportunityStatus.PUBLISHED ? 'Close Opportunity' : 'Publish Opportunity'}
                        </Button>
                    )}
                    {opportunity.status === OpportunityStatus.ON_HOLD && (
                        <Button
                            variant='link'
                            style={css`
                                padding: 0 15px;
                                white-space: nowrap;
                                height: 48px;
                            `}
                            onClick={onClose}
                            dataTestId='opportunity-close'
                        >
                            Close
                        </Button>
                    )}
                    <Button
                        variant='link'
                        style={css`
                            padding: 0 15px;
                            white-space: nowrap;
                            height: 48px;
                        `}
                        onClick={onDuplicate}
                        dataTestId='opportunity-duplicate'
                    >
                        Duplicate
                    </Button>
                    {opportunity.status !== OpportunityStatus.CLOSED && (
                        <Fragment>
                            <Button
                                variant='link'
                                style={css`
                                    padding: 0 15px;
                                    white-space: nowrap;
                                    height: 48px;
                                `}
                                onClick={onEdit}
                                dataTestId='opportunity-edit'
                            >
                                Edit
                            </Button>

                            <Tooltip
                                title={opportunity.status === OpportunityStatus.DRAFT && 'Only Published opportunities can be shared.'}
                            >
                                <div
                                    css={css`
                                        ${opportunity.status === OpportunityStatus.DRAFT &&
                                        css`
                                            cursor: not-allowed;
                                        `}
                                    `}
                                    onClick={stopPropagation}
                                >
                                    <Button
                                        variant='link'
                                        style={css`
                                            padding: 0 15px;
                                            white-space: nowrap;
                                            height: 48px;
                                            width: 100%;
                                            ${opportunity.status === OpportunityStatus.DRAFT &&
                                            css`
                                                pointer-events: none;
                                            `}
                                        `}
                                        onClick={onShare}
                                        dataTestId='opportunity-share'
                                        disabled={opportunity.status === OpportunityStatus.DRAFT}
                                    >
                                        Share
                                    </Button>
                                </div>
                            </Tooltip>
                        </Fragment>
                    )}
                    <Button
                        variant='link'
                        style={css`
                            padding: 0 15px;
                            white-space: nowrap;
                            height: 48px;
                        `}
                        onClick={onViewDetails}
                        dataTestId='opportunity-view-details'
                    >
                        View Details
                    </Button>
                    {opportunity.status === OpportunityStatus.PUBLISHED && (
                        <Button
                            variant='link'
                            style={css`
                                padding: 0 15px;
                                white-space: nowrap;
                                height: 48px;
                            `}
                            onClick={onHold}
                            dataTestId='opportunity-hold'
                        >
                            Put on hold
                        </Button>
                    )}
                </div>
            )}
            {ShareModal}
        </div>
    )
}

const OpportunityMoreActions = DropdownComponent(OpportunityMoreActionsBase)

export { OpportunityMoreActions }
