/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useMemo } from 'react'
import { OpportunityStatus } from '../../contracts/opportunities'
import { OpportunityStatusMapping } from '../../contracts/opportunities-manager/contracts'

interface OpportunityStatusCellProps {
    status: OpportunityStatus
}

const OpportunityStatusCell: FunctionComponent<React.PropsWithChildren<OpportunityStatusCellProps>> = ({ status }) => {
    const theme = useTheme()

    const colors = useMemo(
        () => ({
            [OpportunityStatus.DRAFT]: css`
                color: ${theme.colors.violet_5};
                background-color: ${theme.colors.violet_2};
            `,
            [OpportunityStatus.PUBLISHED]: css`
                color: ${theme.colors.green_6};
                background-color: ${theme.colors.green_2};
            `,
            [OpportunityStatus.CLOSED]: css`
                color: ${theme.colors.red_4};
                background-color: ${theme.colors.red_2};
            `,
            [OpportunityStatus.ON_HOLD]: css`
                color: ${theme.colors.gray_5};
                background-color: ${theme.colors.gray_2};
            `,
        }),
        [theme],
    )

    return (
        <div
            css={css`
                display: inline-block;
                padding: 4px;
                font-size: 11px;
                line-height: 14px;
                border-radius: 2px;
                ${colors[status]}
            `}
        >
            {OpportunityStatusMapping[status]}
        </div>
    )
}

export { OpportunityStatusCell }
