/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../forms/ControlledAutocompleteSelect'
import { ControlledTextarea } from '../forms/ControlledTextarea'
import { Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { Modal } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { Nullable } from '../../types'
import { hasErrors } from '../../utils/errors'
import { alphabeticallyAsc } from '../../utils/sorting'
import { useLogger } from '../../utils/useLogger'
import { yup } from '../../validation/yup'
import { getRejectionReasons } from '../../api/open-opportunities/api'
import {
    ApplicationRejectionStatusEnum,
    ApplicationStatus,
    ApplicationStatusEnum,
    UpdateApplicationBody,
} from '../../contracts/opportunities-manager/contracts'

const SHOW_REJECTION_SUCCESS_MESSAGE = 'SHOW_REJECTION_SUCCESS_MESSAGE'

type RejectionReasonFromData = { comment: string; rejectionReason: string }

type RejectionReasonModalProps = {
    onSubmit: (currentRejectionApplicationId: string, data: UpdateApplicationBody) => void
    currentRejectionApplicationId: string
    currentStatus: ApplicationStatusEnum
    currentRate?: number
    closeRejectionModal: () => void
}

const schema = yup.object().shape({
    rejectionReason: yup.string().required(),
    comment: yup.string().when('rejectionReason', { is: (value: string) => value === 'Other', then: yup.string().required() }),
})

const RejectionReasonModal: FunctionComponent<React.PropsWithChildren<RejectionReasonModalProps>> = ({
    onSubmit,
    currentRejectionApplicationId,
    currentStatus,
    currentRate,
    closeRejectionModal,
}) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, errors },
        trigger,
    } = useForm<RejectionReasonFromData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const logError = useLogger('error')
    const { addError } = useNotifications()

    const [rejectionReasons, setRejectionReasons] = useState<Array<Omit<ApplicationStatus, 'draft' | 'status'>>>([])

    const loadRejectionReasons = useCallback(() => {
        getRejectionReasons()
            .then(values =>
                setRejectionReasons(
                    values.rejectionReasons[currentStatus as keyof typeof ApplicationRejectionStatusEnum].sort((a, b) =>
                        a.label === 'Other' ? 1 : b.label === 'Other' ? -1 : alphabeticallyAsc(a.label, b.label),
                    ),
                ),
            )
            .catch(e => {
                addError()
                logError(e)
            })
    }, [addError, currentStatus, logError])

    useEffect(() => loadRejectionReasons(), [loadRejectionReasons])

    const [rejectionReason, setRejectionReason] = useState<Nullable<string>>(null)
    const [comment, setComment] = useState<Nullable<string>>(null)

    const submitCallback = useCallback(
        (data: RejectionReasonFromData) => {
            const reason = rejectionReasons.find(({ label }) => label === data.rejectionReason)?.rejectionReason
            setRejectionReason(data.rejectionReason)
            setComment(data.comment)
            if (reason) {
                onSubmit(currentRejectionApplicationId, {
                    ...(currentRate !== undefined && { internalRate: currentRate || null }),
                    status: {
                        rejectionReason: reason,
                        rejectionReasonComment: data.comment,
                        status: currentStatus,
                    },
                })
            }
        },
        [currentRate, currentRejectionApplicationId, currentStatus, onSubmit, rejectionReasons],
    )
    const theme = useTheme()

    const onBeforeBlur = useCallback(() => trigger('comment'), [trigger])

    return (
        <Modal opened onClose={closeRejectionModal}>
            {currentRejectionApplicationId === SHOW_REJECTION_SUCCESS_MESSAGE ? (
                <div>
                    <FlexBox justifyContent='center'>
                        <Icon
                            name='remove-summary'
                            size={85}
                            css={css`
                                margin: 0 auto;
                            `}
                        />
                    </FlexBox>
                    <h5
                        css={css`
                            text-align: center;
                        `}
                    >
                        Candidate Rejected
                    </h5>
                    <p
                        css={css`
                            color: ${theme.colors.gray_4};
                        `}
                    >
                        Rejection reason
                    </p>
                    <p data-testid='rejection-summary'>{rejectionReason === 'Other' ? comment : rejectionReason}</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit(submitCallback)}>
                    <h5>Are you sure you want to reject this Candidate?</h5>
                    <p>Please provide a reason for the rejection. Your response will be visible to the candidate's manager.</p>
                    <ControlledAutocompleteSelect
                        canFilter={false}
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        label='Rejection reason'
                        placeholder='Choose reason'
                        options={rejectionReasons.map(({ label }) => label)}
                        control={control}
                        name='rejectionReason'
                        dataTestId='rejection-reason'
                        beforeBlur={onBeforeBlur}
                        styles={css`
                            margin-bottom: 24px;
                        `}
                    />
                    <ControlledTextarea label='Note (optional)' name='comment' control={control} />
                    <Divider />
                    <FlexBox alignItems='center' justifyContent='flex-end'>
                        <Button variant='link' onClick={closeRejectionModal}>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            style={css`
                                margin-left: 16px;
                            `}
                            disabled={hasErrors(errors) || !isDirty || isSubmitting}
                            dataTestId='opportunity-reject'
                        >
                            Reject
                        </Button>
                    </FlexBox>
                </form>
            )}
        </Modal>
    )
}

export { RejectionReasonModal, SHOW_REJECTION_SUCCESS_MESSAGE }
