/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { AccessLevelContext } from '../../../AccessLevelContext'
import { Divider } from '../../../components/Divider'
import { IconButton } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { TooltipIcon } from '../../../components/TooltipIcon'
import { Specialist } from '../../../contracts/specialist/specialist'
import { mqMin } from '../../../GlobalStyle'
import { COLOR_PALETTE } from '../../../theme/colors'
import { SpecialistNameForm } from '../specialist-profile/SpecialistNameForm'

type HeaderWithActionsProps = {
    header: string
    style?: SerializedStyles
    specialistData?: Specialist
    onSave?: () => void
    specialistId?: string
    isSearchPage?: boolean
}

const HeaderWithActions: FunctionComponent<React.PropsWithChildren<HeaderWithActionsProps>> = ({
    header,
    style,
    specialistData,
    specialistId,
    onSave,
    children,
    isSearchPage = false,
}) => {
    const isEditModePossible = useMemo(() => onSave && specialistData && specialistId, [onSave, specialistData, specialistId])
    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const enableEditMode = useCallback(() => setEditModeEnabled(true), [setEditModeEnabled])
    const disableEditMode = useCallback(() => setEditModeEnabled(false), [setEditModeEnabled])

    const {
        mySpecialists: {
            profileInformation: { update: canUpdateProfileInformation, read: canReadProfileInformation },
        },
    } = useContext(AccessLevelContext)

    const handleSave = useCallback(() => {
        disableEditMode()
        if (onSave) {
            onSave()
        }
    }, [disableEditMode, onSave])

    return (
        <FlexBox
            justifyContent='space-between'
            alignItems='flex-start'
            css={css`
                margin-bottom: ${(isSearchPage ? '0px' : isEditModePossible && editModeEnabled) ? '-2px' : '16px'};
                flex-direction: column;
                ${style}
                ${mqMin[1]} {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: ${isSearchPage ? '0px' : isEditModePossible && editModeEnabled ? '7px' : '16px'};
                }
            `}
        >
            {canUpdateProfileInformation && isEditModePossible && editModeEnabled ? (
                <SpecialistNameForm
                    specialistId={specialistId as string}
                    specialistData={specialistData as Specialist}
                    onCancel={disableEditMode}
                    onSubmit={handleSave}
                />
            ) : (
                <Fragment>
                    <h4
                        css={css`
                            margin-bottom: 16px;
                        `}
                    >
                        {canReadProfileInformation && header}
                        {canUpdateProfileInformation && isEditModePossible && (
                            <span
                                data-testid='header-with-actions-edit'
                                css={css`
                                    margin-left: 4px;
                                `}
                            >
                                <IconButton name='edit' size={32} onClick={enableEditMode} />
                            </span>
                        )}
                    </h4>
                    {children && (
                        <div
                            css={css`
                                margin-bottom: 16px;
                                flex-shrink: 0;
                            `}
                        >
                            {children}
                        </div>
                    )}
                </Fragment>
            )}
        </FlexBox>
    )
}

type SectionHeaderWithActionsProps = HeaderWithActionsProps & {
    divider?: boolean
    tooltip?: string
    subheader?: boolean
    isPrintable?: boolean
    disabled?: boolean
}

const SectionHeaderWithActions: FunctionComponent<React.PropsWithChildren<SectionHeaderWithActionsProps>> = ({
    header,
    divider,
    subheader,
    tooltip,
    children,
    isPrintable,
    disabled = false,
}) => (
    <div>
        <FlexBox justifyContent='space-between' alignItems='center'>
            <h6
                css={css`
                    margin-bottom: 0px;
                    ${subheader ? 'font-size: 14px;' : null}
                    ${disabled && `color:${COLOR_PALETTE.gray_3};`}
                `}
            >
                <span
                    css={css`
                        line-height: 32px;
                        white-space: nowrap;
                        ${isPrintable ? 'font-size: 12px;' : null}
                        content: ${header};
                    `}
                >
                    {header}
                </span>
                {tooltip && (
                    <span
                        css={css`
                            display: inline-block;
                            margin-left: 10px;
                            top: -1px;
                            position: relative;
                        `}
                    >
                        <Tooltip title={tooltip} placement='top'>
                            <span>
                                <TooltipIcon />
                            </span>
                        </Tooltip>
                    </span>
                )}
            </h6>
            <div>{children}</div>
        </FlexBox>
        {divider && <Divider />}
    </div>
)

export { HeaderWithActions, SectionHeaderWithActions }
