/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

type VioletBoxProps = {
    header: string
    description?: string
    styles?: SerializedStyles
}

const VioletBox: FunctionComponent<React.PropsWithChildren<VioletBoxProps>> = ({ header, description, styles }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                background-color: ${theme.colors.violet_2};
                padding: 8px 16px;
                margin-bottom: 1px;

                ${styles}
            `}
        >
            <div
                css={css`
                    font-weight: 500;
                `}
            >
                {header}
            </div>
            {description && (
                <div
                    css={css`
                        line-height: 16px;
                        font-size: 12px;
                        color: ${theme.colors.gray_4};
                    `}
                >
                    {description}
                </div>
            )}
        </div>
    )
}

export { VioletBox }
