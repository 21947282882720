/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'

const ComponentsCache: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <div
            css={css`
                position: absolute;
                top: -10000px;
                left: -10000px;
            `}
        >
            {children}
        </div>
    )
}

export { ComponentsCache }
