import React, { FunctionComponent } from 'react'

type StripePricingTableProps = {
    companyId: string
}

const StripePricingTable: FunctionComponent<StripePricingTableProps> = ({ companyId }) => {
    return React.createElement('stripe-pricing-table', {
        'pricing-table-id': 'prctbl_1NESpIG4MZLRi60cxYkLpQ6x',
        'publishable-key': 'pk_test_51NE9RnG4MZLRi60clmyl637BeTkWZJBQmCSLScGRV3nSFM1Ge5K6xVTTqWjof0FPWYwyUEzjRFESZcuIwjYZXZgv00SrwruaP7',
        'client-reference-id': companyId,
    })
}
export default StripePricingTable
