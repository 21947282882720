/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, ReactNode } from 'react'
import { mqMin } from '../GlobalStyle'
import { COLOR_PALETTE } from '../theme/colors'
import { AppIcon, IconName } from './Icon'

export type ThankYouViewProps = {
    title: string
    description?: ReactNode
    iconName: IconName
}

const ThankYouView: FunctionComponent<React.PropsWithChildren<ThankYouViewProps>> = ({ title, description, iconName }) => (
    <div
        css={css`
            display: flex;
            flex-direction: column;
            height: calc(100vh - 130px);
        `}
    >
        <div
            css={css`
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                max-width: 610px;
                ${mqMin[1]} {
                    padding-bottom: 88px;
                }
            `}
        >
            <AppIcon
                status='static'
                name={iconName}
                size={80}
                css={css`
                    margin: 0 0 32px;
                `}
            />
            <h3
                css={css`
                    margin-bottom: 30px;
                    color: ${COLOR_PALETTE.gray_6};
                    text-align: center;
                `}
            >
                {title}
            </h3>

            {description && (
                <div
                    css={css`
                        font-size: 14px;
                        line-height: 26px;
                        color: ${COLOR_PALETTE.gray_6};
                        text-align: center;
                    `}
                >
                    {description}
                </div>
            )}
        </div>
    </div>
)

export { ThankYouView }
