/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BackLink } from '../../../components/BackLink'
import { Divider } from '../../../components/Divider'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../../components/layout/Loader'
import { useQuery } from '../../../utils/useQuery'
import { applicationName, generateMenuItems } from './../strengthDiscoveryApp'

const StrengthFormView = () => {
    const [loading, setLoading] = useState<boolean>(true)
    const [link, setLink] = useState<string>('')
    const query = useQuery()
    const { specialistId } = useParams<{ specialistId: string }>()

    useEffect(() => {
        const formLink = query.get('formLink')

        if (formLink) {
            setLink(formLink)
        }
    }, [query])

    return (
        <DashboardLayout
            applicationName={applicationName}
            applicationMenuItems={specialistId ? generateMenuItems(specialistId) : undefined}
        >
            <BackLink
                style={css`
                    margin: 40px 0px 24px 24px;
                `}
                text='Back to Available Strength Discovery'
                path={`/strength-discovery/${specialistId}`}
            />
            <Divider />
            {loading && <ContentLoader />}
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                `}
            >
                <iframe
                    onLoad={() => setLoading(false)}
                    title='Strength form'
                    src={link}
                    css={css`
                        border: none;
                        width: 100vw;
                        height: calc(100vh - 180px);
                    `}
                ></iframe>
            </div>
        </DashboardLayout>
    )
}

export { StrengthFormView }
