/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { InfoAlert } from '../../../../components/InfoAlert'
import { TagInput } from '../../../../components/inputs/tag-input/TagInput'
import { Modal } from '../../../../components/Modal'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { COLOR_PALETTE } from '../../../../theme/colors'
import { emailRegex } from '../../../../utils/regexes'
import { useLogger } from '../../../../utils/useLogger'
import { inviteVendorsToCompany } from '../../api'

type MyMVendorsModalProps = {
    handleCloseModal: () => void
    opened: boolean
    fetchData: () => void
}

const MyVendorsModal: FunctionComponent<React.PropsWithChildren<MyMVendorsModalProps>> = ({ opened, handleCloseModal, fetchData }) => {
    const [tags, setTags] = useState<Array<string>>([])
    const [errorMessage, setErrorMessage] = useState('')
    const [errors, setErrors] = useState<Array<string>>([])

    const { addSuccess, addError } = useNotifications()
    const log = useLogger()

    const isDuplicated = useMemo(() => new Set(tags).size !== tags.length, [tags])

    useEffect(() => {
        if (errors.length === 0) {
            setErrorMessage('')
        } else {
            setErrorMessage(
                `You have entered ${errors.length} invalid email ${
                    errors.length > 1 ? 'addresses' : 'address'
                }. Please check for typos and enter new ${errors.length > 1 ? 'one' : 'ones'}.`,
            )
        }
    }, [errors.length])

    const handleTagChange = useCallback((innerTags: Array<string>): void => {
        setTags(innerTags)
        setErrors([...innerTags.filter(tag => !tag.match(emailRegex))])
    }, [])

    const onSubmit = useCallback(() => {
        if (tags.length > 0) {
            inviteVendorsToCompany(tags)
                .then(() => {
                    addSuccess('Invitations sent successfully')
                    setTags([])
                })
                .catch(error => {
                    log(error)
                    addError()
                })
                .finally(() => {
                    handleCloseModal()
                    fetchData()
                })
        }
    }, [addError, addSuccess, fetchData, handleCloseModal, log, tags])

    return (
        <div>
            <Modal opened={opened} onClose={handleCloseModal} dataTestId='my-vendors-modal'>
                <h5>My Vendors</h5>
                <p>
                    Invite new vendors to your Vendor Management Cloud. They will receive an email allowing them to access your VMC and set
                    up their account. They will be able to see your Open Vacancies but not other vendors’ applications.
                </p>
                <p>
                    If you want your vendors to get an email with your logo and access the platform marked with your logo and colors, go to
                    the person icon in the upper right corner, then to account settings, and select white label to set your preferences (if
                    you haven't already).
                </p>
                <p>Please provide an email address for your vendor. </p>

                <div>
                    <TagInput
                        errorMessage={errorMessage}
                        tags={tags}
                        label='E-mail Address(-es)'
                        placeholder='Type here and press enter...'
                        onChange={handleTagChange}
                        validationRegex={emailRegex}
                        addKeys={['Enter', 'Tab', ';', ' ', ',']}
                        removeOnBackspace
                    />
                    {isDuplicated && (
                        <InfoAlert
                            description='Some email addresses are duplicated. We will ignore the duplicates and send the invitation email to only one of the
                duplicated mails'
                        />
                    )}
                </div>
                <p
                    css={css`
                        color: ${COLOR_PALETTE.gray_4};
                        font-size: 12px;
                    `}
                >
                    Tip: You can also paste multiple emails if they are separeted with a space, comma, semicolon or a new line.
                </p>
                <Divider />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button variant='text' onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button onClick={onSubmit} disabled={errors.length > 0 || tags.length === 0}>
                        Send Invites
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export { MyVendorsModal }
