/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'

type PageWidthWrapperProps = {
    style?: SerializedStyles
}

const PageWidthWrapper: FunctionComponent<React.PropsWithChildren<PageWidthWrapperProps>> = ({ children, style }) => (
    <div
        css={css`
            max-width: 1160px;
            width: 100%;
            margin: 0 auto;

            ${style}
        `}
    >
        {children}
    </div>
)

export { PageWidthWrapper }
