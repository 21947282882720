import { axios } from '../../api/axios'
import { InstantOnboardingState } from './contracts'

const specialistsEndpoint = `/specialists`

const getIsOnboardingStarted = (specialistId: string): Promise<{ started: boolean }> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/onboarding:started`)
}

const instantOnboardingEndpoint = '/instantOnboarding'

const instantOnboardingInit = (): Promise<InstantOnboardingState> => {
    return axios.get(`${instantOnboardingEndpoint}/init`)
}

const instantOnboardingNextStep = (state: InstantOnboardingState): Promise<InstantOnboardingState> => {
    return axios.post(`${instantOnboardingEndpoint}/nextStep`, state)
}

const instantOnboardingPrevStep = (state: InstantOnboardingState): Promise<InstantOnboardingState> => {
    return axios.post(`${instantOnboardingEndpoint}/previousStep`, state)
}

const instantOnboardingFinalize = (state: InstantOnboardingState): Promise<InstantOnboardingState> => {
    return axios.post(`${instantOnboardingEndpoint}/finalize`, state)
}

export { getIsOnboardingStarted, instantOnboardingInit, instantOnboardingNextStep, instantOnboardingPrevStep, instantOnboardingFinalize }
