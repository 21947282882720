/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { HeaderBox } from './HeaderBox'

export type ApplicationMenuItemEntry = {
    path: string
    title: string
    dataTestId?: string
}

type ApplicationMenuProps = {
    items: Array<ApplicationMenuItemEntry>
}

const ApplicationMenu: FunctionComponent<React.PropsWithChildren<ApplicationMenuProps>> = ({ items }) => {
    const location = useLocation()

    return (
        <div
            css={css`
                display: flex;
                ${mqMax[1]} {
                    margin-top: 57px;
                }
            `}
        >
            {items.map(item => {
                const isActive = location.pathname === item.path
                return (
                    <Link
                        to={item.path}
                        key={item.path}
                        css={css`
                            text-decoration: none;
                        `}
                    >
                        <HeaderBox isActive={isActive} dataTestId={item.dataTestId}>
                            {isActive ? (
                                <strong
                                    css={css`
                                        color: ${COLOR_PALETTE.gray_6};
                                    `}
                                >
                                    {item.title}
                                </strong>
                            ) : (
                                <span
                                    css={css`
                                        color: ${COLOR_PALETTE.gray_6};
                                    `}
                                >
                                    {item.title}
                                </span>
                            )}
                        </HeaderBox>
                    </Link>
                )
            })}
        </div>
    )
}

export { ApplicationMenu }
