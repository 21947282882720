/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { Icon } from '../Icon'

enum CheckItemColors {
    GREY,
    BLACK,
    RED,
}

const CheckItem = ({
    children,
    color = CheckItemColors.GREY,
    style,
}: {
    children: any
    color?: CheckItemColors
    style?: SerializedStyles
}) => {
    const getColorStyle = () => {
        switch (color) {
            case CheckItemColors.BLACK:
                return `color: ${COLOR_PALETTE.gray_6};`
            case CheckItemColors.GREY:
                return `color: ${COLOR_PALETTE.gray_3};`
            case CheckItemColors.RED:
                return `color: ${COLOR_PALETTE.red_4};`
        }
    }

    return (
        <span
            css={css`
                display: flex;
                align-items: center;
                ${getColorStyle()}

                ${style}
            `}
        >
            {color === CheckItemColors.BLACK && (
                <Icon
                    name='check'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {color === CheckItemColors.GREY && (
                <Icon
                    name='check-grey'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {color === CheckItemColors.RED && (
                <Icon
                    name='check-red'
                    style={css`
                        margin-right: 6px;
                    `}
                />
            )}
            {children}
        </span>
    )
}

export { CheckItem, CheckItemColors }
