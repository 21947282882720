/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Row } from 'antd'
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useInterval } from 'react-use'
import { getSpecialistCvParsingStatus, removeSpecialistCv, uploadSpecialistCv } from '../../api/api'
import linkedinCvTip from '../../assets/images/linkedin_cv_tip.png'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { ErrorAlert } from '../../components/ErrorAlert'
import { CvDropzone } from '../../components/inputs/CvDropzone'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { Modal } from '../../components/Modal'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { CvSourceType, SpecialistCv, SpecialistCvStatus } from '../../contracts/cvs'
import { mqMax } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { NullableArray } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { CvType } from './components/CvType'

const SpecialistCvParsingPage = () => {
    const {
        selectors: { decodedAccessToken },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const query = useQuery()

    const theme = useTheme()
    const [selectedCvType, setSelectedCvType] = useState<CvSourceType | undefined>(query.get('cvSource') as CvSourceType)
    const [cvParsingStatus, setCvParsingStatus] = useState<NullableArray<SpecialistCv>>(null)
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const log = useLogger('error')
    const { addError } = useNotifications()

    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])

    const toggleModal = useCallback(() => {
        setIsModalVisible(currentIsVisible => !currentIsVisible)
    }, [])

    const fetchSpecialistCvParsingStatus = useCallback(() => {
        getSpecialistCvParsingStatus(specialistId)
            .then(setCvParsingStatus)
            .catch(err => {
                log(err)
                addError()
            })
    }, [specialistId, log, addError])

    const handleDrop = useCallback(
        (files: File | Array<File>) => {
            if ((Array.isArray(files) && files.length > 0) || (!Array.isArray(files) && files)) {
                setIsUploading(true)
                const cvsData = new FormData()
                if (Array.isArray(files)) {
                    for (const file of files) {
                        cvsData.append('file', file)
                    }
                } else {
                    cvsData.append('file', files)
                }
                uploadSpecialistCv(cvsData, specialistId, selectedCvType === CvSourceType.LINKEDIN)
                    .then(() => {
                        fetchSpecialistCvParsingStatus()
                    })
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => setIsUploading(false))
            }
        },
        [specialistId, selectedCvType, fetchSpecialistCvParsingStatus, log, addError],
    )

    useEffect(() => {
        fetchSpecialistCvParsingStatus()
    }, [fetchSpecialistCvParsingStatus])

    useInterval(
        () => {
            fetchSpecialistCvParsingStatus()
        },
        cvParsingStatus?.some(status => status.status === SpecialistCvStatus.IN_PROGRESS) ? 5000 : null,
    )

    useEffect(() => {
        const pendingCv = cvParsingStatus?.find(status => status.status === SpecialistCvStatus.READY_FOR_VERIFICATION)
        const processingCv = cvParsingStatus?.find(status => status.status === SpecialistCvStatus.IN_PROGRESS)
        if (pendingCv) {
            if (pendingCv.cvSource === CvSourceType.LINKEDIN) {
                layoutToggleLoader(false)
                history.push(`/specialist-cv-parsing/${pendingCv.cvParsingId}/experience`)
            } else {
                layoutToggleLoader(false)
                history.push(`/specialist-cv-parsing/${pendingCv.cvParsingId}/skills`)
            }
        } else if (!cvParsingStatus || processingCv) {
            layoutToggleLoader(true)
        } else {
            layoutToggleLoader(false)
        }
    }, [cvParsingStatus, layoutToggleLoader])

    const handleFileDelete = useCallback(
        (cvParsingId: string | undefined) => {
            if (cvParsingStatus && cvParsingId) {
                removeSpecialistCv(specialistId, cvParsingId)
                    .then(() => {
                        fetchSpecialistCvParsingStatus()
                        setSelectedCvType(undefined)
                    })
                    .catch(err => {
                        log(err)
                        addError()
                    })
            }
        },
        [specialistId, cvParsingStatus, log, addError, fetchSpecialistCvParsingStatus],
    )

    const linkedInCvStatus = useMemo(() => cvParsingStatus?.find(status => status.cvSource === CvSourceType.LINKEDIN), [cvParsingStatus])
    const otherCvStatus = useMemo(() => cvParsingStatus?.find(status => status.cvSource === CvSourceType.OTHER), [cvParsingStatus])

    const errorMessage =
        (selectedCvType === CvSourceType.OTHER && otherCvStatus?.errorMessage) ||
        (selectedCvType === CvSourceType.LINKEDIN && linkedInCvStatus?.errorMessage) ||
        null

    return (
        <DashboardLayout applicationName='My Dashboard'>
            <div
                css={css`
                    max-width: 858px;
                    width: 100%;
                    margin: 0 auto;
                    padding: 24px;
                    ${mqMax[1]} {
                        margin-top: 57px;
                    }
                `}
            >
                <FlexBox
                    alignItems='center'
                    justifyContent='space-between'
                    css={css`
                        ${mqMax[1]} {
                            flex-direction: column;
                            text-align: center;
                        }
                    `}
                >
                    <div>
                        <BackLink
                            style={css`
                                margin: 0 0 24px;
                            `}
                            text='Back to dashboard'
                            path={`/specialist-dashboard`}
                        />
                        <h3>Select one of the options below</h3>
                    </div>
                </FlexBox>

                <Row gutter={[24, 24]}>
                    <CvType
                        iconName='pdf-black'
                        primaryText='Upload your existing CV'
                        secondaryText='to quickly retrieve and add your skills'
                        onClick={() => setSelectedCvType(CvSourceType.OTHER)}
                        isSelected={selectedCvType === CvSourceType.OTHER}
                        cvUrl={otherCvStatus?.cvDownloadUrl}
                        cvName={otherCvStatus?.fileName}
                        canDisplayLatestCv={otherCvStatus?.status !== SpecialistCvStatus.IN_PROGRESS}
                        parsingId={otherCvStatus?.cvParsingId}
                        handleFileDelete={handleFileDelete}
                        type={CvSourceType.OTHER}
                    />
                    <CvType
                        iconName='linkedin'
                        primaryText='Upload your saved LinkedIn profile'
                        secondaryText='to add your projects, education and skills'
                        onClick={() => setSelectedCvType(CvSourceType.LINKEDIN)}
                        isSelected={selectedCvType === CvSourceType.LINKEDIN}
                        cvUrl={linkedInCvStatus?.cvDownloadUrl}
                        cvName={linkedInCvStatus?.fileName}
                        canDisplayLatestCv={linkedInCvStatus?.status !== SpecialistCvStatus.IN_PROGRESS}
                        parsingId={linkedInCvStatus?.cvParsingId}
                        handleFileDelete={handleFileDelete}
                        type={CvSourceType.LINKEDIN}
                    />
                </Row>

                {selectedCvType && (
                    <Fragment>
                        <Divider
                            style={css`
                                margin: 24px 0;
                            `}
                        />
                        {errorMessage && (
                            <Fragment>
                                <div
                                    css={css`
                                        margin-bottom: 16px;
                                    `}
                                >
                                    <ErrorAlert>
                                        <span>{errorMessage}</span>
                                        <Button
                                            variant='secondary'
                                            size='small'
                                            style={css`
                                                margin-left: 16px;
                                            `}
                                            onClick={toggleModal}
                                        >
                                            Details
                                        </Button>
                                    </ErrorAlert>
                                </div>
                                <Modal onClose={toggleModal} opened={isModalVisible}>
                                    <h5>File upload failed</h5>
                                    <p
                                        css={css`
                                            color: ${theme.colors.red_4};
                                        `}
                                    >
                                        Please check your CV file and upload again. It cannot be larger than 20 MB and should be a .pdf
                                        {selectedCvType === CvSourceType.OTHER && ' or .docx'}.
                                    </p>
                                </Modal>
                            </Fragment>
                        )}
                        <h6>
                            {selectedCvType === CvSourceType.OTHER
                                ? 'Extract Information from your CV'
                                : 'Upload your saved LinkedIn profile'}
                        </h6>
                        <p>
                            {selectedCvType === CvSourceType.OTHER
                                ? 'Please note that we can extract only skills from your CV file. If you would like to also quickly add projects and education records you can upload your LinkedIn profile by selecting the other option above. To continue, please upload your CV here. It typically takes about 5 seconds to parse a single file.'
                                : 'Upload your saved LinkedIn profile here to extract your experience, education and skills. It typically takes about 5 seconds to parse a single file.'}
                        </p>
                        {selectedCvType === CvSourceType.LINKEDIN && (
                            <FlexBox
                                css={css`
                                    border-radius: 2px;
                                    border: 1px solid ${theme.colors.gray_2};
                                    color: ${theme.colors.gray_4};
                                    padding: 24px;
                                    align-items: center;
                                    margin: 24px 0;
                                    ${mqMax[1]} {
                                        flex-direction: column;
                                    }
                                `}
                            >
                                <div
                                    css={css`
                                        flex: 1;
                                    `}
                                >
                                    <div
                                        css={css`
                                            font-weight: 500;
                                            margin-bottom: 16px;
                                        `}
                                    >
                                        How to save LinkedIn Profile in .pdf version?
                                    </div>
                                    <ol
                                        css={css`
                                            padding-left: 20px;
                                        `}
                                    >
                                        <li>
                                            Go to{' '}
                                            <a href='https://www.linkedin.com/' target='_blank' rel='noopener noreferrer'>
                                                LinkedIn page
                                            </a>
                                            , enter your Profile.
                                        </li>
                                        <li>Click 'More' and select 'Save to PDF'.</li>
                                    </ol>
                                </div>
                                <div
                                    css={css`
                                        flex: 1;
                                    `}
                                >
                                    <img
                                        src={linkedinCvTip}
                                        alt='Linkedin cv tip'
                                        css={css`
                                            max-height: 135px;
                                            max-width: 100%;
                                        `}
                                    />
                                </div>
                            </FlexBox>
                        )}

                        <CvDropzone handleDrop={handleDrop} isUploading={isUploading} pdfOnly={selectedCvType === CvSourceType.LINKEDIN} />
                        <span
                            css={css`
                                display: block;
                                margin-top: 24px;
                                color: ${theme.colors.gray_3};
                                font-size: 12px;
                            `}
                        >
                            Your CV file should be in .docx or .pdf format and cannot be larger than 20 MB.
                        </span>
                    </Fragment>
                )}
            </div>
        </DashboardLayout>
    )
}

export { SpecialistCvParsingPage }
