import { OptionObject } from '../../../inputs/autocomplete-select/Option'
import {
    CountriesAndRegionsGroupsEnum,
    OpportunityLocationTypeEnum,
    OpportunityLocationTypeV2Enum,
    OpportunityOfferTypeEnum,
    OpportunityResponseBody,
    UpdateOpportunityFormData,
} from '../../../../contracts/opportunities-manager/contracts'

const getDefaultEmptyValues = (): UpdateOpportunityFormData => {
    return {
        opportunityTitle: '',
        internalDetails: {
            internalName: '',
            clientName: '',
            opportunityOwner: '',
            internalNotes: '',
            generatedId: '',
            customCvTemplate: false,
            earlyAccessExpiration: null,
        },
        projectDetails: {
            projectTitle: '',
            projectIndustry: '',
            paymentPeriod: '',
            projectDescription: '',
        },
        opportunityDetails: {
            startDate: '',
            endDate: '',
            offerType: OpportunityOfferTypeEnum.INDIVIDUAL,
            numberOfVacancies: '',
            currency: '',
            minimumRate: '',
            maximumRate: '',
        },
        location: {
            locationType: OpportunityLocationTypeEnum.ANYWHERE,
            locationTypeV2: OpportunityLocationTypeV2Enum.REMOTE,
            countriesAndRegions: [],
            countryCitiesList: [],
            otherLocationRequirements: false,
            timeZone: '',
            otherLocationRequirementsText: '',
        },
        jobDetails: {
            technicalRequirements: '',
            minYearsOfExperience: '',
            requiredTechnicalSkills: [],
            keyTechnicalSkills: [],
            preferredTechnicalSkills: [],
            requiredCertifications: '',
            mainResponsibilities: '',
            jobDescription: '',
        },
        contactDetails: {
            contactEmail: '',
        },
    }
}

const getDefaultValues = (opportunity?: OpportunityResponseBody): UpdateOpportunityFormData => {
    return {
        opportunityTitle: opportunity?.opportunityTitle || '',
        internalDetails: {
            internalName: opportunity?.internalDetails.internalName || '',
            clientName: opportunity?.internalDetails.clientName || '',
            opportunityOwner: opportunity?.internalDetails.opportunityOwner.userId || '',
            internalNotes: opportunity?.internalDetails.internalNotes || '',
            generatedId: opportunity?.internalDetails.generatedId || '',
            customCvTemplate: opportunity?.internalDetails.customCvTemplate || false,
            earlyAccessExpiration: opportunity?.internalDetails.earlyAccessExpiration || null,
        },
        projectDetails: {
            projectTitle: opportunity?.projectDetails.projectTitle || '',
            projectIndustry: opportunity?.projectDetails.projectIndustry || '',
            paymentPeriod: opportunity?.projectDetails.paymentPeriod || '',
            projectDescription: opportunity?.projectDetails.projectDescription || '',
        },
        opportunityDetails: {
            startDate: opportunity?.opportunityDetails.startDate || '',
            endDate: opportunity?.opportunityDetails.endDate || '',
            offerType: opportunity?.opportunityDetails.offerType || OpportunityOfferTypeEnum.INDIVIDUAL,
            numberOfVacancies: opportunity?.opportunityDetails.numberOfVacancies || '',
            currency: opportunity?.opportunityDetails.currency || '',
            minimumRate: opportunity?.opportunityDetails.minimumRate || '',
            maximumRate: opportunity?.opportunityDetails.maximumRate || '',
        },
        location: {
            locationType: opportunity?.location.locationType || OpportunityLocationTypeEnum.ANYWHERE,
            locationTypeV2: opportunity?.location.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE,
            countriesAndRegions:
                opportunity?.location.countriesWithCities
                    .map(country => ({ value: country.country, groupName: CountriesAndRegionsGroupsEnum.COUNTRIES }))
                    .concat(
                        opportunity?.location.regions.map(region => ({ value: region, groupName: CountriesAndRegionsGroupsEnum.REGIONS })),
                    ) || [],
            countryCitiesList:
                opportunity?.location.countriesWithCities.reduce<Array<OptionObject>>(
                    (prev, current) => prev.concat(current.cities.map(city => ({ value: city, groupName: current.country }))),
                    [],
                ) || [],
            otherLocationRequirements: opportunity?.location.otherLocationRequirements || false,
            timeZone: opportunity?.location.timeZone || '',
            otherLocationRequirementsText: opportunity?.location.otherLocationRequirementsText || '',
        },
        jobDetails: {
            technicalRequirements: opportunity?.jobDetails.technicalRequirements || '',
            minYearsOfExperience: opportunity?.jobDetails.minYearsOfExperience || '',
            requiredTechnicalSkills: opportunity?.jobDetails.requiredTechnicalSkills || [],
            keyTechnicalSkills: opportunity?.jobDetails.keyTechnicalSkills || [],
            preferredTechnicalSkills: opportunity?.jobDetails.preferredTechnicalSkills || [],
            requiredCertifications: opportunity?.jobDetails.requiredCertifications || '',
            mainResponsibilities: opportunity?.jobDetails.mainResponsibilities || '',
            jobDescription: opportunity?.jobDetails.jobDescription || '',
        },
        contactDetails: {
            contactEmail: opportunity?.contactDetails.contactEmail || '',
        },
    }
}

export { getDefaultValues, getDefaultEmptyValues }
