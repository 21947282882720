/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../Button'

type CreateCompanyButtonProps = {
    closeMenu?: () => void
}

const CreateCompanyButton: FunctionComponent<React.PropsWithChildren<CreateCompanyButtonProps>> = ({ closeMenu }) => {
    return (
        <Link
            css={css`
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
            `}
            onClick={closeMenu}
            to='/dashboard/company-account/company-details/form'
        >
            <Button>Create Company Account</Button>
        </Link>
    )
}

export default CreateCompanyButton
