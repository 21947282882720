/** @jsxImportSource @emotion/react */
import { Divider } from '../../components/Divider'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { PersonalDetailsForm } from '../../components/PersonalDetailsForm'

const AccountPersonalDetails = () => (
    <SettingsWrapper>
        <h4 data-testid='personal-details-check'>Personal Details</h4>
        <Divider />
        <PersonalDetailsForm />
    </SettingsWrapper>
)

export { AccountPersonalDetails }
