/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { AccessLevelContext } from '../../../AccessLevelContext'
import { ContextType } from '../../../contracts/contexts'
import { COLOR_PALETTE, mqMax, mqMin } from '../../../GlobalStyle'
import { history } from '../../../history'
import { useUserAuthentication } from '../../../hooks/useUserAuthentication'
import { useUserContext } from '../../../hooks/useUserContext'
import { ReduxContext } from '../../../redux/Store'
import { afterSuccessForbiddenPaths, getAfterSuccessParam } from '../../../utils/userContext'
import { Button } from '../../Button'
import { Icon } from '../../Icon'
import { Logo } from '../../Logo'
import { ApplicationBox } from './ApplicationBox'
import { ContextMenu } from './ContextMenu'
import { HeaderBox } from './HeaderBox'

const iconStyles = css`
    width: 20px;
    height: 20px;
`

enum TabType {
    leftMenu = 1,
    userMenu = 3,
}

const LogoComponent: FunctionComponent<React.PropsWithChildren<{ forcedLogoUrl?: string }>> = ({ forcedLogoUrl }) => (
    <Logo
        forcedLogoUrl={forcedLogoUrl}
        type='logoFull'
        style={css`
            object-fit: contain;
            height: auto;
            width: auto;
            max-width: 180px;
            max-height: 40px;
            ${mqMax[0]} {
                max-width: 100%;
            }
        `}
    />
)

type HeaderProps = {
    applicationName?: string
    showMenuItems?: boolean
    forcedLogoUrl?: string
}

const Header: FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({
    applicationName,
    children,
    showMenuItems = true,
    forcedLogoUrl,
}) => {
    const theme = useTheme()
    const { isUserAuthorized, isSpecialistAccount } = useUserAuthentication()

    const {
        actions: { layoutToggleActiveTab },
        selectors: { layoutActiveTab, user, decodedAccessToken, whiteLabel },
    } = useContext(ReduxContext)

    const { userContexts, userActiveContextId } = useUserContext()

    const {
        dashboard: { myAccount: myAccountAccess, companyAccount: companyAccountAccess },

        header: { appsMenu, appLabel, menuItems },
    } = useContext(AccessLevelContext)

    const toggleActiveTab = (tab: TabType) => {
        layoutToggleActiveTab(tab)
    }

    const settingsMenuItems = useMemo(() => {
        const settingsMenuItemsArray = []
        if (myAccountAccess) {
            settingsMenuItemsArray.push({
                path: '/dashboard/my-account/personal-details',
                title: 'My Account',
                showDivider: !companyAccountAccess,
            })
        }
        if (companyAccountAccess) {
            settingsMenuItemsArray.push({
                path: '/dashboard/company-account/company-details',
                title: 'Company Account',
                dataTestId: 'settings-menu-company-account',
                showDivider: true,
            })
        }
        return settingsMenuItemsArray
    }, [myAccountAccess, companyAccountAccess])

    const contextsMenuItems = useMemo(
        () =>
            (userContexts || [])
                .filter(context => context.type !== ContextType.USER)
                .map((context, index) => {
                    const canPassAfterSuccess = !afterSuccessForbiddenPaths.includes(history.location.pathname)
                    return {
                        path: `/refresh-user-context?wantedContextId=${context.contextId}${
                            canPassAfterSuccess ? `&${getAfterSuccessParam()}` : ''
                        }`,
                        title: context.contextName,
                        dataTestId: `context-menu-item-${context.type.toLowerCase()}`,
                        showDivider: !!(
                            userContexts && index === userContexts.filter(context => context.type !== ContextType.USER).length - 1
                        ),
                        isHighlighted: context.contextId === userActiveContextId,
                    }
                }),
        [userContexts, userActiveContextId],
    )

    const hasCustomLogo = useMemo(() => whiteLabel?.active && whiteLabel.logoUrl, [whiteLabel])

    return (
        <header
            css={css`
                background-color: ${COLOR_PALETTE.white};
                box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                top: 0;
                left: 0;
                width: 100%;
                z-index: 10;

                ${mqMin[2]} {
                    position: relative;
                }

                ${mqMax[1]} {
                    position: fixed;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                `}
            >
                {appsMenu && showMenuItems && (
                    <HeaderBox
                        onClick={() => toggleActiveTab(TabType.leftMenu)}
                        isActive={layoutActiveTab === TabType.leftMenu}
                        style={css`
                            &:before {
                                display: none;
                            }
                            @media (hover: hover) and (pointer: fine) {
                                &:hover {
                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                        `}
                    >
                        <Icon name='grid' style={iconStyles} dataTestId='menu-grid' />
                    </HeaderBox>
                )}

                <HeaderBox
                    style={css`
                        padding-top: 4px;
                        margin-left: ${appsMenu && showMenuItems ? '0px' : '60px'};

                        ${mqMax[0]} {
                            min-width: 10%;
                            margin-left: 0;
                        }
                    `}
                    isDisabled={!(myAccountAccess || companyAccountAccess)}
                >
                    {isUserAuthorized ? (
                        <Link to={isSpecialistAccount ? '/specialist-dashboard' : '/dashboard'}>
                            <LogoComponent forcedLogoUrl={forcedLogoUrl} />
                        </Link>
                    ) : (
                        <LogoComponent forcedLogoUrl={forcedLogoUrl} />
                    )}
                </HeaderBox>

                {appLabel && (
                    <Fragment>
                        {applicationName && (
                            <ApplicationBox
                                css={css`
                                    margin-left: 8px;
                                `}
                            >
                                {applicationName}
                            </ApplicationBox>
                        )}

                        <div
                            css={css`
                                margin-left: 70px;
                                display: none;
                                justify-content: center;
                                align-items: center;
                                ${mqMin[1]} {
                                    display: flex;
                                }
                            `}
                        >
                            {children}
                        </div>
                    </Fragment>
                )}
            </div>
            {menuItems && showMenuItems && (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    {hasCustomLogo && (
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            `}
                        >
                            <div
                                css={css`
                                    text-align: right;
                                    font-size: 14px;
                                    font-weight: 500;
                                `}
                            >
                                Powered by
                            </div>

                            <Logo
                                forceTalentAlpha
                                type='logoFull'
                                style={css`
                                    height: 24px;
                                    width: 90px;
                                `}
                            />
                        </div>
                    )}

                    <div
                        css={css`
                            display: flex;
                            width: 1px;
                            height: 56px;
                            background-color: ${COLOR_PALETTE.gray_2};
                        `}
                    ></div>
                    <HeaderBox onClick={() => toggleActiveTab(TabType.userMenu)} isActive={layoutActiveTab === TabType.userMenu}>
                        <Icon name='user' style={iconStyles} dataTestId='user-menu' />
                        {layoutActiveTab === TabType.userMenu && (
                            <ContextMenu
                                items={[
                                    {
                                        title: user.email ? user.email : undefined,
                                    },
                                    ...settingsMenuItems,
                                    ...contextsMenuItems,
                                    {
                                        path: '/documents/terms-of-use',
                                        title: 'Terms of use',
                                        isExternal: true,
                                        dataTestId: 'terms-of-use',
                                    },
                                    {
                                        path: '/documents/privacy-policy',
                                        title: 'Privacy Policy',
                                        showDivider: true,
                                        isExternal: true,
                                        dataTestId: 'privacy-policy',
                                    },
                                    {
                                        path: '/logout/user',
                                        title: 'Log out',
                                        dataTestId: 'logout',
                                    },
                                ]}
                                styles={css`
                                    -ms-overflow-style: none;
                                    scrollbar-width: none;
                                    ::-webkit-scrollbar {
                                        display: none;
                                    }
                                `}
                            />
                        )}
                    </HeaderBox>
                </div>
            )}
            {decodedAccessToken?.specialistId && (
                <div
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 56px;
                        border-left: 1px solid ${theme.colors.gray_2};
                    `}
                >
                    <Link to='/logout/specialist'>
                        <Button variant='text'>Log out</Button>
                    </Link>
                </div>
            )}
        </header>
    )
}

export { Header, TabType }
