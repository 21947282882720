/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useContext, useMemo } from 'react'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { AnimatedLeftMenu } from './AnimatedLeftMenu'
import { ApplicationMenu, ApplicationMenuItemEntry } from './ApplicationMenu'
import { Header, TabType } from './Header'
import { MainMenu } from './MainMenu'
import { VMCstatusEnum } from 'src/contracts/vmc'

const Col = styled.div`
    display: flex;
`

enum MenuType {
    myAccount,
    companyAccount,
    leftMenu,
}

type DashboardLayoutProps = {
    menuType?: MenuType
    showMenuItems?: boolean
    applicationName?: string
    applicationMenuItems?: Array<ApplicationMenuItemEntry>
    backgroundColor?: string
    forcedLogoUrl?: string
}

const DashboardLayout: FunctionComponent<React.PropsWithChildren<DashboardLayoutProps>> = ({
    children,
    menuType,
    applicationName,
    applicationMenuItems,
    showMenuItems = true,
    backgroundColor = COLOR_PALETTE.white,
    forcedLogoUrl,
}) => {
    const theme = useTheme()

    const {
        selectors: { layoutActiveTab, featureFlags, whiteLabel, VMCstatus },
    } = useContext(ReduxContext)

    const isVMCOwner = useMemo(() => VMCstatus === VMCstatusEnum.OWNER, [VMCstatus])
    const isVMCVendor = useMemo(() => VMCstatus === VMCstatusEnum.VENDOR, [VMCstatus])

    const companyAccountMenuItems = useMemo(() => {
        const items = [
            {
                title: 'Company Details',
                path: '/dashboard/company-account/company-details',
            },
        ]

        if (featureFlags.whiteLabel && !whiteLabel?.readOnlySettings && !isVMCVendor) {
            items.push({
                title: 'White Label',
                path: '/dashboard/company-account/white-label',
            })
        }

        if (featureFlags.privateHumanCloud && !isVMCVendor && !isVMCOwner) {
            items.push({
                title: 'Private Human Cloud',
                path: '/dashboard/company-account/private-human-cloud',
            })
        }

        items.push({
            title: 'Role Management',
            path: '/dashboard/company-account/role-management',
        })

        if (featureFlags.payments) {
            items.push({
                title: 'Subscription',
                path: '/dashboard/company-account/subscription',
            })
        }
        if (isVMCOwner) {
            items.push({
                title: 'My Vendors',
                path: '/dashboard/company-account/my-vendors',
            })
        }

        return items
    }, [
        featureFlags.privateHumanCloud,
        featureFlags.payments,
        featureFlags.whiteLabel,
        whiteLabel?.readOnlySettings,
        isVMCOwner,
        isVMCVendor,
    ])

    return (
        <section
            css={css`
                background-color: ${backgroundColor};
                position: relative;
                min-height: 100vh;
                height: 100%;
            `}
        >
            <Header showMenuItems={showMenuItems} applicationName={applicationName} forcedLogoUrl={forcedLogoUrl}>
                {applicationMenuItems && <ApplicationMenu items={applicationMenuItems} />}
            </Header>

            <AnimatedLeftMenu show={layoutActiveTab === TabType.leftMenu} />

            <section
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: stretch;
                    ${mqMin[2]} {
                        padding-top: 0;
                        flex-direction: row;
                        justify-content: space-between;
                    }
                `}
            >
                <Col
                    css={css`
                        border-right: 1px solid ${COLOR_PALETTE.gray_2};
                        background-color: ${COLOR_PALETTE.white};
                        z-index: 3;
                    `}
                >
                    {menuType === MenuType.myAccount && (
                        <MainMenu
                            id='myAccount'
                            label='My Account'
                            menuItems={[
                                { title: 'Personal Details', path: '/dashboard/my-account/personal-details' },
                                { title: 'Login Settings', path: '/dashboard/my-account/login-settings' },
                                { title: 'E-mail Notifications', path: '/dashboard/my-account/email-notifications' },
                            ]}
                        />
                    )}

                    {menuType === MenuType.companyAccount && (
                        <MainMenu id='companyAccount' label='Company Account' menuItems={companyAccountMenuItems} />
                    )}
                </Col>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    {applicationMenuItems && showMenuItems && (
                        <div
                            css={css`
                                display: block;
                                padding: 0 8px;
                                background-color: ${theme.colors.white};
                                box-shadow: 0px 4px 12px ${theme.colors.box_shadow_color};
                                ${mqMin[1]} {
                                    display: none;
                                }
                            `}
                        >
                            <ApplicationMenu items={applicationMenuItems} />
                        </div>
                    )}
                    {children}
                </div>
            </section>
        </section>
    )
}

export { DashboardLayout, MenuType }
