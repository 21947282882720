// tslint:disable-next-line: ordered-imports
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom/client'
import { createRoot } from './createRoot'
import * as serviceWorker from './serviceWorker'

const appInit = async () => {
    const AppComponent = await createRoot()

    const root = ReactDOM.createRoot(document.getElementById('root') as Element)
    root.render(AppComponent)
}

appInit()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
