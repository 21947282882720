/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { saveAs } from 'file-saver'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo } from 'react'
import { MemoryRouter } from 'react-router'
import { useEffectOnce, useLocation } from 'react-use'
import { compile, serialize, stringify } from 'stylis'
import { FullProfileData } from '../../../../api/api'
import { axios } from '../../../../api/axios'
import { Button } from '../../../../components/Button'
import { ComponentsCache } from '../../../../components/ComponentsCache'
import { globalStyles } from '../../../../GlobalStyle'
import { ReduxContext } from '../../../../redux/Store'
import { ThemeProvider } from '../../../../ThemeProvider'
import { useLogger } from '../../../../utils/useLogger'
import { createIframe, extractHtml, generateStyleTag, hexToRgb, minifyHtml } from './../../components/PdfDoc/utils'

type PdfDocType = {
    customGlobalStyles?: string
    preview?: boolean
    fileName?: string
    printMargin?: boolean
    onCloseModal?: () => void
    cleanExportProfileState?: () => void
    profileData?: FullProfileData
}

const PdfDoc: FunctionComponent<React.PropsWithChildren<PdfDocType>> = ({
    children,
    customGlobalStyles = '',
    preview = false,
    fileName = 'profile',
    printMargin = false,
    onCloseModal,
    cleanExportProfileState,
    profileData = {},
}) => {
    const log = useLogger('error')

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const VirtualPdfDocument = useMemo(
        () => (
            <MemoryRouter>
                <ThemeProvider>{children}</ThemeProvider>
            </MemoryRouter>
        ),
        [children],
    )

    const theme = useTheme()
    const location = useLocation()
    const globalStylesSerialized = useMemo(() => hexToRgb(serialize(compile(globalStyles(theme).styles), stringify)), [theme])

    const generateHtml = useCallback(() => {
        const content = extractHtml(VirtualPdfDocument)

        return `
        <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Generated DOC</title>
                <style type="text/css">
                    body { 
                        z-index: 1;
                    }
                   
                    /* cyrillic */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73oDd4jQmfxIC7w.woff2) format('woff2');
                        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
                    }
                    /* latin-ext */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73ord4jQmfxIC7w.woff2) format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                    }
                    /* latin */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73oTd4jQmfxI.woff2) format('woff2');
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    /* cyrillic */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73oDd4jQmfxIC7w.woff2) format('woff2');
                        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
                    }
                    /* latin-ext */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73ord4jQmfxIC7w.woff2) format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                    }
                    /* latin */
                    @font-face {
                        font-family: 'Jost';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/jost/v6/92zatBhPNqw73oTd4jQmfxI.woff2) format('woff2');
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                </style>
                <style type="text/css" data-css="global">${globalStylesSerialized}</style>
                <style type="text/css" data-css="global">
                    .a4 {
                        width: 210mm;
                        margin: 0;
                    }
                    ${customGlobalStyles}
                </style>
                ${content.classDefinition.map(generateStyleTag).join('')}
                <base href="${location.origin}">
            </head>
            <body>
                <div class="a4">
                    ${content.html}
                </div>
            </body>
        </html>
    `
    }, [globalStylesSerialized, location.origin, VirtualPdfDocument, customGlobalStyles])

    const handleSavePdf = useCallback(() => {
        layoutToggleLoader(true)

        axios
            .post('/pub/v2/pdf', { data: profileData, printMargin }, { responseType: 'blob' })
            .then((res: any) => {
                saveAs(res, `${fileName}.pdf`)
                layoutToggleLoader(false)
            })

            .catch(log)
            .finally(() => {
                layoutToggleLoader(false)
                if (onCloseModal) onCloseModal()
                if (cleanExportProfileState) cleanExportProfileState()
            })
    }, [cleanExportProfileState, fileName, layoutToggleLoader, log, onCloseModal, printMargin, profileData])

    const handleSaveHtml = useCallback(() => {
        const html = minifyHtml(generateHtml())
        const blob = new Blob([html], { type: 'text/plain;charset=utf-8' })
        saveAs(blob, `${fileName}.html`)
    }, [generateHtml, fileName])

    useEffectOnce(() => {
        if (preview) {
            createIframe(generateHtml())
        } else {
            handleSavePdf()
        }
    })

    return (
        <Fragment>
            <ComponentsCache>{children}</ComponentsCache>

            {preview && (
                <section
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-items: stretch;
                        justify-content: stretch;
                        flex-direction: column;
                        height: 100vh;
                    `}
                >
                    <div
                        id='preview'
                        css={css`
                            display: flex;
                            justify-content: stretch;
                            width: 232mm;
                            border: 1px solid #ccc;
                            padding: 10mm;
                            margin-bottom: 20px;
                            flex: 7;

                            iframe {
                                width: 210mm;
                                border: 0;
                                border: 1px solid #ccc;
                            }
                        `}
                    ></div>

                    <div
                        css={css`
                            flex: 1;
                        `}
                    >
                        <Button
                            onClick={handleSaveHtml}
                            css={css`
                                margin-right: 30px;
                            `}
                        >
                            Save HTML
                        </Button>
                        <Button onClick={handleSavePdf}>Save PDF</Button>
                    </div>
                </section>
            )}
        </Fragment>
    )
}

export { PdfDoc }
