import { CompanyAccessLevelGuard } from '../components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from '../components/CompanyStatusGuard'

const MyVendors = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/my-vendors/form' />
    </CompanyAccessLevelGuard>
)

export { MyVendors }
