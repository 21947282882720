import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AntDropdown } from 'src/components/dropdown/AntDropdown'

const StyledButton = styled(Button, { shouldForwardProp: name => name !== 'hasTopMargin' })<{
    padding?: string
    width?: string
    hasTopMargin?: boolean
}>`
    width: ${({ width }) => width || '100%'};
    padding: ${({ padding }) => padding && padding};
    ${({ hasTopMargin }) =>
        hasTopMargin &&
        css`
            margin-top: 10px;
        `};
`

const StyledDivider = styled(Divider)`
    margin: 0;
`

const StyledLink = styled(Link, { shouldForwardProp: name => name !== 'hasTopMargin' })<{ hasTopMargin: boolean }>`
    text-decoration: none;
    width: 100%;
    ${({ hasTopMargin }) =>
        hasTopMargin &&
        css`
            margin-top: 10px;
        `}
`

const StyledDropdown = styled(AntDropdown)`
    width: 100%;
`

export { StyledButton, StyledDivider, StyledLink, StyledDropdown }
