import { AvailableOn } from 'src/contracts/search/availableOn'

const fileTypeToExtension = (filetype: string) => {
    switch (filetype) {
        case 'application/pdf':
            return '.pdf'
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return '.docx'
        default:
            return ''
    }
}

export const informationHiddenTooltipContent =
    'This opportunity is reserved for Premium users. SME Cloud enables SME companies to share specialists with each other at no cost.'

const findSyndicateNameById = (id: string, syndicates: Array<AvailableOn>) => syndicates.find(e => id === e.id)?.name || ''

export { fileTypeToExtension, findSyndicateNameById }
