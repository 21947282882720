/** @jsxImportSource @emotion/react */

import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../components/DefaultLayout'

const ProfileNotFoundPrivate = () => {
    const navigate = useNavigate()

    const onButtonClick = useCallback(() => {
        navigate('/')
    }, [navigate])

    return (
        <DefaultLayout
            icon='broken-link'
            title='The Link to this Profile is broken!'
            description='The original link has been altered somehow or the Profile you are looking for is no longer available.'
            buttonText='Back to Human Cloud Dashboard'
            onButtonClick={onButtonClick}
            buttonDataTestId='go-to-dashboard'
        />
    )
}

export { ProfileNotFoundPrivate }
