/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { FunctionComponent, useCallback, useState } from 'react'
import { ResponsiveGrid } from '../../../../../components/data-grid/ResponsiveGrid'
import { NoData } from '../../../../../components/NoData'
import { useNotifications } from '../../../../../components/notification/NotificationProvider'
import { useLogger } from '../../../../../utils/useLogger'
import { createComparer } from 'src/utils/sorting'
import { removeUserAccess } from '../../../api'
import { User } from '../../../contracts'
import { RemoveRoleActionHandler } from './RemoveRoleDropdown'
import { RemoveRoleConfirmationModal } from './RemoveRoleModal'

interface RolesGridProps {
    users: Array<User>
    onChangeTable: (_: any, __: any, newSorter: any) => void
    fetchData: () => void
}

const RolesGrid: FunctionComponent<React.PropsWithChildren<RolesGridProps>> = ({ users, onChangeTable, fetchData }) => {
    const [confirmationModalOpened, setConfirmationModalOpened] = useState(false)
    const [specialistIdToRemove, setSpecialistIdToRemove] = useState<string>('')

    const log = useLogger()

    const { addSuccess, addError } = useNotifications()

    const toggleConfirmationModal = useCallback(() => {
        setConfirmationModalOpened(!confirmationModalOpened)
    }, [confirmationModalOpened])

    const handleRemoveRole = useCallback(() => {
        if (specialistIdToRemove) {
            removeUserAccess(specialistIdToRemove)
                .then(() => {
                    addSuccess('User role removed succesfully')
                    fetchData()
                })
                .catch(error => {
                    log(error)
                    addError()
                })
        }
        setSpecialistIdToRemove('')
        toggleConfirmationModal()
    }, [addError, addSuccess, fetchData, log, specialistIdToRemove, toggleConfirmationModal])

    const columns: ColumnsType<User> = [
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName',
            sorter: {
                compare: createComparer('firstName'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
            sorter: {
                compare: createComparer('lastName'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: createComparer('email'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'Role',
            dataIndex: 'groupNames',
            key: 'groupNames',
            sorter: {
                compare: createComparer('groupNames'),
            },
            showSorterTooltip: false,
            render: content =>
                content
                    ?.map((group: any) => group.label)
                    .toString()
                    .replace(',', ', '),
        },
        {
            title: '',
            dataIndex: 'actionsHandler',
            key: 'actionsHandler',
            showSorterTooltip: false,
            render: (_, record) => (
                <RemoveRoleActionHandler
                    setSpecialistIdToRemove={setSpecialistIdToRemove}
                    toggleConfirmationModal={toggleConfirmationModal}
                    recordId={record.userId}
                />
            ),
        },
    ]

    return (
        <div>
            {users.length === 0 ? (
                <div
                    css={css`
                        margin: 40px auto;
                    `}
                >
                    <NoData />
                </div>
            ) : (
                <ResponsiveGrid
                    dataTestId='user-roles-grid'
                    rowKey='userId'
                    dataSource={users}
                    columns={columns}
                    pagination={false}
                    onChange={onChangeTable}
                />
            )}

            <RemoveRoleConfirmationModal opened={confirmationModalOpened} onClose={toggleConfirmationModal} onRemove={handleRemoveRole} />
        </div>
    )
}

export { RolesGrid }
