/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Col } from 'antd'
import { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { getBreakpoint } from '../GlobalStyle'
import { AppIcon, IconName } from './Icon'

type CardProps = {
    link?: string
    iconUrl?: string
    title?: ReactNode
    status?: 'static' | 'disabled' | 'available' | 'unavailable'
    footer?: ReactNode
    styles?: SerializedStyles
    iconName?: IconName
    mediumSpan?: number
    xsmallSpan?: number
    smallSpan?: number
    desktopSpan?: number
    iconBackgroundColor?: string
    iconHoverColor?: string
    height?: string
    iconSize?: number
    onCardClick?: () => void
    dataTestId?: string
    iconStyles?: SerializedStyles
}

const Card: FunctionComponent<React.PropsWithChildren<CardProps>> = ({
    link,
    iconUrl,
    iconName,
    title,
    status = 'static',
    footer,
    styles,
    desktopSpan = 4,
    mediumSpan = 8,
    xsmallSpan = 12,
    smallSpan = 12,
    iconBackgroundColor,
    iconHoverColor,
    iconSize,
    onCardClick,
    dataTestId,
    height = '200px',
    iconStyles,
}) => {
    const [hovered, setHovered] = useState(false)

    const [span, setSpan] = useState(4)

    const { width } = useWindowSize()

    const theme = useTheme()

    const toggleHover = useCallback((value: any) => {
        setHovered(value)
    }, [])

    useEffect(() => {
        const breakpoint = getBreakpoint(width)

        switch (breakpoint) {
            case 0:
                setSpan(xsmallSpan)
                break
            case 1:
                setSpan(smallSpan)
                break
            case 2:
                setSpan(mediumSpan)
                break
            case 3:
                setSpan(desktopSpan)
                break
            case 4:
                setSpan(desktopSpan)
                break
            default:
                break
        }
    }, [desktopSpan, mediumSpan, smallSpan, width, xsmallSpan])

    const handleClick = useCallback(() => {
        if (onCardClick) {
            onCardClick()
        }
    }, [onCardClick])

    return (
        <Col span={span}>
            <div onClick={handleClick} data-testid={dataTestId}>
                <div
                    onMouseEnter={() => toggleHover(true)}
                    onMouseLeave={() => toggleHover(false)}
                    data-testid='app-tile'
                    css={css`
                        height: ${height};
                        display: flex;
                        border-radius: 4px;
                        flex-direction: column;
                        align-items: center;
                        cursor: pointer;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
                        transition: 0.3s;
                        ${styles}
                    `}
                >
                    <AppIcon
                        src={iconUrl}
                        name={iconName}
                        color={iconBackgroundColor || theme.colors.beige_3}
                        hoverColor={iconHoverColor}
                        status={status}
                        hovered={hovered}
                        size={iconSize}
                        css={css`
                            margin-top: 24px;
                            flex-shrink: 0;
                            ${iconStyles}
                        `}
                    />
                    {title}
                    {footer}
                </div>
            </div>
        </Col>
    )
}

export { Card }
