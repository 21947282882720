import { SkillsGroup } from '../../../contracts/personalStrengths'
import { SpecialistProject } from '../../../contracts/specialist/specialistProject'

const parseProjects = (projects: Array<SpecialistProject>) => {
    const companiesDictionary: Array<string> = []
    const parsedProjects: Array<SpecialistProject> = []

    projects.forEach(project => {
        const isInDictionary = companiesDictionary.includes(project.company)

        if (isInDictionary) {
            project.company = `Company ${companiesDictionary.indexOf(project.company) + 1}`
        } else {
            companiesDictionary.push(project.company)
            project.company = `Company ${companiesDictionary.length}`
        }
        parsedProjects.push(project)
    })
    return parsedProjects
}

const containsPersonalStrengths = (groups: Array<SkillsGroup>) => {
    let containsStrength = false
    groups.forEach(group => {
        group.skills.forEach(skill => {
            if (skill.score !== null) {
                containsStrength = true
            }
        })
    })
    return containsStrength
}

export { parseProjects, containsPersonalStrengths }
