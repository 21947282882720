/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { saveAs } from 'file-saver'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import withdrawIcon from '../../../assets/icons/withdraw.svg'
import { DefaultLayout } from '../../../components/DefaultLayout'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { ApplicationDetailsComponent } from '../../../components/opportunities/ApplicationDetailsComponent'
import { ApplicationStatusesHistory } from '../../../components/opportunities/ApplicationStatusesHistory'
import { getAppPath } from '../../../contracts/applications'
import { history } from '../../../history'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { shouldDisplayContractRate } from '../../../utils/application'
import { ContractRateDetails } from '../../../components/ContractRateDetails'
import { getApplicationCv } from '../../../api/opportunities-manager/api'
import { Application as ApplicationManager, ApplicationStatusEnum } from '../../../contracts/opportunities-manager/contracts'
import { getApplication, withdrawApplication } from '../../../api/open-opportunities/api'

import { Application, ApplicationWithdrawalParams } from '../../../contracts/open-opportunities/contracts'
import { menuItems } from '../../../components/open-opportunities/utils/openOpportunitiesUtils'
import { SHOW_WITHDRAWAL_SUCCESS_MESSAGE, WithdrawalReasonModal } from 'src/components/open-opportunities/WithdrawalReasonModal'

const ApplicationDetails = () => {
    const { applicationId } = useParams<{ applicationId: string }>()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const [application, setApplication] = useState<Nullable<Application>>(null)
    const log = useLogger('error')
    const { addError } = useNotifications()

    const [error, setError] = useState<string>('')

    const fetchData = useCallback(() => {
        if (applicationId) {
            layoutToggleLoader(true)
            getApplication(applicationId)
                .then(setApplication)
                .catch(err => {
                    if (err.status === 404 || err.status === 400) {
                        setError('not found')
                    } else {
                        log(err)
                        addError()
                    }
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, applicationId, layoutToggleLoader, log])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const [currentWithdrawalApplicationId, setCurrentWithdrawalApplicationId] = useState<string>()

    const onWithdrawalClick = useCallback(() => setCurrentWithdrawalApplicationId(applicationId), [applicationId])

    const handleWithdrawal = useCallback(
        (id: string, body: ApplicationWithdrawalParams) => {
            withdrawApplication(id, body)
                .then(() => {
                    setCurrentWithdrawalApplicationId(SHOW_WITHDRAWAL_SUCCESS_MESSAGE)
                    fetchData()
                })
                .catch(() => {
                    addError()
                })
        },
        [addError, fetchData],
    )

    const handleCloseModal = useCallback(() => setCurrentWithdrawalApplicationId(''), [])

    const handleCvDownload = useCallback(() => {
        if (applicationId) {
            layoutToggleLoader(true)
            getApplicationCv(applicationId)
                .then(response => {
                    saveAs(response, application?.cvFileName)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, application?.cvFileName, applicationId, layoutToggleLoader, log])

    const onGoBack = useCallback(() => history.push(`${getAppPath('OPEN_OPPORTUNITIES')}/applications`), [])

    const contractRateArgs = {
        status: application?.recentStatus.status,
        currency: application?.currency,
        contractRate: application?.contractRate,
    }
    const showContractRate = shouldDisplayContractRate(contractRateArgs)

    return !application && error === 'not found' ? (
        <DefaultLayout
            icon='cosmonaut'
            iconSize={54}
            iconStyles={css`
                position: absolute;
                bottom: 0;
                left: 0;
            `}
            title='Application not found'
            description='Sorry, this page doesn’t exist.'
            buttonText='Return to the list'
            onButtonClick={onGoBack}
        />
    ) : (
        <DashboardLayout applicationName='Open Opportunities' applicationMenuItems={menuItems}>
            <ApplicationDetailsComponent
                application={application as Nullable<ApplicationManager>}
                backlinkPath={`${getAppPath('OPEN_OPPORTUNITIES')}/applications`}
                opportunityId={application?.opportunityId}
                handleCvDownload={handleCvDownload}
            >
                {application && (
                    <ApplicationStatusesHistory
                        toggleEditMode={onWithdrawalClick}
                        statusHistory={application.statusHistory}
                        recentStatus={application.recentStatus}
                        buttonIcon={withdrawIcon}
                        buttonText='Withdraw'
                        showButton={!application.recentStatus.rejectionReason}
                        iconStyle={css`
                            height: 20px;
                            margin-top: 2px;
                        `}
                        disabled={application.statusHistory.some(({ status }) => status === ApplicationStatusEnum.WITHDRAWN)}
                    />
                )}
                {showContractRate && (
                    <ContractRateDetails
                        currency={contractRateArgs.currency}
                        contractRate={contractRateArgs.contractRate}
                        status={contractRateArgs.status}
                    />
                )}
            </ApplicationDetailsComponent>
            {currentWithdrawalApplicationId && (
                <WithdrawalReasonModal
                    closeRejectionModal={handleCloseModal}
                    currentRejectionApplicationId={currentWithdrawalApplicationId}
                    onSubmit={handleWithdrawal}
                />
            )}
        </DashboardLayout>
    )
}

export { ApplicationDetails }
