import styled from '@emotion/styled'
import { mqMax } from 'src/GlobalStyle'
import { IconButton } from 'src/components/Icon'

const ProfileActions = styled.div`
    display: flex;
    justify-content: flex-end;
    ${mqMax[1]} {
        margin-top: 15px;
    }
`

const SearchButton = styled(IconButton)`
    margin-right: 8px;
`

export { ProfileActions, SearchButton }
