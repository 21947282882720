/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ApplicationFormData } from '../../contracts/opportunities-manager/contracts'

interface ControlledInputInlineEditProps {
    control: Control<ApplicationFormData>
    fieldName: string
    initialValue: string | undefined
    placeholder: string
    regex?: RegExp
}

const ControlledInputInlineEdit: FunctionComponent<React.PropsWithChildren<ControlledInputInlineEditProps>> = ({
    control,
    fieldName,
    initialValue,
    placeholder,
    regex,
}) => (
    <ControlledInput
        control={control}
        defaultValue={initialValue}
        name={fieldName}
        placeholder={placeholder}
        regex={regex}
        style={css`
            max-width: 115px;

            p {
                margin-bottom: -16px;
            }
        `}
    />
)

export { ControlledInputInlineEdit }
