/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import { SorterResult } from 'antd/es/table/interface'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useMemo } from 'react'
import { AutocompleteSelect } from 'src/components/inputs/autocomplete-select/AutocompleteSelect'
import { Checkbox } from 'src/components/inputs/Checkbox'
import { ColorBackgroundWrapper } from 'src/components/layout/ResponsiveWrapper'
import { EMPTY_SORTING } from 'src/contracts/common/sorting'
import { COLOR_PALETTE, mqMax } from 'src/GlobalStyle'
import { useIsMobile } from 'src/utils/hooks'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { getRowBackgroundColor } from '../utils'

type MobileGridProps = {
    onSortingChanged?: (field: string) => void
}

type ShareMySpecialistsResponsiveGridProps = {
    selectedRowKeys: Array<any>
    onRowSelectedChange: Dispatch<SetStateAction<Array<string>>>
}

type ResponsiveGridType<RecordType extends object = any> = FunctionComponent<
    React.PropsWithChildren<TableProps<RecordType> & MobileGridProps & ShareMySpecialistsResponsiveGridProps>
>

type MobileGridItemProps = FunctionComponent<
    React.PropsWithChildren<{
        record: any
        isSelected: boolean
        handleSelectChange: (checked: boolean, id: string) => void
        rowsWithoutActions: Array<any>
        actionsColumn: any
    }>
>

const hasDataIndex = (column: any) => !!column.dataIndex
const hasSorter = (column: any) => !!column.sorter
const extractDataIndex = (column: any) => column.dataIndex
const extractTitle = (column: any) => column.title
const rowClassName = (record: ShareMySpecialistsSpecialist) => getRowBackgroundColor(record.sharedStatus)

const RowWrapper = styled.div`
    margin-bottom: 18px;
`

const TextValue = styled.div`
    color: ${COLOR_PALETTE.gray_6};
    font-size: 14px;
`

const Label = styled.div`
    color: ${COLOR_PALETTE.gray_4};
    font-size: 12px;
    line-height: 16px;
`

const MobileRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

const MobileGridItem: MobileGridItemProps = ({ record, isSelected, handleSelectChange, rowsWithoutActions, actionsColumn }) => {
    const handleCheckboxClick = useCallback((checked: any) => handleSelectChange(checked, record.id), [handleSelectChange, record.id])

    const getBackgroundColor = useMemo(
        () =>
            isSelected
                ? COLOR_PALETTE.blue_2
                : record.sharedStatus === 'BOOKING_REQUEST_RECEIVED'
                ? COLOR_PALETTE.yellow_1
                : COLOR_PALETTE.white,
        [isSelected, record.sharedStatus],
    )
    return (
        <div
            css={css`
                box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                border: 1px solid ${COLOR_PALETTE.gray_2};
                background-color: ${getBackgroundColor};
                border-radius: 2px;
                margin: 16px;
                flex-direction: column;
                padding: 24px 20px;
            `}
        >
            <div
                css={css`
                    display: flex;
                `}
            >
                <div
                    css={css`
                        padding-right: 42px;
                        ${mqMax[1]} {
                            padding-right: 24px;
                        }
                        ${mqMax[0]} {
                            padding-right: 16px;
                        }
                    `}
                >
                    <Checkbox
                        checkboxLabel=''
                        name={`${record.id}`}
                        checked={isSelected}
                        onChange={handleCheckboxClick}
                        disabled={
                            record.sharedStatus === 'BOOKING_REQUEST_ACCEPTED' || record.sharedStatus === 'BOOKING_REQUEST_RECEIVED'
                                ? true
                                : false
                        }
                    />
                </div>
                <div
                    css={css`
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    {rowsWithoutActions?.map((column: any) => {
                        const dataIndex = extractDataIndex(column)
                        const rowValue = dataIndex ? record[dataIndex] : ''
                        const renderer = column?.render || null
                        const ColumnRenderer = column?.title as FunctionComponent<React.PropsWithChildren<unknown>>

                        return (
                            <div
                                key={`k_${dataIndex}`}
                                css={css`
                                    margin: 0 8px;
                                    min-width: 210px;
                                    width: 30%;

                                    ${mqMax[1]} {
                                        width: 45%;
                                    }

                                    ${mqMax[0]} {
                                        width: 100%;
                                    }
                                `}
                            >
                                {renderer ? (
                                    <MobileRowWrapper>
                                        <Label>{typeof column.title === 'string' ? column.title : <ColumnRenderer />}</Label>
                                        <TextValue>{renderer(rowValue, record, dataIndex)}</TextValue>
                                    </MobileRowWrapper>
                                ) : (
                                    <MobileRowWrapper>
                                        <Label>{column.title}</Label>
                                        <TextValue>{rowValue || '-'}</TextValue>
                                    </MobileRowWrapper>
                                )}
                            </div>
                        )
                    })}
                </div>
            </div>
            {actionsColumn && (
                <div
                    css={css`
                        border-top: 1px solid ${COLOR_PALETTE.gray_2};
                        padding-left: 52px;
                        ${mqMax[1]} {
                            padding-left: 24px;
                        }
                        ${mqMax[0]} {
                            padding-left: 8px;
                        }
                    `}
                >
                    <div
                        key={`k_${actionsColumn.dataIndex}`}
                        css={css`
                            width: 100%;
                        `}
                    >
                        {actionsColumn?.render('', record, actionsColumn.dataIndex) || null}
                    </div>
                </div>
            )}
        </div>
    )
}

const MobileGrid: ResponsiveGridType = ({ dataSource, columns, onChange, selectedRowKeys, onRowSelectedChange }) => {
    const sortByOptions = useMemo(() => {
        const baseSorting = [EMPTY_SORTING]
        const sortable = columns ? columns.filter(column => hasSorter(column) && hasDataIndex(column)) : []
        const sortableKeys = sortable.map(extractDataIndex)
        const sortableTitles = sortable.map(extractTitle)

        const mapSortLabel = (key: string) => (key === EMPTY_SORTING ? 'None' : `Sort By: ${sortableTitles[sortableKeys.indexOf(key)]}`)

        return { columns: baseSorting.concat(sortableKeys), mapSortLabel }
    }, [columns])

    const onSortByChange = useCallback(
        ([fieldName]: [any]) => {
            if (onChange) {
                const sorter: SorterResult<string> = fieldName === EMPTY_SORTING ? {} : { field: fieldName, order: 'ascend' }
                onChange({}, {}, sorter, {} as any)
            }
        },
        [onChange],
    )

    const onCheckboxClicked = useCallback(
        (val: boolean, id: string): void =>
            val
                ? onRowSelectedChange(currentValues => currentValues.concat(id))
                : onRowSelectedChange(currentValues => currentValues.filter(key => key !== id)),
        [onRowSelectedChange],
    )

    const isRowSelected = useCallback((id: string) => selectedRowKeys?.some(key => key === id), [selectedRowKeys])

    const rowsWithoutActions = useMemo(() => columns?.filter((column: any) => column?.key !== 'actions') || [], [columns])

    const actionsColumn = useMemo(() => columns?.find((column: any) => column?.key === 'actions'), [columns])

    return (
        <div>
            <RowWrapper>
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${COLOR_PALETTE.gray_1};
                        border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                    `}
                >
                    <AutocompleteSelect
                        placeholder='Sort By'
                        options={sortByOptions.columns}
                        currentValues={''}
                        onSelectedValuesChange={onSortByChange}
                        selectedLabelTransformer={sortByOptions.mapSortLabel}
                        canFilter={false}
                    />
                </ColorBackgroundWrapper>
            </RowWrapper>
            {dataSource?.map(record => (
                <MobileGridItem
                    key={record.id}
                    record={record}
                    isSelected={isRowSelected(record.id)}
                    rowsWithoutActions={rowsWithoutActions}
                    actionsColumn={actionsColumn}
                    handleSelectChange={onCheckboxClicked}
                />
            ))}
        </div>
    )
}

const ShareMySpecialistsResponsiveGrid: ResponsiveGridType = ({
    rowKey,
    dataSource,
    columns,
    onChange,
    pagination,
    selectedRowKeys,
    onRowSelectedChange,
}) => {
    const isMobile = useIsMobile(2)

    return isMobile ? (
        <MobileGrid
            rowKey={rowKey}
            dataSource={dataSource}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
            selectedRowKeys={selectedRowKeys}
            onRowSelectedChange={onRowSelectedChange}
        />
    ) : (
        <Fragment>
            <div
                css={css`
                    max-width: 1440px;
                    margin: 0 auto;
                `}
            >
                <Table
                    css={css`
                        tbody tr:hover {
                            box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                        }
                        .ant-table-tbody > tr > td {
                            padding: 0 2px;

                            :first-of-type {
                                padding: 0 16px;
                            }

                            :last-child {
                                padding: 0 16px;
                            }
                        }
                        .ant-table-thead > tr > th {
                            vertical-align: top;
                        }

                        .ant-table-column-sorters {
                            padding: 0;
                        }
                        .ant-table-thead > tr > th {
                            padding: 16px 2px;
                        }
                    `}
                    rowKey={rowKey}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                    onChange={onChange}
                    rowClassName={rowClassName}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: onRowSelectedChange as any,
                        selectedRowKeys,
                        preserveSelectedRowKeys: true,
                        getCheckboxProps: record => ({
                            disabled:
                                record.sharedStatus === 'BOOKING_REQUEST_ACCEPTED' || record.sharedStatus === 'BOOKING_REQUEST_RECEIVED',
                            'data-testid': 'share-my-specialists-responsive-grid-item-checkbox',
                        }),
                    }}
                />
            </div>
        </Fragment>
    )
}

export { ShareMySpecialistsResponsiveGrid }
