/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useMemo } from 'react'
import { createReducer, useEffectOnce, useUpdateEffect } from 'react-use'
import logger from 'redux-logger'
import { config } from '../../environment/config'
import { mqMax } from '../../GlobalStyle'
import { Nullable } from '../../types'
import { arrayShuffle } from '../../utils/arrayShuffle'
import { deepClone } from '../../utils/deepClone'
import { Disruption } from './components/steps/Disruption'
import { Instruction } from './components/steps/Instruction'
import { Introduction } from './components/steps/Introduction'
import { Memorize } from './components/steps/Memorize'
import { Play } from './components/steps/Play'
import { Results } from './components/steps/Results'
import { Summary } from './components/steps/Summary'
import { GameMatrix, GameMatrixData } from './contracts'
import { matrixes } from './data/matrixes'
import { FlowStep } from './enums'
import { isMatrixCorrectlySolved } from './utils'

const useCustomReducer = createReducer<DotMemoryAction, DotMemoryState>(logger as any)

const GAME_ROUNDS = config.dotMemoryRoundsLimit
const LEVEL_STAIRCASE_LIMIT = 2

const TIME = {
    memorize: 2500,
    disruption: config.dotMemoryDisruptionTime,
    play: 10000,
    results: 1000,
}

const convertGameDataToGameMatrix = (data: GameMatrixData): GameMatrix => {
    return data.map(singleRow => singleRow.map(value => ({ isSelected: false, isClickable: Boolean(value) })))
}

const randomizeGameMatrixes = () => {
    const matrixesSteps = Object.keys(matrixes)
    const randomizedMatrixes: any = {}

    for (const mStep of matrixesSteps) {
        const stepData = arrayShuffle<Array<GameMatrixData>>((matrixes as any)[mStep])
        randomizedMatrixes[mStep] = stepData
    }

    return randomizedMatrixes
}

const isRoundFinished = (matrix: GameMatrix): boolean => {
    let selectedBoxes: number = 0
    let selectableBoxesCount: number = 0
    matrix.forEach(row => {
        selectedBoxes = selectedBoxes + row.filter(box => box.isSelected).length
        selectableBoxesCount = selectableBoxesCount + row.filter(box => box.isClickable).length
    })

    return selectableBoxesCount === selectedBoxes
}

type DotMemoryState = {
    flowStep: FlowStep
    randomizedGameData: { [key: number]: Array<GameMatrixData> }
    gameResults: Array<GameMatrix>
    currentInterval: Nullable<any>
    currentMatrix: Nullable<GameMatrix>
    level: number
    levelStaircase: number
    currentGameRound: number
    isTraining: boolean
}

const initialState = {
    flowStep: FlowStep.Introduction,
    randomizedGameData: {},
    gameResults: [],
    currentInterval: null,
    currentMatrix: null,
    level: 2,
    levelStaircase: 0,
    currentGameRound: 0,
    isTraining: true,
}

type DotMemoryActionType =
    | 'SET_FLOW_STEP'
    | 'SET_RANDOMIZED_GAME_DATA'
    | 'UPDATE_RANDOMIZED_GAME_DATA'
    | 'SET_GAME_MATRIXES'
    | 'SET_CURRENT_INTERVAL'
    | 'CLEAR_CURRENT_INTERVAL'
    | 'SET_CURRENT_MATRIX'
    | 'UPDATE_CURRENT_MATRIX'
    | 'LEVEL_UP'
    | 'LEVEL_DOWN'
    | 'SET_CURRENT_GAME_ROUND'
    | 'RESET_CURRENT_GAME_ROUND'
    | 'DISABLE_TRAINING'

const type = {
    SET_FLOW_STEP: 'SET_FLOW_STEP',
    SET_RANDOMIZED_GAME_DATA: 'SET_RANDOMIZED_GAME_DATA',
    UPDATE_RANDOMIZED_GAME_DATA: 'UPDATE_RANDOMIZED_GAME_DATA',
    SET_GAME_RESULTS: 'SET_GAME_RESULTS',
    SET_CURRENT_INTERVAL: 'SET_CURRENT_INTERVAL',
    CLEAR_CURRENT_INTERVAL: 'CLEAR_CURRENT_INTERVAL',
    SET_CURRENT_MATRIX: 'SET_CURRENT_MATRIX',
    UPDATE_CURRENT_MATRIX: 'UPDATE_CURRENT_MATRIX',
    LEVEL_UP: 'LEVEL_UP',
    LEVEL_DOWN: 'LEVEL_DOWN',
    SET_CURRENT_GAME_ROUND: 'SET_CURRENT_GAME_ROUND',
    RESET_CURRENT_GAME_ROUND: 'RESET_CURRENT_GAME_ROUND',
    DISABLE_TRAINING: 'DISABLE_TRAINING',
}

type DotMemoryAction = {
    type: DotMemoryActionType
    payload: any
}

const reducer = (state: DotMemoryState, action: DotMemoryAction) => {
    let output: DotMemoryState = { ...state }

    switch (action.type) {
        case type.SET_CURRENT_INTERVAL:
            output = { ...state, currentInterval: action.payload }
            break

        case type.CLEAR_CURRENT_INTERVAL:
            output = { ...state, currentInterval: null }
            break

        case type.SET_CURRENT_MATRIX:
        case type.UPDATE_CURRENT_MATRIX:
            output = { ...state, currentMatrix: action.payload }
            break

        case type.SET_FLOW_STEP:
            output = { ...state, flowStep: action.payload }
            break

        case type.SET_GAME_RESULTS:
            output = { ...state, gameResults: [...state.gameResults, action.payload] }
            break

        case type.LEVEL_UP:
            output = { ...state, level: state.level + 1, levelStaircase: 0 }
            break

        case type.LEVEL_DOWN:
            output = { ...state, level: state.level - 1, levelStaircase: 0 }
            break

        case type.SET_RANDOMIZED_GAME_DATA:
        case type.UPDATE_RANDOMIZED_GAME_DATA:
            output = { ...state, randomizedGameData: action.payload }
            break

        case type.SET_CURRENT_GAME_ROUND:
            output = { ...state, currentGameRound: action.payload, levelStaircase: state.levelStaircase + 1 }
            break

        case type.RESET_CURRENT_GAME_ROUND:
            output = { ...state, currentGameRound: 0, levelStaircase: 0 }
            break

        case type.DISABLE_TRAINING:
            output = { ...state, isTraining: false }
            break
    }

    return output
}

const DotMemory = () => {
    const [state, dispatch] = useCustomReducer(reducer, initialState)
    const dispatchAction = useCallback(
        (type: string, payload?: any) => dispatch({ type: type as DotMemoryActionType, payload }),
        [dispatch],
    )

    const setStep = useCallback(
        (step: number) => {
            dispatchAction(type.SET_FLOW_STEP, step)
        },
        [dispatchAction],
    )
    const getCurrentMatrix = useCallback(() => {
        let randomizedGameData = deepClone(state.randomizedGameData)

        if (randomizedGameData[state.level].length === 0) {
            randomizedGameData = randomizeGameMatrixes()
        }

        const singleMatrix = randomizedGameData[state.level].pop()
        dispatchAction(type.UPDATE_RANDOMIZED_GAME_DATA, randomizedGameData)
        return convertGameDataToGameMatrix(singleMatrix as GameMatrixData)
    }, [state.level, state.randomizedGameData, dispatchAction])

    const startGame = useCallback(
        (isTraining: boolean) => {
            if (!isTraining) {
                dispatchAction(type.DISABLE_TRAINING)
            }
            dispatchAction(type.RESET_CURRENT_GAME_ROUND)
            dispatchAction(type.SET_CURRENT_MATRIX, getCurrentMatrix())
            setStep(FlowStep.Memorize)
        },
        [dispatchAction, getCurrentMatrix, setStep],
    )

    const finishGame = () => {
        dispatchAction(type.RESET_CURRENT_GAME_ROUND)
        dispatchAction(type.SET_CURRENT_MATRIX, null)
        setStep(FlowStep.Summary)
    }

    const updateMatrix = useCallback(
        (rowIndex: any, colIndex: any) => {
            const updatedMatrix = deepClone(state.currentMatrix as GameMatrix)
            updatedMatrix[rowIndex][colIndex].isSelected = true
            dispatchAction(type.UPDATE_CURRENT_MATRIX, updatedMatrix)

            if (isRoundFinished(updatedMatrix)) {
                setStep(FlowStep.Results)
            }
        },
        [state.currentMatrix, dispatchAction, setStep],
    )

    useEffectOnce(() => {
        dispatchAction(type.SET_RANDOMIZED_GAME_DATA, randomizeGameMatrixes())
    })

    useUpdateEffect(() => {
        if (state.currentInterval) {
            clearInterval(state.currentInterval)
            dispatchAction(type.CLEAR_CURRENT_INTERVAL)
        }

        if (state.flowStep === FlowStep.Memorize) {
            dispatchAction(type.SET_CURRENT_MATRIX, getCurrentMatrix())
        }
    }, [state.flowStep])

    useUpdateEffect(() => {
        if (!state.isTraining && state.flowStep === FlowStep.Results) {
            if (state.currentMatrix !== null) {
                dispatchAction(type.SET_GAME_RESULTS, state.currentMatrix)
                if (state.levelStaircase >= LEVEL_STAIRCASE_LIMIT) {
                    const previousRound = state.gameResults[state.gameResults.length - 1]
                    const isPreviousRoundCorrectlySolved = isMatrixCorrectlySolved(previousRound)
                    const isCurrentRoundCorrectlySolved = isMatrixCorrectlySolved(state.currentMatrix)

                    if (isCurrentRoundCorrectlySolved && isPreviousRoundCorrectlySolved && state.level < 9) {
                        dispatchAction(type.LEVEL_UP)
                    } else if (!isCurrentRoundCorrectlySolved && !isPreviousRoundCorrectlySolved && state.level > 2) {
                        dispatchAction(type.LEVEL_DOWN)
                    }
                }
            }

            if (state.currentGameRound + 1 > GAME_ROUNDS) {
                finishGame()
            } else {
                setStep(FlowStep.Memorize)
            }
        }
    }, [state.currentGameRound])

    useUpdateEffect(() => {
        // setup time intervals
        const timer =
            state.flowStep === FlowStep.Memorize
                ? TIME.memorize
                : state.flowStep === FlowStep.Disruption
                ? TIME.disruption
                : state.flowStep === FlowStep.Play
                ? TIME.play
                : state.flowStep === FlowStep.Results
                ? TIME.results
                : null

        if (timer !== null && state.currentInterval === null) {
            dispatchAction(
                type.SET_CURRENT_INTERVAL,
                setTimeout(() => {
                    // update flow step
                    if ([FlowStep.Memorize, FlowStep.Disruption, FlowStep.Play].includes(state.flowStep)) {
                        dispatchAction(type.SET_FLOW_STEP, state.flowStep + 1)
                    }

                    if (!state.isTraining) {
                        // update game round
                        if (state.flowStep === FlowStep.Results) {
                            dispatchAction(type.SET_CURRENT_GAME_ROUND, state.currentGameRound + 1)
                        }
                    }
                }, timer),
            )
        }
    }, [state.flowStep, dispatchAction, state.currentInterval])

    const component = useMemo(() => {
        let output = null
        const round = state.isTraining ? 1 : state.currentGameRound + 1
        const rounds = state.isTraining ? 1 : GAME_ROUNDS

        switch (state.flowStep) {
            case FlowStep.Introduction:
                output = <Introduction changeStep={setStep} />
                break

            case FlowStep.Instruction:
                output = <Instruction changeStep={setStep} />
                break

            case FlowStep.Memorize:
                output = state.currentMatrix ? (
                    <Memorize matrix={state.currentMatrix} time={TIME.memorize} round={round} rounds={rounds} />
                ) : null
                break

            case FlowStep.Disruption:
                output = <Disruption time={TIME.disruption} round={round} rounds={rounds} />
                break

            case FlowStep.Play:
                output = state.currentMatrix ? (
                    <Play matrix={state.currentMatrix} onClick={updateMatrix} time={TIME.play} round={round} rounds={rounds} />
                ) : null
                break

            case FlowStep.Results:
                output = state.currentMatrix ? (
                    <Results
                        matrix={state.currentMatrix}
                        round={round}
                        rounds={rounds}
                        isTraining={state.isTraining}
                        startAgain={startGame}
                    />
                ) : null
                break

            case FlowStep.Summary:
                output = <Summary results={state.gameResults} />
                break
        }

        return output
    }, [state.flowStep, state.currentMatrix, updateMatrix, setStep, state.currentGameRound, state.gameResults, state.isTraining, startGame])

    return (
        <div
            css={css`
                display: flex;
                width: 100%;
                height: 100%;
                min-height: 100vh;
                overflow: auto;
                justify-content: center;
                align-items: center;
                padding: 24px 0px;

                ${mqMax[1]} {
                    padding: 24px 24px;
                }
            `}
        >
            {component}
        </div>
    )
}

export { DotMemory }
