/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getBookingDetailsForManager, sendBookingResponse } from 'src/api/api'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledRadioGroup, ControlledRadioGroupOptionType } from 'src/components/forms/ControlledRadioGroup'
import { ControlledTextarea } from 'src/components/forms/ControlledTextarea'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { Modal } from 'src/components/Modal'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { ButtonContainer, GreyParagraph } from 'src/components/shared/shared-styles'
import { BookingDetailsForManager, mapResponseType, ResponseType } from 'src/contracts/bookings/booking'
import { mqMax } from 'src/GlobalStyle'
import { ReduxContext } from 'src/redux/Store'
import { COLOR_PALETTE } from 'src/theme/colors'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { hasErrors } from 'src/utils/errors'
import { useLogger } from 'src/utils/useLogger'
import { yup } from 'src/validation/yup'

const ResponseOptions = [
    ResponseType.SPECIALIST_DOESNT_WORK,
    ResponseType.SPECIALIST_IN_ANOTHER_PROJECT,
    ResponseType.PROJECT_DURATION_NOT_FIT,
    ResponseType.PROJECT_TECHSTACK_NOT_FIT,
    ResponseType.OTHER,
]

const EntryBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
`

const ValueParagraph = styled.p`
    font-weight: 500;
    font-size: 18px;
    color: ${COLOR_PALETTE.gray_6};
`

const schema: any = yup.object().shape({
    response: yup.string().required(),
    refuseReason: yup.string().when(['response'], {
        is: 'REJECTED',
        then: yup.string().required(),
    }),
    comment: yup.string().when(['refuseReason'], {
        is: (refuseReason: string) => refuseReason === 'OTHER',
        then: yup.string().required(),
    }),
})

type BookNowResponseModalProps = {
    opened: boolean
    onClose: () => void
    bookNowResponseModalData: { specialistId: string; bookingId: string }
    fetchSpecialists: () => void
}

const initialBookingDetails = {
    bookingRequestId: '',
    specialistId: '',
    specialistName: '',
    projectDuration: '',
    idealProjectStartDate: 0,
    latestProjectStartDate: 0,
    techStack: [],
}

const BookNowResponseModal: FunctionComponent<React.PropsWithChildren<BookNowResponseModalProps>> = ({
    opened,
    onClose,
    bookNowResponseModalData,
    fetchSpecialists,
}) => {
    const [bookingDetails, setBookingDetails] = useState<Nullable<BookingDetailsForManager>>(initialBookingDetails)

    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        reset,
        control,
        getValues,
        watch,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
    })

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { bookingId, specialistId } = bookNowResponseModalData

    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()

    const watchResponse = watch('response')
    const watchRefuseReason = watch('refuseReason')

    const onSubmit = (formData: any) => {
        if (bookingId) {
            layoutToggleLoader(true)
            sendBookingResponse(formData, bookingId, specialistId)
                .then(() => {
                    addSuccess('You successfully responded to the Booking Request.')
                    onClose()
                    fetchSpecialists()
                })
                .catch(log)
                .finally(() => layoutToggleLoader(false))
        }
    }

    useEffect(() => {
        if (bookingId) {
            layoutToggleLoader(true)
            getBookingDetailsForManager(bookingId, specialistId)
                .then(data => setBookingDetails(data))
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [specialistId, log, addError, layoutToggleLoader, bookingId])

    useEffect(() => {
        reset({ response: 'ACCEPTED', refuseReason: '' })
    }, [reset])

    useEffect(() => {
        if (watchResponse === 'ACCEPTED') {
            clearErrors('comment')
        } else if (watchRefuseReason?.length > 0 && watchRefuseReason[0] === 'OTHER') {
            clearErrors('comment')
        }
    }, [watchResponse, watchRefuseReason, clearErrors])

    const isOther: boolean = useMemo(() => watchRefuseReason?.length > 0 && watchRefuseReason[0] === 'OTHER', [watchRefuseReason])

    return (
        <Modal opened={opened} onClose={onClose}>
            <div
                css={css`
                    width: 464px;

                    ${mqMax[1]} {
                        max-width: 464px;
                        width: 100%;
                    }
                `}
            >
                <h5>Booking Request</h5>
                <p
                    css={css`
                        font-weight: 500;
                        color: ${COLOR_PALETTE.gray_6};
                        margin-bottom: 24px;
                    `}
                >
                    Project Details
                </p>

                <EntryBox>
                    <GreyParagraph
                        css={css`
                            margin-bottom: 6px;
                        `}
                    >
                        Specialist Name:
                    </GreyParagraph>
                    <ValueParagraph>{bookingDetails?.specialistName}</ValueParagraph>
                </EntryBox>

                <EntryBox>
                    <GreyParagraph
                        css={css`
                            margin-bottom: 6px;
                        `}
                    >
                        Project Duration:
                    </GreyParagraph>
                    <ValueParagraph>{bookingDetails?.projectDuration}</ValueParagraph>
                </EntryBox>

                <FlexBox justifyContent='space-between'>
                    <EntryBox
                        css={css`
                            flex: 1;
                        `}
                    >
                        <GreyParagraph
                            css={css`
                                margin-bottom: 6px;
                            `}
                        >
                            Ideal Project Start Date:
                        </GreyParagraph>
                        <ValueParagraph>{convertTimestampToDateString(bookingDetails?.idealProjectStartDate || 0)}</ValueParagraph>
                    </EntryBox>

                    {bookingDetails?.latestProjectStartDate && (
                        <EntryBox
                            css={css`
                                flex: 1;
                            `}
                        >
                            <GreyParagraph
                                css={css`
                                    margin-bottom: 6px;
                                `}
                            >
                                Latest Project Start Date:
                            </GreyParagraph>
                            <ValueParagraph>{convertTimestampToDateString(bookingDetails.latestProjectStartDate)}</ValueParagraph>
                        </EntryBox>
                    )}
                </FlexBox>

                <EntryBox>
                    <GreyParagraph
                        css={css`
                            margin-bottom: 6px;
                        `}
                    >
                        Project Stack:
                    </GreyParagraph>
                    <ValueParagraph>
                        {bookingDetails?.techStack.reduce((prev, curr) => {
                            return prev ? prev + ', ' + curr : curr
                        }, '')}
                    </ValueParagraph>
                </EntryBox>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <ControlledRadioGroup
                        control={control}
                        name='response'
                        defaultValue={getValues('response')}
                        options={[
                            {
                                value: 'ACCEPTED',
                                label: 'Yes, I confirm this Specialist’s availability for the duration of the project',
                                type: ControlledRadioGroupOptionType.OPTION,
                            },
                            {
                                value: 'REJECTED',
                                label: 'No, this Specialist is not available for part or all of the project duration',
                                type: ControlledRadioGroupOptionType.OPTION,
                                wrapperStyle: css`
                                    margin-bottom: 24px;
                                `,
                            },
                        ]}
                    />

                    {watchResponse === 'REJECTED' && (
                        <FieldWrapper noLabel>
                            <ControlledAutocompleteSelect
                                canFilter={false}
                                control={control}
                                name='refuseReason'
                                placeholder='Choose a reason'
                                options={ResponseOptions}
                                selectedLabelTransformer={mapResponseType}
                                defaultValues={getValues('refuseReason') ? getValues('refuseReason') : []}
                                dropdownWidth='100%'
                                valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            />
                        </FieldWrapper>
                    )}

                    <FieldWrapper>
                        <ControlledTextarea
                            label={isOther ? 'Written Clarification:' : 'Written Clarification (Optional):'}
                            name='comment'
                            control={control}
                            placeholder='Type something'
                        />
                    </FieldWrapper>

                    <p>
                        If you confirm your Specialist’s availability, the client will be notified and given your details to contact you
                        directly to finalize the details of the project.
                    </p>

                    <Divider />

                    <ButtonContainer>
                        <Button
                            css={css`
                                margin-right: 28px;
                            `}
                            variant='link'
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button type='submit' variant='primary' disabled={isSubmitting || hasErrors(errors)}>
                            Send Response
                        </Button>
                    </ButtonContainer>
                </form>
            </div>
        </Modal>
    )
}

export { BookNowResponseModal }
