import { Paging, PagingProps } from '../../contracts/common/pagingProps'
import { SearchLocation } from '../../contracts/common/searchLocation'
import { Sorting } from '../../contracts/common/sorting'
import { AvailableOn } from '../../contracts/search/availableOn'
import { SearchSpecialist } from '../../contracts/search/searchSpecialist'
import { StringMapping } from '../../types'

export interface Highlights {
    [key: string]: Array<string>
}

export type NonSearchQueryableFields = {
    savedProfile?: boolean
}

export interface SearchSpecialistEntry {
    score: number
    specialist: SearchSpecialist
    highlights: Highlights
}

export interface SpecialistsSearchFilters {
    seniorities?: Array<string>
    skills?: Array<string>
    industries?: Array<string>
    languages?: Array<string>
    availableOn?: Array<string>
    experience?: {
        lte: number
        gte: number
    }
    locations?: Array<SearchLocation>
    remote?: boolean
    rate?: {
        gte: number
        lte: number
    }
    availableFrom?: number
    availableTo?: number
    showForCompany?: ShowForCompany
    currencies?: Array<string>
}

export interface SpecialistsSearchRequest extends SpecialistsSearchFilters {
    searchText?: string
    paging?: PagingProps
    savedProfile?: boolean
}

export interface SpecialistsSearchResponse {
    total: number
    specialists: SearchSpecialistEntry[]
    matchingCount: number
}

export enum ShowForCompany {
    ALL = 'ALL',
    MY = 'MY',
    OTHERS = 'OTHERS',
}

const showForCompanyMapping: StringMapping = {
    [ShowForCompany.ALL]: 'Show All Specialists',
    [ShowForCompany.MY]: 'Only my Specialists in search results',
    [ShowForCompany.OTHERS]: 'Exclude my Specialists from search results',
}

const mapShowForCompany = (type: ShowForCompany) => {
    return showForCompanyMapping[type]
}

const showForCompanyTypes = [ShowForCompany.ALL, ShowForCompany.MY, ShowForCompany.OTHERS]

export interface SearchFiltersRequest {
    searchText?: string
}

export interface SearchFiltersResponse {
    skills?: Array<string>
    languages?: Array<string>
    projects?: Array<string>
    industries?: Array<string>
    seniorities?: Array<string>
    minExperience?: number
    maxExperience?: number
    locations?: Array<SearchLocation>
    minRate?: number
    maxRate?: number
    availableOn?: Array<AvailableOn>
    currencies?: Array<string>
}

export type BookingsFilters = {
    seniorities?: Array<string>
    statuses?: Array<string>
    roles?: Array<string>
}

export type BookingsSearchRequest = Partial<BookingsFilters> &
    Paging &
    Sorting & {
        searchText?: string
    }

export type BookingsSearchResponse = {
    bookings: Array<Booking>
    total: number
}

export type BookingsFiltersRequest = Partial<BookingsFilters> & {
    searchText?: string
}

export type BookingsFiltersResponse = BookingsFilters & {}

export interface Booking {
    id: string
    projectName: string
    role: string
    lastUpdatedAt: number
    bookingStatus: string
    bookingRequestSentOn: number
    specialistId: string
    bookedBy: string
    anonymousNumber: number
}

export interface BookingDetails {
    bookingStatus: string
    idealProjectStartDate: number
    lastUpdatedAt: number
    latestProjectStartDate: number
    projectDuration: string
    projectName: string
    techStack: Array<string>
}

export { mapShowForCompany, showForCompanyMapping, showForCompanyTypes }
