/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, ReactNode, useCallback } from 'react'

type SwitchTabProps = {
    name: string
    description: ReactNode
    header: ReactNode
    color: string
    onTabClick: () => void
    isSelected?: boolean
    disabled?: boolean
    styles?: SerializedStyles
}

const SwitchTab: FunctionComponent<React.PropsWithChildren<SwitchTabProps>> = ({
    name,
    header,
    description,
    color,
    onTabClick,
    isSelected,
    disabled,
    styles,
}) => {
    const handleClick = useCallback(() => {
        if (onTabClick && !disabled) {
            onTabClick()
        }
    }, [disabled, onTabClick])

    const theme = useTheme()

    return (
        <button
            key={name}
            css={css`
                border-radius: 2px;
                cursor: ${disabled ? 'not-allowed' : 'pointer'};
                outline: none;
                border: none;
                padding: 16px;
                border: 1px solid ${disabled ? theme.colors.gray_1 : color};
                background-color: ${disabled ? theme.colors.gray_1 : isSelected ? color : 'transparent'};
                flex: 1;
                text-align: left;

                :first-of-type {
                    margin-left: 0;
                }
                &:hover {
                    background-color: ${disabled ? theme.colors.gray_1 : color};
                }
                ${styles}
            `}
            onClick={handleClick}
        >
            {header}
            {description}
        </button>
    )
}

export { SwitchTab }
