/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { OpportunityLocationTypeV2Enum } from '../../contracts/opportunities-manager/contracts'
import { Nullable } from '../../types'

const OnsiteRemoteTag: FunctionComponent<{ type: Nullable<OpportunityLocationTypeV2Enum> }> = ({ type }) => (
    <div
        css={css`
            line-height: 16px;
            text-align: center;
            margin-right: 8px;
            white-space: nowrap;
            border-radius: 2px;
            font-weight: 500;
        `}
        data-testid='location-type'
    >
        {type === OpportunityLocationTypeV2Enum.ON_SITE ? 'On Site:' : type === OpportunityLocationTypeV2Enum.HYBRID && 'Hybrid from:'}
    </div>
)

export { OnsiteRemoteTag }
