/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import useCollapse from 'react-collapsed'
import skillsformRating from '../../../../../src/assets/images/skillform_rating.gif'
import { Button } from '../../../../components/Button'
import { mqMax } from '../../../../GlobalStyle'

type InstructionBoxProps = {}

const InstructionBox: FunctionComponent<React.PropsWithChildren<InstructionBoxProps>> = () => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])

    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px;
                    width: 100%;
                    background-color: ${theme.colors.beige_1};
                    margin: 0;
                    ${mqMax[2]} {
                        margin-bottom: 24px;
                    }
                `}
            >
                <h6>Rating team members skills</h6>
                <p>
                    <strong>Please follow the instructions below:</strong>
                </p>
                <div {...getCollapseProps()}>
                    <ol
                        css={css`
                            padding-left: 20px;
                        `}
                    >
                        <li>Rate whether you or your teammate have a more advanced knowledge of a given skill.</li>
                        <li>If you feel that your expertise is similar, choose “Same level”.</li>
                        <li>If you don’t know how advanced is yours teammate knowledge of a given skill, feel free to skip it.</li>

                        <img
                            alt='Skills rating'
                            src={skillsformRating}
                            css={css`
                                box-shadow: ${theme.shadow.small};
                                border-radius: 4px;
                                width: 100%;
                                max-width: 314px;
                                margin: 8px 0;
                            `}
                        />
                    </ol>
                </div>

                <Button variant='linkForm' {...getToggleProps({ onClick: toggle })}>
                    {isExpanded ? 'Hide instructions' : 'Expand instructions'}{' '}
                </Button>
            </div>
        </Fragment>
    )
}

export { InstructionBox }
