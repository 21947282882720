/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from './Button'
import { Icon } from './Icon'

type BackLinkProps = {
    text: string
    path?: string
    style?: SerializedStyles
    onClick?: () => void
    dataTestId?: string
}

const BackLink: FunctionComponent<React.PropsWithChildren<BackLinkProps>> = ({ text, path, style, onClick, dataTestId, ...rest }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        } else if (path) {
            navigate(path)
        }
    }, [onClick, path, navigate])

    return (
        <Button
            variant='text'
            dataTestId={dataTestId}
            css={css`
                display: flex;
                width: fit-content;
                color: ${theme.colors.gray_6};
                padding-left: 0;
                padding-right: 0;

                &:hover {
                    text-decoration: underline;
                    text-decoration-color: ${theme.colors.main};
                }
                ${style}
            `}
            onClick={handleClick}
            {...rest}
            data-testid='back-link'
        >
            <span
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                <Icon
                    name='back'
                    size={16}
                    css={css`
                        vertical-align: middle;
                        margin-right: 8px;
                    `}
                />
                {text}
            </span>
        </Button>
    )
}

export { BackLink }
