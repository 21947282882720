/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { AuthPageLayout } from '../../components/layout/AuthPageLayout'
import { ManagerLoginForm } from '../../components/ManagerLoginForm'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { RegisterForm } from '../../components/RegisterForm'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { getCompanyInvitationsUserRegistrationStatus } from './api'

const CompanyInvitationAuthPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const query = useQuery()
    const { prospectId } = useParams<{ prospectId: string }>()
    const [isUserRegistered, setIsUserRegistered] = useState<Nullable<boolean>>(null)

    const log = useLogger()
    const { addError } = useNotifications()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    useEffect(() => {
        const userEmail = query.get('userEmail')
        layoutToggleLoader(true)
        if (prospectId && userEmail) {
            getCompanyInvitationsUserRegistrationStatus(prospectId, userEmail)
                .then(({ userRegistered }) => {
                    setIsUserRegistered(userRegistered)
                })
                .catch(err => {
                    log(err)
                    addError('Invitation link is broken!')
                    history.push('/')
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        } else {
            addError('Invitation link is broken!')
            history.push('/')
        }
    }, [addError, layoutToggleLoader, log, prospectId, query])

    return isUserRegistered ? (
        <AuthPageLayout>
            <ManagerLoginForm
                header={<h5>Log in to accept the invite and join {query.get('companyName')} on Talent Alpha</h5>}
                forcedEmail={decodeURIComponent(query.get('userEmail') || '') || undefined}
            />
            <p
                css={css`
                    margin-top: 16px;
                    font-size: 12px;
                `}
            >
                If you’re using Google or Microsoft authentication, please make sure to use the same email address you received the
                invitation email at.
            </p>
        </AuthPageLayout>
    ) : isUserRegistered === false ? (
        <RegisterForm
            forcedEmail={decodeURIComponent(query.get('userEmail') || '') || undefined}
            header={<h5>Create an account to accept the invite and join {query.get('companyName')} on Talent Alpha</h5>}
        />
    ) : null
}

export { CompanyInvitationAuthPage }
