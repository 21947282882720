/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useState } from 'react'
import useCollapse from 'react-collapsed'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { H6, SkillsBoxWrapper } from './styles'

export type CheckableSkill = {
    name: string
    checked?: boolean
}

type SuggestedSkillsBoxProps = {
    handleSkillAdd: (skill: Array<string>) => void
    handleSkillRemove?: (skill: string) => void
    suggestedSkills: Array<string | CheckableSkill>
    className?: string
    title?: string
    showToggle?: boolean
}
type SkillItemProps = {
    handleSkillAdd: (skill: Array<string>) => void
    handleSkillRemove?: (skill: string) => void
    skill: string
    checked?: boolean
}

const SkillItem: FunctionComponent<React.PropsWithChildren<SkillItemProps>> = ({ skill, handleSkillAdd, handleSkillRemove, checked }) => {
    const theme = useTheme()

    const addSkill = useCallback(() => {
        handleSkillAdd([skill])
    }, [handleSkillAdd, skill])

    const removeSkill = useCallback(() => {
        if (handleSkillRemove) {
            handleSkillRemove(skill)
        }
    }, [handleSkillRemove, skill])

    return (
        <FlexBox
            justifyContent='center'
            alignItems='center'
            css={css`
                background-color: ${theme.colors.violet_2};
                color: ${theme.colors.violet_5};
                padding: 8px;
                margin-right: 8px;
                margin-top: 8px;
                border-radius: 2px;
                font-size: 12px;
                cursor: default;
                ${!checked &&
                css`
                    img {
                        opacity: 0;
                    }
                    &:hover {
                        img {
                            opacity: 1;
                        }
                    }
                `}
            `}
        >
            <div
                css={css`
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 17px;
                    background-color: ${theme.colors.white};
                    padding-left: 1px;
                    margin-right: 6px;
                    cursor: pointer;
                    &:hover {
                        background-color: ${theme.colors.violet_3};
                    }
                `}
                onClick={addSkill}
            >
                {checked ? <Icon name='check' /> : '+'}
            </div>
            {skill}
            {handleSkillRemove && (
                <Icon
                    size={20}
                    name='close-grey'
                    style={css`
                        cursor: pointer;
                    `}
                    onClick={removeSkill}
                />
            )}
        </FlexBox>
    )
}

const SuggestedSkillsBox: FunctionComponent<React.PropsWithChildren<SuggestedSkillsBoxProps>> = ({
    handleSkillAdd,
    handleSkillRemove,
    suggestedSkills,
    className,
    title,
    showToggle = true,
}) => {
    const [isExpanded, setIsExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])

    const getSkill = useCallback((skill: string | CheckableSkill) => (typeof skill === 'string' ? skill : skill.name), [])

    return (
        <SkillsBoxWrapper data-testid='suggested-skill-box' className={className}>
            <FlexBox justifyContent='space-between' alignItems='center'>
                <H6>
                    {title || 'Suggested skills'} ({suggestedSkills.length})
                </H6>
                {showToggle && (
                    <Button
                        variant='linkForm'
                        {...getToggleProps({
                            onClick: toggle,
                            style: css`
                                padding: 0;
                            `,
                        })}
                    >
                        {isExpanded ? 'Hide' : 'Show'}
                    </Button>
                )}
            </FlexBox>
            <div {...getCollapseProps()}>
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                    `}
                >
                    {suggestedSkills.map(skill => (
                        <SkillItem
                            key={getSkill(skill)}
                            skill={getSkill(skill)}
                            handleSkillAdd={handleSkillAdd}
                            handleSkillRemove={handleSkillRemove}
                            checked={typeof skill !== 'string' && skill.checked}
                        />
                    ))}
                </FlexBox>
            </div>
        </SkillsBoxWrapper>
    )
}

export { SuggestedSkillsBox }
