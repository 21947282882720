import queryString from 'query-string'
import { SortEntryWithField, SortOrder } from '../../../contracts/common/sorting'
import { encodeField, parseToArray, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { SearchOpportunitiesRequestParams } from '../../../contracts/opportunities-manager/contracts'

const stringifyOpportunitiesManagerSearchRequestToQuery = (searchRequest: SearchOpportunitiesRequestParams): string => {
    const params = []
    const {
        projectStartDate,
        projectEndDate,
        lastUpdate,
        addedOn,
        countries,
        regions,
        statuses,
        paging,
        searchText,
        ownerName,
        sorting,
        locationTypes,
        industries,
    } = searchRequest

    if (statuses && statuses.length > 0) {
        params.push(`statuses=${encodeField(statuses)}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (ownerName) {
        params.push(`ownerName=${encodeField(ownerName)}`)
    }

    if (projectStartDate) {
        params.push(`projectStartDate=${projectStartDate}`)
    }

    if (projectEndDate) {
        params.push(`projectEndDate=${projectEndDate}`)
    }

    if (lastUpdate) {
        params.push(`lastUpdate=${lastUpdate}`)
    }

    if (addedOn) {
        params.push(`addedOn=${addedOn}`)
    }

    if (countries && countries.length > 0) {
        params.push(`countries=${encodeField(countries)}`)
    }

    if (regions && regions.length > 0) {
        params.push(`regions=${encodeField(regions)}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (sorting && sorting.length > 0) {
        params.push(`sorting=${sorting.map(sort => `${(sort as SortEntryWithField).field}__${sort.order}`).join(',')}`)
    }

    if (locationTypes && locationTypes.length > 0) {
        params.push(`locationTypes=${encodeField(locationTypes)}`)
    }
    if (industries && industries.length > 0) {
        params.push(`industries=${encodeField(industries)}`)
    }

    return params.join('&')
}

const parseQueryToOpportunitiesManagerSearchRequest = (searchQuery: string): SearchOpportunitiesRequestParams => {
    const params = queryString.parse(searchQuery, { decode: false })
    const {
        projectStartDate,
        projectEndDate,
        lastUpdate,
        addedOn,
        countries,
        regions,
        statuses,
        page,
        searchText,
        size,
        ownerName,
        sorting,
        locationTypes,
        industries,
    } = params

    const result = {
        searchText: parseToString(searchText),
        projectStartDate: projectStartDate ? parseToNumber(projectStartDate) : null,
        projectEndDate: projectEndDate ? parseToNumber(projectEndDate) : null,
        lastUpdate: lastUpdate ? parseToNumber(lastUpdate) : null,
        addedOn: addedOn ? parseToNumber(addedOn) : null,
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, 10),
        },
        statuses: parseToArray(statuses),
        countries: parseToArray(countries),
        regions: parseToArray(regions),
        ownerName: parseToString(ownerName),
        sorting: sorting ? [{ field: (sorting as string).split('__')[0], order: (sorting as string).split('__')[1] as SortOrder }] : [],
        locationTypes: parseToArray(locationTypes),
        industries: parseToArray(industries),
    }

    return result
}

export { parseQueryToOpportunitiesManagerSearchRequest, stringifyOpportunitiesManagerSearchRequestToQuery }
