/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import moment, { Moment } from 'moment'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../../../components/filters/FiltersBarHelpers'
import { RangeSelection } from '../../../components/filters/RangeSelection'
import { AutocompleteSelect } from '../../../components/inputs/autocomplete-select/AutocompleteSelect'
import { Checkbox } from '../../../components/inputs/Checkbox'
import { DatePickerInput } from '../../../components/inputs/DatePickerInput'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { AvailableOn } from '../../../contracts/search/availableOn'
import { mqMin } from '../../../GlobalStyle'
import { useUserContext } from '../../../hooks/useUserContext'
import { ReduxContext } from '../../../redux/Store'
import { toMonths, toYears } from '../../../utils/searchFilters'
import { useLogger } from '../../../utils/useLogger'
import { findSyndicateNameById } from 'src/utils/values'
import { mapShowForCompany, SearchFiltersResponse, ShowForCompany, showForCompanyTypes, SpecialistsSearchFilters } from '../contracts'
import { countSelectedFilters } from '../talentMarketplaceUtils'

const initialFilters: SpecialistsSearchFilters = {
    seniorities: [],
    skills: [],
    industries: [],
    languages: [],
    locations: [],
    availableOn: [],
    remote: false,
    showForCompany: ShowForCompany.ALL,
    currencies: [],
}

type FiltersBarProps = {
    onChangeFilters: (filters: SpecialistsSearchFilters) => void
    onClearFilters: () => void
    selectedFilters: SpecialistsSearchFilters
    possibleFiltersValues: SearchFiltersResponse
    filtersOpened: boolean
    syndicates: Array<AvailableOn>
}

const FiltersBar: FunctionComponent<React.PropsWithChildren<FiltersBarProps>> = ({
    onChangeFilters,
    selectedFilters,
    onClearFilters,
    possibleFiltersValues,
    filtersOpened,
    syndicates,
}) => {
    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)

    const { activeContext } = useUserContext()

    const log = useLogger('error')
    const [hasCompany, setHasCompany] = useState<boolean>(false)
    const [filters, setFilters] = useState<SpecialistsSearchFilters>(selectedFilters)
    const [seniorities, setSeniorities] = useState<Array<string> | undefined>([])
    const [skills, setSkills] = useState<Array<string>>([])
    const [industries, setIndustries] = useState<Array<string>>([])
    const [languages, setLanguages] = useState<Array<string>>([])
    const [minPossibleExperience, setMinPossibleExperience] = useState(0)
    const [maxPossibleExperience, setMaxPossibleExperience] = useState(40)
    const [minPossibleRate, setMinPossibleRate] = useState(0)
    const [maxPossibleRate, setMaxPossibleRate] = useState(250)

    const isInSyndicate = useMemo(() => syndicates.length > 1, [syndicates.length])

    const countries = useMemo(() => {
        const locations = possibleFiltersValues.locations
        return locations ? locations.map(location => location.country) : []
    }, [possibleFiltersValues.locations])

    const cities = useMemo(() => {
        const possible = possibleFiltersValues.locations
        const country = filters.locations && filters.locations.length ? filters.locations[0].country : null
        if (country && possible) {
            const foundLocation = possible.find(location => location.country === country)
            if (foundLocation) {
                return foundLocation.cities
            }
        }
        return []
    }, [possibleFiltersValues.locations, filters.locations])

    const setSortedValues = (values: Array<string> | undefined, setter: (vals: Array<string>) => void) => {
        if (values) {
            const sortedValues = [...values]
            sortedValues.sort()
            setter(sortedValues)
        }
    }

    useEffect(() => {
        if (activeContext?.companyId) {
            if (activeContext.companyId) {
                setHasCompany(true)
            } else {
                setHasCompany(false)
            }
        }
    }, [log, featureFlags, activeContext])

    useEffect(() => {
        setSeniorities(possibleFiltersValues.seniorities)
        setSortedValues(possibleFiltersValues.skills, setSkills)
        setSortedValues(possibleFiltersValues.industries, setIndustries)
        setSortedValues(possibleFiltersValues.languages, setLanguages)

        if (possibleFiltersValues.minExperience !== undefined) {
            setMinPossibleExperience(Math.ceil(possibleFiltersValues.minExperience))
        }
        if (possibleFiltersValues.maxExperience !== undefined) {
            setMaxPossibleExperience(Math.ceil(possibleFiltersValues.maxExperience))
        }
        if (possibleFiltersValues.minRate !== undefined) {
            setMinPossibleRate(possibleFiltersValues.minRate)
        }
        if (possibleFiltersValues.maxRate !== undefined) {
            setMaxPossibleRate(possibleFiltersValues.maxRate)
        }
    }, [filters, possibleFiltersValues])

    const onSelectFilterChange = (values: Array<string>, filterName: any) => {
        // TODO: type
        const currentFilters: any = { ...filters } // TODO: type
        if (currentFilters[filterName]) {
            currentFilters[filterName] = values
        } else if (filterName.startsWith('locations')) {
            // temporary solution with one location
            if (filterName === 'locations.country') {
                if (values.length > 0) {
                    currentFilters.locations = [{ country: values[0], cities: [] }]
                } else {
                    currentFilters.locations = []
                }
            } else if (filterName === 'locations.country.cities') {
                const country = currentFilters.locations[0].country
                currentFilters.locations = [{ country, cities: values }]
            }
        }

        setFilters(currentFilters)
        onChangeFilters(currentFilters)
    }

    const onRangeFilterChange = useCallback(
        (gte: number, lte: number) => {
            const experience = { gte, lte }
            const currentFilters = { ...filters }
            currentFilters.experience = experience

            setFilters(currentFilters)
            onChangeFilters(currentFilters)
        },
        [filters, onChangeFilters],
    )

    const onRateRangeFilterChange = useCallback(
        (gte: number, lte: number) => {
            const rate = { gte, lte }
            const currentFilters = { ...filters }
            currentFilters.rate = rate

            setFilters(currentFilters)
            onChangeFilters(currentFilters)
        },
        [filters, onChangeFilters],
    )

    const onAvailableFromChange = useCallback(
        (availableFrom: number) => {
            const currentFilters = { ...filters }
            currentFilters.availableFrom = Number(
                moment
                    .utc(new Date(availableFrom * 1000))
                    .startOf('day')
                    .format('X'),
            )
            setFilters(currentFilters)
            onChangeFilters(currentFilters)
        },
        [filters, onChangeFilters],
    )

    const onRemoteFilterChange = () => {
        const currentFilters = { ...filters }
        currentFilters.remote = !currentFilters.remote

        setFilters(currentFilters)
        onChangeFilters(currentFilters)
    }

    const onClearAll = () => {
        setFilters(initialFilters)
        onClearFilters()
    }

    const availableOnCurrentValues = useMemo(
        () => (filters?.availableOn || []).filter(v => ((possibleFiltersValues?.availableOn || []).find(a => a.id === v) ? v : null)),
        [filters, possibleFiltersValues],
    )

    return (
        <FiltersBarWrapper filtersOpened={filtersOpened}>
            <FlexBox
                css={css`
                    width: 100%;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Seniority'
                            options={seniorities}
                            filterName='seniorities'
                            isActive={(filters?.seniorities?.length || 0) > 0}
                            currentValues={filters.seniorities}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='specialist-search-filters-bar-seniority'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Skills'
                            options={skills}
                            filterName='skills'
                            currentValues={filters.skills}
                            isActive={(filters?.skills?.length || 0) > 0}
                            virtualized
                            dropdownWidth='280px'
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='specialist-search-filters-bar-skills'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Currency'
                            options={possibleFiltersValues.currencies || []}
                            filterName='currencies'
                            currentValues={filters.currencies || []}
                            onSelectedValuesChange={onSelectFilterChange}
                            canClear
                            dataTestId='specialist-search-filters-bar-currency'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <RangeSelection
                            placeholder='Rate'
                            label='Rate'
                            isActive={
                                (filters?.rate?.gte || minPossibleRate) > minPossibleRate ||
                                (filters?.rate?.lte || maxPossibleRate) < maxPossibleRate
                            }
                            min={minPossibleRate}
                            max={maxPossibleRate}
                            onRangeChange={onRateRangeFilterChange}
                            selectedMin={filters.rate ? filters.rate.gte : null}
                            selectedMax={filters.rate ? filters.rate.lte : null}
                            dataTestId='specialist-search-filters-bar-rate'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Available from'
                            onChange={onAvailableFromChange}
                            value={filters.availableFrom}
                            isActive={Boolean(filters.availableFrom)}
                            disabledDate={(date: Moment): boolean => date.isBefore(moment.utc())}
                            dataTestId='specialist-search-filters-bar-available-from'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Industry'
                            options={industries}
                            filterName='industries'
                            currentValues={filters.industries}
                            isActive={(filters?.industries?.length || 0) > 0}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='specialist-search-filters-bar-industry'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Language'
                            options={languages}
                            filterName='languages'
                            currentValues={filters.languages}
                            isActive={(filters?.languages?.length || 0) > 0}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='specialist-search-filters-bar-language'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <RangeSelection
                            placeholder='Experience'
                            label='Experience (In Years)'
                            isActive={
                                toYears(filters?.experience?.gte || minPossibleExperience) > toYears(minPossibleExperience) ||
                                toYears(filters?.experience?.lte || maxPossibleExperience) < toYears(maxPossibleExperience)
                            }
                            min={toYears(minPossibleExperience)}
                            max={toYears(maxPossibleExperience)}
                            valueTransformer={toMonths}
                            onRangeChange={onRangeFilterChange}
                            selectedMin={filters.experience ? toYears(filters.experience.gte) : null}
                            selectedMax={filters.experience ? toYears(filters.experience.lte) : null}
                            dataTestId='specialist-search-filters-bar-experience'
                        />
                    </FilterColumn>

                    {isInSyndicate && (
                        <FilterColumn>
                            <AutocompleteSelect
                                multiple
                                placeholder='Available On'
                                options={possibleFiltersValues?.availableOn?.map(v => v.id) || []}
                                filterName='availableOn'
                                onSelectedValuesChange={onSelectFilterChange}
                                currentValues={availableOnCurrentValues}
                                isActive={(filters?.availableOn || []).length > 0}
                                selectedLabelTransformer={(id: string) =>
                                    findSyndicateNameById(id, possibleFiltersValues?.availableOn || [])
                                }
                                dataTestId='specialist-search-filters-bar-available-on'
                            />
                        </FilterColumn>
                    )}

                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Country'
                            options={countries}
                            filterName='locations.country'
                            isActive={(filters?.locations?.length ? filters.locations[0].country.length : 0) > 0}
                            currentValues={filters && filters.locations && filters.locations.length ? [filters.locations[0].country] : []}
                            onSelectedValuesChange={onSelectFilterChange}
                            canClear
                            dataTestId='specialist-search-filters-bar-country'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='City'
                            isActive={(filters?.locations?.length ? filters.locations[0].cities.length : 0) > 0}
                            options={cities}
                            filterName='locations.country.cities'
                            disabled={filters.locations && filters.locations.length > 0 ? false : true}
                            currentValues={filters && filters.locations && filters.locations.length ? filters.locations[0].cities : []}
                            onSelectedValuesChange={onSelectFilterChange}
                            dataTestId='specialist-search-filters-bar-city'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <div
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                height: 40px;
                            `}
                        >
                            <Checkbox
                                onChange={onRemoteFilterChange}
                                name='talent_marketplace_remote'
                                checked={!!filters.remote}
                                checkboxLabel='Remote'
                                dataTestId='specialist-search-filters-bar-remote'
                            />
                        </div>
                    </FilterColumn>
                    {hasCompany && (
                        <div
                            css={css`
                                display: block;
                                padding: 0 12px 0 0;
                                width: 100%;
                                margin-bottom: 16px;
                                ${mqMin[1]} {
                                    max-width: 250px;
                                    width: 100%;
                                }
                            `}
                        >
                            <AutocompleteSelect
                                placeholder='Filter Specialists'
                                filterName='showForCompany'
                                options={showForCompanyTypes}
                                currentValues={[filters && filters.showForCompany]}
                                onSelectedValuesChange={onSelectFilterChange}
                                canFilter={false}
                                selectedLabelTransformer={mapShowForCompany}
                                dataTestId='specialist-search-filters-bar-results-type'
                            />
                        </div>
                    )}
                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <FiltersClearTrigger onClear={onClearAll} quantity={countSelectedFilters(filters)} />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { FiltersBar, initialFilters }
