/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useMemo } from 'react'
import { mqMax } from '../../GlobalStyle'
import { COLOR_PALETTE } from '../../theme/colors'
import { Nullable } from '../../types'
import { convertTimestampToDateString, monthFormat } from '../../utils/dates'

type DetailRowProps = {
    isPrintable?: boolean
    content?: string
}

const DetailRow: FunctionComponent<React.PropsWithChildren<DetailRowProps>> = ({ isPrintable, children, content = '' }) => {
    const styles = useMemo(() => {
        return isPrintable
            ? css`
                  margin-bottom: 5px;
              `
            : css`
                  margin-bottom: 16px;
              `
    }, [isPrintable])

    return (
        <div
            css={css`
                display: flex;
                ${styles}
                content: ${content};
            `}
        >
            {children}
        </div>
    )
}

type DetailKeyProps = {
    wide?: boolean
    disabled?: boolean
}

const DetailKey = styled.div<DetailKeyProps>`
    flex: 1;
    color: ${({ disabled }) => (disabled ? COLOR_PALETTE.gray_3 : COLOR_PALETTE.gray_4)};
    max-width: ${({ wide }) => (wide ? 180 : 160)}px;
`
type DetailValueProps = {
    disabled?: boolean
}

const DetailValue = styled.div<DetailValueProps>`
    color: ${({ disabled }) => (disabled ? COLOR_PALETTE.gray_3 : COLOR_PALETTE.gray_6)};
    display: flex;
    word-break: break-word;
    flex: 2;
    flex-wrap: wrap;
`

type DateStringProps = {
    isPrintable?: boolean
}

const DateString: FunctionComponent<React.PropsWithChildren<DateStringProps>> = ({ children, isPrintable }) => {
    return (
        <span
            css={css`
                background-color: ${isPrintable ? null : COLOR_PALETTE.gray_1};
                border-radius: 55px;
                white-space: nowrap;
                padding: 0 8px;
                margin: 0;
                content: ${children?.toString()};
            `}
        >
            {children}
        </span>
    )
}

type DurationDetailsProps = DetailKeyProps & {
    from: Nullable<number>
    to: Nullable<number>
    isActual: boolean
    label?: string
    actualText?: string
    isPrintable?: boolean
}

const DurationDetails: FunctionComponent<React.PropsWithChildren<DurationDetailsProps>> = ({
    from,
    to,
    isActual,
    label = 'Duration:',
    actualText = 'In Progress',
    wide,
    isPrintable = false,
    disabled = false,
}) => {
    return (
        <Fragment>
            {!isPrintable && (
                <DetailKey
                    wide={wide}
                    css={css`
                        margin-right: 8px;
                        ${mqMax[1]} {
                            margin-right: 0;
                        }
                    `}
                    disabled={disabled}
                >
                    {label}
                </DetailKey>
            )}
            <DetailValue
                css={css`
                    flex-wrap: nowrap;
                    ${mqMax[1]} {
                        flex-wrap: wrap;
                    }
                `}
                disabled={disabled}
            >
                {from !== null && <DateString isPrintable={isPrintable}>{convertTimestampToDateString(from, monthFormat)}</DateString>}
                <DetailKey
                    css={css`
                        margin-right: 8px;
                        margin-left: 8px;
                        max-width: fit-content;
                    `}
                    disabled={disabled}
                >
                    -
                </DetailKey>

                {to !== null && <DateString isPrintable={isPrintable}>{convertTimestampToDateString(to, monthFormat)}</DateString>}
                {isActual && <DateString isPrintable={isPrintable}>{actualText}</DateString>}
            </DetailValue>
        </Fragment>
    )
}

type DetailProps = DetailKeyProps & {
    label: string
    value?: string
    isPrintable?: boolean
}

const Detail: FunctionComponent<React.PropsWithChildren<DetailProps>> = ({
    label,
    value,
    wide,
    children,
    isPrintable,
    disabled = false,
}) => {
    return (
        <Fragment>
            <DetailRow isPrintable={isPrintable} content={value}>
                <DetailKey disabled={disabled} wide={wide}>
                    {label}
                </DetailKey>
                {!children && <DetailValue disabled={disabled}>{value || ' - '}</DetailValue>}
                {children && <DetailValue disabled={disabled}>{children}</DetailValue>}
            </DetailRow>
        </Fragment>
    )
}

export { Detail, DetailKey, DetailRow, DetailValue, DurationDetails, DateString }
