/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useCallback } from 'react'
import { Button } from '../../../../components/Button'
import { Icon } from '../../../../components/Icon'
import { COLOR_PALETTE } from '../../../../GlobalStyle'
import { FlowStep } from '../../enums'

const CheckIcon = () => (
    <div
        css={css`
            background-color: ${COLOR_PALETTE.green_2};
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 11px;
        `}
    >
        <Icon name={'check'} size={14} />
    </div>
)

const ListItem = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 16px;
`

const Description = styled.div`
    width: 286px;
`

type IntroductionProps = {
    changeStep: (step: FlowStep) => void
}

const Introduction: FunctionComponent<React.PropsWithChildren<IntroductionProps>> = ({ changeStep }) => {
    const handleStart = useCallback(() => {
        changeStep(FlowStep.Instruction)
    }, [changeStep])

    return (
        <section
            css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                width: 375px;
                height: 100%;
                min-height: 620px;
            `}
        >
            <div>
                <h1
                    css={css`
                        font-size: 32px;
                        width: 76px;
                        line-height: 32px;
                    `}
                >
                    Dot memory
                </h1>
                <div
                    css={css`
                        font-size: 14px;
                        color: ${COLOR_PALETTE.gray_4};
                    `}
                >
                    by Talent Alpha (2020)
                </div>
            </div>

            <div>
                <ListItem>
                    <CheckIcon />
                    <Description>This game measures your visual working memory.</Description>
                </ListItem>

                <ListItem>
                    <CheckIcon />
                    <Description>
                        For best performance results, make sure that you can focus for 6 - 7 minutes and try to eliminate potential sources
                        of distraction (e.g. mute your phone).
                    </Description>
                </ListItem>

                <div
                    css={css`
                        border: 1px solid ${COLOR_PALETTE.green_2};
                        padding: 45px;
                    `}
                >
                    <h6
                        css={css`
                            font-size: 14px;
                        `}
                    >
                        Try a Practice Game
                    </h6>

                    <div
                        css={css`
                            color: ${COLOR_PALETTE.gray_4};
                            margin-bottom: 22px;
                        `}
                    >
                        Practice how to play the game before we measure your results.
                    </div>

                    <Button
                        css={css`
                            width: 100%;
                        `}
                        onClick={handleStart}
                    >
                        Start Game
                    </Button>
                </div>
            </div>
        </section>
    )
}

export { Introduction }
