/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { FullPhoneInput, PhoneInputProps } from '../inputs/FullPhoneInput'
import { ControlledFieldProps } from './types'

export type ControlledPhoneInputProps = Omit<PhoneInputProps, 'onBlur' | 'onChange' | 'value' | 'errorMessage'> &
    ControlledFieldProps<string> & {}

const ControlledPhoneInput: FunctionComponent<React.PropsWithChildren<ControlledPhoneInputProps>> = ({
    name,
    infoMessage,
    label,
    placeholder,
    type,
    hasPassword,
    disabled,
    style,
    inputStyle,
    defaultValue = '',
    labelTooltip,
    resetOnBlur,
    isActive,
    regex,
    dataTestId,
    countryAreaCodes,
    firstOptionEmpty,
    canClear,
    control,
}) => {
    const {
        field: { onBlur, onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    return (
        <FullPhoneInput
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            infoMessage={infoMessage}
            label={label}
            placeholder={placeholder}
            type={type}
            hasPassword={hasPassword}
            disabled={disabled}
            style={style}
            inputStyle={inputStyle}
            labelTooltip={labelTooltip}
            resetOnBlur={resetOnBlur}
            isActive={isActive}
            regex={regex}
            dataTestId={dataTestId}
            countryAreaCodes={countryAreaCodes}
            firstOptionEmpty={firstOptionEmpty}
            canClear={canClear}
        />
    )
}

export { ControlledPhoneInput }
