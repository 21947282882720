/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import nl2br from 'nl2br'
import { Fragment, FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { FullProfileData } from '../../../../api/api'
import { WhiteLabel } from '../../../../contracts/whiteLabel'
import { COLOR_PALETTE } from '../../../../GlobalStyle'
import { Nullable } from '../../../../types'
import { randomString } from '../../../../utils/strings'
import { DotSeparatedValues } from '../DotSeparatedValues'
import { containsPersonalStrengths, parseProjects } from '../helpers'
import { LanguageBox } from '../LanguageBox'
import { PrintableLogo } from '../PrintableLogo'
import { ProfileExportProjectItem } from '../ProfileExportProjectItem'
import { CertificateItem } from './CertificateItem'
import { QuickSummaryPersonalStrengths } from './QuickSummaryPersonalStrengths'

type LabelProps = {
    backgroundColor?: string
}

const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({ children, backgroundColor = COLOR_PALETTE.violet_2 }) => (
    <div
        css={css`
            background-color: ${backgroundColor};
            border-radius: 2px;
            padding: 3px 10px 4px;
            display: inline-block;
            font-size: 10px;
            content: ${children as string};
        `}
    >
        {children}
    </div>
)

type QuickSummaryProps = {
    profileData?: FullProfileData
    isAnonymized?: boolean
    showBigFive?: boolean
    chosenProjects?: Nullable<Array<string>>
    whiteLabel?: WhiteLabel
}

const QuickSummary: FunctionComponent<React.PropsWithChildren<QuickSummaryProps>> = ({
    profileData,
    isAnonymized = false,
    showBigFive = true,
    chosenProjects,
    whiteLabel,
}) => {
    const filteredProjects = useMemo(() => {
        return profileData?.projects.filter(project => chosenProjects?.includes(project.id))
    }, [chosenProjects, profileData])

    const transformedProjects = useMemo(() => {
        if (isAnonymized && filteredProjects) {
            return parseProjects(filteredProjects)
        } else {
            return filteredProjects
        }
    }, [filteredProjects, isAnonymized])

    return (
        <section
            css={css`
                width: 210mm;
                min-height: 297mm;
                display: flex;
                * {
                    font-size: 10px;
                }
            `}
            className='quick-summary'
        >
            <section
                css={css`
                    border-right: 1px solid ${COLOR_PALETTE.gray_2};
                    background-color: ${whiteLabel?.active ? COLOR_PALETTE.white : COLOR_PALETTE.violet_1};
                    padding: 24px;
                    width: 70mm;
                    top: 0;
                    left: 0;
                    position: fixed;
                    min-height: 297mm;
                    height: 100%;
                `}
            ></section>
            <section
                css={css`
                    padding: 24px;
                    width: 70mm;
                    top: 0;
                    left: 0;
                    position: absolute;
                    min-height: 297mm;
                    height: 100%;
                `}
            >
                <PrintableLogo whiteLabel={whiteLabel} />
                <div
                    css={css`
                        margin: 16px 0 10px;
                    `}
                >
                    {!isAnonymized && (
                        <div
                            css={css`
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 24px;
                            `}
                        >
                            <div>{profileData?.specialist?.firstName}</div> <div>{profileData?.specialist?.lastName}</div>
                        </div>
                    )}
                </div>
                <div>
                    {profileData?.specialist.seniority && (
                        <div
                            css={css`
                                background-color: ${COLOR_PALETTE.violet_3};
                                border-radius: 2px;
                                margin-bottom: 2px;
                                display: inline-block;
                                padding: 5px 10px;
                                font-weight: 500;
                                white-space: nowrap;
                                content: ${profileData?.specialist.seniority};
                            `}
                        >
                            {profileData?.specialist.seniority}
                        </div>
                    )}
                </div>
                <div>
                    {profileData?.specialist.role && (
                        <div
                            css={css`
                                background-color: ${COLOR_PALETTE.violet_3};
                                border-radius: 2px;
                                margin-bottom: 15px;
                                display: inline-block;
                                padding: 5px 10px;
                                font-weight: 500;
                                white-space: nowrap;
                            `}
                        >
                            {profileData?.specialist.role}
                        </div>
                    )}
                </div>
                {profileData?.summary?.experience && (
                    <Fragment>
                        <div
                            css={css`
                                margin-top: 12px;
                            `}
                        >
                            <Label>Experience: </Label>
                        </div>
                        <div
                            css={css`
                                font-style: normal;
                                font-weight: 500;
                                line-height: 18px;
                                margin-top: 12px;
                            `}
                        >
                            {profileData?.summary?.experience}
                        </div>
                    </Fragment>
                )}

                {(profileData?.languages || []).length > 0 && (
                    <div
                        css={css`
                            margin-top: 24px;
                            margin-bottom: 12px;
                        `}
                    >
                        <Label>Languages: </Label>
                    </div>
                )}
                {profileData?.languages.map(language => (
                    <LanguageBox key={language.id || randomString()} language={language} />
                ))}
                {showBigFive && containsPersonalStrengths(profileData?.personalStrengths?.groups || []) && (
                    <div
                        css={css`
                            margin: 24px 0 12px;
                            width: 100%;
                        `}
                    >
                        <Fragment>
                            <Label>Big Five Personality Traits: </Label>
                            <QuickSummaryPersonalStrengths groups={profileData?.personalStrengths?.groups || []} />
                        </Fragment>
                    </div>
                )}
                <div
                    data-powered-by
                    css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: calc(297mm - 50px);
                        margin: 0 8mm 0 12mm;
                    `}
                >
                    <div
                        css={css`
                            color: ${COLOR_PALETTE.gray_3};
                            text-align: right;
                            margin-bottom: 10px;
                            white-space: nowrap;
                        `}
                    >
                        Powered by:
                    </div>
                    <Link to={profileData?.link || ''} target='_blank' rel='noopener noreferrer'>
                        <PrintableLogo
                            style={css`
                                transform: scale(0.7);
                                width: 90px;
                            `}
                        />
                    </Link>
                </div>
            </section>

            <section
                css={css`
                    padding: 24px;
                    height: 100%;
                    width: 140mm;
                    margin-left: calc(70mm + 24px);
                `}
            >
                <p
                    css={css`
                        content: 'Description';
                    `}
                    dangerouslySetInnerHTML={{
                        __html: nl2br(profileData?.generalInformation?.profileSummary || ''),
                    }}
                />
                {(profileData?.verifiedTopSkills || []).length > 0 && (
                    <div data-avoid-break>
                        <div
                            css={css`
                                margin-top: 24px;
                                margin-bottom: 12px;
                            `}
                        >
                            <Label>Top Skills: </Label>
                        </div>

                        <DotSeparatedValues values={profileData?.verifiedTopSkills} isQuickSummary />
                    </div>
                )}

                {(transformedProjects || []).length > 0 && (
                    <Fragment>
                        <div
                            css={css`
                                margin: 12px 0;
                            `}
                        >
                            <Label>Experience: </Label>
                        </div>
                        {transformedProjects?.map(project => {
                            return <ProfileExportProjectItem key={project.id} project={project} />
                        })}
                    </Fragment>
                )}

                {(profileData?.education.filter(education => education.type === 'CERTIFICATE_COURSE') || []).length > 0 && (
                    <div
                        data-avoid-break
                        css={css`
                            margin-top: 12px;
                        `}
                    >
                        <Label>Certificates: </Label>
                        {profileData?.education.map(
                            certificate =>
                                certificate.type === 'CERTIFICATE_COURSE' && (
                                    <CertificateItem key={certificate.id} certificate={certificate} />
                                ),
                        )}
                    </div>
                )}
            </section>
        </section>
    )
}

export { QuickSummary }
