import { Nullable, StringMapping } from '../../types'

export enum LevelType {
    BASIC = 'BASIC',
    GOOD = 'GOOD',
    EXPERT = 'EXPERT',
    NATIVE = 'NATIVE',
}

export const levelTypes = [LevelType.BASIC, LevelType.GOOD, LevelType.EXPERT, LevelType.NATIVE]

export interface LanguageRequest {
    name: string
    level?: LevelType
}

export interface LanguageResponse extends LanguageRequest {
    id: string
    levelLabel?: string
}

export const levelsMapping: StringMapping = {
    [LevelType.BASIC]: 'Basic',
    [LevelType.GOOD]: 'Good',
    [LevelType.EXPERT]: 'Expert',
    [LevelType.NATIVE]: 'Native',
}

const mapLevelType = (type: Nullable<LevelType>) => {
    return type !== null ? levelsMapping[type] : ''
}

export { mapLevelType }
