import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { mqMax } from '../../GlobalStyle'

export const ContentContainerStyles = css`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 28px 0;

    ${mqMax[2]} {
        padding: 28px 16px;
    }
`

export const WhiteContentContainer = styled.section`
    ${ContentContainerStyles}
`

export const ContentWrapper = styled.div`
    width: 100%;
`
