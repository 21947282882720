/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import useCollapse from 'react-collapsed'
import skillsRating from '../../../src/assets/images/skills_rating.gif'
import { SpecialistSkills } from '../../contracts/skills'
import { mqMax } from '../../GlobalStyle'
import { getSpecialistSkillsEditView } from '../../pages/specialist-skills-edit/api'
import { useSkills } from '../../redux/dictionaryDataHooks'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Icon } from '../Icon'
import { AutocompleteSelect } from '../inputs/autocomplete-select/AutocompleteSelect'
import { Modal } from '../Modal'

type AddSkillsBoxProps = {
    handleSkillSelect: (skills: Array<string>) => void
    skills: Nullable<SpecialistSkills>
    unvalidated?: boolean
    specialistId?: string
}

const getSkillsNames = (skills: Nullable<SpecialistSkills>) =>
    skills
        ? skills.categories.reduce<Array<string>>(
              (newSkillsList, category) =>
                  newSkillsList.concat(
                      category.skills.reduce<Array<string>>(
                          (categorySkills, categorySkill) =>
                              categorySkill.name ? categorySkills.concat(categorySkill.name) : categorySkills,
                          [],
                      ),
                  ),
              [],
          )
        : []

const AddSkillsBox: FunctionComponent<React.PropsWithChildren<AddSkillsBoxProps>> = ({
    handleSkillSelect,
    skills,
    unvalidated = false,
    specialistId,
}) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])
    const allSkills = useSkills()
    const [possessedSkills, setPossessedSkills] = useState<Array<string>>([])
    const skillsList = useMemo(
        () => allSkills?.filter(skill => !getSkillsNames(skills).concat(possessedSkills).includes(skill)),
        [allSkills, possessedSkills, skills],
    )
    const log = useLogger('error')
    const [opened, setOpened] = useState(false)

    const toggleModal = useCallback(() => {
        setOpened(wasOpened => !wasOpened)
    }, [])

    useEffect(() => {
        if (specialistId) {
            getSpecialistSkillsEditView(specialistId)
                .then(specialistSkills => {
                    setPossessedSkills(getSkillsNames(specialistSkills))
                })
                .catch(log)
        }
    }, [log, specialistId])

    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px;
                    width: 100%;
                    background-color: ${theme.colors.beige_1};
                    margin: 0;
                    ${mqMax[2]} {
                        margin-bottom: 24px;
                    }
                `}
                data-testid='add-skill-box'
            >
                <h6>Adding and rating skills</h6>
                <p>If you would like to add or edit skills, please follow the instructions below:</p>
                <div {...getCollapseProps()}>
                    <ol
                        css={css`
                            padding-left: 20px;
                        `}
                    >
                        <li>Add skills by using “Select Skill” dropdown.</li>
                        {unvalidated && (
                            <li>
                                If you feel that extracted data is not a skill, please help us improve our parsing model. Click “Not a
                                skill”, we won’t add it to your profile.
                            </li>
                        )}
                        <li>
                            Choose the level of expertise for each skill: Expert, Good or Basic. Please make sure to rate all added skills.
                        </li>
                        <img
                            alt='Skills rating'
                            src={skillsRating}
                            css={css`
                                box-shadow: ${theme.shadow.small};
                                border-radius: 4px;
                                width: 100%;
                                margin: 8px 0;
                                max-width: 314px;
                            `}
                        />

                        <li>Remove skills from this profile by clicking “Remove” button.</li>
                    </ol>
                </div>
                <Button variant='linkForm' {...getToggleProps({ onClick: toggle })}>
                    {isExpanded ? 'Hide instructions' : 'Expand instructions'}{' '}
                </Button>
                <div
                    css={css`
                        display: block;
                        ${mqMax[2]} {
                            display: none;
                        }
                    `}
                    data-testid='add-skills-box-desktop'
                >
                    <Divider />
                    <AutocompleteSelect
                        virtualized
                        multiple
                        placeholder='Choose Skills'
                        options={skillsList}
                        currentValues={[]}
                        forceDownOpen
                        onSelectedValuesChange={handleSkillSelect}
                        dataTestId='add-skills-box-select'
                        applyButtonText='Add'
                    />
                </div>
            </div>
            <div
                css={css`
                    display: none;
                    ${mqMax[2]} {
                        display: block;
                    }
                `}
                data-testid='add-skills-box-mobile'
            >
                <div
                    css={css`
                        padding: 16px 24px;
                        position: fixed;
                        z-index: 1;
                        bottom: 60px;
                        left: 0;
                        right: 0;
                        background-color: ${theme.colors.beige_1};
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        font-weight: 500;
                        box-shadow: ${theme.shadow.bottomBar};
                        border-top: 1px solid ${theme.colors.gray_2};
                    `}
                    onClick={toggleModal}
                >
                    <div>Add more skills</div>
                    <Icon name='arrow-right-filled' />
                </div>
                <Modal onClose={toggleModal} opened={opened}>
                    <h6>Adding and rating your skills</h6>
                    <p>Add skills manually by selecting from the “Select Skill” dropdown.</p>
                    <AutocompleteSelect
                        virtualized
                        placeholder='Choose Skills'
                        options={skillsList}
                        currentValues={[]}
                        onSelectedValuesChange={handleSkillSelect}
                        dataTestId='add-skills-box-select'
                    />
                </Modal>
            </div>
        </Fragment>
    )
}

export { AddSkillsBox }
