/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent } from 'react'
import { KeyInfo, KeyInfoNameMapping } from '../pages/my-specialists/contracts'
import { Icon } from './Icon'
import { FlexBox } from './layout/FlexBoxHelpers'

type KeyInfoIndicatorProps = {
    text: string
    missingKeyInfo?: Array<KeyInfo>
}
const KeyInfoIndicator: FunctionComponent<React.PropsWithChildren<KeyInfoIndicatorProps>> = ({ text, missingKeyInfo }) => (
    <FlexBox alignItems='center'>
        <span>{text}</span>
        {missingKeyInfo && missingKeyInfo.length > 0 && (
            <Tooltip
                placement='rightBottom'
                title={
                    <div>
                        This profile is missing some data:
                        <ul>
                            {missingKeyInfo.map(keyInfo => (
                                <li key={keyInfo}>{KeyInfoNameMapping[keyInfo]}</li>
                            ))}
                        </ul>
                    </div>
                }
            >
                <Icon
                    name='exclamation-mark'
                    size={16}
                    style={css`
                        margin-left: 8px;
                    `}
                />
            </Tooltip>
        )}
    </FlexBox>
)

export { KeyInfoIndicator }
