import { Collapse } from 'antd'

import { StyledSectionHeader, StyledCollapse } from './styles'
import { useHandleQuestionClick } from './hooks'
import { MANAGE_MY_COMPANY } from './consts'

const ManageMyCompany = ({ setQuestion, question }: { setQuestion: (q: string) => void; question: string | undefined }) => {
    const onQuestionClick = useHandleQuestionClick(setQuestion)

    return (
        <StyledCollapse defaultActiveKey={question} onChange={onQuestionClick}>
            <Collapse.Panel
                key={MANAGE_MY_COMPANY.MANAGE_MY_COMPANY_INVITE}
                header={<StyledSectionHeader>How do I invite more people to join my company?</StyledSectionHeader>}
            >
                <p>
                    You can invite more users to manage the data in your company. You can use the Role Management feature that is located in
                    the Company Account section of the app (visible if you click the person icon in the top right-hand corner of the
                    screen). You just need to provide the user’s email address and they will need to register on our platform using the
                    invite link. The user will then receive an invite email. Once they have registered, they will be able to manage your
                    company data, including specialists, applying for opportunities, etc.
                </p>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export { ManageMyCompany }
