/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import rectangles from './../assets/images/rectangle.svg'
import { Button } from './Button'
import { Icon, IconName } from './Icon'
import { Modal } from './Modal'

type WelcomeScreenProps = {
    isVisible: boolean
    handleRejection: () => void
    handleConfirmation: () => void
    description: string
    title: string
    subtitle?: string
    icon?: IconName
}

const WelcomeScreen: FunctionComponent<React.PropsWithChildren<WelcomeScreenProps>> = ({
    isVisible,
    handleRejection,
    handleConfirmation,
    description,
    title,
    subtitle,
    icon,
}) => {
    const theme = useTheme()

    return (
        <Modal
            onClose={handleRejection}
            opened={isVisible}
            closeButton={false}
            containerStyles={css`
                z-index: 99;
            `}
            style={css`
                padding: 0;
            `}
        >
            <header
                css={css`
                    box-sizing: border-box;
                    height: 175px;
                    padding: 32px 40px;
                    background-color: ${theme.colors.violet_1};
                    position: relative;
                `}
            >
                <img
                    css={css`
                        position: absolute;
                        top: 48px;
                        right: 0;
                    `}
                    src={rectangles}
                    alt=''
                />
                <div
                    css={css`
                        font-size: 26px;
                        font-weight: 500;
                        max-width: 265px;
                        line-height: 36px;
                        margin-bottom: 25px;
                        color: ${theme.colors.gray_6};
                        position: relative;
                    `}
                    data-testid='cancel-confirmation-modal-check'
                >
                    {title}
                </div>
                {subtitle && (
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            position: relative;
                        `}
                    >
                        {icon && <Icon name={icon} />}
                        <div
                            css={css`
                                margin-left: 10px;
                            `}
                        >
                            {subtitle}
                        </div>
                    </div>
                )}
            </header>

            <section
                css={css`
                    padding: 32px 40px;
                `}
            >
                <div
                    css={css`
                        margin-top: 17px;
                        color: ${theme.colors.gray_4};
                    `}
                >
                    {description}
                </div>
                <div
                    css={css`
                        margin-top: 49px;
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        css={css`
                            margin-right: 29px;
                        `}
                        variant='link'
                        onClick={handleRejection}
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmation} dataTestId='welcome-screen-confirmation-button'>
                        Start
                    </Button>
                </div>
            </section>
        </Modal>
    )
}

export { WelcomeScreen }
