/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Skill, SpecialistSkills } from '../../contracts/skills'
import { mqMin } from '../../GlobalStyle'
import { Nullable } from '../../types'
import { useIsMobile } from '../../utils/hooks'
import { DataNotFound } from '../DataNotFound'
import { VioletBox } from '../VioletBox'
import { SkillsQuestionnaireItem } from './SkillsQuestionnaireItem'
import { SuggestedSkillsBox } from './SuggestedSkillsBox'

type SkillsQuestionnaireProps = {
    specialistSkills: Nullable<SpecialistSkills>
    handleSkillChange: (skill: Skill, category?: string) => void
    handleSkillAdd?: (skills: Array<string>) => void
    handleSkillRemove?: (skill: string) => void
    removable?: boolean
    unvalidated?: boolean
    suggestedSkills?: Array<string>
    showExtractedSkillsInfo?: boolean
}

const SkillsQuestionnaire: FunctionComponent<React.PropsWithChildren<SkillsQuestionnaireProps>> = ({
    specialistSkills,
    handleSkillChange,
    handleSkillRemove,
    handleSkillAdd,
    removable,
    unvalidated,
    suggestedSkills,
    showExtractedSkillsInfo,
}) => {
    const isMobile = useIsMobile(2)
    const isNewAddedSectionPresent = specialistSkills?.categories.some(
        category => category.category === 'Added Skills' || category.category === 'New Added',
    )
    return (
        <div>
            {!!suggestedSkills?.length && handleSkillAdd && handleSkillRemove && (
                <SuggestedSkillsBox
                    suggestedSkills={suggestedSkills}
                    handleSkillAdd={handleSkillAdd}
                    handleSkillRemove={handleSkillRemove}
                />
            )}

            {!isNewAddedSectionPresent && showExtractedSkillsInfo && (
                <VioletBox
                    header='Extracted Skills'
                    description='You can find new skills that were found in provided CV. Skills that are already added to your profile are not displayed here.'
                    styles={css`
                        margin-bottom: 24px;
                    `}
                />
            )}
            {specialistSkills?.categories.map(category => (
                <div key={category.category}>
                    <VioletBox
                        header={`${category.category} (${category.skills.length})`}
                        description={
                            category.category === 'Added Skills' || category.category === 'New Added'
                                ? // tslint:disable-next-line: quotemark
                                  "Please rate knowledge level for all added skills for this specialist. In most cases, non-rates skills on profile don't add value to shared profiles."
                                : undefined
                        }
                    />
                    {category.skills.map(skill => (
                        <SkillsQuestionnaireItem
                            key={skill.id}
                            value={skill}
                            category={category.category}
                            onChange={handleSkillChange}
                            removable={removable}
                            unvalidated={category.category === 'Added Skills' || category.category === 'New Added' ? false : unvalidated}
                        />
                    ))}
                    {(category.category === 'Added Skills' || category.category === 'New Added') && showExtractedSkillsInfo && (
                        <VioletBox
                            header='Extracted Skills'
                            description='You can find new skills that were found in provided CV. Skills that are already added to your profile are not displayed here.'
                            styles={css`
                                margin-bottom: 6px;
                            `}
                        />
                    )}
                </div>
            ))}
            {specialistSkills?.categories.length === 0 && (
                <DataNotFound
                    title='Add first skill'
                    iconName='add-empty'
                    description={
                        isMobile
                            ? 'Add your skills by clicking “Add more skills” below'
                            : 'Add your skills by using the “Select Skill” dropdown on the right'
                    }
                    iconSize={85}
                    styles={css`
                        ${mqMin[2]} {
                            margin-top: 140px;
                        }
                    `}
                />
            )}
        </div>
    )
}

export { SkillsQuestionnaire }
