/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { Step } from './Step'
import { StepStatus, StepType } from './types'

type StepsIndicatorProps = {
    steps?: Array<StepType>
    styles?: SerializedStyles
    stepIndicatorStyles?: (status: StepStatus) => SerializedStyles
    stepStyles?: SerializedStyles
    dividerStyles?: SerializedStyles
}

const StepsIndicator: FunctionComponent<React.PropsWithChildren<StepsIndicatorProps>> = ({
    steps,
    styles,
    stepIndicatorStyles,
    stepStyles,
    dividerStyles,
}) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                flex-wrap: wrap;
                padding: 0 24px 20px 0;
                border-radius: 4px;
                background-color: ${theme.colors.violet_1};
                align-items: center;

                ${styles}
            `}
        >
            {steps?.map((step, index) => (
                <Fragment key={step.name}>
                    <Step step={step} stepNumber={index + 1} indicatorStyles={stepIndicatorStyles} styles={stepStyles} />
                    {index !== steps.length - 1 && (
                        <div
                            css={css`
                                width: 26px;
                                height: 1px;
                                background-color: ${theme.colors.violet_3};
                                margin: 20px 0 0 24px;

                                ${dividerStyles}
                            `}
                        />
                    )}
                </Fragment>
            ))}
        </div>
    )
}

export { StepsIndicator }
