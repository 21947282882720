/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { Button } from '../../../components/Button'
import { AntDropdown } from '../../../components/dropdown/AntDropdown'
import { getSvg, Icon } from '../../../components/Icon'
import { getAppPath } from '../../../contracts/applications'
import { history } from '../../../history'

type AddSpecialistsActionsProps = {
    onClickAddSpecialist: () => void
}

enum Action {
    FORM = 'FORM',
    CVS = 'CVS',
    SPREADSHEET = 'SPREADSHEET',
}

const options = [
    {
        title: 'Fill a Form',
        key: Action.FORM,
        dataTestId: 'create-specialist-form-trigger',
    },
    {
        title: 'Upload CVs',
        key: Action.CVS,
        dataTestId: 'create-specialist-cvs-trigger',
    },
    {
        title: 'Import from Spreadsheet',
        key: Action.SPREADSHEET,
    },
]

const AddSpecialistsActions: FunctionComponent<React.PropsWithChildren<AddSpecialistsActionsProps>> = ({ onClickAddSpecialist }) => {
    const theme = useTheme()

    const onClickImportSpecialist = useCallback(() => {
        history.push(`${getAppPath('MY_SPECIALISTS')}/import-specialists`)
    }, [])

    const handleItemClick = useCallback(
        (key: string) => {
            switch (key) {
                case Action.FORM: {
                    onClickAddSpecialist()
                    break
                }
                case Action.CVS: {
                    history.push(`${getAppPath('MY_SPECIALISTS')}/cv-parsing`)
                    break
                }
                case Action.SPREADSHEET: {
                    onClickImportSpecialist()
                    break
                }
                default:
                    break
            }
        },
        [onClickAddSpecialist, onClickImportSpecialist],
    )

    return (
        <AntDropdown options={options} handleClick={handleItemClick}>
            <Button variant='primary' icon={getSvg('user-add')} dataTestId='create-specialist-dropdown'>
                Create New Specialist{' '}
                <Icon
                    name='arrow-down'
                    style={css`
                        ${theme.colors.text === theme.colors.white && 'filter:invert();'}
                        margin-top:-2px;
                    `}
                    size={10}
                />
            </Button>
        </AntDropdown>
    )
}

export { AddSpecialistsActions }
