import * as d3 from 'd3'
import isEqual from 'lodash.isequal'
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '@emotion/react'
import { ChartProps, GraphData, Node } from './types'
import { arc, middleArcLine, textFits, width, x, y } from './utils'

const Chart: FunctionComponent<React.PropsWithChildren<ChartProps>> = ({ data, setBreadcrumbsData, nodeColor, id }) => {
    const chart = useRef<SVGSVGElement>(null)
    const [transitionEndTime, setTransitionEndTime] = useState<number>(0)
    const theme = useTheme()

    const mouseover = useCallback(
        (_: Event, node: Node) => {
            const percentageString = node.data.name
            const nodeArray = node.ancestors().reverse()
            nodeArray.shift()
            setBreadcrumbsData({ nodeArray, percentageString })
            d3.select(chart.current).selectAll('path').style('opacity', 0.5)
            d3.select(chart.current)
                .selectAll('path')
                .filter(n => !!nodeArray.find(i => isEqual(i, n)))
                .style('opacity', 1)
        },
        [setBreadcrumbsData],
    )

    const mouseleave = useCallback(() => {
        const mouseLeaveAnimations = () => {
            d3.select(chart.current).selectAll('path').on('mouseover', null)
            d3.select(chart.current)
                .selectAll('path')
                .transition()
                .duration(1000)
                .style('opacity', 1)
                .on('end', function (this: SVGPathElement) {
                    d3.select(this).on('mouseover', mouseover as any)
                } as any)
        }
        if (transitionEndTime) {
            setTimeout(() => {
                mouseLeaveAnimations()
            }, transitionEndTime - new Date().getTime())
        } else {
            mouseLeaveAnimations()
        }
    }, [mouseover, transitionEndTime])

    const focusOn = useCallback((node = { x0: 0, x1: 1, y0: 0, y1: 1 }) => {
        let shouldAnimate = true
        setTransitionEndTime(currentTransitionEndTime => {
            const now = new Date().getTime()
            if (now - currentTransitionEndTime < 0) {
                shouldAnimate = false
                return currentTransitionEndTime
            }
            return now + 750
        })
        if (shouldAnimate) {
            const transition = d3
                .select<SVGSVGElement | null, Node[]>(chart.current)
                .transition()
                .duration(750)
                .tween('scale', () => {
                    const xd = d3.interpolate(x.domain(), [node.x0, node.x1])
                    const yd = d3.interpolate(y.domain(), [node.y0, 1])
                    return (t: number) => {
                        x.domain(xd(t))
                        y.domain(yd(t))
                    }
                })
                .on('end', () => {
                    setTransitionEndTime(0)
                })
            transition.selectAll<SVGPathElement, Node>('path.main-arc').attrTween('d', n => () => arc(n) as string)
            transition.selectAll<SVGPathElement, Node>('path.hidden-arc').attrTween('d', n => () => middleArcLine(n))
            transition.selectAll<SVGTextElement, Node>('text').attrTween('display', n => () => textFits(n) ? '' : 'none')
        }
    }, [])

    useEffect(() => {
        const svg = d3
            .select(chart.current)
            .style('font', '10px sans-serif')
            .attr('viewBox', `${-width / 2} ${-width / 2} ${width} ${width}`)
            .on('click', () => focusOn())
            .on('mouseleave', mouseleave)

        const root = d3.hierarchy(data)
        root.sum(graphData => graphData.value || 0)
        const slice = svg.selectAll('g.slice').data(d3.partition<GraphData>()(root).descendants())
        const newSlice = slice
            .enter()
            .append('g')
            .attr('class', 'slice')
            .on('click', ((event: Event, node: Node) => {
                focusOn(node)
                event.stopPropagation()
            }) as any)
            .on('mouseover', mouseover as any)
            .on('touchstart', ((event: Event, node: Node) => {
                focusOn(node)
                event.stopPropagation()
            }) as any)
        newSlice
            .append('path')
            .attr('class', 'main-arc')
            .attr('d', arc)
            .style('stroke', theme.colors.white)
            .style('stroke-width', '0.5px')
            .style('fill', nodeColor)
        newSlice
            .append('path')
            .attr('class', 'hidden-arc')
            .style('visibility', 'hidden')
            .attr('id', (_, i) => `${id}-hiddenArc${i}`)
            .attr('d', middleArcLine)
        const text = newSlice.append('text').attr('display', node => (textFits(node) ? null : 'none'))
        text.append('textPath')
            .attr('startOffset', '50%')
            .attr('pointer-events', 'none')
            .attr('dominant-baseline', 'middle')
            .attr('text-anchor', 'middle')
            .attr('xlink:href', (_, i) => `#${id}-hiddenArc${i}`)
            .attr('font-size', '10px')
            .text(node => node.data.name)
    }, [data, focusOn, mouseleave, mouseover, nodeColor, id, theme])

    return <svg ref={chart}></svg>
}

export { Chart }
