import { Collapse } from 'antd'

import { StyledSectionHeader, StyledCollapse } from './styles'
import { TALENT_MARKETPLACE } from './consts'
import { useHandleQuestionClick } from './hooks'

const TalentMarketplace = ({ setQuestion, question }: { setQuestion: (q: string) => void; question: string | undefined }) => {
    const onQuestionClick = useHandleQuestionClick(setQuestion)

    return (
        <StyledCollapse defaultActiveKey={question} onChange={onQuestionClick}>
            <Collapse.Panel
                key={TALENT_MARKETPLACE.TALENT_MARKETPLACE_APP_OVERVIEW}
                header={<StyledSectionHeader>Talent Marketplace – app overview</StyledSectionHeader>}
            >
                <p>
                    The Talent Marketplace application allows you to browse all of the shared specialists profiles, as well as searching for
                    and booking specialists.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={TALENT_MARKETPLACE.TALENT_MARKETPLACE_SHARE_MY_SPECIALIST}
                header={<StyledSectionHeader>How do I share my specialist(s) with the Talent Marketplace?</StyledSectionHeader>}
            >
                <p>
                    You can share your specialists in the Share My Specialists tab in the My Specialists app. You will need to set some
                    fields, such as hourly rate, availability, and specialist location before you can share a specialist.
                </p>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export { TalentMarketplace }
