/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { TextColorPicker, TextColorPickerProps } from '../../pages/dashboard/company-account/components/TextColorPicker'
import { ControlledFieldProps } from './types'

type ControlledTextColorPickerProps = Omit<TextColorPickerProps, 'onChange' | 'checked'> & ControlledFieldProps<boolean> & {}

const ControlledTextColorPicker: FunctionComponent<React.PropsWithChildren<ControlledTextColorPickerProps>> = ({
    name,
    control,
    defaultValue,
    color,
}) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
        defaultValue,
    })
    return <TextColorPicker checked={value} onChange={onChange} color={color} />
}

export { ControlledTextColorPicker }
