/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

type ProgressBarProps = {
    currentProgress?: number
    styles?: SerializedStyles
}

const ProgressBar: FunctionComponent<React.PropsWithChildren<ProgressBarProps>> = ({ currentProgress = 0, styles }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                width: 100%;
                height: 8px;
                background-color: ${theme.colors.green_1};
                border-radius: 4px;
                margin-top: 10px;
                margin-bottom: 24px;
                overflow: hidden;
            `}
            data-testid='progress-wrapper'
        >
            <div
                css={css`
                    height: 8px;
                    border-radius: 4px;
                    background-color: ${theme.colors.green_4};
                    width: 100%;
                    transform: translate(${-100 + currentProgress}%);
                    ${styles}
                `}
                data-testid='progress'
            ></div>
        </div>
    )
}

export { ProgressBar }
