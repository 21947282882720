/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useSyndicate } from '../../hooks/useSyndicate'
import { RegisterForm } from '../../components/RegisterForm'

const Register = () => {
    const { isPwcSyndicate, syndicate } = useSyndicate()

    return (
        <RegisterForm
            buttonText='Join as Company Manager'
            isPwcSyndicate={isPwcSyndicate}
            syndicate={syndicate}
            header={
                <h5
                    css={css`
                        margin-bottom: 0;
                    `}
                >
                    Create a Company Manager Account
                </h5>
            }
        />
    )
}

export { Register }
