/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { usePromise } from 'react-use'
import { Button } from '../../components/Button'
import { ControlledTextarea } from '../../components/forms/ControlledTextarea'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { hasErrors } from '../../utils/errors'
import { yup } from '../../validation/yup'
import { updateSpecialistProfileSummary } from './api'

const schema = yup.object().shape({
    summary: yup.string().required().max(1000, 'Summary must be at most 1000 characters'),
})

type SummaryFormProps = {
    summary?: string
    onSuccess: (summary: string) => void
    onCancel: () => void
}

type FormValues = {
    summary: string
}

const SummaryForm: FunctionComponent<React.PropsWithChildren<SummaryFormProps>> = ({ summary = '', onSuccess, onCancel }) => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const { addError } = useNotifications()
    const isMounted = usePromise()
    const {
        control,
        handleSubmit,
        watch,
        formState: { errors, isDirty, isSubmitting },
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            summary,
        },
    })

    const summaryValue = watch('summary')

    const onSubmit = useCallback(
        (values: any) => {
            isMounted(updateSpecialistProfileSummary(decodedAccessToken.specialistId, values.summary))
                .then(({ profileSummary }) => {
                    onSuccess(profileSummary)
                })
                .catch(() => {
                    addError()
                })
        },
        [onSuccess, isMounted, decodedAccessToken.specialistId, addError],
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextarea name='summary' control={control} infoMessage={`${summaryValue.length}/1000`} />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button variant='link' onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting || !isDirty || hasErrors(errors)}
                    css={css`
                        margin-left: 30px;
                    `}
                    dataTestId='profile-summary-save'
                >
                    Save
                </Button>
            </div>
        </form>
    )
}

export { SummaryForm }
