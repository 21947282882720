/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Fragment, useCallback, useState } from 'react'
import { FunctionComponent, useMemo } from 'react'
import { useMeasure } from 'react-use'
import { AppIcon } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Modal } from '../../../components/Modal'

type TestImageProps = {
    height: number
    url: string
    width: number
    styles?: SerializedStyles
}

const TestImage: FunctionComponent<React.PropsWithChildren<TestImageProps>> = ({ height, url, width, styles }) => {
    const [ref, { width: imageWidth }] = useMeasure<HTMLImageElement>()
    const [isModalVisible, setIsModalVisible] = useState(false)

    const canZoomImage = useMemo(() => imageWidth < width, [imageWidth, width])

    const toggleModal = useCallback(() => {
        setIsModalVisible(currentIsModalVisible => !currentIsModalVisible)
    }, [])

    const theme = useTheme()

    return (
        <Fragment>
            <FlexBox
                alignItems='center'
                justifyContent='center'
                css={css`
                    position: relative;
                    ${styles}
                `}
            >
                <img
                    ref={ref}
                    src={url}
                    alt=''
                    height={height}
                    width={width}
                    css={css`
                        max-width: 100%;
                        height: auto;
                        background-color: ${theme.colors.gray_1};
                    `}
                />
                {canZoomImage && (
                    <AppIcon
                        name='zoom'
                        onClick={toggleModal}
                        style={css`
                            position: absolute;
                            :hover {
                                cursor: pointer;
                            }
                        `}
                    />
                )}
            </FlexBox>
            <Modal
                onClose={toggleModal}
                opened={isModalVisible}
                size='content'
                style={css`
                    padding: 0;
                    overflow: scroll;
                `}
                closeButtonStyles={css`
                    border-radius: 50%;
                    background-color: ${theme.colors.gray_6};
                `}
            >
                <img src={url} alt='' />
            </Modal>
        </Fragment>
    )
}

export { TestImage }
