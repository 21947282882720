/** @jsxImportSource @emotion/react */
import { SerializedStyles, css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useCallback } from 'react'
import TruncatedList from 'react-truncate-list'
import { VerifiedSkill } from '../../contracts/profile/skill'
import { SkillItem } from './SkillItem'

interface SkillsListProps {
    skills: Array<string> | Array<VerifiedSkill>
    searchWords?: Array<string>
    truncated?: boolean
    topSkills?: boolean
    styles?: SerializedStyles
}

const SkillsList: FunctionComponent<React.PropsWithChildren<SkillsListProps>> = ({
    skills,
    searchWords = [],
    truncated = false,
    topSkills = false,
    styles,
}) => {
    const theme = useTheme()

    const renderSkill = useCallback(
        (skill: string | VerifiedSkill, index: number, list: Array<string> | Array<VerifiedSkill>) => (
            <SkillItem
                key={`${typeof skill === 'string' ? skill : skill.id || skill.name}-${index}`}
                skill={skill}
                index={index}
                list={list}
                searchWords={searchWords}
            />
        ),
        [searchWords],
    )

    return skills.length > 0 ? (
        <div
            css={css`
                ${styles}
            `}
        >
            {truncated ? (
                <div
                    css={css`
                        li {
                            list-style: none;
                        }
                    `}
                >
                    <TruncatedList
                        css={css`
                            display: flex;
                            flex-wrap: wrap;
                            height: 50px;
                            justify-content: flex-start;
                            align-items: ${topSkills ? 'center' : 'flex-start'};
                            padding: 0;
                            margin-bottom: 0;
                            li {
                                list-style: none;
                            }
                        `}
                        renderTruncator={({ hiddenItemsCount }) => (
                            <Tooltip
                                title={[...skills.slice(-hiddenItemsCount)].map(skill => (
                                    <div key={typeof skill === 'string' ? skill : skill.id || skill.name}>
                                        {typeof skill === 'string' ? skill : skill.name}
                                    </div>
                                ))}
                            >
                                <div
                                    css={css`
                                        color: ${theme.colors.violet_6};
                                        line-height: 22px;
                                    `}
                                >
                                    {hiddenItemsCount} more
                                </div>
                            </Tooltip>
                        )}
                    >
                        {skills.map(renderSkill)}
                    </TruncatedList>
                </div>
            ) : (
                skills.map(renderSkill)
            )}
        </div>
    ) : (
        <div
            css={css`
                line-height: 50px;
                text-align: center;
                color: ${theme.colors.gray_3};
            `}
        >
            {topSkills ? 'No Skills Added Yet' : 'Not available'}
        </div>
    )
}

export { SkillsList }
