/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { signOut } from '../../api/firebase'
import { useUserAuthentication } from '../../hooks/useUserAuthentication'
import { ReduxContext } from '../../redux/Store'
import { useQuery } from '../../utils/useQuery'

const LogoutUser = () => {
    const { logout } = useUserAuthentication()
    const query = useQuery()

    const {
        actions: { setTermsStatus },
        selectors: { user, userId, featureFlags },
    } = useContext(ReduxContext)

    const navigate = useNavigate()

    useEffect(() => {
        setTermsStatus(null)
        signOut().then(logout)
    }, [setTermsStatus, logout])

    useEffect(() => {
        if (user !== null && !userId) {
            const afterLogin = query.get('afterLogin')

            navigate(`/logout?${featureFlags ? 'role=manager' : ''}${afterLogin ? `&afterLogin=${encodeURIComponent(afterLogin)}` : ''}`)
        }
    }, [userId, user, navigate, query, featureFlags])

    return <Fragment></Fragment>
}

export { LogoutUser }
