/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { VerifiedSkill } from '../../../contracts/profile/skill'

type DotSeparatedValuesProps = {
    values?: Array<VerifiedSkill>
    isQuickSummary?: boolean
}

const DotSeparatedValues: FunctionComponent<React.PropsWithChildren<DotSeparatedValuesProps>> = ({ values, isQuickSummary = false }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                max-width: ${isQuickSummary ? '100%' : 'calc(100% - 150px)'};
                width: ${isQuickSummary ? 'auto' : 'calc(100% - 150px)'};
                flex-wrap: wrap;
                content: ${values?.map(skill => skill.name)};
            `}
        >
            {values?.map((skill, index) => {
                return (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            content: ${skill.name};
                            white-space: nowrap;
                            ${skill.verified ? 'font-weight:500' : ''}
                        `}
                        key={index}
                    >
                        <span>{skill.name}</span>
                        {skill.verified && (
                            <FlexBox
                                alignItems='center'
                                css={css`
                                    background-color: ${theme.colors.blue_3};
                                    border-radius: 50%;
                                    padding: 3px;
                                    margin-left: 4px;
                                `}
                            >
                                <svg
                                    width='18'
                                    height='18'
                                    viewBox='0 0 18 18'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    css={css`
                                        width: 8px;
                                        height: 8px;
                                    `}
                                >
                                    <path d='M2 10L6 14L16 4' stroke={theme.colors.gray_6} />
                                </svg>
                            </FlexBox>
                        )}
                        {index < values.length - 1 && (
                            <div
                                css={css`
                                    width: 2px;
                                    height: 2px;
                                    background-color: ${theme.colors.gray_3};
                                    margin: 0 10px;
                                    border-radius: 1px;
                                    box-sizing: border-box;
                                `}
                            />
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export { DotSeparatedValues }
