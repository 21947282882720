/** @jsxImportSource @emotion/react */

import { Rate } from 'antd'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../theme/colors'
import { InputField, InputFieldProps } from './input-field/InputField'

export type RatingStarsProps = InputFieldProps & {
    onChange?: (val: any) => void
    value?: number
}

const RatingStars: FunctionComponent<React.PropsWithChildren<RatingStarsProps>> = ({
    onChange,
    value,
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    dataTestId,
}) => {
    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId}
        >
            <Rate value={value} onChange={onChange} style={{ color: COLOR_PALETTE.gray_6 }} />
        </InputField>
    )
}

export { RatingStars }
