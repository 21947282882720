/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { mqMax } from 'src/GlobalStyle'
import { useCurrencies } from 'src/redux/dictionaryDataHooks'
import { ShareMySpecialistsFormData } from '../../contracts'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { CellWrapper, InputWrapper } from '../../utils'
interface CurrencyProps {
    record: ShareMySpecialistsSpecialist
}

interface CurrencyEditProps extends CurrencyProps {
    index: number
    control: Control<ShareMySpecialistsFormData>
}

const Currency: FunctionComponent<React.PropsWithChildren<CurrencyProps>> = ({ record }) => (
    <CellWrapper
        css={css`
            width: 100px;
            ${mqMax[2]} {
                width: 100%;
            }
        `}
    >
        {record.currency || '-'}
    </CellWrapper>
)

const CurrencyEdit: FunctionComponent<React.PropsWithChildren<CurrencyEditProps>> = ({ record, index, control }) => {
    const currencies = useCurrencies()
    return (
        <InputWrapper
            css={css`
                width: 105px;
                ${mqMax[2]} {
                    width: 100%;
                }
            `}
        >
            <ControlledAutocompleteSelect
                placeholder='Currency'
                options={currencies}
                defaultValue={record.currency ? [record.currency] : []}
                control={control}
                name={`specialists.${index}.currency`}
                canClear
                dropdownWidth='100%'
                valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
            />
        </InputWrapper>
    )
}

export { Currency, CurrencyEdit }
