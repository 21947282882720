/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import specialistDashboardPortrait from '../../../assets/images/specialist_dashboard_portrait.png'
import { mqMax } from '../../../GlobalStyle'

const InfoBox = () => {
    return (
        <div
            css={css`
                ${mqMax[1]} {
                    text-align: center;
                    margin: 0 auto;
                    padding: 24px;
                }
            `}
        >
            <img
                src={specialistDashboardPortrait}
                alt=''
                css={css`
                    width: 100%;
                    max-width: 337px;
                    margin: 0 auto;
                    display: block;
                `}
            />
            <h5
                css={css`
                    margin-top: 24px;
                `}
            >
                What’s the secret behind your talent genome?
            </h5>
            <p>
                When you fill in your skills and your experience, your employer will be able to understand your technical competencies,
                social skills, cognitive abilities and personality traits. Accordingly, the Talent Genome™ of each individual or even the
                entire organization is created providing valuable insights.
            </p>
        </div>
    )
}

export { InfoBox }
