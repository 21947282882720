/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import isEqual from 'lodash.isequal'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { getSvg } from '../../../../components/Icon'
import { DashboardLayout, MenuType } from '../../../../components/layout/dashboard/DashboardLayout'
import { Pagination } from '../../../../components/Pagination'
import { SearchInput } from '../../../../components/SearchInput'
import { Tabs, TabsColor } from '../../../../components/Tabs'
import { CompanyInvitation } from '../../../../contracts/role-management/CompanyInvitation'
import { mqMax } from '../../../../GlobalStyle'
import { ReduxContext } from '../../../../redux/Store'
import { useLogger } from '../../../../utils/useLogger'
import { companyInvitationsSearch, companyUsersSearch } from '../../api'
import { User } from '../../contracts'
import { InvitationsGrid } from './components/InvitationsGrid'
import { RolesGrid } from './components/RolesGrid'
import { InviteManagersModal } from './RoleManagementModal'
import { SettingsWrapper } from 'src/components/layout/ResponsiveWrapper'

const defaultPageSize = 10
const availableTabs = [{ title: 'Roles' }, { title: 'Invitations' }]

const RoleManagementForm = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const [invitationModalOpened, setInvitationModalOpened] = useState(false)
    const [selectedTab, setSelectedTab] = useState<string>('Roles')
    const [searchText, setSearchText] = useState<string>('')
    const [companyInvitations, setCompanyInvitations] = useState<Array<CompanyInvitation>>([])
    const [paging, setPaging] = useState({ page: 1, size: defaultPageSize })
    const [sorting, setSorting] = useState<Array<any>>([])
    const [total, setTotal] = useState<number>(0)

    const handleSearch = useCallback((text: string) => {
        setSearchText(text)
        const newPaging = { page: 1, size: defaultPageSize }
        setPaging(prev => (!isEqual(prev, newPaging) ? newPaging : prev))
    }, [])

    const onChangePage = useCallback((page: any, size: any) => {
        setPaging({ page, size })
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        const newSize = Number(size.toString())
        setPaging(oldPaging => (oldPaging.size !== newSize ? { page: oldPaging.page, size: newSize } : oldPaging))
    }, [])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            const newSorting = newSorter.filter(({ order }) => order)
            setSorting(newSorting)
        } else if (newSorter) {
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    const log = useLogger()

    const [users, setUsers] = useState<Array<User>>([])

    useEffect(() => {
        if (
            total > 0 &&
            ((companyInvitations.length === 0 && selectedTab === 'Invitations') || (users.length === 0 && selectedTab === 'Roles')) &&
            paging.page > 1
        ) {
            setPaging(oldPaging => ({ page: 1, size: oldPaging.size }))
        }
    }, [companyInvitations.length, paging.page, selectedTab, total, users.length])

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)
        if (selectedTab === 'Roles') {
            companyUsersSearch({ paging, searchText, sorting })
                .then(data => {
                    setUsers(data.users)
                    setTotal(data.total)
                })
                .catch(log)
                .finally(() => {
                    layoutToggleLoader(false)
                })
        } else {
            companyInvitationsSearch({ paging, searchText, sorting })
                .then(data => {
                    setCompanyInvitations(data.invitations)
                    setTotal(data.total)
                })
                .catch(log)
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [layoutToggleLoader, log, paging, searchText, selectedTab, sorting])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleTabChange = useCallback((newTab: any) => {
        setSelectedTab(availableTabs[newTab].title)
        setPaging({ page: 1, size: defaultPageSize })
        setSorting([])
    }, [])

    const toggleInvitationModal = useCallback(() => {
        setInvitationModalOpened(!invitationModalOpened)
    }, [invitationModalOpened])

    return (
        <Fragment>
            <DashboardLayout menuType={MenuType.companyAccount}>
                <SettingsWrapper>
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                        `}
                    >
                        <section>
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 1em;
                                `}
                            >
                                <h4
                                    css={css`
                                        margin-bottom: 0;
                                    `}
                                >
                                    Role Management
                                </h4>
                                <Button
                                    variant='primary'
                                    icon={getSvg('user-add')}
                                    dataTestId='invite-functional-managers'
                                    onClick={toggleInvitationModal}
                                >
                                    Invite Functional Managers
                                </Button>
                            </div>
                            <Divider />
                            <p>
                                Here you can view all of the users and their roles which your Company currently has added to your Talent
                                Alpha Company Account. You can also add new Functional Managers to give other members of your team access to
                                manage your Specialist Profiles and Bookings.
                            </p>
                            <SearchInput
                                onSubmit={handleSearch}
                                filtersOpened={false}
                                onToggleFilters={() => null}
                                showFilterOption={false}
                                styles={css`
                                    margin: 24px 0;
                                `}
                            />

                            <Tabs tabs={availableTabs} onTabChange={handleTabChange} mode={TabsColor.BLACK} />
                            {selectedTab === 'Roles' && <RolesGrid users={users} onChangeTable={onChangeTable} fetchData={fetchData} />}
                            {selectedTab === 'Invitations' && (
                                <InvitationsGrid
                                    companyInvitations={companyInvitations}
                                    onChangeTable={onChangeTable}
                                    fetchData={fetchData}
                                />
                            )}
                            <div
                                css={css`
                                    margin-top: 40px;
                                `}
                            />
                            {total > 0 && (
                                <Pagination
                                    pageSize={paging.size}
                                    onChange={onChangePage}
                                    current={paging.page}
                                    total={total}
                                    onChangePageSize={onChangePageSize}
                                    css={css`
                                        ${mqMax[0]} {
                                            display: flex;
                                            justify-content: center;
                                        }
                                    `}
                                />
                            )}
                        </section>
                    </div>
                </SettingsWrapper>
                <InviteManagersModal opened={invitationModalOpened} handleCloseModal={toggleInvitationModal} fetchData={fetchData} />
            </DashboardLayout>
        </Fragment>
    )
}

export { RoleManagementForm }
