/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent, ReactNode } from 'react'
import { mqMin } from '../GlobalStyle'
import { COLOR_PALETTE } from '../theme/colors'
import { Button } from './Button'
import { Icon, IconName, RoundIconWrapper } from './Icon'
import { ResponsiveWrapper } from './layout/ResponsiveWrapper'

type DefaultLayoutProps = {
    icon?: IconName
    title?: string
    description?: ReactNode
    buttonText?: string
    onButtonClick?: () => void
    iconSize?: number
    iconStyles?: SerializedStyles
    iconWrapperStyles?: SerializedStyles
    buttonDataTestId?: string
}

const DefaultLayout: FunctionComponent<React.PropsWithChildren<DefaultLayoutProps>> = ({
    icon,
    title,
    description,
    buttonText,
    onButtonClick,
    iconSize = 28,
    iconStyles,
    iconWrapperStyles,
    children = null,
    buttonDataTestId = '',
}) => {
    return (
        <ResponsiveWrapper withLogo>
            <div
                css={css`
                    margin: auto 0;
                    margin-top: 24px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    width: 100%;

                    ${mqMin[1]} {
                        padding-bottom: 88px;
                    }
                `}
            >
                {icon && (
                    <RoundIconWrapper
                        size={60}
                        css={css`
                            overflow: hidden;
                            position: relative;
                            border-width: 4px;
                            border-style: solid;
                            margin: 0 0 32px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 0;
                            background-color: ${COLOR_PALETTE.beige_1};
                            ${iconWrapperStyles}
                        `}
                    >
                        <Icon
                            size={iconSize}
                            name={icon}
                            css={css`
                                ${iconStyles}
                            `}
                        />
                    </RoundIconWrapper>
                )}

                {title && (
                    <h3
                        css={css`
                            margin-bottom: 32px;
                        `}
                    >
                        {title}
                    </h3>
                )}

                {description && (
                    <div
                        css={css`
                            line-height: 26px;
                            font-size: 14px;
                            margin-bottom: 32px;
                        `}
                    >
                        {description}
                    </div>
                )}

                {onButtonClick && (
                    <Button
                        onClick={onButtonClick}
                        variant='primary'
                        css={css`
                            width: 240px;
                            margin: 0;
                        `}
                        dataTestId={buttonDataTestId}
                    >
                        {buttonText}
                    </Button>
                )}

                {children}
            </div>
        </ResponsiveWrapper>
    )
}

export { DefaultLayout }
