/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePromise } from 'react-use'
import { DefaultLayout } from '../../components/DefaultLayout'
import { ContentLoader } from '../../components/layout/Loader'
import { mqMax } from '../../GlobalStyle'
import { useUserAuthentication } from '../../hooks/useUserAuthentication'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { confirmCompany } from './api'

enum Status {
    WaitingForConfirmation = 'WaitingForConfirmation',
    Success = 'Success',
    Failed = 'Failed',
}

const ConfirmCompanyEmail = () => {
    const { isUserAuthorized } = useUserAuthentication()
    const [status, setStatus] = useState<Nullable<Status>>(Status.WaitingForConfirmation)
    const [confirmedCompanyContextId, setConfirmedCompanyContextId] = useState('')
    const mounted = usePromise()
    const query = useQuery()
    const navigate = useNavigate()
    const prospectId = query.get('prospectId')
    const [isBrokenLink, setIsBrokenLink] = useState(false)
    const log = useLogger('error')

    useEffect(() => {
        if (!prospectId) {
            setIsBrokenLink(true)
        }
    }, [prospectId])

    const handleConfirmCompany = useCallback(() => {
        if (prospectId) {
            mounted(confirmCompany(prospectId))
                .then(({ contextId }) => {
                    setStatus(Status.Success)
                    setConfirmedCompanyContextId(contextId)
                })
                .catch(e => {
                    log(e)
                    setStatus(Status.Failed)
                })
        }
    }, [prospectId, mounted, log])

    useEffect(() => {
        if (status === Status.WaitingForConfirmation) {
            handleConfirmCompany()
        }
    }, [handleConfirmCompany, status])

    const handleGoToPlatform = useCallback(() => {
        navigate(
            isUserAuthorized
                ? `/refresh-user-context${confirmedCompanyContextId ? `?wantedContextId=${confirmedCompanyContextId}` : ''}`
                : '/?role=manager',
        )
    }, [navigate, isUserAuthorized, confirmedCompanyContextId])

    const statusView = useMemo(() => {
        let output = null

        if (isBrokenLink) {
            output = (
                <DefaultLayout
                    icon='broken-link'
                    title='Sorry, the link is broken.'
                    description='We will do our best to fix it as soon as we can. In the meantime, head back to your Human Cloud Dashboard.'
                    buttonText='Go Back to Dashboard'
                    onButtonClick={handleGoToPlatform}
                />
            )
        } else {
            switch (status) {
                case Status.WaitingForConfirmation:
                    output = (
                        <DefaultLayout>
                            <div
                                css={css`
                                    width: 572px;

                                    ${mqMax[1]} {
                                        width: calc(100vw - 28px);
                                    }
                                `}
                            >
                                <ContentLoader height='150px' />
                                <p
                                    css={css`
                                        text-align: center;
                                    `}
                                >
                                    Creating your Company Account...
                                </p>
                            </div>
                        </DefaultLayout>
                    )
                    break

                case Status.Success:
                    output = (
                        <DefaultLayout
                            icon='check'
                            title='Your Company Account was created successfully!'
                            buttonText='Enter Human Cloud Dashboard'
                            onButtonClick={handleGoToPlatform}
                        />
                    )
                    break

                case Status.Failed:
                    output = (
                        <DefaultLayout
                            icon='smiley-drop'
                            title='Sorry, we were unable to create your Company Account.'
                            description='Please try confirming your Company Account once again.'
                            buttonText='Confirm Company Account Again'
                            onButtonClick={handleConfirmCompany}
                        />
                    )
                    break
            }
        }
        return output
    }, [isBrokenLink, handleGoToPlatform, handleConfirmCompany, status])

    return <Fragment>{statusView}</Fragment>
}

export { ConfirmCompanyEmail }
