/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import Highlighter from 'react-highlight-words'
import { Theme } from '../theme/theme'

interface TextHighlightProps {
    searchWords: Array<string>
    text: string
}

const highlightStyle = (theme: Theme) => ({
    backgroundColor: theme.colors.main_light,
    borderRadius: 2,
})

const TextHighlight = ({ searchWords, text }: TextHighlightProps) => {
    const theme = useTheme()
    return <Highlighter highlightStyle={highlightStyle(theme)} searchWords={searchWords} textToHighlight={text || ''} autoEscape={true} />
}

export { TextHighlight }
