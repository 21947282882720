/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { getSvg } from '../../../../components/Icon'
import { DashboardLayout, MenuType } from '../../../../components/layout/dashboard/DashboardLayout'

import { Tabs, TabsColor } from '../../../../components/Tabs'

import { ReduxContext } from '../../../../redux/Store'
import { useLogger } from '../../../../utils/useLogger'
import { VMCUsersSearch, vendorsInvitationsSearch } from '../../api'

import { InvitationsGrid } from './components/InvitationsGrid'
import { RolesGrid } from './components/RolesGrid'
import { SettingsWrapper } from 'src/components/layout/ResponsiveWrapper'
import { VMCInvitation, VMCUser } from 'src/contracts/vmc'
import { MyVendorsModal } from './MyVendorsModal'

const availableTabs = [{ title: 'Vendors' }, { title: 'Invitations' }]

const MyVendorsForm = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const [invitationModalOpened, setInvitationModalOpened] = useState(false)
    const [selectedTab, setSelectedTab] = useState<string>('Vendors')

    const [vmcInvitations, setVmcInvitations] = useState<Array<VMCInvitation>>([])

    const log = useLogger()

    const [users, setUsers] = useState<Array<VMCUser>>([])

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)
        if (selectedTab === 'Vendors') {
            VMCUsersSearch()
                .then(response => {
                    setUsers(response.vmcs)
                })
                .catch(log)
                .finally(() => {
                    layoutToggleLoader(false)
                })
        } else {
            vendorsInvitationsSearch()
                .then(data => {
                    setVmcInvitations(data.invitations)
                })
                .catch(log)
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [layoutToggleLoader, log, selectedTab])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleTabChange = useCallback((newTab: any) => {
        setSelectedTab(availableTabs[newTab].title)
    }, [])

    const toggleInvitationModal = useCallback(() => {
        setInvitationModalOpened(!invitationModalOpened)
    }, [invitationModalOpened])

    return (
        <Fragment>
            <DashboardLayout menuType={MenuType.companyAccount}>
                <SettingsWrapper>
                    <div
                        css={css`
                            display: flex;
                            width: 100%;
                        `}
                    >
                        <section>
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 1em;
                                `}
                            >
                                <h4
                                    css={css`
                                        margin-bottom: 0;
                                    `}
                                >
                                    My Vendors
                                </h4>
                                <Button
                                    variant='primary'
                                    icon={getSvg('user-add')}
                                    dataTestId='invite-functional-managers'
                                    onClick={toggleInvitationModal}
                                >
                                    Invite Vendors
                                </Button>
                            </div>
                            <Divider />
                            {selectedTab === 'Vendors' && (
                                <p>
                                    Here you can see the list of companies that have joined your Vendor Management Cloud. You can check
                                    their contact details and the date of their last login. You can send invitations to new vendors and add
                                    new Functional Managers.
                                </p>
                            )}
                            {selectedTab === 'Invitations' && (
                                <p>
                                    Here you can see all the invitations you have sent to the vendors to let them join your Vendor
                                    Management Cloud. When they set up their account, they will disappear from this list and will appear in
                                    the VMC tab.
                                </p>
                            )}

                            <Tabs tabs={availableTabs} onTabChange={handleTabChange} mode={TabsColor.BLACK} />
                            {selectedTab === 'Vendors' && <RolesGrid users={users} fetchData={fetchData} />}
                            {selectedTab === 'Invitations' && <InvitationsGrid vmcInvitations={vmcInvitations} fetchData={fetchData} />}
                            <div
                                css={css`
                                    margin-top: 40px;
                                `}
                            />
                        </section>
                    </div>
                </SettingsWrapper>
                <MyVendorsModal opened={invitationModalOpened} handleCloseModal={toggleInvitationModal} fetchData={fetchData} />
            </DashboardLayout>
        </Fragment>
    )
}

export { MyVendorsForm }
