/** @jsxImportSource @emotion/react */

import { useCallback } from 'react'
import { DefaultLayout } from '../../../../components/DefaultLayout'
import { history } from '../../../../history'

const InvitationExpiredPage = () => {
    const handleRedirection = useCallback(() => {
        history.push('/dashboard')
    }, [])

    return (
        <DefaultLayout
            icon='broken-link'
            title=' Oops, the link has expired.'
            description='We’re sorry, but the link you’ve followed is no longer active.'
            onButtonClick={handleRedirection}
            buttonText='Go to Dashboard'
        />
    )
}

export { InvitationExpiredPage }
