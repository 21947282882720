/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, Fragment, FunctionComponent, RefObject, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useLifecycles } from 'react-use'
import { getSpecialistProjects } from '../../../api/api'
import { Button } from '../../../components/Button'
import { ContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { ProjectBox } from '../../../components/specialist/ProjectBox'
import { UnsavedChangesModal } from '../../../components/UnsavedChangesModal'
import { SpecialistProject } from '../../../contracts/specialist/specialistProject'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { deleteSpecialistProject, saveSpecialistProject, updateSpecialistProject } from '../api'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

type ProjectsProps = {
    header?: string
    specialistId: string
    onSave?: () => void
    focus?: boolean
    setForceFocus?: Dispatch<SetStateAction<string>>
    containerRef?: RefObject<HTMLElement>
}

const Projects: FunctionComponent<React.PropsWithChildren<ProjectsProps>> = ({
    specialistId,
    onSave,
    header = 'Projects',
    focus,
    setForceFocus,
    containerRef,
}) => {
    const [currentlyEditedIndex, setCurrentlyEditedIndex] = useState<number>(-1)
    const [projects, setProjects] = useState<Nullable<Array<SpecialistProject>>>(null)
    const [unsavedChangesOpened, setUnsavedChangesOpened] = useState<boolean>(false)
    const [isMounted, setIsMounted] = useState(false)
    const [saving, setSaving] = useState(false)

    const log = useLogger('error')

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const { addSuccess, addError } = useNotifications()

    const saveCallback = () => {
        fetchProjects()
        setCurrentlyEditedIndex(-1)
        if (onSave) {
            onSave()
        }
    }

    const onSaveClicked = (index: number, formData: any): void => {
        if (projects) {
            setSaving(true)
            const project = projects[index]

            formData.from = Number.parseInt(formData.from, 10)
            formData.to = Number.parseInt(formData.to, 10)

            if (formData.currentlyWorkingThere) {
                delete formData.to
            }

            if (project.id) {
                updateSpecialistProject(specialistId, project.id, formData)
                    .then(() => saveCallback())
                    .then(() => addSuccess('Project details updated.'))
                    .catch(() => addError())
                    .finally(() => setSaving(false))
            } else {
                saveSpecialistProject(specialistId, formData)
                    .then(() => saveCallback())
                    .then(() => addSuccess('Project added!'))
                    .catch(() => addError())
                    .finally(() => setSaving(false))
            }
        }
    }

    const onEditClicked = (index: number): void => {
        setCurrentlyEditedIndex(index)
    }

    const onDeleteClicked = (index: number): void => {
        if (projects) {
            const projectId = projects[index]?.id

            if (projectId) {
                deleteSpecialistProject(specialistId, projectId)
                    .then(() => {
                        removeProjectFromList(index)
                        if (onSave) {
                            onSave()
                        }
                    })
                    .then(() => addSuccess('Project removed.'))
                    .catch(() => addError())
            } else {
                removeProjectFromList(index)
            }
        }
    }

    const onCancelClicked = (index: number): void => {
        if (projects) {
            const project = projects[index]

            if (!project.id) {
                removeProjectFromList(index)
            }

            setCurrentlyEditedIndex(-1)
        }
    }

    const onLeaveClicked = (): void => {
        onCancelClicked(currentlyEditedIndex)
    }

    const onStayClicked = (): void => {
        setUnsavedChangesOpened(false)
    }

    const removeProjectFromList = (index: number): void => {
        if (projects) {
            projects.splice(index, 1)

            setProjects(projects)
        }
    }

    const addProject = useCallback((): void => {
        const newProject: SpecialistProject = {
            id: '',
            role: '',
            from: null,
            to: null,
            currentlyWorkingThere: false,
            industry: '',
            project: '',
            stack: [],
            description: '',
            company: '',
            country: '',
            city: '',
        }
        if (projects) {
            setProjects([newProject, ...projects])
            setCurrentlyEditedIndex(0)
        }
    }, [projects])

    const fetchProjects: () => void = useCallback(() => {
        getSpecialistProjects(specialistId)
            .then((data: Array<SpecialistProject>) => {
                if (isMounted) {
                    setProjects(data)
                }
            })
            .catch(log)
    }, [isMounted, log, specialistId])

    useEffect(() => {
        fetchProjects()
    }, [fetchProjects])

    useEffect(() => {
        if (projects !== null && focus && containerRef?.current && setForceFocus) {
            if (!projects.find(project => project.id === '')) {
                addProject()
            }
            containerRef.current.scrollIntoView({ behavior: 'smooth' })
            setForceFocus('')
        }
    }, [addProject, containerRef, focus, projects, setForceFocus])

    return (
        <Fragment>
            <SectionWrapper>
                <SectionHeaderWithActions header={header} divider>
                    {projects && projects.length > 0 && (
                        <Button
                            variant='tertiary'
                            onClick={addProject}
                            disabled={currentlyEditedIndex !== -1}
                            name='add-project'
                            dataTestId='add-project'
                        >
                            Add Projects
                        </Button>
                    )}
                </SectionHeaderWithActions>

                <section
                    css={css`
                        position: relative;
                    `}
                >
                    {projects === null ? (
                        <ContentLoader />
                    ) : (
                        <Fragment>
                            {projects.length > 0 ? (
                                <Fragment>
                                    {projects.map((project, index) => {
                                        return (
                                            <ProjectBox
                                                key={project.id}
                                                project={project}
                                                index={index}
                                                onSaveClicked={onSaveClicked}
                                                onEditClicked={onEditClicked}
                                                onDeleteClicked={onDeleteClicked}
                                                onCancelClicked={onCancelClicked}
                                                editMode={currentlyEditedIndex === index}
                                                saving={saving}
                                            />
                                        )
                                    })}
                                </Fragment>
                            ) : (
                                <SectionDataNotFound description='Currently there are no Projects added.'>
                                    <Button name='add-project' onClick={addProject} variant='tertiary' dataTestId='add-project'>
                                        Add Projects
                                    </Button>
                                </SectionDataNotFound>
                            )}
                        </Fragment>
                    )}
                </section>
            </SectionWrapper>

            <UnsavedChangesModal opened={unsavedChangesOpened} onLeaveClicked={onLeaveClicked} onStayClicked={onStayClicked} />
        </Fragment>
    )
}

export { Projects }
