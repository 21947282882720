import { FunctionComponent } from 'react'
import { Props, slide as Menu } from 'react-burger-menu'
import { COLOR_PALETTE } from '../theme/colors'

const menuStyles = {
    bmCrossButton: {
        height: '24px',
        width: '24px',
    },
    bmCross: {
        background: COLOR_PALETTE.gray_6,
    },
    bmMenuWrap: {
        position: 'fixed',
        height: '100%',
        maxWidth: '100%',
    },
    bmMenu: {
        background: COLOR_PALETTE.white,
        padding: '40px',
    },
    bmMorphShape: {
        fill: '#373a47',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.5)',
    },
    bmItemList: {
        height: 'auto',
    },
}

type SideBoxProps = Props & {}

const SideBox: FunctionComponent<React.PropsWithChildren<SideBoxProps>> = ({
    width = 670,
    right = true,
    itemListElement = 'div',
    styles = menuStyles,
    customBurgerIcon = false,
    ...rest
}) => {
    return (
        <Menu styles={styles} itemListElement={itemListElement} right={right} width={width} customBurgerIcon={customBurgerIcon} {...rest} />
    )
}
export { SideBox }
