/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, MutableRefObject, useCallback, useContext, useState } from 'react'
import { Button } from '../../../../components/Button'
import { IconButton } from '../../../../components/Icon'
import { DropdownComponent } from '../../../../components/inputs/autocomplete-select/DropdownComponent'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { mqMax } from '../../../../GlobalStyle'
import { ReduxContext } from '../../../../redux/Store'
import { log } from '../../../../utils/useLogger'
import { deleteFeedbackTeams } from '../api'
import { DeleteConfirmationModal } from './DeleteConfirmationModal'

type MoreActionsDropdownBaseProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    teamId: string
    fetchData: () => void
}

const Actions: FunctionComponent<React.PropsWithChildren<MoreActionsDropdownBaseProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    teamId,
    fetchData,
}) => {
    const { addError, addSuccess } = useNotifications()
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const showModal = useCallback(() => {
        setIsModalVisible(true)
    }, [])

    const handleRejection = useCallback(() => {
        setIsModalVisible(false)
    }, [])

    const handleConfirmation = useCallback(() => {
        layoutToggleLoader(true)
        deleteFeedbackTeams(teamId)
            .then(() => {
                addSuccess('Your Feedback Team was removed from the My Specialists App.')
                setIsModalVisible(false)
                fetchData()
            })
            .catch(error => {
                log(error)
                addError()
                layoutToggleLoader(false)
            })
    }, [addError, addSuccess, fetchData, teamId, layoutToggleLoader])

    const theme = useTheme()

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                justify-content: flex-end;
            `}
            ref={rangeRef}
        >
            <IconButton name='more' onClick={toggleOpen} />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        height: 45px;
                        width: 174px;
                        top: -50px;
                        background-color: ${theme.colors.white};
                        border: 1px solid ${theme.colors.gray_2};
                        border-radius: 2px;
                        ${mqMax[1]} {
                            width: 174px;
                            top: -5;
                            left: -10px;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-around;
                            flex-direction: column;
                            height: 45px;
                            width: 174px;
                        `}
                    >
                        <Button
                            style={css`
                                margin-left: 18px;
                            `}
                            variant='linkDelete'
                            onClick={showModal}
                        >
                            Delete Feedback Team
                        </Button>
                    </div>
                </div>
            )}

            <DeleteConfirmationModal isVisible={isModalVisible} handleConfirmation={handleConfirmation} handleRejection={handleRejection} />
        </div>
    )
}

const ActionsDropDown = DropdownComponent(Actions)

export { ActionsDropDown }
