/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, ReactNode, useCallback } from 'react'
import { SubscriptionRequiredModal } from 'src/components/open-opportunities/SubscriptionRequiredModal'
import { history } from 'src/history'

type SubscriptionLayerProps = {
    children: ReactNode
    visible: boolean
}

const SubscriptionLayer: FunctionComponent<SubscriptionLayerProps> = ({ visible, children }) => {
    const handleSubscribe = useCallback(() => {
        history.push('/dashboard/company-account/subscription/details')
    }, [])

    const handleClose = useCallback(() => {
        history.push('/open-opportunities')
    }, [])

    return visible ? (
        <div
            css={css`
                position: absolute;
                top: 1;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.24);
            `}
        >
            {children}
            <SubscriptionRequiredModal
                closeButtonContent='Back to Opportunities'
                opened
                onClose={handleClose}
                onSubscribe={handleSubscribe}
            />
        </div>
    ) : (
        <>{children}</>
    )
}

export { SubscriptionLayer }
