/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { randomString } from '../../../utils/strings'
import { GameBox } from '../contracts'
import { ClickableBox } from './ClickableBox'

type GameBoardProps = {
    matrix: any | Array<Array<GameBox>>
    isVisible: boolean
    boxSize: string
    padding: string
    handleClick: (rowIndex: number, colIndex: number) => void
    isFinished?: boolean
    isCorrect?: boolean
}

const GameBoard: FunctionComponent<React.PropsWithChildren<GameBoardProps>> = ({
    matrix,
    isVisible,
    boxSize,
    padding,
    handleClick,
    isFinished = false,
    isCorrect = false,
}) => {
    const theme = useTheme()

    return (
        <section
            css={css`
                position: relative;
            `}
        >
            {isCorrect && (
                <div
                    css={css`
                        background-color: ${theme.colors.green_2};
                        width: 235px;
                        height: 235px;
                        border-radius: 50%;
                        color: ${theme.colors.green_6};
                        font-size: 44px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        box-shadow: 0px 0px 74px rgba(0, 0, 0, 0.12);
                    `}
                    className='animate__animated animate__tada'
                >
                    Correct!
                </div>
            )}
            {matrix.map((row: any, rowIndex: any) => {
                return (
                    <div
                        key={randomString()}
                        css={css`
                            background-color: ${theme.colors.white};
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding-top: ${rowIndex === 0 ? padding : '0px'};
                            padding-right: ${padding};
                            padding-bottom: ${padding};
                            padding-left: ${padding};
                        `}
                    >
                        {row.map((item: any, colIndex: any) => {
                            return (
                                <ClickableBox
                                    key={colIndex}
                                    item={item}
                                    boxSize={boxSize}
                                    click={() => handleClick(rowIndex, colIndex)}
                                    isVisible={isVisible}
                                    showResults={isFinished}
                                    dataTestId={`box_${rowIndex}_${colIndex}`}
                                />
                            )
                        })}
                    </div>
                )
            })}
        </section>
    )
}

export { GameBoard }
