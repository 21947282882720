/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import RcPagination, { PaginationProps } from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import { FunctionComponent } from 'react'
import { mqMax } from '../GlobalStyle'
import { Theme } from '../theme/theme'
import { blackToWhiteFilter, Icon } from './Icon'
import { AutocompleteSelect } from './inputs/autocomplete-select/AutocompleteSelect'

const defaultLocale = {
    items_per_page: '/ page',
    jump_to: 'Go to',
    jump_to_confirm: 'confirm',
    page: '',
    prev_page: 'Previous Page',
    next_page: 'Next Page',
    prev_5: 'Previous 5 Pages',
    next_5: 'Next 5 Pages',
    prev_3: 'Previous 3 Pages',
    next_3: 'Next 3 Pages',
}

const PaginationElementStylesBase = css`
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 12px;
    margin-right: 4px;
    border-radius: 2px;
    border: none;
`

const getPaginationStyles = (theme: Theme) => css`
    .rc-pagination-item {
        ${PaginationElementStylesBase}
        padding: 0 4px;
        transition: 0.1s;
        background-color: transparent;
        &:hover {
            background-color: ${theme.colors.gray_6};
            & a {
                color: ${theme.colors.white};
                text-decoration: none;
            }
        }
    }
    .rc-pagination-item-active {
        background-color: ${theme.colors.beige_3};
        & a {
            color: ${theme.colors.gray_6};
            text-decoration: none;
        }
        &:hover {
            border: none;
        }
    }
    .rc-pagination-jump-prev,
    .rc-pagination-jump-next {
        ${PaginationElementStylesBase}
        color: ${theme.colors.gray_6};

        &:after {
            margin-top: 4px;
            content: '...';
            display: block;
            color: ${theme.colors.gray_6};
        }
    }
    .rc-pagination-prev,
    .rc-pagination-next {
        ${PaginationElementStylesBase}
        padding: 0 12px;
        transition: 0.1s;
        white-space: nowrap;
        background-color: ${theme.colors.beige_3};
        color: ${theme.colors.gray_6};

        &:hover {
            background-color: ${theme.colors.gray_6};
            color: ${theme.colors.white};
            > span > img {
                filter: ${blackToWhiteFilter};
            }
        }
        > span > img {
            margin-bottom: 2px;
        }
    }
    .rc-pagination-prev {
        > span > img {
            transform: scale(-1, 1);
        }
        margin-right: 60px;
        ${mqMax[1]} {
            margin-right: 30px;
        }
        ${mqMax[0]} {
            margin-right: 10px;
        }
    }
    .rc-pagination-next {
        margin-left: 60px;
        ${mqMax[1]} {
            margin-left: 30px;
        }
        ${mqMax[0]} {
            margin-left: 10px;
        }
    }
    .rc-pagination-disabled,
    .rc-pagination-disabled:hover {
        background-color: ${theme.colors.gray_2};
        color: ${theme.colors.gray_3};
        > span > img {
            filter: invert(50%);
        }
    }
`

type ItemRenderer = (page: number, type: string, element: React.ReactNode) => React.ReactNode

const customItemRender: ItemRenderer = (current, type, element) => {
    if (type === 'prev') {
        element = (
            <span>
                <Icon name='arrow-right' size={12} />
                <span
                    css={css`
                        margin-left: 12px;
                        ${mqMax[1]} {
                            display: none;
                        }
                    `}
                >
                    Previous
                </span>
            </span>
        )
    } else if (type === 'next') {
        element = (
            <span>
                <span
                    css={css`
                        margin-right: 12px;
                        ${mqMax[1]} {
                            display: none;
                        }
                    `}
                >
                    Next
                </span>
                <Icon name='arrow-right' size={12} />
            </span>
        )
    } else if (type === 'jump-prev' || type === 'jump-next') {
        element = null
    }
    return element
}

type CustomPaginationProps = PaginationProps & { resultAmountBase?: number; onChangePageSize?: (value: string[], option: any) => void }

const Pagination: FunctionComponent<React.PropsWithChildren<CustomPaginationProps>> = ({
    itemRender = customItemRender,
    locale = defaultLocale,
    onChangePageSize,
    defaultPageSize = 10,
    pageSize,
    ...props
}) => {
    const theme = useTheme()
    return (
        <div>
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                    ${getPaginationStyles(theme)}
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <div
                    css={css`
                        margin-right: 40px;
                        display: flex;
                        align-items: center;
                        ${mqMax[1]} {
                            margin-right: 0;
                            margin-bottom: 24px;
                            justify-content: flex-end;
                        }
                    `}
                >
                    <div
                        css={css`
                            margin-right: 12px;
                            ${mqMax[1]} {
                                display: none;
                            }
                        `}
                    >
                        Show:{' '}
                    </div>
                    <div
                        css={css`
                            width: 75px;
                        `}
                    >
                        <AutocompleteSelect
                            options={[defaultPageSize, defaultPageSize * 2, defaultPageSize * 3]}
                            canFilter={false}
                            onSelectedValuesChange={onChangePageSize}
                            currentValues={[pageSize]}
                            name='pageSize'
                        />
                    </div>
                </div>
                <RcPagination
                    locale={defaultLocale}
                    itemRender={itemRender}
                    pageSize={pageSize}
                    defaultPageSize={defaultPageSize}
                    {...props}
                />
            </div>
        </div>
    )
}

export { Pagination }
