/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE, mqMax } from '../GlobalStyle'
import { Button } from './Button'
import { Icon } from './Icon'

type BlueActionBoxProps = {
    withInfoIcon?: boolean
    text: string
    buttonText?: string
    action?: () => void
    styles?: SerializedStyles
}

const BlueActionBox: FunctionComponent<React.PropsWithChildren<BlueActionBoxProps>> = ({
    withInfoIcon = false,
    text,
    buttonText,
    action,
    styles,
}) => (
    <div
        css={css`
            text-align: center;
            background-color: ${COLOR_PALETTE.blue_2};
            padding: 4px 0;
            max-width: 1440px;
            position: relative;
            ${styles}
        `}
    >
        {withInfoIcon && (
            <Icon
                name='info-round'
                css={css`
                    position: absolute;
                    top: calc(50% - 8px);
                    left: 16px;
                `}
            />
        )}
        {text}
        {buttonText && (
            <Button
                variant='secondary'
                size='small'
                onClick={action}
                style={css`
                    margin-left: 16px;

                    ${mqMax[1]} {
                        margin: 12px;
                    }
                `}
            >
                {buttonText}
            </Button>
        )}
    </div>
)

export { BlueActionBox }
