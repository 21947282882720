/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { mqMax } from 'src/GlobalStyle'
import { Divider } from 'src/components/Divider'
import { FieldLine } from 'src/components/FieldLine'
import { TextHighlight } from 'src/components/TextHighlight'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { OfferBadges } from 'src/components/opportunities/OfferBadges'
import { OpportunityLocation } from 'src/components/opportunities/OpportunityLocation'
import { locationLabel } from 'src/components/opportunities/utils'
import { ProfileHeaderInfo } from 'src/components/profile/ProfileHeaderInfo'
import { SkillsList } from 'src/components/talent/SkillsList'

import { OpportunityStatus } from 'src/contracts/opportunities'
import { OpportunityLocationTypeV2Enum } from 'src/contracts/opportunities-manager/contracts'
import { ReduxContext } from 'src/redux/Store'
import { Nullable, StringMapping } from 'src/types'
import { formatUnixDate } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'

import { EarlyAccessBadge } from './EarlyAccessBadge'
import { SMEBadge } from './SMEBadge'
import { informationHiddenTooltipContent } from 'src/utils/values'
import { Application, Opportunity } from 'src/contracts/open-opportunities/contracts'
import { getOpenOpportunitiesApplications } from 'src/api/open-opportunities/api'
import { formatDealAndRate } from 'src/components/open-opportunities/utils/openOpportunitiesUtils'

type OpenOpportunityCardProps = {
    opportunity: Opportunity
    searchWords: Array<string>
    appPath: string
}

const dealAndRateRegex = /(\d+-\d+ )/g

const locationTooltip: StringMapping = {
    [OpportunityLocationTypeV2Enum.ON_SITE]: 'Only onsite from these locations',
    [OpportunityLocationTypeV2Enum.HYBRID]:
        'A combination of on-site and remote work, as specified by the employer. Further details can be found in the opportunity description.',
    [OpportunityLocationTypeV2Enum.REMOTE]: 'Fully remote from these locations',
}

const OpenOpportunityCard: FunctionComponent<React.PropsWithChildren<OpenOpportunityCardProps>> = ({
    opportunity,
    searchWords,
    appPath,
}) => {
    const theme = useTheme()

    const [applications, setApplications] = useState<Nullable<Array<Application>>>(null)
    const [isFetchingApplications, setFetchingApplications] = useState<boolean>(false)

    const log = useLogger('error')
    const { addError } = useNotifications()

    const {
        selectors: {
            featureFlags: { payments },
        },
    } = useContext(ReduxContext)

    const isInformationHidden = useMemo(
        () => opportunity.smeCloud && opportunity.hiddenInformation,
        [opportunity.hiddenInformation, opportunity.smeCloud],
    )

    const getApplications = useCallback(() => {
        if (!applications) {
            setFetchingApplications(true)
            getOpenOpportunitiesApplications(opportunity.opportunityId)
                .then(response => {
                    setFetchingApplications(false)
                    setApplications(response.applications.filter(application => application.appliedOn))
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, applications, log, opportunity.opportunityId])

    return (
        <Link
            to={`${appPath}/${opportunity.opportunityId}?prevPath=${encodeURIComponent(
                window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
            )}`}
            data-testid='open-opportunity-card'
            css={css`
                background-color: ${theme.colors.white};
                box-shadow: ${theme.shadow.small};
                border-radius: 4px;
                padding: 24px;
                cursor: pointer;
                display: block;
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    ${mqMax[1]} {
                        flex-direction: column-reverse;
                    }
                `}
            >
                <FlexBox
                    css={css`
                        margin-bottom: 16px;
                    `}
                >
                    <OfferBadges
                        applied={opportunity.myApplicationSize || undefined}
                        offerType={opportunity.offerType}
                        vacancies={opportunity.vacancies}
                        appliedOptions={applications || []}
                        getAppliedOptions={getApplications}
                        isLoadingApplied={isFetchingApplications}
                        status={opportunity.status}
                    />
                </FlexBox>
                {payments && opportunity.earlyAccess ? <EarlyAccessBadge /> : null}
                {opportunity.smeCloud ? <SMEBadge /> : null}
            </div>
            <div
                css={
                    opportunity.status === OpportunityStatus.ON_HOLD &&
                    css`
                        opacity: 0.5;
                    `
                }
            >
                <h5
                    css={css`
                        margin-right: auto;
                        font-size: 18px;
                        margin-bottom: 0;

                        ${opportunity.myApplicationSize &&
                        css`
                            margin-top: -4px;
                        `}
                    `}
                >
                    <TextHighlight searchWords={searchWords} text={opportunity.opportunityTitle} />
                </h5>
                <Divider
                    style={css`
                        margin: 5px 0 0;
                        height: 8px;
                    `}
                />
                <ProfileHeaderInfo
                    elements={[
                        {
                            iconName: 'violet-calendar',
                            label: `${formatUnixDate(opportunity.projectStartDate, 'DD.MM.YYYY')} – ${formatUnixDate(
                                opportunity.projectEndDate,
                                'DD.MM.YYYY',
                            )}`,
                            tooltip: 'Project dates',
                        },
                        {
                            iconName: 'experience',
                            label: opportunity.years
                                ? opportunity.years > 1
                                    ? `${opportunity.years} years`
                                    : `${opportunity.years} year`
                                : '<1 year',
                            tooltip: 'Experience',
                        },
                        {
                            iconName: 'code-editor',
                            label: <TextHighlight searchWords={searchWords} text={opportunity.industry} />,
                            tooltip: 'Industry',
                            dataTestId: 'open-opportunity-industry',
                        },
                    ]}
                    style={css`
                        border: 0;

                        ${mqMax[2]} {
                            flex-direction: column;
                            padding: 30px 0 10px;

                            div {
                                margin-bottom: 12px;
                            }
                        }

                        div {
                            margin-right: 20px;
                            font-size: 14px;
                        }
                    `}
                />

                <div>
                    <Tooltip title={isInformationHidden ? informationHiddenTooltipContent : ''}>
                        <div
                            css={css`
                                background-color: ${theme.colors.violet_1};
                                border-radius: 4px;
                                padding: 8px;

                                ${mqMax[2]} {
                                    ul {
                                        overflow: auto;
                                        height: fit-content;
                                    }
                                }
                            `}
                        >
                            <SkillsList
                                truncated
                                topSkills
                                skills={opportunity.requiredSkills}
                                searchWords={searchWords}
                                styles={css`
                                    filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                `}
                            />
                        </div>
                    </Tooltip>
                    <FlexBox
                        css={css`
                            margin-left: auto;
                            margin: 25px 0 10px;
                            padding: 0;
                        `}
                    >
                        <Tooltip
                            placement='topLeft'
                            title={
                                isInformationHidden
                                    ? informationHiddenTooltipContent
                                    : locationTooltip[opportunity.location?.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE]
                            }
                        >
                            <span>
                                <FieldLine
                                    fields={[
                                        {
                                            label: locationLabel[
                                                opportunity.location?.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE
                                            ],
                                            value: '',
                                        },
                                    ]}
                                />
                            </span>
                        </Tooltip>
                        {opportunity.location && (
                            <div
                                css={css`
                                    flex: 1;

                                    & > div > div:first-of-type {
                                        display: none;
                                    }
                                `}
                            >
                                <OpportunityLocation
                                    type={opportunity.location?.locationTypeV2}
                                    countriesCitiesList={opportunity.location?.countriesWithCities}
                                    regions={opportunity.location?.regions}
                                    countries={opportunity.location?.countriesWithCities.map(c => c.country)}
                                />
                            </div>
                        )}
                    </FlexBox>

                    <FieldLine
                        style={css`
                            margin-left: auto;
                            margin: 12px 0;
                            padding: 0;
                        `}
                        fields={[
                            {
                                label: 'Deal size',
                                value: (
                                    <span
                                        css={css`
                                            font-weight: 400;

                                            ${opportunity.dealSize === 'Negotiable' &&
                                            css`
                                                color: ${theme.colors.gray_3};
                                            `}
                                        `}
                                    >
                                        {opportunity.dealSize?.match(dealAndRateRegex)
                                            ? formatDealAndRate(opportunity.dealSize)
                                            : opportunity.dealSize}
                                    </span>
                                ),
                            },
                        ]}
                    />
                    <Tooltip title={isInformationHidden ? informationHiddenTooltipContent : ''}>
                        <FieldLine
                            style={css`
                                margin-left: auto;
                                margin-bottom: 12px;
                                padding: 0;
                            `}
                            fields={[
                                {
                                    label: 'Rate',
                                    value: (
                                        <span
                                            css={css`
                                                font-weight: 400;
                                                ${opportunity.rate === 'Negotiable' &&
                                                css`
                                                    color: ${theme.colors.gray_3};
                                                `}
                                                filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                            `}
                                        >
                                            {opportunity.rate?.match(dealAndRateRegex)
                                                ? formatDealAndRate(opportunity.rate)
                                                : opportunity.rate}
                                        </span>
                                    ),
                                },
                            ]}
                        />
                    </Tooltip>
                </div>
            </div>
        </Link>
    )
}

export { OpenOpportunityCard }
