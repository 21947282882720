/** @jsxImportSource @emotion/react */
import { useCallback } from 'react'
import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'

import { yup } from 'src/validation/yup'
import { Modal } from 'src/components/Modal'
import { Divider } from 'src/components/Divider'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { Button } from 'src/components/Button'
import { hasErrors } from 'src/utils/errors'

import { UpdateApplicationBody, ApplicationStatusEnum } from '../../contracts/opportunities-manager/contracts'

import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'

interface InterviewData {
    interviewDate: number
}
const schema = yup.object().shape({
    interviewDate: yup.number().required(),
})

interface OwnProps {
    closeModal: () => void
    currentApplicationId: string
    currentStatus: ApplicationStatusEnum
    onSubmit: (applicationId: string, data: UpdateApplicationBody) => void
}

const InterviewDateModal: React.FC<OwnProps> = ({ closeModal, currentApplicationId, currentStatus, onSubmit }) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, errors },
    } = useForm<InterviewData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const submitCallback = useCallback(
        (data: InterviewData) => {
            onSubmit(currentApplicationId, {
                status: { status: currentStatus, interviewDate: data.interviewDate },
            })
        },
        [onSubmit, currentApplicationId, currentStatus],
    )

    return (
        <Modal opened onClose={closeModal}>
            <form onSubmit={handleSubmit(submitCallback)}>
                <h5>Please provide interview date</h5>

                <ControlledDatePickerInput
                    control={control}
                    name='interviewDate'
                    label='Interview date'
                    placeholder='Choose date'
                    disabledDate={date => date.isBefore(moment().startOf('day'))}
                    showTime
                    format='DD.MM.YYYY HH:mm'
                    minuteStep={15}
                />

                <Divider />

                <FlexBox alignItems='center' justifyContent='flex-end'>
                    <Button variant='link' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        style={css`
                            margin-left: 16px;
                        `}
                        disabled={hasErrors(errors) || !isDirty || isSubmitting}
                        dataTestId='submit-interview-rate'
                    >
                        Accept
                    </Button>
                </FlexBox>
            </form>
        </Modal>
    )
}

export { InterviewDateModal }
