/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffectOnce, useInterval } from 'react-use'
import { getSpecialistCvParsingStatus, getSpecialistStrengthForms, removeSpecialistCv } from '../../../api/api'
import { Button } from '../../../components/Button'
import { blackToGreenLightFilter, Icon } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Spinner } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { TooltipIcon } from '../../../components/TooltipIcon'
import { CvSourceType, SpecialistCv, SpecialistCvStatus } from '../../../contracts/cvs'
import { SkillForm } from '../../../contracts/skillForm'
import { mqMax } from '../../../GlobalStyle'
import { history } from '../../../history'
import { ReduxContext } from '../../../redux/Store'
import { NullableArray } from '../../../types'
import { generateSubTestId } from '../../../utils/testid'
import { useLogger } from '../../../utils/useLogger'
import { mapTaskType, SubtaskStatus, TaskType, TaskTypeEnum } from '../contracts'
import { Subtask } from './Subtask'

type TaskProps = {
    task: TaskType
    dataTestId?: string
}

const Task: FunctionComponent<React.PropsWithChildren<TaskProps>> = ({ task, dataTestId }) => {
    const {
        selectors: { decodedAccessToken },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const [strengthForms, setStrengthForms] = useState<Array<SkillForm>>()

    const theme = useTheme()
    const areSomeSubtasksDone = useMemo(() => task.subtasks.some(subtask => subtask.status === SubtaskStatus.COMPLETED), [task.subtasks])
    const areAllSubtasksDone = useMemo(() => !task.subtasks.some(subtask => subtask.status !== SubtaskStatus.COMPLETED), [task.subtasks])
    const areSomePsychometricsDone = useMemo(
        () => task.type === TaskTypeEnum.STRENGTH_DISCOVERY && strengthForms?.some(form => form.isCompleted),
        [strengthForms, task.type],
    )
    const log = useLogger('error')
    const { addError } = useNotifications()
    const [cvParsingStatus, setCvParsingStatus] = useState<NullableArray<SpecialistCv>>(null)

    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])

    useEffectOnce(() => {
        if (task.type === TaskTypeEnum.STRENGTH_DISCOVERY) {
            getSpecialistStrengthForms(specialistId)
                .then(data => {
                    setStrengthForms(data)
                })
                .catch(log)
        }
    })

    useEffect(() => {
        if (task.type === TaskTypeEnum.CV_PARSING) {
            layoutToggleLoader(true)
            getSpecialistCvParsingStatus(specialistId)
                .then(setCvParsingStatus)
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [specialistId, log, task.type, layoutToggleLoader, addError])

    const handleUploadAgain = useCallback(() => {
        const cvParsingId = cvParsingStatus?.find(status => status.status === SpecialistCvStatus.READY_FOR_VERIFICATION)?.cvParsingId
        if (cvParsingStatus && cvParsingId) {
            layoutToggleLoader(true)
            removeSpecialistCv(specialistId, cvParsingId)
                .then(() => history.push('/specialist-cv-parsing'))
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [specialistId, cvParsingStatus, log, layoutToggleLoader, addError])

    const cvStatusInProgress = useMemo(
        () => cvParsingStatus?.find(status => status.status === SpecialistCvStatus.IN_PROGRESS),
        [cvParsingStatus],
    )
    const cvStatusReadyForVerification = useMemo(
        () => cvParsingStatus?.find(status => status.status === SpecialistCvStatus.READY_FOR_VERIFICATION),
        [cvParsingStatus],
    )

    const cvStatusError = useMemo(
        () =>
            !cvStatusInProgress &&
            !cvStatusReadyForVerification &&
            cvParsingStatus?.find(status => status.status === SpecialistCvStatus.ERROR),
        [cvParsingStatus, cvStatusInProgress, cvStatusReadyForVerification],
    )

    useInterval(
        () => {
            getSpecialistCvParsingStatus(specialistId)
                .then(setCvParsingStatus)
                .catch(err => {
                    log(err)
                    addError()
                })
        },
        task.type === TaskTypeEnum.CV_PARSING && cvStatusInProgress ? 5000 : null,
    )

    const handleStartClick = useCallback(() => {
        switch (task.type) {
            case TaskTypeEnum.BASIC_INFO: {
                history.push(`/my-specialists/basic-info`)
                break
            }
            case TaskTypeEnum.TECH_SKILLS: {
                history.push(`/skills-discovery/${specialistId}`)
                break
            }
            case TaskTypeEnum.STRENGTH_DISCOVERY: {
                history.push(`/strength-discovery/${specialistId}`)
                break
            }
            case TaskTypeEnum.CV_PARSING: {
                history.push(`/specialist-cv-parsing${cvStatusError ? `?cvSource=${cvStatusError.cvSource}` : ''}`)
                break
            }
            case TaskTypeEnum.TEST_YOUR_SKILLS: {
                history.push(`/skills-test/${specialistId}`)
                break
            }
        }
    }, [task.type, specialistId, cvStatusError])

    const shouldDisplayActionButton = useMemo(
        () =>
            (!areAllSubtasksDone ||
                (areAllSubtasksDone && task.type === TaskTypeEnum.BASIC_INFO) ||
                (areAllSubtasksDone && task.type === TaskTypeEnum.CV_PARSING)) &&
            !cvStatusInProgress &&
            !cvStatusReadyForVerification,
        [areAllSubtasksDone, cvStatusInProgress, cvStatusReadyForVerification, task.type],
    )

    const shouldDisplayCheckMark = useMemo(
        () =>
            !(
                !areAllSubtasksDone ||
                (areAllSubtasksDone && task.type === TaskTypeEnum.BASIC_INFO) ||
                (areAllSubtasksDone && task.type === TaskTypeEnum.CV_PARSING)
            ) &&
            !cvStatusInProgress &&
            !cvStatusReadyForVerification,
        [areAllSubtasksDone, cvStatusInProgress, cvStatusReadyForVerification, task.type],
    )

    return (
        <div
            css={css`
                box-shadow: 0px 4px 12px ${theme.colors.box_shadow_color};
                border: 1px solid ${theme.colors.gray_2};
                padding: 40px;
                border-radius: 4px;
                margin-bottom: 16px;
                ${areAllSubtasksDone && `background-color:${theme.colors.green_1}`}
                ${cvStatusError && `background-color:${theme.colors.red_1}`}
            `}
        >
            <FlexBox
                alignItems='center'
                justifyContent='space-between'
                css={css`
                    width: 100%;
                    ${mqMax[1]} {
                        flex-direction: column;
                        align-items: flex-start;
                    }
                `}
            >
                <div>
                    <h6>{mapTaskType(task.type)}</h6>
                    {task.subtasks.map(subtask => (
                        <Subtask key={subtask.name} subtask={subtask} isErrorPresent={!!cvStatusError} />
                    ))}
                </div>
                {cvStatusError && (
                    <FlexBox
                        css={css`
                            color: ${theme.colors.red_4};
                            align-items: center;
                        `}
                    >
                        Parsing error
                        <Tooltip title={cvStatusError.errorMessage}>
                            <span>
                                <TooltipIcon
                                    styles={css`
                                        margin-left: 8px;
                                    `}
                                />
                            </span>
                        </Tooltip>
                    </FlexBox>
                )}
                {cvStatusInProgress && (
                    <FlexBox alignItems='center'>
                        <Button
                            dataTestId={generateSubTestId(dataTestId, 'in-progress')}
                            css={css`
                                padding: 16px;
                                ${mqMax[1]} {
                                    align-self: flex-end;
                                    margin-top: 16px;
                                }
                            `}
                            onClick={handleStartClick}
                            variant='linkForm'
                        >
                            parsing in progress...
                        </Button>
                        <div
                            css={css`
                                position: relative;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                            `}
                        >
                            <Spinner circleWidth={2} sizeInner={12} sizeOuter={16} />
                        </div>
                    </FlexBox>
                )}
                {cvStatusReadyForVerification && (
                    <FlexBox
                        css={css`
                            flex-direction: column;
                            align-items: flex-end;
                        `}
                    >
                        <Link
                            to={
                                cvStatusReadyForVerification.cvSource === CvSourceType.LINKEDIN
                                    ? `/specialist-cv-parsing/${cvStatusReadyForVerification.cvParsingId}/experience`
                                    : `/specialist-cv-parsing/${cvStatusReadyForVerification.cvParsingId}/skills`
                            }
                        >
                            <Button
                                dataTestId={generateSubTestId(dataTestId, 'finishExtracting')}
                                css={css`
                                    ${mqMax[1]} {
                                        align-self: flex-end;
                                        margin-top: 16px;
                                    }
                                `}
                                variant='linkForm'
                            >
                                <FlexBox
                                    css={css`
                                        justify-content: center;
                                    `}
                                >
                                    Finish Extracting
                                    <Icon
                                        name='arrow-right-single'
                                        size={18}
                                        css={css`
                                            margin-left: 2px;
                                        `}
                                    />
                                </FlexBox>
                            </Button>
                        </Link>
                        <Button
                            dataTestId={generateSubTestId(dataTestId, 'start')}
                            css={css`
                                ${mqMax[1]} {
                                    align-self: flex-start;
                                    margin-top: 16px;
                                }
                            `}
                            onClick={handleUploadAgain}
                            variant='linkForm'
                        >
                            <FlexBox
                                css={css`
                                    justify-content: center;
                                `}
                            >
                                Upload Again
                                <Icon
                                    name='arrow-right-single'
                                    size={18}
                                    css={css`
                                        margin-left: 2px;
                                    `}
                                />
                            </FlexBox>
                        </Button>
                    </FlexBox>
                )}
                {shouldDisplayActionButton && (
                    <Button
                        dataTestId={generateSubTestId(dataTestId, 'start')}
                        css={css`
                            padding: 16px 0;
                            ${mqMax[1]} {
                                align-self: flex-start;
                                margin-top: 16px;
                            }
                        `}
                        onClick={handleStartClick}
                        variant='linkForm'
                    >
                        <FlexBox
                            css={css`
                                justify-content: center;
                            `}
                        >
                            {areAllSubtasksDone
                                ? 'Update Info'
                                : (task.type === TaskTypeEnum.TECH_SKILLS && areSomeSubtasksDone) ||
                                  (task.type === TaskTypeEnum.STRENGTH_DISCOVERY && areSomePsychometricsDone)
                                ? 'Continue'
                                : 'Start'}
                            <Icon
                                name='arrow-right-single'
                                size={18}
                                css={css`
                                    margin-left: 2px;
                                `}
                            />
                        </FlexBox>
                    </Button>
                )}
                {shouldDisplayCheckMark && (
                    <Icon
                        dataTestId={generateSubTestId(dataTestId, 'check')}
                        name='check'
                        size={28}
                        style={css`
                            filter: ${blackToGreenLightFilter};
                        `}
                    />
                )}
            </FlexBox>
        </div>
    )
}

export { Task }
