/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from 'moment'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/Button'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { AvailableOn } from 'src/contracts/search/availableOn'
import { mqMax } from 'src/GlobalStyle'
import { useCountries, useCurrencies } from 'src/redux/dictionaryDataHooks'
import { hasErrors } from 'src/utils/errors'
import { useIsMobile } from 'src/utils/hooks'
import { naturalNumbersRegex } from 'src/utils/regexes'
import { FIELD_REQUIRED, INVALID_NUMBER } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'
import { SharedMapping, sharedTypes } from '../contracts'
import { findSyndicateNameById } from 'src/utils/values'
import { BottomBar } from 'src/components/BottomBar'
import { BulkEditModal } from './BulkEditModal'

const schema: any = yup.object().shape({
    availableFrom: yup.number().required(),
    rate: yup.lazy(value => (value === '' ? yup.string() : yup.number().positive().typeError(INVALID_NUMBER))),
    availableOn: yup.array().of(yup.string()).min(1, FIELD_REQUIRED).required(),
    shared: yup.string().required(),
    country: yup.string().required(),
    currency: yup.string().required(),
})

type BulkEditBarProps = {
    selectedRecords: Array<string>
    clearRowSelection: () => void
    bulkEditSpecialists: (formData: any) => void
    syndicates: Array<AvailableOn>
    options: Array<string>
    isBulkEditRateValidationFailed: boolean
    setIsBulkEditRateValidationFailed: Dispatch<SetStateAction<boolean>>
}

const mapShared = (shared: string): string | undefined => Object.keys(SharedMapping).find(key => SharedMapping[key] === shared)

const BulkEditBar: FunctionComponent<React.PropsWithChildren<BulkEditBarProps>> = ({
    selectedRecords,
    clearRowSelection,
    bulkEditSpecialists,
    syndicates,
    options,
    isBulkEditRateValidationFailed,
    setIsBulkEditRateValidationFailed,
}) => {
    const {
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
        reset,
        control,
        setError,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const currencies = useCurrencies()

    const isMobile = useIsMobile(2)
    const [editModalOpened, setEditModalOpened] = useState<boolean>(false)

    const isInSyndicate = useMemo(() => syndicates.length > 1, [syndicates.length])

    const prepareRequestBody = useCallback(
        (formData: any): any => {
            const requestBody: any = {
                availableFrom: Number(
                    moment
                        .utc(new Date(formData.availableFrom * 1000))
                        .startOf('day')
                        .format('X'),
                ),
                availableOn: formData.availableOn,
                rate: Number.parseInt(formData.rate, 10),
                shared: mapShared(formData.shared),
                country: formData.country,
                currency: formData.currency,
            }

            requestBody.ids = selectedRecords

            return requestBody
        },
        [selectedRecords],
    )

    const onCancel = useCallback(() => {
        clearRowSelection()
    }, [clearRowSelection])

    const onSubmit = useCallback(
        (formData: any) => {
            const requestBody = prepareRequestBody(formData)
            bulkEditSpecialists(requestBody)

            if (editModalOpened) {
                setEditModalOpened(false)
            }
        },
        [prepareRequestBody, editModalOpened, bulkEditSpecialists],
    )

    const onEdit = useCallback(() => {
        setEditModalOpened(true)
    }, [])

    useEffect(() => {
        if (!isInSyndicate) {
            reset({ availableFrom: Date.now() / 1000, availableOn: [syndicates[0].id] })
        } else {
            reset({ availableFrom: Date.now() / 1000, availableOn: [] })
        }
    }, [isInSyndicate, reset, syndicates])

    useEffect(() => {
        if (isBulkEditRateValidationFailed) {
            setError('rate', { message: 'Rate is required' })
            setIsBulkEditRateValidationFailed(false)
        }
    }, [isBulkEditRateValidationFailed, setError, setIsBulkEditRateValidationFailed])

    useEffect(() => {
        clearErrors('rate')
    }, [clearErrors, selectedRecords.length])

    return (
        <Fragment>
            <BottomBar
                containerStyles={css`
                    ${mqMax[1]} {
                        height: 100px;
                    }
                `}
            >
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    css={css`
                        height: 75px;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                        `}
                    >
                        {!isMobile && (
                            <div
                                css={css`
                                    display: flex;
                                `}
                            >
                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        max-width: 140px;
                                        margin-right: 16px;
                                    `}
                                >
                                    <ControlledAutocompleteSelect
                                        placeholder='Country'
                                        options={memorizedCountries}
                                        defaultValue={[]}
                                        control={control}
                                        name='country'
                                        dataTestId='company-details-form-country'
                                        canClear
                                        dropdownWidth='100%'
                                        styles={css`
                                            min-width: 123px;
                                        `}
                                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                    />
                                </FieldWrapper>

                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        max-width: 140px;
                                        margin-right: 16px;
                                    `}
                                >
                                    <ControlledAutocompleteSelect
                                        placeholder='Currency'
                                        options={currencies}
                                        control={control}
                                        name='currency'
                                        canClear
                                        dropdownWidth='100%'
                                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                        dataTestId='bulk-edit-currency'
                                    />
                                </FieldWrapper>

                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        max-width: 140px;
                                    `}
                                >
                                    <ControlledInput
                                        control={control}
                                        name='rate'
                                        type='text'
                                        placeholder='Hourly Rate'
                                        regex={naturalNumbersRegex}
                                        style={css`
                                            max-width: 140px;
                                        `}
                                        dataTestId='bulk-edit-rate'
                                    />
                                </FieldWrapper>

                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        max-width: 198px;
                                        margin: 0 16px;
                                    `}
                                >
                                    <ControlledDatePickerInput
                                        control={control}
                                        name='availableFrom'
                                        placeholder='Available From'
                                        style={{ height: '40px', marginRight: '8px' }}
                                        popupStyle={{ position: 'fixed' }}
                                        dropdownAlign={{ points: ['bl', 'tl'] }}
                                        dataTestId='bulk-edit-available-from'
                                    />
                                </FieldWrapper>

                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        width: unset;
                                        max-width: 198px;
                                        margin-right: 16px;
                                    `}
                                >
                                    <ControlledAutocompleteSelect
                                        multiple
                                        canFilter={false}
                                        placeholder='Available On'
                                        options={options}
                                        disabled={!isInSyndicate}
                                        selectedLabelTransformer={(id: string) => findSyndicateNameById(id, syndicates)}
                                        control={control}
                                        name='availableOn'
                                        dataTestId='bulk-edit-available-on'
                                    />
                                </FieldWrapper>

                                <FieldWrapper
                                    noLabel
                                    css={css`
                                        width: unset;
                                        max-width: 198px;
                                    `}
                                >
                                    <ControlledAutocompleteSelect
                                        canFilter={false}
                                        placeholder='Share Status'
                                        options={sharedTypes.map(opt => SharedMapping[opt])}
                                        control={control}
                                        name='shared'
                                        dataTestId='bulk-edit-shared'
                                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                    />
                                </FieldWrapper>
                            </div>
                        )}
                    </div>

                    <div
                        css={css`
                            display: flex;
                            align-self: center;
                            align-items: center;

                            ${mqMax[1]} {
                                width: 100%;
                                flex-direction: column-reverse;
                                justify-content: center;
                            }
                        `}
                    >
                        <Button
                            variant='link'
                            style={css`
                                margin-right: 22px;

                                ${mqMax[1]} {
                                    width: 100%;
                                    margin-right: 0;
                                    flex-direction: column-reverse;
                                    justify-content: center;
                                    margin-top: 5px;
                                }
                            `}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>

                        {isMobile ? (
                            <Button variant='primary' onClick={onEdit}>
                                Edit
                            </Button>
                        ) : (
                            <Button
                                type='submit'
                                variant='primary'
                                disabled={isSubmitting || !isDirty || hasErrors(errors)}
                                dataTestId='share-my-specialists-bulk-edit-save'
                            >
                                Save
                            </Button>
                        )}
                    </div>
                </form>
            </BottomBar>

            {editModalOpened && (
                <BulkEditModal
                    opened
                    onClose={() => setEditModalOpened(false)}
                    onFormSubmit={onSubmit}
                    validationSchema={schema}
                    sharedOptions={sharedTypes}
                    syndicates={syndicates}
                    availableOnOptions={options}
                />
            )}
        </Fragment>
    )
}

export { BulkEditBar }
