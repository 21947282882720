/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

const Info: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                border-radius: 4px;
                background: ${theme.colors.gray_1};
                padding: 26px 32px;
            `}
        >
            {children}
        </div>
    )
}

export { Info }
