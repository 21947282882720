import styled from '@emotion/styled'

const Box = styled.div`
    background: ${p => p.theme.colors.white};
    border: 1px solid ${p => p.theme.colors.violet_2};
    border-radius: 2px;
    padding: 24px;
    margin-bottom: 24px;
`

export { Box }
