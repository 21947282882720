/** @jsxImportSource @emotion/react */

import { FunctionComponent, useCallback } from 'react'
import { useController } from 'react-hook-form'
import { Input, InputProps } from '../inputs/Input'
import { ControlledFieldProps } from './types'

export type ControlledInputProps = Omit<InputProps, 'onBlur' | 'onChange' | 'value' | 'errorMessage'> &
    ControlledFieldProps<string> & {
        beforeChange?: () => void
        externalError?: string
        beforeBlur?: () => void
        shouldUnregister?: boolean
    }

const ControlledInput: FunctionComponent<React.PropsWithChildren<ControlledInputProps>> = ({
    infoMessage,
    label,
    placeholder,
    type,
    hasPassword,
    disabled,
    style,
    inputStyle,
    labelTooltip,
    resetOnBlur,
    isActive,
    regex,
    dataTestId,
    defaultValue,
    name,
    control,
    beforeChange,
    externalError,
    tabIndex,
    autofocus,
    beforeBlur,
    prefix,
    shouldUnregister = true,
}) => {
    const {
        field: { onBlur, onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue: defaultValue || '',
        shouldUnregister,
    })

    const handleChange = useCallback(
        (newValue: string) => {
            if (beforeChange) {
                beforeChange()
            }
            onChange(newValue)
        },
        [beforeChange, onChange],
    )

    const handleBlur = useCallback(() => {
        if (beforeBlur) {
            beforeBlur()
        }
        onBlur()
    }, [onBlur, beforeBlur])

    return (
        <Input
            name={name}
            onBlur={handleBlur}
            onChange={handleChange}
            value={value}
            errorMessage={error?.message || externalError}
            errorTooltipMessage={error?.types?.tooltip as string}
            infoMessage={infoMessage}
            label={label}
            placeholder={placeholder}
            type={type}
            hasPassword={hasPassword}
            disabled={disabled}
            style={style}
            inputStyle={inputStyle}
            labelTooltip={labelTooltip}
            resetOnBlur={resetOnBlur}
            isActive={isActive}
            regex={regex}
            dataTestId={dataTestId}
            tabIndex={tabIndex}
            autofocus={autofocus}
            prefix={prefix}
        />
    )
}

export { ControlledInput }
