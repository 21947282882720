import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { FunctionComponent, useContext, useEffect, useMemo } from 'react'
import { getWhiteLabel, getWhiteLabelAnonymous, getWhiteLabelSpecialistSummary } from './api/api'
import { useRoutesMatching } from './hooks/useRoutesMatching'
import { useUserAuthentication } from './hooks/useUserAuthentication'
import { ReduxContext } from './redux/Store'
import { generateTheme, Theme } from './theme/theme'
import { getUserRole } from './utils/currentUser'
import { useLogger } from './utils/useLogger'

const ThemeProvider: FunctionComponent<React.PropsWithChildren<{}>> = ({ children }) => {
    const log = useLogger('error')

    const {
        actions: { whiteLabelSet, setShouldWaitForWhiteLabel },
        selectors: { whiteLabel, user, decodedAccessToken, userActiveContextId },
    } = useContext(ReduxContext)

    const { matchAnonymousProfile, matchProfileSummary, matchPublicProfile } = useRoutesMatching()

    const { isUserAuthorized } = useUserAuthentication()

    useEffect(() => {
        if (matchPublicProfile) {
            setShouldWaitForWhiteLabel(false)
        }
    }, [matchPublicProfile, setShouldWaitForWhiteLabel, userActiveContextId])

    useEffect(() => {
        if (!matchPublicProfile && !matchAnonymousProfile && !matchProfileSummary) {
            const savedWhiteLabel = localStorage.getItem('whiteLabel')
            if (savedWhiteLabel) {
                try {
                    whiteLabelSet(JSON.parse(savedWhiteLabel))
                } catch (e) {
                    log(e)
                }
            }
        }
    }, [whiteLabelSet, log, matchPublicProfile, matchAnonymousProfile, matchProfileSummary])

    useEffect(() => {
        const sharedLinkId = matchAnonymousProfile?.params?.linkId

        if (sharedLinkId) {
            setShouldWaitForWhiteLabel(true)
            getWhiteLabelAnonymous(sharedLinkId)
                .then(data => {
                    if (data) {
                        whiteLabelSet(data)
                    }
                })
                .catch(log)
                .finally(() => setShouldWaitForWhiteLabel(false))
        }
    }, [whiteLabelSet, setShouldWaitForWhiteLabel, log, userActiveContextId, matchAnonymousProfile])

    const specialistId = useMemo(
        () => matchProfileSummary?.params?.specialistId || decodedAccessToken?.specialistId,
        [decodedAccessToken?.specialistId, matchProfileSummary?.params?.specialistId],
    )

    useEffect(() => {
        if (specialistId && whiteLabel === null && isUserAuthorized) {
            setShouldWaitForWhiteLabel(true)
            getWhiteLabelSpecialistSummary(specialistId)
                .then(data => {
                    if (data) {
                        whiteLabelSet(data)
                    }
                })
                .catch(log)
                .finally(() => setShouldWaitForWhiteLabel(false))
        }
    }, [whiteLabelSet, setShouldWaitForWhiteLabel, log, whiteLabel, isUserAuthorized, specialistId])

    useEffect(() => {
        const role = getUserRole()

        if (
            user?.providerId &&
            role !== 'specialist' &&
            !matchPublicProfile &&
            !matchAnonymousProfile &&
            !matchProfileSummary &&
            userActiveContextId
        ) {
            setShouldWaitForWhiteLabel(true)
            getWhiteLabel()
                .then(data => {
                    if (data) {
                        whiteLabelSet(data)
                        localStorage.setItem('whiteLabel', JSON.stringify(data))
                    }
                })
                .catch(log)
                .finally(() => setShouldWaitForWhiteLabel(false))
        }
    }, [
        whiteLabelSet,
        user,
        setShouldWaitForWhiteLabel,
        log,
        userActiveContextId,
        matchPublicProfile,
        matchAnonymousProfile,
        matchProfileSummary,
    ])

    const theme = useMemo<Theme>(() => generateTheme(whiteLabel), [whiteLabel])

    return <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
}

export { ThemeProvider }
