/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { ChangeEvent, FunctionComponent, ReactNode, useEffect, useState } from 'react'
import { Theme } from '../../theme/theme'
import { Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { InputField, InputFieldProps } from './input-field/InputField'

export type InputProps = InputFieldProps & {
    name: string
    onChange?: (value: string) => void
    value?: string
    placeholder?: string
    type?: string
    hasPassword?: boolean
    disabled?: boolean
    style?: SerializedStyles
    inputStyle?: SerializedStyles
    innerRef?: any
    resetOnBlur?: boolean
    isActive?: boolean
    regex?: RegExp
    onBlur?: (val: string) => void
    dataTestId?: string
    tabIndex?: number
    autofocus?: boolean
    prefix?: ReactNode
}

const defaultInputStyle = (theme: Theme) => css`
    width: 100%;
    height: 40px;
    border: 1px solid ${theme.colors.gray_2};
    border-radius: 2px;
    padding: 0 16px;
    outline: none;
    color: ${theme.colors.gray_6};

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &[type='number'] {
        -moz-appearance: textfield;
    }

    &[disabled] {
        background-color: ${theme.colors.gray_1};
        color: ${theme.colors.gray_3};
    }

    &:focus {
        border-color: ${theme.colors.gray_3};
    }
    &::placeholder {
        color: ${theme.colors.gray_3};
    }
`

const Input: FunctionComponent<React.PropsWithChildren<InputProps>> = ({
    name,
    onChange,
    value = '',
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    label,
    labelTooltip,
    placeholder,
    type = 'text',
    hasPassword = false,
    disabled = false,
    style,
    inputStyle,
    innerRef,
    resetOnBlur = false,
    isActive,
    regex,
    onBlur,
    dataTestId,
    tabIndex = 0,
    autofocus = false,
    prefix,
}) => {
    const [valueState, setValueState] = useState(value)
    const [inputType, setInputType] = useState(type)
    const theme = useTheme()

    useEffect(() => {
        setValueState(value)
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (regex?.test(event.target.value) || !regex) {
            setValueState(event.target.value)
            if (onChange) {
                onChange(event.target.value)
            }
        }
    }

    const handleBlur = (event: any) => {
        if (resetOnBlur) {
            setValueState(value)
            if (onChange) {
                onChange(value)
            }
        } else if (onBlur) {
            onBlur(event.target.value)
        }
    }

    const controlProps = !innerRef
        ? {
              value: valueState,
              onChange: handleChange,
              onBlur: handleBlur,
          }
        : {}

    const togglePasswordVisibility = () => {
        if (inputType === 'password') {
            setInputType('text')
        } else {
            setInputType('password')
        }
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                position: relative;

                ${style}
            `}
        >
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                dataTestId={dataTestId || name}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
            >
                <FlexBox
                    css={
                        prefix
                            ? css`
                                  border: 1px solid ${theme.colors.gray_2};
                                  border-color: ${isActive ? theme.colors.gray_3 : errorMessage ? theme.colors.red_4 : theme.colors.gray_2};
                                  border-radius: 2px;
                                  align-items: stretch;
                                  overflow: hidden;
                              `
                            : undefined
                    }
                >
                    {prefix && (
                        <FlexBox
                            css={css`
                                background-color: ${theme.colors.gray_1};
                                color: ${theme.colors.gray_3};
                                align-items: center;
                                justify-content: center;
                                padding: 0 10px;
                            `}
                        >
                            {prefix}
                        </FlexBox>
                    )}
                    <input
                        css={css`
                            ${defaultInputStyle(theme)}
                            border-color: ${isActive ? theme.colors.gray_3 : errorMessage ? theme.colors.red_4 : theme.colors.gray_2};
                            ${inputStyle}
                            ${prefix ? 'border:none;' : ''}
                        `}
                        name={name}
                        placeholder={placeholder}
                        type={inputType}
                        disabled={disabled}
                        ref={innerRef}
                        data-testid={dataTestId || name}
                        tabIndex={tabIndex}
                        autoFocus={autofocus}
                        {...controlProps}
                    />
                    {hasPassword && (
                        <div
                            css={css`
                                cursor: pointer;
                                position: absolute;
                                top: 32px;
                                right: 16px;
                            `}
                            onClick={togglePasswordVisibility}
                        >
                            {inputType === 'password' && <Icon name='eye' />}
                            {inputType !== 'password' && <Icon name='eye-hide' />}
                        </div>
                    )}
                </FlexBox>
            </InputField>
        </div>
    )
}

export { Input, defaultInputStyle }
