/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Radio as RadioAntd } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import { FunctionComponent, ReactNode } from 'react'
import { InputField, InputFieldProps } from './input-field/InputField'

export type RadioGroupProps = InputFieldProps & {
    onChange?: (val: RadioChangeEvent) => void
    name?: string
    value?: string
    children: any
    styles?: SerializedStyles
    dataTestId?: string
}

const RadioGroup: FunctionComponent<React.PropsWithChildren<RadioGroupProps>> = ({
    name,
    value,
    onChange,
    children,
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    styles,
    dataTestId,
}) => {
    const theme = useTheme()
    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId || name}
        >
            <RadioAntd.Group
                name={name}
                value={value}
                onChange={onChange}
                css={css`
                    ${errorMessage
                        ? `
                            label.ant-radio-wrapper > span { 
                                color: ${theme.colors.red_4}; }
                            label.ant-radio-wrapper .ant-radio .ant-radio-inner { 
                                border-color: ${theme.colors.red_4}; 
                                
                                &::after {
                                    background-color: ${theme.colors.red_4}; 
                                }
                            }`
                        : null}
                    ${styles}
                `}
            >
                {children}
            </RadioAntd.Group>
        </InputField>
    )
}

type RadioProps = {
    label: ReactNode
    value: string
    onChange?: (val: RadioChangeEvent) => void
    disabled?: boolean
    style?: SerializedStyles
    checked?: boolean
    dataTestId?: string
}

const Radio: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({ onChange, label, value, disabled, style, dataTestId }) => {
    return (
        <RadioAntd
            onChange={onChange}
            value={value}
            disabled={disabled}
            css={css`
                display: flex;
                align-items: center;
                ${style}
            `}
            data-testid={dataTestId || label}
        >
            {label}
        </RadioAntd>
    )
}

const RadioButton: FunctionComponent<React.PropsWithChildren<RadioProps>> = ({ onChange, label, value, disabled, style, dataTestId }) => (
    <RadioAntd.Button onChange={onChange} value={value} disabled={disabled} css={style} data-testid={dataTestId || label}>
        {label}
    </RadioAntd.Button>
)

export { RadioGroup, Radio, RadioButton }
