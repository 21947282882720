import { DropParserProps } from 'src/components/inputs/Dropzone'
import { ClientAccountFormData } from './components/ClientForm'
import { PartnerAccountFormData } from './components/PartnerForm'

const MIN_LOGO_SIZE = 50
const MAX_LOGO_SIZE = 180

const parseLogo = ({ acceptedFiles, onChange, onError }: DropParserProps) => {
    const file = acceptedFiles[0]
    if (file) {
        const reader = new FileReader()
        reader.onload = () => {
            const image = new Image()
            image.onload = () => {
                if ((image.width < MIN_LOGO_SIZE || image.height < MIN_LOGO_SIZE) && onError && file.type !== 'image/svg+xml') {
                    onError(new Error(`Provided image is too small. Width and height should have at least ${MIN_LOGO_SIZE}px`))
                } else if ((image.width > MAX_LOGO_SIZE && image.height > MAX_LOGO_SIZE) || file.type === 'image/svg+xml') {
                    const proportions = image.width / image.height
                    const width = Math.round(proportions * MAX_LOGO_SIZE)
                    const height = Math.round(width / proportions)
                    const canvas = document.createElement('canvas')
                    canvas.width = width
                    canvas.height = height
                    const context = canvas.getContext('2d')
                    if (context) {
                        context.drawImage(image, 0, 0, width, height)
                        const mime = 'image/png'
                        context.canvas.toBlob(
                            blob => {
                                if (blob) {
                                    onChange(
                                        new File([blob], 'logo.png', {
                                            type: mime,
                                            lastModified: Date.now(),
                                        }),
                                    )
                                } else {
                                    onChange(file)
                                }
                            },
                            mime,
                            1,
                        )
                    } else {
                        onChange(file)
                    }
                } else {
                    onChange(file)
                }
            }
            image.src = reader.result as string
        }
        reader.readAsDataURL(file)
    }
}

const parseToPartnerData = (companyData: PartnerAccountFormData & UserFormData): PartnerAccountFormData => {
    return {
        legalName: companyData.legalName,
        country: companyData.country,
        city: companyData.city,
        website: companyData.website,
        workEmailAddress: companyData.workEmailAddress,
        roleInTheCompany: companyData.roleInTheCompany,
        companySize: companyData.companySize,
        partnerClutchUrl: companyData.partnerClutchUrl,
        partnerSpecialistLocations: companyData.partnerSpecialistLocations,
        partnerCoreTechStack: companyData.partnerCoreTechStack,
        partnerTechStack: companyData.partnerTechStack,
        partnerIndustries: companyData.partnerIndustries,
    }
}

const parseToClientData = (companyData: ClientAccountFormData & UserFormData): ClientAccountFormData => {
    return {
        legalName: companyData.legalName,
        country: companyData.country,
        city: companyData.city,
        website: companyData.website,
        workEmailAddress: companyData.workEmailAddress,
        roleInTheCompany: companyData.roleInTheCompany,
        companySize: companyData.companySize,
        clientIndustries: companyData.clientIndustries,
        clientSizeOfITWorkForce: companyData.clientSizeOfITWorkForce,
    }
}

const parseCompanyDetails = (company: any) => JSON.parse(JSON.stringify(company, (key, value) => (value === null ? '' : value)))

export type UserFormData = {
    firstName: string
    lastName: string
    phone: string
}

export { parseLogo, parseToPartnerData, parseToClientData, parseCompanyDetails }
