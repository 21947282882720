/** @jsxImportSource @emotion/react */

import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'antd'

import editIcon from 'src/assets/icons/edit.svg'
import { getRejectionReasons } from 'src/api/open-opportunities/api'
import { ApplicationRejectionStatusEnum, ApplicationStatus, ApplicationStatusEnum } from 'src/contracts/opportunities-manager/contracts'
import { useApplicationStatusCellColors } from 'src/components/opportunities-manager/utils/useApplicationStatusCellColors'
import { Nullable } from 'src/types'
import { shouldDisplayContractRate } from 'src/utils/application'

import { useLogger } from 'src/utils/useLogger'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { useNotifications } from '../notification/NotificationProvider'
import { ContractRateDetails } from '../ContractRateDetails'
import { RejectionReason, StyledButton } from './ApplicationStatusesHistory.styles'
import { Status } from './Status'
import { RejectionReasons } from 'src/contracts/open-opportunities/contracts'
import { StatusNote } from './StatusNote'

type ApplicationStatusesHistoryProps = {
    statusHistory: Array<ApplicationStatus>
    recentStatus: ApplicationStatus
    toggleEditMode: () => void
    buttonIcon?: any
    buttonText?: string
    showButton?: boolean
    iconStyle?: SerializedStyles
    disabled?: boolean
    currency?: string
    contractRate?: Nullable<number>
    tooltip?: string
}

const formatRejectionReason = (values: RejectionReasons, recentStatus: ApplicationStatus) => {
    // The label of the recent status (e.g. Rejected by Talent Alpha) isn't the label of the rejection reason (e.g. Experience requirements not met)
    // So we need to find the label of the rejection reason in the list of rejection reasons
    // The rejection reason of the recent status is the same as the one of the rejection reason in the list of rejection reasons (e.g. EXPERIENCE)
    const rejectionReason = values.rejectionReasons[recentStatus.status as keyof typeof ApplicationRejectionStatusEnum]?.find(
        item => item.rejectionReason === recentStatus.rejectionReason,
    )?.label
    return `${rejectionReason ? `${rejectionReason}. ` : ''}${recentStatus.rejectionReasonComment || ''}`
}

const ApplicationStatusesHistory: FunctionComponent<React.PropsWithChildren<ApplicationStatusesHistoryProps>> = ({
    toggleEditMode,
    statusHistory,
    recentStatus,
    buttonIcon,
    buttonText,
    showButton = true,
    iconStyle,
    disabled,
    currency,
    contractRate,
    tooltip,
}) => {
    const colors = useApplicationStatusCellColors()
    const theme = useTheme()

    const count = useMemo(() => statusHistory.length, [statusHistory])

    const logError = useLogger('error')
    const { addError } = useNotifications()

    const [rejectionReason, setRejectionReason] = useState<Nullable<string>>(null)

    const loadRejectionReasons = useCallback(() => {
        if (recentStatus.rejectionReason) {
            if (recentStatus.rejectionReason === 'OTHER') {
                setRejectionReason(recentStatus.rejectionReasonComment || null)
            } else {
                getRejectionReasons()
                    .then(values => setRejectionReason(formatRejectionReason(values, recentStatus)))
                    .catch(e => {
                        addError()
                        logError(e)
                    })
            }
        } else if (recentStatus.status === ApplicationStatusEnum.ON_HOLD) {
            setRejectionReason('This Opportunity has received enough applications. Your application is On Hold for now.')
        }
    }, [addError, logError, recentStatus])

    useEffect(() => loadRejectionReasons(), [loadRejectionReasons])

    const contractRateArgs = { status: recentStatus.status, currency, contractRate }
    const showContractRate = shouldDisplayContractRate(contractRateArgs)
    const showStatusNote = useMemo(() => recentStatus.status !== 'OFFER_REJECTED', [recentStatus.status])

    return (
        <div>
            <FlexBox alignItems='center' justifyContent='space-between'>
                <div>Status:</div>
                {showButton && (
                    <StyledButton
                        variant='linkForm'
                        icon={buttonIcon || editIcon}
                        iconStyle={iconStyle}
                        onClick={toggleEditMode}
                        disabled={disabled}
                    >
                        {tooltip ? <Tooltip title={tooltip}>{buttonText || 'Edit'}</Tooltip> : buttonText || 'Edit'}
                    </StyledButton>
                )}
            </FlexBox>
            <FlexBox
                css={css`
                    flex-wrap: wrap;
                `}
            >
                {statusHistory.map((status, index) => (
                    <Status data-testid='status-history' key={index} colors={colors} count={count} index={index} status={status} />
                ))}
            </FlexBox>
            {rejectionReason && (
                <RejectionReason
                    backgroundColor={
                        recentStatus.status === ApplicationStatusEnum.WITHDRAWN || recentStatus.status === ApplicationStatusEnum.ON_HOLD
                            ? theme.colors.beige_2
                            : colors[recentStatus.status].backgroundColor
                    }
                    colors={colors}
                >
                    {rejectionReason}
                </RejectionReason>
            )}
            <p>
                {showContractRate && (
                    <ContractRateDetails
                        currency={contractRateArgs.currency}
                        contractRate={contractRateArgs.contractRate}
                        status={contractRateArgs.status}
                    />
                )}
                {showStatusNote && recentStatus.rejectionReasonComment && <StatusNote note={recentStatus.rejectionReasonComment} />}
            </p>
        </div>
    )
}
export { ApplicationStatusesHistory }
