/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useEffectOnce } from 'react-use'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { ControlledCheckbox } from '../../components/forms/ControlledCheckbox'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'
import { getSubscriptions, saveSubscriptions } from '../dashboard/api'
import { LegacyEmailSubscription } from '../dashboard/contracts'

const LegacyEmailNotifications = () => {
    const { removeAllToasts } = useToasts()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { addError, addSuccess } = useNotifications()
    const log = useLogger('error')
    const [data, setData] = useState<Array<LegacyEmailSubscription>>([])
    const {
        control,
        getValues,
        reset,
        formState: { isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
    })
    const [isDataFetched, setIsDataFetched] = useState(false)

    const setDefaultValues = useCallback(
        (subscriptions: Array<LegacyEmailSubscription>) => {
            const formValues: { [key: string]: boolean } = {}
            subscriptions.forEach((subscription: any) => {
                formValues[subscription.companyId] = subscription.notify === 'SUBSCRIBE'
            })
            reset(formValues)
        },
        [reset],
    )

    useEffectOnce(() => {
        layoutToggleLoader(true)
        getSubscriptions()
            .then(response => {
                setData(response.subscriptions)
                setDefaultValues(response.subscriptions)
                setIsDataFetched(true)
            })
            .catch(log)
            .finally(() => layoutToggleLoader(false))
    })

    const handleSave = useCallback(() => {
        const formValues: { [key: string]: boolean } = getValues()
        const dataToSave: Array<LegacyEmailSubscription> = Object.keys(formValues).map(companyId => ({
            companyId,
            notify: formValues[companyId] ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
        }))

        removeAllToasts()
        layoutToggleLoader(true)
        saveSubscriptions(dataToSave)
            .then(response => {
                setData(response.subscriptions)
                setDefaultValues(response.subscriptions)
                addSuccess('You have successfully unsubscribed from e-mail notifications')
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [addError, addSuccess, layoutToggleLoader, log, removeAllToasts, getValues, setDefaultValues])

    return (
        <SettingsWrapper>
            <h4>E-mail Notifications</h4>
            <Divider />
            <div
                css={css`
                    margin-bottom: 40px;
                `}
            >
                Please mark the checkbox below if you would like to receive notifications about activity on the platform related to your
                company. These emails will include the following categories: specialist bookings updates and reminders, applying to
                opportunities.
            </div>
            {isDataFetched && (
                <Fragment>
                    {data.map(subscription => (
                        <div
                            key={subscription.companyId}
                            css={css`
                                margin-bottom: 34px;
                            `}
                        >
                            <div
                                css={css`
                                    font-size: 20px;
                                    margin-bottom: 14px;
                                    font-weight: 500;
                                `}
                            >
                                {subscription.companyName}
                            </div>
                            <ControlledCheckbox
                                control={control}
                                checkboxLabel='I would like to receive e-mail notifications'
                                name={subscription.companyId}
                            />
                        </div>
                    ))}
                </Fragment>
            )}

            <Divider />
            <div>
                <Button
                    variant='link'
                    onClick={() => setDefaultValues(data)}
                    disabled={false}
                    css={css`
                        margin-right: 30px;
                    `}
                >
                    Cancel
                </Button>
                <Button variant='primary' onClick={handleSave} disabled={!isDirty}>
                    Save Preferences
                </Button>
            </div>
        </SettingsWrapper>
    )
}

export { LegacyEmailNotifications }
