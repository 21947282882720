import { CompanyAccessLevelGuard } from './components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from './components/CompanyStatusGuard'

const CompanyDetails = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/company-details/form' />
    </CompanyAccessLevelGuard>
)

export { CompanyDetails }
