/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ChangeEvent, Fragment, FunctionComponent, useCallback, useRef, useState } from 'react'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AntDropdown } from 'src/components/dropdown/AntDropdown'
import { IconButton } from 'src/components/Icon'
import { Modal } from 'src/components/Modal'
import { CVType } from '../contracts'
import { ButtonsWrapper, CancelButton, CVActionsWrapper, DownloadIcon, UploadIcon, VerifyIcon } from './styles'
import { Tooltip } from 'antd'

type CVActionsProps = {
    isEditing: boolean
    handleClick: () => void
    handleSubmit: () => void
    handleDownload: (key: CVType) => void
    handleUpload: (event: ChangeEvent<HTMLInputElement>) => void
    handleVerify: () => void
    hasAnonymizedCV: boolean
}

const CVActions: FunctionComponent<CVActionsProps> = ({
    isEditing,
    handleClick,
    handleSubmit,
    handleDownload,
    handleUpload,
    handleVerify,
    hasAnonymizedCV,
}) => {
    const fileInput = useRef<HTMLInputElement>(null)
    const openFileDialog = useCallback(() => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }, [])
    const [isModalVisible, setModalVisible] = useState(false)
    const toggleModal = useCallback(() => {
        if (hasAnonymizedCV) {
            setModalVisible(wasVisible => !wasVisible)
        }
    }, [hasAnonymizedCV])
    const onVerify = useCallback(() => {
        handleVerify()
        toggleModal()
    }, [handleVerify, toggleModal])

    return (
        <CVActionsWrapper>
            {isEditing ? (
                <Fragment>
                    <Button variant='text' onClick={handleClick}>
                        Cancel
                    </Button>
                    <Button variant='primary' onClick={handleSubmit} dataTestId='cv-management-inline-edit-save'>
                        Save
                    </Button>
                </Fragment>
            ) : (
                <Tooltip title='Edit'>
                    <div>
                        <IconButton name='edit' onClick={handleClick} dataTestId='cv-edit' />
                    </div>
                </Tooltip>
            )}
            <AntDropdown
                options={
                    hasAnonymizedCV
                        ? [
                              { title: 'Anonymized', key: CVType.Anonymized, dataTestId: CVType.Anonymized },
                              { title: 'Original file', key: CVType.Original, dataTestId: CVType.Original },
                          ]
                        : []
                }
                handleClick={handleDownload}
            >
                <Tooltip title='Download CV'>
                    <div>
                        <DownloadIcon
                            name='download'
                            dataTestId='cv-download'
                            {...(!hasAnonymizedCV && {
                                onClick: () => {
                                    handleDownload(CVType.Original)
                                },
                            })}
                        />
                    </div>
                </Tooltip>
            </AntDropdown>
            <Tooltip title='Upload Anonymized CV'>
                <div>
                    <UploadIcon name='upload' onClick={openFileDialog} />
                </div>
            </Tooltip>
            <Tooltip
                placement='topRight'
                title={hasAnonymizedCV ? 'Confirm Anonymized CV Visibility' : 'Please upload anonymized CV before verifying'}
            >
                <div>
                    <VerifyIcon name='verify' onClick={toggleModal} dataTestId='cv-verify' disabled={!hasAnonymizedCV} />
                </div>
            </Tooltip>
            <Modal onClose={toggleModal} opened={isModalVisible}>
                <h5>Are you sure about verifying this CV?</h5>
                <Divider />
                <ButtonsWrapper>
                    <CancelButton variant='link' onClick={toggleModal}>
                        Cancel
                    </CancelButton>
                    <Button dataTestId='cv-verify-save' onClick={onVerify} variant='primary'>
                        Save
                    </Button>
                </ButtonsWrapper>
            </Modal>
            <input
                type='file'
                accept='application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                ref={fileInput}
                onChange={handleUpload}
                data-testid='cv-upload'
                css={css`
                    display: none;
                `}
            />
        </CVActionsWrapper>
    )
}

export { CVActions }
