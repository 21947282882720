import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../../../GlobalStyle'

type SelectItemProps = {
    selected?: boolean
    multiple?: boolean
    autosize?: boolean
}

const checkboxActive = `data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='4.5' width='15' height='15' fill='white' stroke='%23ACACAC' /%3E%3C/svg%3E`

const checkboxSelected = `data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='3.5' y='4.5' width='15' height='15' fill='white' stroke='%2313100D' /%3E%3Crect x='4.5' y='5.5' width='13' height='13' fill='%2313100D' stroke='black' /%3E%3Cpath d='M7 12.6L9.4 15L15.4 9' stroke='white' /%3E%3C/svg%3E`

const SelectItem = styled.button<SelectItemProps>`
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    padding: 12px 10px 12px ${(props: SelectItemProps) => (props.multiple ? 42 : 18)}px;
    position: relative;
    cursor: pointer;
    color: ${COLOR_PALETTE.gray_6};
    background: none;
    outline: none;
    border: none;
    width: 100%;
    text-align: left;
    ${(props: SelectItemProps) =>
        !props.autosize &&
        css`
            height: 40px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        `}

    &:before {
        ${(props: SelectItemProps) => (props.multiple ? 'content: "";' : '')} // display only for multiple
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 18px;
        // prettier-ignore
        background-image: url("${(props: SelectItemProps) => (props.selected ? checkboxSelected : checkboxActive)}");
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        background-position: center center;
    }
    &:hover,
    &:focus {
        background-color: ${COLOR_PALETTE.gray_1};
    }
    &:empty {
        min-height: 40px;
    }
`

const SelectDivider = styled.div`
    background-color: ${COLOR_PALETTE.gray_2};
    height: 1px;
    margin: 0 12px;
`

export { SelectItem, SelectDivider }
