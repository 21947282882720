/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Switch } from '../../../../components/inputs/switch/Switch'
import { COLOR_PALETTE } from '../../../../GlobalStyle'

export type TextColorPickerProps = {
    checked?: boolean
    onChange?: (color: boolean) => void
    color: string
}

const TextColorPicker: FunctionComponent<React.PropsWithChildren<TextColorPickerProps>> = ({ checked, onChange, color }) => (
    <div
        css={css`
            display: flex;
            align-items: center;
            margin-bottom: 24px;
        `}
    >
        <Switch checked={checked} onChange={onChange} leftText='Light' rightText='Dark' />
        <div
            css={css`
                margin-left: 20px;
                padding: 9px 20px;
                color: ${checked ? COLOR_PALETTE.gray_6 : COLOR_PALETTE.white};
                background-color: ${color};
            `}
            data-testid='color-picker-example'
        >
            Example
        </div>
    </div>
)

export { TextColorPicker }
