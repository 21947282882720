/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import React, { useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ApplicationType, getAppPath } from '../../contracts/applications'
import { OpportunityStatus } from '../../contracts/opportunities'

import { rowButtonStyles } from '../open-opportunities/utils/style'
import { OpportunityResponseBody } from '../../contracts/opportunities-manager/contracts'
import { Button } from '../Button'
import { ReduxContext } from 'src/redux/Store'

const ApplyLink: React.FC<{
    opportunity: Omit<OpportunityResponseBody, 'internalDetails'>
    isPermittedToOpportunitiesEarlyAccess: boolean
    applicationType: ApplicationType
    openSubscriptionModal: () => void
}> = ({ opportunity, openSubscriptionModal, isPermittedToOpportunitiesEarlyAccess, applicationType = 'OPEN_OPPORTUNITIES' }) => {
    const getPopupContainer = useCallback((node: HTMLElement) => node.parentElement || node, [])

    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)

    return opportunity.opportunityStatus === OpportunityStatus.ON_HOLD ? (
        <Tooltip
            getPopupContainer={getPopupContainer}
            title='This Opportunity has received enough applications and is currently On Hold. You cannot apply to it now.'
        >
            <div
                css={css`
                    ${rowButtonStyles};
                    margin-bottom: 10px;
                    cursor: not-allowed;
                `}
            >
                <Button
                    style={css`
                        ${rowButtonStyles};
                        pointer-events: none;
                    `}
                    size='big'
                    dataTestId='open-opportunity-apply'
                    disabled
                >
                    On Hold
                </Button>
            </div>
        </Tooltip>
    ) : featureFlags.payments && !isPermittedToOpportunitiesEarlyAccess ? (
        <Button
            style={css`
                ${rowButtonStyles};
                margin-bottom: 10px;
            `}
            size='big'
            dataTestId='open-opportunity-apply'
            onClick={openSubscriptionModal}
        >
            Apply Now
        </Button>
    ) : (
        <Link
            to={`${getAppPath(applicationType)}/${opportunity.opportunityId}/application-wizard`}
            css={css`
                width: 100%;
            `}
        >
            <Button
                style={css`
                    ${rowButtonStyles};
                    margin-bottom: 10px;
                `}
                size='big'
                dataTestId='open-opportunity-apply'
            >
                Apply Now
            </Button>
        </Link>
    )
}

export { ApplyLink }
