/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { Nullable } from '../../../types'
import { GameBoard } from './GameBoard'

const DEFAULT_MATRIX = [
    [
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
    ],
    [
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
    ],
    [
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
    ],
    [
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
        { isClickable: false, isSelected: false },
    ],
]

type GamePlayProps = {
    animation?: boolean
    width?: number
    height?: number
    boxSize?: number
    padding?: number
    isResultCorrect?: boolean
    defaultMatrix?: any
}

const GamePlay: FunctionComponent<React.PropsWithChildren<GamePlayProps>> = ({
    animation = true,
    width = 138,
    height = 165,
    boxSize = 27,
    padding = 5,
    isResultCorrect = false,
    defaultMatrix = DEFAULT_MATRIX,
}) => {
    const interval = useRef<Nullable<any>>(null)
    const [step, setStep] = useState(1)
    const [isCorrect, setIsCorrect] = useState(false)
    const [matrix, setMatrix] = useState(defaultMatrix)

    useEffect(() => {
        if (step < 5) {
            interval.current = setInterval(
                () => {
                    switch (step) {
                        case 1:
                            matrix[3][1] = { isClickable: true, isSelected: true }
                            setMatrix(matrix)
                            setStep(2)
                            break

                        case 2:
                            matrix[2][2] = { isClickable: true, isSelected: true }
                            setMatrix(matrix)
                            setStep(3)
                            break

                        case 3:
                            matrix[1][2] = { isClickable: true, isSelected: true }
                            setMatrix(matrix)
                            setStep(4)
                            break

                        case 4:
                            matrix[0][3] = { isClickable: true, isSelected: true }
                            setMatrix(matrix)
                            setStep(5)
                            break
                    }
                },
                animation ? 800 : 1,
            )
        } else {
            setIsCorrect(isResultCorrect)
        }

        return () => clearInterval(interval.current)
    }, [step, matrix, animation, isResultCorrect])

    return (
        <div
            css={css`
                width: ${width + 'px'};
                height: ${height + 'px'};
                margin: 0 auto;
            `}
        >
            <GameBoard
                matrix={matrix}
                isVisible={true}
                padding={padding + 'px'}
                boxSize={boxSize + 'px'}
                handleClick={() => null}
                isCorrect={isCorrect}
            />
        </div>
    )
}

export { GamePlay }
