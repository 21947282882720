import styled from '@emotion/styled'

import { Button } from 'src/components/Button'

const DownloadAnonymizedCV = styled(Button)`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.violet_5};
    padding-left: 0;
`

const AnonymizedCVUploadDate = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray_3};
`

export { DownloadAnonymizedCV, AnonymizedCVUploadDate }
