/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { Col, Row } from 'antd'
import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import infoMarkImg from '../assets/icons/info-mark.svg'
import { SkillForm } from '../contracts/skillForm'
import { COLOR_PALETTE, mqMax } from '../GlobalStyle'
import { TestStatus } from '../pages/skills-test/tests'
import { useIsMobile } from '../utils/hooks'
import { randomString } from '../utils/strings'
import { BackLink } from './BackLink'
import { Divider } from './Divider'
import { FormTile } from './FormTile'

const Paragraph = styled.div`
    color: ${COLOR_PALETTE.gray_6};
`

const GreyParagraph = styled.p`
    color: ${COLOR_PALETTE.gray_5};
`

export type TilesViewFormType = SkillForm & {
    status?: TestStatus
}

type TilesViewProps = {
    forms: Array<TilesViewFormType>
    onFillOutClick: (link: string, openInIframe: boolean) => void
    title: string
    description: ReactNode
    sideImage: string
    sideTitle: string
    sideDescription: Array<{ content: string; css?: SerializedStyles }>
    completedDescription: ReactNode
    completedHint: string
    tileStyles?: {
        notCompletedIconStyles?: SerializedStyles
        completedIconStyles?: SerializedStyles
    }
}

const TilesView: FunctionComponent<React.PropsWithChildren<TilesViewProps>> = ({
    forms,
    onFillOutClick,
    title,
    description,
    sideImage,
    sideTitle,
    sideDescription,
    completedDescription,
    completedHint,
    tileStyles = {},
}) => {
    const [isAnyFormCompleted, setIsAnyFormCompleted] = useState<boolean>(false)

    useEffect(() => {
        if (forms) {
            setIsAnyFormCompleted(forms.some(form => form.isCompleted))
        }
    }, [forms])

    const isMobile = useIsMobile()

    return (
        <div
            css={css`
                max-width: 1160px;
                width: 100%;
                margin: 0 auto;
                padding: 24px;
                ${mqMax[1]} {
                    margin-top: 57px;
                }
            `}
        >
            <BackLink
                style={css`
                    margin: 40px 0px 24px 0;
                `}
                text='Back to dashboard'
                path={`/specialist-dashboard`}
                dataTestId='back-to-dashboard-button'
            />

            <h4
                css={css`
                    margin-top: 24px;
                    color: ${COLOR_PALETTE.gray_6};
                `}
            >
                {title}
            </h4>
            {description}
            <Divider />
            <Row gutter={40}>
                <Col span={isMobile ? 24 : 16}>
                    {isAnyFormCompleted && (
                        <div
                            css={css`
                                background: ${COLOR_PALETTE.blue_2};
                                border-radius: 4px;
                                padding: 24px 32px;
                                display: flex;
                                margin-bottom: 24px;

                                ${mqMax[0]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <img
                                alt='Info mark'
                                src={infoMarkImg}
                                css={css`
                                    ${mqMax[0]} {
                                        width: 40px;
                                        height: 40px;
                                    }
                                `}
                            ></img>
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    margin-left: 24px;

                                    ${mqMax[0]} {
                                        margin-left: 0;
                                        margin-top: 16px;
                                    }
                                `}
                            >
                                <Paragraph>{completedDescription}</Paragraph>
                                <Paragraph
                                    css={css`
                                        font-weight: 500;
                                    `}
                                >
                                    <p>{completedHint}</p>
                                </Paragraph>
                            </div>
                        </div>
                    )}
                    <Row gutter={[20, 20]} wrap>
                        {forms.map(form => (
                            <FormTile tileStyles={tileStyles} key={randomString()} form={form} onFillOutClick={onFillOutClick} />
                        ))}
                    </Row>
                </Col>
                <Col span={isMobile ? 24 : 8}>
                    <img
                        alt=''
                        src={sideImage}
                        css={css`
                            margin: 16px 0;
                            max-width: 100%;
                            ${mqMax[1]} {
                                width: 100%;
                                margin: 24px;
                            }
                        `}
                    ></img>
                    <div>
                        <h6
                            css={css`
                                color: ${COLOR_PALETTE.gray_6};
                            `}
                        >
                            {sideTitle}
                        </h6>
                        {sideDescription.map(item => (
                            <GreyParagraph css={item.css || undefined} key={randomString()}>
                                {item.content}
                            </GreyParagraph>
                        ))}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export { TilesView }
