/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Popover } from 'antd'
import { FunctionComponent, useCallback } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import { Input } from './Input'
import { InputField, InputFieldProps } from './input-field/InputField'

export type ColorPickerProps = InputFieldProps & {
    value?: string
    onChange?: (color: string) => void
}

const ColorPicker: FunctionComponent<React.PropsWithChildren<ColorPickerProps>> = ({
    value,
    onChange,
    label = 'Choose color:',
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    dataTestId,
}) => {
    const theme = useTheme()
    const handleColorChange = useCallback((c: string) => onChange && /^#[0-9A-F]{6}$/i.test(c) && onChange(c), [onChange])
    const handleColorPick = useCallback((c: ColorResult) => onChange && onChange(c.hex), [onChange])
    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId}
        >
            <div
                css={css`
                    display: flex;
                    width: 150px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        width: 110px;
                    `}
                >
                    <Input
                        name='color'
                        value={value?.toUpperCase()}
                        onChange={handleColorChange}
                        resetOnBlur
                        inputStyle={css`
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                            ${errorMessage ? `border-color: ${theme.colors.red_4}` : ''}
                        `}
                    />
                </div>
                <Popover
                    content={<ChromePicker disableAlpha color={value} onChange={handleColorPick} />}
                    trigger='click'
                    placement='topRight'
                >
                    <div
                        css={css`
                            width: 40px;
                            height: 40px;
                            background-color: ${value};
                            border: 1px solid ${errorMessage ? theme.colors.red_4 : theme.colors.gray_2};
                            border-left: none;
                            border-top-right-radius: 2px;
                            border-bottom-right-radius: 2px;
                        `}
                    ></div>
                </Popover>
            </div>
        </InputField>
    )
}

export { ColorPicker }
