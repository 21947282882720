/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { IconButton } from 'src/components/Icon'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { mqMax } from 'src/GlobalStyle'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { MoreActionsDropdown } from './MoreActionsDropdown'

type ProfileActionsRendererProps = {
    id: string
    sharedStatus: string
    handleKeysChange: (id: string) => void
    onEditInMySpecialists: (id: string) => void
    currentBookingRequestId?: string
    setBookNowResponseModalOpen: Dispatch<SetStateAction<boolean>>
    setBookNowResponseModalData: Dispatch<
        SetStateAction<{
            specialistId: string
            bookingId: string
        }>
    >
}

type ProfileActionsEditRendererProps = {
    onSubmit: (id: string) => void
    watch: any
    index: number
    record: ShareMySpecialistsSpecialist
    handleKeysChange: (id: string) => void
}

const ProfileActionsRenderer: FunctionComponent<React.PropsWithChildren<ProfileActionsRendererProps>> = ({
    id,
    handleKeysChange,
    onEditInMySpecialists,
    sharedStatus,
    currentBookingRequestId,
    setBookNowResponseModalOpen,
    setBookNowResponseModalData,
}) => {
    const handleClick = useCallback(() => {
        handleKeysChange(id)
    }, [handleKeysChange, id])

    const handleProjectModalOpen = useCallback(() => {
        if (currentBookingRequestId) {
            setBookNowResponseModalData({ specialistId: id, bookingId: currentBookingRequestId })
            setBookNowResponseModalOpen(true)
        }
    }, [setBookNowResponseModalOpen, currentBookingRequestId, id, setBookNowResponseModalData])

    return (
        <Fragment>
            <div
                css={css`
                    justify-content: center;
                    min-height: 40px;
                    min-width: 140px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    ${mqMax[2]} {
                        margin-top: 15px;
                    }
                `}
            >
                <FlexBox>
                    <div
                        css={css`
                            margin-right: 3px;
                        `}
                    >
                        {sharedStatus === 'NOT_SHARED' || sharedStatus === 'SHARED' ? (
                            <Tooltip placement='topRight' title='Share/Hide'>
                                <div>
                                    <IconButton
                                        onClick={handleClick}
                                        name='edit'
                                        variant='standard'
                                        dataTestId='share-my-specialists-inline-edit'
                                    />
                                </div>
                            </Tooltip>
                        ) : null}
                    </div>
                    <div
                        css={css`
                            margin-right: 3px;
                        `}
                    >
                        {sharedStatus === 'BOOKING_REQUEST_RECEIVED' && (
                            <Tooltip placement='topRight' title='View Offer'>
                                <div>
                                    <IconButton name='mail' variant='standard' onClick={handleProjectModalOpen} />
                                </div>
                            </Tooltip>
                        )}
                        {sharedStatus === 'BOOKING_REQUEST_ACCEPTED' && (
                            <Tooltip placement='topRight' title='Share Feedback'>
                                <div>
                                    <Link to={`/my-specialists/share/feedback?bookingId=${currentBookingRequestId}&specialistId=${id}`}>
                                        <IconButton name='feedback' variant='standard' />
                                    </Link>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                    <MoreActionsDropdown recordId={id} onEditInMySpecialistsClick={onEditInMySpecialists} />
                </FlexBox>
            </div>
        </Fragment>
    )
}

const ProfileActionsEditRenderer: FunctionComponent<React.PropsWithChildren<ProfileActionsEditRendererProps>> = ({
    record,
    handleKeysChange,
    onSubmit,
    watch,
    index,
}) => {
    const handleClick = useCallback(() => {
        handleKeysChange(record.id)
    }, [handleKeysChange, record.id])

    const handleSubmit = useCallback(() => {
        onSubmit(record.id)
    }, [record.id, onSubmit])

    const isDirty =
        Number(watch(`specialists.${index}.rate`)) !== (record?.rate || '') ||
        watch(`specialists.${index}.availableFrom`) !== (record?.availableFrom || null) ||
        record?.availableOn.sort().toString() !== watch(`specialists.${index}.availableOn`).sort().toString() ||
        watch(`specialists.${index}.status`) !== (record?.sharedStatus || undefined) ||
        watch(`specialists.${index}.country`) !== (record?.country || '') ||
        watch(`specialists.${index}.currency`) !== (record?.currency || '')

    return (
        <div
            css={css`
                display: flex;
                ${mqMax[2]} {
                    margin-top: 18px;
                    justify-content: flex-end;
                }
            `}
        >
            <Button variant='text' onClick={handleClick}>
                Cancel
            </Button>
            <Button variant='primary' onClick={handleSubmit} disabled={!isDirty} dataTestId='share-my-specialists-inline-edit-save'>
                Save
            </Button>
        </div>
    )
}

export { ProfileActionsRenderer, ProfileActionsEditRenderer }
