import { ApplicationMenuItemEntry } from '../../components/layout/dashboard/ApplicationMenu'
import { getAppPath } from '../../contracts/applications'

const applicationName = 'Skill Discovery'

const generateMenuItems = (specialistId: string): Array<ApplicationMenuItemEntry> => [
    {
        path: getAppPath('SKILLS_DISCOVERY', specialistId),
        title: 'Skill Discovery',
    },
]

export { generateMenuItems, applicationName }
