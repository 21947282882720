import { MouseEvent, useCallback, useMemo, useState } from 'react'
import { usePromise } from 'react-use'
import { useNotifications } from '../../notification/NotificationProvider'
import { getAppPath } from '../../../contracts/applications'
import { OpportunityStatus } from '../../../contracts/opportunities'
import { history } from '../../../history'
import { useLogger } from '../../../utils/useLogger'
import { duplicateOpportunity, updateOpportunityStatus } from '../../../api/opportunities-manager/api'
import { ShareOpportunityModal } from '../../opportunities/ShareOpportunityModal'

type UseOpportunitiesActionsProps = {
    opportunityId: string
    status: OpportunityStatus | undefined
    onStatusChangeSuccessCallback?: (opportunityId: string, status: OpportunityStatus) => void
    toggle?: (e: MouseEvent) => void
    opportunityTitle?: string
    isSmeCloudApp?: boolean
    isVMCCloudApp?: boolean
}

const useOpportunitiesActions = ({
    opportunityId,
    status,
    onStatusChangeSuccessCallback,
    toggle,
    opportunityTitle,
    isSmeCloudApp = false,
    isVMCCloudApp = false,
}: UseOpportunitiesActionsProps) => {
    const isMounted = usePromise()
    const { addSuccess, addError } = useNotifications()
    const [shareModalOpen, setShareModalOpen] = useState<boolean>(false)
    const log = useLogger('error')

    const appPath = useMemo(
        () => (isSmeCloudApp ? getAppPath('OPPORTUNITIES_MANAGER_SME') : getAppPath('OPPORTUNITIES_MANAGER')),
        [isSmeCloudApp],
    )

    const onActionClick = useCallback(() => {
        history.push(
            `${appPath}/opportunities/${opportunityId}/edit?prevPath=${encodeURIComponent(
                window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
            )}`,
        )
    }, [appPath, opportunityId])

    const onStatusChange = useCallback(
        (newStatus: OpportunityStatus) => (e: MouseEvent) => {
            e.stopPropagation()
            if (toggle) {
                toggle(e)
            }

            isMounted(updateOpportunityStatus(opportunityId, { status: newStatus }))
                .then(response => {
                    addSuccess('You have successfully changed status to ' + newStatus)
                    if (onStatusChangeSuccessCallback) {
                        onStatusChangeSuccessCallback(response.opportunityId, response.opportunityStatus)
                    }
                })
                .catch(err => {
                    if (status === OpportunityStatus.DRAFT && err.status === 400) {
                        addError(
                            `Opportunity ${opportunityTitle} is missing some key information. Complete all required fields to publish your Opportunity.`,
                            { actionText: 'Edit Opportunity', onActionClick },
                        )
                    } else {
                        log(err)
                        addError()
                    }
                })
        },
        [
            toggle,
            status,
            isMounted,
            opportunityId,
            addSuccess,
            onStatusChangeSuccessCallback,
            addError,
            opportunityTitle,
            onActionClick,
            log,
        ],
    )

    const onToggleStatus = useMemo(
        () => onStatusChange(status === OpportunityStatus.PUBLISHED ? OpportunityStatus.CLOSED : OpportunityStatus.PUBLISHED),
        [onStatusChange, status],
    )

    const onShare = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (toggle) {
                toggle(e)
            }
            setShareModalOpen(true)
        },
        [toggle],
    )

    const onShareModalClose = useCallback(() => {
        setShareModalOpen(false)
    }, [])

    const onEdit = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (toggle) {
                toggle(e)
            }
            history.push(
                `${appPath}/opportunities/${opportunityId}/edit?prevPath=${encodeURIComponent(
                    window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
                )}`,
            )
        },
        [appPath, opportunityId, toggle],
    )

    const onViewDetails = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (toggle) {
                toggle(e)
            }
            history.push(
                `${appPath}/opportunities/${opportunityId}?prevPath=${encodeURIComponent(
                    window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
                )}`,
            )
        },
        [appPath, opportunityId, toggle],
    )

    const onDuplicate = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (toggle) {
                toggle(e)
            }
            if (opportunityId) {
                isMounted(duplicateOpportunity(opportunityId))
                    .then(duplicatedOpportunity => {
                        addSuccess('You successfully duplicated Opportunity')
                        history.push(
                            `${appPath}/opportunities/${duplicatedOpportunity.opportunityId}/edit?prevPath=${encodeURIComponent(
                                window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
                            )}`,
                        )
                    })
                    .catch(err => {
                        addError()
                        log(err)
                    })
            }
        },
        [addError, addSuccess, appPath, isMounted, log, opportunityId, toggle],
    )

    const onHold = useMemo(() => onStatusChange(OpportunityStatus.ON_HOLD), [onStatusChange])

    const onClose = useMemo(() => onStatusChange(OpportunityStatus.CLOSED), [onStatusChange])

    const ShareModal = useMemo(
        () =>
            shareModalOpen ? (
                <ShareOpportunityModal
                    opened={shareModalOpen}
                    onClose={onShareModalClose}
                    opportunityId={opportunityId}
                    isVMCCloudApp={isVMCCloudApp}
                />
            ) : null,

        [onShareModalClose, opportunityId, shareModalOpen, isVMCCloudApp],
    )

    return { onToggleStatus, onShare, onEdit, onViewDetails, onDuplicate, onHold, onClose, ShareModal }
}
export { useOpportunitiesActions }
