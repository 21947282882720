/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react'
import { Dispatch, FunctionComponent, MutableRefObject, SetStateAction, useCallback } from 'react'
import { Button } from '../../components/Button'
import { IconButton } from '../../components/Icon'
import { DropdownComponent } from '../../components/inputs/autocomplete-select/DropdownComponent'

type ApplicationCvDropdownProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    handleReplaceCvFile: () => void
}
const ApplicationCv: FunctionComponent<React.PropsWithChildren<ApplicationCvDropdownProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    handleReplaceCvFile,
}) => {
    const theme = useTheme()

    const toggleOpen = useCallback(() => {
        setIsOpen(wasOpen => !wasOpen)
    }, [setIsOpen])

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                justify-content: flex-end;
            `}
            ref={rangeRef}
        >
            <IconButton name='more' onClick={toggleOpen} />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        top: -50px;
                        background-color: ${theme.colors.white};
                        border: 1px solid ${theme.colors.gray_2};
                        border-radius: 2px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-around;
                            flex-direction: column;
                            width: 105px;
                            padding: 8px 16px;
                        `}
                    >
                        <Button onClick={handleReplaceCvFile} variant='link'>
                            Replace file
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

const ApplicationCvDropdown = DropdownComponent(ApplicationCv)

export { ApplicationCvDropdown }
