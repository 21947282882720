/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { FormType } from '../contracts'
import { Paragraph } from './Paragraph'

type ThankYouScreenProps = {
    handleFinish: () => void
    handleViewProfileClick: () => void
    type?: FormType
}

const ThankYouScreen: FunctionComponent<React.PropsWithChildren<ThankYouScreenProps>> = ({
    handleFinish,
    handleViewProfileClick,
    type,
}) => (
    <section
        css={css`
            max-width: 576px;
            margin-top: 20px;
        `}
    >
        {type === 'SKILL' && (
            <Paragraph>
                Thank you for completing skill form! All rated skills are now visible on your profile page. You can also edit and add more
                skills.
            </Paragraph>
        )}

        {type === 'STRENGTH' && (
            <Paragraph>Thank you for your time and responses. You can find the results on your Profile page.</Paragraph>
        )}

        <div
            css={css`
                margin-top: 60px;
                display: flex;
                flex-direction: row;
            `}
        >
            <Button onClick={handleFinish}>Go to dashboard</Button>

            <Button
                css={css`
                    margin-left: 20px;
                `}
                onClick={handleViewProfileClick}
                variant='tertiary'
                dataTestId='view-profile'
            >
                View your profile
            </Button>
        </div>
    </section>
)

export { ThankYouScreen }
