/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackLink } from '../../../components/BackLink'
import { Button } from '../../../components/Button'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Spinner } from '../../../components/layout/Loader'
import { PageWidthWrapper } from '../../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../../components/layout/SpecialistPageWrapper'
import { LayoutWithStickyColumn } from '../../../components/layout/LayoutWithStickyColumn'
import { mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { Nullable, StringMapping } from '../../../types'
import { BottomBar } from 'src/components/BottomBar'
import { FormDataModel, FormType } from '../contracts'
import { InstructionBox } from './InstructionBox'
import { SingleForm } from './SingleForm'
import { ThankYouScreen } from './ThankYouScreen'
import { WelcomeScreen } from './WelcomeScreen'

const scrollTop = () => {
    window.scrollTo(0, 0)
}

const backPath = '/specialist-dashboard'
const profilePath = '/profile-summary'

type FormViewProps = {
    data: Nullable<FormDataModel>
    handleChange: (data: any) => void
    handleSubmit: () => void
    handleStart: () => void
    activeSavesIndicator: Nullable<number>
    showThankYouScreen: boolean
    showWelcomeScreen: boolean
    showForm: boolean
    type?: FormType
    defaultLabels?: StringMapping
    isValid?: (state: boolean) => void
    isNextStepEnabled?: boolean
}

const FormView: FunctionComponent<React.PropsWithChildren<FormViewProps>> = ({
    data,
    handleChange,
    handleSubmit,
    handleStart,
    activeSavesIndicator,
    showThankYouScreen,
    showWelcomeScreen,
    showForm,
    type = 'SKILL',
    defaultLabels = null,
    isValid,
    isNextStepEnabled = true,
}) => {
    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const theme = useTheme()
    const navigate = useNavigate()

    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const handleNextPage = useCallback(() => {
        if (data && currentPageIndex < data.pages.length) {
            scrollTop()
            setCurrentPageIndex(currentPageIndex + 1)
        }
    }, [currentPageIndex, data])

    const handlePreviousPage = useCallback(() => {
        if (currentPageIndex > 0) {
            scrollTop()
            setCurrentPageIndex(currentPageIndex - 1)
        }
    }, [currentPageIndex])

    const lastPageIndex = useMemo(() => {
        return data ? data.pages.length - 1 : 0
    }, [data])

    const handleFinish = useCallback(() => {
        navigate(backPath)
    }, [navigate])

    const handleViewProfileClick = useCallback(() => {
        navigate(`${profilePath}/${decodedAccessToken.specialistId}`)
    }, [decodedAccessToken, navigate])

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;
                ${mqMax[2]} {
                    margin-bottom: 150px;
                }
            `}
        >
            <PageWidthWrapper>
                <BackLink
                    text='Back to dashboard'
                    path={backPath}
                    style={css`
                        margin-bottom: 24px;
                    `}
                />
                <h3>
                    {data ? data.name : ''} {showThankYouScreen && 'form completed'}
                </h3>
            </PageWidthWrapper>

            {data && (
                <Fragment>
                    {showWelcomeScreen && (
                        <PageWidthWrapper>
                            <WelcomeScreen handleStart={handleStart} type={type} />
                        </PageWidthWrapper>
                    )}

                    {showForm && (
                        <Fragment>
                            <LayoutWithStickyColumn
                                firstCol={
                                    <SingleForm
                                        handleChange={handleChange}
                                        data={data}
                                        currentPageIndex={currentPageIndex}
                                        type={type}
                                        defaultLabels={defaultLabels}
                                        isValid={isValid}
                                    />
                                }
                                secondCol={<InstructionBox type={type} />}
                            />

                            <BottomBar
                                containerStyles={css`
                                    z-index: 1;
                                    height: 60px;
                                `}
                            >
                                <LayoutWithStickyColumn
                                    firstCol={
                                        <FlexBox
                                            css={css`
                                                align-items: center;
                                                justify-content: flex-end;
                                            `}
                                        >
                                            {activeSavesIndicator !== null && (
                                                <div
                                                    css={css`
                                                        color: ${activeSavesIndicator > 0 ? 'transparent' : theme.colors.gray_3};
                                                        margin-right: 16px;
                                                        position: relative;
                                                        display: flex;
                                                        align-items: center;
                                                        justify-content: center;
                                                    `}
                                                >
                                                    All changes saved
                                                    {activeSavesIndicator > 0 && (
                                                        <div
                                                            css={css`
                                                                position: absolute;
                                                                display: flex;
                                                                align-items: center;
                                                                justify-content: center;
                                                            `}
                                                        >
                                                            <Spinner circleWidth={3} sizeInner={25} sizeOuter={30} />
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <div
                                                css={css`
                                                    display: flex;
                                                    justify-content: flex-end;
                                                `}
                                            >
                                                {currentPageIndex !== 0 && (
                                                    <Button
                                                        css={css`
                                                            margin-right: 20px;
                                                        `}
                                                        onClick={handlePreviousPage}
                                                        disabled={currentPageIndex === 0}
                                                        variant='tertiary'
                                                    >
                                                        Previous
                                                    </Button>
                                                )}

                                                {currentPageIndex !== lastPageIndex && (
                                                    <Button
                                                        css={css`
                                                            margin-right: ${currentPageIndex === lastPageIndex ? '20px' : '0px'};
                                                        `}
                                                        onClick={handleNextPage}
                                                        disabled={!isNextStepEnabled}
                                                        dataTestId='next-step'
                                                    >
                                                        Next
                                                    </Button>
                                                )}

                                                {currentPageIndex === lastPageIndex && (
                                                    <Button disabled={!isNextStepEnabled} onClick={handleSubmit} dataTestId='submit-form'>
                                                        Submit
                                                    </Button>
                                                )}
                                            </div>
                                        </FlexBox>
                                    }
                                />
                            </BottomBar>
                        </Fragment>
                    )}

                    {showThankYouScreen && (
                        <PageWidthWrapper>
                            <ThankYouScreen handleFinish={handleFinish} handleViewProfileClick={handleViewProfileClick} type={type} />
                        </PageWidthWrapper>
                    )}
                </Fragment>
            )}
        </SpecialistPageWrapper>
    )
}

export { FormView }
