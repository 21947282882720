/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useMemo, useState } from 'react'
import useCollapse from 'react-collapsed'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Icon } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { Nullable } from '../../../../types'
import { KeyInfo, KeyInfoResponse } from '../../contracts'
import { ToDoListItem } from './ToDoListItem'

type ToDoListProps = {
    specialistId: string
    isCvLoading: boolean
    handleCvUpload: () => void
    keyInfo: Nullable<KeyInfoResponse>
    setForceFocus: Dispatch<SetStateAction<string>>
    handleDismiss: () => void
}

const ToDoList: FunctionComponent<React.PropsWithChildren<ToDoListProps>> = ({
    specialistId,
    isCvLoading,
    handleCvUpload,
    keyInfo,
    setForceFocus,
    handleDismiss,
}) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])
    const navigate = useNavigate()

    const completedNumber = useMemo(() => (keyInfo ? keyInfo.sections.filter(section => section.completed).length : null), [keyInfo])

    const handleListItemClick = useCallback(
        (type: any) => {
            switch (type) {
                case KeyInfo.UPLOAD_CV: {
                    handleCvUpload()
                    break
                }
                case KeyInfo.LOCATION: {
                    setForceFocus(KeyInfo.LOCATION)
                    break
                }
                case KeyInfo.EXPERIENCE: {
                    setForceFocus(KeyInfo.EXPERIENCE)
                    break
                }
                case KeyInfo.EDUCATION: {
                    setForceFocus(KeyInfo.EDUCATION)
                    break
                }
                case KeyInfo.SKILLS: {
                    navigate(`/my-specialists/specialist/${specialistId}/skills-edit`)
                    break
                }
                default: {
                    break
                }
            }
        },
        [handleCvUpload, navigate, setForceFocus, specialistId],
    )

    return keyInfo ? (
        <div
            css={css`
                background-color: ${theme.colors.beige_1};
                border-radius: 4px;
                padding: 16px;
                margin-bottom: 24px;
            `}
        >
            <FlexBox
                {...getToggleProps({
                    alignItems: 'center',
                    onClick: toggle,
                    css: css`
                        font-weight: 500;
                        cursor: pointer;
                        padding: 16px;
                        margin: -16px;
                    `,
                })}
            >
                <div
                    css={css`
                        flex: 1;
                    `}
                >
                    Key information
                </div>
                <div
                    css={css`
                        padding: 6px;
                        font-size: 12px;
                        border-radius: 2px;
                        background-color: ${theme.colors.green_2};
                        line-height: 16px;
                        margin-right: 10px;
                    `}
                >
                    {completedNumber}/{keyInfo.sections.length}
                </div>
                <Icon name='arrow-down' />
            </FlexBox>
            <div {...getCollapseProps()}>
                {keyInfo.sections.filter(section => !section.completed).length === 0 ? (
                    <FlexBox
                        alignItems='center'
                        css={css`
                            flex-direction: column;
                            text-align: center;
                            margin-top: 24px;
                        `}
                    >
                        <Icon
                            name='check'
                            size={48}
                            style={css`
                                background-color: ${theme.colors.green_3};
                                padding: 12px;
                                border-radius: 50%;
                                margin-bottom: 16px;
                            `}
                        />
                        <h6>
                            Thank you!
                            <br />
                            You should be all set now.
                        </h6>
                        <div
                            css={css`
                                color: ${theme.colors.gray_4};
                                margin-bottom: 16px;
                            `}
                        >
                            You can now share this profile on Talent Marketplace or apply to existing open opportunities. Of course, adding
                            extra information about past projects and known skills can help the other side of the transaction to make their
                            decision easier.
                        </div>
                        <Button variant='linkForm' onClick={handleDismiss}>
                            Dismiss
                        </Button>
                    </FlexBox>
                ) : (
                    <Fragment>
                        <div
                            css={css`
                                margin-top: 16px;
                                color: ${theme.colors.gray_4};
                            `}
                        >
                            Providing all required information ensures that this profile has better visibility and is processed faster.
                        </div>
                        <Divider
                            style={css`
                                margin-bottom: -14px;
                            `}
                        />
                        <div
                            css={css`
                                background: linear-gradient(
                                    to right,
                                    transparent 23px,
                                    ${theme.colors.green_3} 23px,
                                    ${theme.colors.green_3} 24px,
                                    transparent 24px
                                );
                                background-size: 100% calc(100% - 62px);
                                background-repeat: no-repeat;
                                background-position: center;
                            `}
                        >
                            {keyInfo.sections.map(section => (
                                <ToDoListItem
                                    key={section.type}
                                    completed={section.completed}
                                    type={section.type}
                                    handleClick={handleListItemClick}
                                    showLoader={!section.completed && section.type === KeyInfo.UPLOAD_CV && isCvLoading}
                                />
                            ))}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    ) : null
}

export { ToDoList }
