/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { mqMin } from '../../GlobalStyle'
import back from '../../assets/icons/back.svg'
import { Button } from '../../components/Button'
import { Icon } from '../../components/Icon'
import { OpportunitySummary } from '../../components/opportunities/OpportunitySummary'
import { Nullable } from '../../types'
import { useIsMobile } from '../../utils/hooks'
import { OpportunitySummary as OpportunitySummaryType } from '../../contracts/opportunities-manager/contracts'
import { ApplicationData } from './ApplicationWizard/redux/types'

type OpportunitySummaryColumnProps = {
    opportunityId?: string
    opportunity?: Nullable<OpportunitySummaryType>
    applications?: Array<ApplicationData>
}

const OpportunitySummaryColumn: FunctionComponent<React.PropsWithChildren<OpportunitySummaryColumnProps>> = ({
    opportunityId,
    opportunity,
    applications,
}) => {
    const theme = useTheme()

    const [isOpportunitySummaryVisible, setIsOpportunitySummaryVisible] = useState(true)
    const toggleOpportunitySummary = useCallback(() => {
        setIsOpportunitySummaryVisible(wasVisible => !wasVisible)
    }, [])

    const isMobile = useIsMobile(2)

    return opportunityId && !isMobile ? (
        <Fragment>
            <div
                css={css`
                    border-left: 1px solid ${theme.colors.violet_2};
                    width: 300px;
                    background-color: ${theme.colors.beige_1};
                    padding: 24px;
                    overflow-y: scroll;
                    display: ${isOpportunitySummaryVisible ? 'block' : 'none'};
                    position: relative;
                    z-index: 1;

                    ${mqMin[3]} {
                        width: 350px;
                    }
                `}
            >
                <Button
                    variant='linkForm'
                    icon={back}
                    style={css`
                        flex-direction: row-reverse;
                    `}
                    iconStyle={css`
                        transform: translate(8px, 0) rotate(180deg);
                    `}
                    onClick={toggleOpportunitySummary}
                >
                    Hide
                </Button>
                <OpportunitySummary opportunityId={opportunityId} opportunity={opportunity} applicationsData={applications} />
            </div>
            <div
                css={css`
                    position: absolute;
                    top: 78px;
                    right: 0;
                    width: 38px;
                    height: 38px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: ${theme.colors.beige_3};
                    cursor: pointer;
                `}
                onClick={toggleOpportunitySummary}
            >
                <Icon name='back' />
            </div>
        </Fragment>
    ) : null
}

export { OpportunitySummaryColumn }
