/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useContext, useEffect } from 'react'
import { getUser } from '../api/api'
import { ReduxContext } from '../redux/Store'
import { useLogger } from '../utils/useLogger'
import { Modal } from './Modal'
import { PersonalDetailsForm } from './PersonalDetailsForm'

const PersonalDetailsModal: FunctionComponent<{ hasPendingInvites: boolean }> = ({ hasPendingInvites }) => {
    const theme = useTheme()
    const log = useLogger('error')
    const {
        selectors: { user, userPersonalDetails },
        actions: { userUpdatePersonalDetails },
    } = useContext(ReduxContext)

    useEffect(() => {
        const runEffect = async () => {
            try {
                if (user?.uid) {
                    const data = await getUser(user.firebaseId)
                    userUpdatePersonalDetails({ firstName: data.firstName, lastName: data.lastName, phone: data.phone })
                }
            } catch (e) {
                log(e)
            }
        }
        runEffect()
    }, [log, user, userUpdatePersonalDetails])

    return userPersonalDetails && !(userPersonalDetails.firstName && userPersonalDetails.lastName) ? (
        <Modal maskClosable={false} closeButton={false} opened={true}>
            <h5
                css={css`
                    text-align: center;
                `}
            >
                To start using Talent Alpha, please enter your contact information.
            </h5>
            <p
                css={css`
                    padding: 8px 16px;
                    text-align: center;
                    background-color: ${theme.colors.beige_2};
                `}
            >
                We'll use this info if we need to contact you about your specialists or Opportunities.
            </p>
            <PersonalDetailsForm isPersonalDetailsModal hasPendingInvites={hasPendingInvites} />
        </Modal>
    ) : null
}

export { PersonalDetailsModal }
