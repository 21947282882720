/** @jsxImportSource @emotion/react */
import { FunctionComponent, useEffect } from 'react'
import { signOut } from '../../api/firebase'
import { history } from '../../history'
import { useUserAuthentication } from '../../hooks/useUserAuthentication'

const LogoutSpecialist: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { logout } = useUserAuthentication()

    useEffect(() => {
        signOut()
            .then(logout)
            .then(() => {
                history.push(`/logout?role=specialist`)
            })
    }, [logout])

    return null
}

export { LogoutSpecialist }
