/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

type HeaderBoxProps = {
    isActive?: boolean
    isDisabled?: boolean
    onClick?: () => void
    style?: SerializedStyles
    dataTestId?: string
}

const HeaderBox: FunctionComponent<React.PropsWithChildren<HeaderBoxProps>> = ({
    children,
    isActive = false,
    isDisabled = false,
    onClick = () => null,
    style = {},
    dataTestId,
}) => {
    const theme = useTheme()

    return (
        <div
            onClick={onClick}
            css={css`
                height: 56px;
                padding: 0 8px;
                min-width: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                &:before {
                    content: '';
                    display: ${isActive && !isDisabled ? 'block' : 'none'};
                    position: absolute;
                    top: 0;
                    left: 8px;
                    right: 8px;
                    height: 4px;
                    background-color: ${theme.colors.main};
                }

                ${!isDisabled &&
                `
                @media(hover: hover) and (pointer: fine){
                &:hover {
                    cursor: ${isActive ? 'default' : 'pointer'};
                    &:before {
                        display: block;
                    }
                }}`}

                ${style}
            `}
            data-testid={dataTestId}
        >
            {children}
        </div>
    )
}

export { HeaderBox }
