/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { GreyParagraph } from '../shared/shared-styles'

type ContactedAtProps = {
    contactDate: string
    style?: SerializedStyles
}

const ContactedAt: FunctionComponent<React.PropsWithChildren<ContactedAtProps>> = ({ contactDate, style }) => (
    <FlexBox
        alignItems='center'
        css={css`
            width: 100%;
            padding: 20px 0 9px;
            ${style}
        `}
    >
        <Icon name='flag'></Icon>
        <GreyParagraph
            css={css`
                margin: 0 0 0 10px;
            `}
        >
            Last contact: {contactDate}
        </GreyParagraph>
    </FlexBox>
)

export { ContactedAt }
