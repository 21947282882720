/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useContext, useMemo } from 'react'
import { BlueActionBox } from '../../components/BlueActionBox'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { ContentLoader } from '../../components/layout/Loader'
import { ApplicationsWrapper } from '../../components/layout/ResponsiveWrapper'
import { app2TileGroups } from '../../contracts/applications'
import { mqMax } from '../../GlobalStyle'
import { history } from '../../history'
import { useUserPendingInvitations } from '../../hooks/useUserPendingInvitations'
import { useApplications } from '../../redux/dictionaryDataHooks'
import { Applications } from './applications/Applications'
import { ReduxContext } from 'src/redux/Store'
import { VMCstatusEnum } from 'src/contracts/vmc'

const Dashboard = () => {
    const applications = useApplications()
    const appTileGroups = useMemo(() => (applications ? app2TileGroups(applications) : null), [applications])
    const pendingInvitations = useUserPendingInvitations()

    const {
        selectors: { VMCstatus },
    } = useContext(ReduxContext)

    const isVMCUser = useMemo(() => VMCstatus !== VMCstatusEnum.NONE, [VMCstatus])

    return (
        <DashboardLayout>
            <ApplicationsWrapper>
                <h3
                    css={css`
                        ${mqMax[1]} {
                            margin-top: 75px;
                        }
                    `}
                    data-testid='dashboard-check'
                >
                    {isVMCUser ? 'Welcome to the Vendor Management Cloud' : 'Welcome to Talent Alpha Human Cloud'}
                </h3>
                <Divider />
                {!appTileGroups ? (
                    <ContentLoader />
                ) : (
                    <Fragment>
                        {pendingInvitations && pendingInvitations.length > 0 && (
                            <BlueActionBox
                                withInfoIcon
                                text={`You have ${pendingInvitations.length} pending invite${
                                    pendingInvitations.length > 1 ? 's' : ''
                                } to join company account`}
                                buttonText='View Invitations'
                                action={() => history.push('/pending-invitations')}
                                styles={css`
                                    margin-bottom: 24px;
                                `}
                            />
                        )}
                        <FlexBox
                            css={css`
                                flex-wrap: wrap;
                            `}
                        >
                            <Applications groups={appTileGroups} />
                        </FlexBox>
                    </Fragment>
                )}
            </ApplicationsWrapper>
        </DashboardLayout>
    )
}

export { Dashboard }
