import { useContext, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { ContextType } from '../../contracts/contexts'
import { history } from '../../history'
import { useUserContext } from '../../hooks/useUserContext'
import { ReduxContext } from '../../redux/Store'
import { uuidRegex } from '../../utils/regexes'
import { useQuery } from '../../utils/useQuery'

const REFETCH_CONTEXT_DEBOUNCE_TIME = 1000

const RefreshUserContext = () => {
    const {
        actions: { dictionaryDataSet },
    } = useContext(ReduxContext)
    const [wasUserContextsCleaned, setWasUserContextsCleaned] = useState(false)
    const [url, setUrl] = useState('')
    const query = useQuery()
    const { pathname } = useLocation()

    const { setActiveContextId, removeContextsData, userContexts } = useUserContext()

    useEffect(() => {
        if (url) {
            history.push(url)
        }
    }, [url])

    useEffect(() => {
        if (userContexts && !wasUserContextsCleaned) {
            removeContextsData()
            setWasUserContextsCleaned(true)
            dictionaryDataSet({ name: 'applications', data: null })
        }
    }, [removeContextsData, dictionaryDataSet, wasUserContextsCleaned, userContexts])

    useEffect(() => {
        if (wasUserContextsCleaned) {
            if (userContexts?.length) {
                const defaultContext =
                    userContexts?.find(context => context.type !== ContextType.USER)?.contextId || userContexts?.[0]?.contextId
                if (uuidRegex.test(query.get('wantedContextId') || '')) {
                    const wantedContextId = userContexts?.find(context => context.contextId === query.get('wantedContextId'))?.contextId
                    if (wantedContextId) {
                        setActiveContextId(wantedContextId)
                        let redirectUrl = query.get('afterSuccess') || '/dashboard'
                        if (!userContexts?.find(context => context.type !== ContextType.USER)) {
                            redirectUrl = 'dashboard/company-account/company-details'
                        }
                        setUrl(redirectUrl)
                    } else {
                        setActiveContextId(defaultContext)
                        history.push('/unavailable-context')
                    }
                } else if (defaultContext) {
                    setActiveContextId(defaultContext)
                    let redirectUrl = query.get('afterSuccess') || '/dashboard'
                    if (
                        !userContexts?.find(context => context.type !== ContextType.USER) &&
                        !matchPath<'prospectId', '/company-invitation/:prospectId'>('/company-invitation/:prospectId', pathname)
                    ) {
                        redirectUrl = 'dashboard/company-account/company-details'
                    }
                    history.push(redirectUrl)
                } else {
                    history.push('/unavailable-context')
                }
            } else if (userContexts?.length === 0) {
                setTimeout(() => removeContextsData(), REFETCH_CONTEXT_DEBOUNCE_TIME)
            }
        }
    }, [userContexts, setActiveContextId, query, removeContextsData, wasUserContextsCleaned, pathname])

    return null
}

export { RefreshUserContext }
