import { axios } from '../../../api/axios'
import { FeedbackQuestion, FeedbackQuestionType, PeerSkillAnswer, PeerSkills } from './contracts'

const feedbackQuestionEndpoint = `/v1/feedback`

const getFeedbackQuestions = (): Promise<Array<FeedbackQuestion>> => {
    return axios.get(feedbackQuestionEndpoint)
}

const saveFeedbackQuestion = (code: FeedbackQuestionType, answersIds: Array<string>): Promise<FeedbackQuestion> => {
    return axios.post(`${feedbackQuestionEndpoint}/${code}/evaluator/me`, { answersIds })
}

const getFeedbackQuestionsPeerPerformance = (): Promise<FeedbackQuestion> => {
    return axios.get(`${feedbackQuestionEndpoint}/PP/team/-/evaluator/me`)
}

const savePeerPerformance = (teamId: string, answersIds: Array<string>, assessedSpecialistId: string): Promise<FeedbackQuestion> => {
    return axios.post(`${feedbackQuestionEndpoint}/PP/team/${teamId}/evaluator/me`, { answersIds, assessedSpecialistId })
}

const getPeerSkills = (): Promise<PeerSkills> => {
    return axios.get(`${feedbackQuestionEndpoint}/team/-/evaluator/me/peer-skills/random`)
}

const submitPeerSkills = (teamId: string, assessedSpecialistId: string, answers: Array<PeerSkillAnswer>): Promise<any> => {
    return axios.post(`${feedbackQuestionEndpoint}/team/${teamId}/evaluator/me/peer-skills/${assessedSpecialistId}:submit`, { answers })
}

const rejectPeerSkills = (teamId: string, assessedSpecialistId: string): Promise<PeerSkills> => {
    return axios.post(`${feedbackQuestionEndpoint}/team/${teamId}/evaluator/me/peer-skills/${assessedSpecialistId}:reject`)
}

export {
    getFeedbackQuestions,
    getFeedbackQuestionsPeerPerformance,
    saveFeedbackQuestion,
    savePeerPerformance,
    getPeerSkills,
    submitPeerSkills,
    rejectPeerSkills,
}
