import { useCallback, useContext, useMemo } from 'react'
import { ReduxContext } from '../redux/Store'
import { Nullable } from '../types'
import { setUserActiveContextStorage } from '../utils/userContext'
import { ContextGroupName } from 'src/contracts/contexts'

const useUserContext = () => {
    const {
        actions: { setUserActiveContextId, setUserContexts },
        selectors: { userContexts, userActiveContextId, userActiveContextGroups },
    } = useContext(ReduxContext)

    const setActiveContextId = useCallback(
        (contextId: string) => {
            setUserActiveContextStorage(contextId)
            setUserActiveContextId(contextId)
        },
        [setUserActiveContextId],
    )

    const removeContextsData = useCallback(() => {
        setUserContexts(null)
        setUserActiveContextId(null)
    }, [setUserContexts, setUserActiveContextId])

    const activeContext = useMemo(
        () => userContexts?.find(context => context.contextId === userActiveContextId),
        [userActiveContextId, userContexts],
    )

    const isSuperAdmin: Nullable<boolean> = userActiveContextGroups
        ? userActiveContextGroups.some(group => group.name === 'COMPANY_SUPER_ADMIN')
        : null

    const isFunctionalManager: Nullable<boolean> = userActiveContextGroups
        ? userActiveContextGroups.some(group => group.name === ContextGroupName.FUNCTIONAL_MANAGER)
        : null

    return {
        userContexts,
        userActiveContextId,
        userActiveContextGroups,
        setActiveContextId,
        removeContextsData,
        activeContext,
        isSuperAdmin,
        isFunctionalManager,
    }
}

export { useUserContext }
