/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { RadioChangeEvent } from 'antd'
import { FunctionComponent, useCallback } from 'react'
import { RadioButton, RadioGroup } from '../../../components/inputs/Radio'
import { StrengthProficiency } from '../../../contracts/strengths'
import { mqMax } from '../../../GlobalStyle'
import { StringMapping } from '../../../types'

const defaultLabels: StringMapping = {
    [StrengthProficiency.STRONGLY_DISAGREE]: 'Strongly disagree',
    [StrengthProficiency.DISAGREE]: 'Disagree',
    [StrengthProficiency.NEUTRAL]: 'Neutral',
    [StrengthProficiency.AGREE]: 'Agree',
    [StrengthProficiency.STRONGLY_AGREE]: 'Strongly agree',
}

type StrengthQuestionnaireItemProps = {
    id: string
    value?: StrengthProficiency
    onChange: (skill: any) => void
    labels?: StringMapping
}

const mobileStylesDefinition = css`
    ${mqMax[1]} {
        flex: auto;
        padding: 12px;
        width: 100%;
        margin-bottom: 8px;
    }
`

const StrengthQuestionnaireItem: FunctionComponent<React.PropsWithChildren<StrengthQuestionnaireItemProps>> = ({
    id,
    onChange,
    value,
    labels = defaultLabels,
}) => {
    const handleChange = useCallback(
        (event: RadioChangeEvent) => {
            onChange({ answer: event.target.value, id })
        },
        [id, onChange],
    )

    return (
        <RadioGroup
            onChange={handleChange}
            value={value}
            styles={css`
                display: flex;
                justify-content: stretch;

                ${mqMax[1]} {
                    margin-top: 16px;
                    flex-direction: column;
                }

                .ant-radio-button-wrapper {
                    justify-content: center;
                }
            `}
        >
            <RadioButton
                style={css`
                    flex: 1;

                    ${mobileStylesDefinition}
                `}
                value={StrengthProficiency.STRONGLY_DISAGREE}
                label={labels[StrengthProficiency.STRONGLY_DISAGREE]}
                checked={value === StrengthProficiency.STRONGLY_DISAGREE}
            />

            <RadioButton
                style={css`
                    flex: 1;

                    ${mobileStylesDefinition}
                `}
                value={StrengthProficiency.DISAGREE}
                label={labels[StrengthProficiency.DISAGREE]}
                checked={value === StrengthProficiency.DISAGREE}
            />

            <RadioButton
                style={css`
                    flex: 1;

                    ${mobileStylesDefinition}
                `}
                value={StrengthProficiency.NEUTRAL}
                label={labels[StrengthProficiency.NEUTRAL]}
                checked={value === StrengthProficiency.NEUTRAL}
            />

            <RadioButton
                style={css`
                    flex: 1;

                    ${mobileStylesDefinition}
                `}
                value={StrengthProficiency.AGREE}
                label={labels[StrengthProficiency.AGREE]}
                checked={value === StrengthProficiency.AGREE}
            />

            <RadioButton
                style={css`
                    flex: 1;

                    ${mobileStylesDefinition}
                `}
                value={StrengthProficiency.STRONGLY_AGREE}
                label={labels[StrengthProficiency.STRONGLY_AGREE]}
                checked={value === StrengthProficiency.STRONGLY_AGREE}
            />
        </RadioGroup>
    )
}

export { StrengthQuestionnaireItem }
