/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { getFullProfileData } from '../../../api/api'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { Profile } from '../../../contracts/profile/profile'
import { mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { SpecialistProfile } from '../shared/SpecialistProfile'
import { parseProfile } from './utils'

const ProfileSummary = () => {
    const { specialistId } = useParams<{ specialistId: string }>()
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const navigate = useNavigate()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    useEffectOnce(() => {
        if (specialistId) {
            layoutToggleLoader(true)
            getFullProfileData(specialistId)
                .then(data => {
                    const { specialist, summary, projects, education, languages, techSkills, personalStrengths, verifiedTopSkills } = data
                    setProfile(
                        parseProfile(specialist, summary, projects, education, languages, techSkills, personalStrengths, verifiedTopSkills),
                    )
                })
                .catch(() => {
                    navigate('/page-not-found-public', { replace: true })
                })
                .finally(() => layoutToggleLoader(false))
        }
    })

    return (
        <DashboardLayout applicationName='My Dashboard'>
            {profile && (
                <div
                    css={css`
                        ${mqMax[1]} {
                            margin-top: 57px;
                        }
                    `}
                >
                    <SpecialistProfile specialistProfile={profile} isMySpecialists showLogoutButton />
                </div>
            )}
        </DashboardLayout>
    )
}

export { ProfileSummary }
