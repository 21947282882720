import memoize from 'lodash.memoize'
import { config } from '../environment/config'
import { State } from '../redux/Store'
import { Nullable } from '../types'
import { axios } from 'src/api/axios'

export type FeatureName =
    | 'whiteLabel'
    | 'privateHumanCloud'
    | 'everestWelcome'
    | 'opportunitiesManager'
    | 'notes'
    | 'continuousFeedback'
    | 'hasAccessToContinuousFeedback'
    | 'maintenance'
    | 'managerAiCvParsing'
    | 'newDropdownPoc'
    | 'specialistProfileRework'
    | 'opportunitiesKanban'
    | 'opportunityContact'
    | 'hideSpecialistLogin'
    | 'optionalSpecialistEmail'
    | 'shareCandidate'
    | 'talentMarketplaceMargin'
    | 'CVManagement'
    | 'opportunitySpecialistInvite'
    | 'payments'

export type FeatureStrategy = {
    name: 'default' | 'userWithId'
    parameters: any
    constraints?: Array<any>
}

export type Feature = {
    name: string
    type: string
    enabled: boolean
    stale: boolean
    strategies: Array<FeatureStrategy>
    variants: Array<any>
}

export type FeatureFlags = {
    [key in FeatureName]: boolean
}

type GetFeaturesResponse = {
    version: number
    features: Array<Feature>
}

const fetchFeatures = (): Promise<Nullable<GetFeaturesResponse>> => axios.get(`${config.apiUrl}/pub/featureToggles`)

const mapToUnleashEnv = (env?: string): string => {
    let unleashEnv

    switch (env) {
        case 'dev':
            unleashEnv = 'dev'
            break
        case 'stage':
            unleashEnv = 'stage'
            break
        case 'production':
            unleashEnv = 'prod'
            break
        default:
            unleashEnv = 'dev'
    }

    return unleashEnv
}

const withinConstraint = (constraint: any, params: any) => {
    const paramValue = params[constraint.contextName]
    const inValues = constraint.values.some((val: string) => val === paramValue)
    return constraint.operator === 'IN' ? inValues : !inValues
}

const checkDefaultStrategy = (strategy: FeatureStrategy, params: any = {}) => {
    if (strategy.constraints && strategy.constraints.length > 0) {
        return strategy.constraints.some(constraint => withinConstraint(constraint, params))
    } else {
        return true
    }
}

const checkUserWithIdStrategy = (strategy: FeatureStrategy, params: any = {}) => {
    if (
        strategy.constraints &&
        strategy.constraints.length > 0 &&
        !strategy.constraints.some(constraint => withinConstraint(constraint, params))
    ) {
        return false
    }

    if (strategy.parameters.userIds) {
        return strategy.parameters.userIds.split(',').some((userId: string) => userId === params.userId)
    } else {
        return true
    }
}

const getValueByStrategy = (strategies: Array<FeatureStrategy>, params: any) => {
    return strategies.some(strategy => {
        switch (strategy.name) {
            case 'userWithId': {
                return checkUserWithIdStrategy(strategy, params)
            }
            default: {
                return checkDefaultStrategy(strategy, params)
            }
        }
    })
}

const featureFlagsSelector = memoize((state: State): FeatureFlags => {
    const envPostfix = `_${mapToUnleashEnv(process.env.REACT_APP_ENV)}`
    const activeContext = state.userContexts?.find(context => context.contextId === state.userActiveContextId)
    const userId = activeContext?.companyId

    if (state.features) {
        return state.features
            .filter(feature => feature.name.includes(envPostfix))
            .map(feature => ({ ...feature, name: feature.name.replace(envPostfix, '') as FeatureName }))
            .map(feature => {
                let enabled = false

                if (feature.enabled) {
                    enabled = getValueByStrategy(feature.strategies, {
                        environment: mapToUnleashEnv(process.env.REACT_APP_ENV),
                        userId,
                    })
                }

                return { name: feature.name, value: enabled }
            })
            .reduce((acc, next) => {
                acc[next.name] = next.value
                return acc
            }, {} as FeatureFlags)
    } else {
        return {} as FeatureFlags
    }
})

export { featureFlagsSelector, fetchFeatures }
