/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { AccountPersonalDetails } from '../../account/AccountPersonalDetails'
import { MyAccountAccessLevelGuard } from './components/MyAccountAccessLevelGuard'

const PersonalDetails = () => (
    <MyAccountAccessLevelGuard>
        <DashboardLayout menuType={MenuType.myAccount}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                `}
            >
                <AccountPersonalDetails />
            </div>
        </DashboardLayout>
    </MyAccountAccessLevelGuard>
)

export { PersonalDetails }
