/** @jsxImportSource @emotion/react */

import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { FullProfileData, getFullProfileDataToExport, getSpecialistTechSkillsToExport } from '../../api/api'
import { FullExportSkills } from '../../contracts/skills'
import { useRoutesMatching } from '../../hooks/useRoutesMatching'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getLink } from '../profile/shared/helpers'
import { FullExport } from './components/FullExport/FullExport'
import { parseProjects } from './components/helpers'
import { PdfDoc } from './components/PdfDoc/PdfDoc'

const customGlobalStyles = `
    *[data-print-only] { opacity: 1 !important;}

    @page:first { margin: 0px 0 60px 0;} 

    *[data-avoid-break] {
        page-break-inside: avoid !important;
    }
`

type FullProps = {
    specialistId?: string
    forceDownload?: boolean
    isAnonymized?: boolean
    cleanExportProfileState?: () => void
    anonymousProfile?: FullProfileData | null
    showEducationDuration?: boolean
}

const Full: FunctionComponent<React.PropsWithChildren<FullProps>> = ({
    specialistId,
    forceDownload = false,
    isAnonymized = false,
    cleanExportProfileState,
    anonymousProfile,
    showEducationDuration = true,
}) => {
    const {
        actions: { layoutToggleLoader },
        selectors: { whiteLabel },
    } = useContext(ReduxContext)

    const log = useLogger('error')

    const [profileData, setProfileData] = useState<Nullable<FullProfileData>>(null)
    const [skills, setSkills] = useState<Nullable<FullExportSkills>>(null)

    const anonymized = useMemo(() => isAnonymized, [isAnonymized])

    const { matchTalentMarketplaceProfile, matchPublicProfile, matchAnonymousProfile } = useRoutesMatching()

    const query = useMemo(() => {
        let newQuery: 'marketplaceLinkId' | 'sharedLinkId' | 'specialistId'

        if (matchPublicProfile) {
            newQuery = 'marketplaceLinkId'
        } else if (matchAnonymousProfile) {
            newQuery = 'sharedLinkId'
        } else {
            newQuery = 'specialistId'
        }

        return newQuery
    }, [matchAnonymousProfile, matchPublicProfile])

    const id = useMemo(() => {
        let newId: string
        if (matchPublicProfile?.params.linkId) {
            newId = matchPublicProfile.params.linkId
        } else if (matchAnonymousProfile?.params.linkId) {
            newId = matchAnonymousProfile.params.linkId
        } else if (specialistId) {
            newId = specialistId
        } else {
            newId = ''
        }

        return newId
    }, [matchAnonymousProfile, matchPublicProfile, specialistId])

    useEffect(() => {
        if (query && id) {
            getSpecialistTechSkillsToExport(query, id).then(setSkills).catch(log)
        }
    }, [id, log, query])

    useEffect(() => {
        if (anonymousProfile) {
            layoutToggleLoader(true)
            setProfileData({
                ...anonymousProfile,
                link: getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, anonymousProfile.link),
            })
            layoutToggleLoader(false)
        } else {
            if (specialistId) {
                layoutToggleLoader(true)
                getFullProfileDataToExport(specialistId, true, true)
                    .then(data => {
                        if (anonymized) {
                            setProfileData({
                                ...data,
                                projects: parseProjects(data?.projects),
                                specialist: { ...data.specialist, firstName: '', lastName: '' },
                                link: getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, data.link),
                            })
                        } else {
                            setProfileData({
                                ...data,
                                link: getLink(!!matchTalentMarketplaceProfile, !!matchPublicProfile, !!matchAnonymousProfile, data.link),
                            })
                        }
                    })
                    .catch(log)
                    .finally(() => layoutToggleLoader(false))
            }
        }
    }, [
        anonymized,
        anonymousProfile,
        layoutToggleLoader,
        log,
        matchAnonymousProfile,
        matchPublicProfile,
        matchTalentMarketplaceProfile,
        specialistId,
    ])

    return profileData && skills ? (
        <PdfDoc
            customGlobalStyles={customGlobalStyles}
            fileName={`profile-full-${specialistId}`}
            preview={!forceDownload}
            cleanExportProfileState={cleanExportProfileState}
            printMargin={true}
            profileData={profileData}
        >
            <FullExport
                profileData={profileData}
                whiteLabel={whiteLabel || undefined}
                showEducationDuration={showEducationDuration}
                skills={skills}
            />
        </PdfDoc>
    ) : null
}

export { Full }
