import { useCallback } from 'react'

const useHandleQuestionClick = (setQuestion: (q: string) => void) => {
    return useCallback(
        (questions: string | string[]) => {
            const chosenQuestion = typeof questions === 'string' ? questions : questions.at(-1)

            // user collapsed all the questions
            if (!chosenQuestion) {
                setQuestion('')
            } else {
                setQuestion(chosenQuestion)
            }
        },
        [setQuestion],
    )
}

export { useHandleQuestionClick }
