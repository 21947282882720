import { ApplicationMenuItemEntry } from '../../components/layout/dashboard/ApplicationMenu'
import { getAppPath } from '../../contracts/applications'

const applicationName = 'Skill Test'

const generateMenuItems = (specialistId: string): Array<ApplicationMenuItemEntry> => [
    {
        path: getAppPath('SKILLS_TEST', specialistId),
        title: 'Skill Test',
    },
]

export { generateMenuItems, applicationName }
