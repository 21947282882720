/** @jsxImportSource @emotion/react */

import { DefaultLayout } from '../../components/DefaultLayout'

const ProfileNotFoundPublic = () => {
    return (
        <DefaultLayout
            icon='broken-link-public'
            title='This Profile has Expired or the Link is Broken'
            description='Whoever sent you the link to this Specialist, may have sent you a broken link, or the Specialist Profile they wanted to send is no longer available. Try requesting that they send the link to this Specialist again.'
        />
    )
}

export { ProfileNotFoundPublic }
