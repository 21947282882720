/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { ControlledRadioGroup, ControlledRadioGroupOptionType } from '../../components/forms/ControlledRadioGroup'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { SkillProficiency } from '../../contracts/skills'
import { mqMax } from '../../GlobalStyle'
import { StringMapping } from '../../types'
import { ApplicationSkill } from '../../contracts/opportunities-manager/contracts'
import { RateSkillsFormData } from './RateSkillsForm'

type SkillRateItemProps = {
    name: string
    control: Control<RateSkillsFormData>
    skill: ApplicationSkill
}

export const skillProficiencyMapping: StringMapping = {
    [SkillProficiency.BASIC]: 'Basic',
    [SkillProficiency.GOOD]: 'Good',
    [SkillProficiency.EXPERT]: 'Expert',
    [SkillProficiency.NOT_RATED]: 'Unsure',
}

const SkillRateItem: FunctionComponent<React.PropsWithChildren<SkillRateItemProps>> = ({ name, control, skill }) => {
    const theme = useTheme()

    return (
        <FlexBox
            alignItems='center'
            justifyContent='space-between'
            css={css`
                padding: 16px;
                border-radius: 2px;
                width: 100%;

                ${mqMax[1]} {
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 16px 8px;
                }
            `}
        >
            <div
                css={css`
                    color: ${theme.colors.gray_6};
                `}
            >
                {skill.name}
            </div>

            <div>
                <ControlledRadioGroup
                    control={control}
                    name={name}
                    styles={css`
                        width: 305px;
                        ${mqMax[1]} {
                            margin-top: 16px;
                        }
                    `}
                    options={[
                        {
                            style: css`
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `,
                            value: SkillProficiency.BASIC,
                            label: skillProficiencyMapping[SkillProficiency.BASIC],
                            type: ControlledRadioGroupOptionType.BUTTON,
                        },
                        {
                            style: css`
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `,
                            value: SkillProficiency.GOOD,
                            label: skillProficiencyMapping[SkillProficiency.GOOD],
                            type: ControlledRadioGroupOptionType.BUTTON,
                        },
                        {
                            style: css`
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `,
                            value: SkillProficiency.EXPERT,
                            label: skillProficiencyMapping[SkillProficiency.EXPERT],
                            type: ControlledRadioGroupOptionType.BUTTON,
                        },
                        {
                            style: css`
                                border: none !important;
                                box-shadow: none !important;
                                background-color: transparent !important;
                                &:hover {
                                    border: none;
                                    text-decoration: underline;
                                }
                                ${mqMax[1]} {
                                    padding: 12px;
                                }
                            `,
                            value: SkillProficiency.NOT_RATED,
                            label: skillProficiencyMapping[SkillProficiency.NOT_RATED],
                            type: ControlledRadioGroupOptionType.BUTTON,
                        },
                    ]}
                />
            </div>
        </FlexBox>
    )
}

export { SkillRateItem }
