import { Tooltip } from 'antd'
import { FunctionComponent, MouseEvent, useCallback } from 'react'
import { useCopyToClipboard } from 'react-use'
import { useNotifications } from '../../../components/notification/NotificationProvider'

type SpecialistNumberCellProps = {
    anonymousNumber: number
}

const SpecialistNumberCell: FunctionComponent<React.PropsWithChildren<SpecialistNumberCellProps>> = ({ anonymousNumber }) => {
    const [state, copyToClipboard] = useCopyToClipboard()

    const { addSuccess, addError } = useNotifications()

    const handleClick = useCallback(
        (event: MouseEvent) => {
            event.preventDefault()
            event.stopPropagation()

            if (anonymousNumber) {
                copyToClipboard(anonymousNumber.toString())
            } else {
                return
            }

            if (state.error) {
                addError('Could not copy Specialist ID to clipboard')
            } else {
                addSuccess('Copied to clipboard!')
            }
        },
        [addError, addSuccess, anonymousNumber, copyToClipboard, state.error],
    )

    return (
        <Tooltip title={anonymousNumber ? 'Copy to clipboard' : null}>
            <div onClick={handleClick}>Specialist {anonymousNumber}</div>
        </Tooltip>
    )
}

export { SpecialistNumberCell }
