/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent } from 'react'
import { ListChildComponentProps } from 'react-window'
import { SelectDivider, SelectItem } from './styled'

const OptionVirtualized: FunctionComponent<React.PropsWithChildren<ListChildComponentProps>> = ({ data, index, style }) => {
    const { onOptionClick, isOptionChecked, multiple, labelTransformer, options, checkedArraylength, dataTestId = '' } = data
    const option = options[index]

    return (
        <Fragment>
            <div style={style}>
                <SelectItem onClick={() => onOptionClick(option)} multiple={multiple} selected={isOptionChecked(option)}>
                    <span title={option} data-testid={dataTestId}>
                        {labelTransformer(option)}
                    </span>
                </SelectItem>

                {index === checkedArraylength - 1 && multiple ? <SelectDivider /> : ''}
            </div>
        </Fragment>
    )
}

export { OptionVirtualized }
