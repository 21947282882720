import { FunctionComponent } from 'react'
import { getAppPath } from './contracts/applications'
import { ContextGroupName } from './contracts/contexts'
import { Faq } from './pages/Faq/Faq'
import { SkillForm } from './pages/alchemer/SkillForm'
import { StrengthForm } from './pages/alchemer/StrengthForm'
import { CompanyInvitationAuthPage } from './pages/company-invitation/CompanyInvitationAuthPage'
import { ConfirmCompanyEmail } from './pages/confirm-company-email/ConfirmCompanyEmail'
import { ContinuousFeedback } from './pages/continuous-feedback/manager/ContinuousFeedback'
import { AddTeam } from './pages/continuous-feedback/manager/components/AddTeam'
import { ContinuousFeedbackSpecialist } from './pages/continuous-feedback/specialist/ContinousFeedbackSpecialist'
import { CVManagementPage } from './pages/cv-management/CVManagementPage'
import { Dashboard } from './pages/dashboard/Dashboard'
import { CompanyDetails } from './pages/dashboard/company-account/CompanyDetails'
import { CompanyDetailsForm } from './pages/dashboard/company-account/CompanyDetailsForm'
import { PrivateHumanCloud } from './pages/dashboard/company-account/PrivateHumanCloud'
import { PrivateHumanCloudForm } from './pages/dashboard/company-account/PrivateHumanCloudForm'
import { WhiteLabel } from './pages/dashboard/company-account/WhiteLabel'
import { WhiteLabelForm } from './pages/dashboard/company-account/WhiteLabelForm'
import { SubscriptionGuard } from './pages/dashboard/company-account/payments/Subscription'
import { SubscriptionDetails } from './pages/dashboard/company-account/payments/SubscriptionDetails'
import { JoinCompanyPage } from './pages/dashboard/company-account/role-management/JoinCompanyPage'
import { InvitationExpiredPage } from './pages/dashboard/company-account/role-management/LinkExpiredPage'
import { PendingInvitationsPage } from './pages/dashboard/company-account/role-management/PendingInvitationsPage'
import { RoleManagement } from './pages/dashboard/company-account/role-management/RoleManagement'
import { RoleManagementForm } from './pages/dashboard/company-account/role-management/RoleManagementForm'
import { EmailNotificationSettings } from './pages/dashboard/my-account/EmailNotificationsSettings'
import { LoginSettings } from './pages/dashboard/my-account/LoginSettings'
import { PersonalDetails } from './pages/dashboard/my-account/PersonalDetails'
import { MarketingConsents } from './pages/documents/MarketingConsents'
import { PrivacyPolicy } from './pages/documents/PrivacyPolicy'
import { TermsOfUse } from './pages/documents/TermsOfUse'
import { DotMemory } from './pages/dot-memory/DotMemory'
import { AccessDenied } from './pages/error/AccessDenied'
import { ForbiddenForCurrentUser } from './pages/error/ForbiddenForCurrentUser'
import { MagicLinkAccessDenied } from './pages/error/MagicLinkAccessDenied'
import { PageNotFound } from './pages/error/PageNotFound'
import { ProfileNotFoundPrivate } from './pages/error/ProfileNotFoundPrivate'
import { ProfileNotFoundPublic } from './pages/error/ProfileNotFoundPublic'
import { UnavailableContext } from './pages/error/UnavailableContext'
import { FirebaseActionHandler } from './pages/firebase-action-handler/FirebaseActionHandler'
import { InstantOnboarding } from './pages/instant-onboarding/InstantOnboarding'
import { InstantOnboardingWelcomeScreen } from './pages/instant-onboarding/InstantOnboardingWelcomeScreen'
import { KanbanPocPage } from './pages/kanban/KanbanPocPage'
import { Login } from './pages/login/Login'
import { LogoutSpecialist } from './pages/logout/LogoutSpecialist'
import { LogoutUser } from './pages/logout/LogoutUser'
import { BasicInfo } from './pages/my-specialists/BasicInfo'
import { CustomFieldsPage } from './pages/my-specialists/CustomFieldsPage'
import { CvParsingPage } from './pages/my-specialists/CvParsingPage'
import { MagicLinkSent } from './pages/my-specialists/MagicLinkSent'
import { ResendMagicLink } from './pages/my-specialists/ResendMagicLink'
import { ShareMySpecialistsPage } from './pages/my-specialists/ShareMySpecialistsPage'
import { SpecialistFinishCvParsingPage } from './pages/my-specialists/SpecialistFinishCvParsingPage'
import { SpecialistProfilePage } from './pages/my-specialists/SpecialistProfilePage'
import { SpecialistsSearchPage } from './pages/my-specialists/SpecialistsSearchPage'
import { ShareMySpecialistsFeedbackPage } from './pages/my-specialists/share-my-specialists/ShareMySpecialistsFeedbackPage'
import { MySpecialistsAnonymousProfile } from './pages/my-specialists/shared/MySpecialistsAnonymousProfile'
import CorrectionSpreadsheetDownload from './pages/my-specialists/specialists-import/CorrectionSpreadsheetDownload'
import { SpecialistsImportPage } from './pages/my-specialists/specialists-import/SpecialistsImportPage'
import { ApplicationDetails as OpenOpportunitiesApplicationDetails } from './pages/open-opportunities/v2/ApplicationDetails'
import { ApplicationDetailsVMC as OpenVacanciesApplicationDetails } from './pages/open-vacancies/ApplicationDetailsVMC'

import { ApplicationWizard } from './components/open-opportunities/ApplicationWizard/ApplicationWizard'

import { OpenOpportunitiesDetails } from './pages/open-opportunities/v2/OpenOpportunitiesDetails'
import { OpenOpportunitiesList } from './pages/open-opportunities/v2/OpenOpportunitiesList'
import { AddOpportunityPage } from './pages/opportunities-manager/v2/AddOpportunityPage'
import { AllCandidates } from './pages/opportunities-manager/v2/AllCandidates'
import { ApplicationDetails } from './pages/opportunities-manager/v2/ApplicationDetails'
import { EditOpportunityPage } from './pages/opportunities-manager/v2/EditOpportunityPage'
import { OpportunitiesList } from './pages/opportunities-manager/v2/OpportunitiesList'
import { OpportunityApplicationsPage } from './pages/opportunities-manager/v2/OpportunityApplicationsPage'
import { OpportunityDetails } from './pages/opportunities-manager/v2/OpportunityDetails'
import { SharedOpportunityPage } from './pages/opportunities-manager/v2/SharedOpportunityPage'
import { Full } from './pages/pdf/Full'
import { Pdf } from './pages/pdf/Pdf'
import { Quick } from './pages/pdf/Quick'
import { InternalProfile } from './pages/profile/internal-profile/InternalProfile'
import { ProfileSummary } from './pages/profile/profile-summary/ProfileSummary'
import { PublicProfile } from './pages/profile/public-profile/PublicProfile'
import { RecoverPassword } from './pages/recover-password/RecoverPassword'
import { RefreshUserContext } from './pages/refresh-user-context/RefreshUserContext'
import { Register } from './pages/register/Register'
import { RegisterConfirmation } from './pages/register/RegisterConfirmation'
import { SkillsDiscoveryPage } from './pages/skills-discovery/SkillsDiscoveryPage'
import { SkillFormView } from './pages/skills-discovery/components/SkillFormView'
import { SkillTestPage } from './pages/skills-test/SkillTestPage'
import { SkillsTestPage } from './pages/skills-test/SkillsTestPage'
import { SpecialistCvParsingPage } from './pages/specialist-cv-parsing/SpecialistCvParsingPage'
import { SpecialistEducationPage } from './pages/specialist-cv-parsing/SpecialistEducation'
import { SpecialistExperiencePage } from './pages/specialist-cv-parsing/SpecialistExperiencePage'
import { SpecialistSkillsRatingPage } from './pages/specialist-cv-parsing/SpecialistSkillsRatingPage'
import { SpecialistDashboard } from './pages/specialist-dashboard/SpecialistDashboard'
import { SpecialistSkillsEdit } from './pages/specialist-skills-edit/SpecialistSkillsEdit'
import { StrengthDiscoveryPage } from './pages/strength-discovery/StrengthDiscoveryPage'
import { StrengthFormView } from './pages/strength-discovery/components/StrengthFormView'
import { MarketplaceBookingsSearchPage } from './pages/talent-marketplace/BookingsSearchPage'
import { MarketplaceFeedbackPage } from './pages/talent-marketplace/MarketplaceFeedbackPage'
import { MarketplaceSpecialistsSearchPage } from './pages/talent-marketplace/SpecialistsSearchPage'
import { SpecialistsSearchSavedProfiles } from './pages/talent-marketplace/SpecialistsSearchSavedProfiles'
import { TermsDecline } from './pages/terms-decline/TermsDecline'
import { FeatureFlags } from './redux/featureFlags'
import { OpportunityDetailsSme } from './pages/opportunities-manages-sme/OpportunityDetailsSme'
import { OpportunitiesListSme } from './pages/opportunities-manages-sme/OpportunitiesListSme'
import { EditOpportunityPageSme } from './pages/opportunities-manages-sme/EditOpportunityPageSme'
import { OpportunityApplicationsPageSme } from './pages/opportunities-manages-sme/OpportunityApplicationsPageSme'
import { ApplicationDetailsSme } from './pages/opportunities-manages-sme/ApplicationDetailsSme'
import { AddOpportunityPageSme } from './pages/opportunities-manages-sme/AddOpportunityPageSme'
import { AllCandidatesSme } from './pages/opportunities-manages-sme/AllCandidatesSme'
import { MyVendorsForm } from './pages/dashboard/company-account/my-vendors/MyVendorsForm'
import { VMCInvitationAuthPage } from './pages/vmc-invitation/VMCInvitationAuthPage'
import { OpportunitiesListVMC } from './pages/vacancies-manager/OpportunitiesListVMC'
import { OpportunityDetailsVMC } from './pages/vacancies-manager/OpportunityDetailsVMC'
import { EditOpportunityPageVMC } from './pages/vacancies-manager/EditOpportunityPageVMC'
import { OpportunityApplicationsPageVMC } from './pages/vacancies-manager/OpportunityApplicationsPageVMC'
import { ApplicationDetailsVMC } from './pages/vacancies-manager/ApplicationDetailsVMC'
import { AddVacancy } from './pages/vacancies-manager/AddVacancy'
import { AllCandidatesVMC } from './pages/vacancies-manager/AllCandidatesVMC'
import { OpenVacanciesList } from './pages/open-vacancies/OpenVacanciesList'
import { OpenVacanciesDetails } from './pages/open-vacancies/OpenVacanciesDetails'
import { OpenVacanciesApplicationsPage } from './pages/open-vacancies/OpenVacanciesApplicationsPage'
import { SharedVacancyPage } from './pages/open-vacancies/SharedVacancyPage'
import { MyVendors } from './pages/dashboard/company-account/my-vendors/MyVendors'

type Routes = Array<RouteDefinition>

type RouteDefinition = {
    path: string
    component?: FunctionComponent<React.PropsWithChildren<any>>
    children?: Routes
    public?: boolean
    allowedGroups?: Array<ContextGroupName>
}

const getRoutes = (featureFlags: FeatureFlags): Routes => [
    {
        path: '/',
        component: Login,
        public: true,
    },
    {
        path: '/logout',
        component: Login,
        public: true,
        children: [
            {
                path: '/user',
                component: LogoutUser,
                public: true,
            },
            {
                path: '/specialist',
                component: LogoutSpecialist,
                public: true,
            },
        ],
    },
    {
        path: '/documents',
        children: [
            {
                path: '/terms-of-use',
                component: TermsOfUse,
                public: true,
            },
            {
                path: '/privacy-policy',
                component: PrivacyPolicy,
                public: true,
            },
            {
                path: '/marketing-consents',
                component: MarketingConsents,
                public: true,
            },
        ],
    },
    {
        path: '/register',
        component: Register,
        public: true,
        children: [
            {
                path: '/confirmation',
                component: RegisterConfirmation,
                public: true,
            },
        ],
    },
    {
        path: '/recover-password',
        component: RecoverPassword,
        public: true,
    },
    {
        path: '/instant-onboarding',
        component: InstantOnboarding,
        allowedGroups: [ContextGroupName.SPECIALIST],
        children: [
            {
                path: '/welcome',
                component: InstantOnboardingWelcomeScreen,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
            {
                path: '/step/:key',
                component: InstantOnboarding,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
        ],
    },
    {
        path: '/specialist-dashboard',
        component: SpecialistDashboard,
        allowedGroups: [ContextGroupName.SPECIALIST],
    },
    {
        path: '/specialist-cv-parsing',
        component: SpecialistCvParsingPage,
        allowedGroups: [ContextGroupName.SPECIALIST],
        children: [
            {
                path: '/:cvParsingId/skills',
                component: SpecialistSkillsRatingPage,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
            {
                path: '/:cvParsingId/experience',
                component: SpecialistExperiencePage,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
            {
                path: '/:cvParsingId/education',
                component: SpecialistEducationPage,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
        ],
    },
    {
        path: '/specialist-skills-edit',
        component: SpecialistSkillsEdit,
        allowedGroups: [ContextGroupName.SPECIALIST],
    },
    {
        path: '/dashboard',
        component: Dashboard,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER, ContextGroupName.COMPANY_SUPER_ADMIN],
        children: [
            {
                path: '/my-account',
                children: [
                    {
                        path: '/login-settings',
                        component: LoginSettings,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                        ],
                    },
                    {
                        path: '/personal-details',
                        component: PersonalDetails,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                        ],
                    },
                    {
                        path: '/email-notifications',
                        component: EmailNotificationSettings,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                        ],
                    },
                ],
            },
            {
                path: '/company-account',
                children: [
                    {
                        path: '/company-details',
                        component: CompanyDetails,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/form',
                                component: CompanyDetailsForm,
                                allowedGroups: [
                                    ContextGroupName.BASIC_USER,
                                    ContextGroupName.COMPANY_SUPER_ADMIN,
                                    ContextGroupName.FUNCTIONAL_MANAGER,
                                ],
                            },
                        ],
                    },
                    {
                        path: '/white-label',
                        component: WhiteLabel,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/form',
                                component: WhiteLabelForm,
                                allowedGroups: [ContextGroupName.COMPANY_SUPER_ADMIN, ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                    {
                        path: '/private-human-cloud',
                        component: PrivateHumanCloud,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/form',
                                component: PrivateHumanCloudForm,
                                allowedGroups: [ContextGroupName.COMPANY_SUPER_ADMIN, ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                    {
                        path: '/role-management',
                        component: RoleManagement,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/form',
                                component: RoleManagementForm,
                                allowedGroups: [ContextGroupName.COMPANY_SUPER_ADMIN, ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                    {
                        path: '/my-vendors',
                        component: MyVendors,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/form',
                                component: MyVendorsForm,
                                allowedGroups: [ContextGroupName.COMPANY_SUPER_ADMIN, ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },

                    {
                        path: '/subscription',
                        component: SubscriptionGuard,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                        ],
                        children: [
                            {
                                path: '/details',
                                component: SubscriptionDetails,
                                allowedGroups: [ContextGroupName.COMPANY_SUPER_ADMIN, ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: '/profile/:specialistId',
        component: InternalProfile,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
    },
    {
        path: '/public/profile/:linkId',
        component: PublicProfile,
        public: true,
    },
    {
        path: '/profile-summary/:specialistId',
        component: ProfileSummary,
        allowedGroups: [ContextGroupName.SPECIALIST],
    },
    {
        path: '/anonymous/profile/:linkId',
        component: MySpecialistsAnonymousProfile,
        public: true,
    },
    {
        path: '/confirm-company-email',
        component: ConfirmCompanyEmail,
        public: true,
    },
    {
        path: getAppPath('TALENT_MARKETPLACE'),
        component: MarketplaceSpecialistsSearchPage,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/saved-profiles',
                component: SpecialistsSearchSavedProfiles,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/bookings',
                component: MarketplaceBookingsSearchPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/feedback',
                component: MarketplaceFeedbackPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
        ],
    },
    {
        path: getAppPath('MY_SPECIALISTS'),
        component: SpecialistsSearchPage,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/specialist/:specialistId',
                component: SpecialistProfilePage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER, ContextGroupName.SPECIALIST],
            },
            {
                path: '/specialist/:specialistId/skills-edit',
                component: SpecialistSkillsEdit,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER, ContextGroupName.SPECIALIST],
            },
            {
                path: '/specialist/:specialistId/finish-extracting/:cvParsingId',
                component: SpecialistFinishCvParsingPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/import-specialists',
                component: SpecialistsImportPage,
                children: [{ path: '/correction-spreadsheet/:importId', component: CorrectionSpreadsheetDownload }],
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/custom-fields',
                component: CustomFieldsPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/basic-info',
                component: BasicInfo,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
            {
                path: '/cv-parsing',
                component: CvParsingPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/continuous-feedback',
                component: ContinuousFeedback,
                allowedGroups: [
                    ContextGroupName.BASIC_USER,
                    ContextGroupName.FUNCTIONAL_MANAGER,
                    ContextGroupName.COMPANY_SUPER_ADMIN,
                    ContextGroupName.SPECIALIST,
                ],
                children: [
                    {
                        path: '/add-team',
                        component: AddTeam,
                        allowedGroups: [
                            ContextGroupName.BASIC_USER,
                            ContextGroupName.FUNCTIONAL_MANAGER,
                            ContextGroupName.COMPANY_SUPER_ADMIN,
                            ContextGroupName.SPECIALIST,
                        ],
                    },
                ],
            },
            {
                path: '/share',
                component: ShareMySpecialistsPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    { path: '/feedback', component: ShareMySpecialistsFeedbackPage, allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER] },
                ],
            },
        ],
    },
    {
        path: `${getAppPath('SKILLS_DISCOVERY')}`,
        component: SkillsDiscoveryPage,
        allowedGroups: [ContextGroupName.SPECIALIST],
        children: [
            {
                path: '/form',
                component: SkillFormView,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
        ],
    },
    {
        path: `${getAppPath('STRENGTH_DISCOVERY')}`,
        component: StrengthDiscoveryPage,
        allowedGroups: [ContextGroupName.SPECIALIST],
        children: [
            {
                path: '/form',
                component: StrengthFormView,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
        ],
    },
    {
        path: `${getAppPath('SKILLS_TEST')}`,
        component: SkillsTestPage,
        allowedGroups: [ContextGroupName.SPECIALIST],
        children: [
            {
                path: '/:testId',
                component: SkillTestPage,
                allowedGroups: [ContextGroupName.SPECIALIST],
            },
        ],
    },
    {
        path: `${getAppPath('OPPORTUNITIES_MANAGER')}`,
        component: OpportunitiesList,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/opportunities/:opportunityId',
                component: OpportunityDetails,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/edit',
                        component: EditOpportunityPage,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                    {
                        path: '/applications',
                        component: OpportunityApplicationsPage,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                        children: [
                            {
                                path: '/:applicationId',
                                component: ApplicationDetails,
                                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/add-opportunity',
                component: AddOpportunityPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/all-candidates',
                component: AllCandidates,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
        ],
    },

    {
        path: `${getAppPath('OPPORTUNITIES_MANAGER_SME')}`,
        component: OpportunitiesListSme,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/opportunities/:opportunityId',
                component: OpportunityDetailsSme,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/edit',
                        component: EditOpportunityPageSme,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                    {
                        path: '/applications',
                        component: OpportunityApplicationsPageSme,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                        children: [
                            {
                                path: '/:applicationId',
                                component: ApplicationDetailsSme,
                                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/add-opportunity',
                component: AddOpportunityPageSme,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/all-candidates',
                component: AllCandidatesSme,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
        ],
    },
    {
        path: `${getAppPath('VACANCIES_MANAGER')}`,
        component: OpportunitiesListVMC,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/vacancy/:opportunityId',
                component: OpportunityDetailsVMC,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/edit',
                        component: EditOpportunityPageVMC,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                    {
                        path: '/applications',
                        component: OpportunityApplicationsPageVMC,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                        children: [
                            {
                                path: '/:applicationId',
                                component: ApplicationDetailsVMC,
                                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                            },
                        ],
                    },
                ],
            },
            {
                path: '/add-vacancy',
                component: AddVacancy,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
            {
                path: '/all-candidates',
                component: AllCandidatesVMC,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
            },
        ],
    },
    {
        path: `${getAppPath('OPEN_OPPORTUNITIES')}`,
        component: OpenOpportunitiesList,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/:opportunityId',
                component: OpenOpportunitiesDetails,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/application-wizard',
                        component: ApplicationWizard,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                ],
            },
            {
                path: '/applications',
                component: OpenVacanciesApplicationsPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/:applicationId',
                        component: OpenOpportunitiesApplicationDetails,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                ],
            },
        ],
    },
    {
        path: `${getAppPath('OPEN_VACANCIES')}`,
        component: OpenVacanciesList,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
        children: [
            {
                path: '/:opportunityId',
                component: OpenVacanciesDetails,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/application-wizard',
                        component: ApplicationWizard,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                ],
            },
            {
                path: '/applications',
                component: OpenVacanciesApplicationsPage,
                allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                children: [
                    {
                        path: '/:applicationId',
                        component: OpenVacanciesApplicationDetails,
                        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
                    },
                ],
            },
        ],
    },
    {
        path: '/public/opportunity/:sharedLinkId',
        component: SharedOpportunityPage,
        public: true,
    },
    {
        path: '/public/vacancy/:sharedLinkId',
        component: SharedVacancyPage,
        public: true,
    },
    {
        path: '/action',
        component: FirebaseActionHandler,
        public: true,
    },
    {
        path: '/magic-link',
        children: [
            {
                path: '/resend',
                component: ResendMagicLink,
                public: true,
            },
            {
                path: '/confirmation',
                component: MagicLinkSent,
                public: true,
            },
        ],
    },
    {
        path: '/access-denied',
        component: AccessDenied,
        public: true,
    },
    {
        path: '/magic-link-access-denied',
        component: MagicLinkAccessDenied,
        public: true,
    },
    {
        path: '/page-not-found',
        component: PageNotFound,
        public: true,
    },
    {
        path: '/form/:formId/:specialistId',
        component: SkillForm,
        public: true,
    },
    {
        path: '/strength-form/:formId/:specialistId',
        component: StrengthForm,
        public: true,
    },
    {
        path: '/profile-not-found-public',
        component: ProfileNotFoundPublic,
        public: true,
    },
    {
        path: '/profile-not-found',
        component: ProfileNotFoundPrivate,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
    },
    {
        path: '/invitation-expired',
        component: InvitationExpiredPage,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/specialist-terms-decline',
        component: TermsDecline,
        public: true,
        allowedGroups: [ContextGroupName.SPECIALIST],
    },
    {
        path: '/company-invitation/:prospectId',
        component: JoinCompanyPage,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/company-invitation/:prospectId/auth',
        component: CompanyInvitationAuthPage,
        public: true,
    },
    {
        path: '/pending-invitations',
        component: PendingInvitationsPage,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/refresh-user-context',
        component: RefreshUserContext,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/dot-memory',
        component: DotMemory,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/forbidden-for-current-user',
        component: ForbiddenForCurrentUser,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },
    {
        path: '/unavailable-context',
        component: UnavailableContext,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
    },

    {
        path: '/pdf',
        component: Pdf,
        allowedGroups: [
            ContextGroupName.BASIC_USER,
            ContextGroupName.FUNCTIONAL_MANAGER,
            ContextGroupName.COMPANY_SUPER_ADMIN,
            ContextGroupName.SPECIALIST,
        ],
        children: [
            {
                path: '/quick',
                component: Quick,
                allowedGroups: [
                    ContextGroupName.BASIC_USER,
                    ContextGroupName.FUNCTIONAL_MANAGER,
                    ContextGroupName.COMPANY_SUPER_ADMIN,
                    ContextGroupName.SPECIALIST,
                ],
            },
            {
                path: '/full',
                component: Full,
                allowedGroups: [
                    ContextGroupName.BASIC_USER,
                    ContextGroupName.FUNCTIONAL_MANAGER,
                    ContextGroupName.COMPANY_SUPER_ADMIN,
                    ContextGroupName.SPECIALIST,
                ],
            },
        ],
    },
    {
        path: '/cv-management',
        component: CVManagementPage,
        allowedGroups: [ContextGroupName.FUNCTIONAL_MANAGER],
    },
    {
        path: '/continuous-feedback',
        component: ContinuousFeedbackSpecialist,
        // allowedGroups: [
        //     ContextGroupName.SPECIALIST,
        // ],
        public: true,
    },
    {
        path: '/storybook',
        component: undefined,
        // allowedGroups: [
        //     ContextGroupName.SPECIALIST,
        // ],
        public: true,
    },
    {
        path: '/kanban',
        component: KanbanPocPage,
        public: true,
    },
    {
        path: '/faq',
        component: Faq,
        public: true,
    },
    {
        path: '/vmc-invitation/:prospectId',
        component: VMCInvitationAuthPage,
        public: true,
    },
]

const mapRoutes = (routesDefinition: Routes) => {
    const output: Routes = []

    const mapNode = (routes: Routes, prefix = '') => {
        routes.forEach((routeDef: RouteDefinition) => {
            if (routeDef.children) {
                mapNode(routeDef.children, prefix + routeDef.path)
                delete routeDef.children
            }

            if (routeDef.component) {
                routeDef.path = prefix + routeDef.path
                output.push(routeDef)
            }
        })
    }

    mapNode(routesDefinition)
    return output
}

const getRoutesDefinition = (featureFlags: FeatureFlags) => {
    const routes = getRoutes(featureFlags)
    return mapRoutes(routes)
}

export { getRoutesDefinition }
