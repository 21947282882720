/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'

type StepDescriptionProps = {
    step: number
    description: string
}

const StepDescription: FunctionComponent<React.PropsWithChildren<StepDescriptionProps>> = ({ step, description }) => (
    <div
        css={css`
            text-align: center;
            margin-top: 30px;
            margin-bottom: 100px;
        `}
    >
        <div
            css={css`
                font-weight: bold;
            `}
            data-testid='step-indicator'
        >
            Step {step}
        </div>
        <div>{description}</div>
    </div>
)

export { StepDescription }
