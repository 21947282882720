/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { Fragment, FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { Button } from '../../../../components/Button'
import { ResponsiveGrid } from '../../../../components/data-grid/ResponsiveGrid'
import { Divider } from '../../../../components/Divider'
import { DashboardLayout } from '../../../../components/layout/dashboard/DashboardLayout'
import { FullPageWrapper } from '../../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { TruncateList } from '../../../../components/TruncateList'
import { TurncateListHiddenItemsCount } from '../../../../components/TurncateListHiddenItemsCount'
import { breakpoints } from '../../../../GlobalStyle'
import { useMySpecialistsMenuItems } from '../../../../hooks/useMySpecialistsMenuItems'
import { ReduxContext } from '../../../../redux/Store'
import { useLogger } from '../../../../utils/useLogger'
import { getFeedbackTeams } from '../api'
import { FeedbackTeam } from '../contracts'
import { ActionsDropDown } from './ActionsDropDown'
import { ResultsWrapper } from './ResultsWrapper'

const TeamsList: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [teams, setTeams] = useState<Array<FeedbackTeam>>([])
    const {
        state: {
            layout: { showLoader },
        },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { menuItems, applicationName } = useMySpecialistsMenuItems()
    const log = useLogger('error')
    const { addError } = useNotifications()

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)
        getFeedbackTeams()
            .then(setTeams)
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [layoutToggleLoader, log, addError])

    const columns: ColumnsType<any> = useMemo(
        () => [
            {
                title: 'Team Name',
                dataIndex: 'name',
            },
            {
                title: 'Team Members',
                dataIndex: 'members',
                render: (_, record) => (
                    <div
                        css={css`
                            max-width: 500px;
                        `}
                    >
                        <TruncateList list={record.members} ItemsCount={TurncateListHiddenItemsCount} showCommaSeparator={true} />
                    </div>
                ),
            },
            {
                title: '',
                dataIndex: 'id',
                render: (_, record) => <ActionsDropDown teamId={record.id} fetchData={fetchData} />,
                width: 170,
            },
        ],
        [fetchData],
    )

    useEffectOnce(fetchData)

    return (
        <Fragment>
            <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
                <ResultsWrapper pending={showLoader} total={teams.length}>
                    <FullPageWrapper>
                        <section
                            css={css`
                                margin-top: 27px;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                            `}
                        >
                            <div
                                css={css`
                                    font-size: 24px;
                                    font-weight: 500;
                                `}
                            >
                                Feedback Teams
                            </div>

                            <Link to='/my-specialists/continuous-feedback/add-team'>
                                <Button>Add Feedback Team</Button>
                            </Link>
                        </section>

                        <Divider
                            style={css`
                                margin-bottom: -10px;
                            `}
                        />

                        <ResponsiveGrid
                            rowKey='name'
                            dataSource={teams}
                            columns={columns}
                            pagination={false}
                            scroll={{ x: breakpoints[1] }}
                            mobileItemStyles={() => css``}
                        />
                    </FullPageWrapper>
                </ResultsWrapper>
            </DashboardLayout>
        </Fragment>
    )
}

export { TeamsList }
