import { OptionObject } from 'src/components/inputs/autocomplete-select/Option'
import { Paging } from 'src/contracts/common/pagingProps'
import { Sorting } from 'src/contracts/common/sorting'
import { OpportunityStatus } from 'src/contracts/opportunities'
import { SkillProficiency } from 'src/contracts/skills'
import { Nullable, StringMapping } from 'src/types'

interface OpportunityOwner {
    displayName: string
    userId: string
}

interface OpportunityInternalDetails {
    clientName: string
    internalName: string
    internalNotes: string
    opportunityOwner: OpportunityOwner
    generatedId: string
    customCvTemplate: boolean
    earlyAccessExpiration: number | null
    smeCloud?: boolean
}

interface OpportunityInternalDetailsFormData extends Omit<OpportunityInternalDetails, 'opportunityOwner'> {
    opportunityOwner: string
    earlyAccessExpiration: number | null
}

interface OpportunityJobDetails {
    mainResponsibilities: string
    minYearsOfExperience: Nullable<number>
    preferredTechnicalSkills: Array<string>
    requiredCertifications: string
    requiredTechnicalSkills: Array<string>
    keyTechnicalSkills: Array<string>
    technicalRequirements: string
    jobDescription: Nullable<string>
}

interface OpportunityContactDetails {
    contactEmail: Nullable<string>
}

interface OpportunityJobDetailsFormData extends Omit<OpportunityJobDetails, 'minYearsOfExperience'> {
    minYearsOfExperience: number | ''
}

interface OpportunityLocationCountriesWithCities {
    cities: Array<string>
    country: string
}

export interface OpportunityLocationRequest {
    countriesWithCities: Array<OpportunityLocationCountriesWithCities>
    locationType?: OpportunityLocationTypeV2Enum
    otherLocationRequirementsText: string
    regions: Array<string>
    timeZone: string
}
export interface OpportunityLocationResponse extends Omit<OpportunityLocationRequest, 'locationType'> {
    locationType: OpportunityLocationTypeEnum
    locationTypeV2: Nullable<OpportunityLocationTypeV2Enum>
    otherLocationRequirements: boolean
}

interface OpportunityLocationFormData extends Omit<OpportunityLocationResponse, 'regions' | 'countriesWithCities'> {
    countryCitiesList: Array<OptionObject>
    countriesAndRegions: Array<OptionObject>
}

export enum CountriesAndRegionsGroupsEnum {
    COUNTRIES = 'Countries',
    REGIONS = 'Regions',
}

export enum OpportunityOfferTypeEnum {
    INDIVIDUAL = 'INDIVIDUAL',
    TEAM = 'TEAM',
}

export const opportunityOfferTypeMapping = {
    [OpportunityOfferTypeEnum.INDIVIDUAL]: 'Individual',
    [OpportunityOfferTypeEnum.TEAM]: 'Team',
}

interface OpportunityDetails {
    currency: string
    endDate: Nullable<number>
    maximumDealSize?: Nullable<number>
    maximumRate: Nullable<number>
    minimumDealSize?: Nullable<number>
    minimumRate: Nullable<number>
    numberOfVacancies: Nullable<number>
    offerType: OpportunityOfferTypeEnum
    startDate: Nullable<number>
}

interface OpportunityDetailsFormData
    extends Omit<
        OpportunityDetails,
        'endDate' | 'maximumDealSize' | 'maximumRate' | 'minimumDealSize' | 'minimumRate' | 'numberOfVacancies' | 'startDate'
    > {
    endDate: number | ''
    maximumRate: number | ''
    minimumRate: number | ''
    numberOfVacancies: number | ''
    startDate: number | ''
}

interface OpportunityProjectDetails {
    paymentPeriod: Nullable<number>
    projectDescription: string
    projectIndustry: string
    projectTitle: string
}

interface OpportunityProjectDetailsFormData extends Omit<OpportunityProjectDetails, 'paymentPeriod'> {
    paymentPeriod: number | ''
}

export interface UpdateOpportunityRequestBody {
    internalDetails: OpportunityInternalDetails
    contactDetails: OpportunityContactDetails
    jobDetails: OpportunityJobDetails
    location: OpportunityLocationRequest
    opportunityDetails: OpportunityDetails
    opportunityTitle: string
    projectDetails: OpportunityProjectDetails
    isSmeCloud?: boolean
}

export interface UpdateOpportunityFormData
    extends Omit<UpdateOpportunityRequestBody, 'location' | 'internalDetails' | 'jobDetails' | 'opportunityDetails' | 'projectDetails'> {
    internalDetails: OpportunityInternalDetailsFormData
    contactDetails: OpportunityContactDetails
    jobDetails: OpportunityJobDetailsFormData
    location: OpportunityLocationFormData
    opportunityDetails: OpportunityDetailsFormData
    projectDetails: OpportunityProjectDetailsFormData
    isSmeCloud?: boolean
}

export const OpportunityStatusMapping: StringMapping = {
    [OpportunityStatus.DRAFT]: 'Draft',
    [OpportunityStatus.PUBLISHED]: 'Published',
    [OpportunityStatus.CLOSED]: 'Closed',
    [OpportunityStatus.ON_HOLD]: 'On Hold',
}

export enum OpportunityLocationTypeEnum {
    ANYWHERE = 'ANYWHERE',
    SPECIFIC_LOCATION = 'SPECIFIC_LOCATION',
}

export enum OpportunityLocationTypeV2Enum {
    ON_SITE = 'ON_SITE',
    HYBRID = 'HYBRID',
    REMOTE = 'REMOTE',
}

export interface OpportunityResponseBody {
    createdAt: number
    internalDetails: OpportunityInternalDetails
    contactDetails: OpportunityContactDetails
    jobDetails: OpportunityJobDetails
    location: OpportunityLocationResponse
    opportunityDetails: OpportunityDetails
    opportunityId: string
    opportunityStatus: OpportunityStatus
    opportunityTitle: string
    projectDetails: OpportunityProjectDetails
    publishedAt: number
    readableId: number
    updatedAt: number
    availableForCurrentCompany?: boolean
    earlyAccess: boolean
    smeCloud: boolean
    hiddenInformation: boolean
}

export interface SearchOpportunityItem {
    addedOn: number
    clientName: string
    companyId: string
    generatedId: string
    hasNewApplications: boolean
    internalName: string
    location: OpportunityLocationResponse
    numberOfVacancies: number
    numberOfCandidates: number
    opportunityId: string
    opportunityOwnerId: string
    opportunityOwnerName: string
    opportunityTitle: string
    projectEndDate: number
    projectStartDate: number
    status: OpportunityStatus
}

export interface SearchOpportunityResponseBody {
    opportunities: Array<SearchOpportunityItem>
    total: number
}

export interface SearchOpportunitiesFiltersOptions {
    countries: Array<string>
    locationTypes: Array<string>
    regions: Array<string>
    statuses: Array<string>
    ownersNames: Array<string>
    industries: Array<string>
}
export interface SearchOpportunitiesFilters extends Omit<SearchOpportunitiesFiltersOptions, 'ownersNames'> {
    addedOn: Nullable<number>
    lastUpdate: Nullable<number>
    ownerName: Nullable<string>
    projectEndDate: Nullable<number>
    projectStartDate: Nullable<number>
    industries: Array<string>
}
export interface SearchOpportunitiesRequestParams extends SearchOpportunitiesFilters, Paging, Sorting {
    searchText: Nullable<string>
    internalManager?: boolean
}

export enum ApplicationStatusEnum {
    NEW_APPLICATION = 'NEW_APPLICATION',
    REVIEWING = 'REVIEWING',
    INFO_REQUESTED = 'INFO_REQUESTED',
    PRESENTED = 'PRESENTED',
    PRESENTED_IN_ANOTHER_OPPORTUNITY = 'PRESENTED_IN_ANOTHER_OPPORTUNITY',
    ON_HOLD = 'ON_HOLD',
    REJECTED_BY_TA = 'REJECTED_BY_TA',
    REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
    WITHDRAWN = 'WITHDRAWN',
    ACCEPTED = 'ACCEPTED',
    INTERVIEW = 'INTERVIEW',
    INTERVIEW_SCHEDULING = 'INTERVIEW_SCHEDULING',
    INTERVIEW_FINISHED = 'INTERVIEW_FINISHED',
    TECH_TEST = 'TECH_TEST',
    OFFER = 'OFFER',
    OFFER_ACCEPTED_NEGOTIATIONS = 'OFFER_ACCEPTED_NEGOTIATIONS',
    OFFER_ACCEPTED = 'OFFER_ACCEPTED',
    OFFER_REJECTED = 'OFFER_REJECTED',
    WON = 'WON',
    OPPORTUNITY_CLOSED = 'OPPORTUNITY_CLOSED',
    INVITED = 'INVITED',
}

export enum ApplicationRejectionStatusEnum {
    REJECTED_BY_TA = 'REJECTED_BY_TA',
    REJECTED_BY_CLIENT = 'REJECTED_BY_CLIENT',
    OFFER_REJECTED = 'OFFER_REJECTED',
}

export const applicationStatusesList = [
    ApplicationStatusEnum.INVITED,
    ApplicationStatusEnum.NEW_APPLICATION,
    ApplicationStatusEnum.REVIEWING,
    ApplicationStatusEnum.INFO_REQUESTED,
    ApplicationStatusEnum.PRESENTED,
    ApplicationStatusEnum.PRESENTED_IN_ANOTHER_OPPORTUNITY,
    ApplicationStatusEnum.ON_HOLD,
    ApplicationStatusEnum.REJECTED_BY_TA,
    ApplicationStatusEnum.REJECTED_BY_CLIENT,
    ApplicationStatusEnum.WITHDRAWN,
    ApplicationStatusEnum.ACCEPTED,
    ApplicationStatusEnum.INTERVIEW,
    ApplicationStatusEnum.INTERVIEW_SCHEDULING,
    ApplicationStatusEnum.INTERVIEW_FINISHED,
    ApplicationStatusEnum.TECH_TEST,
    ApplicationStatusEnum.OFFER,
    ApplicationStatusEnum.OFFER_ACCEPTED_NEGOTIATIONS,
    ApplicationStatusEnum.OFFER_ACCEPTED,
    ApplicationStatusEnum.OFFER_REJECTED,
    ApplicationStatusEnum.WON,
]

export const applicationRejectionStatusesList = [
    ApplicationStatusEnum.REJECTED_BY_TA,
    ApplicationStatusEnum.REJECTED_BY_CLIENT,
    ApplicationStatusEnum.OFFER_REJECTED,
]

export const contractRateApplicationStatusesList = [ApplicationStatusEnum.OFFER_ACCEPTED]

export const interviewDateStatusesList = [ApplicationStatusEnum.INTERVIEW]

export const ApplicationStatusMapping: StringMapping = {
    [ApplicationStatusEnum.NEW_APPLICATION]: 'New application',
    [ApplicationStatusEnum.REVIEWING]: 'Reviewing',
    [ApplicationStatusEnum.INFO_REQUESTED]: 'Info requested',
    [ApplicationStatusEnum.PRESENTED]: 'Presented',
    [ApplicationStatusEnum.PRESENTED_IN_ANOTHER_OPPORTUNITY]: 'Presented in another opportunity',
    [ApplicationStatusEnum.ON_HOLD]: 'On hold',
    [ApplicationStatusEnum.REJECTED_BY_TA]: 'Rejected by TA',
    [ApplicationStatusEnum.REJECTED_BY_CLIENT]: 'Rejected by client',
    [ApplicationStatusEnum.WITHDRAWN]: 'Partner withdrawn',
    [ApplicationStatusEnum.ACCEPTED]: 'Accepted',
    [ApplicationStatusEnum.INTERVIEW]: 'Interview',
    [ApplicationStatusEnum.INTERVIEW_SCHEDULING]: 'Interview Scheduling',
    [ApplicationStatusEnum.INTERVIEW_FINISHED]: 'Interview Finished',
    [ApplicationStatusEnum.TECH_TEST]: 'Tech test',
    [ApplicationStatusEnum.OFFER]: 'Offer',
    [ApplicationStatusEnum.OFFER_ACCEPTED_NEGOTIATIONS]: 'Offer accepted - negotiations',
    [ApplicationStatusEnum.OFFER_ACCEPTED]: 'Offer accepted',
    [ApplicationStatusEnum.OFFER_REJECTED]: 'Offer rejected',
    [ApplicationStatusEnum.WON]: 'Won',
    [ApplicationStatusEnum.OPPORTUNITY_CLOSED]: 'Opportunity closed',
    [ApplicationStatusEnum.INVITED]: 'Invite sent',
}

export const ApplicationStatusLabel: StringMapping = {
    [ApplicationStatusEnum.NEW_APPLICATION]: 'New Application',
    [ApplicationStatusEnum.REVIEWING]: 'In Review',
    [ApplicationStatusEnum.INFO_REQUESTED]: 'Requested more info',
    [ApplicationStatusEnum.PRESENTED]: 'Presented to Client',
    [ApplicationStatusEnum.PRESENTED_IN_ANOTHER_OPPORTUNITY]: 'Presented in another opportunity',
    [ApplicationStatusEnum.ON_HOLD]: 'Opportunity put On-Hold',
    [ApplicationStatusEnum.REJECTED_BY_TA]: 'Rejected by Talent Alpha',
    [ApplicationStatusEnum.REJECTED_BY_CLIENT]: 'Rejected by Client',
    [ApplicationStatusEnum.WITHDRAWN]: 'Partner withdrawn',
    [ApplicationStatusEnum.ACCEPTED]: 'Scheduling interview',
    [ApplicationStatusEnum.INTERVIEW]: 'Interview',
    [ApplicationStatusEnum.INTERVIEW_SCHEDULING]: 'Interview Scheduling',
    [ApplicationStatusEnum.INTERVIEW_FINISHED]: 'Interview Finished',
    [ApplicationStatusEnum.TECH_TEST]: 'Tech Test',
    [ApplicationStatusEnum.OFFER]: 'Offer stage',
    [ApplicationStatusEnum.OFFER_ACCEPTED_NEGOTIATIONS]: 'Offer accepted - terms negotiation',
    [ApplicationStatusEnum.OFFER_ACCEPTED]: 'Offer accepted - terms agreed',
    [ApplicationStatusEnum.OFFER_REJECTED]: 'Offer rejected',
    [ApplicationStatusEnum.WON]: 'Won',
    [ApplicationStatusEnum.OPPORTUNITY_CLOSED]: 'Opportunity closed automatically',
    [ApplicationStatusEnum.INVITED]: 'Invite sent',
}

export interface SearchApplicationsFiltersOptions {
    cities: Array<string>
    countries: Array<string>
    partnerNames: Array<string>
    roles: Array<string>
    statuses: Array<string>
    maxRate: Nullable<number>
    minRate: Nullable<number>
}
export interface SearchApplicationsFilters extends SearchApplicationsFiltersOptions {
    availableFrom: Nullable<number>
    availableTo: Nullable<number>
    msa: Nullable<boolean>
}

export interface SearchApplicationsRequestParams extends SearchApplicationsFilters, Paging, Sorting {
    searchText: Nullable<string>
}

export interface SearchAllApplicationsFiltersOptions {
    opportunityOwners: Array<string>
    partnerNames: Array<string>
    clientNames: Array<string>
    opportunityInternalNames: Array<string>
    statusValues: Array<string>
}

export interface SearchAllApplicationsFilters extends SearchAllApplicationsFiltersOptions {
    opportunityOwners: Array<string>
    partnerNames: Array<string>
    clientNames: Array<string>
    opportunityInternalNames: Array<string>
    statusValues: Array<string>
}

export interface SearchAllApplicationsRequestParams extends SearchAllApplicationsFilters, Paging, Sorting {
    searchText: Nullable<string>
    internalManager?: boolean
}

export interface ApplicationStatus {
    draft?: boolean
    label: string
    rejectionReason?: string
    rejectionReasonComment?: Nullable<string>
    status: ApplicationStatusEnum
    interviewDate?: number
}

export interface ApplicationSkill {
    name: string
    rate: SkillProficiency
}
export interface Application {
    applicationId: string
    availableFrom: number
    availableTo: number
    city: string
    comment?: string
    contractRate: Nullable<number>
    country: string
    currency: string
    cvFileName: string
    firstName: string
    lastName: string
    msa: boolean
    partnerName: string
    preferredRate?: number
    negotiableRate?: number
    recentStatus: ApplicationStatus
    role: string
    skills: Array<ApplicationSkill>
    statusHistory: Array<ApplicationStatus>
    submittedBy: { email: Nullable<string>; firstName: Nullable<string>; lastName: Nullable<string> }
    submittedOn?: number
    internalRate?: number
    smeCloud: boolean
}
export interface SearchApplicationsResponseBody {
    applications: Array<Application>
    clientName: string
    currency: string
    generatedId: string
    internalName: string
    opportunityId: string
    opportunityTitle: string
    total: number
}

export interface SearchAllApplicationsResponseBody {
    applications: Array<AllApplicationsItem>
    total: number
}

export interface UpdateApplicationBody {
    contractRate?: Nullable<number>
    internalRate?: Nullable<number>
    status?: Omit<ApplicationStatus, 'label'> & { rejectionReasonComment?: string }
}

export interface OpportunitySummary {
    appliedWith: number
    currency: string
    customCvTemplate: boolean
    endDate: number
    location: OpportunityLocationResponse
    maxDealSize: number
    maxRate: number
    minDealSize: number
    minRate: number
    minYearsOfExperience: number
    opportunityId: string
    opportunityOfferType: OpportunityOfferTypeEnum
    opportunityTitle: string
    projectIndustry: string
    startDate: number
    vacancies: number
    availableForCurrentCompany?: boolean
}

export interface AllApplicationsItem {
    candidateName: string
    role: string
    partnerName: string
    contactPerson: Nullable<string>
    clientName: string
    opportunityInternalName: string
    internalRate: number
    currency: string
    status: ApplicationStatusEnum
    opportunityId: string
    contractRate: Nullable<number>
    applicationId: string
}
export interface ApplicationFormData {
    applications: Array<{
        internalRate: number | ''
        status: ApplicationStatusEnum
    }>
}

export type ApplicationStatusColors = { [key in ApplicationStatusEnum]: { color: string; backgroundColor: string } }
