/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect, useState } from 'react'
import { ItemsContainer, MoreInfoRow, MoreInfoTitle, reduceArrayToString } from '../../../components/profile/MoreInfo'
import { SkillsList } from '../../../components/talent/SkillsList'
import { SpecialistGeneralInformation } from '../../../contracts/specialist/specialistGeneralInformation'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { SectionWrapper } from '../components/SectionWrapper'
import { OverviewForm } from './OverviewForm'

type OverviewProps = {
    generalInformation: SpecialistGeneralInformation
    specialistId: string
    focusLocation: boolean
    fetchGeneralInformation: () => void
    setForceFocus: Dispatch<SetStateAction<string>>
}

const Label = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: ${COLOR_PALETTE.gray_4};
`

const Overview: FunctionComponent<React.PropsWithChildren<OverviewProps>> = ({
    generalInformation,
    specialistId,
    fetchGeneralInformation,
    focusLocation,
    setForceFocus,
}) => {
    const [editing, setEditing] = useState(false)

    const theme = useTheme()

    const toggleEdit = useCallback(() => {
        setEditing(!editing)
    }, [editing])

    useEffect(() => {
        if (focusLocation) {
            setEditing(true)
        }
    }, [focusLocation])

    return (
        <SectionWrapper onEditClick={toggleEdit} showButton={!editing}>
            {editing ? (
                <OverviewForm
                    generalInformation={generalInformation}
                    specialistId={specialistId}
                    fetchGeneralInformation={fetchGeneralInformation}
                    toggleEdit={toggleEdit}
                    focusLocation={focusLocation}
                    setForceFocus={setForceFocus}
                />
            ) : (
                <div>
                    {' '}
                    <h5>Overview</h5>
                    <Label>Profile Summary</Label>
                    <p>{generalInformation.profileSummary || '-'}</p>
                    <div
                        css={css`
                            margin-bottom: 12px;
                        `}
                    >
                        <MoreInfoTitle>Top Skills: </MoreInfoTitle>
                        <div
                            css={css`
                                background-color: ${theme.colors.violet_1};
                                border-radius: 4px;
                                padding: 8px;
                            `}
                        >
                            <SkillsList skills={(generalInformation && generalInformation.verifiedTopSkills) || []} truncated topSkills />
                        </div>
                    </div>
                    <MoreInfoRow>
                        <MoreInfoTitle>Potential Roles:</MoreInfoTitle>
                        <ItemsContainer>{reduceArrayToString(generalInformation.potentialRoles) || '-'}</ItemsContainer>
                    </MoreInfoRow>
                    <MoreInfoRow>
                        <MoreInfoTitle>Industry Experience:</MoreInfoTitle>
                        <ItemsContainer>{reduceArrayToString(generalInformation.industries) || '-'}</ItemsContainer>
                    </MoreInfoRow>
                    <MoreInfoRow>
                        <MoreInfoTitle>Professional Responsibilities:</MoreInfoTitle>
                        <ItemsContainer>{reduceArrayToString(generalInformation.responsibilities) || '-'}</ItemsContainer>
                    </MoreInfoRow>
                    <MoreInfoRow>
                        <MoreInfoTitle>Country:</MoreInfoTitle>
                        <ItemsContainer>{generalInformation.country ? generalInformation.country : '-'}</ItemsContainer>
                    </MoreInfoRow>
                    <MoreInfoRow>
                        <MoreInfoTitle>City:</MoreInfoTitle>
                        <ItemsContainer>{generalInformation.city ? generalInformation.city : '-'}</ItemsContainer>
                    </MoreInfoRow>
                    <MoreInfoRow>
                        <MoreInfoTitle>Remote:</MoreInfoTitle>
                        <ItemsContainer>{generalInformation.remote ? 'Yes' : 'No'}</ItemsContainer>
                    </MoreInfoRow>
                </div>
            )}
        </SectionWrapper>
    )
}

export { Overview }
