/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, MouseEvent, useCallback } from 'react'

import { IconButton } from '../Icon'
import { mqMax } from '../../GlobalStyle'

interface ApplicationActionsProps {
    applicationId: string
    opportunityId?: string
    disabled?: boolean
    tooltip?: string
    handleEditingKeysChange: (id: string) => void
    handleInviteModalOpen?: (isOpen: boolean) => void
    handleChooseApplicationId?: (applicationId: string) => void
}

const ApplicationActions: FunctionComponent<React.PropsWithChildren<ApplicationActionsProps>> = ({
    applicationId,
    opportunityId,
    handleEditingKeysChange,
    handleInviteModalOpen,
    handleChooseApplicationId,
    disabled,
    tooltip,
}) => {
    const handleEditClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            handleEditingKeysChange(applicationId)
        },
        [applicationId, handleEditingKeysChange],
    )

    const handleModalOpen = (e: MouseEvent) => {
        e.stopPropagation()
        if (handleInviteModalOpen && handleChooseApplicationId) {
            handleInviteModalOpen(true)
            handleChooseApplicationId(applicationId)
        }
    }

    return (
        <Fragment>
            <div
                css={css`
                    justify-content: center;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    ${mqMax[1]} {
                        margin-top: 15px;
                        justify-content: space-between;
                    }
                `}
            >
                {tooltip ? (
                    <Tooltip title={tooltip}>
                        <IconButton disabled={disabled} name='edit' dataTestId='opportunity-edit-status' />
                    </Tooltip>
                ) : (
                    <IconButton name='edit' disabled={disabled} onClick={handleEditClick} dataTestId='opportunity-edit-status' />
                )}
                {opportunityId && (
                    <div>
                        <IconButton name='plane' onClick={handleModalOpen} dataTestId='opportunity-invite-candidate' />
                    </div>
                )}
            </div>
        </Fragment>
    )
}

export { ApplicationActions }
