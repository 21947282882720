/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { Dropzone, DropzoneProps } from '../inputs/Dropzone'
import { ControlledFieldProps } from './types'

type ControlledDropzoneProps = Omit<DropzoneProps, 'onChange' | 'errorMessage'> &
    ControlledFieldProps<Array<File> | File> & {
        showError?: boolean
    }

const ControlledDropzone: FunctionComponent<React.PropsWithChildren<ControlledDropzoneProps>> = ({
    onError,
    isDarkMode,
    accept,
    multiple,
    parser,
    dropErrorMessages,
    dropzoneContent,
    showError,
    dataTestId,
    maxSize,
    control,
    name,
    infoMessage,
    label,
    labelTooltip,
    ...rest
}) => {
    const {
        field: { onChange },
        fieldState: { error },
    } = useController({
        name,
        control,
    })

    return (
        <Dropzone
            infoMessage={infoMessage}
            label={label}
            labelTooltip={labelTooltip}
            onChange={onChange}
            onError={onError}
            isDarkMode={isDarkMode}
            accept={accept}
            multiple={multiple}
            parser={parser}
            dropErrorMessages={dropErrorMessages}
            dropzoneContent={dropzoneContent}
            errorMessage={showError ? error?.message : undefined}
            errorTooltipMessage={error?.types?.tooltip as string}
            dataTestId={dataTestId}
            maxSize={maxSize}
            {...rest}
        />
    )
}

export { ControlledDropzone }
