import { axios } from '../../api/axios'
import { SkillForm } from '../../contracts/skillForm'

const specialistsEndpoint = '/specialists'

const getSpecialistSkillForms = (specialistId: string): Promise<Array<SkillForm>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/skillForms`)
}

export { getSpecialistSkillForms }
