/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { getSvg, IconButton } from '../../../components/Icon'
import { FlexBox, FlexColumn, FlexColumns } from '../../../components/layout/FlexBoxHelpers'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { Modal, ModalProps } from '../../../components/Modal'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { AxiosApiError } from '../../../contracts/common/apiError'
import { Specialist } from '../../../contracts/specialist/specialist'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'
import { useSpecialistRoles } from '../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../redux/Store'
import { Modify } from '../../../types'
import { hasErrors } from '../../../utils/errors'
import { SPECIALIST_DUPLICATED } from '../../../validation/validation-messages'
import { yup } from '../../../validation/yup'
import { addSpecialists } from '../api'

const checkLocalUniq = (email = '', context: any) => {
    const fields = context.from[1].value.specialists
    const occurrences = fields.filter((field: any) => field.email === email).length
    return !email || occurrences === 1
}

type AddSpecialistModalProps = Modify<
    ModalProps,
    {
        onClose: (specialists: Array<string>) => void
    }
>

const initialSpecialists = [
    {
        firstName: '',
        lastName: '',
        email: '',
        role: '',
    },
]

const AddSpecialistsModal: FunctionComponent<React.PropsWithChildren<AddSpecialistModalProps>> = ({ onClose, opened }) => {
    const {
        actions: { layoutToggleLoader },
        selectors: {
            featureFlags: { optionalSpecialistEmail },
        },
    } = useContext(ReduxContext)

    const schema = useMemo(
        () =>
            yup.object().shape({
                specialists: yup.array().of(
                    yup.object().shape({
                        firstName: yup.string().required(),
                        lastName: yup.string().required(),
                        role: yup.string().required(),
                        ...(!optionalSpecialistEmail && {
                            email: yup.string().email().required().test('async-email-unique', SPECIALIST_DUPLICATED, checkLocalUniq),
                        }),
                    }),
                ),
            }),
        [optionalSpecialistEmail],
    )

    const specialistRoles = useSpecialistRoles()
    const { addError } = useNotifications()
    const {
        control,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            specialists: initialSpecialists,
        },
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'specialists',
    })

    const onAddSpecialistsSuccess = useCallback(
        (specialists: Array<Specialist>) => {
            onClose(specialists.map(specialist => specialist.email || ''))
        },
        [onClose],
    )

    useEffect(() => {
        if (opened) {
            reset({
                specialists: [],
            })
            append(initialSpecialists)
        }
    }, [reset, opened, append])

    const handleClose = useCallback(() => {
        reset({
            specialists: initialSpecialists,
        })
        onClose([])
    }, [reset, onClose])

    const onAddSpecialistsError = useCallback(
        ({ data: error }: AxiosApiError<any>) => {
            if (error.code === 'SPECIALIST_EMAIL_EXISTS') {
                const specialists = watch('specialists')
                const emails = error.data.email
                const emailArray = emails.split(',')
                const indexes: number[] = []

                emailArray.forEach((email: string) => {
                    const index = specialists.findIndex(e => e.email === email)
                    indexes.push(index)
                })

                indexes.forEach((index: number) => {
                    setError(`specialists.${index}.email`, { type: 'required', message: SPECIALIST_DUPLICATED })
                })
            } else {
                addError()
            }
        },
        [setError, watch, addError],
    )

    const onSubmit = useCallback(
        (data: any) => {
            layoutToggleLoader(true)
            addSpecialists(data.specialists)
                .then(() => onAddSpecialistsSuccess(data.specialists))
                .catch(onAddSpecialistsError)
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, onAddSpecialistsError, onAddSpecialistsSuccess],
    )

    return (
        <Modal onClose={handleClose} opened={opened} maskClosable={false} size='content'>
            <h5>Create New Specialist</h5>
            <p
                css={css`
                    color: ${COLOR_PALETTE.gray_4};
                `}
            >
                Create Basic Profiles for New Specialist and send invites to let them add more information to their Profiles.
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    {fields.map((item, index) => (
                        <FlexColumns key={item.id}>
                            <FlexColumn>
                                <FieldWrapper noLabel>
                                    <ControlledInput
                                        placeholder='First name'
                                        name={`specialists.${index}.firstName`}
                                        defaultValue={item.firstName}
                                        control={control}
                                        shouldUnregister={false}
                                    />
                                </FieldWrapper>
                            </FlexColumn>
                            <FlexColumn>
                                <FieldWrapper noLabel>
                                    <ControlledInput
                                        placeholder='Last name'
                                        name={`specialists.${index}.lastName`}
                                        defaultValue={item.lastName}
                                        control={control}
                                        shouldUnregister={false}
                                    />
                                </FieldWrapper>
                            </FlexColumn>
                            {!optionalSpecialistEmail && (
                                <FlexColumn>
                                    <FieldWrapper noLabel>
                                        <ControlledInput
                                            placeholder='E-mail'
                                            name={`specialists.${index}.email`}
                                            defaultValue={item.email}
                                            control={control}
                                            shouldUnregister={false}
                                        />
                                    </FieldWrapper>
                                </FlexColumn>
                            )}
                            <FlexColumn>
                                <FieldWrapper noLabel>
                                    <ControlledAutocompleteSelect
                                        dataTestId='add-specialist-role'
                                        placeholder='Role'
                                        options={specialistRoles}
                                        control={control}
                                        name={`specialists.${index}.role`}
                                        styles={css`
                                            ${mqMin[1]} {
                                                width: 250px;
                                            }
                                        `}
                                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                        shouldUnregister={false}
                                    />
                                </FieldWrapper>
                            </FlexColumn>
                            <FlexColumn shrinkable minWidth='40px'>
                                {(fields.length > 1 || index >= 1) && (
                                    <IconButton name='small-close' onClick={() => remove(index)} title='Remove' />
                                )}
                            </FlexColumn>
                        </FlexColumns>
                    ))}
                </div>
                <Button variant='linkForm' onClick={() => append(initialSpecialists)}>
                    + Add One More
                </Button>
                <Divider />
                <FlexBox justifyContent='flex-end'>
                    <Button
                        variant='text'
                        css={css`
                            margin-right: 8px;
                        `}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant='primary'
                        icon={getSvg('user-add')}
                        type='submit'
                        disabled={hasErrors(errors)}
                        dataTestId='create-specialist-confirmation'
                    >
                        Create New Specialist
                    </Button>
                </FlexBox>
            </form>
        </Modal>
    )
}

export { AddSpecialistsModal }
