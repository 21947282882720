/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { DragDropContext, OnDragEndResponder } from '@hello-pangea/dnd'
import { CardsType, KanbanBoardProps, KanbanColumnType } from './contract'
import { KanbanColumn } from './KanbanColumn'
import { generateState, getCardsCount } from './utils'

const KanbanBoard = <T,>({ columnsList, cardsList, onDragEnd, cardRenderer, kanbanIncrementFlag }: KanbanBoardProps<T>) => {
    const [columns, setColumns] = useState<Array<KanbanColumnType>>(columnsList.map(column => ({ ...column, isCollapsed: false })))
    const [cards, setCards] = useState<CardsType<T>>(generateState<T>(columns, cardsList))

    const handleColumnClick = useCallback((index: number) => {
        setColumns(previousColumns =>
            previousColumns.map((previousColumn, previousColumnIndex) =>
                previousColumnIndex === index ? { ...previousColumn, isCollapsed: !previousColumn.isCollapsed } : previousColumn,
            ),
        )
    }, [])

    const handleDragEnd: OnDragEndResponder = useCallback(
        result => {
            const { source, destination } = result

            if (!destination) {
                return
            }

            setCards(previousCards => {
                const card = previousCards[source.droppableId][source.index]
                const cardWithNewColumnId = { ...card, columnId: destination.droppableId }
                let sourceArray = previousCards[source.droppableId].slice()
                sourceArray.splice(source.index, 1)
                const cardsWithChangedSource = { ...previousCards, [source.droppableId]: sourceArray }
                let destinationArray = cardsWithChangedSource[destination.droppableId].slice()
                destinationArray.splice(destination.index, 0, cardWithNewColumnId)
                const cardsWithChangedDestination = { ...cardsWithChangedSource, [destination.droppableId]: destinationArray }
                onDragEnd(cardWithNewColumnId, cardsWithChangedDestination)
                return cardsWithChangedDestination
            })
        },
        [onDragEnd],
    )

    useEffect(() => {
        if (cardsList.length !== getCardsCount(cards)) {
            setCards(generateState<T>(columns, cardsList))
        }
    }, [cards, cardsList, columns])

    useEffect(() => {
        setCards(generateState<T>(columns, cardsList))
    }, [kanbanIncrementFlag, columns, cardsList])

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div
                css={css`
                    display: inline-flex;
                    padding: 20px 0;
                    overflow: hidden;
                `}
            >
                {columns.map((column, index) => (
                    <KanbanColumn<T>
                        key={column.id}
                        column={column}
                        cardRenderer={cardRenderer}
                        cards={cards[column.id]}
                        columnIndex={index}
                        handleColumnClick={handleColumnClick}
                    />
                ))}
            </div>
        </DragDropContext>
    )
}

export { KanbanBoard }
