/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Container, Wrapper } from './BottomBar.styles'

type BottomBarProps = {
    containerStyles?: SerializedStyles
    styles?: SerializedStyles
}

const BottomBar: FunctionComponent<React.PropsWithChildren<BottomBarProps>> = ({ children, styles, containerStyles }) => (
    <Container containerStyles={containerStyles}>
        <Wrapper styles={styles}>{children}</Wrapper>
    </Container>
)

export { BottomBar }
