/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, SyntheticEvent, useCallback, useContext, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { app2TileGroups, AppTileConfig, AppTileGroup } from '../../../contracts/applications'
import { COLOR_PALETTE, mqMax, mqMin } from '../../../GlobalStyle'
import { useApplications } from '../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../redux/Store'
import { FAQ_APP } from '../../../pages/Faq/consts'
import { Divider } from '../../Divider'
import { AppIcon, Icon } from '../../Icon'
import { ContentLoader } from '../Loader'
import CreateCompanyButton from './CreateCompanyButton'
import { TabType } from './Header'
import { HeaderBox } from './HeaderBox'

const iconStyles = css`
    width: 20px;
    height: 20px;
`

type LeftMenuProps = {
    label?: string
}

type AppLinkProps = {
    app: AppTileConfig
    current: boolean
}

const AppLink: FunctionComponent<React.PropsWithChildren<AppLinkProps>> = ({ app, current }) => {
    const theme = useTheme()

    const {
        actions: { layoutToggleActiveTab },
    } = useContext(ReduxContext)

    const closeMenu = useCallback(() => {
        layoutToggleActiveTab(TabType.leftMenu)
    }, [layoutToggleActiveTab])

    const handleClick = (e: SyntheticEvent) => {
        if (app.status === 'disabled' || app.status === 'unavailable') {
            return e.preventDefault()
        } else {
            closeMenu()
        }
    }
    return (
        <Fragment>
            {app.url ? (
                <a
                    href={app.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    onClick={handleClick}
                    css={css`
                        display: flex;
                        min-height: 48px;
                        margin-left: 17px;
                        align-items: center;
                        cursor: ${app.status === 'available' ? 'pointer' : 'not-allowed'};
                        &:hover {
                            .icon {
                                background-color: ${app.status === 'available' ? theme.colors.main : 'inherit'};
                                ${app.status === 'available' && theme.colors.text === COLOR_PALETTE.white && 'img { filter:invert(); }'}
                            }
                        }
                    `}
                >
                    <AppIcon
                        size={30}
                        src={app.iconUrl}
                        status={app.status}
                        variant='menu'
                        className='icon'
                        css={css`
                            flex-shrink: 0;
                            ${current &&
                            app.status !== 'disabled' &&
                            app.status !== 'unavailable' &&
                            `background-color: ${theme.colors.main};`};
                            ${current &&
                            app.status !== 'disabled' &&
                            app.status !== 'unavailable' &&
                            theme.colors.text === COLOR_PALETTE.white &&
                            'img { filter:invert(); }'}
                        `}
                    />
                    <span
                        className='text'
                        css={css`
                            margin-left: 14px;
                            color: ${app.status === 'available' ? COLOR_PALETTE.gray_6 : COLOR_PALETTE.gray_3};
                            font-weight: ${current ? '500' : 'inherit'};
                        `}
                    >
                        {app.title}
                    </span>
                </a>
            ) : (
                <Link
                    to={app.to}
                    onClick={handleClick}
                    css={css`
                        display: flex;
                        min-height: 48px;
                        margin-left: 17px;
                        align-items: center;
                        cursor: ${app.status === 'available' ? 'pointer' : 'not-allowed'};
                        &:hover {
                            .icon {
                                background-color: ${app.status === 'available' ? theme.colors.main : 'inherit'};
                                ${app.status === 'available' && theme.colors.text === COLOR_PALETTE.white && 'img { filter:invert(); }'}
                            }
                        }
                    `}
                >
                    <AppIcon
                        size={30}
                        src={app.iconUrl}
                        status={app.status}
                        variant='menu'
                        className='icon'
                        css={css`
                            flex-shrink: 0;
                            ${current &&
                            app.status !== 'disabled' &&
                            app.status !== 'unavailable' &&
                            `background-color: ${theme.colors.main}`};
                            ${current &&
                            app.status !== 'disabled' &&
                            app.status !== 'unavailable' &&
                            theme.colors.text === COLOR_PALETTE.white &&
                            'img { filter:invert(); }'}
                        `}
                    />
                    <span
                        className='text'
                        css={css`
                            margin-left: 14px;
                            color: ${app.status === 'available' ? COLOR_PALETTE.gray_6 : COLOR_PALETTE.gray_3};
                            font-weight: ${current ? '500' : 'inherit'};
                        `}
                    >
                        {app.title}
                    </span>
                </Link>
            )}
        </Fragment>
    )
}

type HomeLinkProps = {
    closeMenu: () => void
    current: boolean
}

const HomeLink: FunctionComponent<React.PropsWithChildren<HomeLinkProps>> = ({ closeMenu, current }) => {
    const theme = useTheme()

    const handleClose = () => {
        closeMenu()
    }

    return (
        <Link
            to='/dashboard'
            css={css`
                display: flex;
                min-height: 48px;
                margin-left: 17px;
                align-items: center;
                cursor: pointer;
                &:hover {
                    .icon {
                        background-color: ${theme.colors.main};
                        ${theme.colors.text === COLOR_PALETTE.white && 'img { filter:invert(); }'}
                    }
                }
            `}
            onClick={handleClose}
        >
            <AppIcon
                size={30}
                name='cloud'
                variant='menu'
                className='icon'
                css={css`
                    flex-shrink: 0;
                    ${current && ` background-color: ${theme.colors.main}; `};
                    ${current && theme.colors.text === COLOR_PALETTE.white && 'img { filter:invert(); }'}
                `}
            />
            <span
                className='text'
                css={css`
                    margin-left: 14px;
                    font-weight: ${current ? '500' : 'inherit'};
                `}
            >
                Home
            </span>
        </Link>
    )
}

const isCurrent = (currentPath: string, appTo: string): boolean => {
    const splitPath = currentPath.split('/')
    const subApps = splitPath.length > 2

    return subApps ? appTo !== '' && splitPath[1] === appTo.replace('/', '') : appTo !== '' && currentPath !== '/' && currentPath === appTo
}

type AppListProps = AppTileGroup & { currentPath: string }

const AppList: FunctionComponent<React.PropsWithChildren<AppListProps>> = ({ apps, currentPath }) => {
    return (
        <ul
            css={css`
                list-style: none;
                padding: 0;
                margin: 0;
            `}
        >
            {apps &&
                apps.map((app, index) => (
                    <li key={index}>
                        <AppLink app={app} current={isCurrent(currentPath, app.to)} />
                    </li>
                ))}
        </ul>
    )
}

type AppGroupsProps = {
    groups: Array<AppTileGroup>
    closeMenu: () => void
}

type AppGroupWrapperProps = {
    isEmpty: boolean
}

const AppGroupWrapper = styled.div<AppGroupWrapperProps>`
    border-top: 'none';
    display: ${({ isEmpty }: any) => (isEmpty ? 'none' : 'block')};
`

const AppGroups: FunctionComponent<React.PropsWithChildren<AppGroupsProps>> = ({ groups, closeMenu }) => {
    const currentPath: string = useLocation().pathname

    const newDashboardApps = groups.filter(
        group => group.name === 'Your Free Apps:' || group.name === 'Apps available with a Company Account:',
    )

    return (
        <div>
            <HomeLink closeMenu={closeMenu} current={currentPath.endsWith('/dashboard')} />

            {newDashboardApps.map(group => (
                <AppGroupWrapper key={group.name} isEmpty={group.apps.length === 0}>
                    {group.name === 'Apps available with a Company Account:' && <Divider />}
                    <AppList apps={group.apps} currentPath={currentPath} />
                    {group.name === 'Apps available with a Company Account:' && (
                        <div
                            css={css`
                                margin-top: 16px;
                            `}
                        >
                            <CreateCompanyButton closeMenu={closeMenu} />
                        </div>
                    )}
                </AppGroupWrapper>
            ))}
        </div>
    )
}

const LeftMenu: FunctionComponent<React.PropsWithChildren<LeftMenuProps>> = ({ label = 'Your apps:' }) => {
    const {
        actions: { layoutToggleActiveTab },
    } = useContext(ReduxContext)
    const applications = useApplications()
    const appsWithFaq = useMemo(() => {
        const result = applications?.slice()

        if (result) result.push(FAQ_APP)

        return result
    }, [applications])
    const appTileGroups = useMemo(() => (appsWithFaq ? app2TileGroups(appsWithFaq) : null), [appsWithFaq])

    const closeMenu = useCallback(() => {
        layoutToggleActiveTab(TabType.leftMenu)
    }, [layoutToggleActiveTab])

    return (
        <div
            css={css`
                ${mqMin[1]} {
                    width: 300px;
                    min-height: 100vh;
                }
                ${mqMax[1]} {
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    position: fixed;
                }
            `}
        >
            <div
                css={css`
                    height: 57px;
                    display: flex;
                    justify-content: flex-start;
                `}
            >
                <HeaderBox
                    onClick={closeMenu}
                    style={css`
                        &:before {
                            display: none;
                        }
                        @media (hover: hover) and (pointer: fine) {
                            &:hover {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    `}
                >
                    <Icon name='grid' style={iconStyles} />
                </HeaderBox>
            </div>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    ${mqMax[1]} {
                        left: 0;
                        width: 100%;
                        background-color: ${COLOR_PALETTE.white};
                    }
                    ${mqMin[2]} {
                        background-color: ${COLOR_PALETTE.white};
                        position: relative;
                    }
                `}
            >
                {!appTileGroups ? <ContentLoader /> : <AppGroups closeMenu={closeMenu} groups={appTileGroups} />}
            </div>
        </div>
    )
}

export { LeftMenu }
