/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithGoogle, signInWithMicrosoft } from '../api/firebase'
import google from '../assets/logos/google.svg'
import linkedin_color from '../assets/logos/linkedin_color.svg'
import microsoft from '../assets/logos/microsoft.svg'
import { mqMax } from '../GlobalStyle'
import { ReduxContext } from '../redux/Store'
import { useQuery } from '../utils/useQuery'
import { Button } from './Button'
import { Divider } from './Divider'
import { GoogleLoginModal } from './GoogleLoginModal'

type AlternativeAuthMethodsProps = {
    buttonsStyle?: SerializedStyles
    styles?: SerializedStyles
    microsoftButtonText?: string
    googleButtonText?: string
    dividerText?: string
}

const AlternativeAuthMethods: FunctionComponent<React.PropsWithChildren<AlternativeAuthMethodsProps>> = ({
    styles,
    buttonsStyle,
    microsoftButtonText = 'Microsoft',
    googleButtonText = 'Google',
    dividerText = 'or continue with',
}) => {
    const [isGoogleLoginModalVisible, setIsGoogleLoginModalVisible] = useState<boolean>(false)
    const navigate = useNavigate()
    const query = useQuery()
    const {
        selectors: { userId, user },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (userId) {
            const afterLogin = query.get('afterLogin')

            if (!afterLogin) {
                navigate('/dashboard')
                return
            }

            const autoLogoutsRaw = sessionStorage.getItem('autoLogouts')
            const autoLogouts = autoLogoutsRaw ? JSON.parse(autoLogoutsRaw) : {}

            if (autoLogouts[user.email] === afterLogin || autoLogouts['unknown-user'] === afterLogin) {
                if (autoLogouts[user.email] === afterLogin) {
                    delete autoLogouts[user.email]
                    sessionStorage.setItem('autoLogouts', JSON.stringify(autoLogouts))
                } else if (autoLogouts['unknown-user'] === afterLogin) {
                    delete autoLogouts['unknown-user']
                    sessionStorage.setItem('autoLogouts', JSON.stringify(autoLogouts))
                }

                navigate(afterLogin)
            } else {
                navigate('/dashboard')
            }
        }
    }, [navigate, query, user, userId])

    const toggleGoogleLoginModal = useCallback(() => {
        setIsGoogleLoginModalVisible(currentIsGoogleLoginModalVisible => !currentIsGoogleLoginModalVisible)
    }, [])

    const handleMicrosoftSSO = async () => {
        await signInWithMicrosoft(toggleGoogleLoginModal)
    }

    const handleGoogleSSO = async () => {
        await signInWithGoogle()
    }

    return (
        <Fragment>
            <Divider
                style={css`
                    margin-bottom: 16px;
                `}
                text={dividerText}
            />
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    ${mqMax[0]} {
                        flex-direction: column;
                    }
                    ${styles}
                `}
            >
                <Button
                    variant='sso'
                    onClick={handleMicrosoftSSO}
                    icon={microsoft}
                    style={css`
                        width: calc(50% - 5px);
                        ${mqMax[0]} {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                        ${buttonsStyle}
                    `}
                >
                    {microsoftButtonText}
                </Button>
                <Button
                    variant='sso'
                    onClick={handleGoogleSSO}
                    icon={google}
                    style={css`
                        width: calc(50% - 5px);
                        ${mqMax[0]} {
                            margin-bottom: 16px;
                            width: 100%;
                        }
                        ${buttonsStyle}
                    `}
                >
                    {googleButtonText}
                </Button>

                {false && (
                    <Button
                        variant='sso'
                        icon={linkedin_color}
                        style={css`
                            width: calc(33% - 5px);
                            ${mqMax[0]} {
                                margin-bottom: 16px;
                                width: 100%;
                            }
                            ${buttonsStyle}
                        `}
                    >
                        LinkedIn
                    </Button>
                )}
            </div>
            <GoogleLoginModal opened={isGoogleLoginModalVisible} onClose={toggleGoogleLoginModal} />
        </Fragment>
    )
}

export { AlternativeAuthMethods }
