import styled from '@emotion/styled'

const SideMenuBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
    border: 1px solid ${props => props.theme.colors.violet_2};
    background-color: ${props => props.theme.colors.white};
    border-radius: 4px;
    padding: 32px 32px 24px;
`

export { SideMenuBox }
