import { Control } from 'react-hook-form'

import { Box } from 'src/components/opportunities-manager/Box'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { ControlledCheckbox } from 'src/components/forms/ControlledCheckbox'
import { UpdateOpportunityFormData } from 'src/contracts/opportunities-manager/contracts'

interface OwnProps {
    control: Control<UpdateOpportunityFormData>
}

const CustomCVTemplate: React.FC<OwnProps> = ({ control }) => {
    return (
        <Box>
            <h6>Custom CV Template</h6>
            <FieldWrapper noLabel noError>
                <ControlledCheckbox
                    control={control}
                    name='internalDetails.customCvTemplate'
                    checkboxLabel='Use Custom CV Template'
                    checkboxTooltip='If a custom template is used, everyone applying to this opportunity will see a warning message about this CV template. Please contact TA dev team to replace this template.'
                />
            </FieldWrapper>
        </Box>
    )
}

export { CustomCVTemplate }
