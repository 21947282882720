/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useWindowSize } from 'react-use'
import { getCities } from '../../api/api'
import { Country } from '../../contracts/country'
import { SpecialistProject, SpecialistProjectForm } from '../../contracts/specialist/specialistProject'
import { breakpoints, mqMax } from '../../GlobalStyle'
import { useCountries, useIndustries, useSkills, useSpecialistRoles } from '../../redux/dictionaryDataHooks'
import { Nullable } from '../../types'
import { monthFormat } from '../../utils/dates'
import { hasErrors } from '../../utils/errors'
import { INVALID_DATE_RANGE } from '../../validation/validation-messages'
import { yup } from '../../validation/yup'
import { Button } from '../Button'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../forms/ControlledAutocompleteSelect'
import { ControlledCheckbox } from '../forms/ControlledCheckbox'
import { ControlledDatePickerInput } from '../forms/ControlledDatePickerInput'
import { ControlledInput } from '../forms/ControlledInput'
import { ControlledSuggestionInput } from '../forms/ControlledSuggestionInput'

import { IconButton } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { FieldWrapper } from '../layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../layout/ResponsiveColumns'
import { ButtonContainer } from '../shared/shared-styles'
import { ProjectBoxProps } from './ProjectBox'
import { ControlledTextEditor } from '../forms/ControlledTextEditor'

const initialValues: SpecialistProjectForm = {
    role: '',
    currentlyWorkingThere: false,
    from: null,
    to: null,
    project: '',
    industry: '',
    stack: [],
    description: '',
    country: '',
    city: '',
    company: '',
}

const ROLE_NOT_FOUND_ERROR_MESSAGE = 'Role not found in our system'

const schema = yup.object().shape({
    role: yup.string().required(),
    from: yup.number().nullable().required(),
    to: yup
        .number()
        .nullable()
        .when(['currentlyWorkingThere'], {
            is: false,
            then: yup.number().min(yup.ref('from'), INVALID_DATE_RANGE).required(),
        }),
    project: yup.string(),
    industry: yup.string(),
    stack: yup.array(yup.string()),
    description: yup.string(),
    country: yup.string(),
    city: yup.string(),
    company: yup.string().required(),
})

const ProjectBoxEdit: FunctionComponent<React.PropsWithChildren<ProjectBoxProps>> = ({
    project,
    index,
    onSaveClicked,
    onCancelClicked,
    forceValidation,
    canCancel,
    removable,
    onDeleteClicked,
    saving,
    setIsDirty,
}) => {
    const roles = useSpecialistRoles()
    const {
        handleSubmit,
        control,
        watch,
        getValues,
        setValue,
        formState: { errors, touchedFields, isDirty, isSubmitting },
        reset,
        clearErrors,
        trigger,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: initialValues,
    })

    const countries = useCountries()?.map((country: Country) => country.name) || null
    const industries = useIndustries()
    const skills = useSkills()
    const watchCurrentlyWorkingThere = watch('currentlyWorkingThere')
    const watchCountry = watch('country')
    const [formDataSetCount, setFormDataSetCount] = useState(0)
    const [savedProject, setSavedProject] = useState<Nullable<SpecialistProject>>(null)

    useEffect(() => {
        if (forceValidation && formDataSetCount) {
            trigger()
        }
    }, [forceValidation, trigger, formDataSetCount])

    useEffect(() => {
        if (!project) {
            reset({ currentlyWorkingThere: false })
            setFormDataSetCount(currentFormDataSetCount => currentFormDataSetCount + 1)
        } else {
            if (JSON.stringify(savedProject) !== JSON.stringify(project)) {
                reset({
                    role: project.role || initialValues.role,
                    currentlyWorkingThere: project.currentlyWorkingThere || initialValues.currentlyWorkingThere,
                    from: project.from || initialValues.from,
                    to: project.to || initialValues.to,
                    project: project.project || initialValues.project,
                    industry: project.industry || initialValues.industry,
                    stack: project.stack || initialValues.stack,
                    description: project.description || initialValues.description,
                    country: project.country || initialValues.country,
                    city: project.city || initialValues.city,
                    company: project.company || initialValues.company,
                })
                setSavedProject(project)
                setFormDataSetCount(currentFormDataSetCount => currentFormDataSetCount + 1)
            }
        }
    }, [reset, project, savedProject])

    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    const getCitiesForCountry = useCallback((fiter: string) => getCities(watchCountry, fiter), [watchCountry])

    const onSubmit = (formData: SpecialistProjectForm): void => {
        onSaveClicked(index, formData)
    }

    const onCancel = (): void => {
        onCancelClicked(index)
    }
    const handleClearErrors = useCallback(
        (v: Array<boolean>) => {
            clearErrors('to')
            return v
        },
        [clearErrors],
    )

    const { width } = useWindowSize()

    useEffect(() => {
        if (setIsDirty) {
            setIsDirty(isDirty, index)
        }
        return () => {
            if (setIsDirty) {
                setIsDirty(false, index)
            }
        }
    }, [isDirty, index, setIsDirty])

    return (
        <Fragment>
            <form data-testid='project-box-edit' onSubmit={handleSubmit(onSubmit)}>
                <FieldWrapper>
                    <ControlledInput name='company' placeholder='Company' label='Company' control={control} />
                </FieldWrapper>
                <FieldWrapper>
                    <Tooltip
                        visible={errors.role?.message === ROLE_NOT_FOUND_ERROR_MESSAGE}
                        placement={width > breakpoints[1] ? 'right' : 'bottom'}
                        title='Unfortunately, the role we found in your file is not present in our system. Please select the best matching role from this list.'
                    >
                        <ControlledAutocompleteSelect
                            label='Role'
                            placeholder='Role'
                            options={roles}
                            control={control}
                            name='role'
                            dropdownWidth='100%'
                            defaultValues={[getValues('role')]}
                            styles={css`
                                width: ${errors.role?.message === ROLE_NOT_FOUND_ERROR_MESSAGE ? 'calc(100% - 250px)' : '100%'};
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </Tooltip>
                </FieldWrapper>

                <ResponsiveColumns>
                    <FirstColumn>
                        <FieldWrapper>
                            <ControlledDatePickerInput
                                format={monthFormat}
                                picker='month'
                                control={control}
                                name='from'
                                label='From'
                                placeholder='MMM YYYY'
                            />
                        </FieldWrapper>
                    </FirstColumn>

                    <SecondColumn>
                        <FieldWrapper>
                            <ControlledDatePickerInput
                                control={control}
                                format={monthFormat}
                                picker='month'
                                placeholder='MMM YYYY'
                                disabled={watchCurrentlyWorkingThere}
                                name='to'
                                label='To'
                            />
                        </FieldWrapper>
                    </SecondColumn>
                </ResponsiveColumns>

                <ControlledCheckbox
                    checkboxLabel='Currently working there'
                    defaultValue={project.currentlyWorkingThere || false}
                    style={css`
                        margin-bottom: 16px;
                    `}
                    control={control}
                    beforeChange={handleClearErrors}
                    name='currentlyWorkingThere'
                />

                <FieldWrapper>
                    <ControlledInput name='project' placeholder='Project (optional)' label='Project (optional)' control={control} />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Industry (optional)'
                        placeholder='Industry (optional)'
                        options={industries}
                        control={control}
                        defaultValues={[getValues('industry')]}
                        name='industry'
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Stack/Technologies (optional)'
                        placeholder='Stack/Technologies (optional)'
                        defaultValues={getValues('stack') && getValues('stack').length > 0 ? getValues('stack') : []}
                        virtualized
                        options={skills}
                        multiple
                        control={control}
                        name='stack'
                        dropdownWidth='100%'
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledTextEditor
                        control={control}
                        name='description'
                        label='Description (optional)'
                        placeholder='Tell Us More About Your Role (optional)'
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Country (optional)'
                        placeholder='Country (optional)'
                        options={countries}
                        control={control}
                        defaultValues={[getValues('country')]}
                        name='country'
                        dataTestId='project-box-stack-country'
                        canClear
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledSuggestionInput
                        label='City (optional)'
                        placeholder='Choose city'
                        fetchOptions={getCitiesForCountry}
                        disabled={!watchCountry}
                        control={control}
                        name='city'
                        allowOnlyValuesFromDictionary
                        fetchFromLetterNumber={1}
                        dataTestId='project-box-stack-city'
                    />
                </FieldWrapper>

                <FlexBox alignItems='center' justifyContent={removable ? 'space-between' : 'flex-end'}>
                    {removable && <IconButton name='trash' size={32} onClick={() => onDeleteClicked(index)} />}
                    <ButtonContainer>
                        {canCancel && (
                            <Button
                                css={css`
                                    margin-right: 28px;
                                `}
                                variant='link'
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            type='submit'
                            variant='primary'
                            disabled={isSubmitting || !isDirty || hasErrors(errors) || saving}
                            dataTestId='save-project'
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </ButtonContainer>
                </FlexBox>
            </form>
        </Fragment>
    )
}

export { ProjectBoxEdit }
