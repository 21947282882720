/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { signInWithEmailAndPassword } from '../api/firebase'
import { ReduxContext } from '../redux/Store'
import { COLOR_PALETTE } from '../theme/colors'
import { Nullable } from '../types'
import { hasErrors } from '../utils/errors'
import { useLogger } from '../utils/useLogger'
import { useQuery } from '../utils/useQuery'
import { yup } from '../validation/yup'
import { Alert } from './Alert'
import { AlternativeAuthMethods } from './AlternativeAuthMethods'
import { Button } from './Button'
import { ControlledInput } from './forms/ControlledInput'
import { HiddenInput } from './HiddenInput'
import { FieldWrapper } from './layout/FormHelpers'
import { useNotifications } from './notification/NotificationProvider'

const schema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
})

type FormValues = {
    email: string
    password: string
}

const ManagerLoginForm: FunctionComponent<
    React.PropsWithChildren<{
        header?: ReactNode
        showRegistrationLink?: boolean
        buttonText?: string
        forcedEmail?: string
        styles?: SerializedStyles
    }>
> = ({ header, showRegistrationLink, buttonText = 'Log in', forcedEmail, styles }) => {
    const {
        actions: { layoutToggleLoader, setShouldWaitForUserContexts, setShouldWaitForWhiteLabel },
    } = useContext(ReduxContext)
    const query = useQuery()
    const { addError } = useNotifications()
    const log = useLogger()

    const [initialNotification, setInitialNotification] = useState<Nullable<string>>(null)
    const [wrongCredentials, setWrongCredentials] = useState<boolean>(false)

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        shouldFocusError: false,
        defaultValues: {
            email: forcedEmail || '',
        },
    })

    useEffect(() => {
        const notification = query.get('notification')

        if (notification) {
            setInitialNotification(notification)
        }
    }, [query])

    const handleLoginSubmit = useCallback(
        (formData: FormValues) => {
            layoutToggleLoader(true)
            const { email, password } = formData
            signInWithEmailAndPassword(email, password)
                .then(response => {
                    if (response?.code?.startsWith('auth/')) {
                        setWrongCredentials(true)
                    }
                    if (response?.user) {
                        setShouldWaitForUserContexts(true)
                        if (response?.user?.emailVerified !== false) {
                            setShouldWaitForWhiteLabel(true)
                        }
                    }
                })
                .catch(error => {
                    log(error)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        },
        [addError, layoutToggleLoader, log, setShouldWaitForUserContexts, setShouldWaitForWhiteLabel],
    )
    return (
        <div
            css={css`
                ${styles}
            `}
        >
            <div
                css={css`
                    margin-top: 40px;
                `}
            >
                {wrongCredentials && <Alert content='Incorrect email address or password.' type='error' />}
                {initialNotification === 'password-reset-success' && <Alert content='Your new password was created successfully!' />}
                {initialNotification === 'password-reset-failed' && (
                    <Alert content='There was an issue creating new password, please try again.' type='error' />
                )}
                {initialNotification === 'email-verified' && <Alert content='Your email was verified successfully!' />}
                {initialNotification === 'email-verification-failed' && (
                    <Alert content='There was an issue verifying your email, please try again.' type='error' />
                )}
            </div>

            {header}
            <form
                css={css`
                    display: flex;
                    flex-direction: column;
                `}
                onSubmit={handleSubmit(handleLoginSubmit)}
                autoComplete='off'
                role='tablist'
            >
                <FieldWrapper>
                    <HiddenInput name='email' placeholder='Email' tabIndex={-1} disabled={!!forcedEmail} />
                    <ControlledInput
                        name='email'
                        placeholder='Email'
                        label='Email'
                        control={control}
                        dataTestId='login-input'
                        tabIndex={0}
                        disabled={!!forcedEmail}
                        autofocus={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <HiddenInput type='password' tabIndex={-1} />
                    <ControlledInput
                        name='password'
                        placeholder='Password'
                        label='Password'
                        type='password'
                        hasPassword={true}
                        control={control}
                        dataTestId='password-input'
                        tabIndex={0}
                    />
                </FieldWrapper>

                <Link
                    to='/recover-password'
                    css={css`
                        margin-bottom: 20px;
                        text-align: right;

                        &:focus-visible {
                            outline: 5px auto -webkit-focus-ring-color;
                        }
                    `}
                >
                    Forgot your password?
                </Link>

                <Button
                    variant='primary'
                    type='submit'
                    disabled={hasErrors(errors)}
                    style={css`
                        margin-bottom: 16px;
                    `}
                    dataTestId='sign-in'
                    tabIndex={0}
                >
                    {buttonText}
                </Button>
            </form>
            {showRegistrationLink && (
                <p
                    css={css`
                        width: 100%;
                        text-align: center;
                        color: ${COLOR_PALETTE.gray_4};
                    `}
                >
                    Don’t have access yet?{' '}
                    <Link
                        to='/register'
                        css={css`
                            &:focus-visible {
                                outline: 5px auto -webkit-focus-ring-color;
                            }
                        `}
                    >
                        Join Us now
                    </Link>
                </p>
            )}
            <AlternativeAuthMethods />
        </div>
    )
}

export { ManagerLoginForm }
