/** @jsxImportSource @emotion/react */

import { FunctionComponent, useCallback, useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getStrengthForm, saveStrengthFormAnswer, submitStrengthForm } from './api'
import { FormView } from './components/FormView'
import { FormDataModel } from './contracts'

const StrengthForm: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [showWelcomeScreen, setShowWelcomeScreen] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const [showThankYouScreen, setThankYouScreen] = useState(false)
    const { formId, specialistId } = useParams<{ formId: string; specialistId: string }>()
    const [data, setData] = useState<Nullable<FormDataModel>>(null)
    const [activeSavesIndicator, setActiveSavesIndicator] = useState<Nullable<number>>(null)
    const [isValid, setIsValid] = useState<boolean>(false)

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const log = useLogger('error')
    const { addError } = useNotifications()

    useEffectOnce(() => {
        if (formId && specialistId) {
            layoutToggleLoader(true)
            getStrengthForm(formId, specialistId)
                .then((result: any) => {
                    setData(result)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    })

    const handleStart = useCallback(() => {
        setShowWelcomeScreen(false)
        setShowForm(true)
    }, [])

    const handleChange = useCallback(
        (event: any) => {
            if (formId && specialistId) {
                setActiveSavesIndicator(1)
                saveStrengthFormAnswer(formId, specialistId, event)
                    .then(data => log(data, 'log'))
                    .catch((err: any) => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        setActiveSavesIndicator(0)
                    })
            }
        },
        [formId, specialistId, log, addError],
    )

    const handleSubmit = useCallback(() => {
        if (formId && specialistId) {
            layoutToggleLoader(true)
            submitStrengthForm(formId, specialistId)
                .then(() => {
                    setShowForm(false)
                    setThankYouScreen(true)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [formId, specialistId, log, layoutToggleLoader, addError])

    return (
        <FormView
            data={data}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            activeSavesIndicator={activeSavesIndicator}
            showThankYouScreen={showThankYouScreen}
            handleStart={handleStart}
            showForm={showForm}
            showWelcomeScreen={showWelcomeScreen}
            type='STRENGTH'
            isValid={setIsValid}
            isNextStepEnabled={isValid}
        />
    )
}

export { StrengthForm }
