/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../../components/forms/ControlledAutocompleteSelect'
import { ControlledCheckbox } from '../../../../components/forms/ControlledCheckbox'
import { ControlledDatePickerInput } from '../../../../components/forms/ControlledDatePickerInput'
import { ControlledInput } from '../../../../components/forms/ControlledInput'
import { IconButton } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { FieldWrapper } from '../../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../../components/layout/ResponsiveColumns'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { Education, EducationType, mapEducationType } from '../../../../contracts/specialist/specialistEducations'
import { mqMax } from '../../../../GlobalStyle'
import { monthFormat } from '../../../../utils/dates'
import { hasErrors } from '../../../../utils/errors'
import { INVALID_DATE_RANGE } from '../../../../validation/validation-messages'
import { yup, yupNumberTimestamp } from '../../../../validation/yup'
import { saveSpecialistEducation, updateSpecialistEducation } from '../../api'
import { ItemBoxWrapper } from '../../shared/ProfileTabsHelpers'
import { TabFormWrapper } from '../../shared/TabFormWrapper'
import { educationTypes, studiesEducationTypes } from './QualificationsHelpers'

const initialEducation = {
    id: '',
    type: null,
    from: null,
    to: null,
    inProgress: false,
    studiesSpecialization: '',
    studiesDepartment: '',
    studiesUniversity: '',
    certificationAcquisitionDate: null,
    certificationHasExpiration: false,
    certificationExpirationDate: null,
    certificationTitle: '',
    certificationOrganization: '',
    languageName: '',
    languageSchool: '',
}

const schema = yup.object().shape({
    id: yup.string(),
    type: yup.string().required().oneOf(educationTypes),
    from: yup
        .number()
        .nullable()
        .when('type', {
            is: (type: EducationType) => type !== EducationType.CERTIFICATE_COURSE,
            then: yupNumberTimestamp.required(),
        }),
    inProgress: yup.boolean().nullable(),
    to: yup
        .number()
        .nullable()
        .when(['type', 'inProgress'], {
            is: (type: EducationType, inProgress: boolean) => type !== EducationType.CERTIFICATE_COURSE && inProgress === false,
            then: yupNumberTimestamp.required().min(yup.ref('from'), INVALID_DATE_RANGE).required(),
        }),
    studiesSpecialization: yup.string().when('type', {
        is: (type: EducationType) => studiesEducationTypes.includes(type),
        then: yup.string().nullable(),
    }),
    studiesDepartment: yup.string().when('type', {
        is: (type: EducationType) => studiesEducationTypes.includes(type),
        then: yup.string().nullable(),
    }),
    studiesUniversity: yup.string().when('type', {
        is: (type: EducationType) => studiesEducationTypes.includes(type),
        then: yup.string().nullable(),
    }),
    certificationTitle: yup.string().when('type', {
        is: EducationType.CERTIFICATE_COURSE,
        then: yup.string().nullable(),
    }),
    certificationOrganization: yup.string().when('type', {
        is: EducationType.CERTIFICATE_COURSE,
        then: yup.string().nullable(),
    }),
    certificationAcquisitionDate: yup.number().nullable().when('type', {
        is: EducationType.CERTIFICATE_COURSE,
        then: yupNumberTimestamp.required(),
    }),
    certificationExpirationDate: yup
        .number()
        .nullable()
        .when(['type', 'certificationHasExpiration'], {
            is: (type: EducationType, certificationHasExpiration: boolean) =>
                type === EducationType.CERTIFICATE_COURSE && certificationHasExpiration === true,
            then: yupNumberTimestamp.required(),
        }),
    certificationHasExpiration: yup.boolean().nullable().when('type', {
        is: EducationType.CERTIFICATE_COURSE,
        then: yup.boolean(),
    }),
    languageName: yup.string().when('type', {
        is: EducationType.LANGUAGE_COURSE,
        then: yup.string().nullable(),
    }),
    languageSchool: yup.string().when('type', {
        is: EducationType.LANGUAGE_COURSE,
        then: yup.string().nullable(),
    }),
})

const sanitizeEducationData = (formData: Education) => {
    const {
        type,
        from,
        to,
        inProgress,
        certificationAcquisitionDate,
        certificationHasExpiration,
        certificationExpirationDate,
        certificationOrganization,
        certificationTitle,
        studiesDepartment,
        studiesUniversity,
        studiesSpecialization,
        languageName,
        languageSchool,
    } = formData
    const { id: _, ...baseEducation } = initialEducation
    const shared = { ...baseEducation, type }
    let data: Education
    switch (type) {
        case EducationType.CERTIFICATE_COURSE:
            data = {
                ...shared,
                certificationAcquisitionDate,
                certificationHasExpiration,
                certificationExpirationDate: certificationHasExpiration ? certificationExpirationDate : null,
                certificationOrganization,
                certificationTitle,
            }
            break
        case EducationType.LANGUAGE_COURSE:
            data = {
                ...shared,
                languageName,
                languageSchool,
                from,
                inProgress,
                to: inProgress ? null : to,
            }
            break
        default:
            data = {
                ...shared,
                studiesDepartment: studiesDepartment || '',
                studiesUniversity: studiesUniversity || '',
                studiesSpecialization: studiesSpecialization || '',
                from,
                inProgress,
                to: inProgress ? null : to,
            }
    }
    return data
}

type QualificationsFormProps = {
    specialistId?: string
    onClose: (educationId?: string) => void
    onSave?: () => void
    education?: Education
    customSubmit?: (educationId: string, formData: Education) => void
    aiCvParsing?: boolean
    canClose?: boolean
    removable?: boolean
    handleDelete?: (educationId: string) => void
    setIsDirty?: (isDirty: boolean, id: string) => void
}

const QualificationsForm: FunctionComponent<React.PropsWithChildren<QualificationsFormProps>> = ({
    specialistId,
    onClose,
    onSave,
    education = initialEducation,
    customSubmit,
    aiCvParsing = false,
    canClose = true,
    removable = false,
    handleDelete = () => null,
    setIsDirty,
}) => {
    const [saving, setSaving] = useState(false)
    const { addSuccess, addError } = useNotifications()
    const {
        control,
        watch,
        handleSubmit,
        formState: { errors, isDirty, isSubmitting },
        clearErrors,
        trigger,
    } = useForm<Education>({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: sanitizeEducationData(education),
        shouldFocusError: false,
    })

    const onSubmit = useCallback(
        (formData: any) => {
            if (specialistId) {
                setSaving(true)

                const sanitizedData = sanitizeEducationData(formData)
                if (education.id) {
                    updateSpecialistEducation(specialistId, education.id, sanitizedData)
                        .then(() => {
                            addSuccess('Education saved.')
                            setSaving(false)
                            if (onSave) {
                                onSave()
                            }
                        })
                        .catch(() => {
                            addError()
                            setSaving(false)
                        })
                } else {
                    saveSpecialistEducation(specialistId, sanitizedData)
                        .then(() => {
                            addSuccess('Education added.')
                            setSaving(false)
                            if (onSave) {
                                onSave()
                            }
                        })
                        .catch(() => {
                            addError()
                            setSaving(false)
                        })
                }
            } else if (customSubmit && education.id) {
                customSubmit(education.id, formData)
            }
        },
        [specialistId, customSubmit, education.id, addSuccess, onSave, addError],
    )
    const type = aiCvParsing ? EducationType.OTHER_STUDIES : watch('type')
    const inProgress = aiCvParsing ? false : watch('inProgress', initialEducation.inProgress)
    const certificationHasExpiration = watch('certificationHasExpiration', initialEducation.certificationHasExpiration)
    const isStudies = studiesEducationTypes.includes(type as EducationType)
    const isLanguageCourse = type === EducationType.LANGUAGE_COURSE
    const isCertificateCourse = type === EducationType.CERTIFICATE_COURSE

    useEffect(() => {
        clearErrors()
    }, [clearErrors, type])

    useEffect(() => {
        if (aiCvParsing) {
            trigger()
        }
    }, [aiCvParsing, trigger])

    const handleCleanCertificationExpirationDateError = useCallback(
        (v: Array<boolean>) => {
            clearErrors('certificationExpirationDate')
            return v
        },
        [clearErrors],
    )

    const handleToError = useCallback(
        (v: Array<boolean>) => {
            clearErrors('to')
            return v
        },
        [clearErrors],
    )

    const handleClose = useCallback(() => {
        onClose(education.id)
    }, [education.id, onClose])

    const handleDeleteClick = useCallback(() => {
        handleDelete(education.id || '')
    }, [education.id, handleDelete])

    useEffect(() => {
        if (setIsDirty) {
            setIsDirty(isDirty, education.id || '')
        }
        return () => {
            if (setIsDirty) {
                setIsDirty(false, education.id || '')
            }
        }
    }, [isDirty, education.id, setIsDirty])

    return (
        <ItemBoxWrapper dataTestId='qualifications-form'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <TabFormWrapper fullWidth={aiCvParsing}>
                    <ControlledInput type='hidden' name='id' control={control} />
                    <FieldWrapper shown={!aiCvParsing}>
                        <ControlledAutocompleteSelect
                            dropdownWidth='100%'
                            name='type'
                            label='Type'
                            placeholder='Choose type'
                            options={educationTypes}
                            canFilter={false}
                            control={control}
                            selectedLabelTransformer={(val: EducationType) => mapEducationType(val)}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>
                    <Fragment>
                        <ResponsiveColumns>
                            <FirstColumn>
                                <FieldWrapper shown={isStudies || isLanguageCourse}>
                                    <ControlledDatePickerInput
                                        control={control}
                                        name='from'
                                        label='From'
                                        format={monthFormat}
                                        picker='month'
                                        placeholder='MMM YYYY'
                                    />
                                </FieldWrapper>
                            </FirstColumn>
                            <SecondColumn>
                                <FieldWrapper shown={isStudies || isLanguageCourse}>
                                    <ControlledDatePickerInput
                                        control={control}
                                        name='to'
                                        label='To'
                                        format={monthFormat}
                                        picker='month'
                                        placeholder='MMM YYYY'
                                        disabled={inProgress}
                                    />
                                </FieldWrapper>
                            </SecondColumn>
                        </ResponsiveColumns>
                        <ResponsiveColumns>
                            <FirstColumn>
                                <FieldWrapper shown={isCertificateCourse}>
                                    <ControlledDatePickerInput
                                        control={control}
                                        name='certificationAcquisitionDate'
                                        label='Date of Completion'
                                        format={monthFormat}
                                        picker='month'
                                        placeholder='MMM YYYY'
                                    />
                                </FieldWrapper>
                            </FirstColumn>
                        </ResponsiveColumns>
                        <FieldWrapper shown={isCertificateCourse} noLabel noError>
                            <ControlledCheckbox
                                checkboxLabel='This Certification has an Expiration Date'
                                control={control}
                                name='certificationHasExpiration'
                                beforeChange={handleCleanCertificationExpirationDateError}
                            />
                        </FieldWrapper>
                        <ResponsiveColumns>
                            <FirstColumn>
                                <FieldWrapper shown={isCertificateCourse && certificationHasExpiration}>
                                    <ControlledDatePickerInput
                                        control={control}
                                        name='certificationExpirationDate'
                                        label='Date of Expiration'
                                        format={monthFormat}
                                        picker='month'
                                        placeholder='MMM YYYY'
                                    />
                                </FieldWrapper>
                            </FirstColumn>
                        </ResponsiveColumns>
                        <FieldWrapper noLabel noError shown={!aiCvParsing && (isLanguageCourse || isStudies)}>
                            <ControlledCheckbox
                                control={control}
                                name='inProgress'
                                checkboxLabel='In progress'
                                beforeChange={handleToError}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isStudies}>
                            <ControlledInput
                                label='Degree / Specialization (optional)'
                                placeholder='Degree / Specialization (optional)'
                                name='studiesSpecialization'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isStudies}>
                            <ControlledInput
                                label='Department (optional)'
                                placeholder='Department (optional)'
                                name='studiesDepartment'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isStudies}>
                            <ControlledInput
                                label='University / College (optional)'
                                placeholder='University / College (optional)'
                                name='studiesUniversity'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isCertificateCourse}>
                            <ControlledInput
                                label='Certification / Title (optional)'
                                placeholder='Certification / Title (optional)'
                                name='certificationTitle'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isCertificateCourse}>
                            <ControlledInput
                                label='Certyfing Organization (optional)'
                                placeholder='Certyfing Organization (optional)'
                                name='certificationOrganization'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isLanguageCourse}>
                            <ControlledInput
                                label='Language studied and level (optional)'
                                placeholder='English B1'
                                name='languageName'
                                control={control}
                            />
                        </FieldWrapper>
                        <FieldWrapper shown={isLanguageCourse}>
                            <ControlledInput
                                label='Language School / Institution (optional)'
                                placeholder='Language School / Institution (optional)'
                                name='languageSchool'
                                control={control}
                            />
                        </FieldWrapper>
                    </Fragment>
                </TabFormWrapper>
                <Divider />
                <FlexBox alignItems='center' justifyContent={removable ? 'space-between' : 'flex-end'}>
                    {removable && <IconButton name='trash' size={32} onClick={handleDeleteClick} />}
                    <section
                        css={css`
                            display: flex;
                            justify-content: flex-end;

                            ${mqMax[1]} {
                                flex-direction: column-reverse;
                                justify-content: center;
                                align-items: center;
                            }
                        `}
                    >
                        {canClose && (
                            <Button
                                css={css`
                                    margin-right: 12px;
                                    ${mqMax[1]} {
                                        width: 100%;
                                        margin: 12px 0 0;
                                    }
                                `}
                                variant='text'
                                onClick={handleClose}
                                disabled={saving}
                            >
                                Cancel
                            </Button>
                        )}
                        <Button
                            style={css`
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            variant='primary'
                            type='submit'
                            disabled={saving || isSubmitting || !isDirty || hasErrors(errors)}
                            dataTestId='save-qualification'
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </Button>
                    </section>
                </FlexBox>
            </form>
        </ItemBoxWrapper>
    )
}

export { QualificationsForm, studiesEducationTypes }
