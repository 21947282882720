/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, ReactNode } from 'react'
import { mqMax, mqMin } from '../GlobalStyle'
import { Dot } from './Dot'

type FieldLineProps = {
    fields: Array<{
        label: string
        value: ReactNode
    }>
    style?: SerializedStyles
    dotStyle?: SerializedStyles
}

const FieldLine: FunctionComponent<React.PropsWithChildren<FieldLineProps>> = ({ fields, style, dotStyle }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                font-size: 14px;
                ${style}
                flex-wrap: wrap;
                flex-direction: column;

                ${mqMin[1]} {
                    flex-direction: row;
                }
            `}
        >
            {fields.map((field, index, array) => {
                return (
                    <span
                        css={css`
                            display: flex;
                            margin-bottom: 8px;

                            ${mqMin[1]} {
                                margin-bottom: 0;
                            }
                        `}
                        key={field.label}
                    >
                        <span
                            css={css`
                                color: ${theme.colors.gray_4};
                                margin-right: 5px;
                            `}
                        >
                            {field.label}:
                        </span>
                        <span
                            css={css`
                                font-weight: 500;
                            `}
                        >
                            {field.value}
                        </span>
                        {index < array.length - 1 && (
                            <Dot
                                style={css`
                                    ${mqMax[1]} {
                                        display: none;
                                    }
                                    ${dotStyle}
                                `}
                            />
                        )}
                    </span>
                )
            })}
        </div>
    )
}

export { FieldLine }
