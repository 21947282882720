import { useTheme } from '@emotion/react'
import { useMemo } from 'react'
import { ApplicationStatusColors, ApplicationStatusEnum } from '../../../contracts/opportunities-manager/contracts'

const useApplicationStatusCellColors = () => {
    const theme = useTheme()

    const colors: ApplicationStatusColors = useMemo(() => {
        const violet = {
            color: theme.colors.violet_5,
            backgroundColor: theme.colors.violet_2,
        }

        const green = {
            color: theme.colors.green_6,
            backgroundColor: theme.colors.green_2,
        }

        const red = {
            color: theme.colors.red_4,
            backgroundColor: theme.colors.red_2,
        }

        const gray = {
            color: theme.colors.gray_4,
            backgroundColor: theme.colors.gray_2,
        }

        const darkGray = {
            color: theme.colors.gray_5,
            backgroundColor: theme.colors.gray_2,
        }

        return {
            [ApplicationStatusEnum.NEW_APPLICATION]: violet,
            [ApplicationStatusEnum.REVIEWING]: violet,
            [ApplicationStatusEnum.REJECTED_BY_TA]: red,
            [ApplicationStatusEnum.REJECTED_BY_CLIENT]: red,
            [ApplicationStatusEnum.WITHDRAWN]: gray,
            [ApplicationStatusEnum.ACCEPTED]: violet,
            [ApplicationStatusEnum.INTERVIEW]: violet,
            [ApplicationStatusEnum.INTERVIEW_SCHEDULING]: violet,
            [ApplicationStatusEnum.INTERVIEW_FINISHED]: violet,
            [ApplicationStatusEnum.OFFER]: violet,
            [ApplicationStatusEnum.OFFER_REJECTED]: red,
            [ApplicationStatusEnum.WON]: green,
            [ApplicationStatusEnum.INFO_REQUESTED]: violet,
            [ApplicationStatusEnum.PRESENTED]: violet,
            [ApplicationStatusEnum.PRESENTED_IN_ANOTHER_OPPORTUNITY]: violet,
            [ApplicationStatusEnum.TECH_TEST]: violet,
            [ApplicationStatusEnum.OFFER_ACCEPTED_NEGOTIATIONS]: violet,
            [ApplicationStatusEnum.OFFER_ACCEPTED]: violet,
            [ApplicationStatusEnum.OPPORTUNITY_CLOSED]: red,
            [ApplicationStatusEnum.ON_HOLD]: darkGray,
            [ApplicationStatusEnum.INVITED]: violet,
        }
    }, [theme])

    return colors
}

export { useApplicationStatusCellColors }
