/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import React, { FunctionComponent } from 'react'
import { StrengthTab } from '../specialist-profile/StrengthTab'

type PersonalStrengthTabProps = {
    specialistId: string
}

const PersonalStrengthTab: FunctionComponent<React.PropsWithChildren<PersonalStrengthTabProps>> = ({ specialistId }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                border: 1px solid ${theme.colors.gray_2};
                padding: 0 24px;
            `}
        >
            <StrengthTab specialistId={specialistId} divider={false} />
        </div>
    )
}

export { PersonalStrengthTab }
