/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, Ref, useCallback, useState } from 'react'
import { NotesArray } from '../../contracts/notes/Notes'
import { CvStatus } from '../../pages/profile/contracts'
import { Nullable } from '../../types'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { NoData } from '../NoData'
import { TooltipIcon } from '../TooltipIcon'
import { NoteItem } from './NoteItem'

type NotesProps = {
    notes: NotesArray
    specialistId: string
    fetchNotes?: () => void
    downloadCv?: () => void
    cvStatus?: Nullable<CvStatus>
    forwardedRef?: Ref<HTMLDivElement>
}

const Notes: FunctionComponent<React.PropsWithChildren<NotesProps>> = ({
    notes,
    specialistId,
    fetchNotes,
    cvStatus,
    downloadCv,
    forwardedRef,
}) => {
    const [adding, setAdding] = useState(false)

    const toggleAddNote = useCallback(() => {
        setAdding(!adding)
    }, [adding])

    const handleDownload = useCallback(() => {
        if (downloadCv) {
            downloadCv()
        }
    }, [downloadCv])

    return (
        <div data-testid='notes-group' ref={forwardedRef}>
            <FlexBox justifyContent='space-between' alignItems='center'>
                <FlexBox alignItems='center'>
                    <h6
                        css={css`
                            margin-bottom: 0;
                            margin-right: 12px;
                        `}
                        data-testid='notes-title'
                    >
                        Internal notes
                    </h6>
                    <Tooltip title='This field allows you to add quick notes to any profile. These notes are for internal use and are visible to managers of your company only.'>
                        <span>
                            <TooltipIcon />
                        </span>
                    </Tooltip>
                </FlexBox>
                <FlexBox>
                    {cvStatus !== CvStatus.HIDDEN && (
                        <Tooltip title={cvStatus === CvStatus.INACTIVE && 'This profile does not have a CV attached to it.'}>
                            <div>
                                <Button
                                    variant='tertiary'
                                    onClick={handleDownload}
                                    style={css`
                                        margin-right: 12px;
                                        pointer-events: ${cvStatus === CvStatus.INACTIVE ? 'none' : 'auto'};
                                    `}
                                    disabled={cvStatus === CvStatus.INACTIVE}
                                >
                                    Download CV
                                </Button>
                            </div>
                        </Tooltip>
                    )}

                    <Button variant='tertiary' onClick={toggleAddNote}>
                        Add note
                    </Button>
                </FlexBox>
            </FlexBox>
            <Divider />
            {adding && (
                <NoteItem
                    adding
                    toggleOpen={toggleAddNote}
                    specialistId={specialistId}
                    fetchNotes={fetchNotes}
                    toggleAddNote={toggleAddNote}
                />
            )}

            {notes.length === 0 ? (
                <div>
                    <NoData
                        message='No Notes'
                        style={css`
                            margin: 80px auto;
                        `}
                    />
                    <Divider />
                </div>
            ) : (
                notes.map((note, index) => {
                    return <NoteItem key={note.id} note={note} specialistId={specialistId} fetchNotes={fetchNotes} />
                })
            )}
        </div>
    )
}

export { Notes }
