/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Nullable } from '../../../../types'
import { GameMatrix } from '../../contracts'
import { GameBoard } from '../GameBoard'
import { StepWrapper } from '../StepWrapper'

type MemorizeProps = {
    matrix?: Nullable<GameMatrix>
    time: number
    round: number
    rounds: number
}

const Memorize: FunctionComponent<React.PropsWithChildren<MemorizeProps>> = ({ matrix, time, round, rounds }) => {
    return (
        <StepWrapper step={1} round={round} rounds={rounds} time={time} description='Memorize the positions of the black dots.'>
            <div
                css={css`
                    width: 324px;
                    height: 300px;
                    margin: 0 auto 82px;
                `}
            >
                <GameBoard matrix={matrix} isVisible={true} padding='14px' boxSize='62px' handleClick={() => null} isCorrect={false} />
            </div>
        </StepWrapper>
    )
}
export { Memorize }
