import { KeyboardEvent } from 'react'

// This is the minimum we need at this moment.
// Require more, feel free to add more :P
export const getKeyboardKey = (event: KeyboardEvent): string => {
    let key = event.nativeEvent.code
    // because some browsers are retarded...
    if (!key) {
        switch (event.which) {
            case 38:
                key = 'ArrowUp'
                break
            case 40:
                key = 'ArrowDown'
                break
            case 13:
                key = 'Enter'
                break
            default:
                break
        }
    }

    return key
}
