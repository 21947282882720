import { FC } from 'react'
import { MsaInfoWrapper, MsaLink } from './MsaInfo.styles'

type MsaInfoProps = {
    marginBottom?: string
}

const MsaInfo: FC<MsaInfoProps> = ({ marginBottom }) => (
    <MsaInfoWrapper marginBottom={marginBottom} data-testid='msa-info'>
        Please note, we can't process your candidates until you sign an MSA agreement:{' '}
        <MsaLink
            href='https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=5bea3fb2-25a2-43a2-8541-cea120053325&env=na3&acct=a0ed6a44-afdd-49e7-ad46-8000f2baa16a&v=2'
            target='_blank'
            rel='noopener noreferrer'
        >
            Sign an MSA agreement [EN]
        </MsaLink>
        <MsaLink
            href='https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=8fae4399-0b0f-4d1e-82cd-7b096b3deea0&env=na3&acct=a0ed6a44-afdd-49e7-ad46-8000f2baa16a&v=2'
            target='_blank'
            rel='noopener noreferrer'
        >
            Sign an MSA agreement [PL]
        </MsaLink>
        After signing, your MSA will be processed within the next 2 business days. Keep in mind that our MSA terms are standard across all
        clients and partners and can’t be edited.
    </MsaInfoWrapper>
)

export { MsaInfo }
