/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, useCallback, useState } from 'react'
import { DraggableChildrenFn } from '@hello-pangea/dnd'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { CardsType, KanbanCard } from '../../components/kanban-board/contract'
import { KanbanBoard } from '../../components/kanban-board/KanbanBoard'
import { SearchApplication } from '../../contracts/open-opportunities/contracts'
import { columnsList, getApplications } from './mockData'

const KanbanPocPage = () => {
    const theme = useTheme()
    const [cardsList, setCardsList] = useState<Array<KanbanCard<SearchApplication>>>(getApplications(50))

    const cardRenderer: (card: KanbanCard<SearchApplication>) => DraggableChildrenFn = useCallback(
        card => provided =>
            (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    css={css`
                        border: 1px solid ${theme.colors.violet_2};
                        border-radius: 4px;
                        padding: 16px;
                        margin-bottom: 8px;
                        background-color: ${theme.colors.white};
                    `}
                >
                    <div
                        css={css`
                            font-weight: 500;
                            font-size: 16px;
                        `}
                    >
                        {card.firstName} {card.lastName}
                    </div>
                    {card.role}
                    <Divider />
                    {`${card.country}${card.city ? `, ${card.city}` : ''}`}
                </div>
            ),
        [theme.colors.violet_2, theme.colors.white],
    )

    const onDragEnd = useCallback((_: KanbanCard<SearchApplication>, cards: CardsType<SearchApplication>) => {
        setCardsList(Object.values(cards).flat())
    }, [])

    const handleAdd10 = useCallback(() => {
        setCardsList(prevCardsList => prevCardsList.concat(getApplications(10, prevCardsList)))
    }, [])

    return (
        <Fragment>
            <Button onClick={handleAdd10}>Add 10</Button> <span>{cardsList.length} total</span>
            <KanbanBoard<SearchApplication>
                columnsList={columnsList}
                cardsList={cardsList}
                onDragEnd={onDragEnd}
                cardRenderer={cardRenderer}
            />
        </Fragment>
    )
}

export { KanbanPocPage }
