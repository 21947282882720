import { Nullable, StringMapping } from '../types'

export enum ContextType {
    COMPANY = 'COMPANY',
    USER = 'USER',
}
export interface UserContext {
    companyId: Nullable<string>
    contextId: string
    contextName: string
    type: ContextType
}

export enum ContextGroupName {
    COMPANY_SUPER_ADMIN = 'COMPANY_SUPER_ADMIN',
    FUNCTIONAL_MANAGER = 'FUNCTIONAL_MANAGER',
    SPECIALIST = 'SPECIALIST',
    BASIC_USER = 'BASIC_USER',
}

export interface UserContextGroup {
    name: ContextGroupName
}

export interface PendingInvitation {
    prospectId: string
    companyName: string
}

const contextGroupNameMapping: StringMapping = {
    [ContextGroupName.COMPANY_SUPER_ADMIN]: 'Company Super Admin',
    [ContextGroupName.FUNCTIONAL_MANAGER]: 'Functional Manager',
    [ContextGroupName.SPECIALIST]: 'Specialist',
    [ContextGroupName.BASIC_USER]: 'Basic User',
}

const mapContextGroupName = (type: Nullable<ContextGroupName>) => (type !== null ? contextGroupNameMapping[type] : '')

export { mapContextGroupName, contextGroupNameMapping }
