/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useState } from 'react'
import { Divider } from '../../../components/Divider'
import { FieldLabel } from '../../../components/inputs/input-field/FieldLabel'
import { Loader } from '../../../components/layout/Loader'
import { Modal, ModalProps } from '../../../components/Modal'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { BookingDetails } from '../../../contracts/bookings/booking'
import { Nullable } from '../../../types'
import { convertTimestampToDateString } from '../../../utils/dates'
import { useLogger } from '../../../utils/useLogger'
import { getBookingDetails } from '../api'

type BookingRequestModalProps = ModalProps & {
    onClose: () => void
    bookingId: string
    specialistId: string
}

const Data = styled.p`
    font-size: 18px;
    min-height: 28px;
`

const BookingRequestModal: FunctionComponent<React.PropsWithChildren<BookingRequestModalProps>> = ({
    onClose,
    bookingId,
    specialistId,
}) => {
    const theme = useTheme()
    const [bookingDetails, setBookingDetails] = useState<Nullable<BookingDetails>>(null)
    const log = useLogger('error')
    const { addError } = useNotifications()

    useEffect(() => {
        getBookingDetails(bookingId, specialistId)
            .then(data => setBookingDetails(data))
            .catch(err => {
                log(err)
                addError()
                onClose()
            })
    }, [bookingId, specialistId, log, addError, onClose])

    return !bookingDetails ? (
        <Loader dataTestId='main-app-loader' />
    ) : (
        <Modal onClose={onClose} opened dataTestId='booking-modal'>
            <h5>Booking Request</h5>
            <Divider />
            <FieldLabel label='Project Name' />
            <Data>{bookingDetails?.projectName}</Data>
            <FieldLabel label='What is the expected project duration?' />
            <Data>{bookingDetails?.projectDuration}</Data>
            <FieldLabel label='What is the ideal project start date?' />
            <Data>{bookingDetails ? convertTimestampToDateString(bookingDetails.idealProjectStartDate) : ''}</Data>
            <FieldLabel label='What is the latest project start date?' />
            <Data>{bookingDetails ? convertTimestampToDateString(bookingDetails.latestProjectStartDate) : ''}</Data>
            <FieldLabel label='What stack do you use in project?' />
            <Data>{bookingDetails?.techStack.join(', ')}</Data>
            <div
                css={css`
                    position: relative;
                    bottom: -32px;
                    padding: 7px 40px;
                    margin: 0 -40px;
                    background-color: ${theme.colors.violet_2};
                    color: ${theme.colors.gray_6};
                    font-size: 12px;
                    text-align: center;
                `}
            >
                Booking Request Status: {bookingDetails?.bookingStatus}, last update on:{' '}
                {bookingDetails ? convertTimestampToDateString(bookingDetails.lastUpdatedAt) : ''}
            </div>
        </Modal>
    )
}

export { BookingRequestModal }
