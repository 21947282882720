/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, memo, ReactElement } from 'react'
import { Draggable } from '@hello-pangea/dnd'
import { InnerListProps } from './contract'

const InnerList = <T,>({ isCollapsed, cards, cardRenderer }: InnerListProps<T>): ReactElement => {
    const theme = useTheme()
    return isCollapsed ? (
        <div
            css={css`
                display: flex;
                flex-wrap: wrap;
            `}
        >
            {cards.map(card => (
                <div
                    key={card.id}
                    css={css`
                        width: 20px;
                        height: 20px;
                        margin: 0 0 4px 4px;
                        background-color: ${theme.colors.beige_1};
                    `}
                />
            ))}
        </div>
    ) : (
        <Fragment>
            {cards.map((card, index) => (
                <Draggable draggableId={card.id} index={index} children={cardRenderer(card)} key={card.id} />
            ))}
        </Fragment>
    )
}

const MemoizedInnerList = memo(InnerList) as typeof InnerList

export { MemoizedInnerList }
