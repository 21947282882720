/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { LanguageRequest, mapLevelType } from 'src/contracts/profile/language'
import { mqMax } from 'src/GlobalStyle'
import { InlineFieldWrapper, ItemActionsPanel, PropertyLabel, PropertyValue } from 'src/pages/my-specialists/shared/ProfileTabsHelpers'
import { IconButton } from './Icon'
import { FlexBox } from './layout/FlexBoxHelpers'

type LanguageItemDetailsProps = {
    language: LanguageRequest
    toggleEditMode: () => void
    handleDelete: () => void
}

const LanguageItemDetails: FunctionComponent<LanguageItemDetailsProps> = ({ language: { name, level }, toggleEditMode, handleDelete }) => (
    <FlexBox
        css={css`
            ${mqMax[1]} {
                flex-direction: column;
            }
        `}
    >
        <InlineFieldWrapper>
            <PropertyLabel
                css={css`
                    line-height: 40px;
                `}
            >
                Language
            </PropertyLabel>
            <PropertyValue
                css={css`
                    line-height: 40px;
                `}
            >
                {name}
            </PropertyValue>
        </InlineFieldWrapper>
        <InlineFieldWrapper
            css={css`
                margin-left: 24px;
                ${mqMax[1]} {
                    margin: 0;
                }
            `}
        >
            <PropertyLabel
                css={css`
                    line-height: 40px;
                `}
            >
                Level
            </PropertyLabel>
            <PropertyValue
                css={css`
                    line-height: 40px;
                `}
            >
                {level && mapLevelType(level)}
            </PropertyValue>
        </InlineFieldWrapper>
        <ItemActionsPanel>
            <IconButton name='edit' size={36} onClick={toggleEditMode} dataTestId='edit-language' />
            <IconButton name='trash' size={36} onClick={handleDelete} dataTestId='remove-language' />
        </ItemActionsPanel>
    </FlexBox>
)

export { LanguageItemDetails }
