/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../Logo'

const PageLayoutA: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    return (
        <div
            css={css`
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                height: 100vh;
                padding: 24px 10%;
            `}
        >
            <Link to='/'>
                <Logo
                    type='logoFull'
                    css={css`
                        align-self: self-start;
                    `}
                />
            </Link>
            <div
                css={css`
                    margin: 0 auto;
                    max-width: 500px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: auto;
                `}
            >
                {children}
            </div>
        </div>
    )
}

export { PageLayoutA }
