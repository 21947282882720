/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { ColumnsType } from 'antd/es/table'
import { ChangeEvent, Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ResponsiveGrid } from 'src/components/data-grid/ResponsiveGrid'
import { Divider } from 'src/components/Divider'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { ColorBackgroundWrapper, FullPageWrapper } from 'src/components/layout/ResponsiveWrapper'
import { YesNoCell } from 'src/components/MSA'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { Pagination } from 'src/components/Pagination'
import { mqMax, mqMin } from 'src/GlobalStyle'
import { useCompanyMembers } from 'src/hooks/useCompanyMembers'
import { useInlineEdit } from 'src/hooks/useInlineEdit'
import { ReduxContext } from 'src/redux/Store'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { countArrayFilters } from 'src/utils/filters'
import { useLogger } from 'src/utils/useLogger'
import { yup } from 'src/validation/yup'
import { downloadAnonymizedCV } from 'src/api/api'
import { downloadCV, editCV, getCvs, getCvsFilters, uploadAnonymizedCV, verifyCV } from './api'
import { CVActions } from './components/CVActions'
import { CvsFiltersBar } from './components/CvsFiltersBar'
import { StatusEdit } from './components/StatusEdit'
import { TAOwnerEdit } from './components/TAOwnerEdit'
import { CV, CVEditFormData, CvsFiltersResponse, CvsRequest, CVStatusMapping, CVType } from './contracts'
import { FiltersWrapper, H4, PaginationWrapper, StyledDataNotFound, StyledSearchInput } from './styles'
import saveAs from 'file-saver'
import { fileTypeToExtension } from 'src/utils/values'

const schema = yup.object().shape({
    cvs: yup.array().of(
        yup.object().shape({
            status: yup.string().required(),
            taOwner: yup.string(),
        }),
    ),
})

const DEFAULT_ITEMS_PER_PAGE = 10

const defaultFilters: CvsRequest = {
    paging: { page: 1, size: DEFAULT_ITEMS_PER_PAGE },
    searchText: '',
    shNames: [],
    sorting: null,
    taOwners: [],
    msaSigned: false,
    premium: false,
}

const CVManagementPage: FunctionComponent = () => {
    const { addError, addSuccess } = useNotifications()
    const {
        selectors: {
            featureFlags: { CVManagement },
        },
    } = useContext(ReduxContext)
    const log = useLogger()
    const theme = useTheme()
    const navigate = useNavigate()

    const [cvs, setCvs] = useState<Nullable<Array<CV>>>(null)
    const [totalCvs, setTotalCvs] = useState(0)
    const [isPending, setPending] = useState(false)
    const [showFilters, setShowFilters] = useState(true)
    const [currentPage, setCurrentPage] = useState<number>(defaultFilters.paging.page)
    const [pageSize, setPageSize] = useState<number>(defaultFilters.paging.size)
    const [filtersOptions, setFiltersOptions] = useState<CvsFiltersResponse>({
        shNames: [],
        taOwners: [],
        msaSigned: false,
        premium: false,
    })
    const [selectedFilters, setSelectedFilters] = useState<CvsFiltersResponse>({
        shNames: [],
        taOwners: [],
        msaSigned: false,
        premium: false,
    })
    const [searchText, setSearchText] = useState('')

    const companyMembers = useCompanyMembers()

    const { handleEditingKeysChange, isEditing, control, getIndex, trigger, errors, getValues } = useInlineEdit<CVEditFormData>({
        schema,
        fieldArrayName: 'cvs',
    })

    useEffect(() => {
        if (!CVManagement) {
            navigate('/dashboard')
        }
    }, [navigate, CVManagement])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const loadFilters = useCallback(() => {
        getCvsFilters(defaultFilters).then(setFiltersOptions).catch(log)
    }, [setFiltersOptions, log])

    const loadCvs = useCallback(() => {
        setPending(true)

        getCvs({
            ...selectedFilters,
            sorting: null,
            searchText,
            paging: {
                page: currentPage,
                size: pageSize,
            },
        })
            .then(data => {
                setCvs(data.cvs)
                setTotalCvs(data.total)
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => {
                setPending(false)
            })
    }, [addError, currentPage, log, pageSize, searchText, selectedFilters])

    useEffect(() => {
        if (CVManagement) {
            loadFilters()
        }
    }, [CVManagement, loadFilters])

    useEffect(() => {
        if (CVManagement) {
            loadCvs()
        }
    }, [CVManagement, loadCvs])

    const onChangeFilters = useCallback((filters: CvsFiltersResponse) => {
        setSelectedFilters(filters)
    }, [])

    const onChangePageSize = useCallback(
        (size: Array<string>) => {
            if (totalCvs > 0 && cvs?.length === 0 && currentPage > 1) {
                setCurrentPage(1)
            } else {
                setPageSize(Number(size.toString()))
            }
        },
        [currentPage, cvs?.length, totalCvs],
    )

    const onClearFilters = useCallback(() => {
        setSelectedFilters(defaultFilters)
    }, [])
    const filtersApplied = useMemo(() => {
        let counter = 0
        counter += countArrayFilters(selectedFilters, ['shNames', 'taOwners', 'premium', 'msaSigned'])
        return counter > 0
    }, [selectedFilters])

    const searchOrFilters = !!searchText || filtersApplied

    const onCVChange = useCallback(
        (specialistId: string) => {
            const index = getIndex(specialistId)
            trigger([`cvs.${index}.status`, `cvs.${index}.taOwner`]).then(() => {
                if (!errors?.cvs?.[index]) {
                    const status = getValues(`cvs.${index}.status`)
                    const taOwner = getValues(`cvs.${index}.taOwner`)
                    const cv = cvs?.find(item => item.specialistId === specialistId)
                    const dataChanged = status !== cv?.status || taOwner !== (cv?.taOwner || '')

                    if (dataChanged) {
                        setPending(true)
                        editCV({ status, taOwner }, specialistId)
                            .then(data => {
                                setCvs(prevCvs =>
                                    prevCvs
                                        ? prevCvs?.map(item =>
                                              item.specialistId === specialistId
                                                  ? { ...item, status: data.status, taOwner: data.taOwner }
                                                  : item,
                                          )
                                        : prevCvs,
                                )
                                handleEditingKeysChange(specialistId)
                                loadFilters()
                            })
                            .catch(err => {
                                addError()
                                log(err)
                            })
                            .finally(() => {
                                setPending(false)
                            })
                    } else {
                        handleEditingKeysChange(specialistId)
                    }
                }
            })
        },
        [addError, cvs, errors?.cvs, getIndex, getValues, handleEditingKeysChange, loadFilters, log, trigger],
    )

    const columns: ColumnsType<CV> = useMemo(
        () => [
            {
                title: 'Name',
                dataIndex: 'name',
                showSorterTooltip: false,
                render: (_, { firstName, lastName }) => `${firstName} ${lastName}`,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                showSorterTooltip: false,
            },
            {
                title: 'SH Name',
                dataIndex: 'shName',
                showSorterTooltip: false,
            },
            {
                title: 'MSA',
                dataIndex: 'msa',
                showSorterTooltip: false,
                render: (_, { msaSigned }) => <YesNoCell yes={msaSigned} />,
            },
            {
                title: 'Premium',
                dataIndex: 'premium',
                showSorterTooltip: false,
                render: (_, { premium }) => <YesNoCell yes={premium} />,
            },
            {
                title: 'CV Uploaded On',
                dataIndex: 'cvUploadedOn',
                showSorterTooltip: false,
                render: (_, { cvUploadedOn }) => (cvUploadedOn ? convertTimestampToDateString(cvUploadedOn) : '-'),
            },
            {
                title: 'TA Owner',
                dataIndex: 'taOwner',
                showSorterTooltip: false,
                render: (_, { specialistId, taOwner }) =>
                    isEditing(specialistId) ? (
                        <TAOwnerEdit
                            taOwner={taOwner}
                            control={control}
                            index={getIndex(specialistId)}
                            options={companyMembers?.members.map(({ email }) => email) || []}
                        />
                    ) : (
                        taOwner
                    ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                showSorterTooltip: false,
                render: (_, { status, specialistId }) =>
                    isEditing(specialistId) ? (
                        <StatusEdit status={status} control={control} index={getIndex(specialistId)} />
                    ) : (
                        CVStatusMapping[status]
                    ),
            },
            {
                dataIndex: 'actions',
                render: (_, { specialistId, hasAnonymizedCv, firstName, lastName, role, shName }) => {
                    const handleClick = () => {
                        handleEditingKeysChange(specialistId)
                    }
                    const handleSubmit = () => {
                        onCVChange(specialistId)
                    }
                    const handleDownload = (key: CVType) => {
                        const request = key === CVType.Anonymized ? downloadAnonymizedCV : downloadCV
                        request(specialistId)
                            .then(data => {
                                saveAs(
                                    data,
                                    `${
                                        key === CVType.Anonymized ? 'Anonymized_' : ''
                                    }${firstName} ${lastName}_${role}_${shName}${fileTypeToExtension(data.type)}`,
                                )
                            })
                            .catch(err => {
                                addError()
                                log(err)
                            })
                    }
                    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
                        const files = e.target.files
                        if (files && files.length > 0) {
                            setPending(true)
                            const cvData = new FormData()
                            cvData.append('file', files[0])
                            uploadAnonymizedCV(cvData, specialistId)
                                .then(() => {
                                    addSuccess('File was uploaded successfully')
                                    loadCvs()
                                })
                                .catch(err => {
                                    addError()
                                    log(err)
                                    setPending(false)
                                })
                        }
                    }
                    const handleVerify = () => {
                        setPending(true)
                        verifyCV(specialistId)
                            .then(() => {
                                loadCvs()
                            })
                            .catch(err => {
                                addError()
                                log(err)
                                setPending(false)
                            })
                    }

                    return (
                        <CVActions
                            isEditing={isEditing(specialistId)}
                            handleClick={handleClick}
                            handleSubmit={handleSubmit}
                            handleDownload={handleDownload}
                            handleUpload={handleUpload}
                            handleVerify={handleVerify}
                            hasAnonymizedCV={hasAnonymizedCv}
                        />
                    )
                },
            },
        ],
        [addError, addSuccess, companyMembers?.members, control, getIndex, handleEditingKeysChange, isEditing, loadCvs, log, onCVChange],
    )

    return (
        <DashboardLayout applicationName='CV Management'>
            {!isPending && !filtersApplied && !searchText && cvs?.length === 0 ? null : (
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${theme.colors.gray_1};
                        padding: 18px 16px 0;
                        ${mqMin[1]} {
                            border-bottom: 1px solid ${theme.colors.gray_2};
                        }
                    `}
                >
                    <FiltersWrapper>
                        <H4>CV Management</H4>
                        <StyledSearchInput
                            initialText={searchText}
                            onSubmit={setSearchText}
                            filtersOpened={showFilters}
                            onToggleFilters={onToggleFilters}
                            placeholder='First Name, Last Name, SH Name, etc.'
                            dataTestId='cvs-search'
                        />
                        <CvsFiltersBar
                            possibleFilters={filtersOptions}
                            selectedFilters={selectedFilters}
                            opened={showFilters}
                            onClearAll={onClearFilters}
                            onChangeFilters={onChangeFilters}
                        />
                        <Divider />
                    </FiltersWrapper>
                </ColorBackgroundWrapper>
            )}
            {cvs && totalCvs > 0 && (
                <Fragment>
                    <ResponsiveGrid rowKey='specialistId' dataSource={cvs} columns={columns} pagination={false} mobileBreakpoint={2} />
                    <FullPageWrapper>
                        <PaginationWrapper>
                            <Pagination
                                pageSize={pageSize}
                                total={totalCvs}
                                defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                                onChangePageSize={onChangePageSize}
                                onChange={setCurrentPage}
                                current={currentPage}
                                css={css`
                                    ${mqMax[0]} {
                                        display: flex;
                                        justify-content: center;
                                    }
                                `}
                            />
                        </PaginationWrapper>
                    </FullPageWrapper>
                </Fragment>
            )}
            {totalCvs === 0 && !isPending && (
                <StyledDataNotFound
                    searchOrFilters={searchOrFilters}
                    iconName='add-empty'
                    iconSize={85}
                    title={'Good job! No new CVs found'}
                    description={searchOrFilters ? 'Try changing your filters or query' : 'All specialists have their CV verified'}
                />
            )}
            {isPending && <OverlayContentLoader />}
        </DashboardLayout>
    )
}

export { CVManagementPage }
