import { FunctionComponent, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

type ScrollTopProps = {
    action?: any
}

const ScrollToTop: FunctionComponent<React.PropsWithChildren<ScrollTopProps>> = ({ action }) => {
    const { pathname } = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname, action])

    return null
}

export { ScrollToTop }
