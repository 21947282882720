import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

import { Divider } from 'src/components/Divider'
import { Modal } from 'src/components/Modal'
import { ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { SendInviteButton } from 'src/components/profile/styled'
import { GreyParagraph } from 'src/components/shared/shared-styles'
import { hasErrors } from 'src/utils/errors'
import { yup } from 'src/validation/yup'

import { useInviteToOpp } from './hooks'
import { FormData } from './types'

interface OwnProps {
    opened: boolean
    onClose: () => void
    specialistId?: string
    isCandidateView?: boolean
    applicationId?: string
    title?: string
    description?: string
    selectPlaceholder?: string
}

const schema = yup.object().shape({
    opportunity: yup.array().of(
        yup.object().shape({
            value: yup.string(),
            label: yup.string(),
            groupName: yup.string(),
        }),
    ),
})

const InviteToOpportunityModal: React.FC<OwnProps> = ({
    onClose,
    opened,
    specialistId,
    isCandidateView = false,
    applicationId,
    title = 'Select the opportunity',
    description = "Please select an opportunity and the platform will send an invite to this specialist's manager.",
    selectPlaceholder = 'Select opportunity',
}) => {
    const {
        control,
        formState: { errors, touchedFields, isDirty, isSubmitting },
        handleSubmit,
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const { invite, loadingOpps, selectifiedOpps, handleInviteCandidate } = useInviteToOpp({ onClose, opened, specialistId })

    const onSubmit = (data: FormData) => {
        if (isCandidateView && applicationId) {
            handleInviteCandidate(applicationId, data)
        } else {
            invite(data)
        }
    }

    return (
        <Modal onClose={onClose} opened={opened}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h5>{title}</h5>
                <GreyParagraph>{description}</GreyParagraph>
                <ControlledAutocompleteSelect
                    placeholder={loadingOpps ? 'Loading...' : selectPlaceholder ?? 'Select opportunity'}
                    options={selectifiedOpps}
                    control={control}
                    name='opportunity'
                    dropdownWidth='100%'
                    dropdownHeight='280px'
                    selectedLabelTransformer={({ label }) => label}
                    disabled={loadingOpps}
                    forceDownOpen
                />
                <Divider />
                <SendInviteButton type='submit' disabled={isSubmitting || !isDirty || !touchedFields || hasErrors(errors)}>
                    {isSubmitting ? 'Inviting...' : 'Send Invite'}
                </SendInviteButton>
            </form>
        </Modal>
    )
}

export { InviteToOpportunityModal }
