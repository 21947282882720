/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useState } from 'react'
import { getCurrentUserPendingInvitations, getUser } from '../../../../../api/api'
import { Button } from '../../../../../components/Button'
import { Divider } from '../../../../../components/Divider'
import { Modal } from '../../../../../components/Modal'
import { useNotifications } from '../../../../../components/notification/NotificationProvider'
import { PendingInvitation as PendingInvitationType } from '../../../../../contracts/contexts'
import { history } from '../../../../../history'
import { useUserContext } from '../../../../../hooks/useUserContext'
import { ReduxContext } from '../../../../../redux/Store'
import { useLogger } from '../../../../../utils/useLogger'
import { confirmInvitation, rejectInvitation } from '../../../api'

type PendingInvitationProps = {
    pendingInvitation: PendingInvitationType
}

enum InvitationStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

const invitationStatusMapping = {
    [InvitationStatus.PENDING]: 'Pending',
    [InvitationStatus.ACCEPTED]: 'Accepted',
    [InvitationStatus.REJECTED]: 'Rejected',
}

const PendingInvitation: FunctionComponent<React.PropsWithChildren<PendingInvitationProps>> = ({ pendingInvitation }) => {
    const [status, setStatus] = useState(InvitationStatus.PENDING)
    const [contextId, setContextId] = useState<string>()
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
    const theme = useTheme()
    const log = useLogger('error')
    const { addError } = useNotifications()
    const {
        selectors: { user },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const toggleModal = useCallback(() => {
        setIsModalVisible(currentIsModalVisible => !currentIsModalVisible)
    }, [])

    const { setActiveContextId, removeContextsData } = useUserContext()

    const handleAccept = useCallback(() => {
        layoutToggleLoader(true)
        confirmInvitation(pendingInvitation.prospectId)
            .then(data => {
                if (data) {
                    setContextId(data.contextId)
                    setStatus(InvitationStatus.ACCEPTED)
                    getUser(user.firebaseId)
                        .then(userData => {
                            if (!userData.firstName) {
                                history.push(
                                    `/refresh-user-context?wantedContextId=${data.contextId}&afterSuccess=/dashboard/my-account/personal-details?afterSuccess=/dashboard`,
                                )
                            } else {
                                removeContextsData()
                                setActiveContextId(data.contextId)
                            }
                        })
                        .catch(error => {
                            log(error)
                            addError()
                        })
                        .finally(() => layoutToggleLoader(false))
                } else {
                    layoutToggleLoader(false)
                }
            })
            .catch(error => {
                log(error)
                addError()
                layoutToggleLoader(false)
            })
    }, [layoutToggleLoader, pendingInvitation.prospectId, removeContextsData, setActiveContextId, user.firebaseId, log, addError])

    const handleReject = useCallback(() => {
        rejectInvitation(pendingInvitation.prospectId)
            .then(() => {
                setStatus(InvitationStatus.REJECTED)

                getCurrentUserPendingInvitations()
                    .then(pendingInvitations => {
                        if (pendingInvitations.length === 0) {
                            history.push('/dashboard/company-account/company-details')
                        }
                    })
                    .catch(log)
                    .finally(() => layoutToggleLoader(false))
            })
            .catch(error => {
                log(error)
                addError()
                layoutToggleLoader(false)
            })
        toggleModal()
    }, [pendingInvitation.prospectId, toggleModal, log, layoutToggleLoader, addError])

    const handleGoToCompanyDashboard = useCallback(() => {
        history.push(`/refresh-user-context?wantedContextId=${contextId}`)
    }, [contextId])

    return (
        <div
            css={css`
                padding: 24px 34px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                border: 1px solid;
                border-radius: 4px;
                border-color: ${status === InvitationStatus.ACCEPTED
                    ? theme.colors.green_1
                    : status === InvitationStatus.REJECTED
                    ? theme.colors.red_2
                    : theme.colors.gray_2};
                background-color: ${status === InvitationStatus.ACCEPTED
                    ? theme.colors.green_1
                    : status === InvitationStatus.REJECTED
                    ? theme.colors.red_2
                    : theme.colors.white};
                ${status === InvitationStatus.PENDING && `box-shadow: 0 4px 12px ${theme.colors.box_shadow_color};`}
            `}
        >
            <div>
                <h6
                    css={css`
                        margin: 0;
                    `}
                >
                    {pendingInvitation.companyName}
                </h6>
                <div
                    css={css`
                        color: ${status === InvitationStatus.ACCEPTED
                            ? theme.colors.green_4
                            : status === InvitationStatus.REJECTED
                            ? theme.colors.red_4
                            : theme.colors.gray_3};
                    `}
                >
                    {invitationStatusMapping[status]}
                </div>
            </div>
            {status === InvitationStatus.PENDING && (
                <div
                    css={css`
                        min-width: 165px;
                        justify-content: flex-end;
                        display: flex;
                    `}
                >
                    <Button onClick={handleAccept}>Accept</Button>
                    <Button
                        onClick={toggleModal}
                        variant='tertiary'
                        style={css`
                            margin-left: 16px;
                        `}
                    >
                        Reject
                    </Button>
                </div>
            )}
            {status === InvitationStatus.ACCEPTED && (
                <div>
                    <Button
                        onClick={handleGoToCompanyDashboard}
                        variant='tertiary'
                        style={css`
                            margin-left: 16px;
                        `}
                    >
                        Go to Company’s Dashboard
                    </Button>
                </div>
            )}
            <Modal onClose={toggleModal} opened={isModalVisible}>
                <h5>Are you sure you want to reject this invite?</h5>
                <p>If you reject invite you will not be able to undo this action</p>
                <Divider />
                <div
                    css={css`
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant='link'
                        onClick={toggleModal}
                        style={css`
                            margin-right: 20px;
                        `}
                    >
                        Cancel
                    </Button>
                    <Button
                        css={css`
                            margin-right: 8px;
                        `}
                        onClick={handleReject}
                        variant='delete'
                    >
                        Reject
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export { PendingInvitation }
