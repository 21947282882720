/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { Nullable } from '../../../types'

type TimerProps = {
    time: Nullable<number>
}

const Timer: FunctionComponent<React.PropsWithChildren<TimerProps>> = ({ time }) => (
    <div
        css={css`
            width: 210px;
            height: 8px;
            border: 1px solid ${COLOR_PALETTE.green_2};
            border-radius: 4px;
            margin-top: 28px;
            opacity: ${time === null ? 0 : 1};
        `}
    >
        <div
            css={css`
                animation: ${'width-animate ' + time + 'ms linear infinite alternate'};
                height: 6px;
                border-radius: 4px;
                background-color: ${COLOR_PALETTE.green_2};

                @keyframes width-animate {
                    0% {
                        width: 0%;
                    }
                    100% {
                        width: 100%;
                    }
                }
            `}
        ></div>
    </div>
)

export { Timer }
