import { initializeApp, getApps } from 'firebase/app'
import {
    GoogleAuthProvider,
    OAuthProvider,
    getAuth,
    browserLocalPersistence,
    signInWithPopup as fSignInWithPopup,
    signOut as fSignOut,
    signInWithEmailAndPassword as fSignInWithEmailAndPassword,
    createUserWithEmailAndPassword as fCreateUserWithEmailAndPassword,
    sendPasswordResetEmail as fSendPasswordResetEmail,
    sendEmailVerification as fSendEmailVerification,
    applyActionCode as fApplyActionCode,
    verifyPasswordResetCode as fVerifyPasswordResetCode,
    confirmPasswordReset as fConfirmPasswordReset,
} from 'firebase/auth'

import { config } from '../environment/config'
import { log } from '../utils/useLogger'

const AUTH_EXIST_ERROR_CODE = 'auth/account-exists-with-different-credential'

if (!getApps().length) {
    initializeApp(config.firebaseConfig)
}

const providerGoogle = new GoogleAuthProvider()

const providerMicrosoft = new OAuthProvider('microsoft.com')
providerMicrosoft.setCustomParameters({})

const auth = getAuth()
auth.tenantId = config.gcpTenantId
auth.useDeviceLanguage()

const handleError = (error: any): Error => {
    log(error, 'error')
    return error
}

const signInWithPopup = async (popupProvider: any, showGoogleLoginModal?: () => void): Promise<any> => {
    let response: any

    await auth
        .setPersistence(browserLocalPersistence)
        .then(async () => {
            await fSignInWithPopup(auth, popupProvider)
                .then((r: any) => (response = r))
                .catch(error => {
                    if (error?.code === AUTH_EXIST_ERROR_CODE && showGoogleLoginModal) {
                        showGoogleLoginModal()
                    } else {
                        handleError(error)
                    }
                })
        })
        .catch(handleError)

    return response
}

const signInWithGoogle = async (): Promise<any> => {
    return signInWithPopup(providerGoogle)
}

const signInWithMicrosoft = async (showGoogleLoginModal: () => void): Promise<any> => {
    return signInWithPopup(providerMicrosoft, showGoogleLoginModal)
}

const signOut = async (): Promise<null> => {
    await fSignOut(auth).then().catch(handleError)

    return null
}

const signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    const response = await fSignInWithEmailAndPassword(auth, email, password).catch(handleError)

    return response
}

const createUserWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    const response = await fCreateUserWithEmailAndPassword(auth, email, password).catch(handleError)

    return response
}

const sendPasswordResetEmail = async (email: string): Promise<any> => {
    return await fSendPasswordResetEmail(auth, email).catch(handleError)
}

const sendVerificationEmail = async (): Promise<any> => {
    const user = auth.currentUser
    let response

    if (user && !user.emailVerified) {
        response = await fSendEmailVerification(user).catch(handleError)
    }

    return response
}

const applyActionCode = async (actionCode: string): Promise<any> => {
    return fApplyActionCode(auth, actionCode)
}

const verifyPasswordResetCode = async (actionCode: string): Promise<any> => {
    return fVerifyPasswordResetCode(auth, actionCode)
}

const confirmPasswordReset = async (code: string, newPassword: string): Promise<any> => {
    return fConfirmPasswordReset(auth, code, newPassword)
}

export {
    auth,
    signInWithGoogle,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    applyActionCode,
    verifyPasswordResetCode,
    confirmPasswordReset,
    signInWithMicrosoft,
    sendVerificationEmail,
}
