import styled from '@emotion/styled'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'

const EducationContainer = styled.div`
    background-color: ${COLOR_PALETTE.white};
    padding: 26px 36px 24px;
    margin-top: 24px;
    border: 1px solid ${COLOR_PALETTE.gray_2};
    border-radius: 4px;
`

const EducationHeader = styled.div`
    font-size: 14px;
    white-space: nowrap;
    ${mqMax[1]} {
        margin-bottom: 12px;
    }
`

const EducationDetailRow = styled.div`
    display: flex;
    margin: 14px 0;

    &:last-of-type {
        margin-bottom: 0;
    }

    ${mqMax[1]} {
        flex-direction: column;
    }
`

const DurationLabel = styled.small`
    flex: 1;
    color: ${COLOR_PALETTE.gray_3};
    line-height: 24px;
    white-space: nowrap;
    font-size: 14px;
`

const EducationTitle = styled.span`
    font-size: 14px;
    color: ${COLOR_PALETTE.gray_3};
    flex: 1;
`

const EducationValue = styled.p`
    margin: 0;
    flex: 4;
`

const EducationDurationBox = styled.div`
    background-color: ${COLOR_PALETTE.gray_2};
    color: ${COLOR_PALETTE.gray_5};
    border-radius: 55px;
    padding: 2px 12px;
    font-size: 14px;
    min-width: 90px;
    text-align: center;
`

export { EducationContainer, EducationHeader, EducationDetailRow, DurationLabel, EducationTitle, EducationValue, EducationDurationBox }
