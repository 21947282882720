/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../components/Button'
import { DefaultLayout } from '../../components/DefaultLayout'
import { mqMin } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'

const AccessDenied: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const navigate = useNavigate()

    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const handleGoBack = useCallback((): void => {
        navigate(decodedAccessToken.specialistId ? '/specialist-dashboard' : '/')
    }, [navigate, decodedAccessToken])

    const handleLogout = useCallback(() => {
        navigate('/logout/user')
    }, [navigate])

    return (
        <DefaultLayout
            icon='broken-link-public'
            title='You don’t have permission to view this page.'
            description={'You are already logged in as different user. To continue you need to log in with associated e-mail address.'}
        >
            <div
                css={css`
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;

                    ${mqMin[1]} {
                        flex-direction: row;
                    }
                `}
            >
                <Button
                    css={css`
                        margin-bottom: 10px;

                        ${mqMin[1]} {
                            margin-right: 8px;
                        }
                    `}
                    onClick={handleGoBack}
                >
                    Go to Dashboard
                </Button>
                <Button
                    css={css`
                        margin-bottom: 10px;
                    `}
                    onClick={handleLogout}
                    variant='tertiary'
                    dataTestId='different-user'
                >
                    Log In as different user
                </Button>
            </div>
        </DefaultLayout>
    )
}

export { AccessDenied }
