/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { ColorPicker, ColorPickerProps } from '../inputs/ColorPicker'
import { ControlledFieldProps } from './types'

type ControlledColorPickerProps = Omit<ColorPickerProps, 'value' | 'onChange' | 'errorMessage'> & ControlledFieldProps<string>

const ControlledColorPicker: FunctionComponent<React.PropsWithChildren<ControlledColorPickerProps>> = ({
    control,
    name,
    infoMessage,
    defaultValue,
    label,
    labelTooltip,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    return (
        <ColorPicker
            label={label}
            labelTooltip={labelTooltip}
            onChange={onChange}
            value={value}
            infoMessage={infoMessage}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
        />
    )
}

export { ControlledColorPicker }
