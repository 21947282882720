/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useMemo } from 'react'
import { VerifiedSkill } from '../../contracts/profile/skill'
import { SearchSpecialist } from '../../contracts/search/searchSpecialist'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { getStringFromArray } from '../../utils/lists'
import { TextHighlight } from '../TextHighlight'
import { SkillsList } from './SkillsList'
import { TalentBoxLabelGrey } from './TalentResultBox'

const TalentBoxBottomInfoRow = styled.div`
    width: 100%;
    margin: 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;

    &:last-of-type {
        margin: 0;
    }
`

const TalentBoxBottomTruncateWrapper = styled.div`
    width: 100%;
    margin: 0 0 15px;

    &:last-of-type {
        margin: 0;
    }
`

const TalentBoxColumnHeading = styled.div`
    width: 35%;
    padding-right: 10px;
`

const TalentBoxColumnContent = styled.div`
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 20px;
    color: ${COLOR_PALETTE.gray_6};
`

export interface TalentBoxBottomInfoProps {
    talent: SearchSpecialist
    searchWords: Array<string>
}

const TalentBoxBottomInfo: FunctionComponent<React.PropsWithChildren<TalentBoxBottomInfoProps>> = ({ talent, searchWords }) => {
    const theme = useTheme()

    const topSkills = useMemo((): Array<VerifiedSkill> => {
        const { rolesInfo } = talent
        let skills: Array<VerifiedSkill> = []
        if (rolesInfo && rolesInfo.length) {
            rolesInfo.forEach(role => {
                skills = skills.concat(role.verifiedTopSkills)
            })
        }
        return skills.length > 0 ? skills : []
    }, [talent])

    return talent ? (
        <div
            css={css`
                padding: 15px 16px;
            `}
        >
            <Fragment>
                <TalentBoxLabelGrey>Top Skills</TalentBoxLabelGrey>

                <TalentBoxBottomTruncateWrapper
                    css={css`
                        background-color: ${theme.colors.violet_1};
                        border-radius: 4px;
                        padding: 8px;
                    `}
                >
                    <SkillsList skills={topSkills} searchWords={searchWords} truncated topSkills />
                </TalentBoxBottomTruncateWrapper>
            </Fragment>

            <TalentBoxBottomInfoRow>
                <TalentBoxColumnHeading>
                    <TalentBoxLabelGrey
                        css={css`
                            padding-left: 8px;
                        `}
                    >
                        Languages
                    </TalentBoxLabelGrey>
                </TalentBoxColumnHeading>
                <TalentBoxColumnContent>
                    <TextHighlight searchWords={searchWords} text={getStringFromArray(talent.languages)} />
                </TalentBoxColumnContent>
            </TalentBoxBottomInfoRow>
            <TalentBoxBottomInfoRow>
                <TalentBoxColumnHeading>
                    <TalentBoxLabelGrey
                        css={css`
                            padding-left: 8px;
                        `}
                    >
                        Location
                    </TalentBoxLabelGrey>
                </TalentBoxColumnHeading>
                <TalentBoxColumnContent>
                    <TextHighlight searchWords={searchWords} text={talent.location} />
                </TalentBoxColumnContent>
            </TalentBoxBottomInfoRow>
        </div>
    ) : null
}

export { TalentBoxBottomInfo }
