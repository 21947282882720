/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { mqMax } from '../../../GlobalStyle'
import { Button } from '../../Button'
import { ControlledRatingStars } from '../../forms/ControlledRatingStars'
import { ControlledTextarea } from '../../forms/ControlledTextarea'
import { FieldWrapper } from '../../layout/FormHelpers'

type SecondStepPositiveFeedbackProps = {
    pleaseRateMessage: string
    onPositiveSubmit: (formData: any) => void
}

const SecondStepPositiveFeedback: FunctionComponent<React.PropsWithChildren<SecondStepPositiveFeedbackProps>> = ({
    pleaseRateMessage,
    onPositiveSubmit,
}) => {
    const { handleSubmit, control, reset } = useForm()

    useEffect(() => {
        reset({ rating: 0 })
    }, [reset])

    return (
        <form onSubmit={handleSubmit(onPositiveSubmit)}>
            <h3
                css={css`
                    margin: 24px 0;
                `}
            >
                Congratulations!
            </h3>

            <p
                css={css`
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 24px;
                `}
            >
                Would you like to share any additional feedback with us?
            </p>

            <FieldWrapper>
                <ControlledTextarea placeholder='Type something' control={control} label='Add a Comment (Optional)' name='comments' />
            </FieldWrapper>

            <p
                css={css`
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 24px;
                `}
            >
                {pleaseRateMessage}
            </p>

            <ControlledRatingStars control={control} name='rating' />

            <FieldWrapper
                css={css`
                    margin-top: 24px;
                `}
            >
                <ControlledTextarea
                    placeholder='Type something'
                    control={control}
                    label='Add a Comment (Optional)'
                    name='additionalComments'
                />
            </FieldWrapper>

            <Button
                variant='primary'
                type='submit'
                style={css`
                    width: 145px;

                    ${mqMax[1]} {
                        width: 100%;
                    }
                `}
            >
                Submit
            </Button>
        </form>
    )
}

export { SecondStepPositiveFeedback }
