/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { signInWithLink } from '../../api/api'
import { Button } from '../../components/Button'
import { ControlledInput } from '../../components/forms/ControlledInput'
import { Icon, RoundIconWrapper } from '../../components/Icon'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { PageLayoutA } from '../../components/layout/PageLayoutA'
import { TwoCols } from '../../components/layout/TwoCols'
import { Logo } from '../../components/Logo'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { UserDataSSO } from '../../contracts/userDataSSO'
import { mqMax } from '../../GlobalStyle'
import { useUserAuthentication } from '../../hooks/useUserAuthentication'
import { ReduxContext } from '../../redux/Store'
import { COLOR_PALETTE } from '../../theme/colors'
import { Nullable } from '../../types'
import { hasErrors } from '../../utils/errors'
import { useLogger } from '../../utils/useLogger'
import { yup } from '../../validation/yup'
import girlFloatingSkills from './../../assets/images/girl_floating_skills.png'

const schema = yup.object().shape({
    email: yup.string().email(),
})

type ConfirmEmailType = {
    oobCode: string
    continueUrl: Nullable<string>
}

const ConfirmEmail: FunctionComponent<React.PropsWithChildren<ConfirmEmailType>> = ({ oobCode, continueUrl }) => {
    const navigate = useNavigate()
    const { addError } = useNotifications()
    const log = useLogger('error')
    const { login } = useUserAuthentication()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const [error, setError] = useState<string>('')
    const {
        formState: { errors, isSubmitting, isDirty },
        handleSubmit,
        control,
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        },
    })

    const signIn = useCallback(
        (email: string) => {
            layoutToggleLoader(true)
            signInWithLink(email, oobCode, continueUrl)
                .then((userData: UserDataSSO) => {
                    userData.firebaseId = userData.userId
                    userData.emailVerified = true
                    userData.accessToken = userData.token
                    userData.providerId = 'magic-link'

                    login(userData, 'specialist')

                    if (continueUrl) {
                        const path = continueUrl.split('.com')

                        navigate(path[1])
                    } else {
                        navigate('/')
                    }
                })
                .catch(err => {
                    if (err.status === 400) {
                        setError('INVALID_EMAIL')
                    } else if (err.status === 405) {
                        navigate(`/magic-link/resend?url=${encodeURIComponent(continueUrl || '')}`)
                    } else {
                        addError()
                    }

                    log(err)
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        },
        [oobCode, addError, continueUrl, navigate, layoutToggleLoader, log, login],
    )

    const onSubmit = useCallback(
        (formData: any): void => {
            signIn(formData.email)
        },
        [signIn],
    )

    return error === 'INVALID_EMAIL' ? (
        <PageLayoutA>
            <div
                css={css`
                    text-align: center;
                `}
            >
                <RoundIconWrapper
                    size={80}
                    css={css`
                        overflow: hidden;
                        position: relative;
                        border-width: 4px;
                        border-style: solid;
                        margin: 0 auto 32px auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <Icon size={32} name='envelope-warning' />
                </RoundIconWrapper>

                <h4>The E-mail You Entered is Incorrect</h4>
                <p>
                    Please click on the original link in the first e-mail we sent, confirm your e-mail address, click on the new ‘magic
                    link’ and then confirm your e-mail address once again.
                </p>
                <p>We apologize for the inconvenience but we love data security! </p>
            </div>
        </PageLayoutA>
    ) : (
        <TwoCols
            firstCol={
                <div
                    css={css`
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 48px 16px;
                    `}
                >
                    <div
                        css={css`
                            margin: 0 auto;
                            max-width: 420px;
                            display: flex;
                            flex-direction: column;
                            flex: 1;
                            align-items: flex-start;
                        `}
                    >
                        <Logo type='logoFull' />
                        <div
                            css={css`
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                flex: 1;
                            `}
                        >
                            <h4>Please Confirm Your Email Address</h4>
                            <p>
                                And then we'll take you straight to your profile! Please make sure you submit the same email address that
                                you received the initial request to start editing your Talent Alpha Profile. Thanks!
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <FieldWrapper>
                                    <ControlledInput
                                        control={control}
                                        name='email'
                                        placeholder='E-mail'
                                        label='E-mail:'
                                        dataTestId='confirm-email-input'
                                    />
                                </FieldWrapper>
                                <Button
                                    variant='primary'
                                    type='submit'
                                    disabled={isSubmitting || !isDirty || hasErrors(errors)}
                                    css={css`
                                        width: 145px;
                                        ${mqMax[1]} {
                                            width: 100%;
                                            margin-bottom: 100px;
                                        }
                                    `}
                                    dataTestId='confirm-email-submit'
                                >
                                    Continue
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            }
            secondCol={
                <div
                    css={css`
                        height: 100%;
                        background-color: ${COLOR_PALETTE.beige_2};
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    `}
                >
                    <div
                        css={css`
                            margin: 0 auto;
                            max-width: 370px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            text-align: center;
                        `}
                    >
                        <img
                            src={girlFloatingSkills}
                            alt='girl and floating skill tags'
                            css={css`
                                width: 100%;
                            `}
                        />
                        <h4>What’s the secret behind your talent genome?</h4>
                        <p>
                            When you fill in your skills and your experience, your employer will be able to understand your technical
                            competencies, social skills, cognitive abilities and personality traits. Accordingly, the Talent Genome™ of each
                            individual or even the entire organization is created providing valuable insights.
                        </p>
                    </div>
                </div>
            }
        />
    )
}

export { ConfirmEmail }
