/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { AccessLevelContext } from '../../AccessLevelContext'
import { BackLink } from '../../components/BackLink'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { getAppPath } from '../../contracts/applications'
import { ParsedCv, ProcessingCv } from '../../contracts/cvs'
import { useMySpecialistsMenuItems } from '../../hooks/useMySpecialistsMenuItems'
import { useLogger } from '../../utils/useLogger'
import { getParsedCvs, getProcessingCvs } from './api'
import { ConfirmExtractedData } from './cv-parsing/ConfirmExtractedData'
import { UploadCvs } from './cv-parsing/UploadCvs'

const CvParsingPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const log = useLogger('error')

    const [processingCvs, setProcessingCvs] = useState<Array<ProcessingCv>>([])
    const [parsedCvs, setParsedCvs] = useState<Array<ParsedCv>>([])

    const { menuItems, applicationName } = useMySpecialistsMenuItems()
    const { mySpecialists: mySpecialistsAccess } = useContext(AccessLevelContext)

    const loadParsedCvs = useCallback(() => {
        getParsedCvs().then(setParsedCvs).catch(log)
    }, [log])

    const loadProcessingCvs = useCallback(() => {
        return getProcessingCvs()
            .then(cvs => {
                setProcessingCvs(cvs)
                return cvs
            })
            .catch(log)
    }, [log, setProcessingCvs])

    const onRemoveCv = useCallback(
        (id: string) => {
            setProcessingCvs(processingCvs.filter(cv => cv.id !== id))
            setParsedCvs(parsedCvs.filter(cv => cv.id !== id))
        },
        [parsedCvs, processingCvs],
    )

    useEffect(() => {
        loadProcessingCvs()
        loadParsedCvs()
    }, [loadParsedCvs, loadProcessingCvs])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    padding-top: 25px;
                    max-width: 1440px;
                    margin: 0 auto;
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        margin: 0 auto;
                    `}
                >
                    {mySpecialistsAccess.backToList && (
                        <BackLink
                            path={getAppPath('MY_SPECIALISTS')}
                            text='Back to List'
                            style={css`
                                margin-bottom: 16px;
                                margin-left: 25px;
                            `}
                        />
                    )}
                    <div
                        css={css`
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                        `}
                    >
                        <div
                            css={css`
                                padding: 0 25px;
                            `}
                        >
                            <h3>Create New Specialist</h3>
                            <Divider />
                            <UploadCvs loadProcessingCvs={loadProcessingCvs} />
                            <Divider />
                        </div>
                        <ConfirmExtractedData
                            loadParsedCvs={loadParsedCvs}
                            loadProcessingCvs={loadProcessingCvs}
                            processingCvs={processingCvs}
                            onRemoveProcessingCv={onRemoveCv}
                            onRemoveParsedCv={onRemoveCv}
                            parsedCvs={parsedCvs}
                        />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export { CvParsingPage }
