/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent } from 'react'
import { FieldInfo, FieldInfoProps } from './FieldInfo'
import { FieldLabel, FieldLabelProps } from './FieldLabel'

export type InputFieldProps = FieldLabelProps & FieldInfoProps

const InputField: FunctionComponent<React.PropsWithChildren<InputFieldProps>> = ({
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    dataTestId,
    children,
}) => (
    <Fragment>
        <FieldLabel label={label} labelTooltip={labelTooltip} />
        {children}
        <FieldInfo
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId}
        />
    </Fragment>
)

export { InputField }
