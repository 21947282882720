import queryString from 'query-string'
import { getAppPath } from '../../../contracts/applications'
import { Paging } from '../../../contracts/common/pagingProps'
import { Nullable } from '../../../types'
import { countArrayFilters } from '../../../utils/filters'
import { encodeField, parseToArray, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { OpportunityOfferTypeEnum } from '../../../contracts/opportunities-manager/contracts'

import { OpenOpportunitiesParams, ResponseType } from '../../../contracts/open-opportunities/contracts'
import { OpenOpportunitiesFilters } from '../OpenOpportunitiesFiltersBar'

const DEFAULT_ITEMS_PER_PAGE = 10

const countFilters = (selectedFilters: Omit<OpenOpportunitiesParams, 'paging' | 'searchText' | 'sorting'>): number => {
    let counter = 0

    counter += countArrayFilters(selectedFilters, ['countries', 'industries', 'locationTypes', 'regions', 'skills', 'timeZones'])
    ;['currency', 'minMinExperienceInYears', 'minRate', 'projectStartDate', 'projectEndDate', 'responseType', 'type', 'visibility'].forEach(
        filterName => {
            if (selectedFilters[filterName as keyof OpenOpportunitiesFilters]) {
                counter++
            }
        },
    )
    return counter
}

const formatDealAndRate = (text: string) => {
    const textArray = text.split(/[- ]/)
    const min = +textArray[0]
    const max = +textArray[1]
    const rest = textArray.slice(2)

    return `${min.toLocaleString('en')} – ${max.toLocaleString('en')} ${rest.join('')}`
}

const menuItems = [
    {
        path: getAppPath('OPEN_OPPORTUNITIES'),
        title: 'Find Open Opportunities',
    },
    { path: `${getAppPath('OPEN_OPPORTUNITIES')}/applications`, title: 'My Applications', dataTestId: 'applications' },
]

const VMCmenuItems = [
    {
        path: getAppPath('OPEN_VACANCIES'),
        title: 'Find Open Vacancies',
    },
    { path: `${getAppPath('OPEN_VACANCIES')}/applications`, title: 'My Applications', dataTestId: 'applications' },
]

const stringifyOpenOpportunitiesSearchRequestToQuery = (searchRequest: OpenOpportunitiesParams): string => {
    const params = []
    const {
        countries,
        currency,
        industries,
        locationTypes,
        maxMinExperienceInYears,
        minMinExperienceInYears,
        minRate,
        paging,
        projectEndDate,
        projectStartDate,
        regions,
        responseType,
        searchText,
        skills,
        timeZones,
        type,
        visibilityValues,
    } = searchRequest

    if (countries && countries.length > 0) {
        params.push(`countries=${encodeField(countries)}`)
    }

    if (currency) {
        params.push(`currency=${encodeField(currency)}`)
    }

    if (industries && industries.length > 0) {
        params.push(`industries=${encodeField(industries)}`)
    }

    if (locationTypes && locationTypes.length > 0) {
        params.push(`locationTypes=${encodeField(locationTypes)}`)
    }

    if (maxMinExperienceInYears) {
        params.push(`maxMinExperienceInYears=${maxMinExperienceInYears}`)
    }

    if (minMinExperienceInYears) {
        params.push(`minMinExperienceInYears=${minMinExperienceInYears}`)
    }

    if (minRate) {
        params.push(`minRate=${minRate}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (projectEndDate) {
        params.push(`projectEndDate=${projectEndDate}`)
    }

    if (projectStartDate) {
        params.push(`projectStartDate=${projectStartDate}`)
    }

    if (regions && regions.length > 0) {
        params.push(`regions=${encodeField(regions)}`)
    }

    if (responseType) {
        params.push(`responseType=${responseType}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (skills && skills.length > 0) {
        params.push(`requiredSkills=${encodeField(skills)}`)
    }

    if (timeZones && timeZones.length > 0) {
        params.push(`timeZones=${encodeField(timeZones)}`)
    }

    if (type) {
        params.push(`type=${type}`)
    }
    if (visibilityValues && visibilityValues.length > 0) {
        params.push(`visibilityValues=${encodeField(visibilityValues)}`)
    }

    return params.join('&')
}

const parseQueryToOpenOpportunitiesSearchRequest = (searchQuery: string): OpenOpportunitiesParams & OpenOpportunitiesFilters & Paging => {
    const params = queryString.parse(searchQuery, { decode: false })
    const {
        countries,
        currency,
        industries,
        locationTypes,
        maxMinExperienceInYears,
        minMinExperienceInYears,
        minRate,
        page,
        projectEndDate,
        projectStartDate,
        regions,
        requiredSkills,
        responseType,
        searchText,
        size,
        timeZones,
        type,
        visibilityValues,
    } = params

    const result = {
        countries: parseToArray(countries),
        currency: parseToString(currency),
        industries: parseToArray(industries),
        locationTypes: parseToArray(locationTypes),
        maxMinExperienceInYears: maxMinExperienceInYears ? parseToNumber(maxMinExperienceInYears) : null,
        minMinExperienceInYears: minMinExperienceInYears ? parseToNumber(minMinExperienceInYears) : null,
        minRate: minRate ? parseToNumber(minRate) : null,
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, 10),
        },
        projectEndDate: projectEndDate ? parseToNumber(projectEndDate) : null,
        projectStartDate: projectStartDate ? parseToNumber(projectStartDate) : null,
        regions: parseToArray(regions),
        responseType: responseType as Nullable<ResponseType>,
        searchText: parseToString(searchText),
        skills: parseToArray(requiredSkills),
        sorting: [],
        timeZones: parseToArray(timeZones),
        type: type as Nullable<OpportunityOfferTypeEnum>,
        visibilityValues: parseToArray(visibilityValues),
    }

    return result
}

export {
    DEFAULT_ITEMS_PER_PAGE,
    countFilters,
    formatDealAndRate,
    menuItems,
    parseQueryToOpenOpportunitiesSearchRequest,
    stringifyOpenOpportunitiesSearchRequestToQuery,
    VMCmenuItems,
}
