/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useLifecycles } from 'react-use'
import { getApplications, getCities, updateUser } from 'src/api/api'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ControlledPhoneInput } from 'src/components/forms/ControlledPhoneInput'
import { ControlledSuggestionInput } from 'src/components/forms/ControlledSuggestionInput'
import { FlexButtons } from 'src/components/layout/FlexBoxHelpers'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from 'src/components/layout/ResponsiveColumns'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { companySizeMapping, companySizes, UserSegmentationCompany } from 'src/contracts/company'
import { Country } from 'src/contracts/country'
import { UserForm } from 'src/contracts/userData'
import { mqMax } from 'src/GlobalStyle'
import { useUserCompanies } from 'src/hooks/useUserCompanies'
import { useUserContext } from 'src/hooks/useUserContext'
import { useCountries, useIndustries, useSkills } from 'src/redux/dictionaryDataHooks'
import { ReduxContext } from 'src/redux/Store'
import { hasErrors } from 'src/utils/errors'
import { emailRegex, phoneRegex, uuidRegex, websiteRegex } from 'src/utils/regexes'
import { useLogger } from 'src/utils/useLogger'
import { getUserActiveContextStorage } from 'src/utils/userContext'
import { EMAIL_EXIST, FIELD_REQUIRED, INVALID_URL, PHONE_NOT_VALID } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'
import { addUserSegmentationCompany, editUserSegmentationCompany } from '../../api'
import { parseCompanyDetails, parseToPartnerData, UserFormData } from '../utils'
import { UserSegmentationRequestData } from '../../contracts'

const schema = yup.object().shape({
    legalName: yup.string().max(80).required(),
    country: yup.string().required(),
    city: yup.string().required(),
    website: yup.string().matches(websiteRegex, INVALID_URL).required(),
    workEmailAddress: yup.string().matches(emailRegex).required(),
    roleInTheCompany: yup.string().required(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    partnerClutchUrl: yup.string(),
    partnerCoreTechStack: yup.array().min(1, FIELD_REQUIRED).max(10, 'Max 10 skills allowed'),
    partnerSpecialistLocations: yup.array().min(1, FIELD_REQUIRED),
    partnerTechStack: yup.array().max(50, 'Max 50 skills allowed'),
    partnerIndustries: yup.array().min(1, FIELD_REQUIRED),
    phone: yup.lazy(value => (value ? yup.string().matches(phoneRegex, PHONE_NOT_VALID) : yup.string())),
    companySize: yup.string().min(1, FIELD_REQUIRED).required(FIELD_REQUIRED),
})

export interface PartnerAccountFormData {
    legalName: string
    country: string
    city: string
    website: string
    workEmailAddress: string
    roleInTheCompany: string
    companySize: string
    partnerClutchUrl: string
    partnerSpecialistLocations: Array<string>
    partnerCoreTechStack: Array<string>
    partnerTechStack: Array<string>
    partnerIndustries: Array<string>
}

type PartnerFormProps = {
    companyData: UserSegmentationCompany
    companyRoles: Array<string>
    userData: UserForm
    handleToggleModal: () => void
    cancel: () => void
    countries: Array<Country>
    editMode: boolean
    hasVmcStatus?: boolean
}

const PartnerForm: FunctionComponent<React.PropsWithChildren<PartnerFormProps>> = ({
    companyData,
    companyRoles,
    userData,
    handleToggleModal,
    cancel,
    countries,
    editMode,
    hasVmcStatus = false,
}) => {
    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])

    const navigate = useNavigate()

    const {
        selectors: { firebaseId, user, whiteLabel },
        actions: { layoutToggleLoader, dictionaryDataSet, companyUpdate },
    } = useContext(ReduxContext)

    const [isMounted, setIsMounted] = useState(false)

    const {
        handleSubmit,
        formState: { errors, isSubmitting, isDirty, touchedFields },
        control,
        setValue,
        watch,
        getValues,
        reset,
        setError,
    } = useForm<PartnerAccountFormData & UserFormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const { activeContext, isSuperAdmin } = useUserContext()
    const { hasInactiveCompany } = useUserCompanies()

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const log = useLogger()

    const { addSuccess, addError } = useNotifications()

    const watchCountry = watch('country')

    const skills = useSkills()
    const industries = useIndustries()

    useEffect(() => {
        if (!isSuperAdmin && hasInactiveCompany) {
            navigate('/dashboard/company-account/company-details')
        }
    }, [activeContext, hasInactiveCompany, navigate, isMounted, isSuperAdmin])

    const mapPersonalRequestData = useCallback(
        (data: UserFormData): any => ({
            id: user.uid,
            email: user.email,
            firebaseId,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
        }),
        [firebaseId, user.email, user.uid],
    )

    const onSubmit = useCallback(
        (data: PartnerAccountFormData & UserFormData) => {
            layoutToggleLoader(true)
            const companyRequest: UserSegmentationRequestData = {
                id: companyData.id || null,
                userId: firebaseId,
                type: 'PARTNER',
                clientIndustries: [],
                clientSizeOfITWorkForce: null,
                ...parseToPartnerData(data),
            }

            const modifyCompany = editMode
                ? editUserSegmentationCompany(companyData.id, companyRequest)
                : addUserSegmentationCompany(companyRequest, hasVmcStatus)

            modifyCompany
                .then(company => {
                    const additionalData =
                        typeof company === 'string'
                            ? { id: company }
                            : { id: company.id, createdAt: company.createdAt, msaSigned: company.msaSigned, status: company.status }
                    companyUpdate({ ...companyRequest, ...additionalData })
                    const { storedUserActiveContextId } = getUserActiveContextStorage()
                    navigate(
                        `/refresh-user-context?afterSuccess=/dashboard/company-account/company-details${
                            uuidRegex.test(storedUserActiveContextId) ? `&wantedContextId=${storedUserActiveContextId}` : ''
                        }`,
                    )
                })
                .then(() => {
                    getApplications().then(applicationsData => {
                        dictionaryDataSet({ name: 'applications', data: applicationsData })
                    })
                })
                .then(() => {
                    updateUser(firebaseId || '', mapPersonalRequestData(data)).catch(log)
                })
                .then(() => {
                    addSuccess('Company form submitted successfully.')
                })
                .catch(e => {
                    if (e.data.code === 'WORK_EMAIL_ALREADY_EXISTS') {
                        setError('workEmailAddress', { type: 'required', message: EMAIL_EXIST })
                    }
                    addError('There was a problem submitting your form. Please try again.')
                    log(e)
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        },

        [
            addError,
            addSuccess,
            companyData.id,
            companyUpdate,
            dictionaryDataSet,
            editMode,
            firebaseId,
            navigate,
            layoutToggleLoader,
            log,
            mapPersonalRequestData,
            setError,
            hasVmcStatus,
        ],
    )

    useEffect(() => {
        reset(parseCompanyDetails({ ...userData, ...companyData }))
    }, [companyData, reset, userData])

    const getCitiesForCountry = useCallback((fiter: string) => getCities(watchCountry, fiter), [watchCountry])

    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    const handleCancel = useCallback((): void => {
        if (isDirty) {
            handleToggleModal()
        } else {
            cancel()
        }
    }, [cancel, isDirty, handleToggleModal])

    return (
        <Fragment>
            {!whiteLabel?.readOnlySettings && (
                <h6
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    Company Details
                </h6>
            )}
            <Divider />
            <form onSubmit={handleSubmit(onSubmit)}>
                <section>
                    <FieldWrapper>
                        <ControlledInput
                            name='legalName'
                            label='Company Legal Name'
                            placeholder='Company Legal Name'
                            dataTestId='legal-name'
                            control={control}
                            labelTooltip='A legal name is the name that appears on the formation document of a corporation, LLC, LP or other statutory business entity. The legal name is the official name of your company that identifies it to the government.'
                        />
                    </FieldWrapper>
                    <ResponsiveColumns>
                        <FirstColumn>
                            <FieldWrapper>
                                <ControlledAutocompleteSelect
                                    label='Country'
                                    placeholder='Choose country'
                                    options={memorizedCountries}
                                    control={control}
                                    name='country'
                                    dataTestId='company-details-form-country'
                                    canClear
                                    dropdownWidth='100%'
                                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                                />
                            </FieldWrapper>
                        </FirstColumn>
                        <SecondColumn>
                            <FieldWrapper>
                                <ControlledSuggestionInput
                                    label='City'
                                    placeholder='Choose city'
                                    fetchOptions={getCitiesForCountry}
                                    disabled={!watchCountry}
                                    control={control}
                                    name='city'
                                    allowOnlyValuesFromDictionary
                                    fetchFromLetterNumber={1}
                                    dataTestId='company-details-form-city'
                                />
                            </FieldWrapper>
                        </SecondColumn>
                    </ResponsiveColumns>

                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            placeholder='Company size'
                            options={companySizes}
                            canFilter={false}
                            label='Company size'
                            control={control}
                            name='companySize'
                            selectedLabelTransformer={(val: string) => companySizeMapping[val]}
                            dataTestId='companySize'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            multiple
                            label='Please select the industries your company has experience with'
                            placeholder='Industries'
                            options={industries}
                            control={control}
                            name='partnerIndustries'
                            dropdownWidth='100%'
                            labelTooltip='We use this to pair you with opportunities that require deep domain knowledge.'
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledInput
                            name='partnerClutchUrl'
                            placeholder='Clutch URL'
                            label='Clutch URL (optional)'
                            dataTestId='partnerClutchUrl'
                            control={control}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            multiple
                            label='Locations of your specialists'
                            placeholder='Locations of your specialists'
                            options={memorizedCountries}
                            control={control}
                            name='partnerSpecialistLocations'
                            dropdownWidth='100%'
                            labelTooltip='We will use the location of your specialists to send you the notifications about the most relevant opportunities.'
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Core Tech Stack'
                            placeholder='Tech Stack'
                            virtualized
                            options={skills}
                            multiple
                            control={control}
                            name='partnerCoreTechStack'
                            dropdownWidth='100%'
                            labelTooltip='Please select up to 10 technologies your company has expertise with. Those technologies will be used to provide the most relevant opportunities for your company.'
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Additional Tech Stack (optional)'
                            placeholder='Tech Stack'
                            virtualized
                            options={skills}
                            multiple
                            control={control}
                            name='partnerTechStack'
                            dropdownWidth='100%'
                        />
                    </FieldWrapper>
                    <Divider />
                    <h6>Personal Information</h6>
                    <ResponsiveColumns>
                        <FirstColumn>
                            <FieldWrapper>
                                <ControlledInput
                                    name='firstName'
                                    placeholder=''
                                    label='First Name'
                                    dataTestId='first-name'
                                    control={control}
                                />
                            </FieldWrapper>
                        </FirstColumn>
                        <SecondColumn>
                            <FieldWrapper>
                                <ControlledInput
                                    name='lastName'
                                    placeholder=''
                                    label='Last Name'
                                    dataTestId='last-name'
                                    control={control}
                                />
                            </FieldWrapper>
                        </SecondColumn>
                    </ResponsiveColumns>

                    <FieldWrapper>
                        <ControlledPhoneInput
                            control={control}
                            name='phone'
                            label='Phone (optional)'
                            placeholder='Phone'
                            countryAreaCodes={countries}
                            dataTestId='phone'
                            canClear
                        />
                    </FieldWrapper>

                    <p>We’ll update this info in Personal Details settings.</p>
                    <Divider />
                </section>

                <section>
                    <h6>Additional Information</h6>
                    <FieldWrapper>
                        <ControlledInput
                            name='website'
                            label='Official Company Website'
                            placeholder='Official Company Website'
                            dataTestId='website'
                            control={control}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledInput
                            name='workEmailAddress'
                            label='Your Work Email Address'
                            placeholder='Your Work Email Address'
                            dataTestId='workEmailAddress'
                            control={control}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Your Role in the Company'
                            placeholder='Your Role in the Company'
                            options={companyRoles}
                            control={control}
                            name='roleInTheCompany'
                            dataTestId='roleInTheCompany'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>
                </section>

                <FlexButtons
                    css={css`
                        ${mqMax[1]} {
                            flex-direction: column-reverse;
                        }
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 24px;

                            ${mqMax[1]} {
                                margin: 12px 0 0;
                                justify-content: center;
                                width: 100%;
                            }
                        `}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        css={css`
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                        variant='primary'
                        type='submit'
                        disabled={isSubmitting || !isDirty || hasErrors(errors)}
                        dataTestId='save-company-account'
                    >
                        Save Company Account
                    </Button>
                </FlexButtons>
            </form>
        </Fragment>
    )
}

export { PartnerForm }
