/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { LayoutWithStickyColumn } from '../../components/layout/LayoutWithStickyColumn'
import { Spinner } from '../../components/layout/Loader'
import { PageWidthWrapper } from '../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../components/layout/SpecialistPageWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { AddSkillsBox } from '../../components/skills-questionnaire/AddSkillsBox'
import { SkillsQuestionnaire } from '../../components/skills-questionnaire/SkillsQuestionnaire'
import { Skill, SkillProficiency, SkillStatus, SpecialistSkills } from '../../contracts/skills'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { BottomBar } from 'src/components/BottomBar'
import { SpecialistTabType } from '../my-specialists/SpecialistProfilePage'
import {
    addSkill,
    addSkills,
    deleteSkill,
    deleteSpecialistSuggestedSkill,
    getSpecialistSkillsEditView,
    getSpecialistSuggestedSkills,
    updateSkillLevel,
} from './api'

type SpecialistSkillsEditProps = {}

const NEW_ADDED = 'New Added'

const SpecialistSkillsEdit: FunctionComponent<React.PropsWithChildren<SpecialistSkillsEditProps>> = () => {
    const { specialistId: specialistIdParam } = useParams<{ specialistId: string }>()
    const { addError } = useNotifications()
    const log = useLogger('error')
    const [skills, setSkills] = useState<Nullable<SpecialistSkills>>(null)
    const [suggestedSkills, setSuggestedSkills] = useState<Array<string>>([])
    const [activeSavesIndicator, setActiveSavesIndicator] = useState<Nullable<number>>(null)
    const {
        selectors: { decodedAccessToken },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const specialistId = useMemo(() => decodedAccessToken?.specialistId || specialistIdParam, [decodedAccessToken, specialistIdParam])

    useEffect(() => {
        layoutToggleLoader(true)
        Promise.allSettled([getSpecialistSkillsEditView(specialistId), getSpecialistSuggestedSkills(specialistId)])
            .then(results => {
                if (results[0].status === 'fulfilled') {
                    setSkills(results[0].value)
                } else {
                    log(results[0].reason)
                    addError()
                }
                if (results[1].status === 'fulfilled') {
                    setSuggestedSkills(results[1].value.suggestedSkills)
                } else {
                    log(results[1].reason)
                }
            })
            .finally(() => layoutToggleLoader(false))
    }, [addError, layoutToggleLoader, log, specialistId])

    const handleError = useCallback(
        (categoryIndex: any, skillIndex: any, oldSkill: any) => {
            setSkills(oldSkills => {
                const newSkills = { ...(oldSkills as SpecialistSkills) }
                newSkills.categories[categoryIndex].skills[skillIndex] = oldSkill
                return newSkills
            })
            addError()
        },
        [addError],
    )

    const handleSkillChange = useCallback(
        (newSkill: Skill, category?: string) => {
            if (skills) {
                const categoryIndex = skills.categories.findIndex(oldCategory => oldCategory.category === category)
                const skillIndex = skills.categories[categoryIndex].skills.findIndex(skill => skill.id === newSkill.id)
                const oldSkill = skills.categories[categoryIndex].skills[skillIndex]
                setSkills(oldSkills => {
                    const newSkills = { ...(oldSkills as SpecialistSkills) }
                    newSkills.categories[categoryIndex].skills[skillIndex] = {
                        ...newSkill,
                        proficiency: newSkill.status === SkillStatus.DELETED ? SkillProficiency.NOT_RATED : newSkill.proficiency,
                    }
                    return newSkills
                })

                setActiveSavesIndicator(activeSaves => (activeSaves === null ? 1 : activeSaves + 1))

                if (newSkill.proficiency !== oldSkill.proficiency) {
                    updateSkillLevel(specialistId, newSkill.id, newSkill.proficiency)
                        .catch(() => {
                            handleError(categoryIndex, skillIndex, oldSkill)
                        })
                        .finally(() => setActiveSavesIndicator(activeSaves => (activeSaves as number) - 1))
                }

                if (newSkill.status !== oldSkill.status) {
                    if (newSkill.status === SkillStatus.DELETED) {
                        deleteSkill(specialistId, newSkill.id)
                            .catch(() => {
                                handleError(categoryIndex, skillIndex, oldSkill)
                            })
                            .finally(() => setActiveSavesIndicator(activeSaves => (activeSaves as number) - 1))
                    }
                    if (newSkill.status === SkillStatus.IN_PROGRESS) {
                        addSkill(specialistId, newSkill.name)
                            .then(skill => {
                                setSkills(oldSkills => {
                                    const newSkills = { ...(oldSkills as SpecialistSkills) }
                                    newSkills.categories[categoryIndex].skills[skillIndex] = skill
                                    return newSkills
                                })
                            })
                            .catch(() => {
                                handleError(categoryIndex, skillIndex, oldSkill)
                            })
                            .finally(() => setActiveSavesIndicator(activeSaves => (activeSaves as number) - 1))
                    }
                }
            }
        },
        [skills, specialistId, handleError],
    )

    const handleSkillAdd = useCallback(
        (skillsToAdd: Array<string>) => {
            layoutToggleLoader(true)

            addSkills(specialistId, skillsToAdd)
                .then(addedSkills => {
                    setSkills(oldSkills => {
                        const newSkills = { ...(oldSkills as SpecialistSkills) }
                        if (newSkills.categories?.[0]?.category === NEW_ADDED) {
                            newSkills.categories[0].skills = newSkills.categories[0].skills
                                .concat(addedSkills.skills.filter(skill => skill !== undefined))
                                .sort((skillA, skillB) => (skillA.name > skillB.name ? 1 : -1))
                        } else {
                            newSkills.categories.unshift({
                                category: NEW_ADDED,
                                skills: addedSkills.skills.filter(skill => skill !== undefined),
                            })
                        }

                        return newSkills
                    })
                    setSuggestedSkills(currentSuggestedSkills =>
                        currentSuggestedSkills.filter(suggestedSkill => !skillsToAdd.find(skill => skill === suggestedSkill)),
                    )
                })
                .catch(e => {
                    log(e)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        },
        [addError, layoutToggleLoader, log, specialistId],
    )

    const handleSkillRemove = useCallback(
        (suggestedSkill: any) => {
            setSuggestedSkills(currentSuggestedSkills => currentSuggestedSkills.filter(skill => skill !== suggestedSkill))
            deleteSpecialistSuggestedSkill(specialistId, suggestedSkill).catch(err => {
                log(err)
                addError()
                setSuggestedSkills(currentSuggestedSkills => currentSuggestedSkills.concat(suggestedSkill))
            })
        },
        [addError, log, specialistId],
    )

    const backPath = useMemo(
        () =>
            specialistIdParam
                ? `/my-specialists/specialist/${specialistId}?tab=${SpecialistTabType.TECHNICAL}`
                : `/profile-summary/${specialistId}`,
        [specialistIdParam, specialistId],
    )

    const theme = useTheme()

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;
                min-height: 100vh;
                ${mqMax[2]} {
                    margin-bottom: 150px;
                }
            `}
        >
            <PageWidthWrapper>
                <BackLink
                    text='Back to profile'
                    path={backPath}
                    style={css`
                        margin-bottom: 24px;
                    `}
                    dataTestId='back'
                />
                <h3>Edit Technical Skills</h3>
            </PageWidthWrapper>
            <LayoutWithStickyColumn
                firstCol={
                    <SkillsQuestionnaire
                        handleSkillAdd={handleSkillAdd}
                        handleSkillRemove={handleSkillRemove}
                        specialistSkills={skills}
                        handleSkillChange={handleSkillChange}
                        removable
                        suggestedSkills={suggestedSkills}
                    />
                }
                secondCol={<AddSkillsBox handleSkillSelect={handleSkillAdd} skills={skills} specialistId={specialistId} />}
            />
            <BottomBar
                containerStyles={css`
                    z-index: 1;
                    height: 60px;
                `}
            >
                <LayoutWithStickyColumn
                    firstCol={
                        <FlexBox
                            css={css`
                                align-items: center;
                                justify-content: flex-end;
                            `}
                        >
                            {activeSavesIndicator !== null && (
                                <div
                                    css={css`
                                        color: ${activeSavesIndicator > 0 ? 'transparent' : theme.colors.gray_3};
                                        margin-right: 16px;
                                        position: relative;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    `}
                                >
                                    All changes saved
                                    {activeSavesIndicator > 0 && (
                                        <div
                                            css={css`
                                                position: absolute;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                            `}
                                        >
                                            <Spinner circleWidth={3} sizeInner={25} sizeOuter={30} />
                                        </div>
                                    )}
                                </div>
                            )}
                            <Link
                                to={backPath}
                                css={css`
                                    &:hover {
                                        text-decoration: none;
                                    }
                                `}
                            >
                                <Button variant='primary'>Done</Button>
                            </Link>
                        </FlexBox>
                    }
                />
            </BottomBar>
        </SpecialistPageWrapper>
    )
}

export { SpecialistSkillsEdit }
