/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { SendMagicLink } from './components/SendMagicLink'

const ResendMagicLink: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <SendMagicLink
        header='Oops, the link has expired.'
        description='Please enter your e-mail address again and we’ll send you a new link.'
    />
)

export { ResendMagicLink }
