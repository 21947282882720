/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, MutableRefObject, useCallback } from 'react'
import { Button } from '../../../../../components/Button'
import { IconButton } from '../../../../../components/Icon'
import { DropdownComponent } from '../../../../../components/inputs/autocomplete-select/DropdownComponent'
import { useNotifications } from '../../../../../components/notification/NotificationProvider'
import { COLOR_PALETTE, mqMax } from '../../../../../GlobalStyle'
import { log } from '../../../../../utils/useLogger'
import { cancelVMSInvitation, resendVMSInvitation } from '../../../api'

type MoreActionsDropdownBaseProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    recordId: string
    fetchData: () => void
}

const InvitationsDropdown: FunctionComponent<React.PropsWithChildren<MoreActionsDropdownBaseProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    recordId,
    fetchData,
}) => {
    const { addError, addSuccess } = useNotifications()

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const handleResend = useCallback(() => {
        resendVMSInvitation(recordId)
            .then(() => {
                addSuccess('Invite was resent successfully')
            })
            .catch(error => {
                log(error)
                addError()
            })
            .finally(() => {
                toggleOpen()
                fetchData()
            })
    }, [addError, addSuccess, recordId, toggleOpen, fetchData])

    const handleCancel = useCallback(() => {
        cancelVMSInvitation(recordId)
            .then(() => {
                addSuccess('Invite was cancelled successfully')
            })
            .catch(error => {
                log(error)
                addError()
            })
            .finally(() => {
                fetchData()
            })
    }, [addError, addSuccess, fetchData, recordId])

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                justify-content: flex-end;
            `}
            ref={rangeRef}
        >
            <IconButton name='more' onClick={toggleOpen} />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        height: 90px;
                        width: 174px;
                        top: -90px;
                        background-color: ${COLOR_PALETTE.white};
                        border: 1px solid ${COLOR_PALETTE.gray_2};
                        border-radius: 2px;
                        ${mqMax[1]} {
                            width: 174px;
                            top: -5;
                            left: -10px;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-around;
                            flex-direction: column;
                            height: 90px;
                            width: 174px;
                        `}
                    >
                        <Button
                            style={css`
                                margin-left: 18px;
                            `}
                            onClick={handleResend}
                            variant='link'
                        >
                            Resend Invite
                        </Button>
                        <Button
                            style={css`
                                margin-left: 18px;
                            `}
                            variant='linkDelete'
                            onClick={handleCancel}
                        >
                            Cancel Invite
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

const InvitationsActionHandler = DropdownComponent(InvitationsDropdown)

export { InvitationsActionHandler }
