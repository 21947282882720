/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
    addSpecialistProfileToSavedProfiles,
    getShareMySpecialistsSyndicates,
    removeSpecialistProfileFromSavedProfiles,
} from '../../api/api'
import { BlueActionBox } from '../../components/BlueActionBox'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from '../../components/layout/Loader'
import { FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Pagination } from '../../components/Pagination'
import { ScrollToTop } from '../../components/ScrollUp'
import { SearchInput } from '../../components/SearchInput'
import { TalentResultBox, TalentResultBoxContainer } from '../../components/talent/TalentResultBox'
import { AvailableOn as AvailableOnType } from '../../contracts/search/availableOn'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { useUserContext } from '../../hooks/useUserContext'
import { useDebouncedEffect } from '../../utils/hooks'
import { defaultPageSize } from '../../utils/searchFilters'
import { useLogger } from '../../utils/useLogger'
import { HeaderWithActions } from '../my-specialists/shared/HeaderWithActions'
import { getSavedSpecialistsSearchFilters, getSpecialistsProfiles, getSpecialistsSearchFilters } from './api'
import { SearchResultsInfo } from './components/SearchResultsInfo'
import { ShowForCompany, SpecialistsSearchFilters, SpecialistsSearchRequest, SpecialistsSearchResponse } from './contracts'
import { FiltersBar } from './specialists-search/FiltersBar'
import { applicationName, useMenuItems } from './talentMarketplaceApp'
import {
    countSelectedFilters,
    parseQueryToSearchRequest,
    sanitizePossibleFilters,
    stringifySearchRequestToQuery,
} from './talentMarketplaceUtils'

type SearchProps = {
    onlySavedProfiles?: boolean
}

const MarketplaceSpecialistsSearchPage: FunctionComponent<React.PropsWithChildren<SearchProps>> = ({ onlySavedProfiles = false }) => {
    const { activeContext } = useUserContext()
    const navigate = useNavigate()
    const { search } = useLocation()
    const { addSuccess } = useNotifications()
    const menuItems = useMenuItems()
    const log = useLogger('error')
    const searchRequest = useMemo<SpecialistsSearchRequest>(
        () => parseQueryToSearchRequest(search, { savedProfile: onlySavedProfiles }),
        [search, onlySavedProfiles],
    )
    const searchFilters = useMemo<SpecialistsSearchFilters>(() => {
        const { searchText: s, paging: p, savedProfile, ...filtersOnly } = searchRequest
        return filtersOnly
    }, [searchRequest])
    const [searchText, setSearchText] = useState(searchRequest.searchText)
    const [initialSearchText] = useState(searchRequest.searchText)
    const [filters, setFilters] = useState<SpecialistsSearchFilters>(searchFilters)
    const [paging, setPaging] = useState(searchRequest.paging)

    const [filtersOpened, setFiltersOpened] = useState(true)

    const [possibleFilters, setPossibleFilters] = useState({})
    const [resultsPending, setResultsPending] = useState(false)
    const [results, setResults] = useState<SpecialistsSearchResponse>()
    const [hasCompanyAccount, setHasCompanyAccount] = useState<boolean>(true)
    const [syndicates, setSyndicates] = useState<Array<AvailableOnType>>([])

    const { addError } = useNotifications()

    const fetchSyndicates = useCallback(() => {
        getShareMySpecialistsSyndicates()
            .then((data: Array<AvailableOnType>) => {
                setSyndicates(data)
            })
            .catch(err => {
                addError()
                log(err)
            })
    }, [addError, log])

    useEffect(() => {
        fetchSyndicates()
    }, [fetchSyndicates])

    useEffect(() => {
        if (activeContext?.companyId) {
            setHasCompanyAccount(true)
        }
    }, [activeContext])

    useEffect(() => {
        const completeRequest = { searchText, paging, ...filters }
        const newSearchQuery = stringifySearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [searchText, filters, paging, navigate])

    useEffect(() => {
        const request = onlySavedProfiles ? getSavedSpecialistsSearchFilters : getSpecialistsSearchFilters
        request({ searchText: searchRequest.searchText })
            .then(filtersData => {
                setPossibleFilters(sanitizePossibleFilters(filtersData))
                if (initialSearchText !== searchRequest.searchText) {
                    setPaging(oldPaging => ({
                        page: 1,
                        size: oldPaging ? oldPaging.size : defaultPageSize,
                    }))
                }
            })
            .catch(log)
    }, [searchRequest.searchText, initialSearchText, log, onlySavedProfiles])

    useDebouncedEffect(
        () => {
            setResultsPending(true)
            getSpecialistsProfiles(searchRequest)
                .then(data => {
                    setResults(data)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    setResultsPending(false)
                })
        },
        300,
        [searchRequest],
    )

    const onChangeSearchText = useCallback((newSearchText: string) => {
        setSearchText(newSearchText)
    }, [])

    const onChangeFilters = useCallback(
        (newFilters: SpecialistsSearchFilters) => {
            setFilters(newFilters)
            setPaging({
                page: 1,
                size: paging ? paging.size : defaultPageSize,
            })
        },
        [paging],
    )

    const onClearFilters = useCallback(() => {
        setFilters({ showForCompany: ShowForCompany.ALL })
        setPaging({
            page: 1,
            size: paging ? paging.size : defaultPageSize,
        })
    }, [paging])

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(!filtersOpened)
    }, [filtersOpened])

    const onChangePageSize = useCallback(
        (size: Array<string>) => {
            setPaging({ page: paging ? paging.page : 1, size: Number(size.toString()) })
        },
        [paging],
    )

    useEffect(() => {
        if (results && results?.total > 0 && results?.specialists.length === 0 && paging && paging.page > 1) {
            setPaging({
                page: 1,
                size: paging ? paging.size : defaultPageSize,
            })
        }
    }, [results, searchFilters, onChangeFilters, paging])

    const onToggleSavedProfile = useCallback(
        (savedProfile: boolean, specialistId: string) => {
            if (savedProfile) {
                return addSpecialistProfileToSavedProfiles(specialistId).then(() => {
                    addSuccess('Specialist added to Saved Profiles successfully.')
                })
            } else {
                return removeSpecialistProfileFromSavedProfiles(specialistId).then(() => {
                    if (onlySavedProfiles) {
                        setResults(oldResults =>
                            oldResults
                                ? {
                                      matchingCount: oldResults.matchingCount - 1,
                                      total: oldResults.total - 1,
                                      specialists: oldResults.specialists.filter(
                                          specialist => specialist.specialist.specialistId !== specialistId,
                                      ),
                                  }
                                : undefined,
                        )
                    }
                    addSuccess('Specialist removed from Saved Profiles successfully.')
                })
            }
        },
        [addSuccess, onlySavedProfiles],
    )

    const onChangePage = useCallback((page: number, size: number) => {
        setPaging({
            page,
            size,
        })
    }, [])

    const goToCreateCompany = useCallback(() => {
        navigate('/dashboard/company-account/company-details')
    }, [navigate])

    const showResults = results && ((onlySavedProfiles === true && results.matchingCount) || onlySavedProfiles === false)

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems} backgroundColor={COLOR_PALETTE.gray_1}>
            <ScrollToTop action={paging} />

            <FullPageWrapper>
                <HeaderWithActions header={onlySavedProfiles ? 'Saved Profiles' : 'Find Talent'} isSearchPage />
                <SearchInput
                    initialText={searchText}
                    dataTestId='main-search'
                    onSubmit={onChangeSearchText}
                    filtersOpened={filtersOpened}
                    onToggleFilters={onToggleFilters}
                    placeholder='Skills, tools, frameworks etc.'
                    // withTypist TODO: remove placeholder and enable typist when BE improve search engine
                />
                <FiltersBar
                    possibleFiltersValues={possibleFilters}
                    onChangeFilters={onChangeFilters}
                    selectedFilters={searchFilters}
                    onClearFilters={onClearFilters}
                    filtersOpened={filtersOpened}
                    syndicates={syndicates}
                />
                <div
                    css={css`
                        position: relative;
                        margin: 0 auto 60px;
                        min-height: 400px;
                    `}
                >
                    {results && (
                        <Fragment>
                            {!hasCompanyAccount && (
                                <BlueActionBox
                                    text='Create Company Account to Book specialists'
                                    buttonText='Create company account'
                                    action={goToCreateCompany}
                                    styles={css`
                                        ${mqMax[2]} {
                                            margin: 16px;
                                        }
                                    `}
                                />
                            )}
                            <Divider />
                            <SearchResultsInfo
                                searchResults={results}
                                searchText={searchText}
                                filtersCount={countSelectedFilters(searchRequest)}
                                savedProfiles={onlySavedProfiles}
                            />
                            {showResults && (
                                <Fragment>
                                    <TalentResultBoxContainer>
                                        {results.specialists.map(specialistEntry => (
                                            <TalentResultBox
                                                key={specialistEntry.specialist.specialistId}
                                                talent={specialistEntry.specialist}
                                                searchWords={searchText ? searchText.split(' ').map(word => word.toLowerCase()) : []}
                                                onToggleSavedProfile={onToggleSavedProfile}
                                                onlySavedProfiles={onlySavedProfiles}
                                            />
                                        ))}
                                    </TalentResultBoxContainer>

                                    <div
                                        css={css`
                                            margin-top: 48px;
                                        `}
                                    >
                                        <Pagination
                                            total={results.total}
                                            pageSize={paging?.size}
                                            defaultPageSize={defaultPageSize}
                                            current={paging?.page || 1}
                                            onChange={onChangePage}
                                            onChangePageSize={onChangePageSize}
                                            css={css`
                                                ${mqMax[0]} {
                                                    display: flex;
                                                    justify-content: center;
                                                }
                                            `}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                    {resultsPending && <OverlayContentLoader />}
                </div>
            </FullPageWrapper>
        </DashboardLayout>
    )
}

export { MarketplaceSpecialistsSearchPage }
