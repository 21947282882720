/** @jsxImportSource @emotion/react */
import { useCallback } from 'react'
import { css } from '@emotion/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { yup } from '../../validation/yup'
import { Modal } from '../Modal'
import { ControlledInput } from '../forms/ControlledInput'
import { ControlledTextarea } from '../forms/ControlledTextarea'
import { Divider } from '../Divider'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { Button } from '../Button'
import { hasErrors } from '../../utils/errors'
import { Nullable } from '../../types'
import { UpdateApplicationBody, ApplicationStatusEnum } from '../../contracts/opportunities-manager/contracts'

interface ContractRateData {
    contractRate: number
    note?: string
}
const schema = yup.object().shape({
    contractRate: yup.number().required(),
    note: yup.string(),
})

interface OwnProps {
    closeModal: () => void
    contractRate: Nullable<number> | undefined
    currency?: string
    currentApplicationId: string
    currentStatus: ApplicationStatusEnum
    onSubmit: (applicationId: string, data: UpdateApplicationBody) => void
}

const ContractRateModal: React.FC<OwnProps> = ({ closeModal, contractRate, currency, currentApplicationId, currentStatus, onSubmit }) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, errors },
    } = useForm<ContractRateData>({
        // `contractRate` can be null but `defaultValues` expects either a value or `undefined`
        defaultValues: { contractRate: contractRate || undefined },
        resolver: yupResolver(schema),
        mode: 'onChange',
    })

    const submitCallback = useCallback(
        (data: ContractRateData) => {
            onSubmit(currentApplicationId, {
                status: { rejectionReasonComment: data.note, status: currentStatus },
                contractRate: data.contractRate,
            })
        },
        [currentApplicationId, currentStatus, onSubmit],
    )

    return (
        <Modal opened onClose={closeModal}>
            <form onSubmit={handleSubmit(submitCallback)}>
                <h5>Please provide the contract rate for this candidate</h5>
                <p>This contract rate will also be sent to the candidate's manager and visible in candidate details.</p>

                <ControlledInput
                    label='Contract Rate'
                    type='number'
                    name='contractRate'
                    control={control}
                    prefix={`${currency}/h`}
                    style={css`
                        margin-bottom: 24px;
                    `}
                />
                <ControlledTextarea label='Note (optional)' name='note' control={control} />

                <Divider />

                <FlexBox alignItems='center' justifyContent='flex-end'>
                    <Button variant='link' onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        type='submit'
                        style={css`
                            margin-left: 16px;
                        `}
                        disabled={hasErrors(errors) || !isDirty || isSubmitting}
                        dataTestId='submit-contract-rate'
                    >
                        Accept
                    </Button>
                </FlexBox>
            </form>
        </Modal>
    )
}

export { ContractRateModal }
