/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'

import { ControlledInput } from '../../../forms/ControlledInput'

import { FieldWrapper } from '../../../layout/FormHelpers'

import { Box } from '../../Box'
import { UpdateOpportunityFormData } from '../../../../contracts/opportunities-manager/contracts'

type ContactDetailsProps = {
    control: Control<UpdateOpportunityFormData>
}

const ContactDetails: FunctionComponent<React.PropsWithChildren<ContactDetailsProps>> = ({ control }) => {
    return (
        <Box>
            <h6>Contact Details for Vendor Inquiries</h6>

            <FieldWrapper>
                <ControlledInput control={control} name='contactDetails.contactEmail' label='Email' />
            </FieldWrapper>
        </Box>
    )
}

export { ContactDetails }
