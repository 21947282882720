import { Dropdown, Menu } from 'antd'
import { MenuInfo } from 'rc-menu/lib/interface'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { ExportType } from '../profile-export/types'
import { MenuItem, TitleEventEntity } from './types'
import { MenuOptionArrow } from './styles'
import { FlexBox } from '../layout/FlexBoxHelpers'

const preventDefault = (e: TitleEventEntity) => {
    e.domEvent.preventDefault()
    e.domEvent.stopPropagation()
}

const getMenuItems = (menuItems: Array<MenuItem>): any =>
    menuItems.map(item => ({
        label:
            'children' in item ? (
                <FlexBox alignItems='center'>
                    {item.title} <MenuOptionArrow name='arrow-right-filled' size={10} />
                </FlexBox>
            ) : (
                item.title
            ),
        key: item.key,
        'data-testid': item.dataTestId,
        children: item.children && getMenuItems(item.children),
        disabled: item.disabled,
        ...(item.children && { onTitleClick: preventDefault }),
    }))

type AntDropdownProps = {
    options: Array<MenuItem>
    trigger?: Array<'click' | 'hover' | 'contextMenu'>
    handleClick: (key: any) => void
    className?: string
}

const AntDropdown: FunctionComponent<React.PropsWithChildren<AntDropdownProps>> = ({
    options,
    handleClick,
    trigger = ['click'],
    children,
    className,
}) => {
    const handleDropdownClick = useCallback(
        (info: MenuInfo) => {
            info.domEvent.preventDefault()
            info.domEvent.stopPropagation()
            return handleClick(info.key as ExportType)
        },
        [handleClick],
    )

    const menu = useMemo(() => <Menu onClick={handleDropdownClick} items={getMenuItems(options)} />, [handleDropdownClick, options])

    return (
        <div className={className}>
            <Dropdown overlay={menu} arrow={false} trigger={trigger} getPopupContainer={node => node.parentElement as HTMLElement}>
                {children}
            </Dropdown>
        </div>
    )
}

export { AntDropdown }
