/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AccessLevelContext } from 'src/AccessLevelContext'
import { instantOnboardingLoadingStatus } from 'src/api/api'
import { IconButton } from 'src/components/Icon'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { InstantOnboardingLoadingStatus } from 'src/contracts/instant-onboarding/InstantOnboardingLoadingStatus'
import { SpecialistGeneralInformation } from 'src/contracts/specialist/specialistGeneralInformation'
import { Nullable } from 'src/types'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'
import { SectionHeaderWithActions } from '../../shared/HeaderWithActions'
import { SectionWrapper } from '../SectionHelpers'
import { ProfileTabProps } from '../tabTypes'
import { GeneralInformationForm } from './GeneralInformationForm'
import { GeneralInformationReadOnly } from './GeneralInformationReadOnly'

type GeneralInformationTabProps = ProfileTabProps & {
    companyName: string
    focusLocation: boolean
    setForceFocus: Dispatch<SetStateAction<string>>
    generalInformation: Nullable<SpecialistGeneralInformation>
    fetchGeneralInformation: () => void
}

const GeneralInformationTab: FunctionComponent<React.PropsWithChildren<GeneralInformationTabProps>> = ({
    specialistId,
    onSave,
    companyName,
    focusLocation,
    setForceFocus,
    generalInformation,
    fetchGeneralInformation,
}) => {
    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const [specialistData, setSpecialistData] = useState<Nullable<SpecialistGeneralInformation>>(generalInformation)
    const [retries, setRetries] = useState(0)
    const maxRetries = 10
    const [status, setStatus] = useState<InstantOnboardingLoadingStatus>(null)
    const [ioDataSyncFinished, setIoDataSyncFinished] = useState(false)

    const query = useQuery()
    const log = useLogger()
    const { addError } = useNotifications()

    const afterInstantOnboarding = useMemo(() => query.get('instantOnboarding') === 'finished', [query])

    const {
        mySpecialists: {
            generalInformation: { update: canUpdateGeneralInformation },
        },
    } = useContext(AccessLevelContext)
    const enableEditMode = useCallback(() => setEditModeEnabled(true), [setEditModeEnabled])
    const disableEditMode = useCallback(() => setEditModeEnabled(false), [setEditModeEnabled])

    useEffect(() => {
        if (afterInstantOnboarding && ioDataSyncFinished) {
            fetchGeneralInformation()
        }
    }, [afterInstantOnboarding, fetchGeneralInformation, ioDataSyncFinished])

    const handleSave = useCallback(
        (data: any) => {
            if (onSave) {
                onSave()
            }
            setSpecialistData(data)

            disableEditMode()
        },
        [onSave, disableEditMode],
    )

    const handleRetry = useCallback(() => {
        instantOnboardingLoadingStatus(specialistId)
            .then(data => {
                setStatus(data)

                if (retries > 0) {
                    setRetries(0)
                }

                if (data === 'specialist-profile-updated') {
                    setIoDataSyncFinished(true)
                }
            })
            .catch(error => {
                setRetries(retries + 1)
                log(error)
                if (error.status === 400) {
                    setIoDataSyncFinished(true)
                    addError()
                }
            })
    }, [addError, log, retries, specialistId])

    useEffect(() => {
        if (afterInstantOnboarding && status !== 'specialist-profile-updated' && retries <= maxRetries) {
            if (status === null) {
                handleRetry()
            } else {
                setTimeout(handleRetry, 2000)
            }
        }
    }, [afterInstantOnboarding, handleRetry, retries, status])

    useEffect(() => {
        if (focusLocation) {
            setEditModeEnabled(true)
        }
    }, [focusLocation])

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='General Job Details' divider>
                {canUpdateGeneralInformation && !editModeEnabled && (
                    <IconButton dataTestId='edit-general-job-details' name='edit' size={32} onClick={enableEditMode} />
                )}
            </SectionHeaderWithActions>
            <section
                css={css`
                    position: relative;
                `}
            >
                {specialistData === null ? (
                    <OverlayContentLoader />
                ) : (
                    <Fragment>
                        {editModeEnabled ? (
                            <GeneralInformationForm
                                onCancel={disableEditMode}
                                onSubmit={handleSave}
                                specialistData={specialistData}
                                specialistId={specialistId}
                                focusLocation={focusLocation}
                                setForceFocus={setForceFocus}
                            />
                        ) : (
                            <GeneralInformationReadOnly
                                specialistData={specialistData}
                                canUpdateGeneralInformation={canUpdateGeneralInformation}
                                companyName={companyName}
                                specialistId={specialistId}
                                setSpecialistData={setSpecialistData}
                                afterInstantOnboarding={afterInstantOnboarding}
                                ioDataSyncFinished={ioDataSyncFinished}
                            />
                        )}
                    </Fragment>
                )}
            </section>
        </SectionWrapper>
    )
}

export { GeneralInformationTab }
