/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useMemo } from 'react'
import { Country } from '../../contracts/country'
import { AutocompleteSelect } from './autocomplete-select/AutocompleteSelect'
import { Input, InputProps } from './Input'
import { FieldLabel } from './input-field/FieldLabel'

const isPlusCode = (phone: string, firstGap: number) => {
    return phone && phone[0] === '+' && firstGap >= 2 && firstGap <= 5
}

const isZeroCode = (phone: string, firstGap: number) => {
    return phone && phone.startsWith('00') && firstGap >= 3 && firstGap <= 6
}

export const extractPhoneParts = (newPhone: string, countryAreaCodes: Array<Country>) => {
    const parts = {
        areaCode: '',
        internalPhone: '',
    }
    const firstGap = newPhone.indexOf(' ')
    // like +1 up to +1939
    const isPlus = isPlusCode(newPhone, firstGap)
    const isZero = isZeroCode(newPhone, firstGap)

    if (isPlus || isZero) {
        const rawCode = newPhone.slice(0, firstGap)
        const newAreaCode = isZero ? rawCode.replace('00', '+') : rawCode
        const newInternalPhone = newPhone.slice(firstGap + 1, newPhone.length)
        const foundCountryArea = countryAreaCodes.find(a => a.phonePrefix === newAreaCode)
        if (foundCountryArea) {
            parts.areaCode = newAreaCode
            parts.internalPhone = newInternalPhone
        } else {
            parts.internalPhone = newPhone
        }
    } else {
        parts.internalPhone = newPhone
    }
    return parts
}

const mergePhoneParts = (areaCode: string, internalPhone: string) => {
    return areaCode ? areaCode + ' ' + internalPhone : internalPhone
}

export type PhoneInputProps = InputProps & {
    countryAreaCodes?: Array<Country>
    firstOptionEmpty?: boolean
    canClear?: boolean
}

const FullPhoneInput: FunctionComponent<React.PropsWithChildren<PhoneInputProps>> = ({
    name,
    label,
    labelTooltip,
    value,
    onChange,
    countryAreaCodes = [],
    errorMessage,
    firstOptionEmpty = false,
    canClear = false,
    ...rest
}) => {
    const selectOptions = useMemo(
        () => (firstOptionEmpty ? [{ name: 'Do not set', phonePrefix: '' }].concat(countryAreaCodes) : countryAreaCodes),
        [firstOptionEmpty, countryAreaCodes],
    )

    const handleChange = useCallback(
        (phone: string) => {
            if (onChange) {
                onChange(phone)
            }
        },
        [onChange],
    )

    const parts = useMemo(() => extractPhoneParts(value || '', countryAreaCodes), [value, countryAreaCodes])

    const countryArea = useMemo(() => countryAreaCodes.find(a => a.phonePrefix === parts.areaCode), [parts.areaCode, countryAreaCodes])

    // internal phone parts handlers
    const handleAreaCodeChange = useCallback(
        (options: Array<Country>) => {
            let phonePrefix = ''

            if (options.length > 0) {
                phonePrefix = options[0].phonePrefix
            }

            handleChange(mergePhoneParts(phonePrefix, parts.internalPhone))
        },
        [handleChange, parts.internalPhone],
    )
    const handleInternalPhoneChange = useCallback(
        (newPhone: string) => {
            const newParts = extractPhoneParts(newPhone, countryAreaCodes)
            if (newParts.areaCode) {
                handleChange(mergePhoneParts(newParts.areaCode, newParts.internalPhone))
            } else {
                handleChange(mergePhoneParts(parts.areaCode, newParts.internalPhone))
            }
        },
        [countryAreaCodes, handleChange, parts.areaCode],
    )

    let areaCodeErrors: any = false
    if (errorMessage && !parts.areaCode) {
        areaCodeErrors = 'Select area'
    }
    let internalPhoneError = ''
    if (errorMessage) {
        internalPhoneError = errorMessage // default error from validation
    }

    return (
        <Fragment>
            <FieldLabel label={label} labelTooltip={labelTooltip} />
            <div
                css={css`
                    display: flex;
                `}
            >
                <div
                    css={css`
                        flex-shrink: 0;
                        width: 130px;
                    `}
                >
                    <AutocompleteSelect
                        placeholder='Area'
                        options={selectOptions}
                        currentValues={countryArea ? [countryArea] : []}
                        selectedLabelTransformer={(opt: Country) => `${opt.phonePrefix}`}
                        labelTransformer={(opt: Country) => `${opt.phonePrefix} ${opt.name}`}
                        onSelectedValuesChange={handleAreaCodeChange}
                        errorMessage={areaCodeErrors}
                        dataTestId={rest.dataTestId}
                        selectWidth='120px'
                        canClear={canClear}
                    />
                </div>
                <div
                    css={css`
                        padding-left: 10px;
                        flex-grow: 1;
                    `}
                >
                    <Input
                        name='internalPhone'
                        onChange={handleInternalPhoneChange}
                        value={parts.internalPhone}
                        errorMessage={internalPhoneError}
                        {...rest}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export { FullPhoneInput }
