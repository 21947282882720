import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { mqMax } from 'src/GlobalStyle'
import { Icon } from 'src/components/Icon'

const EarlyAccessBadgeWrapper = styled.div`
    background-color: ${props => props.theme.colors.blue_3};
    border-radius: 20px;
    padding: 7px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    ${mqMax[1]} {
        margin-bottom: 12px;
    }
`

const Label = styled.div`
    margin-left: 8px;
`

const EarlyAccessBadge = () => {
    return (
        <Tooltip
            placement='top'
            title='This opportunity is available for Premium users only during the early access period (early access usually lasts for 48 hours)'
        >
            <EarlyAccessBadgeWrapper>
                <Icon name='time' size={16} />
                <Label>Early access</Label>
            </EarlyAccessBadgeWrapper>
        </Tooltip>
    )
}

export { EarlyAccessBadge }
