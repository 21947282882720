/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useMemo, useState } from 'react'
import useCollapse from 'react-collapsed'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Icon, IconButton } from '../../../components/Icon'
import { AutocompleteSelect } from '../../../components/inputs/autocomplete-select/AutocompleteSelect'
import { Input } from '../../../components/inputs/Input'

type InstantOnboardingAdditionalRolesProps = {
    options: Array<string>
    disabled?: boolean
    handleChange: (value: string) => void
}

const CUSTOM_ROLE_TRIGGER = 'Other'

const InstantOnboardingAdditionalRoles: FunctionComponent<React.PropsWithChildren<InstantOnboardingAdditionalRolesProps>> = ({
    options,
    disabled,
    handleChange,
}) => {
    const theme = useTheme()
    const [value, setValue] = useState('')
    const [customValue, setCustomValue] = useState('')
    const [isExpanded, setIsExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

    const toggle = useCallback(() => {
        setIsExpanded(prevExpanded => !prevExpanded)
        setValue('')
        setCustomValue('')
    }, [])

    const handleAdd = useCallback(() => {
        handleChange(value === CUSTOM_ROLE_TRIGGER ? customValue : value)
        setIsExpanded(false)
        setCustomValue('')
        setValue('')
    }, [handleChange, value, customValue])

    const handleValueChange = useCallback((event: any) => {
        setValue(event[0])
    }, [])

    const handleCustomRoleChange = useCallback((event: any) => {
        setCustomValue(event)
    }, [])

    const roles = useMemo(() => [...options, CUSTOM_ROLE_TRIGGER], [options])

    return (
        <Fragment>
            <Divider />
            <div
                {...getToggleProps({
                    onClick: toggle,
                })}
                data-testid='instant-onboarding-additional-roles-toggle-button'
            >
                <div
                    css={css`
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 17px 0 30px;
                        color: ${disabled ? theme.colors.gray_4 : theme.colors.violet_5};
                    `}
                >
                    Add an additional role
                    <Icon
                        name='arrow-down'
                        css={css`
                            color: ${theme.colors.gray_6};
                            transition: transform 0.25s;
                            margin-left: 8px;
                            ${isExpanded && 'transform: rotate(180deg)'}
                        `}
                    />
                </div>
            </div>

            <div {...getCollapseProps()}>
                <p>You can have up to 5 roles on the list above</p>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        margin-bottom: 16px;
                    `}
                >
                    <AutocompleteSelect
                        placeholder='Choose role'
                        canFilter={true}
                        options={roles}
                        disabled={disabled}
                        currentValues={[value]}
                        onSelectedValuesChange={handleValueChange}
                        dataTestId='role-select'
                    />

                    <div
                        css={css`
                            margin-left: 8px;
                        `}
                    >
                        <IconButton
                            name='close'
                            size={30}
                            onClick={toggle}
                            style={css`
                                width: 25px;
                                height: 25px;
                            `}
                        />
                    </div>
                </div>

                {value === CUSTOM_ROLE_TRIGGER && (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            margin-bottom: 16px;
                        `}
                    >
                        <Input
                            name='role'
                            onChange={handleCustomRoleChange}
                            style={css`
                                width: 100%;
                            `}
                            dataTestId='custom-role-input'
                        />

                        <div
                            css={css`
                                margin-left: 8px;
                            `}
                        >
                            <IconButton
                                name='close'
                                size={30}
                                disabled={true}
                                onClick={toggle}
                                style={css`
                                    opacity: 0;
                                    width: 25px;
                                    height: 25px;
                                `}
                            />
                        </div>
                    </div>
                )}

                <Button variant='secondary' onClick={handleAdd} disabled={value === '' && customValue === ''}>
                    Add to list
                </Button>
            </div>
            <Divider />
        </Fragment>
    )
}

export { InstantOnboardingAdditionalRoles }
