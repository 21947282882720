/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useEffect } from 'react'
import { IconButton } from '../../../components/Icon'
import { FieldLabel } from '../../../components/inputs/input-field/FieldLabel'
import { CustomField } from '../../../contracts/customFields'
import { mqMax } from '../../../GlobalStyle'
import { NullableArray } from '../../../types'

type CustomFieldInfoProps = FunctionComponent<
    React.PropsWithChildren<{
        setEditMode: Dispatch<SetStateAction<boolean>>
        setMyCustomFields: Dispatch<SetStateAction<NullableArray<CustomField>>>
        setIdToRemove: Dispatch<SetStateAction<string | undefined>>
        customField: CustomField
    }>
>

const CustomFieldInfo: CustomFieldInfoProps = ({ setEditMode, setIdToRemove, customField, setMyCustomFields }) => {
    const handleEditClick = useCallback(() => {
        setEditMode(true)
    }, [setEditMode])

    const handleRemoveClick = useCallback(() => {
        setIdToRemove(customField.id)
    }, [setIdToRemove, customField.id])

    useEffect(() => {
        if (!customField.id) {
            setMyCustomFields(fields => fields?.filter(field => !!field.id) || fields)
        }
    }, [customField.id, setMyCustomFields])

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
            `}
            data-testid='custom-field-info'
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    flex: 1;

                    ${mqMax[0]} {
                        flex-wrap: wrap;
                    }
                `}
            >
                <FieldLabel
                    label='Field Name'
                    styles={css`
                        margin-right: 30px;
                        margin-bottom: 0;
                        ${mqMax[0]} {
                            width: 100%;
                            margin-right: 0;
                        }
                    `}
                />

                <div
                    css={css`
                        flex: 1;
                        margin-bottom: 0;
                    `}
                >
                    {customField.fieldName}
                </div>
            </div>
            <IconButton name='edit' onClick={handleEditClick} dataTestId='custom-field-info-edit' />
            <IconButton name='trash' onClick={handleRemoveClick} dataTestId='custom-field-info-remove' />
        </div>
    )
}

export { CustomFieldInfo }
