/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { BlueActionBox } from '../../components/BlueActionBox'
import { Nullable } from '../../types'
import { Box } from '../../components/opportunities-manager/Box'
import { ApplicationSkill } from '../../contracts/opportunities-manager/contracts'

import { SkillRateItem } from './SkillRateItem'
import { ApplicationData } from './ApplicationWizard/redux/types'

export type RateSkillsFormData = {
    skills: Array<ApplicationSkill>
}

const useRateSkillsForm = (application: Nullable<ApplicationData>) => {
    const theme = useTheme()
    const {
        control,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<RateSkillsFormData>({
        defaultValues: {
            skills: application?.skills,
        },
    })

    useEffect(() => {
        reset({
            skills: application?.skills,
        })
    }, [application, reset])

    const RateSkillsForm = useMemo(
        () => (
            <Box>
                <h6>Rate Required Skills (optional step)</h6>
                <p
                    css={css`
                        color: ${theme.colors.gray_4};
                    `}
                >
                    Applicants with rated skills are usually processed faster.
                </p>
                <BlueActionBox
                    text={`Skill ratings will be updated on this specialist's profile.`}
                    styles={css`
                        background-color: ${theme.colors.beige_2};
                        margin-bottom: 16px;
                        border-radius: 4px;
                        padding: 8px;
                    `}
                />
                {(application?.skills || []).map((skill, index) => (
                    <SkillRateItem control={control} name={`skills.[${index}].rate`} skill={skill} key={skill.name} />
                ))}
            </Box>
        ),
        [application?.skills, control, theme],
    )

    return { RateSkillsForm, getValues, isDirty }
}

export { useRateSkillsForm }
