/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { FlexBox } from './layout/FlexBoxHelpers'
import { ProgressBar } from './ProgressBar'

type ProgressBarTimerProps = {
    time: number
    handleTimeExpiration?: () => void
    initialTimeLeft?: number
}

const formatTime = (time: number) =>
    time > 0
        ? `${Math.floor(time / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${(time % 60).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
          })}`
        : 'Time Expired'

const ProgressBarTimer: FunctionComponent<React.PropsWithChildren<ProgressBarTimerProps>> = ({
    time,
    initialTimeLeft = time,
    handleTimeExpiration,
}) => {
    const step = useMemo(() => Number((100 / time).toFixed(2)), [time])
    const initialProgress = useMemo(() => 100 - Number((initialTimeLeft * step).toFixed(2)), [initialTimeLeft, step])
    const [currentProgress, setCurrentProgress] = useState(initialProgress)
    const [timeLeft, setTimeLeft] = useState(initialTimeLeft)

    useEffect(() => {
        setCurrentProgress(initialProgress)
    }, [initialProgress])

    useEffect(() => {
        if (timeLeft === 0 && handleTimeExpiration) {
            handleTimeExpiration()
        }

        const updateBar = setTimeout(() => setCurrentProgress(100), 0)

        const updateTime = timeLeft
            ? setTimeout(() => setTimeLeft(prevTimeLeft => (prevTimeLeft === 0 ? 0 : prevTimeLeft - 1)), 1000)
            : null

        return () => {
            if (updateTime) {
                clearTimeout(updateTime)
            }
            clearTimeout(updateBar)
        }
    }, [handleTimeExpiration, step, timeLeft])

    return (
        <FlexBox alignItems='center'>
            <ProgressBar
                currentProgress={currentProgress}
                styles={css`
                    transition: transform ${initialTimeLeft}s linear;
                `}
            />
            <div
                css={css`
                    min-width: 105px;
                    margin-bottom: 14px;
                    padding-left: 16px;
                    text-align: right;
                    font-weight: 500;
                `}
            >
                {formatTime(timeLeft)}
            </div>
        </FlexBox>
    )
}

export { ProgressBarTimer }
