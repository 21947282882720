import { StringMapping } from '../types'
import { VerifiedSkill } from './profile/skill'

export enum SkillProficiency {
    EXPERT = 'EXPERT',
    GOOD = 'GOOD',
    BASIC = 'BASIC',
    NOT_RATED = 'NOT_RATED',
}

export const SkillProficiencyMapping: StringMapping = {
    [SkillProficiency.BASIC]: 'Basic',
    [SkillProficiency.GOOD]: 'Good',
    [SkillProficiency.EXPERT]: 'Expert',
    [SkillProficiency.NOT_RATED]: 'Not rated',
}

export enum SkillStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    DELETED = 'DELETED',
    NOT_VALID = 'NOT_VALID',
}

export interface Skill {
    id: string
    name: string
    proficiency: SkillProficiency
    status?: SkillStatus
}

export interface SkillsCategory {
    category: string
    skills: Array<Skill>
}

export interface SpecialistSkills {
    categories: Array<SkillsCategory>
    cvParsingId?: string
}

export interface SkillUpdateBody {
    proficiency: SkillProficiency
    id: string
}

export interface FullExportSkills {
    mergedCategories: Array<{
        basic: Array<VerifiedSkill>
        expert: Array<VerifiedSkill>
        good: Array<VerifiedSkill>
        name: string
        notRated: Array<VerifiedSkill>
    }>
}
