import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { COLOR_PALETTE, mqMax } from 'src/GlobalStyle'
import { Nullable } from 'src/types'
import { countArrayFilters } from 'src/utils/filters'
import { ShareMySpecialistsFilters } from './contracts'
import { SharedType } from 'src/contracts/specialist/specialist'

const countSelectedFilters = (filters: ShareMySpecialistsFilters): number => {
    let counter = 0
    const { rateFrom, rateTo, availableFrom } = filters

    counter += countArrayFilters(filters, ['roles', 'seniorities', 'availableOn', 'shared', 'currencies'])

    if (rateFrom) {
        counter++
    }
    if (rateTo) {
        counter++
    }
    if (availableFrom) {
        counter++
    }

    return counter
}

const InputWrapper = styled.div`
    min-height: 60px;
    margin-top: 14px;
    ${mqMax[2]} {
        margin: 0;
    }
`

const CellWrapper = styled.div`
    padding: 26px 6px;
    ${mqMax[2]} {
        padding: 0;
    }
`

const YellowDot = styled.div`
    background-color: ${COLOR_PALETTE.yellow_5};
    height: 8px;
    width: 8px;
    margin-left: 8px;
    border-radius: 50%;
`

const getSharedStatusStyles = (sharedStatus: Nullable<SharedType>) => {
    const styleBase = css`
        min-width: 130px;
        padding: 6px;
        ${mqMax[2]} {
            min-width: none;
        }
    `

    switch (sharedStatus) {
        case 'SHARED':
            return css`
                ${styleBase}
                font-weight: 500;
                color: ${COLOR_PALETTE.green_6};
            `
        case 'NOT_SHARED':
            return css`
                ${styleBase}
                color: ${COLOR_PALETTE.violet_4};
            `
        case 'BOOKING_REQUEST_ACCEPTED':
            return css`
                ${styleBase}
                font-weight: 500;
                color: ${COLOR_PALETTE.blue_5};
            `

        case 'BOOKING_REQUEST_RECEIVED':
            return css`
                ${styleBase}
                color: ${COLOR_PALETTE.beige_5};
            `
        default:
            break
    }
}

const getRowBackgroundColor = (sharedStatus: Nullable<SharedType>) => {
    switch (sharedStatus) {
        case 'BOOKING_REQUEST_RECEIVED':
            return 'ant-table-row-yellow'
        default:
            return ''
    }
}

export { countSelectedFilters, InputWrapper, CellWrapper, getSharedStatusStyles, getRowBackgroundColor, YellowDot }
