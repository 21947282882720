import styled from '@emotion/styled'
import { DataNotFound } from 'src/components/DataNotFound'
import { SearchInput } from 'src/components/SearchInput'
import { mqMax } from 'src/GlobalStyle'

const FiltersWrapper = styled.div`
    max-width: 1440px;
    margin: 0 auto;
    ${mqMax[1]} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray_2};
    }
`
const H4 = styled.h4`
    margin-bottom: 16px;
`
const PaginationWrapper = styled.div`
    margin: 40px 0 120px 0;
`

const StyledSearchInput = styled(SearchInput)`
    margin-bottom: 16px;
`

const StyledDataNotFound = styled(DataNotFound, { shouldForwardProp: name => name !== 'searchOrFilters' })<{ searchOrFilters: boolean }>`
    margin-top: ${({ searchOrFilters }) => (searchOrFilters ? '50px' : '150px')};
`

export { FiltersWrapper, H4, PaginationWrapper, StyledSearchInput, StyledDataNotFound }
