/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useClickAway } from 'react-use'
import { InputField, InputFieldProps } from '../inputs/input-field/InputField'

const modules = {
    toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
    ],
}

export type TextEditorProps = InputFieldProps & {
    name: string
    onChange?: (value: string) => void
    value?: string
    placeholder?: string
    style?: SerializedStyles
    dataTestId?: string
    disabled?: boolean
    height?: number
}
const TextEditor: FunctionComponent<React.PropsWithChildren<TextEditorProps>> = ({
    name,
    onChange,
    value,
    placeholder,
    style,
    dataTestId,
    height = 300,
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    disabled,
}) => {
    const [focused, setFocused] = useState(false)

    const theme = useTheme()
    const ref = useRef(null)

    useClickAway(ref, () => {
        setFocused(false)
    })

    const handleFocus = useCallback((e: any) => {
        setFocused(true)
    }, [])

    return (
        <div
            css={css`
                min-height: ${height + 100}px;
            `}
        >
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
                dataTestId={dataTestId || name}
            >
                <div
                    css={css`
                        .quill {
                            border: 1px solid ${errorMessage ? theme.colors.red_4 : focused ? theme.colors.gray_3 : theme.colors.gray_2};
                            border-radius: 2px;
                        }
                        .ql-toolbar {
                            border: none;
                            border-bottom: 1px solid ${theme.colors.gray_2};
                        }

                        .ql-snow {
                            font-family: 'Jost';
                        }
                        strong {
                            font-weight: bold;
                        }
                        .ql-editor {
                            height: ${height}px;
                            font-size: 14px;
                        }

                        .ql-container.ql-snow {
                            border: none;
                        }

                        .ql-editor.ql-blank::before {
                            font-style: normal;
                            color: ${theme.colors.gray_3};
                            font-size: 14px;
                        }

                        ${style}
                    `}
                    ref={ref}
                    data-testid={dataTestId || name}
                >
                    <ReactQuill
                        placeholder={placeholder}
                        modules={modules}
                        theme='snow'
                        value={value}
                        onChange={onChange}
                        onFocus={handleFocus}
                        readOnly={disabled}
                    />
                </div>
            </InputField>
        </div>
    )
}

export { TextEditor }
