import { GameMatrix } from './contracts'

const isMatrixCorrectlySolved = (matrix: GameMatrix): boolean => {
    let clickableBoxesCount = 0
    let correctSelectionsCount = 0
    matrix?.forEach(row => {
        clickableBoxesCount = clickableBoxesCount + row.filter(box => box.isClickable).length
        correctSelectionsCount = correctSelectionsCount + row.filter(box => box.isClickable && box.isSelected).length
    })

    return correctSelectionsCount === clickableBoxesCount
}

export { isMatrixCorrectlySolved }
