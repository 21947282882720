/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import moment, { Moment } from 'moment'
import { Fragment, FunctionComponent, useCallback, useEffect } from 'react'
import { Control, UseFormClearErrors, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { BlueActionBox } from 'src/components/BlueActionBox'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ControlledRadioGroup } from 'src/components/forms/ControlledRadioGroup'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { useCurrencies } from 'src/redux/dictionaryDataHooks'
import { Box } from '../../Box'
import { OpportunityOfferTypeEnum, UpdateOpportunityFormData } from '../../../../contracts/opportunities-manager/contracts'
import { naturalNumbersRegex, rationalNumbersRegex } from 'src/utils/regexes'

type OpportunityDetailsProps = {
    control: Control<UpdateOpportunityFormData>
    watch: UseFormWatch<UpdateOpportunityFormData>
    setValue: UseFormSetValue<UpdateOpportunityFormData>
    getValues: UseFormGetValues<UpdateOpportunityFormData>
    clearErrors: UseFormClearErrors<UpdateOpportunityFormData>
    isVMCCloudApp: boolean
}

const OpportunityDetails: FunctionComponent<React.PropsWithChildren<OpportunityDetailsProps>> = ({
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    isVMCCloudApp,
}) => {
    const theme = useTheme()
    const currencies = useCurrencies()

    const disabledEndDate = useCallback(
        (date: Moment): boolean => {
            const startDate = getValues('opportunityDetails.startDate')
            return startDate !== ''
                ? moment(date).isBefore(moment().startOf('day')) || moment(date).isSameOrBefore(moment(startDate * 1000))
                : moment(date).isBefore(moment().startOf('day'))
        },
        [getValues],
    )
    const disabledStartDate = useCallback(
        (date: Moment): boolean => {
            const endDate = getValues('opportunityDetails.endDate')
            return endDate !== ''
                ? moment(date).isBefore(moment().startOf('day')) || moment(date).isSameOrAfter(moment(endDate * 1000))
                : moment(date).isBefore(moment().startOf('day'))
        },
        [getValues],
    )
    const [currency, minimumRate, maximumRate] = watch([
        'opportunityDetails.currency',
        'opportunityDetails.minimumRate',
        'opportunityDetails.maximumRate',
    ])

    useEffect(() => {
        if (!currency) {
            setValue('opportunityDetails.maximumRate', '')
            setValue('opportunityDetails.minimumRate', '')
            clearErrors(['opportunityDetails.maximumRate', 'opportunityDetails.minimumRate'])
        }
    }, [clearErrors, currency, setValue])

    useEffect(() => {
        if (minimumRate <= maximumRate) {
            clearErrors(['opportunityDetails.maximumRate', 'opportunityDetails.minimumRate'])
        }
    }, [clearErrors, maximumRate, minimumRate])

    return (
        <Box>
            <h6>{isVMCCloudApp ? 'Vacancy Details' : 'Opportunity Details'}</h6>
            <FlexBox>
                <FieldWrapper
                    css={css`
                        padding-right: 8px;
                    `}
                >
                    <ControlledDatePickerInput
                        control={control}
                        name='opportunityDetails.startDate'
                        label='Start Date'
                        placeholder='Add date'
                        disabledDate={disabledStartDate}
                    />
                </FieldWrapper>
                <FieldWrapper
                    css={css`
                        padding-left: 8px;
                    `}
                >
                    <ControlledDatePickerInput
                        control={control}
                        name='opportunityDetails.endDate'
                        label='End Date'
                        placeholder='Add date'
                        disabledDate={disabledEndDate}
                    />
                </FieldWrapper>
            </FlexBox>
            <FieldWrapper>
                <ControlledRadioGroup
                    control={control}
                    name='opportunityDetails.offerType'
                    label='Offer type'
                    options={[
                        {
                            label: 'Individual',
                            value: OpportunityOfferTypeEnum.INDIVIDUAL,
                        },
                        {
                            label: 'Team',
                            value: OpportunityOfferTypeEnum.TEAM,
                        },
                    ]}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput
                    control={control}
                    name='opportunityDetails.numberOfVacancies'
                    label='No. of vacancies'
                    placeholder='Number of vacancies'
                    regex={naturalNumbersRegex}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    options={currencies}
                    control={control}
                    name='opportunityDetails.currency'
                    label='Currency'
                    placeholder='Choose currency'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            {currency && (
                <Fragment>
                    <FlexBox>
                        <FieldWrapper
                            css={css`
                                padding-right: 8px;
                            `}
                        >
                            <ControlledInput
                                control={control}
                                name='opportunityDetails.minimumRate'
                                label='Minimum Rate (optional)'
                                placeholder='Add rate'
                                regex={rationalNumbersRegex}
                            />
                        </FieldWrapper>
                        <FieldWrapper
                            css={css`
                                padding-left: 8px;
                            `}
                        >
                            <ControlledInput
                                control={control}
                                name='opportunityDetails.maximumRate'
                                label='Maximum Rate (optional)'
                                placeholder='Add rate'
                                regex={rationalNumbersRegex}
                            />
                        </FieldWrapper>
                    </FlexBox>
                    <BlueActionBox
                        text='Please note that estimated deal size will be calculated automatically if all fields in this section are filled in.'
                        styles={css`
                            background-color: ${theme.colors.beige_2};
                            border-radius: 4px;
                            padding: 8px;
                        `}
                    />
                </Fragment>
            )}
        </Box>
    )
}

export { OpportunityDetails }
