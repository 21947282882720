/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useCallback, useContext, useState } from 'react'
import useCollapse from 'react-collapsed'
import { Link, useParams } from 'react-router-dom'

import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { Icon, IconButton } from 'src/components/Icon'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { ContentLoader, OverlayContentLoader, Spinner } from 'src/components/layout/Loader'
import { SpecialistCv, SpecialistCvStatus, SpecialistParsedCv } from 'src/contracts/cvs'
import { CvData } from 'src/contracts/specialist/specialistCv'
import { COLOR_PALETTE } from 'src//GlobalStyle'
import { ReduxContext } from 'src/redux/Store'
import { Nullable, NullableArray } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { Specialist } from 'src/contracts/specialist/specialist'

import { DeleteConfirmationModal } from '../DeleteConfirmationModal'
import { DownloadAnonymizedCV, AnonymizedCVUploadDate } from './styles'
import { useAnonymizedCV } from './utils'

type UploadCvProps = {
    handleCvUpload: () => void
    handleDeleteCv: () => void
    cvFile: Nullable<CvData>
    styles?: SerializedStyles
    cvParsingStatus: NullableArray<SpecialistCv>
    specialistParsedCvs: Array<SpecialistParsedCv>
    isLoading: boolean
    specialist: Specialist
    anonymousNickname?: string
}

const Wrapper: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px;
                background: ${theme.colors.beige_1};
                border-radius: 4px;
                margin: 16px 0;
                width: 100%;
                position: relative;
            `}
        >
            {children}
        </div>
    )
}

type CvsAddedBySpecialistProps = {
    specialistParsedCvs: Array<SpecialistParsedCv>
}

const CvsAddedBySpecialist: FunctionComponent<React.PropsWithChildren<CvsAddedBySpecialistProps>> = ({ specialistParsedCvs }) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])

    return (
        <Wrapper>
            <FlexBox
                {...getToggleProps({
                    css: css`
                        margin-bottom: 8px;
                        margin: 0;
                        width: 100%;
                        cursor: pointer;
                    `,
                    onClick: toggle,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                })}
            >
                Files added by Specialist
                <Icon name='arrow-down' />
            </FlexBox>
            <div {...getCollapseProps()}>
                <div
                    css={css`
                        font-size: 12px;
                        color: ${theme.colors.gray_3};
                        margin-top: 8px;
                    `}
                >
                    Visible internally
                </div>
                <Divider
                    style={css`
                        margin-bottom: 0;
                    `}
                />
                {specialistParsedCvs.map(parsedCv => (
                    <Fragment key={parsedCv.cvDownloadUrl}>
                        <a href={parsedCv.cvDownloadUrl} download target='_blank' rel='noopener noreferrer'>
                            <Tooltip title={parsedCv.fileName}>
                                <div
                                    css={css`
                                        font-weight: 500;
                                        color: ${theme.colors.violet_5};
                                    `}
                                >
                                    Preview file
                                </div>
                            </Tooltip>
                        </a>
                        <div
                            css={css`
                                font-size: 12px;
                                color: ${theme.colors.gray_3};
                            `}
                        >
                            Uploaded: {convertTimestampToDateString(parsedCv.uploadedOn)}
                        </div>
                    </Fragment>
                ))}
            </div>
        </Wrapper>
    )
}

const UploadCv: FunctionComponent<React.PropsWithChildren<UploadCvProps>> = ({
    handleCvUpload,
    handleDeleteCv,
    cvFile,
    styles,
    isLoading,
    cvParsingStatus,
    specialistParsedCvs,
    specialist,
    anonymousNickname,
}) => {
    const {
        selectors: {
            featureFlags: { managerAiCvParsing },
        },
    } = useContext(ReduxContext)
    const theme = useTheme()

    const { specialistId } = useParams()

    const [isModalVisible, setModalVisible] = useState<boolean>(false)

    const showModal = useCallback(() => setModalVisible(true), [])

    const handleRejection = useCallback(() => setModalVisible(false), [])

    const handleConfirmation = useCallback(() => {
        handleDeleteCv()
        setModalVisible(false)
    }, [handleDeleteCv])

    const { hasBeenUploaded, openAnonymizedCV, uploadDate } = useAnonymizedCV(specialist, anonymousNickname)

    return (
        <Fragment>
            {managerAiCvParsing ? (
                <Fragment>
                    <Wrapper>
                        {isLoading && <OverlayContentLoader />}
                        <h6
                            css={css`
                                margin-bottom: 8px;
                            `}
                        >
                            Upload CV
                        </h6>
                        <div
                            css={css`
                                font-size: 12px;
                                color: ${theme.colors.gray_3};
                            `}
                        >
                            Your uploaded CV will be anonymized by the Talent Alpha team and will be added to the shared specialist's
                            profile.
                        </div>
                        <Divider
                            style={css`
                                margin-bottom: 0;
                            `}
                        />
                        {cvParsingStatus && cvParsingStatus[0].cvDownloadUrl ? (
                            <Fragment>
                                <FlexBox
                                    justifyContent='space-between'
                                    alignItems='center'
                                    css={css`
                                        width: 100%;
                                    `}
                                >
                                    <a href={cvParsingStatus[0].cvDownloadUrl || ''} download target='_blank' rel='noopener noreferrer'>
                                        <div
                                            css={css`
                                                font-weight: 500;
                                                color: ${theme.colors.violet_5};
                                            `}
                                        >
                                            Preview CV
                                        </div>
                                    </a>
                                    {cvParsingStatus && cvParsingStatus[0].status !== SpecialistCvStatus.IN_PROGRESS && (
                                        <FlexBox alignItems='center'>
                                            <Button
                                                variant='text'
                                                css={css`
                                                    padding: 0;
                                                    margin-right: 8px;
                                                    color: ${theme.colors.violet_5};
                                                `}
                                                onClick={handleCvUpload}
                                            >
                                                Replace
                                            </Button>
                                            <IconButton
                                                name='trash'
                                                innerSize={20}
                                                size={30}
                                                onClick={showModal}
                                                dataTestId='remove-cv-icon'
                                            />
                                        </FlexBox>
                                    )}
                                </FlexBox>

                                {cvParsingStatus && cvParsingStatus[0].status === SpecialistCvStatus.IN_PROGRESS ? (
                                    <FlexBox
                                        css={css`
                                            color: ${theme.colors.blue_5};
                                            width: 100%;
                                        `}
                                        alignItems='center'
                                        justifyContent='space-between'
                                    >
                                        parsing in progress...
                                        <div
                                            css={css`
                                                position: relative;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 20px;
                                                height: 20px;
                                            `}
                                        >
                                            <Spinner circleWidth={2} sizeInner={12} sizeOuter={16} dataTestId='processing-cv-spinner' />
                                        </div>
                                    </FlexBox>
                                ) : (
                                    <Fragment>
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                color: ${theme.colors.gray_3};
                                            `}
                                        >
                                            Uploaded: {convertTimestampToDateString(cvParsingStatus[0].uploadedOn || 0)}
                                        </div>
                                        {cvParsingStatus[0].status === SpecialistCvStatus.READY_FOR_VERIFICATION && (
                                            <Link
                                                css={css`
                                                    width: 100%;
                                                `}
                                                to={`/my-specialists/specialist/${specialistId}/finish-extracting/${cvParsingStatus[0].cvParsingId}`}
                                            >
                                                <FlexBox
                                                    alignItems='center'
                                                    justifyContent='space-between'
                                                    css={css`
                                                        position: relative;
                                                        padding: 10px;
                                                        width: 100%;
                                                        margin-top: 4px;
                                                        background-color: ${theme.colors.white};
                                                        border-radius: 4px;
                                                        box-shadow: ${theme.shadow.small};
                                                        cursor: pointer;
                                                    `}
                                                    data-testid='finish-cv-parsing-link'
                                                >
                                                    Finish extracting
                                                    <Icon name='arrow-right-single' size={20} />
                                                </FlexBox>
                                            </Link>
                                        )}
                                        {cvParsingStatus[0].status === SpecialistCvStatus.ERROR && (
                                            <FlexBox
                                                alignItems='center'
                                                justifyContent='space-between'
                                                css={css`
                                                    position: relative;
                                                    padding: 10px;
                                                    width: 100%;
                                                    margin-top: 4px;
                                                    background-color: ${theme.colors.white};
                                                    border-radius: 4px;
                                                    box-shadow: ${theme.shadow.small};
                                                    cursor: pointer;
                                                `}
                                            >
                                                <span
                                                    css={css`
                                                        color: ${theme.colors.red_5};
                                                    `}
                                                >
                                                    Retry
                                                </span>
                                            </FlexBox>
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        ) : (
                            <Button
                                variant='text'
                                css={css`
                                    padding: 0;
                                    color: ${theme.colors.violet_4};
                                `}
                                onClick={handleCvUpload}
                            >
                                Upload file
                            </Button>
                        )}
                        <Divider
                            style={css`
                                margin-bottom: 0;
                            `}
                        />
                        {hasBeenUploaded && (
                            <div>
                                <DownloadAnonymizedCV variant='text' onClick={openAnonymizedCV}>
                                    Anonymized CV
                                </DownloadAnonymizedCV>
                                <AnonymizedCVUploadDate
                                    css={css`
                                        font-size: 12px;
                                        color: ${theme.colors.gray_3};
                                    `}
                                >
                                    Uploaded: {uploadDate}
                                </AnonymizedCVUploadDate>
                            </div>
                        )}
                    </Wrapper>
                    {specialistParsedCvs.length > 0 && <CvsAddedBySpecialist specialistParsedCvs={specialistParsedCvs} />}
                </Fragment>
            ) : (
                <div
                    css={css`
                        margin-bottom: 32px;
                        padding: 24px;
                        box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.08);
                        ${styles}
                    `}
                >
                    {isLoading ? (
                        <ContentLoader autoHeight={false} height='50px' />
                    ) : (
                        <Fragment>
                            <FlexBox justifyContent='space-between'>
                                <div>
                                    <h6
                                        css={css`
                                            margin-bottom: 8px;
                                        `}
                                    >
                                        Upload CV
                                    </h6>
                                </div>
                                {!cvFile?.id && (
                                    <Button
                                        variant='text'
                                        css={css`
                                            padding: 0;
                                            color: ${COLOR_PALETTE.violet_4};
                                        `}
                                        onClick={handleCvUpload}
                                    >
                                        Add New File
                                    </Button>
                                )}
                            </FlexBox>

                            <div
                                css={css`
                                    font-size: 12px;
                                    color: ${COLOR_PALETTE.gray_3};
                                `}
                            >
                                Visible internally. The CV can be in either .docx or .pdf format.
                            </div>

                            {cvFile?.id && (
                                <Fragment>
                                    <Divider
                                        style={css`
                                            margin-bottom: 0;
                                        `}
                                    />
                                    <a href={cvFile.downloadUrl || ''} download target='_blank' rel='noopener noreferrer'>
                                        <div
                                            css={css`
                                                font-weight: 500;
                                                font-size: 12px;
                                                color: ${COLOR_PALETTE.gray_6};
                                            `}
                                        >
                                            {cvFile.fileName}
                                        </div>
                                    </a>
                                    <FlexBox justifyContent='space-between'>
                                        <div
                                            css={css`
                                                font-size: 12px;
                                                color: ${COLOR_PALETTE.gray_3};
                                                line-height: 30px;
                                            `}
                                        >
                                            Uploaded: {convertTimestampToDateString(cvFile.uploadedAt || 0)}
                                        </div>

                                        <FlexBox alignItems='center'>
                                            <Button
                                                variant='text'
                                                css={css`
                                                    padding: 0;
                                                    margin-right: 20px;
                                                    color: ${COLOR_PALETTE.violet_4};
                                                `}
                                                onClick={handleCvUpload}
                                            >
                                                Replace
                                            </Button>
                                            <IconButton name='trash' innerSize={20} size={30} onClick={showModal} />
                                        </FlexBox>
                                    </FlexBox>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            )}
            <DeleteConfirmationModal isVisible={isModalVisible} handleConfirmation={handleConfirmation} handleRejection={handleRejection} />
        </Fragment>
    )
}

export { UploadCv }
