import * as d3 from 'd3'
import { VerifiedSkill } from '../../contracts/profile/skill'
import { SkillMatrix } from '../../contracts/profile/skillMatrix'
import { COLOR_PALETTE } from '../../theme/colors'
import { GraphData, Node } from './types'

export const taPallette = [
    COLOR_PALETTE.yellow_5,
    COLOR_PALETTE.yellow_4,
    COLOR_PALETTE.yellow_3,
    COLOR_PALETTE.yellow_2,
    COLOR_PALETTE.green_6,
    COLOR_PALETTE.green_4,
    COLOR_PALETTE.green_3,
    COLOR_PALETTE.blue_4,
    COLOR_PALETTE.blue_3,
    COLOR_PALETTE.violet_5,
    COLOR_PALETTE.violet_4,
    COLOR_PALETTE.violet_3,
    COLOR_PALETTE.violet_2,
]

export const breadcrumbsSize = {
    height: 30,
    space: 3,
    arrowAngleHeight: 10,
}

export const calculateTextWidth = (text?: string): number => {
    if (!text) {
        return 0
    }
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
        context.font = '16px Prompt'
    }
    const textWidth = context ? context.measureText(text).width + 20 : 0
    return textWidth
}

export const breadcrumbPoints = (node: Node, index: number) => {
    const textWidth = calculateTextWidth(node.data.name)
    const points = []
    points.push('0,0')
    points.push(textWidth + ',0')
    points.push(textWidth + breadcrumbsSize.arrowAngleHeight + ',' + breadcrumbsSize.height / 2)
    points.push(textWidth + ',' + breadcrumbsSize.height)
    points.push('0,' + breadcrumbsSize.height)
    if (index > 0) {
        // Leftmost breadcrumb; don't include 6th vertex.
        points.push(breadcrumbsSize.arrowAngleHeight + ',' + breadcrumbsSize.height / 2)
    }
    return points.join(' ')
}

export const width = 560
export const radius = width / 2

export const x = d3
    .scaleLinear()
    .range([0, 2 * Math.PI])
    .clamp(true)

export const y = d3.scaleSqrt().range([radius * 0.1, radius])
const arcNode: any = d3.arc<void, Node>()
export const arc = arcNode
    .startAngle((node: Node) => x(node.x0))
    .endAngle((node: Node) => x(node.x1))
    .innerRadius((node: Node) => Math.max(0, y(node.y0 ? node.y0 : 0.075) as number))
    .outerRadius((node: Node) => Math.max(0, y(node.y1) as number))

export const middleArcLine = (node: Node) => {
    const halfPi = Math.PI / 2
    const angles = [(x(node.x0) as number) - halfPi, (x(node.x1) as number) - halfPi]
    const r = Math.max(0, ((y(node.y0) as number) + (y(node.y1) as number)) / 2)
    const middleAngle = (angles[1] + angles[0]) / 2
    const invertDirection = middleAngle > 0 && middleAngle < Math.PI // On lower quadrants write text ccw
    if (invertDirection) {
        angles.reverse()
    }
    const path = d3.path()
    path.arc(0, 0, r, angles[0], angles[1], invertDirection)
    return path.toString()
}

export const textFits = (node: Node) => {
    const CHAR_SPACE = 6
    const deltaAngle = (x(node.x1) as number) - (x(node.x0) as number)
    const r = Math.max(0, ((y(node.y0) as number) + (y(node.y1) as number)) / 2)
    const perimeter = r * deltaAngle
    return (node.data?.name?.length || 0) * CHAR_SPACE < perimeter
}

type CalcBreadcrumbPositionAttrs = {
    textWidths: number[]
    maxWidth: number
}

export const calcBreadcrumbPosition = ({ textWidths, maxWidth }: CalcBreadcrumbPositionAttrs) => {
    let currentLine = 0
    let currentBreakpoint = 0
    return textWidths.map((currentWidth, index) => {
        const widths = [...textWidths]
        widths.length = index
        let ancestorsWidth = widths.reduce((previous, current) => previous + current, 0)
        ancestorsWidth += index * breadcrumbsSize.space
        if (ancestorsWidth - currentBreakpoint + currentWidth > maxWidth) {
            currentLine++
            currentBreakpoint = ancestorsWidth
        }
        return [ancestorsWidth - currentBreakpoint, currentLine * (breadcrumbsSize.height + breadcrumbsSize.space)]
    })
}

export const parseSkillMatrix = (skillMatrix: SkillMatrix | null, rootName = 'Skills'): GraphData | null =>
    skillMatrix?.categories?.length
        ? {
              name: rootName,
              children: skillMatrix.categories.map(category => ({
                  name: category.name,
                  children: category.subcategories.map(subcategory => ({
                      name: subcategory.name,
                      children: [
                          ...(subcategory.basic as Array<string | VerifiedSkill>).map(basic => ({
                              name: (basic as VerifiedSkill).name || (basic as string),
                              value: 1,
                          })),
                          ...(subcategory.good as Array<string | VerifiedSkill>).map(good => ({
                              name: (good as VerifiedSkill).name || (good as string),
                              value: 2,
                          })),
                          ...(subcategory.expert as Array<string | VerifiedSkill>).map(expert => ({
                              name: (expert as VerifiedSkill).name || (expert as string),
                              value: 3,
                          })),
                          ...(subcategory.notRated as Array<string | VerifiedSkill>).map(notRated => ({
                              name: (notRated as VerifiedSkill).name || (notRated as string),
                              value: 1,
                          })),
                      ],
                  })),
              })),
          }
        : null
