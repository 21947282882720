/** @jsxImportSource @emotion/react */

import { FunctionComponent, useContext, useMemo, useState } from 'react'
import { AwardsBox } from '../../components/AwardsBox'
import { EverestView } from '../../components/EverestView'
import { AuthPageLayout } from '../../components/layout/AuthPageLayout'
import { ManagerLoginBanner } from '../../components/ManagerLoginBanner'
import { SpecialistLoginBanner } from '../../components/SpecialistLoginBanner'
import { TabbedLoginForm } from '../../components/TabbedLoginForm'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useQuery } from '../../utils/useQuery'

const Login: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)
    const query = useQuery()

    const defaultSelectedTab = useMemo(() => {
        switch (query.get('role')) {
            case 'specialist': {
                return 'specialist'
            }
            case 'manager': {
                return 'manager'
            }
            default: {
                return featureFlags.hideSpecialistLogin ? 'manager' : null
            }
        }
    }, [featureFlags.hideSpecialistLogin, query])

    const [secondCol, setSecondCol] = useState<Nullable<string>>(defaultSelectedTab)
    const secondColContent = useMemo(() => {
        switch (secondCol) {
            case 'manager': {
                return <ManagerLoginBanner />
            }
            case 'specialist': {
                return <SpecialistLoginBanner />
            }
            default: {
                return featureFlags.everestWelcome ? <EverestView /> : <AwardsBox />
            }
        }
    }, [featureFlags.everestWelcome, secondCol])

    return (
        <AuthPageLayout secondCol={secondColContent}>
            <TabbedLoginForm defaultTab={defaultSelectedTab} onTabChange={setSecondCol} />
        </AuthPageLayout>
    )
}

export { Login }
