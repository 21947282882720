import * as Sentry from '@sentry/react'
import { useEffectOnce } from 'react-use'
import { config } from './environment/config'
import { FunctionComponent, useEffect } from 'react'
import { Routes, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

const SentryProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const ignoreErrors = [
        'Socket hung up',
        'SocketProtocolError',
        'Client connection establishment timed out',
        'https://js.intercomcdn.com/',
        'ResizeObserver',
    ]

    const routingInstrumentation = Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    )

    useEffectOnce(() => {
        if (window.navigator.userAgent !== 'Cypress') {
            Sentry.init({
                beforeSend: (event, _) => (event && event.message && ignoreErrors.includes(event.message) ? null : event),
                dsn: 'https://5cd208217de8499994629df1221f28be@o290206.ingest.sentry.io/5460281',
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation,
                    }),
                ],
                tracesSampleRate: 1.0,
                environment: config.environment,
                release: process.env.REACT_APP_VERSION,
                // There are issues with using localhost when interacting with google auth,
                // using 127.0.0.1 lets us circumvent that.
                denyUrls: [/localhost/i, '127.0.0.1'],
                ignoreErrors,
            })
        }
    })

    return <SentryRoutes>{children}</SentryRoutes>
}

export { SentryProvider }
