/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Modal } from '../../../components/Modal'

type ExitConfirmationModalProps = {
    opened: boolean
    handleClose: () => void
    handleConfirmation: () => void
}

const ExitConfirmationModal: FunctionComponent<React.PropsWithChildren<ExitConfirmationModalProps>> = ({
    opened,
    handleClose,
    handleConfirmation,
}) => (
    <Modal opened={opened} onClose={handleClose}>
        <h5>Are you sure you want to exit?</h5>
        <p>Your progress will be saved but the timer for the current question won’t stop and may run out.</p>
        <Divider />
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button variant='text' onClick={handleClose}>
                Cancel
            </Button>
            <Button onClick={handleConfirmation}>Exit</Button>
        </div>
    </Modal>
)

export { ExitConfirmationModal }
