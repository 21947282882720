import { CompanyAccessLevelGuard } from './components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from './components/CompanyStatusGuard'

const WhiteLabel = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/white-label/form' />
    </CompanyAccessLevelGuard>
)

export { WhiteLabel }
