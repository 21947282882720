/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { removeSpecialistCv } from '../../api/api'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { PageWidthWrapper } from '../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../components/layout/SpecialistPageWrapper'
import { LayoutWithStickyColumn } from '../../components/layout/LayoutWithStickyColumn'
import { LeavePageConfirmationModal } from '../../components/LeavePageConfirmationModal'
import { NoData } from '../../components/NoData'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { StepsIndicator } from '../../components/steps-indicator/StepsIndicator'
import { StepStatus } from '../../components/steps-indicator/types'
import { Education, EducationStatus } from '../../contracts/specialist/specialistEducations'
import { mqMin } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { NullableArray } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { QualificationsForm } from '../my-specialists/specialist-profile/education/QualificationsForm'
import { QualificationsEntry } from '../my-specialists/specialist-profile/EducationQualificationsSection'
import {
    editSpecialistCvEducation,
    getSpecialistCvEducation,
    markSpecialistCvEducationAsDeleted,
    undoMarkingSpecialistCvEducationAsDeleted,
} from './api'

const SpecialistEducationPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const {
        actions: { layoutToggleLoader },
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])
    const { cvParsingId } = useParams<{ cvParsingId: string }>()
    const [education, setEducation] = useState<NullableArray<Education>>(null)
    const [editingIds, setEditingIds] = useState<Array<string>>([])
    const [notValidIds, setNotValidIds] = useState<Array<string>>([])
    const [dirtyEducations, setDirtyEducations] = useState<{ [k: string]: boolean }>({})
    const { addError } = useNotifications()
    const theme = useTheme()
    const log = useLogger('error')

    useEffect(() => {
        if (cvParsingId) {
            layoutToggleLoader(true)
            getSpecialistCvEducation(cvParsingId)
                .then(data => {
                    setEducation(data.studies)
                    data.studies.forEach(edu => {
                        if (edu.status === EducationStatus.NOT_VALID) {
                            setEditingIds(currentEditingIds => currentEditingIds.concat(edu.id || ''))
                            setNotValidIds(currentNotValidIds => currentNotValidIds.concat(edu.id || ''))
                        }
                    })
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, cvParsingId, layoutToggleLoader, log])

    const handleDelete = useCallback(
        (educationId: string) => {
            if (cvParsingId) {
                layoutToggleLoader(true)
                markSpecialistCvEducationAsDeleted(cvParsingId, educationId)
                    .then(data => setEducation(data.studies))
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [addError, cvParsingId, layoutToggleLoader, log],
    )

    const handleUndo = useCallback(
        (educationId: string) => {
            if (cvParsingId) {
                layoutToggleLoader(true)
                undoMarkingSpecialistCvEducationAsDeleted(cvParsingId, educationId)
                    .then(data => setEducation(data.studies))
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [addError, cvParsingId, layoutToggleLoader, log],
    )

    const handleEdit = useCallback((educationId: string) => {
        setEditingIds(currentEditingIds => currentEditingIds.concat(educationId))
    }, [])

    const handleClose = useCallback((educationId?: string) => {
        setEditingIds(currentEditingIds => currentEditingIds.filter(id => id !== educationId))
    }, [])

    const handleSave = useCallback(
        (educationId: any, formData: any) => {
            if (cvParsingId) {
                layoutToggleLoader(true)
                editSpecialistCvEducation(cvParsingId, educationId, {
                    from: formData.from,
                    studiesDepartment: formData.studiesDepartment,
                    studiesSpecialization: formData.studiesSpecialization,
                    studiesUniversity: formData.studiesUniversity,
                    to: formData.to,
                    type: formData.type,
                })
                    .then(data => {
                        setEducation(data.studies)
                        setNotValidIds(currentNotValidIds => currentNotValidIds.filter(notValidId => notValidId !== educationId))
                        handleClose(educationId)
                    })
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [addError, cvParsingId, handleClose, layoutToggleLoader, log],
    )

    const handleCancelProcess = useCallback(() => {
        if (cvParsingId) {
            removeSpecialistCv(specialistId, cvParsingId)
                .then(() => history.push('/specialist-dashboard'))
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [addError, cvParsingId, layoutToggleLoader, log, specialistId])

    const canGoNextStep = useMemo(
        () => notValidIds.every(notValidId => education?.find(edu => edu.id === notValidId)?.status === EducationStatus.DELETED),
        [education, notValidIds],
    )

    const handleDirtyEducations = useCallback((isDirty: any, id: any) => {
        setDirtyEducations(educations => {
            const newEducations = { ...educations }
            newEducations[id] = isDirty
            return newEducations
        })
    }, [])

    const isAnyEducationDirty = useMemo(() => Object.values(dirtyEducations).find(dirtyEducation => dirtyEducation), [dirtyEducations])

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;
            `}
        >
            <PageWidthWrapper>
                <BackLink
                    style={css`
                        margin: 0 0 24px;
                    `}
                    text='Back to dashboard'
                    path='/specialist-dashboard'
                />
                <h3>Confirm Extracted Data</h3>
                <p>Choose which data to add to your profile. You’ll also be able to edit this data later in your Profile.</p>
            </PageWidthWrapper>
            <LayoutWithStickyColumn
                firstCol={
                    <Fragment>
                        <StepsIndicator
                            steps={[
                                {
                                    name: 'Experience',
                                    status: StepStatus.DONE,
                                },
                                {
                                    name: 'Education',
                                    status: StepStatus.ACTIVE,
                                },
                                { name: 'Skills', status: StepStatus.PENDING },
                            ]}
                        />
                        <h6
                            css={css`
                                margin-top: 16px;
                            `}
                        >
                            Education
                        </h6>
                        {education?.map(edu =>
                            editingIds.includes(edu.id || '') && edu.status !== EducationStatus.DELETED ? (
                                <QualificationsForm
                                    key={edu.id}
                                    education={edu}
                                    onClose={handleClose}
                                    customSubmit={handleSave}
                                    aiCvParsing
                                    canClose={!notValidIds.includes(edu.id as string)}
                                    handleDelete={handleDelete}
                                    removable
                                    setIsDirty={handleDirtyEducations}
                                />
                            ) : (
                                <QualificationsEntry
                                    key={edu.id}
                                    education={edu}
                                    handleDelete={handleDelete}
                                    handleEdit={handleEdit}
                                    handleUndo={handleUndo}
                                />
                            ),
                        )}
                        {education?.length === 0 && (
                            <Fragment>
                                <NoData
                                    message='No education records found in the file'
                                    style={css`
                                        margin: 90px 0;
                                    `}
                                />
                                <Divider />
                            </Fragment>
                        )}
                        <FlexBox justifyContent='space-between'>
                            <Button variant='link' onClick={handleCancelProcess}>
                                Cancel
                            </Button>
                            <div>
                                <Link to={`/specialist-cv-parsing/${cvParsingId}/experience`}>
                                    <Button variant='tertiary'>Previous</Button>
                                </Link>
                                {canGoNextStep ? (
                                    <Link to={`/specialist-cv-parsing/${cvParsingId}/skills`}>
                                        <Button
                                            variant='primary'
                                            style={css`
                                                margin-left: 16px;
                                            `}
                                        >
                                            Next
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        variant='primary'
                                        style={css`
                                            margin-left: 16px;
                                        `}
                                        disabled
                                    >
                                        Next
                                    </Button>
                                )}
                            </div>
                        </FlexBox>
                    </Fragment>
                }
                secondCol={
                    <div
                        css={css`
                            padding: 24px;
                            width: 100%;
                            background-color: ${theme.colors.beige_1};
                            margin-bottom: 24px;
                            ${mqMin[2]} {
                                margin: 0;
                            }
                        `}
                    >
                        <h6>Please follow the instructions below:</h6>
                        <ol
                            css={css`
                                padding-left: 20px;
                            `}
                        >
                            <li>
                                If you want to add or change the education, certification, langauges select the record that you want to
                                change and click the edit button.
                            </li>
                            <li>If you wish to delete the record then click the delete option.</li>
                            <li>Once you completed please click “Next” button. </li>
                        </ol>
                    </div>
                }
            />
            {isAnyEducationDirty && <LeavePageConfirmationModal />}
        </SpecialistPageWrapper>
    )
}

export { SpecialistEducationPage }
