import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ControlledTextEditor } from 'src/components/forms/ControlledTextEditor'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { useIndustries } from 'src/redux/dictionaryDataHooks'
import { UpdateOpportunityFormData } from '../../../../contracts/opportunities-manager/contracts'
import { Box } from '../../Box'
import { naturalNumbersRegex } from 'src/utils/regexes'

type InternalDetailsProps = {
    control: Control<UpdateOpportunityFormData>
}

const ProjectDetails: FunctionComponent<React.PropsWithChildren<InternalDetailsProps>> = ({ control }) => {
    const industries = useIndustries()
    return (
        <Box>
            <h6>Project Details</h6>
            <FieldWrapper>
                <ControlledInput control={control} name='projectDetails.projectTitle' label='Project Title' placeholder='Project Title' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    options={industries}
                    control={control}
                    name='projectDetails.projectIndustry'
                    label='Project Industry'
                    placeholder='Project Industry'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput
                    control={control}
                    name='projectDetails.paymentPeriod'
                    label='Payment period'
                    placeholder='Days for payment'
                    regex={naturalNumbersRegex}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledTextEditor
                    control={control}
                    name='projectDetails.projectDescription'
                    label='Project Description (optional)'
                    placeholder='Tell us more about the project'
                />
            </FieldWrapper>
        </Box>
    )
}

export { ProjectDetails }
