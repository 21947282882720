/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ResponsiveGrid } from '../../components/data-grid/ResponsiveGrid'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { ColorBackgroundWrapper, FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Pagination } from '../../components/Pagination'
import { ScrollToTop } from '../../components/ScrollUp'
import { SearchInput } from '../../components/SearchInput'
import { breakpoints, COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'
import { HeaderWithActions } from '../my-specialists/shared/HeaderWithActions'
import { getBookings, getBookingsFilters } from './api'
import { FiltersBar } from './bookings-search/FiltersBar'
import { BookingsResultsWrapper } from './bookings-search/ResultsWrapper'
import {
    columns,
    defaultPageSize,
    initialFilters,
    mobileItemStyles,
    parseQueryToSearchRequest,
    rowClassName,
    stringifySearchRequestToQuery,
} from './bookings-search/utils'
import { Booking, BookingsFilters, BookingsSearchRequest } from './contracts'
import { applicationName, useMenuItems } from './talentMarketplaceApp'

const MarketplaceBookingsSearchPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [bookings, setBookings] = useState<Array<Booking>>([])
    const [filtersOpened, setFiltersOpened] = useState(false)
    const [possibleFilters, setPossibleFilters] = useState({})
    const [sorting, setSorting] = useState<Array<any>>([])
    const [total, setTotal] = useState(0)
    const menuItems = useMenuItems()

    const {
        state: {
            layout: { showLoader },
        },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const log = useLogger('error')
    const { addError } = useNotifications()

    const navigate = useNavigate()
    const { search, pathname, hash } = useLocation()

    const searchRequest = useMemo<BookingsSearchRequest>(() => parseQueryToSearchRequest(search), [search])
    const searchFilters = useMemo<BookingsFilters>(() => {
        const { searchText: s, paging: p, ...filtersOnly } = searchRequest
        return filtersOnly
    }, [searchRequest])

    const [paging, setPaging] = useState({
        page: searchRequest.paging?.page || 1,
        size: searchRequest.paging?.size || defaultPageSize,
    })
    const [searchText, setSearchText] = useState(searchRequest.searchText || '')

    const [selectedFilters, setSelectedFilters] = useState<BookingsFilters>(searchFilters || initialFilters)

    useEffect(() => {
        const completeRequest = { searchText, paging, ...selectedFilters }
        const newSearchQuery = stringifySearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [paging, navigate, searchText, selectedFilters])

    const fetchPossibleFilters = useCallback(() => {
        const request = { searchText, ...selectedFilters }
        getBookingsFilters(request)
            .then(data => {
                setPossibleFilters(data)
            })
            .catch(err => {
                log(err)
                addError()
            })
    }, [searchText, selectedFilters, log, addError])

    const fetchBookings = useCallback(() => {
        layoutToggleLoader(true)
        const request = { searchText, ...selectedFilters, paging, sorting }
        getBookings(request)
            .then(data => {
                if (data && data.bookings) {
                    setBookings(data.bookings)
                    setTotal(data.total)
                } else {
                    setBookings([])
                    setTotal(0)
                }
            })
            .catch(err => {
                log(err)
                addError()
            })
            .finally(() => {
                layoutToggleLoader(false)
            })
    }, [searchText, selectedFilters, paging, sorting, addError, log, layoutToggleLoader])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    useEffect(() => {
        if (search) {
            setFiltersOpened(true)
        }
    }, [search])

    useEffect(fetchPossibleFilters, [fetchPossibleFilters])

    useEffect(fetchBookings, [fetchBookings])

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(opened => !opened)
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters(initialFilters)
    }, [])

    const onChangePage = useCallback((page: any, size: any) => {
        setPaging({ page, size })
    }, [])

    const onChangePageSize = useCallback(
        (size: Array<string>) => {
            setPaging({ page: paging.page, size: Number(size.toString()) })
        },
        [paging],
    )

    useEffect(() => {
        if (total > 0 && bookings.length === 0 && paging.page > 1) {
            setPaging(oldPaging => ({
                page: 1,
                size: oldPaging.size,
            }))
        }
    }, [bookings.length, paging.page, total])

    const getLink = useCallback(
        (record: any) => {
            return `/profile/${record.specialistId}?prevPath=${encodeURIComponent(`${pathname}${search}${hash}`)}`
        },
        [hash, pathname, search],
    )

    return (
        <Fragment>
            <ScrollToTop action={paging} />
            <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${COLOR_PALETTE.gray_1};
                        padding: 18px 16px 0;
                        ${mqMin[1]} {
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        }
                    `}
                >
                    <div
                        css={css`
                            max-width: 1440px;
                            margin: 0 auto;
                            ${mqMax[1]} {
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                            }

                            ${mqMin[2]} {
                                padding: 0 24px;
                            }
                        `}
                    >
                        <HeaderWithActions header='Bookings' isSearchPage />
                        <SearchInput
                            initialText={searchText}
                            disabled={!bookings?.length && !search}
                            onSubmit={setSearchText}
                            filtersOpened={filtersOpened}
                            onToggleFilters={onToggleFilters}
                            placeholder='Project name, skills etc'
                            styles={css`
                                margin-bottom: 16px;
                            `}
                            dataTestId='bookings-search'
                        />
                        <FiltersBar
                            filtersOpened={filtersOpened}
                            possibleFiltersValues={possibleFilters}
                            selectedFilters={selectedFilters}
                            onChangeFilters={setSelectedFilters}
                            onClearFilters={onClearFilters}
                        />
                    </div>
                </ColorBackgroundWrapper>

                <BookingsResultsWrapper pending={showLoader} total={bookings.length}>
                    <FullPageWrapper>
                        <ResponsiveGrid
                            rowKey='id'
                            dataSource={bookings}
                            columns={columns}
                            pagination={false}
                            onChange={onChangeTable}
                            scroll={{ x: breakpoints[1] }}
                            rowClassName={rowClassName}
                            mobileItemStyles={mobileItemStyles}
                            getLink={getLink}
                        />
                        <div
                            css={css`
                                margin-top: 40px;
                            `}
                        >
                            <Pagination
                                pageSize={paging.size}
                                defaultPageSize={defaultPageSize}
                                total={total}
                                onChange={onChangePage}
                                onChangePageSize={onChangePageSize}
                                current={paging.page}
                                css={css`
                                    ${mqMax[0]} {
                                        display: flex;
                                        justify-content: center;
                                    }
                                `}
                            />
                        </div>
                    </FullPageWrapper>
                </BookingsResultsWrapper>
            </DashboardLayout>
        </Fragment>
    )
}

export { MarketplaceBookingsSearchPage }
