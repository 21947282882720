/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'

type DividerProps = {
    text?: string
    color?: string
    style?: SerializedStyles
    className?: string
}

const Divider: FunctionComponent<React.PropsWithChildren<DividerProps>> = ({ text, color = COLOR_PALETTE.gray_2, style, className }) => {
    return (
        <div
            css={css`
                height: 20px;
                position: relative;
                width: 100%;
                display: inline-flex;
                justify-content: center;
                margin-bottom: 13px; // plus height/2 => ~23px of visual margin
                ${style}
            `}
            className={className}
        >
            <div
                css={css`
                    position: absolute;
                    top: 11px;
                    width: 100%;
                    border-bottom: 1px solid ${color};
                `}
            />
            {text && (
                <span
                    css={css`
                        z-index: 0;
                        padding: 0 5px;
                        color: ${COLOR_PALETTE.gray_3};
                        background-color: ${COLOR_PALETTE.white};
                    `}
                >
                    {text}
                </span>
            )}
        </div>
    )
}

export { Divider }
