import React from 'react'
import styled from '@emotion/styled'
import { Collapse, CollapseProps } from 'antd'

import { mqMax } from '../../GlobalStyle'
import { Icon } from 'src/components/Icon'

const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 40px;

    ${mqMax[1]} {
        grid-template-columns: 1fr;
    }
`

const StyledHeader = styled.h4`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};
    padding-bottom: 24px;
    margin-bottom: 36px;
`

const StyledSectionHeader = styled.span`
    font-size: 16px;
    font-weight: 500;
`

const StyledCollapseInternal = styled(Collapse)`
    > :not(:last-child) {
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};
    }
`
const StyledCollapse = ({ children, ...props }: { children: React.ReactNode } & CollapseProps) => {
    return (
        <StyledCollapseInternal
            ghost
            expandIcon={({ isActive }) => <Icon name={isActive ? 'arrow-down' : 'arrow-right-filled'} />}
            {...props}
        >
            {children}
        </StyledCollapseInternal>
    )
}

export { StyledSectionHeader, ContentWrapper, StyledCollapse, StyledHeader }
