/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Position } from '../../contracts/profile/position'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { formatDate, formatUnixDate } from '../../utils/dates'
import { SkillsList } from '../talent/SkillsList'
import { Truncate } from '../Truncate'
import { parseHTMLString } from '../opportunities/utils'

const TimelineEntryHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
    padding-bottom: 20px;
`

type TimelineEntryLabeledInfoProps = {
    label: string
    hideLabelOnMobile?: boolean
    alignItems?: string
    styles?: SerializedStyles
}

const TimelineEntryLabeledInfo: FunctionComponent<React.PropsWithChildren<TimelineEntryLabeledInfoProps>> = ({
    label,
    hideLabelOnMobile = false,
    styles = {},
    children,
    alignItems = 'center',
    ...other
}) => {
    return (
        <div
            css={css`
                margin-top: 20px;
                font-size: 14px;
                ${styles}
            `}
            {...other}
        >
            <div
                css={css`
                    display: flex;
                    align-items: ${alignItems};
                `}
            >
                <div
                    css={css`
                        margin-right: 10px;
                        color: ${COLOR_PALETTE.gray_3};
                        flex: 0 0 15%;

                        ${hideLabelOnMobile &&
                        `${mqMax[0]} {
                            display: none;
                        }`}
                    `}
                >
                    {label}:
                </div>
                <div
                    css={css`
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                    `}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}

type TimelineEntryProps = {
    position: Position
    styles?: SerializedStyles
    isUnix?: boolean
}

const TimelineEntry: FunctionComponent<React.PropsWithChildren<TimelineEntryProps>> = ({ position, styles = {}, isUnix }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                width: 100%;
                margin: 0 0 24px 24px;
                border: 1px solid ${theme.colors.gray_2};
                background-color: ${theme.colors.white};
                padding: 26px;
                border-radius: 4px;
                box-shadow: ${theme.shadow.small};

                ${mqMax[1]} {
                    margin: 0;
                }

                ${styles}
            `}
        >
            <TimelineEntryHeader>
                <div>{position.roles.join(', ')}</div>
                {position.from && (
                    <TimelineEntryLabeledInfo
                        label='Duration'
                        hideLabelOnMobile
                        styles={css`
                            margin-top: 0;
                            font-size: 14px;
                        `}
                    >
                        <div
                            css={css`
                                background-color: ${theme.colors.gray_2};
                                border-radius: 55px;
                                padding: 2px 12px;
                                text-align: center;
                            `}
                        >
                            {position.from
                                ? `${isUnix ? formatUnixDate(position.from) : formatDate(position.from)} - ${
                                      position.to ? (isUnix ? formatUnixDate(position.to) : formatDate(position.to)) : 'now'
                                  }`
                                : null}
                        </div>
                    </TimelineEntryLabeledInfo>
                )}
            </TimelineEntryHeader>
            {position.industry && (
                <TimelineEntryLabeledInfo label='Industry'>
                    <div>{position.industry}</div>
                </TimelineEntryLabeledInfo>
            )}
            {position.techStack.length > 0 && position.techStack[0] && (
                <TimelineEntryLabeledInfo label='Stack'>
                    <SkillsList skills={position.techStack} />
                </TimelineEntryLabeledInfo>
            )}

            {position.description && (
                <TimelineEntryLabeledInfo label='Description' alignItems='flex-start'>
                    <Truncate> {parseHTMLString(position.description)}</Truncate>
                </TimelineEntryLabeledInfo>
            )}
        </div>
    )
}

export { TimelineEntry }
