import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../../../GlobalStyle'

const ApplicationBox = styled.div`
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    border-radius: 2px;
    color: ${COLOR_PALETTE.white};
    background-color: ${COLOR_PALETTE.gray_6};
    padding: 1px 6px;
`

export { ApplicationBox }
