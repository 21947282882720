import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'

const TermsDecline = () => {
    return (
        <ResponsiveWrapper withLogo>
            <h5>You declined Talent Alpha's Terms</h5>
            <p>In order to edit your profile you have to agree to:</p>
            <ul>
                <li>Terms of Use</li>
            </ul>
            <p>If you accidentally clicked decline, please use the link from email again.</p>
        </ResponsiveWrapper>
    )
}

export { TermsDecline }
