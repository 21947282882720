/** @jsxImportSource @emotion/react */

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { FeedbackStep, FeedbackWizard } from '../../components/feedback/FeedbackWizard'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { DictionaryEntry } from '../../contracts/dictionaryEntry'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import {
    getMarketplaceBookingFeedbackState,
    getMarketplaceFeedbackNegativeResponseReasonOptions,
    sendInProgressMarketplaceFeedback,
    sendMarketplaceRateExperience,
    sendNegativeMarketplaceFeedback,
    sendPositiveMarketplaceFeedback,
} from './api'
import { applicationName, useMenuItems } from './talentMarketplaceApp'

const MarketplaceFeedbackPage = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const menuItems = useMenuItems()
    const log = useLogger('error')
    const query = useQuery()

    const bookingId = useMemo(() => query.get('bookingId') || '', [query])
    const specialistId = useMemo(() => query.get('specialistId') || '', [query])
    const [step, setStep] = useState<FeedbackStep>(FeedbackStep.PENDING_RESULT)
    const [negativeResponseOptions, setNegativeResponseOptions] = useState<Array<DictionaryEntry>>([])

    const handleError = useCallback((error: any) => {
        if (error.data.code === 'ADDING_BOOKING_FEEDBACK_NOT_ALLOWED') {
            setStep(FeedbackStep.NOT_ALLOWED)
        }
    }, [])

    const onRateYourExperienceSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendMarketplaceRateExperience({ ...formData, bookingId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, handleError, log],
    )

    const onPositiveFeedbackSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendPositiveMarketplaceFeedback({ ...formData, bookingId, specialistId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, specialistId, handleError, log],
    )

    const onNegativeFeedbackSubmit = useCallback(
        (formData: any) => {
            layoutToggleLoader(true)

            sendNegativeMarketplaceFeedback({ ...formData, bookingId, specialistId })
                .then(() => {
                    setStep(FeedbackStep.DONE)
                })
                .catch(error => {
                    handleError(error)
                    log(error)
                })
                .finally(() => layoutToggleLoader(false))
        },
        [layoutToggleLoader, bookingId, specialistId, handleError, log],
    )

    const onInProgressSelected = useCallback(() => {
        layoutToggleLoader(true)

        sendInProgressMarketplaceFeedback({ bookingId })
            .then()
            .catch(error => {
                handleError(error)
                log(error)
            })
            .finally(() => layoutToggleLoader(false))
    }, [layoutToggleLoader, bookingId, handleError, log])

    const matchStepResponse = useCallback((response: string) => {
        switch (response) {
            case 'PENDING_RESULT':
                setStep(FeedbackStep.PENDING_RESULT)
                break
            case 'PENDING_RATE_EXPERIENCE':
                setStep(FeedbackStep.PENDING_RATE_EXPERIENCE)
                break
            case 'DONE':
                setStep(FeedbackStep.DONE)
                break
            case 'EXPIRED':
                setStep(FeedbackStep.EXPIRED)
                break
        }
    }, [])

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)

        Promise.all([getMarketplaceFeedbackNegativeResponseReasonOptions(), getMarketplaceBookingFeedbackState(bookingId)])
            .then(responses => {
                setNegativeResponseOptions(responses[0])
                matchStepResponse(responses[1])
            })
            .catch(log)
            .finally(() => {
                layoutToggleLoader(false)
            })
    }, [layoutToggleLoader, log, bookingId, matchStepResponse])

    useEffect(() => fetchData(), [fetchData])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <FeedbackWizard
                step={step}
                backButtonText='Back to Bookings'
                backButtonPath='/talent-marketplace/bookings'
                pleaseRateMessage='Please rate your experience booking a Specialist on the Talent Marketplace (optional).'
                newFeedbackFirstStepBackText='Back'
                newFeedbackSecondStepPositiveResponseSubmit={onPositiveFeedbackSubmit}
                newFeedbackSecondStepInProgressResponseSubmit={onInProgressSelected}
                newFeedbackSecondStepNegativeResponseSubmit={onNegativeFeedbackSubmit}
                newFeedbackSecondStepNegativeReasonOptions={negativeResponseOptions}
                rateExperienceOnSubmit={onRateYourExperienceSubmit}
            />
        </DashboardLayout>
    )
}

export { MarketplaceFeedbackPage }
