/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useState } from 'react'
import { SpecialistGeneralInformation } from '../../../contracts/specialist/specialistGeneralInformation'
import { SectionWrapper } from '../components/SectionWrapper'
import { BoxTitle } from '../SpecialistDetails'
import { RoleAndSeniorityForm } from './RoleAndSeniorityForm'

type RoleAndSeniorityProps = {
    generalInformation: SpecialistGeneralInformation
    specialistId: string
    fetchGeneralInformation: () => void
}

const RoleAndSeniority: FunctionComponent<React.PropsWithChildren<RoleAndSeniorityProps>> = ({
    generalInformation,
    specialistId,
    fetchGeneralInformation,
}) => {
    const [editing, setEditing] = useState(false)

    const theme = useTheme()

    const toggleEdit = useCallback(() => {
        setEditing(!editing)
    }, [editing])

    return (
        <SectionWrapper onEditClick={toggleEdit} showButton={!editing}>
            {editing ? (
                <RoleAndSeniorityForm
                    toggleEdit={toggleEdit}
                    generalInformation={generalInformation}
                    specialistId={specialistId}
                    fetchGeneralInformation={fetchGeneralInformation}
                />
            ) : (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        height: 100%;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                        `}
                    >
                        <BoxTitle>{generalInformation.role}</BoxTitle>

                        {generalInformation.seniority && (
                            <div
                                css={css`
                                    background-color: ${theme.colors.green_2};
                                    font-size: 11px;
                                    display: flex;
                                    align-items: center;
                                    line-height: 14px;
                                    padding: 0 6px;
                                    margin-left: 12px;
                                `}
                            >
                                {generalInformation.seniority}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </SectionWrapper>
    )
}

export { RoleAndSeniority }
