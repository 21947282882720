/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Table } from 'antd'
import { TableProps } from 'antd/es/table'
import { SorterResult } from 'antd/es/table/interface'
import { Fragment, FunctionComponent, ReactNode, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { EMPTY_SORTING } from '../../contracts/common/sorting'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { Theme } from '../../theme/theme'
import { useIsMobile } from '../../utils/hooks'
import { AutocompleteSelect } from '../inputs/autocomplete-select/AutocompleteSelect'
import { ColorBackgroundWrapper } from '../layout/ResponsiveWrapper'

type MobileGridProps<RecordType> = {
    onSortingChanged?: (field: string) => void
    mobileItemStyles?: (record: RecordType, theme: Theme) => SerializedStyles
}

type ResponsiveGridType<RecordType extends object = any> = FunctionComponent<
    React.PropsWithChildren<
        TableProps<RecordType> &
            MobileGridProps<RecordType> & {
                dataTestId?: string
                wrapperStyle?: SerializedStyles
                getLink?: (record: unknown) => string
                mobileBreakpoint?: number
            }
    >
>

const hasDataIndex = (column: any) => !!column.dataIndex
const hasSorter = (column: any) => !!column.sorter
const extractDataIndex = (column: any) => column.dataIndex
const extractTitle = (column: any) => column.title

const RowWrapper = styled.div`
    margin-bottom: 16px;
`

const TextValue = styled.div`
    font-size: 14px;
`

const Label = styled.div`
    color: ${COLOR_PALETTE.gray_4};
    font-size: 12px;
    line-height: 16px;
`

const MobileRowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

const MobileGrid: ResponsiveGridType = ({ dataSource, columns, onChange, mobileItemStyles, onRow, dataTestId, rowKey = 'id' }) => {
    const theme = useTheme()

    const sortable = useMemo(() => {
        return columns && columns.some(column => hasSorter(column))
    }, [columns])
    const sortByOptions = useMemo(() => {
        const baseSorting = [EMPTY_SORTING]
        const sortable = columns ? columns.filter(column => hasSorter(column) && hasDataIndex(column)) : []
        const sortableKeys = sortable.map(extractDataIndex)
        const sortableTitles = sortable.map(extractTitle)
        const mapSortLabel = (key: string) => {
            const labelIndex = sortableKeys.indexOf(key)
            let title
            if (key === EMPTY_SORTING) {
                title = 'None'
            } else {
                title = <Fragment>Sort By: {sortableTitles[labelIndex]}</Fragment>
            }
            return title
        }

        return { columns: baseSorting.concat(sortableKeys), mapSortLabel }
    }, [columns])

    const onSortByChange = useCallback(
        ([fieldName]: [any]) => {
            const isEmpty = fieldName === EMPTY_SORTING
            if (onChange) {
                let sorter: SorterResult<string>
                if (isEmpty) {
                    sorter = {}
                } else {
                    sorter = { field: fieldName, order: 'ascend' }
                }
                onChange({}, {}, sorter, {} as any)
            }
        },
        [onChange],
    )

    return (
        <div data-testid={dataTestId}>
            {sortable && (
                <RowWrapper>
                    <ColorBackgroundWrapper
                        css={css`
                            background-color: ${COLOR_PALETTE.gray_1};
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        `}
                    >
                        <AutocompleteSelect
                            placeholder='Sort By'
                            options={sortByOptions.columns}
                            currentValues={''}
                            onSelectedValuesChange={onSortByChange}
                            selectedLabelTransformer={sortByOptions.mapSortLabel}
                            canFilter={false}
                        />
                    </ColorBackgroundWrapper>
                </RowWrapper>
            )}
            {dataSource &&
                dataSource.map((record, index) => {
                    const rowProps = onRow ? onRow(record, index) : {}

                    return (
                        <div
                            key={record[`${rowKey}`]}
                            data-testid={`${dataTestId}-item`}
                            css={css`
                                box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                                border: 1px solid ${COLOR_PALETTE.gray_2};
                                border-radius: 2px;
                                margin: 16px;
                                ${mobileItemStyles ? mobileItemStyles(record, theme) : ''}
                            `}
                            {...rowProps}
                        >
                            <RowWrapper
                                css={css`
                                    padding: 19px;
                                `}
                            >
                                {columns &&
                                    columns.map(column => {
                                        const dataIndex = extractDataIndex(column)
                                        const rowValue = dataIndex ? record[dataIndex] : ''
                                        const renderer = column?.render || null

                                        return (
                                            <div key={`k_${dataIndex}`}>
                                                {renderer ? (
                                                    <MobileRowWrapper>
                                                        <Label>
                                                            <>{column.title}</>
                                                        </Label>
                                                        <TextValue>
                                                            <>{renderer(rowValue, record, dataIndex)}</>
                                                        </TextValue>
                                                    </MobileRowWrapper>
                                                ) : (
                                                    <MobileRowWrapper>
                                                        <Label>
                                                            <>{column.title}</>
                                                        </Label>
                                                        <TextValue>{rowValue || '-'}</TextValue>
                                                    </MobileRowWrapper>
                                                )}
                                            </div>
                                        )
                                    })}
                            </RowWrapper>
                        </div>
                    )
                })}
        </div>
    )
}

const ResponsiveGrid: ResponsiveGridType = ({
    rowKey,
    dataSource,
    columns,
    onChange,
    pagination,
    rowClassName,
    mobileItemStyles,
    onRow,
    dataTestId,
    wrapperStyle,
    getLink,
    mobileBreakpoint = 1,
}) => {
    const isMobile = useIsMobile(mobileBreakpoint)

    useEffectOnce(() => {
        if (columns && getLink) {
            columns.forEach((column: any) => {
                const renderer = column?.render || null

                column.render = renderer
                    ? (value: ReactNode, record: any, index: number) => (
                          <Link
                              to={getLink(record)}
                              css={css`
                                  display: flex;
                                  height: calc(100% + 32px);
                                  align-items: center;
                                  margin: -16px;
                                  padding: 16px;
                              `}
                          >
                              {renderer(value, record, index)}
                          </Link>
                      )
                    : (value: ReactNode, record: any) => (
                          <Link
                              to={getLink(record)}
                              css={css`
                                  display: flex;
                                  height: calc(100% + 32px);
                                  align-items: center;
                                  margin: -16px;
                                  padding: 16px;
                              `}
                          >
                              {value}
                          </Link>
                      )
            })
        }
    })

    return isMobile ? (
        <MobileGrid
            rowKey={rowKey}
            dataSource={dataSource}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
            mobileItemStyles={mobileItemStyles}
            onRow={onRow}
            dataTestId={dataTestId}
        />
    ) : (
        <Fragment>
            <div
                css={css`
                    max-width: 1440px;
                    margin: 0px auto 16px;
                    ${wrapperStyle}
                `}
                data-testid={dataTestId}
            >
                <Table
                    onRow={onRow}
                    rowClassName={rowClassName}
                    rowKey={rowKey}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={pagination}
                    onChange={onChange}
                />
            </div>
        </Fragment>
    )
}

export { ResponsiveGrid }
