/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { DataNotFound } from '../../../components/DataNotFound'
import { Divider } from '../../../components/Divider'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { FiltersBar, FiltersFunctions } from '../../../components/opportunities/FiltersBar'
import { Pagination } from '../../../components/Pagination'
import { ScrollToTop } from '../../../components/ScrollUp'
import { SearchInput } from '../../../components/SearchInput'
import { SortEntryWithField } from '../../../contracts/common/sorting'
import { mqMax } from '../../../GlobalStyle'
import { Nullable } from '../../../types'
import { useDebouncedEffect } from '../../../utils/hooks'
import { useLogger } from '../../../utils/useLogger'
import { getOpenOpportunitiesFilters, searchOpenOpportunities } from '../../../api/open-opportunities/api'

import {
    OpenOpportunitiesFiltersResponse,
    OpenOpportunitiesParams,
    Opportunity,
    ResponseType,
} from '../../../contracts/open-opportunities/contracts'
import { DEFAULT_ITEMS_PER_PAGE } from '../../../components/open-opportunities/utils/openOpportunitiesUtils'
import {
    countFilters,
    menuItems,
    parseQueryToOpenOpportunitiesSearchRequest,
    stringifyOpenOpportunitiesSearchRequestToQuery,
} from '../../../components/open-opportunities/utils/openOpportunitiesUtils'
import { useCompany } from 'src/hooks/useCompany'
import { OpenOpportunitiesFilters, OpenOpportunitiesFiltersBar } from 'src/components/open-opportunities/OpenOpportunitiesFiltersBar'
import { OpenOpportunitiesSorting } from 'src/components/open-opportunities/OpenOpportunitiesSorting'
import { OpenOpportunityCard } from 'src/components/open-opportunities/OpenOpportunitiesCard'
import { getAppPath } from 'src/contracts/applications'

const OpenOpportunitiesList: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [totalOpportunities, setTotalOpportunities] = useState<Nullable<number>>(null)
    const [totalVacancies, setTotalVacancies] = useState<Nullable<number>>(null)
    const [opportunities, setOpportunities] = useState<Nullable<Array<Opportunity>>>(null)
    const [isFetchingOpportunities, setIsFetchingOpportunities] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [filtersOptions, setFiltersOptions] = useState<OpenOpportunitiesFiltersResponse>({
        countries: [],
        currencies: [],
        industries: [],
        locationTypes: [],
        maxMinExperienceInYears: null,
        minMinExperienceInYears: null,
        opportunityTypes: [],
        regions: [],
        skills: [],
        timeZones: [],
        visibilityValues: [],
    })

    const [sorting, setSorting] = useState<Array<SortEntryWithField>>([
        {
            field: 'datePosted',
            order: 'descend',
        },
    ])

    const logError = useLogger('error')
    const { addError } = useNotifications()

    const { company } = useCompany()

    const isAllowedToSMECloudApp = useMemo(() => company?.smeCloud || false, [company])

    const navigate = useNavigate()
    const { search } = useLocation()

    useEffect(() => {
        if (search) {
            setShowFilters(true)
        }
    }, [search])

    const searchRequest = useMemo<OpenOpportunitiesParams>(() => parseQueryToOpenOpportunitiesSearchRequest(search), [search])
    const searchFilters = useMemo((): OpenOpportunitiesFilters => {
        const { searchText: s, minRate: m, paging: p, sorting: so, ...onlyFilters } = searchRequest
        return onlyFilters
    }, [searchRequest])

    const [currentPage, setCurrentPage] = useState<number>(searchRequest.paging?.page || 1)
    const [pageSize, setPageSize] = useState<number>(searchRequest.paging?.size || DEFAULT_ITEMS_PER_PAGE)
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText)
    const [selectedFilters, setSelectedFilters] = useState<OpenOpportunitiesFilters>(searchFilters)

    const [minRateValue, setMinRateValue] = useState<string | undefined>(searchRequest.minRate?.toString())
    const [minRate, setMinRate] = useState<Nullable<number>>(searchRequest.minRate)
    const handleMinRate = useCallback((rate: any) => setMinRateValue(rate), [])
    useDebouncedEffect(
        () => {
            if (minRateValue !== undefined) {
                setMinRate(minRateValue ? +minRateValue : null)
                setCurrentPage(1)
            }
        },
        1000,
        [minRateValue],
    )

    useEffect(() => {
        const completeRequest = {
            searchText,
            minRate,
            paging: { page: currentPage, size: pageSize },
            sorting,
            ...selectedFilters,
        }
        const newSearchQuery = stringifyOpenOpportunitiesSearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [currentPage, navigate, searchText, minRate, selectedFilters, sorting, pageSize])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const onChangeFilters = useCallback((filters: any) => {
        setSelectedFilters(filters)
        setCurrentPage(1)
    }, [])

    const handleSearch = useCallback((text: any) => {
        setSearchText(text)
        setCurrentPage(1)
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        setPageSize(Number(size.toString()))
    }, [])

    useEffect(() => {
        if (totalOpportunities && totalOpportunities > 0 && opportunities?.length === 0 && currentPage > 1) {
            setCurrentPage(1)
        }
    }, [currentPage, opportunities?.length, totalOpportunities])

    const filtersCount = useMemo(() => {
        return countFilters({
            minRate,
            ...selectedFilters,
        })
    }, [minRate, selectedFilters])

    const onClearFilters = useCallback(() => {
        if (filtersCount > 0) {
            setMinRate(null)
            setMinRateValue(undefined)
            setSelectedFilters({
                countries: [],
                currency: '',
                industries: [],
                locationTypes: [],
                maxMinExperienceInYears: null,
                minMinExperienceInYears: null,
                projectEndDate: null,
                projectStartDate: null,
                regions: [],
                responseType: null,
                skills: [],
                timeZones: [],
                type: null,
                visibilityValues: [],
            })
        }
    }, [filtersCount])

    const loadFilters = useCallback(() => {
        getOpenOpportunitiesFilters().then(filters =>
            setFiltersOptions({
                countries: filters.countries,
                currencies: filters.currencies,
                industries: filters.industries,
                locationTypes: filters.locationTypes,
                maxMinExperienceInYears: filters.maxMinExperienceInYears,
                minMinExperienceInYears: filters.minMinExperienceInYears,
                opportunityTypes: filters.opportunityTypes,
                regions: filters.regions,
                skills: filters.skills,
                timeZones: filters.timeZones,
                visibilityValues: filters.visibilityValues,
            }),
        )
    }, [])

    const onSortingChange = useCallback((sortingField: string) => {
        setSorting([
            {
                field: sortingField,
                order: 'descend',
            },
        ])
    }, [])

    useEffect(() => {
        loadFilters()
    }, [loadFilters])

    const loadOpportunities = useCallback(() => {
        setIsFetchingOpportunities(true)
        searchOpenOpportunities({
            searchText,
            minRate,
            ...selectedFilters,
            responseType: selectedFilters.responseType || ResponseType.ALL_RESULTS,
            paging: {
                page: currentPage,
                size: pageSize,
            },
            sorting,
        })
            .then(data => {
                setIsFetchingOpportunities(false)
                setOpportunities(data.opportunities)
                setTotalOpportunities(data.total)
                setTotalVacancies(data.totalVacancies)
            })
            .catch(e => {
                setIsFetchingOpportunities(false)
                addError()
                logError(e)
            })
    }, [searchText, minRate, selectedFilters, currentPage, addError, logError, sorting, pageSize])

    useEffect(() => {
        loadOpportunities()
    }, [loadOpportunities, selectedFilters])

    const theme = useTheme()

    const searchWords = useMemo(() => (searchText ? searchText.split(' ') : []), [searchText])

    return (
        <DashboardLayout backgroundColor={theme.colors.gray_1} applicationName='Open Opportunities' applicationMenuItems={menuItems}>
            <ScrollToTop action={currentPage} />
            {!searchText && filtersCount === 0 && opportunities && totalOpportunities === 0 ? null : (
                <div
                    css={css`
                        padding: 18px 16px ${totalOpportunities === 0 && !isFetchingOpportunities ? '0' : '100px'};
                        max-width: 1440px;
                        margin: 0 auto;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            ${mqMax[1]} {
                                flex: none;
                            }
                            margin-bottom: 16px;
                        `}
                    >
                        <h4
                            css={css`
                                margin-bottom: 10px;
                            `}
                        >
                            Find Open Opportunities
                        </h4>
                    </div>
                    <SearchInput
                        initialText={searchText}
                        onSubmit={handleSearch}
                        filtersOpened={showFilters}
                        onToggleFilters={onToggleFilters}
                        placeholder='Roles, skills, location, etc.'
                        styles={css`
                            margin-bottom: 16px;
                        `}
                        dataTestId='open-opportunities-search'
                    />
                    {showFilters && filtersOptions && (
                        <FiltersBar<OpenOpportunitiesFilters> selectedFilters={selectedFilters} onChangeFilters={onChangeFilters}>
                            {({
                                onSelectFilterChange,
                                onLocationChange,
                                onValueChange,
                                onDatePickerChange,
                                onRangeFilterChange,
                            }: FiltersFunctions) => (
                                <OpenOpportunitiesFiltersBar
                                    opened={showFilters}
                                    filtersOptions={filtersOptions}
                                    selectedFilters={selectedFilters}
                                    onClearAll={onClearFilters}
                                    minRateValue={minRateValue}
                                    handleMinRate={handleMinRate}
                                    filtersCount={filtersCount}
                                    onSelectFilterChange={onSelectFilterChange}
                                    onLocationChange={onLocationChange}
                                    onValueChange={onValueChange}
                                    onDatePickerChange={onDatePickerChange}
                                    onRangeFilterChange={onRangeFilterChange}
                                    isAllowedToSMECloudApp={isAllowedToSMECloudApp}
                                />
                            )}
                        </FiltersBar>
                    )}
                    <Divider />
                    <div
                        css={css`
                            display: flex;
                        `}
                    >
                        <span
                            css={css`
                                margin-bottom: 17px;
                                display: block;
                            `}
                            data-testid='open-opportunities-total'
                        >
                            {totalVacancies} Vacanc{totalVacancies === 1 ? 'y' : 'ies'} found in {totalOpportunities} Opportunit
                            {totalOpportunities === 1 ? 'y' : 'ies'}
                        </span>
                        <OpenOpportunitiesSorting sorting={sorting[0].field} onChange={onSortingChange} />
                    </div>
                    {opportunities && (
                        <div
                            css={css`
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 16px;
                                margin-bottom: 40px;

                                ${mqMax[1]} {
                                    grid-template-columns: 1fr;
                                }
                            `}
                        >
                            {opportunities.map(opportunity => (
                                <OpenOpportunityCard
                                    key={opportunity.opportunityId}
                                    opportunity={opportunity}
                                    searchWords={searchWords}
                                    appPath={getAppPath('OPEN_OPPORTUNITIES')}
                                />
                            ))}
                        </div>
                    )}
                    {totalOpportunities !== null && totalOpportunities > 0 && (
                        <Pagination
                            pageSize={pageSize}
                            total={totalOpportunities}
                            onChange={setCurrentPage}
                            current={currentPage}
                            defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                            onChangePageSize={onChangePageSize}
                            css={css`
                                ${mqMax[0]} {
                                    display: flex;
                                    justify-content: center;
                                }
                            `}
                        />
                    )}
                </div>
            )}
            {totalOpportunities === 0 && !isFetchingOpportunities && (
                <DataNotFound
                    styles={css`
                        margin-top: ${searchText || filtersCount > 0 ? '50px' : '150px'};
                    `}
                    iconName={searchText || filtersCount > 0 ? 'no-data' : 'add-empty'}
                    iconSize={60}
                    title={searchText || filtersCount > 0 ? 'No Opportunities found' : 'No Opportunities Added'}
                    description={
                        searchText || filtersCount > 0
                            ? 'Try changing your filters or query'
                            : 'There are no open opportunities at the moment.'
                    }
                ></DataNotFound>
            )}
            {isFetchingOpportunities && <OverlayContentLoader />}
        </DashboardLayout>
    )
}

export { OpenOpportunitiesList }
