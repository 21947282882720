import useBoundingclientrect from '@rooks/use-boundingclientrect'
import * as d3 from 'd3'
import { FunctionComponent, MutableRefObject, useEffect, useRef, useState } from 'react'
import { BreadCrumbsProps, Node } from './types'
import { breadcrumbPoints, breadcrumbsSize, calcBreadcrumbPosition, calculateTextWidth } from './utils'

type UseBoundingclientrectType = (ref: MutableRefObject<HTMLElement | SVGSVGElement | null>) => ClientRect | DOMRect | null

const BreadCrumbs: FunctionComponent<React.PropsWithChildren<BreadCrumbsProps>> = ({ breadCrumbsData, nodeColor, id }) => {
    const breadcrumbs = useRef<SVGSVGElement>(null)
    const breadcrumbsBoundingClientRect = (useBoundingclientrect as UseBoundingclientrectType)(breadcrumbs)
    const [wrapperHeight, setWrapperHeight] = useState(0)
    const textWidths = breadCrumbsData.nodeArray.map(x => calculateTextWidth(x.data.name))
    const breadcrumbsPosition = calcBreadcrumbPosition({
        textWidths,
        maxWidth: breadcrumbsBoundingClientRect?.width || Infinity,
    })

    useEffect(() => {
        const resetWrapperHeight = () => setWrapperHeight(0)
        window.addEventListener('resize', resetWrapperHeight)
        return () => {
            window.removeEventListener('resize', resetWrapperHeight)
        }
    }, [])

    useEffect(() => {
        const lastIndex = breadcrumbsPosition.length - 1
        setWrapperHeight(Math.max(wrapperHeight, breadcrumbsPosition[lastIndex] ? breadcrumbsPosition[lastIndex][1] : 0))
    }, [setWrapperHeight, wrapperHeight, breadcrumbsPosition])

    useEffect(() => {
        const g = d3
            .select(breadcrumbs.current)
            .selectAll<SVGGElement, Node>('g')
            .data<Node>(breadCrumbsData.nodeArray, x => breadCrumbsData.percentageString + x.data.name + x.depth)

        const entering = g.enter().append('svg:g')

        entering.append('svg:polygon').attr('points', breadcrumbPoints).attr('fill', nodeColor)

        entering
            .append('svg:text')
            .attr('x', x => (calculateTextWidth(x.data.name) + breadcrumbsSize.arrowAngleHeight) / 2)
            .attr('y', breadcrumbsSize.height / 2)
            .attr('dy', '0.35em')
            .attr('text-anchor', 'middle')
            .text(x => x.data.name || '')

        entering.attr('transform', (_, i) => `translate(${breadcrumbsPosition[i][0]}, ${breadcrumbsPosition[i][1]})`)

        g.exit().remove()
        d3.select(breadcrumbs.current).style('visibility', '')
    }, [breadCrumbsData, nodeColor, breadcrumbsPosition])

    return (
        <svg
            ref={breadcrumbs}
            width='100%'
            height={wrapperHeight + breadcrumbsSize.height + breadcrumbsSize.space + 20}
            id={`${id}-trail`}
        ></svg>
    )
}

export { BreadCrumbs }
