/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getSpecialist } from '../../api/api'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { StepsIndicator } from '../../components/steps-indicator/StepsIndicator'
import { StepStatus, StepType } from '../../components/steps-indicator/types'
import { Specialist } from '../../contracts/specialist/specialist'
import { mqMax } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { EducationLanguagesSection } from './specialist-profile/EducationLanguagesSection'
import { EducationQualificationsSection } from './specialist-profile/EducationQualificationsSection'
import { Projects } from './specialist-profile/Projects'
import { Summary } from './Summary'

const BasicInfo = () => {
    const query = useQuery()
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const log = useLogger('error')
    const skipExperience = query.get('skipExperience') !== null

    const [specialist, setSpecialist] = useState<Nullable<Specialist>>(null)
    const [steps, setSteps] = useState<Array<StepType>>([
        {
            name: 'Experience',
            status: skipExperience ? StepStatus.DONE : StepStatus.ACTIVE,
        },
        { name: 'Education', status: skipExperience ? StepStatus.ACTIVE : StepStatus.PENDING },
    ])

    const activeStepIndex = useMemo(() => steps.findIndex(step => step.status === StepStatus.ACTIVE), [steps])

    const onNext = useCallback(() => {
        setSteps(steps =>
            steps.map((step, index) => {
                let status

                if (index === activeStepIndex) {
                    status = StepStatus.DONE
                } else if (index === activeStepIndex + 1) {
                    status = StepStatus.ACTIVE
                } else {
                    status = step.status
                }

                return { ...step, status }
            }),
        )
    }, [activeStepIndex])

    const onSave = useCallback(() => {
        history.push('/specialist-dashboard')
    }, [])

    const onBack = useCallback(() => {
        setSteps(steps =>
            steps.map((step, index) => {
                let status

                if (index === activeStepIndex) {
                    status = StepStatus.PENDING
                } else if (index === activeStepIndex - 1) {
                    status = StepStatus.ACTIVE
                } else {
                    status = step.status
                }

                return { ...step, status }
            }),
        )
    }, [activeStepIndex])

    useEffect(() => {
        if (decodedAccessToken.specialistId) {
            getSpecialist(decodedAccessToken.specialistId).then(setSpecialist).catch(log)
        }
    }, [decodedAccessToken, log])

    return (
        <DashboardLayout applicationName='My Dashboard'>
            <div
                css={css`
                    max-width: 858px;
                    width: 100%;
                    margin: 0 auto;
                    padding: 24px;
                    ${mqMax[1]} {
                        margin-top: 57px;
                    }
                `}
            >
                <FlexBox
                    alignItems='center'
                    justifyContent='space-between'
                    css={css`
                        ${mqMax[1]} {
                            flex-direction: column;
                            text-align: center;
                        }
                    `}
                >
                    <div>
                        <BackLink
                            style={css`
                                margin: 0 0 24px;
                            `}
                            text='Back to dashboard'
                            path={`/specialist-dashboard`}
                        />
                        <h3
                            css={css`
                                margin-bottom: 0;
                            `}
                        >
                            {specialist?.firstName} {specialist?.lastName}
                        </h3>
                    </div>
                </FlexBox>
                <StepsIndicator
                    styles={css`
                        margin-top: 24px;
                    `}
                    steps={steps}
                />
                <Summary />
                {specialist && activeStepIndex === 0 && <Projects header='Experience' specialistId={specialist.id} />}
                {specialist && activeStepIndex === 1 && (
                    <Fragment>
                        <EducationQualificationsSection specialistId={specialist.id} />
                        <EducationLanguagesSection specialistId={specialist.id} />
                    </Fragment>
                )}
                <Divider />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    {activeStepIndex === 0 && (
                        <Button dataTestId='basic-info-next' onClick={onNext}>
                            Next
                        </Button>
                    )}
                    {activeStepIndex === 1 && (
                        <Fragment>
                            <Button variant='tertiary' onClick={onBack}>
                                Previous
                            </Button>
                            <Button
                                style={css`
                                    margin-left: 20px;
                                `}
                                onClick={onSave}
                                dataTestId='save-changes'
                            >
                                Save Changes
                            </Button>
                        </Fragment>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}

export { BasicInfo }
