export type FeedbackQuestionType = 'MP' | 'PH' | 'MS' | 'PP' | 'TSA'

export type FeedbackQuestion = {
    feedbackCode: FeedbackQuestionType
    feedbackName: 'My Performance' | 'Project Healthcheck' | 'My Satisfaction' | 'Peer Performance'
    question: string
    answers: Array<{
        answerId: string
        answer: string
        isSelected?: boolean
    }>
    teamId?: string
    assessedSpecialistId?: string
    assessedSpecialistLabel?: string
}

export type PeerSkills = {
    assessedSpecialistId: string
    assessedSpecialistLabel: string
    assessedSpecialistSkills: Array<PeerSkillsGroup>
    feedbackName: string
    teamId: string
}

export enum SkillRate {
    EVALUATOR = 'EVALUATOR',
    EQUAL = 'EQUAL',
    SPECIALIST = 'SPECIALIST',
}

export type PeerSkillAnswer = {
    rate: SkillRate | undefined
    skill: string
}

export type PeerSkillsGroup = {
    category: string
    skills: Array<string> | Array<PeerSkillAnswer>
}
