/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useRef } from 'react'
import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { SuggestionInput, SuggestionInputProps } from '../inputs/SuggestionInput'
import { ControlledFieldProps } from './types'

type ControlledSuggestionInputProps = Omit<SuggestionInputProps, 'onBlur' | 'onChange' | 'value' | 'errorMessage'> &
    ControlledFieldProps<string> & {
        beforeBlur?: () => void
        focus?: boolean
        clearFocus?: () => void
    }

const ControlledSuggestionInput: FunctionComponent<React.PropsWithChildren<ControlledSuggestionInputProps>> = ({
    name,
    placeholder,
    label,
    disabled,
    fetchOptions,
    onFocus,
    infoMessage,
    labelTooltip,
    allowOnlyValuesFromDictionary,
    fetchFromLetterNumber,
    dataTestId,
    styles,
    control,
    defaultValue,
    beforeBlur,
    focus,
    clearFocus,
}) => {
    const {
        field: { onBlur, onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    const handleBlur = useCallback(() => {
        if (beforeBlur) {
            beforeBlur()
        }
        onBlur()
    }, [beforeBlur, onBlur])

    const ref = useRef<HTMLInputElement>(null)
    useEffect(() => {
        if (focus && clearFocus) {
            ref.current?.focus()
            clearFocus()
        }
    }, [focus, ref, clearFocus])

    return (
        <SuggestionInput
            onChange={onChange}
            onBlur={handleBlur}
            onFocus={onFocus}
            infoMessage={infoMessage}
            name={name}
            placeholder={placeholder}
            label={label}
            labelTooltip={labelTooltip}
            disabled={disabled}
            value={value}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            fetchOptions={fetchOptions}
            allowOnlyValuesFromDictionary={allowOnlyValuesFromDictionary}
            fetchFromLetterNumber={fetchFromLetterNumber}
            dataTestId={dataTestId}
            styles={styles}
            forwardedRef={focus !== undefined ? ref : undefined}
        />
    )
}

export { ControlledSuggestionInput }
