import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { DataNotFound, DataNotFoundProps } from '../../../components/DataNotFound'

type SectionDataNotFoundProps = Partial<DataNotFoundProps>

const SectionDataNotFound: FunctionComponent<React.PropsWithChildren<SectionDataNotFoundProps>> = ({
    title = 'No Data Added',
    iconName = 'no-data',
    description,
    iconSize,
    children,
}) => {
    return (
        <DataNotFound
            title={title}
            description={description}
            iconName={iconName}
            iconSize={iconSize}
            styles={css`
                margin-top: 12px;
            `}
        >
            {children}
        </DataNotFound>
    )
}

const SectionWrapper = styled.div`
    margin-top: 40px;
    margin-bottom: 48px;
`

export { SectionDataNotFound, SectionWrapper }
