import { Nullable } from '../../../../types'
import { Application } from '../../../../contracts/open-opportunities/contracts'

export type Action = { type: string; payload?: any }

export enum CurrentStep {
    COMPLETE_INFORMATION = 'COMPLETE_INFORMATION',
    RATE_SKILLS = 'RATE_SKILLS',
    REVIEW_AND_APPLY = 'REVIEW_AND_APPLY',
}

export type ApplicationData = Omit<Application, 'statusHistory' | 'opportunityId'> & {
    currentStep: CurrentStep
    isSendingCv: boolean
    isUpdatingCv: boolean
    isNotShared?: boolean
    isRelatedSpecialistFetched?: boolean
}

export type State = {
    applications: Array<ApplicationData>
    selectedApplicationId: Nullable<string>
}
