import { StringMapping, Nullable } from 'src/types'

export enum ProcessingCvStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    ERROR = 'ERROR',
    PARSING_FINISHED = 'PARSING_FINISHED',
}

export enum SpecialistCvStatus {
    WAITING_FOR_CV = 'WAITING_FOR_CV',
    IN_PROGRESS = 'IN_PROGRESS',
    ERROR = 'ERROR',
    READY_FOR_VERIFICATION = 'READY_FOR_VERIFICATION',
}
export interface ProcessingCv {
    errorMessage: string
    fileName: string
    id: string
    status: ProcessingCvStatus
    uploadedOn: number
}

export enum CvSourceType {
    LINKEDIN = 'LINKEDIN',
    OTHER = 'OTHER',
}
export interface ParsedCv {
    cvDownloadUrl: string
    cvFileName: string
    emails: Array<string>
    id: string
    names: Array<{
        firstName: ''
        lastName: ''
    }>
    skills: Array<string>
    previewFiles: Array<string>
    roleToTopSkills: {
        [role: string]: Array<string>
    }
    roles: Array<string>
}

export interface SpecialistCv {
    cvParsingId: string
    cvSource: CvSourceType
    errorMessage: string
    fileName: string
    cvDownloadUrl: string
    status: SpecialistCvStatus
    uploadedOn: number
}

export interface SpecialistParsedSkillsResponse {
    cvParsingId: string
    skills: Array<string>
}

export enum SkillProficiency {
    BASIC = 'BASIC',
    EXPERT = 'EXPERT',
    GOOD = 'GOOD',
    NOT_RATED = 'NOT_RATED',
}

export interface RatedSkill {
    name: string
    proficiency: SkillProficiency
}

export const SkillProficiencyMapping: StringMapping = {
    [SkillProficiency.BASIC]: 'Basic',
    [SkillProficiency.GOOD]: 'Good',
    [SkillProficiency.EXPERT]: 'Expert',
    [SkillProficiency.NOT_RATED]: 'Not rated',
}

export interface SpecialistParsedCv {
    cvDownloadUrl: string
    fileName: string
    uploadedOn: number
}

export interface AnonymizedCVInfo {
    anonymizedCvUploadedOn: Nullable<number>
}
