/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { ApplicationStatus, ApplicationStatusMapping } from '../../contracts/opportunities-manager/contracts'
import { useApplicationStatusCellColors } from './utils/useApplicationStatusCellColors'

interface ApplicationStatusCellProps {
    status: ApplicationStatus
    showMsaRequired?: boolean
}

const ApplicationStatusCell: FunctionComponent<React.PropsWithChildren<ApplicationStatusCellProps>> = ({ status, showMsaRequired }) => {
    const colors = useApplicationStatusCellColors()
    const theme = useTheme()

    return (
        <div
            css={css`
                display: inline-block;
                padding: 4px;
                font-size: 11px;
                line-height: 14px;
                border-radius: 2px;
                color: ${showMsaRequired ? theme.colors.red_4 : colors[status.status].color};
                background-color: ${showMsaRequired ? theme.colors.red_2 : colors[status.status].backgroundColor};
            `}
            data-testid='application-status'
        >
            {showMsaRequired ? 'MSA required' : ApplicationStatusMapping[status.status]}
        </div>
    )
}

export { ApplicationStatusCell }
