import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../components/DefaultLayout'

const PageNotFound: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const navigate = useNavigate()

    const handleGoBack = useCallback((): void => {
        navigate('/')
    }, [navigate])

    return (
        <DefaultLayout
            icon='smiley-drop-circle'
            iconSize={60}
            title='404'
            description='Sorry, something went wrong on our end but we’re working to fix the problem. You can go back to the dashboard and try to access this page again, or wait a little while and try again later.'
            buttonText='Go back'
            onButtonClick={handleGoBack}
            buttonDataTestId='go-to-dashboard'
        />
    )
}

export { PageNotFound }
