/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { Control, UseFormClearErrors, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { getCities } from '../../../../api/api'
import { Divider } from '../../../Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../../../forms/ControlledAutocompleteSelect'
import { ControlledCheckbox } from '../../../forms/ControlledCheckbox'
import { ControlledRadioGroup } from '../../../forms/ControlledRadioGroup'
import { ControlledTextarea } from '../../../forms/ControlledTextarea'
import { OptionObject } from '../../../inputs/autocomplete-select/Option'
import { FieldWrapper } from '../../../layout/FormHelpers'
import { useCountries, useRegions, useTimezones } from '../../../../redux/dictionaryDataHooks'
import { Box } from '../../Box'
import {
    CountriesAndRegionsGroupsEnum,
    OpportunityLocationTypeEnum,
    OpportunityLocationTypeV2Enum,
    UpdateOpportunityFormData,
} from '../../../../contracts/opportunities-manager/contracts'

type LocationDetailsProps = {
    control: Control<UpdateOpportunityFormData>
    watch: UseFormWatch<UpdateOpportunityFormData>
    setValue: UseFormSetValue<UpdateOpportunityFormData>
    getValues: UseFormGetValues<UpdateOpportunityFormData>
    clearErrors: UseFormClearErrors<UpdateOpportunityFormData>
}

const LocationDetails: FunctionComponent<React.PropsWithChildren<LocationDetailsProps>> = ({
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
}) => {
    const countries = useCountries()
    const regions = useRegions()
    const timezones = useTimezones()
    const memorizedCountriesAndRegions = useMemo(
        () =>
            regions
                ?.map(region => ({ value: region, groupName: CountriesAndRegionsGroupsEnum.REGIONS }))
                .concat(countries?.map(c => ({ value: c.name, groupName: CountriesAndRegionsGroupsEnum.COUNTRIES })) || []) || [],
        [countries, regions],
    )

    const [locationType, locationTypeV2, countriesAndRegions, otherLocationRequirements] = watch([
        'location.locationType',
        'location.locationTypeV2',
        'location.countriesAndRegions',
        'location.otherLocationRequirements',
        // Due to react 18 `StrictMode`, conditionally rendered fields can have its values set to `undefined`,
        // even if default value is specified.
        // Possibly related, https://github.com/react-hook-form/react-hook-form/discussions/6825
    ]) as [OpportunityLocationTypeEnum, OpportunityLocationTypeV2Enum, OptionObject[] | undefined, boolean]

    const [citiesList, setCitiesList] = useState<Array<OptionObject>>([])

    useEffect(() => {
        if (countriesAndRegions) {
            Promise.all(countriesAndRegions.map(option => getCities((option as OptionObject).value, ''))).then(cities => {
                const arrayOfOptions = countriesAndRegions as Array<OptionObject>
                setCitiesList(
                    arrayOfOptions
                        .reduce<Array<OptionObject>>(
                            (result, country, index) =>
                                result.concat(cities[index].map(city => ({ value: city, groupName: country.value }))),
                            [],
                        )
                        .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0)),
                )
            })
        }
    }, [countriesAndRegions])

    const isAnyRegionSelected = useMemo(
        () =>
            countriesAndRegions?.length &&
            countriesAndRegions.some(countryOrRegion => (countryOrRegion as OptionObject).groupName === 'Regions'),
        [countriesAndRegions],
    )

    useEffect(() => {
        if (isAnyRegionSelected) {
            setValue('location.countryCitiesList', [])
            clearErrors('location.countryCitiesList')
            setValue('location.locationTypeV2', OpportunityLocationTypeV2Enum.REMOTE)
        }
    }, [clearErrors, isAnyRegionSelected, setValue])

    useEffect(() => {
        const citiesField = getValues('location.countryCitiesList')
        const cities = citiesField?.filter(city =>
            countriesAndRegions
                ? countriesAndRegions
                      .map(selectedCountries => (selectedCountries as OptionObject).value)
                      .includes((city as OptionObject).groupName)
                : true,
        )
        setValue('location.countryCitiesList', cities)
    }, [countriesAndRegions, getValues, setValue])

    useEffect(() => {
        if (locationType === OpportunityLocationTypeEnum.ANYWHERE) {
            setValue('location.countriesAndRegions', [])
            clearErrors('location.countriesAndRegions')
            setValue('location.locationTypeV2', OpportunityLocationTypeV2Enum.REMOTE)
            clearErrors('location.locationTypeV2')
            setValue('location.countryCitiesList', [])
            clearErrors('location.countryCitiesList')
        }
    }, [locationType, setValue, clearErrors])

    useEffect(() => {
        if (locationTypeV2 === OpportunityLocationTypeV2Enum.REMOTE) {
            setValue('location.countryCitiesList', [])
        }
    }, [clearErrors, setValue, locationTypeV2])

    return (
        <Box>
            <h6>Location Details</h6>
            <FieldWrapper
                css={css`
                    min-height: auto;
                    margin-bottom: 20px;
                `}
            >
                <ControlledRadioGroup
                    control={control}
                    name='location.locationType'
                    label='Open for applications from'
                    options={[
                        {
                            label: 'Anywhere ',
                            value: OpportunityLocationTypeEnum.ANYWHERE,
                        },
                        {
                            label: 'Specific Location',
                            value: OpportunityLocationTypeEnum.SPECIFIC_LOCATION,
                        },
                    ]}
                />
            </FieldWrapper>
            {locationType === OpportunityLocationTypeEnum.SPECIFIC_LOCATION && (
                <Fragment>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            label='Country/Region'
                            placeholder='Choose country or region'
                            options={memorizedCountriesAndRegions}
                            control={control}
                            name='location.countriesAndRegions'
                            dropdownWidth='100%'
                            canClear
                            multiple
                            selectedLabelTransformer={(option: any) => option.value}
                        />
                    </FieldWrapper>
                    {countriesAndRegions && countriesAndRegions.length > 0 && !isAnyRegionSelected && (
                        <FieldWrapper
                            css={css`
                                min-height: auto;
                                margin-bottom: 20px;
                            `}
                        >
                            <ControlledRadioGroup
                                control={control}
                                name='location.locationTypeV2'
                                options={[
                                    {
                                        label: 'Fully Remote',
                                        value: OpportunityLocationTypeV2Enum.REMOTE,
                                    },
                                    {
                                        label: 'Hybrid',
                                        value: OpportunityLocationTypeV2Enum.HYBRID,
                                    },
                                    {
                                        label: 'On Site',
                                        value: OpportunityLocationTypeV2Enum.ON_SITE,
                                    },
                                ]}
                            />
                        </FieldWrapper>
                    )}
                    {locationTypeV2 !== OpportunityLocationTypeV2Enum.REMOTE && (
                        <FieldWrapper>
                            <ControlledAutocompleteSelect
                                label='Cities'
                                placeholder='Choose Cities'
                                options={citiesList}
                                control={control}
                                name='location.countryCitiesList'
                                dropdownWidth='100%'
                                canClear
                                multiple
                                selectedLabelTransformer={(option: any) => option.value}
                                virtualized
                            />
                        </FieldWrapper>
                    )}
                </Fragment>
            )}
            <Divider />
            <FieldWrapper
                css={css`
                    min-height: auto;
                    margin-bottom: 20px;
                `}
            >
                <ControlledCheckbox
                    checkboxLabel='Other Location Requirements'
                    control={control}
                    name='location.otherLocationRequirements'
                />
            </FieldWrapper>

            {otherLocationRequirements && (
                <Fragment>
                    <FieldWrapper>
                        <ControlledAutocompleteSelect
                            canFilter={false}
                            label='Working timezone (optional)'
                            placeholder='Choose Time Zone'
                            options={timezones}
                            control={control}
                            name='location.timeZone'
                            dropdownWidth='100%'
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    </FieldWrapper>

                    <FieldWrapper>
                        <ControlledTextarea
                            label='Other (optional)'
                            name='location.otherLocationRequirementsText'
                            control={control}
                            placeholder='Relocation, travelling requirements etc.'
                        />
                    </FieldWrapper>
                </Fragment>
            )}
        </Box>
    )
}

export { LocationDetails }
