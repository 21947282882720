/** @jsxImportSource @emotion/react */

import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getShareProfileLinkIds, getSpecialistProfile } from '../../../api/api'
import { DashboardLayout } from '../../../components/layout/dashboard/DashboardLayout'
import { Loader } from '../../../components/layout/Loader'
import { Profile } from '../../../contracts/profile/profile'
import { useUserContext } from '../../../hooks/useUserContext'
import { Nullable } from '../../../types'
import { applicationName, useMenuItems } from '../../talent-marketplace/talentMarketplaceApp'
import { SpecialistProfile } from '../shared/SpecialistProfile'

const InternalProfile = () => {
    const { activeContext } = useUserContext()

    const [loading, setLoading] = useState(true)
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const [shareLinkIds, setShareLinkIds] = useState<{ rateVisibleLinkId: string; rateHiddenLinkId: string }>()
    const [refetchProfile, setRefetchProfile] = useState(false)
    const [hasCompanyAccount, setHasCompanyAccount] = useState<boolean>(false)
    const navigate = useNavigate()
    const { specialistId } = useParams<{ specialistId: string }>()

    const menuItems = useMenuItems()

    const fetchSpecialistProfile: () => void = useCallback(() => {
        if (specialistId) {
            setLoading(true)

            getSpecialistProfile(specialistId)
                .then((specialistProfile: any) => {
                    setProfile(specialistProfile)
                })
                .catch((error: any) => {
                    if (error?.status === 404) {
                        navigate('/profile-not-found')
                    }
                })
                .finally(() => {
                    setLoading(false)
                })

            getShareProfileLinkIds(specialistId).then(data => {
                setShareLinkIds(data)
            })
        }
    }, [specialistId, navigate])

    useEffect(() => {
        if (activeContext?.companyId) {
            setHasCompanyAccount(true)
        }
    }, [activeContext])

    useEffect(() => {
        fetchSpecialistProfile()
    }, [fetchSpecialistProfile, specialistId])

    useEffect(() => {
        if (refetchProfile) {
            fetchSpecialistProfile()
            setRefetchProfile(false)
        }
    }, [fetchSpecialistProfile, refetchProfile])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            {loading && <Loader />}
            {profile && (
                <Fragment>
                    <SpecialistProfile
                        specialistProfile={profile}
                        shareProfileLinkIds={shareLinkIds}
                        setRefetchProfile={setRefetchProfile}
                        setLoading={setLoading}
                        hasCompanyAccount={hasCompanyAccount}
                    />
                </Fragment>
            )}
        </DashboardLayout>
    )
}

export { InternalProfile }
