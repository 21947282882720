import { FunctionComponent, useCallback, useContext } from 'react'

import { useNotifications } from '../../components/notification/NotificationProvider'
import { getAppPath } from '../../contracts/applications'
import { CompanyMembersResponse } from '../../contracts/companyMembers'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { OpportunityFormPage } from '../../components/opportunities-manager/OpportunityForm/OpportunityFormPage'
import { parseFormData } from '../../components/opportunities-manager/OpportunityForm/utils/parseFormData'
import { addOpportunity } from '../../api/opportunities-manager/api'
import { UpdateOpportunityFormData } from '../../contracts/opportunities-manager/contracts'

const AddOpportunityPageSme: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const query = useQuery()

    const isSmeCloudApp = true

    const appPath = getAppPath('OPPORTUNITIES_MANAGER_SME')

    const onSubmit = useCallback(
        (formData: UpdateOpportunityFormData, companyMembers: Nullable<CompanyMembersResponse>, hideLoaderOnSuccess = true) => {
            layoutToggleLoader(true)
            return addOpportunity(parseFormData(formData, companyMembers, isSmeCloudApp))
                .then(newOpportunity => {
                    if (hideLoaderOnSuccess) {
                        addSuccess('You successfully saved this Opportunity')
                        layoutToggleLoader(false)
                        history.push(decodeURIComponent(query.get('prevPath') || appPath))
                    }
                    return newOpportunity
                })
                .catch(err => {
                    log(err)
                    addError()
                    layoutToggleLoader(false)
                })
        },
        [addError, addSuccess, appPath, isSmeCloudApp, layoutToggleLoader, log, query],
    )

    return <OpportunityFormPage onSubmit={onSubmit} isSmeCloudApp={isSmeCloudApp} isVMCCloudApp={false} />
}

export { AddOpportunityPageSme }
