import { VMCInvitationAdditionalDataResponse, VMCInvitationsResponse, VMCUsersResponse, VMCstatusEnum } from 'src/contracts/vmc'
import { axios } from '../../api/axios'
import { UserSegmentationCompany } from '../../contracts/company'
import { UserContext } from '../../contracts/contexts'
import { JoinSyndicateRequest, Syndicate, SyndicateContactPersonData } from '../../contracts/syndicates'
import { WhiteLabel } from '../../contracts/whiteLabel'
import {
    EmailSubscription,
    InvitationsSearchResponse,
    LegacyEmailSubscription,
    RolesSearchResponse,
    SearchRequest,
    UserSegmentationRequestData,
    WhiteLabelUpdateRequest,
} from './contracts'

const companyInvitationsEndpoint = '/companyInvitations'

const checkInvitationStatus = (prospectId: string): Promise<{ whiteLabel: WhiteLabel }> => {
    return axios.get(`${companyInvitationsEndpoint}/${prospectId}`)
}

const confirmInvitation = (prospectId: string): Promise<UserContext> => {
    return axios.post(`${companyInvitationsEndpoint}/${prospectId}:confirmInvitation`)
}

const rejectInvitation = (prospectId: string): Promise<UserContext> => {
    return axios.post(`${companyInvitationsEndpoint}/${prospectId}:reject`)
}

const resendCompanyInvitation = (prospectId: string): Promise<UserContext> => {
    return axios.post(`${companyInvitationsEndpoint}/${prospectId}:resend`)
}

const cancelCompanyInvitation = (prospectId: string): Promise<UserContext> => {
    return axios.post(`${companyInvitationsEndpoint}/${prospectId}:cancel`)
}

const inviteMembersToCompany = (emails: Array<string>): Promise<void> => {
    return axios.post(`${companyInvitationsEndpoint}:inviteMembersToCompany`, { emails })
}

const companyUsersSearch = (rolesSearch: SearchRequest): Promise<RolesSearchResponse> => {
    return axios.post(`/currentCompany/users:search`, rolesSearch)
}

const removeUserAccess = (userId: string): Promise<void> => {
    return axios.delete(`currentCompany/users/${userId}`)
}

const companyInvitationsSearch = (invitationSearch: SearchRequest): Promise<InvitationsSearchResponse> => {
    return axios.post(`${companyInvitationsEndpoint}:search`, invitationSearch)
}

const companiesEndpoint = 'companies'
const companiesEndpointV4 = 'v4/companies'

const sendCompanyEmailConfirmation = (companyId: string): Promise<void> => {
    return axios.post(`${companiesEndpoint}/${companyId}:sendConfirmationEmail`)
}

const editUserSegmentationCompany = (companyId: string, data: UserSegmentationRequestData): Promise<UserSegmentationCompany> => {
    return axios.put(`${companiesEndpointV4}/${companyId}`, data)
}

const addUserSegmentationCompany = (data: UserSegmentationRequestData, isVmcRegistration = false): Promise<string> => {
    return axios.post(`${companiesEndpointV4}?isVmcRegistration=${isVmcRegistration}`, data)
}

const removeInactiveCompany = (companyId: string): Promise<void> => {
    return axios.delete(`${companiesEndpoint}/${companyId}:notVerified`)
}

const settingsEndpoint = `/settings`

const updateWhiteLabel = (whiteLabel: WhiteLabelUpdateRequest): Promise<WhiteLabel> => {
    return axios.put(`${settingsEndpoint}:whiteLabel`, whiteLabel)
}

const updateLogo = (data: FormData) => {
    return axios.put(`${settingsEndpoint}:logo`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const updateDarkModeLogo = (data: FormData) => {
    return axios.put(`${settingsEndpoint}:darkModeLogo`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

// SYNDICATES

const syndicatesEndpoint = '/syndicates'

const getSyndicate = (): Promise<Syndicate> => {
    return axios.get(syndicatesEndpoint)
}
const joinSyndicate = (data: JoinSyndicateRequest): Promise<Syndicate> => {
    return axios.post(syndicatesEndpoint, data)
}
const updateSyndicateContactPersonData = (data: SyndicateContactPersonData): Promise<Syndicate> => {
    return axios.put(syndicatesEndpoint, data)
}
const getIsSyndicateMember = (): Promise<boolean> => {
    return axios.get(`${syndicatesEndpoint}:isMember`)
}
const getSyndicateName = (code: string): Promise<string> => {
    return axios.get(`${syndicatesEndpoint}:name?syndicateActivationCode=${code}`)
}

const subscriptionsEndpoint = '/subscription/my'

const getSubscriptions = (): Promise<{ subscriptions: Array<LegacyEmailSubscription> }> => {
    return axios.get(subscriptionsEndpoint)
}

const getSubscriptionsV2 = (): Promise<{ subscriptions: Array<EmailSubscription> }> => {
    return axios.get(`v2/${subscriptionsEndpoint}`)
}

const saveSubscriptions = (subscriptions: Array<LegacyEmailSubscription>): Promise<{ subscriptions: Array<LegacyEmailSubscription> }> => {
    return axios.post(subscriptionsEndpoint, { subscriptions })
}

const saveSubscriptionsV2 = (
    subscriptions: Array<{ id: string; notify: 'SUBSCRIBE' | 'UNSUBSCRIBE' }>,
): Promise<{ subscriptions: Array<EmailSubscription> }> => {
    return axios.post(`v2/${subscriptionsEndpoint}`, { subscriptions })
}

// VMC management

const vendorsEndpoint = '/vmcInvitations'

const inviteVendorsToCompany = (emails: Array<string>): Promise<void> => {
    return axios.post(`${vendorsEndpoint}:inviteMembersToVmc`, { emails })
}

const vendorsInvitationsSearch = (): Promise<VMCInvitationsResponse> => {
    return axios.post(`${vendorsEndpoint}:search`)
}

const resendVMSInvitation = (prospectId: string): Promise<void> => {
    return axios.post(`${vendorsEndpoint}/${prospectId}:resend`)
}

const cancelVMSInvitation = (prospectId: string): Promise<void> => {
    return axios.post(`${vendorsEndpoint}/${prospectId}:cancel`)
}

const getUserEmailVMC = (prospectId: string): Promise<VMCInvitationAdditionalDataResponse> => {
    return axios.get(`anonymous${vendorsEndpoint}/${prospectId}`)
}

const VMCUsersSearch = (): Promise<VMCUsersResponse> => {
    return axios.post(`/vmc:search`)
}

const getVMCUserStatus = (): Promise<VMCstatusEnum> => {
    return axios.get('/currentUser/vmcStatus')
}

export {
    checkInvitationStatus,
    confirmInvitation,
    rejectInvitation,
    resendCompanyInvitation,
    cancelCompanyInvitation,
    inviteMembersToCompany,
    companyUsersSearch,
    removeUserAccess,
    companyInvitationsSearch,
    sendCompanyEmailConfirmation,
    editUserSegmentationCompany,
    addUserSegmentationCompany,
    removeInactiveCompany,
    updateWhiteLabel,
    updateLogo,
    updateDarkModeLogo,
    getSyndicate,
    joinSyndicate,
    updateSyndicateContactPersonData,
    getIsSyndicateMember,
    getSyndicateName,
    getSubscriptions,
    saveSubscriptions,
    getSubscriptionsV2,
    saveSubscriptionsV2,
    inviteVendorsToCompany,
    vendorsInvitationsSearch,
    resendVMSInvitation,
    cancelVMSInvitation,
    VMCUsersSearch,
    getVMCUserStatus,
    getUserEmailVMC,
}
