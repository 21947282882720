/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { saveAs } from 'file-saver'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { DefaultLayout } from 'src/components/DefaultLayout'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { ApplicationDetailsComponent } from 'src/components/opportunities/ApplicationDetailsComponent'
import { ApplicationStatusesHistory } from 'src/components/opportunities/ApplicationStatusesHistory'
import { getAppPath } from 'src/contracts/applications'
import { ReduxContext } from 'src/redux/Store'
import { Nullable } from 'src/types'
import { useLogger } from 'src/utils/useLogger'
import { history } from 'src/history'
import { ApplicationStatusEnum } from 'src/contracts/opportunities-manager/contracts'

import { getApplicationCv, getApplicationManagerView } from '../../api/opportunities-manager/api'
import { UpdateApplicationStatusForm } from '../../components/opportunities-manager/UpdateApplicationStatusForm'
import { Application } from '../../contracts/opportunities-manager/contracts'
import { SMEmenuItems } from '../../components/opportunities-manager/utils/menuItems'

const ApplicationDetailsSme = () => {
    const { opportunityId, applicationId } = useParams<{ opportunityId: string; applicationId: string }>()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const [application, setApplication] = useState<Nullable<Application>>(null)
    const log = useLogger('error')
    const { addError } = useNotifications()
    const [error, setError] = useState<string>('')

    const [isEditMode, setIsEditMode] = useState(false)

    const appPath = getAppPath('OPPORTUNITIES_MANAGER_SME')

    const toggleEditMode = useCallback(() => {
        setIsEditMode(currentIsEditMode => !currentIsEditMode)
    }, [])

    const fetchData = useCallback(() => {
        if (applicationId) {
            layoutToggleLoader(true)
            getApplicationManagerView(applicationId)
                .then(setApplication)
                .catch(err => {
                    if (err.data.code === 'APPLICATION_NOT_FOUND') {
                        setError('not found')
                    } else {
                        log(err)
                        addError()
                    }
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, applicationId, layoutToggleLoader, log])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleCvDownload = useCallback(() => {
        if (applicationId) {
            layoutToggleLoader(true)
            getApplicationCv(applicationId)
                .then(response => {
                    saveAs(response, application?.cvFileName)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, application?.cvFileName, applicationId, layoutToggleLoader, log])

    const onGoBack = useCallback(() => history.push(`${appPath}/opportunities/${opportunityId}/applications`), [opportunityId, appPath])
    const isInvited = application?.recentStatus.status === ApplicationStatusEnum.INVITED

    return !application && error === 'not found' ? (
        <DefaultLayout
            icon='cosmonaut'
            iconSize={54}
            iconStyles={css`
                position: absolute;
                bottom: 0;
                left: 0;
            `}
            title='Application not found'
            description='Sorry, this page doesn’t exist.'
            buttonText='Return to the list'
            onButtonClick={onGoBack}
        />
    ) : (
        <DashboardLayout applicationName='Opportunities Manager SME' applicationMenuItems={SMEmenuItems}>
            <ApplicationDetailsComponent
                application={application}
                backlinkPath={`${appPath}/${opportunityId}/applications`}
                handleCvDownload={handleCvDownload}
                opportunityId={opportunityId}
                isSmeCloud={true}
            >
                {application && applicationId && (
                    <Fragment>
                        {isEditMode ? (
                            <UpdateApplicationStatusForm
                                applicationId={applicationId}
                                recentStatus={application.recentStatus}
                                toggleEditMode={toggleEditMode}
                                setApplication={setApplication}
                                currency={application.currency}
                                application={application}
                            />
                        ) : (
                            <ApplicationStatusesHistory
                                toggleEditMode={toggleEditMode}
                                statusHistory={application.statusHistory}
                                recentStatus={application.recentStatus}
                                currency={application.currency}
                                contractRate={application.contractRate}
                                disabled={isInvited}
                                tooltip={
                                    isInvited ? "You can't edit the invited candidates until their manager accepts the invite." : undefined
                                }
                            />
                        )}
                    </Fragment>
                )}
            </ApplicationDetailsComponent>
        </DashboardLayout>
    )
}

export { ApplicationDetailsSme }
