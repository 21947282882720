import { useCallback, useEffect, useState } from 'react'
import saveAs from 'file-saver'

import { useNotifications } from 'src/components/notification/NotificationProvider'
import { useLogger } from 'src/utils/useLogger'
import { downloadAnonymizedCV, getAnonymizedCVInfo } from 'src/api/api'
import { Specialist } from 'src/contracts/specialist/specialist'
import { convertTimestampToDateString } from 'src/utils/dates'
import { fileTypeToExtension } from 'src/utils/values'

const useAnonymizedCV = (specialist: Specialist, anonymousNickname?: string) => {
    const { addError } = useNotifications()
    const log = useLogger()
    const [uploadDate, setUploadDate] = useState<string | null>(null)
    const openAnonymizedCV = useCallback(async () => {
        try {
            const anonymizedCV = await downloadAnonymizedCV(specialist.id)
            const filename = `${specialist.seniority ? `${specialist.seniority}_` : ''}${specialist.role}${
                anonymousNickname ? `_${anonymousNickname}` : ''
            }${fileTypeToExtension(anonymizedCV.type)}`
            saveAs(anonymizedCV, filename)
        } catch (err) {
            addError()
            log(err)
        }
    }, [addError, anonymousNickname, log, specialist.id, specialist.role, specialist.seniority])
    const hasBeenUploaded = !!uploadDate

    useEffect(() => {
        getAnonymizedCVInfo(specialist.id).then(({ anonymizedCvUploadedOn }) => {
            if (anonymizedCvUploadedOn) {
                setUploadDate(convertTimestampToDateString(anonymizedCvUploadedOn))
            }
        })
    }, [specialist.id])

    return { openAnonymizedCV, uploadDate, hasBeenUploaded }
}

export { useAnonymizedCV }
