import styled from '@emotion/styled'

const MsaInfoWrapper = styled.div<{ marginBottom?: string }>`
    padding: 8px;
    line-height: 20px;
    background-color: ${({ theme }) => theme.colors.red_2};
    margin-bottom: ${({ marginBottom }) => marginBottom};
`

const MsaLink = styled.a`
    text-decoration: underline;
    margin-right: 4px;
    display: block;
    margin: 1rem 0;
`

export { MsaInfoWrapper, MsaLink }
