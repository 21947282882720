/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from 'src/components/Button'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { Modal } from 'src/components/Modal'
import { AvailableOn } from 'src/contracts/search/availableOn'
import { useCountries, useCurrencies } from 'src/redux/dictionaryDataHooks'
import { hasErrors } from 'src/utils/errors'
import { SharedMapping } from '../contracts'
import { findSyndicateNameById } from 'src/utils/values'

type BulkEditModalProps = {
    opened: boolean
    onClose: () => void
    onFormSubmit: (formData: any) => void
    validationSchema: any
    sharedOptions: Array<any>
    syndicates: Array<AvailableOn>
    availableOnOptions: Array<string>
}

const BulkEditModal: FunctionComponent<React.PropsWithChildren<BulkEditModalProps>> = ({
    opened,
    onClose,
    onFormSubmit,
    validationSchema,
    sharedOptions,
    syndicates,
    availableOnOptions,
}) => {
    const {
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
        reset,
        control,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    })

    const onSubmit = useCallback(
        (formData: any) => {
            onFormSubmit(formData)
        },
        [onFormSubmit],
    )

    useEffect(() => {
        reset({ availableFrom: Date.now() / 1000, availableOn: [] })
    }, [reset])

    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const currencies = useCurrencies()

    return (
        <Modal
            onClose={onClose}
            opened={opened}
            style={css`
                width: 366px;
                max-height: 100vh;
                overflow-y: scroll;
            `}
        >
            <h5>Edit specialists</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FieldWrapper noLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Country'
                        options={memorizedCountries}
                        control={control}
                        name='country'
                        canClear
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        forceDownOpen
                    />
                </FieldWrapper>

                <FieldWrapper noLabel>
                    <ControlledAutocompleteSelect
                        placeholder='Currency'
                        options={currencies}
                        control={control}
                        name='currency'
                        canClear
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        forceDownOpen
                    />
                </FieldWrapper>

                <FieldWrapper noLabel>
                    <ControlledInput name='rate' control={control} type='number' placeholder='Hourly Rate' />
                </FieldWrapper>

                <FieldWrapper noLabel>
                    <ControlledDatePickerInput control={control} name='availableFrom' placeholder='Available From' />
                </FieldWrapper>

                <FieldWrapper noLabel>
                    <ControlledAutocompleteSelect
                        multiple
                        canFilter={false}
                        placeholder='Available On'
                        options={availableOnOptions}
                        selectedLabelTransformer={(id: string) => findSyndicateNameById(id, syndicates)}
                        control={control}
                        name='availableOn'
                    />
                </FieldWrapper>

                <FieldWrapper noLabel>
                    <ControlledAutocompleteSelect
                        canFilter={false}
                        placeholder='Share Status'
                        options={sharedOptions.map(opt => SharedMapping[opt])}
                        control={control}
                        name='shared'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>

                <Divider />

                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 22px;
                        `}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>

                    <Button type='submit' variant='primary' disabled={isSubmitting || !isDirty || hasErrors(errors)}>
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

export { BulkEditModal }
