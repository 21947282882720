/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import everest from '../assets/images/everest.png'
import { COLOR_PALETTE } from '../theme/colors'

const EverestView = () => {
    return (
        <div
            css={css`
                display: flex;
                background-color: ${COLOR_PALETTE.beige_3};
                flex-direction: column;
                position: relative;
                justify-content: space-between;
                height: 100%;
            `}
        >
            <div
                css={css`
                    width: 100%;
                    height: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    @media (min-aspect-ratio: 12/4) {
                        height: 100%;
                    }
                `}
            >
                <h2
                    css={css`
                        margin-bottom: 16px;
                        padding: 0;
                    `}
                >
                    Welcome to
                </h2>

                <div
                    css={css`
                        background-color: ${COLOR_PALETTE.beige_4};
                        color: ${COLOR_PALETTE.gray_6};
                        width: 160px;
                        font-size: 22px;
                        line-height: 32px;
                        border-radius: 32px;
                        font-weight: 500;
                        margin: 0 auto;
                        margin-bottom: 16px;
                    `}
                >
                    Version 2.0
                </div>
                <h2>of our Platform</h2>
            </div>
            <div
                css={css`
                    position: relative;
                    height: 50%;
                    width: 100%;
                    @media (min-aspect-ratio: 12/4) {
                        display: none;
                    }
                `}
            >
                <div
                    css={css`
                        width: 100%;
                        height: 100%;
                        background-image: url(${everest});
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        z-index: 4;
                        position: absolute;
                    `}
                ></div>
            </div>
        </div>
    )
}

export { EverestView }
