/** @jsxImportSource @emotion/react */

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { SkillsList } from '../talent/SkillsList'

export const MoreInfoRow = styled.div`
    display: flex;
    padding: 12px 0;

    &:last-of-type {
        border: none;
    }

    ${mqMax[1]} {
        flex-direction: column;
    }
`

export const MoreInfoTitle = styled.p`
    color: ${COLOR_PALETTE.gray_4};
    flex: 1;
    margin: 0;

    ${mqMax[1]} {
        margin-bottom: 12px;
    }
`

export const ItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 2;
`

type MoreInfoProps = { profile: Profile }

const MoreInfo: FunctionComponent<React.PropsWithChildren<MoreInfoProps>> = ({ profile }) => {
    const theme = useTheme()

    return (
        <div
            css={css`
                border-bottom: 1px solid ${theme.colors.beige_2};
            `}
        >
            {((profile && profile.verifiedTopSkills) || [])?.length > 0 && (
                <div
                    css={css`
                        margin-bottom: 12px;
                    `}
                >
                    <MoreInfoTitle>Top Skills: </MoreInfoTitle>
                    <div
                        css={css`
                            background-color: ${theme.colors.violet_1};
                            border-radius: 4px;
                            padding: 8px;
                        `}
                    >
                        <SkillsList skills={(profile && profile.verifiedTopSkills) || []} truncated topSkills />
                    </div>
                </div>
            )}

            {profile.potentialRoles && profile.potentialRoles.length > 0 && (
                <MoreInfoRow>
                    <MoreInfoTitle>Potential Roles:</MoreInfoTitle>
                    <ItemsContainer>{reduceArrayToString(profile.potentialRoles)}</ItemsContainer>
                </MoreInfoRow>
            )}
            {profile.recentIndustries.length > 0 && (
                <MoreInfoRow>
                    <MoreInfoTitle>Industry Experience:</MoreInfoTitle>
                    <ItemsContainer>{reduceArrayToString(profile.recentIndustries)}</ItemsContainer>
                </MoreInfoRow>
            )}

            {profile.responsibilities && profile.responsibilities.length > 0 && (
                <MoreInfoRow>
                    <MoreInfoTitle>Professional Responsibilities:</MoreInfoTitle>
                    <ItemsContainer>{reduceArrayToString(profile.responsibilities)}</ItemsContainer>
                </MoreInfoRow>
            )}

            {profile.location && (
                <MoreInfoRow>
                    <MoreInfoTitle>Location:</MoreInfoTitle>
                    <ItemsContainer>{profile.location}</ItemsContainer>
                </MoreInfoRow>
            )}
        </div>
    )
}

export { MoreInfo }

export const reduceArrayToString = (array: Array<string>): string => {
    return array.reduce((prev: string, curr: string) => {
        let result
        if (prev) {
            result = `${prev}, ${curr}`
        } else {
            result = `${curr}`
        }

        return result
    }, '')
}
