/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, MouseEvent, useCallback } from 'react'
import { useLocation } from 'react-use'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Input } from '../inputs/Input'
import { Modal, ModalProps } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'

type ShareOpportunityModalProps = ModalProps & { onClose: () => void; opportunityId: string; isVMCCloudApp?: boolean }

const ShareOpportunityModal: FunctionComponent<React.PropsWithChildren<ShareOpportunityModalProps>> = ({
    opportunityId,
    onClose,
    opened,
    isVMCCloudApp = false,
}) => {
    const { addSuccess } = useNotifications()
    const location = useLocation()
    const shareLink = isVMCCloudApp
        ? `${location.origin}/public/vacancy/${opportunityId}`
        : `${location.origin}/public/opportunity/${opportunityId}`
    const copyToClipboard = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            navigator.clipboard.writeText(shareLink)
            addSuccess('Link copied!')
            onClose()
        },
        [addSuccess, onClose, shareLink],
    )

    const handleClose = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            onClose()
        },
        [onClose],
    )

    return (
        <Modal onClose={onClose} opened={opened}>
            <h5> {isVMCCloudApp ? 'Share the link to the Vacancy' : 'Share the link to the Opportunity'} </h5>
            <GreyParagraph>
                {isVMCCloudApp ? 'Let others take a look at this vacancy.' : 'Let others take a look at this opportunity.'}
            </GreyParagraph>
            <Input name='sharableLink' value={shareLink} disabled={true}></Input>
            <Divider />
            <ButtonContainer>
                <Button
                    css={css`
                        margin-right: 28px;
                    `}
                    variant='link'
                    onClick={handleClose}
                >
                    Go Back
                </Button>
                <Button dataTestId='opportunity-share-modal-share' onClick={copyToClipboard} variant='primary'>
                    Copy & Close
                </Button>
            </ButtonContainer>
        </Modal>
    )
}

export { ShareOpportunityModal }
