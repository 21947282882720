/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledCheckbox } from '../../../components/forms/ControlledCheckbox'
import { ControlledColorPicker } from '../../../components/forms/ControlledColorPicker'
import { ControlledDropzone } from '../../../components/forms/ControlledDropzone'
import { ControlledTextColorPicker } from '../../../components/forms/ControlledTextColorPicker'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { SettingsWrapper } from '../../../components/layout/ResponsiveWrapper'
import { Logo } from '../../../components/Logo'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { hasErrors, resolveFieldError } from '../../../utils/errors'
import { useLogger } from '../../../utils/useLogger'
import { FIELD_REQUIRED } from '../../../validation/validation-messages'
import { yup } from '../../../validation/yup'
import { updateDarkModeLogo, updateLogo, updateWhiteLabel } from '../api'
import { parseLogo } from './utils'

type FormValues = {
    active: boolean
    color: string
    darkText: boolean
    logo: File
    darkModeLogo?: File
}

const LogoTip = () => (
    <p
        css={css`
            margin-top: 1em;
            font-size: 11px;
            line-height: 14px;
            color: ${COLOR_PALETTE.gray_4};
        `}
    >
        In order for your logo to look the best, you need to upload a .PNG or .JPG file that is at least 150x50px. Also, please send a
        horizontal logo if possible. If you aren’t certain about which logo to upload, please consult your marketing team.
    </p>
)

const StyledImage = styled.img`
    max-width: 80%;
    max-height: 80%;
`

const ImageDropZoneContainer = styled.div`
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
`

const schema = yup.object().shape({
    active: yup.boolean().required(),
    color: yup.string().required(),
    darkText: yup.boolean().required(),
    logo: yup.mixed(),
    darkModeLogo: yup.mixed(),
})

const WhiteLabelForm: FunctionComponent<React.PropsWithChildren<{}>> = () => {
    const log = useLogger('error')

    const {
        actions: { whiteLabelSet },
        selectors: { whiteLabel },
    } = useContext(ReduxContext)
    const [newLogoUrl, setNewLogoUrl] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [darkModeNewLogoUrl, setDarkModeNewLogoUrl] = useState<string>('')
    const { addError, addSuccess } = useNotifications()
    const {
        control,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        setValue,
        reset,
        formState: { errors, isDirty },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            active: Boolean(whiteLabel?.active),
            color: whiteLabel?.color || COLOR_PALETTE.yellow_4,
            darkText: Boolean(whiteLabel?.darkText),
            logo: undefined,
            darkModeLogo: undefined,
        },
    })
    const navigate = useNavigate()

    useEffect(() => {
        if (whiteLabel?.readOnlySettings) {
            navigate('/dashboard/company-account/company-details')
        }
    }, [navigate, whiteLabel?.readOnlySettings])

    const handleWhiteLabelSubmit = useCallback(
        ({ logo: newLogo, darkModeLogo: newDarkModeLogo, ...rest }: FormValues) => {
            setIsSubmitting(true)
            if (!(newLogo || newDarkModeLogo) && !whiteLabel?.logoUrl) {
                setError('logo', { type: 'required', message: FIELD_REQUIRED })
                addError('Your Company Logo is required')
                setIsSubmitting(false)
            } else {
                const requests = []
                if (newLogo || (newDarkModeLogo && !whiteLabel?.logoUrl)) {
                    const logoData = new FormData()
                    logoData.append('file', newLogo || newDarkModeLogo)
                    requests.push(updateLogo(logoData))
                }
                if (newDarkModeLogo) {
                    const darkModeLogoData = new FormData()
                    darkModeLogoData.append('file', newDarkModeLogo)
                    requests.push(updateDarkModeLogo(darkModeLogoData))
                }
                Promise.all(requests)
                    .then(() =>
                        updateWhiteLabel(rest)
                            .then(data => {
                                whiteLabelSet(data)
                                localStorage.setItem('whiteLabel', JSON.stringify(data))
                                addSuccess('White label updated successfully')
                            })
                            .catch(e => {
                                addError('White label update failed.')
                                log(e)
                            })
                            .finally(() => {
                                setIsSubmitting(false)
                            }),
                    )
                    .catch(e => {
                        addError('Logo update failed.')
                        setIsSubmitting(false)
                        log(e)
                    })
            }
        },
        [whiteLabelSet, whiteLabel, setError, addError, addSuccess, log],
    )

    const [active, logo, darkModeLogo, color] = watch(['active', 'logo', 'darkModeLogo', 'color'])

    const cleanUrl = useCallback((url: string) => {
        URL.revokeObjectURL(url)
        return ''
    }, [])

    useEffect(() => {
        if (!active) {
            setDarkModeNewLogoUrl(cleanUrl)
            setNewLogoUrl(cleanUrl)
        }
    }, [active, cleanUrl])

    useEffect(() => {
        if (whiteLabel && active) {
            setValue('color', whiteLabel.color || COLOR_PALETTE.yellow_4)
            setValue('darkText', whiteLabel.darkText)
            setNewLogoUrl(whiteLabel.logoUrl || '')
            setDarkModeNewLogoUrl(whiteLabel.darkModeLogoUrl || '')
        }
    }, [whiteLabel, active, setValue])

    useEffect(() => {
        if (whiteLabel) {
            reset({ active: whiteLabel.active, color: whiteLabel.color || COLOR_PALETTE.yellow_4, darkText: whiteLabel.darkText })
            setNewLogoUrl(whiteLabel.logoUrl || '')
            setDarkModeNewLogoUrl(whiteLabel.darkModeLogoUrl || '')
        }
    }, [whiteLabel, reset])

    useEffect(() => {
        if (logo) {
            setNewLogoUrl(url => {
                URL.revokeObjectURL(url)
                return URL.createObjectURL(logo)
            })
            clearErrors('darkModeLogo')
        }
    }, [logo, clearErrors])

    useEffect(() => {
        if (darkModeLogo) {
            setDarkModeNewLogoUrl(url => {
                URL.revokeObjectURL(url)
                return URL.createObjectURL(darkModeLogo)
            })
            clearErrors('logo')
        }
    }, [darkModeLogo, clearErrors])

    useEffect(() => () => URL.revokeObjectURL(newLogoUrl), [newLogoUrl])

    useEffect(() => () => URL.revokeObjectURL(darkModeNewLogoUrl), [darkModeNewLogoUrl])

    useEffect(() => {
        if ((newLogoUrl || darkModeNewLogoUrl) && (errors.logo || errors.darkModeLogo)) {
            addError(resolveFieldError(errors.logo || errors.darkModeLogo))
            clearErrors('darkModeLogo')
            clearErrors('logo')
        }
    }, [errors.logo, newLogoUrl, errors.darkModeLogo, darkModeNewLogoUrl, addError, clearErrors])

    const isButtonDisabled = useMemo(() => isSubmitting || !isDirty || hasErrors(errors), [isDirty, isSubmitting, errors])

    return (
        <DashboardLayout menuType={MenuType.companyAccount}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                `}
            >
                <SettingsWrapper>
                    <h4>White Label</h4>
                    <Divider />
                    <h6
                        css={css`
                            margin-bottom: 32px;
                        `}
                    >
                        Your Company Logo
                    </h6>
                    <p>
                        Uploading your logo will automatically replace the Talent Alpha logo with your own at the top left of the Human
                        Cloud Platform across all app and the shareable Specialist profile.
                    </p>
                    <br />
                    <form
                        css={css`
                            display: flex;
                            flex-direction: column;
                            position: relative;
                        `}
                        onSubmit={handleSubmit(handleWhiteLabelSubmit)}
                    >
                        <ControlledCheckbox
                            control={control}
                            checkboxLabel='Activate customized logo & branding for Human Cloud Platform'
                            name='active'
                        />
                        <br />
                        <div
                            css={css`
                                ${!active && 'display:none;'}
                            `}
                        >
                            <ControlledDropzone
                                name='logo'
                                accept={{ 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] }}
                                dropErrorMessages={{
                                    invalidType: 'This file type cannot be uploaded. Accepted file types are: .jpeg, .jpg, .png, .svg .',
                                }}
                                control={control}
                                parser={parseLogo}
                                showError={!(newLogoUrl || darkModeNewLogoUrl)}
                                onError={message => setError('logo', { type: 'manual', message: message.message })}
                                dropzoneContent={
                                    <ImageDropZoneContainer>
                                        {newLogoUrl || darkModeNewLogoUrl ? (
                                            <StyledImage src={newLogoUrl || darkModeNewLogoUrl} alt='logo' />
                                        ) : (
                                            <Logo type='logoFull' />
                                        )}
                                    </ImageDropZoneContainer>
                                }
                            />

                            <LogoTip />
                            <Divider />
                            <h6
                                css={css`
                                    margin: 10px 0 32px;
                                `}
                            >
                                Your Company Logo for Dark Mode (optional)
                            </h6>
                            <ControlledDropzone
                                name='darkModeLogo'
                                control={control}
                                isDarkMode
                                accept={{ 'image/jpeg': [], 'image/png': [], 'image/svg+xml': [] }}
                                parser={parseLogo}
                                showError={!(darkModeNewLogoUrl || newLogoUrl)}
                                onError={message => setError('logo', { type: 'manual', message: message.message })}
                                dropzoneContent={
                                    <ImageDropZoneContainer>
                                        {darkModeNewLogoUrl || newLogoUrl ? (
                                            <StyledImage src={darkModeNewLogoUrl || newLogoUrl} alt='darkmode logo' />
                                        ) : (
                                            <Logo type='logoFullInverted' />
                                        )}
                                    </ImageDropZoneContainer>
                                }
                            />
                            <LogoTip />
                            <Divider />
                            <h6
                                css={css`
                                    margin: 2px 0 24px;
                                `}
                            >
                                Your Company Color
                            </h6>
                            <ControlledColorPicker name='color' control={control} />
                            <Divider />
                            <h6
                                css={css`
                                    margin: 2px 0 24px;
                                `}
                            >
                                Text Color
                            </h6>
                            <ControlledTextColorPicker color={color} name='darkText' control={control} />
                        </div>
                        {(active || whiteLabel?.active) && (
                            <Fragment>
                                <Divider />
                                <div>
                                    <Button
                                        type='submit'
                                        disabled={isButtonDisabled}
                                        css={css`
                                            ${mqMax[1]} {
                                                width: 100%;
                                            }
                                        `}
                                        dataTestId='whitelabel-submit'
                                    >
                                        Save Details
                                    </Button>
                                </div>
                            </Fragment>
                        )}
                        {(!whiteLabel || isSubmitting) && <OverlayContentLoader />}
                    </form>
                </SettingsWrapper>
            </div>
        </DashboardLayout>
    )
}

export { WhiteLabelForm }
