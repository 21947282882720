/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback } from 'react'
import { mqMin } from '../GlobalStyle'
import { Nullable } from '../types'
import { SwitchTab } from './SwitchTab'

const tabs = [
    {
        name: 'manager',
        header: 'Manager',
        description: 'I would like to manage my company’s data or to create new company account',
        secondary: false,
    },
    {
        name: 'specialist',
        header: 'Specialist',
        description: 'I was invited to fill in my profile data, like skills and projects',
        secondary: true,
    },
]

type TabbedLoginProps = { onTabClick: (tab: string) => void; selectedTab: Nullable<string> }

const TabbedLogin: FunctionComponent<React.PropsWithChildren<TabbedLoginProps>> = ({ onTabClick, selectedTab }) => {
    const theme = useTheme()

    const handleTabClick = useCallback(
        (name: string) => {
            onTabClick(name)
        },
        [onTabClick],
    )

    return (
        <Fragment>
            {selectedTab === null && (
                <Fragment>
                    <h3
                        css={css`
                            margin-top: 12vh;
                            font-size: 34px;
                            line-height: 40px;
                        `}
                        data-testid='login-check'
                    >
                        Welcome to <br />
                        Talent Alpha Platform
                    </h3>
                    <span
                        css={css`
                            font-size: 18px;
                            color: ${theme.colors.gray_4};
                        `}
                    >
                        - Kindly select from the following:
                    </span>
                </Fragment>
            )}
            {selectedTab === null ? (
                <div
                    css={css`
                        margin-top: 20px;
                        display: flex;
                        ${mqMin[1]} {
                            margin-top: 40px;
                        }
                    `}
                >
                    {tabs.map((tab, index) => (
                        <SwitchTab
                            color={tab.secondary ? theme.colors.green_3 : theme.colors.blue_3}
                            isSelected={selectedTab === tab.name}
                            name={tab.name}
                            key={index}
                            description={
                                <span
                                    css={css`
                                        margin-top: 8px;
                                        display: block;
                                        color: ${selectedTab === tab.name ? theme.colors.gray_6 : theme.colors.gray_4};
                                        line-height: 14px;
                                        font-size: 11px;
                                    `}
                                >
                                    {tab.description}
                                </span>
                            }
                            header={
                                <span
                                    css={css`
                                        font-size: 18px;
                                        line-height: 26px;
                                        font-weight: 500;
                                    `}
                                >
                                    {tab.header}
                                </span>
                            }
                            onTabClick={() => handleTabClick(tab.name)}
                            styles={css`
                                margin-left: ${tab.secondary ? '11px' : '0px'};
                            `}
                        />
                    ))}
                </div>
            ) : (
                <div
                    css={css`
                        margin-top: 20px;
                        display: flex;

                        ${mqMin[1]} {
                            margin-top: 40px;
                        }
                    `}
                >
                    {tabs.map((tab, index) => (
                        <SwitchTab
                            color={tab.secondary ? theme.colors.green_3 : theme.colors.blue_3}
                            name={tab.name}
                            key={index}
                            isSelected={selectedTab === tab.name}
                            description={
                                <span
                                    css={css`
                                        margin-top: 8px;
                                        display: block;
                                        color: ${selectedTab === tab.name ? theme.colors.gray_6 : theme.colors.gray_4};
                                        line-height: 14px;
                                        font-size: 11px;
                                    `}
                                >
                                    {tab.description}
                                </span>
                            }
                            header={
                                <span
                                    css={css`
                                        font-size: 18px;
                                        line-height: 26px;
                                        font-weight: 500;
                                    `}
                                >
                                    {tab.header}
                                </span>
                            }
                            onTabClick={() => handleTabClick(tab.name)}
                            styles={css`
                                margin-left: ${tab.secondary ? '11px' : '0px'};
                            `}
                        />
                    ))}
                </div>
            )}
        </Fragment>
    )
}

export { TabbedLogin }
