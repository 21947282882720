/** @jsxImportSource @emotion/react */

import { Fragment, useMemo } from 'react'
import { FunctionComponent } from 'react'
import { GroupName } from './GroupName'
import { SelectItem } from './styled'

export type OptionObject = {
    value: string
    groupName: string
}

export type OptionType = string | OptionObject

type OptionProps = {
    option: OptionType
    previousOption?: OptionType
    labelTransformer: (opt: any) => string
    index: number
    onOptionClick: (option: OptionType) => void
    multiple: boolean
    isOptionChecked: (option: OptionType) => boolean
    dataTestIdDefault: string
    shouldRenderGroupNames: boolean
}

const Option: FunctionComponent<React.PropsWithChildren<OptionProps>> = ({
    option,
    labelTransformer,
    index,
    onOptionClick,
    multiple,
    isOptionChecked,
    dataTestIdDefault,
    previousOption,
    shouldRenderGroupNames,
}) => {
    const shouldRenderGroupName = useMemo(
        () =>
            shouldRenderGroupNames &&
            typeof option !== 'string' &&
            typeof previousOption !== 'string' &&
            option.groupName &&
            previousOption?.groupName !== option.groupName,
        [option, previousOption, shouldRenderGroupNames],
    )
    return (
        <Fragment>
            {shouldRenderGroupName && <GroupName name={(option as OptionObject).groupName} index={index} />}
            <SelectItem
                key={`${index}_k_${labelTransformer(option)}`}
                onClick={() => onOptionClick(option)}
                multiple={multiple}
                selected={isOptionChecked(option)}
                autosize
                data-testid={`${dataTestIdDefault}-item`}
            >
                {labelTransformer(option)}
            </SelectItem>
        </Fragment>
    )
}

export { Option }
