/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { bodyBold, COLOR_PALETTE, mqMax, mqMin } from '../../../GlobalStyle'
import { useUserContext } from '../../../hooks/useUserContext'
import { Theme } from '../../../theme/theme'
import { AutocompleteSelect } from '../../inputs/autocomplete-select/AutocompleteSelect'

const activeItemStyle = (theme: Theme) => css`
    border-left: 3px solid ${theme.colors.main};
`

const disabledItemStyle = (theme: Theme) => css`
    color: ${theme.colors.gray_3};

    &:hover {
        cursor: not-allowed;
        border-left: 3px solid transparent;
    }
`

type MenuItemType = {
    title: string
    path: string
}

export type MenuItems = Array<MenuItemType>

type MainMenuProps = {
    menuItems: MenuItems
    label: string
    id: string
}

const MainMenu: FunctionComponent<React.PropsWithChildren<MainMenuProps>> = ({ menuItems, label, id }) => {
    const theme = useTheme()

    const location = useLocation()
    const navigate = useNavigate()

    const { isSuperAdmin, isFunctionalManager } = useUserContext()

    const convertValuesToStrings = useCallback(() => menuItems.map(item => item.title), [menuItems])

    const activeMenuItem = useMemo(
        () => menuItems.filter(item => location.pathname.includes(item.path)).map(item => item.title),
        [menuItems, location.pathname],
    )

    const handleChange = useCallback(
        (event: Array<string>) => {
            const selectedItem = menuItems.find(item => event.includes(item.title))
            if (selectedItem) {
                navigate(selectedItem.path)
            }
        },
        [navigate, menuItems],
    )

    const handleRedirection = useCallback(
        (item: MenuItemType) => {
            navigate(item.path)
        },
        [navigate],
    )

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                background-color: ${COLOR_PALETTE.gray_1};
                padding-bottom: 24px;

                ${mqMin[2]} {
                    background-color: ${COLOR_PALETTE.white};
                    top: 0;
                    min-height: calc(100vh - 57px);
                    height: 100%;
                    width: 300px;
                }

                ${mqMax[1]} {
                    margin-top: 57px;
                }
            `}
        >
            <div
                css={css`
                    margin-bottom: 5px;
                    padding: 20px 24px;
                    color: ${COLOR_PALETTE.gray_6};

                    ${bodyBold};

                    ${mqMin[2]} {
                        color: ${COLOR_PALETTE.gray_3};
                    }
                `}
            >
                {label}
            </div>

            <div
                css={css`
                    display: block;
                    margin: 0 20px;

                    ${mqMin[2]} {
                        display: none;
                    }
                `}
            >
                <AutocompleteSelect
                    placeholder='Select section'
                    options={convertValuesToStrings()}
                    currentValues={activeMenuItem}
                    onSelectedValuesChange={handleChange}
                    canFilter={false}
                />
            </div>

            <ul
                css={css`
                    display: none;
                    list-style: none;
                    padding: 0 0 25px;
                    margin: 0;

                    ${mqMin[2]} {
                        display: block;
                    }
                `}
            >
                {menuItems.map((item, index) => {
                    const isActive = location.pathname.includes(item.path)
                    const isDisabled = id !== 'myAccount' && !isSuperAdmin && !isFunctionalManager
                    return (
                        <li
                            key={index}
                            onClick={isDisabled ? undefined : () => handleRedirection(item)}
                            data-testid={item.title}
                            css={css`
                                border-left: 3px solid transparent;
                                padding: 6px 20px;

                                &:hover {
                                    ${activeItemStyle(theme)};
                                    cursor: ${isActive ? 'default' : 'pointer'};
                                }

                                ${isActive && activeItemStyle(theme)}
                                ${isDisabled && disabledItemStyle(theme)}
                            `}
                        >
                            {item.title}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export { MainMenu }
