import { useCallback, useContext, useEffect } from 'react'
import {
    getApplications,
    getCountries,
    getCurrencies,
    getIndustries,
    getLanguages,
    getRegions,
    getResponsibilities,
    getSeniorities,
    getSkills,
    getSpecialistRoles,
    getTimezones,
} from '../api/api'
import { Application } from '../contracts/applications'
import { useUserContext } from '../hooks/useUserContext'
import { log } from '../utils/useLogger'
import { ReduxContext } from './Store'

const useSpecialistRoles = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { specialistRoles },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (specialistRoles === null) {
            getSpecialistRoles()
                .then(data => {
                    dictionaryDataSet({ name: 'specialistRoles', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, specialistRoles])

    return specialistRoles
}

const useCountries = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { countries },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (countries === null) {
            getCountries()
                .then(data => {
                    dictionaryDataSet({ name: 'countries', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, countries])

    return countries
}

const useRegions = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { regions },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (regions === null) {
            getRegions()
                .then(data => {
                    dictionaryDataSet({ name: 'regions', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, regions])

    return regions
}

const useTimezones = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { timezones },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (timezones === null) {
            getTimezones()
                .then(data => {
                    dictionaryDataSet({ name: 'timezones', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, timezones])

    return timezones
}

const useCurrencies = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { currencies },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (currencies === null) {
            getCurrencies()
                .then(data => {
                    dictionaryDataSet({ name: 'currencies', data })
                })
                .catch(log)
        }
    }, [currencies, dictionaryDataSet])

    return currencies
}
const useApplications = (refreshCounter = 0) => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { applications },
            featureFlags: { CVManagement },
        },
    } = useContext(ReduxContext)

    const { userActiveContextId } = useUserContext()

    const fetchApplications = useCallback(() => {
        getApplications()
            .then((data: Array<Application>) => {
                const filteredApps = data.filter(app => !(!CVManagement && app.name === 'CV Management'))
                dictionaryDataSet({ name: 'applications', data: filteredApps })
            })
            .catch(log)
    }, [CVManagement, dictionaryDataSet])

    useEffect(() => {
        if (applications === null || refreshCounter > 0) {
            fetchApplications()
        }
    }, [applications, refreshCounter, fetchApplications])

    useEffect(() => {
        fetchApplications()
    }, [fetchApplications, userActiveContextId])

    return applications
}

const useIndustries = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { industries },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (industries === null) {
            getIndustries()
                .then(data => {
                    dictionaryDataSet({ name: 'industries', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, industries])

    return industries
}

const useLanguages = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { languages },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (languages === null) {
            getLanguages()
                .then(data => {
                    dictionaryDataSet({ name: 'languages', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, languages])

    return languages
}

const useSkills = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { skills },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (skills === null) {
            getSkills()
                .then(data => {
                    dictionaryDataSet({ name: 'skills', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, skills])

    return skills
}

const useResponsibilities = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { responsibilities },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (responsibilities === null) {
            getResponsibilities()
                .then(data => {
                    dictionaryDataSet({ name: 'responsibilities', data })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, responsibilities])

    return responsibilities
}

const useSeniorities = () => {
    const {
        actions: { dictionaryDataSet },
        selectors: {
            dictionaryData: { seniorities },
        },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (seniorities === null) {
            getSeniorities()
                .then(({ seniority }) => {
                    dictionaryDataSet({ name: 'seniorities', data: seniority })
                })
                .catch(log)
        }
    }, [dictionaryDataSet, seniorities])

    return seniorities
}

export {
    useSpecialistRoles,
    useCountries,
    useRegions,
    useApplications,
    useIndustries,
    useSkills,
    useLanguages,
    useResponsibilities,
    useTimezones,
    useCurrencies,
    useSeniorities,
}
