import * as yup from 'yup'
import { DATE_REQUIRED, EMAIL_INVALID, FIELD_REQUIRED, VALUE_INVALID, VALUE_POSITIVE } from './validation-messages'

yup.setLocale({
    mixed: {
        required: FIELD_REQUIRED,
        notType: VALUE_INVALID,
    },
    string: {
        matches: VALUE_INVALID,
        email: EMAIL_INVALID,
    },
    number: {
        positive: VALUE_POSITIVE,
    },
})

const yupNumberTimestamp = yup.number().typeError(DATE_REQUIRED)

yup.addMethod<yup.ObjectSchema<{ [name: string]: any }>>(yup.object, 'uniqueProperty', function (propertyName, message) {
    return this.test('unique', message, function (value) {
        if (!value || !value[propertyName]) {
            return true
        }

        if ((this.parent as Array<any>).filter(v => v !== value).some(v => v[propertyName] === value[propertyName])) {
            throw this.createError({
                path: `${this.path}.${propertyName}`,
            })
        }

        return true
    })
})

export { yup, yupNumberTimestamp }
