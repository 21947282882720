/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../../../components/Button'
import { Divider } from '../../../../../components/Divider'
import { Modal } from '../../../../../components/Modal'

type RemoveRoleConfirmationModalModalProps = {
    onRemove: () => void
    opened: boolean
    onClose: () => void
}

const RemoveRoleConfirmationModal: FunctionComponent<React.PropsWithChildren<RemoveRoleConfirmationModalModalProps>> = ({
    opened,
    onClose,
    onRemove,
}) => (
    <Modal opened={opened} onClose={onClose}>
        <h5>Are you sure you want to remove this role for this user?</h5>
        <Divider />
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button
                variant='link'
                style={css`
                    margin-right: 22px;
                `}
                onClick={onClose}
            >
                Cancel
            </Button>

            <Button type='submit' variant='primary' onClick={onRemove}>
                Remove
            </Button>
        </div>
    </Modal>
)

export { RemoveRoleConfirmationModal }
