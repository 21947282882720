/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Profile } from '../../contracts/profile/profile'
import { mqMax } from '../../GlobalStyle'
import { TalentBadge } from '../Badge'
import { ProfileHeaderInfo } from './ProfileHeaderInfo'
import { FlexContainer, ProfileNameRow, TitleH3 } from './styled'
import { calcAvailability } from './utils'

type ProfileHeaderProps = {
    profile: Profile
}

export const ProfileHeaderLabel = styled.div`
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    margin-left: 8px;
`

const ProfileHeader: FunctionComponent<React.PropsWithChildren<ProfileHeaderProps>> = ({ profile }) => {
    const theme = useTheme()

    return (
        <Fragment>
            <div
                css={css`
                    ${mqMax[2]} {
                        display: none;
                    }
                `}
            >
                <div
                    css={css`
                        margin: 20px 0 24px;
                    `}
                >
                    <ProfileNameRow>
                        <FlexContainer>
                            <TitleH3>{profile.role}</TitleH3>{' '}
                            {profile.seniority && profile.seniority !== '-' && (
                                <TalentBadge
                                    style={css`
                                        line-height: 36px;
                                    `}
                                >
                                    {profile.seniority}
                                </TalentBadge>
                            )}
                        </FlexContainer>
                    </ProfileNameRow>
                    <div
                        css={css`
                            font-size: 12px;
                            color: ${theme.colors.gray_4};
                            line-height: 16px;
                        `}
                    >
                        {profile?.nickname || ''}
                    </div>
                </div>
            </div>

            <ProfileHeaderInfo
                elements={[
                    { iconName: 'experience', label: profile.experience ? profile.experience : '<1 year', tooltip: 'Experience' },
                    {
                        iconName: 'price',
                        label: profile.rate && profile.currency ? `${profile.rate} ${profile.currency}` : null,
                        dataTestId: 'rate',
                        tooltip: 'Hourly Rate',
                    },
                    {
                        iconName: 'violet-calendar',
                        label: profile.availableFrom ? calcAvailability(profile.availableFrom) : null,
                        tooltip: 'Availability',
                    },
                ]}
            />
        </Fragment>
    )
}

export { ProfileHeader }
