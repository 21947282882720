/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { Icon } from '../../../../components/Icon'

const Paragraph = styled.p`
    color: ${props => props.theme.colors.gray_5};
`

type ThankYouScreenProps = {}

const ThankYouScreen: FunctionComponent<React.PropsWithChildren<ThankYouScreenProps>> = () => {
    const navigate = useNavigate()

    const handleFinish = useCallback(() => {
        navigate('/')
    }, [navigate])

    const theme = useTheme()

    return (
        <section
            css={css`
                max-width: 576px;
                margin-top: 20px;
            `}
        >
            <div
                css={css`
                    background-color: ${theme.colors.green_3};
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 60px;
                    margin-bottom: 24px;
                `}
            >
                <Icon name='check' size={30} />
            </div>
            <h3>Continuous Feedback Form completed</h3>

            <Paragraph>
                Thank you for completing your Continuous Feedback Form. We will notify you when it will be available again!
            </Paragraph>

            <div
                css={css`
                    margin-top: 60px;
                    display: flex;
                    flex-direction: row;
                `}
            >
                <Button onClick={handleFinish}>Go to dashboard</Button>
            </div>
        </section>
    )
}

export { ThankYouScreen }
