/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, MouseEvent, useCallback } from 'react'
import { blackToGrayFilter, blackToVioletDarkFilter, Icon } from '../../components/Icon'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { mqMax } from '../../GlobalStyle'

type ApplicationActionsProps = {
    applicationId: string
    onWithdrawClick: (applicationId: string) => void
    disabled?: boolean
}

const ApplicationActions: FunctionComponent<React.PropsWithChildren<ApplicationActionsProps>> = ({
    applicationId,
    onWithdrawClick,
    disabled,
}) => {
    const handleWithdrawClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (!disabled) {
                onWithdrawClick(applicationId)
            }
        },
        [applicationId, disabled, onWithdrawClick],
    )

    return (
        <Tooltip placement='topLeft' title={!disabled && 'Withdraw Candidate'}>
            <FlexBox
                css={css`
                    padding: 5px;
                    width: 45px;
                    cursor: pointer;

                    ${mqMax[2]} {
                        margin: 10px 0 0 -5px;
                    }
                `}
            >
                <Icon
                    name='withdraw'
                    onClick={handleWithdrawClick}
                    size={24}
                    style={css`
                        filter: ${blackToVioletDarkFilter};

                        ${disabled &&
                        css`
                            filter: ${blackToGrayFilter};
                            cursor: not-allowed;
                        `}
                    `}
                    dataTestId='withdraw-icon'
                />
                <Icon
                    name='arrow-right-single'
                    size={20}
                    style={css`
                        display: none;
                        filter: ${blackToVioletDarkFilter};
                        margin-left: 20px;
                    `}
                />
            </FlexBox>
        </Tooltip>
    )
}

export { ApplicationActions }
