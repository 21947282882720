/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Dispatch, FunctionComponent, RefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useLifecycles } from 'react-use'
import { getSpecialistEducation } from '../../../api/api'
import { Button } from '../../../components/Button'
import { IconButton } from '../../../components/Icon'
import { ContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { Education, EducationStatus, EducationType } from '../../../contracts/specialist/specialistEducations'
import { NullableArray } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { deleteSpecialistEducation } from '../api'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { QualificationsForm } from './education/QualificationsForm'
import {
    CertificateEntry,
    EducationEntryProps,
    LanguageEntry,
    studiesEducationTypes,
    StudiesEntry,
} from './education/QualificationsHelpers'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

type EducationQualificationsSectionProps = {
    specialistId: string
    onSave?: () => void
    focus?: boolean
    setForceFocus?: Dispatch<SetStateAction<string>>
    containerRef?: RefObject<HTMLElement>
}

const EducationQualificationsSection: FunctionComponent<React.PropsWithChildren<EducationQualificationsSectionProps>> = ({
    specialistId,
    onSave,
    focus,
    setForceFocus,
    containerRef,
}) => {
    const [educations, setEducations] = useState<NullableArray<Education>>(null)
    const [creationFormOpened, setCreationFormOpened] = useState(false)
    const [editionEducationId, setEditionEducationId] = useState('')
    const [isMounted, setIsMounted] = useState(false)

    const someFormOpened = creationFormOpened || editionEducationId !== ''
    const { addSuccess, addError } = useNotifications()
    const log = useLogger('error')

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const fetchEducation = useCallback(() => {
        getSpecialistEducation(specialistId)
            .then(data => {
                if (isMounted) {
                    setEducations(data)
                }
            })
            .catch(log)
    }, [isMounted, log, specialistId])

    useEffect(() => {
        fetchEducation()
    }, [fetchEducation])

    const handleFormOpen = useCallback(() => {
        setCreationFormOpened(true)
    }, [])

    const handleCreationFormClose = useCallback(() => {
        setCreationFormOpened(false)
    }, [])

    const handleEditionFormClose = useCallback(() => {
        setEditionEducationId('')
    }, [])

    const onCreated = useCallback(() => {
        if (onSave) {
            onSave()
        }
        setCreationFormOpened(false)
        fetchEducation()
    }, [onSave, fetchEducation])

    const onEdited = useCallback(() => {
        if (onSave) {
            onSave()
        }
        setEditionEducationId('')
        fetchEducation()
    }, [onSave, fetchEducation])

    const handleEdit = useCallback((educationId: any) => {
        setEditionEducationId(educationId)
    }, [])

    const handleDelete = useCallback(
        (educationId: any) => {
            deleteSpecialistEducation(specialistId, educationId)
                .then(() => {
                    addSuccess('Education deleted.')
                    fetchEducation()
                    if (onSave) {
                        onSave()
                    }
                })
                .catch(() => {
                    addError()
                })
        },
        [specialistId, addSuccess, fetchEducation, onSave, addError],
    )

    useEffect(() => {
        if (focus && containerRef?.current && setForceFocus) {
            handleFormOpen()
            containerRef.current.scrollIntoView({ behavior: 'smooth' })
            setForceFocus('')
        }
    }, [handleFormOpen, focus, setForceFocus, containerRef])

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Education' divider>
                {educations !== null && educations.length !== 0 && (
                    <Button variant='tertiary' dataTestId='add-education' onClick={handleFormOpen} disabled={someFormOpened}>
                        Add Education
                    </Button>
                )}
            </SectionHeaderWithActions>
            {creationFormOpened && <QualificationsForm specialistId={specialistId} onClose={handleCreationFormClose} onSave={onCreated} />}
            {educations === null && <ContentLoader />}
            {educations !== null && educations.length === 0 && !creationFormOpened && (
                <SectionDataNotFound description='Add data to inform about your Education.'>
                    <Button variant='tertiary' dataTestId='add-education' onClick={handleFormOpen}>
                        Add Education
                    </Button>
                </SectionDataNotFound>
            )}
            {educations !== null &&
                educations.length > 0 &&
                educations.map(education => {
                    return education.id === editionEducationId ? (
                        <QualificationsForm
                            education={education}
                            key={education.id}
                            specialistId={specialistId}
                            onClose={handleEditionFormClose}
                            onSave={onEdited}
                        />
                    ) : (
                        <QualificationsEntry
                            key={education.id}
                            specialistId={specialistId}
                            education={education}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                        />
                    )
                })}
        </SectionWrapper>
    )
}

type QualificationsEntryProps = {
    specialistId?: string
    handleDelete?: (educationId: string) => void
    handleEdit?: (educationId: string) => void
    handleUndo?: (educationId: string) => void
    education: Education
    isEditable?: boolean
    isPrintable?: boolean
    showEducationDuration?: boolean
}

const QualificationsEntry: FunctionComponent<React.PropsWithChildren<QualificationsEntryProps>> = ({
    education,
    handleDelete = () => null,
    handleEdit = () => null,
    handleUndo = () => null,
    isEditable = true,
    isPrintable = false,
    showEducationDuration = true,
}) => {
    const RenderComponent = useMemo<FunctionComponent<React.PropsWithChildren<EducationEntryProps>>>(() => {
        let output: FunctionComponent<React.PropsWithChildren<EducationEntryProps>> = () => null
        switch (education.type) {
            case EducationType.CERTIFICATE_COURSE:
                output = CertificateEntry
                break
            case EducationType.LANGUAGE_COURSE:
                output = LanguageEntry
                break

            default: {
                if (education.type !== null && studiesEducationTypes.includes(education.type)) {
                    output = StudiesEntry
                    break
                }
            }
        }
        return output
    }, [education.type])

    return (
        <div
            data-avoid-break
            css={css`
                page-break-inside: avoid;
                ${isPrintable && 'padding-bottom: 8mm'};
            `}
        >
            <RenderComponent isPrintable={isPrintable} showEducationDuration={showEducationDuration} {...education}>
                {isEditable && (
                    <Actions
                        isEducationDeleted={education.status === EducationStatus.DELETED}
                        educationId={education.id}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleUndo={handleUndo}
                    />
                )}
            </RenderComponent>
        </div>
    )
}

type ActionsProps = {
    educationId?: string
    isEducationDeleted: boolean
    handleDelete: (educationId: string) => void
    handleEdit: (educationId: string) => void
    handleUndo: (educationId: string) => void
}

const Actions: FunctionComponent<React.PropsWithChildren<ActionsProps>> = ({
    educationId,
    isEducationDeleted,
    handleUndo,
    handleEdit,
    handleDelete,
}) => {
    const onDeleteClick = useCallback(() => {
        if (educationId) {
            handleDelete(educationId)
        }
    }, [educationId, handleDelete])

    const onEditClick = useCallback(() => {
        if (educationId) {
            handleEdit(educationId)
        }
    }, [educationId, handleEdit])

    const onUndoClick = useCallback(() => {
        if (educationId) {
            handleUndo(educationId)
        }
    }, [educationId, handleUndo])

    return isEducationDeleted ? (
        <div
            css={css`
                margin-left: 16px;
            `}
        >
            <Button variant='linkForm' size='small' onClick={onUndoClick}>
                Undo
            </Button>
        </div>
    ) : (
        <div
            css={css`
                margin-left: 16px;
            `}
        >
            <IconButton name='edit' onClick={onEditClick} size={32} title='Edit' />
            <IconButton name='trash' onClick={onDeleteClick} size={32} title='Delete' dataTestId='remove-education' />
        </div>
    )
}

export { EducationQualificationsSection, QualificationsEntry }
