/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { Icon } from '../../../../components/Icon'
import { FlexBox } from '../../../../components/layout/FlexBoxHelpers'
import { OverlayContentLoader } from '../../../../components/layout/Loader'
import { KeyInfo, KeyInfoDescriptionMapping, KeyInfoNameMapping, Section } from '../../contracts'

type ToDoListItemProps = Section & {
    handleClick: (type: KeyInfo) => void
    showLoader?: boolean
}

const ToDoListItem: FunctionComponent<React.PropsWithChildren<ToDoListItemProps>> = ({ type, completed, handleClick, showLoader }) => {
    const theme = useTheme()

    const onClick = useCallback(() => {
        handleClick(type)
    }, [handleClick, type])

    return (
        <FlexBox
            alignItems='center'
            css={css`
                position: relative;
                padding: 10px;
                width: 100%;
                margin-top: 4px;
                border: 1px solid ${completed ? 'transparent' : theme.colors.gray_2};
                ${!completed
                    ? `
                background-color: ${theme.colors.white};
                border-radius: 4px;
                ${showLoader ? '' : 'cursor: pointer;'}
                :hover {
                    ${showLoader ? '' : `background-color: ${theme.colors.violet_1};`}
                }`
                    : ''}
            `}
            onClick={completed || showLoader ? undefined : onClick}
        >
            {showLoader && <OverlayContentLoader />}
            <FlexBox
                alignItems='center'
                justifyContent='center'
                css={css`
                    width: 26px;
                    height: 26px;
                    background-color: ${completed ? theme.colors.green_3 : theme.colors.violet_2};
                    border-radius: 13px;
                `}
            >
                {completed && <Icon name='check' />}
            </FlexBox>
            <FlexBox
                css={css`
                    flex-direction: column;
                    margin-left: 12px;
                    flex: 1;
                `}
            >
                <div
                    css={css`
                        color: ${theme.colors.gray_6};
                    `}
                >
                    {KeyInfoNameMapping[type]}
                </div>
                <div
                    css={css`
                        color: ${theme.colors.gray_3};
                    `}
                >
                    {KeyInfoDescriptionMapping[type]}
                </div>
            </FlexBox>
            {!completed && <Icon name='arrow-right-single' size={20} />}
        </FlexBox>
    )
}

export { ToDoListItem }
