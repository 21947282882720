/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSpecialistStrengthForms } from '../../api/api'
import strengthDiscoveryImg from '../../assets/images/strength_discovery_portrait.png'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../components/layout/Loader'
import { ScrollToTop } from '../../components/ScrollUp'
import { TilesView } from '../../components/TilesView'
import { SkillForm } from '../../contracts/skillForm'
import { COLOR_PALETTE } from '../../theme/colors'
import { Nullable } from '../../types'
import { applicationName, generateMenuItems } from './strengthDiscoveryApp'

const StrengthDiscoveryPage = () => {
    const [strengthForms, setStrengthForms] = useState<Array<SkillForm>>([])
    const [isEachStrengthFormSolved, setIsEachStrengthFormSolved] = useState<Nullable<boolean>>(null)
    const navigate = useNavigate()
    const { specialistId } = useParams<{ specialistId: string }>()

    useEffect(() => {
        if (specialistId) {
            getSpecialistStrengthForms(specialistId).then((sf: Array<SkillForm>) => setStrengthForms(sf))
        }
    }, [specialistId])

    useEffect(() => {
        if (strengthForms && strengthForms.length > 0) {
            setIsEachStrengthFormSolved(!strengthForms.some(strengthForm => !strengthForm.isCompleted))
        }
    }, [strengthForms])

    const onFillOutClick = useCallback(
        (link: string, openInIframe: boolean): void => {
            if (openInIframe) {
                navigate(`/strength-discovery/${specialistId}/form?formLink=${link}`)
            } else {
                const url = new URL(link)
                navigate(url.pathname)
            }
        },
        [navigate, specialistId],
    )

    useEffect(() => {
        if (isEachStrengthFormSolved) {
            navigate('/specialist-dashboard')
        }
    }, [isEachStrengthFormSolved, navigate])

    return (
        <Fragment>
            <ScrollToTop />
            <DashboardLayout
                applicationName={applicationName}
                applicationMenuItems={specialistId ? generateMenuItems(specialistId) : undefined}
                showMenuItems={false}
            >
                {isEachStrengthFormSolved === null && <ContentLoader />}
                {isEachStrengthFormSolved === false && (
                    <TilesView
                        tileStyles={{
                            notCompletedIconStyles: css`
                                background-color: ${COLOR_PALETTE.green_3};
                            `,
                            completedIconStyles: css`
                                background-color: ${COLOR_PALETTE.green_6};
                            `,
                        }}
                        forms={strengthForms}
                        onFillOutClick={onFillOutClick}
                        title='Welcome to Strength Discovery'
                        description={
                            <Fragment>
                                <p>
                                    Below you will find a selection of questionnaires and psychometric tests in easy to complete forms. The
                                    Creative Behaviours and Big 5 tests each measure different traits. Once you complete them all you will
                                    discover your unique strengths, cognitive abilities and motivations.
                                </p>
                            </Fragment>
                        }
                        sideImage={strengthDiscoveryImg}
                        sideTitle='About Strength Discovery'
                        sideDescription={[
                            {
                                content:
                                    'The Strength Discovery process is a dynamic process that is currently based on the Big 5 and Creative Behaviours psychometric tests. Read more about each below:',
                            },
                            {
                                content: 'Big 5',
                                css: css`
                                    font-weight: 800;
                                    margin-bottom: 0;
                                `,
                            },
                            {
                                content:
                                    'We use a standardized set of measurements developed by scientists to measure personality traits according to the Big Five Model, which asserts that each personality trait is a spectrum.',
                            },
                            {
                                content: 'Creative Behaviours',
                                css: css`
                                    font-weight: 800;
                                    margin-bottom: 0;
                                `,
                            },
                            {
                                content:
                                    'Creative Behaviours are measured with The Creative Behaviour Questionnaire developed by Bernacka (2016). It measures a person’s thinking styles and whether they prefer to solve problems alone or in a group.',
                            },
                        ]}
                        completedDescription={
                            <Fragment>
                                <p>
                                    You will not be able to edit a completed Strength Form once you have submitted your answers. You will
                                    see the results of these forms on your profile page.
                                </p>
                            </Fragment>
                        }
                        completedHint='Please fill out all of the Strength Forms prepared for you.'
                    />
                )}
            </DashboardLayout>
        </Fragment>
    )
}

export { StrengthDiscoveryPage }
