/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { mqMax } from 'src/GlobalStyle'
import { BlueActionBox } from 'src/components//BlueActionBox'
import { BackLink } from 'src/components/BackLink'
import { DefaultLayout } from 'src/components/DefaultLayout'
import { Divider } from 'src/components/Divider'
import { FieldLine } from 'src/components/FieldLine'
import { FieldValue } from 'src/components/FieldValue'
import { SectionHeader } from 'src/components/SectionHeader'
import { TagBox } from 'src/components/TagBox'
import { TooltipIcon } from 'src/components/TooltipIcon'
import { FieldLabel } from 'src/components/inputs/input-field/FieldLabel'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { LayoutWithStickyColumn } from 'src/components/layout/LayoutWithStickyColumn'
import { ApplicationMenuItemEntry } from 'src/components/layout/dashboard/ApplicationMenu'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { ProfileHeaderInfo } from 'src/components/profile/ProfileHeaderInfo'
import { ApplicationType, getAppPath } from 'src/contracts/applications'
import { OpportunityStatus } from 'src/contracts/opportunities'
import { history } from 'src/history'
import { useUserAuthentication } from 'src/hooks/useUserAuthentication'
import { getOpenOpportunitiesApplications } from 'src/api/open-opportunities/api'
import { Application } from 'src/contracts/open-opportunities/contracts'
import { OpportunityLocationTypeV2Enum, OpportunityResponseBody } from 'src/contracts/opportunities-manager/contracts'
import { Nullable } from 'src/types'
import { convertTimestampToDateString, formatUnixDate } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'

import { ReduxContext } from 'src/redux/Store'
import { CountryCitiesList } from './CountryCitiesList'
import { OfferBadges } from './OfferBadges'
import { ContentWrapper, WhiteContentContainer } from './opportunities'
import { locationLabel, parseHTMLString } from './utils'
import { SMEBadge } from '../open-opportunities/SMEBadge'
import { EarlyAccessBadge } from '../open-opportunities/EarlyAccessBadge'

type OpportunityDetailsProps = {
    applicationMenuItems?: ApplicationMenuItemEntry[]
    applicationName?: string
    applicationType?: ApplicationType
    dataTestId?: string
    error?: string
    notFoundBacklinkText?: string
    opportunity: Nullable<OpportunityResponseBody>
    secondCol: ReactNode
    shareModal: ReactNode
}

const Row = styled.div`
    display: flex;
    margin: 8px 0;
`

const LocationTooltip: FunctionComponent = () => (
    <Tooltip
        title={
            <span>
                Remote: expert works off-site
                <br />
                On site: expert is required in the office every day
                <br />
                Hybrid: a combination of on-site and remote, as specified by the employer
            </span>
        }
    >
        <span
            css={css`
                margin-left: 8px;
            `}
        >
            <TooltipIcon />
        </span>
    </Tooltip>
)

const OpportunityDetails: FunctionComponent<React.PropsWithChildren<OpportunityDetailsProps>> = ({
    applicationMenuItems,
    applicationName,
    applicationType,
    dataTestId,
    error,
    opportunity,
    secondCol,
    shareModal,
}) => {
    const query = useQuery()
    const theme = useTheme()

    const { isUserAuthorized, isSpecialistAccount } = useUserAuthentication()

    const log = useLogger('error')
    const { addError } = useNotifications()

    const [applications, setApplications] = useState<Nullable<Array<Application>>>(null)
    const [isFetchingApplications, setFetchingApplications] = useState<boolean>(false)

    const isInformationHidden = useMemo(() => opportunity && opportunity.smeCloud && opportunity.hiddenInformation, [opportunity])

    const isSmeCloud = useMemo(() => opportunity?.smeCloud || opportunity?.internalDetails?.smeCloud, [opportunity])

    const {
        selectors: {
            featureFlags: { payments },
        },
    } = useContext(ReduxContext)

    const loadApplications = useCallback(() => {
        if (opportunity?.opportunityId && isUserAuthorized && !isSpecialistAccount) {
            setFetchingApplications(true)
            getOpenOpportunitiesApplications(opportunity.opportunityId)
                .then(response => {
                    setFetchingApplications(false)
                    setApplications(response.applications.filter(application => application.appliedOn))
                })
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [opportunity?.opportunityId, isUserAuthorized, isSpecialistAccount, log, addError])

    useEffect(() => {
        loadApplications()
    }, [loadApplications])

    const onGoBack = useCallback(() => history.push(applicationType ? getAppPath(applicationType) : '/'), [applicationType])

    const onSite = opportunity?.location.locationTypeV2 && opportunity.location.locationTypeV2 !== OpportunityLocationTypeV2Enum.REMOTE

    const { minimumRate, maximumRate, minimumDealSize, maximumDealSize, currency } = opportunity?.opportunityDetails || {}

    const formattedDealSize =
        minimumDealSize && maximumDealSize
            ? `${minimumDealSize.toLocaleString('en')} - ${maximumDealSize.toLocaleString('en')} ${currency}`
            : minimumDealSize
            ? `Minimum ${minimumDealSize.toLocaleString('en')} ${currency}`
            : maximumDealSize
            ? `Maximum ${maximumDealSize.toLocaleString('en')} ${currency}`
            : ''

    const formattedCurrency =
        minimumRate !== undefined && minimumRate !== null && maximumRate !== undefined && maximumRate !== null
            ? `${minimumRate.toLocaleString('en')} - ${maximumRate.toLocaleString('en')} ${currency}`
            : minimumRate !== undefined && minimumRate !== null
            ? `Minimum ${minimumRate.toLocaleString('en')} ${currency}`
            : maximumRate !== undefined && maximumRate !== null
            ? `Maximum ${maximumRate.toLocaleString('en')} ${currency}`
            : ''

    return !opportunity && error === 'not found' ? (
        <DefaultLayout
            icon='alert'
            iconSize={35}
            title='404'
            description='Sorry, something went wrong on our end but we’re working to fix the problem. You can go back to the dashboard and try to access this page again, or wait a little while and try again later.'
            buttonText='Back to Opportunities'
            onButtonClick={onGoBack}
        />
    ) : !opportunity && error === 'closed' ? (
        <DefaultLayout
            icon='closed'
            iconSize={35}
            iconWrapperStyles={css`
                background-color: ${theme.colors.beige_2};
            `}
            title='Opportunity Closed'
            description='This opportunity has already been closed, which is why you can’t see it here. To view a list of open opportunities, click the button below.'
            buttonText='View Open Opportunities'
            onButtonClick={onGoBack}
        />
    ) : (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={applicationMenuItems}>
            {opportunity && (
                <div
                    css={css`
                        padding: 32px 24px 24px;
                        ${mqMax[1]} {
                            padding: 24px 16px;
                            ${(!isUserAuthorized || isSpecialistAccount) &&
                            css`
                                margin-top: 57px;
                            `}
                        }
                    `}
                >
                    <LayoutWithStickyColumn
                        style={css`
                            overflow-x: hidden;
                        `}
                        firstCol={
                            <Fragment>
                                <ContentWrapper data-testid={dataTestId}>
                                    {isUserAuthorized && !isSpecialistAccount && applicationType && (
                                        <BackLink
                                            style={css`
                                                padding-top: 0;
                                                margin-bottom: 20px;

                                                ${mqMax[2]} {
                                                    padding-top: 10px;
                                                }
                                            `}
                                            path={decodeURIComponent(query.get('prevPath') || getAppPath(applicationType))}
                                            text='Back to list'
                                            dataTestId='back'
                                        />
                                    )}
                                    {opportunity.opportunityStatus === OpportunityStatus.DRAFT && (
                                        <BlueActionBox
                                            withInfoIcon
                                            text='This is draft'
                                            styles={css`
                                                background-color: ${theme.colors.beige_2};
                                                margin-bottom: 16px;
                                                border-radius: 4px;
                                                padding: 8px;
                                            `}
                                        />
                                    )}
                                    {opportunity.internalDetails && (
                                        <Fragment>
                                            {opportunity.internalDetails.internalName && (
                                                <span
                                                    css={css`
                                                        display: block;
                                                        margin-bottom: 10px;
                                                        font-weight: 500;
                                                    `}
                                                >
                                                    {opportunity.internalDetails.internalName}{' '}
                                                    <span
                                                        css={css`
                                                            color: ${theme.colors.gray_3};
                                                            font-weight: 400;
                                                        `}
                                                    >
                                                        (Name visible only to your Company)
                                                    </span>
                                                </span>
                                            )}

                                            {opportunity.internalDetails.clientName && (
                                                <span
                                                    css={css`
                                                        display: block;
                                                        margin-bottom: 10px;
                                                        font-weight: 500;
                                                    `}
                                                >
                                                    {opportunity.internalDetails.clientName}{' '}
                                                    <span
                                                        css={css`
                                                            color: ${theme.colors.gray_3};
                                                            font-weight: 400;
                                                        `}
                                                    >
                                                        (Client name visible only to your Company)
                                                    </span>
                                                </span>
                                            )}
                                        </Fragment>
                                    )}
                                    <div
                                        css={css`
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: center;
                                            flex-wrap: wrap;
                                            ${mqMax[1]} {
                                                flex-direction: column-reverse;
                                                align-items: flex-start;
                                            }
                                        `}
                                    >
                                        <FlexBox
                                            css={css`
                                                margin: ${isUserAuthorized && !isSpecialistAccount ? '24px 0' : '0 0 24px'};
                                            `}
                                        >
                                            <OfferBadges
                                                applied={applications?.length || undefined}
                                                appliedOptions={applications || []}
                                                isLoadingApplied={isFetchingApplications}
                                                offerType={opportunity.opportunityDetails.offerType}
                                                vacancies={opportunity.opportunityDetails.numberOfVacancies}
                                            />
                                        </FlexBox>
                                        {payments && opportunity.earlyAccess ? <EarlyAccessBadge /> : null}
                                        {isSmeCloud ? <SMEBadge /> : null}
                                    </div>
                                    <h3
                                        css={css`
                                            margin-bottom: 10px;
                                        `}
                                    >
                                        {opportunity.opportunityTitle}
                                    </h3>
                                    <ProfileHeaderInfo
                                        elements={[
                                            {
                                                iconName: 'violet-calendar',
                                                label:
                                                    opportunity.opportunityDetails.startDate && opportunity.opportunityDetails.endDate
                                                        ? `${formatUnixDate(
                                                              opportunity.opportunityDetails.startDate,
                                                              'DD.MM.YYYY',
                                                          )} – ${formatUnixDate(opportunity.opportunityDetails.endDate, 'DD.MM.YYYY')}`
                                                        : null,
                                                tooltip: 'Project dates',
                                            },
                                            {
                                                iconName: 'experience',
                                                label: opportunity.jobDetails.minYearsOfExperience
                                                    ? opportunity.jobDetails.minYearsOfExperience > 1
                                                        ? `${opportunity.jobDetails.minYearsOfExperience} years`
                                                        : `${opportunity.jobDetails.minYearsOfExperience} year`
                                                    : '<1 year',
                                                tooltip: 'Experience',
                                            },
                                            {
                                                iconName: 'code-editor',
                                                label: opportunity.projectDetails.projectIndustry,
                                                tooltip: 'Industry',
                                            },
                                        ]}
                                    />
                                </ContentWrapper>

                                <WhiteContentContainer
                                    css={css`
                                        padding-bottom: 220px;
                                        padding-top: 12px;
                                    `}
                                >
                                    <ContentWrapper>
                                        <div>
                                            <Row
                                                css={css`
                                                    flex-wrap: wrap;
                                                    align-items: center;
                                                    margin-bottom: 12px;
                                                `}
                                            >
                                                <FieldLabel
                                                    label={`${
                                                        locationLabel[
                                                            opportunity.location.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE
                                                        ]
                                                    }:`}
                                                    styles={css`
                                                        width: 120px;
                                                        margin-bottom: 0;

                                                        & + strong {
                                                            white-space: pre-wrap;
                                                        }
                                                    `}
                                                />
                                                <div
                                                    css={css`
                                                        display: flex;
                                                        align-items: center;
                                                    `}
                                                >
                                                    {onSite ? (
                                                        <CountryCitiesList countryCitiesList={opportunity.location.countriesWithCities} />
                                                    ) : (
                                                        opportunity.location.regions
                                                            .concat(opportunity.location.countriesWithCities.map(place => place.country))
                                                            .join(', ') || 'Anywhere'
                                                    )}
                                                    <LocationTooltip />
                                                </div>
                                            </Row>

                                            <Row>
                                                <FieldLabel
                                                    label='Deal Size:'
                                                    styles={css`
                                                        width: 120px;
                                                    `}
                                                />
                                                <div
                                                    css={css`
                                                        font-weight: 500;
                                                    `}
                                                >
                                                    {formattedDealSize}
                                                </div>
                                            </Row>

                                            <Row>
                                                <FieldLabel
                                                    label='Rate:'
                                                    styles={css`
                                                        width: 120px;
                                                    `}
                                                />
                                                <div
                                                    css={css`
                                                        font-weight: 500;
                                                        filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                                    `}
                                                >
                                                    {formattedCurrency}
                                                </div>
                                            </Row>
                                        </div>

                                        {(opportunity.jobDetails.jobDescription ||
                                            opportunity.jobDetails.technicalRequirements ||
                                            opportunity.jobDetails.requiredTechnicalSkills.length > 0 ||
                                            opportunity.jobDetails.keyTechnicalSkills.length > 0 ||
                                            opportunity.jobDetails.preferredTechnicalSkills.length > 0 ||
                                            opportunity.jobDetails.requiredCertifications ||
                                            opportunity.jobDetails.mainResponsibilities) && <SectionHeader>Job Details</SectionHeader>}
                                        {opportunity.jobDetails.jobDescription && (
                                            <Fragment>
                                                <FieldLabel label='Job Description' />
                                                <FieldValue>{parseHTMLString(opportunity.jobDetails.jobDescription)}</FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.technicalRequirements && (
                                            <Fragment>
                                                <FieldLabel label='Technical Requirements' />
                                                <FieldValue>{parseHTMLString(opportunity.jobDetails.technicalRequirements)}</FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.requiredTechnicalSkills.length > 0 && (
                                            <Fragment>
                                                <FieldLabel label='Required Technical Skills' />
                                                <FieldValue>
                                                    <TagBox
                                                        tags={opportunity.jobDetails.requiredTechnicalSkills}
                                                        style={css`
                                                            filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                                        `}
                                                    />
                                                </FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.keyTechnicalSkills.length > 0 && (
                                            <Fragment>
                                                <FieldLabel label='Key Skills' />
                                                <FieldValue>
                                                    <TagBox
                                                        tags={opportunity.jobDetails.keyTechnicalSkills}
                                                        style={css`
                                                            filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                                        `}
                                                    />
                                                </FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.preferredTechnicalSkills.length > 0 && (
                                            <Fragment>
                                                <FieldLabel label='Preferred Technical Skills' />
                                                <FieldValue>
                                                    <TagBox
                                                        tags={opportunity.jobDetails.preferredTechnicalSkills}
                                                        style={css`
                                                            background-color: transparent;
                                                            filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                                        `}
                                                    />
                                                </FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.requiredCertifications && (
                                            <Fragment>
                                                <FieldLabel label='Required Certifications' />
                                                <FieldValue>{opportunity.jobDetails.requiredCertifications}</FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.jobDetails.mainResponsibilities && (
                                            <Fragment>
                                                <FieldLabel label='Main Responsibilities' />
                                                <FieldValue>{parseHTMLString(opportunity.jobDetails.mainResponsibilities)}</FieldValue>
                                            </Fragment>
                                        )}
                                        <SectionHeader>Location Requirements</SectionHeader>
                                        <FieldLabel label='Location' />
                                        <FieldValue>
                                            <FlexBox
                                                css={css`
                                                    flex-wrap: wrap;
                                                    padding-bottom: 4px;
                                                `}
                                                alignItems='center'
                                            >
                                                <FieldLabel
                                                    label={`${
                                                        locationLabel[
                                                            opportunity.location.locationTypeV2 || OpportunityLocationTypeV2Enum.REMOTE
                                                        ]
                                                    }:`}
                                                    styles={css`
                                                        margin-bottom: 0;
                                                    `}
                                                />
                                                <FlexBox alignItems='center'>
                                                    {onSite ? (
                                                        <CountryCitiesList countryCitiesList={opportunity.location.countriesWithCities} />
                                                    ) : (
                                                        opportunity.location.regions
                                                            .concat(opportunity.location.countriesWithCities.map(place => place.country))
                                                            .join(', ') || 'Anywhere'
                                                    )}
                                                    <LocationTooltip />
                                                </FlexBox>
                                            </FlexBox>
                                        </FieldValue>

                                        {opportunity.location.timeZone && (
                                            <Fragment>
                                                <FieldLabel label='Working timezone' />
                                                <FieldValue>{opportunity.location.timeZone}</FieldValue>
                                            </Fragment>
                                        )}
                                        {opportunity.location.otherLocationRequirementsText && (
                                            <Fragment>
                                                <FieldLabel label='Other' />
                                                <FieldValue>{opportunity.location.otherLocationRequirementsText}</FieldValue>
                                            </Fragment>
                                        )}

                                        {opportunity.projectDetails.projectDescription && (
                                            <div
                                                css={css`
                                                    background-color: ${theme.colors.beige_1};
                                                    padding: 24px;
                                                    border-radius: 4px;
                                                `}
                                            >
                                                <h6>About the project</h6>
                                                {opportunity.projectDetails.projectTitle && (
                                                    <div>
                                                        <strong>{opportunity.projectDetails.projectTitle}</strong> <br />{' '}
                                                    </div>
                                                )}

                                                <br />
                                                {parseHTMLString(opportunity.projectDetails.projectDescription)}
                                                <br />

                                                {opportunity.projectDetails.paymentPeriod && (
                                                    <div>
                                                        <FieldLabel label='Payment period' />
                                                        <strong>
                                                            {opportunity.projectDetails.paymentPeriod}
                                                            {opportunity.projectDetails.paymentPeriod > 1 ? 'Days' : 'Day'}
                                                        </strong>
                                                        <br />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        {isSmeCloud && opportunity?.contactDetails?.contactEmail && (
                                            <>
                                                <SectionHeader>Contact Details for Vendor Inquiries</SectionHeader>
                                                {opportunity?.contactDetails?.contactEmail && (
                                                    <FlexBox alignItems='center'>
                                                        <FieldLabel label='Email:' />
                                                        <div>
                                                            <FieldValue
                                                                css={css`
                                                                    margin-bottom: 4px;
                                                                    margin-left: 6px;
                                                                    filter: ${isInformationHidden ? ' blur(4px)' : 'none'};
                                                                `}
                                                            >
                                                                {opportunity.contactDetails.contactEmail}
                                                            </FieldValue>
                                                        </div>
                                                    </FlexBox>
                                                )}
                                            </>
                                        )}

                                        {opportunity.internalDetails && (
                                            <Fragment>
                                                <SectionHeader
                                                    css={css`
                                                        border-top: none;
                                                    `}
                                                >
                                                    Internal details
                                                </SectionHeader>
                                                {opportunity.internalDetails.generatedId && (
                                                    <Fragment>
                                                        <FieldLabel label='Opportunity ID' />
                                                        <FieldValue>{opportunity.internalDetails.generatedId}</FieldValue>
                                                    </Fragment>
                                                )}
                                                {opportunity.internalDetails.clientName && (
                                                    <Fragment>
                                                        <FieldLabel label='Client name' />
                                                        <FieldValue>{opportunity.internalDetails.clientName}</FieldValue>
                                                    </Fragment>
                                                )}
                                                {opportunity.internalDetails.internalName && (
                                                    <Fragment>
                                                        <FieldLabel label='Internal name' />
                                                        <FieldValue>{opportunity.internalDetails.internalName}</FieldValue>
                                                    </Fragment>
                                                )}
                                                {opportunity.internalDetails.internalNotes && (
                                                    <Fragment>
                                                        <FieldLabel label='Internal Notes (visible only to your Company)' />
                                                        <FieldValue>{opportunity.internalDetails.internalNotes}</FieldValue>
                                                    </Fragment>
                                                )}
                                                {opportunity.internalDetails.opportunityOwner.displayName && (
                                                    <Fragment>
                                                        <FieldLabel label='Opportunity Owner' />
                                                        <FieldValue>{opportunity.internalDetails.opportunityOwner.displayName}</FieldValue>
                                                    </Fragment>
                                                )}
                                                <Divider />
                                            </Fragment>
                                        )}

                                        <FieldLine
                                            style={css`
                                                margin: ${opportunity.internalDetails ? '0 0 20px' : '30px 0 20px'};
                                            `}
                                            dotStyle={css`
                                                margin: 0 24px;
                                            `}
                                            fields={[
                                                {
                                                    label: 'Added on',
                                                    value: convertTimestampToDateString(opportunity.createdAt),
                                                },
                                                {
                                                    label: 'Last update',
                                                    value: convertTimestampToDateString(opportunity.updatedAt),
                                                },
                                                ...(opportunity.readableId ? [{ label: 'Id', value: opportunity.readableId }] : []),
                                            ]}
                                        />
                                    </ContentWrapper>
                                </WhiteContentContainer>
                                {shareModal}
                            </Fragment>
                        }
                        secondCol={secondCol}
                    />
                </div>
            )}
        </DashboardLayout>
    )
}

export { OpportunityDetails }
