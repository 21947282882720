/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { RadioChangeEvent } from 'antd'
import { FunctionComponent, useCallback } from 'react'
import { RadioButton, RadioGroup } from '../../../../components/inputs/Radio'
import { mqMax } from '../../../../GlobalStyle'
import { StringMapping } from '../../../../types'
import { SkillRate } from '../contracts'

const defaultLabels: StringMapping = {
    [SkillRate.EVALUATOR]: 'I know better',
    [SkillRate.EQUAL]: 'Same level',
    [SkillRate.SPECIALIST]: 'They know better',
}

type SingleItemItemProps = {
    label: string
    id: string
    value?: SkillRate
    onChange: (skill: any) => void
    labels?: StringMapping
}

const mobileStylesDefinition = css`
    ${mqMax[1]} {
        flex: auto;
        padding: 12px;
        width: 100%;
        margin-bottom: 8px;
    }
`

const SingleItem: FunctionComponent<React.PropsWithChildren<SingleItemItemProps>> = ({
    id,
    onChange,
    value,
    labels = defaultLabels,
    label,
}) => {
    const handleChange = useCallback(
        (event: RadioChangeEvent) => {
            onChange({ answer: event.target.value, id })
        },
        [id, onChange],
    )

    const theme = useTheme()

    return (
        <div
            css={css`
                padding: 16px 0;
                margin-bottom: 16px;
                border-bottom: 1px solid ${theme.colors.gray_2};
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            `}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                `}
            >
                {label}
            </div>
            <div
                css={css`
                    min-width: 500px;
                `}
            >
                <RadioGroup
                    onChange={handleChange}
                    value={value}
                    styles={css`
                        display: flex;
                        justify-content: stretch;
                        min-width: 500px;

                        ${mqMax[1]} {
                            margin-top: 16px;
                            flex-direction: column;
                        }

                        .ant-radio-button-wrapper {
                            justify-content: center;
                        }
                    `}
                >
                    <RadioButton
                        style={css`
                            flex: 1;

                            ${mobileStylesDefinition}
                        `}
                        value={SkillRate.EVALUATOR}
                        label={labels[SkillRate.EVALUATOR]}
                        checked={value === SkillRate.EVALUATOR}
                    />

                    <RadioButton
                        style={css`
                            flex: 1;

                            ${mobileStylesDefinition}
                        `}
                        value={SkillRate.EQUAL}
                        label={labels[SkillRate.EQUAL]}
                        checked={value === SkillRate.EQUAL}
                    />

                    <RadioButton
                        style={css`
                            flex: 1;

                            ${mobileStylesDefinition}
                        `}
                        value={SkillRate.SPECIALIST}
                        label={labels[SkillRate.SPECIALIST]}
                        checked={value === SkillRate.SPECIALIST}
                    />
                </RadioGroup>
            </div>
        </div>
    )
}

export { SingleItem }
