/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment from 'moment'
import nl2br from 'nl2br'
import { FunctionComponent } from 'react'
import { SpecialistProject } from '../../../contracts/specialist/specialistProject'
import { mqMax } from '../../../GlobalStyle'
import { COLOR_PALETTE } from '../../../theme/colors'
import { monthFormat } from '../../../utils/dates'

type ProfileExportProjectItemProps = {
    project: SpecialistProject
}

const ProfileExportProjectItem: FunctionComponent<React.PropsWithChildren<ProfileExportProjectItemProps>> = ({ project }) => {
    return (
        <section
            data-avoid-break
            className='project'
            css={css`
                padding-bottom: 8mm;
                width: 100%;
                content: ${project.id as string};
            `}
        >
            <div
                css={css`
                    font-size: 12px;
                    font-weight: 500;
                    margin: 12px 0;
                    content: ${project.role as string};
                `}
            >
                {project.role}
            </div>
            <div
                css={css`
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    width: 100%;
                    height: 1rem;
                    content: ${project.company as string};
                `}
            >
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        content: ${project.company as string};
                    `}
                >
                    <div
                        css={css`
                            font-size: 10px;
                            font-weight: 500;
                            white-space: nowrap;
                            content: ${project.company as string};
                        `}
                    >
                        {project.company}
                    </div>
                    <div
                        css={css`
                            width: 2px;
                            height: 2px;
                            background-color: ${COLOR_PALETTE.gray_3};
                            margin: 0 10px;
                            border-radius: 1px;
                            box-sizing: border-box;
                        `}
                    />

                    <div
                        css={css`
                            display: flex;
                            ${mqMax[1]} {
                                margin: 4px 0;
                                content: ${project.industry as string};
                            }
                        `}
                    >
                        <div
                            css={css`
                                color: ${COLOR_PALETTE.gray_4};
                                font-size: 10px;
                                margin-right: 4px;
                            `}
                        >
                            Industry
                        </div>
                        <div
                            css={css`
                                display: inline-block;
                                font-size: 10px;
                                white-space: nowrap;
                            `}
                        >
                            {project.industry ? project.industry : '-'}
                        </div>
                    </div>
                </div>
                <div
                    css={css`
                        font-size: 10px;
                        white-space: nowrap;
                        min-width: 110px;
                        flex-grow: 1;
                        text-align: right;
                        content: ${project.id as string};
                    `}
                >
                    <span>
                        {typeof project.from === 'string'
                            ? moment(project.from).format(monthFormat)
                            : project.from && moment.unix(project.from || 0).format(monthFormat)}{' '}
                        -{' '}
                    </span>
                    <span>
                        {typeof project.to === 'string'
                            ? moment(project.to).format(monthFormat)
                            : project.to
                            ? moment.unix(project.to).format(monthFormat)
                            : 'Now'}
                    </span>
                </div>
            </div>
            <p
                css={css`
                    color: ${COLOR_PALETTE.gray_4};
                    font-size: 10px;
                    /* max-width: calc(140mm - 70px); */
                    margin-top: 14px;
                    overflow: hidden;
                    content: ${project.description};
                `}
                dangerouslySetInnerHTML={{
                    __html: nl2br(project.description ? `Description of a project: ${project.description}` : ' - '),
                }}
            ></p>
            <div
                css={css`
                    border-bottom: 1px solid ${COLOR_PALETTE.gray_1};
                `}
            ></div>
        </section>
    )
}

export { ProfileExportProjectItem }
