import { Collapse } from 'antd'

import { StyledSectionHeader, StyledCollapse } from './styles'
import { OPPORTUNITITES } from './consts'
import { useHandleQuestionClick } from './hooks'

const Opportunites = ({ setQuestion, question }: { setQuestion: (q: string) => void; question: string | undefined }) => {
    const onQuestionClick = useHandleQuestionClick(setQuestion)

    return (
        <StyledCollapse defaultActiveKey={question} onChange={onQuestionClick}>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_APP_OVERVIEW}
                header={<StyledSectionHeader>App overview</StyledSectionHeader>}
            >
                <p>
                    The Open Opportunities application allows you to search and apply for various opportunities. You can also view the
                    history and status of your past applications on the My Applications tab.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_INFORMATION_TO_APPLY}
                header={<StyledSectionHeader>What information do I need in order to apply?</StyledSectionHeader>}
            >
                <p>
                    Our application flow requires just the minimum information from you in order to start the application process. You can
                    either apply with one of your existing specialists that was previously added to the platform or simply drag and drop a
                    CV of a specialist. You need to provide the following information for each candidate:
                </p>
                <ul>
                    <li>First and last name</li>
                    <li>Role and CV</li>
                    <li>Preferred and negotiable rates</li>
                    <li>Availability dates</li>
                    <li>Location (only for a country with fully remote opportunities)</li>
                </ul>
                <p>
                    There is also a step which involves rating the skills required for an opportunity you’re applying for, but this is
                    completely optional.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_APPLICATION}
                header={<StyledSectionHeader>How do I ensure that my application is processed correctly and fast?</StyledSectionHeader>}
            >
                <p>Here are several golden rules that can help speed up the application process:</p>
                <ol>
                    <li>
                        Location. Some of our projects have specific requirements due to the specialist's location. Please make sure that
                        the location of your specialist meets them.
                    </li>
                    <li>
                        Validation process. Please be aware that each client has a different validation process. We do our best to make this
                        as quick as possible.
                    </li>
                    <li>
                        Updates. We understand that our industry is very dynamic. Please, keep us updated if anything changes in your
                        application.
                    </li>
                    <li>
                        Requirements. Main technical requirements are defined in an open opportunity description. Please, rate them during
                        the application. It will speed up the process and offers a better chance for a positive outcome.
                    </li>
                    <li>
                        CVs. Most of our clients are international. Please, remember that a CV and other application documents should be in
                        English.
                    </li>
                    <li>
                        Rates. Our goal is to make the process transparent in any area, including rates. If a specialist’s rate exceeds the
                        budget, we can't guarantee that he/she will be presented to the client. The final rate can be different based on the
                        result of technical verification.
                    </li>
                </ol>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_APPLICATION_FLOW}
                header={<StyledSectionHeader>What does the application flow look like from start to finish?</StyledSectionHeader>}
            >
                <p>
                    After applying, your candidate will be screened and updated by our Matching team within the next 72 hours. If a
                    submitted Specialist/team meets the project's requirements, they are further presented to the client and join the
                    validation process. It can include stages such as a technical interview, a coding challenge, portfolio verification,
                    etc. The exact stages of the process can vary depending on the client. If the client's decision is positive, the
                    Matching Team will set the project's terms with you. These include rate, start date, duration, and equipment policy. If
                    a client's decision is negative, you'll be provided with feedback regarding your specialist. You will get an email
                    update from Talent Alpha in case any of the following happens: your specialist wins an application or gets rejected; an
                    opportunity is closed or put on hold by the client
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_MULTIPLE_OPPORTUNITIES}
                header={<StyledSectionHeader>Can I apply for multiple opportunities with the same specialist?</StyledSectionHeader>}
            >
                <p>
                    You absolutely can! Just make sure the availability dates are all set correctly. Your specialist can participate in
                    multiple processes at the same time.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_UPDATES}
                header={<StyledSectionHeader>Will I receive any updates when my specialist gets hired/rejected?</StyledSectionHeader>}
            >
                <p>
                    You will get an automatic email update from the Talent Alpha platform in case your specialist wins the opportunity, gets
                    rejected, if you withdraw the candidate or in the case when the opportunity is closed or put on hold. Talent Alpha team
                    may also contact you in case additional details are needed or in order to facilitate setting up an interview call.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_WITHDRAW}
                header={<StyledSectionHeader>How can I withdraw from an application?</StyledSectionHeader>}
            >
                <p>
                    In order to withdraw your candidate from the application process, please go to the My Applications tab located in the
                    Open Opportunities app, find your candidate there and click the Withdraw Candidate icon (on the right).
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_APPLYING}
                header={
                    <StyledSectionHeader>
                        Will applying with a specialist make their data visible to other users of the platform?
                    </StyledSectionHeader>
                }
            >
                <p>
                    All of the data you provide when applying for an opportunity will be visible to the members of the Talent Alpha team and
                    will be shared with the client when a specialist profile passes the initial review process. A specialist’s data on the
                    platform will never be shared with anyone else nor will it be made publicly available on the platform unless you
                    explicitly choose to do so.
                </p>
            </Collapse.Panel>
            <Collapse.Panel
                key={OPPORTUNITITES.OPPORTUNITITES_DOCUMENTS}
                header={<StyledSectionHeader>Which documents need to be signed?</StyledSectionHeader>}
            >
                <p>
                    You can start exploring the Talent Alpha platform without signing any documents at all! But once you find an opportunity
                    you like or would like to start sharing your available specialists on our Talent Marketplace and receive bookings from
                    potential clients, you will need to sign a Master Service Agreement. This is a document containing general terms and
                    conditions of your cooperation with Talent Alpha. After your specialist passes all the required stages of the
                    application, you will be contacted by our Project Management team. You will need to sign a Statement of Work document
                    which contains the details of the client’s project, as well as the terms on which the project will be executed.
                    Depending on the Client and project’s requirements, there may be additional documents or policies to be signed by you or
                    your specialist. For the invoicing process, the specialist(s) will need to provide timesheets (using client’s or Talent
                    Alpha’s Excel template) at the end of each month. After that, the Talent Alpha team will also ask for an invoice.
                </p>
            </Collapse.Panel>
        </StyledCollapse>
    )
}

export { Opportunites }
