/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import isEqual from 'lodash.isequal'
import moment from 'moment'
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getShareMySpecialistsSyndicates } from 'src/api/api'
import { BlueActionBox } from 'src/components/BlueActionBox'
import { KeyInfoIndicator } from 'src/components/KeyInfoIndicator'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { ColorBackgroundWrapper, FullPageWrapper } from 'src/components/layout/ResponsiveWrapper'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { Pagination } from 'src/components/Pagination'
import { ScrollToTop } from 'src/components/ScrollUp'
import { SearchInput } from 'src/components/SearchInput'
import { TooltipIcon } from 'src/components/TooltipIcon'
import { AvailableOn as AvailableOnType } from 'src/contracts/search/availableOn'
import { breakpoints, mqMax, mqMin } from 'src/GlobalStyle'
import { useInlineEdit } from 'src/hooks/useInlineEdit'
import { useMySpecialistsMenuItems } from 'src/hooks/useMySpecialistsMenuItems'
import {
    bulkEditShareMySpecialistsSpecialists,
    getShareMySpecialistsFilters,
    getShareMySpecialistsSpecialistsProfiles,
    getShareMySpecialistsSpecialistsSelectAll,
    getShareMySpecialistsStatistics,
} from './share-my-specialists/api'
import { editShareMySpecialistsSpecialistProfile } from 'src/api/api'
import { BookNowResponseModal } from './share-my-specialists/components/BookNowResponseModal'
import { BulkEditBar } from './share-my-specialists/components/BulkEditBar'
import { Availability, AvailabilityEdit } from './share-my-specialists/components/cells/Availability'
import { AvailableOn, AvailableOnEdit } from './share-my-specialists/components/cells/AvailableOn'
import { Country, CountryEdit } from './share-my-specialists/components/cells/CountryEdit'
import { Currency, CurrencyEdit } from './share-my-specialists/components/cells/Currency'
import { Rate, RateEdit } from './share-my-specialists/components/cells/Rate'
import { Status, StatusEdit } from './share-my-specialists/components/cells/Status'
import { ShareMySpecialistsHeader } from './share-my-specialists/components/ShareMySpecialistsHeader'
import { ShareMySpecialistsResponsiveGrid } from './share-my-specialists/components/ShareMySpecialistsResponsiveGrid'
import { ShareMySpecialistsFiltersBar } from './share-my-specialists/components/ShareMySpecialistsSearchFiltersBar'
import { ProfileActionsEditRenderer, ProfileActionsRenderer } from './share-my-specialists/components/ProfileActionsRenderer'
import { SelectedSpecialists } from './share-my-specialists/components/SelectedSpecialists'
import { SpecialistsResultsWrapper } from './share-my-specialists/components/SpecialistsResultWrapper'
import { ShareMySpecialistsFilters, ShareMySpecialistsFormData, ShareMySpecialistsStatistics } from './share-my-specialists/contracts'
import { ShareMySpecialistsEditData, ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { createComparer } from 'src/utils/sorting'
import { countSelectedFilters, YellowDot } from './share-my-specialists/utils'
import { ReduxContext } from 'src/redux/Store'
import { Nullable, NullableArray } from 'src/types'
import { alphabeticallyAsc } from 'src/utils/sorting'
import { capitalizeFirstLetter } from 'src/utils/strings'
import { useLogger } from 'src/utils/useLogger'
import { CELL_REQUIRED, FIELD_REQUIRED } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'
import { TARate } from './components/TARate'

const schema = yup.object().shape({
    specialists: yup.array().of(
        yup.object().shape({
            rate: yup.number().required(CELL_REQUIRED).typeError(CELL_REQUIRED).max(999999, 'This value is too large'),
            availableFrom: yup.number().typeError(FIELD_REQUIRED).required(FIELD_REQUIRED),
            availableOn: yup.array().of(yup.string()).min(1, FIELD_REQUIRED).required(FIELD_REQUIRED),
            sharedStatus: yup.string().required(),
            country: yup.string().required(),
            currency: yup.string().required(),
        }),
    ),
})

const mapSpecialists = (specialists: Array<ShareMySpecialistsSpecialist>) => {
    return specialists.map(specialist => ({
        ...specialist,
        roleAndSeniority: `${specialist.role}||${specialist.seniority}`,
    }))
}

const initialFilters: ShareMySpecialistsFilters = {
    availableFrom: null,
    rateFrom: null,
    rateTo: null,
    roles: [],
    seniorities: [],
    shared: [],
    availableOn: [],
    currencies: [],
}

const defaultPageSize = 10

const ShareMySpecialistsPage = () => {
    const [pending, setPending] = useState<boolean>(true)
    const [searchText, setSearchText] = useState<string>('')
    const [filtersOpened, setFiltersOpened] = useState<boolean>(true)
    const [possibleFilters, setPossibleFilters] = useState(initialFilters)
    const [selectedFilters, setSelectedFilters] = useState(initialFilters)
    const [total, setTotal] = useState(0)
    const filtered = searchText !== '' || countSelectedFilters(selectedFilters) > 0
    const [specialists, setSpecialists] = useState<NullableArray<ShareMySpecialistsSpecialist>>(null)
    const [paging, setPaging] = useState({ page: 1, size: defaultPageSize })
    const [sorting, setSorting] = useState<Array<any>>([])
    const [selectedRowKeys, setSelectedRowKeys] = useState<Array<string>>([])
    const [isBulkEditRateValidationFailed, setIsBulkEditRateValidationFailed] = useState(false)

    const [ShareMySpecialistsStatistics, setShareMySpecialistsStatistics] = useState<Nullable<ShareMySpecialistsStatistics>>(null)
    const [syndicates, setSyndicates] = useState<Array<AvailableOnType>>([])

    const [bookNowResponseModalOpen, setBookNowResponseModalOpen] = useState<boolean>(false)
    const [bookNowResponseModalData, setBookNowResponseModalData] = useState<{ specialistId: string; bookingId: string }>({
        specialistId: '',
        bookingId: '',
    })

    const { menuItems, applicationName } = useMySpecialistsMenuItems()
    const {
        selectors: {
            featureFlags: { talentMarketplaceMargin },
        },
    } = useContext(ReduxContext)

    const availableOnOptions = useMemo(() => syndicates.map(syndicate => syndicate.id), [syndicates])

    const navigate = useNavigate()
    const theme = useTheme()
    const log = useLogger('error')

    const { handleEditingKeysChange, isEditing, control, getIndex, setValue, trigger, errors, getValues, watch } =
        useInlineEdit<ShareMySpecialistsFormData>({
            schema,
            fieldArrayName: 'specialists',
        })
    const { addSuccess, addError } = useNotifications()

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(!filtersOpened)
    }, [filtersOpened])

    const onChangeSearchText = useCallback(
        (text: string) => {
            setSearchText(text)
            setSelectedFilters(initialFilters)
            const newPaging = { page: 1, size: paging.size }
            setPaging(prev => (!isEqual(prev, newPaging) ? newPaging : prev))
        },
        [paging.size],
    )

    const onChangeFilters = useCallback((newFilters: ShareMySpecialistsFilters) => {
        setSelectedFilters(newFilters)
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters(initialFilters)
    }, [])

    const onChangePage = useCallback((page: any, size: any) => {
        setPaging({ page, size })
    }, [])

    const onChangePageSize = useCallback(
        (size: Array<string>) => {
            setPaging({ page: paging.page, size: Number(size.toString()) })
        },
        [paging.page],
    )

    useEffect(() => {
        if (total > 0 && specialists?.length === 0 && paging.page > 1) {
            setPaging(oldPaging => ({ page: 1, size: oldPaging.size }))
        }
    }, [paging.page, specialists?.length, total])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))

            const sortingWithRole = newSorting.map(sorter =>
                sorter.field === 'roleAndSeniority' ? { field: 'role', order: sorter.order } : sorter,
            )

            setSorting(sortingWithRole)
        } else if (newSorter) {
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []

            const sortingWithRole = newSorting.map(sorter =>
                sorter.field === 'roleAndSeniority' ? { field: 'role', order: sorter.order } : sorter,
            )
            setSorting(sortingWithRole)
        }
    }, [])

    const fetchPossibleFilters = useCallback(() => {
        const request = { searchText, ...initialFilters }

        getShareMySpecialistsFilters(request)
            .then(data => {
                setPossibleFilters(data)
            })
            .catch(err => {
                addError()
                log(err)
            })
    }, [addError, log, searchText])

    const fetchSyndicates = useCallback(() => {
        getShareMySpecialistsSyndicates()
            .then((data: Array<AvailableOnType>) => {
                setSyndicates(data)
            })
            .catch(err => {
                addError()
                log(err)
            })
    }, [addError, log])

    useEffect(() => {
        fetchSyndicates()
    }, [fetchSyndicates])

    const handleSelectAll = useCallback(() => {
        setPending(true)
        getShareMySpecialistsSpecialistsSelectAll({ searchText, ...selectedFilters })
            .then(data => {
                if (data?.specialistsIds) {
                    setSelectedRowKeys(data.specialistsIds)
                }
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => setPending(false))
    }, [setPending, searchText, selectedFilters, addError, log])

    const fetchShareMySpecialistsStatistics = useCallback(() => {
        setPending(true)

        getShareMySpecialistsStatistics()
            .then((statistics: ShareMySpecialistsStatistics) => {
                setShareMySpecialistsStatistics(statistics)
            })
            .catch(log)
            .finally(() => setPending(false))
    }, [setPending, log])

    const fetchSpecialists = useCallback(() => {
        setPending(true)
        getShareMySpecialistsSpecialistsProfiles({ searchText, ...selectedFilters, paging, sorting })
            .then(data => {
                if (data.specialists) {
                    setSpecialists(mapSpecialists(data.specialists))
                    setTotal(data.total)
                }
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => {
                setPending(false)
            })
    }, [searchText, selectedFilters, paging, sorting, addError, log])

    const fetchData = useCallback(() => {
        setPending(true)

        Promise.all([
            getShareMySpecialistsSpecialistsProfiles({ searchText, ...selectedFilters, paging, sorting }),
            getShareMySpecialistsStatistics(),
            getShareMySpecialistsFilters({ searchText, ...initialFilters }),
        ])
            .then(data => {
                const [specialistsObject, statistics, filters] = data

                if (specialistsObject.specialists) {
                    setSpecialists(mapSpecialists(specialistsObject.specialists))
                    setTotal(specialistsObject.total)
                }

                setShareMySpecialistsStatistics(statistics)
                setPossibleFilters(filters)
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => {
                setPending(false)
            })
    }, [addError, log, setPending, searchText, selectedFilters, paging, sorting])

    useEffect(() => {
        fetchShareMySpecialistsStatistics()
    }, [fetchShareMySpecialistsStatistics])

    useEffect(fetchSpecialists, [fetchSpecialists])

    useEffect(fetchPossibleFilters, [selectedFilters, fetchPossibleFilters])

    const wasDataTouched = useCallback(
        (id: string, data: ShareMySpecialistsEditData) => {
            const specialistData = specialists?.find(specialist => specialist.id === id)

            return !(
                specialistData?.rate === data.rate &&
                specialistData?.availableFrom === data.availableFrom &&
                specialistData?.availableOn.sort().toString() === data.availableOn?.sort().toString() &&
                specialistData?.currency === data.currency
            )
        },
        [specialists],
    )

    const wasStatusTouched = useCallback(
        (id: string, status?: Nullable<string>) => {
            const specialistData = specialists?.find(specialist => specialist.id === id)

            return specialistData?.sharedStatus !== status
        },
        [specialists],
    )

    const onSubmit = useCallback(
        (id: string) => {
            const index = getIndex(id)
            trigger([
                `specialists.${index}.rate`,
                `specialists.${index}.availableFrom`,
                `specialists.${index}.shared`,
                `specialists.${index}.availableOn`,
                `specialists.${index}.country`,
                `specialists.${index}.currency`,
            ]).then(() => {
                if (!errors?.specialists?.[index]) {
                    setPending(true)
                    const rate = getValues(`specialists.${index}.rate`)
                    const availableFrom = getValues(`specialists.${index}.availableFrom`)
                    const status = getValues(`specialists.${index}.shared`)
                    const availableOn = getValues(`specialists.${index}.availableOn`)
                    const country = getValues(`specialists.${index}.country`)
                    const currency = getValues(`specialists.${index}.currency`)

                    const data: ShareMySpecialistsEditData = {}
                    if (rate) {
                        data.rate = +rate
                    }
                    if (availableFrom) {
                        data.availableFrom = Number(
                            moment
                                .utc(new Date(availableFrom * 1000))
                                .startOf('day')
                                .format('X'),
                        )
                    }
                    if (status) {
                        data.shared = status
                    }
                    if (availableOn) {
                        data.availableOn = availableOn
                    }

                    data.country = country
                    data.currency = currency

                    editShareMySpecialistsSpecialistProfile(id, data)
                        .then(() => {
                            if (wasDataTouched(id, data)) {
                                addSuccess('Your change was made successfully.')
                            }
                            if (wasStatusTouched(id, status) && data.shared === 'SHARED') {
                                addSuccess(`Congrats, you've shared a Specialist on the Talent Marketplace!`)
                            } else if (wasStatusTouched(id, status) && data.shared === 'NOT_SHARED') {
                                addSuccess('Your Specialist is no longer shared on the Talent Marketplace.')
                            }

                            handleEditingKeysChange(id)
                        })
                        .then(() => {
                            fetchData()
                        })
                        .catch(err => {
                            addError()
                            log(err)
                            setPending(false)
                        })
                }
            })
        },
        [
            addError,
            addSuccess,
            errors,
            fetchData,
            getIndex,
            getValues,
            handleEditingKeysChange,
            log,
            trigger,
            wasDataTouched,
            wasStatusTouched,
        ],
    )

    const onEditInMySpecialistsClick = useCallback(
        (specialistId: string) => {
            navigate(`/my-specialists/specialist/${specialistId}`)
        },
        [navigate],
    )

    const clearRowSelection = useCallback(() => {
        setSelectedRowKeys([])
    }, [])

    const bulkEditSpecialists = useCallback(
        (requestBody: any) => {
            setPending(true)

            bulkEditShareMySpecialistsSpecialists(requestBody)
                .then(() => {
                    clearRowSelection()
                    fetchData()
                    addSuccess('Your changes were made successfully. ')
                })
                .catch(err => {
                    if (err.status === 400) {
                        setIsBulkEditRateValidationFailed(true)
                    } else {
                        addError()
                        log(err)
                    }
                })
                .finally(() => setPending(false))
        },
        [addError, addSuccess, clearRowSelection, fetchData, log],
    )
    const handleCloseBookNowResponseModal = useCallback(() => {
        setBookNowResponseModalOpen(false)
    }, [])

    const columns: ColumnsType<ShareMySpecialistsSpecialist> = useMemo(
        () => [
            {
                title: 'Name',
                dataIndex: 'name',
                render: (_: string, record: ShareMySpecialistsSpecialist) => {
                    const name = `${record.firstName} ${record.lastName}`

                    return record.sharedStatus === 'BOOKING_REQUEST_RECEIVED' ? (
                        <FlexBox alignItems='center'>
                            <KeyInfoIndicator text={name} missingKeyInfo={record.missingKeyInfo} />
                            <YellowDot />
                        </FlexBox>
                    ) : (
                        <KeyInfoIndicator text={name} missingKeyInfo={record.missingKeyInfo} />
                    )
                },
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`),
                    multiple: 1,
                },
                showSorterTooltip: false,
            },
            {
                title: 'Role',
                dataIndex: 'roleAndSeniority',
                sorter: {
                    compare: createComparer('role'),
                    multiple: 3,
                },
                showSorterTooltip: false,
                render: (roleAndSeniority: string) => {
                    const [role, seniority] = roleAndSeniority.split('||')

                    const RoleBadge = () => <div>{role}</div>
                    const SeniorityBadge = () => (
                        <div
                            css={css`
                                ${mqMin[1]} {
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: none;
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                    margin-top: 10px;
                                    margin-bottom: -2px;

                                    ${mqMax[1]} {
                                        display: block;
                                    }
                                `}
                            >
                                Seniority
                            </div>
                            {seniority !== 'null' ? capitalizeFirstLetter(seniority.toLocaleLowerCase()) : '-'}
                        </div>
                    )

                    return (
                        <Fragment>
                            <RoleBadge />
                            <SeniorityBadge />
                        </Fragment>
                    )
                },
            },
            {
                title: 'Country',
                dataIndex: 'country',
                sorter: {
                    compare: createComparer('country'),
                    multiple: 2,
                },
                showSorterTooltip: false,
                render: (_: string, record: ShareMySpecialistsSpecialist) => {
                    return (
                        <div
                            css={css`
                                min-width: 130px;
                            `}
                        >
                            {isEditing(record.id) ? (
                                <CountryEdit record={record} control={control} index={getIndex(record.id)} />
                            ) : (
                                <Country record={record} />
                            )}
                        </div>
                    )
                },
            },
            {
                title: 'Currency',
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <CurrencyEdit record={record} control={control} index={getIndex(record.id)} />
                    ) : (
                        <Currency record={record} />
                    ),
            },
            {
                title: 'Hourly Rate',
                dataIndex: 'rate',
                sorter: {
                    compare: createComparer('rate'),
                    multiple: 5,
                },
                showSorterTooltip: false,
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <RateEdit record={record} control={control} index={getIndex(record.id)} />
                    ) : (
                        <Rate record={record} />
                    ),
                align: 'center',
            },
            talentMarketplaceMargin
                ? {
                      title: 'TA Rate',
                      render: (_: any, record: ShareMySpecialistsSpecialist) => (
                          <TARate record={record} index={getIndex(record.id)} control={control} />
                      ),
                      align: 'center',
                  }
                : {},
            {
                title: 'Available From',
                dataIndex: 'availableFrom',
                sorter: {
                    compare: createComparer('availableFrom'),
                    multiple: 6,
                },
                showSorterTooltip: false,
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <AvailabilityEdit
                            availableDate={record.availableFrom}
                            control={control}
                            availableName='availableFrom'
                            index={getIndex(record.id)}
                        />
                    ) : (
                        <Availability availableDate={record.availableFrom} />
                    ),
            },
            {
                title: () => (
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                        `}
                    >
                        Available On
                        <Tooltip title='TA Human Cloud is the official name of the pool of Specialists currently available on Talent Alpha’s Talent Marketplace App.'>
                            <span>
                                <TooltipIcon />
                            </span>
                        </Tooltip>
                    </div>
                ),
                dataIndex: 'availableOn',
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <AvailableOnEdit
                            control={control}
                            availableOn={record.availableOn}
                            index={getIndex(record.id)}
                            setValue={setValue}
                            syndicates={syndicates}
                            options={availableOnOptions}
                        />
                    ) : (
                        <AvailableOn availableOn={record.availableOn} index={getIndex(record.id)} syndicates={syndicates} />
                    ),
            },
            {
                title: 'Status',
                dataIndex: 'sharedStatus',
                sorter: {
                    compare: createComparer('status'),
                    multiple: 8,
                },
                showSorterTooltip: false,
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <StatusEdit
                            control={control}
                            editing={isEditing(record.id)}
                            sharedStatus={record.sharedStatus}
                            index={getIndex(record.id)}
                            setValue={setValue}
                        />
                    ) : (
                        <Status
                            sharedStatus={record.sharedStatus}
                            id={record.id}
                            currentBookingRequestId={record.currentBookingRequestId}
                            setBookNowResponseModalOpen={setBookNowResponseModalOpen}
                            setBookNowResponseModalData={setBookNowResponseModalData}
                        />
                    ),
                width: 140,
            },
            {
                title: '',
                key: 'actions',
                render: (_: any, record: ShareMySpecialistsSpecialist) =>
                    isEditing(record.id) ? (
                        <ProfileActionsEditRenderer
                            record={record}
                            handleKeysChange={handleEditingKeysChange}
                            onSubmit={onSubmit}
                            watch={watch}
                            index={getIndex(record.id)}
                        />
                    ) : (
                        <ProfileActionsRenderer
                            sharedStatus={record.sharedStatus}
                            id={record.id}
                            handleKeysChange={handleEditingKeysChange}
                            onEditInMySpecialists={onEditInMySpecialistsClick}
                            currentBookingRequestId={record.currentBookingRequestId}
                            setBookNowResponseModalOpen={setBookNowResponseModalOpen}
                            setBookNowResponseModalData={setBookNowResponseModalData}
                        />
                    ),
            },
        ],
        [
            isEditing,
            control,
            getIndex,
            setValue,
            syndicates,
            availableOnOptions,
            handleEditingKeysChange,
            onSubmit,
            watch,
            onEditInMySpecialistsClick,
            theme.colors,
            talentMarketplaceMargin,
        ],
    )

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <ScrollToTop action={paging} />
            <ColorBackgroundWrapper
                css={css`
                    background-color: ${theme.colors.gray_1};
                    padding: 18px 16px 0;
                    ${mqMin[1]} {
                        border-bottom: 1px solid ${theme.colors.gray_2};
                    }
                `}
            >
                <div
                    css={css`
                        max-width: 1440px;
                        margin: 0 auto;
                        ${mqMax[1]} {
                            border-bottom: 1px solid ${theme.colors.gray_2};
                        }
                    `}
                >
                    <ShareMySpecialistsHeader statistics={ShareMySpecialistsStatistics} />

                    <SearchInput
                        initialText={searchText}
                        onSubmit={onChangeSearchText}
                        disabled={!specialists?.length && !filtered}
                        filtersOpened={filtersOpened}
                        onToggleFilters={onToggleFilters}
                        placeholder='Skills, tools, frameworks, etc.'
                        styles={css`
                            margin-bottom: 16px;
                        `}
                    />
                    <ShareMySpecialistsFiltersBar
                        opened={filtersOpened}
                        possibleFilters={possibleFilters}
                        selectedFilters={selectedFilters}
                        onChangeFilters={onChangeFilters}
                        onClearAll={onClearFilters}
                        syndicates={syndicates}
                    />
                </div>
            </ColorBackgroundWrapper>
            <div
                css={css`
                    position: relative;
                    margin: 0 auto 100px;
                    max-width: 1440px;
                `}
            >
                {talentMarketplaceMargin && (
                    <BlueActionBox
                        withInfoIcon
                        text={`A 10% margin will be added to each specialist's set rate when shared on Talent Marketplace.`}
                        styles={css`
                            padding: 10px;
                        `}
                    />
                )}
                <SpecialistsResultsWrapper total={total} filtered={filtered} pending={pending}>
                    <SelectedSpecialists
                        numberOfSelectedSpecialists={selectedRowKeys.length}
                        total={total}
                        allEditableSpecialists={ShareMySpecialistsStatistics?.allEditableSpecialists || 0}
                        handleSelectAll={handleSelectAll}
                        handleDeselectAll={clearRowSelection}
                    />

                    <ShareMySpecialistsResponsiveGrid
                        rowKey='id'
                        dataSource={specialists || []}
                        columns={columns}
                        pagination={false}
                        onChange={onChangeTable}
                        scroll={{ x: breakpoints[1] }}
                        selectedRowKeys={selectedRowKeys}
                        onRowSelectedChange={setSelectedRowKeys}
                    />

                    <FullPageWrapper>
                        <div
                            css={css`
                                margin: 40px 0 120px 0;
                            `}
                        >
                            <Pagination
                                pageSize={paging.size}
                                total={total}
                                onChange={onChangePage}
                                current={paging.page}
                                onChangePageSize={onChangePageSize}
                                css={css`
                                    ${mqMax[0]} {
                                        display: flex;
                                        justify-content: center;
                                    }
                                `}
                            />
                        </div>
                    </FullPageWrapper>
                    {selectedRowKeys.length > 0 && (
                        <BulkEditBar
                            selectedRecords={selectedRowKeys}
                            clearRowSelection={clearRowSelection}
                            bulkEditSpecialists={bulkEditSpecialists}
                            syndicates={syndicates}
                            options={availableOnOptions}
                            isBulkEditRateValidationFailed={isBulkEditRateValidationFailed}
                            setIsBulkEditRateValidationFailed={setIsBulkEditRateValidationFailed}
                        />
                    )}
                </SpecialistsResultsWrapper>
            </div>
            {bookNowResponseModalOpen && (
                <BookNowResponseModal
                    opened={bookNowResponseModalOpen}
                    bookNowResponseModalData={bookNowResponseModalData}
                    onClose={handleCloseBookNowResponseModal}
                    fetchSpecialists={fetchSpecialists}
                />
            )}
        </DashboardLayout>
    )
}

export { ShareMySpecialistsPage }
