/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Modal } from '../../../components/Modal'

type DeleteProfileModalProps = {
    deleteProfileHandler: () => void
    handleCloseConfirmation: () => void
    showDeleteConfirmation: boolean
}

const DeleteProfileModal: FunctionComponent<React.PropsWithChildren<DeleteProfileModalProps>> = ({
    deleteProfileHandler,
    handleCloseConfirmation,
    showDeleteConfirmation,
}) => {
    return (
        <Modal onClose={handleCloseConfirmation} opened={showDeleteConfirmation} dataTestId='delete-specialist-profile'>
            <h5>Are you sure about deleting?</h5>
            <p>Deleting Specialist is an irreversible action.</p>
            <Divider />
            <div
                css={css`
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                `}
            >
                <Button
                    variant='link'
                    onClick={handleCloseConfirmation}
                    style={css`
                        margin-right: 20px;
                    `}
                >
                    Cancel
                </Button>
                <Button
                    dataTestId='delete-profile-modal-delete'
                    css={css`
                        margin-right: 8px;
                    `}
                    onClick={deleteProfileHandler}
                    variant='delete'
                >
                    Delete this Specialist
                </Button>
            </div>
        </Modal>
    )
}

export { DeleteProfileModal }
