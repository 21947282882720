/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider } from '../../../components/Divider'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { SettingsWrapper } from '../../../components/layout/ResponsiveWrapper'
import { useUserCompanies } from '../../../hooks/useUserCompanies'
import { useUserContext } from '../../../hooks/useUserContext'
import { ReduxContext } from '../../../redux/Store'
import { LeavingConfirmationModal } from './components/LeavingConfirmationModal'
import { UserSegmentationForm } from './components/UserSegmentationForm'
import { VMCstatusEnum } from 'src/contracts/vmc'
import { getVMCUserStatus } from '../api'
import { useEffectOnce } from 'react-use'

const CompanyDetailsForm = () => {
    const navigate = useNavigate()

    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false)

    const { activeContext, isSuperAdmin } = useUserContext()
    const { hasInactiveCompany } = useUserCompanies()
    const {
        selectors: { whiteLabel, VMCstatus },
        actions: { setVMCstatus },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (!isSuperAdmin && hasInactiveCompany) {
            navigate('/dashboard/company-account/company-details')
        }
    }, [activeContext, hasInactiveCompany, navigate, isSuperAdmin])

    useEffectOnce(() => {
        getVMCUserStatus()
            .then(response => {
                setVMCstatus(response)
            })
            .catch(error => {
                console.log(error)
            })
    })

    const cancel = useCallback((): void => {
        navigate('/dashboard')
    }, [navigate])

    const hideLeavingConfirmationModal = useCallback((): void => {
        setSaveChangesModalVisible(false)
    }, [])

    const hasVmcStatus = useMemo(() => VMCstatus !== VMCstatusEnum.NONE, [VMCstatus])

    return (
        <Fragment>
            <DashboardLayout menuType={MenuType.companyAccount}>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                    `}
                >
                    <SettingsWrapper>
                        <h4>Company Details</h4>
                        {!whiteLabel?.readOnlySettings && <Divider />}

                        <section>
                            {!hasVmcStatus && (
                                <h5>
                                    {whiteLabel?.readOnlySettings
                                        ? 'Please provide additional details to finish the registration.'
                                        : 'What’s your main reason for using the Talent Alpha platform?'}
                                </h5>
                            )}
                            <UserSegmentationForm hasVmcStatus={hasVmcStatus} />
                        </section>
                    </SettingsWrapper>
                </div>
            </DashboardLayout>
            <LeavingConfirmationModal
                isVisible={saveChangesModalVisible}
                handleConfirmation={cancel}
                handleRejection={hideLeavingConfirmationModal}
            />
        </Fragment>
    )
}

export { CompanyDetailsForm }
