/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback } from 'react'
import { mqMax } from '../../GlobalStyle'
import { Button } from '../Button'

interface ApplicationEditActionsProps {
    applicationId: string
    handleEditingKeysChange: (id: string) => void
    handleSubmit: (id: string) => void
}

const ApplicationEditActions: FunctionComponent<React.PropsWithChildren<ApplicationEditActionsProps>> = ({
    applicationId,
    handleEditingKeysChange,
    handleSubmit,
}) => {
    const handleCancel = useCallback(() => {
        handleEditingKeysChange(applicationId)
    }, [applicationId, handleEditingKeysChange])

    const handleSave = useCallback(() => {
        handleSubmit(applicationId)
    }, [applicationId, handleSubmit])
    return (
        <Fragment>
            <div
                css={css`
                    justify-content: center;
                    min-height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    ${mqMax[1]} {
                        margin-top: 15px;
                        justify-content: space-between;
                    }
                `}
            >
                <Button
                    onClick={handleSave}
                    style={css`
                        margin-right: 16px;
                    `}
                    dataTestId='opportunity-save-status'
                >
                    Save
                </Button>
                <Button variant='link' onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Fragment>
    )
}

export { ApplicationEditActions }
