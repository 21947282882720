/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useBeforeUnload } from 'react-use'
import { history } from '../history'
import { Nullable } from '../types'
import { Button } from './Button'
import { Divider } from './Divider'
import { FlexBox } from './layout/FlexBoxHelpers'
import { Modal } from './Modal'

type RawLeavePageConfirmationModalType = {
    isModalVisible: boolean
    closeModal: () => void
    handleLeaveClick: () => void
    title?: string
    description?: string
    backButtonContent?: string
    leaveButtonContent?: string
}

const RawLeavePageConfirmationModal: FunctionComponent<React.PropsWithChildren<RawLeavePageConfirmationModalType>> = ({
    isModalVisible,
    closeModal,
    handleLeaveClick,
    title = 'Are you sure you want to leave?',
    description = 'The changes you made will not be saved.',
    backButtonContent = ' Cancel',
    leaveButtonContent = 'Leave',
}) => (
    <Modal opened={isModalVisible} onClose={closeModal}>
        <h5>{title}</h5>
        <p>{description}</p>
        <Divider />
        <FlexBox
            css={css`
                justify-content: flex-end;
                align-items: center;
            `}
        >
            <Button variant='link' onClick={closeModal}>
                {backButtonContent}
            </Button>
            <Button
                css={css`
                    margin-left: 32px;
                `}
                onClick={handleLeaveClick}
            >
                {leaveButtonContent}
            </Button>
        </FlexBox>
    </Modal>
)

type LeaveConfirmationModalProps = {
    title?: string
    description?: string
    backButtonContent?: string
    leaveButtonContent?: string
}

const LeavePageConfirmationModal: FunctionComponent<React.PropsWithChildren<LeaveConfirmationModalProps>> = ({
    description,
    title,
    backButtonContent,
    leaveButtonContent,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [forceLeave, setForceLeave] = useState(false)
    const [pathname, setPathname] = useState<Nullable<string>>(null)
    const closeModal = useCallback(() => {
        setIsModalVisible(false)
    }, [])
    const handleLeaveClick = useCallback(() => {
        setForceLeave(true)
    }, [])

    useEffect(() => {
        const unblock = history.block(tx => {
            setPathname(tx.location.pathname)
            if (!forceLeave) {
                setIsModalVisible(true)
                return false
            }
        })

        if (forceLeave) {
            unblock()
        }

        return () => {
            unblock()
        }
    }, [setIsModalVisible, forceLeave])

    useEffect(() => {
        if (forceLeave && pathname) {
            history.push(pathname)
        }
    }, [forceLeave, pathname])

    useBeforeUnload(true, 'Are you sure you want to leave? The changes you made will not be saved.')

    return (
        <RawLeavePageConfirmationModal
            isModalVisible={isModalVisible}
            closeModal={closeModal}
            handleLeaveClick={handleLeaveClick}
            description={description}
            backButtonContent={backButtonContent}
            leaveButtonContent={leaveButtonContent}
            title={title}
        />
    )
}
export { LeavePageConfirmationModal, RawLeavePageConfirmationModal }
