import { axios } from 'src/api/axios'
import { cvsPath } from '../../api/api'
import { CV, CVEditRequest, CvsFiltersResponse, CvsRequest, CvsSearchResponse } from './contracts'

const editCV = (data: CVEditRequest, id: string): Promise<CV> => {
    return axios.put(`${cvsPath}/${id}`, data)
}

const getCvs = (data: CvsRequest): Promise<CvsSearchResponse> => {
    return axios.post(`${cvsPath}:search`, data)
}

const getCvsFilters = (data: CvsRequest): Promise<CvsFiltersResponse> => {
    return axios.post(`${cvsPath}:filters`, data)
}

const downloadCV = (id: string): Promise<File> => {
    return axios.get(`${cvsPath}/${id}:downloadCv`, {
        responseType: 'blob',
    })
}

const uploadAnonymizedCV = (data: FormData, id: string): Promise<void> => {
    return axios.post(`${cvsPath}/${id}:uploadAnonymizedCv`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const verifyCV = (id: string): Promise<void> => {
    return axios.put(`${cvsPath}/${id}:markAsVerified`)
}

export { editCV, getCvs, getCvsFilters, downloadCV, uploadAnonymizedCV, verifyCV }
