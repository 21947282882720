/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Modal, ModalProps } from '../../../components/Modal'
import { ButtonContainer, GreyParagraph } from '../../../components/shared/shared-styles'

const NotAvailableModal: FunctionComponent<React.PropsWithChildren<ModalProps>> = ({ onClose, opened }) => {
    const navigate = useNavigate()

    const goToTalentMarketplace = useCallback(() => {
        navigate('/talent-marketplace')
    }, [navigate])

    return (
        <Modal onClose={onClose} opened={opened}>
            <h5>Sorry, this Specialist can't be booked at the moment.</h5>
            <GreyParagraph>
                Another User has sent this Specialist a Booking Request and the booking process may take several days to complete. You can
                save this Specialist's Profile to better see when they become available.
            </GreyParagraph>
            <GreyParagraph>
                To start searching for a similar available Specialist on our Talent Marketplace, click the link below.
            </GreyParagraph>

            <Divider />

            <ButtonContainer>
                <Button
                    css={css`
                        margin-right: 28px;
                    `}
                    variant='link'
                    onClick={onClose}
                >
                    Close
                </Button>
                <Button type='submit' variant='primary' onClick={goToTalentMarketplace}>
                    Search for a Similar Specialist
                </Button>
            </ButtonContainer>
        </Modal>
    )
}

export { NotAvailableModal }
