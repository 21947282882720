import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'src/components/Button'
import { blackToGrayFilter, IconButton } from 'src/components/Icon'
import { mqMax } from 'src/GlobalStyle'

const CVActionsWrapper = styled.div`
    justify-content: center;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    ${mqMax[1]} {
        margin-top: 15px;
    }
`

const DownloadIcon = styled(IconButton)`
    margin: 0 15px;
`

const UploadIcon = styled(IconButton)`
    margin-right: 15px;
`

const VerifyIcon = styled(IconButton)<{ disabled: boolean }>`
    ${({ disabled }) =>
        disabled &&
        css`
            filter: ${blackToGrayFilter};
            cursor: not-allowed;
            &:hover {
                background: none;
            }
        `}
`

const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

const CancelButton = styled(Button)`
    margin-right: 20px;
`

export { CVActionsWrapper, DownloadIcon, UploadIcon, VerifyIcon, ButtonsWrapper, CancelButton }
