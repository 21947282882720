/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import axios, { AxiosResponse } from 'axios'
import { Fragment, FunctionComponent, ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { signDocuments, signUp, signUpWithoutEmailVerification } from '../api/api'
import { signInWithEmailAndPassword } from '../api/firebase'
import { DocumentType } from '../contracts/documentsResponse'
import { config } from '../environment/config'
import { ReduxContext } from '../redux/Store'
import { emailRegex } from '../utils/regexes'
import { useLogger } from '../utils/useLogger'
import { AlternativeAuthMethods } from './AlternativeAuthMethods'
import { Button } from './Button'
import { Divider } from './Divider'
import { HiddenInput } from './HiddenInput'
import { Checkbox } from './inputs/Checkbox'
import { Input } from './inputs/Input'
import { AuthPageLayout } from './layout/AuthPageLayout'
import { FieldWrapper } from './layout/FormHelpers'
import { useNotifications } from './notification/NotificationProvider'
import { PasswordStrength } from './password/PasswordStrength'

const RegisterForm: FunctionComponent<
    React.PropsWithChildren<{
        isPwcSyndicate?: boolean
        buttonText?: string
        forcedEmail?: string
        styles?: SerializedStyles
        header?: ReactNode
        syndicate?: string | null
        isVMCinvitationPage?: boolean
    }>
> = ({ isPwcSyndicate, buttonText = 'Create an account', forcedEmail, styles, header, syndicate, isVMCinvitationPage = false }) => {
    const {
        actions: { layoutToggleLoader, setShouldWaitForUserContexts, setShouldWaitForWhiteLabel },
    } = useContext(ReduxContext)
    const [email, setEmail] = useState(forcedEmail || '')
    const [password, setPassword] = useState('')
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    const [emailErrorText, setEmailErrorText] = useState('')
    const [passwordErrorText, setPasswordErrorText] = useState('')
    const [passwordLengthValid, setPasswordLengthValid] = useState<boolean | null>(null)
    const [passwordDigitValid, setPasswordDigitValid] = useState<boolean | null>(null)
    const [passwordCapitalLetterValid, setPasswordCapitalLetterValid] = useState<boolean | null>(null)
    const [termsAcceptedError, setTermsAcceptedError] = useState('')

    const theme = useTheme()
    const navigate = useNavigate()
    const recaptchaRef = useRef(null)
    const { addError } = useNotifications()
    const log = useLogger()

    const isEmailValid = useCallback((): boolean => {
        let isValid = true

        if (email.length === 0) {
            setEmailErrorText('You must enter your email')
            isValid = false
        } else if (!emailRegex.test(email)) {
            setEmailErrorText('This email is invalid')
            isValid = false
        }

        return isValid
    }, [email])

    const isPasswordValid = useCallback((): boolean => {
        let isValid = true

        if (password.length < 8 || password.length > 24) {
            setPasswordLengthValid(false)
            isValid = false
        }

        if (!/\d/.test(password)) {
            setPasswordDigitValid(false)
            isValid = false
        }

        if (!/[A-Z]/.test(password)) {
            setPasswordCapitalLetterValid(false)
            isValid = false
        }

        if (!isValid) {
            setPasswordErrorText(' ')
        }

        return isValid
    }, [password])

    const areTermsAccepted = useCallback((): boolean => {
        if (!termsAccepted) {
            setTermsAcceptedError(' ')
            return false
        }

        return true
    }, [termsAccepted])

    const validateForm = useCallback((): boolean => {
        let isValid = true

        if (!isEmailValid()) {
            isValid = false
        }

        if (!isPasswordValid()) {
            isValid = false
        }

        if (!areTermsAccepted()) {
            isValid = false
        }

        return isValid
    }, [isEmailValid, isPasswordValid, areTermsAccepted])

    const onSubmit = useCallback(async () => {
        if (validateForm()) {
            ;(recaptchaRef.current as any).reset()
            try {
                await (recaptchaRef.current as any).execute()
            } catch (e) {
                log(e)
                addError()
            }
        }
    }, [addError, log, validateForm])

    const handleSubmit = useCallback((): void => {
        layoutToggleLoader(true)
        const docs: Array<DocumentType> = ['PRIVACY_POLICY', 'TERMS_OF_USE']

        if (newsletterAccepted) {
            docs.push('MARKETING_CONSENTS')
        }

        signDocuments(docs, email)
            .then(() => {
                if (forcedEmail) {
                    signUpWithoutEmailVerification(email, password)
                        .then(() => {
                            signInWithEmailAndPassword(email, password)
                                .then(response => {
                                    if (response?.code?.startsWith('auth/')) {
                                        addError()
                                    }
                                    if (response?.user) {
                                        setShouldWaitForUserContexts(true)
                                        if (response?.user?.emailVerified !== false) {
                                            setShouldWaitForWhiteLabel(true)
                                        }
                                    }
                                })
                                .catch(error => {
                                    log(error)
                                    addError()
                                })
                                .finally(() => layoutToggleLoader(false))
                        })
                        .catch(error => {
                            log(error)
                            addError()
                            layoutToggleLoader(false)
                        })
                } else {
                    signUp(email, password)
                        .then(() => {
                            const params = queryString.stringify({ email, syndicate }, { skipNull: true })
                            const url = `/register/confirmation?${params}`
                            navigate(url)
                        })
                        .catch(error => {
                            log(error)
                            addError()
                        })
                        .finally(() => layoutToggleLoader(false))
                }
            })
            .catch(error => {
                log(error)
                addError()
                layoutToggleLoader(false)
            })
    }, [
        layoutToggleLoader,
        newsletterAccepted,
        email,
        forcedEmail,
        password,
        addError,
        setShouldWaitForUserContexts,
        setShouldWaitForWhiteLabel,
        log,
        navigate,
        syndicate,
    ])

    const handleCaptchaChange = useCallback(
        async (token: string | null) => {
            if (token) {
                const captchaValid = await validateCaptcha(token)
                if (captchaValid) {
                    handleSubmit()
                }
            }
        },
        [handleSubmit],
    )

    const validateCaptcha = (token: string): Promise<boolean> => {
        return axios.get(`${config.apiUrl}/pub/verifyCaptchaToken?token=${token}`).then((response: AxiosResponse<{ success: boolean }>) => {
            return response.data.success
        })
    }

    const handlePasswordChange = useCallback(
        (value: string): void => {
            setPassword(value)

            if (passwordErrorText) {
                setPasswordErrorText('')
            }

            if (value.length >= 8 && value.length <= 24) {
                setPasswordLengthValid(true)
            } else {
                setPasswordLengthValid(null)
            }

            if (/\d/.test(value)) {
                setPasswordDigitValid(true)
            } else {
                setPasswordDigitValid(null)
            }

            if (/[A-Z]/.test(value)) {
                setPasswordCapitalLetterValid(true)
            } else {
                setPasswordCapitalLetterValid(null)
            }
        },
        [passwordErrorText],
    )

    const handleEmailChange = useCallback(
        (value: string): void => {
            if (!forcedEmail) {
                setEmail(value)

                if (emailErrorText) {
                    setEmailErrorText('')
                }
            }
        },
        [emailErrorText, forcedEmail],
    )

    const handleTermsChange = useCallback(
        (value: boolean): void => {
            setTermsAccepted(value)

            if (termsAcceptedError) {
                setTermsAcceptedError('')
            }
        },
        [termsAcceptedError],
    )

    useEffect(() => {
        if (forcedEmail) {
            setEmail(forcedEmail)
        }
    }, [forcedEmail])

    return (
        <AuthPageLayout>
            {header}
            <ReCAPTCHA
                sitekey={config.captchaSitekey}
                size='invisible'
                ref={recaptchaRef}
                badge='bottomright'
                onChange={handleCaptchaChange}
            />
            {!isPwcSyndicate && (
                <Fragment>
                    <div
                        css={css`
                            width: 100%;
                            color: ${theme.colors.gray_4};
                            margin-top: 4px;
                            margin-bottom: 16px;
                        `}
                    >
                        Already have an account?{' '}
                        <Link
                            to={'/?role=manager'}
                            css={css`
                                &:focus-visible {
                                    outline: 5px auto -webkit-focus-ring-color;
                                }
                            `}
                        >
                            Log In
                        </Link>
                    </div>
                    <Divider />
                </Fragment>
            )}
            <form
                css={css`
                    display: flex;
                    flex-direction: column;
                    ${styles}
                    ${isPwcSyndicate &&
                    css`
                        margin-top: 12px;
                    `}
                `}
                autoComplete='off'
                role='tablist'
            >
                <FieldWrapper>
                    <HiddenInput name='email' placeholder='Email' tabIndex={-1} />

                    <Input
                        name='email'
                        placeholder='Email'
                        label='Email'
                        onChange={handleEmailChange}
                        value={email}
                        errorMessage={emailErrorText}
                        dataTestId='login-input'
                        tabIndex={0}
                        autofocus={true}
                        disabled={!!forcedEmail}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <HiddenInput type='password' tabIndex={-1} />

                    <Input
                        name='password'
                        placeholder='Password'
                        label='Password'
                        type='password'
                        hasPassword={true}
                        errorMessage={passwordErrorText}
                        onChange={handlePasswordChange}
                        dataTestId='password-input'
                        tabIndex={0}
                    />
                </FieldWrapper>
                <PasswordStrength
                    indicators={{
                        minMax: passwordLengthValid,
                        oneCapital: passwordCapitalLetterValid,
                        oneDigit: passwordDigitValid,
                    }}
                />
                <Checkbox
                    name='terms'
                    style={css`
                        margin-bottom: ${isPwcSyndicate ? '22px' : '12px'};
                        input:focus-visible + & {
                            outline: 5px auto -webkit-focus-ring-color;
                        }
                    `}
                    tabIndex={0}
                    checkboxLabel={
                        <Fragment>
                            *I agree to the Talent Alpha's{' '}
                            <Link
                                css={css`
                                    text-decoration: underline;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                    &:focus-within {
                                        outline: 5px auto -webkit-focus-ring-color;
                                    }
                                `}
                                to='/documents/terms-of-use'
                                target='_blank'
                            >
                                Terms of Use
                            </Link>
                        </Fragment>
                    }
                    checked={termsAccepted}
                    onChange={handleTermsChange}
                    errorMessage={termsAcceptedError}
                    dataTestId='terms-of-use-check'
                />
                {!isPwcSyndicate && !isVMCinvitationPage && (
                    <Checkbox
                        name='newsletter'
                        style={css`
                            margin-bottom: 22px;
                            input:focus-visible + & {
                                outline: 5px auto -webkit-focus-ring-color;
                            }
                        `}
                        checkboxLabel='I want to sign up for the newsletter (optional)'
                        checked={newsletterAccepted}
                        onChange={setNewsletterAccepted}
                        tabIndex={0}
                    />
                )}

                <Button
                    variant='primary'
                    onClick={onSubmit}
                    style={css`
                        margin-bottom: 16px;
                    `}
                    dataTestId='create-account-submit'
                >
                    {buttonText}
                </Button>
                {!isVMCinvitationPage && <AlternativeAuthMethods />}
            </form>

            {forcedEmail && (
                <p
                    css={css`
                        margin-top: 16px;
                        font-size: 12px;
                    `}
                >
                    If you’re using Google or Microsoft authentication, please make sure to use the same email address you received the
                    invitation email at.
                </p>
            )}

            <small
                css={css`
                    display: block;
                    text-align: center;
                    color: ${theme.colors.gray_4};
                    width: 306px;
                    margin: 10px auto 0;
                `}
            >
                This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
            </small>
            <Link
                css={css`
                    font-size: 12px;
                    display: block;
                    text-align: center;
                    text-decoration: underline;
                    margin-top: 8px;

                    &:hover {
                        text-decoration: underline;
                    }
                `}
                to='/documents/privacy-policy'
                target='_blank'
            >
                Talent Alpha's Privacy Policy
            </Link>
        </AuthPageLayout>
    )
}

export { RegisterForm }
