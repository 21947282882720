/** @jsxImportSource @emotion/react */
import { useState, useMemo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { NavBar } from '../../components/NavBar'
import { FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { SECTIONS, QUESTION_PARAM_KEY, QUESTIONS_PARAM_TO_ENUM } from './consts'
import { questionToSection, getParam } from './utils'
import { ContentWrapper, StyledHeader } from './styles'
import { MySpecialists } from './MySpecialists'
import { TalentMarketplace } from './TalentMarketplace'
import { Opportunites } from './Opportunities'
import { ManageMyCompany } from './ManageMyCompany'
import { css } from '@emotion/react'
import { SubscriptionFAQ } from './SubscriptionFAQ'

const showSection = (section: string, setQuestion: (q: string) => void, question: string | undefined) => {
    switch (section) {
        case 'My Specialists':
            return <MySpecialists setQuestion={setQuestion} question={question} />
        case 'Talent Marketplace':
            return <TalentMarketplace setQuestion={setQuestion} question={question} />
        case 'Opportunities':
            return <Opportunites setQuestion={setQuestion} question={question} />
        case 'Manage my company':
            return <ManageMyCompany setQuestion={setQuestion} question={question} />
        case 'Subscription':
            return <SubscriptionFAQ setQuestion={setQuestion} question={question} />
        default:
            return null
    }
}

const Faq = () => {
    const [params, setParams] = useSearchParams()
    const question = useMemo(() => {
        const param = params.get(QUESTION_PARAM_KEY)

        if (!param) return param

        const enumifiedParam = QUESTIONS_PARAM_TO_ENUM[decodeURIComponent(param)]

        return decodeURIComponent(enumifiedParam)
    }, [params])

    const [section, setSection] = useState(questionToSection(question))
    const setQuestion = useCallback(
        (q: string) => {
            setParams({ [QUESTION_PARAM_KEY]: getParam(q) })
        },
        [setParams],
    )

    return (
        <DashboardLayout>
            <FullPageWrapper>
                <section
                    css={css`
                        max-width: 920px;
                    `}
                >
                    <StyledHeader>FAQ</StyledHeader>
                    <ContentWrapper>
                        <NavBar items={SECTIONS} handleClick={setSection} selectedItem={section} />
                        {showSection(section, setQuestion, question || undefined)}
                    </ContentWrapper>
                </section>
            </FullPageWrapper>
        </DashboardLayout>
    )
}

export { Faq }
