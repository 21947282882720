/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

type AlertProps = { content: string; type?: 'error' | 'success' | 'info'; dataTestId?: string }

const Alert: FunctionComponent<React.PropsWithChildren<AlertProps>> = ({ content, type = 'success', dataTestId = '' }) => {
    const theme = useTheme()

    const errorStyles = css`
        background-color: #ffe9e9;
        color: ${theme.colors.red_4};
    `

    const successStyles = css`
        background-color: ${theme.colors.main};
        color: ${theme.colors.text};
    `

    const infoStyles = css`
        background-color: ${theme.colors.blue_2};
        color: ${theme.colors.gray_6};
    `

    const getStyles = () => {
        switch (type) {
            case 'error':
                return errorStyles

            case 'success':
                return successStyles

            case 'info':
                return infoStyles

            default:
                throw new Error('Undefined alert type!')
        }
    }

    return (
        <div
            css={css`
                position: relative;
                text-align: center;
                padding: 8px;
                border-radius: 4px;
                font-size: 12px;

                ${getStyles()}
            `}
            dangerouslySetInnerHTML={{ __html: content }}
            data-testid={dataTestId}
        ></div>
    )
}

export { Alert }
