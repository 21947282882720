/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/es/table'

import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { mqMax, mqMin } from 'src/GlobalStyle'
import { BackLink } from 'src/components/BackLink'
import { Button } from 'src/components/Button'
import { DataNotFound } from 'src/components/DataNotFound'
import { Divider } from 'src/components/Divider'
import { YesNoCell } from 'src/components/MSA'
import { Pagination } from 'src/components/Pagination'
import { SearchInput } from 'src/components/SearchInput'
import { ResponsiveGrid } from 'src/components/data-grid/ResponsiveGrid'
import { AutocompleteSelectValuesTransformerEnum } from 'src/components/forms/ControlledAutocompleteSelect'
import { Switch } from 'src/components/inputs/switch/Switch'
import { KanbanCard } from 'src/components/kanban-board/contract'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { ColorBackgroundWrapper, FullPageWrapper } from 'src/components/layout/ResponsiveWrapper'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { FiltersBar, FiltersFunctions } from 'src/components/opportunities/FiltersBar'
import { InviteToOpportunityModal } from 'src/components/profile/InviteToOpportunityModal'
import { getAppPath } from 'src/contracts/applications'
import { SortEntry } from 'src/contracts/common/sorting'
import { ReduxContext } from 'src/redux/Store'
import { COLOR_PALETTE } from 'src/theme/colors'
import { Nullable } from 'src/types'
import { getRate } from 'src/utils/application'
import { convertTimestampToDateString } from 'src/utils/dates'
import { countArrayFilters } from 'src/utils/filters'
import { rationalNumbersRegex } from 'src/utils/regexes'
import { alphabeticallyAsc, numberAsc } from 'src/utils/sorting'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'
import { getOpportunityApplicationsFilters, searchOpportunityApplications } from '../../../api/opportunities-manager/api'
import { ApplicationActions } from '../../../components/opportunities-manager/ApplicationActions'
import { ApplicationEditActions } from '../../../components/opportunities-manager/ApplicationEditActions'
import { ApplicationStatusCell } from '../../../components/opportunities-manager/ApplicationStatusCell'
import { ApplicationsFiltersBar } from '../../../components/opportunities-manager/ApplicationsFiltersBar'
import { ControlledAutocompleteSelectInlineEdit } from '../../../components/opportunities-manager/ControlledAutocompleteSelectInlineEdit'
import { ControlledInputInlineEdit } from '../../../components/opportunities-manager/ControlledInputInlineEdit'
import { OpportunitiesKanban } from '../../../components/opportunities-manager/OpportunitiesKanban'
import {
    Application,
    ApplicationStatusEnum,
    ApplicationStatusMapping,
    SearchApplicationsFilters,
    SearchApplicationsFiltersOptions,
    SearchApplicationsRequestParams,
    SearchApplicationsResponseBody,
    applicationStatusesList,
} from '../../../contracts/opportunities-manager/contracts'
import {
    parseQueryToOpportunitiesManagerApplicationsSearchRequest,
    stringifyOpportunitiesManagerApplicationsSearchRequestToQuery,
} from '../../../components/opportunities-manager/utils/applicationsSearchUtils'
import { menuItems } from '../../../components/opportunities-manager/utils/menuItems'
import { useApplicationUpdate } from '../../../components/opportunities-manager/utils/useApplicationUpdate'
import { useApplicationsInlineEdit } from '../../../components/opportunities-manager/utils/useApplicationsInlineEdit'

const DEFAULT_ITEMS_PER_PAGE = 10

const defaultFilterOptions: SearchApplicationsFiltersOptions = {
    cities: [],
    countries: [],
    partnerNames: [],
    roles: [],
    statuses: [],
    maxRate: null,
    minRate: null,
}

const OpportunityApplicationsPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const { addError } = useNotifications()
    const log = useLogger('error')

    const {
        selectors: {
            featureFlags: { opportunitiesKanban },
        },
    } = useContext(ReduxContext)
    const { opportunityId } = useParams<{ opportunityId: string }>()
    const [sorting, setSorting] = useState<Array<SortEntry>>([])
    const [applications, setApplications] = useState<Nullable<Array<Application>>>()
    const [opportunityData, setOpportunityData] =
        useState<Nullable<Omit<SearchApplicationsResponseBody, 'applications' | 'total' | 'opportunityId'>>>()
    const [isFetchingApplications, setIsFetchingApplications] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [isKanbanBoard, setIsKanbanBoard] = useState(false)
    const [kanbanIncrementFlag, setKanbanIncrementFlag] = useState(0)
    const forceKanbanRerender = () => setKanbanIncrementFlag(f => f + 1)
    const [inviteToOpportunityModal, setInviteToOpportunityModal] = useState(false)
    const [choosenApplicationId, setChoosenApplicationId] = useState('')

    const navigate = useNavigate()
    const { search, pathname, hash } = useLocation()
    const query = useQuery()

    const appPath = getAppPath('OPPORTUNITIES_MANAGER')

    useEffect(() => {
        if (search) {
            setShowFilters(true)
        }
    }, [search])

    const searchRequest = useMemo<Omit<SearchApplicationsRequestParams, 'sorting'>>(
        () => parseQueryToOpportunitiesManagerApplicationsSearchRequest(search),
        [search],
    )

    const [currentPage, setCurrentPage] = useState<number>(searchRequest.paging?.page || 1)
    const [pageSize, setPageSize] = useState<number>(searchRequest.paging?.size || DEFAULT_ITEMS_PER_PAGE)

    const searchFilters = useMemo((): SearchApplicationsFilters => {
        const { searchText: s, paging: p, ...onlyFilters } = searchRequest
        return onlyFilters
    }, [searchRequest])

    const [selectedFilters, setSelectedFilters] = useState<SearchApplicationsFilters>(searchFilters)
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText || '')

    useEffect(() => {
        const completeRequest = { searchText, paging: { page: currentPage, size: pageSize }, sorting, ...selectedFilters }
        const newSearchQuery = stringifyOpportunitiesManagerApplicationsSearchRequestToQuery(completeRequest, query.get('prevPath'))
        navigate({ search: newSearchQuery }, { replace: true })
    }, [currentPage, navigate, searchText, selectedFilters, pageSize, sorting, query])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const [filtersOptions, setFiltersOptions] = useState<SearchApplicationsFiltersOptions>(defaultFilterOptions)

    const loadFilters = useCallback(() => {
        if (opportunityId) {
            getOpportunityApplicationsFilters(opportunityId)
                .then(setFiltersOptions)
                .catch(err => {
                    addError()
                    log(err)
                })
        }
    }, [addError, log, opportunityId])

    useEffect(() => {
        loadFilters()
    }, [loadFilters])

    const onChangeFilters = useCallback((filters: SearchApplicationsFilters) => {
        setSelectedFilters(filters)
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        setPageSize(Number(size.toString()))
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters({
            countries: [],
            statuses: [],
            availableFrom: null,
            availableTo: null,
            maxRate: null,
            minRate: null,
            msa: null,
            cities: [],
            partnerNames: [],
            roles: [],
        })
    }, [])
    const filtersApplied = useMemo(() => {
        let counter = 0

        counter += countArrayFilters(selectedFilters, ['countries', 'statuses', 'cities', 'partnerNames', 'roles'])
        ;['availableFrom', 'availableTo', 'maxRate', 'minRate', 'msa'].forEach(filterName => {
            if (selectedFilters[filterName as keyof SearchApplicationsFilters]) {
                counter++
            }
        })

        return counter > 0
    }, [selectedFilters])

    const [totalOpportunities, setTotalOpportunities] = useState<number>(0)
    const onChangePage = useCallback(setCurrentPage, [setCurrentPage])

    useEffect(() => {
        if (totalOpportunities > 0 && applications?.length === 0 && currentPage > 1) {
            setCurrentPage(1)
        }
    }, [applications?.length, currentPage, totalOpportunities])

    const loadApplications = useCallback(() => {
        if (opportunityId) {
            setIsFetchingApplications(true)

            searchOpportunityApplications(opportunityId, {
                ...selectedFilters,
                sorting,
                searchText,
                paging: {
                    page: currentPage,
                    size: pageSize,
                },
            })
                .then(data => {
                    setApplications(data.applications)
                    setTotalOpportunities(data.total)
                    setOpportunityData({
                        clientName: data.clientName,
                        currency: data.currency,
                        generatedId: data.generatedId,
                        internalName: data.internalName,
                        opportunityTitle: data.opportunityTitle,
                    })
                })
                .catch(err => {
                    addError()
                    log(err)
                })
                .finally(() => {
                    setIsFetchingApplications(false)
                })
        }
    }, [addError, currentPage, log, opportunityId, pageSize, searchText, selectedFilters, sorting])

    const { handleEditingKeysChange, isEditing, control, getIndex, setValue, trigger, errors, getValues } = useApplicationsInlineEdit()
    const afterUpdateApplicationRequestCallback = useCallback(
        (applicationId: string, application?: Application) => {
            if (!isKanbanBoard) {
                handleEditingKeysChange(applicationId)
            }
            if (application) {
                setApplications(prev => prev?.map(item => (item.applicationId === applicationId ? application : item)) || null)
            }
        },
        [handleEditingKeysChange, isKanbanBoard],
    )
    const { handleApplicationChange, ApplicationStatusUpdateRejectionReasonModal } = useApplicationUpdate({
        afterRequestCallback: afterUpdateApplicationRequestCallback,
        onsStatusUpdateFailure: forceKanbanRerender,
        applicationData: { applications, currency: opportunityData?.currency },
    })

    const onApplicationChange = useCallback(
        (applicationId: string) => {
            const index = getIndex(applicationId)
            trigger([`applications.${index}.status`, `applications.${index}.internalRate`]).then(() => {
                if (!errors?.applications?.[index]) {
                    const internalRate = +getValues(`applications.${index}.internalRate`)
                    const status = getValues(`applications.${index}.status`)
                    const application = applications?.find(app => app.applicationId === applicationId)
                    const statusChanged = status !== application?.recentStatus.status
                    const rateChanged = internalRate !== (application?.internalRate || 0)
                    if (statusChanged || rateChanged) {
                        handleApplicationChange(applicationId, statusChanged ? status : undefined, rateChanged ? internalRate : undefined)
                    } else {
                        afterUpdateApplicationRequestCallback(applicationId)
                    }
                }
            })
        },
        [afterUpdateApplicationRequestCallback, applications, errors?.applications, getIndex, getValues, handleApplicationChange, trigger],
    )

    const handleChooseApplicationId = (applicationId: string) => {
        setChoosenApplicationId(applicationId)
    }

    const handleInviteModalOpen = (isOpen: boolean) => {
        setInviteToOpportunityModal(isOpen)
    }

    const handleInviteModalClose = () => {
        setInviteToOpportunityModal(false)
        setChoosenApplicationId('')
    }

    const columns: ColumnsType<Application> = useMemo(() => {
        const fullColumns: ColumnsType<Application> = [
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`),
                },
                showSorterTooltip: false,
                render: (_, data) => `${data.lastName} ${data.firstName}`,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.role || '', b.role || ''),
                },
                showSorterTooltip: false,
            },

            {
                title: 'Partner Name',
                dataIndex: 'partnerName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.partnerName || '', b.partnerName || ''),
                },
                showSorterTooltip: false,
            },

            {
                title: 'MSA',
                dataIndex: 'msa',
                sorter: true,
                showSorterTooltip: false,
                render: (msa: boolean) => <YesNoCell yes={msa} />,
            },

            {
                title: 'Country',
                dataIndex: 'location',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.country || '', b.country || ''),
                },
                showSorterTooltip: false,
                render: (_, data) => data.country,
            },
            {
                title: 'Availability From',
                dataIndex: 'availableFrom',
                sorter: {
                    compare: (a, b) => numberAsc(a.availableFrom || 0, b.availableFrom || 0),
                },
                render: (availableFrom: number) => (availableFrom ? convertTimestampToDateString(availableFrom) : '-'),
                showSorterTooltip: false,
            },
            {
                title: `Rate (${opportunityData?.currency}/h)`,
                dataIndex: 'rate',
                sorter: {
                    compare: (a, b) => numberAsc(a.preferredRate || 0, b.preferredRate || 0),
                },
                render: (_, data) => <span>{getRate(data, { showCurrency: false })}</span>,
                showSorterTooltip: false,
            },
            {
                title: `TA Rate (${opportunityData?.currency}/h)`,
                dataIndex: 'internalRate',
                sorter: {
                    compare: (a, b) => numberAsc(a.internalRate || 0, b.internalRate || 0),
                },
                showSorterTooltip: false,
                render: (_, data) =>
                    isEditing(data.applicationId) ? (
                        <ControlledInputInlineEdit
                            control={control}
                            fieldName={`applications.${getIndex(data.applicationId)}.internalRate`}
                            initialValue={data.internalRate ? data.internalRate.toString() : ''}
                            placeholder='TA Rate'
                            regex={rationalNumbersRegex}
                        />
                    ) : (
                        <span data-testid='internal-rate'>{data.internalRate || '-'}</span>
                    ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.recentStatus.label || '', b.recentStatus.label || ''),
                },
                showSorterTooltip: false,
                render: (_, data) =>
                    isEditing(data.applicationId) ? (
                        <ControlledAutocompleteSelectInlineEdit
                            control={control}
                            options={applicationStatusesList}
                            fieldName={`applications.${getIndex(data.applicationId)}.status`}
                            setValue={setValue}
                            initialValue={data.recentStatus.status}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            selectedLabelTransformer={val => ApplicationStatusMapping[val as string]}
                        />
                    ) : (
                        <ApplicationStatusCell status={data.recentStatus} />
                    ),
            },
            {
                title: '',
                dataIndex: 'actions',
                render: (_, data) => {
                    const isInvited = data.recentStatus.status === ApplicationStatusEnum.INVITED
                    return isEditing(data.applicationId) ? (
                        <ApplicationEditActions
                            applicationId={data.applicationId}
                            handleEditingKeysChange={handleEditingKeysChange}
                            handleSubmit={onApplicationChange}
                        />
                    ) : (
                        <ApplicationActions
                            applicationId={data.applicationId}
                            disabled={isInvited}
                            tooltip={
                                isInvited ? "You can't edit the invited candidates until their manager accepts the invite." : undefined
                            }
                            handleEditingKeysChange={handleEditingKeysChange}
                            handleChooseApplicationId={handleChooseApplicationId}
                            handleInviteModalOpen={handleInviteModalOpen}
                            opportunityId={data.applicationId}
                        />
                    )
                },
                width: 80,
            },
        ]

        return fullColumns
    }, [opportunityData?.currency, isEditing, control, getIndex, setValue, handleEditingKeysChange, onApplicationChange])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            setCurrentPage(1)
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            setCurrentPage(1)
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    useEffect(() => {
        loadApplications()
    }, [loadApplications])

    const rowProps = useCallback(
        (record: any) => ({
            'data-testid': 'opportunity-table-row',
            onClick: () =>
                !isEditing(record.applicationId) &&
                navigate(
                    `${appPath}/opportunities/${opportunityId}/applications/${record.applicationId}?prevPath=${encodeURIComponent(
                        `${pathname}${encodeURIComponent(search)}${hash}`,
                    )}`,
                ),
        }),
        [isEditing, navigate, appPath, opportunityId, pathname, search, hash],
    )

    const onKanbanCardDrag = useCallback(
        (card: KanbanCard<Application>) => {
            handleApplicationChange(card.applicationId, card.columnId as ApplicationStatusEnum)
        },
        [handleApplicationChange],
    )

    return (
        <DashboardLayout applicationName='Opportunities Manager' applicationMenuItems={menuItems}>
            {!isFetchingApplications && !filtersApplied && !searchText && applications?.length === 0 ? null : (
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${COLOR_PALETTE.gray_1};
                        padding: 18px 16px 0;
                        ${mqMin[1]} {
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        }
                    `}
                >
                    <div
                        css={css`
                            max-width: 1440px;
                            margin: 0 auto;
                            ${mqMax[1]} {
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                            }
                        `}
                    >
                        <BackLink
                            style={css`
                                padding-top: 0;
                                margin-bottom: 10px;

                                ${mqMax[2]} {
                                    padding-top: 10px;
                                }
                            `}
                            path={decodeURIComponent(query.get('prevPath') || appPath)}
                            text='Back to list'
                            dataTestId='back'
                        />
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                ${mqMax[1]} {
                                    flex: none;
                                }
                                margin-bottom: 16px;
                            `}
                        >
                            <h4
                                css={css`
                                    margin: 0;
                                `}
                            >
                                {opportunityData
                                    ? `${opportunityData.clientName}: ${opportunityData.opportunityTitle} (${opportunityData.generatedId})`
                                    : 'My Applications'}
                            </h4>
                            <Link
                                to={`${appPath}/opportunities/${opportunityId}?prevPath=${encodeURIComponent(
                                    window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
                                )}`}
                                css={css`
                                    &:hover {
                                        text-decoration: none;
                                    }
                                `}
                            >
                                <Button variant='primary' dataTestId='opportunity-view-details'>
                                    View Opportunity Details
                                </Button>
                            </Link>
                        </div>
                        <SearchInput
                            initialText={searchText}
                            onSubmit={setSearchText}
                            filtersOpened={showFilters}
                            onToggleFilters={onToggleFilters}
                            placeholder='Opportunity Title, Internal Name, Client Name, etc.'
                            styles={css`
                                margin-bottom: 16px;
                            `}
                            dataTestId='opportunities-manager-search'
                        />
                        {showFilters && filtersOptions && opportunityData?.currency && (
                            <FiltersBar<SearchApplicationsFilters> selectedFilters={selectedFilters} onChangeFilters={onChangeFilters}>
                                {({ onSelectFilterChange, onValueChange, onDatePickerChange, onRangeFilterChange }: FiltersFunctions) => (
                                    <ApplicationsFiltersBar
                                        opened={showFilters}
                                        filtersOptions={filtersOptions}
                                        selectedFilters={selectedFilters}
                                        onClearAll={onClearFilters}
                                        onSelectFilterChange={onSelectFilterChange}
                                        onValueChange={onValueChange}
                                        onDatePickerChange={onDatePickerChange}
                                        onRangeFilterChange={onRangeFilterChange}
                                        currency={opportunityData.currency}
                                    />
                                )}
                            </FiltersBar>
                        )}
                        <Divider />
                        <div
                            css={css`
                                display: flex;
                                justify-content: ${isKanbanBoard ? 'flex-end' : 'space-between'};
                            `}
                        >
                            {!isKanbanBoard && (
                                <span data-testid='opportunities-manager-total-found'>
                                    {totalOpportunities} Result{totalOpportunities !== 1 && 's'} Found
                                </span>
                            )}
                            {opportunitiesKanban && (
                                <Switch
                                    leftText='View as kanban board'
                                    rightText=''
                                    checked={isKanbanBoard}
                                    onChange={setIsKanbanBoard}
                                    dataTestId='switch-kanban'
                                />
                            )}
                        </div>
                    </div>
                </ColorBackgroundWrapper>
            )}
            {applications &&
                totalOpportunities > 0 &&
                (isKanbanBoard ? (
                    <OpportunitiesKanban
                        applications={applications}
                        onDragEnd={onKanbanCardDrag}
                        kanbanIncrementFlag={kanbanIncrementFlag}
                    />
                ) : (
                    <Fragment>
                        <ResponsiveGrid
                            rowKey='applicationId'
                            dataSource={applications}
                            columns={columns}
                            pagination={false}
                            dataTestId='opportunities-table'
                            onRow={rowProps}
                            onChange={onChangeTable}
                            mobileBreakpoint={2}
                        />
                        <FullPageWrapper>
                            <div
                                css={css`
                                    margin: 40px 0 120px 0;
                                `}
                            >
                                <Pagination
                                    pageSize={pageSize}
                                    total={totalOpportunities}
                                    defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                                    onChangePageSize={onChangePageSize}
                                    onChange={onChangePage}
                                    current={currentPage}
                                    css={css`
                                        ${mqMax[0]} {
                                            display: flex;
                                            justify-content: center;
                                        }
                                    `}
                                />
                            </div>
                        </FullPageWrapper>
                    </Fragment>
                ))}
            {totalOpportunities === 0 && !isFetchingApplications && (
                <DataNotFound
                    styles={css`
                        margin-top: ${searchText || filtersApplied ? '50px' : '150px'};
                    `}
                    iconName='add-empty'
                    iconSize={85}
                    title={searchText || filtersApplied ? 'No Applications found' : 'No Applications'}
                    description={
                        searchText || filtersApplied
                            ? 'Try changing your filters or query'
                            : 'No one has applied for your Opportunities yet.'
                    }
                >
                    {!searchText && !filtersApplied && (
                        <Link
                            to={decodeURIComponent(query.get('prevPath') || appPath)}
                            css={css`
                                &:hover {
                                    text-decoration: none;
                                }
                            `}
                        >
                            <Button dataTestId='opportunity-manager-add'>Back to Opportunities</Button>
                        </Link>
                    )}
                </DataNotFound>
            )}
            {isFetchingApplications && <OverlayContentLoader />}
            {ApplicationStatusUpdateRejectionReasonModal}
            <InviteToOpportunityModal
                onClose={() => handleInviteModalClose()}
                opened={inviteToOpportunityModal}
                isCandidateView={true}
                applicationId={choosenApplicationId}
            />
        </DashboardLayout>
    )
}

export { OpportunityApplicationsPage }
