/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { TeamsList } from './components/TeamsList'

const ContinuousFeedback: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    return <TeamsList />
}

export { ContinuousFeedback }
