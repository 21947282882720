/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { ReduxContext } from '../../../redux/Store'
import { TabType } from './Header'
import { LeftMenu } from './LeftMenu'

type AnimatedLeftMenuProps = {
    show: boolean
}

const AnimatedLeftMenu: FunctionComponent<React.PropsWithChildren<AnimatedLeftMenuProps>> = ({ show }) => {
    const [shouldRender, setShouldRender] = useState(show)
    const ref = useRef(null)

    const {
        actions: { layoutToggleActiveTab },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (show) {
            setShouldRender(true)
        }
    }, [show])

    const onAnimationEnd = useCallback(() => {
        if (!show) {
            setShouldRender(false)
        }
    }, [show])

    const closeMenu = useCallback(() => {
        layoutToggleActiveTab(TabType.leftMenu)
    }, [layoutToggleActiveTab])

    useClickAway(ref, () => {
        closeMenu()
    })

    return (
        <Fragment>
            {shouldRender && (
                <section
                    onAnimationEnd={onAnimationEnd}
                    ref={ref}
                    css={css`
                        position: absolute;
                        min-height: 100%;
                        top: 0;
                        border-right: 1px solid ${COLOR_PALETTE.gray_2};
                        background-color: ${COLOR_PALETTE.white};
                        z-index: 12;
                        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
                        animation: ${show ? 'fadeIn' : 'fadeOut'} ease-in 0.1s;
                        ${mqMax[1]} {
                            width: 100%;
                        }

                        @keyframes fadeIn {
                            0% {
                                opacity: 0;
                            }
                            100% {
                                opacity: 1;
                            }
                        }

                        @keyframes fadeOut {
                            0% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0;
                            }
                        }
                    `}
                >
                    <LeftMenu />
                </section>
            )}
        </Fragment>
    )
}

export { AnimatedLeftMenu }
