/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { mqMax } from '../../GlobalStyle'
import { useMenuItemsSpecialist } from '../../hooks/useMenuItemsSpecialist'
import { DashboardLayout } from './dashboard/DashboardLayout'

type SpecialistPageWrapperProps = {
    style?: SerializedStyles
}

const SpecialistPageWrapper: FunctionComponent<React.PropsWithChildren<SpecialistPageWrapperProps>> = ({ children, style }) => {
    const { menuItems, applicationName } = useMenuItemsSpecialist()

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <div
                css={css`
                    padding: 24px;
                    ${mqMax[1]} {
                        margin-top: 57px;
                        padding: 24px 16px;
                    }
                    ${style}
                `}
            >
                {children}
            </div>
        </DashboardLayout>
    )
}

export { SpecialistPageWrapper }
