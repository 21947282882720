/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import skillsDiscoveryImg from '../../assets/images/skills_discovery_portrait.png'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../components/layout/Loader'
import { ScrollToTop } from '../../components/ScrollUp'
import { TilesView } from '../../components/TilesView'
import { SkillForm } from '../../contracts/skillForm'
import { Nullable } from '../../types'
import { getSpecialistSkillForms } from './api'
import { applicationName, generateMenuItems } from './skillsDiscoveryApp'

const SkillsDiscoveryPage = () => {
    const [skillForms, setSkillForms] = useState<Array<SkillForm>>([])
    const [isEachSkillFormSolved, setIsEachSkillFormSolved] = useState<Nullable<boolean>>(null)
    const navigate = useNavigate()
    const { specialistId } = useParams<{ specialistId: string }>()

    useEffect(() => {
        if (specialistId) {
            getSpecialistSkillForms(specialistId).then((sf: Array<SkillForm>) => setSkillForms(sf))
        }
    }, [specialistId])

    useEffect(() => {
        if (skillForms && skillForms.length > 0) {
            setIsEachSkillFormSolved(!skillForms.some(skillForm => !skillForm.isCompleted))
        }
    }, [skillForms])

    const onFillOutClick = (link: string, openInIframe: boolean): void => {
        if (openInIframe) {
            navigate(`/skills-discovery/${specialistId}/form?formLink=${link}`)
        } else {
            const url = new URL(link)
            navigate(url.pathname)
        }
    }

    useEffect(() => {
        if (isEachSkillFormSolved) {
            navigate('/specialist-dashboard')
        }
    }, [isEachSkillFormSolved, navigate])

    return (
        <Fragment>
            <ScrollToTop />
            <DashboardLayout
                applicationName={applicationName}
                applicationMenuItems={specialistId ? generateMenuItems(specialistId) : undefined}
                showMenuItems={false}
            >
                {isEachSkillFormSolved === null && <ContentLoader />}
                {isEachSkillFormSolved === false && (
                    <TilesView
                        forms={skillForms}
                        onFillOutClick={onFillOutClick}
                        title='Welcome to Skill Discovery'
                        description={
                            <p>
                                Below you will find a selection of Skill Forms that we’ve prepared for you based on your current role. Each
                                form will take you through several stages to help you map as much of your technical skills and knowledge as
                                possible. Please be as honest and as objective as possible.
                            </p>
                        }
                        sideImage={skillsDiscoveryImg}
                        sideTitle='About Skill Discovery'
                        sideDescription={[
                            {
                                content:
                                    'The Skill Discovery process is a dynamic process built around intelligent Skill Forms that are designed to help map your current technical skills, and knowledge of various tools, libraries and frameworks.',
                            },
                            {
                                content:
                                    'We are constantly developing the Skill Discovery process to make it more intuitive and faster. Look out for machine learning-enhanced Dynamic Skill Forms in the near future which will be even shorter forms that will be tailored to understanding your precise domain knowledge even better and faster.',
                            },
                            {
                                content:
                                    'Entering accurate data into our Skill Forms is absolutely crucial for both your current Manager as well as potential Clients who view your profile and want to get an accurate view of your current technical skills and abilities. We are building a complex a digital representation of your profile at Talent Alpha which is called a Talent Genome and you and your technical skillsare at the very heart of it.',
                            },
                        ]}
                        completedDescription={
                            <p>
                                You will not be able to edit a completed Skill Form once you have submitted your answers. You will see the
                                results of these forms on your profile page.
                            </p>
                        }
                        completedHint='Please fill out all of the Skill Forms prepared for you.'
                    />
                )}
            </DashboardLayout>
        </Fragment>
    )
}

export { SkillsDiscoveryPage }
