/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useMemo } from 'react'
import { Icon } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { SubtaskStatus, SubtaskType } from '../contracts'

type SubtaskProps = {
    subtask: SubtaskType
    isErrorPresent: boolean
}

const Subtask: FunctionComponent<React.PropsWithChildren<SubtaskProps>> = ({ subtask, isErrorPresent }) => {
    const theme = useTheme()

    const backgroundColor = useMemo(
        () =>
            isErrorPresent ? theme.colors.red_2 : subtask.status === SubtaskStatus.COMPLETED ? theme.colors.green_3 : theme.colors.violet_2,
        [subtask.status, theme, isErrorPresent],
    )

    return (
        <FlexBox
            alignItems='center'
            css={css`
                :not(:last-child) {
                    margin-bottom: 8px;
                }
            `}
        >
            <FlexBox
                alignItems='center'
                justifyContent='center'
                css={css`
                    width: 26px;
                    flex-shrink: 0;
                    height: 26px;
                    border-radius: 50%;
                    background-color: ${backgroundColor};
                    margin-right: 12px;
                `}
            >
                {subtask.status === SubtaskStatus.COMPLETED && <Icon name='check' />}
            </FlexBox>
            <p
                css={css`
                    margin: 0;
                    color: ${theme.colors.gray_6};
                    white-space: break-spaces;
                `}
            >
                {subtask.name}
            </p>
        </FlexBox>
    )
}

export { Subtask }
