import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { Autocomplete, Option } from '../../components/inputs/autocomplete/Autocomplete'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getAllSpecialists } from '../../api/open-opportunities/api'

type CandidatesDropdownProps = {
    handleApplyWithSpecialist: (specialistId: string) => void
    isSendingApplication: boolean
    opportunityId?: string
}

const CandidatesDropdown: FunctionComponent<React.PropsWithChildren<CandidatesDropdownProps>> = ({
    handleApplyWithSpecialist,
    isSendingApplication,
    opportunityId,
}) => {
    const log = useLogger('error')
    const { addError } = useNotifications()
    const [specialistsList, setSpecialistsList] = useState<Nullable<Array<Option>>>(null)

    const fetchSpecialists = useCallback(() => {
        if (opportunityId) {
            getAllSpecialists(opportunityId)
                .then(specialists =>
                    setSpecialistsList(
                        specialists.availableSpecialists.map(specialist => ({
                            label: `${specialist.firstName} ${specialist.lastName}`,
                            value: specialist.specialistId,
                            helperLabel: specialist.role,
                        })),
                    ),
                )
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, opportunityId])

    useEffect(() => {
        if (!isSendingApplication) {
            fetchSpecialists()
        }
    }, [fetchSpecialists, isSendingApplication])

    const handleCandidateSelect = useCallback(
        (specialistId: any) => {
            handleApplyWithSpecialist(specialistId)
        },
        [handleApplyWithSpecialist],
    )

    return (
        <Autocomplete
            disabled={isSendingApplication}
            value={[]}
            options={specialistsList}
            mode={null}
            filterOption={(input, option: Option) => (option.label as string).toLowerCase().includes(input.toLowerCase())}
            onChange={handleCandidateSelect}
            dataTestId='candidates-dropdown'
            placeholder='Choose existing specialist profile(s)'
        />
    )
}

export { CandidatesDropdown }
