import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { LanguageItemDetails } from 'src/components/LanguageItemDetails'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { LanguageResponse } from '../../../../contracts/profile/language'
import { NullableArray } from '../../../../types'
import { deleteSpecialistLanguage } from '../../api'
import { ItemBoxWrapper } from '../../shared/ProfileTabsHelpers'
import { LanguageItemForm } from './LanguageItemForm'

type LanguageItemProps = {
    languageEntry: LanguageResponse
    languageDictionary?: NullableArray<string>
    specialistId: string
    languages?: Array<LanguageResponse>
    fetchLanguages: () => void
    onSave?: () => void
    dataTestId?: string
}

const LanguageItem: FunctionComponent<React.PropsWithChildren<LanguageItemProps>> = ({
    languageEntry,
    languageDictionary = null,
    specialistId,
    languages,
    fetchLanguages,
    onSave,
    dataTestId = '',
}) => {
    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const { addSuccess, addError, removeAllNotifications } = useNotifications()

    const { id } = languageEntry

    useEffect(() => {
        fetchLanguages()
    }, [fetchLanguages])

    const toggleEditMode = useCallback(() => {
        setEditModeEnabled(currentEditModeEnabled => !currentEditModeEnabled)
    }, [])

    const handleDelete = useCallback(() => {
        removeAllNotifications()

        if (id) {
            deleteSpecialistLanguage(specialistId, id)
                .then(() => {
                    addSuccess('Language deleted!')
                    fetchLanguages()

                    if (onSave) {
                        onSave()
                    }
                })
                .catch(() => {
                    addError()
                })
        }
    }, [addError, addSuccess, fetchLanguages, id, onSave, removeAllNotifications, specialistId])

    return (
        <ItemBoxWrapper dataTestId={dataTestId}>
            {editModeEnabled ? (
                <LanguageItemForm
                    language={languageEntry}
                    languageDictionary={languageDictionary}
                    specialistId={specialistId}
                    languages={languages}
                    fetchLanguages={fetchLanguages}
                    onSave={onSave}
                    toggleEditMode={toggleEditMode}
                    handleDelete={handleDelete}
                />
            ) : (
                <LanguageItemDetails language={languageEntry} toggleEditMode={toggleEditMode} handleDelete={handleDelete} />
            )}
        </ItemBoxWrapper>
    )
}

export { LanguageItem }
