/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { Icon } from '../../../../components/Icon'
import { COLOR_PALETTE } from '../../../../GlobalStyle'
import { useLogger } from '../../../../utils/useLogger'
import { processDotMemory } from '../../api'
import { GameMatrix } from '../../contracts'
import { GameWrapper } from '../GameWrapper'

type SummaryProps = {
    results: Array<GameMatrix>
}

const Summary: FunctionComponent<React.PropsWithChildren<SummaryProps>> = ({ results }) => {
    const log = useLogger()

    useEffect(() => {
        processDotMemory(results).catch(log)
    }, [log, results])

    const accuracy = useMemo(() => {
        let clickableDotsCount = 0
        let clickedDotsCount = 0

        results.forEach(matrix => {
            matrix.forEach(row => {
                clickableDotsCount = clickableDotsCount + row.filter(dot => dot.isClickable).length
                clickedDotsCount = clickedDotsCount + row.filter(dot => dot.isClickable && dot.isSelected).length
            })
        })

        return Math.ceil((clickedDotsCount / clickableDotsCount) * 100)
    }, [results])

    const bestLevel = useMemo(() => {
        let level = 2
        let accuracy = 0

        results.forEach(result => {
            let clickableDotsCount = 0

            result.forEach(row => {
                row.forEach(dot => {
                    if (dot.isClickable) {
                        clickableDotsCount = clickableDotsCount + 1
                    }
                })
            })

            if (level <= clickableDotsCount) {
                level = clickableDotsCount

                let clickedDotsCount = 0
                result.forEach(row => {
                    clickableDotsCount = clickableDotsCount + row.filter(dot => dot.isClickable).length
                    clickedDotsCount = clickedDotsCount + row.filter(dot => dot.isClickable && dot.isSelected).length
                })
                const levelAccuracy = Math.ceil((clickedDotsCount / clickableDotsCount) * 100)
                if (levelAccuracy > accuracy) {
                    accuracy = levelAccuracy
                }
            }
        })

        return { level, accuracy }
    }, [results])

    return (
        <GameWrapper>
            <div
                css={css`
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 26px;
                `}
            >
                New Talent Identified!
            </div>

            <div
                css={css`
                    background-color: ${COLOR_PALETTE.beige_2};
                    padding: 35px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 25px;
                    `}
                >
                    <div
                        css={css`
                            background-color: ${COLOR_PALETTE.violet_2};
                            border-radius: 50%;
                            width: 67px;
                            height: 67px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 20px;
                        `}
                    >
                        <Icon name='chip' size={36} />
                    </div>
                    <div
                        css={css`
                            color: ${COLOR_PALETTE.violet_5};
                        `}
                        data-testid={'dot-memory-summary'}
                    >
                        Working Memory
                    </div>
                </div>
                <div>
                    <p>
                        You reached the level with {bestLevel.level} dots to memorize and your overall accuracy was {accuracy}%.
                    </p>
                    <p>
                        Working memory is the ability to temporarily store, maintain and manipulate information. This game measures how your
                        working memory can hold visual and spatial material. This ability is very helpful in the IT world and for
                        understanding mathematical, technical and engineering concepts.
                    </p>
                    <p>
                        We are still gathering information about other users’ performance. We’ll let you know how you compare with other
                        specialists soon.
                    </p>
                </div>
            </div>
        </GameWrapper>
    )
}
export { Summary }
