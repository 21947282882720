/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../theme/colors'
import girlWithGlasses from '../assets/images/girl_with_glasses.png'

const SpecialistLoginBanner: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <div
        css={css`
            height: 100%;
            background-color: ${COLOR_PALETTE.green_1};
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        `}
    >
        <div
            css={css`
                margin: 0 auto;
                max-width: 370px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            `}
        >
            <img
                src={girlWithGlasses}
                alt='girl and floating skill tags'
                css={css`
                    width: 100%;
                `}
            />
            <h4>What is the secret behind your talent genome?</h4>
            <p>
                When you fill in your skills and your experience, you gain deeper understanding of your technical competencies, social
                skills, cognitive abilities, and personality traits. Accordingly, your Talent Genome™ is key in identifying your skills,
                strengths, and potential development.
            </p>
        </div>
    </div>
)

export { SpecialistLoginBanner }
