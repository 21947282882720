const COLOR_PALETTE = {
    white: '#ffffff',
    black: '#000000',

    gray_6: '#13100D', // v2/gray_6
    gray_5: '#333333', // v2/gray_1_5
    gray_4: '#6F6F6F', // v2/gray_1_40
    gray_3: '#ACACAC', // v2/gray_1_30
    gray_2: '#E8E8E8', // v2/gray_1_20
    gray_1: '#F8F8F8', // v2/gray_1_10

    yellow_6: '#D9AD3D', // v2/yellow_6
    yellow_5: '#EFDB01', // v2/yellow_5
    yellow_4: '#FFE900', // v2/yellow_4
    yellow_3: '#F4F198', // v2/yellow_3
    yellow_2: '#F4F4CC', // v2/yellow_2
    yellow_1: '#FEFEF4', // v2/yellow_1

    beige_6: '#3D3329', // v2/beige_6
    beige_5: '#7A6652', // v2/beige_5
    beige_4: '#E0CCB8', // v2/beige_4
    beige_3: '#EEE7DF', // v2/beige_3
    beige_2: '#F7F1EC', // v2/beige_2
    beige_1: '#F6F5F4', // v2/beige_1

    green_6: '#015956', // v2/green_6
    green_5: '#329A91', // v2/green_5
    green_4: '#3EC1B6', // v2/green_4
    green_3: '#AEE4E0', // v2/green_3
    green_2: '#D9EFEE', // v2/green_2
    green_1: '#E7F4F3', // v2/green_1

    blue_6: '#1B4469', // v2/blue_6
    blue_5: '#2469A8', // v2/blue_5
    blue_4: '#82B5E4', // v2/blue_4
    blue_3: '#BEE0FF', // v2/blue_3
    blue_2: '#DFEFFF', // v2/blue_2
    blue_1: '#EBF5FF', // v2/blue_1

    violet_6: '#221240', // v2/violet_6
    violet_5: '#4F3A77', // v2/violet_5
    violet_4: '#77629D', // v2/violet_4
    violet_3: '#C0B7D1', // v2/violet_3
    violet_2: '#E9E4F1', // v2/violet_2
    violet_1: '#F4F2F8', // v2/violet_1

    red_6: '#500B0B', // v2/red_6
    red_5: '#980404', // v2/red_5
    red_4: '#C20000', // v2/red_4
    red_3: '#ECCECE', // v2/red_3
    red_2: '#F9E5E5', // v2/red_2
    red_1: '#FBF6F6', // v2/red_1

    box_shadow_color: 'rgba(0, 0, 0, 0.08)',

    orange_1: '#EB8C00',
} as const

export { COLOR_PALETTE }
