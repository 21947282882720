import { useCallback } from 'react'
import { config } from '../environment/config'
import { COLOR_PALETTE } from '../theme/colors'

type LogType = 'log' | 'error'

const colorSchema = (logType: LogType) => {
    switch (logType) {
        case 'log':
            return `background: ${COLOR_PALETTE.yellow_4}; color: ${COLOR_PALETTE.gray_6}`

        case 'error':
            return `background: ${COLOR_PALETTE.red_5}; color: ${COLOR_PALETTE.white}`
    }
}

const prefix = (logType: LogType) => {
    switch (logType) {
        case 'log':
            return `Debug`

        case 'error':
            return `Error`
    }
}

const log = (dataToLog: any, logType: LogType = 'log') => {
    if (config.environment === 'dev' || config.environment === 'stage') {
        if (Array.isArray(dataToLog)) {
            console.info(`%c ${prefix(logType)} `, colorSchema(logType), ...dataToLog)
        } else {
            console.info(`%c ${prefix(logType)} `, colorSchema(logType), dataToLog)
        }
    }
}

const useLogger = (type: LogType = 'log') => {
    return useCallback(
        (dataToLog: any, logType: LogType = type) => {
            log(dataToLog, logType)
        },
        [type],
    )
}

export { useLogger, log }
