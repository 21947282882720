import TagManager from 'react-gtm-module'
import { useEffectOnce } from 'react-use'

const useGoogleTagManager = () => {
    useEffectOnce(() => {
        if (window.navigator.userAgent !== 'Cypress') {
            TagManager.initialize({
                gtmId: 'GTM-WPVCLCF',
            })
        }
    })
}

export { useGoogleTagManager }
