import { Paging } from 'src/contracts/common/pagingProps'
import { SearchLocation } from 'src/contracts/common/searchLocation'
import { Sorting } from 'src/contracts/common/sorting'
import { CustomFieldValue } from 'src/contracts/customFields'
import { ParsedCv, ProcessingCv } from 'src/contracts/cvs'
import { Gender } from 'src/contracts/gender'
import { SocialLink } from 'src/contracts/socialLink'
import { Specialist } from 'src/contracts/specialist/specialist'
import { StringMapping } from 'src/types'

export type SpecialistProfileInformationRequest = {
    birthDate: number
    city?: string
    country?: string
    gender?: Gender
    phone: string
    postalCode?: string
    socialLinks: Array<SocialLink>
    street?: string
}

export type SpecialistProfileInformationResponse = SpecialistProfileInformationRequest & {
    id: string
}

export type SpecialistsFiltersOptions = {
    employmentTypes: Array<string>
    locations: Array<SearchLocation>
    roles: Array<string>
    seniorities: Array<string>
    skills: Array<string>
}

export type SpecialistsFilters = SpecialistsFiltersOptions & {
    remote: boolean
}

export type SpecialistsFiltersRequest = {
    searchText: string
}

export type SpecialistsFiltersResponse = SpecialistsFilters & {}

export interface SpecialistSearchText {
    searchText?: string
}

export interface SpecialistSearchRequest extends SpecialistSearchText, Partial<SpecialistsFilters>, Paging, Sorting {}

export interface SpecialistSearchResponse {
    specialists: Array<Specialist>
    total: number
}

export interface ProfileSummaryResponse {
    profileSummary: string
}

export type ImportErrors = {
    row: number
    errorType: string
    fieldName: string
    fieldContent?: string
}

export type ImportErrorResponse = {
    errors?: Array<ImportErrors>
}

export type ImportInProgressResponse = {
    inProgress: boolean
}

export type GetParsedCvsResponse = Array<ParsedCv>

export type GetProcessingCvsResponse = Array<ProcessingCv>

export interface ParsedSpecialist {
    cvParsingId: string
    firstName: string
    lastName: string
    role: string
    topSkills: Array<string>
}

export interface SpecialistDashboardAccess {
    accessGranted: boolean
}

export enum KeyInfo {
    BASIC_INFORMATION = 'BASIC_INFORMATION',
    UPLOAD_CV = 'UPLOAD_CV',
    LOCATION = 'LOCATION',
    EXPERIENCE = 'EXPERIENCE',
    EDUCATION = 'EDUCATION',
    SKILLS = 'SKILLS',
}

export const KeyInfoNameMapping: StringMapping = {
    [KeyInfo.BASIC_INFORMATION]: 'Basic Information',
    [KeyInfo.UPLOAD_CV]: 'Upload CV',
    [KeyInfo.LOCATION]: 'Location',
    [KeyInfo.EXPERIENCE]: 'Experience',
    [KeyInfo.EDUCATION]: 'Education',
    [KeyInfo.SKILLS]: 'Skills',
}

export const KeyInfoDescriptionMapping: StringMapping = {
    [KeyInfo.BASIC_INFORMATION]: 'Added when specialist profile is created',
    [KeyInfo.UPLOAD_CV]: 'Upload a PDF or DOCX file',
    [KeyInfo.LOCATION]: 'Add country',
    [KeyInfo.EXPERIENCE]: 'Add past projects',
    [KeyInfo.EDUCATION]: 'Add education and languages',
    [KeyInfo.SKILLS]: 'Add and rate specialist’s skills',
}

export type Section = {
    completed: boolean
    type: KeyInfo
}

export type KeyInfoResponse = {
    sections: Array<Section>
}

export type PersonalInformation = {
    birthDate: number
    city: string
    country: string
    customFields: Array<CustomFieldValue>
    email?: string
    employmentType: string
    firstName: string
    gender: Gender
    id: string
    lastName: string
    phone: string
    postalCode: string
    socialLinks: Array<SocialLink>
    street: string
}
