/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { DashboardLayout, MenuType } from '../../../components/layout/dashboard/DashboardLayout'
import { LegacyEmailNotifications } from '../../account/LegacyEmailNotifications'
import { MyAccountAccessLevelGuard } from './components/MyAccountAccessLevelGuard'
import { useContext } from 'react'
import { ReduxContext } from 'src/redux/Store'
import { EmailNotifications } from 'src/pages/account/EmailNotifications'

const EmailNotificationSettings = () => {
    const {
        selectors: {
            featureFlags: { payments },
        },
    } = useContext(ReduxContext)
    return (
        <MyAccountAccessLevelGuard>
            <DashboardLayout menuType={MenuType.myAccount}>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                    `}
                >
                    {payments ? <EmailNotifications /> : <LegacyEmailNotifications />}
                </div>
            </DashboardLayout>
        </MyAccountAccessLevelGuard>
    )
}

export { EmailNotificationSettings }
