/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { FunctionComponent } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../filters/FiltersBarHelpers'
import { AutocompleteSelect } from '../inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../inputs/autocomplete-select/Option'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { countArrayFilters } from '../../utils/filters'
import {
    ApplicationStatusLabel,
    SearchAllApplicationsFilters,
    SearchAllApplicationsFiltersOptions,
} from '../../contracts/opportunities-manager/contracts'

type AllApplicationsFiltersBarProps = {
    filtersOptions: SearchAllApplicationsFiltersOptions
    selectedFilters: SearchAllApplicationsFilters
    opened: boolean
    onClearAll: () => void
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
}

const AllApplicationsFiltersBar: FunctionComponent<React.PropsWithChildren<AllApplicationsFiltersBarProps>> = ({
    filtersOptions,
    selectedFilters,
    opened,
    onClearAll,
    onSelectFilterChange,
}) => {
    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                    align-items: center;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                        align-items: center;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Opp Owner'
                            filterName='opportunityOwners'
                            options={filtersOptions.opportunityOwners}
                            currentValues={selectedFilters.opportunityOwners}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Partner Names'
                            filterName='partnerNames'
                            options={filtersOptions.partnerNames}
                            currentValues={selectedFilters.partnerNames}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Client Names'
                            filterName='clientNames'
                            options={filtersOptions.clientNames}
                            currentValues={selectedFilters.clientNames}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Opp Name'
                            filterName='opportunityInternalNames'
                            options={filtersOptions.opportunityInternalNames}
                            currentValues={selectedFilters.opportunityInternalNames}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterColumn>

                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Status'
                            filterName='statusValues'
                            options={filtersOptions.statusValues}
                            currentValues={selectedFilters.statusValues}
                            labelTransformer={(opt: string) => ApplicationStatusLabel[opt] || opt}
                            selectedLabelTransformer={(opt: string) => ApplicationStatusLabel[opt] || opt}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                        />
                    </FilterColumn>

                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                            margin-bottom: 16px;
                        `}
                    >
                        <FiltersClearTrigger
                            onClear={onClearAll}
                            quantity={countArrayFilters(selectedFilters, Object.keys(selectedFilters))}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { AllApplicationsFiltersBar }
