/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { DataNotFound } from '../../../../components/DataNotFound'

type ResultsWrapperProps = {
    pending?: boolean
    total: number
}

const ResultsWrapper: FunctionComponent<React.PropsWithChildren<ResultsWrapperProps>> = ({ pending, total, children }) => (
    <div
        css={css`
            position: relative;
            z-index: 2;
        `}
    >
        {!pending && !total && (
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                `}
            >
                <DataNotFound
                    styles={css`
                        margin-top: 10vh;

                        h5 {
                            max-width: 450px;
                        }
                    `}
                    iconName='no-data'
                    title={'No Feedback Teams Added'}
                    description={
                        <span
                            css={css`
                                display: block;
                                max-width: 450px;
                            `}
                        >
                            You haven’t added any Feedback Teams yet.
                        </span>
                    }
                />
                <Link to='/my-specialists/continuous-feedback/add-team'>
                    <Button>Add Feedback Team</Button>
                </Link>
            </div>
        )}
        {total > 0 && <div>{children}</div>}
    </div>
)

export { ResultsWrapper }
