/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { mqMin } from '../../GlobalStyle'
import { AppIcon } from '../Icon'

const FeedbackNotAllowed: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    return (
        <div
            css={css`
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                height: calc(100vh - 57px);
                padding: 24px 10%;
            `}
        >
            <div
                css={css`
                    max-width: 500px;
                    margin: auto;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    ${mqMin[1]} {
                        padding-bottom: 88px;
                    }
                `}
            >
                <AppIcon
                    status='static'
                    name='trophy'
                    size={100}
                    css={css`
                        margin: 0 auto 32px auto;
                    `}
                />
                <h3
                    css={css`
                        margin-bottom: 30px;
                    `}
                >
                    Thank you for sharing your feedback!
                </h3>

                <p
                    css={css`
                        max-width: 398px;
                    `}
                >
                    We have already received feedback about this Project/Specialist. Please check your e-mail to review the feedback that
                    was sent. Thanks!
                </p>
            </div>
        </div>
    )
}

export { FeedbackNotAllowed }
