export enum InvitationStatus {
    PENDING = 'PENDING',
    REJECTED = 'REJECTED',
}

export const invitationStatusMapping = {
    [InvitationStatus.PENDING]: 'Pending',
    [InvitationStatus.REJECTED]: 'Rejected',
}

export interface CompanyInvitation {
    companyId: string
    email: string
    status: InvitationStatus
    updatedAt: number
    id: string
}
