/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { SkillsGroup } from '../../../../contracts/personalStrengths'
import { COLOR_PALETTE } from '../../../../theme/colors'
import { Nullable } from '../../../../types'

const scale = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

type QuickSummaryPersonalStrengthsProps = {
    groups: Nullable<Array<SkillsGroup>>
}

const QuickSummaryPersonalStrengths: FunctionComponent<React.PropsWithChildren<QuickSummaryPersonalStrengthsProps>> = ({ groups }) => {
    const theme = useTheme()

    return (
        <section
            css={css`
                margin-top: 24px;
            `}
        >
            {groups?.map(
                group =>
                    group.name === 'Big Five Personality Traits' && (
                        <div
                            key={group.name}
                            css={css`
                                width: 100%;
                                text-align: center;
                            `}
                        >
                            {group.skills.map(skill => (
                                <div
                                    key={skill.traits.toString()}
                                    css={css`
                                        margin-bottom: 16px;
                                    `}
                                >
                                    <div
                                        css={css`
                                            text-align: center;
                                            color: ${theme.colors.green_6};
                                            font-weight: 500;
                                            font-size: 10px;
                                        `}
                                    >
                                        {skill.traits.join(' / ')}
                                    </div>
                                    <div
                                        css={css`
                                            display: flex;
                                            justify-content: space-around;
                                            align-items: center;
                                            margin-top: 12px;
                                        `}
                                    >
                                        <div
                                            css={css`
                                                width: 100%;
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    height: 4px;
                                                    border-radius: 1px;
                                                    background-color: ${theme.colors.violet_2};
                                                    position: relative;
                                                    width: 100%;
                                                `}
                                            >
                                                {skill.score && (
                                                    <div
                                                        css={css`
                                                            background-color: ${theme.colors.green_4};
                                                            height: 4px;
                                                            padding-left: ${skill.score !== 1 ? '8px' : '0'};
                                                            padding-right: ${skill.score !== 10 ? '32px' : '0'};
                                                            width: ${skill.score === 1 || skill.score === 10 ? 0 : 12}px;
                                                            border-radius: 2px;
                                                            position: relative;

                                                            left: ${(skill.score - 1) * 21.28 - 10}px;
                                                        `}
                                                    >
                                                        <div
                                                            css={css`
                                                                background-color: ${theme.colors.gray_6};
                                                                color: ${theme.colors.white};
                                                                border: 2px solid ${theme.colors.white};
                                                                border-radius: 12px;
                                                                width: 24px;
                                                                height: 24px;
                                                                text-align: center;
                                                                line-height: 20px;
                                                                position: relative;
                                                                top: -10px;
                                                            `}
                                                        >
                                                            {skill.score}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    font-size: 10px;
                                    text-align: center;
                                `}
                            >
                                {scale.map(scaleItem => (
                                    <div
                                        key={scaleItem}
                                        css={css`
                                            width: 24px;
                                            font-size: 10px;
                                            color: ${COLOR_PALETTE.gray_3};
                                        `}
                                    >
                                        {scaleItem}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ),
            )}
        </section>
    )
}

export { QuickSummaryPersonalStrengths }
