/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { useController } from 'react-hook-form'
import { DatePickerInput, DatePickerInputProps } from '../inputs/DatePickerInput'
import { ControlledFieldProps } from './types'

type ControlledDatePickerInputProps = Omit<DatePickerInputProps, 'value' | 'onChange' | 'errorMessage'> & ControlledFieldProps<number> & {}

const ControlledDatePickerInput: FunctionComponent<React.PropsWithChildren<ControlledDatePickerInputProps>> = ({
    name,
    placeholder,
    style,
    disabled,
    format,
    defaultValue,
    disabledDate,
    extraFooter,
    showToday,
    popupStyle,
    dropdownAlign,
    isActive,
    dataTestId,
    picker,
    control,
    label,
    labelTooltip,
    infoMessage,
    canClear,
    showTime,
    minuteStep,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
        shouldUnregister: true,
    })

    return (
        <DatePickerInput
            label={label}
            labelTooltip={labelTooltip}
            infoMessage={infoMessage}
            name={name}
            placeholder={placeholder}
            style={style}
            disabled={disabled}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            format={format}
            defaultValue={defaultValue}
            disabledDate={disabledDate}
            extraFooter={extraFooter}
            showToday={showToday}
            popupStyle={popupStyle}
            dropdownAlign={dropdownAlign}
            isActive={isActive}
            dataTestId={dataTestId}
            picker={picker}
            onChange={onChange}
            value={value}
            canClear={canClear}
            showTime={showTime}
            minuteStep={minuteStep}
        />
    )
}
export { ControlledDatePickerInput }
