/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { FormEvent, Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useInterval, usePromise } from 'react-use'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledSuggestionInput } from 'src/components/forms/ControlledSuggestionInput'
import { IconButton } from 'src/components/Icon'
import { LeavePageConfirmationModal } from 'src/components/LeavePageConfirmationModal'
import { NoData } from 'src/components/NoData'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { Tabs, TabsColor } from 'src/components/Tabs'
import { getAppPath } from 'src/contracts/applications'
import { ParsedCv, ProcessingCv } from 'src/contracts/cvs'
import { history } from 'src/history'
import { useSkills, useSpecialistRoles } from 'src/redux/dictionaryDataHooks'
import { ReduxContext } from 'src/redux/Store'
import { resolveFieldError } from 'src/utils/errors'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'
import { yup } from 'src/validation/yup'
import { createSpecialistsFromCvs, deleteParsedCv } from '../api'
import { ExtractedData } from './ExtractedData'
import { ProcessingCvs } from './ProcessingCvs'

type ConfirmExtractedDataProps = {
    processingCvs: Array<ProcessingCv>
    onRemoveParsedCv: (id: string) => void
    parsedCvs: Array<ParsedCv>
    onRemoveProcessingCv: (id: string) => void
    loadProcessingCvs: () => Promise<Array<ProcessingCv> | void>
    loadParsedCvs: () => void
}

type FormDataType = {
    parsedCvs: Array<{
        firstName: string
        lastName: string
        role: string
        topSkills: Array<string>
    }>
}

const ConfirmExtractedData: FunctionComponent<React.PropsWithChildren<ConfirmExtractedDataProps>> = ({
    processingCvs,
    onRemoveProcessingCv,
    parsedCvs,
    onRemoveParsedCv,
    loadParsedCvs,
    loadProcessingCvs,
}) => {
    const query = useQuery()
    const [currentTab, setCurrentTab] = useState(query.get('tab') === 'extractedData' ? 1 : 0)
    const [selectedParsedCvs, setSelectedParsedCvs] = useState<Array<string>>([])
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const schema = useMemo(
        () =>
            yup.object().shape({
                parsedCvs: yup.array().of(
                    yup.object().shape({
                        firstName: yup.string().trim().required(),
                        lastName: yup.string().trim().required(),
                        role: yup.string().required(),
                        topSkills: yup.array().of(yup.string().required()),
                    }),
                ),
            }),
        [],
    )

    const {
        control,
        getValues,
        setValue,
        trigger,
        watch,
        formState: { errors, isDirty },
    } = useForm<FormDataType>({
        mode: 'onBlur',
        resolver: yupResolver(schema),
    })

    const { remove } = useFieldArray({
        control,
        name: 'parsedCvs',
    })

    const topSkills = watch('parsedCvs')?.map(val => val.topSkills) as (string[] | undefined)[] | undefined

    const theme = useTheme()
    const roles = useSpecialistRoles()
    const skills = useSkills()
    const isMounted = usePromise()
    const log = useLogger()
    const { addSuccess } = useNotifications()

    const { hash } = useLocation()
    const wrapperRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        if (hash === '#confirm-data' && wrapperRef) {
            wrapperRef.current?.scrollIntoView({ behavior: 'smooth' })
        }
    }, [hash])

    useInterval(() => onProcessingCVsDataRequest(), 5000)

    const onRemoveCv = useCallback(
        (id: string, index: number) => {
            remove(index)
            onRemoveParsedCv(id)
            isMounted(deleteParsedCv(id)).catch(log)
        },
        [remove, onRemoveParsedCv, isMounted, log],
    )

    const onSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault()
            setIsSubmitting(true)
            const cvsIndexesToSend = parsedCvs.reduce((previousValue, currentValue, index) => {
                if (selectedParsedCvs.includes(currentValue.id)) {
                    return previousValue.concat(index)
                }
                return previousValue
            }, [] as Array<number>)

            const fieldsToValidate = cvsIndexesToSend
                .map(cvIndex => [
                    `parsedCvs.${cvIndex}.firstName`,
                    `parsedCvs.${cvIndex}.lastName`,
                    `parsedCvs.${cvIndex}.role`,
                    `parsedCvs.${cvIndex}.topSkills`,
                ])
                .flat() as Array<
                | `parsedCvs.${number}.firstName`
                | `parsedCvs.${number}.lastName`
                | `parsedCvs.${number}.role`
                | `parsedCvs.${number}.topSkills`
            >

            trigger(fieldsToValidate).then(() => {
                const cvsToSend = cvsIndexesToSend.map(index => {
                    return {
                        cvParsingId: parsedCvs[index].id,
                        firstName: getValues(`parsedCvs.${index}.firstName`),
                        lastName: getValues(`parsedCvs.${index}.lastName`),
                        role: getValues(`parsedCvs.${index}.role`),
                        topSkills: getValues(`parsedCvs.${index}.topSkills`) || [],
                    }
                })

                layoutToggleLoader(true)

                isMounted(createSpecialistsFromCvs(cvsToSend))
                    .then(() => {
                        history.push(getAppPath('MY_SPECIALISTS'))
                        addSuccess(`New Specialist${cvsToSend.length > 1 ? 's were' : ' was'} created successfully.`)
                    })
                    .catch(err => {
                        log(err)
                        setIsSubmitting(false)
                    })
                    .finally(() => layoutToggleLoader(false))
            })
        },
        [parsedCvs, trigger, selectedParsedCvs, layoutToggleLoader, isMounted, getValues, addSuccess, log],
    )

    const validCvsLength = parsedCvs.length - (errors?.parsedCvs?.filter?.(error => !!error).length || 0)

    useEffect(() => {
        trigger()
    }, [trigger])

    const handleDeselectAll = useCallback(() => {
        setSelectedParsedCvs([])
    }, [])

    const handleSelectAll = useCallback(() => {
        setSelectedParsedCvs(parsedCvs.filter((_, index) => !errors.parsedCvs?.[index]).map(cv => cv.id))
    }, [errors.parsedCvs, parsedCvs])

    const columns = useMemo<ColumnsType<ParsedCv>>(
        () => [
            {
                title: 'First Name',
                key: 'firstName',
                render: (_, record, index) => {
                    const errorText = resolveFieldError(errors?.parsedCvs?.[index]?.firstName)
                    if (errorText && selectedParsedCvs.includes(record.id)) {
                        setSelectedParsedCvs(currentSelectedParsedCvs =>
                            currentSelectedParsedCvs.filter(selectedCv => selectedCv !== record.id),
                        )
                    }
                    return (
                        <div>
                            <ControlledSuggestionInput
                                control={control}
                                fetchOptions={() =>
                                    Promise.resolve(record.names.filter(name => !!name.firstName).map(name => name.firstName))
                                }
                                name={`parsedCvs.${index}.firstName`}
                                dataTestId='my-specialists-cv-parsing-first-name'
                                placeholder='First name'
                                fetchFromLetterNumber={0}
                                defaultValue={record.names?.[0]?.firstName}
                                styles={css`
                                    ${errorText ? 'margin-top:18px;' : 'margin:18px 0'}
                                `}
                            />
                        </div>
                    )
                },
            },
            {
                title: 'Last Name',
                key: 'lastName',
                render: (_, record, index) => {
                    const errorText = resolveFieldError(errors?.parsedCvs?.[index]?.lastName)
                    if (errorText && selectedParsedCvs.includes(record.id)) {
                        setSelectedParsedCvs(currentSelectedParsedCvs =>
                            currentSelectedParsedCvs.filter(selectedCv => selectedCv !== record.id),
                        )
                    }
                    return (
                        <ControlledSuggestionInput
                            control={control}
                            placeholder='Last name'
                            fetchOptions={() => Promise.resolve(record.names.filter(name => !!name.lastName).map(name => name.lastName))}
                            name={`parsedCvs.${index}.lastName`}
                            dataTestId='my-specialists-cv-parsing-last-name'
                            fetchFromLetterNumber={0}
                            defaultValue={record.names?.[0]?.lastName}
                            styles={css`
                                ${errorText ? 'margin-top:18px;' : 'margin:18px 0'}
                            `}
                        />
                    )
                },
            },
            {
                title: 'Role',
                key: 'role',
                render: (_, record, index) => {
                    const errorText = resolveFieldError(errors?.parsedCvs?.[index]?.role)
                    return (
                        <ControlledAutocompleteSelect
                            placeholder='Role'
                            options={record.roles.filter(role => !!role).concat(roles?.filter(role => !record.roles.includes(role)) || [])}
                            dataTestId='my-specialists-cv-parsing-role'
                            control={control}
                            beforeChange={val => {
                                setValue(`parsedCvs.${index}.topSkills`, record.roleToTopSkills[val?.toString() || ''] || [], {
                                    shouldValidate: true,
                                })
                            }}
                            defaultValue={[record.roles[0]]}
                            name={`parsedCvs.${index}.role`}
                            styles={css`
                                ${errorText ? 'margin-top:18px;' : 'margin:18px 0'}
                            `}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        />
                    )
                },
            },
            {
                title: 'Top Skills',
                key: 'topSkills',
                width: 300,
                render: (_, record, index) => {
                    return (
                        <ControlledAutocompleteSelect
                            placeholder='Top Skills'
                            options={skills}
                            dataTestId='my-specialists-cv-parsing-top-skills'
                            control={control}
                            name={`parsedCvs.${index}.topSkills`}
                            multiple
                            virtualized
                            defaultValue={record.roleToTopSkills[record.roles[0]]}
                        />
                    )
                },
            },
            {
                title: '',
                dataIndex: 'skills',
                key: 'skills',
                width: 50,
                render: (_, record, index) => {
                    const skillsList = record.skills.filter(skill => topSkills && !topSkills[index]?.includes(skill))
                    return (
                        !!skillsList.length && (
                            <div
                                css={css`
                                    background-color: ${theme.colors.violet_2};
                                    width: 40px;
                                    height: 40px;
                                    text-align: center;
                                    line-height: 40px;
                                    margin-top: 15px;
                                    margin-bottom: 15px;
                                `}
                            >
                                <Tooltip title={`Additional Tech Skills parsed and added to profile: ${skillsList.join(', ')}`}>
                                    +{skillsList.length}
                                </Tooltip>
                            </div>
                        )
                    )
                },
            },
            {
                title: '',
                key: 'actions',
                width: 160,
                render: (_, record, index) => (
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}
                    >
                        <a
                            href={record.cvDownloadUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                            css={css`
                                color: ${theme.colors.violet_6};
                            `}
                        >
                            Download CV
                        </a>
                        <IconButton
                            name='close'
                            onClick={() => onRemoveCv(record.id, index)}
                            dataTestId='my-specialists-cv-parsing-remove-extracted-data'
                        />
                    </div>
                ),
            },
        ],
        [
            control,
            errors?.parsedCvs,
            onRemoveCv,
            roles,
            selectedParsedCvs,
            setValue,
            skills,
            theme.colors.violet_2,
            theme.colors.violet_6,
            topSkills,
        ],
    )

    const onProcessingCVsDataRequest = useCallback(() => {
        if (loadProcessingCvs) {
            loadProcessingCvs()
                .then(() => {
                    if (processingCvs.length !== parsedCvs.length) {
                        loadParsedCvs()
                    }
                })
                .finally(() => {
                    trigger()
                })
        }
    }, [loadProcessingCvs, processingCvs.length, parsedCvs, loadParsedCvs, trigger])

    const handleSelectParsedCvs = useCallback((ids: Array<string>) => {
        setSelectedParsedCvs(ids)
    }, [])

    useEffect(() => {
        if (currentTab === 0) {
            loadProcessingCvs()
        } else {
            loadParsedCvs()
        }
    }, [currentTab, loadProcessingCvs, loadParsedCvs])

    useEffect(() => {
        trigger()
    }, [parsedCvs, trigger])
    return (
        <div
            css={css`
                min-height: 550px;
                margin-bottom: 48px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            `}
            ref={wrapperRef}
        >
            <div
                css={css`
                    padding: 0 25px;
                `}
            >
                <h4>2. Confirm Extracted Data</h4>
                <p>
                    To add specialists to your team, select them and click ‘Create Profiles’ after checking for any missing or incorrect
                    data.
                </p>
            </div>
            <Fragment>
                <div
                    css={css`
                        padding: 0 25px;
                    `}
                >
                    <Tabs
                        tabs={[
                            { title: `Processing (${processingCvs?.length || 0})` },
                            {
                                title: `Extracted Data (${parsedCvs?.length || 0})`,
                                dataTestId: 'my-specialists-cv-parsing-extracted-data-tab',
                            },
                        ]}
                        mode={TabsColor.BLACK}
                        onTabChange={setCurrentTab}
                        currentTab={currentTab}
                        showOnMobile
                    />
                    {processingCvs.length > 0 ? (
                        <ProcessingCvs
                            cvs={processingCvs}
                            onRemove={onRemoveProcessingCv}
                            shouldBeVisible={currentTab === 0}
                            setCurrentTab={setCurrentTab}
                        />
                    ) : (
                        <NoData
                            style={css`
                                margin-top: 50px;
                                min-height: 250px;
                                justify-content: center;
                                align-items: center;
                                display: ${currentTab === 0 ? 'flex' : 'none'};
                            `}
                            message='There are no new uploaded CVs that are being processed now.'
                        />
                    )}

                    {parsedCvs.length > 0 ? (
                        <ExtractedData
                            shouldBeVisible={currentTab === 1}
                            cvs={parsedCvs}
                            columns={columns}
                            selectedParsedCvs={selectedParsedCvs}
                            onSubmit={onSubmit}
                            validCvsLength={validCvsLength}
                            errors={errors}
                            handleSelectParsedCvs={handleSelectParsedCvs}
                            handleDeselectAll={handleDeselectAll}
                            handleSelectAll={handleSelectAll}
                            isSubmitting={isSubmitting}
                        />
                    ) : (
                        <NoData
                            style={css`
                                margin-top: 50px;
                                min-height: 250px;
                                justify-content: center;
                                align-items: center;
                                display: ${currentTab === 1 ? 'flex' : 'none'};
                            `}
                            message='There are no parsed CVs. Please upload CVs to start parsing process.'
                        />
                    )}
                </div>
            </Fragment>
            {isDirty && !isSubmitting && <LeavePageConfirmationModal />}
        </div>
    )
}

export { ConfirmExtractedData }
