/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'

type LabelProps = {
    backgroundColor?: string
    styles?: SerializedStyles
}

const Label: FunctionComponent<React.PropsWithChildren<LabelProps>> = ({
    children,
    backgroundColor = COLOR_PALETTE.violet_2,
    styles = null,
}) => (
    <div
        css={css`
            background-color: ${backgroundColor};
            border-radius: 2px;
            display: inline-block;
            content: ${children as string};
            ${styles}
        `}
    >
        {children}
    </div>
)

export { Label }
