/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, ReactNode } from 'react'
import { mqMax } from '../../GlobalStyle'
import { Icon, IconName } from '../Icon'
import { ProfileHeaderLabel } from './ProfileHeader'

type HeaderElement = {
    label: ReactNode
    iconName: IconName
    dataTestId?: string
    tooltip?: string
}

type ProfileHeaderInfoProps = {
    elements: Array<HeaderElement>
    style?: SerializedStyles
}

const ProfileHeaderInfo: FunctionComponent<React.PropsWithChildren<ProfileHeaderInfoProps>> = ({ elements, style }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                border-top: 1px solid ${theme.colors.beige_2};
                border-bottom: 1px solid ${theme.colors.beige_2};
                padding: 24px 0;
                ${mqMax[1]} {
                    flex-direction: column;
                }

                ${style}
            `}
        >
            {elements.map((element, index) =>
                element.label !== null ? (
                    <Tooltip title={element.tooltip} key={index}>
                        <div
                            css={css`
                                display: flex;
                                margin-right: 64px;
                                width: fit-content;
                                ${mqMax[1]} {
                                    margin-bottom: 24px;
                                }
                            `}
                            data-testid={element.dataTestId}
                        >
                            <Icon name={element.iconName} size={24} />
                            <ProfileHeaderLabel>{element.label}</ProfileHeaderLabel>
                        </div>
                    </Tooltip>
                ) : null,
            )}
        </div>
    )
}

export { ProfileHeaderInfo }
