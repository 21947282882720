import { useCallback, useContext, useMemo, useState } from 'react'
import { useNotifications } from '../../notification/NotificationProvider'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { shouldShowContractRateModal, shouldshowInterviewDateModal } from '../../../utils/application'
import { updateAllOpportunityApplication } from '../../../api/opportunities-manager/api'
import { RejectionReasonModal, SHOW_REJECTION_SUCCESS_MESSAGE } from '../RejectionReasonModal'
import { ContractRateModal } from '../ContractRateModal'
import { InterviewDateModal } from 'src/components/opportunities-manager/InterviewDateModal'
import {
    applicationRejectionStatusesList,
    contractRateApplicationStatusesList,
    interviewDateStatusesList,
    ApplicationStatusEnum,
    UpdateApplicationBody,
    AllApplicationsItem,
} from '../../../contracts/opportunities-manager/contracts'

interface UseApplicationUpdateArgs {
    // called when application is updated (after status change, etc.)
    afterRequestCallback?: (applicationId: string, application?: AllApplicationsItem) => void
    // called when either updating application failed, or we cancelled it intentionally (by closing modal)
    onsStatusUpdateFailure?: () => void
    // currency: needed so that when contract reate modal is shown, we show proper currency for its input
    // applications: are used to find corresponding application (by its id) and from it we extract contract rate
    applicationData?: { applications?: Nullable<AllApplicationsItem[]> }
}

const useAllApplicationUpdate = ({ afterRequestCallback, onsStatusUpdateFailure, applicationData }: UseApplicationUpdateArgs) => {
    const [currentApplicationId, setCurrentApplicationId] = useState<Nullable<string>>(null)
    const [currentStatus, setCurrentStatus] = useState<Nullable<ApplicationStatusEnum>>(null)
    const [currentRate, setCurrentRate] = useState<number | undefined>(undefined)

    const log = useLogger('error')
    const { addError } = useNotifications()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const currency = useMemo(
        () => applicationData?.applications?.find(application => application.applicationId === currentApplicationId)?.currency || 'USD',
        [applicationData?.applications, currentApplicationId],
    )

    const updateApplication = useCallback(
        (applicationId: string, data: UpdateApplicationBody) => {
            layoutToggleLoader(true)

            updateAllOpportunityApplication(applicationId, data)
                .then(application => {
                    if (data.status && applicationRejectionStatusesList.includes(data.status.status)) {
                        setCurrentApplicationId(prev => prev && SHOW_REJECTION_SUCCESS_MESSAGE)
                    }
                    if (
                        data.status &&
                        [...contractRateApplicationStatusesList, ...interviewDateStatusesList].includes(data.status.status)
                    ) {
                        setCurrentApplicationId(null)
                    }
                    if (afterRequestCallback) {
                        afterRequestCallback(applicationId, application)
                    }
                })
                .catch(err => {
                    log(err)
                    addError()
                    if (onsStatusUpdateFailure) onsStatusUpdateFailure()
                    if (afterRequestCallback) {
                        afterRequestCallback(applicationId)
                    }
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        },
        [addError, afterRequestCallback, layoutToggleLoader, log, onsStatusUpdateFailure],
    )

    const handleApplicationChange = useCallback(
        (applicationId: string, status?: ApplicationStatusEnum, internalRate?: number) => {
            if (status && applicationRejectionStatusesList.includes(status)) {
                setCurrentApplicationId(applicationId)
                setCurrentStatus(status || null)
                setCurrentRate(internalRate)
            } else if (status && [...contractRateApplicationStatusesList, ...interviewDateStatusesList].includes(status)) {
                setCurrentApplicationId(applicationId)
                setCurrentStatus(status || null)
            } else {
                updateApplication(applicationId, {
                    internalRate,
                    ...(status && { status: { status } }),
                })
            }
        },
        [updateApplication],
    )

    const closeModal = useCallback(() => {
        setCurrentApplicationId(null)
        if (onsStatusUpdateFailure) onsStatusUpdateFailure()
    }, [onsStatusUpdateFailure])

    const showApplicationRejectedModal = currentApplicationId && currentStatus && applicationRejectionStatusesList.includes(currentStatus)

    const contractRateArgs = useMemo(
        () => ({
            applicationId: currentApplicationId,
            applications: applicationData?.applications,
            status: currentStatus,
        }),
        [currentApplicationId, applicationData?.applications, currentStatus],
    )
    const showContractRateModal = shouldShowContractRateModal(contractRateArgs)

    const interviewDateArgs = useMemo(
        () => ({
            applicationId: currentApplicationId,
            status: currentStatus,
        }),
        [currentApplicationId, currentStatus],
    )
    const showInterviewDateModal = shouldshowInterviewDateModal(interviewDateArgs)

    const ApplicationStatusUpdateRejectionReasonModal = useMemo(() => {
        if (showApplicationRejectedModal) {
            return (
                <RejectionReasonModal
                    onSubmit={updateApplication}
                    currentRejectionApplicationId={currentApplicationId}
                    currentStatus={currentStatus}
                    currentRate={currentRate}
                    closeRejectionModal={closeModal}
                />
            )
        } else if (showContractRateModal) {
            const contractRate = contractRateArgs.applications.find(
                ({ applicationId }) => applicationId === contractRateArgs.applicationId,
            )?.contractRate

            return (
                <ContractRateModal
                    closeModal={closeModal}
                    contractRate={contractRate}
                    currency={currency}
                    currentApplicationId={contractRateArgs.applicationId}
                    currentStatus={contractRateArgs.status}
                    onSubmit={updateApplication}
                />
            )
        } else if (showInterviewDateModal) {
            return (
                <InterviewDateModal
                    closeModal={closeModal}
                    currentApplicationId={interviewDateArgs.applicationId}
                    currentStatus={interviewDateArgs.status}
                    onSubmit={updateApplication}
                />
            )
        }
    }, [
        closeModal,
        contractRateArgs,
        currency,
        currentApplicationId,
        currentRate,
        currentStatus,
        updateApplication,
        showApplicationRejectedModal,
        showContractRateModal,
        showInterviewDateModal,
        interviewDateArgs,
    ])

    return { handleApplicationChange, ApplicationStatusUpdateRejectionReasonModal }
}

export { useAllApplicationUpdate }
