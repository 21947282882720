export type ApplicationType =
    | 'TALENT_MARKETPLACE'
    | 'MY_SPECIALISTS'
    | 'SKILLS_DISCOVERY'
    | 'STRENGTH_DISCOVERY'
    | 'SKILLS_TEST'
    | 'SALES'
    | 'APPLICATION_TRACKING'
    | 'PULSE_SURVEY'
    | 'PSYCHOMETRIC_MEASUREMENTS'
    | 'SYNDICATE'
    | 'CONTRACTOR_MANAGEMENT'
    | 'INSTANT_ONBOARDING'
    | 'CV_PARSING'
    | 'OPPORTUNITIES_MANAGER'
    | 'OPPORTUNITIES_MANAGER_SME'
    | 'OPEN_OPPORTUNITIES'
    | 'ALL_CANDIDATES'
    | 'ALL_CANDIDATES_SME'
    | 'VACANCIES_MANAGER_ALL_CANDIDATES'
    | 'VACANCIES_MANAGER'
    | 'OPEN_VACANCIES'
    | 'FAQ'
    | 'VMC_MANAGEMENT'

export type AvailabilityType = 'AVAILABLE' | 'UNAVAILABLE' | 'UPCOMING' | 'LABS' | 'PLANNED'

type ApplicationUrlResponseType = 'INTERNAL' | 'EXTERNAL'

type ApplicationUrlResponse = {
    path: string
    type: ApplicationUrlResponseType
}

export interface Application {
    id: string
    name: AppName
    order: number
    tooltip: string
    applicationType: ApplicationType
    availabilityType: AvailabilityType
    url?: string
    applicationUrlResponse: ApplicationUrlResponse
    iconUrl: string
    total: string
}

// helper types

export type AppTileGroup = {
    name?: string
    apps: Array<AppTileConfig>
}

export type AppTileStatus = 'available' | 'unavailable' | 'disabled'

export type AppName = 'Opportunities Manager' | 'Open Opportunities' | 'My Specialists' | 'Talent Marketplace' | string

export type AppTileConfig = {
    to: string
    iconUrl: string
    title: AppName
    status: AppTileStatus
    subtitle: string
    hint: string
    url?: string
    applicationType?: ApplicationType
    availabilityType?: AvailabilityType
    total: string
}

const getAppSubtitle: (availability: AvailabilityType) => string = availability => {
    switch (availability) {
        case 'AVAILABLE':
            return 'Available Now'
        case 'UNAVAILABLE':
            return 'Get Access'
        case 'UPCOMING':
            return 'Later Feature'
        default:
            return 'Later Feature'
    }
}

const getAppStatus: (availability: AvailabilityType) => AppTileStatus = availability => {
    switch (availability) {
        case 'AVAILABLE':
        case 'LABS':
            return 'available'
        case 'UNAVAILABLE':
            return 'unavailable'
        case 'UPCOMING':
            return 'disabled'
        default:
            return 'disabled'
    }
}

const getAppPath: (type: ApplicationType, resourceId?: string) => string = (type, resourceId = '') => {
    switch (type) {
        case 'TALENT_MARKETPLACE':
            return '/talent-marketplace'
        case 'MY_SPECIALISTS':
            return '/my-specialists'
        case 'SKILLS_DISCOVERY':
            return resourceId ? `/skills-discovery/${resourceId}` : '/skills-discovery/:specialistId'
        case 'STRENGTH_DISCOVERY':
            return resourceId ? `/strength-discovery/${resourceId}` : '/strength-discovery/:specialistId'
        case 'SKILLS_TEST':
            return resourceId ? `/skills-test/${resourceId}` : '/skills-test/:specialistId'
        case 'OPPORTUNITIES_MANAGER':
            return '/opportunities-manager'
        case 'OPPORTUNITIES_MANAGER_SME':
            return '/opportunities-manager-sme'
        case 'OPEN_OPPORTUNITIES':
            return '/open-opportunities'
        case 'ALL_CANDIDATES':
            return '/opportunities-manager/all-candidates'
        case 'ALL_CANDIDATES_SME':
            return '/opportunities-manager-sme/all-candidates'
        case 'VACANCIES_MANAGER':
            return '/vacancies-manager'
        case 'VACANCIES_MANAGER_ALL_CANDIDATES':
            return '/vacancies-manager/all-candidates'
        case 'OPEN_VACANCIES':
            return '/open-vacancies'
        case 'VMC_MANAGEMENT':
            return '/dashboard/company-account/vmc-management'
        default:
            return ''
    }
}

const getAppAccessPath: (type: ApplicationType) => string = type => {
    if (type === 'MY_SPECIALISTS') {
        return '/dashboard/company-account/company-details'
    }
    return ''
}

const app2TileFilter: (app: Application) => boolean = app => app.availabilityType !== 'PLANNED'

const app2TileConfig: (app: Application) => AppTileConfig = app => ({
    title: app.name,
    hint: app.tooltip,
    iconUrl: app.iconUrl,
    total: app.total,
    to:
        app.availabilityType === 'UNAVAILABLE'
            ? getAppAccessPath(app.applicationType)
            : app.applicationUrlResponse.type === 'INTERNAL'
            ? app.applicationUrlResponse.path
            : '',
    status: getAppStatus(app.availabilityType),
    subtitle: getAppSubtitle(app.availabilityType),
    applicationType: app.applicationType,
    availabilityType: app.availabilityType,
    url: app.applicationUrlResponse.type === 'EXTERNAL' ? app.applicationUrlResponse.path : '',
})

const app2TileGroups: (apps: Array<Application>) => Array<AppTileGroup> = (apps: Array<Application>) => {
    const displayed = apps.filter(app2TileFilter)
    const available = displayed.filter(app => app.availabilityType === 'AVAILABLE').map(app2TileConfig)
    const unavailable = displayed.filter(app => app.availabilityType === 'UNAVAILABLE').map(app2TileConfig)
    const upcoming = displayed.filter(app => app.availabilityType === 'UPCOMING').map(app2TileConfig)

    const output = [
        { name: 'Your Free Apps:', apps: available },
        { name: 'Apps available with a Company Account:', apps: unavailable },
        { name: 'Coming Soon:', apps: upcoming },
    ]

    return output
}

export { app2TileConfig, app2TileGroups, getAppPath }
