import { axios } from '../../api/axios'
import { TaskType } from './contracts'

// SPECIALISTS
const specialistsEndpoint = `/specialists`

const getSpecialistTasks = (specialistId: string): Promise<Array<TaskType>> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/tasks`)
}

export { getSpecialistTasks }
