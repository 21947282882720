/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useContext, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Profile } from '../../contracts/profile/profile'
import { ReduxContext } from '../../redux/Store'
import { Button } from '../Button'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { SkillGraph } from '../skill-graph/SkillGraph'
import { SkillMatrix } from '../SkillMatrix'
import { TabProps, Tabs, TabsColor } from '../Tabs'

type TechnicalSkillsProps = { profile: Profile }

const TechnicalSkills: FunctionComponent<React.PropsWithChildren<TechnicalSkillsProps>> = ({ profile }) => {
    const [selectedTechnicalSkillsTab, setSelectedTechnicalSkillsTab] = useState<string>('Skill Matrix')
    const [availableTechnicalSkillsTabs] = useState<Array<TabProps>>([{ title: 'Skill Matrix' }, { title: 'Skill Graph', disabled: false }])
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])
    const filename = useMemo(
        () => `${profile.seniority ? `${profile.seniority}_` : ''}${profile.role}${profile.nickname ? `_${profile.nickname}` : ''}`,
        [profile.nickname, profile.role, profile.seniority],
    )

    return (
        <Fragment>
            <FlexBox
                alignItems='center'
                justifyContent='space-between'
                css={css`
                    margin-bottom: 26px;
                `}
            >
                <h6
                    css={css`
                        margin-bottom: 0;
                    `}
                >
                    Technical Skills
                </h6>
                {profile.specialistId && specialistId === profile.specialistId && (
                    <Link to='/specialist-skills-edit'>
                        <Button variant='tertiary' dataTestId='specialist-skills-edit-button'>
                            {profile.skillMatrix.categories.length === 0 ? 'Add' : 'Edit'} Skills
                        </Button>
                    </Link>
                )}
            </FlexBox>

            <Tabs
                tabs={availableTechnicalSkillsTabs}
                showOnMobile={true}
                onTabChange={newTab => {
                    setSelectedTechnicalSkillsTab(availableTechnicalSkillsTabs[newTab].title)
                }}
                mode={TabsColor.BLACK}
            />

            {selectedTechnicalSkillsTab === 'Skill Matrix' && (
                <SkillMatrix
                    skillMatrix={profile.skillMatrix}
                    id={profile.specialistId}
                    hasAnonymizedCv={profile.hasAnonymizedCv}
                    filename={filename}
                />
            )}
            {selectedTechnicalSkillsTab === 'Skill Graph' && (
                <div
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    <SkillGraph
                        skillMatrix={profile.skillMatrix}
                        rootName={profile.nickname}
                        id={profile.specialistId || ''}
                        hasAnonymizedCv={profile.hasAnonymizedCv}
                        filename={filename}
                    />
                </div>
            )}
        </Fragment>
    )
}

export { TechnicalSkills }
