/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { DataNotFound } from '../../../components/DataNotFound'
import { SpecialistsSearchResponse } from '../contracts'

const SearchResultsInfoParagraph = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    margin: 0 0 4px;
    color: ${COLOR_PALETTE.gray_6};
    width: 100%;
`

const SearchResultsInfoSubText = styled.p`
    font-size: 12px;
    line-height: 120%;
    width: 100%;
    color: ${COLOR_PALETTE.gray_4};
`

export interface SearchResultsInfoProps {
    searchResults: SpecialistsSearchResponse
    filtersCount: number
    savedProfiles: boolean
    searchText?: string
}

const SearchResultsInfo: FunctionComponent<React.PropsWithChildren<SearchResultsInfoProps>> = ({
    searchResults,
    filtersCount,
    searchText = '',
    savedProfiles,
}) => {
    const isQueryNotMatchInformationVisible = () => {
        return searchResults && searchResults.matchingCount === 0
    }

    const isTotalNumberVisible = () => {
        return searchResults && searchResults.total && searchResults.matchingCount !== 0
    }

    const renderSearchResultsInfo = () => {
        if (isQueryNotMatchInformationVisible()) {
            return (
                <Fragment>
                    {!savedProfiles && (
                        <Fragment>
                            <SearchResultsInfoParagraph>
                                Your search <strong>"{searchText}"</strong>
                                {filtersCount > 0 && ' and the above selected filters'} did not match one of the Specialist Profiles
                                currently on our Human Cloud'.
                            </SearchResultsInfoParagraph>

                            <SearchResultsInfoSubText>
                                Please take a look at some examples of Specialist Profiles below and refine your search according to
                                specific skills, roles, industries etc.
                            </SearchResultsInfoSubText>
                        </Fragment>
                    )}

                    {savedProfiles && (
                        <div
                            css={css`
                                margin-top: 5vh;
                                width: 100%;
                                display: flex;
                                justify-content: center;
                            `}
                        >
                            <DataNotFound
                                styles={css`
                                    max-width: 475px;
                                `}
                                iconName='no-data'
                                title='No Specialists Added to Your Saved Profiles'
                                description='Add the Specialist Profiles that you are interested in to the “Saved Profiles” section. You’ll be able to find your favorite profiles all in one place.  '
                            />
                        </div>
                    )}
                </Fragment>
            )
        }

        if (isTotalNumberVisible()) {
            return (
                <SearchResultsInfoParagraph>
                    {searchResults.total} Result{searchResults.total > 1 && 's'} Found
                </SearchResultsInfoParagraph>
            )
        }

        return null
    }

    if (searchResults) {
        return (
            <div
                css={css`
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 16px 0;
                `}
            >
                {renderSearchResultsInfo()}
            </div>
        )
    }

    return null
}

export { SearchResultsInfo }
