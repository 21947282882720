/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useState } from 'react'
import { SpecialistLoginForm } from '../pages/login/SpecialistLoginForm'
import { ReduxContext } from '../redux/Store'
import { Nullable } from '../types'
import { ManagerLoginForm } from './ManagerLoginForm'
import { TabbedLogin } from './TabbedLogin'

type TabbedLoginFormProps = {
    defaultTab?: Nullable<string>
    onTabChange?(tab: string): void
}

const TabbedLoginForm: FunctionComponent<React.PropsWithChildren<TabbedLoginFormProps>> = ({ onTabChange, defaultTab = null }) => {
    const {
        selectors: {
            featureFlags: { hideSpecialistLogin },
        },
    } = useContext(ReduxContext)

    const [selectedTab, setSelectedTab] = useState<Nullable<string>>(defaultTab)

    const onTabClick = useCallback(
        (tab: string) => {
            setSelectedTab(tab)

            if (onTabChange) {
                onTabChange(tab)
            }
        },
        [onTabChange],
    )

    const loginText = hideSpecialistLogin ? 'Log in' : 'Log in as Manager'

    return (
        <Fragment>
            {!hideSpecialistLogin && <TabbedLogin onTabClick={onTabClick} selectedTab={selectedTab} />}
            {selectedTab === 'specialist' && <SpecialistLoginForm />}
            {selectedTab === 'manager' && (
                <ManagerLoginForm
                    header={
                        <h3
                            css={css`
                                margin-top: 30px;
                            `}
                            data-testid='login-check'
                        >
                            {loginText}
                        </h3>
                    }
                    showRegistrationLink
                    buttonText={loginText}
                    styles={css`
                        padding-bottom: 100px;
                    `}
                />
            )}
        </Fragment>
    )
}

export { TabbedLoginForm }
