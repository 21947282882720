/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Loader } from '../../../components/layout/Loader'
import { Specialist } from '../../../contracts/specialist/specialist'
import { Nullable } from '../../../types'
import { TaskType } from '../contracts'
import { Task } from './Task'

type TaskListProps = {
    tasks: Nullable<Array<TaskType>>
    specialist?: Nullable<Specialist>
}

const TaskList: FunctionComponent<React.PropsWithChildren<TaskListProps>> = ({ tasks, specialist }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const handleViewProfileClick = useCallback(() => {
        navigate(`/profile-summary/${specialist?.id}`)
    }, [navigate, specialist])

    return !tasks || !specialist ? (
        <Loader />
    ) : (
        <Fragment>
            <FlexBox
                justifyContent='space-between'
                css={css`
                    margin-bottom: 24px;
                    flex-wrap: wrap;
                `}
            >
                <p>
                    {specialist?.role}
                    <span
                        css={css`
                            color: ${theme.colors.violet_2};
                            margin: 0 16px;
                        `}
                    >
                        &#9679;
                    </span>
                    {specialist?.email}
                </p>

                <Button variant='primary' onClick={handleViewProfileClick} dataTestId='specialist-dashboard-view-profile'>
                    View Profile
                </Button>
            </FlexBox>
            {tasks.map(task => (
                <Task key={task.type} task={task} dataTestId={`task-${task.type.toLowerCase().replace(/_/g, '-')}`} />
            ))}
        </Fragment>
    )
}

export { TaskList }
