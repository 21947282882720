export enum TermsNames {
    MARKETING_CONSENTS = 'MARKETING_CONSENTS',
    TERMS_OF_USE = 'TERMS_OF_USE',
}

export interface TermsToReCertify {
    id: string
    markdownContent: string
    name: string
    required: boolean
    version: string
}

export interface CheckedTerms {
    isChecked: boolean
    id: string
}

export interface TermsResponse {
    hasNoCertifiedTerms: boolean
    termsToReCertify: Array<TermsToReCertify>
}
