/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { mqMax } from 'src/GlobalStyle'
import { Divider } from 'src/components/Divider'
import { DashboardLayout, MenuType } from 'src/components/layout/dashboard/DashboardLayout'
import StripePricingTable from '../components/StripePricingTable'
import { useUserContext } from 'src/hooks/useUserContext'
import { useSubscription } from 'src/hooks/useSubscription'
import { formatUnixDate } from 'src/utils/dates'
import { Button } from 'src/components/Button'
import { Link } from 'react-router-dom'

import { InfoLink } from 'src/components/InfoLink'
import { BenefitsTable } from '../components/BenefitsTable'

import styled from '@emotion/styled'
import { Icon } from 'src/components/Icon'
import { SettingsWrapper } from 'src/components/layout/ResponsiveWrapper'

const Badge = styled.span`
    border-radius: 4px;
    padding: 2px 4px;
    background-color: ${props => props.theme.colors.blue_3};
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
`

const SubscriptionBox = styled.div`
    background-color: ${props => props.theme.colors.beige_1};
    border: ${props => `1px solid ${props.theme.colors.beige_3}`};
    border-radius: 4px;
    min-height: 115px;
    padding: 2rem;
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 32%;
    ${mqMax[1]} {
        width: 100%;
    }
`

const SubscriptionLabel = styled.div`
    font-size: 16px;
    margin-bottom: 1rem;
`

const SubscriptionMain = styled.div`
    font-weight: 500;
    font-size: 18px;
`

const BenefitTile = styled.div`
    border-radius: 4px;
    border: ${props => `1px solid ${props.theme.colors.violet_2}`};
    display: flex;
    flex-direction: column;
    padding: 2rem;
    margin: 0.5rem 0;
    width: 32%;
    ${mqMax[1]} {
        width: 100%;
    }
`

const BenefitTileHeading = styled.div`
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    margin: 1rem 0;
`

const BenefitText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
`

const IconWrapper = styled.div`
    background-color: ${props => props.theme.colors.beige_2};
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 12px;
`

const SubscriptionDetails = () => {
    const { activeContext } = useUserContext()
    const { subscriptionData } = useSubscription()

    return (
        <DashboardLayout menuType={MenuType.companyAccount}>
            <SettingsWrapper>
                <div
                    css={css`
                        display: flex;
                        width: 100%;
                    `}
                >
                    <section
                        css={css`
                            display: block;
                            width: 100%;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                margin-bottom: 1em;
                            `}
                        >
                            <h4
                                css={css`
                                    margin-bottom: 0;
                                `}
                            >
                                Subscription
                            </h4>
                        </div>
                        <Divider />

                        {subscriptionData?.hasActiveSubscription && (
                            <div>
                                <div
                                    css={css`
                                        display: flex;
                                        align-items: center;
                                        margin-bottom: 1em;
                                    `}
                                >
                                    <div
                                        css={css`
                                            font-weight: 500;
                                            font-size: 16px;
                                        `}
                                    >
                                        Status:
                                    </div>
                                    <Badge>Active</Badge>
                                </div>

                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: space-between;
                                        ${mqMax[1]} {
                                            flex-direction: column;
                                        }
                                    `}
                                >
                                    <SubscriptionBox>
                                        <SubscriptionLabel> Subscription Price:</SubscriptionLabel>
                                        <SubscriptionMain>
                                            {subscriptionData?.details?.amountPaid
                                                ? `${
                                                      subscriptionData?.details?.amountPaid / 100
                                                  } ${subscriptionData?.details?.currency?.toUpperCase()}`
                                                : '-'}
                                        </SubscriptionMain>
                                    </SubscriptionBox>
                                    <SubscriptionBox>
                                        <SubscriptionLabel>Subscription Plan:</SubscriptionLabel>
                                        <SubscriptionMain>
                                            {subscriptionData?.details?.planDescription ? subscriptionData?.details?.planDescription : '-'}
                                        </SubscriptionMain>
                                    </SubscriptionBox>
                                    <SubscriptionBox>
                                        <SubscriptionLabel> Subscription Until:</SubscriptionLabel>
                                        <SubscriptionMain>
                                            {subscriptionData?.details?.periodEnd
                                                ? formatUnixDate(subscriptionData?.details?.periodEnd, 'DD MMM YYYY')
                                                : '-'}
                                        </SubscriptionMain>
                                    </SubscriptionBox>
                                </div>

                                <div
                                    css={css`
                                        margin: 2rem 0;
                                    `}
                                >
                                    <a
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        href='https://billing.stripe.com/p/login/test_3cscQbcGifKcc4EfYY'
                                    >
                                        <Button>Manage Subscription</Button>
                                    </a>
                                </div>
                                <Divider />
                                <div
                                    css={css`
                                        margin-bottom: 2rem;
                                    `}
                                >
                                    <InfoLink
                                        icon='binoculars'
                                        title='FAQ'
                                        subtitle='Find out more about our Premium Subscription'
                                        linkUrl='/faq?q=benefits'
                                        linkTitle='Read FAQ'
                                    />
                                </div>
                                <h5>Your benefits</h5>
                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: space-between;
                                        ${mqMax[1]} {
                                            flex-direction: column;
                                        }
                                    `}
                                >
                                    <BenefitTile>
                                        <IconWrapper>
                                            <Icon name='star-outlined' size={32} />
                                        </IconWrapper>
                                        <BenefitTileHeading>Early Access</BenefitTileHeading>
                                        <BenefitText>
                                            New opportunity is available for subscribers for up to 48h before everyone else
                                        </BenefitText>
                                    </BenefitTile>
                                    <BenefitTile>
                                        <IconWrapper>
                                            <Icon name='star-outlined' size={32} />
                                        </IconWrapper>
                                        <BenefitTileHeading>Automatic alerts</BenefitTileHeading>
                                        <BenefitText>Automatic email alerts about new matching opportunities.</BenefitText>
                                    </BenefitTile>
                                    <BenefitTile>
                                        <IconWrapper>
                                            <Icon name='star-outlined' size={32} />
                                        </IconWrapper>
                                        <BenefitTileHeading>CV Management</BenefitTileHeading>
                                        <BenefitText>
                                            Talent Alpha team will anonymize your CVs on Talent Marketplace to reduce the human bias.
                                        </BenefitText>
                                    </BenefitTile>
                                </div>
                            </div>
                        )}

                        {activeContext?.companyId && !subscriptionData?.hasActiveSubscription && (
                            <div
                                css={css`
                                    max-width: 580px;
                                `}
                            >
                                <p>
                                    Subscribe to become a Premium user and get early access to opportunities, email alerts about new
                                    matching opportunities, and free anonymization of your CVs. You can find more details about our
                                    subscription benefits in our{' '}
                                    <Link to='/faq?q=benefits' target='_blank'>
                                        FAQ section
                                    </Link>
                                    .
                                </p>
                                <StripePricingTable companyId={activeContext?.companyId} />
                                <div
                                    css={css`
                                        margin-bottom: 12px;
                                    `}
                                >
                                    <InfoLink
                                        icon='binoculars'
                                        title='FAQ'
                                        subtitle='Find out more about our Premium Subscription'
                                        linkUrl='/faq?q=benefits'
                                        linkTitle='Read FAQ'
                                    />
                                </div>
                                <Divider />
                                <BenefitsTable />
                            </div>
                        )}
                    </section>
                </div>
            </SettingsWrapper>
        </DashboardLayout>
    )
}

export { SubscriptionDetails }
