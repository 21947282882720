/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useCallback } from 'react'
import { Button } from '../../../components/Button'
import { Icon, IconButton } from '../../../components/Icon'
import { Spinner } from '../../../components/layout/Loader'
import { ProcessingCvStatus } from '../../../contracts/cvs'

type StatusRendererProps = {
    status: ProcessingCvStatus
    errorMessage: string
    id: string
    onRemove: (id: string) => void
    setCurrentTab: Dispatch<SetStateAction<number>>
}

const StatusRenderer: FunctionComponent<React.PropsWithChildren<StatusRendererProps>> = ({
    status,
    errorMessage,
    id,
    onRemove,
    setCurrentTab,
}) => {
    const theme = useTheme()
    const handleRemove = useCallback(() => {
        onRemove(id)
    }, [id, onRemove])

    const handleConfirmExtractedDataClick = useCallback(() => {
        setCurrentTab(1)
    }, [setCurrentTab])

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: flex-end;
            `}
        >
            {status === ProcessingCvStatus.IN_PROGRESS ? (
                <Fragment>
                    <div
                        css={css`
                            color: ${theme.colors.blue_5};
                        `}
                    >
                        parsing in progress...
                    </div>
                    <div
                        css={css`
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            margin-left: 16px;
                        `}
                    >
                        <Spinner circleWidth={2} sizeInner={12} sizeOuter={16} />
                    </div>
                </Fragment>
            ) : status === ProcessingCvStatus.PARSING_FINISHED ? (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        color: ${theme.colors.green_5};
                    `}
                >
                    <div
                        css={css`
                            color: ${theme.colors.green_5};
                            margin-right: 16px;
                        `}
                    >
                        Data Extracted
                    </div>
                    <Icon name='check-green' size={16} />
                    <Button
                        variant='linkForm'
                        css={css`
                            margin-left: 16px;
                        `}
                        onClick={handleConfirmExtractedDataClick}
                    >
                        Confirm extracted data
                        <Icon name='arrow-right-single' />
                    </Button>
                </div>
            ) : (
                <Fragment>
                    <div
                        css={css`
                            color: ${theme.colors.red_4};
                        `}
                    >
                        {errorMessage || 'Couldn’t parse'}
                    </div>
                    <div
                        css={css`
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 40px;
                            margin-left: 16px;
                        `}
                    >
                        <div
                            css={css`
                                position: absolute;
                            `}
                        >
                            <IconButton name='close' size={40} onClick={handleRemove} />
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export { StatusRenderer }
