/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { signInWithGoogle } from '../api/firebase'
import google from '../assets/logos/google.svg'
import { Button } from './Button'
import { Divider } from './Divider'
import { Modal } from './Modal'

type GoogleLoginModalProps = {
    opened: boolean
    onClose: () => void
}

const GoogleLoginModal: FunctionComponent<React.PropsWithChildren<GoogleLoginModalProps>> = ({ opened, onClose }) => {
    const handleGoogleSSO = async () => {
        await signInWithGoogle()
    }

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            style={css`
                margin-top: calc(50% - 162px);
            `}
        >
            <div
                css={css`
                    max-width: 464px;
                `}
            >
                <h5>An account already exists for the e-mail you provided</h5>
                <p>Your account uses a Google account login, so please continue logging in using your Google credentials.</p>
                <Button
                    onClick={handleGoogleSSO}
                    variant='sso'
                    icon={google}
                    style={css`
                        width: 100%;
                        margin: 10px 0 14px;
                    `}
                >
                    Continue with Google
                </Button>
                <Divider />
                <div
                    css={css`
                        display: flex;
                        justify-content: flex-end;
                    `}
                >
                    <Button variant='text' onClick={onClose}>
                        Close
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export { GoogleLoginModal }
