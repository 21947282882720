/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { AutoComplete } from 'antd'
import { FunctionComponent, RefObject, useCallback, useEffect, useState } from 'react'
import { useDebouncedEffect } from '../../utils/hooks'
import { useLogger } from '../../utils/useLogger'
import { defaultInputStyle } from './Input'
import { InputField, InputFieldProps } from './input-field/InputField'

type Option = {
    label: string
    value: string
}

export type SuggestionInputProps = InputFieldProps & {
    onChange: (e: string) => void
    onBlur?: () => void
    onFocus?: () => void
    name?: string
    placeholder?: string
    disabled?: boolean
    value?: string
    fetchOptions?: (filter: string) => Promise<Array<string>>
    allowOnlyValuesFromDictionary?: boolean
    fetchFromLetterNumber?: number
    dataTestId?: string
    styles?: SerializedStyles
    forwardedRef?: RefObject<HTMLInputElement>
}

const parseOptions = (opts: Array<string>): Array<Option> => Array.from(new Set(opts)).map(o => ({ label: o, value: o }))

const SuggestionInput: FunctionComponent<React.PropsWithChildren<SuggestionInputProps>> = ({
    fetchOptions,
    onChange,
    onBlur,
    onFocus,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    name,
    value,
    placeholder,
    label,
    labelTooltip,
    disabled = false,
    allowOnlyValuesFromDictionary = false,
    fetchFromLetterNumber = 3,
    dataTestId = '',
    styles,
    forwardedRef,
    ...rest
}) => {
    const theme = useTheme()

    const [filter, setFilter] = useState('')
    const [options, setOptions] = useState<Array<Option>>([])
    const [defaultTestId, setDefaultTestId] = useState('')

    const log = useLogger('error')

    useDebouncedEffect(
        () => {
            fetchOptions && filter.length >= fetchFromLetterNumber
                ? fetchOptions(filter)
                      .then(opts => {
                          setOptions(parseOptions(opts))
                      })
                      .catch(log)
                : setOptions([])
        },
        250,
        [filter, fetchFromLetterNumber],
    )

    useEffect(() => {
        setOptions([])
    }, [name])

    useEffect(() => {
        setDefaultTestId(dataTestId ? dataTestId : name || '')
    }, [dataTestId, name])

    const handleChange = useCallback(
        (newValue: string) => {
            const itemFromList = options.find(o => o.value.toUpperCase() === newValue.toUpperCase())
            if (!itemFromList) {
                if (allowOnlyValuesFromDictionary) {
                    onChange('')
                } else {
                    onChange(newValue)
                    setFilter('')
                }
            }
        },
        [options, allowOnlyValuesFromDictionary, onChange],
    )

    const handleBlur = useCallback(() => {
        setFilter('')
        if (!forwardedRef) {
            onChange(value?.trim() || '')
        }
        if (onBlur) {
            onBlur()
        }
    }, [forwardedRef, onBlur, onChange, value])

    const handleSelect = useCallback(
        (newValue: string) => {
            onChange(newValue)
            setFilter('')
        },
        [onChange],
    )

    return (
        <InputField
            label={label}
            labelTooltip={labelTooltip}
            errorMessage={errorMessage}
            errorTooltipMessage={errorTooltipMessage}
            infoMessage={infoMessage}
            dataTestId={dataTestId || name}
        >
            {/* TODO: https://github.com/ant-design/ant-design/issues/27347 */}
            <AutoComplete
                options={options}
                onSearch={setFilter}
                onChange={handleChange}
                value={value || filter}
                disabled={disabled}
                dropdownClassName={`${defaultTestId}-dropdown`}
                {...rest}
                css={css`
                    width: 100%;
                `}
                onSelect={handleSelect}
            >
                <input
                    name={name}
                    placeholder={placeholder}
                    type='text'
                    disabled={disabled}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                    css={css`
                        ${defaultInputStyle(theme)}
                        ${errorMessage ? `border: 1px solid ${theme.colors.red_4};` : ''}
                        ${styles}
                    `}
                    data-testid={defaultTestId}
                    ref={forwardedRef}
                />
            </AutoComplete>
        </InputField>
    )
}

export { SuggestionInput }
