import React, { useEffect, useMemo, useState } from 'react'
import { Control, useWatch } from 'react-hook-form'

import { Rate } from '../share-my-specialists/components/cells/Rate'
import { ShareMySpecialistsFormData } from '../share-my-specialists/contracts'
import { ShareMySpecialistsSpecialist } from 'src/contracts/specialist/specialist'
import { useDebounce } from 'react-use'
import { getTARate } from 'src/api/api'
import logger from 'redux-logger'

interface OwnProps {
    record: ShareMySpecialistsSpecialist
    index: number
    control: Control<ShareMySpecialistsFormData>
}
const TARate: React.FC<OwnProps> = ({ record, index, control }) => {
    const formRate = useWatch({ control, name: `specialists.${index}.rate` })
    const [isRateFetching, setIsRateFetching] = useState(false)
    const [TARate, setTARate] = useState('-')

    const rate = useMemo(() => {
        // rate has been cleared from the input

        if (formRate === '') {
            setTARate('-')
            return 0
            // rate input has been edited
        } else if (!!formRate) {
            setIsRateFetching(true)
            return Number(formRate)
        }

        // rate input is closed, or it hasn't been edited
        return record.rate
    }, [formRate, record.rate])

    useEffect(() => {
        if (!formRate) {
            setIsRateFetching(false)
            setTARate('-')
        }
    }, [formRate])

    useDebounce(
        () => {
            if (rate) {
                getTARate(rate)
                    .then(data => {
                        setTARate(String(data.tarate))
                    })
                    .catch(logger)
                    .finally(() => {
                        setIsRateFetching(false)
                    })
            } else {
                setIsRateFetching(false)
                setTARate('-')
            }
        },
        500,
        [rate],
    )

    return isRateFetching ? <div>Loading...</div> : <Rate record={{ ...record, rate: Number(TARate) }} roundToInteger />
}

export { TARate }
