/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, MouseEvent, useCallback, useState } from 'react'
import { Button } from '../../../components/Button'
import { Card } from '../../../components/Card'
import { Divider } from '../../../components/Divider'
import { IconButton, IconName } from '../../../components/Icon'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { Modal } from '../../../components/Modal'
import { CvSourceType } from '../../../contracts/cvs'

type CvTypeProps = {
    iconName: IconName
    primaryText: string
    secondaryText: string
    isSelected: boolean
    cvUrl?: string
    cvName?: string
    parsingId?: string
    onClick: () => void
    canDisplayLatestCv: boolean
    handleFileDelete: (parsingId: string | undefined) => void
    type: CvSourceType
}

const CvType: FunctionComponent<React.PropsWithChildren<CvTypeProps>> = ({
    iconName,
    primaryText,
    secondaryText,
    isSelected,
    onClick,
    cvUrl,
    cvName,
    canDisplayLatestCv,
    parsingId,
    handleFileDelete,
    type,
}) => {
    const theme = useTheme()
    const [isModalOpened, setIsModalOpened] = useState(false)

    const toggleModal = useCallback((e?: MouseEvent) => {
        if (e) {
            e.stopPropagation()
        }
        setIsModalOpened(currentIsModalOpened => !currentIsModalOpened)
    }, [])

    const handleDeleteClick = useCallback(() => {
        handleFileDelete(parsingId)
        toggleModal()
    }, [handleFileDelete, parsingId, toggleModal])

    return (
        <Fragment>
            <Card
                dataTestId={`cv-type-${type}`}
                iconName={iconName}
                desktopSpan={12}
                xsmallSpan={24}
                mediumSpan={12}
                smallSpan={24}
                iconSize={48}
                height='244px'
                onCardClick={onClick}
                iconBackgroundColor={isSelected ? theme.colors.violet_2 : theme.colors.violet_1}
                iconHoverColor={theme.colors.violet_3}
                styles={css`
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
                    &:hover {
                        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                    }
                    background-color: ${isSelected ? theme.colors.violet_1 : 'transparent'};
                `}
                footer={
                    <Button
                        variant='secondary'
                        size='small'
                        css={css`
                            margin: 16px 0;
                        `}
                    >
                        Start
                    </Button>
                }
                title={
                    <Fragment>
                        <div
                            css={css`
                                margin: 26px 0 8px;
                                font-size: 16px;
                                font-weight: 500;
                            `}
                        >
                            {primaryText}
                        </div>
                        <div
                            css={css`
                                color: ${theme.colors.gray_4};
                            `}
                        >
                            {secondaryText}
                        </div>
                        {cvName && canDisplayLatestCv && (
                            <FlexBox
                                css={css`
                                    align-items: center;
                                    justify-content: center;
                                `}
                            >
                                <a href={cvUrl} target='_blank' rel='noopener noreferrer'>
                                    <Button variant='linkForm'>{cvName}</Button>
                                </a>
                                <IconButton name='trash' size={32} onClick={toggleModal} />
                            </FlexBox>
                        )}
                    </Fragment>
                }
            />
            <Modal opened={isModalOpened} onClose={toggleModal}>
                <FlexBox
                    css={css`
                        flex-direction: column;
                    `}
                >
                    <h5>Delete File</h5>
                    <p>Deleting file will not affect data on your profile</p>
                    <Divider />
                    <div
                        css={css`
                            display: flex;
                            align-self: flex-end;
                        `}
                    >
                        <Button
                            variant='link'
                            style={css`
                                margin-right: 22px;
                            `}
                            onClick={toggleModal}
                        >
                            Cancel
                        </Button>

                        <Button type='submit' variant='delete' onClick={handleDeleteClick}>
                            Remove
                        </Button>
                    </div>
                </FlexBox>
            </Modal>
        </Fragment>
    )
}

export { CvType }
