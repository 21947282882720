/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useLifecycles } from 'react-use'
import { getUser, getUserSegmentationCompanies } from '../../../../api/api'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Info } from '../../../../components/Info'
import { ContentLoader } from '../../../../components/layout/Loader'
import { SettingsWrapper } from '../../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { Company } from '../../../../contracts/company'
import { useUserCompanies } from '../../../../hooks/useUserCompanies'
import { ReduxContext } from '../../../../redux/Store'
import { Nullable } from '../../../../types'
import { useLogger } from '../../../../utils/useLogger'
import { removeInactiveCompany, sendCompanyEmailConfirmation } from '../../api'

const CreateCompanyAccountPossible: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <Fragment>
        <h6>Create Company Account</h6>
        <p>Create a Company Account to unleash the full potential of the Human Cloud.</p>
        <Divider />
        <Link
            to='/dashboard/company-account/company-details/form'
            css={css`
                text-decoration: none;
            `}
        >
            <Button dataTestId='create-company-account-trigger'>Create Company Account</Button>
        </Link>
    </Fragment>
)

const CreateCompanyAccountImpossible: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <Fragment>
        <h6>Create Company Account</h6>
        <p>
            You will be able to create a Company Account once you fill in your personal details. Create a Company Account to unleash the
            full potential of the Human Cloud.
        </p>
        <Divider />
        <Link
            to='/dashboard/my-account/personal-details'
            css={css`
                text-decoration: none;
            `}
        >
            <Button dataTestId='personal-data-settings'>Personal Data Settings</Button>
        </Link>
        <Divider />
        <Button disabled dataTestId='create-company-account-trigger'>
            Create Company Account
        </Button>
    </Fragment>
)

const ConfirmCompanyEmail = ({
    workEmail,
    handleCancelRequest,
    handleResendClick,
}: {
    workEmail: string
    handleCancelRequest: () => void
    handleResendClick: () => void
}) => (
    <Fragment>
        <h6 data-testid='confirm-company-account-check'>Confirm Company Account</h6>
        <p>
            Check your work email <strong>{workEmail}</strong> to confirm Company Account.
        </p>
        <Divider />
        <Button variant='link' onClick={handleResendClick}>
            Resend Email
        </Button>
        <br />
        <Button variant='link' onClick={handleCancelRequest} dataTestId='remove-inactive-company'>
            Cancel Request
        </Button>
    </Fragment>
)

interface CopanyAccountProps {
    company?: Company
    status: Nullable<string>
    getStatus: () => void
}

const CompanyAccount: FunctionComponent<React.PropsWithChildren<CopanyAccountProps>> = ({ company, status }) => {
    const {
        selectors: { user },
    } = useContext(ReduxContext)

    const { addSuccess, addError } = useNotifications()

    const { hasInactiveCompany } = useUserCompanies()

    const log = useLogger()

    const navigate = useNavigate()

    const [workEmail, setWorkEmail] = useState<Nullable<string>>(null)
    const [companyId, setCompanyId] = useState('')
    const [hasPersonalDetails, setHasPersonalDetails] = useState<Nullable<boolean>>(null)
    const [isMounted, setIsMounted] = useState(false)

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    useEffect(() => {
        if (user.firebaseId) {
            getUser(user.firebaseId)
                .then(userData => {
                    if (userData.email && userData.firstName && userData.lastName && isMounted) {
                        return setHasPersonalDetails(true)
                    }

                    if (isMounted) setHasPersonalDetails(false)
                })
                .catch(() => {
                    if (isMounted) {
                        setHasPersonalDetails(false)
                    }
                })
        }
    }, [company, isMounted, user.firebaseId])

    const handleResendClick = useCallback(() => {
        sendCompanyEmailConfirmation(companyId)
            .then(() => {
                addSuccess('The confirmation email has been sent successfully')
            })

            .catch(log)
    }, [addSuccess, log, companyId])

    useEffect(() => {
        getUserSegmentationCompanies().then(companies => {
            const inactiveCompany = companies.find(company => company.status === 'INACTIVE')
            if (isMounted && inactiveCompany) {
                setWorkEmail(inactiveCompany.workEmailAddress)
                setCompanyId(inactiveCompany.id)
            }
        })
    }, [isMounted])

    const handleCancelRequest = useCallback(() => {
        removeInactiveCompany(companyId)
            .then(() => {
                addSuccess('The request was cancelled successfully')
                navigate('/dashboard')
            })

            .catch(error => {
                log(error)
                addError()
            })
    }, [addSuccess, addError, navigate, log, companyId])

    return (
        <SettingsWrapper>
            <h4>Company Account</h4>

            <Divider />

            <Info>
                {hasPersonalDetails === null ||
                (hasPersonalDetails === true && hasInactiveCompany === null) ||
                (hasPersonalDetails === true && hasInactiveCompany === true && workEmail === null) ? (
                    <ContentLoader />
                ) : (
                    <Fragment>
                        {hasPersonalDetails && status === 'NO_COMPANIES' && <CreateCompanyAccountPossible />}
                        {hasPersonalDetails === false && <CreateCompanyAccountImpossible />}
                        {hasPersonalDetails && status === 'INACTIVE' && workEmail && (
                            <ConfirmCompanyEmail
                                workEmail={workEmail}
                                handleCancelRequest={handleCancelRequest}
                                handleResendClick={handleResendClick}
                            />
                        )}
                    </Fragment>
                )}
            </Info>
        </SettingsWrapper>
    )
}

export { CompanyAccount }
