import { useContext, useEffect, useState } from 'react'
import { usePageVisibility } from 'react-page-visibility'
import { getCurrentUserContextGroups, getCurrentUserContexts } from '../api/api'
import { history } from '../history'
import { ReduxContext } from '../redux/Store'
import { getUserRole } from '../utils/currentUser'
import { uuidRegex } from '../utils/regexes'
import { useLogger } from '../utils/useLogger'
import { afterSuccessForbiddenPaths, getAfterSuccessParam, getUserActiveContextStorage } from '../utils/userContext'
import { useUserAuthentication } from './useUserAuthentication'
import { getVMCUserStatus } from 'src/pages/dashboard/api'

const useUserContextListeners = () => {
    const {
        actions: {
            setUserContexts,
            setUserActiveContextId,
            setUserActiveContextGroups,
            setShouldWaitForUserContexts,
            setShouldWaitForWhiteLabel,
            setVMCstatus,
        },
        selectors: { userContexts, userActiveContextId, VMCstatus },
    } = useContext(ReduxContext)

    const [shouldUpdateApp, setShouldUpdateApp] = useState(false)

    const { isUserAuthorized, isWaitingForStatus } = useUserAuthentication()

    const log = useLogger('error')

    const role = getUserRole()

    useEffect(() => {
        // TODO: enable contexts for specialists when BE will be ready
        if (!userContexts && isUserAuthorized && !isWaitingForStatus && role !== 'specialist') {
            setShouldWaitForUserContexts(true)
            getCurrentUserContexts()
                .then(contexts => {
                    setUserContexts(contexts)
                })
                .catch(e => {
                    log(e)
                    setUserActiveContextGroups(null)
                })
        } else if (!isWaitingForStatus) {
            setShouldWaitForUserContexts(false)
        }
    }, [
        setUserContexts,
        log,
        userContexts,
        isUserAuthorized,
        isWaitingForStatus,
        setUserActiveContextGroups,
        setShouldWaitForUserContexts,
        role,
    ])

    useEffect(() => {
        if (!userActiveContextId && isUserAuthorized && !isWaitingForStatus && userContexts) {
            const { storedUserActiveContextId } = getUserActiveContextStorage()
            const canPassAfterSuccess = !afterSuccessForbiddenPaths.includes(history.location.pathname)
            if (uuidRegex.test(storedUserActiveContextId)) {
                if (userContexts.some(context => context.contextId === storedUserActiveContextId)) {
                    if (
                        userContexts.some(context => context.type === 'COMPANY') &&
                        userContexts.find(context => context.contextId === storedUserActiveContextId)?.type === 'USER'
                    ) {
                        history.push('/refresh-user-context')
                    } else {
                        setUserActiveContextId(storedUserActiveContextId)
                        setShouldWaitForWhiteLabel(true)
                    }
                } else if (history.location.pathname !== '/refresh-user-context' && history.location.pathname !== '/unavailable-context') {
                    history.push(
                        `/refresh-user-context?wantedContextId=${storedUserActiveContextId}${
                            canPassAfterSuccess ? `&${getAfterSuccessParam()}` : ''
                        }`,
                    )
                }
            } else if (history.location.pathname !== '/refresh-user-context' && history.location.pathname !== '/unavailable-context') {
                history.push(`/refresh-user-context${canPassAfterSuccess ? `?${getAfterSuccessParam()}` : ''}`)
            }
        }
    }, [setUserActiveContextId, userActiveContextId, userContexts, isUserAuthorized, isWaitingForStatus, setShouldWaitForWhiteLabel])

    useEffect(() => {
        if (userActiveContextId && isUserAuthorized && !isWaitingForStatus) {
            getCurrentUserContextGroups(userActiveContextId)
                .then(contextGroups => setUserActiveContextGroups(contextGroups))
                .catch(e => {
                    log(e)
                    setUserActiveContextGroups(null)
                })
                .finally(() => setShouldWaitForUserContexts(false))
        }
    }, [userActiveContextId, log, setUserActiveContextGroups, isUserAuthorized, isWaitingForStatus, setShouldWaitForUserContexts])

    const isVisible = usePageVisibility()

    useEffect(() => {
        if (getUserRole() !== 'specialist') {
            const syncContext = () => {
                const { storedUserActiveContextId } = getUserActiveContextStorage()
                if (uuidRegex.test(storedUserActiveContextId) && storedUserActiveContextId !== userActiveContextId) {
                    const canPassAfterSuccess = !afterSuccessForbiddenPaths.includes(history.location.pathname)
                    history.push(
                        `/refresh-user-context?wantedContextId=${storedUserActiveContextId}${
                            canPassAfterSuccess ? `&${getAfterSuccessParam()}` : ''
                        }`,
                    )
                }
            }
            if (!isVisible) {
                setShouldUpdateApp(true)
            } else {
                if (shouldUpdateApp) {
                    syncContext()
                }
                setShouldUpdateApp(false)
            }
        }
    }, [isVisible, userActiveContextId, shouldUpdateApp])

    useEffect(() => {
        if (isUserAuthorized && !isWaitingForStatus) {
            getVMCUserStatus()
                .then(response => {
                    setVMCstatus(response)
                })
                .catch(log)
        }
    }, [isUserAuthorized, log, setVMCstatus, VMCstatus, isWaitingForStatus])
}

export { useUserContextListeners }
