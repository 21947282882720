import queryString from 'query-string'
import { Nullable } from '../../../types'
import { encodeField, parseToArray, parseToBoolean, parseToNumber, parseToString } from '../../../utils/searchFilters'
import { ApplicationStatusEnum, SearchApplicationsRequestParams } from '../../../contracts/opportunities-manager/contracts'

const stringifyOpportunitiesManagerApplicationsSearchRequestToQuery = (
    searchRequest: SearchApplicationsRequestParams,
    prevPath: Nullable<string>,
): string => {
    const params = []
    const { countries, statuses, paging, searchText, availableFrom, availableTo, maxRate, minRate, msa, cities, partnerNames, roles } =
        searchRequest

    if (statuses && statuses.length > 0) {
        params.push(`statuses=${statuses.join(',')}`)
    }

    if (searchText) {
        params.push(`searchText=${encodeField(searchText)}`)
    }

    if (countries && countries.length > 0) {
        params.push(`countries=${encodeField(countries)}`)
    }

    if (cities && cities.length > 0) {
        params.push(`cities=${encodeField(cities)}`)
    }

    if (partnerNames && partnerNames.length > 0) {
        params.push(`partnerNames=${encodeField(partnerNames)}`)
    }

    if (roles && roles.length > 0) {
        params.push(`roles=${encodeField(roles)}`)
    }

    if (paging && paging.page !== 1) {
        params.push(`page=${paging.page}`)
    }

    if (paging && paging.size) {
        params.push(`size=${paging.size}`)
    }

    if (availableFrom) {
        params.push(`availableFrom=${availableFrom}`)
    }

    if (availableTo) {
        params.push(`availableTo=${availableTo}`)
    }

    if (maxRate) {
        params.push(`maxRate=${maxRate}`)
    }

    if (minRate) {
        params.push(`minRate=${minRate}`)
    }

    if (msa || msa === false) {
        params.push(`msa=${msa ? 1 : 0}`)
    }

    if (prevPath) {
        params.push(`prevPath=${encodeURIComponent(prevPath)}`)
    }

    return params.join('&')
}

const parseQueryToOpportunitiesManagerApplicationsSearchRequest = (
    searchQuery: string,
): Omit<SearchApplicationsRequestParams, 'sorting'> => {
    const params = queryString.parse(searchQuery, { decode: false })
    const { countries, statuses, page, searchText, size, availableFrom, availableTo, maxRate, minRate, msa, cities, partnerNames, roles } =
        params

    const result: Omit<SearchApplicationsRequestParams, 'sorting'> = {
        availableFrom: availableFrom ? parseToNumber(availableFrom) : null,
        availableTo: availableTo ? parseToNumber(availableTo) : null,
        maxRate: maxRate ? parseToNumber(maxRate) : null,
        minRate: minRate ? parseToNumber(minRate) : null,
        searchText: parseToString(searchText),
        msa: msa ? parseToBoolean(msa) : null,
        cities: parseToArray(cities),
        partnerNames: parseToArray(partnerNames),
        roles: parseToArray(roles),
        paging: {
            page: parseToNumber(page, 1),
            size: parseToNumber(size, 10),
        },
        statuses: statuses ? ((typeof statuses === 'string' ? statuses : '').split(',') as Array<ApplicationStatusEnum>) : [],
        countries: parseToArray(countries),
    }

    return result
}

export { stringifyOpportunitiesManagerApplicationsSearchRequestToQuery, parseQueryToOpportunitiesManagerApplicationsSearchRequest }
