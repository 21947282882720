/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { ProgressBar } from '../../../components/ProgressBar'
import { SkillsQuestionnaireItem } from '../../../components/skills-questionnaire/SkillsQuestionnaireItem'
import { Skill } from '../../../contracts/skills'
import { StrengthProficiency } from '../../../contracts/strengths'
import { Nullable, StringMapping } from '../../../types'
import { FormDataModel, FormType } from '../contracts'
import { StrengthQuestionnaireItem } from './StrengthQuestionnaireItem'

type SingleFormProps = {
    handleChange: (skill: any) => void
    data: FormDataModel
    currentPageIndex: number
    type?: FormType
    defaultLabels?: Nullable<StringMapping>
    isValid?: (state: boolean) => void
}

const mapDataToFormValues = (data: FormDataModel, type: FormType = 'SKILL') => {
    let output: any = {}

    if (type === 'SKILL') {
        data.pages.forEach(page => {
            page.sections.forEach(question => {
                question.answers?.forEach(answer => {
                    output[`skills[${question.id}][${answer.id}]`] = answer || null
                })
            })
        })
    }

    if (type === 'STRENGTH') {
        data.pages.forEach(page => {
            page.sections.forEach(question => {
                output[`strengths[${question.id}]`] = question.answer || null
            })
        })
    }

    return output
}

const SingleForm: FunctionComponent<React.PropsWithChildren<SingleFormProps>> = ({
    data,
    handleChange,
    currentPageIndex,
    type,
    defaultLabels,
    isValid,
}) => {
    const [values, setValues] = useState(mapDataToFormValues(data, type))
    const theme = useTheme()

    const isInvalid = useCallback(() => {
        if (type === 'STRENGTH') {
            const currentPageQuestions = data.pages[currentPageIndex].sections
            return currentPageQuestions.some(question => values[`strengths[${question.id}]`] === null)
        }

        return false
    }, [data.pages, currentPageIndex, values, type])

    useEffect(() => {
        if (isValid) {
            isValid(!isInvalid())
        }
    }, [values, currentPageIndex, isInvalid, isValid])

    const onValueChanges = useCallback(
        (event: any, name: any) => {
            if (type === 'STRENGTH') {
                setValues({ ...values, [name]: event.answer })
            }

            if (type === 'SKILL') {
                setValues({ ...values, [name]: event })
            }

            if (handleChange) {
                handleChange(event)
            }
        },
        [values, handleChange, type],
    )

    const isDirty = useCallback((field: string) => values[field] !== null, [values])

    const currentProgress = useMemo(() => (data ? ((currentPageIndex + 1) / data.pages.length) * 100 : 100), [currentPageIndex, data])

    const getStyles = useCallback(
        (question: any) => {
            if (type === 'STRENGTH') {
                const isTouched = isDirty(`strengths[${question.id}]`)
                const styles = `background-color: ${theme.colors.white};
            border-bottom: 1px solid ${isTouched ? theme.colors.gray_2 : theme.colors.white};`

                return styles
            }

            return null
        },
        [isDirty, theme.colors.gray_2, theme.colors.white, type],
    )

    return (
        <Fragment>
            <ProgressBar currentProgress={currentProgress} />

            <p data-testid='description'>{data.description}</p>
            {data.pages.map((page, pageIndex) => (
                <div
                    key={pageIndex}
                    css={css`
                        margin-top: 20px;
                        display: ${pageIndex === currentPageIndex ? 'block' : 'none'};
                    `}
                    data-testid={`page-${pageIndex}`}
                >
                    <h5
                        css={css`
                            margin-top: 20px;
                            margin-bottom: 20px;
                        `}
                        data-testid='page-name'
                    >
                        {page.name}
                    </h5>

                    {type === 'SKILL' &&
                        page.sections.map((question, questionIndex) => (
                            <div key={question.id}>
                                <h6
                                    css={css`
                                        font-size: 16px;
                                        margin-top: 24px;
                                        margin-bottom: 24px;
                                    `}
                                    data-testid={`question-${questionIndex}`}
                                >
                                    {questionIndex + 1}. {question.question}
                                </h6>

                                {question.answers?.map((answer, index) => (
                                    <SkillsQuestionnaireItem
                                        key={`skills[${question.id}][${answer.id}]`}
                                        value={values[`skills[${question.id}][${answer.id}]`] as Skill}
                                        expandedScale={true}
                                        labels={defaultLabels as StringMapping}
                                        onChange={event => onValueChanges(event, `skills[${question.id}][${answer.id}]`)}
                                    />
                                ))}
                            </div>
                        ))}

                    {type === 'STRENGTH' &&
                        page.sections.map((question, questionIndex) => (
                            <div
                                key={question.id}
                                css={css`
                                    ${getStyles(question)}

                                    padding: 24px 16px;
                                `}
                            >
                                <div
                                    css={css`
                                        font-weight: 500;
                                        font-size: 16px;
                                        margin-bottom: 16px;
                                    `}
                                    data-testid={`question-${questionIndex}`}
                                >
                                    {question.question}
                                </div>

                                <StrengthQuestionnaireItem
                                    id={question.id}
                                    value={values[`strengths[${question.id}]`] as StrengthProficiency}
                                    onChange={event => onValueChanges(event, `strengths[${question.id}]`)}
                                />
                            </div>
                        ))}
                </div>
            ))}
        </Fragment>
    )
}

export { SingleForm }
