import { Application } from '../../../../contracts/open-opportunities/contracts'
import { ADD, INIT, REMOVE, SELECT_APPLICATION, SET_STEP, UPDATE_APPLICATION_CV } from './actions'
import { Action, State } from './types'
import { parseApplication } from './utils'

const initialState = { applications: [], selectedApplicationId: null }

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case INIT: {
            if (state.applications.length === 0 && action.payload.applications.length > 0) {
                // Provided invited candidate id might be obsolete, in that case we want to fallback to first available application.
                const selectedApplicationId =
                    action.payload.selectedApplicationId &&
                    (action.payload.applications as Application[]).find(
                        ({ applicationId }) => applicationId === action.payload.selectedApplicationId,
                    )
                        ? action.payload.selectedApplicationId
                        : null

                return {
                    applications: [...state.applications, ...action.payload.applications.map(parseApplication)],
                    selectedApplicationId: selectedApplicationId || action.payload.applications[0]?.applicationId,
                }
            }

            return state
        }

        case ADD: {
            const applications = [...state.applications].concat(parseApplication(action.payload.newApplicationData))
            if (action.payload.currentApplicationData) {
                applications[
                    applications.findIndex(application => application.applicationId === action.payload.currentApplicationData.applicationId)
                ] = action.payload.currentApplicationData
            }
            return {
                applications,
                selectedApplicationId: action.payload.newApplicationData.applicationId,
            }
        }

        case REMOVE: {
            const applications = [...state.applications.filter(application => application.applicationId !== action.payload)]
            return {
                applications,
                selectedApplicationId:
                    state.selectedApplicationId === action.payload ? applications[0]?.applicationId || null : state.selectedApplicationId,
            }
        }

        case SELECT_APPLICATION: {
            const applications = [...state.applications]
            if (action.payload.currentApplicationData) {
                applications[
                    applications.findIndex(application => application.applicationId === action.payload.currentApplicationData.applicationId)
                ] = action.payload.currentApplicationData
            }
            return {
                applications,
                selectedApplicationId: action.payload.applicationId,
            }
        }

        case SET_STEP: {
            const applications = [...state.applications]
            applications[applications.findIndex(application => application.applicationId === action.payload.applicationId)] = action.payload
            return {
                ...state,
                applications,
            }
        }

        case UPDATE_APPLICATION_CV: {
            const applications = [...state.applications]
            const applicationIndex = applications.findIndex(application => application.applicationId === action.payload.applicationId)
            applications[applicationIndex] = {
                ...applications[applicationIndex],
                ...action.payload.application,
                isSendingCv: action.payload.isSendingCv,
                cvFileName: action.payload.cvFileName || applications[applicationIndex].cvFileName,
                isUpdatingCv: action.payload.isUpdatingCv,
            }
            return {
                ...state,
                applications,
            }
        }

        default:
            return state
    }
}

export { reducer, initialState }
