/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, MouseEvent, useCallback } from 'react'
import { blackToBeige4Filter, Icon, IconButton } from '../../components/Icon'
import { Theme } from '../../theme/theme'
import { Nullable } from '../../types'

import { ApplicationStatusEnum } from 'src/contracts/opportunities-manager/contracts'
import { ApplicationData } from './ApplicationWizard/redux/types'

type CandidatesListItemProps = {
    application?: ApplicationData
    onClick?: (applicationId: string) => void
    onRemove?: (applicationId: string) => void
    selectedApplication?: Nullable<string>
}

const animation = (theme: Theme) => css`
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: linear-gradient(to right, ${theme.colors.beige_3} 8%, ${theme.colors.beige_4} 38%, ${theme.colors.beige_3} 54%);
    background-size: 1000px 640px;
    @keyframes placeHolderShimmer {
        0% {
            background-position: -1000px 0;
        }
        100% {
            background-position: 1000px 0;
        }
    }
`

const CandidatesListItem: FunctionComponent<React.PropsWithChildren<CandidatesListItemProps>> = ({
    application,
    onClick,
    onRemove,
    selectedApplication,
}) => {
    const theme = useTheme()

    const handleClick = useCallback(() => {
        if (application && onClick) {
            onClick(application.applicationId)
        }
    }, [application, onClick])

    const handleRemove = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            if (application && onRemove) {
                onRemove(application.applicationId)
            }
        },
        [application, onRemove],
    )

    const isApplicationIncomplete = application?.recentStatus.draft || application?.recentStatus.status === ApplicationStatusEnum.INVITED

    return (
        <div
            css={css`
                padding: 16px;
                border-radius: 4px;
                box-shadow: ${theme.shadow.small};
                margin-bottom: 8px;
                border: 1px solid
                    ${application && selectedApplication === application.applicationId ? theme.colors.violet_3 : theme.colors.gray_2};
                background-color: ${!application || isApplicationIncomplete ? 'transparent' : theme.colors.green_1};
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                width: 100%;
                text-align: left;
            `}
            onClick={handleClick}
            data-testid='candidates-list-item'
        >
            <div>
                <div
                    css={css`
                        font-size: 16px;
                        font-weight: 500;
                        background-color: ${application ? 'transparent' : theme.colors.beige_3};
                    `}
                >
                    {application ? (
                        application.firstName || application.lastName ? (
                            `${application.firstName} ${application.lastName}`
                        ) : (
                            application.cvFileName || ''
                        )
                    ) : (
                        <div
                            css={css`
                                width: 200px;
                                height: 20px;
                                margin-bottom: 4px;

                                ${!application ? animation(theme) : ''}
                            `}
                        />
                    )}
                </div>
                <div
                    css={css`
                        font-size: 12px;
                        color: ${theme.colors.gray_4};
                    `}
                >
                    {application ? (
                        application.role
                    ) : (
                        <div
                            css={css`
                                width: 75px;
                                height: 16px;

                                ${!application ? animation(theme) : ''}
                            `}
                        />
                    )}
                </div>
            </div>
            {application && (
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                    `}
                >
                    {application.recentStatus.draft ? (
                        <Fragment>
                            <Tooltip
                                placement='right'
                                title={<div>Please fill in all required information in order to apply with this specialist.</div>}
                            >
                                <span>
                                    <Icon name='exclamation-mark' size={18} />
                                </span>
                            </Tooltip>
                            <IconButton
                                name='close'
                                onClick={handleRemove}
                                styles={css`
                                    margin-left: 8px;
                                `}
                                size={18}
                                innerSize={18}
                                style={css`
                                    filter: ${blackToBeige4Filter} !important;
                                `}
                                dataTestId='remove-candidate'
                            />
                        </Fragment>
                    ) : (
                        <Icon
                            name='check'
                            size={18}
                            style={css`
                                background-color: ${theme.colors.green_3};
                                padding: 4px;
                                border-radius: 8px;
                            `}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export { CandidatesListItem }
