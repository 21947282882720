/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Profile } from '../../../contracts/profile/profile'
import { Nullable } from '../../../types'
import { getPublicSpecialistProfile } from '../api'
import { SpecialistProfile } from '../shared/SpecialistProfile'

const PublicProfile = () => {
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const navigate = useNavigate()
    const { linkId } = useParams<{ linkId: string }>()

    useEffect(() => {
        if (linkId) {
            getPublicSpecialistProfile(linkId)
                .then((specialistProfile: any) => {
                    setProfile(specialistProfile)
                })
                .catch((error: any) => {
                    if (error?.status === 404) {
                        navigate('/profile-not-found-public')
                    }
                })
        }
    }, [linkId, navigate])

    return <Fragment>{profile && <SpecialistProfile specialistProfile={profile} isPublic={true} />}</Fragment>
}

export { PublicProfile }
