import { Paging } from 'src/contracts/common/pagingProps'
import { Sorting } from 'src/contracts/common/sorting'
import { AvailableOn } from 'src/contracts/search/availableOn'
import { ShareMySpecialistsEditData, ShareMySpecialistsSpecialist, SharedType } from 'src/contracts/specialist/specialist'
import { Nullable, StringMapping } from 'src/types'

export type ShareMySpecialistsFilters = {
    availableFrom?: Nullable<number>
    availableTo?: Nullable<number>
    rateFrom?: Nullable<number>
    rateTo?: Nullable<number>
    roles: Array<string>
    seniorities: Array<string>
    shared: Array<string>
    availableOn: Array<AvailableOn>
    currencies: Array<string>
}

export interface ShareMySpecialistsSearchText {
    searchText?: string
}

export interface ShareMySpecialistsSearchRequest
    extends ShareMySpecialistsSearchText,
        Partial<ShareMySpecialistsFilters>,
        Paging,
        Sorting {}

export interface ShareMySpecialistsSearchResponse {
    specialists: Array<ShareMySpecialistsSpecialist>
    total: number
}

export const sharedTypes = [SharedType.SHARED, SharedType.NOT_SHARED]

export const SharedMapping: StringMapping = {
    [SharedType.SHARED]: 'Shared',
    [SharedType.NOT_SHARED]: 'Not Shared',
    [SharedType.BOOKING_REQUEST_ACCEPTED]: 'Booking Request Accepted',
    [SharedType.BOOKING_REQUEST_RECEIVED]: 'Booking Request Received',
}

export const mapSharedType = (type: Nullable<SharedType>) => {
    return type !== null ? SharedMapping[type] : ''
}

export interface ShareMySpecialistsSelectAllRequest extends ShareMySpecialistsSearchText, Partial<ShareMySpecialistsFilters> {}

export interface ShareMySpecialistsSelectAllResponse {
    specialistsIds: Array<string>
}

export interface ShareMySpecialistsStatistics {
    allSpecialists: number
    allSpecialistsInShareMySpecialists: number
    allSpecialistsShared: number
    allSpecialistsSharedInPublicHumanCloud: number
    allSpecialistsSharedInSyndicate: number
    allSpecialistsAvailableToShareMySpecialists: number
    allEditableSpecialists: number
}

export type ShareMySpecialistsFormData = {
    specialists: Array<Omit<ShareMySpecialistsEditData, 'rate'> & { rate?: number | '' }>
}
