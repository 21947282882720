import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useEffect, useContext } from 'react'

import { useLogger } from '../utils/useLogger'
import { useNotifications } from '../components/notification/NotificationProvider'
import { addUserToSyndicate } from '../api/api'
import { wait } from '../utils/promise'
import { ReduxContext } from '../redux/Store'

const PWC_SYNDICATE = 'pwc'

const parseSyndicateParam = (syndicateParam: queryString.ParsedQuery[keyof queryString.ParsedQuery]) => {
    // we expect syndicatge param to be passed only as a single argument/string
    if (!syndicateParam || Array.isArray(syndicateParam)) {
        return null
    }

    return syndicateParam
}

const useSyndicate = () => {
    const { search } = useLocation()
    const query = queryString.parse(search)
    const syndicate = parseSyndicateParam(query.syndicate)
    const isPwcSyndicate = syndicate === PWC_SYNDICATE

    return { syndicate, isPwcSyndicate }
}

const addUserToSyndicateWithDelay = async ({
    params,
    delay,
    errorCallback,
    loadingCallback,
}: {
    params: { email: string; syndicate: string }
    delay?: number
    errorCallback: (e: unknown) => void
    loadingCallback: (isLoading: boolean) => void
}) => {
    loadingCallback(true)
    if (delay) {
        await wait(delay)
    }
    try {
        await addUserToSyndicate(params)
    } catch (error) {
        errorCallback(error)
    } finally {
        loadingCallback(false)
    }
}

const useAddUserToSyndicate = ({ email, delay }: { email: string | null; delay?: number }) => {
    const { syndicate } = useSyndicate()
    const log = useLogger()
    const { addError } = useNotifications()
    const {
        actions: { layoutToggleLoader: loadingCallback },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (email && syndicate) {
            addUserToSyndicateWithDelay({
                params: { email, syndicate },
                delay,
                errorCallback: error => {
                    log(error)
                    addError()
                },
                loadingCallback,
            })
        }
    }, [addError, delay, email, loadingCallback, log, syndicate])
}

export { useSyndicate, useAddUserToSyndicate }
