/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledRadioGroup } from '../../../components/forms/ControlledRadioGroup'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { ProgressBarTimer } from '../../../components/ProgressBarTimer'
import { Answer, NextQuestionRequestBody, Question } from '../tests'
import { TestImage } from './Image'

type TestQuestionProps = {
    question: Question
    handleSendAnswer: (data: NextQuestionRequestBody) => void
    toggleFeedbackModal: () => void
}

const AnswerWrapper = styled.div`
    font-size: 18px;
    white-space: break-spaces;
`

const TestQuestion: FunctionComponent<React.PropsWithChildren<TestQuestionProps>> = ({
    question,
    handleSendAnswer,
    toggleFeedbackModal,
}) => {
    const [isTimeExpired, setIsTimeExpired] = useState(false)

    const { handleSubmit, control, watch } = useForm<NextQuestionRequestBody>({
        mode: 'onChange',
        defaultValues: {
            answer: Answer.NO_ANSWER,
        },
    })

    const answer = watch('answer')

    const handleTimeExpiration = useCallback(() => {
        setIsTimeExpired(true)
    }, [])

    const theme = useTheme()

    return (
        <Fragment>
            <ProgressBarTimer initialTimeLeft={question.timeLeft / 1000} time={90} handleTimeExpiration={handleTimeExpiration} />
            <div
                css={css`
                    font-size: 18px;
                    font-weight: 500;
                `}
            >
                {question.statement}
            </div>

            {question.statementImage && (
                <TestImage
                    url={question.statementImage.url}
                    width={question.statementImage.width}
                    height={question.statementImage.height}
                    styles={css`
                        margin-bottom: 20px;
                    `}
                />
            )}

            <form
                onSubmit={handleSubmit(handleSendAnswer)}
                css={css`
                    margin-top: 24px;
                `}
            >
                <ControlledRadioGroup
                    dataTestId='test-answers'
                    styles={css`
                        display: flex;
                        flex-direction: column;
                        .ant-radio-wrapper {
                            padding: 16px 8px 16px 16px;
                            margin: 0;
                        }
                        .ant-radio-wrapper-checked {
                            background-color: ${theme.colors.violet_1};
                            border-radius: 4px;
                        }
                        .ant-radio-checked {
                            .ant-radio-inner {
                                border-color: ${theme.colors.violet_4};
                                :after {
                                    background-color: ${theme.colors.violet_4};
                                }
                            }
                        }
                    `}
                    control={control}
                    name='answer'
                    options={[
                        {
                            label: question.answerAImage ? (
                                <TestImage
                                    url={question.answerAImage.url}
                                    width={question.answerAImage.width}
                                    height={question.answerAImage.height}
                                />
                            ) : (
                                <AnswerWrapper>{question.answerA}</AnswerWrapper>
                            ),
                            value: Answer.A,
                            disabled: isTimeExpired,
                        },
                        {
                            label: question.answerBImage ? (
                                <TestImage
                                    url={question.answerBImage.url}
                                    width={question.answerBImage.width}
                                    height={question.answerBImage.height}
                                />
                            ) : (
                                <AnswerWrapper>{question.answerB}</AnswerWrapper>
                            ),
                            value: Answer.B,
                            disabled: isTimeExpired,
                        },
                        {
                            label: question.answerCImage ? (
                                <TestImage
                                    url={question.answerCImage.url}
                                    width={question.answerCImage.width}
                                    height={question.answerCImage.height}
                                />
                            ) : (
                                <AnswerWrapper>{question.answerC}</AnswerWrapper>
                            ),
                            value: Answer.C,
                            disabled: isTimeExpired,
                        },
                        {
                            label: question.answerDImage ? (
                                <TestImage
                                    url={question.answerDImage.url}
                                    width={question.answerDImage.width}
                                    height={question.answerDImage.height}
                                />
                            ) : (
                                <AnswerWrapper>{question.answerD}</AnswerWrapper>
                            ),
                            value: Answer.D,
                            disabled: isTimeExpired,
                        },
                    ]}
                />
                <Divider />
                <p>
                    Is something wrong with this question?{' '}
                    <Button
                        variant='text'
                        style={css`
                            padding: 0;
                            font-weight: 500;
                        `}
                        onClick={toggleFeedbackModal}
                        dataTestId='feedback-button'
                    >
                        Give Feedback
                    </Button>
                </p>
                <Divider />
                <FlexBox justifyContent='flex-end'>
                    <Button
                        type='submit'
                        style={css`
                            padding-left: 60px;
                            padding-right: 60px;
                        `}
                        disabled={answer === Answer.NO_ANSWER && !isTimeExpired}
                        dataTestId='next-question'
                    >
                        Next
                    </Button>
                </FlexBox>
            </form>
        </Fragment>
    )
}

export { TestQuestion }
