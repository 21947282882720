/** @jsxImportSource @emotion/react */
import { FunctionComponent, useEffect } from 'react'
import { Control, FieldPath, FieldPathValue, UseFormSetValue } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { OptionType } from 'src/components/inputs/autocomplete-select/Option'
import { ApplicationFormData } from '../../contracts/opportunities-manager/contracts'

interface ControlledAutocompleteSelectInlineEditProps {
    control: Control<ApplicationFormData>
    fieldName: FieldPath<ApplicationFormData>
    setValue: UseFormSetValue<ApplicationFormData>
    initialValue: FieldPathValue<ApplicationFormData, FieldPath<ApplicationFormData>>
    options: Array<OptionType>
    selectedLabelTransformer?: (val: OptionType) => any
    valuesTransformer?: AutocompleteSelectValuesTransformerEnum
}

const ControlledAutocompleteSelectInlineEdit: FunctionComponent<React.PropsWithChildren<ControlledAutocompleteSelectInlineEditProps>> = ({
    initialValue,
    fieldName,
    control,
    setValue,
    options,
    selectedLabelTransformer,
    valuesTransformer,
}) => {
    useEffect(() => {
        setValue(fieldName, initialValue)
    }, [initialValue, setValue, fieldName])

    return (
        <ControlledAutocompleteSelect
            canFilter={false}
            control={control}
            name={fieldName}
            options={options}
            selectedLabelTransformer={selectedLabelTransformer}
            valuesTransformer={valuesTransformer}
        />
    )
}

export { ControlledAutocompleteSelectInlineEdit }
