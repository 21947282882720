/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { AccessLevelContext } from 'src/AccessLevelContext'
import { addSpecialistProfileToSavedProfiles, downloadAnonymizedCV, removeSpecialistProfileFromSavedProfiles } from 'src/api/api'
import docIcon from 'src/assets/icons/doc.svg'
import dotsIcon from 'src/assets/icons/dots.svg'
import downloadIcon from 'src/assets/icons/download.svg'
import favIcon from 'src/assets/icons/fav.svg'
import shareIcon from 'src/assets/icons/share.svg'
import userIcon from 'src/assets/icons/user.svg'
import { Button } from 'src/components/Button'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { ProfileExportButton } from 'src/components/profile-export/ProfileExportButton'
import { ExportType } from 'src/components/profile-export/types'
import { ContactedAt } from 'src/components/profile/ContactedAt'
import { Note } from 'src/contracts/notes/Notes'
import { Profile } from 'src/contracts/profile/profile'
import { useRoutesMatching } from 'src/hooks/useRoutesMatching'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'
import { getSpecialistAvailability } from 'src/pages/profile/api'
import { Icon } from 'src/components/Icon'
import { SideMenuBox } from 'src/components/SideMenuBox'
import { saveAs } from 'file-saver'
import { fileTypeToExtension } from 'src/utils/values'
import { ReduxContext } from 'src/redux/Store'

import { StyledButton, StyledDivider, StyledDropdown, StyledLink } from './styles'
import { InviteToOpportunity } from './InviteToOpportunity'

type SpecialistProfileSideMenuProps = {
    isPublic?: boolean
    isSpecialistAccount: boolean
    hasCompanyAccount?: boolean
    specialistProfile: Nullable<Profile>
    setRefetchProfile?: (val: boolean) => void
    setLoading?: (val: boolean) => void
    shouldSeeNotes: boolean
    onClickExportProfile: (val: ExportType) => void
    companyNotes: Array<Note>
    setContactModal: (val: boolean) => void
    setNotAvailableModal: (val: boolean) => void
    setShareModal: (val: boolean) => void
    scrollToNotes: () => void
}

enum OtherType {
    ExportAnonymized = 'custom-export-anonymized',
    Notes = 'notes',
}

const downloadOptions = [
    {
        title: 'Anonymized',
        key: OtherType.ExportAnonymized,
        dataTestId: OtherType.ExportAnonymized,
    },
]

const isSpecialist = (specialistId: string | null | undefined): specialistId is string => {
    return Boolean(specialistId)
}

const SpecialistProfileSideMenu: FunctionComponent<React.PropsWithChildren<SpecialistProfileSideMenuProps>> = ({
    isPublic,
    isSpecialistAccount,
    hasCompanyAccount,
    specialistProfile,
    setRefetchProfile,
    setLoading,
    shouldSeeNotes,
    onClickExportProfile,
    companyNotes,
    setContactModal,
    setNotAvailableModal,
    setShareModal,
    scrollToNotes,
}) => {
    const [savedProfile, setSavedProfile] = useState<boolean>(specialistProfile?.savedProfile || false)
    const [contactDate, setContactDate] = useState<string>()

    const log = useLogger()
    const { addSuccess, addError } = useNotifications()
    const { matchProfileSummary } = useRoutesMatching()

    const {
        talentMarketplace: { savedProfiles },
    } = useContext(AccessLevelContext)
    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)

    const specialistId = specialistProfile?.specialistId
    // Check below can't be used inside 'showInviteToAnOpportunity' because typescript can't deduce that
    // when `showInviteToAnOpportunity` is true it means that 'isSpecialistDefined' is also true.
    const isSpecialistDefined = isSpecialist(specialistId)
    const showInviteToAnOpportunity = !isPublic && featureFlags.opportunitySpecialistInvite

    const openContactModal = useCallback(() => {
        if (specialistProfile?.specialistId) {
            getSpecialistAvailability(specialistProfile.specialistId)
                .then(response => {
                    if (response.available) {
                        setContactModal(true)
                    } else {
                        if (setRefetchProfile) {
                            setRefetchProfile(true)
                        }
                        setNotAvailableModal(true)
                    }
                })
                .catch(log)
        }
    }, [log, setContactModal, setNotAvailableModal, setRefetchProfile, specialistProfile])

    const toggleSavedProfile = useCallback(() => {
        if (specialistProfile?.specialistId) {
            if (setLoading) {
                setLoading(true)
            }

            if (savedProfile) {
                removeSpecialistProfileFromSavedProfiles(specialistProfile?.specialistId).then(() => {
                    setSavedProfile(false)
                    addSuccess('Specialist removed from Saved Profiles successfully.')

                    if (setLoading) {
                        setLoading(false)
                    }
                })
            } else {
                addSpecialistProfileToSavedProfiles(specialistProfile?.specialistId).then(() => {
                    setSavedProfile(true)
                    addSuccess('Specialist added to Saved Profiles successfully.')

                    if (setLoading) {
                        setLoading(false)
                    }
                })
            }
        }
    }, [addSuccess, savedProfile, setLoading, specialistProfile])

    const shareProfile = useCallback(() => {
        setShareModal(true)
    }, [setShareModal])

    useEffect(() => {
        if (specialistProfile?.contactedAt) {
            setContactDate(convertTimestampToDateString(specialistProfile?.contactedAt))
        }
    }, [specialistProfile])

    const handleCVDownload = useCallback(() => {
        if (specialistProfile?.specialistId) {
            downloadAnonymizedCV(specialistProfile?.specialistId)
                .then(data => {
                    saveAs(
                        data,
                        `${specialistProfile.seniority ? `${specialistProfile.seniority}_` : ''}${specialistProfile.role}${
                            specialistProfile.nickname ? `_${specialistProfile.nickname}` : ''
                        }${fileTypeToExtension(data.type)}`,
                    )
                })
                .catch(err => {
                    addError()
                    log(err)
                })
        }
    }, [addError, log, specialistProfile?.nickname, specialistProfile?.role, specialistProfile?.seniority, specialistProfile?.specialistId])

    const handleOther = useCallback(
        (key: OtherType) => {
            switch (key) {
                case OtherType.ExportAnonymized:
                    onClickExportProfile(ExportType.CustomExportAnonymized)
                    break
                case OtherType.Notes:
                    scrollToNotes()
                    break
            }
        },
        [onClickExportProfile, scrollToNotes],
    )

    return (
        <SideMenuBox>
            {!isPublic && !isSpecialistAccount && (
                <StyledButton
                    disabled={!hasCompanyAccount || !specialistProfile?.isAvailable}
                    onClick={openContactModal}
                    size='big'
                    dataTestId='specialist-profile-book-now'
                >
                    Book Now
                </StyledButton>
            )}
            {!isPublic && !isSpecialistAccount && savedProfiles && (
                <StyledButton onClick={toggleSavedProfile} hasTopMargin icon={favIcon} variant={savedProfile ? 'violet' : 'violetBordered'}>
                    {savedProfile ? 'Profile Saved' : 'Save profile'}
                </StyledButton>
            )}
            {!isPublic && !isSpecialistAccount && contactDate && (
                <Fragment>
                    <ContactedAt contactDate={contactDate} />
                    <StyledDivider />
                </Fragment>
            )}
            {specialistProfile?.isMySpecialist && (
                <Fragment>
                    <StyledLink
                        to={`/my-specialists/specialist/${specialistProfile?.specialistId}`}
                        hasTopMargin={!(!isPublic && !isSpecialistAccount && contactDate)}
                    >
                        <Button variant='link' icon={userIcon}>
                            View in My Specialists
                        </Button>
                    </StyledLink>
                    <StyledDivider />
                </Fragment>
            )}
            {matchProfileSummary && (
                <ProfileExportButton handleClick={onClickExportProfile}>
                    <StyledButton icon={downloadIcon} variant='link' dataTestId='export-profile-button'>
                        Export profile
                    </StyledButton>
                </ProfileExportButton>
            )}
            {!isPublic && !isSpecialistAccount && specialistProfile?.hasAnonymizedCv && (
                <Fragment>
                    <StyledButton
                        variant='link'
                        icon={downloadIcon}
                        hasTopMargin={!(specialistProfile?.isMySpecialist || contactDate)}
                        dataTestId='view-cv-button'
                        onClick={handleCVDownload}
                    >
                        Download CV
                    </StyledButton>
                    <StyledDivider />
                </Fragment>
            )}
            {!isPublic && !isSpecialistAccount && (
                <StyledButton
                    variant='link'
                    icon={shareIcon}
                    onClick={shareProfile}
                    disabled={!specialistProfile?.isAvailable}
                    dataTestId='share-profile'
                    hasTopMargin={!(specialistProfile?.isMySpecialist || contactDate || specialistProfile?.hasAnonymizedCv)}
                >
                    Share Specialist
                </StyledButton>
            )}
            {!isPublic && !isSpecialistAccount && (
                <Fragment>
                    {!isPublic && <StyledDivider />}
                    <StyledDropdown
                        options={[
                            {
                                title: (
                                    <StyledButton
                                        variant='link'
                                        icon={downloadIcon}
                                        padding='7px 0'
                                        width='auto'
                                        iconStyle={css`
                                            margin-right: 18px;
                                        `}
                                        dataTestId='export-profile-button'
                                    >
                                        Download Profile
                                    </StyledButton>
                                ),
                                children: downloadOptions,
                            },
                            ...(shouldSeeNotes
                                ? [
                                      {
                                          title: (
                                              <StyledButton
                                                  variant='link'
                                                  icon={docIcon}
                                                  padding='0 0 5px'
                                                  width='auto'
                                                  iconStyle={css`
                                                      margin-right: 18px;
                                                  `}
                                              >
                                                  {`Notes (${companyNotes.length})`}
                                              </StyledButton>
                                          ),
                                          key: OtherType.Notes,
                                          dataTestId: OtherType.Notes,
                                      },
                                  ]
                                : []),
                        ]}
                        handleClick={handleOther}
                    >
                        <Button
                            icon={dotsIcon}
                            variant='link'
                            dataTestId='other-button'
                            iconStyle={css`
                                height: 18px;
                            `}
                        >
                            Other <Icon name='arrow-down' />
                        </Button>
                    </StyledDropdown>
                </Fragment>
            )}
            {isPublic && !isSpecialistAccount && (
                <Fragment>
                    <StyledDropdown options={downloadOptions} handleClick={onClickExportProfile}>
                        <Button variant='link' icon={downloadIcon} dataTestId='export-profile-button'>
                            Download Profile
                        </Button>
                    </StyledDropdown>
                    {shouldSeeNotes && (
                        <Fragment>
                            <StyledDivider />
                            <StyledButton variant='link' icon={docIcon} onClick={scrollToNotes}>
                                {`Notes (${companyNotes.length})`}
                            </StyledButton>
                        </Fragment>
                    )}
                </Fragment>
            )}

            {showInviteToAnOpportunity && isSpecialistDefined && <InviteToOpportunity specialistId={specialistId} />}
        </SideMenuBox>
    )
}

export { SpecialistProfileSideMenu }
