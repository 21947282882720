import { useCallback, useContext, useEffect, useState } from 'react'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'

const useInstantOnboardingGuard = () => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const [specialistId, setSpecialistId] = useState<Nullable<string>>(null)
    const [specialistName, setSpecialistName] = useState<Nullable<string>>(null)

    useEffect(() => {
        if (decodedAccessToken?.specialistId) {
            setSpecialistId(decodedAccessToken.specialistId)
            setSpecialistName(decodedAccessToken.specialistName)
        }
    }, [decodedAccessToken])

    const goToApp = useCallback(() => {
        if (decodedAccessToken && decodedAccessToken.specialistId) {
            history.push('/specialist-dashboard')
        } else {
            history.push('/')
        }
    }, [decodedAccessToken])

    return { goToApp, specialistId, specialistName }
}

export { useInstantOnboardingGuard }
