/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'

const Span = styled.span<{ yes: boolean }>`
    color: ${({ yes, theme }) => !yes && theme.colors.red_4};
`

const YesNoCell: FunctionComponent<{ yes: boolean }> = ({ yes }) => <Span yes={yes}>{yes ? 'Yes' : 'No'}</Span>

export { YesNoCell }
