import { Nullable } from '../../types'

export enum ExperienceStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    DELETED = 'DELETED',
    NOT_VALID = 'NOT_VALID',
}

export interface SpecialistProject {
    id: string
    role: string
    from: Nullable<number>
    to: Nullable<number>
    currentlyWorkingThere: boolean
    project: Nullable<string>
    industry: Nullable<string>
    stack: Array<string>
    description: Nullable<string>
    country: Nullable<string>
    city: Nullable<string>
    company: string
    status?: ExperienceStatus
}

export interface SpecialistProjectForm {
    role: string
    from: Nullable<number>
    to: Nullable<number>
    currentlyWorkingThere: boolean
    project: string
    industry: string
    stack: Array<string>
    description: string
    country: string
    city: string
    company: string
}
