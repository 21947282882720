/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { FunctionComponent } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SearchSpecialist } from '../../contracts/search/searchSpecialist'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'
import { toYears } from '../../utils/searchFilters'
import { blackToViolet3Filter, Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { calcAvailability } from '../profile/utils'
import { TalentBoxBottomInfo } from './TalentBoxBottomInfo'
import { TalentBoxRolesInfo } from './TalentBoxRolesInfo'

export const TalentBoxLabelBlack = styled.label`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: ${COLOR_PALETTE.gray_6};
`

export const TalentBoxLabelGrey = styled.label`
    font-size: 12px;
    line-height: 22px;
    font-weight: normal;
    width: 100%;
    display: block;
    padding: 0 0 5px;
    color: ${COLOR_PALETTE.gray_4};
`

const TalentBoxRow = styled.div`
    display: flex;
`

const TalentResultBoxContainer = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(1, 1fr);
    ${mqMin[1]} {
        grid-template-columns: repeat(2, 1fr);
    }
    ${mqMin[2]} {
        grid-template-columns: repeat(3, 1fr);
    }
`

const formatExperience = (months: number) => {
    const years = toYears(months)
    return years < 1 ? '<1 year' : `${years}+ ${years >= 2 ? 'years' : 'year'}`
}

export interface TalentResultBoxProps {
    talent?: SearchSpecialist
    searchWords: Array<string>
    onClick?: () => void
    onToggleSavedProfile: (savedProfile: boolean, specialistId: string) => Promise<any>
    onlySavedProfiles: boolean
}

const TalentResultBox: FunctionComponent<React.PropsWithChildren<TalentResultBoxProps>> = ({
    talent,
    searchWords,
    onClick,
    onToggleSavedProfile,
    onlySavedProfiles,
}) => {
    const location = useLocation()
    const theme = useTheme()

    return talent ? (
        <Link
            to={`/profile/${talent.specialistId}?prevPath=${encodeURIComponent(
                `${location.pathname}${encodeURIComponent(location.search)}${location.hash}`,
            )}`}
            css={css`
                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
                label:hover,
                span:hover,
                div:hover {
                    cursor: pointer;
                }
            `}
            data-testid='talent-result-box'
        >
            <div
                onClick={onClick}
                css={css`
                    background-color: ${COLOR_PALETTE.white};
                    border-radius: 2px;
                    width: 100%;
                    box-shadow: 0 4px 12px ${COLOR_PALETTE.box_shadow_color};
                    transition: all linear 0.3s;
                    height: 100%;
                    position: relative;

                    &:hover {
                        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
                        transition: all linear 0.3s;

                        .contact-us-button {
                            opacity: 1;
                            transition: all linear 0.3s;
                        }
                    }
                `}
            >
                <TalentBoxRolesInfo
                    talent={talent}
                    searchWords={searchWords}
                    onToggleSavedProfile={onToggleSavedProfile}
                    onlySavedProfiles={onlySavedProfiles}
                />
                <TalentBoxRow
                    css={css`
                        border-bottom: 1px solid ${theme.colors.gray_2};
                        padding-bottom: 12px;
                    `}
                >
                    <Tooltip title='Experience'>
                        <FlexBox
                            justifyContent='center'
                            alignItems='center'
                            css={css`
                                margin-top: 20px;
                                padding: 0 16px 5px;
                            `}
                        >
                            <Icon
                                style={css`
                                    margin-right: 8px;
                                    filter: ${blackToViolet3Filter};
                                `}
                                name='bag'
                            />
                            <TalentBoxLabelBlack>{formatExperience(talent?.experienceInMonths)}</TalentBoxLabelBlack>
                        </FlexBox>
                    </Tooltip>
                    <Tooltip title='Hourly Rate'>
                        <FlexBox
                            justifyContent='center'
                            alignItems='center'
                            css={css`
                                margin-top: 20px;
                                padding: 0 16px 5px;
                            `}
                        >
                            <Icon
                                style={css`
                                    margin-right: 8px;
                                    filter: ${blackToViolet3Filter};
                                `}
                                name='dollar-circle'
                            />
                            <TalentBoxLabelBlack>
                                {talent.rate && talent.currency ? `${talent.rate} ${talent.currency}` : '-'}
                            </TalentBoxLabelBlack>
                        </FlexBox>
                    </Tooltip>
                    <Tooltip title='Availability'>
                        <FlexBox
                            justifyContent='center'
                            alignItems='center'
                            css={css`
                                margin-top: 20px;
                                padding: 0 16px 5px;
                            `}
                        >
                            <Icon
                                style={css`
                                    margin-right: 8px;
                                    filter: ${blackToViolet3Filter};
                                `}
                                name='calendar-days'
                            />
                            <TalentBoxLabelBlack>{calcAvailability(talent.availableFrom)}</TalentBoxLabelBlack>
                        </FlexBox>
                    </Tooltip>
                </TalentBoxRow>
                <TalentBoxBottomInfo talent={talent} searchWords={searchWords} />
            </div>
        </Link>
    ) : null
}

export { TalentResultBox, TalentResultBoxContainer }
