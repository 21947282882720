/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import nl2br from 'nl2br'
import { Fragment, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { FullProfileData } from '../../../../api/api'
import { PersonalStrengthsChart } from '../../../../components/personal-strengths-chart/PersonalStrengthsChart'
import { VerifiedSkill } from '../../../../contracts/profile/skill'
import { FullExportSkills } from '../../../../contracts/skills'
import { WhiteLabel } from '../../../../contracts/whiteLabel'
import { COLOR_PALETTE } from '../../../../theme/colors'
import { Nullable } from '../../../../types'
import { randomString } from '../../../../utils/strings'
import { QualificationsEntry } from '../../../my-specialists/specialist-profile/EducationQualificationsSection'
import { DotSeparatedValues } from '../DotSeparatedValues'
import { containsPersonalStrengths } from '../helpers'
import { Label } from '../Label'
import { LanguageBox } from '../LanguageBox'
import { PrintableLogo } from '../PrintableLogo'
import { ProfileExportProjectItem } from '../ProfileExportProjectItem'

const GeneralLabel = styled.div`
    color: ${COLOR_PALETTE.violet_5};
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
    font-weight: 500px;
    page-break-inside: avoid;
`

const SkillsGroupLabel = styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 11px;
`

const SectionLabel = styled.div`
    background-color: ${COLOR_PALETTE.violet_1};
    border-radius: 2px;
    font-size: 13px;
    font-weight: 500px;
    padding: 6px;
`

const Row = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
`

const Column = styled.div`
    width: 150px;
`

const GroupColumn = styled.div`
    width: 100px;
`

const GreyLabel = styled.div`
    color: ${COLOR_PALETTE.gray_4};
    content: ${randomString()};
    font-size: 10px;
`

const BreakPage = styled.div`
    page-break-after: always;
`

const Paragraph: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => (
    <div
        css={css`
            margin-bottom: 10px;
            content: ${children as string};
            page-break-inside: avoid;
        `}
    >
        {children}
    </div>
)

type SkillsGroupRowProps = {
    data: Array<VerifiedSkill>
    label: string
}

const SkillsGroupRow: FunctionComponent<React.PropsWithChildren<SkillsGroupRowProps>> = ({ data, label }) => {
    return (
        <Row
            data-avoid-break
            css={css`
                content: ${data.map(d => d.name)};
                margin-bottom: 24px;
                padding-bottom: 12px;
                border-bottom: 1px solid ${COLOR_PALETTE.gray_1};
                align-items: flex-start;
            `}
        >
            <GroupColumn>
                <GreyLabel>{label}</GreyLabel>
            </GroupColumn>
            <DotSeparatedValues values={data} />
        </Row>
    )
}

type SkillsGroupType = {
    skills: FullExportSkills
}

const SkillsGroup: FunctionComponent<React.PropsWithChildren<SkillsGroupType>> = ({ skills }) => {
    return (
        <div>
            {skills.mergedCategories.map((category, i) => (
                <div data-avoid-break key={i}>
                    <SkillsGroupLabel>
                        <SectionLabel data-avoid-break>{category.name}</SectionLabel>
                    </SkillsGroupLabel>

                    {category.expert?.length !== 0 && <SkillsGroupRow data={category.expert} label='Expert' />}
                    {category.good?.length !== 0 && <SkillsGroupRow data={category.good} label='Good' />}
                    {category.basic?.length !== 0 && <SkillsGroupRow data={category.basic} label='Basic' />}
                    {category.notRated?.length !== 0 && <SkillsGroupRow data={category.notRated} label='Not rated' />}
                </div>
            ))}
        </div>
    )
}

type FullExportProps = {
    profileData: FullProfileData
    whiteLabel: WhiteLabel | undefined
    showEducationDuration?: boolean
    skills: Nullable<FullExportSkills>
}

const FullExport: FunctionComponent<React.PropsWithChildren<FullExportProps>> = ({
    profileData,
    whiteLabel,
    showEducationDuration,
    skills,
}) => {
    return (
        <section
            css={css`
                width: 210mm;

                * {
                    font-size: 10px;
                }
            `}
        >
            <div
                css={css`
                    width: 100%;
                    min-height: 115px;
                    background-color: ${whiteLabel ? COLOR_PALETTE.white : COLOR_PALETTE.violet_1};
                    padding: 20px 35px;
                    color: ${COLOR_PALETTE.gray_5};
                `}
            >
                <PrintableLogo whiteLabel={whiteLabel} />

                <div
                    css={css`
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 36px;
                        margin-bottom: 10px;
                        white-space: nowrap;
                    `}
                >
                    {profileData.specialist?.firstName} {profileData.specialist?.lastName}
                </div>

                <div
                    css={css`
                        background-color: ${COLOR_PALETTE.violet_3};
                        border-radius: 2px;
                        margin-bottom: 15px;
                        display: inline-block;
                        padding: 5px 10px;
                        font-weight: 500;
                    `}
                >
                    {profileData.generalInformation?.seniority &&
                        profileData.generalInformation.seniority !== 'Regular' &&
                        profileData.generalInformation.seniority + ' '}
                    {profileData.generalInformation?.role}
                </div>

                <div
                    dangerouslySetInnerHTML={{
                        __html: nl2br(profileData.generalInformation?.profileSummary || ''),
                    }}
                />

                {whiteLabel && (
                    <div
                        css={css`
                            margin-top: 20px;
                            margin-bottom: -20px;
                            height: 1px;
                            background-color: ${whiteLabel ? COLOR_PALETTE.gray_1 : 'transparent'};
                        `}
                    />
                )}
            </div>

            <section
                css={css`
                    padding: 20px 40px 40px;
                `}
            >
                {profileData.summary?.experience && (
                    <Row
                        css={css`
                            content: ${profileData.summary?.experience};
                        `}
                    >
                        <Column>
                            <Label
                                styles={css`
                                    padding: 2px 10px;
                                `}
                            >
                                Experience
                            </Label>
                        </Column>
                        <div>{profileData.summary?.experience}</div>
                    </Row>
                )}

                {profileData.verifiedTopSkills?.length !== 0 && (
                    <Row
                        css={css`
                            content: ${(profileData.verifiedTopSkills || []).join(', ')};
                        `}
                    >
                        <Column>
                            <Label
                                styles={css`
                                    padding: 2px 10px;
                                `}
                            >
                                Top Skills
                            </Label>
                        </Column>
                        <DotSeparatedValues values={profileData.verifiedTopSkills || []} />
                    </Row>
                )}

                {profileData.generalInformation?.potentialRoles?.length !== 0 && (
                    <Row
                        css={css`
                            content: ${(profileData.generalInformation?.potentialRoles || []).join(', ')};
                        `}
                    >
                        <Column>
                            <Label
                                styles={css`
                                    padding: 2px 10px;
                                `}
                            >
                                Potential Roles
                            </Label>
                        </Column>
                        <div>{(profileData.generalInformation?.potentialRoles || []).join(', ')}</div>
                    </Row>
                )}

                {profileData.generalInformation?.industries?.length !== 0 && (
                    <Row
                        css={css`
                            content: ${(profileData.generalInformation?.industries || []).join(', ')};
                        `}
                    >
                        <Column>
                            <Label
                                styles={css`
                                    padding: 2px 10px;
                                `}
                            >
                                Industry Experience
                            </Label>
                        </Column>
                        <div>{(profileData.generalInformation?.industries || []).join(', ')}</div>
                    </Row>
                )}

                {(profileData.generalInformation?.country || profileData.generalInformation?.city) && (
                    <Row
                        css={css`
                            content: ${profileData.generalInformation?.country + '-' + profileData.generalInformation?.city};
                        `}
                    >
                        <Column>
                            <Label
                                styles={css`
                                    padding: 2px 10px;
                                `}
                            >
                                Location
                            </Label>
                        </Column>
                        <div>
                            {profileData.generalInformation?.remote && 'Remote, '}Client Premises: {profileData.generalInformation?.country}{' '}
                            ({profileData.generalInformation?.city}), Depends on the Project
                        </div>
                    </Row>
                )}

                {skills && skills?.mergedCategories?.length > 0 && (
                    <Fragment>
                        <GeneralLabel>Technical Skills</GeneralLabel>
                        <SkillsGroup skills={skills} />
                    </Fragment>
                )}

                {profileData?.projects?.length !== 0 && (
                    <Fragment>
                        <GeneralLabel>Experience</GeneralLabel>
                        {profileData.projects.map(project => {
                            return <ProfileExportProjectItem key={project.id} project={project} />
                        })}
                    </Fragment>
                )}

                {profileData?.education?.length !== 0 && (
                    <div data-avoid-break>
                        <GeneralLabel>Education</GeneralLabel>
                        {profileData.education.map((education, index) => (
                            <QualificationsEntry
                                key={index}
                                education={education}
                                isEditable={false}
                                isPrintable={true}
                                showEducationDuration={showEducationDuration}
                            />
                        ))}
                    </div>
                )}

                {profileData?.languages?.length !== 0 && (
                    <div data-avoid-break>
                        <GeneralLabel>Languages</GeneralLabel>
                        {profileData.languages.map((language, index) => (
                            <LanguageBox key={index} language={language} />
                        ))}
                    </div>
                )}

                {profileData.personalStrengths && containsPersonalStrengths(profileData.personalStrengths?.groups) && (
                    <Fragment>
                        <BreakPage />
                        <GeneralLabel
                            css={css`
                                margin-top: 0;
                            `}
                        >
                            Experience
                        </GeneralLabel>
                        <Paragraph>
                            The results you see in this section are from verified psychometric tools. Each individual result indicates where
                            the Specialist scored on a scale of a given trait compared to the rest of the cohort of individuals have have
                            taken this test. These results are not editable.
                        </Paragraph>

                        <Paragraph>
                            Note: Since the results are relative to the entire cohort, your results may change over time as more IT
                            Specialists complete the Strength Discovery stage.{' '}
                        </Paragraph>

                        <Paragraph>
                            We show the entire spectrum of a given trait because the real score lies within this range (with an 85%
                            confidence interval). Every test has a certain margine of error, so we reflect this in the results.
                        </Paragraph>

                        <SectionLabel>Big Five Personality Traits</SectionLabel>
                        <section
                            css={css`
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                            `}
                        >
                            <div
                                css={css`
                                    margin-top: 30px;
                                    width: 70%;
                                    text-align: center;
                                `}
                            >
                                We use a standardized set of measures developed by scientists to measure personality traits according to the
                                Big Five Model, that asserts that each personality trait is a spectrum.
                            </div>

                            <div
                                css={css`
                                    margin-top: 10px;
                                `}
                            >
                                <PersonalStrengthsChart groups={profileData.personalStrengths?.groups} isPrintable={true} />
                            </div>
                        </section>
                    </Fragment>
                )}

                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        width: 170px;
                        align-items: center;
                        margin: 200px auto auto auto;
                    `}
                >
                    <div
                        css={css`
                            font-size: 11px;
                            margin-top: -10px;
                            white-space: nowrap;
                        `}
                    >
                        Powered by
                    </div>
                    <div>
                        <Link to={profileData.link}>
                            <PrintableLogo />
                        </Link>
                    </div>
                </div>
            </section>
        </section>
    )
}

export { FullExport }
