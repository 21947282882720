import { axios } from '../../api/axios'

const companiesEndpoint = `/companies`

const confirmCompany = (
    prospectId: string,
): Promise<{
    companyId: string
    contextId: string
}> => {
    return axios.post(`${companiesEndpoint}/${prospectId}:confirmCompany`)
}

export { confirmCompany }
