/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { Button } from '../../components/Button'
import { Dropzone } from '../../components/inputs/Dropzone'
import { OverlayContentLoader } from '../../components/layout/Loader'

type CandidatesDropzoneProps = {
    handleApplyWithCv: (files: Array<File> | File) => void
    isSendingApplication: boolean
}

const CandidatesDropzone: FunctionComponent<React.PropsWithChildren<CandidatesDropzoneProps>> = ({
    handleApplyWithCv,
    isSendingApplication,
}) => {
    const theme = useTheme()

    return (
        <Dropzone
            dataTestId='candidates-dropzone'
            onChange={handleApplyWithCv}
            accept={{ 'application/pdf': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] }}
            styles={css`
                background-color: ${theme.colors.violet_1};
                border-color: ${theme.colors.violet_3};
                :hover {
                    border-color: ${theme.colors.violet_3};
                }
            `}
            dragActiveStyles={css`
                background-color: ${theme.colors.violet_2};
            `}
            dropzoneContent={
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        padding: 36px 0;
                        height: 135px;
                    `}
                >
                    {isSendingApplication ? (
                        <OverlayContentLoader />
                    ) : (
                        <Fragment>
                            <div
                                css={css`
                                    margin-bottom: 12px;
                                `}
                            >
                                Drag and Drop CV(s) here
                            </div>
                            <Button variant='secondary' size='small'>
                                Browse Files
                            </Button>
                        </Fragment>
                    )}
                </div>
            }
        />
    )
}

export { CandidatesDropzone }
