/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Education as EducationModel } from '../contracts/profile/education'
import { COLOR_PALETTE, mqMax } from '../GlobalStyle'
import { formatUnixDate } from '../utils/dates'
import { capitalizeFirstLetter } from '../utils/strings'

const EducationContainer = styled.div`
    background-color: ${COLOR_PALETTE.white};
    padding: 26px 36px 24px;
    margin-top: 24px;
    border: 1px solid ${COLOR_PALETTE.gray_2};
`

const EducationHeader = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
    padding-bottom: 20px;
    margin-bottom: 2px;
`

const EducationDetailRow = styled.div`
    display: flex;
    margin: 14px 0;

    &:last-of-type {
        margin-bottom: 0;
    }
`

const EducationTitle = styled.small`
    flex: 2;
    color: ${COLOR_PALETTE.gray_3};
    font-size: 14px;
`

const EducationValue = styled.p`
    margin: 0;
    flex: 5;
`

type EducationDurationProps = { from: number; to?: number }

const EducationDuration: FunctionComponent<React.PropsWithChildren<EducationDurationProps>> = ({ from, to }) => (
    <div
        css={css`
            margin-top: 0;
            font-size: 14px;
        `}
    >
        <div
            css={css`
                display: flex;
                align-items: center;
            `}
        >
            <div
                css={css`
                    margin-right: 10px;
                    color: ${COLOR_PALETTE.gray_3};
                    flex: 0 0 15%;

                    ${mqMax[0]} {
                        display: none;
                    }
                `}
            >
                Duration:
            </div>
            <div
                css={css`
                    background-color: ${COLOR_PALETTE.gray_2};
                    border-radius: 55px;
                    padding: 2px 12px;
                `}
            >
                {`${formatUnixDate(from)} - ${to ? formatUnixDate(to) : 'now'}`}
            </div>
        </div>
    </div>
)

type EducationProps = { education: EducationModel }

const Education: FunctionComponent<React.PropsWithChildren<EducationProps>> = ({ education }) => {
    return (
        <Fragment>
            <EducationContainer>
                {(education.type === 'EDUCATION' ||
                    education.type === 'BACHELOR_STUDIES' ||
                    education.type === 'MASTER_STUDIES' ||
                    education.type === 'OTHER_STUDIES' ||
                    education.type === 'PHD_STUDIES') && (
                    <Fragment>
                        <EducationHeader>
                            Studies
                            {education.from && <EducationDuration from={education.from} to={education.to} />}
                        </EducationHeader>

                        {education.specialization && (
                            <EducationDetailRow>
                                <EducationTitle>Course:</EducationTitle>
                                <EducationValue>{education.specialization}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.faculty && (
                            <EducationDetailRow>
                                <EducationTitle>Faculty:</EducationTitle>
                                <EducationValue>{education.faculty}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.school && (
                            <EducationDetailRow>
                                <EducationTitle>University:</EducationTitle>
                                <EducationValue>{education.school}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}

                {(education.type === 'COURSE' ||
                    education.type === 'CERTIFICATE' ||
                    education.type === 'CERTIFICATE_COURSE' ||
                    education.type === 'LANGUAGE_COURSE') && (
                    <Fragment>
                        <EducationHeader>
                            {capitalizeFirstLetter(education.type.replace(/_/g, ' ').toLowerCase())}
                            {education.from && <EducationDuration from={education.from} to={education.to} />}
                        </EducationHeader>

                        {education.name && (
                            <EducationDetailRow>
                                <EducationTitle>Subject:</EducationTitle>
                                <EducationValue>{education.name}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.school && (
                            <EducationDetailRow>
                                <EducationTitle>Name:</EducationTitle>
                                <EducationValue>{education.school}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}
            </EducationContainer>
        </Fragment>
    )
}

export { Education }
