/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useLifecycles } from 'react-use'
import { getSpecialistPsychometricSkills } from '../../../api/api'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { PersonalStrengthsChart } from '../../../components/personal-strengths-chart/PersonalStrengthsChart'
import { PersonalStrengths } from '../../../contracts/personalStrengths'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

type StrengthTabProps = {
    specialistId: string
    divider?: boolean
}

const StrengthTab: FunctionComponent<React.PropsWithChildren<StrengthTabProps>> = ({ specialistId, divider = true }) => {
    const [loading, setLoading] = useState<boolean>(true)
    const [personalStrengths, setPersonalStrengths] = useState<Nullable<PersonalStrengths>>(null)
    const [isMounted, setIsMounted] = useState(false)

    const log = useLogger('error')

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const fetchSkills = useCallback((): void => {
        setLoading(true)

        getSpecialistPsychometricSkills(specialistId)
            .then(data => {
                if (isMounted) {
                    setPersonalStrengths(data)
                }
            })
            .catch(log)
            .finally(() => {
                setLoading(false)
            })
    }, [specialistId, log, isMounted])

    useEffect(() => {
        fetchSkills()
    }, [fetchSkills])

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Personal Strengths' divider={divider}></SectionHeaderWithActions>

            <section
                css={css`
                    position: relative;
                `}
            >
                {loading ? (
                    <OverlayContentLoader />
                ) : (
                    <Fragment>
                        {personalStrengths ? (
                            <Fragment>
                                <PersonalStrengthsChart {...personalStrengths} />
                            </Fragment>
                        ) : (
                            <SectionDataNotFound description='The results of the Strength Discovery Stage will appear here once the Specialist has submitted them.' />
                        )}
                    </Fragment>
                )}
            </section>
        </SectionWrapper>
    )
}

export { StrengthTab }
