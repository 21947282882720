/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Dispatch, Fragment, FunctionComponent, SetStateAction, useRef } from 'react'
import docIcon from 'src/assets/icons/doc.svg'
import userIcon from 'src/assets/icons/user.svg'
import { Button } from 'src/components/Button'
import { Specialist } from 'src/contracts/specialist/specialist'
import { SpecialistGeneralInformation } from 'src/contracts/specialist/specialistGeneralInformation'
import { mqMax } from 'src/GlobalStyle'
import { KeyInfo } from './contracts'
import { Overview } from './sections/Overview'
import { PersonalStrengthTab } from './sections/PersonalStrenghtTab'
import { RoleAndSeniority } from './sections/RoleAndSeniority'
import { TechnicalSkills } from './sections/TechnicalSkills'
import { EducationLanguagesSection } from './specialist-profile/EducationLanguagesSection'
import { EducationQualificationsSection } from './specialist-profile/EducationQualificationsSection'
import { Projects } from './specialist-profile/Projects'
import { Nullable } from 'src/types'

export const BoxTitle = styled.div`
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
`

type SpecialistDetailsProps = {
    specialist: Specialist
    specialistId: string
    handleFilesClick: () => void
    handlePersonalInformationClick: () => void
    forceFocus: string
    setForceFocus: Dispatch<SetStateAction<string>>
    generalInformation: Nullable<SpecialistGeneralInformation>
    fetchGeneralInformation: () => void
}

const SpecialistDetails: FunctionComponent<React.PropsWithChildren<SpecialistDetailsProps>> = ({
    specialist,
    specialistId,
    handleFilesClick,
    handlePersonalInformationClick,
    forceFocus,
    setForceFocus,
    generalInformation,
    fetchGeneralInformation,
}) => {
    const projectsContainerRef = useRef<HTMLDivElement>(null)
    const educationContainerRef = useRef<HTMLDivElement>(null)

    return (
        <Fragment>
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <h4>
                    {specialist.firstName} {specialist.lastName}
                </h4>
                <div>
                    <Button variant='beigeBordered' icon={docIcon} onClick={handleFilesClick}>
                        Files
                    </Button>
                    <Button
                        variant='beigeBordered'
                        icon={userIcon}
                        style={css`
                            margin-left: 16px;
                        `}
                        onClick={handlePersonalInformationClick}
                    >
                        Personal Information
                    </Button>
                </div>
            </div>
            {generalInformation && (
                <RoleAndSeniority
                    generalInformation={generalInformation}
                    specialistId={specialistId}
                    fetchGeneralInformation={fetchGeneralInformation}
                />
            )}
            {generalInformation && (
                <Overview
                    focusLocation={forceFocus === KeyInfo.LOCATION}
                    generalInformation={generalInformation}
                    specialistId={specialistId}
                    fetchGeneralInformation={fetchGeneralInformation}
                    setForceFocus={setForceFocus}
                />
            )}
            <div ref={projectsContainerRef}>
                <Projects
                    specialistId={specialistId}
                    focus={forceFocus === KeyInfo.EXPERIENCE}
                    setForceFocus={setForceFocus}
                    containerRef={projectsContainerRef}
                />
            </div>
            <div ref={educationContainerRef}>
                <EducationQualificationsSection
                    specialistId={specialistId}
                    focus={forceFocus === KeyInfo.EDUCATION}
                    setForceFocus={setForceFocus}
                    containerRef={educationContainerRef}
                />
            </div>
            <EducationLanguagesSection specialistId={specialistId} />
            <TechnicalSkills specialistId={specialistId} />
            <PersonalStrengthTab specialistId={specialistId} />
        </Fragment>
    )
}

export { SpecialistDetails }
