/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { BackLink } from '../../../../components/BackLink'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { ControlledAutocompleteSelect } from '../../../../components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from '../../../../components/forms/ControlledInput'
import { DashboardLayout } from '../../../../components/layout/dashboard/DashboardLayout'
import { FullPageWrapper } from '../../../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { useMySpecialistsMenuItems } from '../../../../hooks/useMySpecialistsMenuItems'
import { ReduxContext } from '../../../../redux/Store'
import { useLogger } from '../../../../utils/useLogger'
import { yup } from '../../../../validation/yup'
import { getFeedbackTeamsProposalMembers, saveFeedbackTeams } from '../api'
import { Specialist } from '../contracts'

const BACK_URL = '/my-specialists/continuous-feedback'

const Row = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`

const Label = styled.div`
    margin-bottom: 10px;
    color: ${props => props.theme.colors.gray_4};
`

const SectionLabel = styled.div`
    font-weight: 500;
    font-size: 14px;
`

const FormBox = styled.div`
    background-color: ${props => props.theme.colors.violet_1};
    margin-bottom: 8px;
    padding: 24px;
`

const FromBoxLabel = styled.div`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 4px;
`

const schema = yup.object().shape({
    name: yup.string().required(),
    specialistIds: yup.array().required().min(2, 'Select at least 2 specialists'),
})

const AddTeam: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const {
        control,
        getValues,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
        defaultValues: {
            name: '',
            specialistIds: [],
        },
    })

    const [specialists, setSpecialists] = useState<Array<any>>([])

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { menuItems, applicationName } = useMySpecialistsMenuItems()
    const log = useLogger('error')
    const navigate = useNavigate()
    const { addError } = useNotifications()

    const fetchData = useCallback(() => {
        layoutToggleLoader(true)
        getFeedbackTeamsProposalMembers()
            .then(members => setSpecialists(members))
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [layoutToggleLoader, log, addError])

    useEffectOnce(fetchData)

    const label = useCallback((opt: Specialist) => `${opt.firstName} ${opt.lastName} (${opt.role})`, [])

    const handleSubmit = useCallback(() => {
        const values = getValues()
        const request = { ...values, specialistIds: values.specialistIds.map((s: Specialist) => s.specialistId) }

        layoutToggleLoader(true)
        saveFeedbackTeams(request)
            .then(() => {
                navigate(BACK_URL)
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [getValues, log, addError, navigate, layoutToggleLoader])

    return (
        <Fragment>
            <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
                <FullPageWrapper
                    css={css`
                        max-width: 572px;
                    `}
                >
                    <BackLink
                        style={css`
                            margin: 40px 0px 24px 0;
                        `}
                        text='Back to teams'
                        path={BACK_URL}
                    />
                    <section
                        css={css`
                            margin-top: 27px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                        `}
                    >
                        <div
                            css={css`
                                font-size: 24px;
                                font-weight: 500;
                            `}
                        >
                            Add New Feedback Team
                        </div>
                    </section>

                    <Divider
                        style={css`
                            margin-bottom: 26px;
                        `}
                    />

                    <h6>Feedback Team Details</h6>
                    <Row>
                        <Label>Team Name</Label>
                        <ControlledInput name='name' control={control} />
                    </Row>

                    <SectionLabel>Please choose the Specialists that you wish to add to this Feedback Team.</SectionLabel>

                    <Row>
                        <Label>Choose Specialists</Label>
                        <ControlledAutocompleteSelect
                            name='specialistIds'
                            control={control}
                            multiple={true}
                            options={specialists}
                            labelTransformer={label}
                            selectedLabelTransformer={label}
                        />
                    </Row>

                    <Divider
                        style={css`
                            margin-top: 20px;
                        `}
                    />

                    <h6>Feedback Forms Details</h6>
                    <p>Specialists from this team will receive forms listed below on a monthly basis.</p>

                    <FormBox>
                        <FromBoxLabel>Tech Skills Assessment</FromBoxLabel>
                        <div>Assessment of Specialist’s teammates technical skills.</div>
                    </FormBox>

                    <FormBox>
                        <FromBoxLabel>Performance Feedback</FromBoxLabel>
                        <div>Assessment of Specialist’s and their teammates work performance.</div>
                    </FormBox>

                    <FormBox>
                        <FromBoxLabel>Project Health Check</FromBoxLabel>
                        <div>Assessment of the project progress and status.</div>
                    </FormBox>

                    <FormBox>
                        <FromBoxLabel>Satisfaction Feedback</FromBoxLabel>
                        <div>Assessment of Specialist’s satisfaction with their work environment.</div>
                    </FormBox>

                    <Divider />
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-end;
                        `}
                    >
                        <Link to={BACK_URL}>
                            <Button
                                variant='link'
                                css={css`
                                    margin-right: 20px;
                                `}
                            >
                                Cancel
                            </Button>
                        </Link>
                        <Button variant='primary' onClick={handleSubmit} disabled={!isValid}>
                            Send Feedback Forms
                        </Button>
                    </div>
                </FullPageWrapper>
            </DashboardLayout>
        </Fragment>
    )
}

export { AddTeam }
