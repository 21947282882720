/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { EducationType } from '../../../../contracts/specialist/specialistEducations'
import { COLOR_PALETTE } from '../../../../GlobalStyle'
import { Nullable } from '../../../../types'

type CertificateItemProps = {
    certificate: {
        id?: string
        type: Nullable<EducationType>
        from: Nullable<number>
        to: Nullable<number>
        inProgress: boolean
        studiesSpecialization: string
        studiesDepartment: string
        studiesUniversity: string
        certificationAcquisitionDate: Nullable<number>
        certificationHasExpiration: boolean
        certificationExpirationDate: Nullable<number>
        certificationTitle: string
        certificationOrganization: string
        languageName: string
        languageSchool: string
    }
}

export const CertificateItem: FunctionComponent<React.PropsWithChildren<CertificateItemProps>> = ({ certificate }) => {
    return (
        <div
            data-break-avoid
            css={css`
                display: flex;
                margin-top: 14px;
                content: ${certificate.id as string};
                border-bottom: 1px solid ${COLOR_PALETTE.gray_1};
                padding: 8px 0;
                align-items: center;
            `}
        >
            <div
                css={css`
                    color: ${COLOR_PALETTE.gray_3};
                    font-size: 10px;
                    content: ${certificate.id as string};
                    margin-right: 12px;
                `}
            >
                Subject:
            </div>
            <div
                css={css`
                    font-size: 10px;
                    content: ${certificate.certificationTitle as string};
                `}
            >
                {certificate.certificationTitle}
            </div>
            <div
                css={css`
                    width: 2px;
                    height: 2px;
                    background-color: ${COLOR_PALETTE.gray_3};
                    margin: 0 10px;
                    border-radius: 1px;
                    box-sizing: border-box;
                `}
            />
            <div
                css={css`
                    color: ${COLOR_PALETTE.gray_3};
                    font-size: 10px;
                    content: ${certificate.id as string};
                    margin-right: 12px;
                `}
            >
                Name:
            </div>
            <div
                css={css`
                    font-size: 10px;
                    content: ${certificate.certificationOrganization as string};
                `}
            >
                {certificate.certificationOrganization}
            </div>
        </div>
    )
}
