/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent } from 'react'
import { mqMax } from '../../GlobalStyle'
import { Application } from '../../contracts/open-opportunities/contracts'
import { OpportunityOfferTypeEnum } from '../../contracts/opportunities-manager/contracts'
import { Nullable } from '../../types'
import { capitalizeFirstLetter } from '../../utils/strings'
import { blackToWhiteFilter, Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { OpportunityStatus } from '../../contracts/opportunities'
import { AppliedDropdown } from '../open-opportunities/AppliedDropdown'

type OfferBadgeProps = {
    offerType: OpportunityOfferTypeEnum
    vacancies: Nullable<number>
    applied?: number
    appliedOptions?: Array<Application>
    getAppliedOptions?: () => void
    isLoadingApplied?: boolean
    status?: OpportunityStatus
}

const OfferBadges: FunctionComponent<React.PropsWithChildren<OfferBadgeProps>> = ({
    offerType,
    vacancies,
    applied,
    appliedOptions,
    getAppliedOptions,
    isLoadingApplied,
    status,
}) => {
    const theme = useTheme()

    const isTeamOffer = offerType === OpportunityOfferTypeEnum.TEAM

    return (
        <Fragment>
            {status === OpportunityStatus.ON_HOLD && (
                <Tooltip
                    placement='topLeft'
                    title='This Opportunity has received enough applications and is currently On Hold. You cannot apply to it now.'
                >
                    <div
                        css={css`
                            padding: 8px;
                            color: ${theme.colors.gray_5};
                            background-color: ${theme.colors.gray_2};
                            border-radius: 2px;
                            font-size: 12px;
                            margin-right: 8px;

                            ${mqMax[3]} {
                                margin-right: 4px;
                                padding: 7px;
                            }
                        `}
                    >
                        On hold
                    </div>
                </Tooltip>
            )}
            <Tooltip placement='topLeft' title={isTeamOffer ? 'Looking for an entire team' : 'Looking for individual specialists'}>
                <FlexBox
                    alignItems='center'
                    css={css`
                        padding: 8px;
                        color: ${theme.colors.white};
                        background-color: ${isTeamOffer ? theme.colors.green_5 : theme.colors.violet_4};
                        border-radius: 2px;
                        margin-right: 8px;
                        font-size: 12px;

                        ${mqMax[3]} {
                            margin-right: 4px;
                            padding: 7px;
                        }
                    `}
                >
                    <Icon
                        name={isTeamOffer ? 'team' : 'user'}
                        size={isTeamOffer ? 18 : 16}
                        style={
                            isTeamOffer
                                ? css`
                                      margin-right: 6px;
                                  `
                                : css`
                                      margin-right: 8px;
                                      filter: ${blackToWhiteFilter};
                                  `
                        }
                    />
                    {offerType ? capitalizeFirstLetter(offerType.toLocaleLowerCase()) : '-'}
                </FlexBox>
            </Tooltip>
            {vacancies !== null && (
                <div
                    css={css`
                        padding: 8px;
                        border-radius: 2px;
                        background-color: ${isTeamOffer ? theme.colors.green_1 : theme.colors.violet_2};
                        margin-right: 8px;
                        font-size: 12px;

                        ${mqMax[3]} {
                            white-space: nowrap;
                            padding: 7px;
                            margin-right: 4px;
                        }
                    `}
                >
                    <Fragment>
                        {vacancies}
                        {vacancies !== 1 ? ' Vacancies' : ' Vacancy'}
                    </Fragment>
                </div>
            )}
            {applied !== undefined && (
                <div
                    css={css`
                        position: relative;

                        ${appliedOptions &&
                        css`
                            &:hover {
                                & > div:first-of-type {
                                    background-color: ${theme.colors.beige_4};
                                }

                                .applied-dropdown {
                                    display: block;
                                }
                            }
                        `}
                    `}
                    onMouseOver={getAppliedOptions}
                >
                    <div
                        css={css`
                            padding: 7px 8px;
                            border-radius: 2px;
                            border: 1px solid ${theme.colors.beige_4};
                            font-size: 12px;

                            ${mqMax[3]} {
                                white-space: nowrap;
                                padding: 6px 7px;
                            }
                        `}
                    >
                        Applied with: {applied}
                    </div>
                    <div
                        css={css`
                            height: 4px;
                        `}
                    />
                    {appliedOptions && <AppliedDropdown appliedOptions={appliedOptions} isLoadingApplied={isLoadingApplied} />}
                </div>
            )}
        </Fragment>
    )
}

export { OfferBadges }
