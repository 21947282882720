/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { getCountries, getUser, updateUser } from '../api/api'
import { Country } from '../contracts/country'
import { mqMax } from '../GlobalStyle'
import { history } from '../history'
import { useUserContext } from '../hooks/useUserContext'
import { ReduxContext } from '../redux/Store'
import { hasErrors } from '../utils/errors'
import { phoneRegex } from '../utils/regexes'
import { useQuery } from '../utils/useQuery'
import { PHONE_NOT_VALID } from '../validation/validation-messages'
import { yup } from '../validation/yup'
import { Button } from './Button'
import { Divider } from './Divider'
import { ControlledInput } from './forms/ControlledInput'
import { ControlledPhoneInput } from './forms/ControlledPhoneInput'
import { FlexButtons } from './layout/FlexBoxHelpers'
import { FieldWrapper } from './layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from './layout/ResponsiveColumns'
import { useNotifications } from './notification/NotificationProvider'

type FormData = {
    firstName: string
    lastName: string
    phone: string
}

type PersonalDetailsFormProps = {
    isPersonalDetailsModal?: boolean
    hasPendingInvites?: boolean
}

const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    phone: yup.lazy(value => (value ? yup.string().matches(phoneRegex, PHONE_NOT_VALID) : yup.string())),
})

const PersonalDetailsForm: FunctionComponent<React.PropsWithChildren<PersonalDetailsFormProps>> = ({
    isPersonalDetailsModal,
    hasPendingInvites,
}) => {
    const {
        selectors: { user },
        actions: { layoutToggleLoader, userUpdatePersonalDetails },
    } = useContext(ReduxContext)
    const [countries, setCountries] = useState<Array<Country>>([])
    const [hasCompanyAccount, setHasCompanyAccount] = useState(false)

    const query = useQuery()

    const {
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
        reset,
        control,
    } = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    })
    const { addSuccess, addError } = useNotifications()
    const { activeContext } = useUserContext()

    useEffect(() => {
        if (activeContext?.companyId) {
            setHasCompanyAccount(true)
        }
    }, [activeContext])

    useEffect(() => {
        const runEffect = async () => {
            layoutToggleLoader(true)
            const countriesData = await getCountries()
            setCountries(countriesData)
            if (user?.uid) {
                const data = await getUser(user.firebaseId)
                reset({ firstName: data.firstName || '', lastName: data.lastName || '', phone: data.phone || '' })
            }
        }
        runEffect().finally(() => layoutToggleLoader(false))
    }, [user, reset, layoutToggleLoader])

    const onSubmit = useCallback(
        (formData: FormData) => {
            const newData = {
                id: user.uid,
                email: user.email,
                firebaseId: user.firebaseId,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phone: formData.phone,
            }

            layoutToggleLoader(true)
            updateUser(user.firebaseId, newData)
                .then(({ firstName, lastName, phone }) => {
                    reset({ firstName, lastName, phone })
                    userUpdatePersonalDetails({ firstName, lastName, phone })
                    addSuccess('User data updated!')
                })
                .catch(() => {
                    addError()
                    userUpdatePersonalDetails(null)
                })
                .finally(() => {
                    layoutToggleLoader(false)
                    const redirect = !hasCompanyAccount ? '/dashboard/company-account/company-details/form' : query.get('afterSuccess')
                    if (redirect && (!hasPendingInvites || !isPersonalDetailsModal)) {
                        history.push(redirect)
                    }
                })
        },
        [
            addError,
            addSuccess,
            hasCompanyAccount,
            hasPendingInvites,
            isPersonalDetailsModal,
            layoutToggleLoader,
            query,
            reset,
            user.email,
            user.firebaseId,
            user.uid,
            userUpdatePersonalDetails,
        ],
    )

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ResponsiveColumns>
                <FirstColumn>
                    <FieldWrapper>
                        <ControlledInput name='firstName' placeholder='' label='First Name' dataTestId='first-name' control={control} />
                    </FieldWrapper>
                </FirstColumn>
                <SecondColumn>
                    <FieldWrapper>
                        <ControlledInput name='lastName' placeholder='' label='Last Name' dataTestId='last-name' control={control} />
                    </FieldWrapper>
                </SecondColumn>
            </ResponsiveColumns>
            <FieldWrapper>
                <ControlledPhoneInput
                    control={control}
                    name='phone'
                    label='Phone (optional)'
                    placeholder='Phone'
                    defaultValue=''
                    countryAreaCodes={countries}
                    dataTestId='personal-details-phone'
                    canClear
                />
            </FieldWrapper>
            <Divider />
            <FlexButtons
                justifyContent={isPersonalDetailsModal ? 'flex-end' : undefined}
                css={css`
                    ${mqMax[1]} {
                        flex-direction: column-reverse;
                    }
                `}
            >
                {!isPersonalDetailsModal && (
                    <Button
                        css={css`
                            margin-right: 24px;
                            ${mqMax[1]} {
                                width: 100%;
                                justify-content: center;
                                margin-right: 0;
                                margin-top: 12px;
                            }
                        `}
                        variant='link'
                        onClick={() => {
                            reset()
                        }}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    dataTestId='account-personal-details-submit'
                    variant='primary'
                    type='submit'
                    disabled={isSubmitting || !isDirty || hasErrors(errors)}
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Save Details
                </Button>
            </FlexButtons>
        </form>
    )
}

export { PersonalDetailsForm }
