/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
    DropResult,
} from '@hello-pangea/dnd'
import { Icon } from '../../components/Icon'
import { ListElementProps } from '../../components/TilesList'
import { useSpecialistRoles } from '../../redux/dictionaryDataHooks'
import { COLOR_PALETTE } from '../../theme/colors'
import { randomString } from '../../utils/strings'
import { InstantOnboardingAdditionalRoles } from './components/InstantOnboardingAdditionalRoles'

type StyledElement = {
    css: SerializedStyles
} & any

const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number): Array<ListElementProps> => {
    const result = Array.from(list) as Array<ListElementProps>
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
}

const grid = 5

type SortableListProps = {
    options: Array<ListElementProps>
    onChange?: (options: Array<ListElementProps>) => void
    maxNumChoicesReached?: boolean
    onReset?: () => void
}

const SortableList: FunctionComponent<React.PropsWithChildren<SortableListProps>> = ({
    options,
    onChange,
    maxNumChoicesReached,
    onReset,
}) => {
    const [items, setItems] = useState(options)
    const rolesDictionary = useSpecialistRoles()

    const updateList = useCallback(
        (list: Array<ListElementProps>) => {
            const updatedList = list.map(item => ({ ...item, isSelected: true }))
            setItems(updatedList)

            if (onChange) {
                onChange(updatedList)
            }
        },
        [onChange],
    )

    useEffect(() => {
        setItems(options)
    }, [options])

    const emptyFields = useMemo(() => {
        const output = []
        if (items.length < 5) {
            for (let i = items.length; i < 5; i++) {
                output.push(null)
            }
        }
        return output
    }, [items])

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (result.destination) {
                const reorderedList = reorder(items, result.source.index, result.destination.index)
                updateList(reorderedList)
            }
        },
        [items, updateList],
    )

    const handleItemRemove = useCallback(
        (item: ListElementProps) => {
            updateList(items.filter(i => i.id !== item.id))
        },
        [items, updateList],
    )

    const addItem = useCallback(
        (event: string) => {
            if (event) {
                const newList = [...items]
                newList.push({ content: event, id: randomString() })
                updateList(newList)
            }
        },
        [items, updateList],
    )

    const resetList = useCallback(() => {
        if (onReset) {
            onReset()
        }
    }, [onReset])

    return (
        <section>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            css={css`
                                background: ${COLOR_PALETTE.gray_1};
                                padding: ${grid * 2 + 'px'};
                                width: 100%;
                                color: ${COLOR_PALETTE.gray_6};
                            `}
                        >
                            <div
                                css={css`
                                    margin-bottom: 10px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                `}
                            >
                                <div>Drag & Drop to change the order.</div>
                                <div
                                    onClick={resetList}
                                    css={css`
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        cursor: pointer;

                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    `}
                                    data-testid='sortable-list-reset'
                                >
                                    <Icon
                                        name='refresh'
                                        size={16}
                                        css={css`
                                            margin-right: 10px;
                                        `}
                                    />{' '}
                                    Reset List
                                </div>
                            </div>

                            {items.map((item: ListElementProps, index: number) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided: DraggableProvided & StyledElement, snapshot: DraggableStateSnapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            data-testid='sortable-list-item'
                                            css={css`
                                                user-select: none;
                                                padding: ${grid * 2 + 'px'};
                                                margin: 0 0 ${grid + 'px'} 0;
                                                background: ${snapshot.isDragging ? COLOR_PALETTE.gray_2 : COLOR_PALETTE.white};
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;

                                                ${provided.draggableProps.style}
                                            `}
                                        >
                                            <div
                                                css={css`
                                                    display: flex;
                                                    justify-content: flex-start;
                                                    align-items: center;
                                                `}
                                            >
                                                <Icon name='grid' />{' '}
                                                <div
                                                    css={css`
                                                        margin-left: 20px;
                                                    `}
                                                >
                                                    <div
                                                        css={css`
                                                            height: 40px;
                                                            display: flex;
                                                            align-items: center;
                                                        `}
                                                    >
                                                        {item.content}
                                                    </div>
                                                </div>
                                            </div>
                                            {items.length > 3 && (
                                                <Icon
                                                    onClick={() => handleItemRemove(item)}
                                                    name='close'
                                                    size={24}
                                                    style={css`
                                                        &:hover {
                                                            cursor: pointer;
                                                            opacity: 0.8;
                                                        }
                                                    `}
                                                    dataTestId='sortable-list-item-remove'
                                                />
                                            )}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}

                            {emptyFields.map((field, index) => (
                                <div
                                    key={index}
                                    css={css`
                                        user-select: none;
                                        padding: ${grid * 2 + 'px'};
                                        margin: 0 0 ${grid + 'px'} 0;
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        height: 60px;
                                        border: 1px solid ${COLOR_PALETTE.gray_2};
                                    `}
                                ></div>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {rolesDictionary !== null && (
                <InstantOnboardingAdditionalRoles options={rolesDictionary} disabled={maxNumChoicesReached} handleChange={addItem} />
            )}
        </section>
    )
}

export { SortableList }
