/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { COLOR_PALETTE, mqMin } from '../../GlobalStyle'

const TwoCols = ({ firstCol, secondCol }: { firstCol: any; secondCol: any }) => {
    return (
        <section
            css={css`
                height: 100vh;
                background-color: ${COLOR_PALETTE.white};
                display: flex;
                justify-content: space-between;
                flex-direction: row;
            `}
        >
            <div
                css={css`
                    height: 100vh;
                    width: 100%;
                    overflow: auto;

                    ${mqMin[2]} {
                        width: 50%;
                    }
                `}
            >
                {firstCol}
            </div>

            <div
                css={css`
                    height: 100vh;
                    width: 50%;
                    position: relative;
                    overflow: auto;
                    display: none;

                    ${mqMin[2]} {
                        display: block;
                    }
                `}
            >
                {secondCol}
            </div>
        </section>
    )
}

export { TwoCols }
