import { useContext, useMemo } from 'react'
import { AccessLevelContext } from '../../AccessLevelContext'
import { ApplicationMenuItemEntry } from '../../components/layout/dashboard/ApplicationMenu'
import { getAppPath } from '../../contracts/applications'

const applicationName = 'Talent Marketplace'

const useMenuItems = () => {
    const {
        talentMarketplace: { findTalent, savedProfiles, bookings },
    } = useContext(AccessLevelContext)

    const menuItems = useMemo<Array<ApplicationMenuItemEntry>>(() => {
        const menuItemsArray = []
        if (findTalent) {
            menuItemsArray.push({
                path: getAppPath('TALENT_MARKETPLACE'),
                title: 'Find Talent',
            })
        }
        if (savedProfiles) {
            menuItemsArray.push({
                path: `${getAppPath('TALENT_MARKETPLACE')}/saved-profiles`,
                title: 'Saved Profiles',
                dataTestId: 'my-specialists-saved-profiles-menu-item',
            })
        }

        if (bookings) {
            menuItemsArray.push({
                path: `${getAppPath('TALENT_MARKETPLACE')}/bookings`,
                title: 'Bookings',
                dataTestId: 'my-specialists-bookings-menu-item',
            })
        }

        return menuItemsArray
    }, [findTalent, savedProfiles, bookings])

    return menuItems
}

export { useMenuItems, applicationName }
