/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ColumnsType } from 'antd/lib/table'
import { FunctionComponent } from 'react'
import { ResponsiveGrid } from '../../../../../components/data-grid/ResponsiveGrid'
import { NoData } from '../../../../../components/NoData'
import { CompanyInvitation, InvitationStatus, invitationStatusMapping } from '../../../../../contracts/role-management/CompanyInvitation'
import { COLOR_PALETTE } from '../../../../../theme/colors'
import { convertTimestampToDateString } from '../../../../../utils/dates'
import { createComparer } from 'src/utils/sorting'
import { InvitationsActionHandler } from './InvitationsHandler'

interface InvitationsGridProps {
    companyInvitations: Array<CompanyInvitation>
    onChangeTable: (_: any, __: any, newSorter: any) => void
    fetchData: () => void
}

const PendingText = styled.div`
    color: ${COLOR_PALETTE.violet_4};
`

const RejectedText = styled.div`
    color: ${COLOR_PALETTE.red_4};
`

const InvitationsGrid: FunctionComponent<React.PropsWithChildren<InvitationsGridProps>> = ({
    companyInvitations,
    onChangeTable,
    fetchData,
}) => {
    const columns: ColumnsType<CompanyInvitation> = [
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            sorter: {
                compare: createComparer('email'),
            },
            showSorterTooltip: false,
            render: content => content || '-',
        },
        {
            title: 'Invitation Status',
            dataIndex: 'status',
            key: 'status',
            sorter: {
                compare: createComparer('status'),
            },
            render: (status: InvitationStatus) =>
                status === InvitationStatus.PENDING ? (
                    <PendingText>{invitationStatusMapping[status]}</PendingText>
                ) : (
                    <RejectedText>{invitationStatusMapping[status]}</RejectedText>
                ),
            showSorterTooltip: false,
        },
        {
            title: 'Last update',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: {
                compare: createComparer('updatedAt'),
            },
            render: updatedAt => convertTimestampToDateString(updatedAt),
            showSorterTooltip: false,
        },
        {
            title: '',
            dataIndex: 'actionsHandler',
            key: 'actionsHandler',
            showSorterTooltip: false,
            render: (_, record) => <InvitationsActionHandler recordId={record.id} fetchData={fetchData} />,
        },
    ]

    return (
        <div>
            {companyInvitations.length === 0 ? (
                <div
                    css={css`
                        margin: 40px auto;
                    `}
                >
                    <NoData />
                </div>
            ) : (
                <ResponsiveGrid
                    dataTestId='user-roles-grid'
                    rowKey='id'
                    dataSource={companyInvitations}
                    columns={columns}
                    pagination={false}
                    onChange={onChangeTable}
                />
            )}
        </div>
    )
}

export { InvitationsGrid }
