/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useCallback, useMemo, useState } from 'react'
import { COLOR_PALETTE } from '../theme/colors'
import { Icon } from './Icon'

export type ListElementProps = {
    id: string
    content: string
    editable?: boolean
    isSelected?: boolean
}

type TilesListProps = {
    options: Array<ListElementProps>
    onChange?: (data: Array<ListElementProps>) => void
    maxNumChoicesReached?: boolean
}

const Box = styled.div`
    width: 123px;
    height: 123px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${COLOR_PALETTE.gray_2};
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    margin-bottom: 13px;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
`

const visibleOptionsNumber = 12

const TilesList: FunctionComponent<React.PropsWithChildren<TilesListProps>> = ({ options, onChange, maxNumChoicesReached }) => {
    const updateList = useCallback(
        (list: any) => {
            if (onChange) {
                onChange(list)
            }
        },
        [onChange],
    )

    const [isExpanded, setIsExpanded] = useState(false)
    const isListExtendable = useMemo(() => options?.length > visibleOptionsNumber, [options])
    const toggleList = useCallback((state: boolean) => setIsExpanded(state), [])
    const invisibleOptionsCount = useMemo(() => (options ? options.length - visibleOptionsNumber + 1 : 0), [options])
    const visibleOptions = useMemo(
        () => (isExpanded || options.length <= visibleOptionsNumber ? options : [...options].splice(0, visibleOptionsNumber - 1)),
        [options, isExpanded],
    )

    const toggleItem = useCallback(
        (item: ListElementProps) => {
            if (!item.isSelected && maxNumChoicesReached) {
                return null
            } else {
                const updatedList = [...options]
                item.isSelected = !item.isSelected
                updateList(updatedList)
            }
        },
        [updateList, options, maxNumChoicesReached],
    )

    return (
        <section
            css={css`
                display: grid;
                grid-template-columns: repeat(auto-fill, 123px);
                grid-gap: 5px;
                justify-content: space-between;
            `}
        >
            {visibleOptions.map((item: ListElementProps, index: number) => (
                <Box
                    key={index}
                    css={css`
                        background-color: ${item.isSelected
                            ? COLOR_PALETTE.green_4
                            : maxNumChoicesReached
                            ? COLOR_PALETTE.gray_2
                            : COLOR_PALETTE.white};
                        color: ${item.isSelected
                            ? COLOR_PALETTE.white
                            : maxNumChoicesReached
                            ? COLOR_PALETTE.gray_4
                            : COLOR_PALETTE.gray_6};
                        cursor: ${maxNumChoicesReached && !item.isSelected ? 'not-allowed' : 'pointer'};
                    `}
                    onClick={() => toggleItem(item)}
                    data-testid='tiles-list-option'
                >
                    {item.content}
                </Box>
            ))}

            {isListExtendable && !isExpanded && (
                <Box
                    css={css`
                        flex-direction: column;
                        background-color: ${COLOR_PALETTE.gray_6};
                        color: ${COLOR_PALETTE.white};
                    `}
                    onClick={() => toggleList(true)}
                >
                    <div>
                        <span>Show More</span>
                        <Icon
                            name={'arrow-down'}
                            size={14}
                            css={css`
                                filter: invert(100%);
                                transform: rotate(-90deg);
                                position: relative;
                                top: -2px;
                                right: -5px;
                            `}
                        />
                    </div>
                    {options && (
                        <div
                            css={css`
                                font-size: 12px;
                                opacity: 0.5;
                                margin-top: 5px;
                            `}
                        >
                            (+{invisibleOptionsCount} result{invisibleOptionsCount + 1 > 1 ? 's' : ''})
                        </div>
                    )}
                </Box>
            )}
        </section>
    )
}

export { TilesList }
