const defaultPageSize = 9

type Parsed<T> = T | (T | null)[] | null | undefined

const parseToArray: (value: Parsed<string>) => Array<string> = value => {
    if (typeof value === 'string') {
        return value ? value.split(',').map(el => decodeURIComponent(el)) : []
    } else if (Array.isArray(value)) {
        return value.reduce((acc, v) => {
            if (v) {
                acc.push(decodeURIComponent(v))
            }
            return acc
        }, [] as Array<string>)
    } else {
        return value ? [value] : []
    }
}

type NumberParser = (value: Parsed<string>, defaultValue?: number) => number
const parseToNumber: NumberParser = (value, defaultValue = 0) => {
    if (typeof value === 'string') {
        return parseInt(value, 10) || defaultValue
    } else {
        return defaultValue
    }
}

const parseToBoolean: (value: Parsed<string>) => boolean = value => {
    return parseToNumber(value) !== 0
}

const parseToString: (value: Parsed<string>) => string = value => {
    if (typeof value === 'string') {
        return decodeURIComponent(value)
    } else {
        return ''
    }
}

const encodeField = (value: string | string[]) => {
    if (typeof value === 'string') {
        return encodeURIComponent(value)
    } else {
        return value.map(item => encodeURIComponent(item)).join(',')
    }
}

const toMonths = (years: number) => years * 12
const toYears = (months: number) => Math.floor(months / 12)

const resolveOptionsChange = (currentValues: Array<any>, options: Array<any>) =>
    currentValues.filter(value => (options.includes(value) ? value : null))

export { toMonths, toYears, defaultPageSize, resolveOptionsChange, parseToArray, parseToNumber, parseToBoolean, parseToString, encodeField }
