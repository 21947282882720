/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Icon } from './Icon'

const NoDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const GreyText = styled.p`
    color: ${COLOR_PALETTE.gray_3};
    margin-top: 12px;
`

type NoDataProps = {
    header?: string
    message?: string
    style?: SerializedStyles
}

const NoData: FunctionComponent<React.PropsWithChildren<NoDataProps>> = ({ header, message = 'No Data Available', style }) => {
    return (
        <NoDataContainer css={style}>
            <Icon
                name='no-data'
                style={css`
                    height: 28px;
                    width: 28px;
                `}
            />
            {header && (
                <h6
                    css={css`
                        margin-top: 24px;
                    `}
                >
                    {header}
                </h6>
            )}
            <GreyText>{message}</GreyText>
        </NoDataContainer>
    )
}

export { NoData }
