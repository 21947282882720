const VALUE_POSITIVE = 'Value must be positive'
const VALUE_INVALID = 'Value is invalid'
const FIELD_REQUIRED = 'This field is required'
const PHONE_NOT_VALID = 'This is not a valid phone number'
const DATE_RANGE_REQUIRED = 'Date range is required'
const DATE_REQUIRED = 'Date is required'
const INVALID_DATE_FORMAT = 'Invalid date format'
const PHONE_KIND = 'Add phone kind'
const PHONE_INVALID = 'Please enter valid phone'
const PHONE_REQUIRED = 'Phone with area code required'
const EMAIL_INVALID = 'Please enter valid e-mail'
const EMAIL_DUPLICATED = 'This e-mail is taken or duplicated'
const EMAIL_EXIST = 'This e-mail already exists'
const SPECIALIST_DUPLICATED = 'This specialist already exists'
const PASSWORD_MUST_MATCH = 'Passwords don’t match'
const INVALID_DATE_RANGE = 'Date "To" cannot be before "From"'
const INVALID_DATE_LATEST = 'Date "Latest" cannot be before "Ideal"'
const INVALID_URL = 'Please enter a valid URL'
const INVALID_NUMBER = 'Value should be a number'
const CELL_REQUIRED = 'Required'
const CELL_RANGE = 'Should be after "from"'
const MAX_LENGTH_1000 = 'Length of the text cannot exceed 1000 characters '
const MAX_INTEGER_MESSAGE = 'Value can not be greater than 2147483647'
const LOCATION_MESSAGE = 'Location doesn’t match the requirements for this opportunity'

export {
    VALUE_POSITIVE,
    VALUE_INVALID,
    FIELD_REQUIRED,
    PHONE_NOT_VALID,
    DATE_REQUIRED,
    DATE_RANGE_REQUIRED,
    PHONE_KIND,
    PHONE_INVALID,
    PHONE_REQUIRED,
    EMAIL_INVALID,
    EMAIL_DUPLICATED,
    PASSWORD_MUST_MATCH,
    SPECIALIST_DUPLICATED,
    INVALID_DATE_RANGE,
    INVALID_DATE_LATEST,
    INVALID_URL,
    INVALID_NUMBER,
    CELL_REQUIRED,
    CELL_RANGE,
    INVALID_DATE_FORMAT,
    MAX_LENGTH_1000,
    EMAIL_EXIST,
    MAX_INTEGER_MESSAGE,
    LOCATION_MESSAGE,
}
