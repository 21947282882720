import { useContext, useMemo } from 'react'
import { getAppPath } from '../contracts/applications'
import { ReduxContext } from '../redux/Store'

const useMySpecialistsMenuItems = () => {
    const {
        selectors: {
            featureFlags: { continuousFeedback },
            VMCstatus,
        },
    } = useContext(ReduxContext)

    const menuItems = useMemo(() => {
        const output = [
            {
                path: `${getAppPath('MY_SPECIALISTS')}`,
                title: 'My Specialists',
                dataTestId: 'my-specialists-my-specialists-menu-item',
            },
        ]
        if (VMCstatus === 'NONE') {
            output.push({
                path: `${getAppPath('MY_SPECIALISTS')}/share`,
                title: 'Share My Specialists',
                dataTestId: 'my-specialists-share',
            })
        }

        if (continuousFeedback && VMCstatus === 'NONE') {
            output.push({
                path: `${getAppPath('MY_SPECIALISTS')}/continuous-feedback`,
                title: 'Feedback Teams',
                dataTestId: 'my-specialists-feedback-teams-menu-item',
            })
        }

        output.push({
            path: `${getAppPath('MY_SPECIALISTS')}/custom-fields`,
            title: 'Custom Fields',
            dataTestId: 'custom-fields',
        })

        return output
    }, [continuousFeedback, VMCstatus])

    return { menuItems, applicationName: 'My Specialists' }
}

export { useMySpecialistsMenuItems }
