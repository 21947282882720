import { Nullable, StringMapping } from '../../types'

export enum SubtaskStatus {
    NEW = 'NEW',
    COMPLETED = 'COMPLETED',
}

export enum TaskTypeEnum {
    BASIC_INFO = 'BASIC_INFO',
    TECH_SKILLS = 'TECH_SKILLS',
    STRENGTH_DISCOVERY = 'STRENGTH_DISCOVERY',
    CV_PARSING = 'CV_PARSING',
    TEST_YOUR_SKILLS = 'TEST_YOUR_SKILLS',
}

export type SubtaskType = { name: string; status: SubtaskStatus }
export type TaskType = { type: TaskTypeEnum; subtasks: Array<SubtaskType> }

const taskTypeMapping: StringMapping = {
    [TaskTypeEnum.BASIC_INFO]: 'Add your Experience and Education',
    [TaskTypeEnum.TECH_SKILLS]: 'Map Your Technical Skills',
    [TaskTypeEnum.STRENGTH_DISCOVERY]: 'Discover Your Strengths',
    [TaskTypeEnum.CV_PARSING]: 'Extract data from a file',
    [TaskTypeEnum.TEST_YOUR_SKILLS]: 'Test Your Skills',
}

const mapTaskType = (type: Nullable<TaskTypeEnum>) => (type !== null ? taskTypeMapping[type] : '')

export { taskTypeMapping, mapTaskType }
