/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLifecycles } from 'react-use'
import { DashboardLayout, MenuType } from '../../../../components/layout/dashboard/DashboardLayout'
import { useUserCompanies } from '../../../../hooks/useUserCompanies'
import { useUserContext } from '../../../../hooks/useUserContext'
import { Nullable } from '../../../../types'
import { CompanyAccount } from './CompanyAccount'

const CompanyStatusGuard: FunctionComponent<React.PropsWithChildren<{ redirectUrl: string }>> = ({ redirectUrl }) => {
    const { activeContext, isSuperAdmin, isFunctionalManager } = useUserContext()
    const { hasInactiveCompany } = useUserCompanies()

    const navigate = useNavigate()
    const [status, setStatus] = useState<Nullable<string>>(null)
    const [showWizard, setShowWizard] = useState(false)
    const [isMounted, setIsMounted] = useState(false)

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    useEffect(() => {
        if ((isSuperAdmin || isFunctionalManager) && activeContext?.companyId) {
            setStatus('VERIFIED')
            navigate(redirectUrl)
        } else {
            if (hasInactiveCompany && isMounted) {
                setStatus('INACTIVE')
            } else {
                setStatus('NO_COMPANIES')
            }

            setShowWizard(true)
        }
    }, [activeContext, hasInactiveCompany, navigate, isMounted, isSuperAdmin, isFunctionalManager, redirectUrl])

    const getStatus = useCallback(() => {
        if ((isSuperAdmin || isFunctionalManager) && activeContext?.companyId) {
            setStatus('VERIFIED')
            navigate(redirectUrl)
        } else {
            if (hasInactiveCompany && isMounted) {
                setStatus('INACTIVE')
            } else {
                setStatus('NO_COMPANIES')
            }

            setShowWizard(true)
        }
    }, [isSuperAdmin, isFunctionalManager, activeContext?.companyId, navigate, redirectUrl, hasInactiveCompany, isMounted])

    return (
        <DashboardLayout menuType={MenuType.companyAccount}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                `}
            >
                {showWizard && <CompanyAccount status={status} getStatus={getStatus} />}
            </div>
        </DashboardLayout>
    )
}

export { CompanyStatusGuard }
