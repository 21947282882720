import { Application } from '../../../../contracts/open-opportunities/contracts'
import { ApplicationData, CurrentStep } from './types'

const parseApplication = ({ statusHistory, opportunityId, ...rest }: Application): ApplicationData => {
    return { ...rest, currentStep: CurrentStep.COMPLETE_INFORMATION, isSendingCv: false, isUpdatingCv: false }
}

const getPreviousStep = (currentStep: CurrentStep): CurrentStep => {
    switch (currentStep) {
        case CurrentStep.REVIEW_AND_APPLY: {
            return CurrentStep.RATE_SKILLS
        }
        default: {
            return CurrentStep.COMPLETE_INFORMATION
        }
    }
}

const getNextStep = (currentStep: CurrentStep): CurrentStep => {
    switch (currentStep) {
        case CurrentStep.COMPLETE_INFORMATION: {
            return CurrentStep.RATE_SKILLS
        }
        default: {
            return CurrentStep.REVIEW_AND_APPLY
        }
    }
}

export { parseApplication, getPreviousStep, getNextStep }
