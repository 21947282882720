/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment, { Moment } from 'moment'
import { FunctionComponent, useCallback } from 'react'
import { FilterDoubleColumn, FiltersBarWrapper, FiltersClearTrigger } from '../../components/filters/FiltersBarHelpers'
import { RangeSelection } from '../../components/filters/RangeSelection'
import { AutocompleteSelect } from '../../components/inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../../components/inputs/autocomplete-select/Option'
import { DatePickerInput } from '../../components/inputs/DatePickerInput'
import { Input } from '../../components/inputs/Input'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { RangeFilter } from '../../components/opportunities/FiltersBar'
import { mqMax } from '../../GlobalStyle'
import { Nullable } from '../../types'
import { OpportunityOfferTypeEnum, opportunityOfferTypeMapping } from '../../contracts/opportunities-manager/contracts'
import {
    OpenOpportunitiesFiltersResponse,
    ResponseType,
    responseTypeOptions,
    visibilityTypeOptions,
} from 'src/contracts/open-opportunities/contracts'

export type OpenOpportunitiesFilters = {
    countries: Array<string>
    currency: string
    industries: Array<string>
    locationTypes: Array<string>
    maxMinExperienceInYears: Nullable<number>
    minMinExperienceInYears: Nullable<number>
    projectEndDate: Nullable<number>
    projectStartDate: Nullable<number>
    regions: Array<string>
    responseType: Nullable<ResponseType>
    skills: Array<string>
    timeZones: Array<string>
    type: Nullable<OpportunityOfferTypeEnum>
    visibilityValues: Array<string>
}

type OpenOpportunitiesFiltersBarProps = {
    opened: boolean
    filtersOptions: OpenOpportunitiesFiltersResponse
    selectedFilters: OpenOpportunitiesFilters
    onClearAll: () => void
    minRateValue: string | undefined
    handleMinRate: (rate: string) => void
    filtersCount: number
    isAllowedToSMECloudApp: boolean
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
    onLocationChange: (values: Array<OptionType>, filterName: string) => void
    onValueChange: (value: any, filterName: string) => void
    onDatePickerChange: (timestamp: any, filterName: string) => void
    onRangeFilterChange: (values: Array<RangeFilter>) => void
}

const OpenOpportunitiesFiltersBar: FunctionComponent<React.PropsWithChildren<OpenOpportunitiesFiltersBarProps>> = ({
    opened,
    onClearAll,
    selectedFilters,
    filtersOptions,
    minRateValue,
    handleMinRate,
    filtersCount,
    onSelectFilterChange,
    onLocationChange,
    onValueChange,
    onDatePickerChange,
    onRangeFilterChange,
    isAllowedToSMECloudApp,
}) => {
    const onProjectStartDateChange = useCallback(
        (projectStartDate: any) => onDatePickerChange(projectStartDate, 'projectStartDate'),
        [onDatePickerChange],
    )
    const onProjectEndDateChange = useCallback(
        (projectEndDate: any) => onDatePickerChange(projectEndDate, 'projectEndDate'),
        [onDatePickerChange],
    )
    const disableProjectEndDate = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc()) ||
            (selectedFilters.projectStartDate !== undefined && selectedFilters.projectStartDate !== null
                ? date.isBefore(moment.utc(selectedFilters.projectStartDate * 1000))
                : false),

        [selectedFilters],
    )
    const disableProjectStartDate = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc()) ||
            (selectedFilters.projectStartDate !== undefined && selectedFilters.projectEndDate !== null
                ? date.isAfter(moment.utc(selectedFilters.projectEndDate * 1000))
                : false),
        [selectedFilters],
    )

    const onExperienceRangeFilterChange = useCallback(
        (minMinExperienceInYears: number, maxMinExperienceInYears: number) => {
            onRangeFilterChange([
                { value: minMinExperienceInYears, filterName: 'minMinExperienceInYears' },
                { value: maxMinExperienceInYears, filterName: 'maxMinExperienceInYears' },
            ])
        },
        [onRangeFilterChange],
    )

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <FlexBox
                    justifyContent='flex-start'
                    css={css`
                        flex-grow: 1;
                        flex-wrap: wrap;
                        align-items: center;
                    `}
                >
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            canClear={true}
                            canFilter={false}
                            placeholder='Opportunity Type'
                            filterName='type'
                            options={filtersOptions.opportunityTypes}
                            labelTransformer={(opt: string) => opportunityOfferTypeMapping[opt as OpportunityOfferTypeEnum]}
                            selectedLabelTransformer={(opt: string) => opportunityOfferTypeMapping[opt as OpportunityOfferTypeEnum]}
                            currentValues={[selectedFilters.type]}
                            onSelectedValuesChange={onValueChange}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <DatePickerInput
                            placeholder='Project Start Date'
                            onChange={onProjectStartDateChange}
                            value={selectedFilters.projectStartDate}
                            isActive={Boolean(selectedFilters.projectStartDate)}
                            disabledDate={disableProjectStartDate}
                            style={{ padding: '8px 9px 8px 16px' }}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <DatePickerInput
                            placeholder='Project End Date'
                            onChange={onProjectEndDateChange}
                            value={selectedFilters.projectEndDate}
                            isActive={Boolean(selectedFilters.projectEndDate)}
                            disabledDate={disableProjectEndDate}
                            style={{ padding: '8px 9px 8px 16px' }}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            multiple
                            placeholder='Country/Region'
                            filterName='countriesRegions'
                            options={filtersOptions.regions
                                .map(country => ({ value: country, groupName: 'Regions' }))
                                .concat(filtersOptions.countries.map(country => ({ value: country, groupName: 'Countries' })))}
                            currentValues={selectedFilters.regions
                                .map(region => ({ value: region, groupName: 'Regions' }))
                                .concat(selectedFilters.countries.map(country => ({ value: country, groupName: 'Countries' })))}
                            onSelectedValuesChange={onLocationChange}
                            selectedLabelTransformer={(option: any) => option.value}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            multiple
                            placeholder='Location type'
                            filterName='locationTypes'
                            options={filtersOptions.locationTypes}
                            currentValues={selectedFilters.locationTypes}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                            dataTestId='opportunity-location-types'
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            multiple
                            placeholder='Time Zone'
                            filterName='timeZones'
                            options={filtersOptions.timeZones}
                            currentValues={selectedFilters.timeZones}
                            onSelectedValuesChange={onSelectFilterChange}
                        />
                    </FilterDoubleColumn>
                    <div
                        css={css`
                            flex-basis: 100%;
                            height: 0;

                            ${mqMax[2]} {
                                display: none;
                            }
                        `}
                    />
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            multiple
                            placeholder='Skills'
                            filterName='skills'
                            options={filtersOptions.skills}
                            currentValues={selectedFilters.skills}
                            onSelectedValuesChange={onSelectFilterChange}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            placeholder='Project Industry'
                            filterName='industries'
                            options={filtersOptions.industries}
                            currentValues={selectedFilters.industries}
                            onSelectedValuesChange={onSelectFilterChange}
                            multiple
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <RangeSelection
                            placeholder='Experience'
                            label='Min Experience (In Years)'
                            min={filtersOptions.minMinExperienceInYears}
                            max={filtersOptions.maxMinExperienceInYears}
                            onRangeChange={onExperienceRangeFilterChange}
                            selectedMin={selectedFilters.minMinExperienceInYears}
                            selectedMax={selectedFilters.maxMinExperienceInYears}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            canClear={true}
                            placeholder='Currency'
                            filterName='currency'
                            options={filtersOptions.currencies}
                            currentValues={[selectedFilters.currency]}
                            onSelectedValuesChange={onValueChange}
                        />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <Input name='minRate' placeholder='Min Rate' value={minRateValue} type='number' onChange={handleMinRate} />
                    </FilterDoubleColumn>
                    <FilterDoubleColumn width={15}>
                        <AutocompleteSelect
                            canFilter={false}
                            placeholder='Show All Opportunities'
                            filterName='responseType'
                            options={Object.keys(responseTypeOptions)}
                            labelTransformer={(opt: string) => responseTypeOptions[opt]}
                            selectedLabelTransformer={(opt: string) => responseTypeOptions[opt]}
                            currentValues={[selectedFilters.responseType]}
                            onSelectedValuesChange={onValueChange}
                        />
                    </FilterDoubleColumn>
                    {isAllowedToSMECloudApp && (
                        <FilterDoubleColumn width={15}>
                            <AutocompleteSelect
                                canFilter={false}
                                multiple
                                placeholder='Visibility'
                                filterName='visibilityValues'
                                options={filtersOptions.visibilityValues}
                                labelTransformer={(opt: string) => visibilityTypeOptions[opt]}
                                selectedLabelTransformer={(opt: string) => visibilityTypeOptions[opt]}
                                currentValues={selectedFilters.visibilityValues}
                                onSelectedValuesChange={onSelectFilterChange}
                            />
                        </FilterDoubleColumn>
                    )}
                </FlexBox>
                <FlexBox
                    css={css`
                        flex-grow: 1;
                        margin: 0 0 16px auto;
                    `}
                >
                    <FiltersClearTrigger onClear={onClearAll} quantity={filtersCount} />
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { OpenOpportunitiesFiltersBar }
