/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { mqMax, mqMin } from '../../GlobalStyle'
import { Button } from '../Button'
import { AppIcon } from '../Icon'

type FeedbackExpiredProps = {
    backButtonText: string
    backButtonPath: string
}

const FeedbackExpired: FunctionComponent<React.PropsWithChildren<FeedbackExpiredProps>> = ({ backButtonText, backButtonPath }) => {
    const navigate = useNavigate()

    const onBackButtonClick = useCallback(() => {
        navigate(backButtonPath)
    }, [navigate, backButtonPath])

    return (
        <div
            css={css`
                display: flex;
                box-sizing: border-box;
                flex-direction: column;
                height: calc(100vh - 57px);
                padding: 24px 10%;
            `}
        >
            <div
                css={css`
                    max-width: 560px;
                    margin: auto;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    ${mqMin[1]} {
                        padding-bottom: 88px;
                    }
                `}
            >
                <AppIcon
                    status='static'
                    name='sad'
                    size={100}
                    css={css`
                        margin: 0 auto 32px auto;
                    `}
                />
                <h3
                    css={css`
                        margin-bottom: 30px;
                    `}
                >
                    This Booking Request Has Been Closed
                </h3>

                <p
                    css={css`
                        max-width: 540px;
                    `}
                >
                    We’re sorry but the the time period for you to share feedback about the status of this Booking Request has expired and
                    we have officially closed this Booking Request. Thanks!
                </p>

                <Button
                    variant='primary'
                    onClick={onBackButtonClick}
                    style={css`
                        margin-top: 18px;
                        width: 213px;

                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    {backButtonText}
                </Button>
            </div>
        </div>
    )
}

export { FeedbackExpired }
