/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useContext, useEffect, useState } from 'react'
import { getSpecialist } from '../../api/api'
import { Divider } from '../../components/Divider'
import { LayoutWithStickyColumn } from '../../components/layout/LayoutWithStickyColumn'
import { PageWidthWrapper } from '../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../components/layout/SpecialistPageWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Specialist } from '../../contracts/specialist/specialist'
import { mqMax } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getSpecialistTasks } from './api'
import { InfoBox } from './components/InfoBox'
import { TaskList } from './components/TaskList'
import { TaskType } from './contracts'

const SpecialistDashboard = () => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const log = useLogger('error')

    const [tasks, setTasks] = useState<Nullable<Array<TaskType>>>(null)
    const [specialist, setSpecialist] = useState<Nullable<Specialist | undefined>>(null)
    const { addError } = useNotifications()

    useEffect(() => {
        if (decodedAccessToken.specialistId) {
            getSpecialistTasks(decodedAccessToken.specialistId)
                .then(setTasks)
                .catch(err => {
                    log(err)
                    addError()
                    setTasks([])
                })

            getSpecialist(decodedAccessToken.specialistId)
                .then(setSpecialist)
                .catch(err => {
                    log(err)
                    addError()
                    setSpecialist(undefined)
                })
        }
    }, [decodedAccessToken.specialistId, log, addError])

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;

                ${mqMax[1]} {
                    margin-top: 0;
                }
            `}
        >
            <PageWidthWrapper
                style={css`
                    padding: 24px 0;

                    ${mqMax[1]} {
                        margin-top: 57px;
                    }
                `}
            >
                <h3>
                    {specialist?.firstName} {specialist?.lastName}
                </h3>
                <Divider />
            </PageWidthWrapper>
            <LayoutWithStickyColumn
                dataTestId='specialist-dashboard'
                firstCol={<TaskList specialist={specialist} tasks={tasks} />}
                secondCol={<InfoBox />}
                keepOrder
            />
        </SpecialistPageWrapper>
    )
}

export { SpecialistDashboard }
