/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { getCities } from '../../../api/api'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../components/forms/ControlledAutocompleteSelect'
import { ControlledDatePickerInput } from '../../../components/forms/ControlledDatePickerInput'
import { ControlledInput } from '../../../components/forms/ControlledInput'
import { ControlledPhoneInput } from '../../../components/forms/ControlledPhoneInput'
import { ControlledSuggestionInput } from '../../../components/forms/ControlledSuggestionInput'
import { Icon } from '../../../components/Icon'
import { InfoAlert } from '../../../components/InfoAlert'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { CustomFieldValue } from '../../../contracts/customFields'
import { Gender, genderOptions } from '../../../contracts/gender'
import { SocialLink } from '../../../contracts/socialLink'
import { mapEmploymentToType, mapEmploymentType } from '../../../contracts/specialist/specialist'
import { useCountries } from '../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { hasErrors } from '../../../utils/errors'
import { phoneRegex, websiteRegex } from '../../../utils/regexes'
import { useLogger } from '../../../utils/useLogger'
import { INVALID_DATE_FORMAT, INVALID_URL, PHONE_NOT_VALID } from '../../../validation/validation-messages'
import { yup } from '../../../validation/yup'
import { getSpecialistProfileInformationV2, updateSpecialistProfileInformationV2 } from '../api'
import { PersonalInformation } from '../contracts'

type PersonalInformationFormProps = {
    personalInformation: PersonalInformation
    onSubmit: (data: PersonalInformation) => void
    onCancel: () => void
}

const getDefaultValues = (personalInformation: PersonalInformation) => {
    const socialLinks: Array<SocialLink> = []
    if (personalInformation.socialLinks) {
        const specialKeys = ['Linkedin', 'Github']
        specialKeys.forEach(label => {
            const linkValue = personalInformation.socialLinks.find(link => link.label === label)
            socialLinks.push({ label, url: linkValue?.url || '' })
        })
        if (Array.isArray(personalInformation.socialLinks)) {
            personalInformation.socialLinks
                .filter(link => !specialKeys.includes((link as any).label))
                .forEach(link => socialLinks.push({ label: link.label || '', url: link.url || '' }))
        }
    }

    const customFields: Array<CustomFieldValue> = (personalInformation.customFields || []).map(customField => ({
        fieldName: customField.fieldName,
        value: customField.value || '',
    }))

    return {
        birthDate: personalInformation.birthDate || 0,
        city: personalInformation.city || '',
        country: personalInformation.country || '',
        customFields,
        email: personalInformation.email || '',
        employmentType: mapEmploymentType(personalInformation.employmentType) || '',
        firstName: personalInformation.firstName || '',
        gender: personalInformation.gender || ('' as Gender),
        id: personalInformation.id,
        lastName: personalInformation.lastName || '',
        phone: personalInformation.phone || '',
        postalCode: personalInformation.postalCode || '',
        socialLinks,
        street: personalInformation.street || '',
    }
}

const prepareRequestData = (data: PersonalInformation) => {
    const socialLinks = data.socialLinks.filter(socialLink => socialLink.url)
    const employmentType = mapEmploymentToType(data.employmentType)
    return { ...data, socialLinks, employmentType }
}

const PersonalInformationForm: FunctionComponent<React.PropsWithChildren<PersonalInformationFormProps>> = ({
    personalInformation,
    onSubmit,
    onCancel,
}) => {
    const theme = useTheme()
    const {
        selectors: {
            featureFlags: { optionalSpecialistEmail },
        },
    } = useContext(ReduxContext)
    const schema = useMemo(
        () =>
            yup.object().shape({
                firstName: yup.string().required(),
                lastName: yup.string().required(),
                customFields: yup.array().of(yup.object().shape({ fieldName: yup.string().required(), value: yup.string() })),
                email: optionalSpecialistEmail ? yup.string().email() : yup.string().email().required(),
                employmentType: yup.string(),
                country: yup.string(),
                city: yup.string(),
                gender: yup.string(),
                birthDate: yup.mixed().test({
                    message: INVALID_DATE_FORMAT,
                    test: (value: any) => value === '' || value === null || yup.number().isValidSync(value),
                }),
                phone: yup.lazy(value => (value === '' ? yup.string() : yup.string().matches(phoneRegex, PHONE_NOT_VALID))),
                postalCode: yup.string(),
                street: yup.string(),
                socialLinks: yup.array(
                    yup.object().shape({
                        url: yup.lazy(value =>
                            value === ''
                                ? yup.string()
                                : yup.string().matches(websiteRegex, { message: INVALID_URL, excludeEmptyString: true }),
                        ),
                        label: yup.string(),
                    }),
                ),
            }),
        [optionalSpecialistEmail],
    )

    const {
        control,
        watch,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors, touchedFields, isDirty },
    } = useForm<PersonalInformation>({
        defaultValues: getDefaultValues(personalInformation),
        resolver: yupResolver(schema),
    })

    const { fields: customFields } = useFieldArray({
        control,
        name: 'customFields',
    })

    const {
        fields: socialLinks,
        append: appendSocialLink,
        remove: removeSocialLink,
    } = useFieldArray({
        control,
        name: 'socialLinks',
    })

    const watchCountry = watch('country')
    const countriesDictionary = useCountries()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const getCitiesForCountry = useCallback((filter: string) => getCities(watchCountry, filter), [watchCountry])
    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h5>Personal Information</h5>
            <InfoAlert
                description='Personal informations are visible only internally'
                styles={css`
                    background-color: ${theme.colors.beige_2};
                    margin-bottom: 32px;
                `}
            />
            <h6>General Information (visible internally)</h6>
            <FieldWrapper>
                <ControlledInput name='firstName' control={control} label='First name' placeholder='First name' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput name='lastName' control={control} label='Last name' placeholder='Last name' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput
                    name='email'
                    control={control}
                    label={`E-mail${optionalSpecialistEmail ? ' (optional)' : ''}`}
                    placeholder='E-mail'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    name='employmentType'
                    placeholder='Choose type'
                    control={control}
                    label='Employment Status (optional)'
                    options={['B2B', 'Candidate', 'Contractor', 'Full Time Employee']}
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            {customFields && !!customFields.length && (
                <Fragment>
                    <h6>Additional Information (optional)</h6>
                    {customFields.map((customField, index) => (
                        <FieldWrapper key={customField.id}>
                            <ControlledInput
                                control={control}
                                name={`customFields.${index}.value`}
                                placeholder={customField.fieldName}
                                label={customField.fieldName}
                                defaultValue={customField.value}
                                shouldUnregister={false}
                            />
                        </FieldWrapper>
                    ))}
                </Fragment>
            )}
            <h6>Personal Information (optional)</h6>
            <FieldWrapper>
                <ControlledPhoneInput
                    control={control}
                    name='phone'
                    label='Phone'
                    placeholder='Phone number'
                    countryAreaCodes={countriesDictionary || []}
                />
            </FieldWrapper>
            <FieldWrapper
                css={css`
                    position: relative;
                `}
            >
                <ControlledDatePickerInput control={control} name='birthDate' label='Birth Date' placeholder='DD.MM.YYYY' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    name='gender'
                    control={control}
                    label='Gender'
                    options={genderOptions}
                    placeholder='Choose gender'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            <h6>Home Address (optional)</h6>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    label='Country'
                    placeholder='Choose country'
                    options={memorizedCountries}
                    control={control}
                    name='country'
                    canClear
                    dropdownWidth='100%'
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledSuggestionInput
                    label='City:'
                    placeholder='Choose city'
                    fetchOptions={getCitiesForCountry}
                    disabled={!watchCountry}
                    control={control}
                    name='city'
                    allowOnlyValuesFromDictionary
                    fetchFromLetterNumber={1}
                    dataTestId='company-details-form-city'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput name='street' control={control} label='Street No.' placeholder='10 Downing Street' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput name='postalCode' control={control} label='Postal' placeholder='Postal' />
            </FieldWrapper>
            <h6>Social Links (optional)</h6>
            {socialLinks.map((socialLink, index) => {
                return (
                    <FieldWrapper
                        key={socialLink.id}
                        css={css`
                            position: relative;
                        `}
                    >
                        <ControlledInput
                            control={control}
                            name={`socialLinks.${index}.url`}
                            placeholder=''
                            label={socialLink.label}
                            defaultValue={socialLink.url}
                            shouldUnregister={false}
                        />
                        {index > 1 && (
                            <Icon
                                style={css`
                                    position: absolute;
                                    top: 9px;
                                    right: -30px;

                                    &:hover {
                                        opacity: 0.5;
                                        cursor: pointer;
                                    }
                                `}
                                name='small-close'
                                size={24}
                                onClick={() => removeSocialLink(index)}
                            />
                        )}
                    </FieldWrapper>
                )
            })}

            <Button variant='linkForm' onClick={() => appendSocialLink({ label: '', url: '' })} dataTestId='another-link'>
                + Add One More
            </Button>

            <Divider />
            <Button
                variant='link'
                style={css`
                    margin-right: 24px;
                `}
                onClick={onCancel}
            >
                Cancel
            </Button>

            <Button variant='primary' type='submit' disabled={!isDirty || hasErrors(errors)}>
                Save Details
            </Button>
        </form>
    )
}

type PersonalInformationSectionProps = { specialistId: string; closeSideBox: () => void }

const PersonalInformationSection: FunctionComponent<React.PropsWithChildren<PersonalInformationSectionProps>> = ({
    specialistId,
    closeSideBox,
}) => {
    const [personalInformation, setPersonalInformation] = useState<Nullable<PersonalInformation>>(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const log = useLogger('error')
    const { addError } = useNotifications()

    useEffect(() => {
        getSpecialistProfileInformationV2(specialistId)
            .then(setPersonalInformation)
            .catch(err => {
                log(err)
                addError()
            })
    }, [addError, log, specialistId])

    const handleSubmit = useCallback(
        (data: PersonalInformation) => {
            setIsSubmitting(true)
            updateSpecialistProfileInformationV2(specialistId, prepareRequestData(data))
                .then(setPersonalInformation)
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    setIsSubmitting(false)
                    closeSideBox()
                })
        },
        [addError, closeSideBox, log, specialistId],
    )

    const handleCancel = useCallback(() => {
        closeSideBox()
    }, [closeSideBox])

    return isSubmitting || !personalInformation ? (
        <OverlayContentLoader />
    ) : (
        <PersonalInformationForm personalInformation={personalInformation} onSubmit={handleSubmit} onCancel={handleCancel} />
    )
}

export { PersonalInformationSection }
