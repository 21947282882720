import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useState } from 'react'
import { ArrayPath, FieldArray, FieldValues, useFieldArray, useForm } from 'react-hook-form'
import { yup } from 'src/validation/yup'

type UseInlineEditProps<T extends FieldValues> = {
    schema?: yup.ObjectSchema<any>
    fieldArrayName: ArrayPath<T>
}

const useInlineEdit = <T extends FieldValues>({ schema, fieldArrayName }: UseInlineEditProps<T>) => {
    const {
        control,
        trigger,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm<T>({
        mode: 'onBlur',
        resolver: schema ? yupResolver(schema) : undefined,
    })
    const { append, remove } = useFieldArray({
        control,
        name: fieldArrayName,
    })

    const [editingKeys, setEditingKeys] = useState<Array<string>>([])

    const getIndex = useCallback((id: string) => editingKeys.indexOf(id), [editingKeys])
    const isEditing = useCallback((id: string) => editingKeys.includes(id), [editingKeys])

    const handleEditingKeysChange = useCallback(
        (id: string) => {
            if (editingKeys.includes(id)) {
                setEditingKeys(editingKeys.filter((key: string) => key !== id))
                remove(getIndex(id))
            } else {
                setEditingKeys([...editingKeys, id])
                append({} as FieldArray<T, ArrayPath<T>>)
            }
        },
        [editingKeys, remove, getIndex, append],
    )

    return { handleEditingKeysChange, isEditing, getIndex, trigger, getValues, setValue, watch, errors, control }
}

export { useInlineEdit }
