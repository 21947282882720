import { CompanyAccessLevelGuard } from './components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from './components/CompanyStatusGuard'

const PrivateHumanCloud = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/private-human-cloud/form' />
    </CompanyAccessLevelGuard>
)

export { PrivateHumanCloud }
