/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { Icon } from '../../Icon'

const ContextMenuItem = styled.li`
    padding: 8px;
    position: relative;
`

const MenuDivider = styled.hr`
    height: 1px;
    background-color: ${COLOR_PALETTE.gray_2};
    display: block;
    width: 100%;
    border: 0;
`

type ItemType = {
    title: string
    path?: string
    showDivider?: boolean
    isExternal?: boolean
    dataTestId?: string
    isHighlighted?: boolean
}

type ContextMenuProps = {
    items: Array<ItemType>
    styles?: SerializedStyles
}

const ContextMenu: FunctionComponent<React.PropsWithChildren<ContextMenuProps>> = ({ items, styles = {} }) => {
    const theme = useTheme()

    return (
        <Fragment>
            <section
                css={css`
                    position: fixed;
                    background: transparent;
                    width: 100%;
                    height: 100%;
                    top: 57px;
                    left: 0;
                    z-index: 99;
                `}
            ></section>
            <ul
                css={css`
                    list-style: none;
                    padding: 8px 16px;
                    margin: 0;
                    border: 1px solid ${COLOR_PALETTE.gray_2};
                    box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                    border-radius: 2px;
                    position: absolute;
                    right: 10px;
                    top: 42px;
                    min-width: 200px;
                    max-width: 300px;
                    background-color: ${COLOR_PALETTE.white};
                    z-index: 100;
                    max-height: calc(100vh - 42px);
                    overflow-y: scroll;

                    ${styles}
                `}
            >
                {items
                    .filter(({ title }) => Boolean(title))
                    .map((item, index) => (
                        <Fragment key={index}>
                            <ContextMenuItem
                                css={() =>
                                    item.isHighlighted &&
                                    css`
                                        &:before {
                                            content: '';
                                            display: block;
                                            position: absolute;
                                            left: -16px;
                                            top: 0;
                                            height: 100%;
                                            width: 3px;
                                            background-color: ${theme.colors.main};
                                        }
                                    `
                                }
                            >
                                {item.path ? (
                                    <Link to={item.path} target={item.isExternal ? '_blank' : undefined} data-testid={item.dataTestId}>
                                        {item.title}
                                    </Link>
                                ) : (
                                    <div
                                        css={css`
                                            color: ${COLOR_PALETTE.gray_4};
                                            overflow: hidden;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        `}
                                    >
                                        {item.title}
                                    </div>
                                )}
                                {item.isExternal && (
                                    <Icon
                                        style={css`
                                            position: absolute;
                                            top: 9px;
                                            right: 5px;
                                        `}
                                        name='external'
                                    />
                                )}
                            </ContextMenuItem>
                            {item.showDivider && <MenuDivider />}
                        </Fragment>
                    ))}
            </ul>
        </Fragment>
    )
}

export { ContextMenu }
