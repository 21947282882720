/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import {
    ChangeEvent,
    Fragment,
    FunctionComponent,
    KeyboardEvent,
    MouseEvent,
    MutableRefObject,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { FixedSizeList } from 'react-window'
import { mqMin } from '../../../GlobalStyle'
import { Nullable } from '../../../types'
import { distinctFilter } from '../../../utils/filters'
import { InputField, InputFieldProps } from '../input-field/InputField'
import { DropdownComponent, DropdownContainer, DropdownDivider } from './DropdownComponent'
import { DropdownFooter } from './DropdownFooter'
import { FilterBox } from './FilterBox'
import { Option, OptionType } from './Option'
import { OptionVirtualized } from './OptionVirtualized'
import { SelectDivider } from './styled'
import { calculateDisplayedValues } from './utils'

export type AutoCompleteSelectBaseProps = InputFieldProps & {
    rangeRef?: MutableRefObject<HTMLDivElement>
    isOpen?: boolean
    options: Nullable<Array<OptionType>>
    filterName?: string
    setIsOpen?: (isOpen: boolean) => void
    placeholder?: string
    canFilter?: boolean
    onSelectedValuesChange?: (values: Array<OptionType>, filterName: string) => void
    dropdownWidth?: string
    dropdownHeight?: string | number
    selectWidth?: string
    currentValues?: Nullable<Array<OptionType>>
    disabled?: boolean
    multiple?: boolean
    selectedLabelTransformer?: (opt: any) => string
    labelTransformer?: (opt: any) => string
    searchTransformer?: (opt: any) => string
    defaultValues?: Array<OptionType>
    name?: string
    virtualized?: boolean
    innerRef?: any
    isActive?: boolean
    dataTestId?: string
    canClear?: boolean
    onBlur: () => void
    dropdownStyles?: SerializedStyles
    styles?: SerializedStyles
    forceDownOpen?: boolean
    buttonRef?: RefObject<HTMLButtonElement>
    applyButtonText?: string
}

export const AutocompleteSelectBase: FunctionComponent<React.PropsWithChildren<AutoCompleteSelectBaseProps>> = ({
    placeholder = '',
    options,
    filterName = '',
    canFilter = true,
    onSelectedValuesChange = () => null,
    dropdownWidth,
    dropdownHeight,
    selectWidth,
    currentValues = null,
    rangeRef,
    isOpen,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    setIsOpen,
    disabled = false,
    multiple = false,
    selectedLabelTransformer = opt => opt,
    labelTransformer = selectedLabelTransformer,
    searchTransformer = labelTransformer,
    label,
    labelTooltip,
    defaultValues,
    innerRef,
    name,
    virtualized,
    isActive,
    dataTestId = '',
    canClear = false,
    onBlur = () => null,
    dropdownStyles = '',
    forceDownOpen = false,
    styles,
    buttonRef,
    applyButtonText,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement | any>()
    const [filteredOptions, setFilteredOptions] = useState<Array<OptionType>>([])
    const [selectedValues, setSelectedValues] = useState(currentValues || defaultValues || [])
    const [newValues, setNewValues] = useState(currentValues || [])
    const [hasChanged, setHasChanged] = useState(false)
    const [upperValues, setUpperValues] = useState<Array<OptionType>>([])
    const [noneSelectedValues, setNoneSelectedValues] = useState<Array<OptionType>>([])
    const [isTyping, setIsTyping] = useState(false)
    const [dataTestIdDefault, setDataTestIdDefault] = useState('')

    useEffect(() => {
        setDataTestIdDefault(dataTestId !== '' ? dataTestId : name || '')
    }, [dataTestId, name])

    useEffect(() => {
        setSelectedValues(currentValues || [])
        setNewValues(currentValues || [])
        setUpperValues(currentValues || [])
    }, [currentValues])

    useEffect(() => {
        if (Array.isArray(options)) {
            setFilteredOptions(options)
            setNoneSelectedValues(options)
        }
    }, [options])

    const onSearching = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target
            setIsTyping(inputRef.current.value.length > 0)

            if (Array.isArray(options)) {
                const newFilteredOptions = options?.filter(option => searchTransformer(option).toLowerCase().includes(value?.toLowerCase()))
                setFilteredOptions(newFilteredOptions)
            }
        },
        [options, searchTransformer],
    )
    const onFilterBoxClick = useCallback(() => {
        if (!disabled) {
            if (setIsOpen) {
                setIsOpen(!isOpen)
            }
            if (!isOpen && Array.isArray(options)) {
                const leftValues = options?.filter(
                    item => !selectedValues.map(value => JSON.stringify(value)).includes(JSON.stringify(item)),
                )
                setNoneSelectedValues(leftValues)
                setNewValues(selectedValues)
                setFilteredOptions(options)
                setHasChanged(true)
                setIsTyping(false)
            }
        }
    }, [disabled, isOpen, options, selectedValues, setIsOpen])

    const onFilterBoxClear = useCallback(() => {
        setSelectedValues([])
        setNoneSelectedValues([])
        onSelectedValuesChange([], filterName)
        setHasChanged(true)
        onBlur()
    }, [onSelectedValuesChange, filterName, onBlur])

    const onClearAll = useCallback(() => {
        setNewValues([])
        setUpperValues([])
        setSelectedValues([])
        setNoneSelectedValues([])
        onSelectedValuesChange([], filterName)
        setHasChanged(true)
        setIsTyping(false)
    }, [filterName, onSelectedValuesChange])

    const onApply = useCallback(() => {
        if (hasChanged && Array.isArray(options)) {
            const newCurrentValues = newValues.filter(distinctFilter)
            const leftValues = options?.filter(item => !newCurrentValues.map(value => JSON.stringify(value)).includes(JSON.stringify(item)))
            setNoneSelectedValues(leftValues)
            setUpperValues(newValues)
            setSelectedValues(newCurrentValues)
            onSelectedValuesChange(newCurrentValues, filterName)
            if (setIsOpen) {
                setIsOpen(false)
            }
            setIsTyping(false)
            onBlur()
        }
    }, [filterName, newValues, onSelectedValuesChange, options, setIsOpen, hasChanged, onBlur])

    const selectOption = useCallback(
        (event: OptionType) => {
            const newOptions = multiple ? [...newValues] : []
            newOptions.push(event)
            const values = newOptions.filter(distinctFilter)
            setNewValues(values)

            if (!multiple) {
                setSelectedValues(values)
                onSelectedValuesChange(values, filterName)
                if (setIsOpen) {
                    setIsOpen(false)
                }
                onBlur()
            }
        },
        [filterName, multiple, newValues, onSelectedValuesChange, setIsOpen, onBlur],
    )

    const deselectOption = useCallback(
        (event: OptionType) => {
            const newOptions = [...newValues]
            setNewValues(newOptions.filter(option => JSON.stringify(option) !== JSON.stringify(event)))
        },
        [newValues],
    )
    const isOptionChecked = useCallback(
        (option: OptionType): boolean => newValues.map(value => JSON.stringify(value)).includes(JSON.stringify(option)),
        [newValues],
    )

    const onOptionClick = useCallback(
        (option: OptionType) => {
            if (isOptionChecked(option)) {
                if (multiple) {
                    deselectOption(option)
                } else {
                    selectOption(option)
                }
            } else {
                selectOption(option)
            }
            setHasChanged(true)
        },
        [deselectOption, isOptionChecked, multiple, selectOption],
    )

    const displayedValue = useMemo(
        () =>
            (values: Array<any>): string => {
                if (values?.length === 1) {
                    return selectedLabelTransformer(values[0])
                }

                if (values?.length > 1 && rangeRef) {
                    return calculateDisplayedValues({
                        values,
                        width: rangeRef?.current?.clientWidth - 70, // 70px is sum of padding, margin & arrow
                        transformer: selectedLabelTransformer,
                    })
                }

                return ''
            },
        [rangeRef, selectedLabelTransformer],
    )

    const renderOptions = useCallback(
        (optionsToRender: Array<OptionType>, shouldRenderGroupNames = false) =>
            optionsToRender?.map((option, index) => (
                <Option
                    key={`${index}_k_${labelTransformer(option)}`}
                    option={option}
                    previousOption={optionsToRender[index - 1]}
                    index={index}
                    labelTransformer={labelTransformer}
                    onOptionClick={onOptionClick}
                    multiple={multiple}
                    isOptionChecked={isOptionChecked}
                    dataTestIdDefault={dataTestIdDefault}
                    shouldRenderGroupNames={shouldRenderGroupNames}
                />
            )),
        [dataTestIdDefault, isOptionChecked, labelTransformer, multiple, onOptionClick],
    )

    const handleWrapperClick = useCallback((e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])

    const handleKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                if (setIsOpen) {
                    setIsOpen(false)
                }
            }
        },
        [setIsOpen],
    )

    const theme = useTheme()

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                display: block;
                flex: 1;
                ${styles}
            `}
            onClick={handleWrapperClick}
            onKeyDown={handleKeyDown}
            {...rest}
        >
            {innerRef && (
                <input
                    hidden
                    name={name}
                    ref={innerRef}
                    value={selectedValues as any}
                    onChange={() => null}
                    data-testid={dataTestIdDefault}
                />
            )}
            <InputField
                label={label}
                labelTooltip={labelTooltip}
                errorMessage={errorMessage}
                errorTooltipMessage={errorTooltipMessage}
                infoMessage={infoMessage}
                dataTestId={dataTestId || name}
            >
                <FilterBox
                    dataTestId={`filter-${dataTestIdDefault}`}
                    onClick={onFilterBoxClick}
                    placeholder={placeholder}
                    displayedValue={displayedValue(selectedValues)}
                    onClear={!disabled && canClear && selectedValues.length > 0 ? onFilterBoxClear : undefined}
                    styles={css`
                        width: ${selectWidth};
                        ${disabled ? `background-color: ${theme.colors.gray_1};` : ''}
                        ${disabled ? `color: ${theme.colors.gray_3};` : ''}
                    ${errorMessage ? `border: 1px solid ${theme.colors.red_4};` : ''}
                    ${isActive || isOpen ? `border: 1px solid ${theme.colors.gray_3};` : ''}
                    `}
                    forwardedRef={buttonRef}
                />
            </InputField>

            {isOpen && (
                <DropdownContainer
                    noLabel={!label}
                    forceDownOpen={forceDownOpen}
                    styles={css`
                        min-width: ${dropdownWidth};
                        background-color: ${theme.colors.white};
                        ${mqMin[1]} {
                            min-width: max(100%, ${dropdownWidth});
                        }
                        ${dropdownStyles}
                    `}
                >
                    {canFilter && (
                        <div
                            css={css`
                                display: flex;
                                border-bottom: 1px solid ${theme.colors.gray_2};
                                position: relative;
                            `}
                        >
                            <input
                                placeholder='Start typing'
                                type='text'
                                ref={inputRef}
                                autoFocus
                                onChange={onSearching}
                                css={css`
                                    width: 100%;
                                    box-shadow: none;
                                    padding: 16px;
                                    font-size: 14px;
                                    line-height: 130%;
                                    border: 0;
                                    color: ${theme.colors.gray_3};
                                `}
                                autoComplete='off'
                                data-testid={`filter-${dataTestIdDefault}-input`}
                            />
                        </div>
                    )}
                    {virtualized && (
                        <Fragment>
                            {!isTyping && noneSelectedValues.length > 0 ? (
                                <FixedSizeList
                                    itemData={{
                                        options: upperValues.concat(noneSelectedValues),
                                        onOptionClick,
                                        isOptionChecked,
                                        multiple,
                                        labelTransformer,
                                        checkedArraylength: upperValues.length,
                                        dataTestId: `${dataTestIdDefault}-item`,
                                    }}
                                    height={dropdownHeight || 220}
                                    itemCount={upperValues.concat(noneSelectedValues).length}
                                    itemSize={40}
                                    width='100%'
                                >
                                    {OptionVirtualized}
                                </FixedSizeList>
                            ) : (
                                <FixedSizeList
                                    itemData={{
                                        options: filteredOptions,
                                        onOptionClick,
                                        isOptionChecked,
                                        multiple,
                                        labelTransformer,
                                        dataTestId: `${dataTestIdDefault}-item`,
                                    }}
                                    height={dropdownHeight || 220}
                                    itemCount={filteredOptions.length}
                                    itemSize={40}
                                    width='100%'
                                >
                                    {OptionVirtualized}
                                </FixedSizeList>
                            )}
                        </Fragment>
                    )}
                    {!virtualized && (
                        <div
                            css={css`
                                max-height: ${dropdownHeight || '220px'};
                                overflow-y: auto;
                            `}
                        >
                            {upperValues.length > 0 && !isTyping && multiple && upperValues.length !== filteredOptions.length && (
                                <Fragment>
                                    {renderOptions(upperValues)}
                                    <SelectDivider />
                                </Fragment>
                            )}

                            {!(isTyping || !multiple) && noneSelectedValues.length > 0
                                ? renderOptions(noneSelectedValues, true)
                                : renderOptions(filteredOptions, true)}
                        </div>
                    )}
                    {multiple && (
                        <Fragment>
                            <DropdownDivider />
                            <DropdownFooter
                                onApply={onApply}
                                onClearAll={onClearAll}
                                buttonsDisabled={!hasChanged}
                                dataTestId={dataTestIdDefault}
                                applyButtonText={applyButtonText}
                            />
                        </Fragment>
                    )}
                </DropdownContainer>
            )}
        </div>
    )
}

const AutocompleteSelect = DropdownComponent(AutocompleteSelectBase)

export { AutocompleteSelect }
