import { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { getSpecialistCvParsingStatus, removeSpecialistCv } from '../api/api'
import { axios } from '../api/axios'
import { useNotifications } from '../components/notification/NotificationProvider'
import { SkillProficiency, SpecialistCv } from '../contracts/cvs'
import { Skill, SkillStatus, SkillUpdateBody, SpecialistSkills } from '../contracts/skills'
import { history } from '../history'
import { ReduxContext } from '../redux/Store'
import { Nullable } from '../types'
import { useLogger } from '../utils/useLogger'

const specialistsEndpoint = `/specialists`

const addSkillsToSpecialistParsedSkills = (cvParsingId: string, names: Array<string>): Promise<SpecialistSkills> => {
    return axios.post(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills:batchCreate`, { names })
}

const confirmExtractedData = (cvParsingId: string): Promise<void> => {
    return axios.post(`/v2${specialistsEndpoint}/me/cvs/${cvParsingId}:confirmOwnData`)
}

const getSpecialistParsedSkills = (cvParsingId: string): Promise<SpecialistSkills> => {
    return axios.get(`/v2${specialistsEndpoint}/-/cvs/${cvParsingId}/skills`)
}

const markSpecialistParsedSkillAsDeleted = (cvParsingId: string, skillId: string): Promise<SpecialistSkills> => {
    return axios.post(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills/${skillId}:markAsDeleted`)
}

const markSpecialistParsedSkillAsNotValid = (cvParsingId: string, skillId: string): Promise<SpecialistSkills> => {
    return axios.post(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills/${skillId}:markAsNotValid`)
}

const resetSpecialistParsedSkill = (cvParsingId: string, skillId: string): Promise<SpecialistSkills> => {
    return axios.post(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills/${skillId}:undo`)
}
const updateSpecialistParsedSkill = (cvParsingId: string, data: SkillUpdateBody): Promise<SpecialistSkills> => {
    return axios.put(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills`, data)
}

type useSpecialistCvParsingArgs = { specialistId?: string; cvParsingId?: string; redirectUrl: string; successNotification: ReactNode }

const useSpecialistCvParsing = ({ specialistId, cvParsingId, redirectUrl, successNotification }: useSpecialistCvParsingArgs) => {
    const [cvParsingStatus, setCvParsingStatus] = useState<Nullable<SpecialistCv>>(null)
    const { addError, addSuccess } = useNotifications()
    const log = useLogger('error')
    const [skills, setSkills] = useState<Nullable<SpecialistSkills>>(null)
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (specialistId) {
            getSpecialistCvParsingStatus(specialistId)
                .then(data => {
                    setCvParsingStatus(data.find(status => status.cvParsingId === cvParsingId) || null)
                })
                .catch(err => {
                    log(err)
                })
        }
    }, [cvParsingId, log, specialistId])

    useEffect(() => {
        if (cvParsingId) {
            layoutToggleLoader(true)
            getSpecialistParsedSkills(cvParsingId)
                .then(data => {
                    setSkills(data)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [cvParsingId, layoutToggleLoader, log, addError])

    const handleError = useCallback(
        (categoryIndex: any, skillIndex: any, oldSkill: any) => {
            setSkills(oldSkills => {
                const newSkills = { ...(oldSkills as SpecialistSkills) }
                newSkills.categories[categoryIndex].skills[skillIndex] = oldSkill
                return newSkills
            })
            addError()
        },
        [addError],
    )

    const handleSkillChange = useCallback(
        (newSkill: Skill, category?: string) => {
            if (skills) {
                const categoryIndex = skills.categories.findIndex(oldCategory => oldCategory.category === category)
                const skillIndex = skills.categories[categoryIndex].skills.findIndex(skill => skill.id === newSkill.id)
                const oldSkill = skills.categories[categoryIndex].skills[skillIndex]
                setSkills(oldSkills => {
                    const newSkills = { ...(oldSkills as SpecialistSkills) }
                    newSkills.categories[categoryIndex].skills[skillIndex] = newSkill
                    return newSkills
                })

                if (cvParsingId && newSkill.proficiency !== oldSkill.proficiency) {
                    updateSpecialistParsedSkill(cvParsingId, { id: newSkill.id, proficiency: newSkill.proficiency }).catch(() => {
                        handleError(categoryIndex, skillIndex, oldSkill)
                    })
                }

                if (cvParsingId && newSkill.status !== oldSkill.status) {
                    if (newSkill.status === SkillStatus.DELETED) {
                        markSpecialistParsedSkillAsDeleted(cvParsingId, newSkill.id).catch(() => {
                            handleError(categoryIndex, skillIndex, oldSkill)
                        })
                    }
                    if (newSkill.status === SkillStatus.NOT_VALID) {
                        markSpecialistParsedSkillAsNotValid(cvParsingId, newSkill.id).catch(() => {
                            handleError(categoryIndex, skillIndex, oldSkill)
                        })
                    }
                    if (newSkill.status === SkillStatus.IN_PROGRESS) {
                        resetSpecialistParsedSkill(cvParsingId, newSkill.id).catch(() => {
                            handleError(categoryIndex, skillIndex, oldSkill)
                        })
                    }
                }
            }
        },
        [skills, cvParsingId, handleError],
    )

    const handleSkillAdd = useCallback(
        (newSkills: any) => {
            if (cvParsingId) {
                layoutToggleLoader(true)
                addSkillsToSpecialistParsedSkills(cvParsingId, newSkills)
                    .then(setSkills)
                    .catch(() => {
                        addError()
                    })
                    .finally(() => layoutToggleLoader(false))
            }
        },
        [cvParsingId, addError, layoutToggleLoader],
    )

    const handleParsingConfirmation = useCallback(() => {
        if (cvParsingId) {
            layoutToggleLoader(true)
            confirmExtractedData(cvParsingId)
                .then(() => {
                    history.push(redirectUrl)
                    addSuccess(successNotification)
                })
                .catch(e => {
                    log(e)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [cvParsingId, layoutToggleLoader, redirectUrl, addSuccess, successNotification, log, addError])

    const areSomeSkillsNotRated = useMemo(
        () =>
            skills?.categories.some(category =>
                category.skills.some(skill => skill.status === SkillStatus.IN_PROGRESS && skill.proficiency === SkillProficiency.NOT_RATED),
            ),
        [skills],
    )

    const handleCancelProcess = useCallback(() => {
        if (cvParsingId && specialistId) {
            removeSpecialistCv(specialistId, cvParsingId)
                .then(() => history.push(redirectUrl))
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [addError, cvParsingId, layoutToggleLoader, log, redirectUrl, specialistId])

    return {
        cvParsingStatus,
        skills,
        handleSkillChange,
        handleSkillAdd,
        handleParsingConfirmation,
        areSomeSkillsNotRated,
        handleCancelProcess,
    }
}

export { useSpecialistCvParsing }
