/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../theme/colors'

type DotProps = {
    style?: SerializedStyles
}

const Dot: FunctionComponent<React.PropsWithChildren<DotProps>> = ({ style }) => (
    <span
        css={css`
            color: ${COLOR_PALETTE.gray_3};
            margin: 0 4px;
            display: inline-block;
            font-size: 10px;
            text-align: center;
            ${style}
        `}
    >
        &#9679;
    </span>
)

export { Dot }
