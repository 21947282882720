/** @jsxImportSource @emotion/react */
import { css, Theme } from '@emotion/react'
import { ColumnsType } from 'antd/lib/table'
import { Dispatch, FunctionComponent, SetStateAction, useCallback, useMemo } from 'react'
import { ResponsiveGrid } from '../../../components/data-grid/ResponsiveGrid'
import { ProcessingCv, ProcessingCvStatus } from '../../../contracts/cvs'
import { convertTimestampToDateString } from '../../../utils/dates'
import { useLogger } from '../../../utils/useLogger'
import { deleteProcessingCv } from '../api'
import { StatusRenderer } from './StatusRenderer'

type ProcessingCvsProps = {
    cvs: Array<ProcessingCv>
    onRemove: (id: string) => void
    shouldBeVisible: boolean
    setCurrentTab: Dispatch<SetStateAction<number>>
}

const ProcessingCvs: FunctionComponent<React.PropsWithChildren<ProcessingCvsProps>> = ({
    cvs,
    onRemove,
    shouldBeVisible,
    setCurrentTab,
}) => {
    const logError = useLogger('error')

    const onRemoveCv = useCallback(
        (id: any) => {
            deleteProcessingCv(id)
                .then(() => {
                    onRemove(id)
                })
                .catch(logError)
        },
        [logError, onRemove],
    )

    const columns = useMemo<ColumnsType<ProcessingCv>>(
        () => [
            {
                title: 'File Name',
                dataIndex: 'fileName',
            },
            {
                title: 'Uploaded On',
                dataIndex: 'uploadedOn',
                render: record => record && convertTimestampToDateString(record),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                render: (_, record) => (
                    <StatusRenderer
                        status={record.status}
                        errorMessage={record.errorMessage}
                        id={record.id}
                        onRemove={onRemoveCv}
                        setCurrentTab={setCurrentTab}
                    />
                ),
                width: 320,
            },
        ],
        [onRemoveCv, setCurrentTab],
    )

    const rowClassName = (record: ProcessingCv) => {
        let className = 'ant-table-row-blue'
        if (record.status === ProcessingCvStatus.ERROR) {
            className = 'ant-table-row-red'
        }
        if (record.status === ProcessingCvStatus.PARSING_FINISHED) {
            className = 'ant-table-row-green'
        }
        return className
    }

    const getBackgroundColor = (status: ProcessingCvStatus, theme: Theme) => {
        switch (status) {
            case ProcessingCvStatus.ERROR:
                return theme.colors.red_2

            case ProcessingCvStatus.PARSING_FINISHED:
                return theme.colors.green_1
            case ProcessingCvStatus.IN_PROGRESS:
                return theme.colors.blue_2
            default:
                return 'transparent'
        }
    }

    return (
        <ResponsiveGrid
            rowKey='id'
            dataSource={cvs}
            columns={columns}
            pagination={false}
            rowClassName={rowClassName}
            mobileItemStyles={(record: any, theme: Theme) =>
                css`
                    background-color: ${getBackgroundColor(record.status, theme)};
                `
            }
            wrapperStyle={css`
                margin: 0;
                max-width: none;
                display: ${shouldBeVisible ? 'block' : 'none'};
            `}
        />
    )
}

export { ProcessingCvs }
