/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { LanguageResponse } from '../../../contracts/profile/language'
import { COLOR_PALETTE } from '../../../GlobalStyle'

const LanguageName = styled.div`
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    margin-right: 8px;
    min-width: 60px;
    color: ${COLOR_PALETTE.gray_5};
    content: ${props => props.children as any};
`

const LanguageLevel = styled.div`
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    color: ${COLOR_PALETTE.gray_3};
    content: ${props => props.children as any};
`

type LanguageBoxProps = {
    language: LanguageResponse
}

const LanguageBox: FunctionComponent<React.PropsWithChildren<LanguageBoxProps>> = ({ language }) => {
    return (
        <FlexBox
            key={language.id}
            css={css`
                margin-top: 12px;
            `}
        >
            <LanguageName>{language.name}</LanguageName>
            <LanguageLevel>{language?.levelLabel ? language?.levelLabel : language.level}</LanguageLevel>
        </FlexBox>
    )
}

export { LanguageBox }
