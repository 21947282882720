import { PersonalStrengths } from '../../../contracts/personalStrengths'
import { MySpecialistsAnonymousEducation } from '../../../contracts/profile/education'
import { LanguageResponse } from '../../../contracts/profile/language'
import { Project } from '../../../contracts/profile/project'
import { VerifiedSkill } from '../../../contracts/profile/skill'
import { SkillMatrix } from '../../../contracts/profile/skillMatrix'
import { Specialist, SpecialistSummary } from '../../../contracts/specialist/specialist'
import { Education } from '../../../contracts/specialist/specialistEducations'
import { SpecialistProject } from '../../../contracts/specialist/specialistProject'
import { Nullable } from '../../../types'

const parseProfile = (
    specialist: Specialist,
    summary: SpecialistSummary,
    projects: Array<SpecialistProject>,
    education: Array<Education>,
    languages: Array<LanguageResponse>,
    techSkills: SkillMatrix,
    personalStrengths: Nullable<PersonalStrengths>,
    verifiedTopSkills?: Array<VerifiedSkill>,
) => {
    const parsedProjects = parseProjects(projects)

    return {
        savedProfile: false,
        secondaryRole: '',
        secondarySeniority: '',
        secondaryTopSkills: [],
        contactedAt: 0,
        specialistId: specialist.id || '',
        nickname: `${specialist.firstName} ${specialist.lastName}`,
        experience: summary.experience || '',
        primaryTopSkills: specialist.topSkills?.length ? specialist.topSkills : ['-'],
        recentProjects: [summary.recentProject || '-'],
        recentRoles: [summary.recentRole || '-'],
        recentIndustries: [summary.recentIndustry || '-'],
        role: specialist.role,
        seniority: specialist.seniority || '-',
        location:
            `${specialist.remote ? 'Remote; ' : ''}${specialist.country ? `${specialist.country} ` : ''}${
                specialist.city ? `(${specialist.city});` : ''
            }` || '-',
        languages,
        projects: parsedProjects,
        education: education as Array<MySpecialistsAnonymousEducation>,
        skillMatrix: techSkills,
        rate: specialist.rate,
        availableFrom: specialist.availableFrom,
        availableOnSyndicates: specialist.availableOnSyndicates,
        availableInPublicCloud: specialist.availableInPublicCloud,
        publicCloud: specialist.publicCloud,
        personalStrengths,
        verifiedTopSkills,
    }
}

const parseProjects = (projects: Array<SpecialistProject>) => {
    const parsedProjects: Array<Project> = []

    projects.forEach(project => {
        const company = parsedProjects.find(p => p.companyName === project.company)
        const position = {
            from: project.from,
            to: project.to,
            industry: project.industry,
            roles: [project.role],
            techStack: project.stack,
            description: project.description,
        }
        if (company) {
            company.positions.push(position)
        } else {
            parsedProjects.push({
                companyName: project.company,
                positions: [position],
            })
        }
    })

    return parsedProjects
}

export { parseProfile }
