/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, MutableRefObject, useCallback } from 'react'
import trashIcon from '../../../../../assets/icons/trash.svg'
import { Button } from '../../../../../components/Button'
import { IconButton } from '../../../../../components/Icon'
import { DropdownComponent } from '../../../../../components/inputs/autocomplete-select/DropdownComponent'
import { COLOR_PALETTE, mqMax } from '../../../../../GlobalStyle'

type InvitationDropdownBaseProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    recordId: string
    setSpecialistIdToRemove: (recordId: string) => void
    toggleConfirmationModal: () => void
}

const RemoveRoleDropdown: FunctionComponent<React.PropsWithChildren<InvitationDropdownBaseProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    recordId,
    setSpecialistIdToRemove,
    toggleConfirmationModal,
}) => {
    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const onRemove = useCallback(() => {
        setSpecialistIdToRemove(recordId)
        toggleConfirmationModal()
    }, [recordId, setSpecialistIdToRemove, toggleConfirmationModal])

    return (
        <div
            css={css`
                position: relative;
                display: flex;
                justify-content: flex-end;
            `}
            ref={rangeRef}
        >
            <IconButton name='more' onClick={toggleOpen} />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        height: 48px;
                        width: 174px;
                        top: -51px;
                        background-color: ${COLOR_PALETTE.white};
                        border: 1px solid ${COLOR_PALETTE.gray_2};
                        border-radius: 2px;
                        ${mqMax[1]} {
                            width: 174px;
                            top: 0;
                            left: -10px;
                        }
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            height: 48px;
                            width: 174px;
                            padding-bottom: 4px;
                        `}
                    >
                        <Button
                            style={css`
                                margin-left: 18px;
                            `}
                            variant='linkDelete'
                            icon={trashIcon}
                            onClick={onRemove}
                        >
                            Remove Role
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

const RemoveRoleActionHandler = DropdownComponent(RemoveRoleDropdown)

export { RemoveRoleActionHandler }
