/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import calculateSize from 'calculate-size'
import { Fragment, ReactNode } from 'react'
import ReactHtmlParser from 'html-react-parser'
import nl2br from 'nl2br'
import DOMPurify from 'dompurify'

import { SearchLocation } from 'src/contracts/common/searchLocation'
import { OpportunityLocationTypeV2Enum } from 'src/contracts/opportunities-manager/contracts'
import { StringMapping } from 'src/types'
import { Dot } from 'src/components//Dot'

const generateVisibleValues = ({
    values,
    width,
    isLastSet = true,
}: {
    values: Array<string>
    width: number
    isLastSet?: boolean
}): { content: string; numberOfNotVisibleValues: number; calculatedWidth: number } => {
    let content = ''
    let numberOfNotVisibleValues = values.length
    let calculatedWidth = 0

    values?.some((value, index) => {
        const newValue = `${content}${index !== 0 ? ', ' : ''}${value}`
        const isLastItem = isLastSet && index === values.length - 1

        calculatedWidth = calculateSize(newValue, { fontWeight: '400', fontSize: '14px', font: 'Jost, sans-serif' }).width

        if (!(calculatedWidth > width - (isLastItem ? 0 : 35))) {
            numberOfNotVisibleValues = numberOfNotVisibleValues - 1
            content = newValue
        }

        return calculatedWidth > width - (isLastItem ? 0 : 35) || index === values.length - 1
    })

    return { content, numberOfNotVisibleValues, calculatedWidth }
}

const generateVisibleOnsiteValues = ({
    values,
    width,
}: {
    values: Array<SearchLocation>
    width: number
}): { content: ReactNode; numberOfNotVisibleValues: number } => {
    const content: Array<ReactNode> = []
    let numberOfNotVisibleValues = values.reduce(
        (currentNumberOfNotVisibleValues, location) => currentNumberOfNotVisibleValues + location.cities.length,
        0,
    )
    let widthLeft = width
    values?.some((location, index) => {
        const countryNamedWidth = calculateSize(`${location.country}:_`, {
            fontWeight: '500',
            fontSize: '14px',
            font: 'Jost, sans-serif',
        }).width
        const isLastSet = index === values.length - 1

        widthLeft = widthLeft - countryNamedWidth

        let isFinished = false

        if (widthLeft < 50) {
            isFinished = true
        } else {
            if (index !== 0) {
                content.push(<Dot key={`${location.country}_dot`} />)
                widthLeft = widthLeft - 15
            }

            const {
                content: localContent,
                numberOfNotVisibleValues: localNumberOfNotVisibleValues,
                calculatedWidth,
            } = generateVisibleValues({
                values: location.cities,
                width: widthLeft,
                isLastSet,
            })
            widthLeft = widthLeft - calculatedWidth
            numberOfNotVisibleValues = numberOfNotVisibleValues - location.cities.length + localNumberOfNotVisibleValues

            content.push(<strong key={`${location.country}_country`}>{location.country}: </strong>)
            content.push(
                <span
                    css={css`
                        font-weight: 400;
                    `}
                    key={`${location.country}_cities`}
                >
                    {localContent}
                </span>,
            )

            if (localNumberOfNotVisibleValues !== 0 || isLastSet) {
                isFinished = true
            }
        }

        return isFinished
    })

    return { content: <Fragment>{content}</Fragment>, numberOfNotVisibleValues }
}

const locationLabel: StringMapping = {
    [OpportunityLocationTypeV2Enum.ON_SITE]: 'On site from',
    [OpportunityLocationTypeV2Enum.HYBRID]: 'Hybrid from',
    [OpportunityLocationTypeV2Enum.REMOTE]: 'Remote from',
}

const parseHTMLString = (html: string) => {
    return ReactHtmlParser(DOMPurify.sanitize(nl2br(html)))
}

export { generateVisibleValues, generateVisibleOnsiteValues, locationLabel, parseHTMLString }
