import { css } from '@emotion/react'

import { Theme } from '../../..//theme/theme'

const globalRcSwitchStyles = (theme: Theme) => css`
    .rc-switch {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        width: 44px;
        height: 22px;
        line-height: 20px;
        padding: 0;
        vertical-align: middle;
        border-radius: 20px 20px;
        border: 1px solid ${theme.colors.gray_3};
        background-color: ${theme.colors.white};
        cursor: pointer;
        transition: background-color 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    }
    .rc-switch-inner {
        color: ${theme.colors.white};
        font-size: 12px;
        position: absolute;
        left: 24px;
        top: 0;
    }
    .rc-switch:after {
        position: absolute;
        width: 18px;
        height: 18px;
        left: 2px;
        top: 1px;
        border-radius: 50% 50%;
        background-color: ${theme.colors.gray_6};
        content: ' ';
        cursor: pointer;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
        transform: scale(1);
        transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1), background-color 0.3s;
        animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
        animation-duration: 0.3s;
        animation-name: rcSwitchOff;
    }

    .rc-switch:focus {
        outline: none;
    }
    .rc-switch-checked {
        background-color: ${theme.colors.gray_6};
    }
    .rc-switch-checked .rc-switch-inner {
        left: 6px;
    }
    .rc-switch-checked:after {
        left: 22px;
        background-color: ${theme.colors.white};
    }
    .rc-switch-disabled {
        cursor: no-drop;
        background: #ccc;
        border-color: #ccc;
    }
    .rc-switch-disabled:after {
        background: #9e9e9e;
        animation-name: none;
        cursor: no-drop;
    }
    .rc-switch-disabled:hover:after {
        transform: scale(1);
        animation-name: none;
    }
    .rc-switch-label {
        display: inline-block;
        line-height: 20px;
        font-size: 14px;
        padding-left: 10px;
        vertical-align: middle;
        white-space: normal;
        pointer-events: none;
        user-select: text;
    }
    @keyframes rcSwitchOn {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.25);
        }
        100% {
            transform: scale(1.1);
        }
    }
    @keyframes rcSwitchOff {
        0% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
`

export { globalRcSwitchStyles }
