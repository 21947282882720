import { FunctionComponent } from 'react'
import { AntDropdown } from '../dropdown/AntDropdown'
import { ExportType } from './types'

type ProfileExportButtonProps = {
    children?: any
    handleClick: (key: ExportType) => void
}
const options = [
    {
        title: 'Anonymized',
        key: ExportType.CustomExportAnonymized,
        dataTestId: ExportType.CustomExportAnonymized,
    },
    {
        title: 'Not anonymized',
        key: ExportType.CustomExportNotAnonymized,
        dataTestId: ExportType.CustomExportNotAnonymized,
    },
]

const ProfileExportButton: FunctionComponent<React.PropsWithChildren<ProfileExportButtonProps>> = ({ children, handleClick }) => (
    <AntDropdown options={options} handleClick={handleClick}>
        {children}
    </AntDropdown>
)

export { ProfileExportButton }
