import { CompanyMembersResponse } from '../../../../contracts/companyMembers'
import { Nullable } from '../../../../types'
import {
    CountriesAndRegionsGroupsEnum,
    UpdateOpportunityFormData,
    UpdateOpportunityRequestBody,
} from '../../../../contracts/opportunities-manager/contracts'

const parseFormData = (
    formData: UpdateOpportunityFormData,
    companyMembers: Nullable<CompanyMembersResponse>,
    smeCloud: boolean,
): UpdateOpportunityRequestBody => {
    const opportunityOwner = companyMembers?.members.find(member => member.userId === formData.internalDetails.opportunityOwner)
    const internalDetails = {
        ...formData.internalDetails,
        smeCloud,
        opportunityOwner: {
            displayName: opportunityOwner?.email || '',
            userId: opportunityOwner?.userId || '',
        },
    }

    const jobDetails = {
        ...formData.jobDetails,
        minYearsOfExperience: formData.jobDetails.minYearsOfExperience ? Number(formData.jobDetails.minYearsOfExperience) : null,
    }

    const location = {
        countriesWithCities: formData.location.countriesAndRegions
            .filter(countryOrRegion => countryOrRegion.groupName === CountriesAndRegionsGroupsEnum.COUNTRIES)
            .map(country => ({
                country: country.value,
                cities: formData.location.countryCitiesList.filter(city => city.groupName === country.value).map(city => city.value),
            })),
        otherLocationRequirementsText: formData.location.otherLocationRequirementsText,
        locationType: formData.location.locationTypeV2 || undefined,
        regions: formData.location.countriesAndRegions
            .filter(countryOrRegion => countryOrRegion.groupName === CountriesAndRegionsGroupsEnum.REGIONS)
            .map(region => region.value),
        timeZone: formData.location.timeZone,
    }

    const opportunityDetails = {
        ...formData.opportunityDetails,
        endDate: formData.opportunityDetails.endDate || null,
        maximumRate: formData.opportunityDetails.maximumRate || null,
        minimumRate: formData.opportunityDetails.minimumRate || null,
        numberOfVacancies: formData.opportunityDetails.numberOfVacancies || null,
        startDate: formData.opportunityDetails.startDate || null,
    }

    const projectDetails = {
        ...formData.projectDetails,
        paymentPeriod: formData.projectDetails.paymentPeriod || null,
    }

    const contactDetails = {
        contactEmail: formData.contactDetails.contactEmail,
    }

    return {
        internalDetails,
        jobDetails,
        location,
        opportunityDetails,
        opportunityTitle: formData.opportunityTitle,
        projectDetails,
        contactDetails,
    }
}

export { parseFormData }
