const rareCssProperties = [
    '-webkit-app-region',
    '-webkit-border-horizontal-spacing',
    '-webkit-border-image',
    '-webkit-border-vertical-spacing',
    '-webkit-box-align',
    '-webkit-box-decoration-break',
    '-webkit-box-direction',
    '-webkit-box-flex',
    '-webkit-box-ordinal-group',
    '-webkit-box-orient',
    '-webkit-box-pack',
    '-webkit-box-reflect',
    '-webkit-highlight',
    '-webkit-hyphenate-character',
    '-webkit-line-clamp',
    '-webkit-locale',
    '-webkit-mask-box-image',
    '-webkit-mask-box-image-outset',
    '-webkit-mask-box-image-repeat',
    '-webkit-mask-box-image-slice',
    '-webkit-mask-box-image-source',
    '-webkit-mask-box-image-width',
    '-webkit-mask-clip',
    '-webkit-mask-composite',
    '-webkit-mask-image',
    '-webkit-mask-origin',
    '-webkit-mask-position',
    '-webkit-mask-repeat',
    '-webkit-mask-size',
    '-webkit-print-color-adjust',
    '-webkit-rtl-ordering',
    '-webkit-tap-highlight-color',
    '-webkit-text-combine',
    '-webkit-text-decorations-in-effect',
    '-webkit-text-emphasis-color',
    '-webkit-text-emphasis-position',
    '-webkit-text-emphasis-style',
    '-webkit-text-fill-color',
    '-webkit-text-orientation',
    '-webkit-text-security',
    '-webkit-text-stroke-color',
    '-webkit-text-stroke-width',
    '-webkit-user-drag',
    '-webkit-user-modify',
    '-webkit-writing-mode',
    'alignment-baseline',
    'animation-delay',
    'animation-direction',
    'animation-duration',
    'animation-fill-mode',
    'animation-iteration-count',
    'animation-name',
    'animation-play-state',
    'animation-timing-function',
    'backdrop-filter',
    'backface-visibility',
    'background-attachment',
    'background-blend-mode',
    'background-clip',
    'background-origin',
    'baseline-shift',
    'block-size',
    'border-block-end-color',
    'border-block-end-style',
    'border-block-end-width',
    'border-block-start-color',
    'border-block-start-style',
    'border-block-start-width',
    'border-image-outset',
    'border-image-repeat',
    'border-image-slice',
    'border-image-source',
    'border-image-width',
    'buffered-rendering',
    'caption-side',
    'caret-color',
    'clip',
    'clip-path',
    'clip-rule',
    'color-interpolation',
    'color-interpolation-filters',
    'color-rendering',
    'column-gap',
    'column-rule-color',
    'column-rule-style',
    'column-rule-width',
    'column-span',
    'column-width',
    'cx',
    'cy',
    'd',
    'direction',
    'dominant-baseline',
    'flood-color',
    'flood-opacity',
    'font-kerning',
    'font-optical-sizing',
    'font-variant',
    'font-variant-caps',
    'font-variant-east-asian',
    'font-variant-ligatures',
    'font-variant-numeric',
    'hyphens',
    'image-orientation',
    'image-rendering',
    'inset-block-end',
    'inset-block-start',
    'inset-inline-end',
    'inset-inline-start',
    'isolation',
    'lighting-color',
    'marker-end',
    'marker-mid',
    'marker-start',
    'mask-type',
    'max-block-size',
    'max-height',
    'max-inline-siz',
    'min-block-size',
    'min-inline-size',
    'mix-blend-mode',
    'object-fit',
    'object-position',
    'offset-distance',
    'offset-path',
    'offset-rotate',
    'orphans',
    'outline-color',
    'outline-offset',
    'outline-style',
    'outline-width',
    'overflow-anchor',
    'overflow-wrap',
    'overscroll-behavior-block',
    'overscroll-behavior-inline',
    'paint-order',
    'perspective',
    'perspective-origin',
    'pointer-events',
    'r',
    'row-gap',
    'ruby-position',
    'rx',
    'ry',
    'scroll-behavior',
    'scroll-margin-block-end',
    'scroll-margin-block-start',
    'scroll-margin-inline-end',
    'scroll-margin-inline-start',
    'scroll-padding-block-end',
    'scroll-padding-block-start',
    'scroll-padding-inline-end',
    'scroll-padding-inline-start',
    'shape-image-threshold',
    'shape-margin',
    'shape-outside',
    'shape-rendering',
    'speak',
    'stop-color',
    'stop-opacity',
    'stroke-dasharray',
    'stroke-dashoffset',
    'stroke-linecap',
    'stroke-linejoin',
    'stroke-miterlimit',
    'stroke-opacity',
    'stroke-width',
    'tab-size',
    'table-layout',
    'text-align-last',
    'text-anchor',
    'text-rendering',
    'text-size-adjust',
    'text-underline-position',
    'touch-action',
    'transform-style',
    'transition-delay',
    'transition-duration',
    'transition-property',
    'transition-timing-function',
    'unicode-bidi',
    'user-select',
    'vector-effect',
    'widows',
    'will-change',
    'writing-mode',
    'x',
    'y',
    'zoom',
]

export { rareCssProperties }
