import moment, { Moment } from 'moment-timezone'

const defaultFormat = 'DD.MM.YYYY'
const monthFormat = 'MMM YYYY'
const quarterFormat = 'Q[Q]/YYYY'
const yearFormat = 'YYYY'
const weekStartEndFormat = (value: Moment) =>
    `${moment(value).startOf('week').format(defaultFormat)} ~ ${moment(value).endOf('week').format(defaultFormat)}`
const interviewDateFormat = 'DD.MM.YY, HH:mm'

const formatDate = (stringDate: string | number, dateFormat = monthFormat): string => {
    return moment(stringDate).format(dateFormat)
}

const formatUtcDate = (stringDate: string | number, dateFormat = monthFormat): string => {
    return moment.utc(stringDate).format(dateFormat)
}
const formatUnixDate = (stringDate: string | number, dateFormat = monthFormat): string => {
    if (typeof stringDate === 'number') {
        return moment.unix(stringDate).format(dateFormat)
    } else {
        return moment(stringDate).format(dateFormat)
    }
}
const formatInterviewDate = (timestamp: number) => {
    const polishTimezone = 'Europe/Warsaw'
    const date = moment.unix(timestamp).tz(polishTimezone)
    const offset = date.utcOffset() / 60
    return `${date.format(interviewDateFormat)} UTC+${offset}`
}

const convertTimestampToDateString = (timestamp: number, dateFormat = defaultFormat) => formatDate(timestamp * 1000, dateFormat)

const getMonthsValue = (months: number): string => (months === 0 ? '<1 Month' : `${months} Months`)

const normalizeTimeStamp = (timestamp: number) =>
    moment
        .utc(new Date(timestamp * 1000))
        .endOf('day')
        .format('X')

export {
    formatDate,
    convertTimestampToDateString,
    getMonthsValue,
    normalizeTimeStamp,
    formatUnixDate,
    formatUtcDate,
    weekStartEndFormat,
    monthFormat,
    quarterFormat,
    yearFormat,
    formatInterviewDate,
}
