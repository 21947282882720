import { axios } from '../../api/axios'
import { CustomField, SetCustomFieldBody } from '../../contracts/customFields'
import { RatedSkill } from '../../contracts/cvs'
import { Note } from '../../contracts/notes/Notes'
import { LanguageRequest } from '../../contracts/profile/language'
import { Specialist } from '../../contracts/specialist/specialist'
import { CvData } from '../../contracts/specialist/specialistCv'
import { Education } from '../../contracts/specialist/specialistEducations'
import { SpecialistGeneralInformation } from '../../contracts/specialist/specialistGeneralInformation'
import { SpecialistProject } from '../../contracts/specialist/specialistProject'
import {
    GetParsedCvsResponse,
    GetProcessingCvsResponse,
    ImportInProgressResponse,
    KeyInfoResponse,
    ParsedSpecialist,
    PersonalInformation,
    ProfileSummaryResponse,
    SpecialistDashboardAccess,
    SpecialistProfileInformationRequest,
    SpecialistProfileInformationResponse,
    SpecialistSearchRequest,
    SpecialistSearchResponse,
    SpecialistsFiltersRequest,
    SpecialistsFiltersResponse,
} from './contracts'

const specialistsEndpoint = `/specialists`

const getKeyInfo = (specialistId: string): Promise<KeyInfoResponse> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}:key-info`)
}

const sendInvitation = (specialistId: string): Promise<any> => {
    return axios.post(`/v1${specialistsEndpoint}/${specialistId}/invitations/-:send`)
}

const getSpecialistDashboardAccess = (specialistId: string): Promise<SpecialistDashboardAccess> => {
    return axios.get(`/v1/specialists/${specialistId}/dashboardAccess`)
}

const cancelInvitation = (specialistId: string): Promise<any> => {
    return axios.post(`/v1${specialistsEndpoint}/${specialistId}/invitations/-:cancel`)
}

const addSpecialists = (specialists: Array<Specialist>): Promise<Array<Specialist>> => {
    return axios.post(`${specialistsEndpoint}:batchCreate`, { specialists })
}

const getSpecialists = (searchRequest: SpecialistSearchRequest): Promise<SpecialistSearchResponse> => {
    return axios.post(`${specialistsEndpoint}:search`, searchRequest)
}

const getSpecialistsFilters = (searchRequest: SpecialistsFiltersRequest): Promise<SpecialistsFiltersResponse> => {
    return axios.post(`${specialistsEndpoint}:filters`, searchRequest)
}

const updateSpecialistGeneralInformation = (
    specialistId: string,
    specialistGeneralInformation: Omit<SpecialistGeneralInformation, 'verifiedTopSkills'>,
): Promise<SpecialistGeneralInformation> => {
    return axios.put(`/v2${specialistsEndpoint}/${specialistId}/generalInformation`, specialistGeneralInformation)
}

const getSpecialistProfileSummary = (specialistId: string): Promise<ProfileSummaryResponse> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/generalInformation:profileSummary`)
}

const updateSpecialistProfileSummary = (specialistId: string, profileSummary: string): Promise<ProfileSummaryResponse> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/generalInformation:profileSummary`, { profileSummary })
}

const updateSpecialist = (specialistId: string, specialist: Omit<Specialist, 'id'>): Promise<Specialist> => {
    return axios.put(`${specialistsEndpoint}/${specialistId}`, specialist)
}

const deleteSpecialist = (specialistId: string): Promise<void> => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}`)
}

const saveSpecialistProject = (specialistId: string, project: SpecialistProject) => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/projects`, project)
}

const updateSpecialistProject = (specialistId: string, projectId: string, project: SpecialistProject) => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/projects/${projectId}`, project)
}

const deleteSpecialistProject = (specialistId: string, projectId: string) => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}/projects/${projectId}`)
}

const getSpecialistProfileInformation = (specialistId: string): Promise<SpecialistProfileInformationResponse> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/personalInformation`)
}

const getSpecialistProfileInformationV2 = (specialistId: string): Promise<PersonalInformation> => {
    return axios.get(`/v2${specialistsEndpoint}/${specialistId}/personalInformation`)
}

const updateSpecialistProfileInformationV2 = (specialistId: string, data: PersonalInformation): Promise<PersonalInformation> => {
    return axios.put(`/v2${specialistsEndpoint}/${specialistId}/personalInformation`, data)
}

const getAnonymousSpecialistProfile = (linkId: string): Promise<SpecialistProfileInformationResponse> => {
    return axios.get(`/anonymous/specialists/${linkId}`)
}

const saveSpecialistProfileInformation = (
    specialistId: string,
    data: SpecialistProfileInformationRequest,
): Promise<SpecialistProfileInformationResponse> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/personalInformation`, data)
}

const updateSpecialistProfileInformation = (
    specialistId: string,
    personalInformationId: string,
    data: SpecialistProfileInformationRequest,
): Promise<SpecialistProfileInformationResponse> => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/personalInformation/${personalInformationId}`, data)
}

const saveSpecialistEducation = (specialistId: string, education: Education): Promise<Education> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/education`, education)
}

const updateSpecialistEducation = (specialistId: string, educationId: string, education: Education): Promise<Education> => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/education/${educationId}`, education)
}

const deleteSpecialistEducation = (specialistId: string, educationId: string): Promise<Education> => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}/education/${educationId}`)
}

const saveSpecialistLanguage = (specialistId: string, language: LanguageRequest) => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/languages`, language)
}

const deleteSpecialistLanguage = (specialistId: string, languageId: string) => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}/languages/${languageId}`)
}

const updateSpecialistLanguage = (specialistId: string, languageId: string, language: LanguageRequest) => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/languages/${languageId}`, language)
}

const uploadCv = (data: FormData, specialistId: string): Promise<CvData> => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/cv`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const deleteCv = (specialistId: string) => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}/cv`)
}

const getCv = (specialistId: string): Promise<CvData> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/cv`)
}

const addMySkills = (cvParsingId: string, data: { ratedSkills: Array<RatedSkill> }): Promise<void> => {
    return axios.post(`/v1${specialistsEndpoint}/me/cvs/${cvParsingId}:addSkills`, data)
}

const importSpecialistsEndpoint = '/specialistImport'

const checkImportInProgress = (): Promise<ImportInProgressResponse> => {
    return axios.get(`${importSpecialistsEndpoint}:inProgress`)
}

const uploadSpecialists = (file: File): Promise<void> => {
    const formData = new FormData()
    formData.append('', file)
    return axios.post(`${specialistsEndpoint}:bulkUpload`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const downloadErrorReport = (importId: string) => {
    return axios.get(`${specialistsEndpoint}:bulkUploadErrorReport?importId=${importId}`, {
        responseType: 'blob',
    })
}

// CUSTOM FIELDS

const customFieldsEndpoint = `/custom-fields`

const getCustomFields = (): Promise<Array<CustomField>> => {
    return axios.get(customFieldsEndpoint)
}

const addCustomField = (data: SetCustomFieldBody): Promise<CustomField> => {
    return axios.post(customFieldsEndpoint, data)
}

const updateCustomField = (id: string, data: SetCustomFieldBody): Promise<CustomField> => {
    return axios.put(`${customFieldsEndpoint}/${id}`, data)
}

const removeCustomField = (id: string): Promise<any> => {
    return axios.delete(`${customFieldsEndpoint}/${id}`)
}

// NOTES

const addNote = (specialistId: string, content: string): Promise<Note> => {
    return axios.post(`/v1/${specialistsEndpoint}/${specialistId}/notes`, { content })
}

const editMyNote = (specialistId: string, noteId: string, content: string): Promise<Note> => {
    return axios.post(`/v1/${specialistsEndpoint}/${specialistId}/notes/${noteId}/my`, { content })
}

const deleteMyNote = (specialistId: string, noteId: string): Promise<void> => {
    return axios.delete(`/v1/${specialistsEndpoint}/${specialistId}/notes/${noteId}/my`)
}

// Cv parsing

const cvsEndpoint = '/v1/cvs'

const getParsedCvsCount = (): Promise<number> => {
    return axios.get(`${cvsEndpoint}/parsedCvs:count`)
}

const uploadCvs = (data: FormData): Promise<void> => {
    return axios.post(`${cvsEndpoint}:bulkUpload`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
}

const getProcessingCvs = (): Promise<GetProcessingCvsResponse> => {
    return axios.get(`${cvsEndpoint}/processingCvs`)
}

const deleteProcessingCv = (id: string): Promise<void> => {
    return axios.delete(`${cvsEndpoint}/processingCvs/${id}`)
}

const getParsedCvs = (): Promise<GetParsedCvsResponse> => {
    return axios.get(`${cvsEndpoint}/parsedCvs`)
}

const deleteParsedCv = (id: string): Promise<void> => {
    return axios.delete(`${cvsEndpoint}/parsedCvs/${id}`)
}

const createSpecialistsFromCvs = (parsedSpecialists: Array<ParsedSpecialist>) => {
    return axios.post(`${cvsEndpoint}/parsedCvs:bulkCreate`, { parsedSpecialists })
}

export {
    addSpecialists,
    getSpecialists,
    getSpecialistsFilters,
    updateSpecialist,
    deleteSpecialist,
    saveSpecialistEducation,
    updateSpecialistEducation,
    deleteSpecialistEducation,
    saveSpecialistProfileInformation,
    updateSpecialistProfileInformation,
    getSpecialistProfileInformation,
    getSpecialistProfileInformationV2,
    updateSpecialistProfileInformationV2,
    saveSpecialistProject,
    updateSpecialistProject,
    deleteSpecialistProject,
    saveSpecialistLanguage,
    deleteSpecialistLanguage,
    updateSpecialistLanguage,
    getAnonymousSpecialistProfile,
    sendInvitation,
    cancelInvitation,
    getSpecialistDashboardAccess,
    uploadCv,
    getCv,
    deleteCv,
    updateSpecialistGeneralInformation,
    getSpecialistProfileSummary,
    updateSpecialistProfileSummary,
    addMySkills,
    checkImportInProgress,
    uploadSpecialists,
    downloadErrorReport,
    getCustomFields,
    addCustomField,
    updateCustomField,
    removeCustomField,
    addNote,
    editMyNote,
    deleteMyNote,
    getParsedCvsCount,
    uploadCvs,
    getProcessingCvs,
    deleteProcessingCv,
    getParsedCvs,
    deleteParsedCv,
    createSpecialistsFromCvs,
    getKeyInfo,
}
