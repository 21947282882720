import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { Icon } from 'src/components/Icon'
import { TooltipIcon } from 'src/components/TooltipIcon'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'

const benefits = [
    { title: 'Full access to Talent Marketplace: Add, share & manage specialists', free: true, premium: true },
    { title: 'Access to Open Opportunities', free: true, premium: true },
    { title: 'White-label specialist profiles ready to share with clients', free: true, premium: true },
    { title: 'Free online community meetings', free: true, premium: true },
    {
        title: 'Early access to Premium Opportunities',
        tooltipText: 'Early access means that only Premium users can apply to new opportunities during the first 48 hours.',
        free: false,
        premium: true,
    },
    { title: 'New matching Opportunities sent directly to your inbox', free: false, premium: true },
    { title: 'Priority in business deals, transactions and agreements', free: false, premium: true },
    { title: 'A dedicated account manager', free: false, premium: true },
    { title: 'Free CV anonymization', free: false, premium: true },
    { title: 'Pricing insights from IT Services Barometer', free: false, premium: true },
]

const StyledTd = styled.td<{ violet?: boolean; bold?: boolean; center?: boolean }>`
    padding: 1rem 2rem;
    border: ${props => `1px solid ${props.theme.colors.gray_2}`};
    background-color: ${props => (props.violet ? props.theme.colors.violet_1 : 'transparent')};
    font-weight: ${props => (props.bold ? 500 : 400)};
    text-align: ${props => (props.center ? 'center' : 'inherit')};
`

const StyledTh = styled.th<{ violet?: boolean }>`
    padding: 1rem 2rem;
    border: ${props => `1px solid ${props.theme.colors.gray_2}`};
    background-color: ${props => (props.violet ? props.theme.colors.violet_1 : 'transparent')};
    font-size: 18px;
    font-weight: 500;
    text-align: left;
`

const BenefitsTable = () => {
    return (
        <div>
            <table>
                <tr>
                    <StyledTh>Compare plans</StyledTh>
                    <StyledTh>Free</StyledTh>
                    <StyledTh violet>Premium</StyledTh>
                </tr>
                {benefits.map(benefit => (
                    <tr key={benefit.title}>
                        <StyledTd bold>
                            <FlexBox alignItems='center'>
                                {benefit.title}
                                {benefit.tooltipText ? (
                                    <Tooltip placement='top' title={benefit.tooltipText}>
                                        <div>
                                            <TooltipIcon />
                                        </div>
                                    </Tooltip>
                                ) : null}
                            </FlexBox>
                        </StyledTd>
                        <StyledTd center>{benefit.free ? <Icon name='check-reward' size={20} /> : ''}</StyledTd>
                        <StyledTd violet center>
                            {benefit.premium ? <Icon name='check-reward' size={20} /> : ''}
                        </StyledTd>
                    </tr>
                ))}
            </table>
        </div>
    )
}

export { BenefitsTable }
