const matrixes = {
    2: [
        [
            [1, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 1],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 1],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 1],
        ],
    ],

    3: [
        [
            [1, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 1, 1],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 1],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 1, 0, 0],
            [0, 0, 0, 1],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 1, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [1, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 1],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 1, 0, 0],
        ],
    ],

    4: [
        [
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 1],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 1],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [1, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 0, 1, 1],
        ],
        [
            [1, 0, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 1],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 1],
            [1, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 0],
            [1, 1, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 0, 0, 1],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [1, 0, 0, 1],
        ],
    ],

    5: [
        [
            [1, 0, 1, 0],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 1, 0],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [1, 1, 1, 1],
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [1, 0, 1, 0],
            [0, 0, 0, 1],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 0, 1, 1],
            [1, 0, 0, 0],
            [1, 0, 1, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 0, 1],
            [1, 0, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 1],
            [0, 0, 1, 1],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [1, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [1, 1, 0, 0],
            [0, 1, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 1],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 1, 0],
            [1, 0, 0, 0],
            [1, 0, 0, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 1],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 0, 1, 0],
            [0, 0, 0, 1],
            [1, 0, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 0, 0, 0],
        ],
    ],

    6: [
        [
            [0, 0, 1, 1],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
            [0, 0, 0, 0],
        ],
        [
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
            [1, 0, 1, 0],
        ],
        [
            [0, 0, 0, 0],
            [1, 1, 0, 1],
            [0, 0, 0, 0],
            [1, 0, 1, 1],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 1],
            [0, 1, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 1, 0, 1],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 1, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [0, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 0, 1, 0],
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 1],
        ],
        [
            [1, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 1, 1],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 1, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 1, 0],
            [0, 0, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 1],
            [1, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 0, 0, 1],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [1, 0, 0, 1],
        ],
        [
            [0, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 0, 0, 1],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 1, 0],
            [0, 0, 1, 0],
            [1, 0, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 0, 0],
            [0, 0, 1, 0],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 1, 0],
            [1, 1, 0, 0],
            [0, 0, 0, 1],
        ],
    ],
    7: [
        [
            [1, 0, 0, 0],
            [0, 1, 0, 0],
            [1, 0, 1, 1],
            [0, 0, 1, 1],
        ],
        [
            [0, 0, 1, 1],
            [0, 1, 0, 0],
            [0, 1, 0, 1],
            [1, 0, 0, 1],
        ],
        [
            [0, 1, 1, 0],
            [0, 0, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [1, 0, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 1, 0, 1],
            [0, 0, 1, 1],
            [1, 1, 0, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 0, 1],
            [1, 1, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [0, 0, 0, 1],
            [0, 1, 1, 0],
        ],
        [
            [1, 1, 1, 0],
            [0, 0, 0, 1],
            [1, 0, 1, 0],
            [0, 0, 1, 0],
        ],
        [
            [1, 0, 1, 0],
            [1, 1, 0, 1],
            [0, 0, 0, 0],
            [0, 0, 1, 1],
        ],
        [
            [0, 0, 1, 0],
            [1, 0, 1, 1],
            [0, 0, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 0, 1, 1],
            [1, 0, 1, 1],
            [0, 0, 0, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 1, 0, 1],
            [0, 0, 1, 0],
            [0, 0, 1, 0],
            [0, 1, 0, 1],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [0, 0, 1, 1],
            [1, 1, 0, 0],
        ],
        [
            [1, 0, 0, 0],
            [1, 0, 1, 1],
            [0, 1, 1, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [0, 0, 1, 1],
            [1, 1, 0, 1],
            [0, 0, 1, 0],
        ],
        [
            [1, 1, 0, 0],
            [0, 0, 1, 1],
            [1, 1, 0, 0],
            [0, 0, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [1, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [0, 0, 0, 1],
            [1, 0, 1, 0],
        ],
    ],

    8: [
        [
            [1, 1, 1, 1],
            [0, 0, 1, 1],
            [0, 1, 0, 0],
            [0, 0, 0, 1],
        ],
        [
            [1, 0, 0, 1],
            [1, 0, 1, 1],
            [0, 1, 1, 0],
            [0, 0, 0, 1],
        ],
        [
            [0, 0, 1, 0],
            [1, 1, 0, 1],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 1, 0],
            [0, 0, 0, 1],
            [1, 1, 0, 0],
            [1, 1, 0, 1],
        ],
        [
            [1, 0, 1, 1],
            [0, 1, 0, 1],
            [0, 0, 1, 0],
            [1, 1, 0, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 0, 1, 1],
            [1, 0, 0, 0],
            [1, 1, 1, 0],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [0, 0, 1, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 1, 1, 0],
            [1, 0, 0, 1],
            [0, 1, 0, 0],
            [1, 0, 1, 1],
        ],
        [
            [1, 0, 0, 0],
            [1, 1, 0, 0],
            [0, 0, 1, 1],
            [1, 1, 0, 1],
        ],
        [
            [0, 1, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 1, 0],
            [0, 1, 1, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [1, 0, 1, 1],
            [1, 1, 0, 0],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 0, 1],
            [1, 0, 1, 1],
            [1, 1, 0, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 0, 1, 1],
            [1, 1, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [0, 0, 0, 1],
            [1, 0, 1, 0],
            [1, 1, 0, 0],
            [1, 0, 1, 1],
        ],
        [
            [0, 1, 0, 1],
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 1, 0],
        ],
        [
            [0, 0, 1, 1],
            [0, 1, 1, 1],
            [1, 0, 0, 0],
            [0, 1, 1, 0],
        ],
        [
            [1, 0, 1, 1],
            [0, 0, 1, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
        ],
    ],

    9: [
        [
            [1, 0, 1, 1],
            [1, 1, 0, 0],
            [0, 0, 1, 1],
            [0, 1, 1, 0],
        ],
        [
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
            [1, 0, 1, 1],
        ],
        [
            [1, 0, 0, 1],
            [0, 1, 1, 1],
            [1, 0, 1, 0],
            [1, 1, 0, 0],
        ],
        [
            [0, 0, 1, 1],
            [1, 1, 0, 1],
            [1, 1, 0, 0],
            [0, 1, 0, 1],
        ],
        [
            [1, 1, 1, 0],
            [0, 0, 0, 1],
            [1, 0, 1, 1],
            [0, 1, 1, 0],
        ],
        [
            [0, 1, 0, 1],
            [0, 1, 1, 1],
            [1, 0, 1, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 1, 1, 0],
            [1, 1, 0, 1],
        ],
        [
            [0, 1, 1, 0],
            [1, 0, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 1, 1],
        ],
        [
            [0, 1, 0, 1],
            [1, 1, 0, 1],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
        ],
        [
            [0, 1, 1, 0],
            [1, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 1, 0, 1],
        ],
        [
            [0, 1, 0, 0],
            [1, 0, 1, 1],
            [0, 1, 1, 0],
            [1, 0, 0, 1],
        ],
        [
            [1, 0, 1, 0],
            [0, 1, 0, 1],
            [1, 1, 0, 0],
            [1, 0, 1, 1],
        ],
        [
            [1, 0, 1, 0],
            [1, 1, 0, 1],
            [1, 0, 1, 0],
            [0, 0, 1, 1],
        ],
        [
            [1, 0, 1, 1],
            [0, 1, 0, 0],
            [1, 0, 1, 0],
            [1, 0, 1, 1],
        ],
        [
            [0, 1, 0, 1],
            [1, 1, 1, 0],
            [0, 1, 0, 1],
            [1, 0, 1, 0],
        ],
        [
            [1, 0, 1, 1],
            [0, 0, 0, 1],
            [1, 1, 1, 0],
            [0, 1, 0, 1],
        ],
    ],
}

export { matrixes }
