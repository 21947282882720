import { history } from '../history'
import { getDecodedAccessToken } from './currentUser'
import { log } from './useLogger'

const getUserActiveContextStorage = () => {
    let storedUserActiveContextId = null
    let storedUsersActiveContextIds = null
    const decodedAccessToken = getDecodedAccessToken()
    const currentUser = decodedAccessToken?.email
    try {
        storedUsersActiveContextIds = JSON.parse(localStorage.getItem('usersActiveContextIds') || '{}')
        storedUserActiveContextId = currentUser ? storedUsersActiveContextIds[currentUser] : null
    } catch (e) {
        localStorage.removeItem('usersActiveContextIds')
        log(e)
    }

    return {
        storedUserActiveContextId,
        storedUsersActiveContextIds,
        currentUser,
    }
}

const setUserActiveContextStorage = (newContextId: string) => {
    const { storedUsersActiveContextIds, currentUser } = getUserActiveContextStorage()
    if (storedUsersActiveContextIds && currentUser) {
        storedUsersActiveContextIds[currentUser] = newContextId
        localStorage.setItem('usersActiveContextIds', JSON.stringify(storedUsersActiveContextIds))
    }
}

const afterSuccessForbiddenPaths = [
    '/',
    '/logout',
    '/logout/user',
    '/logout/specialist',
    '/refresh-user-context',
    '/unavailable-context',
    '/pending-invitations',
]

const getAfterSuccessParam = () => {
    let afterSuccess = encodeURIComponent(`${history.location.pathname}${history.location.search}${history.location.hash}`)
    if (history.location.pathname === '/talent-marketplace') {
        afterSuccess = encodeURIComponent(`${history.location.pathname}`)
    }
    return `afterSuccess=${afterSuccess}`
}

export { getUserActiveContextStorage, setUserActiveContextStorage, afterSuccessForbiddenPaths, getAfterSuccessParam }
