/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'
import { Logo } from '../Logo'

export type ResponsiveWrapperProps = {
    withLogo?: boolean
    styles?: SerializedStyles
}

const maxContentWidth = 572
const horizontalContentPadding = 16
const verticalContentPadding = 20
const desktopVerticalContentPadding = 40

const WrapperBase = styled.div`
    margin: 18px auto;
    padding: 0 16px;
    width: 100%;
    ${mqMin[2]} {
        padding: 0 24px;
    }
`

const ColorBackgroundWrapper = styled.div`
    margin: 0 auto;
    padding: 18px 16px;
    width: 100%;
    ${mqMin[2]} {
        padding: 18px 24px;
    }
`

const FullPageWrapper = styled(WrapperBase)`
    max-width: 1440px;
`

const ApplicationsWrapper = styled(WrapperBase)`
    max-width: ${1232 + verticalContentPadding}px;
`
const SettingsWrapper = styled(WrapperBase)`
    width: 100%;
    ${mqMin[2]} {
        margin: 0 calc(100% / 12);
        width: 685px;
        padding: ${desktopVerticalContentPadding}px 0;
    }
`

const ResponsiveWrapper: FunctionComponent<React.PropsWithChildren<ResponsiveWrapperProps>> = ({ children, withLogo = false, styles }) => {
    return (
        <div
            css={css`
                background-color: ${COLOR_PALETTE.white};

                ${mqMax[1]} {
                    width: 100%;
                }
            `}
        >
            <div
                css={css`
                    max-width: ${maxContentWidth + horizontalContentPadding}px;
                    margin: 0 auto;
                    min-height: 100vh;
                    ${styles}

                    ${mqMax[1]} {
                        max-width: none;
                    }
                `}
            >
                <div
                    css={css`
                        padding: ${verticalContentPadding}px ${horizontalContentPadding}px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        ${mqMin[1]} {
                            padding-top: ${desktopVerticalContentPadding}px;
                        }
                    `}
                >
                    {withLogo && (
                        <div
                            css={css`
                                flex: 1;
                                height: 45px;
                            `}
                        >
                            <Link to='/'>
                                <Logo type='logoFull' />
                            </Link>
                        </div>
                    )}
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            flex: 30;
                        `}
                    >
                        <div
                            css={css`
                                margin-top: 30px;
                            `}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ResponsiveWrapper, FullPageWrapper, ApplicationsWrapper, SettingsWrapper, ColorBackgroundWrapper }
