import { useCallback, useEffect, useMemo, useState } from 'react'

import { useNotifications } from 'src/components/notification/NotificationProvider'
import { getPublishedOpportunities, inviteToOpportunity } from 'src/api/open-opportunities/api'
import { OpportunityShort } from 'src/contracts/open-opportunities/contracts'
import { useLogger } from 'src/utils/useLogger'

import { inviteCandidate } from 'src/api/opportunities-manager/api'
import { FormData } from './types'

const useInviteToOpp = ({ onClose, opened, specialistId }: { onClose: () => void; opened: boolean; specialistId?: string }) => {
    const log = useLogger()
    const { addError, addSuccess } = useNotifications()
    const [opps, setOpps] = useState<OpportunityShort[]>([])
    const [loadingOpps, setLoadingOpps] = useState(false)
    const selectifiedOpps = useMemo(
        () =>
            opps.map(({ opportunityId, opportunityInternalName, clientName }) => ({
                value: opportunityId,
                label: `${opportunityInternalName} - ${clientName}`,
                // Autocomplete component requires 'groupName' even if we don't care (there are no 'groups' of opps).
                groupName: '',
            })),
        [opps],
    )

    const invite = useCallback(
        async ({ opportunity }: FormData) => {
            // TODO
            // Autocomplete input always returns chosen value(s) as an array, even if it is single choice.
            // We need to fix it so that for single choice, element itself is returned.
            try {
                if (specialistId) {
                    await inviteToOpportunity(opportunity[0].value, specialistId)
                    addSuccess('Specialist has been invited.')
                    onClose()
                }
            } catch (err: any) {
                if (err.status === 409) {
                    addError('This specialist was already invited')
                } else {
                    addError('Error while inviting specialist , please try again later')
                }
                log(err)
            }
        },
        [addError, addSuccess, log, onClose, specialistId],
    )

    const handleInviteCandidate = useCallback(
        (applicationId: string, { opportunity }: FormData) => {
            inviteCandidate(applicationId, opportunity[0].value)
                .then(() => {
                    addSuccess('Specialist has been invited.')
                    onClose()
                })
                .catch(err => {
                    if (err.status === 409) {
                        addError('This specialist was already invited')
                    } else {
                        addError('Error while inviting specialist , please try again later')
                    }
                    log(err)
                })
        },
        [addError, addSuccess, log, onClose],
    )

    useEffect(() => {
        if (opened) {
            setLoadingOpps(true)
            getPublishedOpportunities()
                .then(data => {
                    setOpps(data.opportunityShortResponses)
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    setLoadingOpps(false)
                })
        }
    }, [addError, log, opened])

    return { invite, handleInviteCandidate, loadingOpps, selectifiedOpps }
}

export { useInviteToOpp }
