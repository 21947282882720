import { axios } from '../../api/axios'
import { PossibleSkillLevels } from '../../contracts/profile/skill'
import { Skill, SpecialistSkills } from '../../contracts/skills'

const specialistsEndpoint = `/specialists`

const addSkill = (specialistId: string, name: string): Promise<Skill> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/techskills`, { name })
}

const addSkills = (specialistId: string, skills: Array<string>): Promise<{ skills: Array<Skill> }> => {
    return axios.post(`${specialistsEndpoint}/${specialistId}/techskills:batch`, { skills })
}
const updateSkillLevel = (specialistId: string, skillId: string, newValue: PossibleSkillLevels): Promise<any> => {
    return axios.put(`${specialistsEndpoint}/${specialistId}/techskills`, { id: skillId, proficiency: newValue })
}

const deleteSkill = (specialistId: string, skillId: string): Promise<void> => {
    return axios.delete(`${specialistsEndpoint}/${specialistId}/techskills/${skillId}`)
}

const getSpecialistSkillsEditView = (specialistId: string): Promise<SpecialistSkills> => {
    return axios.get(`${specialistsEndpoint}/${specialistId}/techskills:editView`)
}

const getSpecialistSuggestedSkills = (specialistId: string): Promise<{ suggestedSkills: Array<string> }> => {
    return axios.get(`/v1${specialistsEndpoint}/${specialistId}/suggestedSkills`)
}

const deleteSpecialistSuggestedSkill = (specialistId: string, skillName: string): Promise<void> => {
    return axios.delete(`/v1${specialistsEndpoint}/${specialistId}/suggestedSkills/${skillName}`)
}

export {
    addSkill,
    addSkills,
    updateSkillLevel,
    deleteSkill,
    getSpecialistSkillsEditView,
    getSpecialistSuggestedSkills,
    deleteSpecialistSuggestedSkill,
}
