import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../GlobalStyle'

const SectionHeader = styled.h5`
    border-top: 1px solid ${COLOR_PALETTE.gray_2};
    padding-top: 20px;
    font-size: 18px;
`

export { SectionHeader }
