/** @jsxImportSource @emotion/react */

import React from 'react'
import { css } from '@emotion/react'

interface OwnProps {
    note: string
}

const StatusNote: React.FC<OwnProps> = ({ note }) => {
    return (
        <span
            css={css`
                padding: 8px;
                margin-top: 16px;
                margin-bottom: 0;
                display: inline-block;
            `}
        >
            {note}
        </span>
    )
}

export { StatusNote }
