/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useContext } from 'react'
import { DefaultLayout } from '../../components/DefaultLayout'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { COLOR_PALETTE } from '../../theme/colors'
import { useQuery } from '../../utils/useQuery'
import { sendVerificationEmail } from './api'

import { useAddUserToSyndicate } from '../../hooks/useSyndicate'

const RegisterConfirmation = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const query = useQuery()
    const email = query.get('email')

    useAddUserToSyndicate({ email, delay: 3 * 1000 })

    const { addSuccess, addError } = useNotifications()

    const handleEmailResend = useCallback(() => {
        if (email) {
            layoutToggleLoader(true)
            sendVerificationEmail(email)
                .then(() => addSuccess('Verification link sent!'))
                .catch(() => addError('Failed to send verification link. Please try again.'))
                .finally(() => layoutToggleLoader(false))
        }
    }, [email, addError, addSuccess, layoutToggleLoader])

    return (
        <DefaultLayout icon='mail' title='Thanks for Joining Us'>
            <p
                css={css`
                    margin-bottom: 30px;
                `}
            >
                There is just one last bit left - you need to verify your email address. <br /> Verification email is waiting in your inbox.
                <br />
                <br />
                Didn’t get an email?{' '}
                <span
                    css={css`
                        font-weight: 500;
                        color: ${COLOR_PALETTE.violet_5};

                        &:hover {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    `}
                    onClick={handleEmailResend}
                >
                    Click here
                </span>{' '}
                and we’ll resend it.
            </p>
        </DefaultLayout>
    )
}

export { RegisterConfirmation }
