import { Fragment, FunctionComponent, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AcceptTerms } from '../accept-terms/AcceptTerms'
import { PersonalDetailsModal } from '../components/PersonalDetailsModal'
import { ContextGroupName, ContextType } from '../contracts/contexts'
import { history } from '../history'
import { useRoutesMatching } from '../hooks/useRoutesMatching'
import { useUserAuthentication } from '../hooks/useUserAuthentication'
import { useUserContext } from '../hooks/useUserContext'
import { useUserPendingInvitations } from '../hooks/useUserPendingInvitations'
import { getUserRole } from '../utils/currentUser'

const PrivateRoute: FunctionComponent<
    React.PropsWithChildren<{
        component: FunctionComponent<React.PropsWithChildren<any>>
        allowedGroups: Array<ContextGroupName>
    }>
> = ({ component: RouteComponent, allowedGroups, ...rest }) => {
    const { isPasswordAccountActivated, isWaitingForStatus, isProviderAccountActivated, isUserAuthorized, isUnauthorized, logout } =
        useUserAuthentication()

    const { matchCompanyInvitation } = useRoutesMatching()

    const { prospectId } = useParams<{ prospectId: string }>()

    useEffect(() => {
        if (!isWaitingForStatus) {
            if (isUnauthorized) {
                const autoLogoutsRaw = sessionStorage.getItem('autoLogouts')
                const currentUser = sessionStorage.getItem('currentUser')
                const autoLogouts = autoLogoutsRaw ? JSON.parse(autoLogoutsRaw) : {}
                autoLogouts[
                    currentUser || 'unknown-user'
                ] = `${history.location.pathname}${history.location.search}${history.location.hash}`
                sessionStorage.setItem('autoLogouts', JSON.stringify(autoLogouts))
                logout()

                if (matchCompanyInvitation && prospectId) {
                    history.push(
                        `/company-invitation/${prospectId}/auth${history.location.search}&afterLogin=${encodeURIComponent(
                            `${history.location.pathname}${history.location.search}${history.location.hash}`,
                        )}`,
                    )
                } else {
                    history.push(
                        `/logout/user?afterLogin=${encodeURIComponent(
                            `${history.location.pathname}${history.location.search}${history.location.hash}`,
                        )}`,
                    )
                }
            }

            if (!isProviderAccountActivated && !isPasswordAccountActivated) {
                history.push('/register/confirmation')
            }
        }
    }, [
        prospectId,
        isPasswordAccountActivated,
        isProviderAccountActivated,
        isUnauthorized,
        isWaitingForStatus,
        logout,
        matchCompanyInvitation,
    ])

    const { userActiveContextGroups, activeContext, userContexts } = useUserContext()

    const canAccess = useMemo(() => {
        // TODO: Update when contexts for specialist will be handled in BE
        if (getUserRole() !== 'specialist') {
            return (
                history.location.pathname === '/refresh-user-context' ||
                history.location.pathname === '/unavailable-context' ||
                (userActiveContextGroups ? !!userActiveContextGroups?.some(activeGroup => allowedGroups.includes(activeGroup.name)) : null)
            )
        } else {
            return allowedGroups.includes(ContextGroupName.SPECIALIST)
        }
    }, [userActiveContextGroups, allowedGroups])

    const pendingInvites = useUserPendingInvitations()

    useEffect(() => {
        // TODO: Update when contexts for specialist will be handled in BE
        if (isUserAuthorized && canAccess === false && (getUserRole() === 'specialist' || userActiveContextGroups)) {
            let defaultRedirect = '/dashboard'
            if (getUserRole() === 'specialist') {
                defaultRedirect = `/access-denied`
            }
            if (activeContext?.type === ContextType.USER) {
                if (userContexts && userContexts?.length > 1) {
                    defaultRedirect = `/refresh-user-context?wantedContextId=${
                        userContexts?.find(context => context.type === ContextType.COMPANY)?.contextId
                    }`
                } else if (pendingInvites?.length) {
                    defaultRedirect = '/pending-invitations'
                } else {
                    defaultRedirect = '/dashboard/company-account/company-details'
                }
            }

            history.push(defaultRedirect)
        } else if (
            isUserAuthorized &&
            pendingInvites?.length &&
            history.location.pathname !== '/pending-invitations' &&
            activeContext?.type === ContextType.USER
        ) {
            history.push('/pending-invitations')
        }
    }, [isUserAuthorized, canAccess, userActiveContextGroups, activeContext, pendingInvites, userContexts])

    return (
        <Fragment>
            {isUserAuthorized && canAccess && <RouteComponent {...rest} />}
            <AcceptTerms />
            <PersonalDetailsModal hasPendingInvites={!!pendingInvites?.length} />
        </Fragment>
    )
}

export { PrivateRoute }
