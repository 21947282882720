/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent, useContext, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BackLink } from '../../../components/BackLink'
import { Button } from '../../../components/Button'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { LayoutWithStickyColumn } from '../../../components/layout/LayoutWithStickyColumn'
import { PageWidthWrapper } from '../../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../../components/layout/SpecialistPageWrapper'
import { LeavePageConfirmationModal } from '../../../components/LeavePageConfirmationModal'
import { AddSkillsBox } from '../../../components/skills-questionnaire/AddSkillsBox'
import { SkillsQuestionnaire } from '../../../components/skills-questionnaire/SkillsQuestionnaire'
import { StepsIndicator } from '../../../components/steps-indicator/StepsIndicator'
import { StepStatus } from '../../../components/steps-indicator/types'
import { CvSourceType } from '../../../contracts/cvs'
import { mqMax } from '../../../GlobalStyle'
import { useSpecialistCvParsing } from '../../../hooks/useSpecialistCvParsing'
import { ReduxContext } from '../../../redux/Store'
import { BottomBar } from 'src/components/BottomBar'

type NewSpecialistSkillsRatingPageProps = {}

const NewSpecialistSkillsRatingPage: FunctionComponent<React.PropsWithChildren<NewSpecialistSkillsRatingPageProps>> = () => {
    const { cvParsingId } = useParams<{ cvParsingId: string }>()
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])
    const successNotification = useMemo(
        () => (
            <FlexBox justifyContent='center' alignItems='center'>
                Data added successfully.{' '}
                <Link to={`/profile-summary/${specialistId}`}>
                    <Button
                        size='small'
                        variant='secondary'
                        style={css`
                            margin-left: 16px;
                        `}
                    >
                        View Profile
                    </Button>
                </Link>
            </FlexBox>
        ),
        [specialistId],
    )

    const {
        cvParsingStatus,
        skills,
        handleSkillChange,
        handleSkillAdd,
        handleParsingConfirmation,
        areSomeSkillsNotRated,
        handleCancelProcess,
    } = useSpecialistCvParsing({
        specialistId,
        cvParsingId,
        redirectUrl: '/specialist-dashboard',
        successNotification,
    })

    return (
        <Fragment>
            <SpecialistPageWrapper
                style={css`
                    margin-bottom: 75px;
                    ${mqMax[2]} {
                        margin-bottom: 150px;
                    }
                `}
            >
                <PageWidthWrapper>
                    <BackLink
                        text='Back to dashboard'
                        path='/specialist-dashboard'
                        style={css`
                            margin-bottom: 24px;
                        `}
                    />
                    <h3>Confirm Extracted Data</h3>
                    <p>
                        Choose which skills to add to your profile. Please rate the level of knowledge of all your added skills or remove
                        the ones that you don't want to rate. You will be able to edit this data later on your Profile page.
                    </p>
                </PageWidthWrapper>
                <LayoutWithStickyColumn
                    dataTestId='skills'
                    firstCol={
                        <Fragment>
                            {cvParsingStatus?.cvSource === CvSourceType.LINKEDIN && (
                                <StepsIndicator
                                    styles={css`
                                        margin-bottom: 24px;
                                    `}
                                    steps={[
                                        {
                                            name: 'Experience',
                                            status: StepStatus.DONE,
                                        },
                                        {
                                            name: 'Education',
                                            status: StepStatus.DONE,
                                        },
                                        { name: 'Skills', status: StepStatus.ACTIVE },
                                    ]}
                                />
                            )}
                            <SkillsQuestionnaire specialistSkills={skills} handleSkillChange={handleSkillChange} removable unvalidated />
                        </Fragment>
                    }
                    secondCol={<AddSkillsBox handleSkillSelect={handleSkillAdd} skills={skills} unvalidated specialistId={specialistId} />}
                />
                <BottomBar
                    containerStyles={css`
                        z-index: 1;
                        height: 60px;
                    `}
                >
                    <LayoutWithStickyColumn
                        dataTestId='skills-actions'
                        firstCol={
                            <FlexBox
                                css={css`
                                    align-items: center;
                                    justify-content: space-between;
                                `}
                            >
                                <Button variant='link' onClick={handleCancelProcess}>
                                    Cancel
                                </Button>
                                <FlexBox>
                                    {cvParsingStatus?.cvSource === CvSourceType.LINKEDIN && (
                                        <Link to={`/specialist-cv-parsing/${cvParsingId}/education`}>
                                            <Button variant='tertiary'>Previous</Button>
                                        </Link>
                                    )}
                                    {areSomeSkillsNotRated ? (
                                        <Tooltip title='Rate or Remove all the skills first'>
                                            <div>
                                                <Button
                                                    variant='primary'
                                                    style={css`
                                                        margin-left: 24px;
                                                    `}
                                                    disabled
                                                >
                                                    Add to My Profile
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            variant='primary'
                                            style={css`
                                                margin-left: 24px;
                                            `}
                                            onClick={handleParsingConfirmation}
                                        >
                                            Add to My Profile
                                        </Button>
                                    )}
                                </FlexBox>
                            </FlexBox>
                        }
                    />
                </BottomBar>
            </SpecialistPageWrapper>
            {areSomeSkillsNotRated && (
                <LeavePageConfirmationModal
                    title='Please rate all your skills before leaving'
                    description={`Your profile looks better if you have all of added skills rated. Please rate all your skills or simply remove the ones you don’t want to rate.`}
                    leaveButtonContent='Leave'
                    backButtonContent='Cancel'
                />
            )}
        </Fragment>
    )
}

export { NewSpecialistSkillsRatingPage }
