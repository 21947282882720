/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Button } from '../../components/Button'
import { DataNotFound } from '../../components/DataNotFound'
import { Divider } from '../../components/Divider'
import { InfoAlert } from '../../components/InfoAlert'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { CustomField as CustomFieldType } from '../../contracts/customFields'
import { useMySpecialistsMenuItems } from '../../hooks/useMySpecialistsMenuItems'
import { ReduxContext } from '../../redux/Store'
import { NullableArray } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { addCustomField, getCustomFields, removeCustomField, updateCustomField } from './api'
import { CustomField } from './custom-fields/CustomField'
import { FormValues } from './custom-fields/CustomFieldForm'
import { CustomFieldRemoveModal } from './custom-fields/CustomFieldRemoveModal'

const CustomFieldsPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const { addError } = useNotifications()
    const log = useLogger('error')

    const [myCustomFields, setMyCustomFields] = useState<NullableArray<CustomFieldType>>(null)
    const [idToRemove, setIdToRemove] = useState<string>()
    const isAddingCustomFieldDisabled = useMemo(
        () => myCustomFields?.some(field => !field.id) || myCustomFields?.length === 10,
        [myCustomFields],
    )

    const closeRemoveCustomFieldModal = useCallback(() => {
        setIdToRemove(undefined)
    }, [])

    const fetchCustomFields = useCallback(() => {
        layoutToggleLoader(true)
        getCustomFields()
            .then(data => {
                setMyCustomFields(data)
            })
            .catch(e => {
                log(e)
                addError()
            })
            .finally(() => layoutToggleLoader(false))
    }, [layoutToggleLoader, log, addError])

    const { menuItems, applicationName } = useMySpecialistsMenuItems()

    const handleRemoveCustomField = useCallback(() => {
        if (idToRemove) {
            layoutToggleLoader(true)
            removeCustomField(idToRemove)
                .then(() => {
                    setMyCustomFields(fields => (fields ? fields.filter(field => field.id !== idToRemove) : fields))
                })
                .catch(e => {
                    log(e)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
            closeRemoveCustomFieldModal()
        }
    }, [closeRemoveCustomFieldModal, idToRemove, log, addError, layoutToggleLoader])

    const handleCustomFieldSubmit = useCallback(
        (data: FormValues, id: string, setFieldNameError: () => void) => {
            layoutToggleLoader(true)
            if (id) {
                updateCustomField(id, data)
                    .then((updatedCustomField: CustomFieldType) => {
                        setMyCustomFields(fields =>
                            fields ? fields.map(field => (field.id === id ? updatedCustomField : field)) : [updatedCustomField],
                        )
                    })
                    .catch(e => {
                        if (e.status === 409) {
                            setFieldNameError()
                        }
                        log(e)
                        addError()
                    })
                    .finally(() => layoutToggleLoader(false))
            } else {
                addCustomField(data)
                    .then((newCustomField: CustomFieldType) => {
                        setMyCustomFields(fields =>
                            fields ? fields.map(field => (field.id === id ? newCustomField : field)) : [newCustomField],
                        )
                    })
                    .catch(e => {
                        if (e.status === 409) {
                            setFieldNameError()
                        }
                        log(e)
                        addError()
                    })
                    .finally(() => layoutToggleLoader(false))
            }
        },
        [log, addError, layoutToggleLoader],
    )

    const handleAddCustomFieldClick = useCallback(() => {
        const newField = { fieldName: '', id: '' }
        setMyCustomFields(fields => (fields ? [newField, ...fields] : [newField]))
    }, [])

    useEffect(() => {
        fetchCustomFields()
    }, [fetchCustomFields])

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    padding: 0 16px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        max-width: 550px;
                        margin: 40px 0 100px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}
                    >
                        <h4>Custom Fields</h4>
                        {!!myCustomFields?.length && (
                            <Button
                                css={css`
                                    margin-bottom: 16px;
                                `}
                                onClick={handleAddCustomFieldClick}
                                disabled={isAddingCustomFieldDisabled}
                                dataTestId='custom-fields-add-button'
                            >
                                Add Custom Field
                            </Button>
                        )}
                    </div>
                    <Divider />
                    <div
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        A Custom Field is an additional field of information you add about your Specialists e.g. Department Name, Team Name,
                        Project Group etc. When you create Custom Fields, they are added automatically to the General Information of a
                        Specialist’s Profile under a new ‘Additional Information’ section. You can add up to 10 Custom Fields.{' '}
                    </div>
                    {!(myCustomFields?.length === 1 && !myCustomFields[0].id) && !!myCustomFields?.length && (
                        <InfoAlert
                            description='Editing or Deleting Custom Fields will result in changing this information on all Specialists’ Profiles.'
                            dataTestId='custom-fields-info-box'
                        />
                    )}
                    <Divider
                        style={css`
                            margin: 16px 0;
                        `}
                    />
                    {myCustomFields?.length === 0 && (
                        <DataNotFound iconName='no-data' description='Currently you have no custom fields added.'>
                            <Button onClick={handleAddCustomFieldClick} dataTestId='custom-fields-no-data-found-add-button'>
                                Add Custom Field
                            </Button>
                        </DataNotFound>
                    )}
                    {myCustomFields?.map(customField => (
                        <CustomField
                            key={customField.id}
                            setIdToRemove={setIdToRemove}
                            customField={customField}
                            handleCustomFieldSubmit={handleCustomFieldSubmit}
                            setMyCustomFields={setMyCustomFields}
                        />
                    ))}
                </div>
            </div>
            <CustomFieldRemoveModal opened={!!idToRemove} onClose={closeRemoveCustomFieldModal} onRemove={handleRemoveCustomField} />
        </DashboardLayout>
    )
}

export { CustomFieldsPage }
