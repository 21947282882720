import { BrowserHistory } from 'history'
import { FunctionComponent, useLayoutEffect, useState } from 'react'
import { Router } from 'react-router-dom'

type CustomRouterProps = {
    history: BrowserHistory
}

const CustomRouter: FunctionComponent<React.PropsWithChildren<CustomRouterProps>> = ({ history, ...props }) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    })

    useLayoutEffect(() => history.listen(setState), [history])

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />
}

export { CustomRouter }
