/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useEffect, useState } from 'react'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { Input } from '../inputs/Input'
import { Modal, ModalProps } from '../Modal'
import { useNotifications } from '../notification/NotificationProvider'
import { ButtonContainer, GreyParagraph } from '../shared/shared-styles'

type ShareProfileModalProps = ModalProps & { onClose: () => void; shareLinkId: string; anonymous?: boolean }

const ShareProfileModal: FunctionComponent<React.PropsWithChildren<ShareProfileModalProps>> = ({
    shareLinkId,
    onClose,
    opened,
    anonymous,
}) => {
    const { addSuccess } = useNotifications()
    const [shareLink, setShareLink] = useState<string>('')

    useEffect(() => {
        if (shareLinkId && !anonymous) {
            const publicProfileURL = `${window.location.protocol}//${window.location.host}/public/profile/${shareLinkId}`
            setShareLink(publicProfileURL)
        }
        if (shareLinkId && anonymous) {
            const anonymousProfileURL = `${window.location.protocol}//${window.location.host}/anonymous/profile/${shareLinkId}`
            setShareLink(anonymousProfileURL)
        }
    }, [shareLinkId, anonymous])

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(shareLink)
        addSuccess('Link copied!')

        onClose()
    }

    return (
        <Modal onClose={onClose} opened={opened}>
            <h5>Share the link to the anonymized specialist profile</h5>
            <GreyParagraph>Let others take a look at this specialist profile.</GreyParagraph>
            <GreyParagraph>Shareable Link:</GreyParagraph>
            <Input name='sharableLink' value={shareLink ? shareLink : 'Loading...'} disabled={true}></Input>
            <Divider />
            <ButtonContainer>
                <Button
                    css={css`
                        margin-right: 28px;
                    `}
                    variant='link'
                    onClick={onClose}
                >
                    Go Back
                </Button>
                <Button onClick={copyToClipboard} variant='primary' dataTestId='copy-and-close'>
                    Copy & Close
                </Button>
            </ButtonContainer>
        </Modal>
    )
}

export { ShareProfileModal }
