/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { ContentLoader } from '../../components/layout/Loader'
import { convertTimestampToDateString } from '../../utils/dates'
import { Application } from 'src/contracts/open-opportunities/contracts'

type AppliedDropdownProps = {
    appliedOptions: Array<Application>
    isLoadingApplied?: boolean
}

const AppliedDropdown: FunctionComponent<React.PropsWithChildren<AppliedDropdownProps>> = ({ appliedOptions, isLoadingApplied }) => {
    const theme = useTheme()

    return (
        <div
            className='applied-dropdown'
            css={css`
                background-color: ${theme.colors.white};
                border: 1px solid ${theme.colors.beige_4};
                border-radius: 4px;
                box-shadow: ${theme.shadow.small};
                display: none;
                font-size: 12px;
                position: absolute;
                width: 250px;
                z-index: 100;
            `}
        >
            {isLoadingApplied ? (
                <ContentLoader />
            ) : appliedOptions.length ? (
                <Fragment>
                    {appliedOptions.map(application => {
                        return (
                            application.firstName &&
                            application.lastName && (
                                <FlexBox
                                    key={application.applicationId}
                                    justifyContent='space-between'
                                    css={css`
                                        border-bottom: 1px solid ${theme.colors.beige_3};
                                        padding: 13px 15px;

                                        &:last-child {
                                            border-bottom: 0;
                                        }
                                    `}
                                >
                                    <div
                                        css={css`
                                            color: ${theme.colors.gray_6};
                                        `}
                                    >
                                        {application.firstName} {application.lastName}{' '}
                                    </div>
                                    {application.appliedOn && (
                                        <div
                                            css={css`
                                                color: ${theme.colors.gray_4};
                                            `}
                                        >
                                            sent on: {application.appliedOn ? convertTimestampToDateString(application.appliedOn) : '-'}
                                        </div>
                                    )}
                                </FlexBox>
                            )
                        )
                    })}
                </Fragment>
            ) : (
                <div
                    css={css`
                        line-height: 40px;
                        text-align: center;
                    `}
                >
                    Not available
                </div>
            )}
        </div>
    )
}

export { AppliedDropdown }
