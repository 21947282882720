/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import pic_1 from './../assets/pic_1.svg'
import pic_10 from './../assets/pic_10.svg'
import pic_11 from './../assets/pic_11.svg'
import pic_12 from './../assets/pic_12.svg'
import pic_2 from './../assets/pic_2.svg'
import pic_3 from './../assets/pic_3.svg'
import pic_4 from './../assets/pic_4.svg'
import pic_5 from './../assets/pic_5.svg'
import pic_6 from './../assets/pic_6.svg'
import pic_7 from './../assets/pic_7.svg'
import pic_8 from './../assets/pic_8.svg'
import pic_9 from './../assets/pic_9.svg'

const SIGNS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

type GameSignProps = {
    id: number
    size?: number
    bordered?: boolean
    onClick?: (id: number) => void
    isWin?: boolean
    isSelected?: boolean
}

const GameSign: FunctionComponent<React.PropsWithChildren<GameSignProps>> = ({
    id,
    size = 60,
    bordered = false,
    onClick,
    isWin = false,
    isSelected = false,
}) => {
    const availableSigns = [pic_1, pic_2, pic_3, pic_4, pic_5, pic_6, pic_7, pic_8, pic_9, pic_10, pic_11, pic_12]

    const handleClick = useCallback(() => {
        onClick && onClick(id)
    }, [id, onClick])

    return (
        <div
            onClick={handleClick}
            css={css`
                width: ${size * 2 - 20 + 'px'};
                height: ${size * 2 - 20 + 'px'};
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: ${COLOR_PALETTE.white};
                position: relative;
                box-shadow: ${bordered ? '0px 4px 12px rgba(0, 0, 0, 0.08)' : 'none'};
            `}
        >
            {bordered && (
                <div
                    css={css`
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: ${size * 2 - 20 + 'px'};
                        height: ${size * 2 - 20 + 'px'};
                        background-color: transparent;
                        border-width: 5px;
                        border-style: solid;
                        border-color: ${isSelected ? (isWin ? COLOR_PALETTE.green_6 : COLOR_PALETTE.red_4) : 'transparent'};
                    `}
                    className='animate__animated animate__bounceOut'
                />
            )}

            <img
                src={availableSigns[id]}
                alt=''
                css={css`
                    width: ${size + 'px'};
                    height: ${size + 'px'};
                `}
            />
        </div>
    )
}

export { GameSign, SIGNS }
