/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from 'src/components/filters/FiltersBarHelpers'
import { AutocompleteSelect } from 'src/components/inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from 'src/components/inputs/autocomplete-select/Option'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { countArrayFilters } from 'src/utils/filters'
import { CvsFiltersResponse } from '../contracts'
import { Checkbox } from 'src/components/inputs/Checkbox'

type CvsFiltersBarProps = {
    possibleFilters: CvsFiltersResponse
    selectedFilters: CvsFiltersResponse
    opened: boolean
    onChangeFilters: (filters: CvsFiltersResponse) => void
    onClearAll: () => void
}

const CvsFiltersBar: FunctionComponent<CvsFiltersBarProps> = ({
    possibleFilters,
    selectedFilters,
    opened,
    onChangeFilters,
    onClearAll,
}) => {
    const onSelectedValuesChange = useCallback(
        (values: Array<OptionType>, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: values,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    const onChange = useCallback(
        (checked: boolean, filterName: string) => {
            onChangeFilters({
                ...selectedFilters,
                [filterName]: checked,
            })
        },
        [onChangeFilters, selectedFilters],
    )

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='TA Owner'
                            options={possibleFilters.taOwners}
                            filterName='taOwners'
                            currentValues={selectedFilters.taOwners}
                            onSelectedValuesChange={onSelectedValuesChange}
                            dataTestId='taOwners'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='SH Name'
                            options={possibleFilters.shNames}
                            filterName='shNames'
                            currentValues={selectedFilters.shNames}
                            onSelectedValuesChange={onSelectedValuesChange}
                            dataTestId='shNames'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 2.5rem;
                            `}
                        >
                            <Checkbox
                                checkboxLabel='MSA'
                                name='msaSigned'
                                checked={selectedFilters.msaSigned}
                                onChange={checked => onChange(checked, 'msaSigned')}
                                dataTestId='msaSigned'
                            />
                        </div>
                    </FilterColumn>
                    <FilterColumn>
                        <div
                            css={css`
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                height: 2.5rem;
                            `}
                        >
                            <Checkbox
                                checkboxLabel='Premium'
                                name='premium'
                                checked={selectedFilters.premium}
                                onChange={checked => onChange(checked, 'premium')}
                                dataTestId='premium'
                            />
                        </div>
                    </FilterColumn>
                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                        `}
                    >
                        <FiltersClearTrigger
                            onClear={onClearAll}
                            quantity={countArrayFilters(selectedFilters, Object.keys(selectedFilters))}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { CvsFiltersBar }
