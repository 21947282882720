import { emailRegex } from 'src/utils/regexes'
import { OptionObject } from '../../../inputs/autocomplete-select/Option'
import { MAX_INTEGER } from '../../../../validation/consts'
import { INVALID_NUMBER, MAX_INTEGER_MESSAGE } from '../../../../validation/validation-messages'
import { yup } from '../../../../validation/yup'
import { OpportunityLocationTypeEnum, OpportunityLocationTypeV2Enum } from '../../../../contracts/opportunities-manager/contracts'

const opportunityFormValidationSchema = yup.object().shape({
    opportunityTitle: yup.string().required(),
    internalDetails: yup.object().shape({
        internalName: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        clientName: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        opportunityOwner: yup.string(),
        internalNotes: yup.string(),
        customCvTemplate: yup.boolean(),
        earlyAccessExpiration: yup.number().nullable(),
    }),
    projectDetails: yup.object().shape({
        projectTitle: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        projectIndustry: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        paymentPeriod: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        projectDescription: yup.string(),
    }),
    opportunityDetails: yup.object().shape({
        startDate: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        endDate: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        offerType: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        numberOfVacancies: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        currency: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        minimumRate: yup.lazy(value =>
            value === ''
                ? yup.string()
                : yup
                      .number()
                      .when('maximumRate', (maximumRate, schema) =>
                          maximumRate !== ''
                              ? schema
                                    .positive()
                                    .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                                    .typeError(INVALID_NUMBER)
                                    .max(maximumRate, 'Minimum rate can not be bigger than maximum rate')
                              : schema.positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).typeError(INVALID_NUMBER),
                      ),
        ),
        maximumRate: yup.lazy(value =>
            value === ''
                ? yup.string()
                : yup
                      .number()
                      .when('minimumRate', (minimumRate, schema) =>
                          minimumRate !== ''
                              ? schema
                                    .positive()
                                    .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                                    .typeError(INVALID_NUMBER)
                                    .min(minimumRate, 'Maximum rate can not be lower than minimum rate')
                              : schema.positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).typeError(INVALID_NUMBER),
                      ),
        ),
    }),
    location: yup.object().shape({
        locationType: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        locationTypeV2: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        countriesAndRegions: yup.array().when('locationType', {
            is: OpportunityLocationTypeEnum.SPECIFIC_LOCATION,
            then: yup
                .array()
                .of(
                    yup.object().shape({
                        value: yup.string().required(),
                        groupName: yup.string().required(),
                    }),
                )
                .min(1, 'Please select at least one Country or Region'),
        }),
        countryCitiesList: yup.array().when('locationTypeV2', {
            is: (val: OpportunityLocationTypeV2Enum) => val !== OpportunityLocationTypeV2Enum.REMOTE,
            then: yup
                .array()
                .of(
                    yup.object().shape({
                        value: yup.string().required(),
                        groupName: yup.string().required(),
                    }),
                )
                .test('country-cities-valid', 'Please select at least one city for each country', (val, ctx) => {
                    const countriesOfSelectedCities = val?.map(city => city.groupName)
                    const countriesAndRegions = ctx.parent.countriesAndRegions as Array<OptionObject> | undefined
                    return !!countriesAndRegions?.every(country => countriesOfSelectedCities?.includes(country.value))
                }),
        }),
        otherLocationRequirements: yup.boolean(),
        timeZone: yup.string(),
        otherLocationRequirementsText: yup.string(),
    }),
    jobDetails: yup.object().shape({
        technicalRequirements: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        minYearsOfExperience: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        requiredTechnicalSkills: yup
            .array()
            .of(yup.string())
            .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.min(1, 'Minimum 1 item must be selected'))),
        keyTechnicalSkills: yup
            .array()
            .of(yup.string())
            .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.min(1, 'Minimum 1 item must be selected'))),
        preferredTechnicalSkills: yup.array().of(yup.string()),
        requiredCertifications: yup.string(),
        mainResponsibilities: yup.string(),
        earlyAccessExpiration: yup.number().nullable(),
    }),
})

const smeOpportunityFormValidationSchema = yup.object().shape({
    opportunityTitle: yup.string().required(),
    internalDetails: yup.object().shape({
        internalName: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        clientName: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        opportunityOwner: yup.string(),
        internalNotes: yup.string(),
        customCvTemplate: yup.boolean(),
        earlyAccessExpiration: yup.number().nullable(),
    }),
    projectDetails: yup.object().shape({
        projectTitle: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        projectIndustry: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        paymentPeriod: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        projectDescription: yup.string(),
    }),
    opportunityDetails: yup.object().shape({
        startDate: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        endDate: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        offerType: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        numberOfVacancies: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        currency: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        minimumRate: yup.lazy(value =>
            value === ''
                ? yup.string()
                : yup
                      .number()
                      .when('maximumRate', (maximumRate, schema) =>
                          maximumRate !== ''
                              ? schema
                                    .positive()
                                    .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                                    .typeError(INVALID_NUMBER)
                                    .max(maximumRate, 'Minimum rate can not be bigger than maximum rate')
                              : schema.positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).typeError(INVALID_NUMBER),
                      ),
        ),
        maximumRate: yup.lazy(value =>
            value === ''
                ? yup.string()
                : yup
                      .number()
                      .when('minimumRate', (minimumRate, schema) =>
                          minimumRate !== ''
                              ? schema
                                    .positive()
                                    .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                                    .typeError(INVALID_NUMBER)
                                    .min(minimumRate, 'Maximum rate can not be lower than minimum rate')
                              : schema.positive().max(MAX_INTEGER, MAX_INTEGER_MESSAGE).typeError(INVALID_NUMBER),
                      ),
        ),
    }),
    location: yup.object().shape({
        locationType: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        locationTypeV2: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        countriesAndRegions: yup.array().when('locationType', {
            is: OpportunityLocationTypeEnum.SPECIFIC_LOCATION,
            then: yup
                .array()
                .of(
                    yup.object().shape({
                        value: yup.string().required(),
                        groupName: yup.string().required(),
                    }),
                )
                .min(1, 'Please select at least one Country or Region'),
        }),
        countryCitiesList: yup.array().when('locationTypeV2', {
            is: (val: OpportunityLocationTypeV2Enum) => val !== OpportunityLocationTypeV2Enum.REMOTE,
            then: yup
                .array()
                .of(
                    yup.object().shape({
                        value: yup.string().required(),
                        groupName: yup.string().required(),
                    }),
                )
                .test('country-cities-valid', 'Please select at least one city for each country', (val, ctx) => {
                    const countriesOfSelectedCities = val?.map(city => city.groupName)
                    const countriesAndRegions = ctx.parent.countriesAndRegions as Array<OptionObject> | undefined
                    return !!countriesAndRegions?.every(country => countriesOfSelectedCities?.includes(country.value))
                }),
        }),
        otherLocationRequirements: yup.boolean(),
        timeZone: yup.string(),
        otherLocationRequirementsText: yup.string(),
        contactEmail: yup.string(),
    }),
    jobDetails: yup.object().shape({
        technicalRequirements: yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        minYearsOfExperience: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .number()
                      .positive()
                      .max(MAX_INTEGER, MAX_INTEGER_MESSAGE)
                      .typeError(INVALID_NUMBER)
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
        requiredTechnicalSkills: yup
            .array()
            .of(yup.string())
            .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.min(1, 'Minimum 1 item must be selected'))),
        keyTechnicalSkills: yup
            .array()
            .of(yup.string())
            .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.min(1, 'Minimum 1 item must be selected'))),
        preferredTechnicalSkills: yup.array().of(yup.string()),
        requiredCertifications: yup.string(),
        mainResponsibilities: yup.string(),
        earlyAccessExpiration: yup.number().nullable(),
    }),
    contactDetails: yup.object().shape({
        contactEmail: yup.lazy(value =>
            value === ''
                ? yup.string().when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required()))
                : yup
                      .string()
                      .matches(emailRegex, 'Invalid email format. Use a valid email address.')
                      .when('$isDraft', (isDraft, schema) => (isDraft ? undefined : schema.required())),
        ),
    }),
})

export { opportunityFormValidationSchema, smeOpportunityFormValidationSchema }
