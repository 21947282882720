/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import arrowImage from '../../assets/images/arrow.svg'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { mqMin } from '../../GlobalStyle'
import { Nullable } from '../../types'

import { CandidatesDropdown } from './CandidatesDropdown'
import { CandidatesDropzone } from './CandidatesDropzone'
import { CandidatesListItem } from './CandidatesListItem'
import { ApplicationData } from './ApplicationWizard/redux/types'

type CandidatesListColumnProps = {
    applications: Array<ApplicationData>
    handleSelectApplication: (applicationId: string) => void
    handleRemoveApplication: (applicationId: string) => void
    selectedApplicationId: Nullable<string>
    isSendingApplication: boolean
    handleApplyWithSpecialist: (specialistId: string) => void
    handleApplyWithCv: (files: Array<File> | File) => void
    opportunityId?: string
}

const CandidatesListColumn: FunctionComponent<React.PropsWithChildren<CandidatesListColumnProps>> = ({
    applications,
    handleSelectApplication,
    handleRemoveApplication,
    selectedApplicationId,
    isSendingApplication,
    handleApplyWithSpecialist,
    handleApplyWithCv,
    opportunityId,
}) => {
    const theme = useTheme()
    const isApplicationsObjectEmpty = applications.length === 0

    return (
        <div
            css={css`
                border-right: 1px solid ${theme.colors.violet_2};
                padding: 24px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100vh - 58px);
                overflow-y: scroll;
                background-color: ${theme.colors.white};
                width: 100%;
                position: absolute;
                z-index: 1;
                display: ${selectedApplicationId ? 'none' : undefined};
                ${mqMin[1]} {
                    width: 300px;
                    position: relative;
                    display: flex;
                }
                ${mqMin[3]} {
                    width: 452px;
                }
            `}
        >
            <FlexBox
                css={css`
                    flex-direction: column;
                    height: calc(100vh - 360px);
                `}
            >
                <div
                    css={css`
                        background-color: ${theme.colors.beige_1};
                        padding: 8px 16px;
                        color: ${theme.colors.gray_4};
                        font-weight: 500;
                        border-radius: 4px;
                        margin-bottom: 16px;
                        width: 100%;
                    `}
                >
                    My Candidates ({applications.length}):
                </div>
                <FlexBox
                    css={css`
                        flex-direction: column;
                        text-align: center;
                        align-items: center;
                        justify-content: ${!isSendingApplication && isApplicationsObjectEmpty ? 'center' : 'flex-start'};
                        height: calc(100vh - 412px);
                        width: 100%;
                        overflow: scroll;
                    `}
                >
                    {applications.map(application => (
                        <CandidatesListItem
                            key={application.applicationId}
                            application={application}
                            onClick={handleSelectApplication}
                            onRemove={handleRemoveApplication}
                            selectedApplication={selectedApplicationId}
                        />
                    ))}
                    {isSendingApplication && <CandidatesListItem />}
                    {!isSendingApplication && isApplicationsObjectEmpty && (
                        <div>
                            <h6>Add your first Candidate</h6>
                            <p
                                css={css`
                                    max-width: 270px;
                                    color: ${theme.colors.gray_3};
                                `}
                            >
                                You can apply with Specialists already added to My Specialists App or simply use CV(s).
                            </p>
                            <img
                                src={arrowImage}
                                alt='arrow'
                                css={css`
                                    padding-right: 35px;
                                `}
                            />
                        </div>
                    )}
                </FlexBox>
            </FlexBox>
            <div>
                <div
                    css={css`
                        font-weight: 500;
                        margin-bottom: 16px;
                    `}
                >
                    Add New Candidates
                </div>
                <CandidatesDropdown
                    handleApplyWithSpecialist={handleApplyWithSpecialist}
                    isSendingApplication={isSendingApplication}
                    opportunityId={opportunityId}
                />
                <div
                    css={css`
                        color: ${theme.colors.gray_3};
                        margin: 10px 0;
                        text-align: center;
                    `}
                >
                    OR
                </div>
                <CandidatesDropzone handleApplyWithCv={handleApplyWithCv} isSendingApplication={isSendingApplication} />
            </div>
        </div>
    )
}

export { CandidatesListColumn }
