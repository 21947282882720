/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { MySpecialistsAnonymousEducation } from '../../../contracts/profile/education'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { EducationContainer, EducationDetailRow, EducationHeader, EducationTitle, EducationValue } from '../shared/styled'
import { DurationComponentFromTo } from './DurationComponentFromTo'

type AnonymousEducationProps = { education: MySpecialistsAnonymousEducation }

const MySpecialistsAnonymousEducationItem: FunctionComponent<React.PropsWithChildren<AnonymousEducationProps>> = ({ education }) => {
    const theme = useTheme()
    return (
        <Fragment>
            <EducationContainer
                css={css`
                    box-shadow: ${theme.shadow.small};
                `}
            >
                {(education.type === 'BACHELOR_STUDIES' ||
                    education.type === 'MASTER_STUDIES' ||
                    education.type === 'PHD_STUDIES' ||
                    education.type === 'OTHER_STUDIES') && (
                    <Fragment>
                        <FlexBox
                            css={css`
                                justify-content: space-between;
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                                ${mqMax[1]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <EducationHeader>Higher Education</EducationHeader>

                            {education.from && <DurationComponentFromTo education={education} label='Duration: ' />}
                        </FlexBox>

                        {education.studiesSpecialization && (
                            <EducationDetailRow>
                                <EducationTitle>Course:</EducationTitle>
                                <EducationValue>{education.studiesSpecialization}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.studiesDepartment && (
                            <EducationDetailRow>
                                <EducationTitle>Faculty:</EducationTitle>
                                <EducationValue>{education.studiesDepartment}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.studiesUniversity && (
                            <EducationDetailRow>
                                <EducationTitle>University:</EducationTitle>
                                <EducationValue>{education.studiesUniversity}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}

                {education.type === 'LANGUAGE_COURSE' && (
                    <Fragment>
                        <FlexBox
                            css={css`
                                justify-content: space-between;
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                                ${mqMax[1]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <EducationHeader>Language Course</EducationHeader>

                            {education.from && <DurationComponentFromTo education={education} label='Duration:' />}
                        </FlexBox>
                        {education.languageName && (
                            <EducationDetailRow>
                                <EducationTitle>Language:</EducationTitle>
                                <EducationValue>{education.languageName}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.languageSchool && (
                            <EducationDetailRow>
                                <EducationTitle>School:</EducationTitle>
                                <EducationValue>{education.languageSchool}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}
                {education.type === 'CERTIFICATE_COURSE' && (
                    <Fragment>
                        <FlexBox
                            css={css`
                                justify-content: space-between;
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                                ${mqMax[1]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <EducationHeader>Certificate Course</EducationHeader>

                            {education.certificationAcquisitionDate && <DurationComponentFromTo education={education} label='Validity:' />}
                        </FlexBox>

                        {education.certificationOrganization && (
                            <EducationDetailRow>
                                <EducationTitle>Organisation:</EducationTitle>
                                <EducationValue>{education.certificationOrganization}</EducationValue>
                            </EducationDetailRow>
                        )}

                        {education.certificationTitle && (
                            <EducationDetailRow>
                                <EducationTitle>Title:</EducationTitle>
                                <EducationValue>{education.certificationTitle}</EducationValue>
                            </EducationDetailRow>
                        )}
                    </Fragment>
                )}
            </EducationContainer>
        </Fragment>
    )
}

export { MySpecialistsAnonymousEducationItem }
