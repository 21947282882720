/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { AppIcon } from '../../../components/Icon'
import { Modal } from '../../../components/Modal'
import { Result } from '../tests'

type ResultModalProps = {
    result: Result
    specialistId: string
}

const ResultModal: FunctionComponent<React.PropsWithChildren<ResultModalProps>> = ({ result, specialistId }) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const handleClose = useCallback(() => {
        navigate(`/skills-test/${specialistId}`)
    }, [navigate, specialistId])

    return (
        <Modal opened onClose={handleClose}>
            <AppIcon
                name='check'
                status='static'
                color={theme.colors.green_3}
                size={48}
                style={css`
                    margin-bottom: 24px;
                `}
            />
            <h4>{result.testName} test completed</h4>
            <p
                css={css`
                    font-size: 16px;
                    font-weight: 500;
                `}
            >
                Your score:{' '}
                <span
                    css={css`
                        color: ${theme.colors.green_4};
                    `}
                >
                    {result.correctAnswers}/{result.totalQuestions}
                </span>
            </p>
            <p>
                Based on our assessment your{' '}
                <strong>
                    {result.testName} level is {result.assessment}.
                </strong>
            </p>
            <p>
                Thank you for completing this test. We are currently collecting data and work on a way to display results in your profile as
                well as suggest new ways for your development.
            </p>
            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button onClick={handleClose} dataTestId='go-to-dashboard-button'>
                    Go to Dashboard
                </Button>
            </div>
        </Modal>
    )
}

export { ResultModal }
