import { Nullable, StringMapping } from '../types'

export type UserSegmentationCompany = {
    id: string
    type: Nullable<'PARTNER' | 'CLIENT'>
    userId: Nullable<string>
    legalName: string
    country: string
    city: string
    website: string
    roleInTheCompany: string
    workEmailAddress: string
    status?: string
    createdAt?: number
    companySize: string
    clientIndustries: Nullable<Array<string>>
    clientSizeOfITWorkForce: Nullable<string>
    partnerSpecialistLocations: Nullable<Array<string>>
    partnerClutchUrl: Nullable<string>
    partnerCoreTechStack: Nullable<Array<string>>
    partnerTechStack: Nullable<Array<string>>
    partnerIndustries: Nullable<Array<string>>
    msaSigned?: boolean
    smeCloud?: boolean
}

export interface Company {
    address: string
    city: string
    country: string
    id: string
    legalName: string
    numberOfNonAdminEmployees: string
    postalCode: string
    registrationNumber: string
    roleInTheCompany: string
    userId: string
    website: string
    workEmailAddress: string
    companyWorkforce?: string
    status?: 'INACTIVE' | 'VERIFIED'
    createdAt?: number
    fake?: boolean
    smeCloud: boolean
}

export enum CompanySize {
    SELF_EMPLOYED = 'SELF_EMPLOYED',
    MICRO = 'MICRO',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    ENTERPRISE = 'ENTERPRISE',
}

const companySizeMapping: StringMapping = {
    [CompanySize.SELF_EMPLOYED]: 'Self-employed: 1 Employee',
    [CompanySize.MICRO]: 'Micro Company: 2-9 Employees',
    [CompanySize.SMALL]: 'Small Company: 10-49 Employees',
    [CompanySize.MEDIUM]: 'Medium-sized Company: 50-249 Employees',
    [CompanySize.ENTERPRISE]: 'Enterprise-level Company: 250 Employees or more',
}

const companySizes = Object.keys(companySizeMapping)

export { companySizeMapping, companySizes }
