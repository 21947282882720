/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { Modal } from '../../../../components/Modal'

type DeleteConfirmationModalProps = {
    isVisible: boolean
    handleRejection: () => void
    handleConfirmation: () => void
}

const DeleteConfirmationModal: FunctionComponent<React.PropsWithChildren<DeleteConfirmationModalProps>> = ({
    isVisible,
    handleRejection,
    handleConfirmation,
}) => {
    const theme = useTheme()

    return (
        <Modal onClose={handleRejection} opened={isVisible}>
            <h5
                css={css`
                    color: ${theme.colors.gray_6};
                `}
                data-testid='cancel-confirmation-modal-check'
            >
                Are you sure about deleting?
            </h5>
            <p
                css={css`
                    color: ${theme.colors.gray_4};
                `}
            >
                Deleting Feedback Team is an irreversible action.
            </p>
            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;
                `}
            >
                <Button
                    css={css`
                        margin-right: 29px;
                    `}
                    variant='link'
                    onClick={handleRejection}
                >
                    Cancel
                </Button>
                <Button variant='delete' onClick={handleConfirmation}>
                    Delete
                </Button>
            </div>
        </Modal>
    )
}

export { DeleteConfirmationModal }
