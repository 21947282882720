/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { COLOR_PALETTE, mqMin } from '../../../GlobalStyle'

type DropdownContainerProps = {
    noLabel?: boolean
    styles?: SerializedStyles
    forceDownOpen?: boolean
}

const DropdownContainer: FunctionComponent<React.PropsWithChildren<DropdownContainerProps>> = ({
    noLabel,
    children,
    forceDownOpen,
    styles = {},
}) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [topStyle, setTopStyle] = useState<string>('')

    useEffect(() => {
        if (!forceDownOpen && containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect()

            if (rect.y + rect.height <= window.innerHeight) {
                if (noLabel) {
                    setTopStyle(`top: 48px;`)
                } else {
                    setTopStyle(`top: 72px;`)
                }
            } else {
                setTopStyle(`top: -${rect.height + 8}px;`)
            }
        } else {
            if (noLabel) {
                setTopStyle(`top: 48px;`)
            } else {
                setTopStyle(`top: 72px;`)
            }
        }
    }, [containerRef, forceDownOpen, noLabel])

    return (
        <div
            ref={containerRef}
            css={css`
                position: absolute;
                left: 0;
                background-color: ${COLOR_PALETTE.white};
                border: 1px solid ${COLOR_PALETTE.gray_2};
                z-index: 300;
                border-radius: 2px;
                box-shadow: 0 4px 12px ${COLOR_PALETTE.box_shadow_color};
                min-width: 220px;
                width: 100%;

                ${topStyle}

                ${mqMin[1]} {
                    width: auto;
                    min-width: max(100%, 220px);
                }

                ${styles}
            `}
        >
            {children}
        </div>
    )
}

const DropdownDivider = styled.div`
    width: calc(100% - 36px);
    margin: 0 auto;
    height: 1px;
    background-color: ${COLOR_PALETTE.gray_2};
`

const DropdownComponent = (InnerComponent: any) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const rangeRef = useRef<HTMLDivElement>()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isOpen, setIsOpen] = useState(props.isOpen ? true : false)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    const handleClickOutside = (event: MouseEvent) => {
        if (isOpen && rangeRef && !(rangeRef as { current: HTMLDivElement }).current.contains(event.target as Node)) {
            setIsOpen(false)
        }
    }

    return <InnerComponent {...props} rangeRef={rangeRef} isOpen={isOpen} setIsOpen={setIsOpen} />
}

export { DropdownComponent, DropdownContainer, DropdownDivider }
