import styled from '@emotion/styled'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { Button } from '../Button'
import { Link } from 'react-router-dom'

const GreyParagraph = styled.p`
    color: ${COLOR_PALETTE.gray_4};
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const NoDataContainer = styled.div`
    margin-top: 24px;
`

const CVContainer = styled(FlexBox)<{ margin?: string }>`
    flex-grow: 1;
    flex-direction: column;
    margin: ${({ margin }) => margin || 0};
`

const CVButton = styled(Button)`
    margin-top: 16px;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    margin-top: 10px;
`

export { GreyParagraph, ButtonContainer, NoDataContainer, CVContainer, CVButton, StyledLink }
