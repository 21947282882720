/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { Button } from '../Button'
import { blackToVioletDarkFilter, Icon } from '../Icon'
import { ContentLoader } from '../layout/Loader'
import { DropParserProps, Dropzone } from './Dropzone'

const MAX_FILE_SIZE = 20971520

type CvDropzoneProps = {
    handleDrop: (files: File | Array<File>) => void
    parser?: (props: DropParserProps) => void
    isUploading: boolean
    multiple?: boolean
    pdfOnly?: boolean
}

const CvDropzone: FunctionComponent<React.PropsWithChildren<CvDropzoneProps>> = ({
    handleDrop,
    parser,
    isUploading,
    multiple = false,
    pdfOnly = false,
}) => {
    const theme = useTheme()
    return (
        <Dropzone
            dataTestId='cv-dropzone'
            onChange={handleDrop}
            maxSize={MAX_FILE_SIZE}
            parser={parser}
            accept={
                pdfOnly
                    ? { 'application/pdf': [] }
                    : { 'application/pdf': [], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] }
            }
            multiple={multiple}
            dropErrorMessages={{
                invalidType: `This file type cannot be uploaded. Accepted file ${pdfOnly ? 'type is pdf' : 'types are .pdf or .docx'}.`,
            }}
            disabled={isUploading}
            dropzoneContent={
                <div
                    css={css`
                        position: relative;
                        overflow: hidden;
                    `}
                >
                    <div
                        css={css`
                            padding: 24px;
                            background: ${isUploading ? theme.colors.gray_2 : theme.colors.violet_1};
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            min-height: 200px;
                        `}
                    >
                        {isUploading ? (
                            <Fragment>
                                <ContentLoader />
                                <p>Uploading files...</p>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Icon
                                    name='upload-desktop'
                                    size={39}
                                    style={css`
                                        filter: ${blackToVioletDarkFilter};
                                    `}
                                />
                                <span
                                    css={css`
                                        margin-top: 24px;
                                        text-align: center;
                                    `}
                                >
                                    <strong
                                        css={css`
                                            display: block;
                                        `}
                                    >
                                        Drag and Drop your CVs here <br />
                                        The CV can be in either .docx or .pdf format.
                                    </strong>
                                </span>
                                <Button
                                    css={css`
                                        margin-top: 24px;
                                    `}
                                    variant='secondary'
                                >
                                    Browse Files
                                </Button>
                            </Fragment>
                        )}
                    </div>
                </div>
            }
        />
    )
}

export { CvDropzone }
