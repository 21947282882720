/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import RcSwitch, { SwitchChangeEventHandler } from 'rc-switch'
import { FunctionComponent } from 'react'
import { InputField, InputFieldProps } from '../input-field/InputField'

export type SwitchProps = InputFieldProps & {
    leftText: string
    rightText: string
    onChange?: SwitchChangeEventHandler
    checked?: boolean
}

const Switch: FunctionComponent<React.PropsWithChildren<SwitchProps>> = ({
    checked,
    onChange,
    leftText,
    rightText,
    label,
    labelTooltip,
    errorMessage,
    errorTooltipMessage,
    infoMessage,
    dataTestId,
}) => (
    <InputField
        label={label}
        labelTooltip={labelTooltip}
        errorMessage={errorMessage}
        errorTooltipMessage={errorTooltipMessage}
        infoMessage={infoMessage}
        dataTestId={dataTestId}
    >
        <div
            css={css`
                display: flex;
            `}
        >
            <div
                css={css`
                    margin-right: 15px;
                `}
            >
                {leftText}
            </div>
            <RcSwitch checked={checked} onChange={onChange} loadingIcon={null} data-testid={dataTestId} />
            <div
                css={css`
                    margin-left: 15px;
                `}
            >
                {rightText}
            </div>
        </div>
    </InputField>
)

export { Switch }
