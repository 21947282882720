import { useInlineEdit } from 'src/hooks/useInlineEdit'
import { INVALID_NUMBER } from 'src/validation/validation-messages'
import { yup } from 'src/validation/yup'
import { ApplicationFormData } from '../../../contracts/opportunities-manager/contracts'

const schema = yup.object().shape({
    applications: yup.array().of(
        yup.object().shape({
            status: yup.string().required(),
            internalRate: yup.lazy(value => (value === '' ? yup.string() : yup.number().positive().typeError(INVALID_NUMBER))),
        }),
    ),
})

const useApplicationsInlineEdit = () => {
    const { handleEditingKeysChange, isEditing, getIndex, setValue, control, trigger, errors, getValues } =
        useInlineEdit<ApplicationFormData>({
            schema,
            fieldArrayName: 'applications',
        })

    return { handleEditingKeysChange, isEditing, getIndex, control, setValue, trigger, errors, getValues }
}

export { useApplicationsInlineEdit }
