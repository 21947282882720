import styled from '@emotion/styled'
import { mqMin } from '../../../GlobalStyle'

const TabFormWrapper = styled.section<{ fullWidth?: boolean }>`
    width: 100%;

    ${mqMin[1]} {
        width: ${({ fullWidth }) => (fullWidth ? '100%' : '60%')};
    }
`

export { TabFormWrapper }
