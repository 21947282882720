/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react'
import { CustomField as CustomFieldType } from '../../../contracts/customFields'
import { NullableArray } from '../../../types'
import { CustomFieldForm, FormValues } from './CustomFieldForm'
import { CustomFieldInfo } from './CustomFieldInfo'

type CustomFieldProps = FunctionComponent<
    React.PropsWithChildren<{
        setIdToRemove: Dispatch<SetStateAction<string | undefined>>
        setMyCustomFields: Dispatch<SetStateAction<NullableArray<CustomFieldType>>>
        customField: CustomFieldType
        handleCustomFieldSubmit: (formValues: FormValues, id: string, setError: any) => void
    }>
>

const CustomField: CustomFieldProps = ({ setIdToRemove, customField, handleCustomFieldSubmit, setMyCustomFields }) => {
    const theme = useTheme()
    const [editMode, setEditMode] = useState<boolean>(!customField.id)

    useEffect(() => {
        if (customField.id) {
            setEditMode(false)
        }
    }, [customField])

    return (
        <div
            css={css`
                border: 1px solid ${theme.colors.gray_2};
                padding: 16px 24px;
                margin-bottom: 8px;
            `}
            data-testid='custom-field'
        >
            {editMode ? (
                <CustomFieldForm setEditMode={setEditMode} customField={customField} handleCustomFieldSubmit={handleCustomFieldSubmit} />
            ) : (
                <CustomFieldInfo
                    setEditMode={setEditMode}
                    setIdToRemove={setIdToRemove}
                    customField={customField}
                    setMyCustomFields={setMyCustomFields}
                />
            )}
        </div>
    )
}

export { CustomField }
