/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react'
import { usePromise } from 'react-use'
import { IconButton } from '../../components/Icon'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { getSpecialistProfileSummary } from './api'
import { SummaryForm } from './SummaryForm'

type SummaryProps = {}

const Summary: FunctionComponent<React.PropsWithChildren<SummaryProps>> = () => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)
    const isMounted = usePromise()
    const theme = useTheme()
    const [isEditModeActive, setIsEditModeActive] = useState(false)
    const [summary, setSummary] = useState<Nullable<string>>('')
    const toggleEditMode = useCallback(() => {
        setIsEditModeActive(currentIsEditModeActive => !currentIsEditModeActive)
    }, [])
    const { addError } = useNotifications()

    const onUpdateSuccess = useCallback((summary: string) => {
        setSummary(summary)
        setIsEditModeActive(false)
    }, [])
    const onUpdateCancel = useCallback(() => {
        setIsEditModeActive(false)
    }, [])

    useEffect(() => {
        isMounted(getSpecialistProfileSummary(decodedAccessToken.specialistId))
            .then(({ profileSummary }) => {
                setSummary(profileSummary)
            })
            .catch(() => {
                addError()
            })
    }, [decodedAccessToken.specialistId, isMounted, addError])

    return (
        <div
            css={css`
                margin-top: 24px;
            `}
        >
            <h6>Summary</h6>
            {isEditModeActive ? (
                <SummaryForm onSuccess={onUpdateSuccess} onCancel={onUpdateCancel} summary={summary || undefined} />
            ) : (
                <div
                    css={css`
                        color: ${summary ? 'inherit' : theme.colors.gray_3};
                        white-space: pre-wrap;
                    `}
                >
                    {summary || 'Here you can add a few words about your experience and education.'}
                    <IconButton dataTestId='summary-edit' name='edit' size={30} onClick={toggleEditMode} />
                </div>
            )}
        </div>
    )
}

export { Summary }
