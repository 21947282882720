/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { underlinedText } from '../GlobalStyle'
import { COLOR_PALETTE } from '../theme/colors'
import awards from './../assets/images/awards.png'

const Awards = () => {
    const theme = useTheme()

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
            `}
        >
            <h4
                css={css`
                    color: ${theme.colors.text};
                `}
            >
                Close Your Tech Talent Gap With Our
            </h4>
            <div>
                <h2
                    css={css`
                        ${underlinedText}
                        color: ${theme.colors.text};
                    `}
                >
                    Human Cloud Platform
                </h2>
            </div>
            <img
                css={css`
                    max-width: 470px;
                    ${theme.colors.text === COLOR_PALETTE.white ? 'filter:invert();' : ''};
                `}
                src={awards}
                alt='Our awards'
            />
        </div>
    )
}

export { Awards }
