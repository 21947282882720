/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import { BreakpointIndex, mqMin } from '../../GlobalStyle'

type FlexBoxProps = {
    justifyContent?: string
    alignItems?: string
    style?: any
}

const FlexBox = styled.div<FlexBoxProps>`
    display: flex;
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'flex-start' }) => alignItems};
`

const FlexButtons = styled(FlexBox)`
    margin-right: -4px;
    margin-left: -4px;
    > * {
        margin-right: 4px;
        margin-left: 4px;
    }
`

type FlexColumnsProps = FlexBoxProps & {
    breakAt?: BreakpointIndex
    flexDirection?: string
}

const FlexColumns = styled(FlexBox)<FlexColumnsProps>`
    margin-right: -8px;
    margin-left: -8px;
    flex-direction: ${({ flexDirection = 'column' }) => flexDirection};

    ${({ breakAt = 1 }) => mqMin[breakAt]} {
        flex-direction: row;
    }
`

type FlexColumnProps = {
    shrinkable?: boolean
    flexGrow?: number
    minWidth?: string
    fixedWidth?: string
}

const FlexColumn = styled.div<FlexColumnProps>`
    padding-left: 8px;
    padding-right: 8px;
    min-height: 56px;
    width: ${({ fixedWidth = '100%' }) => fixedWidth};
    min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
    flex-shrink: ${({ shrinkable }) => (shrinkable ? 2 : 1)};
    flex-grow: ${({ flexGrow = 0 }) => flexGrow};
`

export { FlexBox, FlexColumns, FlexColumn, FlexButtons }
