/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { ButtonHTMLAttributes, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../GlobalStyle'
import { Theme } from '../theme/theme'
import { blackToGrayFilter, blackToRedFilter, blackToVioletDarkFilter, blackToWhiteFilter } from './Icon'

export type LinkButtonVariant = 'link' | 'linkDelete' | 'linkForm'
export type ButtonVariant =
    | LinkButtonVariant
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'talent'
    | 'delete'
    | 'sso'
    | 'text'
    | 'violetBordered'
    | 'violet'
    | 'beigeBordered'

export type ButtonSize = 'medium' | 'small' | 'big'

const ButtonBase = css`
    cursor: pointer;
    &[disabled] {
        cursor: not-allowed;
    }
    &:focus-visible {
        outline: 5px auto -webkit-focus-ring-color;
    }
`

const getSizeStyles = (size: ButtonSize, variant: ButtonVariant) => {
    const verticalPadding = size === 'small' ? 5 : size === 'medium' ? 10 : 19
    let horizontalPadding = size === 'small' ? 8 : size === 'medium' ? 16 : 24
    if (variant.includes('link')) {
        horizontalPadding = 0
    }
    return css`
        padding: ${verticalPadding}px ${horizontalPadding}px ${verticalPadding - 1}px;
    `
}

const ButtonBaseSolid = css`
    ${ButtonBase};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    &[disabled],
    &[disabled]:hover {
        background-color: ${COLOR_PALETTE.gray_2};
        border-color: ${COLOR_PALETTE.gray_2};
        color: ${COLOR_PALETTE.gray_3};
        img {
            filter: ${blackToGrayFilter};
        }
    }
`

const ButtonPrimary = (theme: Theme) => css`
    ${ButtonBaseSolid};
    background-color: ${theme.colors.main};
    border: 1px solid ${theme.colors.main};
    color: ${theme.colors.text};
    &:hover {
        background-color: ${theme.colors.main_dark};
        border: 1px solid ${theme.colors.main_dark};
    }
`

const ButtonSecondary = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.gray_6};
    border: 1px solid ${COLOR_PALETTE.gray_6};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.gray_5};
        border: 1px solid ${COLOR_PALETTE.gray_5};
    }
`

const ButtonTertiary = (theme: Theme) => css`
    ${ButtonBaseSolid};
    background-color: transparent;
    border: 1px solid ${theme.colors.main};
    color: ${COLOR_PALETTE.gray_6};
    &:hover {
        background-color: ${theme.colors.main};
        color: ${theme.colors.text};
        img {
            ${theme.colors.text === COLOR_PALETTE.white && 'filter:invert();'}
        }
    }
`

const ButtonTalent = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.violet_5};
    border: 1px solid ${COLOR_PALETTE.violet_5};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.violet_4};
        border: 1px solid ${COLOR_PALETTE.violet_4};
    }
`

const ButtonDelete = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.red_4};
    border: 1px solid ${COLOR_PALETTE.red_4};
    color: ${COLOR_PALETTE.white};
    img {
        filter: ${blackToWhiteFilter};
    }
    &:hover {
        background-color: ${COLOR_PALETTE.red_5};
        border: 1px solid ${COLOR_PALETTE.red_5};
    }
`

const ButtonSSO = css`
    ${ButtonBaseSolid};
    background-color: ${COLOR_PALETTE.white};
    border: 1px solid ${COLOR_PALETTE.gray_2};
    &:hover {
        border: 1px solid ${COLOR_PALETTE.gray_3};
    }
`

const ButtonText = css`
    ${ButtonBaseSolid};
    background-color: transparent;
    border: transparent;
    &:hover {
        text-decoration: underline;
    }
    &[disabled],
    &[disabled]:hover {
        background-color: transparent;
        text-decoration: none;
    }
`

const ButtonViolet = (theme: Theme) => css`
    ${ButtonBaseSolid};
    background-color: ${theme.colors.violet_2};
    border: 1px solid ${theme.colors.violet_2};
`

const ButtonVioletBordered = (theme: Theme) => css`
    ${ButtonBaseSolid};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.violet_3};
`

const ButtonBeigeBordered = (theme: Theme) => css`
    ${ButtonBaseSolid};
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.beige_4};
`

const ButtonBaseLink = css`
    ${ButtonBase};
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;

    &[disabled],
    &[disabled]:hover {
        color: ${COLOR_PALETTE.gray_3};
        text-decoration: none;
        img {
            filter: ${blackToGrayFilter};
        }
    }
`

const ButtonLink = (theme: Theme) => css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.gray_6};
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${theme.colors.main};
    }
`

const ButtonLinkDelete = css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.red_4};
    img {
        filter: ${blackToRedFilter};
    }
    &:hover {
        text-decoration: underline;
    }
`

const ButtonLinkForm = (theme: Theme) => css`
    ${ButtonBaseLink};
    color: ${COLOR_PALETTE.violet_5};
    img {
        filter: ${blackToVioletDarkFilter};
    }
    &:hover {
        text-decoration: underline;
        text-decoration-color: ${theme.colors.main};
    }
`

const getButtonStyles = (variant: ButtonVariant, theme: Theme): SerializedStyles => {
    switch (variant) {
        case 'primary':
            return ButtonPrimary(theme)
        case 'secondary':
            return ButtonSecondary
        case 'tertiary':
            return ButtonTertiary(theme)
        case 'talent':
            return ButtonTalent
        case 'delete':
            return ButtonDelete
        case 'sso':
            return ButtonSSO
        case 'text':
            return ButtonText
        case 'link':
            return ButtonLink(theme)
        case 'linkDelete':
            return ButtonLinkDelete
        case 'linkForm':
            return ButtonLinkForm(theme)
        case 'violet':
            return ButtonViolet(theme)
        case 'violetBordered':
            return ButtonVioletBordered(theme)
        case 'beigeBordered':
            return ButtonBeigeBordered(theme)
        default:
            return css``
    }
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant
    size?: ButtonSize
    icon?: any
    style?: SerializedStyles
    iconStyle?: SerializedStyles
    dataTestId?: string
}

const Button: FunctionComponent<React.PropsWithChildren<ButtonProps>> = ({
    variant = 'primary',
    size = 'medium',
    icon,
    style,
    iconStyle,
    type = 'button',
    children,
    dataTestId = '',
    ...rest
}) => {
    const theme = useTheme()

    return (
        <button
            css={css`
                ${getButtonStyles(variant, theme)}
                ${getSizeStyles(size, variant)}
                ${style}
            `}
            type={type}
            {...rest}
            data-testid={dataTestId}
        >
            {icon && (
                <img
                    css={css`
                        margin-right: 8px;
                        height: 16px;
                        ${theme.colors.text === COLOR_PALETTE.white && variant === 'primary' && 'filter:invert();'}
                        ${iconStyle}
                    `}
                    src={icon}
                    alt='Action button'
                />
            )}
            <span>{children}</span>
        </button>
    )
}

export { Button }
