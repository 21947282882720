/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { removeSpecialistCv } from '../../api/api'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { PageWidthWrapper } from '../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../components/layout/SpecialistPageWrapper'
import { LayoutWithStickyColumn } from '../../components/layout/LayoutWithStickyColumn'
import { LeavePageConfirmationModal } from '../../components/LeavePageConfirmationModal'
import { NoData } from '../../components/NoData'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ProjectBox } from '../../components/specialist/ProjectBox'
import { StepsIndicator } from '../../components/steps-indicator/StepsIndicator'
import { StepStatus } from '../../components/steps-indicator/types'
import { ExperienceStatus, SpecialistProject } from '../../contracts/specialist/specialistProject'
import { mqMin } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { NullableArray } from '../../types'
import { useLogger } from '../../utils/useLogger'
import {
    editSpecialistCvExperience,
    getSpecialistCvExperience,
    markSpecialistCvExperienceAsDeleted,
    undoMarkingSpecialistCvExperienceAsDeleted,
} from './api'

const SpecialistExperiencePage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const {
        actions: { layoutToggleLoader },
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    const specialistId = useMemo(() => decodedAccessToken?.specialistId, [decodedAccessToken])
    const { cvParsingId } = useParams<{ cvParsingId: string }>()
    const [experience, setExperience] = useState<NullableArray<SpecialistProject>>(null)
    const [editingIndexes, setEditingIndexes] = useState<Array<number>>([])
    const [notValidIndexes, setNotValidIndexes] = useState<Array<number>>([])
    const [dirtyProjects, setDirtyProjects] = useState<Array<boolean>>([])
    const { addError } = useNotifications()
    const theme = useTheme()
    const log = useLogger('error')

    useEffect(() => {
        if (cvParsingId) {
            layoutToggleLoader(true)
            getSpecialistCvExperience(cvParsingId)
                .then(data => {
                    setExperience(data.experience)
                    data.experience.forEach((exp, index) => {
                        if (exp.status === ExperienceStatus.NOT_VALID) {
                            setEditingIndexes(currentEditingIndexes => currentEditingIndexes.concat(index))
                            setNotValidIndexes(currentNotValidIndexes => currentNotValidIndexes.concat(index))
                        }
                    })
                })
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => {
                    layoutToggleLoader(false)
                })
        }
    }, [addError, cvParsingId, layoutToggleLoader, log])

    const handleEditClick = useCallback((index: number) => {
        setEditingIndexes(currentEditingIndexes => currentEditingIndexes.concat(index))
    }, [])

    const handleCancelClick = useCallback(
        (index: number) => setEditingIndexes(currentEditingIndexes => currentEditingIndexes.filter(currentIndex => currentIndex !== index)),
        [],
    )

    const handleSaveClick = useCallback(
        (index: number, formData: SpecialistProject) => {
            const experienceId = experience?.[index].id
            if (experienceId && cvParsingId) {
                layoutToggleLoader(true)
                editSpecialistCvExperience(cvParsingId, experienceId, formData)
                    .then(data => {
                        setExperience(data.experience)
                        setNotValidIndexes(currentNotValidIndexes =>
                            currentNotValidIndexes.filter(notValidIndex => notValidIndex !== index),
                        )
                        handleCancelClick(index)
                    })
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [experience, layoutToggleLoader, cvParsingId, handleCancelClick, log, addError],
    )

    const handleDeleteClick = useCallback(
        (index: number) => {
            const experienceId = experience?.[index].id
            if (experienceId && cvParsingId) {
                layoutToggleLoader(true)
                markSpecialistCvExperienceAsDeleted(cvParsingId, experienceId)
                    .then(data => setExperience(data.experience))
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [addError, cvParsingId, experience, layoutToggleLoader, log],
    )

    const handleUndoClick = useCallback(
        (index: number) => {
            const experienceId = experience?.[index].id
            if (experienceId && cvParsingId) {
                layoutToggleLoader(true)
                undoMarkingSpecialistCvExperienceAsDeleted(cvParsingId, experienceId)
                    .then(data => setExperience(data.experience))
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => {
                        layoutToggleLoader(false)
                    })
            }
        },
        [addError, cvParsingId, experience, layoutToggleLoader, log],
    )

    const handleCancelProcess = useCallback(() => {
        if (cvParsingId) {
            removeSpecialistCv(specialistId, cvParsingId)
                .then(() => history.push('/specialist-dashboard'))
                .catch(err => {
                    log(err)
                    addError()
                })
                .finally(() => layoutToggleLoader(false))
        }
    }, [addError, cvParsingId, layoutToggleLoader, log, specialistId])

    const canGoNextStep = useMemo(
        () => notValidIndexes.every(notValidIndex => experience?.[notValidIndex].status === ExperienceStatus.DELETED),
        [experience, notValidIndexes],
    )

    const handleDirtyProjects = useCallback((isDirty: any, index: any) => {
        setDirtyProjects(projects => {
            const newProjects = [...projects]
            newProjects[index] = isDirty
            return newProjects
        })
    }, [])

    const isAnyProjectDirty = useMemo(() => dirtyProjects.find(dirtyProject => dirtyProject), [dirtyProjects])

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;
            `}
        >
            <PageWidthWrapper>
                <BackLink
                    style={css`
                        margin: 0 0 24px;
                    `}
                    text='Back to dashboard'
                    path='/specialist-dashboard'
                />
                <h3>Confirm Extracted Data</h3>
                <p>Choose which data to add to your profile. You’ll also be able to edit this data later in your Profile.</p>
            </PageWidthWrapper>
            <LayoutWithStickyColumn
                firstCol={
                    <Fragment>
                        <StepsIndicator
                            steps={[
                                {
                                    name: 'Experience',
                                    status: StepStatus.ACTIVE,
                                },
                                {
                                    name: 'Education',
                                    status: StepStatus.PENDING,
                                },
                                { name: 'Skills', status: StepStatus.PENDING },
                            ]}
                        />
                        <h6
                            css={css`
                                margin-top: 16px;
                            `}
                        >
                            Experience
                        </h6>
                        {experience?.map((exp, index) => (
                            <ProjectBox
                                key={exp.id}
                                project={exp}
                                index={index}
                                onSaveClicked={handleSaveClick}
                                onEditClicked={handleEditClick}
                                onDeleteClicked={handleDeleteClick}
                                onCancelClicked={handleCancelClick}
                                onUndoClicked={handleUndoClick}
                                editMode={editingIndexes.includes(index)}
                                disabled={exp.status === ExperienceStatus.DELETED}
                                forceValidation
                                canCancel={!notValidIndexes.includes(index)}
                                removable
                                setIsDirty={handleDirtyProjects}
                            />
                        ))}
                        {experience?.length === 0 && (
                            <Fragment>
                                <NoData
                                    message='No experience records found in the file'
                                    style={css`
                                        margin: 90px 0;
                                    `}
                                />
                                <Divider />
                            </Fragment>
                        )}
                        <FlexBox justifyContent='space-between'>
                            <Button variant='link' onClick={handleCancelProcess}>
                                Cancel
                            </Button>
                            {canGoNextStep ? (
                                <Link to={`/specialist-cv-parsing/${cvParsingId}/education`}>
                                    <Button variant='primary'>Next</Button>
                                </Link>
                            ) : (
                                <Button variant='primary' disabled>
                                    Next
                                </Button>
                            )}
                        </FlexBox>
                    </Fragment>
                }
                secondCol={
                    <div
                        css={css`
                            padding: 24px;
                            width: 100%;
                            background-color: ${theme.colors.beige_1};
                            margin-bottom: 24px;
                            ${mqMin[2]} {
                                margin: 0;
                            }
                        `}
                    >
                        <h6>Please follow the instructions below:</h6>
                        <ol
                            css={css`
                                padding-left: 20px;
                            `}
                        >
                            <li>
                                If you want to add or change the experience select the record that you want to change and click the edit
                                button.
                            </li>
                            <li>If you wish to delete the record then click the delete option.</li>
                            <li>Once you completed please click “Next” button. </li>
                        </ol>
                    </div>
                }
            />
            {isAnyProjectDirty && <LeavePageConfirmationModal />}
        </SpecialistPageWrapper>
    )
}

export { SpecialistExperiencePage }
