/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { ColumnsType } from 'antd/es/table'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { BackLink } from 'src/components/BackLink'
import { Button } from 'src/components/Button'
import { ResponsiveGrid } from 'src/components/data-grid/ResponsiveGrid'
import { DataNotFound } from 'src/components/DataNotFound'
import { Divider } from 'src/components/Divider'
import { AutocompleteSelectValuesTransformerEnum } from 'src/components/forms/ControlledAutocompleteSelect'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { ColorBackgroundWrapper, FullPageWrapper } from 'src/components/layout/ResponsiveWrapper'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { FiltersBar, FiltersFunctions } from 'src/components/opportunities/FiltersBar'
import { Pagination } from 'src/components/Pagination'
import { SearchInput } from 'src/components/SearchInput'
import { getAppPath } from 'src/contracts/applications'
import { SortEntry } from 'src/contracts/common/sorting'
import { mqMax, mqMin } from 'src/GlobalStyle'

import { COLOR_PALETTE } from 'src/theme/colors'
import { Nullable } from 'src/types'
import { SMEmenuItems } from '../../components/opportunities-manager/utils/menuItems'

import { countArrayFilters } from 'src/utils/filters'

import { alphabeticallyAsc } from 'src/utils/sorting'
import { useLogger } from 'src/utils/useLogger'
import { useQuery } from 'src/utils/useQuery'
import { getAllApplicationsFilters, searchAllApplications } from '../../api/opportunities-manager/api'
import { ApplicationActions } from '../../components/opportunities-manager/ApplicationActions'
import { ApplicationEditActions } from '../../components/opportunities-manager/ApplicationEditActions'
import { ControlledAutocompleteSelectInlineEdit } from '../../components/opportunities-manager/ControlledAutocompleteSelectInlineEdit'

import {
    AllApplicationsItem,
    ApplicationStatusEnum,
    applicationStatusesList,
    ApplicationStatusMapping,
    SearchAllApplicationsFilters,
    SearchAllApplicationsFiltersOptions,
    SearchAllApplicationsRequestParams,
} from '../../contracts/opportunities-manager/contracts'

import { useApplicationsInlineEdit } from '../../components/opportunities-manager/utils/useApplicationsInlineEdit'

import { AllApplicationsFiltersBar } from '../../components/opportunities-manager/AllApplicationsFilterBar'
import {
    parseQueryToOpportunitiesManagerApplicationsSearchAllRequest,
    stringifyOpportunitiesManagerApplicationsSearchAllRequestToQuery,
} from '../../components/opportunities-manager/utils/applicationSearchAllUtil'

import { InviteToOpportunityModal } from 'src/components/profile/InviteToOpportunityModal'
import { useAllApplicationUpdate } from '../../components/opportunities-manager/utils/useAllApllicationUpdate'

const DEFAULT_ITEMS_PER_PAGE = 10

const defaultFilterOptions: SearchAllApplicationsFiltersOptions = {
    opportunityOwners: [],
    partnerNames: [],
    clientNames: [],
    opportunityInternalNames: [],
    statusValues: [],
}

const AllCandidatesSme = () => {
    const { addError } = useNotifications()
    const log = useLogger('error')

    const [sorting, setSorting] = useState<Array<SortEntry>>([])
    const [applications, setApplications] = useState<Nullable<Array<AllApplicationsItem>>>()
    const [inviteToOpportunityModal, setInviteToOpportunityModal] = useState(false)
    const [choosenApplicationId, setChoosenApplicationId] = useState('')
    const [isFetchingApplications, setIsFetchingApplications] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(false)

    const navigate = useNavigate()
    const { search, pathname, hash } = useLocation()
    const query = useQuery()

    const isSmeCloudApp = true

    const appPath = getAppPath('OPPORTUNITIES_MANAGER_SME')

    useEffect(() => {
        if (search) {
            setShowFilters(true)
        }
    }, [search])

    const searchRequest = useMemo<Omit<SearchAllApplicationsRequestParams, 'sorting'>>(
        () => parseQueryToOpportunitiesManagerApplicationsSearchAllRequest(search),
        [search],
    )

    const [currentPage, setCurrentPage] = useState<number>(searchRequest.paging?.page || 1)
    const [pageSize, setPageSize] = useState<number>(searchRequest.paging?.size || DEFAULT_ITEMS_PER_PAGE)

    const searchFilters = useMemo((): SearchAllApplicationsFilters => {
        const { searchText: s, paging: p, ...onlyFilters } = searchRequest
        return onlyFilters
    }, [searchRequest])

    const [selectedFilters, setSelectedFilters] = useState<SearchAllApplicationsFilters>(searchFilters)
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText || '')

    useEffect(() => {
        const completeRequest = { searchText, paging: { page: currentPage, size: pageSize }, sorting, ...selectedFilters }
        const newSearchQuery = stringifyOpportunitiesManagerApplicationsSearchAllRequestToQuery(completeRequest, query.get('prevPath'))
        navigate({ search: newSearchQuery }, { replace: true })
    }, [currentPage, navigate, searchText, selectedFilters, pageSize, sorting, query])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const [filtersOptions, setFiltersOptions] = useState<SearchAllApplicationsFiltersOptions>(defaultFilterOptions)

    const loadFilters = useCallback(() => {
        getAllApplicationsFilters()
            .then(setFiltersOptions)
            .catch(err => {
                addError()
                log(err)
            })
    }, [addError, log])

    useEffect(() => {
        loadFilters()
    }, [loadFilters])

    const onChangeFilters = useCallback((filters: SearchAllApplicationsFilters) => {
        setSelectedFilters(filters)
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        setPageSize(Number(size.toString()))
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters({
            opportunityOwners: [],
            partnerNames: [],
            clientNames: [],
            opportunityInternalNames: [],
            statusValues: [],
        })
    }, [])
    const filtersApplied = useMemo(() => {
        let counter = 0

        counter += countArrayFilters(selectedFilters, [
            'opportunityOwners',
            'partnerNames',
            'clientNames',
            'opportunityInternalNames',
            'statusValues',
        ])
        ;['availableFrom', 'availableTo', 'maxRate', 'minRate', 'msa'].forEach(filterName => {
            if (selectedFilters[filterName as keyof SearchAllApplicationsFilters]) {
                counter++
            }
        })

        return counter > 0
    }, [selectedFilters])

    const [totalOpportunities, setTotalOpportunities] = useState<number>(0)
    const onChangePage = useCallback(setCurrentPage, [setCurrentPage])

    useEffect(() => {
        if (totalOpportunities > 0 && applications?.length === 0 && currentPage > 1) {
            setCurrentPage(1)
        }
    }, [applications?.length, currentPage, totalOpportunities])

    const loadApplications = useCallback(() => {
        setIsFetchingApplications(true)

        searchAllApplications({
            ...selectedFilters,
            sorting,
            searchText,
            internalManager: !isSmeCloudApp,
            paging: {
                page: currentPage,
                size: pageSize,
            },
        })
            .then(data => {
                setApplications(data.applications)
                setTotalOpportunities(data.total)
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => {
                setIsFetchingApplications(false)
            })
    }, [addError, currentPage, isSmeCloudApp, log, pageSize, searchText, selectedFilters, sorting])

    const { handleEditingKeysChange, isEditing, control, getIndex, setValue, trigger, errors, getValues } = useApplicationsInlineEdit()
    const afterUpdateApplicationRequestCallback = useCallback(
        (applicationId: string, application?: AllApplicationsItem) => {
            handleEditingKeysChange(applicationId)
            if (application) {
                setApplications(prev => prev?.map(item => (item.applicationId === applicationId ? application : item)) || null)
            }
        },
        [handleEditingKeysChange],
    )

    const { handleApplicationChange, ApplicationStatusUpdateRejectionReasonModal } = useAllApplicationUpdate({
        afterRequestCallback: afterUpdateApplicationRequestCallback,
        applicationData: { applications },
    })

    const onApplicationChange = useCallback(
        (applicationId: string) => {
            const index = getIndex(applicationId)

            trigger([`applications.${index}.status`, `applications.${index}.internalRate`]).then(() => {
                if (!errors?.applications?.[index]) {
                    const internalRate = +getValues(`applications.${index}.internalRate`)
                    const status = getValues(`applications.${index}.status`)
                    const application = applications?.find(app => app.applicationId === applicationId)
                    const statusChanged = status !== application?.status
                    const rateChanged = internalRate !== (application?.internalRate || 0)

                    if (statusChanged || rateChanged) {
                        handleApplicationChange(applicationId, statusChanged ? status : undefined, rateChanged ? internalRate : undefined)
                    } else {
                        afterUpdateApplicationRequestCallback(applicationId)
                    }
                }
            })
        },
        [afterUpdateApplicationRequestCallback, applications, errors?.applications, getIndex, getValues, handleApplicationChange, trigger],
    )

    const handleChooseApplicationId = (applicationId: string) => {
        setChoosenApplicationId(applicationId)
    }

    const handleInviteModalOpen = (isOpen: boolean) => {
        setInviteToOpportunityModal(isOpen)
    }

    const handleInviteModalClose = () => {
        setInviteToOpportunityModal(false)
        setChoosenApplicationId('')
    }

    const columns: ColumnsType<AllApplicationsItem> = useMemo(() => {
        const allColumns: ColumnsType<AllApplicationsItem> = [
            {
                title: 'Name',
                dataIndex: 'candidateName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.candidateName, b.candidateName),
                },
                showSorterTooltip: false,
                render: (_, data) => `${data.candidateName} `,
            },
            {
                title: 'Role',
                dataIndex: 'role',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.role || '', b.role || ''),
                },
                showSorterTooltip: false,
            },

            {
                title: 'Partner Name',
                dataIndex: 'partnerName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.partnerName || '', b.partnerName || ''),
                },
                showSorterTooltip: false,
            },
            {
                title: 'Client Name',
                dataIndex: 'clientName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.clientName || '', b.clientName || ''),
                },
                showSorterTooltip: false,
            },
            {
                title: 'Contact Person',
                dataIndex: 'contactPerson',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.contactPerson || '', b.contactPerson || ''),
                },
                showSorterTooltip: false,
            },
            {
                title: 'Opportunity Name',
                dataIndex: 'opportunityInternalName',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.opportunityInternalName || '', b.opportunityInternalName || ''),
                },
                showSorterTooltip: false,
            },

            {
                title: 'Currency',
                dataIndex: 'currency',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.partnerName || '', b.partnerName || ''),
                },
                showSorterTooltip: false,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: {
                    compare: (a, b) => alphabeticallyAsc(a.status || '', b.status || ''),
                },
                showSorterTooltip: false,
                render: (_, data) =>
                    isEditing(data.applicationId) ? (
                        <ControlledAutocompleteSelectInlineEdit
                            control={control}
                            options={applicationStatusesList}
                            fieldName={`applications.${getIndex(data.applicationId)}.status`}
                            setValue={setValue}
                            initialValue={data.status}
                            valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            selectedLabelTransformer={val => ApplicationStatusMapping[val as string]}
                        />
                    ) : (
                        <div>{data.status ? ApplicationStatusMapping[data.status] : '-'}</div>
                    ),
            },
            {
                title: '',
                dataIndex: 'actions',
                render: (_, data) => {
                    const isInvited = data.status === ApplicationStatusEnum.INVITED
                    return isEditing(data.applicationId) ? (
                        <ApplicationEditActions
                            applicationId={data.applicationId}
                            handleEditingKeysChange={handleEditingKeysChange}
                            handleSubmit={onApplicationChange}
                        />
                    ) : (
                        <ApplicationActions
                            applicationId={data.applicationId}
                            disabled={isInvited}
                            tooltip={
                                isInvited ? "You can't edit the invited candidates until their manager accepts the invite." : undefined
                            }
                            handleEditingKeysChange={handleEditingKeysChange}
                            handleChooseApplicationId={handleChooseApplicationId}
                            handleInviteModalOpen={handleInviteModalOpen}
                            opportunityId={data.opportunityId}
                        />
                    )
                },
                width: 80,
            },
        ]

        return allColumns
    }, [control, getIndex, handleEditingKeysChange, isEditing, onApplicationChange, setValue])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            setCurrentPage(1)
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            setCurrentPage(1)
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    useEffect(() => {
        loadApplications()
    }, [loadApplications])

    const rowProps = useCallback(
        (record: any) => ({
            'data-testid': 'opportunity-table-row',
            onClick: () =>
                !isEditing(record.applicationId) &&
                navigate(
                    `${appPath}/opportunities/${record.opportunityId}/applications/${record.applicationId}?prevPath=${encodeURIComponent(
                        `${pathname}${encodeURIComponent(search)}${hash}`,
                    )}`,
                ),
        }),
        [isEditing, navigate, appPath, pathname, search, hash],
    )

    return (
        <DashboardLayout applicationName='Opportunities Manager SME' applicationMenuItems={SMEmenuItems}>
            {!isFetchingApplications && !filtersApplied && !searchText && applications?.length === 0 ? null : (
                <ColorBackgroundWrapper
                    css={css`
                        background-color: ${COLOR_PALETTE.gray_1};
                        padding: 18px 16px 0;
                        ${mqMin[1]} {
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        }
                    `}
                >
                    <div
                        css={css`
                            max-width: 1440px;
                            margin: 0 auto;
                            ${mqMax[1]} {
                                border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                            }
                        `}
                    >
                        <BackLink
                            style={css`
                                padding-top: 0;
                                margin-bottom: 10px;

                                ${mqMax[2]} {
                                    padding-top: 10px;
                                }
                            `}
                            path={decodeURIComponent(query.get('prevPath') || appPath)}
                            text='Back to list'
                            dataTestId='back'
                        />
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                ${mqMax[1]} {
                                    flex: none;
                                }
                                margin-bottom: 16px;
                            `}
                        >
                            <h4
                                css={css`
                                    margin: 0;
                                `}
                            >
                                All Candidates
                            </h4>
                        </div>
                        <SearchInput
                            initialText={searchText}
                            onSubmit={setSearchText}
                            filtersOpened={showFilters}
                            onToggleFilters={onToggleFilters}
                            placeholder='Opportunity Title, Internal Name, Client Name, etc.'
                            styles={css`
                                margin-bottom: 16px;
                            `}
                            dataTestId='opportunities-manager-search'
                        />
                        {showFilters && filtersOptions && (
                            <FiltersBar<SearchAllApplicationsFilters> selectedFilters={selectedFilters} onChangeFilters={onChangeFilters}>
                                {({ onSelectFilterChange }: FiltersFunctions) => (
                                    <AllApplicationsFiltersBar
                                        opened={showFilters}
                                        filtersOptions={filtersOptions}
                                        selectedFilters={selectedFilters}
                                        onClearAll={onClearFilters}
                                        onSelectFilterChange={onSelectFilterChange}
                                    />
                                )}
                            </FiltersBar>
                        )}
                        <Divider />
                        <div
                            css={css`
                                display: flex;
                                justify-content: 'space-between';
                            `}
                        >
                            <span data-testid='opportunities-manager-total-found'>
                                {totalOpportunities} Result{totalOpportunities !== 1 && 's'} Found
                            </span>
                        </div>
                    </div>
                </ColorBackgroundWrapper>
            )}
            {applications && totalOpportunities > 0 && (
                <Fragment>
                    <ResponsiveGrid
                        rowKey='applicationId'
                        dataSource={applications}
                        columns={columns}
                        pagination={false}
                        dataTestId='opportunities-table'
                        onRow={rowProps}
                        onChange={onChangeTable}
                        mobileBreakpoint={2}
                    />
                    <FullPageWrapper>
                        <div
                            css={css`
                                margin: 40px 0 120px 0;
                            `}
                        >
                            <Pagination
                                pageSize={pageSize}
                                total={totalOpportunities}
                                defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                                onChangePageSize={onChangePageSize}
                                onChange={onChangePage}
                                current={currentPage}
                                css={css`
                                    ${mqMax[0]} {
                                        display: flex;
                                        justify-content: center;
                                    }
                                `}
                            />
                        </div>
                    </FullPageWrapper>
                </Fragment>
            )}
            {totalOpportunities === 0 && !isFetchingApplications && (
                <DataNotFound
                    styles={css`
                        margin-top: ${searchText || filtersApplied ? '50px' : '150px'};
                    `}
                    iconName='add-empty'
                    iconSize={85}
                    title={searchText || filtersApplied ? 'No Applications found' : 'No Applications'}
                    description={
                        searchText || filtersApplied
                            ? 'Try changing your filters or query'
                            : 'No one has applied for your Opportunities yet.'
                    }
                >
                    {!searchText && !filtersApplied && (
                        <Link
                            to={decodeURIComponent(query.get('prevPath') || appPath)}
                            css={css`
                                &:hover {
                                    text-decoration: none;
                                }
                            `}
                        >
                            <Button dataTestId='opportunity-manager-add'>Back to Opportunities</Button>
                        </Link>
                    )}
                </DataNotFound>
            )}
            {isFetchingApplications && <OverlayContentLoader />}
            {ApplicationStatusUpdateRejectionReasonModal}
            <InviteToOpportunityModal
                onClose={() => handleInviteModalClose()}
                opened={inviteToOpportunityModal}
                isCandidateView={true}
                applicationId={choosenApplicationId}
            />
        </DashboardLayout>
    )
}

export { AllCandidatesSme }
