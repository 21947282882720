/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FullProfileData } from '../../../api/api'
import { BackLink } from '../../../components/BackLink'
import { TalentBadge } from '../../../components/Badge'
import { Button } from '../../../components/Button'
import { LayoutWithStickyColumn } from '../../../components/layout/LayoutWithStickyColumn'
import { Logo } from '../../../components/Logo'
import { CustomExportModal } from '../../../components/profile-export/CustomExportModal'
import { ExportType } from '../../../components/profile-export/types'
import { BookSpecialistModal } from '../../../components/profile/BookSpecialistModal'
import { ShareProfileModal } from '../../../components/profile/ShareProfileModal'
import { FlexContainer, ProfileNameRow, TitleH3 } from '../../../components/profile/styled'
import { getAppPath } from '../../../contracts/applications'
import { Note } from '../../../contracts/notes/Notes'
import { Profile } from '../../../contracts/profile/profile'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { useRoutesMatching } from '../../../hooks/useRoutesMatching'
import { useUserAuthentication } from '../../../hooks/useUserAuthentication'
import { ReduxContext } from '../../../redux/Store'
import { Nullable } from '../../../types'
import { useQuery } from '../../../utils/useQuery'
import { TalentMarketplaceShareModal } from '../../talent-marketplace/components/TalentMarketplaceShareModal'
import { NotAvailableModal } from './NotAvailableModal'
import { SpecialistProfileMainContent } from './SpecialistProfileMainContent'
import { SpecialistProfileSideMenu } from './SpecialistProfileSideMenu'

type SpecialistProfileProps = {
    specialistProfile: Nullable<Profile>
    isPublic?: boolean
    shareProfileLinkId?: string
    shareProfileLinkIds?: { rateVisibleLinkId: string; rateHiddenLinkId: string }
    setRefetchProfile?: (val: boolean) => void
    setLoading?: (val: boolean) => void
    isMySpecialists?: boolean
    hasCompanyAccount?: boolean
    showLogoutButton?: boolean
}

const SpecialistProfile: FunctionComponent<React.PropsWithChildren<SpecialistProfileProps>> = ({
    specialistProfile,
    isPublic = false,
    shareProfileLinkId,
    shareProfileLinkIds,
    setRefetchProfile,
    setLoading,
    isMySpecialists,
    hasCompanyAccount,
    showLogoutButton = false,
}) => {
    const notesRef = useRef<HTMLDivElement>(null)
    const [contactModal, setContactModal] = useState<boolean>(false)
    const [shareModal, setShareModal] = useState<boolean>(false)
    const [notAvailableModal, setNotAvailableModal] = useState<boolean>(false)
    const [exportType, setExportType] = useState<Nullable<ExportType>>(null)
    const [companyNotes, setCompanyNotes] = useState<Array<Note>>([])
    const [exportProfileSpecialistId, setExportProfileSpecialistId] = useState('')
    const [anonymousProfile, setAnonymousProfile] = useState<FullProfileData>()
    const [exportProfileModalOpen, setExportProfileModalOpen] = useState(false)
    const { isUserAuthorized, isSpecialistAccount } = useUserAuthentication()
    const { matchProfileSummary } = useRoutesMatching()
    const navigate = useNavigate()
    const theme = useTheme()
    const [isAnonymized, setIsAnonymized] = useState<Nullable<boolean>>(null)

    const {
        selectors: {
            featureFlags: { notes },
        },
    } = useContext(ReduxContext)

    const shouldSeeNotes = useMemo(
        () => notes && !matchProfileSummary && isUserAuthorized && !isSpecialistAccount,
        [notes, matchProfileSummary, isUserAuthorized, isSpecialistAccount],
    )

    const onClickExportProfile = useCallback((key: ExportType) => setExportType(key), [])

    const handleShareModalClose = useCallback(() => setShareModal(false), [])

    const handleNotAvailableModalClose = useCallback(() => setNotAvailableModal(false), [])

    const handleLogoutClick = useCallback(() => navigate('/logout/user'), [navigate])

    const closeBookingModal = useCallback(() => {
        setContactModal(false)
    }, [])

    const handleExportProfileCloseModal = useCallback(() => {
        setExportProfileModalOpen(false)
    }, [])

    const query = useQuery()

    const scrollToNotes = useCallback(() => {
        notesRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }, [])
    return (
        <div
            css={css`
                padding: 24px;
                ${isPublic && 'padding-top:80px;'}
                ${mqMax[1]} {
                    padding: 24px 16px;
                    ${isPublic && 'padding-top:80px;'}
                }
            `}
        >
            {isPublic ? (
                <header
                    css={css`
                        background-color: ${COLOR_PALETTE.white};
                        box-shadow: 0px 4px 12px ${COLOR_PALETTE.box_shadow_color};
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        top: 0;
                        left: 0;
                        width: 100%;
                        z-index: 10;
                        position: absolute;
                        padding: 0 16px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            height: 56px;
                            max-width: 1160px;
                            width: 100%;
                        `}
                    >
                        <Logo type='logoFull' />
                    </div>
                    {showLogoutButton && <Button onClick={handleLogoutClick}>Logout</Button>}
                </header>
            ) : null}
            <div
                css={css`
                    display: none;
                    ${mqMax[2]} {
                        display: block;
                    }
                `}
            >
                {isSpecialistAccount ? (
                    <BackLink
                        path={'/specialist-dashboard'}
                        text='Back to dashboard'
                        style={css`
                            margin-bottom: 16px;
                        `}
                    />
                ) : (
                    !isPublic && (
                        <BackLink
                            path={
                                decodeURIComponent(query.get('prevPath') || '').replaceAll('#', '%23') || getAppPath('TALENT_MARKETPLACE')
                            }
                            text='Back to List'
                            style={css`
                                display: flex;
                                width: 100px;
                            `}
                        />
                    )
                )}
                <div
                    css={css`
                        margin: 20px 0 24px;
                    `}
                >
                    <ProfileNameRow>
                        <FlexContainer>
                            <TitleH3>{specialistProfile?.role}</TitleH3>
                            {specialistProfile?.seniority && specialistProfile.seniority !== '-' && (
                                <TalentBadge
                                    style={css`
                                        line-height: 36px;
                                    `}
                                >
                                    {specialistProfile.seniority}
                                </TalentBadge>
                            )}
                        </FlexContainer>
                        <div
                            css={css`
                                font-size: 12px;
                                color: ${theme.colors.gray_4};
                                line-height: 16px;
                            `}
                        >
                            {specialistProfile?.nickname || ''}
                        </div>
                    </ProfileNameRow>
                </div>
            </div>
            <LayoutWithStickyColumn
                firstCol={
                    <SpecialistProfileMainContent
                        isPublic={isPublic}
                        isSpecialistAccount={isSpecialistAccount}
                        hasCompanyAccount={hasCompanyAccount}
                        specialistProfile={specialistProfile}
                        exportType={exportType}
                        setExportType={setExportType}
                        isMySpecialists={isMySpecialists}
                        shouldSeeNotes={shouldSeeNotes}
                        companyNotes={companyNotes}
                        setCompanyNotes={setCompanyNotes}
                        shareProfileLinkId={shareProfileLinkId}
                        setAnonymousProfile={setAnonymousProfile}
                        setIsAnonymized={setIsAnonymized}
                        setExportProfileModalOpen={setExportProfileModalOpen}
                        setExportProfileSpecialistId={setExportProfileSpecialistId}
                        shareProfileLinkIds={shareProfileLinkIds}
                        notesRef={notesRef}
                    />
                }
                secondCol={
                    <SpecialistProfileSideMenu
                        isPublic={isPublic}
                        isSpecialistAccount={isSpecialistAccount}
                        hasCompanyAccount={hasCompanyAccount}
                        specialistProfile={specialistProfile}
                        setRefetchProfile={setRefetchProfile}
                        setLoading={setLoading}
                        shouldSeeNotes={shouldSeeNotes}
                        onClickExportProfile={onClickExportProfile}
                        companyNotes={companyNotes}
                        setContactModal={setContactModal}
                        setNotAvailableModal={setNotAvailableModal}
                        setShareModal={setShareModal}
                        scrollToNotes={scrollToNotes}
                    />
                }
            />
            {!isPublic && (
                <BookSpecialistModal
                    profile={specialistProfile}
                    onClose={closeBookingModal}
                    opened={contactModal}
                    setRefetchProfile={setRefetchProfile}
                />
            )}
            <NotAvailableModal onClose={handleNotAvailableModalClose} opened={notAvailableModal} />
            {shareProfileLinkId && shareProfileLinkId.length > 0 && (
                <ShareProfileModal shareLinkId={shareProfileLinkId} onClose={handleShareModalClose} opened={shareModal} />
            )}
            {shareProfileLinkIds && shareModal && (
                <TalentMarketplaceShareModal
                    opened={shareModal}
                    onClose={handleShareModalClose}
                    linkIdWithRate={shareProfileLinkIds.rateVisibleLinkId}
                    linkIdWithoutRate={shareProfileLinkIds.rateHiddenLinkId}
                />
            )}
            <CustomExportModal
                specialistId={exportProfileSpecialistId || ''}
                opened={exportProfileModalOpen}
                handleCloseModal={handleExportProfileCloseModal}
                exportType={exportType}
                setExportType={setExportType}
                setSpecialistId={setExportProfileSpecialistId}
                anonymousProfile={anonymousProfile}
                isAnonymized={isAnonymized}
            />
        </div>
    )
}

export { SpecialistProfile }
