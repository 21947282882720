/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent, ReactNode } from 'react'
import { useController } from 'react-hook-form'
import { Radio, RadioButton, RadioGroup, RadioGroupProps } from '../inputs/Radio'
import { ControlledFieldProps } from './types'

export enum ControlledRadioGroupOptionType {
    BUTTON = 'BUTTON',
    OPTION = 'OPTION',
    DEFAULT = 'DEFAULT',
}

type ControlledRadioGroupOptionProps = {
    label: ReactNode
    value: any
    disabled?: boolean
    style?: SerializedStyles
    wrapperStyle?: SerializedStyles
    type?: ControlledRadioGroupOptionType
    dataTestId?: string
}

type ControlledRadioGroupProps = Omit<RadioGroupProps, 'onChange' | 'value' | 'errorMessage' | 'children'> &
    ControlledFieldProps<any> & {
        options: Array<ControlledRadioGroupOptionProps>
        externalError?: string
    }

const ControlledRadioGroupOption: FunctionComponent<React.PropsWithChildren<ControlledRadioGroupOptionProps>> = ({
    label,
    value,
    disabled,
    style,
    wrapperStyle,
    type,
    dataTestId = '',
}) => {
    const theme = useTheme()

    return type === ControlledRadioGroupOptionType.BUTTON ? (
        <RadioButton label={label} value={value} disabled={disabled} style={style} dataTestId={`${dataTestId}-${value}`} />
    ) : type === ControlledRadioGroupOptionType.OPTION ? (
        <div
            css={css`
                background-color: ${theme.colors.violet_1};
                margin-bottom: 10px;

                ${wrapperStyle}
            `}
        >
            <Radio
                label={label}
                value={value}
                disabled={disabled}
                dataTestId={`${dataTestId}-${value}`}
                style={css`
                    padding: 14px 0 14px 8px;
                    width: 100%;

                    > span:last-of-type {
                        white-space: normal;
                    }

                    ${style}
                `}
            />
        </div>
    ) : (
        <Radio label={label} value={value} disabled={disabled} style={style} dataTestId={`${dataTestId}-${value}`} />
    )
}

const ControlledRadioGroup: FunctionComponent<React.PropsWithChildren<ControlledRadioGroupProps>> = ({
    options,
    name,
    control,
    styles,
    defaultValue = '',
    externalError,
    dataTestId = '',
    label,
    labelTooltip,
    infoMessage,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    return (
        <RadioGroup
            label={label}
            labelTooltip={labelTooltip}
            infoMessage={infoMessage}
            onChange={onChange}
            value={value}
            errorMessage={error?.message || externalError}
            errorTooltipMessage={error?.types?.tooltip as string}
            name={name}
            styles={styles}
        >
            {options.map(option => (
                <ControlledRadioGroupOption
                    dataTestId={dataTestId || name}
                    label={option.label}
                    value={option.value}
                    disabled={option.disabled}
                    style={option.style}
                    wrapperStyle={option.wrapperStyle}
                    type={option.type}
                    key={option.value}
                />
            ))}
        </RadioGroup>
    )
}

export { ControlledRadioGroup }
