import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { CVEditFormData } from '../contracts'

interface TAOwnerEditProps {
    taOwner: string
    control: Control<CVEditFormData>
    index: number
    options: Array<string>
}

const TAOwnerEdit: FunctionComponent<TAOwnerEditProps> = ({ taOwner, index, control, options }) => (
    <ControlledAutocompleteSelect
        canClear
        options={options}
        defaultValue={taOwner ? [taOwner] : []}
        control={control}
        name={`cvs.${index}.taOwner`}
        dropdownWidth='100%'
        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
        shouldUnregister={false}
        styles={css`
            width: 230px;
        `}
    />
)

export { TAOwnerEdit }
