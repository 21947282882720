/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react'
import { Col, Row } from 'antd'
import { FunctionComponent, ReactNode } from 'react'
import Sticky from 'react-stickynode'
import { useWindowSize } from 'react-use'
import { PageWidthWrapper } from './PageWidthWrapper'

type LayoutWithStickyColumnProps = {
    firstCol?: ReactNode
    secondCol?: ReactNode
    dataTestId?: string
    keepOrder?: boolean
    style?: SerializedStyles
}

const LayoutWithStickyColumn: FunctionComponent<React.PropsWithChildren<LayoutWithStickyColumnProps>> = ({
    firstCol = null,
    secondCol = null,
    dataTestId = '',
    keepOrder,
    style,
}) => {
    const { width } = useWindowSize()
    const isSticky = width > 1024
    return (
        <PageWidthWrapper style={style}>
            <Row gutter={40}>
                <Col data-testid={`${dataTestId}-first-column`} span={isSticky ? 16 : 24} order={keepOrder || isSticky ? 0 : 1}>
                    {firstCol}
                </Col>
                <Col
                    data-testid={`${dataTestId}-second-column`}
                    span={isSticky ? 8 : 24}
                    css={css`
                        position: relative;
                        z-index: 2;
                    `}
                >
                    {isSticky ? (
                        <Sticky enabled top={20}>
                            {secondCol}
                        </Sticky>
                    ) : (
                        secondCol
                    )}
                </Col>
            </Row>
        </PageWidthWrapper>
    )
}

export { LayoutWithStickyColumn }
