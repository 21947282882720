import { axios } from '../../api/axios'
import { Note } from '../../contracts/notes/Notes'
import { Profile } from '../../contracts/profile/profile'
import { CvStatus } from './contracts'

const bookingRequestsEndpoint = '/booking-requests'
const specialistsEndpoint = '/specialists'
const talentMarketplaceSpecialistsEndpoint = `/talentMarketplace/specialists`

const getDownloadableCvStatus = (specialistId: string): Promise<{ status: CvStatus }> => {
    return axios.get(`/v1/specialists/${specialistId}/downloadableCv/-/status`)
}

const getDownloadableCv = (specialistId: string): Promise<File> => {
    return axios.get(`/v1/specialists/${specialistId}/downloadableCv`, {
        responseType: 'blob',
    })
}

const getMyCompanyNotes = (specialistId: string): Promise<Array<Note>> => {
    return axios.get(`/v1/${specialistsEndpoint}/${specialistId}/notes/-/myCompany`)
}

const getSpecialistAvailability = (specialistId: string): Promise<{ available: boolean }> => {
    return axios.get(`${bookingRequestsEndpoint}/-/specialist/${specialistId}:isAvailable`)
}

const getPublicSpecialistProfile = (linkId: string): Promise<Profile> => {
    return axios.get(`${talentMarketplaceSpecialistsEndpoint}/-/links/${linkId}`)
}

export { getMyCompanyNotes, getSpecialistAvailability, getPublicSpecialistProfile, getDownloadableCvStatus, getDownloadableCv }
