export enum StepStatus {
    PENDING = 'PENDING',
    ACTIVE = 'ACTIVE',
    DONE = 'DONE',
}

export type StepType = {
    name: string
    status: StepStatus
}
