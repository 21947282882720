/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import useCollapse from 'react-collapsed'
import { useLifecycles } from 'react-use'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Icon, IconButton } from '../../../components/Icon'
import { SuggestionInput } from '../../../components/inputs/SuggestionInput'
import { StepType } from '../InstantOnboarding'

type InstantOnboardingCustomDataSectionProps = {
    header: string
    description: string
    limit: number
    type: StepType
    fetchOptions?: (filter: string) => Promise<Array<string>>
    disabled: boolean
    handleChange: (values: Array<string>, type: StepType) => void
    values: Array<string>
}

const InstantOnboardingCustomDataSection: FunctionComponent<React.PropsWithChildren<InstantOnboardingCustomDataSectionProps>> = ({
    header,
    description,
    limit,
    type,
    fetchOptions,
    disabled,
    handleChange,
    values,
}) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(values.length !== 0)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const [isMounted, setIsMounted] = useState(false)

    const handleOptionsChange = useCallback(
        (options: Array<string>) => {
            handleChange(options, type)
        },
        [handleChange, type],
    )

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    useEffect(() => {
        if (isMounted) {
            setIsExpanded(values.length !== 0)
        }
    }, [type, values.length, isMounted])

    const handleAdd = useCallback(() => {
        handleOptionsChange([...values, ''])
    }, [handleOptionsChange, values])

    const toggle = useCallback(() => {
        const isExpandedAfterChange = !isExpanded
        setIsExpanded(isExpandedAfterChange)

        if (isExpandedAfterChange) {
            if (values.length === 0) {
                handleOptionsChange([''])
            }
        } else {
            handleOptionsChange(values.filter(Boolean))
        }
    }, [isExpanded, handleOptionsChange, values])

    const handleOptionChange = useCallback(
        (value: string, index: number) => {
            values[index] = value
            handleOptionsChange([...values])
        },
        [handleOptionsChange, values],
    )

    const handleOptionRemove = useCallback(
        (index: number) => {
            handleOptionsChange(values.filter((_, i) => i !== index))
        },
        [handleOptionsChange, values],
    )

    return (
        <Fragment>
            <Divider />
            <div
                {...getToggleProps({
                    onClick: toggle,
                })}
                data-testid='instant-onboarding-custom-data-toggle-button'
            >
                <div
                    css={css`
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        padding: 17px 0 30px;
                        color: ${disabled ? theme.colors.gray_4 : theme.colors.violet_5};
                    `}
                >
                    {header}
                    <Icon
                        name='arrow-down'
                        css={css`
                            color: ${theme.colors.gray_6};
                            transition: transform 0.25s;
                            margin-left: 8px;
                            ${isExpanded && 'transform: rotate(180deg)'}
                        `}
                    />
                </div>
            </div>

            <div {...getCollapseProps()}>
                <p> {description}</p>
                {values.map((value, index) => (
                    <DataEntry
                        key={index}
                        index={index}
                        disabled={disabled}
                        type={type}
                        fetchOptions={fetchOptions}
                        value={value}
                        handleOptionChange={handleOptionChange}
                        handleOptionRemove={handleOptionRemove}
                        toggle={values.length === 1 ? toggle : undefined}
                    />
                ))}

                {values.length < limit && (
                    <Button
                        variant='linkForm'
                        onClick={handleAdd}
                        disabled={disabled || values.includes('')}
                        dataTestId='instant-onboarding-custom-data-add-more'
                    >
                        + Add more
                    </Button>
                )}
            </div>
            <Divider />
        </Fragment>
    )
}

type DataEntryProps = Pick<InstantOnboardingCustomDataSectionProps, 'disabled' | 'fetchOptions' | 'type'> & {
    index: number
    value: string
    toggle?: () => void
    handleOptionChange: (value: string, index: number) => void
    handleOptionRemove: (index: number) => void
}

const DataEntry: FunctionComponent<React.PropsWithChildren<DataEntryProps>> = ({
    index,
    disabled,
    fetchOptions,
    type,
    value,
    handleOptionChange,
    handleOptionRemove,
}) => {
    const [isFocused, setIsFocused] = useState(false)
    const handleFocus = useCallback(() => setIsFocused(true), [])
    const handleBlur = useCallback(() => setIsFocused(false), [])
    const onChange = useCallback((value: any) => handleOptionChange(value, index), [handleOptionChange, index])
    const onRemove = useCallback(() => handleOptionRemove(index), [handleOptionRemove, index])

    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                margin-bottom: 16px;
            `}
        >
            <div
                css={css`
                    margin-right: 8px;
                    width: 22px;
                `}
            >
                {index + 1}.
            </div>
            <SuggestionInput
                disabled={disabled && !value && !isFocused}
                name={`${type}[${index}]`}
                fetchOptions={fetchOptions}
                onChange={onChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
                value={value}
                dataTestId='instant-onboarding-custom-data-input'
            />
            <div
                css={css`
                    margin-left: 8px;
                `}
            >
                <IconButton
                    dataTestId='instant-onboarding-custom-data-close'
                    name='close'
                    size={30}
                    onClick={onRemove}
                    style={css`
                        width: 25px;
                        height: 25px;
                    `}
                />
            </div>
        </div>
    )
}

export { InstantOnboardingCustomDataSection }
