import { FunctionComponent, ReactNode, ReactText } from 'react'
import { useController } from 'react-hook-form'
import { Autocomplete, AutocompleteProps } from '../inputs/autocomplete/Autocomplete'
import { ControlledFieldProps } from './types'

type ControlledAutocompleteProps = Omit<AutocompleteProps, 'value' | 'onChange' | 'errorMessage'> & ControlledFieldProps<string>

export type Option = { label?: string | ReactNode; value: ReactText | number | string; ['data-testid']?: string; helperLabel?: string }

const ControlledAutocomplete: FunctionComponent<React.PropsWithChildren<ControlledAutocompleteProps>> = ({
    name,
    control,
    defaultValue,
    mode,
    placeholder,
    canClear,
    options,
    dropdownWidth,
    isOpen,
    errorTooltipMessage,
    infoMessage,
    disabled,
    label,
    labelTooltip,
    dataTestId = '',
    maxTagCount,
    maxTagTextLength,
    showArrow,
    virtualized,
    styles,
    autoClearSearchValue,
    autoFocus,
}) => {
    const {
        field: { onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue,
    })

    return (
        <Autocomplete
            onChange={onChange}
            currentValues={value}
            errorMessage={error?.message}
            errorTooltipMessage={error?.types?.tooltip as string}
            placeholder={placeholder}
            canClear={canClear}
            options={options}
            dropdownWidth={dropdownWidth}
            isOpen={isOpen}
            infoMessage={infoMessage}
            disabled={disabled}
            label={label}
            labelTooltip={labelTooltip}
            defaultValue={defaultValue}
            mode={mode}
            dataTestId={dataTestId}
            styles={styles}
            name={name}
            maxTagCount={maxTagCount}
            maxTagTextLength={maxTagTextLength}
            showArrow={showArrow}
            value={value}
            virtualized={virtualized}
            autoClearSearchValue={autoClearSearchValue}
            autoFocus={autoFocus}
        />
    )
}

export { ControlledAutocomplete }
