/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Dispatch, FunctionComponent, MouseEvent, MutableRefObject, SetStateAction, useCallback } from 'react'
import { Button } from '../../components/Button'
import { Icon } from '../../components/Icon'
import { DropdownComponent } from '../../components/inputs/autocomplete-select/DropdownComponent'
import { mqMax } from '../../GlobalStyle'

interface OpenOpportunitiesSortingProps {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    sorting: string
    onChange: (option: string) => void
}

const sortingOptions = [
    {
        label: 'Date posted',
        value: 'datePosted',
    },
    { label: 'Last updated', value: 'lastUpdate' },
    { label: 'Start date', value: 'startDate' },
    { label: 'End date', value: 'endDate' },
    { label: 'Min Rate (desc)', value: 'rate' },
    { label: 'Min Deal Size (desc)', value: 'dealSize' },
]

const OpenOpportunitiesSortingBase: FunctionComponent<React.PropsWithChildren<OpenOpportunitiesSortingProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    sorting,
    onChange,
}) => {
    const toggleOpen = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation()
            setIsOpen(prev => !prev)
        },
        [setIsOpen],
    )
    const onSortingChange = useCallback(
        (field: string) => {
            onChange(field)
        },
        [onChange],
    )

    const theme = useTheme()

    return (
        <div
            ref={rangeRef}
            css={css`
                position: relative;
                margin-left: auto;
            `}
            data-testid='opportunity-more-actions'
            onClick={toggleOpen}
        >
            <span
                css={css`
                    cursor: pointer;
                `}
            >
                Sort by:{' '}
                <span
                    css={css`
                        font-weight: 500;
                    `}
                >
                    {sortingOptions.find(sortingOption => sortingOption.value === sorting)?.label}
                </span>
                <Icon
                    name='arrow-down'
                    css={css`
                        margin-left: 4px;
                    `}
                />
            </span>
            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        bottom: calc(100% + 10px);
                        right: 0;
                        background-color: ${theme.colors.white};
                        border: 1px solid ${theme.colors.gray_2};
                        border-radius: 2px;

                        ${mqMax[1]} {
                            left: -50px;
                            right: auto;
                        }
                        z-index: 10;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                        `}
                    >
                        {sortingOptions.map(sortingOption => {
                            return (
                                <Button
                                    key={sortingOption.value}
                                    variant='link'
                                    style={css`
                                        padding: 0 15px;
                                        white-space: nowrap;
                                        height: 48px;
                                        text-align: left;
                                        width: 200px;

                                        ${sortingOption.value === sorting &&
                                        css`
                                            font-weight: 500;
                                        `}
                                    `}
                                    onClick={() => onSortingChange(sortingOption.value)}
                                >
                                    {sortingOption.label}
                                </Button>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}

const OpenOpportunitiesSorting = DropdownComponent(OpenOpportunitiesSortingBase)

export { OpenOpportunitiesSorting }
