/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { useLifecycles } from 'react-use'
import { getCompanyRoles, getCountries, getUser } from 'src/api/api'
import { SwitchTab } from 'src/components/SwitchTab'
import { UserSegmentationCompany } from 'src/contracts/company'
import { Country } from 'src/contracts/country'
import { UserForm } from 'src/contracts/userData'
import { mqMax } from 'src/GlobalStyle'
import { history } from 'src/history'
import { useUserContext } from 'src/hooks/useUserContext'
import { ReduxContext } from 'src/redux/Store'
import { Nullable } from 'src/types'
import { useLogger } from 'src/utils/useLogger'
import { ClientForm } from './ClientForm'
import { LeavingConfirmationModal } from './LeavingConfirmationModal'
import { PartnerForm } from './PartnerForm'
import { useCompany } from 'src/hooks/useCompany'
import { VMCstatusEnum } from 'src/contracts/vmc'

const initialUserData: UserForm = {
    firstName: '',
    lastName: '',
    phone: '',
}

const initialCompanyData: UserSegmentationCompany = {
    id: '',
    type: null,
    userId: '',
    status: '',
    createdAt: 0,
    legalName: '',
    country: '',
    city: '',
    website: '',
    workEmailAddress: '',
    roleInTheCompany: '',
    companySize: '',
    partnerClutchUrl: '',
    partnerSpecialistLocations: [],
    partnerCoreTechStack: [],
    partnerTechStack: [],
    partnerIndustries: [],
    clientIndustries: [],
    clientSizeOfITWorkForce: '',
    msaSigned: false,
}

interface UserSegmentationFormProps {
    hasVmcStatus: boolean
}

const UserSegmentationForm: FC<UserSegmentationFormProps> = ({ hasVmcStatus = false }) => {
    const [editMode, setEditMode] = useState<boolean>(false)
    const [companyData, setCompanyData] = useState<UserSegmentationCompany>(initialCompanyData)
    const [tab, setTab] = useState<Nullable<string>>(null)
    const [isMounted, setIsMounted] = useState(false)
    const [saveChangesModalVisible, setSaveChangesModalVisible] = useState(false)
    const [companyRoles, setCompanyRoles] = useState<Array<string>>([])
    const [userData, setUserData] = useState<UserForm>(initialUserData)
    const [countries, setCountries] = useState<Array<Country>>([])

    const theme = useTheme()
    const { activeContext } = useUserContext()
    const log = useLogger()

    const {
        selectors: { user, whiteLabel, VMCstatus },
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { company } = useCompany()

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    useEffect(() => {
        if (VMCstatus === VMCstatusEnum.INVITED) {
            setTab('PARTNER')
        }
    }, [VMCstatus])

    const handleTabClick = useCallback((name: string) => {
        setTab(name)
    }, [])

    const handleToggleModal = useCallback(() => {
        setSaveChangesModalVisible(!saveChangesModalVisible)
    }, [saveChangesModalVisible])

    useEffect(() => {
        if (activeContext?.companyId) {
            if (company) {
                layoutToggleLoader(false)

                setCompanyData(company)
                setEditMode(true)
                setTab(company.type)
            } else {
                layoutToggleLoader(true)
            }
        }
    }, [activeContext?.companyId, company, layoutToggleLoader])

    const cancel = useCallback((): void => {
        history.push('/dashboard')
    }, [])

    const hideLeavingConfirmationModal = useCallback((): void => {
        setSaveChangesModalVisible(false)
    }, [])

    useEffect(() => {
        getCountries()
            .then(countriesData => {
                if (isMounted) {
                    setCountries(countriesData)
                }
            })
            .catch(log)
    }, [isMounted, log])

    useEffect(() => {
        if (user?.uid) {
            getUser(user.firebaseId)
                .then(data => {
                    if (isMounted) {
                        setUserData(data)
                    }
                })
                .catch(log)
        }
    }, [isMounted, log, user])

    useEffect(() => {
        getCompanyRoles()
            .then((data: Array<string>) => {
                if (isMounted) {
                    setCompanyRoles(data)
                }
            })
            .catch(log)
    }, [isMounted, log])

    return (
        <Fragment>
            {!whiteLabel?.readOnlySettings && !hasVmcStatus && (
                <div
                    css={css`
                        display: flex;
                        ${mqMax[1]} {
                            flex-direction: column;
                        }
                    `}
                >
                    <div>
                        <Tooltip
                            title={
                                companyData.type === 'PARTNER'
                                    ? 'Once your company information has been submitted you need to contact our support to update it. Please contact our support at support@talent-alpha.com'
                                    : ''
                            }
                            placement='top'
                        >
                            <div>
                                <SwitchTab
                                    onTabClick={() => handleTabClick('CLIENT')}
                                    name='client'
                                    header={
                                        <h6
                                            css={css`
                                                color: ${companyData.type === 'PARTNER' ? theme.colors.gray_3 : theme.colors.gray_5};
                                            `}
                                        >
                                            To Find Talent
                                        </h6>
                                    }
                                    isSelected={tab === null || tab === 'CLIENT'}
                                    disabled={companyData.type === 'PARTNER'}
                                    description={
                                        <span
                                            css={css`
                                                font-size: 14px;
                                                line-height: 22px;
                                                color: ${companyData.type === 'PARTNER' ? theme.colors.gray_3 : theme.colors.gray_4};
                                            `}
                                        >
                                            You represent a business and are looking for IT Talent for your technology projects.
                                        </span>
                                    }
                                    color={theme.colors.violet_2}
                                />
                            </div>
                        </Tooltip>
                    </div>

                    <div
                        css={css`
                            margin-left: 16px;
                            ${mqMax[1]} {
                                margin-top: 16px;
                                margin-left: 0px;
                            }
                        `}
                    >
                        <Tooltip
                            title={
                                companyData.type === 'CLIENT'
                                    ? 'Once your company information has been submitted you need to contact our support to update it. Please contact our support at support@talent-alpha.com'
                                    : ''
                            }
                            placement='top'
                        >
                            <div>
                                <SwitchTab
                                    onTabClick={() => handleTabClick('PARTNER')}
                                    name='partner'
                                    isSelected={tab === null || tab === 'PARTNER'}
                                    header={
                                        <h6
                                            css={css`
                                                color: ${companyData.type === 'CLIENT' ? theme.colors.gray_3 : theme.colors.gray_5};
                                            `}
                                        >
                                            To Provide Talent
                                        </h6>
                                    }
                                    disabled={companyData.type === 'CLIENT'}
                                    description={
                                        <span
                                            css={css`
                                                font-size: 14px;
                                                line-height: 22px;
                                                color: ${companyData.type === 'CLIENT' ? theme.colors.gray_3 : theme.colors.gray_4};
                                            `}
                                        >
                                            You represent an IT services company and have available talent looking for work on projects.
                                        </span>
                                    }
                                    color={theme.colors.beige_3}
                                />
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )}
            {tab === 'CLIENT' && !whiteLabel?.readOnlySettings && (
                <ClientForm
                    companyData={companyData}
                    companyRoles={companyRoles}
                    userData={userData}
                    handleToggleModal={handleToggleModal}
                    cancel={cancel}
                    countries={countries}
                    editMode={editMode}
                    hasVmcStatus={hasVmcStatus}
                />
            )}
            {(tab === 'PARTNER' || whiteLabel?.readOnlySettings) && (
                <PartnerForm
                    companyData={companyData}
                    companyRoles={companyRoles}
                    userData={userData}
                    handleToggleModal={handleToggleModal}
                    cancel={cancel}
                    countries={countries}
                    editMode={editMode}
                    hasVmcStatus={hasVmcStatus}
                />
            )}

            <LeavingConfirmationModal
                isVisible={saveChangesModalVisible}
                handleConfirmation={cancel}
                handleRejection={hideLeavingConfirmationModal}
            />
        </Fragment>
    )
}

export { UserSegmentationForm }
