import update from 'immutability-helper'
import { Context, createContext, Dispatch, FunctionComponent, useReducer, useRef } from 'react'
import { TabType } from '../components/layout/dashboard/Header'
import { Application } from '../contracts/applications'
import { UserContext, UserContextGroup } from '../contracts/contexts'
import { Country } from '../contracts/country'
import { WhiteLabel } from '../contracts/whiteLabel'
import { Nullable, NullableArray } from '../types'
import { decodeAccessToken, getDecodedAccessToken } from '../utils/currentUser'
import { log } from '../utils/useLogger'
import { Feature, FeatureFlags, featureFlagsSelector } from './featureFlags'
import { UserSegmentationCompany } from 'src/contracts/company'
import { VMCstatusEnum } from 'src/contracts/vmc'

const ACTIONS = {
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_UPDATE_PERSONAL_DETAILS: 'USER_UPDATE_PERSONAL_DETAILS',
    COMPANY_UPDATE: 'COMPANY_UPDATE',
    LAYOUT_TOGGLE_ACTIVE_TAB: 'LAYOUT_TOGGLE_ACTIVE_TAB',
    LAYOUT_TOGGLE_LOADER: 'LAYOUT_TOGGLE_LOADER',
    DICTIONARY_DATA_SET: 'DICTIONARY_DATA_SET',
    WHITE_LABEL_SET: 'WHITE_LABEL_SET',
    SET_SHOULD_WAIT_FOR_WHITE_LABEL: 'SET_SHOULD_WAIT_FOR_WHITE_LABEL',
    SET_SHOULD_WAIT_FOR_USER_CONTEXTS: 'SET_SHOULD_WAIT_FOR_USER_CONTEXTS',
    SET_TERMS_STATUS: 'SET_TERMS_STATUS',
    SET_FEATURES: 'SET_FEATURES',
    AUTH_SUBSCRIBE: 'AUTH_SUBSCRIBE',
    AUTH_UNSUBSCRIBE: 'AUTH_UNSUBSCRIBE',
    SET_USER_CONTEXTS: 'SET_USER_CONTEXTS',
    SET_USER_ACTIVE_CONTEXT_ID: 'SET_USER_ACTIVE_CONTEXT_ID',
    SET_USER_ACTIVE_CONTEXT_GROUP: 'SET_USER_ACTIVE_CONTEXT_GROUP',
    SET_VMC_STATUS: 'SET_VMC_STATUS',
}

type User = Nullable<any>

type UserPersonalDetails = Nullable<{
    firstName: string
    lastName: string
    phone: string
}>

type Layout = {
    activeTab: Nullable<TabType>
    showLoader: boolean
}

type ApplicationsDictionary = Nullable<Array<Application>>
type CountriesDictionary = Nullable<Array<Country>>
type RegionsDictionary = Nullable<Array<string>>
type SpecialistRolesDictionary = Nullable<Array<string>>
type LanguagesDictionary = Nullable<Array<string>>
type IndustriesDictionary = Nullable<Array<string>>
type SkillsDictionary = Nullable<Array<string>>
type ResponsibilitiesDictionary = Nullable<Array<string>>
type TimeZonesDictionary = Nullable<Array<string>>
type CurrenciesDictionary = Nullable<Array<string>>
type SenioritiesDictionary = Nullable<Array<string>>

type DictionaryData = {
    applications: ApplicationsDictionary
    countries: CountriesDictionary
    regions: RegionsDictionary
    specialistRoles: SpecialistRolesDictionary
    languages: LanguagesDictionary
    industries: IndustriesDictionary
    skills: SkillsDictionary
    responsibilities: ResponsibilitiesDictionary
    timezones: TimeZonesDictionary
    currencies: CurrenciesDictionary
    seniorities: SenioritiesDictionary
}

type DictionaryDataName = keyof DictionaryData
type DictionaryDataValue =
    | ApplicationsDictionary
    | CountriesDictionary
    | SpecialistRolesDictionary
    | IndustriesDictionary
    | SkillsDictionary
    | ResponsibilitiesDictionary
    | SenioritiesDictionary

export type State = {
    user: User
    userPersonalDetails: UserPersonalDetails
    company: Nullable<UserSegmentationCompany>
    layout: Layout
    dictionaryData: DictionaryData
    whiteLabel: Nullable<WhiteLabel>
    shouldWaitForWhiteLabel: boolean
    shouldWaitForUserContexts: boolean
    termsStatus: Nullable<TermsAcceptStatus>
    features: NullableArray<Feature>
    authSubscribed: boolean
    userContexts: NullableArray<UserContext>
    userActiveContextId: Nullable<string>
    userActiveContextGroups: NullableArray<UserContextGroup>
    VMCstatus: VMCstatusEnum
}

type Action = {
    type: string
    payload?: any
}

const initialState = {
    user: null,
    userPersonalDetails: null,
    company: null,
    layout: {
        activeTab: null,
        showLoader: true,
    },
    dictionaryData: {
        applications: null,
        countries: null,
        regions: null,
        specialistRoles: null,
        languages: null,
        industries: null,
        skills: null,
        responsibilities: null,
        timezones: null,
        currencies: null,
        seniorities: null,
    },
    whiteLabel: null,
    shouldWaitForWhiteLabel: true,
    shouldWaitForUserContexts: true,
    termsStatus: null,
    features: null,
    authSubscribed: true,
    userContexts: null,
    userActiveContextId: null,
    userActiveContextGroups: null,
    VMCstatus: VMCstatusEnum.NONE,
}

type DictionaryDataPayload = {
    name: DictionaryDataName
    data: DictionaryDataValue
}

export enum TermsAcceptStatus {
    Declined = 'Declined',
    Accepted = 'Accepted',
    RequiredAction = 'RequiredAction',
}

const reducer = (state: State, action: Action): State => {
    let output

    switch (action.type) {
        case ACTIONS.USER_LOGIN:
            output = update(state, { $merge: { user: action.payload } })
            break

        case ACTIONS.USER_LOGOUT:
            output = update(state, { $merge: { user: {}, company: null, userActiveContextGroups: null, userActiveContextId: null } })
            break

        case ACTIONS.USER_UPDATE_PERSONAL_DETAILS:
            output = update(state, { $merge: { userPersonalDetails: action.payload } })
            break

        case ACTIONS.COMPANY_UPDATE:
            output = update(state, { $merge: { company: action.payload } })
            break

        case ACTIONS.LAYOUT_TOGGLE_ACTIVE_TAB:
            if (state.layout.activeTab === action.payload) {
                output = update(state, { $merge: { layout: { ...state.layout, activeTab: null } } })
            } else {
                output = update(state, { $merge: { layout: { ...state.layout, activeTab: action.payload } } })
            }
            break

        case ACTIONS.LAYOUT_TOGGLE_LOADER:
            output = update(state, { $merge: { layout: { ...state.layout, showLoader: action.payload } } })
            break

        case ACTIONS.DICTIONARY_DATA_SET:
            output = update(state, {
                $merge: {
                    dictionaryData: {
                        ...state.dictionaryData,
                        [action.payload.name]: action.payload.data,
                    },
                },
            })
            break

        case ACTIONS.WHITE_LABEL_SET:
            output = update(state, { $merge: { whiteLabel: action.payload } })
            break

        case ACTIONS.SET_SHOULD_WAIT_FOR_WHITE_LABEL:
            output = update(state, { $merge: { shouldWaitForWhiteLabel: action.payload } })
            break

        case ACTIONS.SET_SHOULD_WAIT_FOR_USER_CONTEXTS:
            output = update(state, { $merge: { shouldWaitForUserContexts: action.payload } })
            break

        case ACTIONS.SET_TERMS_STATUS:
            output = update(state, { $merge: { termsStatus: action.payload } })
            break

        case ACTIONS.SET_FEATURES:
            output = update(state, { $merge: { features: action.payload } })
            break

        case ACTIONS.AUTH_SUBSCRIBE:
            output = update(state, { $merge: { authSubscribed: true } })
            break

        case ACTIONS.AUTH_UNSUBSCRIBE:
            output = update(state, { $merge: { authSubscribed: false } })
            break

        case ACTIONS.SET_USER_CONTEXTS:
            output = update(state, { $merge: { userContexts: action.payload } })
            break

        case ACTIONS.SET_USER_ACTIVE_CONTEXT_ID:
            output = update(state, { $merge: { userActiveContextId: action.payload } })
            break

        case ACTIONS.SET_USER_ACTIVE_CONTEXT_GROUP:
            output = update(state, { $merge: { userActiveContextGroups: action.payload } })
            break
        case ACTIONS.SET_VMC_STATUS:
            output = update(state, { $merge: { VMCstatus: action.payload } })
            break

        default:
            output = state
            break
    }

    return output
}

type Actions = {
    userLogin: (user: User) => void
    userLogout: () => void
    userUpdatePersonalDetails: (userPersonalDetails: UserPersonalDetails) => void
    companyUpdate: (company: UserSegmentationCompany) => void
    authSubscribe: () => void
    authUnsubscribe: () => void
    layoutToggleActiveTab: (tabType: TabType) => void
    layoutToggleLoader: (state: boolean) => void
    dictionaryDataSet: (dataPayload: DictionaryDataPayload) => void
    whiteLabelSet: (whiteLabel: Nullable<WhiteLabel>) => void
    setShouldWaitForWhiteLabel: (isFetching: boolean) => void
    setShouldWaitForUserContexts: (isFetching: boolean) => void
    setTermsStatus: (status: Nullable<TermsAcceptStatus>) => void
    setFeatures: (features: Nullable<Array<Feature>>) => void
    setUserContexts: (userContexts: NullableArray<UserContext>) => void
    setUserActiveContextId: (userContextId: Nullable<string>) => void
    setUserActiveContextGroups: (userContextGroups: NullableArray<UserContextGroup>) => void
    setVMCstatus: (VMCstatus: VMCstatusEnum | null) => void
}

const getActions = (dispatch: Dispatch<Action>) => {
    return {
        userLogin: (user: User) => dispatch({ type: ACTIONS.USER_LOGIN, payload: user }),
        userLogout: () => dispatch({ type: ACTIONS.USER_LOGOUT }),
        userUpdatePersonalDetails: (userPersonalDetails: UserPersonalDetails) =>
            dispatch({ type: ACTIONS.USER_UPDATE_PERSONAL_DETAILS, payload: userPersonalDetails }),
        companyUpdate: (company: UserSegmentationCompany) => dispatch({ type: ACTIONS.COMPANY_UPDATE, payload: company }),
        authSubscribe: () => dispatch({ type: ACTIONS.AUTH_SUBSCRIBE }),
        authUnsubscribe: () => dispatch({ type: ACTIONS.AUTH_UNSUBSCRIBE }),
        layoutToggleActiveTab: (tabType: TabType) =>
            dispatch({
                type: ACTIONS.LAYOUT_TOGGLE_ACTIVE_TAB,
                payload: tabType,
            }),
        layoutToggleLoader: (state: boolean) => dispatch({ type: ACTIONS.LAYOUT_TOGGLE_LOADER, payload: state }),
        dictionaryDataSet: (dataPayload: DictionaryDataPayload) =>
            dispatch({
                type: ACTIONS.DICTIONARY_DATA_SET,
                payload: dataPayload,
            }),
        whiteLabelSet: (whiteLabel: Nullable<WhiteLabel>) => dispatch({ type: ACTIONS.WHITE_LABEL_SET, payload: whiteLabel }),
        setShouldWaitForWhiteLabel: (isFetching: boolean) =>
            dispatch({ type: ACTIONS.SET_SHOULD_WAIT_FOR_WHITE_LABEL, payload: isFetching }),
        setShouldWaitForUserContexts: (isFetching: boolean) =>
            dispatch({ type: ACTIONS.SET_SHOULD_WAIT_FOR_USER_CONTEXTS, payload: isFetching }),
        setTermsStatus: (status: Nullable<TermsAcceptStatus>) => dispatch({ type: ACTIONS.SET_TERMS_STATUS, payload: status }),
        setFeatures: (features: Nullable<Array<Feature>>) => dispatch({ type: ACTIONS.SET_FEATURES, payload: features }),
        setUserContexts: (userContexts: NullableArray<UserContext>) => dispatch({ type: ACTIONS.SET_USER_CONTEXTS, payload: userContexts }),
        setUserActiveContextId: (userContextId: Nullable<string>) =>
            dispatch({ type: ACTIONS.SET_USER_ACTIVE_CONTEXT_ID, payload: userContextId }),
        setUserActiveContextGroups: (userContextGroups: NullableArray<UserContextGroup>) =>
            dispatch({ type: ACTIONS.SET_USER_ACTIVE_CONTEXT_GROUP, payload: userContextGroups }),
        setVMCstatus: (VMCstatus: VMCstatusEnum | null) => dispatch({ type: ACTIONS.SET_VMC_STATUS, payload: VMCstatus }),
    }
}

type Selectors = {
    decodedAccessToken: any
    user: Nullable<User>
    userPersonalDetails: UserPersonalDetails
    userId: Nullable<string>
    firebaseId: Nullable<string>
    company: Nullable<UserSegmentationCompany>
    layoutActiveTab: Nullable<TabType>
    showLoader: boolean
    dictionaryData: DictionaryData
    whiteLabel: Nullable<WhiteLabel>
    shouldWaitForWhiteLabel: boolean
    shouldWaitForUserContexts: boolean
    termsStatus: Nullable<TermsAcceptStatus>
    featureFlags: FeatureFlags
    userContexts: NullableArray<UserContext>
    userActiveContextId: Nullable<string>
    userActiveContextGroups: NullableArray<UserContextGroup>
    VMCstatus: VMCstatusEnum | null
}

const getSelectors = (state: State): Selectors => {
    let decodedAccessToken = getDecodedAccessToken()
    try {
        if (state?.user?.accessToken) {
            decodedAccessToken = decodeAccessToken(state.user.accessToken)
        }
    } catch (err) {
        log(err, 'error')
    }

    return {
        decodedAccessToken,
        user: state?.user || null,
        userPersonalDetails: state?.userPersonalDetails || null,
        userId: state?.user?.uid || null,
        firebaseId: state?.user?.firebaseId || null,
        company: state.company,
        layoutActiveTab: state.layout.activeTab,
        showLoader: state.layout.showLoader,
        dictionaryData: state.dictionaryData,
        whiteLabel: state?.whiteLabel || null,
        shouldWaitForWhiteLabel: state?.shouldWaitForWhiteLabel || false,
        shouldWaitForUserContexts: state?.shouldWaitForUserContexts || false,
        termsStatus: state?.termsStatus,
        featureFlags: featureFlagsSelector(state),
        userContexts: state.userContexts,
        userActiveContextId: state.userActiveContextId,
        userActiveContextGroups: state.userActiveContextGroups,
        VMCstatus: state.VMCstatus,
    }
}

type ReduxContextProps = {
    state: State
    dispatch?: Dispatch<Action>
    actions: Actions
    selectors: Selectors
}

const ReduxContext: Context<ReduxContextProps> = createContext<ReduxContextProps>({
    state: initialState,
    selectors: getSelectors(initialState),
    actions: getActions(() => null),
})

const ReduxProvider: FunctionComponent<React.PropsWithChildren<{ defaultState: State }>> = ({ defaultState, children }): any => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    const selectors = getSelectors(state)
    const actionsRef = useRef<Actions>(getActions(dispatch))

    return <ReduxContext.Provider value={{ state, selectors, actions: actionsRef.current }}>{children}</ReduxContext.Provider>
}

export { ReduxProvider, ReduxContext, initialState }
