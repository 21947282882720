/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent } from 'react'
import { SearchLocation } from '../../contracts/common/searchLocation'
import { Dot } from '../Dot'

const CountryCitiesList: FunctionComponent<{ countryCitiesList: Array<SearchLocation> }> = ({ countryCitiesList }) => (
    <Fragment>
        {countryCitiesList.map((location, index) => (
            <Fragment key={location.country}>
                {index !== 0 && <Dot />}
                <strong>{location.country}: </strong>
                {location.cities.join(', ')}
            </Fragment>
        ))}
    </Fragment>
)

export { CountryCitiesList }
