/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'

export type TalentBadgeVariant = 'primary' | 'secondary' | 'tertiary'

const getTalentBadgeStyles = (variant: TalentBadgeVariant) => {
    let styles
    switch (variant) {
        case 'secondary':
            styles = css`
                opacity: 0.8;
            `
            break
        case 'tertiary':
            styles = css`
                opacity: 0.6;
            `
            break
        default:
            styles = null
    }
    return styles
}

type TalentBadgeProps = {
    variant?: TalentBadgeVariant
    text?: string
    style?: SerializedStyles
}

const TalentBadge: FunctionComponent<React.PropsWithChildren<TalentBadgeProps>> = ({ children, variant = 'primary', text = '', style }) => {
    const theme = useTheme()

    return (
        <span
            css={css`
                display: inline-block;
                border-width: 1px;
                font-size: 12px;
                line-height: 16px;
                border-radius: 2px;
                padding: 1px 6px;
                background-color: ${theme.colors.green_2};
                color: ${theme.colors.gray_6};
                text-transform: capitalize;
                font-weight: 400;

                ${getTalentBadgeStyles(variant)}
                ${style}
            `}
        >
            {text}
            {children}
        </span>
    )
}

export { TalentBadge }
