import {
    ApplicationStatusEnum,
    Application,
    contractRateApplicationStatusesList,
    interviewDateStatusesList,
    AllApplicationsItem,
} from 'src/contracts/opportunities-manager/contracts'
import { Nullable } from 'src/types'

const getRate = (
    application: { currency: string; negotiableRate?: number; preferredRate?: number },
    config: { showCurrency?: boolean } = { showCurrency: true },
) => {
    const currency = config.showCurrency ? ` ${application?.currency}/h` : ''

    let rate: string
    switch (true) {
        case !!application.preferredRate && !!application.negotiableRate:
            rate = `${application.negotiableRate} - ${application.preferredRate} ${currency}`
            break
        case !!application.negotiableRate:
            rate = `${application.negotiableRate} ${currency}`
            break
        case !!application.preferredRate:
            rate = `${application.preferredRate} ${currency}`
            break
        default:
            rate = '–'
            break
    }
    return rate
}

const shouldDisplayContractRate = (args: {
    status: ApplicationStatusEnum | undefined
    currency: string | undefined
    contractRate: Nullable<number> | undefined
}): args is { currency: string; contractRate: number; status: ApplicationStatusEnum } => {
    return Boolean(
        args.status &&
            [ApplicationStatusEnum.OFFER_ACCEPTED, ApplicationStatusEnum.WON].includes(args.status) &&
            args.currency &&
            args.contractRate,
    )
}

const shouldShowContractRateModal = (args: {
    applicationId: Nullable<string>
    applications: Nullable<Application[] | AllApplicationsItem[]> | undefined
    status: Nullable<ApplicationStatusEnum>
}): args is { applicationId: string; applications: Application[]; status: ApplicationStatusEnum } => {
    return Boolean(args.applicationId && args.applications && args.status && contractRateApplicationStatusesList.includes(args.status))
}

const shouldshowInterviewDateModal = (args: {
    applicationId: Nullable<string>
    status: Nullable<ApplicationStatusEnum>
}): args is { applicationId: string; status: ApplicationStatusEnum } => {
    return Boolean(args.applicationId && args.status && interviewDateStatusesList.includes(args.status))
}

export { getRate, shouldDisplayContractRate, shouldShowContractRateModal, shouldshowInterviewDateModal }
