/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Icon } from '../Icon'
import { StepStatus, StepType } from './types'

type StepProps = {
    step: StepType
    stepNumber: number
    indicatorStyles?: (status: StepStatus) => SerializedStyles
    styles?: SerializedStyles
}

const Step: FunctionComponent<React.PropsWithChildren<StepProps>> = ({ step, stepNumber, styles, indicatorStyles }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                color: ${step.status === StepStatus.PENDING ? theme.colors.violet_3 : theme.colors.gray_6};
                padding: 20px 0 0 24px;

                ${styles}
            `}
        >
            <div
                css={css`
                    display: flex;
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                    border: 1px solid;
                    border-color: ${step.status === StepStatus.PENDING ? theme.colors.violet_3 : theme.colors.violet_5};
                    background-color: ${step.status === StepStatus.DONE ? theme.colors.violet_5 : 'transparent'};

                    ${indicatorStyles ? indicatorStyles(step.status) : ''}
                `}
            >
                {step.status === StepStatus.DONE ? <Icon name='check-white' /> : stepNumber}
            </div>
            {step.name}
        </div>
    )
}

export { Step }
