/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../../components/forms/ControlledAutocompleteSelect'
import { ControlledTextarea } from '../../components/forms/ControlledTextarea'
import { Icon } from '../../components/Icon'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { Modal } from '../../components/Modal'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Nullable } from '../../types'
import { hasErrors } from '../../utils/errors'
import { alphabeticallyAsc } from '../../utils/sorting'
import { useLogger } from '../../utils/useLogger'
import { yup } from '../../validation/yup'
import { ApplicationStatus } from '../../contracts/opportunities-manager/contracts'
import { ApplicationWithdrawalParams } from 'src/contracts/open-opportunities/contracts'
import { getRejectionReasons } from 'src/api/open-opportunities/api'

const SHOW_WITHDRAWAL_SUCCESS_MESSAGE = 'SHOW_WITHDRAWAL_SUCCESS_MESSAGE'

type WithdrawalReasonFormData = { comment: string; rejectionReason: string }

type WithdrawalReasonModalProps = {
    onSubmit: (currentRejectionApplicationId: string, body: ApplicationWithdrawalParams) => void
    currentRejectionApplicationId: string
    closeRejectionModal: () => void
}

const schema = yup.object().shape({
    rejectionReason: yup.string().required(),
    comment: yup.string().when('rejectionReason', { is: (value: string) => value === 'Other', then: yup.string().required() }),
})

const WithdrawalReasonModal: FunctionComponent<React.PropsWithChildren<WithdrawalReasonModalProps>> = ({
    onSubmit,
    currentRejectionApplicationId,
    closeRejectionModal,
}) => {
    const {
        control,
        handleSubmit,
        formState: { isDirty, isSubmitting, errors },
        trigger,
    } = useForm<WithdrawalReasonFormData>({
        mode: 'onChange',
        resolver: yupResolver(schema),
    })

    const logError = useLogger('error')
    const { addError } = useNotifications()

    const [rejectionReasons, setRejectionReasons] = useState<Array<Omit<ApplicationStatus, 'draft' | 'status'>>>([])

    const loadRejectionReasons = useCallback(() => {
        getRejectionReasons()
            .then(values =>
                setRejectionReasons(
                    values.rejectionReasons.WITHDRAWN.sort((a, b) =>
                        a.label === 'Other' ? 1 : b.label === 'Other' ? -1 : alphabeticallyAsc(a.label, b.label),
                    ),
                ),
            )
            .catch(e => {
                addError()
                logError(e)
            })
    }, [addError, logError])

    useEffect(() => loadRejectionReasons(), [loadRejectionReasons])

    const [rejectionReasonLabel, setRejectionReasonLabel] = useState<Nullable<string>>(null)
    const [comment, setComment] = useState<Nullable<string>>(null)

    const submitCallback = useCallback(
        (data: WithdrawalReasonFormData) => {
            const rejectionReason = rejectionReasons.find(({ label }) => label === data.rejectionReason)?.rejectionReason
            setRejectionReasonLabel(data.rejectionReason)
            setComment(data.comment)
            if (rejectionReason) {
                onSubmit(currentRejectionApplicationId, { rejectionReason, rejectionReasonComment: data.comment })
            }
        },
        [currentRejectionApplicationId, onSubmit, rejectionReasons],
    )
    const theme = useTheme()

    const onBeforeBlur = useCallback(() => trigger('comment'), [trigger])

    return (
        <Modal opened onClose={closeRejectionModal}>
            {currentRejectionApplicationId === SHOW_WITHDRAWAL_SUCCESS_MESSAGE ? (
                <div>
                    <Icon
                        name='withdraw-summary'
                        size={85}
                        css={css`
                            display: block;
                            margin: 0 auto;
                        `}
                    />
                    <h5
                        css={css`
                            text-align: center;
                        `}
                        data-testid='withdraw-title'
                    >
                        Candidate Withdrawn
                    </h5>

                    <p
                        css={css`
                            color: ${theme.colors.gray_4};
                        `}
                    >
                        Reason for withdrawal
                    </p>
                    <p>{rejectionReasonLabel === 'Other' ? comment : rejectionReasonLabel}</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit(submitCallback)}>
                    <h5
                        css={css`
                            margin-bottom: 24px;
                        `}
                    >
                        Are you sure you want to withdraw this Candidate?
                    </h5>
                    <ControlledAutocompleteSelect
                        canFilter={false}
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                        label='Rejection reason'
                        placeholder='Choose reason'
                        options={rejectionReasons.map(({ label }) => label)}
                        control={control}
                        name='rejectionReason'
                        dataTestId='application-withdrawal-reason'
                        beforeBlur={onBeforeBlur}
                        styles={css`
                            margin-bottom: 24px;
                        `}
                    />
                    <ControlledTextarea label='Reason for application withdrawal' name='comment' control={control} />
                    <Divider />
                    <FlexBox alignItems='center' justifyContent='flex-end'>
                        <Button variant='link' onClick={closeRejectionModal}>
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            style={css`
                                margin-left: 16px;
                            `}
                            disabled={hasErrors(errors) || !isDirty || isSubmitting}
                            dataTestId='withdraw-button'
                        >
                            Withdraw
                        </Button>
                    </FlexBox>
                </form>
            )}
        </Modal>
    )
}

export { WithdrawalReasonModal, SHOW_WITHDRAWAL_SUCCESS_MESSAGE }
