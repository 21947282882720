/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, ReactNode, useCallback, useMemo, useState } from 'react'
import { useSize } from 'react-use'
import { Button } from './Button'

type TruncateProps = {
    children: ReactNode
    truncateHeight?: number
}

const Truncate: FunctionComponent<React.PropsWithChildren<TruncateProps>> = ({ truncateHeight = 80, children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [sized, { height }] = useSize(() => <div>{children}</div>, { height: 20 })

    const shouldBeTruncated = useMemo(() => truncateHeight < height, [height, truncateHeight])

    const toggleReadMore = useCallback(() => {
        setIsCollapsed(currentReadMore => !currentReadMore)
    }, [])

    return (
        <Fragment>
            {isCollapsed ? (
                <div
                    css={css`
                        white-space: pre-wrap;
                    `}
                >
                    {sized}
                    {shouldBeTruncated && (
                        <Button variant='linkForm' onClick={toggleReadMore}>
                            Less
                        </Button>
                    )}
                </div>
            ) : (
                <div>
                    <div
                        css={css`
                            white-space: pre-wrap;
                            max-height: ${shouldBeTruncated ? truncateHeight + 'px' : 'auto'};
                            overflow: hidden;
                            position: relative;
                        `}
                    >
                        {sized}
                        {shouldBeTruncated && (
                            <div
                                css={css`
                                    position: absolute;
                                    background-image: linear-gradient(to bottom, transparent, white);
                                    height: 20px;
                                    width: 100%;
                                    bottom: 0;
                                `}
                            ></div>
                        )}
                    </div>

                    {shouldBeTruncated && (
                        <Button variant='linkForm' onClick={toggleReadMore}>
                            Read More
                        </Button>
                    )}
                </div>
            )}
        </Fragment>
    )
}

export { Truncate }
