import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { FC } from 'react'
import { mqMax } from 'src/GlobalStyle'

type AccessBadgeProps = {
    labelText?: string
}

const BadgeWrapper = styled.div`
    background-color: ${props => props.theme.colors.blue_3};
    border-radius: 20px;
    padding: 7px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    ${mqMax[1]} {
        margin-bottom: 12px;
    }
`

const SMEBadge: FC<AccessBadgeProps> = ({ labelText = 'SME Cloud for SHs' }) => {
    return (
        <Tooltip title='This opportunity is reserved for SME Cloud for Software Houses users. It enables SHs to share specialists with each other at no cost. In the future, SMEs will need to have a Premium Plan to have an access to this Human Cloud.'>
            <BadgeWrapper>{labelText}</BadgeWrapper>
        </Tooltip>
    )
}

export { SMEBadge }
