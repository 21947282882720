const mapErrorType = (type: string): string => {
    switch (type) {
        case 'INVALID':
            return 'Invalid field: '

        case 'DUPLICATED':
            return 'Duplicated field: '

        case 'REQUIRED':
            return 'Required field: '

        default:
            return 'Error in field: '
    }
}

export { mapErrorType }
