import {
    ApplicationStatus,
    ApplicationStatusColors,
    ApplicationStatusMapping,
    ApplicationStatusEnum,
} from 'src/contracts/opportunities-manager/contracts'
import { formatInterviewDate } from 'src/utils/dates'

import { StyledStatus } from './ApplicationStatusesHistory.styles'

interface OwnProps {
    colors: ApplicationStatusColors
    count: number
    index: number
    status: ApplicationStatus
}

const formatStatus = (status: ApplicationStatus) => {
    switch (status.status) {
        case ApplicationStatusEnum.INTERVIEW:
            // TODO
            // `interviewDate` should always be present for this status
            // but we could try to make `ApplicationStatus` a sum type of
            // rejection, interview and 'default' type status,
            // this hovewer wouuld require additional changes in all the places where we use it.
            const formattedDate = status.interviewDate ? `(${formatInterviewDate(status.interviewDate)})` : ''
            const formattedStatus = `${ApplicationStatusMapping[status.status]} ${formattedDate}`
            return formattedStatus
        default:
            return ApplicationStatusMapping[status.status]
    }
}

const Status: React.FC<OwnProps> = ({ colors, count, index, status }) => {
    return (
        <StyledStatus data-testid='status-history' key={index} colors={colors} count={count} index={index} status={status.status}>
            {formatStatus(status)}
        </StyledStatus>
    )
}

export { Status }
