/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { ColumnsType } from 'antd/es/table'
import { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'src/components/Button'
import { ResponsiveGrid } from 'src/components/data-grid/ResponsiveGrid'
import { DataNotFound } from 'src/components/DataNotFound'
import { Divider } from 'src/components/Divider'
import { DashboardLayout } from 'src/components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from 'src/components/layout/Loader'
import { ColorBackgroundWrapper } from 'src/components/layout/ResponsiveWrapper'
import { useNotifications } from 'src/components/notification/NotificationProvider'
import { FiltersBar, FiltersFunctions } from 'src/components/opportunities/FiltersBar'
import { Pagination } from 'src/components/Pagination'
import { SearchInput } from 'src/components/SearchInput'
import { getAppPath } from 'src/contracts/applications'
import { SortEntry } from 'src/contracts/common/sorting'
import { mqMax, mqMin } from 'src/GlobalStyle'
import { Nullable } from 'src/types'
import { convertTimestampToDateString } from 'src/utils/dates'
import { useLogger } from 'src/utils/useLogger'
import { ApplicationStatusCell } from 'src/components/opportunities-manager/ApplicationStatusCell'
import { ApplicationStatus, ApplicationStatusEnum } from 'src/contracts/opportunities-manager/contracts'
import { getApplicationsFilters, searchApplications, withdrawApplication } from '../../api/open-opportunities/api'

import {
    ApplicationsFiltersResponse,
    ApplicationsParams,
    ApplicationWithdrawalParams,
    SearchApplication,
} from '../../contracts/open-opportunities/contracts'
import {
    countFilters,
    parseQueryToApplicationsSearchRequest,
    stringifyApplicationsSearchRequestToQuery,
} from '../../components/open-opportunities/utils/applicationsUtils'
import { DEFAULT_ITEMS_PER_PAGE, VMCmenuItems } from '../../components/open-opportunities/utils/openOpportunitiesUtils'

import { useCompany } from 'src/hooks/useCompany'
import { ApplicationsFilters, ApplicationsFiltersBar } from 'src/components/open-opportunities/ApplicationsFiltersBar'
import { SHOW_WITHDRAWAL_SUCCESS_MESSAGE, WithdrawalReasonModal } from 'src/components/open-opportunities/WithdrawalReasonModal'
import { ApplicationActions } from 'src/components/open-opportunities/ApplicationActions'

const OpenVacanciesApplicationsPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const theme = useTheme()

    const [totalApplications, setTotalApplications] = useState<Nullable<number>>(null)
    const [applications, setApplications] = useState<Nullable<Array<SearchApplication>>>(null)
    const [isFetchingApplications, setIsFetchingApplications] = useState<boolean>(false)
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [filtersOptions, setFiltersOptions] = useState<ApplicationsFiltersResponse>({
        cities: [],
        countries: [],
        roles: [],
        statuses: [],
        submittedBy: [],
    })
    const [sorting, setSorting] = useState<Array<SortEntry>>([])

    const logError = useLogger('error')
    const { addError } = useNotifications()

    const navigate = useNavigate()
    const { hash, pathname, search } = useLocation()

    useEffect(() => {
        if (search) {
            setShowFilters(true)
        }
    }, [search])

    const searchRequest = useMemo<ApplicationsParams>(() => parseQueryToApplicationsSearchRequest(search), [search])
    const searchFilters = useMemo((): ApplicationsFilters => {
        const { searchText: s, paging: p, sorting: so, ...onlyFilters } = searchRequest
        return onlyFilters
    }, [searchRequest])

    const [currentPage, setCurrentPage] = useState<number>(searchRequest.paging?.page || 1)
    const [pageSize, setPageSize] = useState<number>(searchRequest.paging?.size || DEFAULT_ITEMS_PER_PAGE)
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText)
    const [selectedFilters, setSelectedFilters] = useState<ApplicationsFilters>(searchFilters)

    const [currentWithdrawalApplicationId, setCurrentWithdrawalApplicationId] = useState<string>()

    const { company } = useCompany()

    useEffect(() => {
        const completeRequest = {
            searchText,
            paging: { page: currentPage, size: pageSize },
            sorting,
            ...selectedFilters,
        }
        const newSearchQuery = stringifyApplicationsSearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [currentPage, navigate, pageSize, searchText, selectedFilters, sorting])

    const onToggleFilters = useCallback(() => {
        setShowFilters(currentShowFilters => !currentShowFilters)
    }, [])

    const onChangeFilters = useCallback((filters: ApplicationsFilters) => {
        setSelectedFilters(filters)
        setCurrentPage(1)
    }, [])

    const handleSearch = useCallback((text: any) => {
        setSearchText(text)
        setCurrentPage(1)
    }, [])

    const onChangePageSize = useCallback((size: Array<string>) => {
        setPageSize(Number(size.toString()))
    }, [])

    useEffect(() => {
        if (totalApplications && totalApplications > 0 && applications?.length === 0 && currentPage > 1) {
            setCurrentPage(1)
        }
    }, [applications?.length, currentPage, totalApplications])

    const filtersCount = useMemo(() => {
        return countFilters({
            ...selectedFilters,
        })
    }, [selectedFilters])

    const onClearFilters = useCallback(() => {
        if (filtersCount > 0) {
            setSelectedFilters({ appliedOn: null, cities: [], countries: [], roles: [], statuses: [], submittedBy: [] })
        }
    }, [filtersCount])

    const loadFilters = useCallback(() => {
        getApplicationsFilters().then(filters =>
            setFiltersOptions({
                cities: filters.cities,
                countries: filters.countries,
                roles: filters.roles,
                statuses: filters.statuses,
                submittedBy: filters.submittedBy,
            }),
        )
    }, [])

    useEffect(() => {
        loadFilters()
    }, [loadFilters])

    const loadApplications = useCallback(() => {
        setIsFetchingApplications(true)
        searchApplications({ ...selectedFilters, paging: { page: currentPage, size: pageSize }, searchText, sorting })
            .then(data => {
                setIsFetchingApplications(false)
                setApplications(data.applications)
                setTotalApplications(data.total)
            })
            .catch(e => {
                setIsFetchingApplications(false)
                addError()
                logError(e)
            })
    }, [addError, currentPage, logError, pageSize, searchText, selectedFilters, sorting])

    useEffect(() => {
        loadApplications()
    }, [loadApplications])

    const onWithdrawClick = useCallback((applicationId: any) => {
        setCurrentWithdrawalApplicationId(applicationId)
    }, [])

    const handleWithdrawal = useCallback(
        (applicationId: string, body: ApplicationWithdrawalParams) => {
            withdrawApplication(applicationId, body)
                .then(application => {
                    setCurrentWithdrawalApplicationId(SHOW_WITHDRAWAL_SUCCESS_MESSAGE)
                    setApplications(
                        prev =>
                            prev?.map(item =>
                                item.applicationId === applicationId ? { ...item, status: application.recentStatus } : item,
                            ) || null,
                    )
                })
                .catch(() => {
                    addError()
                })
        },
        [addError],
    )

    const handleCloseModal = useCallback(() => setCurrentWithdrawalApplicationId(''), [])

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            setCurrentPage(1)
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            setCurrentPage(1)
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    const columns: ColumnsType<SearchApplication> = useMemo(
        () => [
            {
                title: 'Vacancy',
                dataIndex: 'opportunityTitle',
                sorter: true,
                showSorterTooltip: false,
                render: (_, data) => {
                    const TitleBadge = () => <div data-testid='opportunity-title'>{data.opportunityTitle}</div>
                    const IdBadge = () => (
                        <div
                            css={css`
                                ${mqMin[2]} {
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                }
                            `}
                        >
                            <div
                                css={css`
                                    display: none;
                                    color: ${theme.colors.gray_4};
                                    font-size: 12px;
                                    margin-top: 10px;
                                    margin-bottom: -2px;

                                    ${mqMax[2]} {
                                        display: block;
                                    }
                                `}
                            >
                                Opportunity ID
                            </div>
                            <span
                                css={css`
                                    ${mqMax[2]} {
                                        display: none;
                                    }
                                `}
                            >
                                ID:
                            </span>{' '}
                            {data.generatedOpportunityId || '-'}
                        </div>
                    )

                    return (
                        <Fragment>
                            <TitleBadge />
                            <IdBadge />
                        </Fragment>
                    )
                },
                width: 300,
            },
            {
                title: 'Name',
                dataIndex: 'name',
                sorter: true,
                showSorterTooltip: false,
                render: (_, data) => (data.firstName && data.lastName ? `${data.lastName} ${data.firstName}` : '-'),
            },
            {
                title: 'Role',
                dataIndex: 'role',
                sorter: true,
                showSorterTooltip: false,
                render: (role: string) => role || '-',
            },
            {
                title: 'Location',
                dataIndex: 'location',
                sorter: true,
                showSorterTooltip: false,
                render: (_, data: SearchApplication) => (
                    <Fragment>
                        {data.country && <span>{data.country}</span>}
                        {data.city && (
                            <Fragment>
                                <br
                                    css={css`
                                        ${mqMax[2]} {
                                            display: none;
                                        }
                                    `}
                                />
                                <span
                                    css={css`
                                        ${mqMin[2]} {
                                            display: none;
                                        }
                                    `}
                                >
                                    :{' '}
                                </span>

                                <span
                                    css={css`
                                        color: ${theme.colors.gray_4};
                                        ${mqMin[2]} {
                                            font-size: 12px;
                                        }
                                    `}
                                >
                                    {data.city}
                                </span>
                            </Fragment>
                        )}
                    </Fragment>
                ),
            },
            {
                title: 'Applied On',
                dataIndex: 'appliedOn',
                sorter: true,
                showSorterTooltip: false,
                render: (appliedOn: number) => (appliedOn ? convertTimestampToDateString(appliedOn) : '-'),
                width: 150,
            },
            {
                title: 'Status',
                dataIndex: 'status',
                sorter: true,
                showSorterTooltip: false,
                render: (status: ApplicationStatus, data: SearchApplication) => (
                    <ApplicationStatusCell
                        status={status}
                        showMsaRequired={
                            !data.smeCloud && !!company && !company.msaSigned && status.status === ApplicationStatusEnum.NEW_APPLICATION
                        }
                    />
                ),
            },
            {
                title: '',
                dataIndex: 'actions',
                render: (_, data) => (
                    <ApplicationActions
                        applicationId={data.applicationId}
                        onWithdrawClick={onWithdrawClick}
                        disabled={data.status.status === ApplicationStatusEnum.WITHDRAWN}
                    />
                ),
            },
        ],

        [company, onWithdrawClick, theme.colors.gray_4],
    )

    const rowProps = useCallback(
        (record: any) => ({
            'data-testid': 'application-table-row',
            onClick: () =>
                navigate(
                    `${getAppPath('OPEN_VACANCIES')}/applications/${record.applicationId}?prevPath=${encodeURIComponent(
                        `${pathname}${encodeURIComponent(search)}${hash}`,
                    )}`,
                ),
        }),
        [navigate, pathname, search, hash],
    )

    return (
        <DashboardLayout applicationMenuItems={VMCmenuItems} applicationName='Open Vacancies' backgroundColor={theme.colors.gray_1}>
            {!searchText && filtersCount === 0 && applications && totalApplications === 0 ? null : (
                <Fragment>
                    <ColorBackgroundWrapper
                        css={css`
                            ${mqMin[1]} {
                                border-bottom: 1px solid ${theme.colors.gray_2};
                            }
                        `}
                    >
                        <div
                            css={css`
                                max-width: 1440px;
                                margin: 0 auto;
                            `}
                        >
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    ${mqMax[1]} {
                                        flex: none;
                                    }
                                    margin-bottom: 16px;
                                `}
                            >
                                <h4
                                    css={css`
                                        margin-bottom: 10px;
                                    `}
                                >
                                    My Applications
                                </h4>
                            </div>
                            <SearchInput
                                initialText={searchText}
                                onSubmit={handleSearch}
                                filtersOpened={showFilters}
                                onToggleFilters={onToggleFilters}
                                placeholder='Specialist Name, Opportunity Name, Opportunity ID, etc.'
                                styles={css`
                                    margin-bottom: 24px;
                                `}
                                dataTestId='applications-search'
                            />
                            {showFilters && filtersOptions && (
                                <FiltersBar<ApplicationsFilters> selectedFilters={selectedFilters} onChangeFilters={onChangeFilters}>
                                    {({ onSelectFilterChange, onLocationChange, onDatePickerChange }: FiltersFunctions) => (
                                        <ApplicationsFiltersBar
                                            opened={showFilters}
                                            filtersOptions={filtersOptions}
                                            selectedFilters={selectedFilters}
                                            onClearAll={onClearFilters}
                                            filtersCount={filtersCount}
                                            onSelectFilterChange={onSelectFilterChange}
                                            onLocationChange={onLocationChange}
                                            onDatePickerChange={onDatePickerChange}
                                        />
                                    )}
                                </FiltersBar>
                            )}
                            <Divider
                                style={css`
                                    margin-bottom: 5px;
                                `}
                            />
                            <div
                                css={css`
                                    display: flex;
                                    margin-top: 15px;
                                `}
                            >
                                <span
                                    css={css`
                                        display: block;
                                    `}
                                    data-testid='applications-total'
                                >
                                    {totalApplications} Result{totalApplications !== 1 && 's'} Found
                                </span>
                            </div>
                        </div>
                    </ColorBackgroundWrapper>
                    {applications && totalApplications !== null && totalApplications > 0 && (
                        <Fragment>
                            <div
                                css={css`
                                    background-color: ${theme.colors.white};

                                    ${mqMax[2]} {
                                        border-bottom: 1px solid ${theme.colors.gray_2};
                                        padding-bottom: 4px;
                                    }
                                `}
                            >
                                <ResponsiveGrid
                                    columns={columns}
                                    dataSource={applications}
                                    dataTestId='applications-table'
                                    mobileBreakpoint={2}
                                    onChange={onChangeTable}
                                    onRow={rowProps}
                                    pagination={false}
                                    rowKey='applicationId'
                                    wrapperStyle={css`
                                        .ant-table-row:hover {
                                            img {
                                                display: inline;
                                            }
                                        }
                                    `}
                                />
                            </div>
                            <ColorBackgroundWrapper
                                css={css`
                                    margin: 18px 0 100px;
                                `}
                            >
                                <div
                                    css={css`
                                        max-width: 1440px;
                                        margin: 0 auto;
                                    `}
                                >
                                    <Pagination
                                        pageSize={pageSize}
                                        total={totalApplications}
                                        onChange={setCurrentPage}
                                        current={currentPage}
                                        defaultPageSize={DEFAULT_ITEMS_PER_PAGE}
                                        onChangePageSize={onChangePageSize}
                                        css={css`
                                            ${mqMax[0]} {
                                                display: flex;
                                                justify-content: center;
                                            }
                                        `}
                                    />
                                </div>
                            </ColorBackgroundWrapper>
                        </Fragment>
                    )}
                </Fragment>
            )}
            {currentWithdrawalApplicationId && (
                <WithdrawalReasonModal
                    closeRejectionModal={handleCloseModal}
                    currentRejectionApplicationId={currentWithdrawalApplicationId}
                    onSubmit={handleWithdrawal}
                />
            )}
            {totalApplications === 0 && !isFetchingApplications && (
                <DataNotFound
                    styles={css`
                        margin-top: ${searchText || filtersCount > 0 ? '50px' : '150px'};

                        h5 {
                            max-width: unset;
                        }
                        p {
                            padding: 0 15px;
                        }
                    `}
                    iconName={searchText || filtersCount > 0 ? 'no-data' : 'add-empty'}
                    iconSize={60}
                    title={searchText || filtersCount > 0 ? 'No Applications found' : 'No Applications to see here yet.'}
                    description={
                        searchText || filtersCount > 0 ? (
                            'Try changing your filters or query'
                        ) : (
                            <Fragment>
                                When you apply to an Opportunity, your applications will appear in this section.
                                <br />
                                You'll be able to view your history and change the status of your applications here.
                            </Fragment>
                        )
                    }
                >
                    {!searchText && filtersCount === 0 && (
                        <Link to={`${getAppPath('OPEN_VACANCIES')}`}>
                            <Button
                                style={css`
                                    display: block;
                                    margin: 0 auto 100px;
                                `}
                            >
                                Find Open Vacancies
                            </Button>
                        </Link>
                    )}
                </DataNotFound>
            )}
            {isFetchingApplications && <OverlayContentLoader />}
        </DashboardLayout>
    )
}

export { OpenVacanciesApplicationsPage }
