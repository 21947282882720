import styled from '@emotion/styled'

const GameWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 366px;
`

export { GameWrapper }
