/** @jsxImportSource @emotion/react */

import { Fragment, FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { applyActionCode } from '../../api/firebase'

type VerifyEmailProps = {
    actionCode: string
}

const VerifyEmail: FunctionComponent<React.PropsWithChildren<VerifyEmailProps>> = ({ actionCode }) => {
    const navigate = useNavigate()

    useEffect(() => {
        applyActionCode(actionCode)
            .then(() => navigate(`/?notification=email-verified&role=manager`))
            .catch(() => navigate(`/?notification=email-verification-failed&role=manager`))
    }, [actionCode, navigate])

    return <Fragment></Fragment>
}

export { VerifyEmail }
