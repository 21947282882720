import { Paging } from '../common/pagingProps'
import { SortEntry } from '../common/sorting'
import { OpportunityStatus } from '../opportunities'
import { Nullable } from '../../types'
import {
    Application as ApplicationManagerView,
    ApplicationSkill,
    ApplicationStatusEnum,
    ApplicationStatus as ApplicationStatusManagerView,
    OpportunityLocationResponse,
    OpportunityOfferTypeEnum,
} from '../opportunities-manager/contracts'

export interface ApplicationStatus extends ApplicationStatusManagerView {
    draft: boolean
}

export interface Application extends Omit<ApplicationManagerView, 'msa' | 'partnerName' | 'recentStatus' | 'statusHistory'> {
    appliedOn: number
    opportunityId: string
    recentStatus: ApplicationStatus
    specialistId?: Nullable<string>
    statusHistory: Array<ApplicationStatus>
}

export interface Specialist {
    firstName: string
    specialistId: string
    lastName: string
    role: string
}

export type OpportunityApplicationsResponse = {
    applications: Array<Application>
}

export type ApplicationsFiltersResponse = {
    cities: Array<string>
    countries: Array<string>
    roles: Array<string>
    statuses: Array<ApplicationStatus>
    submittedBy: Array<string>
}

export interface ApplicationsParams extends Paging {
    appliedOn: Nullable<number>
    cities: Array<string>
    countries: Array<string>
    roles: Array<string>
    searchText: string
    sorting: Array<SortEntry>
    statuses: Array<ApplicationStatusEnum>
    submittedBy: Array<string>
}

export type ApplicationWithdrawalParams = {
    rejectionReason: string
    rejectionReasonComment: Nullable<string>
}

export type OpenOpportunitiesFiltersResponse = {
    countries: Array<string>
    currencies: Array<string>
    industries: Array<string>
    locationTypes: Array<string>
    maxMinExperienceInYears: Nullable<number>
    minMinExperienceInYears: Nullable<number>
    opportunityTypes: Array<OpportunityOfferTypeEnum>
    regions: Array<string>
    skills: Array<string>
    timeZones: Array<string>
    visibilityValues: Array<string>
}

export interface OpenOpportunitiesParams {
    countries: Array<string>
    currency: string
    industries: Array<string>
    locationTypes: Array<string>
    maxMinExperienceInYears: Nullable<number>
    minMinExperienceInYears: Nullable<number>
    minRate: Nullable<number>
    paging: {
        page: Nullable<number>
        size: Nullable<number>
    }
    projectEndDate: Nullable<number>
    projectStartDate: Nullable<number>
    regions: Array<string>
    responseType: Nullable<ResponseType>
    searchText: string
    skills: Array<string>
    sorting: Array<SortEntry>
    timeZones: Array<string>
    type: Nullable<OpportunityOfferTypeEnum>
    visibilityValues: Array<string>
}

export type RejectionReasons = {
    rejectionReasons: {
        [ApplicationStatusEnum.REJECTED_BY_TA]: Array<Omit<ApplicationStatus, 'draft' | 'status'>>
        [ApplicationStatusEnum.REJECTED_BY_CLIENT]: Array<Omit<ApplicationStatus, 'draft' | 'status'>>
        [ApplicationStatusEnum.OFFER_REJECTED]: Array<Omit<ApplicationStatus, 'draft' | 'status'>>
        [ApplicationStatusEnum.WITHDRAWN]: Array<Omit<ApplicationStatus, 'draft' | 'status'>>
    }
}

export enum ResponseType {
    ALL_RESULTS = 'ALL_RESULTS',
    MY_APPLICANTS = 'MY_APPLICANTS',
    WITHOUT_MY_APPLICANTS = 'WITHOUT_MY_APPLICANTS',
}

export enum VisibilityType {
    TALENT_POOL = 'TALENT_POOL',
    PUBLIC = 'PUBLIC',
}

export const responseTypeOptions: any = {
    [ResponseType.ALL_RESULTS]: 'Show All',
    [ResponseType.MY_APPLICANTS]: 'With my applicants',
    [ResponseType.WITHOUT_MY_APPLICANTS]: 'Without my applicants',
}

export const visibilityTypeOptions: any = {
    [VisibilityType.TALENT_POOL]: 'SME Cloud',
    [VisibilityType.PUBLIC]: 'Public Cloud',
}

export type Opportunity = {
    addedOn: number
    dealSize: string
    earlyAccess: boolean
    industry: string
    location: OpportunityLocationResponse
    myApplicationSize: number
    offerType: OpportunityOfferTypeEnum
    opportunityId: string
    opportunityTitle: string
    projectEndDate: number
    projectStartDate: number
    rate: string
    requiredSkills: Array<string>
    status: OpportunityStatus
    updatedOn: number
    vacancies: number
    years: number
    smeCloud: boolean
    hiddenInformation: boolean
}

export type SearchApplication = {
    applicationId: string
    appliedOn: number
    city: string
    country: string
    firstName: string
    generatedOpportunityId: string
    lastName: string
    opportunityId: string
    opportunityTitle: string
    role: string
    status: ApplicationStatus
    smeCloud: boolean
}

export type SearchApplicationsResponse = {
    applications: Array<SearchApplication>
    total: number
}

export type SearchOpenOpportunitiesResponse = {
    opportunities: Array<Opportunity>
    total: number
    totalVacancies: number
}

export interface UpdateApplicationRequestBody {
    firstName: string
    lastName: string
    role: string
    preferredRate: '' | number
    negotiableRate: '' | number
    availableFrom: '' | number
    availableTo: '' | number
    country: string
    city: string
    comment: string
    useCvTemplate?: boolean
}

export interface UpdateApplicationSkillsRequestBody {
    skills: Array<ApplicationSkill>
}

export interface OpportunityShort {
    opportunityId: string
    opportunityInternalName: string
    clientName: string
}

export interface InvitedCandidate {
    candidateId: string
    opportunityId: string
    specialistId: string
}

export type OpportunityError = '' | 'not found' | 'closed'
