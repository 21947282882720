import { useEffect } from 'react'
import { useLocation } from 'react-use'
import { uiViewChanges } from '../api/api'
import { useLogger } from '../utils/useLogger'
import { getUserActiveContextStorage } from '../utils/userContext'

const useUiChanges = () => {
    const state = useLocation()
    const log = useLogger()

    useEffect(() => {
        if (['load', 'pushstate'].includes(state.trigger) && state.pathname) {
            const { storedUserActiveContextId } = getUserActiveContextStorage()
            uiViewChanges({ toRoute: state.pathname, contextId: storedUserActiveContextId }).catch(log)
        }
    }, [state.pathname, state.trigger, log])
}

export { useUiChanges }
