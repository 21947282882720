/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useContext, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { resetPassword } from '../../api/api'
import { AwardsBox } from '../../components/AwardsBox'
import { Button } from '../../components/Button'
import { EverestView } from '../../components/EverestView'
import { ControlledInput } from '../../components/forms/ControlledInput'
import { FieldWrapper } from '../../components/layout/FormHelpers'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'
import { TwoCols } from '../../components/layout/TwoCols'
import {
    extractStrengthIndicators,
    NO_CAPITAL,
    NO_DIGIT,
    PasswordStrength,
    TOO_LONG,
    TOO_SHORT,
} from '../../components/password/PasswordStrength'
import { ReduxContext } from '../../redux/Store'
import { hasErrors } from '../../utils/errors'
import { PASSWORD_MUST_MATCH } from '../../validation/validation-messages'
import { yup } from '../../validation/yup'

const schema = yup.object({
    password: yup
        .string()
        .required()
        .min(8, TOO_SHORT)
        .max(24, TOO_LONG)
        .test('oneCapitalTest', NO_CAPITAL, value => /[A-Z]/.test(value as any))
        .test('oneDigitTest', NO_DIGIT, value => /[\d]/.test(value as any)),
    passwordConfirm: yup
        .string()
        .oneOf([yup.ref('password')], PASSWORD_MUST_MATCH)
        .required(PASSWORD_MUST_MATCH),
})

type RecoverPasswordNewPasswordProps = {
    actionCode: string
}

const RecoverPasswordNewPasswordBase: FunctionComponent<React.PropsWithChildren<RecoverPasswordNewPasswordProps>> = ({ actionCode }) => {
    const navigate = useNavigate()
    const {
        control,
        handleSubmit,
        formState: { errors, touchedFields, isDirty, isSubmitting },
    } = useForm({
        resolver: yupResolver(schema),
        criteriaMode: 'all',
        mode: 'onChange',
    })

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const onSubmit = useCallback(
        (data: any) => {
            layoutToggleLoader(true)
            const { password } = data

            resetPassword(password, actionCode)
                .then(() => navigate(`/?notification=password-reset-success&role=manager`))
                .catch(() => navigate(`/?notification=password-reset-failed&role=manager`))
                .finally(() => layoutToggleLoader(false))
        },
        [actionCode, navigate, layoutToggleLoader],
    )

    const indicators = useMemo(
        () => extractStrengthIndicators(errors.password, isDirty || touchedFields?.password),
        [errors.password, isDirty, touchedFields],
    )

    return (
        <ResponsiveWrapper withLogo>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h3
                    css={css`
                        margin-top: 70px;
                        margin-bottom: 16px;
                    `}
                >
                    Create New Password
                </h3>
                <FieldWrapper>
                    <ControlledInput
                        name='password'
                        label='Password:'
                        placeholder='Password'
                        type='password'
                        hasPassword={true}
                        control={control}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <ControlledInput
                        name='passwordConfirm'
                        label='Repeat password:'
                        placeholder='Repeat password'
                        type='password'
                        hasPassword={true}
                        control={control}
                    />
                </FieldWrapper>
                <PasswordStrength
                    indicators={indicators}
                    style={css`
                        margin-top: 16px;
                    `}
                />
                <Button
                    variant='primary'
                    type='submit'
                    disabled={!isDirty || isSubmitting || hasErrors(errors)}
                    css={css`
                        width: 100%;
                    `}
                >
                    Confirm New Password
                </Button>
            </form>
        </ResponsiveWrapper>
    )
}

const RecoverPasswordNewPassword: FunctionComponent<React.PropsWithChildren<RecoverPasswordNewPasswordProps>> = ({ actionCode }) => {
    const {
        selectors: { featureFlags },
    } = useContext(ReduxContext)

    return (
        <TwoCols
            firstCol={<RecoverPasswordNewPasswordBase actionCode={actionCode} />}
            secondCol={featureFlags.everestWelcome ? <EverestView /> : <AwardsBox />}
        />
    )
}

export { RecoverPasswordNewPassword }
