/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, useCallback, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { BlueActionBox } from '../../../BlueActionBox'
import { AutocompleteSelectValuesTransformerEnum, ControlledAutocompleteSelect } from '../../../forms/ControlledAutocompleteSelect'
import { ControlledInput } from '../../../forms/ControlledInput'
import { ControlledTextarea } from '../../../forms/ControlledTextarea'
import { FieldWrapper } from '../../../layout/FormHelpers'
import { CompanyMembersResponse } from '../../../../contracts/companyMembers'
import { Nullable } from '../../../../types'
import { Box } from '../../Box'
import { UpdateOpportunityFormData } from '../../../../contracts/opportunities-manager/contracts'

import { ControlledDatePickerInput } from 'src/components/forms/ControlledDatePickerInput'

type InternalDetailsProps = {
    control: Control<UpdateOpportunityFormData>
    companyMembers: Nullable<CompanyMembersResponse>
    isSmeCloudApp: boolean
    isVMCCloudApp: boolean
}

const InternalDetails: FunctionComponent<React.PropsWithChildren<InternalDetailsProps>> = ({
    control,
    companyMembers,
    isSmeCloudApp,
    isVMCCloudApp,
}) => {
    const theme = useTheme()
    const companyMembersIds = useMemo(() => companyMembers?.members.map(member => member.userId) || [], [companyMembers])
    const opportunityOwnerLabelTransformer = useCallback(
        (opt: any) => companyMembers?.members.find(member => member.userId === opt)?.email || '',
        [companyMembers?.members],
    )

    return (
        <Box>
            <h6>Internal Details</h6>
            <BlueActionBox
                text="Internal Details are only visible to your team and TA. If you don't want to reveal some information to TA, use your own codes"
                styles={css`
                    background-color: ${theme.colors.beige_2};
                    margin-bottom: 16px;
                    border-radius: 4px;
                    padding: 8px;
                `}
            />
            <FieldWrapper>
                <ControlledInput
                    control={control}
                    name='internalDetails.internalName'
                    label='Internal Name'
                    placeholder='e.g. Web Application'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput control={control} name='internalDetails.clientName' label='Client Name' placeholder='Client Name' />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    options={companyMembersIds}
                    selectedLabelTransformer={opportunityOwnerLabelTransformer}
                    control={control}
                    name='internalDetails.opportunityOwner'
                    label={isVMCCloudApp ? 'Vacancy Owner' : 'Opportunity Owner'}
                    placeholder={isVMCCloudApp ? 'Vacancy Owner' : 'Opportunity Owner'}
                    canFilter={false}
                    valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledTextarea
                    control={control}
                    name='internalDetails.internalNotes'
                    label='Internal Notes (optional)'
                    placeholder='Internal Notes'
                />
            </FieldWrapper>
            {!isSmeCloudApp && !isVMCCloudApp && (
                <FieldWrapper>
                    <ControlledDatePickerInput
                        control={control}
                        name='internalDetails.earlyAccessExpiration'
                        label='Early Access Expiration (optional)'
                        placeholder='DD.MM.YYYY'
                        canClear
                        labelTooltip='If this date is set, this opportunity will be published in the Early Access mode. This mode means that only premium users will be able to apply to it, until this date expires.
                    If this date is left empty, all users will be able to apply to this opportunity as soon as it is published.
                    It is not recommended to edit/remove this date once the opportunity has been published.'
                    />
                </FieldWrapper>
            )}
        </Box>
    )
}

export { InternalDetails }
