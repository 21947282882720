/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useCallback, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useToasts } from 'react-toast-notifications'
import { useEffectOnce } from 'react-use'
import { Button } from '../../components/Button'
import { Divider } from '../../components/Divider'
import { ControlledCheckbox } from '../../components/forms/ControlledCheckbox'
import { SettingsWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ReduxContext } from '../../redux/Store'
import { useLogger } from '../../utils/useLogger'
import { getSubscriptionsV2, saveSubscriptionsV2 } from '../dashboard/api'
import { EmailSubscription, Subscription } from '../dashboard/contracts'

const comparator = (a: Subscription, b: Subscription) => {
    if (a.type === 'OPPORTUNITIES_MATCHING' && b.type !== 'OPPORTUNITIES_MATCHING') {
        return -1
    } else if (a.type !== 'OPPORTUNITIES_MATCHING' && b.type === 'OPPORTUNITIES_MATCHING') {
        return 1
    } else {
        return 0
    }
}

const EmailNotifications = () => {
    const { removeAllToasts } = useToasts()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const { addError, addSuccess } = useNotifications()
    const log = useLogger('error')
    const [data, setData] = useState<Array<EmailSubscription>>([])
    const {
        control,
        getValues,
        reset,
        formState: { isDirty },
    } = useForm({
        mode: 'onChange',
        defaultValues: {},
    })
    const [isDataFetched, setIsDataFetched] = useState(false)

    const setDefaultValues = useCallback(
        (subscriptions: Array<EmailSubscription>) => {
            const formValues: { [key: string]: boolean } = {}
            subscriptions.forEach((subscription: EmailSubscription) => {
                subscription.emailSubscriptions.forEach((emailSubscription: Subscription) => {
                    formValues[emailSubscription.id] = emailSubscription.notify === 'SUBSCRIBE'
                })
            })

            reset(formValues)
        },
        [reset],
    )

    useEffectOnce(() => {
        layoutToggleLoader(true)
        getSubscriptionsV2()
            .then(response => {
                setData(response.subscriptions)
                setDefaultValues(response.subscriptions)
                setIsDataFetched(true)
            })
            .catch(log)
            .finally(() => layoutToggleLoader(false))
    })

    const handleSave = useCallback(() => {
        const formValues: { [key: string]: boolean } = getValues()
        const dataToSave: Array<{ id: string; notify: 'SUBSCRIBE' | 'UNSUBSCRIBE' }> = Object.keys(formValues).map(subscriptionId => ({
            id: subscriptionId,
            notify: formValues[subscriptionId] ? 'SUBSCRIBE' : 'UNSUBSCRIBE',
        }))

        removeAllToasts()
        layoutToggleLoader(true)
        saveSubscriptionsV2(dataToSave)
            .then(response => {
                setData(response.subscriptions)
                setDefaultValues(response.subscriptions)
                addSuccess('You have successfully unsubscribed from e-mail notifications')
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [addError, addSuccess, layoutToggleLoader, log, removeAllToasts, getValues, setDefaultValues])

    return (
        <SettingsWrapper>
            <h4>E-mail Notifications</h4>
            <Divider />
            <div
                css={css`
                    margin-bottom: 40px;
                `}
            >
                Please mark the checkboxes below if you would like to receive notifications about activity on the platform related to your
                company. These emails will include the following categories: specialist bookings updates and reminders, your opportunity
                application updates, matching opportunity emails.
            </div>
            {isDataFetched && (
                <Fragment>
                    {data?.map(company => (
                        <div
                            key={company.companyName}
                            css={css`
                                margin-bottom: 34px;
                            `}
                        >
                            <div
                                css={css`
                                    font-size: 20px;
                                    margin-bottom: 14px;
                                    font-weight: 500;
                                `}
                            >
                                {company.companyName}
                            </div>
                            {company.emailSubscriptions?.sort(comparator).map(subscription => (
                                <>
                                    {subscription.type === 'OPPORTUNITIES_MATCHING' ? (
                                        <ControlledCheckbox
                                            control={control}
                                            checkboxLabel='I would like to receive email notifications about opportunities'
                                            name={`${subscription.id}`}
                                        />
                                    ) : (
                                        <ControlledCheckbox
                                            control={control}
                                            checkboxLabel='I would like to receive additional email notifications'
                                            name={`${subscription.id}`}
                                        />
                                    )}
                                </>
                            ))}
                        </div>
                    ))}
                </Fragment>
            )}

            <Divider />
            <div>
                <Button
                    variant='link'
                    onClick={() => setDefaultValues(data)}
                    disabled={false}
                    css={css`
                        margin-right: 30px;
                    `}
                >
                    Cancel
                </Button>
                <Button variant='primary' disabled={!isDirty} onClick={handleSave}>
                    Save Preferences
                </Button>
            </div>
        </SettingsWrapper>
    )
}

export { EmailNotifications }
