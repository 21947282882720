/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { FlexBox } from 'src/components/layout/FlexBoxHelpers'
import { mqMax } from 'src/GlobalStyle'
import { COLOR_PALETTE } from 'src/theme/colors'
import { Nullable } from 'src/types'
import { useIsMobile } from 'src/utils/hooks'
import { ShareMySpecialistsStatistics } from '../contracts'

const StatisticHeader = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: ${COLOR_PALETTE.gray_3};
`

const StatisticParagraph = styled.p`
    margin: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
`

const Statistics: FunctionComponent<React.PropsWithChildren<ShareMySpecialistsHeaderProps>> = ({ statistics }) => {
    return (
        <div
            css={css`
                display: flex;
                align-items: center;

                ${mqMax[1]} {
                    margin-top: 24px;
                }
            `}
        >
            <div
                css={css`
                    height: 28px;
                    border-left: 1px solid ${COLOR_PALETTE.gray_2};
                    margin: 0 18px;

                    ${mqMax[1]} {
                        display: none;
                    }
                `}
            ></div>

            {statistics && (
                <div
                    css={css`
                        margin-right: 24px;

                        ${mqMax[2]} {
                            max-width: 93px;
                        }

                        ${mqMax[1]} {
                            margin-right: 81px;
                        }
                    `}
                >
                    <StatisticHeader>Currently Shared on Talent Marketplace:</StatisticHeader>
                    <StatisticParagraph>{statistics.allSpecialistsShared}</StatisticParagraph>
                </div>
            )}
            {statistics && (
                <div
                    css={css`
                        ${mqMax[2]} {
                            max-width: 106px;
                        }
                    `}
                >
                    <StatisticHeader>Total Added to My Specialists:</StatisticHeader>
                    <StatisticParagraph>{statistics.allSpecialists}</StatisticParagraph>
                </div>
            )}
        </div>
    )
}

type ShareMySpecialistsHeaderProps = {
    statistics: Nullable<ShareMySpecialistsStatistics>
}

const ShareMySpecialistsHeader: FunctionComponent<React.PropsWithChildren<ShareMySpecialistsHeaderProps>> = ({ statistics }) => {
    const isMobile = useIsMobile(1)

    return (
        <FlexBox
            justifyContent='space-between'
            alignItems='flex-start'
            css={css`
                margin-bottom: 16px;
                flex-direction: column;

                ${mqMax[1]} {
                    justify-content: center;
                }
            `}
        >
            <div
                css={css`
                    display: flex;
                    justify-content: space-between;
                    flex: 1;
                    align-items: center;
                    width: 100%;
                `}
            >
                <h4
                    css={css`
                        margin: 0;
                    `}
                >
                    Share specialists
                </h4>
                <div
                    css={css`
                        display: flex;
                        flex: 1;
                        justify-content: space-between;
                        align-items: center;

                        ${mqMax[1]} {
                            flex: none;
                        }
                    `}
                >
                    <div>{statistics && !isMobile && <Statistics statistics={statistics} />}</div>
                </div>
            </div>

            {statistics && isMobile && <Statistics statistics={statistics} />}
        </FlexBox>
    )
}

export { ShareMySpecialistsHeader }
