import { useContext, useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { checkAccess } from '../api/api'
import { useNotifications } from '../components/notification/NotificationProvider'
import { Feature } from '../redux/featureFlags'
import { ReduxContext } from '../redux/Store'
import { useLogger } from '../utils/useLogger'

const useMenuItemsSpecialist = () => {
    const {
        state: { features },
        selectors: {
            featureFlags: { hasAccessToContinuousFeedback },
        },
        actions: { setFeatures },
    } = useContext(ReduxContext)

    const log = useLogger('error')
    const { addError } = useNotifications()

    useEffectOnce(() => {
        checkAccess()
            .then(response => {
                const hasAccessToContinuousFeedbackFlag: Feature = {
                    name: 'hasAccessToContinuousFeedback_dev',
                    enabled: !!response?.hasAccess,
                    stale: false,
                    strategies: [{ constraints: [], name: 'default', parameters: {} }],
                    type: 'release',
                    variants: [],
                }

                setFeatures([
                    ...(features as Array<Feature>),
                    hasAccessToContinuousFeedbackFlag,
                    { ...hasAccessToContinuousFeedbackFlag, name: 'hasAccessToContinuousFeedback_stage' },
                    { ...hasAccessToContinuousFeedbackFlag, name: 'hasAccessToContinuousFeedback_prod' },
                ])
            })
            .catch(err => {
                log(err)
                addError()
            })
    })

    const menuItems = useMemo(() => {
        const output = []

        if (hasAccessToContinuousFeedback) {
            output.push({
                path: '/continuous-feedback',
                title: 'Continuous Feedback',
                dataTestId: 'continuous-feedback-menu-item',
            })
        }

        return output
    }, [hasAccessToContinuousFeedback])

    return { menuItems, applicationName: 'My Dashboard' }
}

export { useMenuItemsSpecialist }
