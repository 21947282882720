/** @jsxImportSource @emotion/react */
import { useMemo, FC } from 'react'
import { css, useTheme, Theme } from '@emotion/react'
import { DraggableChildrenFn } from '@hello-pangea/dnd'
import { Tooltip } from 'antd'

import { convertTimestampToDateString } from '../../utils/dates'
import { FullPageWrapper } from '../layout/ResponsiveWrapper'
import { KanbanBoard } from '../kanban-board/KanbanBoard'
import { Application, ApplicationStatusEnum, ApplicationStatusMapping } from '../../contracts/opportunities-manager/contracts'
import { CardsType, KanbanCard } from '../kanban-board/contract'
import { Divider } from '../Divider'
import { getRate } from '../../utils/application'
import { stringifyBool } from './utils/stringifyBool'

const kanbanColumns = Object.values(ApplicationStatusEnum).map(status => ({
    id: status,
    title: ApplicationStatusMapping[status] || status,
}))

interface OwnProps {
    applications: Application[]
    onDragEnd: (card: KanbanCard<Application>, cards: CardsType<Application>) => void
    kanbanIncrementFlag: number
}

const kanbanCard: (theme: Theme) => (card: KanbanCard<Application>) => DraggableChildrenFn = theme => card => provided =>
    (
        <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            css={css`
                border: 1px solid ${theme.colors.violet_2};
                border-radius: 4px;
                padding: 16px;
                margin-bottom: 8px;
                background-color: ${theme.colors.white};
            `}
            data-testid={card.applicationId}
        >
            <div
                css={css`
                    font-weight: 500;
                    font-size: 16px;
                `}
            >
                {card.firstName} {card.lastName}
            </div>
            {card.role}
            <Divider />
            <div>
                <div>{card.country}</div>
                <div>{getRate(card)}</div>
                <div data-testid='internal-rate'>
                    TA Rate {card.internalRate || '-'} {card.currency}/h
                </div>
                <div>
                    {convertTimestampToDateString(card.availableFrom)} - {convertTimestampToDateString(card.availableTo)}
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                    `}
                >
                    <div>{card.partnerName}</div>
                    <div
                        css={css`
                            color: ${theme.colors.red_4};
                        `}
                    >
                        <Tooltip title='MSA signed?'>{stringifyBool(card.msa)}</Tooltip>
                    </div>
                </div>
            </div>
        </div>
    )

const OpportunitiesKanban: FC<OwnProps> = ({ applications, onDragEnd, kanbanIncrementFlag }) => {
    const theme = useTheme()

    const kanbanCards = useMemo(() => {
        const statusesCounter: { [k: string]: number } = {}
        Object.values(ApplicationStatusEnum).forEach(status => {
            statusesCounter[status] = 0
        })

        return applications.map(app => ({
            ...app,
            id: app.applicationId,
            columnId: app.recentStatus.status,
            index: statusesCounter[app.recentStatus.status]++,
        }))
    }, [applications])
    const cardRenderer = useMemo(() => kanbanCard(theme), [theme])

    return (
        <div
            css={css`
                overflow-x: scroll;
            `}
        >
            <FullPageWrapper>
                <KanbanBoard<Application>
                    columnsList={kanbanColumns}
                    cardsList={kanbanCards}
                    onDragEnd={onDragEnd}
                    cardRenderer={cardRenderer}
                    kanbanIncrementFlag={kanbanIncrementFlag}
                />
            </FullPageWrapper>
        </div>
    )
}

export { OpportunitiesKanban }
