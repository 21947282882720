/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { FunctionComponent, MutableRefObject, useCallback } from 'react'
import editIcon from 'src/assets/icons/edit.svg'
import { Button } from 'src/components/Button'
import { IconButton } from 'src/components/Icon'
import { DropdownComponent } from 'src/components/inputs/autocomplete-select/DropdownComponent'
import { COLOR_PALETTE } from 'src/theme/colors'

type MoreActionsDropdownBaseProps = {
    rangeRef: MutableRefObject<HTMLDivElement>
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    recordId: string
    onEditInMySpecialistsClick: (recordId: string) => void
}

const MoreActionsDropdownBase: FunctionComponent<React.PropsWithChildren<MoreActionsDropdownBaseProps>> = ({
    rangeRef,
    isOpen,
    setIsOpen,
    recordId,
    onEditInMySpecialistsClick,
}) => {
    const theme = useTheme()

    const toggleOpen = useCallback(() => {
        setIsOpen(!isOpen)
    }, [setIsOpen, isOpen])

    const onEditInMySpecialists = useCallback(() => {
        onEditInMySpecialistsClick(recordId)
    }, [onEditInMySpecialistsClick, recordId])

    return (
        <div
            css={css`
                position: relative;
            `}
            ref={rangeRef}
        >
            <IconButton name='more' onClick={toggleOpen} dataTestId='share-my-specialists-specialist-more' />

            {isOpen && (
                <div
                    css={css`
                        position: absolute;
                        height: 48px;
                        width: 274px;
                        top: -51px;
                        left: -234px;
                        background-color: ${COLOR_PALETTE.white};
                        border: 1px solid ${COLOR_PALETTE.gray_2};
                        border-radius: 2px;
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            height: 48px;
                            width: 274px;
                        `}
                    >
                        <div
                            css={css`
                                border-left: 3px solid ${theme.colors.main};
                                height: 36px;
                            `}
                        ></div>
                        <Button
                            variant='link'
                            icon={editIcon}
                            style={css`
                                margin-left: 15px;
                            `}
                            onClick={onEditInMySpecialists}
                        >
                            Edit specialist profile details
                        </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

const MoreActionsDropdown = DropdownComponent(MoreActionsDropdownBase)

export { MoreActionsDropdown }
