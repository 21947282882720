import { FunctionComponent } from 'react'
import { Control } from 'react-hook-form'
import { ControlledAutocompleteSelect } from 'src/components/forms/ControlledAutocompleteSelect'
import { ControlledInput } from 'src/components/forms/ControlledInput'
import { ControlledTextEditor } from 'src/components/forms/ControlledTextEditor'
import { FieldWrapper } from 'src/components/layout/FormHelpers'
import { useSkills } from 'src/redux/dictionaryDataHooks'
import { UpdateOpportunityFormData } from '../../../../contracts/opportunities-manager/contracts'
import { Box } from '../../Box'
import { naturalNumbersRegex } from 'src/utils/regexes'

type JobDetailsProps = {
    control: Control<UpdateOpportunityFormData>
    requiredSkills: Array<string>
}

const JobDetails: FunctionComponent<React.PropsWithChildren<JobDetailsProps>> = ({ control, requiredSkills }) => {
    const techSkills = useSkills()
    return (
        <Box>
            <h6>Job Details</h6>
            <FieldWrapper>
                <ControlledTextEditor
                    control={control}
                    name='jobDetails.technicalRequirements'
                    label='Technical requirements'
                    placeholder='Tell us more about the ideal candidate for this opportunity'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput
                    control={control}
                    name='jobDetails.minYearsOfExperience'
                    label='Minimum Years of Experience'
                    placeholder='Minimum Years of Experience'
                    regex={naturalNumbersRegex}
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    label='Required Technical Skills'
                    placeholder='Choose skills'
                    options={techSkills}
                    control={control}
                    name='jobDetails.requiredTechnicalSkills'
                    dropdownWidth='100%'
                    multiple
                    virtualized
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    label='Key Skills'
                    placeholder='Choose skills'
                    options={requiredSkills}
                    control={control}
                    name='jobDetails.keyTechnicalSkills'
                    dropdownWidth='100%'
                    multiple
                    virtualized
                    labelTooltip='Key Skill will be used to send the matching notifications about this opportunity. Key Skills can only be a sub-set of the required skills, so if you are missing some key skill in this dropdown, please add it to the Required Skills field above.'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledAutocompleteSelect
                    label='Preferred Technical Skills (optional)'
                    placeholder='Choose skills'
                    options={techSkills}
                    control={control}
                    name='jobDetails.preferredTechnicalSkills'
                    dropdownWidth='100%'
                    multiple
                    virtualized
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledInput
                    control={control}
                    name='jobDetails.requiredCertifications'
                    label='Required Certifications (optional)'
                    placeholder='Add certificated'
                />
            </FieldWrapper>
            <FieldWrapper>
                <ControlledTextEditor
                    control={control}
                    name='jobDetails.mainResponsibilities'
                    label='Main Responsibilities (optional)'
                    placeholder='Add responsibilities'
                />
            </FieldWrapper>
        </Box>
    )
}

export { JobDetails }
