/** @jsxImportSource @emotion/react */

import { Dispatch, Fragment, FunctionComponent, RefObject, SetStateAction } from 'react'
import { EducationLanguagesSection } from './EducationLanguagesSection'
import { EducationQualificationsSection } from './EducationQualificationsSection'

export type SpecialistProfileTabProps = {
    specialistId: string
    onSave: () => void
    focus: boolean
    setForceFocus: Dispatch<SetStateAction<string>>
    containerRef?: RefObject<HTMLElement>
}

const EducationTab: FunctionComponent<React.PropsWithChildren<SpecialistProfileTabProps>> = ({
    specialistId,
    onSave,
    focus,
    setForceFocus,
    containerRef,
}) => {
    return (
        <Fragment>
            <EducationQualificationsSection
                specialistId={specialistId}
                onSave={onSave}
                focus={focus}
                setForceFocus={setForceFocus}
                containerRef={containerRef}
            />
            <EducationLanguagesSection specialistId={specialistId} onSave={onSave} />
        </Fragment>
    )
}

export { EducationTab }
