/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Logo } from '../../../components/Logo'
import { Profile } from '../../../contracts/profile/profile'
import { Nullable } from '../../../types'
import { SpecialistProfile } from '../../profile/shared/SpecialistProfile'
import { getAnonymousSpecialistProfile } from '../api'

const MySpecialistsAnonymousProfile = () => {
    const [profile, setProfile] = useState<Nullable<Profile>>(null)
    const navigate = useNavigate()
    const { linkId } = useParams<{ linkId: string }>()

    useEffect(() => {
        if (linkId) {
            getAnonymousSpecialistProfile(linkId)
                .then((specialistProfile: any) => {
                    setProfile(specialistProfile)
                })
                .catch((error: any) => {
                    if (error?.status === 404) {
                        navigate('/profile-not-found-public')
                    }
                })
        }
    }, [linkId, navigate])

    return (
        <Fragment>
            {profile && <SpecialistProfile specialistProfile={profile} isPublic={true} isMySpecialists={true} />}
            <div
                css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 75px 0 100px;
                `}
            >
                <div
                    css={css`
                        padding-right: 12px;
                    `}
                >
                    Powered by
                </div>
                <Logo forceTalentAlpha type='logoFull' />
            </div>
        </Fragment>
    )
}

export { MySpecialistsAnonymousProfile }
