/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../GlobalStyle'

type SpinnerProps = {
    circleWidth?: number
    sizeInner?: number
    sizeOuter?: number
    dataTestId?: string
}

const Spinner: FunctionComponent<React.PropsWithChildren<SpinnerProps>> = ({
    circleWidth = 15,
    sizeInner = 100,
    sizeOuter = 120,
    dataTestId = 'app-loader',
}) => {
    const theme = useTheme()

    const spinning = keyframes`
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }`

    const circleStyles = css`
        display: block;
        position: absolute;
        box-sizing: border-box;
        border-style: solid;
        border-radius: 50%;
        animation: ${spinning} 1.6s linear infinite;
        border-width: ${circleWidth}px;
    `

    return (
        <Fragment>
            <div
                css={css`
                    ${circleStyles};
                    border-color: ${theme.colors.main};
                    border-top-color: transparent;
                    border-left-color: transparent;
                    animation-direction: reverse;
                    width: calc(${sizeInner}px - ${circleWidth}px);
                    height: calc(${sizeInner}px - ${circleWidth}px);
                `}
                data-testid={dataTestId}
            />
            <div
                css={css`
                    border-color: ${COLOR_PALETTE.gray_6};
                    border-top-color: transparent;
                    width: calc(${sizeOuter}px - ${circleWidth}px);
                    height: calc(${sizeOuter}px - ${circleWidth}px);
                    ${circleStyles};
                `}
            />
        </Fragment>
    )
}

type ContentLoaderProps = {
    height?: string
    autoHeight?: boolean
    dataTestId?: string
}

const ContentLoader: FunctionComponent<React.PropsWithChildren<ContentLoaderProps>> = ({
    autoHeight = false,
    height = '100px',
    dataTestId,
}) => (
    <div
        css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            min-height: ${autoHeight ? '100%' : height};
        `}
    >
        <Spinner circleWidth={7.5} sizeInner={50} sizeOuter={60} dataTestId={dataTestId} />
    </div>
)

type LoaderProps = {
    disableOpacity?: boolean
    dataTestId?: string
    className?: string
}

const Overlay = styled.div<LoaderProps>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${({ theme }) => theme.colors.white};
    opacity: ${({ disableOpacity }) => (disableOpacity ? 1 : 0.5)};
`

const OverlayContentLoader: FunctionComponent<React.PropsWithChildren<LoaderProps>> = ({
    disableOpacity = false,
    dataTestId,
    className,
}) => {
    return (
        <Overlay disableOpacity={disableOpacity} className={className}>
            <ContentLoader autoHeight dataTestId={dataTestId} />
        </Overlay>
    )
}

const Loader: FunctionComponent<React.PropsWithChildren<LoaderProps>> = ({ disableOpacity = false, dataTestId }) => {
    return (
        <div
            css={css`
                display: flex;
                align-items: center;
                justify-content: center;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 100;
            `}
        >
            <Overlay disableOpacity={disableOpacity} />
            <Spinner dataTestId={dataTestId} />
        </div>
    )
}

export { Loader, ContentLoader, OverlayContentLoader, Spinner }
