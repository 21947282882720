/** @jsxImportSource @emotion/react */

import { FunctionComponent, useCallback, useEffect, useMemo, useRef } from 'react'
import { useController } from 'react-hook-form'
import { Nullable, NullableArray } from '../../types'
import { AutocompleteSelect, AutoCompleteSelectBaseProps } from '../inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../inputs/autocomplete-select/Option'
import { ControlledFieldProps } from './types'

export enum AutocompleteSelectValuesTransformerEnum {
    ARRAY_TO_STRING = 'ARRAY_TO_STRING',
}

const arrayToString = (array: Array<OptionType>) => array.toString()

export type ControlledAutocompleteSelectProps = Omit<
    AutoCompleteSelectBaseProps,
    'onBlur' | 'currentValues' | 'defaultValue' | 'onSelectedValuesChange' | 'currentValues' | 'innerRef' | 'errorMessage'
> &
    ControlledFieldProps<Nullable<Array<OptionType>>> & {
        valuesTransformer?: AutocompleteSelectValuesTransformerEnum
        beforeChange?: (values: NullableArray<string>) => void
        externalError?: Nullable<{ message: string; tooltipMessage: string }>
        beforeBlur?: () => void
        focus?: boolean
        clearFocus?: () => void
        shouldUnregister?: boolean
    }

const getValuesTransformer = (name: AutocompleteSelectValuesTransformerEnum) => {
    switch (name) {
        case AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING: {
            return arrayToString
        }
    }
}

const ControlledAutocompleteSelect: FunctionComponent<React.PropsWithChildren<ControlledAutocompleteSelectProps>> = ({
    name,
    control,
    defaultValue,
    virtualized,
    isActive,
    dataTestId,
    canClear,
    dropdownStyles,
    styles,
    options,
    filterName,
    placeholder,
    canFilter,
    dropdownWidth,
    dropdownHeight,
    selectWidth,
    disabled,
    multiple,
    selectedLabelTransformer,
    labelTransformer,
    searchTransformer,
    label,
    labelTooltip,
    valuesTransformer,
    beforeChange,
    externalError,
    infoMessage,
    isOpen,
    beforeBlur,
    focus,
    clearFocus,
    shouldUnregister = true,
    forceDownOpen,
}) => {
    const {
        field: { ref, onBlur, onChange, value },
        fieldState: { error },
    } = useController({
        name,
        control,
        defaultValue: valuesTransformer ? getValuesTransformer(valuesTransformer)(defaultValue || []) : defaultValue || [],
        shouldUnregister,
    })

    const currentValues = useMemo(() => (value ? (Array.isArray(value) ? value : [value]) : value), [value])

    const handleChange = useCallback(
        (v: any) => {
            if (beforeChange) {
                beforeChange(v)
            }
            if (valuesTransformer) {
                const transformer = getValuesTransformer(valuesTransformer)
                onChange(transformer(v))
            } else {
                onChange(v)
            }
        },
        [beforeChange, onChange, valuesTransformer],
    )

    const handleBlur = useCallback(() => {
        if (beforeBlur) {
            beforeBlur()
        }
        onBlur()
    }, [onBlur, beforeBlur])

    const buttonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (focus && clearFocus) {
            buttonRef.current?.focus()
            clearFocus()
        }
    }, [focus, buttonRef, clearFocus])

    return (
        <AutocompleteSelect
            name={name}
            onBlur={handleBlur}
            onSelectedValuesChange={handleChange}
            currentValues={currentValues}
            innerRef={ref}
            errorMessage={error?.message || externalError?.message}
            errorTooltipMessage={error?.types?.tooltip || externalError?.tooltipMessage}
            virtualized={virtualized}
            isActive={isActive}
            dataTestId={dataTestId}
            canClear={canClear}
            dropdownStyles={dropdownStyles}
            styles={styles}
            options={options}
            filterName={filterName}
            placeholder={placeholder}
            canFilter={canFilter}
            dropdownWidth={dropdownWidth}
            dropdownHeight={dropdownHeight}
            selectWidth={selectWidth}
            disabled={disabled}
            multiple={multiple}
            selectedLabelTransformer={selectedLabelTransformer}
            labelTransformer={labelTransformer}
            searchTransformer={searchTransformer}
            label={label}
            labelTooltip={labelTooltip}
            infoMessage={infoMessage}
            isOpen={isOpen}
            buttonRef={buttonRef}
            forceDownOpen={forceDownOpen}
        />
    )
}

export { ControlledAutocompleteSelect }
