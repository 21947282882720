/** @jsxImportSource @emotion/react */

import { Fragment, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import skillsTestsImg from '../../assets/images/tests_portrait.png'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { ContentLoader } from '../../components/layout/Loader'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ScrollToTop } from '../../components/ScrollUp'
import { TilesView, TilesViewFormType } from '../../components/TilesView'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getSpecialistTests } from './api'
import { applicationName, generateMenuItems } from './skillsTestApp'
import { Test, TestStatus } from './tests'

const SkillsTestPage = () => {
    const [tests, setTests] = useState<Array<TilesViewFormType>>([])
    const [isEachTestSolved, setIsEachTestSolved] = useState<Nullable<boolean>>(null)
    const navigate = useNavigate()
    const { specialistId } = useParams<{ specialistId: string }>()
    const log = useLogger('error')
    const { addError } = useNotifications()

    useEffect(() => {
        if (specialistId) {
            getSpecialistTests(specialistId)
                .then((data: Array<Test>) =>
                    setTests(
                        data.map(test => ({
                            isCompleted: test.state === TestStatus.FINISHED,
                            id: test.id,
                            title: test.title,
                            link: `/skills-test/${specialistId}/${test.id}`,
                            openInIframe: false,
                            status: test.state,
                        })),
                    ),
                )
                .catch(err => {
                    log(err)
                    addError()
                })
        }
    }, [addError, log, specialistId])

    useEffect(() => {
        if (tests.length > 0) {
            setIsEachTestSolved(!tests.some(test => !test.isCompleted))
        }
    }, [tests])

    const onFillOutClick = useCallback(
        (link: string): void => {
            navigate(link)
        },
        [navigate],
    )

    useEffect(() => {
        if (isEachTestSolved) {
            navigate('/specialist-dashboard')
        }
    }, [isEachTestSolved, navigate])

    return (
        <Fragment>
            <ScrollToTop />
            <DashboardLayout
                applicationName={applicationName}
                applicationMenuItems={specialistId ? generateMenuItems(specialistId) : undefined}
                showMenuItems={false}
            >
                {isEachTestSolved === null && <ContentLoader />}
                {isEachTestSolved === false && (
                    <TilesView
                        forms={tests}
                        onFillOutClick={onFillOutClick}
                        title='Test Your Skills'
                        description={
                            <p>
                                Below you will find a selection of tests that we’ve prepared for you based on your current role and top
                                skills. Each test will take you through several questions to verify your knowledge of a given skill.
                            </p>
                        }
                        sideImage={skillsTestsImg}
                        sideTitle='About Skill Tests'
                        sideDescription={[
                            {
                                content:
                                    'The Skill Tests process is a dynamic procedure built around intelligent test engine that is designed to provide unbiased benchmark for your technical skills, and knowledge of various tools, libraries and frameworks.',
                            },
                            {
                                content:
                                    'Our assessment framework is designed by psychometrics team based on well-established principles of test theory. We analyse the data and key indicators on an ongoing basis to verify and improve our tests.',
                            },
                            {
                                content:
                                    'We are constantly developing the Skill Tests process to make it more intuitive, faster and highly accurate. Our team is working closely with subject-matter experts in order to expand the questions library as well as to make sure the existing ones are validated and kept up to date.',
                            },
                        ]}
                        completedDescription={<p>Make sure to complete a test once you start it to get the results.</p>}
                        completedHint='Please take all tests prepared for you.'
                    />
                )}
            </DashboardLayout>
        </Fragment>
    )
}

export { SkillsTestPage }
