/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { FlexBox } from '../../../components/layout/FlexBoxHelpers'
import { DetailKey, DetailRow, DetailValue } from '../../../components/specialist/DetailHelpers'
import { MySpecialistsAnonymousEducation } from '../../../contracts/profile/education'
import { convertTimestampToDateString, monthFormat } from '../../../utils/dates'
import { DurationLabel, EducationDurationBox } from '../shared/styled'

type DurationComponentFromToProps = {
    education: MySpecialistsAnonymousEducation
    label: string
}

const DurationComponentFromTo: FunctionComponent<React.PropsWithChildren<DurationComponentFromToProps>> = ({ education, label }) => {
    const from = education.from || education.certificationAcquisitionDate
    const to = education.to

    return (
        <DetailRow>
            <DurationLabel
                css={css`
                    margin: 0 20px;
                `}
            >
                {label}
            </DurationLabel>
            <DetailValue>
                <FlexBox>
                    <EducationDurationBox>{from && convertTimestampToDateString(from, monthFormat)}</EducationDurationBox>
                    <DetailKey
                        css={css`
                            margin: 0 10px;
                        `}
                    >
                        -
                    </DetailKey>
                    {education.type === 'CERTIFICATE_COURSE' ? (
                        <DetailKey>
                            <EducationDurationBox
                                css={css`
                                    white-space: nowrap;
                                `}
                            >
                                {to ? convertTimestampToDateString(to, monthFormat) : 'No Expiration Date'}
                            </EducationDurationBox>
                        </DetailKey>
                    ) : (
                        <EducationDurationBox>
                            {education.inProgress || !to ? 'In Progress' : to && convertTimestampToDateString(to, monthFormat)}
                        </EducationDurationBox>
                    )}
                </FlexBox>
            </DetailValue>
        </DetailRow>
    )
}
export { DurationComponentFromTo }
