/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { ColumnProps } from '../layout/ResponsiveColumns'

export type FiltersBarWrapperProps = {
    filtersOpened: boolean
}

const FiltersBarWrapper: FunctionComponent<React.PropsWithChildren<FiltersBarWrapperProps>> = ({ children, filtersOpened }) => {
    return (
        <div
            css={css`
                margin-bottom: 8px;
                display: ${filtersOpened ? 'block' : 'none'};
            `}
        >
            {children}
        </div>
    )
}

type FiltersClearAllProps = {
    onClear: () => void
    quantity: number
}

const FiltersClearTrigger: FunctionComponent<React.PropsWithChildren<FiltersClearAllProps>> = ({ onClear, quantity }) => {
    return (
        <FlexBox alignItems='center' justifyContent='flex-end'>
            <span
                css={css`
                    cursor: pointer;
                    position: relative;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 40px;
                    white-space: nowrap;
                    float: right;
                    color: ${COLOR_PALETTE.violet_4};
                `}
                onClick={onClear}
            >
                Clear All
                {quantity !== 0 && <span> ({quantity})</span>}
            </span>
        </FlexBox>
    )
}

const FiltersContainer = styled.div`
    margin: 0 -6px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const FilterColumn = styled.div<ColumnProps>`
    display: block;
    padding: 0 12px 0 0;
    width: 100%;
    margin-bottom: 16px;
    ${mqMin[1]} {
        max-width: 150px;
        width: ${(props: ColumnProps) => props.width || 20}%;
    }

    ${mqMax[1]} {
        padding: 0 0;
    }
`

const FilterDoubleColumn = styled.div<ColumnProps>`
    display: block;
    padding: 0 12px 0 0;
    width: 100%;
    margin-bottom: 16px;
    ${mqMin[1]} {
        max-width: 350px;
        width: ${(props: ColumnProps) => props.width || 40}%;
    }

    ${mqMax[1]} {
        padding: 0 0;
    }
`

export { FiltersBarWrapper, FiltersContainer, FilterColumn, FiltersClearTrigger, FilterDoubleColumn }
