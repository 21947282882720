/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useState } from 'react'
import useCollapse from 'react-collapsed'
import skillsformRating from '../../../../src/assets/images/skillform_rating.gif'
import { Button } from '../../../components/Button'
import { mqMax } from '../../../GlobalStyle'
import { FormType } from '../contracts'

type InstructionBoxProps = {
    type?: FormType
}

const InstructionBox: FunctionComponent<React.PropsWithChildren<InstructionBoxProps>> = ({ type }) => {
    const theme = useTheme()
    const [isExpanded, setIsExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })
    const toggle = useCallback(() => setIsExpanded(wasExpanded => !wasExpanded), [])

    return (
        <Fragment>
            <div
                css={css`
                    padding: 24px;
                    width: 100%;
                    background-color: ${theme.colors.beige_1};
                    margin: 0;
                    ${mqMax[2]} {
                        margin-bottom: 24px;
                    }
                `}
            >
                {type === 'SKILL' && (
                    <Fragment>
                        <h6>Rating your skills</h6>
                        <p>
                            <strong>Please follow the instructions below:</strong>
                        </p>
                        <div {...getCollapseProps()}>
                            <ol
                                css={css`
                                    padding-left: 20px;
                                `}
                            >
                                <li>Please choose the level of expertise for each skill you’ve used: Expert, Good or Basic.</li>

                                <img
                                    alt='Skills rating'
                                    src={skillsformRating}
                                    css={css`
                                        box-shadow: ${theme.shadow.small};
                                        border-radius: 4px;
                                        width: 100%;
                                        max-width: 314px;
                                        margin: 8px 0;
                                    `}
                                />
                                <li>Just don’t rate the skills you don’t want to add to your profile.</li>
                                <li>You can always add and edit your skills in your profile after completing a skill form.</li>
                            </ol>
                        </div>
                    </Fragment>
                )}

                {type === 'STRENGTH' && (
                    <Fragment>
                        <h6>You will see a number of statements that people can say about themselves.</h6>
                        <p>
                            <strong>Please follow the instructions below:</strong>
                        </p>
                        <div {...getCollapseProps()}>
                            <p>
                                Consider the extent to which each statement is relevant to you and how it reflects your behaviour or
                                preferences. Your task is to state how much you agree or disagree with each of the statements.
                            </p>
                            <p>
                                Tips: <br />
                                Think about how the statement relates to you in general and how you behave in general. There are no good or
                                bad responses to the statements - this questionnaire is not a test and you can't fail it.
                            </p>
                            <p>
                                Don't think too long about the response - it usually takes under 5 minutes to complete this form. Even if a
                                question does not seem entirely relevant, give the answer you consider would be most like you.
                            </p>
                        </div>
                    </Fragment>
                )}

                <Button variant='linkForm' {...getToggleProps({ onClick: toggle })}>
                    {isExpanded ? 'Hide instructions' : 'Expand instructions'}{' '}
                </Button>
            </div>
        </Fragment>
    )
}

export { InstructionBox }
