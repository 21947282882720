/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import { Button } from '../../components/Button'
import { DefaultLayout } from '../../components/DefaultLayout'
import { AutocompleteSelect } from '../../components/inputs/autocomplete-select/AutocompleteSelect'
import { UserContext } from '../../contracts/contexts'
import { mqMin } from '../../GlobalStyle'
import { history } from '../../history'
import { useUserContext } from '../../hooks/useUserContext'

const UnavailableContext = () => {
    const { userContexts } = useUserContext()
    const [chosenContext, setChosenContext] = useState<Array<UserContext>>(userContexts ? [userContexts[0]] : [])

    useEffect(() => {
        if (userContexts) {
            setChosenContext([userContexts[0]])
        }
    }, [userContexts])

    const handleContextSwitch = useCallback(() => {
        history.push(`/refresh-user-context?wantedContextId=${chosenContext[0]?.contextId}`)
    }, [chosenContext])

    const handleBasicUserContextSwitch = useCallback(() => {
        history.push(`/refresh-user-context?wantedContextId=${userContexts?.find(context => context.type === 'USER')?.contextId}`)
    }, [userContexts])

    return (
        <DefaultLayout
            icon='broken-link-public'
            title='Sorry this context is no longer available'
            description={'You were probably removed by Company Super Admin from recent context. Please choose other available context.'}
        >
            {userContexts && (
                <div
                    css={css`
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                    `}
                >
                    <AutocompleteSelect
                        placeholder='Choose Context'
                        options={userContexts}
                        currentValues={chosenContext}
                        onSelectedValuesChange={setChosenContext}
                        canFilter={false}
                        dropdownWidth='100%'
                        selectedLabelTransformer={(userContext: UserContext) => userContext.contextName}
                    />
                </div>
            )}
            <div
                css={css`
                    margin-top: 30px;
                    display: flex;
                    flex-direction: column;

                    ${mqMin[1]} {
                        flex-direction: row;
                    }
                `}
            >
                <Button
                    css={css`
                        margin-bottom: 10px;

                        ${mqMin[1]} {
                            margin-right: 8px;
                        }
                    `}
                    onClick={handleContextSwitch}
                >
                    Enter Human Cloud Dashboard
                </Button>

                <Button
                    css={css`
                        margin-bottom: 10px;
                    `}
                    onClick={handleBasicUserContextSwitch}
                    variant='tertiary'
                    dataTestId='basic-user-context-button'
                >
                    Enter as Basic User
                </Button>
            </div>
        </DefaultLayout>
    )
}

export { UnavailableContext }
