import { Context, createContext, FunctionComponent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useWindowSize } from 'react-use'
import { getCompanyById, getCurrentUserCompanyRoles, getUser } from 'src/api/api'
import { Company } from 'src/contracts/company'
import { UserData } from 'src/contracts/userData'
import { config } from 'src/environment/config'
import { breakpoints } from 'src/GlobalStyle'
import { history } from 'src/history'
import { useUserAuthentication } from 'src/hooks/useUserAuthentication'
import { useUserContext } from 'src/hooks/useUserContext'
import { ReduxContext } from 'src/redux/Store'
import { Nullable } from 'src/types'
import { log } from 'src/utils/useLogger'
import { useCompany } from 'src/hooks/useCompany'

const HIDDEN_LAUNCHER_PATHS = ['/my-specialists/cv-parsing']

const IntercomContext: Context<any> = createContext({
    update: null,
})

const updateLiveSession = (name: string = '', email: string = '') => {
    try {
        ;(window as any).__ls('identify', {
            name,
            email,
        })
    } catch (e) {
        log(e, 'error')
    }
}

const IntercomProvider: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
    const isBooted = useRef<boolean>(false)
    const userDataFetch = useRef<Nullable<Promise<any>>>(null)
    const {
        selectors: { user },
    } = useContext(ReduxContext)

    const { width } = useWindowSize()
    const { isUserAuthorized, isSpecialistAccount } = useUserAuthentication()
    const { isSuperAdmin, activeContext } = useUserContext()
    const [userFullData, setUserFullData] = useState<UserData>()
    const [companyDetails, setCompanyDetails] = useState<Company>()
    const [roles, setRoles] = useState<string>()

    const { company: userSegmentationCompanyDetails } = useCompany()

    useEffect(() => {
        if (!isSpecialistAccount) {
            if (user?.firebaseId) {
                getUser(user?.firebaseId).then(setUserFullData).catch(log)
                getCurrentUserCompanyRoles().then(setRoles).catch(log)
            }

            if (isSuperAdmin && activeContext?.companyId) {
                getCompanyById(activeContext.companyId).then(setCompanyDetails).catch(log)
            }
        }
    }, [activeContext, isSpecialistAccount, isSuperAdmin, user])

    const update = useCallback(
        async (settings: Intercom_.IntercomSettings = {}) => {
            let actionType = null
            const intercomSettings: any = {}

            let launcherHidden = width <= breakpoints[1] || HIDDEN_LAUNCHER_PATHS.includes(history.location.pathname)

            if (isUserAuthorized) {
                if (isSpecialistAccount || (navigator.userAgent === 'Cypress' && !(window as any).forceRunIntercomOnCypress)) {
                    if (isBooted.current) {
                        actionType = 'shutdown'
                    }
                } else {
                    if (user.email) {
                        intercomSettings.email = user.email
                    }

                    if (user.firstName && user.lastName) {
                        intercomSettings.name = `${user.firstName} ${user.lastName}`
                    } else if (user.displayName) {
                        intercomSettings.name = user.displayName
                    }

                    if (userFullData?.phone) {
                        intercomSettings.phone = userFullData.phone
                    }

                    if (userFullData?.registeredAt) {
                        intercomSettings.created_at = userFullData.registeredAt
                    }

                    if (companyDetails) {
                        intercomSettings.company = {
                            company_id: companyDetails.id,
                            name: companyDetails.legalName,
                            size: companyDetails.numberOfNonAdminEmployees,
                            website: companyDetails?.website,
                            created_at: companyDetails?.createdAt,
                            fake: companyDetails?.fake,
                        }
                    }
                    if (userSegmentationCompanyDetails) {
                        intercomSettings.company = {
                            company_id: userSegmentationCompanyDetails.id,
                            name: userSegmentationCompanyDetails.legalName,
                            size: userSegmentationCompanyDetails.companySize,
                            website: userSegmentationCompanyDetails?.website,
                            created_at: userSegmentationCompanyDetails?.createdAt,
                            company_type: userSegmentationCompanyDetails?.type,
                        }
                    }

                    if (roles) {
                        intercomSettings.company_role_in_platform = roles
                    }

                    actionType = isBooted.current ? 'update' : 'boot'
                }
            } else {
                userDataFetch.current = null
                launcherHidden = true
                actionType = isBooted.current ? 'update' : 'boot'
            }
            try {
                switch (actionType) {
                    case 'boot': {
                        window.Intercom('boot', {
                            app_id: config.intercomAppId,
                            ...intercomSettings,
                            hide_default_launcher: launcherHidden,
                            ...settings,
                        })
                        isBooted.current = true
                        break
                    }
                    case 'update': {
                        window.Intercom('update', {
                            ...intercomSettings,
                            hide_default_launcher: launcherHidden,
                            ...settings,
                        })
                        break
                    }
                    case 'shutdown': {
                        window.Intercom('shutdown')
                        isBooted.current = false
                    }
                }
            } catch (error) {
                log(error, 'error')
            }

            // @todo: move livesession.io to a separate file/hook when trial will be finished
            // and we will have decided to leave livesession license
            updateLiveSession(intercomSettings.name, intercomSettings.email)
        },
        [companyDetails, isSpecialistAccount, isUserAuthorized, roles, user, userFullData, width, userSegmentationCompanyDetails],
    )

    useEffect(() => {
        update()
    }, [update])

    useEffect(() => {
        const unlisten: any = history.listen(() => {
            update()
        })

        return () => {
            unlisten()
        }
    }, [update])

    return <IntercomContext.Provider value={{ update }}>{children}</IntercomContext.Provider>
}

export { IntercomProvider, IntercomContext }
