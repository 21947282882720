/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLifecycles } from 'react-use'
import { getExpertises, getIndustries, getResponsibilities, getSkills } from '../../api/api'
import { Button } from '../../components/Button'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { ListElementProps, TilesList } from '../../components/TilesList'
import { COLOR_PALETTE, mqMax } from '../../GlobalStyle'
import { history } from '../../history'
import { ReduxContext } from '../../redux/Store'
import { Nullable } from '../../types'
import { deepClone } from '../../utils/deepClone'
import { randomString } from '../../utils/strings'
import { useLogger } from '../../utils/useLogger'
import { instantOnboardingFinalize, instantOnboardingInit, instantOnboardingNextStep, instantOnboardingPrevStep } from './api'
import { InstantOnboardingCustomDataSection } from './components/InstantOnboardingCustomDataSection'
import { InstantOnboardingState } from './contracts'
import { SortableList } from './SortableList'
import { useInstantOnboardingGuard } from './useInstantOnboardingGuard'

type StepRepresentationType = 'tiles' | 'sortable'
export type StepType = 'expertise' | 'industries' | 'responsibilities' | 'skills-and-technologies' | 'matching-roles'
type CustomDataSectionType = {
    header: string
    description: string
    limit: number
    fetchOptions?: () => Promise<Array<string>>
}

type StepProps = {
    title: string
    highlight: string
    content: string
    type: StepRepresentationType
    showProgress: boolean
    key: StepType
    customDataSection?: CustomDataSectionType
}

const steps: Array<StepProps> = [
    {
        title: 'Expertise',
        highlight:
            'What are your main areas of expertise? Choose up to 3 options in total. Please focus on the last 3-5 years of your personal and professional journey.',
        content: 'You must choose at least one area of expertise.',
        type: 'tiles',
        showProgress: true,
        key: 'expertise',
        customDataSection: {
            header: 'Add additional areas of expertise',
            description: 'You can write in additional areas of expertise below.',
            limit: 3,
            fetchOptions: getExpertises,
        },
    },
    {
        title: 'Industries',
        highlight:
            'Which industries have you worked in? Choose up to 3 options in total. Please focus on the last 3-5 years of your professional experience.',
        content: 'Choose at least one option that best matches an industry you’ve worked in.',
        type: 'tiles',
        showProgress: true,
        key: 'industries',
        customDataSection: {
            header: 'Add additional industries',
            description: 'You can write in additional industries below.',
            limit: 3,
            fetchOptions: getIndustries,
        },
    },
    {
        title: 'Responsibilities',
        highlight:
            'What are the most common responsibilities you’ve had in your job(s) over the last 3-5 years? Choose up to 5 options in total.',
        content: 'Choose at least one option that best matches your responsibilities.',
        type: 'tiles',
        showProgress: true,
        key: 'responsibilities',
        customDataSection: {
            header: 'Add additional responsibilities',
            description: 'You can write in additional responsibilities below.',
            limit: 3,
            fetchOptions: getResponsibilities,
        },
    },
    {
        title: 'Skills & Technologies',
        highlight:
            'Which skills, technologies and tools do you usually work with? Choose up to 10 options in total. Please focus on the last 3-5 years of your personal and professional experience.',
        content: 'Choose at least one option that best matches your current skills and tech knowledge.',
        type: 'tiles',
        showProgress: true,
        key: 'skills-and-technologies',
        customDataSection: {
            header: 'Add additional skills, technologies or tools',
            description: 'You can write in additional skills, technologies or tools below.',
            limit: 10,
            fetchOptions: getSkills,
        },
    },
    {
        title: 'Matching Roles',
        highlight: 'Our algorithm discovered the following 5 roles that match your particular expertise.',
        content:
            'Please sort the roles according to the role that you most identify with at the top and the least at the bottom. Please remove any roles that you feel do not apply to you. If you don’t see your current role or other applicable roles you may add them below.<br /><br />This role ranking will help us speed up the process of creating your Human Cloud profile by presenting you with the most relevant Skill Mapping Forms and Tests.',
        type: 'sortable',
        showProgress: false,
        key: 'matching-roles',
    },
]

const mapToOptions = (
    options: Array<string>,
    selectedOptions: { predefined: Array<string>; custom: Array<string> },
): { predefined: Array<ListElementProps>; custom: Array<ListElementProps> } => {
    return {
        predefined: options.map(option => ({
            id: randomString(),
            content: option,
            isSelected: selectedOptions.predefined.includes(option),
        })),
        custom: selectedOptions.custom.map(option => ({ id: randomString(), content: option, isSelected: true })),
    }
}

const mapToState = (
    options: Array<ListElementProps>,
    customOptions: Array<ListElementProps>,
    state: InstantOnboardingState,
    stepType: StepType,
) => {
    const newState = deepClone(state)
    const predefined = options.filter(o => o.isSelected).map(o => o.content)
    const custom = customOptions.map(o => o.content).filter(Boolean)
    switch (stepType) {
        case 'expertise':
            newState.answers.expertise = { predefined, custom }
            break

        case 'industries':
            newState.answers.industries = { predefined, custom }
            break

        case 'responsibilities':
            newState.answers.responsibilities = { predefined, custom }
            break

        case 'skills-and-technologies':
            newState.answers.skills = { predefined, custom }
            break
    }

    return newState
}

const InstantOnboarding: FunctionComponent<React.PropsWithChildren<any>> = () => {
    const { key } = useParams<{ key: string }>()
    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)
    const log = useLogger()
    const { goToApp } = useInstantOnboardingGuard()
    const { addSuccess } = useNotifications()
    const [step, setStep] = useState(0)
    const [options, setOptions] = useState<Array<ListElementProps>>([])
    const [customOptions, setCustomOptions] = useState<Array<ListElementProps>>([])
    const [state, setState] = useState<Nullable<InstantOnboardingState>>(null)
    const [maxNumChoices, setMaxNumChoices] = useState<Nullable<number>>(null)
    const currentStep = useMemo(() => steps[step], [step])
    const isLastStep = useMemo(() => step === steps.length - 1, [step])
    const isFirstStep = useMemo(() => step === 0, [step])
    const [isMounted, setIsMounted] = useState(false)

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const selectedOptionsCount = useMemo(
        () => options?.filter(o => o.isSelected).length + customOptions.filter(opt => opt.content !== '').length,
        [options, customOptions],
    )
    const maxNumChoicesReached = useMemo(() => selectedOptionsCount === maxNumChoices, [selectedOptionsCount, maxNumChoices])

    const handleChange = useCallback((list: Array<ListElementProps>) => setOptions(list), [])

    const setMatchingRolesDefaults = useCallback(
        (data = state) => {
            const matchingRoles = JSON.parse(data?.matchingRoles).points
            const keys = Object.keys(matchingRoles)
            const sortedRoles = keys.sort((keyA, keyB) => matchingRoles[keyB] - matchingRoles[keyA])
            const predefined = sortedRoles.splice(0, 5)
            return mapToOptions(predefined, {
                predefined,
                custom: [],
            })
        },
        [state],
    )

    const resetRoles = useCallback(() => {
        setOptions(setMatchingRolesDefaults().predefined)
    }, [setMatchingRolesDefaults])

    const mapState = useCallback(
        (response: InstantOnboardingState, key: StepType = currentStep.key) => {
            setState(response)
            let mappedOptions

            switch (key) {
                case 'expertise':
                    setMaxNumChoices(response.maxNumChoices.expertise)
                    mappedOptions = mapToOptions(
                        response.options.expertise || [],
                        response.answers.expertise || { predefined: [], custom: [] },
                    )
                    break

                case 'industries':
                    setMaxNumChoices(response.maxNumChoices.industries)
                    mappedOptions = mapToOptions(
                        response.options.industries || [],
                        response.answers.industries || { predefined: [], custom: [] },
                    )
                    break

                case 'responsibilities':
                    setMaxNumChoices(response.maxNumChoices.responsibilities)
                    mappedOptions = mapToOptions(
                        response.options.responsibilities || [],
                        response.answers.responsibilities || { predefined: [], custom: [] },
                    )
                    break

                case 'skills-and-technologies':
                    setMaxNumChoices(response.maxNumChoices.skills)
                    mappedOptions = mapToOptions(response.options.skills || [], response.answers.skills || { predefined: [], custom: [] })
                    break

                case 'matching-roles':
                    setMaxNumChoices(5)
                    mappedOptions = setMatchingRolesDefaults(response)
                    break
            }

            if (mappedOptions) {
                setOptions(mappedOptions.predefined)
                setCustomOptions(mappedOptions.custom)
            }
        },
        [currentStep.key, setMatchingRolesDefaults],
    )

    const selectedCustomOptions = useMemo(() => customOptions.map(value => value.content), [customOptions])

    const callApi = useCallback(
        (step: 'next' | 'prev', key: StepType = currentStep.key) => {
            if (state && options) {
                setOptions([])
                setMaxNumChoices(null)
                layoutToggleLoader(true)
                const dataToSend = mapToState(options, customOptions, state, currentStep.key)
                let call: (data: InstantOnboardingState) => Promise<InstantOnboardingState>

                switch (step) {
                    case 'next':
                        call = instantOnboardingNextStep
                        break

                    case 'prev':
                        call = instantOnboardingPrevStep
                        break
                }

                call(dataToSend)
                    .then(response => {
                        setCustomOptions([])
                        mapState(response, key)
                        layoutToggleLoader(false)
                    })
                    .finally(() => layoutToggleLoader(false))
            }
        },
        [options, state, currentStep.key, mapState, layoutToggleLoader, customOptions],
    )

    const handleNextStep = useCallback(() => {
        if (isLastStep) {
            log('Finish process.')
            if (state) {
                layoutToggleLoader(true)
                state.chosenRoles = options?.map(o => o.content) || []
                instantOnboardingFinalize(state)
                    .then(() => {
                        addSuccess('Instant onboarding process finished successfully.')
                        goToApp()
                    })
                    .catch(log)
                    .finally(() => layoutToggleLoader(false))
            }
        } else {
            setStep(step + 1)
            const key = steps[step + 1].key
            history.push(`/instant-onboarding/step/${key}`)
            callApi('next', key)
        }
    }, [step, isLastStep, callApi, log, state, addSuccess, layoutToggleLoader, options, goToApp])

    const handlePrevStep = useCallback(() => {
        if (isFirstStep) {
            log('Should be disabled.')
        } else {
            setStep(step - 1)
            const key = steps[step - 1].key
            history.push(`/instant-onboarding/step/${key}`)
            callApi('prev', key)
        }
    }, [step, isFirstStep, callApi, log])

    useEffect(() => {
        if (key && isMounted) {
            const stepIndex = steps.findIndex(s => s.key === key)
            if (stepIndex !== -1) {
                setStep(stepIndex)
            } else {
                history.push('/page-not-found')
            }
        }
    }, [key, setOptions, isMounted])

    const startAgain = useCallback(() => {
        setState(null)
        setStep(0)
        history.push(`/instant-onboarding/step/expertise`)
    }, [])

    useEffect(() => {
        if (isMounted && state === null) {
            layoutToggleLoader(true)

            if (key !== 'expertise') {
                startAgain()
            } else {
                instantOnboardingInit()
                    .then(mapState)
                    .catch(err => log(err, 'error'))
                    .finally(() => layoutToggleLoader(false))
            }
        }
    }, [mapState, state, startAgain, key, layoutToggleLoader, log, isMounted])

    const handleCustomDataChange = useCallback((values: Array<string>, type: string) => {
        const currentCustomValues: Array<ListElementProps> = values.map(value => ({
            isSelected: true,
            content: value,
            id: randomString(),
        }))
        setCustomOptions(currentCustomValues)
    }, [])

    const isSelectedPredefinedOption = useMemo(() => options.find(o => o.isSelected), [options])

    useEffect(() => {
        if (history.action === 'POP' && isMounted) {
            startAgain()
        }
    }, [startAgain, isMounted])

    return (
        <DashboardLayout showMenuItems={false} applicationName='Instant Onboarding'>
            <div
                css={css`
                    display: flex;
                    width: 100%;
                    max-width: 580px;
                    flex-direction: column;
                    margin: 50px auto;
                    padding: 24px;
                    color: ${COLOR_PALETTE.gray_6};
                `}
            >
                {!isLastStep && (
                    <h5
                        css={css`
                            color: ${COLOR_PALETTE.green_4};
                        `}
                    >
                        {step + 1}/{steps.length - 1}
                    </h5>
                )}

                <h3>{currentStep.title}</h3>

                <div
                    css={css`
                        font-size: 18px;
                        margin-bottom: 20px;
                        line-height: 28px;
                        font-weight: 500;
                    `}
                    dangerouslySetInnerHTML={{ __html: currentStep.highlight }}
                ></div>

                <div dangerouslySetInnerHTML={{ __html: currentStep.content }}></div>

                {options.length !== 0 && (
                    <div
                        css={css`
                            width: 100%;
                            margin-top: 40px;
                            margin-bottom: 40px;
                        `}
                    >
                        {currentStep.type === 'sortable' && (
                            <SortableList
                                onChange={handleChange}
                                options={options}
                                maxNumChoicesReached={maxNumChoicesReached}
                                onReset={resetRoles}
                            />
                        )}
                        {currentStep.type === 'tiles' && (
                            <TilesList onChange={handleChange} options={options} maxNumChoicesReached={maxNumChoicesReached} />
                        )}
                    </div>
                )}

                {currentStep.customDataSection && (
                    <InstantOnboardingCustomDataSection
                        header={currentStep.customDataSection.header}
                        description={currentStep.customDataSection.description}
                        limit={currentStep.customDataSection.limit}
                        type={currentStep.key}
                        fetchOptions={currentStep.customDataSection.fetchOptions}
                        disabled={!isSelectedPredefinedOption || maxNumChoicesReached}
                        handleChange={handleCustomDataChange}
                        values={selectedCustomOptions}
                    />
                )}

                <section
                    css={css`
                        display: flex;
                        justify-content: space-between;

                        ${mqMax[1]} {
                            justify-content: center;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }
                    `}
                >
                    <div>
                        {isLastStep && (
                            <Button
                                variant='link'
                                style={css`
                                    margin-right: 24px;

                                    ${mqMax[1]} {
                                        margin: 0 0 12px;
                                    }
                                `}
                                onClick={startAgain}
                            >
                                Start again
                            </Button>
                        )}
                    </div>

                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;

                            ${mqMax[1]} {
                                justify-content: center;
                                flex-direction: column-reverse;
                                justify-content: center;
                                align-items: center;
                            }
                        `}
                    >
                        <Button
                            variant='link'
                            style={css`
                                margin-right: 24px;

                                ${mqMax[1]} {
                                    margin: 12px 0 0;
                                }
                            `}
                            disabled={isFirstStep}
                            onClick={handlePrevStep}
                        >
                            {isFirstStep ? '' : 'Back'}
                        </Button>

                        <Button
                            variant='primary'
                            type='submit'
                            disabled={(!isLastStep && selectedOptionsCount === 0) || !isSelectedPredefinedOption}
                            css={css`
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            onClick={handleNextStep}
                            dataTestId='instant-onboarding-next-step'
                        >
                            {isLastStep ? 'Go to profile' : 'Next'}
                        </Button>
                    </div>
                </section>
            </div>
        </DashboardLayout>
    )
}

export { InstantOnboarding }
