import { DependencyList, EffectCallback, useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { breakpoints } from '../GlobalStyle'

const useDebouncedEffect = (effect: EffectCallback, delay: number, deps: DependencyList) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps)

    useEffect(() => {
        const handler = setTimeout(callback, delay)

        return () => clearTimeout(handler)
    }, [callback, delay])
}

const useIsMobile = (breakpointIndex = 1) => {
    const { width } = useWindowSize()
    const [isMobile, setIsMobile] = useState(width < breakpoints[breakpointIndex])

    useDebouncedEffect(() => setIsMobile(width < breakpoints[breakpointIndex]), 300, [width])

    return isMobile
}

export { useDebouncedEffect, useIsMobile }
