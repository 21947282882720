/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { DefaultLayout } from '../../components/DefaultLayout'

const MagicLinkAccessDenied: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
    <DefaultLayout
        icon='smiley-drop'
        title='We’re Sorry!'
        description='You can no longer access your profile. Please contact your Talent Alpha Account Admin to regain access.'
    />
)

export { MagicLinkAccessDenied }
