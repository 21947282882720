import { axios } from '../../api/axios'
import { DictionaryEntry } from '../../contracts/dictionaryEntry'
import {
    BookingDetails,
    BookingsFiltersRequest,
    BookingsFiltersResponse,
    BookingsSearchRequest,
    BookingsSearchResponse,
    SearchFiltersRequest,
    SearchFiltersResponse,
    SpecialistsSearchRequest,
    SpecialistsSearchResponse,
} from './contracts'

// Bookings

const bookingRequests = '/booking-requests'

const getBookings = (searchRequest: BookingsSearchRequest): Promise<BookingsSearchResponse> => {
    return axios.post(`${bookingRequests}/client:search`, searchRequest)
}

const getBookingsFilters = (searchRequest: BookingsFiltersRequest): Promise<BookingsFiltersResponse> => {
    return axios.post(`${bookingRequests}/client:filters`, searchRequest)
}

const getBookingDetails = (bookingRequestId: string, specialistId: string): Promise<BookingDetails> =>
    axios.get(`${bookingRequests}/${bookingRequestId}/client/specialist/${specialistId}`)

// Booking feedback

const bookingFeedbackEndpoint = '/booking-feedback'

const getMarketplaceFeedbackNegativeResponseReasonOptions = (): Promise<Array<DictionaryEntry>> => {
    return axios.get(`${bookingFeedbackEndpoint}/client-negotiation-fail-reasons`)
}

const getMarketplaceBookingFeedbackState = (bookingId: string): Promise<string> => {
    return axios.get(`${bookingFeedbackEndpoint}/${bookingId}/client-status`)
}

const sendPositiveMarketplaceFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:clientNegotiationSuccess`, data)
}

const sendInProgressMarketplaceFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:clientNegotiationInProgress`, data)
}

const sendNegativeMarketplaceFeedback = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:clientNegotiationFailed`, data)
}

const sendMarketplaceRateExperience = (data: any): Promise<any> => {
    return axios.post(`${bookingFeedbackEndpoint}:clientRateExperience`, data)
}

// TALENT MARKETPLACE
const talentMarketplaceSpecialistsEndpoint = `/talentMarketplace/specialists`

const getSpecialistsProfiles = (searchRequest: SpecialistsSearchRequest): Promise<SpecialistsSearchResponse> => {
    return axios.post(`${talentMarketplaceSpecialistsEndpoint}:search`, searchRequest)
}

const getSpecialistsSearchFilters = (searchRequest: SearchFiltersRequest): Promise<SearchFiltersResponse> => {
    return axios.post(`${talentMarketplaceSpecialistsEndpoint}:filters`, searchRequest)
}

const getSavedSpecialistsSearchFilters = (searchRequest: SearchFiltersRequest): Promise<SearchFiltersResponse> => {
    return axios.post('/talentMarketplace/savedProfiles:filters', searchRequest)
}

export {
    getBookings,
    getBookingsFilters,
    getBookingDetails,
    getMarketplaceFeedbackNegativeResponseReasonOptions,
    getMarketplaceBookingFeedbackState,
    sendPositiveMarketplaceFeedback,
    sendInProgressMarketplaceFeedback,
    sendNegativeMarketplaceFeedback,
    sendMarketplaceRateExperience,
    getSpecialistsProfiles,
    getSpecialistsSearchFilters,
    getSavedSpecialistsSearchFilters,
}
