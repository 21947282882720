/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useMemo } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { GameBox } from '../contracts'

type BoxProps = {
    boxSize: string
    backgroundColor: string
    borderColor: string
    onClick: any
    dataTestId: string
}

const Box: FunctionComponent<React.PropsWithChildren<BoxProps>> = ({ boxSize, backgroundColor, borderColor, onClick, dataTestId }) => {
    return (
        <div
            data-testid={dataTestId}
            onClick={onClick}
            css={css`
                background-color: ${backgroundColor};
                width: ${boxSize};
                height: ${boxSize};
                border-radius: 50%;
                border: 5px solid ${borderColor};
            `}
        />
    )
}

type ClickableBoxProps = {
    boxSize: string
    item: GameBox
    isVisible: boolean
    click: (event: any) => void
    showResults: boolean
    dataTestId: string
}

const ClickableBox: FunctionComponent<React.PropsWithChildren<ClickableBoxProps>> = ({
    boxSize,
    item,
    isVisible,
    click,
    showResults = false,
    dataTestId,
}) => {
    const backgroundColor = useMemo(() => {
        return (isVisible && item.isClickable) || (item.isSelected && !showResults)
            ? COLOR_PALETTE.gray_6
            : showResults && item.isSelected && item.isClickable
            ? COLOR_PALETTE.green_6
            : showResults && item.isSelected && !item.isClickable
            ? COLOR_PALETTE.red_5
            : COLOR_PALETTE.green_2
    }, [item, isVisible, showResults])

    const borderColor = useMemo(() => {
        return showResults && !item.isSelected && item.isClickable ? COLOR_PALETTE.green_6 : 'transparent'
    }, [item, showResults])

    return <Box onClick={click} backgroundColor={backgroundColor} borderColor={borderColor} boxSize={boxSize} dataTestId={dataTestId} />
}

export { ClickableBox }
