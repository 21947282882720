/** @jsxImportSource @emotion/react */

import { FunctionComponent, useMemo } from 'react'
import { Nullable } from '../../../../types'
import { GameMatrix } from '../../contracts'
import { isMatrixCorrectlySolved } from '../../utils'
import { Play } from './Play'

type ResultsProps = {
    matrix?: Nullable<GameMatrix>
    isTraining?: boolean
    round: number
    rounds: number
    startAgain: (isTraining: boolean) => void
}

const Results: FunctionComponent<React.PropsWithChildren<ResultsProps>> = ({ matrix, isTraining, round, rounds, startAgain }) => {
    const isCorrect = useMemo(() => {
        return matrix ? isMatrixCorrectlySolved(matrix) : false
    }, [matrix])

    return (
        <Play
            matrix={matrix}
            isTraining={isTraining}
            isFinished={true}
            round={round}
            rounds={rounds}
            isCorrect={isCorrect}
            startAgain={startAgain}
        />
    )
}
export { Results }
