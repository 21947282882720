/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useContext, useState } from 'react'
import { AppearanceTypes, ToastProps } from 'react-toast-notifications'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { useDebouncedEffect } from '../../utils/hooks'
import { Button } from '../Button'
import { blackToWhiteFilter, Icon, IconName } from '../Icon'

const getVisualProps = (appearance: AppearanceTypes, mainColor?: string | null, darkText?: boolean | null) => {
    let iconName: IconName = 'back'
    let iconStyle
    let wrapperStyle

    switch (appearance) {
        case 'success':
            iconName = 'check'
            iconStyle = css`
                color: ${COLOR_PALETTE.gray_6};
                ${darkText === false && 'filter:invert();'}
            `
            wrapperStyle = css`
                color: ${darkText !== false ? COLOR_PALETTE.gray_6 : COLOR_PALETTE.white};
                background-color: ${mainColor || COLOR_PALETTE.yellow_4};
            `
            break

        case 'warning':
            iconName = 'notification'
            iconStyle = css`
                filter: ${blackToWhiteFilter};
            `
            wrapperStyle = css`
                color: ${COLOR_PALETTE.white};
                background-color: ${COLOR_PALETTE.violet_6};
            `
            break

        case 'error':
            iconName = 'error'
            iconStyle = css`
                color: ${COLOR_PALETTE.red_4};
            `
            wrapperStyle = css`
                color: ${COLOR_PALETTE.red_4};
                background-color: ${COLOR_PALETTE.red_2};
            `
            break

        default:
            // 'info'
            iconName = 'notification' // TODO: change to "i"
            iconStyle = css`
                color: ${COLOR_PALETTE.black};
            `
            wrapperStyle = css`
                background-color: ${COLOR_PALETTE.blue_3};
            `
            break
    }

    return { iconName, iconStyle, wrapperStyle }
}

type NotificationProps = ToastProps

const ANIMATION_TIMEOUT = 500

const Notification: FunctionComponent<React.PropsWithChildren<NotificationProps>> = ({
    children,
    appearance,
    onDismiss,
    autoDismissTimeout,
}) => {
    const [animation, setAnimation] = useState('animate__flipInX')

    const {
        selectors: { whiteLabel },
    } = useContext(ReduxContext)

    const { iconName, iconStyle, wrapperStyle } = getVisualProps(
        appearance,
        whiteLabel?.active ? whiteLabel?.color : null,
        whiteLabel?.active ? whiteLabel?.darkText : null,
    )

    useDebouncedEffect(
        () => {
            setAnimation('animate__flipOutX')
        },
        autoDismissTimeout - ANIMATION_TIMEOUT,
        [],
    )

    const onClose = useCallback(() => {
        setAnimation('animate__flipOutX')
        setTimeout(() => {
            onDismiss()
        }, ANIMATION_TIMEOUT)
    }, [onDismiss])

    return (
        <div
            css={css`
                min-width: 360px;
                max-width: 547px;
                padding: 4px 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                margin: 10px;
                min-height: 24px;
                font-size: 12px;
                line-height: 15px;
                padding: 4px 10px;
                box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.1);
                ${wrapperStyle}
            `}
            className={`animate__animated animate__faster ${animation}`}
        >
            <span
                css={css`
                    margin-right: 16px;
                    margin-left: 8px;
                    display: flex;
                    align-items: center;
                `}
            >
                <Icon name={iconName} css={iconStyle} size={16} />
            </span>
            <span
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    word-break: break-word;
                `}
                data-testid='app-notification'
            >
                {children}
            </span>

            <span
                css={css`
                    margin-left: 16px;
                    margin-right: 8px;
                    display: flex;
                    align-items: center;
                    opacity: 0.7;

                    &:hover {
                        cursor: pointer;
                        opacity: 1;
                    }
                `}
                onClick={onClose}
            >
                <Icon name={'close'} css={iconStyle} size={16} />
            </span>
        </div>
    )
}

export type NotificationContentProps = {
    appearance?: AppearanceTypes
    actionText?: string
    onActionClick?: () => void
    timeout: number
}

const NotificationContent: FunctionComponent<React.PropsWithChildren<NotificationContentProps>> = ({
    children,
    actionText,
    onActionClick,
    appearance,
}) => {
    return (
        <Fragment>
            <span data-testid={`alert-${appearance}`}>{children}</span>
            {actionText && onActionClick && (
                <Button
                    variant={appearance === 'warning' ? 'talent' : 'secondary'}
                    size='small'
                    onClick={onActionClick}
                    css={css`
                        margin-left: 12px;
                        flex-shrink: 0;
                    `}
                >
                    {actionText}
                </Button>
            )}
        </Fragment>
    )
}

export { Notification, NotificationContent }
