/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { Modal, ModalProps } from '../../../components/Modal'

type CustomFieldRemoveModalProps = ModalProps & {
    onClose: () => void
    onRemove: () => void
}

const CustomFieldRemoveModal: FunctionComponent<React.PropsWithChildren<CustomFieldRemoveModalProps>> = ({ opened, onClose, onRemove }) => (
    <Modal opened={opened} onClose={onClose}>
        <h5>Are you sure about deleting?</h5>
        <p>Deleting Custom Field will affect all Specialist’s profiles.</p>
        <Divider />
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button
                variant='link'
                style={css`
                    margin-right: 22px;
                `}
                onClick={onClose}
            >
                Cancel
            </Button>

            <Button variant='delete' onClick={onRemove} dataTestId='cutom-field-remove-modal-delete-button'>
                Delete
            </Button>
        </div>
    </Modal>
)

export { CustomFieldRemoveModal }
