export enum TestStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
}

export type Test = {
    id: string
    title: string
    state: TestStatus
}

export type TestImage = {
    height: number
    url: string
    width: number
}

export type Result = {
    assessment: string
    correctAnswers: number
    testName: string
    totalQuestions: number
}

export type Question = {
    answerA: string
    answerAImage?: TestImage
    answerB: string
    answerBImage?: TestImage
    answerC: string
    answerCImage?: TestImage
    answerD: string
    answerDImage?: TestImage
    id: string
    statement: string
    statementImage?: TestImage
    timeLeft: number
}

export type TestState = {
    finished: boolean
    question: Question
    result: Result
}

export type FeedbackRequestBody = {
    feedback: string
}

export enum Answer {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    NO_ANSWER = 'NO_ANSWER',
}

export type NextQuestionRequestBody = {
    answer: Answer
}
