/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { FunctionComponent } from 'react'
import { Button } from 'src/components/Button'
import { Modal, ModalProps } from 'src/components/Modal'
import subscribe from 'src/assets/images/subscribe.png'
import { Divider } from 'src/components/Divider'

type SubscriptionRequiredModalProps = ModalProps & {
    onClose: () => void
    onSubscribe: () => void
    closeButtonContent?: string
}

const SubscriptionRequiredModal: FunctionComponent<React.PropsWithChildren<SubscriptionRequiredModalProps>> = ({
    opened,
    onClose,
    onSubscribe,
    closeButtonContent = 'Close',
}) => (
    <Modal opened={opened} onClose={onClose} closeButton={false} maskClosable={false}>
        <div>
            <img
                src={subscribe}
                css={css`
                    width: 100%;
                `}
                alt='Subscribe flyer'
            />
        </div>
        <div
            css={css`
                text-align: center;
                margin-top: 1rem;
            `}
        >
            <h5>Subscribe for access</h5>
            <p>
                This Opportunity is only open to Premium users for the first 48 hours. Subscribe to Premium for early access to all
                Opportunities.
            </p>
        </div>
        <Divider />
        <div
            css={css`
                display: flex;
                justify-content: flex-end;
            `}
        >
            <Button
                variant='link'
                style={css`
                    margin-right: 22px;
                `}
                onClick={onClose}
            >
                {closeButtonContent}
            </Button>

            <Button variant='primary' onClick={onSubscribe} dataTestId='open-opportunities-button-subscribe'>
                Subscribe Now
            </Button>
        </div>
    </Modal>
)

export { SubscriptionRequiredModal }
