/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent } from 'react'
import { COLOR_PALETTE } from '../../../GlobalStyle'
import { generateSubTestId } from '../../../utils/testid'
import { Button } from '../../Button'

type DropdownFooterProps = {
    onApply: () => void
    onClearAll: () => void
    applyButtonText?: string
    clearAllButtonText?: string
    buttonsDisabled?: boolean
    dataTestId?: string
}

const DropdownFooter: FunctionComponent<React.PropsWithChildren<DropdownFooterProps>> = ({
    onApply,
    onClearAll,
    applyButtonText = 'Apply',
    clearAllButtonText = 'Clear All',
    buttonsDisabled = false,
    dataTestId = '',
}) => (
    <div
        css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 16px 18px;
            align-items: center;
        `}
    >
        <span
            onClick={onClearAll}
            css={css`
                font-weight: 500;
                font-size: 12px;
                line-height: 140%;
                cursor: pointer;
                color: ${COLOR_PALETTE.violet_4};

                ${buttonsDisabled &&
                css`
                    color: ${COLOR_PALETTE.gray_3};
                `}
            `}
            data-testid={generateSubTestId(dataTestId, 'clear')}
        >
            {clearAllButtonText}
        </span>
        <Button
            onClick={onApply}
            css={css`
                margin: 0;

                ${buttonsDisabled &&
                `
                        background-color: ${COLOR_PALETTE.gray_2} !important;
                        border-color: ${COLOR_PALETTE.gray_2};
                        color: ${COLOR_PALETTE.gray_3} !important;
                    `}
            `}
            dataTestId={`dropdown-apply-${dataTestId}`}
        >
            {applyButtonText}
        </Button>
    </div>
)

export { DropdownFooter }
