/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent } from 'react'
import { mqMax } from '../../GlobalStyle'
import { convertTimestampToDateString, monthFormat } from '../../utils/dates'
import { Button } from '../Button'
import { Divider } from '../Divider'
import { IconButton } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { SkillsList } from '../talent/SkillsList'
import { Truncate } from '../Truncate'
import { DateString, DetailKey, DetailRow, DetailValue } from './DetailHelpers'
import { ProjectBoxProps } from './ProjectBox'
import { parseHTMLString } from '../opportunities/utils'

type ProjectBoxReadProps = Omit<ProjectBoxProps, 'onSaveClicked' | 'onCancelClicked'>

const ProjectBoxRead: FunctionComponent<React.PropsWithChildren<ProjectBoxReadProps>> = ({
    project,
    index,
    onEditClicked,
    onDeleteClicked,
    onUndoClicked,
    disabled,
}) => {
    return (
        <Fragment>
            <FlexBox data-testid='project-box' alignItems='center'>
                <DetailKey disabled={disabled}>Company:</DetailKey>
                <DetailValue disabled={disabled}>
                    <span
                        data-testid='project-box-ro-company'
                        css={css`
                            font-weight: 500;
                        `}
                    >
                        {project.company}
                    </span>
                </DetailValue>
                <div
                    css={css`
                        min-height: 32px;
                    `}
                >
                    {disabled && onUndoClicked ? (
                        <Button variant='linkForm' size='small' onClick={() => onUndoClicked(index)}>
                            Undo
                        </Button>
                    ) : (
                        <Fragment>
                            <IconButton name='edit' size={32} onClick={() => onEditClicked(index)} />
                            <IconButton name='trash' size={32} onClick={() => onDeleteClicked(index)} dataTestId='remove-project' />
                        </Fragment>
                    )}
                </div>
            </FlexBox>

            <Divider />

            {project.role && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Role:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-role'>
                        {project.role}
                    </DetailValue>
                </DetailRow>
            )}

            {project.from && (
                <DetailRow>
                    <DetailKey disabled={disabled}>From:</DetailKey>
                    <DetailValue disabled={disabled}>
                        <FlexBox>
                            <DateString data-testid='project-box-ro-datefrom'>
                                {convertTimestampToDateString(project.from, monthFormat)}
                            </DateString>
                            <DetailKey
                                disabled={disabled}
                                css={css`
                                    margin: 0 20px;
                                    ${mqMax[1]} {
                                        margin: 0 5px;
                                        min-width: 20px;
                                    }
                                `}
                            >
                                To:
                            </DetailKey>
                            {project.to ? (
                                <DateString data-testid='project-box-ro-dateto'>
                                    {convertTimestampToDateString(project.to, monthFormat)}
                                </DateString>
                            ) : (
                                <DateString data-testid='project-box-ro-dateto'>Now</DateString>
                            )}
                        </FlexBox>
                    </DetailValue>
                </DetailRow>
            )}

            {project.project && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Project:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-project'>
                        {project.project}
                    </DetailValue>
                </DetailRow>
            )}

            {project.industry && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Industry:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-industry'>
                        {project.industry}
                    </DetailValue>
                </DetailRow>
            )}

            {project.stack.length > 0 && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Stack:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-stack'>
                        <SkillsList skills={project.stack} />
                    </DetailValue>
                </DetailRow>
            )}

            {project.description && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Description:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-description'>
                        <Truncate>{parseHTMLString(project.description)}</Truncate>
                    </DetailValue>
                </DetailRow>
            )}

            {project.country && (
                <DetailRow>
                    <DetailKey disabled={disabled}>Country:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-country'>
                        {project.country}
                    </DetailValue>
                </DetailRow>
            )}
            {project.city && (
                <DetailRow>
                    <DetailKey disabled={disabled}>City:</DetailKey>
                    <DetailValue disabled={disabled} data-testid='project-box-ro-city'>
                        {project.city}
                    </DetailValue>
                </DetailRow>
            )}
        </Fragment>
    )
}

export { ProjectBoxRead }
