import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { COLOR_PALETTE } from '../../GlobalStyle'

const sliderStyling = {
    trackStyle: [{ backgroundColor: COLOR_PALETTE.gray_6 }],
    handleStyle: [
        {
            backgroundColor: COLOR_PALETTE.gray_6,
            borderColor: COLOR_PALETTE.gray_6,
            boxShadow: 'none',
            opacity: 1,
        },
        {
            backgroundColor: COLOR_PALETTE.gray_6,
            borderColor: COLOR_PALETTE.gray_6,
            boxShadow: 'none',
            opacity: 1,
        },
    ],
    railStyle: { backgroundColor: COLOR_PALETTE.gray_2 },
}

export { Slider, sliderStyling }
