import jwt_decode from 'jwt-decode'
import { UserDataSSO, UserType } from '../contracts/userDataSSO'
import { Nullable } from '../types'
import { log } from './useLogger'

const decodeAccessToken = (token: string): any => {
    let decodedAccessToken = null
    try {
        decodedAccessToken = jwt_decode(token)
    } catch (e) {
        log(e)
    }
    return decodedAccessToken
}

const getAccessToken = (): Nullable<string> => {
    return localStorage.getItem('accessToken')
}

const getDecodedAccessToken = (): Nullable<UserDataSSO> => {
    const accessToken = localStorage.getItem('accessToken')
    return accessToken ? decodeAccessToken(accessToken) : null
}

const getUserRole = (): Nullable<UserType> => {
    return localStorage.getItem('role') as Nullable<UserType>
}

export { decodeAccessToken, getAccessToken, getDecodedAccessToken, getUserRole }
