/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Dispatch, FunctionComponent, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { getCities } from '../../../api/api'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import { ControlledAutocomplete } from '../../../components/forms/ControlledAutocomplete'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../components/forms/ControlledAutocompleteSelect'
import { ControlledSuggestionInput } from '../../../components/forms/ControlledSuggestionInput'
import { ControlledTextarea } from '../../../components/forms/ControlledTextarea'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { FirstColumn, ResponsiveColumns, SecondColumn } from '../../../components/layout/ResponsiveColumns'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { SpecialistGeneralInformation } from '../../../contracts/specialist/specialistGeneralInformation'
import { mqMax } from '../../../GlobalStyle'
import { useCountries, useIndustries, useResponsibilities, useSkills, useSpecialistRoles } from '../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../redux/Store'
import { hasErrors } from '../../../utils/errors'
import { useLogger } from '../../../utils/useLogger'
import { FIELD_REQUIRED, INVALID_NUMBER } from '../../../validation/validation-messages'
import { yup } from '../../../validation/yup'
import { updateSpecialistGeneralInformation } from '../api'

const schema = yup.object().shape({
    yearsOfExperience: yup.lazy(value => (value === '' ? yup.string() : yup.number().positive().typeError(INVALID_NUMBER))),
    potentialRoles: yup.array().of(yup.string()),
    industries: yup.array().of(yup.string()),
    responsibilities: yup.array().of(yup.string()),
    customFields: yup.array().of(yup.object().shape({ fieldName: yup.string().required(), value: yup.string() })),
    profileSummary: yup.string(),
    city: yup.string(),
    country: yup.string().required(FIELD_REQUIRED),
    remote: yup.string(),
})

type OverviewFormProps = {
    generalInformation: SpecialistGeneralInformation
    specialistId: string
    fetchGeneralInformation: () => void
    toggleEdit: () => void
    focusLocation: boolean
    setForceFocus: Dispatch<SetStateAction<string>>
}

const OverviewForm: FunctionComponent<React.PropsWithChildren<OverviewFormProps>> = ({
    specialistId,
    generalInformation,
    fetchGeneralInformation,
    toggleEdit,
    focusLocation,
    setForceFocus,
}) => {
    const countriesDictionary = useCountries()
    const industriesDictionary = useIndustries()
    const skillsDictionary = useSkills()
    const responsibilitiesDictionary = useResponsibilities()
    const memorizedCountries = useMemo(() => countriesDictionary?.map(c => c.name) || [], [countriesDictionary])
    const rolesDictionary = useSpecialistRoles()

    const {
        actions: { layoutToggleLoader },
        selectors: {
            featureFlags: { newDropdownPoc },
        },
    } = useContext(ReduxContext)

    const {
        handleSubmit,
        formState: { errors, touchedFields, isDirty, isSubmitting },
        reset,
        control,
        getValues,
        watch,
        setValue,
        trigger,
    } = useForm<{
        topSkills: Array<string>
        industries: Array<string>
        responsibilities: Array<string>
        potentialRoles: Array<string>
        profileSummary: string
        city: string
        country: string
        remote: string
    }>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            topSkills: [],
            industries: [],
            responsibilities: [],
            potentialRoles: [],
            profileSummary: '',
            city: '',
            country: '',
            remote: 'No',
        },
    })

    const { addSuccess, addError } = useNotifications()
    const log = useLogger()

    useEffect(() => {
        if (generalInformation.topSkills) {
            setValue('topSkills', generalInformation.topSkills)
        }

        if (generalInformation.industries) {
            setValue('industries', generalInformation.industries)
        }

        if (generalInformation.potentialRoles) {
            setValue('potentialRoles', generalInformation.potentialRoles)
        }

        if (generalInformation.responsibilities) {
            setValue('responsibilities', generalInformation.responsibilities)
        }

        if (generalInformation.profileSummary) {
            setValue('profileSummary', generalInformation.profileSummary)
        }
        if (generalInformation.country) {
            setValue('country', generalInformation.country)
        }

        if (generalInformation.city) {
            setValue('city', generalInformation.city)
        }
        if (generalInformation.remote) {
            const remote = generalInformation.remote ? 'Yes' : 'No'
            setValue('remote', remote)
        }

        reset(getValues())
    }, [setValue, generalInformation, reset, getValues])

    const handleFormSubmit = (formData: any) => {
        layoutToggleLoader(true)
        const { industries, responsibilities, potentialRoles, topSkills, profileSummary, remote, country, city } = formData

        const postData = {
            customFields: generalInformation.customFields,
            role: generalInformation.role,
            seniority: generalInformation.seniority,
            email: generalInformation.email,
            employmentType: generalInformation.employmentType,
            industries,
            responsibilities,
            potentialRoles,
            topSkills,
            profileSummary,
            remote: remote === 'Yes',
            country,
            city,
        }

        updateSpecialistGeneralInformation(specialistId, postData)
            .then(() => {
                addSuccess('User data updated!')
                fetchGeneralInformation()
                toggleEdit()
            })
            .catch(error => {
                log(error)
                addError()
            })
            .finally(() => layoutToggleLoader(false))
    }

    const watchCountry = watch('country')
    const getCitiesForCountry = useCallback((filter: string) => getCities(watchCountry, filter), [watchCountry])

    useEffect(() => {
        if (getValues('city') && touchedFields?.country) {
            setValue('city', '')
        }
    }, [watchCountry, getValues, setValue, touchedFields])

    const handleCancel = useCallback(() => {
        toggleEdit()
    }, [toggleEdit])

    const locationRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (focusLocation && locationRef.current) {
            locationRef.current.scrollIntoView({ behavior: 'smooth' })
            setForceFocus('')
        }
    }, [focusLocation, setForceFocus])

    useEffect(() => {
        trigger('country')
    }, [trigger])

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <section>
                <FieldWrapper>
                    <ControlledTextarea
                        label='Summary (optional):'
                        placeholder='Type something'
                        control={control}
                        name='profileSummary'
                        dataTestId='profile-summary'
                    />
                </FieldWrapper>
                <FieldWrapper>
                    {newDropdownPoc ? (
                        <ControlledAutocomplete
                            label='Top Skills (optional):'
                            placeholder='Top Skills'
                            options={skillsDictionary}
                            control={control}
                            name='topSkills'
                            virtualized
                            dropdownWidth='100%'
                            autoClearSearchValue={false}
                            mode='multiple'
                            canClear
                            dataTestId='topSkills'
                        />
                    ) : (
                        <ControlledAutocompleteSelect
                            multiple
                            label='Top Skills (optional):'
                            placeholder='Top Skills'
                            options={skillsDictionary}
                            canFilter
                            control={control}
                            name='topSkills'
                            virtualized
                            dropdownWidth='100%'
                        />
                    )}
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        multiple
                        label='Potential Roles (optional):'
                        placeholder='Potential Roles'
                        options={rolesDictionary}
                        canFilter
                        control={control}
                        name='potentialRoles'
                        dropdownWidth='100%'
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        multiple
                        label='Industry Experience (optional):'
                        placeholder='Industry Experience'
                        options={industriesDictionary}
                        canFilter
                        control={control}
                        name='industries'
                        dropdownWidth='100%'
                    />
                </FieldWrapper>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        multiple
                        label='Professional Responsibilities (optional):'
                        placeholder='Professional Responsibilities'
                        options={responsibilitiesDictionary}
                        canFilter={false}
                        control={control}
                        name='responsibilities'
                        dropdownWidth='100%'
                    />
                </FieldWrapper>
                <ResponsiveColumns>
                    <FirstColumn>
                        <FieldWrapper ref={locationRef}>
                            <ControlledAutocompleteSelect
                                label='Country:'
                                placeholder='Choose country'
                                options={memorizedCountries}
                                control={control}
                                name='country'
                                canClear
                                dataTestId='general-info-form-country'
                                dropdownWidth='100%'
                                isOpen={focusLocation}
                                valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                            />
                        </FieldWrapper>
                    </FirstColumn>

                    <SecondColumn>
                        <FieldWrapper>
                            <ControlledSuggestionInput
                                label='City (optional):'
                                placeholder='Choose city'
                                fetchOptions={getCitiesForCountry}
                                disabled={!watchCountry}
                                control={control}
                                name='city'
                                allowOnlyValuesFromDictionary
                                fetchFromLetterNumber={1}
                                dataTestId='general-info-form-city'
                            />
                        </FieldWrapper>
                    </SecondColumn>
                </ResponsiveColumns>

                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Remote (optional):'
                        placeholder='Choose option'
                        options={['Yes', 'No']}
                        canFilter={false}
                        control={control}
                        name='remote'
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>
            </section>

            <Divider />
            <div
                css={css`
                    display: flex;
                    justify-content: flex-end;

                    ${mqMax[1]} {
                        justify-content: center;
                        flex-direction: column-reverse;
                        justify-content: center;
                        align-items: center;
                    }
                `}
            >
                <Button
                    variant='link'
                    style={css`
                        margin-right: 24px;

                        ${mqMax[1]} {
                            margin: 12px 0 0;
                        }
                    `}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    css={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                    variant='primary'
                    type='submit'
                    disabled={isSubmitting || !isDirty || !touchedFields || hasErrors(errors)}
                    dataTestId='general-information-save'
                >
                    Save Details
                </Button>
            </div>
        </form>
    )
}

export { OverviewForm }
