import computedStyles from 'computed-styles'
import hexRgb from 'hex-rgb'
import { renderToString } from 'react-dom/server'
import { rareCssProperties } from './rareCssProperties'

type StylesDefinition = { [key: string]: any }

type CssClassDefinition = {
    element: any
    className: string
    styles: StylesDefinition
}

const createIframe = (html: string, iframeId: string = 'preview') => {
    const iframe = document.createElement('iframe')
    iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html)
    document.getElementById(iframeId)?.appendChild(iframe)
}

const getComputedStyles = (element: any) => {
    let output = computedStyles(element)
    Object.keys(output).forEach(key => {
        if (rareCssProperties.includes(key)) {
            delete output[key]
        }
    })
    return output
}

const extractHtml = (Component: any) => {
    const html = renderToString(Component)
    const classRegex = /(class=")([^"]*)(")/g
    const classList = Array.from(html.matchAll(classRegex) || [], match => match[2])
    const classDefinition = classList.map(className => {
        const element = document.querySelector(`.${className}`)
        const styles = element ? getComputedStyles(element) : null
        return { element, className, styles }
    })

    return { html, classDefinition }
}

const hexToRgb = (str: string): string => {
    let output: string = str
    const hexRegex = /#([a-f]|[A-F]|[0-9]){3}(([a-f]|[A-F]|[0-9]){3})?\b/g
    const colorList = str.match(hexRegex) || []
    colorList.forEach(color => {
        output = output.replaceAll(color, hexRgb(color, { format: 'css' }))
    })
    return output
}

const convertStyles = (styles: StylesDefinition) => {
    return `
        {
            ${Object.keys(styles)
                .map(key => `${key}:${styles[key]};`)
                .join('')}
        }
    `
}

const generateStyleTag = ({ className, styles }: CssClassDefinition) => {
    return styles ? `<style type="text/css">.${className}${convertStyles(styles)}</style>` : null
}

const minifyHtml = (html: string): string => {
    return html
        .split('\n')
        .map(line => line.trim())
        .join('')
}

export { minifyHtml, generateStyleTag, hexToRgb, extractHtml, createIframe }
