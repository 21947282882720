/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '../../../components/Button'
import { Divider } from '../../../components/Divider'
import {
    AutocompleteSelectValuesTransformerEnum,
    ControlledAutocompleteSelect,
} from '../../../components/forms/ControlledAutocompleteSelect'
import { FieldWrapper } from '../../../components/layout/FormHelpers'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { SpecialistGeneralInformation } from '../../../contracts/specialist/specialistGeneralInformation'
import { mqMax } from '../../../GlobalStyle'
import { useSeniorities, useSpecialistRoles } from '../../../redux/dictionaryDataHooks'
import { ReduxContext } from '../../../redux/Store'
import { hasErrors } from '../../../utils/errors'
import { useLogger } from '../../../utils/useLogger'
import { yup } from '../../../validation/yup'
import { updateSpecialistGeneralInformation } from '../api'

const schema = yup.object().shape({
    role: yup.string(),
    seniority: yup.string(),
})

type RoleAndSeniorityFormProps = {
    generalInformation: SpecialistGeneralInformation
    toggleEdit: () => void
    specialistId: string
    fetchGeneralInformation: () => void
}

const RoleAndSeniorityForm: FunctionComponent<React.PropsWithChildren<RoleAndSeniorityFormProps>> = ({
    toggleEdit,
    generalInformation,
    specialistId,
    fetchGeneralInformation,
}) => {
    const {
        handleSubmit,
        formState: { errors, touchedFields, isDirty, isSubmitting },
        setValue,
        control,
    } = useForm<{
        role: string
        seniority: string
    }>({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            ...generalInformation,
            role: '',
            seniority: '',
        },
    })

    const rolesDictionary = useSpecialistRoles()
    const senioritiesDictionary = useSeniorities()
    const { addSuccess, addError } = useNotifications()
    const log = useLogger()

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    useEffect(() => {
        if (generalInformation?.role) {
            setValue('role', generalInformation.role)
        }
    }, [setValue, generalInformation?.role])

    useEffect(() => {
        if (generalInformation?.seniority) {
            setValue('seniority', generalInformation.seniority)
        }
    }, [setValue, generalInformation?.seniority])

    const handleCancel = () => {
        toggleEdit()
    }

    const handleFormSubmit = (formData: any) => {
        layoutToggleLoader(true)
        const { role, seniority } = formData

        const postData = {
            email: generalInformation.email,
            employmentType: generalInformation.employmentType,
            country: generalInformation.country,
            city: generalInformation.city,
            remote: generalInformation.remote,
            industries: generalInformation.industries,
            responsibilities: generalInformation.responsibilities,
            topSkills: generalInformation.topSkills,
            potentialRoles: generalInformation.potentialRoles,
            experienceYears: generalInformation.experienceYears,
            customFields: generalInformation.customFields,
            profileSummary: generalInformation.profileSummary,
            role,
            seniority,
        }

        updateSpecialistGeneralInformation(specialistId, postData)
            .then(() => {
                addSuccess('User data updated!')
                fetchGeneralInformation()
            })
            .catch(error => {
                addError()
                log(error)
            })
            .finally(() => {
                layoutToggleLoader(false)
                toggleEdit()
            })
    }

    return (
        <div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Role:'
                        placeholder='Choose role'
                        options={rolesDictionary}
                        canFilter={true}
                        control={control}
                        name='role'
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <ControlledAutocompleteSelect
                        label='Seniority (optional):'
                        placeholder='Choose seniority'
                        options={senioritiesDictionary}
                        canFilter={false}
                        control={control}
                        name='seniority'
                        dropdownWidth='100%'
                        valuesTransformer={AutocompleteSelectValuesTransformerEnum.ARRAY_TO_STRING}
                    />
                </FieldWrapper>{' '}
                <Divider />
                <section
                    css={css`
                        display: flex;
                        justify-content: flex-end;

                        ${mqMax[1]} {
                            justify-content: center;
                            flex-direction: column-reverse;
                            justify-content: center;
                            align-items: center;
                        }
                    `}
                >
                    <Button
                        variant='link'
                        style={css`
                            margin-right: 24px;

                            ${mqMax[1]} {
                                margin: 12px 0 0;
                            }
                        `}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        css={css`
                            ${mqMax[1]} {
                                width: 100%;
                            }
                        `}
                        variant='primary'
                        type='submit'
                        disabled={isSubmitting || !isDirty || !touchedFields || hasErrors(errors)}
                        dataTestId='general-information-save'
                    >
                        Save Details
                    </Button>
                </section>
            </form>
        </div>
    )
}

export { RoleAndSeniorityForm }
