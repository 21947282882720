/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import { BackLink } from '../../components/BackLink'
import { Button } from '../../components/Button'
import { ResponsiveWrapper } from '../../components/layout/ResponsiveWrapper'
import { mqMax } from '../../GlobalStyle'

const RecoverPasswordEmailSent = () => {
    return (
        <ResponsiveWrapper withLogo>
            <BackLink
                path={'/?role=manager'}
                text='Back to Login'
                dataTestId='back-to-login'
                style={css`
                    margin-top: 42px;
                `}
            />
            <h3
                css={css`
                    margin-top: 24px;
                    margin-bottom: 16px;
                `}
                data-testid='password-reset-confirmation'
            >
                Email sent
            </h3>
            <p
                css={css`
                    margin-bottom: 24px;
                `}
            >
                A recovery email has been sent so you can create new password.
            </p>
            <Link to={'/?role=manager'}>
                <Button
                    style={css`
                        ${mqMax[1]} {
                            width: 100%;
                        }
                    `}
                >
                    Log In
                </Button>
            </Link>
        </ResponsiveWrapper>
    )
}

export { RecoverPasswordEmailSent }
