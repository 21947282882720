/** @jsxImportSource @emotion/react */
import { css, SerializedStyles, useTheme } from '@emotion/react'
import { FunctionComponent } from 'react'
import { mqMax } from '../GlobalStyle'
import { Icon } from './Icon'

type InfoAlertProps = {
    description: string
    dataTestId?: string
    styles?: SerializedStyles
}

const InfoAlert: FunctionComponent<React.PropsWithChildren<InfoAlertProps>> = ({ description, dataTestId = 'info-alert', styles }) => {
    const theme = useTheme()
    return (
        <div
            css={css`
                text-align: center;
                background-color: ${theme.colors.blue_2};
                padding: 8px 36px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                ${mqMax[0]} {
                    padding: 8px 8px 8px 36px;
                }
                ${styles}
            `}
            data-testid={dataTestId}
        >
            <Icon
                name='info-small'
                css={css`
                    position: absolute;
                    top: calc(50% - 8px);
                    left: 16px;
                `}
            />
            <div
                css={css`
                    max-width: 280px;
                    font-size: 12px;
                `}
            >
                {description}
            </div>
        </div>
    )
}

export { InfoAlert }
