/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { BackLink } from '../../components/BackLink'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { Loader } from '../../components/layout/Loader'
import { FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { WelcomeScreen } from '../../components/WelcomeScreen'
import { mqMax } from '../../GlobalStyle'
import { Nullable } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { getNextQuestion, getSpecialistTests, resumeSpecialistTests, sendQuestionFeedback, startSpecialistTests } from './api'
import { ExitConfirmationModal } from './components/ExitConfirmationModal'
import { FeedbackModal } from './components/FeedbackModal'
import { TestQuestion } from './components/Question'
import { ResultModal } from './components/ResultModal'
import { applicationName, generateMenuItems } from './skillsTestApp'
import { FeedbackRequestBody, NextQuestionRequestBody, Test, TestState, TestStatus } from './tests'

type SkillTestPageProps = {}

const SkillTestPage: FunctionComponent<React.PropsWithChildren<SkillTestPageProps>> = () => {
    const [test, setTest] = useState<Nullable<Test>>(null)
    const [testState, setTestState] = useState<Nullable<TestState>>(null)
    const [isWelcomeModalVisible, setIsWelcomeModalVisible] = useState(false)
    const [isExitConfirmationModalVisible, setIsExitConfirmationModalVisible] = useState(false)
    const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const navigate = useNavigate()

    const { specialistId, testId } = useParams<{ specialistId: string; testId: string }>()
    const log = useLogger('error')
    const { addError, addSuccess } = useNotifications()

    const handleStart = useCallback(() => {
        if (specialistId && testId) {
            setShowLoader(true)
            startSpecialistTests(specialistId, testId)
                .then(setTestState)
                .finally(() => {
                    setShowLoader(false)
                    setIsWelcomeModalVisible(false)
                })
        }
    }, [specialistId, testId])

    const openExitConfirmationModal = useCallback(() => {
        setIsWelcomeModalVisible(false)
        setIsExitConfirmationModalVisible(true)
    }, [])

    const handleExitCancellation = useCallback(() => {
        setIsExitConfirmationModalVisible(false)
    }, [])

    useEffect(() => {
        if (!isExitConfirmationModalVisible && test?.state === TestStatus.NEW && !isWelcomeModalVisible && !testState) {
            setIsWelcomeModalVisible(true)
        }
    }, [isExitConfirmationModalVisible, isWelcomeModalVisible, test, testState])

    const toggleFeedbackModal = useCallback(() => {
        setIsFeedbackModalVisible(isCurrentlyVisible => !isCurrentlyVisible)
    }, [])

    const handleExitConfirmation = useCallback(() => {
        setIsExitConfirmationModalVisible(false)
        navigate(`/skills-test/${specialistId}`)
    }, [navigate, specialistId])

    const handleSendFeedback = useCallback(
        (data: FeedbackRequestBody) => {
            if (testState?.question.id && specialistId && testId) {
                sendQuestionFeedback(specialistId, testId, testState?.question.id, data)
                    .then(() => {
                        setIsFeedbackModalVisible(false)
                        addSuccess('Thank you for your feedback!')
                    })
                    .catch(err => {
                        log(err)
                        addError()
                    })
            }
        },
        [addError, addSuccess, log, specialistId, testId, testState],
    )

    const handleSendAnswer = useCallback(
        (data: NextQuestionRequestBody) => {
            if (testState?.question.id && specialistId && testId) {
                setShowLoader(true)
                getNextQuestion(specialistId, testId, testState?.question.id, data)
                    .then(setTestState)
                    .catch(err => {
                        log(err)
                        addError()
                    })
                    .finally(() => setShowLoader(false))
            }
        },
        [addError, log, specialistId, testId, testState],
    )

    useEffectOnce(() => {
        if (specialistId) {
            setShowLoader(true)
            getSpecialistTests(specialistId)
                .then((tests: Array<Test>) => {
                    const currentTest = tests.find(t => t.id === testId) || null
                    if (currentTest) {
                        setTest(currentTest)
                        if (currentTest.state === TestStatus.NEW) {
                            setIsWelcomeModalVisible(true)
                            setShowLoader(false)
                        } else if (currentTest.state === TestStatus.IN_PROGRESS && testId) {
                            resumeSpecialistTests(specialistId, testId)
                                .then(setTestState)
                                .finally(() => setShowLoader(false))
                        } else {
                            setShowLoader(false)
                            addSuccess('You already finished this test')
                            navigate(`/skills-test/${specialistId}`)
                        }
                    } else {
                        addError('Link to the test is broken')
                        navigate(`/skills-test/${specialistId}`)
                    }
                })
                .catch(err => {
                    log(err)
                    addError()
                    setShowLoader(false)
                })
        }
    })

    return (
        <DashboardLayout
            applicationName={applicationName}
            applicationMenuItems={specialistId ? generateMenuItems(specialistId) : undefined}
            showMenuItems={false}
        >
            <FullPageWrapper
                css={css`
                    max-width: 620px;
                    ${mqMax[1]} {
                        margin: 75px auto 18px;
                    }
                `}
            >
                <BackLink text='Back to Tests' onClick={openExitConfirmationModal} />
                <WelcomeScreen
                    handleConfirmation={handleStart}
                    isVisible={isWelcomeModalVisible}
                    handleRejection={openExitConfirmationModal}
                    description={`You will be presented with a set of questions related to ${test?.title} that you need to answer one by one. You will have limited time to answer each question. It is best to  complete the test in one session - please make sure you won’t be disturbed for several minutes and your internet connection is reliable.`}
                    title={`${test?.title} test`}
                    subtitle='10-15 minutes'
                    icon='clock'
                />
                <ExitConfirmationModal
                    opened={isExitConfirmationModalVisible}
                    handleClose={handleExitCancellation}
                    handleConfirmation={handleExitConfirmation}
                />
                <FeedbackModal opened={isFeedbackModalVisible} handleClose={toggleFeedbackModal} handleSendFeedback={handleSendFeedback} />
                {showLoader ? (
                    <Loader />
                ) : (
                    testState?.question && (
                        <TestQuestion
                            question={testState?.question}
                            handleSendAnswer={handleSendAnswer}
                            toggleFeedbackModal={toggleFeedbackModal}
                        />
                    )
                )}
                {testState?.finished && specialistId && <ResultModal result={testState.result} specialistId={specialistId} />}
            </FullPageWrapper>
        </DashboardLayout>
    )
}

export { SkillTestPage }
