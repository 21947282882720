import pureAxios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { refreshToken as requestRefreshToken } from '../api/api'
import { config } from '../environment/config'
import { history } from '../history'
import { getAccessToken, getUserRole } from '../utils/currentUser'
import { uuidRegex } from '../utils/regexes'
import { log } from '../utils/useLogger'
import { afterSuccessForbiddenPaths, getAfterSuccessParam, getUserActiveContextStorage } from '../utils/userContext'
import { auth } from './../api/firebase'

const handleError = (error: any): void => {
    log(error, 'error')
}

const defaultConfig = {
    baseURL: config.apiUrl,
}

const standardAxios: AxiosInstance = pureAxios.create(defaultConfig)

const onRequestFulfilled = (request: AxiosRequestConfig) => {
    // TODO: get this config directly from the store
    const accessToken = getAccessToken()
    const { storedUserActiveContextId } = getUserActiveContextStorage()

    if (accessToken && request.headers) {
        request.headers['Authorization'] = `Bearer ${accessToken}`
        // TODO: enable contexts for specialists when BE will be ready
        if (storedUserActiveContextId && getUserRole() !== 'specialist' && request.url !== '/currentUser/contexts') {
            request.headers['X-User-Context-Id'] = storedUserActiveContextId
        }
    }

    return request
}
const onRequestRejected = (error: AxiosError) => Promise.reject(error)

const onResponseFulfilled = (response: AxiosResponse) => {
    if (response?.data !== undefined) {
        return response.data
    }

    return response
}

const onResponseRejected = (error: AxiosError) => {
    handleError(error)
    if (error.response && error.response.status !== 401 && error.response.status !== 403) {
        return Promise.reject(error.response)
    }
}

const handleUnauthorizedError = (error: AxiosError) => {
    if (error?.response?.status === 401) {
        if ((error.config['axios-retry'] as any).retryCount === 5 || !getAccessToken()) {
            if (history.location.pathname !== '/logout' && history.location.pathname !== '/logout/user') {
                history.push(
                    `/logout/user?afterLogin=${encodeURIComponent(
                        `${history.location.pathname}${history.location.search}${history.location.hash}`,
                    )}`,
                )
            }
            return false
        }

        const refreshToken = localStorage.getItem('refreshToken')
        const role = localStorage.getItem('role')

        if (refreshToken && role) {
            const tenantId = role === 'specialist' ? config.gcpSpecialistTenantId : config.gcpTenantId

            requestRefreshToken(refreshToken, tenantId)
                .then(({ token, refreshToken }) => {
                    localStorage.setItem('refreshToken', refreshToken)
                    if (role === 'specialist') {
                        localStorage.setItem('accessToken', token)
                    }
                    // The above access token sometimes causes 401 errors for managers, so try to get one from Firebase.
                    else {
                        auth.currentUser?.getIdToken().then(accessToken => {
                            localStorage.setItem('accessToken', accessToken)
                        })
                    }
                })
                .catch(handleError)
        }

        return true
    }

    if (
        error?.response?.status === 403 &&
        (error as AxiosError<any>).response?.data.code === 'CONTEXT_MISMATCH' &&
        history.location.pathname !== '/refresh-user-context' &&
        history.location.pathname !== '/unavailable-context'
    ) {
        const { storedUserActiveContextId } = getUserActiveContextStorage()
        const canPassAfterSuccess = !afterSuccessForbiddenPaths.includes(history.location.pathname)
        history.push(
            `/refresh-user-context?${canPassAfterSuccess ? getAfterSuccessParam() : ''}${
                uuidRegex.test(storedUserActiveContextId) ? `&wantedContextId=${storedUserActiveContextId}` : ''
            }`,
        )
    }

    if (
        error?.response?.status === 403 &&
        (error as AxiosError<any>).response?.data.code === 'FORBIDDEN_FOR_CURRENT_USER' &&
        history.location.pathname !== '/forbidden-for-current-user'
    ) {
        history.push(
            `/forbidden-for-current-user?afterRelogin=${encodeURIComponent(
                `${history.location.pathname}${history.location.search}${history.location.hash}`,
            )}`,
        )
    }

    if (error.config.url === '/currentUser/contexts' && error.config.method === 'get' && error.response?.status === 400) {
        return true
    }

    return false
}

axiosRetry(standardAxios, {
    retries: 5,
    retryCondition: handleUnauthorizedError,
    retryDelay: (retryCount: number) => {
        return Math.pow(2, retryCount) * 1000
    },
})

// Instantiate the interceptor (you can chain it as it returns the axios instance)
standardAxios.interceptors.request.use(onRequestFulfilled, onRequestRejected)
standardAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)

const axios: AxiosInstance = standardAxios

export { axios, pureAxios }
