import { axios } from '../../api/axios'
import { UserDataSSO } from '../../contracts/userDataSSO'
import { config } from '../../environment/config'

const tenantsEndpoint = `/tenants`

const sendVerificationEmail = (email: string): Promise<UserDataSSO> => {
    return axios.post(`${tenantsEndpoint}/${config.gcpTenantId}:sendVerificationEmail`, {
        email,
        url: `${config.appUrl}/action`,
    })
}

export { sendVerificationEmail }
