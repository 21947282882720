import { axios } from '../../api/axios'
import { RatedSkill, SpecialistParsedSkillsResponse } from '../../contracts/cvs'
import { Education } from '../../contracts/specialist/specialistEducations'
import { SpecialistProject } from '../../contracts/specialist/specialistProject'

const specialistsEndpoint = `/specialists`

const editSpecialistCvEducation = (
    cvParsingId: string,
    experienceId: string,
    experience: Partial<Education>,
): Promise<{ cvParsingId: string; studies: Array<Education> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/education/${experienceId}`, experience)
}

const getSpecialistCvEducation = (cvParsingId: string): Promise<{ cvParsingId: string; studies: Array<Education> }> => {
    return axios.get(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/education`)
}

const markSpecialistCvEducationAsDeleted = (
    cvParsingId: string,
    educationId: string,
): Promise<{ cvParsingId: string; studies: Array<Education> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/education/${educationId}:markAsDeleted`)
}

const undoMarkingSpecialistCvEducationAsDeleted = (
    cvParsingId: string,
    educationId: string,
): Promise<{ cvParsingId: string; studies: Array<Education> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/education/${educationId}:undo`)
}

const editSpecialistCvExperience = (
    cvParsingId: string,
    experienceId: string,
    experience: SpecialistProject,
): Promise<{ cvParsingId: string; experience: Array<SpecialistProject> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/experience/${experienceId}`, experience)
}

const getSpecialistCvExperience = (cvParsingId: string): Promise<{ cvParsingId: string; experience: Array<SpecialistProject> }> => {
    return axios.get(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/experience`)
}

const markSpecialistCvExperienceAsDeleted = (
    cvParsingId: string,
    experienceId: string,
): Promise<{ cvParsingId: string; experience: Array<SpecialistProject> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/experience/${experienceId}:markAsDeleted`)
}

const undoMarkingSpecialistCvExperienceAsDeleted = (
    cvParsingId: string,
    experienceId: string,
): Promise<{ cvParsingId: string; experience: Array<SpecialistProject> }> => {
    return axios.post(`/v1/${specialistsEndpoint}/-/cvs/${cvParsingId}/experience/${experienceId}:undo`)
}

const deprecatedConfirmExtractedData = (cvParsingId: string, data: { ratedSkills: Array<RatedSkill> }): Promise<void> => {
    return axios.post(`/v1${specialistsEndpoint}/me/cvs/${cvParsingId}:confirmOwnData`, data)
}

const deprecatedGetSpecialistParsedSkills = (cvParsingId: string): Promise<SpecialistParsedSkillsResponse> => {
    return axios.get(`/v1${specialistsEndpoint}/-/cvs/${cvParsingId}/skills`)
}

export {
    markSpecialistCvEducationAsDeleted,
    undoMarkingSpecialistCvEducationAsDeleted,
    editSpecialistCvEducation,
    getSpecialistCvEducation,
    getSpecialistCvExperience,
    markSpecialistCvExperienceAsDeleted,
    undoMarkingSpecialistCvExperienceAsDeleted,
    editSpecialistCvExperience,
    deprecatedConfirmExtractedData,
    deprecatedGetSpecialistParsedSkills,
}
