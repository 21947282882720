/** @jsxImportSource @emotion/react */

import { FunctionComponent } from 'react'
import { DictionaryEntry } from '../../contracts/dictionaryEntry'
import { FeedbackExpired } from './FeedbackExpired'
import { FeedbackNotAllowed } from './FeedbackNotAllowed'
import { FeedbackThankYou } from './FeedbackThankYou'
import { NewFeedbackForm } from './NewFeedbackForm'
import { RateYourExperience } from './RateYourExperience'

export enum FeedbackStep {
    'PENDING_RESULT',
    'PENDING_RATE_EXPERIENCE',
    'DONE',
    'EXPIRED',
    'NOT_ALLOWED',
}

type FeedbackWizardProps = {
    step: FeedbackStep
    backButtonText: string
    backButtonPath: string
    pleaseRateMessage: string
    newFeedbackFirstStepBackText: string
    newFeedbackSecondStepPositiveResponseSubmit: (formData: any) => void
    newFeedbackSecondStepInProgressResponseSubmit: () => void
    newFeedbackSecondStepNegativeResponseSubmit: (formData: any) => void
    newFeedbackSecondStepNegativeReasonOptions: Array<DictionaryEntry>
    rateExperienceOnSubmit: (formData: any) => void
}

const FeedbackWizard: FunctionComponent<React.PropsWithChildren<FeedbackWizardProps>> = ({
    step,
    backButtonText,
    backButtonPath,
    pleaseRateMessage,
    newFeedbackFirstStepBackText,
    newFeedbackSecondStepPositiveResponseSubmit,
    newFeedbackSecondStepInProgressResponseSubmit,
    newFeedbackSecondStepNegativeResponseSubmit,
    newFeedbackSecondStepNegativeReasonOptions,
    rateExperienceOnSubmit,
}) => {
    switch (step) {
        case FeedbackStep.PENDING_RESULT:
            return (
                <NewFeedbackForm
                    firstStepBackText={newFeedbackFirstStepBackText}
                    backPath={backButtonPath}
                    pleaseRateMessage={pleaseRateMessage}
                    onSecondStepPositiveResponseSubmit={newFeedbackSecondStepPositiveResponseSubmit}
                    secondStepInProgressResponseButtonText={backButtonText}
                    secondStepInProgressSubmit={newFeedbackSecondStepInProgressResponseSubmit}
                    onSecondStepNegativeResponseSubmit={newFeedbackSecondStepNegativeResponseSubmit}
                    secondStepNegativeReasonOptions={newFeedbackSecondStepNegativeReasonOptions}
                />
            )
        case FeedbackStep.PENDING_RATE_EXPERIENCE:
            return <RateYourExperience message={pleaseRateMessage} backPath={backButtonPath} onSubmit={rateExperienceOnSubmit} />
        case FeedbackStep.DONE:
            return <FeedbackThankYou backButtonText={backButtonText} backButtonPath={backButtonPath} />
        case FeedbackStep.NOT_ALLOWED:
            return <FeedbackNotAllowed />
        case FeedbackStep.EXPIRED:
            return <FeedbackExpired backButtonText={backButtonText} backButtonPath={backButtonPath} />
    }
}

export { FeedbackWizard }
