/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Profile } from '../../contracts/profile/profile'
import { COLOR_PALETTE } from '../../GlobalStyle'
import { ReduxContext } from '../../redux/Store'
import { Button } from '../Button'
import { NoData } from '../NoData'
import { NoDataContainer } from '../shared/shared-styles'

const ZeroMarginH6 = styled.h6`
    margin: 0;
`

const LanguagesContainer = styled.div`
    margin-top: 26px;
    background-color: ${COLOR_PALETTE.gray_1};
    padding: 0 24px;
`

const LanguageRow = styled.div`
    display: flex;
    padding: 14px 0;
    border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
    align-items: center;

    &:last-of-type {
        border: none;
    }
`

const LanguageTitle = styled.p`
    color: ${COLOR_PALETTE.gray_6};
    flex: 1;
    margin: 0;
    font-weight: 500;
`

const LanguageLevel = styled.p`
    flex: 2;
    margin: 0;
`

type LanguagesProps = { profile: Profile }

const Languages: FunctionComponent<React.PropsWithChildren<LanguagesProps>> = ({ profile }) => {
    const {
        selectors: { decodedAccessToken },
    } = useContext(ReduxContext)

    return (
        <Fragment>
            <ZeroMarginH6>Languages</ZeroMarginH6>

            {profile.languages && (
                <LanguagesContainer>
                    {profile.languages.map(language => {
                        return (
                            <LanguageRow key={language.name} data-testid='language-row'>
                                <LanguageTitle data-testid='language-name'>{language.name}</LanguageTitle>
                                <LanguageLevel>{language.levelLabel ? language.levelLabel : language.level}</LanguageLevel>
                            </LanguageRow>
                        )
                    })}
                </LanguagesContainer>
            )}

            {profile.languages.length === 0 && (
                <NoDataContainer
                    css={css`
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    `}
                >
                    <NoData />
                    {decodedAccessToken?.specialistId && (
                        <Link
                            to='/my-specialists/basic-info?skipExperience'
                            css={css`
                                text-decoration: none;
                                margin-top: 10px;
                            `}
                        >
                            <Button>Add Languages</Button>
                        </Link>
                    )}
                </NoDataContainer>
            )}
        </Fragment>
    )
}

export { Languages }
