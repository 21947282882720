/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AddLanguage } from 'src/components/AddLanguage'
import { getSpecialistLanguages } from '../../../api/api'
import { Button } from '../../../components/Button'
import { ContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { LanguageRequest, LanguageResponse } from '../../../contracts/profile/language'
import { COLOR_PALETTE, mqMax } from '../../../GlobalStyle'
import { useLanguages } from '../../../redux/dictionaryDataHooks'
import { NullableArray } from '../../../types'
import { yup } from '../../../validation/yup'
import { saveSpecialistLanguage } from '../api'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { LanguageItem } from './education/LanguageItem'
import { SectionDataNotFound, SectionWrapper } from './SectionHelpers'

export type EducationLanguagesProps = {
    specialistId: string
    onSave?: () => void
}

const EducationLanguagesSection: FunctionComponent<React.PropsWithChildren<EducationLanguagesProps>> = ({ specialistId, onSave }) => {
    const [languages, setLanguages] = useState<NullableArray<LanguageResponse>>(null)
    const [isAdding, setIsAdding] = useState(false)
    const [loading, setLoading] = useState(true)
    const { addSuccess, addError } = useNotifications()
    const languagesDictionary: NullableArray<string> = useLanguages()

    const schema = useMemo(
        () =>
            yup.object().shape({
                name: yup
                    .string()
                    .required()
                    .test('validateUniqueLanguage', 'You already selected this language', (pickedLanguage: any) => {
                        for (const lang of languages || []) {
                            if (lang.name === pickedLanguage) {
                                return false
                            }
                        }
                        return true
                    }),
                level: yup.string().required(),
            }),
        [languages],
    )

    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty, isSubmitting },
        clearErrors,
    } = useForm<LanguageRequest>({
        resolver: yupResolver(schema),
    })

    const componentIsMounted = useRef(true)
    useEffect(() => {
        componentIsMounted.current = true
        return () => {
            componentIsMounted.current = false
        }
    }, [])

    const fetchLanguages = useCallback(() => {
        getSpecialistLanguages(specialistId)
            .then((data: Array<LanguageResponse>) => {
                if (componentIsMounted.current) {
                    setLanguages(data)
                    setLoading(false)
                }
            })
            .catch(() => {
                addError()
                setLoading(false)
            })
    }, [addError, specialistId])

    useEffect(() => {
        fetchLanguages()
    }, [fetchLanguages])

    useEffect(() => {
        clearErrors()
    }, [clearErrors])

    const saveData = (formData: LanguageRequest): void => {
        setLoading(true)
        saveSpecialistLanguage(specialistId, formData)
            .then(() => {
                addSuccess('Language Added')
                setLoading(false)
                setIsAdding(false)
                fetchLanguages()
                if (onSave) {
                    onSave()
                }
                reset()
            })
            .catch(() => {
                addError()
                setLoading(false)
            })
    }

    const handleIsAdding = (): void => {
        setIsAdding(!isAdding)
    }

    const theme = useTheme()

    return (
        <SectionWrapper>
            <SectionHeaderWithActions header='Languages' divider>
                {languages !== null && languages.length > 0 && (
                    <Button onClick={handleIsAdding} disabled={isAdding} variant='tertiary' dataTestId='add-language'>
                        Add Language
                    </Button>
                )}
            </SectionHeaderWithActions>

            {isAdding && !loading && (
                <form
                    onSubmit={handleSubmit(saveData)}
                    css={css`
                        border: 1px solid ${COLOR_PALETTE.gray_2};
                        padding: 16px 24px;
                        margin-bottom: 24px;
                        border-radius: 4px;
                        box-shadow: ${theme.shadow.small};
                    `}
                >
                    <AddLanguage control={control} />
                    <section
                        css={css`
                            display: flex;
                            justify-content: flex-end;

                            ${mqMax[1]} {
                                flex-direction: column-reverse;
                                justify-content: center;
                                align-items: center;
                            }
                        `}
                    >
                        <Button
                            css={css`
                                margin-right: 12px;
                                ${mqMax[1]} {
                                    width: 100%;
                                    margin: 12px 0 0;
                                }
                            `}
                            variant='text'
                            onClick={handleIsAdding}
                        >
                            Cancel
                        </Button>
                        <Button
                            style={css`
                                ${mqMax[1]} {
                                    width: 100%;
                                }
                            `}
                            type='submit'
                            variant='primary'
                            disabled={!isDirty || isSubmitting}
                            dataTestId='save-language'
                        >
                            {isSubmitting ? 'Saving...' : 'Save'}
                        </Button>
                    </section>
                </form>
            )}

            {loading ? (
                <ContentLoader />
            ) : languages !== null && languages.length > 0 ? (
                languages.map((languageEntry: LanguageResponse) => {
                    return (
                        <LanguageItem
                            key={languageEntry.id}
                            languageEntry={languageEntry}
                            specialistId={specialistId}
                            languageDictionary={languagesDictionary}
                            languages={languages}
                            fetchLanguages={fetchLanguages}
                            onSave={onSave}
                            dataTestId='language-item'
                        />
                    )
                })
            ) : (
                !isAdding && (
                    <SectionDataNotFound description='Currently you have no languages added.'>
                        <Button onClick={handleIsAdding} variant='tertiary' dataTestId='add-language'>
                            Add Language
                        </Button>
                    </SectionDataNotFound>
                )
            )}
        </SectionWrapper>
    )
}

export { EducationLanguagesSection }
