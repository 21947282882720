import { useEffect, useState } from 'react'
import { getCurrentUserPendingInvitations } from '../api/api'
import { PendingInvitation } from '../contracts/contexts'
import { NullableArray } from '../types'
import { useLogger } from '../utils/useLogger'
import { useUserAuthentication } from './useUserAuthentication'
import { useUserContext } from './useUserContext'

const useUserPendingInvitations = () => {
    const [pendingInvites, setPendingInvites] = useState<NullableArray<PendingInvitation>>(null)
    const log = useLogger('error')
    const { isUserAuthorized } = useUserAuthentication()

    const { userContexts } = useUserContext()

    useEffect(() => {
        if (isUserAuthorized && userContexts && !pendingInvites) {
            getCurrentUserPendingInvitations().then(setPendingInvites).catch(log)
        }
    }, [isUserAuthorized, log, pendingInvites, userContexts])

    return pendingInvites
}

export { useUserPendingInvitations }
