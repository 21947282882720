import { CompanyAccessLevelGuard } from '../components/CompanyAccessLevelGuard'
import { CompanyStatusGuard } from '../components/CompanyStatusGuard'

const SubscriptionGuard = () => (
    <CompanyAccessLevelGuard>
        <CompanyStatusGuard redirectUrl='/dashboard/company-account/subscription/details' />
    </CompanyAccessLevelGuard>
)

export { SubscriptionGuard }
