/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Fragment, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { Button } from '../../../../components/Button'
import { Divider } from '../../../../components/Divider'
import { LayoutWithStickyColumn } from '../../../../components/layout/LayoutWithStickyColumn'
import { PageWidthWrapper } from '../../../../components/layout/PageWidthWrapper'
import { SpecialistPageWrapper } from '../../../../components/layout/SpecialistPageWrapper'
import { useNotifications } from '../../../../components/notification/NotificationProvider'
import { ProgressBar } from '../../../../components/ProgressBar'
import { ListElementProps, TilesList } from '../../../../components/TilesList'
import { WelcomeScreen } from '../../../../components/WelcomeScreen'
import { mqMax } from '../../../../GlobalStyle'
import { ReduxContext } from '../../../../redux/Store'
import { Nullable } from '../../../../types'
import { deepClone } from '../../../../utils/deepClone'
import { useLogger } from '../../../../utils/useLogger'
import {
    getFeedbackQuestions,
    getFeedbackQuestionsPeerPerformance,
    getPeerSkills,
    rejectPeerSkills,
    saveFeedbackQuestion,
    savePeerPerformance,
    submitPeerSkills,
} from '../api'
import { FeedbackQuestion, FeedbackQuestionType, PeerSkillAnswer, PeerSkills, PeerSkillsGroup, SkillRate } from '../contracts'
import { InstructionBox } from './InstructionBox'
import { SingleItem } from './SingleItem'
import { ThankYouScreen } from './TankYouScreen'

const PeerLabel = styled.div`
    font-size: 16px;
    background-color: ${props => props.theme.colors.violet_1};
    font-weight: 500;
    padding: 8px;
    margin-bottom: 35px;
`

const GroupLabel = styled(PeerLabel)`
    background-color: ${props => props.theme.colors.violet_2};
    margin-bottom: 10px;
`

const Question = styled.div`
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 28px;
`

const LargeButton = styled.div`
    border: 1px solid ${props => props.theme.colors.gray_2};
    padding: 23px;
    border-radius: 4px;
    width: 260px;
    text-align: center;
    font-size: 14px;

    &:hover {
        cursor: pointer;
        border: 1px solid ${props => props.theme.colors.green_4};
        background-color: ${props => props.theme.colors.green_4};
        color: ${props => props.theme.colors.white};
    }
`

const FormWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 580px;
    flex-direction: column;
    margin: 10px auto;
    padding: 24px;
    color: ${props => props.theme.colors.gray_6};
`

const ActionButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

type HeaderProps = {
    currentProgress: number
    feedbackName: string
    assessedSpecialistLabel: string
}

const Header: FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({ currentProgress, feedbackName, assessedSpecialistLabel }) => {
    return (
        <Fragment>
            <ProgressBar currentProgress={currentProgress} />
            <h3>{feedbackName}</h3>
            <PeerLabel>{assessedSpecialistLabel}</PeerLabel>
        </Fragment>
    )
}

const mapPeerSkills = (peerSkills: PeerSkills) => {
    const output = deepClone(peerSkills)

    output.assessedSpecialistSkills.forEach(category => {
        category.skills = (category.skills as Array<string>).map((skill: string) => ({ skill, rate: undefined }))
    })

    return output
}

const FormView: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const [questions, setQuestions] = useState<Nullable<Array<FeedbackQuestion>>>(null)
    const [questionType, setQuestionType] = useState<Nullable<FeedbackQuestionType>>(null)
    const [options, setOptions] = useState<Array<ListElementProps>>([])
    const [peerSkills, setPeerSkills] = useState<Nullable<PeerSkills>>(null)
    const [skillRateAccepted, setSkillRateAccepted] = useState<boolean>(false)
    const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true)
    const [showThankYouScreen, setThankYouScreen] = useState<boolean>(false)

    const {
        actions: { layoutToggleLoader },
    } = useContext(ReduxContext)

    const log = useLogger('error')
    const { addError } = useNotifications()

    const handlePeerResponse = useCallback(
        (response: PeerSkills) => {
            if (response && response.assessedSpecialistId !== null) {
                setPeerSkills(mapPeerSkills(response))
            } else {
                setThankYouScreen(true)
            }
        },
        [setPeerSkills, setThankYouScreen],
    )

    useEffectOnce(() => {
        layoutToggleLoader(true)
        Promise.all([getFeedbackQuestions(), getFeedbackQuestionsPeerPerformance()])
            .then(([data, pp]) => {
                setQuestionType(data[0].feedbackCode)
                setQuestions([...data, pp])
            })
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    })

    const currentQuestion = useMemo(() => questions?.find(question => question.feedbackCode === questionType), [questionType, questions])

    const setQuestion = useCallback(() => {
        const out: Array<ListElementProps> = []
        currentQuestion?.answers.forEach(answer => {
            out.push({ id: answer.answerId, content: answer.answer })
        })
        setOptions(out)
    }, [currentQuestion])

    useEffect(setQuestion, [setQuestion])

    const currentProgress = useMemo(() => {
        let output = 0

        switch (questionType) {
            case 'MS':
                output = 20
                break

            case 'PH':
                output = 40
                break

            case 'MP':
                output = 60
                break

            case 'PP':
                output = 80
                break

            case 'TSA':
                output = 100
                break
        }
        return output
    }, [questionType])

    const isTilesTypeQuestion = useMemo(() => {
        return ['MP', 'PH', 'MS', 'PP'].includes(currentQuestion?.feedbackCode || '')
    }, [currentQuestion])

    const nextQuestion = useCallback(() => {
        if (questions && currentQuestion) {
            if (['MP', 'PH', 'MS'].includes(currentQuestion.feedbackCode)) {
                const currentQuestionIndex = questions.findIndex(question => question.feedbackCode === questionType)
                setQuestionType(questions[currentQuestionIndex + 1].feedbackCode)

                layoutToggleLoader(true)
                saveFeedbackQuestion(
                    currentQuestion.feedbackCode,
                    options.filter(a => a.isSelected).map(a => a.id),
                )
                    .then()
                    .catch(err => {
                        addError()
                        log(err)
                    })
                    .finally(() => layoutToggleLoader(false))
            }

            if (currentQuestion.feedbackCode === 'PP' && currentQuestion.teamId && currentQuestion.assessedSpecialistId) {
                setQuestionType('TSA')
                layoutToggleLoader(true)

                Promise.all([
                    savePeerPerformance(
                        currentQuestion.teamId,
                        options.filter(a => a.isSelected).map(a => a.id),
                        currentQuestion.assessedSpecialistId,
                    ),
                    getPeerSkills(),
                ])
                    .then(([data, peerSkillsResponse]) => {
                        handlePeerResponse(peerSkillsResponse)
                    })
                    .catch(err => {
                        addError()
                        log(err)
                    })
                    .finally(() => layoutToggleLoader(false))
            }
        }
    }, [questionType, questions, currentQuestion, addError, layoutToggleLoader, log, options, handlePeerResponse])

    const rejectSkillRate = useCallback(() => {
        layoutToggleLoader(true)
        rejectPeerSkills(peerSkills!.teamId, peerSkills!.assessedSpecialistId)
            .then(handlePeerResponse)
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => layoutToggleLoader(false))
    }, [addError, layoutToggleLoader, log, peerSkills, handlePeerResponse])

    const acceptSkillRate = useCallback(() => {
        setSkillRateAccepted(true)
    }, [])

    const submitPeerSkill = useCallback(() => {
        const answers: Array<PeerSkillAnswer> = []
        peerSkills!.assessedSpecialistSkills.forEach(group => {
            answers.push(...(group.skills as Array<PeerSkillAnswer>))
        })

        layoutToggleLoader(true)
        submitPeerSkills(peerSkills!.teamId, peerSkills!.assessedSpecialistId, answers)
            .then()
            .catch(err => {
                addError()
                log(err)
            })
            .finally(() => {
                layoutToggleLoader(false)
                setThankYouScreen(true)
            })
    }, [addError, layoutToggleLoader, log, peerSkills, setThankYouScreen])

    const updatePeerSkills = useCallback(
        (group: PeerSkillsGroup, event: { answer: SkillRate; id: string }) => {
            const answer = (group.skills as Array<PeerSkillAnswer>).find((answer: PeerSkillAnswer) => answer.skill === event.id)
            if (answer) {
                answer.rate = event.answer
            }
            setPeerSkills(deepClone(peerSkills))
        },
        [peerSkills],
    )

    const hideWelcomeScreen = useCallback(() => {
        setShowWelcomeScreen(false)
    }, [setShowWelcomeScreen])

    const navigate = useNavigate()

    const backToDashboard = useCallback(() => {
        navigate('/')
    }, [navigate])

    return (
        <SpecialistPageWrapper
            style={css`
                margin-bottom: 75px;
                ${mqMax[2]} {
                    margin-bottom: 150px;
                }
            `}
        >
            {showThankYouScreen ? (
                <PageWidthWrapper>
                    <FormWrapper>
                        <ThankYouScreen />
                    </FormWrapper>
                </PageWidthWrapper>
            ) : (
                <Fragment>
                    <WelcomeScreen
                        isVisible={showWelcomeScreen}
                        handleConfirmation={hideWelcomeScreen}
                        handleRejection={backToDashboard}
                        title='Welcome to Continuous Feedback'
                        description='Here you will find a selection of questionnaires that allow you to give feedback on different aspects of your work. Feel free to be open and honest in your feedback as it won’t be shared with anyone and your responses will be anonymized.'
                        icon='clock'
                        subtitle='10 minutes'
                    />
                    {isTilesTypeQuestion && (
                        <PageWidthWrapper>
                            <FormWrapper>
                                <ProgressBar currentProgress={currentProgress} />
                                {isTilesTypeQuestion && (
                                    <Fragment>
                                        <h3>{currentQuestion?.feedbackName}</h3>
                                        {questionType === 'PP' && currentQuestion && (
                                            <PeerLabel>{currentQuestion.assessedSpecialistLabel}</PeerLabel>
                                        )}

                                        <Question
                                            dangerouslySetInnerHTML={{
                                                __html: currentQuestion?.question || '',
                                            }}
                                        ></Question>

                                        <div
                                            css={css`
                                                margin-bottom: 20px;
                                            `}
                                        >
                                            Choose at least one option.
                                        </div>
                                        <TilesList onChange={setOptions} options={options} maxNumChoicesReached={false} />
                                    </Fragment>
                                )}

                                <Divider />
                                <ActionButtonsWrapper>
                                    <Button disabled={options.filter(o => o.isSelected).length === 0} onClick={nextQuestion}>
                                        Next
                                    </Button>
                                </ActionButtonsWrapper>
                            </FormWrapper>
                        </PageWidthWrapper>
                    )}

                    {questionType === 'TSA' && peerSkills && (
                        <Fragment>
                            {!skillRateAccepted ? (
                                <PageWidthWrapper>
                                    <FormWrapper>
                                        <Header
                                            currentProgress={currentProgress}
                                            feedbackName={peerSkills.feedbackName}
                                            assessedSpecialistLabel={peerSkills.assessedSpecialistLabel}
                                        />
                                        <Question>Do you feel knowledgable enough to rate tech skills of this person?</Question>
                                        <div
                                            css={css`
                                                margin-top: 20px;
                                                display: flex;
                                                flex-direction: row;
                                                justify-content: space-between;
                                                margin-bottom: 122px;
                                            `}
                                        >
                                            <LargeButton onClick={rejectSkillRate}>No, propose other person</LargeButton>
                                            <LargeButton onClick={acceptSkillRate}>Yes, let’s rate</LargeButton>
                                        </div>
                                    </FormWrapper>
                                </PageWidthWrapper>
                            ) : (
                                <LayoutWithStickyColumn
                                    firstCol={
                                        <Fragment>
                                            <Header
                                                currentProgress={currentProgress}
                                                feedbackName={peerSkills.feedbackName}
                                                assessedSpecialistLabel={peerSkills.assessedSpecialistLabel}
                                            />

                                            <div
                                                css={css`
                                                    margin-top: -10px;
                                                    margin-bottom: 30px;
                                                `}
                                            >
                                                Please rate only those skills for which you have good understanding of your teammate's
                                                knowledge level. Feel free to be open and honest, your responses will be anonymized and
                                                won’t be shared with anyone.
                                            </div>
                                            {peerSkills.assessedSpecialistSkills.map((group, index) => (
                                                <div key={index}>
                                                    <GroupLabel>
                                                        {group.category} ({group.skills.length})
                                                    </GroupLabel>

                                                    {(group.skills as Array<PeerSkillAnswer>).map((answer: PeerSkillAnswer) => (
                                                        <SingleItem
                                                            key={answer.skill}
                                                            label={answer.skill}
                                                            id={answer.skill}
                                                            value={answer.rate}
                                                            onChange={event => updatePeerSkills(group, event)}
                                                        />
                                                    ))}
                                                </div>
                                            ))}

                                            <ActionButtonsWrapper>
                                                <Button onClick={submitPeerSkill}>Submit</Button>
                                            </ActionButtonsWrapper>
                                        </Fragment>
                                    }
                                    secondCol={<InstructionBox />}
                                />
                            )}
                        </Fragment>
                    )}
                </Fragment>
            )}
        </SpecialistPageWrapper>
    )
}

export { FormView }
