/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import isEqual from 'lodash.isequal'
import { Fragment, FunctionComponent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffectOnce, usePromise } from 'react-use'
import { BlueActionBox } from '../../components/BlueActionBox'
import { ResponsiveGrid } from '../../components/data-grid/ResponsiveGrid'
import { DataNotFound } from '../../components/DataNotFound'
import { IconButton } from '../../components/Icon'
import { KeyInfoIndicator } from '../../components/KeyInfoIndicator'
import { DashboardLayout } from '../../components/layout/dashboard/DashboardLayout'
import { OverlayContentLoader } from '../../components/layout/Loader'
import { ColorBackgroundWrapper, FullPageWrapper } from '../../components/layout/ResponsiveWrapper'
import { useNotifications } from '../../components/notification/NotificationProvider'
import { Pagination } from '../../components/Pagination'
import { CustomExportModal } from '../../components/profile-export/CustomExportModal'
import { ProfileExportButton } from '../../components/profile-export/ProfileExportButton'
import { ExportType } from '../../components/profile-export/types'
import { ScrollToTop } from '../../components/ScrollUp'
import { SearchInput } from '../../components/SearchInput'
import { getAppPath } from '../../contracts/applications'
import { Paging } from '../../contracts/common/pagingProps'
import { mapEmploymentType, Specialist } from '../../contracts/specialist/specialist'
import { breakpoints, COLOR_PALETTE, mqMax, mqMin } from '../../GlobalStyle'
import { history } from '../../history'
import { useMySpecialistsMenuItems } from '../../hooks/useMySpecialistsMenuItems'
import { Nullable, NullableArray } from '../../types'
import { useLogger } from '../../utils/useLogger'
import { useQuery } from '../../utils/useQuery'
import { alphabeticallyAsc } from '../../utils/sorting'
import { Full } from '../pdf/Full'
import { getParsedCvsCount, getSpecialists, getSpecialistsFilters } from './api'
import { SpecialistsFilters, SpecialistsFiltersRequest } from './contracts'
import {
    countSelectedFilters,
    parseQueryToMySpecialistsSearchRequest,
    stringifyMySpecialistsSearchRequestToQuery,
} from './mySpecialistsUtils'
import { HeaderWithActions } from './shared/HeaderWithActions'
import { AddSpecialistsActions } from './specialist-search/AddSpecialistsActions'
import { AddSpecialistsModal } from './specialist-search/AddSpecialistsModal'
import { SpecialistsFiltersBar } from './specialist-search/SpecialistsFiltersBar'
import { ProfileActions, SearchButton } from './styles'

type SpecialistsResultsWrapperProps = {
    pending?: boolean
    total: number
    filtered: boolean
    onClickAddSpecialist: () => void
}

const SpecialistsNotFound = styled(DataNotFound)`
    margin-top: calc(10vh);
`

const SpecialistsResultsWrapper: FunctionComponent<React.PropsWithChildren<SpecialistsResultsWrapperProps>> = ({
    pending,
    total,
    filtered,
    onClickAddSpecialist,
    children,
}) => {
    const log = useLogger('error')
    const [parsedCvsCount, setParsedCvsCount] = useState(0)

    useEffect(() => {
        getParsedCvsCount().then(setParsedCvsCount).catch(log)
    }, [log])

    return (
        <div
            css={css`
                position: relative;
                max-width: 1440px;
                margin: 0 auto;
                ${mqMax[1]} {
                    margin: 12px;
                }
            `}
        >
            {parsedCvsCount > 0 && (
                <BlueActionBox
                    withInfoIcon
                    text={`There ${
                        parsedCvsCount > 1 ? `are ${parsedCvsCount} parsed CVs` : `is ${parsedCvsCount} parsed CV`
                    } pending confirmation.`}
                    buttonText='See Files'
                    action={() => history.push('/my-specialists/cv-parsing?tab=extractedData')}
                    styles={css`
                        margin-bottom: 24px;
                        ${mqMax[1]} {
                            display: flex;
                            flex-direction: column;

                            img {
                                top: 8px;
                            }
                        }
                    `}
                />
            )}

            {!pending && !total && filtered && (
                <SpecialistsNotFound iconName='no-data' title='No Specialists Found' description='Try changing your filters or query' />
            )}

            {!pending && !total && !filtered && (
                <SpecialistsNotFound
                    iconName='no-data'
                    title='No Specialists Added'
                    styles={css`
                        margin-bottom: 32px;
                    `}
                    description={
                        <span
                            css={css`
                                color: ${COLOR_PALETTE.gray_4};
                                max-width: 440px;
                                padding: 16px;
                                display: inline-block;
                            `}
                        >
                            You haven’t added any Specialists yet. You can add them one by one by ‘Creating a New Specialist’ or import them
                            in bulk using our spreadsheet template.
                        </span>
                    }
                >
                    <div
                        css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            ${mqMax[1]} {
                                flex-direction: column;
                            }
                        `}
                    >
                        <AddSpecialistsActions onClickAddSpecialist={onClickAddSpecialist} />
                    </div>
                </SpecialistsNotFound>
            )}
            {total > 0 && <div>{children}</div>}
            {pending && <OverlayContentLoader />}
        </div>
    )
}
const defaultPageSize = 10

const initialSelectedFilters: SpecialistsFilters = {
    employmentTypes: [],
    roles: [],
    seniorities: [],
    skills: [],
    locations: [],
    remote: false,
}

const SpecialistsSearchPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const mounted = usePromise()
    const { addSuccess, addError } = useNotifications()
    const [newSpecialistsEmails, setNewSpecialistsEmails] = useState<Array<string>>([])
    const [filtersOpened, setFiltersOpened] = useState(false)
    const [addSpecialistsOpened, setAddSpecialistsOpened] = useState(false)
    const [pending, setPending] = useState(false)
    const [possibleFilters, setPossibleFilters] = useState<SpecialistsFilters>(initialSelectedFilters)

    const [sorting, setSorting] = useState<Array<any>>([])
    const [specialists, setSpecialists] = useState<NullableArray<Specialist>>(null)
    const [total, setTotal] = useState(0)
    const [exportProfileModalOpen, setExportProfileModalOpen] = useState(false)
    const [exportProfileSpecialistId, setExportProfileSpecialistId] = useState('')
    const [exportType, setExportType] = useState<Nullable<ExportType>>(null)
    const query = useQuery()

    const navigate = useNavigate()
    const { search } = useLocation()

    const { menuItems, applicationName } = useMySpecialistsMenuItems()

    useEffect(() => {
        if (search) {
            setFiltersOpened(true)
        }
    }, [search])

    const onToggleFilters = useCallback(() => {
        setFiltersOpened(!filtersOpened)
    }, [filtersOpened])

    const searchRequest = useMemo<SpecialistsFiltersRequest & SpecialistsFilters & Paging>(
        () => parseQueryToMySpecialistsSearchRequest(search),
        [search],
    )
    const searchFilters = useMemo((): SpecialistsFilters => {
        const { searchText: s, paging: p, ...onlyFilters } = searchRequest
        return onlyFilters
    }, [searchRequest])

    const [paging, setPaging] = useState({ page: searchRequest.paging?.page || 1, size: searchRequest.paging?.size || defaultPageSize })
    const [searchText, setSearchText] = useState<string>(searchRequest.searchText)
    const [selectedFilters, setSelectedFilters] = useState<SpecialistsFilters>(searchFilters)
    const [isAnonymized, setIsAnonymized] = useState<Nullable<boolean>>(null)

    useEffect(() => {
        const completeRequest = { searchText, paging, ...selectedFilters }
        const newSearchQuery = stringifyMySpecialistsSearchRequestToQuery(completeRequest)
        navigate({ search: newSearchQuery }, { replace: true })
    }, [paging, navigate, searchText, selectedFilters])

    const fetchSpecialists = useCallback(() => {
        setPending(true)
        const request = { searchText, ...selectedFilters, paging, sorting }
        getSpecialists(request)
            .then(data => {
                if (data && data.specialists) {
                    const specialistsData: Array<Specialist> = [...data.specialists].map(specialist => {
                        const transformedSpecialist = { ...specialist }

                        if (transformedSpecialist.employmentType) {
                            transformedSpecialist.employmentType = mapEmploymentType(transformedSpecialist.employmentType)
                        }

                        return transformedSpecialist
                    })

                    setSpecialists(specialistsData)
                    setTotal(data.total)
                } else {
                    setSpecialists([])
                    setTotal(0)
                }
            })
            .catch(() => {
                addError()
            })
            .finally(() => {
                setPending(false)
            })
    }, [searchText, selectedFilters, paging, sorting, addError])

    const fetchPossibleFilters = useCallback(() => {
        const request = { searchText }

        mounted(
            getSpecialistsFilters(request)
                .then(
                    data => {
                        setPossibleFilters(data)
                    },
                    () => {
                        addError()
                    },
                )
                .finally(),
        )
    }, [searchText, mounted, addError])

    useEffect(fetchPossibleFilters, [fetchPossibleFilters])

    const onChangeSearchText = useCallback(
        (text: string) => {
            setSearchText(text)
            setSelectedFilters(prev => (!isEqual(prev, initialSelectedFilters) ? initialSelectedFilters : prev))
            const newPaging = { page: 1, size: paging.size }
            setPaging(prev => (!isEqual(prev, newPaging) ? newPaging : prev))
            setNewSpecialistsEmails([])
        },
        [paging.size],
    )

    const onChangeFilters = useCallback((newFilters: SpecialistsFilters) => {
        setSelectedFilters(newFilters)
        setNewSpecialistsEmails([])
    }, [])

    const onChangePageSize = useCallback(
        (size: Array<string>) => {
            setPaging({ page: paging.page, size: Number(size.toString()) })
        },
        [paging],
    )

    useEffect(() => {
        if (total > 0 && specialists?.length === 0 && paging.page > 1) {
            setPaging(oldPaging => ({ page: 1, size: oldPaging.size }))
        }
    }, [paging.page, specialists?.length, total])

    const onClickAddSpecialist = useCallback(() => {
        setAddSpecialistsOpened(true)
    }, [])

    const handleQuickSummaryExport = useCallback(
        (key: ExportType, id: string) => {
            if (id) {
                setExportProfileSpecialistId(id)
            }
            if (key) {
                setExportType(key)
            }
        },
        [setExportType],
    )

    const cleanExportProfileState = useCallback(() => {
        setExportProfileSpecialistId('')
        setExportType(null)
    }, [])

    const exportedDoc = useMemo(() => {
        let output = null

        switch (exportType) {
            case ExportType.FullExportNotAnonymized:
                output = (
                    <Full
                        specialistId={exportProfileSpecialistId}
                        forceDownload={true}
                        cleanExportProfileState={cleanExportProfileState}
                        isAnonymized={false}
                    />
                )
                break

            case ExportType.FullExportAnonymized:
                output = (
                    <Full
                        specialistId={exportProfileSpecialistId}
                        forceDownload={true}
                        isAnonymized={true}
                        cleanExportProfileState={cleanExportProfileState}
                    />
                )
                break

            case ExportType.QuickSummaryNotAnonymized:
            case ExportType.CustomExportNotAnonymized:
                setIsAnonymized(false)
                setExportProfileModalOpen(true)
                break

            case ExportType.QuickSummaryAnonymized:
            case ExportType.CustomExportAnonymized:
                setIsAnonymized(true)
                setExportProfileModalOpen(true)
                break
        }

        return output
    }, [cleanExportProfileState, exportProfileSpecialistId, exportType])

    const onCloseAddSpecialistsModal = useCallback(
        (specialistsEmails: Array<string>) => {
            setAddSpecialistsOpened(false)
            if (specialistsEmails.length > 0) {
                addSuccess(`New Specialist${specialistsEmails.length > 1 ? 's were' : ' was'} created successfully.`)
                setNewSpecialistsEmails(specialistsEmails)
                fetchSpecialists()
            }
        },
        [fetchSpecialists, addSuccess],
    )

    useEffect(fetchSpecialists, [fetchSpecialists])

    useEffectOnce(() => {
        const emails = query.get('emails')
        if (emails) {
            setNewSpecialistsEmails(emails.split(','))
        }
    })

    const filtered = searchText !== '' || countSelectedFilters(selectedFilters) > 0

    const onChangeTable = useCallback((_: any, __: any, newSorter: any) => {
        if (Array.isArray(newSorter)) {
            const newSorting = newSorter.filter(({ order }) => order).map(({ field, order }) => ({ field, order }))
            setSorting(newSorting)
        } else if (newSorter) {
            const { field, order } = newSorter
            const newSorting = order ? [{ field, order }] : []
            setSorting(newSorting)
        }
    }, [])

    const onChangePage = useCallback((page: any, size: any) => {
        setPaging({ page, size })
        setNewSpecialistsEmails([])
    }, [])

    const onClearFilters = useCallback(() => {
        setSelectedFilters(initialSelectedFilters)
    }, [])

    const handleExportProfileCloseModal = useCallback(() => {
        setExportProfileModalOpen(false)
    }, [])

    type ComparerCreator<RecordType = any> = (fieldName: keyof RecordType) => (a: RecordType, b: RecordType) => number
    const createComparer: ComparerCreator = fieldName => (a, b) => a[fieldName] - b[fieldName]

    const columns: ColumnsType<Specialist> = [
        {
            title: 'Name',
            render: (_, record) => (
                <KeyInfoIndicator text={`${record.firstName} ${record.lastName}`} missingKeyInfo={record.missingKeyInfo} />
            ),
            sorter: {
                compare: (a, b) => alphabeticallyAsc(`${a.lastName} ${a.firstName}`, `${b.lastName} ${b.firstName}`),
                multiple: 1,
            },
            showSorterTooltip: false,
            dataIndex: 'name',
        },
        {
            title: 'Employment Status',
            dataIndex: 'employmentType',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            sorter: {
                compare: createComparer('role'),
                multiple: 3,
            },
            showSorterTooltip: false,
        },
        {
            title: 'Seniority',
            dataIndex: 'seniority',
        },
        {
            title: 'Country',
            dataIndex: 'country',
        },
        {
            title: 'City',
            dataIndex: 'city',
        },
        {
            title: '',
            render: (_, record) => <ProfileActionsRenderer specialist={record} onClickExportProfile={handleQuickSummaryExport} />,
            width: 170,
        },
    ]

    const rowClassName = useCallback(
        (record: Specialist) => (record.email && newSpecialistsEmails.includes(record.email) ? 'ant-table-row-created' : ''),
        [newSpecialistsEmails],
    )

    const rowProps = useCallback(
        (record: any) => ({
            onClick: () =>
                history.push(
                    `${getAppPath('MY_SPECIALISTS')}/specialist/${record.id}?prevPath=${encodeURIComponent(
                        window.location.pathname + encodeURIComponent(window.location.search) + window.location.hash,
                    )}`,
                ),
        }),
        [],
    )

    return (
        <DashboardLayout applicationName={applicationName} applicationMenuItems={menuItems}>
            <ScrollToTop action={paging} />

            {exportedDoc}

            <CustomExportModal
                specialistId={exportProfileSpecialistId}
                opened={exportProfileModalOpen}
                handleCloseModal={handleExportProfileCloseModal}
                exportType={exportType}
                setExportType={setExportType}
                setSpecialistId={setExportProfileSpecialistId}
                isAnonymized={isAnonymized}
            />

            <ColorBackgroundWrapper
                css={css`
                    background-color: ${COLOR_PALETTE.gray_1};
                    padding: 18px 16px 0;
                    ${mqMin[1]} {
                        border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                    }
                `}
            >
                <div
                    css={css`
                        max-width: 1440px;
                        margin: 0 auto;
                        ${mqMax[1]} {
                            border-bottom: 1px solid ${COLOR_PALETTE.gray_2};
                        }
                    `}
                >
                    <HeaderWithActions header='My Specialists' isSearchPage>
                        <div
                            css={css`
                                display: flex;
                                ${mqMax[1]} {
                                    flex-direction: column;
                                }
                            `}
                        >
                            <AddSpecialistsActions onClickAddSpecialist={onClickAddSpecialist} />
                        </div>
                    </HeaderWithActions>
                    <SearchInput
                        initialText={searchText}
                        disabled={!specialists?.length && !filtered}
                        onSubmit={onChangeSearchText}
                        filtersOpened={filtersOpened}
                        onToggleFilters={onToggleFilters}
                        placeholder='Skills, tools, frameworks, etc.'
                        dataTestId='my-specialists-search'
                        styles={css`
                            margin-bottom: 16px;
                        `}
                    />
                    <SpecialistsFiltersBar
                        opened={filtersOpened}
                        possibleFilters={possibleFilters}
                        selectedFilters={selectedFilters}
                        onChangeFilters={onChangeFilters}
                        onClearAll={onClearFilters}
                    />
                </div>
            </ColorBackgroundWrapper>

            <SpecialistsResultsWrapper pending={pending} total={total} filtered={filtered} onClickAddSpecialist={onClickAddSpecialist}>
                <ResponsiveGrid
                    dataTestId='specialist-search-results'
                    rowKey='id'
                    dataSource={specialists || []}
                    columns={columns}
                    pagination={false}
                    onChange={onChangeTable}
                    scroll={{ x: breakpoints[1] }}
                    rowClassName={rowClassName}
                    onRow={rowProps}
                />
                <FullPageWrapper>
                    <div
                        css={css`
                            margin-top: 40px;
                        `}
                    >
                        <Pagination
                            pageSize={paging.size}
                            defaultPageSize={defaultPageSize}
                            total={total}
                            onChangePageSize={onChangePageSize}
                            onChange={onChangePage}
                            current={paging.page}
                            css={css`
                                ${mqMax[0]} {
                                    display: flex;
                                    justify-content: center;
                                }
                            `}
                        />
                    </div>
                </FullPageWrapper>
            </SpecialistsResultsWrapper>

            {addSpecialistsOpened && <AddSpecialistsModal onClose={onCloseAddSpecialistsModal} opened={addSpecialistsOpened} />}
        </DashboardLayout>
    )
}

type ProfileActionsRendererProps = {
    specialist: Specialist
    onClickExportProfile?: (key: ExportType, id: string) => void
}

const ProfileActionsRenderer: FunctionComponent<React.PropsWithChildren<ProfileActionsRendererProps>> = ({
    specialist,
    onClickExportProfile,
}) => {
    const handleProfileExport = useCallback(
        (key: ExportType) => {
            if (specialist.id && onClickExportProfile) {
                onClickExportProfile(key, specialist.id)
            }
        },
        [onClickExportProfile, specialist.id],
    )

    const handlePreventDefault = useCallback((e: MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }, [])

    const navigate = useNavigate()
    const handleOpportunitiesSearch = useCallback(
        (e: MouseEvent) => {
            handlePreventDefault(e)
            navigate(`${getAppPath('OPEN_OPPORTUNITIES')}?searchText=${specialist.role}`)
        },
        [handlePreventDefault, navigate, specialist.role],
    )

    return (
        <Fragment>
            <ProfileActions>
                <Tooltip placement='topRight' title='Find a job opportunity for this specialist on our platform right now'>
                    <div>
                        <SearchButton name='find' dataTestId='opportunities-search-grid-button' onClick={handleOpportunitiesSearch} />
                    </div>
                </Tooltip>
                <Tooltip placement='topRight' title='Export Profile'>
                    <div>
                        <ProfileExportButton handleClick={handleProfileExport}>
                            <div onClick={handlePreventDefault}>
                                <IconButton name='download' dataTestId='profile-export-grid-button' />
                            </div>
                        </ProfileExportButton>
                    </div>
                </Tooltip>
            </ProfileActions>
        </Fragment>
    )
}

export { SpecialistsSearchPage, ProfileActionsRenderer }
