/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { Fragment, FunctionComponent } from 'react'
import { SkillsGroup } from '../../contracts/personalStrengths'
import { TooltipIcon } from '../TooltipIcon'

const scale = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

type PersonalStrengthsChartProps = {
    groups: Array<SkillsGroup>
    isPrintable?: boolean
    matchProfileSummary?: boolean
}

const PersonalStrengthsChart: FunctionComponent<React.PropsWithChildren<PersonalStrengthsChartProps>> = ({
    groups,
    isPrintable = false,
    matchProfileSummary = false,
}) => {
    const theme = useTheme()

    return (
        <section>
            {!isPrintable && (
                <section>
                    <p>
                        The results you see in this section are from verified psychometric tools. Each individual result indicates where{' '}
                        {matchProfileSummary ? 'you' : 'the Specialist'} scored on a scale of a given trait compared to the rest of the
                        cohort of individuals who have taken this test. These results are not editable.
                    </p>
                    <p>
                        Note: Since the results are relative to the entire cohort, your results may change over time as more IT Specialists
                        complete the Strength Discovery stage.
                    </p>
                    <p
                        css={css`
                            margin-bottom: 50px;
                        `}
                    >
                        We show the entire spectrum of a given trait because the real score lies within this range (with an 85% confidence
                        interval). Every test has a certain margin of error, so we reflect this in the results.
                    </p>
                </section>
            )}
            {groups.map(group => (
                <div
                    key={group.name}
                    css={css`
                        width: ${isPrintable ? '210mm' : '100%'};
                        text-align: center;
                        page-break-inside: avoid;
                        ${isPrintable && 'transform: scale(0.7)'};
                    `}
                >
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 32px;
                            color: ${theme.colors.gray_6};
                        `}
                    >
                        {group.name}
                        {!isPrintable && (
                            <Tooltip
                                title={
                                    <div>
                                        {group.tooltip}
                                        {group.linkToBlog && (
                                            <a
                                                href={group.linkToBlog}
                                                css={css`
                                                    color: inherit;
                                                    display: block;
                                                    margin-top: 20px;
                                                    :hover {
                                                        color: inherit;
                                                        text-decoration: underline;
                                                    }
                                                `}
                                            >
                                                Learn more here
                                            </a>
                                        )}
                                    </div>
                                }
                            >
                                <span>
                                    <TooltipIcon />
                                </span>
                            </Tooltip>
                        )}
                    </div>

                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            padding: 0 130px 32px;
                            font-size: 10px;
                            text-align: center;
                        `}
                    >
                        {scale.map(scaleItem => (
                            <div
                                key={scaleItem}
                                css={css`
                                    width: 24px;
                                `}
                            >
                                {scaleItem}
                            </div>
                        ))}
                    </div>

                    {group.skills.map(skill => (
                        <div
                            key={skill.traits.toString()}
                            css={css`
                                margin-bottom: 32px;
                            `}
                        >
                            <div
                                css={css`
                                    text-align: center;
                                    color: ${theme.colors.green_6};
                                    font-weight: 500;
                                `}
                            >
                                {skill.traits.join(' / ')}
                            </div>
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                `}
                            >
                                <div
                                    css={css`
                                        width: 130px;
                                        text-align: right;
                                        padding: 0 15px;
                                        color: ${skill.score
                                            ? (skill.score || 0) <= 5
                                                ? theme.colors.gray_6
                                                : theme.colors.gray_3
                                            : theme.colors.gray_3};
                                    `}
                                >
                                    {skill.lowScoreTraits.map(trait => (
                                        <Fragment key={trait}>
                                            {trait}
                                            <br />
                                        </Fragment>
                                    ))}
                                </div>
                                <div
                                    css={css`
                                        width: calc(100% - 260px);
                                    `}
                                >
                                    <div
                                        css={css`
                                            height: 2px;
                                            border-radius: 1px;
                                            background-color: ${theme.colors.green_3};
                                            position: relative;
                                        `}
                                    >
                                        {skill.score && (
                                            <div
                                                css={css`
                                                    background-color: ${theme.colors.green_4};
                                                    height: 4px;
                                                    padding-left: ${skill.score !== 1 ? 'calc((100% - 240px) / 9 + 24px)' : '0'};
                                                    padding-right: ${skill.score !== 10 ? 'calc((100% - 240px) / 9 + 24px)' : '0'};
                                                    width: ${skill.score === 1 || skill.score === 10
                                                        ? 'calc((100% - 240px) / 9 + 48px)'
                                                        : 'calc((100% - 240px) / 9 * 2 + 72px)'};
                                                    border-radius: 2px;
                                                    position: relative;
                                                    top: -1px;
                                                    left: ${skill.score === 1
                                                        ? '0'
                                                        : `calc((100% - 240px) / 9 * ${(skill.score || 0) - 2} + 24px * ${
                                                              (skill.score || 0) - 2
                                                          })`};
                                                `}
                                            >
                                                <div
                                                    css={css`
                                                        background-color: ${theme.colors.gray_6};
                                                        color: ${theme.colors.white};
                                                        border: 2px solid ${theme.colors.white};
                                                        border-radius: 12px;
                                                        width: 24px;
                                                        height: 24px;
                                                        text-align: center;
                                                        line-height: 20px;
                                                        position: relative;
                                                        top: -10px;
                                                    `}
                                                >
                                                    {skill.score}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div
                                    css={css`
                                        width: 130px;
                                        text-align: left;
                                        padding: 0 15px;
                                        color: ${(skill.score || 0) <= 5 ? theme.colors.gray_3 : theme.colors.gray_6};
                                    `}
                                >
                                    {skill.highScoreTraits.map(trait => (
                                        <Fragment key={trait}>
                                            {trait}
                                            <br />
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </section>
    )
}

export { PersonalStrengthsChart }
