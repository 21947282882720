import styled from '@emotion/styled'
import { BreakpointIndex, mqMin } from '../../GlobalStyle'

type ToggleFromProps = {
    breakpoint: BreakpointIndex
}

const HideFrom = styled.div<ToggleFromProps>`
    display: block;
    ${(props: ToggleFromProps) => mqMin[props.breakpoint]} {
        display: none;
    }
`

const ShowFrom = styled.div<ToggleFromProps>`
    display: none;
    ${(props: ToggleFromProps) => mqMin[props.breakpoint]} {
        display: block;
    }
`

export { ShowFrom, HideFrom }
