import { adjectives, animals, Config, countries, names, starWars, uniqueNamesGenerator } from 'unique-names-generator'
import { v4 as uuidv4 } from 'uuid'
import { KanbanCard } from '../../components/kanban-board/contract'
import { SearchApplication } from '../../contracts/open-opportunities/contracts'
import { ApplicationStatusEnum, applicationStatusesList, ApplicationStatusMapping } from '../../contracts/opportunities-manager/contracts'

const statusesArray = applicationStatusesList.concat(ApplicationStatusEnum.OPPORTUNITY_CLOSED)
const columnsList = statusesArray.map(status => ({ id: status, title: ApplicationStatusMapping[status] }))

const nameConfig = {
    dictionaries: [names],
    length: 1,
    style: 'capital',
} as Config

const countryConfig = {
    dictionaries: [countries],
    length: 1,
    style: 'capital',
} as Config

const roleConfig = {
    dictionaries: [starWars],
    length: 1,
    style: 'capital',
} as Config

const cityConfig = {
    dictionaries: [adjectives, animals],
    length: 2,
    separator: ' ',
    style: 'capital',
} as Config

const getRandomStatus = () => statusesArray[Math.floor(Math.random() * statusesArray.length)]

const getApplications = (
    applicationsNumber: number,
    prevApplicationsList: Array<KanbanCard<SearchApplication>> = [],
): Array<KanbanCard<SearchApplication>> => {
    const statusesCounter: { [k: string]: number } = {}
    statusesArray.forEach(status => {
        statusesCounter[status] = 0
    })

    prevApplicationsList.forEach(application => {
        statusesCounter[application.columnId]++
    })

    return Array(applicationsNumber)
        .fill(undefined)
        .map(() => {
            const status = getRandomStatus()
            statusesCounter[status]++
            return {
                index: statusesCounter[status] - 1,
                columnId: status,
                id: uuidv4(),
                applicationId: uuidv4(),
                opportunityId: uuidv4(),
                opportunityTitle: 'to apply 20.06',
                generatedOpportunityId: '38ae',
                firstName: uniqueNamesGenerator(nameConfig),
                lastName: uniqueNamesGenerator(nameConfig),
                role: uniqueNamesGenerator(roleConfig),
                country: uniqueNamesGenerator(countryConfig),
                city: uniqueNamesGenerator(cityConfig),
                appliedOn: 1656934875,
                status: {
                    status,
                    label: 'Opportunity closed automatically',
                    rejectionReason: undefined,
                    draft: false,
                    rejectionReasonComment: null,
                },
                smeCloud: false,
            }
        })
}

export { columnsList, getApplications }
