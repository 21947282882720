/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react'
import { FunctionComponent, useCallback } from 'react'
import { FilterDoubleColumn, FiltersBarWrapper, FiltersClearTrigger } from '../../components/filters/FiltersBarHelpers'
import { AutocompleteSelect } from '../../components/inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../../components/inputs/autocomplete-select/Option'
import { DatePickerInput } from '../../components/inputs/DatePickerInput'
import { FlexBox } from '../../components/layout/FlexBoxHelpers'
import { mqMax } from '../../GlobalStyle'
import { Nullable } from '../../types'
import { ApplicationStatusEnum, ApplicationStatusMapping } from '../../contracts/opportunities-manager/contracts'
import { ApplicationsFiltersResponse } from 'src/contracts/open-opportunities/contracts'

export type ApplicationsFilters = {
    appliedOn: Nullable<number>
    cities: Array<string>
    countries: Array<string>
    roles: Array<string>
    statuses: Array<ApplicationStatusEnum>
    submittedBy: Array<string>
}

type ApplicationsFiltersBarProps = {
    filtersCount: number
    filtersOptions: ApplicationsFiltersResponse
    onClearAll: () => void
    opened: boolean
    selectedFilters: ApplicationsFilters
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
    onLocationChange: (values: Array<OptionType>, filterName: string) => void
    onDatePickerChange: (timestamp: any, filterName: string) => void
}

const ApplicationsFiltersBar: FunctionComponent<React.PropsWithChildren<ApplicationsFiltersBarProps>> = ({
    filtersCount,
    filtersOptions,
    onClearAll,
    opened,
    selectedFilters,
    onSelectFilterChange,
    onLocationChange,
    onDatePickerChange,
}) => {
    const onAppliedOnChange = useCallback((appliedOn: any) => onDatePickerChange(appliedOn, 'appliedOn'), [onDatePickerChange])

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    ${mqMax[1]} {
                        flex-direction: column;
                    }
                `}
            >
                <FilterDoubleColumn width={17}>
                    <AutocompleteSelect
                        multiple
                        placeholder='Role'
                        filterName='roles'
                        options={filtersOptions.roles}
                        currentValues={selectedFilters.roles}
                        onSelectedValuesChange={onSelectFilterChange}
                    />
                </FilterDoubleColumn>
                <FilterDoubleColumn width={17}>
                    <AutocompleteSelect
                        multiple
                        placeholder='Location'
                        filterName='citiesCountries'
                        options={filtersOptions.countries
                            .map(country => ({ value: country, groupName: 'Countries' }))
                            .concat(filtersOptions.cities.map(city => ({ value: city, groupName: 'Cities' })))}
                        currentValues={selectedFilters.countries
                            .map(country => ({ value: country, groupName: 'Countries' }))
                            .concat(selectedFilters.cities.map(city => ({ value: city, groupName: 'Cities' })))}
                        onSelectedValuesChange={onLocationChange}
                        selectedLabelTransformer={(option: any) => option.value}
                    />
                </FilterDoubleColumn>
                <FilterDoubleColumn width={13}>
                    <DatePickerInput
                        placeholder='Applied On'
                        onChange={onAppliedOnChange}
                        value={selectedFilters.appliedOn}
                        isActive={Boolean(selectedFilters.appliedOn)}
                        style={{ padding: '8px 9px 8px 16px' }}
                    />
                </FilterDoubleColumn>

                <FilterDoubleColumn width={17}>
                    <AutocompleteSelect
                        multiple
                        placeholder='Status'
                        filterName='statuses'
                        options={filtersOptions.statuses.map(s => s.status)}
                        currentValues={selectedFilters.statuses}
                        labelTransformer={(opt: string) => ApplicationStatusMapping[opt]}
                        selectedLabelTransformer={(opt: string) => ApplicationStatusMapping[opt]}
                        onSelectedValuesChange={onSelectFilterChange}
                        dataTestId='application-status'
                    />
                </FilterDoubleColumn>
                <FilterDoubleColumn width={13}>
                    <AutocompleteSelect
                        multiple
                        placeholder='Submitted By'
                        filterName='submittedBy'
                        options={filtersOptions.submittedBy}
                        currentValues={selectedFilters.submittedBy}
                        onSelectedValuesChange={onSelectFilterChange}
                        dataTestId='application-submitted-by'
                    />
                </FilterDoubleColumn>
                <div
                    css={css`
                        margin-left: auto;
                    `}
                >
                    <FiltersClearTrigger onClear={onClearAll} quantity={filtersCount} />
                </div>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { ApplicationsFiltersBar }
