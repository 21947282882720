/** @jsxImportSource @emotion/react */
import { css, useTheme } from '@emotion/react'
import { Tooltip } from 'antd'
import { FunctionComponent, useMemo } from 'react'
import { VerifiedSkill } from '../../contracts/profile/skill'
import { Icon } from '../Icon'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { TextHighlight } from '../TextHighlight'

type SkillItemProps = {
    skill: string | VerifiedSkill
    index: number
    list: Array<string> | Array<VerifiedSkill>
    searchWords?: Array<string>
}

const SkillItem: FunctionComponent<React.PropsWithChildren<SkillItemProps>> = ({ skill, index, list, searchWords = [] }) => {
    const theme = useTheme()

    const isVerified = useMemo(() => typeof skill !== 'string' && skill.verified, [skill])

    return (
        <span
            css={css`
                word-break: keep-all;
                display: flex;
                position: relative;
                align-items: center;
                padding: 1px 0;
                line-height: 22px;
            `}
            data-testid={`skill-item`}
        >
            <div
                css={css`
                    color: ${theme.colors.violet_6};
                    ${isVerified ? 'font-weight:500' : ''}
                `}
            >
                {typeof skill === 'string' ? (
                    <TextHighlight searchWords={searchWords} text={skill} />
                ) : (
                    <TextHighlight searchWords={searchWords} text={skill.name} />
                )}
            </div>

            {isVerified && (
                <Tooltip title='This skill is verified by Talent Alpha knowledge test'>
                    <FlexBox
                        alignItems='center'
                        css={css`
                            background-color: ${theme.colors.blue_3};
                            border-radius: 50%;
                            padding: 3px;
                            margin-left: 4px;
                        `}
                    >
                        <Icon name='check' size={8} />
                    </FlexBox>
                </Tooltip>
            )}

            {index !== list.length - 1 && (
                <span
                    css={css`
                        color: ${theme.colors.gray_3};
                        margin: 0 10px;
                        display: inline-block;
                        font-size: 10px;
                        text-align: center;
                    `}
                >
                    &#9679;
                </span>
            )}
        </span>
    )
}

export { SkillItem }
