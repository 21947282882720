/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import moment, { Moment } from 'moment'
import { FunctionComponent, useCallback } from 'react'
import { FilterColumn, FiltersBarWrapper, FiltersClearTrigger } from '../filters/FiltersBarHelpers'
import { AutocompleteSelect } from '../inputs/autocomplete-select/AutocompleteSelect'
import { OptionType } from '../inputs/autocomplete-select/Option'
import { DatePickerInput } from '../inputs/DatePickerInput'
import { FlexBox } from '../layout/FlexBoxHelpers'
import { countArrayFilters } from '../../utils/filters'
import { SearchOpportunitiesFilters, SearchOpportunitiesFiltersOptions } from '../../contracts/opportunities-manager/contracts'

type OpportunitiesFiltersBarProps = {
    filtersOptions: SearchOpportunitiesFiltersOptions
    selectedFilters: SearchOpportunitiesFilters
    opened: boolean
    onClearAll: () => void
    onSelectFilterChange: (values: Array<OptionType>, filterName: string) => void
    onLocationChange: (values: Array<OptionType>, filterName: string) => void
    onValueChange: (value: any, filterName: string) => void
    onDatePickerChange: (timestamp: any, filterName: string) => void
}

const OpportunitiesFiltersBar: FunctionComponent<React.PropsWithChildren<OpportunitiesFiltersBarProps>> = ({
    filtersOptions,
    selectedFilters,
    opened,
    onClearAll,
    onSelectFilterChange,
    onLocationChange,
    onValueChange,
    onDatePickerChange,
}) => {
    const onAddedOnDateChange = useCallback((addedOn: any) => onDatePickerChange(addedOn, 'addedOn'), [onDatePickerChange])
    const onProjectStartDateChange = useCallback(
        (projectStartDate: any) => onDatePickerChange(projectStartDate, 'projectStartDate'),
        [onDatePickerChange],
    )
    const onProjectEndDateChange = useCallback(
        (projectEndDate: any) => onDatePickerChange(projectEndDate, 'projectEndDate'),
        [onDatePickerChange],
    )
    const onLastUpdateDateChange = useCallback(
        (projectEndDate: any) => onDatePickerChange(projectEndDate, 'lastUpdate'),
        [onDatePickerChange],
    )
    const disableProjectEndDate = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc()) ||
            (selectedFilters.projectStartDate !== undefined && selectedFilters.projectStartDate !== null
                ? date.isBefore(moment.utc(selectedFilters.projectStartDate * 1000))
                : false),

        [selectedFilters],
    )
    const disableProjectStartDate = useCallback(
        (date: Moment): boolean =>
            date.isBefore(moment.utc()) ||
            (selectedFilters.projectStartDate !== undefined && selectedFilters.projectEndDate !== null
                ? date.isAfter(moment.utc(selectedFilters.projectEndDate * 1000))
                : false),
        [selectedFilters],
    )

    return (
        <FiltersBarWrapper filtersOpened={opened}>
            <FlexBox
                css={css`
                    width: 100%;
                    align-items: center;
                `}
            >
                <FlexBox
                    css={css`
                        flex-wrap: wrap;
                        width: 100%;
                        align-items: center;
                    `}
                >
                    <FilterColumn>
                        <AutocompleteSelect
                            placeholder='Owner name'
                            filterName='ownerName'
                            options={filtersOptions.ownersNames}
                            currentValues={[selectedFilters.ownerName]}
                            onSelectedValuesChange={onValueChange}
                            canFilter
                            canClear
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Project Start Date'
                            onChange={onProjectStartDateChange}
                            value={selectedFilters.projectStartDate}
                            isActive={Boolean(selectedFilters.projectStartDate)}
                            disabledDate={disableProjectStartDate}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Project End Date'
                            onChange={onProjectEndDateChange}
                            value={selectedFilters.projectEndDate}
                            isActive={Boolean(selectedFilters.projectEndDate)}
                            disabledDate={disableProjectEndDate}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Location'
                            filterName='countriesRegions'
                            options={filtersOptions.regions
                                .map(country => ({ value: country, groupName: 'Regions' }))
                                .concat(filtersOptions.countries.map(country => ({ value: country, groupName: 'Countries' })))}
                            currentValues={selectedFilters.regions
                                .map(region => ({ value: region, groupName: 'Regions' }))
                                .concat(selectedFilters.countries.map(country => ({ value: country, groupName: 'Countries' })))}
                            onSelectedValuesChange={onLocationChange}
                            selectedLabelTransformer={(option: any) => option.value}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Location type'
                            filterName='locationTypes'
                            options={filtersOptions.locationTypes}
                            currentValues={selectedFilters.locationTypes}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                            dataTestId='opportunity-location-types'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Added on'
                            onChange={onAddedOnDateChange}
                            value={selectedFilters.addedOn}
                            isActive={Boolean(selectedFilters.addedOn)}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Status'
                            filterName='statuses'
                            options={filtersOptions.statuses}
                            currentValues={selectedFilters.statuses}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                            dataTestId='opportunity-statuses'
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <DatePickerInput
                            placeholder='Last Updated'
                            onChange={onLastUpdateDateChange}
                            value={selectedFilters.lastUpdate}
                            isActive={Boolean(selectedFilters.lastUpdate)}
                        />
                    </FilterColumn>
                    <FilterColumn>
                        <AutocompleteSelect
                            multiple
                            placeholder='Industries'
                            filterName='industries'
                            options={filtersOptions.industries}
                            currentValues={selectedFilters.industries}
                            onSelectedValuesChange={onSelectFilterChange}
                            canFilter={false}
                            dataTestId='opportunity-industries'
                        />
                    </FilterColumn>
                    <FlexBox
                        justifyContent='flex-end'
                        css={css`
                            flex-grow: 1;
                            margin-bottom: 16px;
                        `}
                    >
                        <FiltersClearTrigger
                            onClear={onClearAll}
                            quantity={countArrayFilters(selectedFilters, Object.keys(selectedFilters))}
                        />
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </FiltersBarWrapper>
    )
}

export { OpportunitiesFiltersBar }
