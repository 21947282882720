/** @jsxImportSource @emotion/react */

import { FunctionComponent, useEffect, useState } from 'react'

import { RegisterForm } from '../../components/RegisterForm'

import { getUserEmailVMC } from '../dashboard/api'
import { useParams } from 'react-router-dom'
import { VMCInvitationAdditionalDataResponse } from 'src/contracts/vmc'

const VMCInvitationAuthPage: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
    const params = useParams()

    const [userData, setUserData] = useState<VMCInvitationAdditionalDataResponse | null>(null)

    useEffect(() => {
        if (params.prospectId && !userData) {
            getUserEmailVMC(params.prospectId)
                .then(response => {
                    setUserData(response)
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {})
        }
    }, [params, userData])

    return (
        <RegisterForm
            forcedEmail={userData?.email ? userData?.email : ''}
            header={<h5>Join {userData?.companyName} by creating a new account on the Talent Alpha Platform</h5>}
            isVMCinvitationPage={true}
        />
    )
}

export { VMCInvitationAuthPage }
