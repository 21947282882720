import { getAppPath } from '../../../contracts/applications'

const menuItems = [
    {
        path: decodeURIComponent(getAppPath('OPPORTUNITIES_MANAGER')),
        title: 'Opportunities',
    },
    {
        path: decodeURIComponent(getAppPath('ALL_CANDIDATES')),
        title: 'All Candidates',
    },
]

const SMEmenuItems = [
    {
        path: decodeURIComponent(getAppPath('OPPORTUNITIES_MANAGER_SME')),
        title: 'Opportunities SME',
    },
    {
        path: decodeURIComponent(getAppPath('ALL_CANDIDATES_SME')),
        title: 'All Candidates',
    },
]

const VacancyManagerMenuItems = [
    {
        path: decodeURIComponent(getAppPath('VACANCIES_MANAGER')),
        title: 'Vacancies',
    },
    {
        path: decodeURIComponent(getAppPath('VACANCIES_MANAGER_ALL_CANDIDATES')),
        title: 'All Candidates',
    },
]

export { SMEmenuItems, menuItems, VacancyManagerMenuItems }
