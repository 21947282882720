/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Fragment, FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useLifecycles } from 'react-use'
import { IconButton } from '../../../components/Icon'
import { OverlayContentLoader } from '../../../components/layout/Loader'
import { useNotifications } from '../../../components/notification/NotificationProvider'
import { Nullable } from '../../../types'
import { useLogger } from '../../../utils/useLogger'
import { getSpecialistProfileInformation } from '../api'
import { TalentPersonalDetails } from '../components/TalentPersonalDetails'
import { SpecialistProfileInformationResponse } from '../contracts'
import { SectionHeaderWithActions } from '../shared/HeaderWithActions'
import { PersonalTabReadOnly } from './PersonalTabReadOnly'
import { SectionWrapper } from './SectionHelpers'
import { ProfileTabProps } from './tabTypes'

const PersonalTab: FunctionComponent<React.PropsWithChildren<ProfileTabProps>> = ({ specialistId, onSave }) => {
    const log = useLogger('error')

    const [editModeEnabled, setEditModeEnabled] = useState(false)
    const [specialistData, setSpecialistData] = useState<Nullable<SpecialistProfileInformationResponse>>(null)
    const [isMounted, setIsMounted] = useState(false)

    const { addError } = useNotifications()

    useLifecycles(
        () => setIsMounted(true),
        () => setIsMounted(false),
    )

    const handleError = useCallback(
        (err: any) => {
            log(err)
            addError('Something went wrong.')
        },
        [addError, log],
    )

    const fetchProfileInformation = useCallback(() => {
        getSpecialistProfileInformation(specialistId)
            .then(data => {
                if (isMounted) {
                    setSpecialistData(data)
                }
            })
            .catch(handleError)
    }, [specialistId, handleError, isMounted])

    useEffect(() => fetchProfileInformation(), [fetchProfileInformation])

    const enableEditMode = useCallback(() => setEditModeEnabled(true), [setEditModeEnabled])
    const disableEditMode = useCallback(() => setEditModeEnabled(false), [setEditModeEnabled])

    const handleSave = useCallback(() => {
        if (onSave) {
            onSave()
        }

        disableEditMode()
        fetchProfileInformation()
    }, [onSave, disableEditMode, fetchProfileInformation])

    return (
        <SectionWrapper>
            <SectionHeaderWithActions
                header='Personal Information'
                tooltip='This information is completely optional and will never be shared with anyone outside of your company. Feel free to leave this section blank.'
                divider
            >
                {!editModeEnabled && <IconButton dataTestId='personal-details-edit' name='edit' size={32} onClick={enableEditMode} />}
            </SectionHeaderWithActions>
            <section
                css={css`
                    position: relative;
                `}
            >
                {specialistData === null ? (
                    <OverlayContentLoader />
                ) : (
                    <Fragment>
                        {editModeEnabled ? (
                            <TalentPersonalDetails
                                onCancel={disableEditMode}
                                onSubmit={handleSave}
                                specialistData={specialistData}
                                specialistId={specialistId}
                            />
                        ) : (
                            <PersonalTabReadOnly personalData={specialistData} />
                        )}
                    </Fragment>
                )}
            </section>
        </SectionWrapper>
    )
}

export { PersonalTab }
