/** @jsxImportSource @emotion/react */
import React from 'react'
import styled from '@emotion/styled'

import { mqMax } from '../GlobalStyle'

const NavbarContainer = styled.div<{ onlyDesktop: boolean }>`
    flex: 2;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};

    ${mqMax[1]} {
        ${({ onlyDesktop }) => `display: ${onlyDesktop ? 'none' : 'initial'}`}
    }
`

const NavbarEntry = styled.div<{ active: boolean }>`
    padding: ${({ active }) => (active ? '0 16px 0 14px' : '0 16px')};
    border-left: ${({ theme, active }) => (active ? `3px solid ${theme.colors.main}` : `1px solid ${theme.colors.gray_2}`)};
    border-right: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};
    cursor: pointer;

    &:last-of-type > div {
        border-bottom: none;
    }
`

const NavbarEntryName = styled.div`
    color: ${({ theme }) => theme.colors.violet_5};
    padding: 14px 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray_2}`};
`

type Item = string
interface OwnProps {
    onlyDesktop?: boolean
    handleClick: (item: Item) => void
    items: Item[]
    selectedItem: Item
}

const NavBar: React.FC<OwnProps> = ({ items, selectedItem, handleClick, onlyDesktop = false }) => {
    return (
        <NavbarContainer onlyDesktop={onlyDesktop}>
            {items.map(item => {
                return (
                    <NavbarEntry key={item} active={item === selectedItem} onClick={() => handleClick(item)}>
                        <NavbarEntryName>{item}</NavbarEntryName>
                    </NavbarEntry>
                )
            })}
        </NavbarContainer>
    )
}

export { NavBar }
